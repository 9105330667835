/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { Fragment, useMemo, useCallback, useState } from "react";
import {
  useRouteMatch,
  useHistory,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { DRMFeature } from "components/Deductions/DeductionsReconciliation/redux/DRMSlice";
import {
  setERPActiveFilters,
  setERPSorting
} from "components/Deductions/DeductionsReconciliation/redux/ERPSlice";
import { useDb } from "contexts/Db";
import { displayUSCurrency } from "helpers/DataProcessing";
import { ERPTransactionObject } from "components/Deductions/models";
import ScrollableColumns from "ui-library/ScrollableColumns";
import { deductionTransactionStatuses } from "components/Deductions/constants/ReconciliationStatuses";
import moment from "moment";
import { DateTime } from "luxon";
import { useOpenClose } from "contexts/OpenClose";
import { infoDialog, confirmDialog } from "helpers/OpenDialog";
import {
  TransactionDisplayService,
  TransactionProcessingService,
  TransactionDependenciesService
} from "reconciliation-services";
import {
  transactionFilters,
  DisplayERPTransactionObject,
  transactionActions
} from "components/Deductions/DeductionsReconciliation/types/transactionTypes";
import { useDRMEvents } from "contexts/DRMEvents";
import { Delete, OpenInNew } from "@mui/icons-material";
import {
  SummaryConfigOptions,
  CollapsibleTableSummaryType
} from "ui-library/CollapsibleTable/CollapsibleTableSummary";
import { renderUTCDateTimeInLocalTimeZone } from "helpers/Time";
import TransactionToolbarActions from "./TransactionToolbarActions";
import ViewTable from "../ViewTable";
import { DRMEventType } from "../../ActivityLog/DRMEvent";
import TransactionCard from "./TransactionCard";
import { DisplayInvoiceObject } from "../../types/invoiceTypes";

export default function ViewERPTransactions() {
  const [currentTransactionFilter, setTransactionFilter] =
    useState<transactionFilters>(transactionFilters.ACTIVE);
  const { activeFilters, sortState } = useSelector(
    (state: RootState) => state.erpTable,
    shallowEqual
  );
  const db = useDb();
  const { addEvent } = useDRMEvents();
  const {
    invoices = {},
    erpTransactions = {},
    customers = {},
    companyUsers = {},
    meta = {},
    permissions = []
  } = db;
  const { accounting_sources: accountingSources = [] } = meta;
  const canCancelTransactions = permissions.includes(
    "cancelInvoicesTransactions"
  );
  const openClose = useOpenClose();
  const { setAppModal, closeAppModal, showSnack } = openClose;
  const history = useHistory();
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  const handleProfileOpen = useCallback(
    (_event, rowData: DisplayERPTransactionObject) => {
      history.push(`/${DRMFeature.RECONCILIATION}/transaction/${rowData.key}`);
    },
    [history]
  );

  const handleCancelTransactionRow = useCallback(
    (_event, rowData: DisplayERPTransactionObject) => {
      const { actionAllowed, dependenciesMessage } =
        TransactionDependenciesService.isSingleTransactionActionAllowed(
          rowData,
          transactionActions.CANCEL,
          invoices
        );

      const newOpenClose = {
        setAppModal,
        closeAppModal
      };

      if (actionAllowed) {
        confirmDialog(
          newOpenClose,
          transactionActions.CANCEL,
          "Are you sure you want to cancel this transaction? This action cannot be undone.",
          () =>
            TransactionProcessingService.updateFirebaseTransaction(
              {
                ...rowData,
                status: deductionTransactionStatuses.CANCELLED
              },
              () => {
                addEvent({
                  type: DRMEventType.TRANSACTION_CANCELLED,
                  transactionKey: rowData.key
                });
                showSnack(
                  `Transaction ${rowData.transactionDisplayId} has been cancelled.`
                );
              }
            )
        );
      } else {
        infoDialog(
          newOpenClose,
          `Error - ${transactionActions.CANCEL}`,
          `Transaction ${rowData.transactionDisplayId} cannot be cancelled because: ${dependenciesMessage}. Please handle these dependencies before trying again.`,
          `Go Back`
        );
      }
    },
    [invoices, setAppModal, closeAppModal, addEvent, showSnack]
  );

  const getFilteredTransactions = useCallback(
    (
      allTransactions: Record<string, ERPTransactionObject>,
      currentFilter: transactionFilters
    ) => {
      return Object.values(allTransactions).filter(transaction => {
        switch (currentFilter) {
          case transactionFilters.ACTIVE: {
            return (
              transaction.status !== deductionTransactionStatuses.CANCELLED
            );
          }
          case transactionFilters.INACTIVE: {
            return (
              transaction.status === deductionTransactionStatuses.CANCELLED
            );
          }
          default: {
            return false;
          }
        }
      });
    },
    []
  );

  const transactions: DisplayERPTransactionObject[] = useMemo(
    () =>
      getFilteredTransactions(
        erpTransactions || {},
        currentTransactionFilter
      ).map(transaction =>
        TransactionDisplayService.processTransactionForDisplay(
          transaction,
          invoices,
          erpTransactions,
          customers,
          companyUsers
        )
      ),
    [
      companyUsers,
      currentTransactionFilter,
      customers,
      erpTransactions,
      getFilteredTransactions,
      invoices
    ]
  );

  const columns = useMemo(() => {
    return Object.keys(deductionTransactionStatuses)
      .map(status => {
        const data = transactions.filter(
          transaction =>
            transaction.status === deductionTransactionStatuses[status]
        );
        return {
          name: TransactionDisplayService.HumanReadableTransactionStatuses[
            deductionTransactionStatuses[status]
          ],
          data
        };
      })
      .filter(status => {
        return currentTransactionFilter === transactionFilters.INACTIVE
          ? true
          : status.name !==
              TransactionDisplayService.HumanReadableTransactionStatuses[
                deductionTransactionStatuses.CANCELLED
              ];
      });
  }, [transactions, currentTransactionFilter]);

  const rowControls = [
    {
      label: "Open Profile",
      icon: <OpenInNew color="action" />,
      onClick: (e, rowData) => handleProfileOpen(e, rowData)
    }
  ].concat(
    canCancelTransactions
      ? [
          {
            label: "Cancel Transaction",
            icon: <Delete color="action" />,
            onClick: (e, rowData) => handleCancelTransactionRow(e, rowData)
          }
        ]
      : []
  );

  const columnConfig = useMemo(() => {
    return {
      transactionDisplayId: {
        label: "Transaction ID #",
        filter: {
          enabled: true
        },
        sorting: {
          enabled: true
        }
      },
      type: {
        label: "Type",
        render: (_, rowData) =>
          TransactionDisplayService.HumanReadableTransactionTypes[rowData.type],
        sorting: {
          enabled: true
        },
        filter: {
          enabled: true
        }
      },
      status: {
        label: "Status",
        render: (_, rowData) =>
          TransactionDisplayService.HumanReadableTransactionStatuses[
            rowData.status
          ],
        sorting: {
          enabled: true
        },
        filter: {
          enabled: true,
          textFilterOverride: (
            value: string,
            rowData: DisplayInvoiceObject
          ) => {
            const displayStatus: string =
              TransactionDisplayService.HumanReadableTransactionStatuses[
                rowData.status
              ];
            return displayStatus.toLowerCase().includes(value.toLowerCase());
          }
        }
      },
      customerName: {
        label: "Customer",
        filter: {
          enabled: true
        },
        sorting: {
          enabled: true
        }
      },
      amount: {
        label: "Original Amount",
        render: (_, rowData) => displayUSCurrency(rowData.amount),
        filter: {
          enabled: true,
          range: {
            min: Math.min(...transactions.map(t => t.amount ?? 0)),
            max: Math.max(...transactions.map(t => t.amount ?? 0))
          }
        },
        sorting: {
          enabled: true
        }
      },
      openAmount: {
        label: "Open Amount",
        render: (_, rowData) => displayUSCurrency(rowData.openAmount),
        filter: {
          enabled: true,
          range: {
            min: Math.min(...transactions.map(t => t.openAmount ?? 0)),
            max: Math.max(...transactions.map(t => t.openAmount ?? 0))
          }
        },
        sorting: {
          enabled: true
        }
      },
      checkNumber: {
        label: "Check #",
        filter: {
          enabled: true
        },
        sorting: {
          enabled: true
        }
      },
      invoiceNumber: {
        label: "Invoice #",
        filter: {
          enabled: true
        },
        sorting: {
          enabled: true
        }
      },
      transactionDate: {
        label: "Transaction Date",
        render: (_, rowData) =>
          rowData.transactionDate
            ? DateTime.fromISO(new Date(rowData.transactionDate).toISOString())
                .toUTC()
                .toFormat("D")
            : "",
        filter: {
          enabled: true,
          daterange: {
            min: new Date(),
            max: new Date(),
            minLabel: "Start",
            maxLabel: "End"
          }
        },
        sorting: {
          enabled: true,
          customSort: (
            a: DisplayERPTransactionObject,
            b: DisplayERPTransactionObject
          ) => moment.utc(a.transactionDate).diff(moment.utc(b.transactionDate))
        }
      },
      createdDate: {
        label: "Date Imported",
        render: (_, rowData) =>
          rowData.createdDate
            ? renderUTCDateTimeInLocalTimeZone(rowData.createdDate)
            : "",
        sorting: {
          enabled: true,
          customSort: (
            a: DisplayERPTransactionObject,
            b: DisplayERPTransactionObject
          ) => moment.utc(a.createdDate).diff(moment.utc(b.createdDate))
        },
        filter: {
          enabled: true,
          daterange: {
            min: new Date(),
            max: new Date(),
            minLabel: "Start",
            maxLabel: "End"
          }
        }
      },
      displayAssignedUser: {
        label: "Assigned To",
        sorting: {
          enabled: true
        },
        filter: {
          enabled: true
        }
      },
      accountingSource: {
        label: "Accounting Source",
        sorting: {
          enabled: true
        },
        filter: {
          enabled: true
        }
      }
    };
  }, [transactions]);

  const summaryConfig = useMemo(
    () =>
      new Map([
        [
          "key",
          {
            label: "# Transactions",
            type: CollapsibleTableSummaryType.COUNT
          }
        ],
        [
          "amount",
          {
            label: "Total Original Amount",
            type: CollapsibleTableSummaryType.SUM,
            render: val => displayUSCurrency(val)
          }
        ],
        [
          "openAmount",
          {
            label: "Total Open Amount",
            type: CollapsibleTableSummaryType.SUM,
            render: val => displayUSCurrency(val)
          }
        ]
      ] as [keyof DisplayERPTransactionObject, SummaryConfigOptions<DisplayERPTransactionObject>][]),
    []
  );

  return (
    <div>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <TransactionToolbarActions
        setTransactionFilter={setTransactionFilter}
        currentTransactionFilter={currentTransactionFilter}
      />
      <Switch>
        <Route exact path={`/${DRMFeature.RECONCILIATION}/transactions`}>
          <Redirect to={`/${DRMFeature.RECONCILIATION}/transactions/list`} />
        </Route>
        <Route path={`${path}/board`}>
          <ScrollableColumns<DisplayERPTransactionObject>
            columns={columns}
            renderCard={(
              key: string,
              index: number,
              data: DisplayERPTransactionObject[]
            ): JSX.Element => (
              <Fragment key={key}>
                <TransactionCard interactive transaction={data[key]} />
              </Fragment>
            )}
          />
        </Route>
        <Route path={`${path}/list`}>
          <ViewTable<DisplayERPTransactionObject>
            data={transactions}
            rowControls={rowControls}
            onRowClick={handleProfileOpen}
            pageLength={20}
            fontSize="0.75rem"
            columnsConfig={columnConfig}
            summaryConfig={summaryConfig}
            summaryStyles={{ leftIndent: true }}
            activeFilters={activeFilters}
            sortState={sortState}
            setActiveFilters={payload => dispatch(setERPActiveFilters(payload))}
            setSortState={payload => dispatch(setERPSorting(payload))}
            resetToDefaults={() => {
              dispatch(
                setERPSorting({
                  orderBy: "transactionDate",
                  sortDir: "asc"
                })
              );
            }}
          />
        </Route>
      </Switch>
    </div>
  );
}
