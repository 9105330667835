import React, { SetStateAction, useMemo } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useFormik } from "formik";
import firebase from "firebase-init";

import getColumns from "./CompanyMappingsTableColumns";
import { BackupRetrievalMeta, useCompanyMapping } from "./ScrapingContext";

type CompanyMappingsTableProps = {
  setShowCustomerMappingTable: SetStateAction<any>;
  formik: ReturnType<typeof useFormik>;
};

function CompanyMappingsTable({
  setShowCustomerMappingTable,
  formik
}: CompanyMappingsTableProps) {
  const { companyMapping: companies, currentCompany } = useCompanyMapping();

  const toggleMappingActiveState = (values, active) => {
    const itemsRef = firebase
      .database()
      .ref(`backupRetrieval/${values.companyId}/${values.portal}`);
    itemsRef.update({
      ...values,
      isInactive: active === false
    });
  };

  const companyMappings = useMemo(() => {
    return Object.values(companies).reduce<Array<BackupRetrievalMeta>>(
      (acc, portal) => {
        acc.push(
          ...Object.values(portal).map(p => ({
            ...p,
            id: `${p.companyId}-${p.customer}`
          }))
        );

        return acc;
      },
      []
    );
  }, [companies]);

  const currentCompanyMappings = useMemo(
    () =>
      companyMappings.filter(mapping => mapping.companyId === currentCompany),
    [companyMappings, currentCompany]
  );

  const columns = getColumns(
    formik,
    setShowCustomerMappingTable,
    toggleMappingActiveState
  );

  return (
    <DataGridPro rows={currentCompanyMappings} columns={columns} pageSize={5} />
  );
}

export default CompanyMappingsTable;
