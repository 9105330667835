import React from "react";

import Typography from "@mui/material/Typography";
import ClearIcon from "@mui/icons-material/Clear";
import Chip from "@mui/material/Chip";
import Button from "ui-library/Button";
import Select from "ui-library/Select";
import MenuItem from "ui-library/MenuItem";
import TextField from "ui-library/TextField";
import {
  getUserDomains,
  setUserDomains,
  addUserDomain
} from "../../../helpers/Firebase";
import DataTable from "../../tables/MuiDataTable";

const styles = {
  subheader: {
    fontSize: 20,
    color: "primary.main"
  },
  superheader: {
    fontFamily: "Ubuntu",
    marginTop: 2,
    padding: 0,
    color: "primary.main"
  },
  settingsButton: {
    float: "right",
    marginTop: "-36px"
  }
};

const DOMAIN_TYPES = ["Whitelisted", "Blacklisted", "Used"];

class ManageDomains extends React.Component {
  constructor(props) {
    super(props);
    this.csvLink = React.createRef();
    this.state = {
      domainList: null,
      newEmailDomain: "",
      newDomainType: "whitelisted",
      domainErrorText: ""
    };
  }

  getDomains = () => {
    getUserDomains(domains => {
      this.setState({
        domainList: domains
      });
    });
  };

  componentDidMount() {
    this.getDomains();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getDomains();
  }

  handleDeleteDomain = (domain, domainType) => {
    const { domainList } = this.state;

    const specificDomains = domainList[domainType] ?? {};
    for (const domainKey in specificDomains) {
      if (specificDomains[domainKey] == domain) {
        delete specificDomains[domainKey];
        break;
      }
    }

    setUserDomains(() => {}, domainType, specificDomains);
  };

  handleAddNewDomain = () => {
    const { domainList, newEmailDomain, newDomainType } = this.state;

    if (newEmailDomain.length == 0 || newEmailDomain.indexOf(".") == -1) {
      this.setState({
        domainErrorText: "Please enter a valid domain."
      });
      return;
    }

    for (const domainType in domainList) {
      if (Object.values(domainList[domainType]).includes(newEmailDomain)) {
        this.setState({
          domainErrorText: `This domain is already ${domainType}.`
        });
        return;
      }
    }

    addUserDomain(
      () => {
        this.setState({
          domainErrorText: ""
        });
      },
      newDomainType,
      newEmailDomain
    );
  };

  getDomainTableJSX = () => {
    const { domainList } = this.state;

    const domainTableJSX = {};

    for (const domainType of DOMAIN_TYPES) {
      const dt = domainType.toLowerCase();
      const allDomains = dt in domainList ? Object.values(domainList[dt]) : [];

      domainTableJSX[`${domainType} (${allDomains.length})`] = [
        <div style={{ verticalAlign: "top", height: "100%" }}>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              alignItems: "flex-start"
            }}>
            {allDomains.map(domain => {
              return (
                <Chip
                  size="medium"
                  label={domain}
                  onDelete={() => this.handleDeleteDomain(domain, dt)}
                  deleteIcon={<ClearIcon />}
                  style={{ margin: "3px" }}
                />
              );
            })}
          </div>
        </div>
      ];
    }

    return domainTableJSX;
  };

  render() {
    return (
      <div>
        <Typography sx={styles.superheader} variant="h4">
          Manage User Domains
        </Typography>
        <br />
        <Typography sx={styles.subheader}>Add New Domain</Typography>
        <div
          style={{
            display: "flex",
            width: "40%",
            alignItems: "flex-end",
            justifyContent: "space-between"
          }}>
          <TextField
            floatingLabelText="Domain"
            value={this.state.newEmailDomain}
            style={{ width: "50%", height: "100%" }}
            onChange={event =>
              this.setState({ newEmailDomain: event.target.value })
            }
            errorText={this.state.domainErrorText}
          />
          <Select
            floatingLabelText="Domain Type"
            onChange={event => {
              this.setState({
                newDomainType: event.target.value
              });
            }}
            value={this.state.newDomainType}
            style={{ width: "30%", height: "100%" }}>
            {DOMAIN_TYPES.map(domainType => {
              return (
                <MenuItem
                  value={domainType.toLowerCase()}
                  children={domainType}
                  key={domainType}
                />
              );
            })}
          </Select>
          <Button label="Add" onClick={() => this.handleAddNewDomain()} />
        </div>
        <br />
        {this.state.domainList && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start"
            }}>
            <DataTable
              title="Manage User Domains"
              data={this.getDomainTableJSX()}
              widths={["20%", "20%", "60%"]}
            />
          </div>
        )}
      </div>
    );
  }
}

export default ManageDomains;
