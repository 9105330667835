import React, { useMemo, useState } from "react";
import CresicorButton from "ui-library/Button";
import CresicorCard from "ui-library/Card";
import CresicorIconButton from "ui-library/IconButton";
import {
  ExpandLess,
  ExpandMore,
  PostAdd as PostAddIcon,
  Delete as DeleteIcon,
  PlusOne as PlusOneIcon,
  Scanner as ScanIcon,
  Favorite as FavoriteIcon
} from "@mui/icons-material";
import { common, grey } from "@mui/material/colors";
import { invoiceActions } from "reconciliation-types/invoiceTypes";
import { useDb } from "contexts/Db";
import { useOpenClose } from "contexts/OpenClose";
import { confirmDialog } from "helpers/OpenDialog";
import { CircularProgress, Collapse, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { InvoiceLineObject, InvoiceObject } from "components/Deductions/models";
import CollapsibleTableSummary, {
  CollapsibleTableSummaryType,
  SummaryConfigOptions
} from "ui-library/CollapsibleTable/CollapsibleTableSummary";
import { displayUSCurrency } from "helpers/DataProcessing";
import { InvoiceDependenciesService } from "reconciliation-services";
import { BACKUP_UPLOADER_FAKE_FILENAME } from "components/Deductions/DeductionsReconciliation/UploadFiles/UploadBackup/Step1/AddFiles";
import InvoiceLineTable from "./InvoiceLineTable";

const grey200 = grey["200"];
const { white } = common;

const useStyles = makeStyles(theme => ({
  tableHeading: {
    marginBottom: theme.spacing(1)
  },
  container: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1)
  },
  summaryContainer: {
    marginBottom: theme.spacing(2),
    display: "flex",
    width: "100%",
    justifyContent: "flex-end"
  }
}));

interface InvoiceProfileTableProps {
  scanButtonDisabled: boolean;
  uploadInvoiceLinesButtonDisabled: boolean;
  handleAttemptInvoiceAction: (action: any) => void;
  matchLoading: boolean;
  invoiceKey: string;
  invoice: InvoiceObject;
  invoiceLines: Record<string, InvoiceLineObject>;
  companyid: string;
}

const buttonStyle = { color: white, marginTop: -8, marginLeft: "5px" };

export default function InvoiceProfileTable(props: InvoiceProfileTableProps) {
  const {
    scanButtonDisabled,
    uploadInvoiceLinesButtonDisabled,
    handleAttemptInvoiceAction,
    matchLoading,
    invoiceKey,
    invoice,
    invoiceLines,
    companyid
  } = props;

  const classes = useStyles();
  const db = useDb();
  const openClose = useOpenClose();
  const [invoiceLinesOpen, setInvoiceLinesOpen] = useState(true);

  const { showStealthDropDownWindow, showSnack } = openClose;

  // recalculate dependencies if invoice changes
  const allInvoiceDependencies = useMemo(
    () => InvoiceDependenciesService.calculateInvoiceDependencies(invoice),
    [invoice]
  );

  const summaryConfig = useMemo(
    () =>
      new Map([
        [
          "amount",
          {
            label: "Total Original Amount",
            type: CollapsibleTableSummaryType.SUM,
            render: val => displayUSCurrency(val)
          }
        ]
      ] as [keyof InvoiceLineObject, SummaryConfigOptions<InvoiceLineObject>][]),
    []
  );

  const disableButtons =
    matchLoading || scanButtonDisabled || uploadInvoiceLinesButtonDisabled;

  return (
    <div className={classes.container}>
      <Typography
        variant="h6"
        className={classes.tableHeading}>{`Invoice Lines (${
        Object.keys(invoiceLines).length
      })`}</Typography>
      <CresicorCard
        title={" "}
        titleColor={grey200}
        action={
          <Stack>
            <CresicorButton
              disabled={
                disableButtons ||
                invoice.originalFile.fileName === BACKUP_UPLOADER_FAKE_FILENAME
              }
              size="small"
              label={
                disableButtons ? (
                  <CircularProgress size={14} style={{ color: white }} />
                ) : (
                  "Scan"
                )
              }
              onClick={() =>
                handleAttemptInvoiceAction(
                  invoiceActions.SCAN_AND_UPLOAD_INVOICE_LINES
                )
              }
              icon={<ScanIcon />}
            />
            <CresicorButton
              size="small"
              label={
                disableButtons ? (
                  <CircularProgress size={14} style={{ color: white }} />
                ) : (
                  "Upload Lines"
                )
              }
              onClick={() =>
                handleAttemptInvoiceAction(invoiceActions.UPLOAD_INVOICE_LINES)
              }
              icon={<PostAddIcon />}
              disabled={disableButtons}
            />
            <CresicorButton
              disabled={disableButtons}
              size="small"
              label="Add Lines"
              onClick={() =>
                handleAttemptInvoiceAction(invoiceActions.ADD_INVOICE_LINES)
              }
              icon={<PlusOneIcon />}
            />
            <CresicorButton
              label={
                matchLoading ? (
                  <CircularProgress size={14} style={{ color: white }} />
                ) : (
                  "Auto-Match"
                )
              }
              onClick={() =>
                confirmDialog(
                  openClose,
                  "Confirm Auto-Match",
                  "Are you sure you want to auto-match invoice lines? Unsaved changes will be saved, and any existing matched promotion lines will be reset.",
                  () => handleAttemptInvoiceAction(invoiceActions.AUTO_MATCH)
                )
              }
              size="small"
              icon={<FavoriteIcon />}
              disabled={disableButtons}
            />
            <CresicorButton
              size="small"
              label="Delete All Lines"
              color="error"
              onClick={() =>
                handleAttemptInvoiceAction(invoiceActions.DELETE_INVOICE_LINES)
              }
              icon={<DeleteIcon />}
              disabled={disableButtons}
            />
            <CresicorIconButton
              onClick={() => {
                setInvoiceLinesOpen(!invoiceLinesOpen);
              }}
              style={{ marginTop: -5 }}>
              {invoiceLinesOpen ? <ExpandLess /> : <ExpandMore />}
            </CresicorIconButton>
          </Stack>
        }>
        <div className={classes.summaryContainer}>
          <CollapsibleTableSummary
            data={Object.values(invoiceLines)}
            summaryConfig={summaryConfig}
          />
        </div>
        <Collapse in={invoiceLinesOpen} timeout="auto" unmountOnExit>
          <InvoiceLineTable
            invoiceKey={invoiceKey}
            invoice={invoice}
            data={invoiceLines}
          />
        </Collapse>
      </CresicorCard>
    </div>
  );
}
