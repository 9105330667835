/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { RootState } from "store";
import { useHistory } from "react-router-dom";
import IconButton from "ui-library/IconButton";
import IconMenu from "ui-library/IconMenu";
import MenuItem from "ui-library/MenuItem";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddBoxIcon from "@mui/icons-material/AddBox";
import makeStyles from "@mui/styles/makeStyles";
import { isUserCresicorEmployee } from "helpers/Firebase";
import {
  invoiceFilters,
  invoiceFilterNamesCresicorInternal,
  invoiceFilterNamesCustomerFacing
} from "components/Deductions/DeductionsReconciliation/types/invoiceTypes";

import { reset } from "../../UploadFiles/UploadBackup/redux/actionCreators";
import ViewGuiControls from "../ViewGuiControls";
import ActivityLogButton from "../../ActivityLog/ActivityLogButton";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "5px",
    paddingBottom: "5px"
  },
  buttonContainer: { display: "flex" }
}));

export interface ViewBackupToolbarActionsProps {
  currentInvoiceFilter: invoiceFilters;
  setInvoiceFilter: (x: invoiceFilters) => void;
}

export default function ViewBackupToolbarActions(
  props: ViewBackupToolbarActionsProps
) {
  const classes = useStyles();
  const { currentInvoiceFilter, setInvoiceFilter } = props;
  const isCresicorEmployee = isUserCresicorEmployee();
  const dispatch = useDispatch();
  const history = useHistory();

  const { drmFeatureState } = useSelector(
    (state: RootState) => state.drm,
    shallowEqual
  );

  return (
    <div className={classes.container}>
      <ViewGuiControls />
      <div className={classes.buttonContainer}>
        <ActivityLogButton
          filters={{ allInvoices: true }}
          title="All Invoice Activity"
          iconButton
        />
        <IconMenu
          iconButtonElement={
            <IconButton tooltip="Filter Invoices" size="large">
              <FilterListIcon />
            </IconButton>
          }
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
          targetOrigin={{ horizontal: "right", vertical: "top" }}>
          {Object.values(invoiceFilters)
            .filter(option =>
              isCresicorEmployee
                ? true
                : Object.keys(invoiceFilterNamesCustomerFacing).includes(option)
            )
            .map(option => (
              <MenuItem
                checked={currentInvoiceFilter === option}
                key={option}
                value={option}
                onClick={() => setInvoiceFilter(option)}>
                {isCresicorEmployee
                  ? invoiceFilterNamesCresicorInternal[option]
                  : invoiceFilterNamesCustomerFacing[option]}
              </MenuItem>
            ))}
        </IconMenu>
        <IconButton
          tooltip="Upload Backup"
          onClick={() => {
            dispatch(reset());
            history.push(`/${drmFeatureState}/invoices/upload`);
          }}
          size="large">
          <AddBoxIcon />
        </IconButton>
      </div>
    </div>
  );
}
