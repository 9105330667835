import React from "react";
import axios from "axios";

import CircularProgress from "@mui/material/CircularProgress";
import DatePicker from "ui-library/DatePicker";
import Button from "ui-library/Button";
import MenuItem from "ui-library/MenuItem";
import TextField from "ui-library/TextField";

import Select from "ui-library/Select";

import {
  addFirebase,
  firebase,
  uploadFilesToStorage,
  cloudRunFunctionURL
} from "helpers/Firebase";
import { red, teal } from "@mui/material/colors";
import Stack from "@mui/material/Stack";
import FilePicker from "../WebsiteElements/FilePicker";

const red500 = red["500"];
const teal400 = teal["400"];

class NewReport extends React.Component {
  // Three steps:
  // 0 = user still entering information
  // 1 = loading screen
  // 2 = finished screen

  constructor(props) {
    super(props);
    this.state = {
      newReport: {
        name: "",
        type: "",
        startDate: null,
        endDate: null,
        helpText: ""
      },
      step: 0
    };

    this.helpTextDict = {
      KeHE: "For KeHE, please upload the file obtained via \
             KeHE Connect Portal > Reports > KeHE Full POD Vendor Report.",
      UNFI: "For UNFI, please upload the UNFI Supplier Break Out Report.",
      Aperity: "Please make sure you upload only ONE month of data."
    };

    this.distributorRequired = {
      KeHE: true,
      UNFI: true,
      DOT: true,
      Aperity: false
    };
  }

  handleTextChange = (field, value) => {
    const { newReport } = this.state;

    newReport[field] = value.trim();

    if (field == "type") {
      this.setState({ helpText: this.helpTextDict[value] || "" });
    }

    this.setState({
      newReport
    });
  };

  validateReport = newReport => {
    const user = firebase.auth().currentUser;

    axios
      .post(`${cloudRunFunctionURL}/api/check_distributor_report`, {
        data: {
          file_path: this.state.filePath,
          report: newReport,
          companyid: this.props.db.companyid
        }
      })
      .then(response => {
        const { data } = response.data;
        if (data.success) {
          addFirebase(16, newReport);
          this.setState({ step: 3 });
        } else {
          this.props.openClose.showSnack(data.error);
          this.setState({ step: 3, error: true });
        }
      })
      .catch(err => {
        this.props.openClose.showSnack("Unknown error occurred.");
        this.setState({ step: 3, error: true });
      });
  };

  saveToDatabase = () => {
    // first, check for errors
    if (!this.state.newReport.name) {
      this.setState({
        nameError: "Name is required."
      });
      return;
    }
    if (!this.state.newReport.type) {
      this.setState({
        typeError: "Type is required."
      });
      return;
    }
    if (
      !this.state.newReport.distributor &&
      this.distributorRequired[this.state.newReport.type]
    ) {
      this.setState({
        distError: "Distributor is required."
      });
      return;
    }
    if (!this.state.newReport.startDate) {
      this.setState({
        startDateError: "Required"
      });
      return;
    }
    if (!this.state.newReport.endDate) {
      this.setState({
        endDateError: "Required"
      });
      return;
    }
    if (!this.state.file) {
      this.setState({
        noFileUploaded: "Please upload a file!"
      });
      return;
    }

    for (const key in this.props.db.reports) {
      const existingReport = this.props.db.reports[key];
      if (
        this.state.newReport.name == existingReport.name ||
        (existingReport.otherNames &&
          existingReport.otherNames.indexOf(this.state.newReport.name) != -1)
      ) {
        this.setState({
          nameError:
            "Report name already exists in database, please choose a new name."
        });
        return;
      }
    }

    if (this.state.newReport.startDate > this.state.newReport.endDate) {
      this.setState({
        errorText: (
          <div>
            <font color="red">ERROR: End date cannot be before start date</font>
            <br />
          </div>
        )
      });
      return;
    }

    this.setState({
      errorText: "",
      noFileUploaded: ""
    });

    const { newReport } = this.state;

    const filePath = `${this.state.companyid}/Saved Distributor Reports/${this.state.file.name}`;

    this.setState({ step: 1 });

    uploadFilesToStorage(
      [filePath],
      [this.state.file],
      () => {
        newReport.filePath = filePath;
        newReport.startDate = newReport.startDate.toString();
        newReport.endDate = newReport.endDate.toString();
        this.setState({ step: 2, filePath }, () => {
          this.validateReport(newReport);
        });
      },
      null,
      () => {
        this.setState({ step: 3, error: true });
      }
    );

    // this.props.openClose.closeAppModal();
  };

  componentDidMount() {
    this.setState({ step: 0 });
    const user = firebase.auth().currentUser;
    firebase
      .database()
      .ref(`users/${user.uid}/company_id`)
      .on("value", snapshot => {
        if (snapshot.val()) {
          const companyid = snapshot.val();
          this.setState({ userid: user.uid, companyid });
        }
      });
  }

  render() {
    // TODO
    const supportedTypes = ["KeHE", "UNFI", "DOT", "Aperity"];

    // is the distributor not applicable?
    const distributorNA =
      this.state.newReport.type &&
      !this.distributorRequired[this.state.newReport.type];

    return (
      <div>
        {this.state.errorText || ""}
        {this.state.step == 0 ? (
          <div>
            <TextField
              floatingLabelText="Name"
              onChange={event => {
                this.handleTextChange("name", event.target.value.trim());
              }}
              errorText={this.state.nameError}
              fullWidth
            />
            <br />
            <Select
              floatingLabelText="Type"
              onChange={(event, index, value) => {
                this.handleTextChange("type", value);
              }}
              errorText={this.state.typeError}
              value={this.state.newReport.type}
              fullWidth>
              {supportedTypes.map(type => {
                return <MenuItem value={type} children={type} key={type} />;
              })}
            </Select>
            <br />
            <font color="orange">{this.state.helpText}</font>
            <br />
            <Select
              floatingLabelText="Distributor"
              onChange={(event, index, value) => {
                this.handleTextChange("distributor", value);
              }}
              errorText={this.state.distError}
              value={distributorNA ? null : this.state.newReport.distributor}
              fullWidth
              disabled={distributorNA}>
              {Object.entries(this.props.db.customers)
                .filter(customer => customer[1].isDistributor)
                .sort((a, b) => {
                  if (a[1].name < b[1].name) return -1;
                  if (a[1].name > b[1].name) return 1;
                  return 0;
                })
                .map(customer => {
                  return (
                    <MenuItem
                      value={customer[0]}
                      children={customer[1].name}
                      key={customer[0]}
                    />
                  );
                })}
            </Select>
            <br />
            {distributorNA && (
              <font color={teal400}>
                Distributor field is not applicable for this type of report.
              </font>
            )}
            <br />
            <Stack>
              <DatePicker
                floatingLabelText="Start Date"
                onChange={date => {
                  const { newReport } = this.state;
                  newReport.startDate = date;
                  this.setState({ newReport });
                }}
                value={this.state.newReport.startDate}
                textFieldStyle={{ width: "50%" }}
                autoOk
                errorText={this.state.startDateError}
                fullWidth
              />

              <DatePicker
                floatingLabelText="End Date"
                onChange={date => {
                  const { newReport } = this.state;
                  newReport.endDate = date;
                  this.setState({ newReport });
                }}
                value={this.state.newReport.endDate}
                textFieldStyle={{ width: "50%" }}
                autoOk
                errorText={this.state.endDateError}
                fullWidth
              />
            </Stack>
            <br />
            <FilePicker
              message="Drag and drop report or click to upload (up to 20 MB)"
              acceptedFiles={[
                ".xlsx",
                ".XLSX",
                ".xls",
                ".XLS",
                ".csv",
                ".CSV",
                ".pdf",
                ".PDF"
              ]}
              maxFileSize={20 << 20}
              onChange={files => this.setState({ file: files[0] })}
            />
            {this.state.noFileUploaded && (
              <div style={{ color: red500 }}>{this.state.noFileUploaded}</div>
            )}
          </div>
        ) : this.state.step == 1 ? (
          <div>
            <div className="centering">
              <CircularProgress size={80} thickness={5} />
            </div>
            <br />
            <div className="centering">
              Uploading your report, please wait...
            </div>
          </div>
        ) : this.state.step == 2 ? (
          <div>
            <br />
            <div className="centering">
              <CircularProgress size={80} thickness={5} />
            </div>
            <br />
            <div className="centering">
              Validating your report, please wait...
            </div>
          </div>
        ) : this.state.error ? (
          <div className="centering">
            <br />
            Vividly was unable to upload your report. Please ensure that the
            report is in the correct format.
          </div>
        ) : (
          <div className="centering">
            <br />
            Your report was uploaded successfully.
          </div>
        )}
        <br />
        <div className="centering">
          {this.state.step != 1 && this.state.step != 2 && (
            <Button
              label={this.state.step < 3 ? "Save to Database" : "Finish"}
              onClick={
                this.state.step < 3
                  ? this.saveToDatabase
                  : () => {
                      this.props.openClose.closeAppModal();
                      this.setState({
                        newReport: {
                          name: "",
                          type: "",
                          startDate: null,
                          endDate: null
                        },
                        step: 0,
                        file: null
                      });
                    }
              }
            />
          )}
          {this.state.step == 0 && (
            <Button
              label="Cancel"
              color="error"
              variant="text"
              onClick={this.props.openClose.closeAppModal}
            />
          )}
        </div>
        <br />
        <br />
      </div>
    );
  }
}

export default NewReport;
