import React from "react";
import DatePickerV5, {
  DatePickerProps as MUIDatePickerProps
} from "@mui/lab/DatePicker";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import TextField, { TextFieldProps } from "@mui/material/TextField";

type DatePickerProps = MUIDatePickerProps & {
  floatingLabelText: MUIDatePickerProps["label"];
  compact?: boolean;
  inputStyle?: TextFieldProps["sx"];
  fullWidth?: boolean;
  inputVariant?: TextFieldProps["variant"];
  style?: Record<string, string | number>;
  inputSize?: TextFieldProps["size"];
  type?: string; // use "mobile" for less-buggy version
};

const DatePicker = (props: DatePickerProps) => {
  const {
    floatingLabelText,
    style,
    inputFormat,
    inputStyle,
    inputVariant = "standard",
    inputSize = "medium",
    compact,
    fullWidth,
    type,
    ...other
  } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {type === "mobile" ? (
        <MobileDatePicker
          orientation="landscape"
          inputFormat={inputFormat || "yyyy-MM-dd"}
          label={floatingLabelText}
          {...other}
          renderInput={params => {
            return (
              <TextField
                variant={inputVariant}
                sx={{
                  minWidth: "180px",
                  width: fullWidth ? "100%" : "auto",
                  "& input": {
                    ...(compact && { p: 0 })
                  },
                  ...inputStyle
                }}
                {...params}
              />
            );
          }}
        />
      ) : (
        <DatePickerV5
          orientation="landscape"
          inputFormat={inputFormat || "yyyy-MM-dd"}
          label={floatingLabelText}
          {...other}
          renderInput={params => {
            return (
              <TextField
                variant={inputVariant}
                sx={{
                  minWidth: "180px",
                  width: fullWidth ? "100%" : "auto",
                  "& input": {
                    ...(compact && { p: 0 })
                  },
                  ...inputStyle
                }}
                {...params}
              />
            );
          }}
        />
      )}
    </LocalizationProvider>
  );
};

export default DatePicker;
