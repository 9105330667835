import React from "react";

import ReactTable from "react-table";
import { displayMoney } from "helpers/DataProcessing";

class SummaryTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          Header: "Spend Source",
          accessor: "field",
          Cell: this.renderEditable
        },
        {
          Header: "Amount",
          accessor: "amount",
          Cell: this.renderEditable
        }
      ],
      data: []
    };
  }

  renderEditable = cellInfo => {
    return cellInfo.column.id !== "amount" || cellInfo.original.notMoney ? (
      <div>{cellInfo.original[cellInfo.column.id]}</div>
    ) : (
      <div style={{ fontWeight: cellInfo.original.bold ? 800 : 400 }}>
        {displayMoney(cellInfo.original[cellInfo.column.id])}
      </div>
    );
  };

  componentDidMount() {
    this.setState({ data: this.props.data });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.data });
  }

  render() {
    return (
      <ReactTable
        defaultPageSize={4}
        data={this.state.data}
        columns={this.state.columns}
        showPageSizeOptions={false}
        showPagination={false}
        sortable={false}
      />
    );
  }
}

export default SummaryTable;
