import React, { useState, useEffect } from "react";

import { AVFView } from "./types";

import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import IconButton from "ui-library/IconButton";
import PollIcon from "@mui/icons-material/Poll";

import "./forecast.css";
import {
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceArea,
  ComposedChart,
  Legend,
  Line,
  Bar
} from "recharts";

import { utcToReadableDateInFuture } from "./utilities";

export const AVFGraph = ({
  data,
  tableStart,
  tableEnd,
  loading,
  onZoom
}: {
  data: AVFView;
  tableStart: string;
  tableEnd: string;
  loading;
  onZoom: (start: number, end: number) => void;
}) => {
  const morphedData = data.actuals_values.map((actual, index) => {
    return {
      week: utcToReadableDateInFuture(Date.parse(tableStart), index),
      actual,
      forecast: data.forecasted_values[index],
      diff: data.difference[index],
      index
    };
  });

  const weekMap = morphedData.reduce((acc, obj) => {
    acc[obj.index] = obj.week;
    return acc;
  }, {});

  const CustomizedAxisTick = ({ x, y, payload }) => (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-20)"
        fontSize={13}>
        {weekMap[payload.value]}
      </text>
    </g>
  );

  const getLabel = (value, name, props) => {
    return weekMap[value];
  };

  const originalLeft = 0;
  const originalRight = morphedData.length - 1;
  const originalBottom = Math.min(
    Math.min(...data.actuals_values),
    Math.min(...data.forecasted_values),
    Math.min(...data.difference)
  );

  const originalTop = Math.max(
    Math.max(...data.actuals_values),
    Math.max(...data.forecasted_values)
  );

  const [leftDomain, setLeftDomain] = useState(originalLeft);
  const [rightDomain, setRightDomain] = useState(originalRight);
  useEffect(() => setLeftDomain(originalLeft), [originalLeft]);
  useEffect(() => setRightDomain(originalRight), [originalRight]);

  const [bottom, setBottom] = useState(originalBottom);
  const [top, setTop] = useState(originalTop);
  useEffect(() => setBottom(originalBottom), [originalBottom]);
  useEffect(() => setTop(originalTop), [originalTop]);

  const [highlightLeft, setHighlightLeft] = useState<number | undefined>(
    undefined
  );
  const [highlightRight, setHighlightRight] = useState<number | undefined>(
    undefined
  );

  const zoom = () => {
    if (highlightLeft === highlightRight || !highlightRight || !highlightLeft) {
      setHighlightLeft(undefined);
      setHighlightRight(undefined);
      return;
    }

    const newLeftHighlight = !(highlightLeft > highlightRight)
      ? highlightLeft
      : highlightRight;
    const newRightHighlight = !(highlightLeft > highlightRight)
      ? highlightRight
      : highlightLeft;

    setLeftDomain(newLeftHighlight);
    setRightDomain(newRightHighlight);
    setHighlightLeft(undefined);
    setHighlightRight(undefined);

    const [newBottom, newTop] = getYAxis(newLeftHighlight, newRightHighlight);
    setBottom(newBottom);
    setTop(newTop);

    onZoom(newLeftHighlight, newRightHighlight);
  };

  const zoomOut = () => {
    setLeftDomain(originalLeft);
    setRightDomain(originalRight);
    setBottom(originalBottom);
    setTop(originalTop);
    onZoom(originalLeft, originalRight);
  };

  const getYAxis = (xLeft, xRight) => {
    const offset = 20;

    const massagedData = morphedData.reduce(
      (acc, obj) => {
        acc.actuals.push(obj.actual);
        acc.forecasts.push(obj.forecast);
        acc.diffs.push(obj.diff);
        return acc;
      },
      {
        actuals: [] as number[],
        forecasts: [] as number[],
        diffs: [] as number[]
      }
    );

    const slicedActuals = massagedData.actuals.slice(xLeft, xRight);
    const slicedForecasts = massagedData.forecasts.slice(xLeft, xRight);
    const slicedDiffs = massagedData.diffs.slice(xLeft, xRight);

    const highestY = Math.max(
      Math.max(...slicedActuals),
      Math.max(...slicedForecasts),
      Math.max(...slicedDiffs)
    );
    const lowestY = Math.min(
      Math.min(...slicedActuals),
      Math.min(...slicedForecasts),
      Math.min(...slicedDiffs)
    );

    return [lowestY - offset, highestY + offset];
  };

  return (
    <div>
      {morphedData.length > 0 ? (
        <div>
          <div>
            <IconButton onClick={() => zoomOut()}>
              <ZoomOutIcon />
            </IconButton>
          </div>
          <div>
            <ResponsiveContainer width={700} height={500}>
              <ComposedChart
                data={morphedData}
                margin={{ top: 10, right: 60, left: 0, bottom: 20 }}
                onMouseDown={e => setHighlightLeft(e.activeLabel)}
                onMouseMove={e => setHighlightRight(e.activeLabel)}
                onMouseUp={() => zoom()}>
                <XAxis
                  allowDataOverflow
                  dataKey="index"
                  type="number"
                  tick={CustomizedAxisTick}
                  domain={[leftDomain, rightDomain]}
                  minTickGap={25}
                />
                <YAxis
                  allowDataOverflow
                  // tickFormatter={d3.format(".2s")}
                  domain={[bottom, top]}
                />
                <CartesianGrid stroke="#ccc" />
                <Tooltip labelFormatter={getLabel} />
                <Legend verticalAlign="top" />
                <Line
                  type="monotone"
                  dataKey="actual"
                  stroke="#ff7300"
                  dot={false}
                />
                <Area
                  type="monotone"
                  dataKey="forecast"
                  fill="#8884d8"
                  stroke="#8884d8"
                />
                <Bar dataKey="diff" fill="#413ea0" />
                {highlightLeft && highlightRight ? (
                  <ReferenceArea
                    x1={highlightLeft}
                    x2={highlightRight}
                    strokeOpacity={0.3}
                  />
                ) : null}
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      ) : (
        <div
          style={{
            height: "700px",
            width: "500px",
            textAlign: "center",
            paddingTop: 150
          }}>
          No data to display. <br />
          <PollIcon style={{ fontSize: 60 }} />
        </div>
      )}
    </div>
  );
};
