import * as reconciliationTypes from "components/Deductions/models";
import { invoiceTypes } from "components/Deductions/constants/ReconciliationTypes";
import { invoiceStatuses } from "components/Deductions/constants/ReconciliationStatuses";
import { cloneDeep } from "lodash";

// ENUMS

export enum uploadSteps {
  ADD_FILES_AND_ASSIGN_PAGES = 1,
  ADD_METADATA = 2,
  UPLOADING = 3,
  DONE = 4
}

export enum invoiceInputFields {
  FILE_NAME = "originalFile.fileName",
  INVOICE_NUMBER = "invoiceNumber",
  CHECK_NUMBER = "checkNumber",
  START_PAGE = "originalFile.startPage",
  END_PAGE = "originalFile.endPage",
  CUSTOMER_KEY = "customerKey",
  BACKUP_SOURCE = "backupSource",
  TOTAL_AMOUNT = "amount",
  TOTAL_DISPLAY_AMOUNT = "displayAmount",
  INVOICE_TYPE = "type",
  START_DATE = "startDate",
  END_DATE = "endDate",
  SUGGESTED_PROM_TYPES = "suggestedPromTypes"
}

// INTERFACES

export interface InputInvoiceObject extends reconciliationTypes.InvoiceObject {
  displayAmount: string;
}

export interface UploadFilesErrorObject {
  // errors for entire batch
  noFilesUploaded: boolean;
  duplicateFilesDetected: boolean;
  duplicateFileNames: string[];
}

export interface AssignPagesErrorObject {
  // errors for each invoice
  missingInvoiceNumber;
  invoiceNumberExists: boolean; // already exists in DB
  duplicateInvoiceNumber: boolean; // duplicate among batch
  noAssignedFile: boolean;
  reversedPageRange: boolean; // if start page > end page
  invalidStartPage: boolean; // if start page < 1
  invalidEndPage: boolean; // if end page > # of pages
}

export interface EnterMetadataErrorObject {
  // errors for each invoice
  missingCustomer: boolean;
  missingAmount: boolean;
  invalidAmount: boolean;
  zeroAmount: boolean;
  invalidEndDate: boolean;
  invalidStartDate: boolean;
  endDateBeforeStartDate: boolean;
  missingInvoiceNumber: boolean;
}

export interface ErrorState {
  uploadFiles: UploadFilesErrorObject[];
  assignPages: AssignPagesErrorObject[];
  enterMetadata: EnterMetadataErrorObject[];
}

export interface UploadBackupState {
  currentUploadStep: uploadSteps;
  newInvoices: InputInvoiceObject[];
  newFiles: File[];
  newFilePageCounts: number[];
  viewedFileIdx: number;
  errors: ErrorState;
  showErrors: boolean;
  uploadError: boolean;
  showUnknownError: boolean;
  fakeInvoiceMode?: boolean;
}

// STATE RELATED OBJECTS

export const blankInvoiceObj: InputInvoiceObject = {
  key: null,
  invoiceNumber: null,
  customerKey: null,
  backupSource: null,
  amount: null,
  displayAmount: "",
  type: invoiceTypes.TRADE_DEDUCTION,
  suggestedPromTypes: null,
  status: invoiceStatuses.NEW,
  createdDate: null,
  createdUser: null,
  startDate: null, // NOTE [DRM-825]: Start date must start out as null.
  endDate: null, // NOTE [DRM-825]: Start date must start out as null.
  originalFile: {
    fileBatch: null,
    fileName: null,
    startPage: null,
    endPage: null
  },
  needsCresicorApproval: false
};

export const blankUploadFilesErrorState: UploadFilesErrorObject = {
  noFilesUploaded: true,
  duplicateFilesDetected: false,
  duplicateFileNames: []
};

export const blankAssignPagesErrorObject: AssignPagesErrorObject = {
  missingInvoiceNumber: true,
  invoiceNumberExists: false,
  duplicateInvoiceNumber: false,
  noAssignedFile: true,
  reversedPageRange: false,
  invalidStartPage: false,
  invalidEndPage: false
};

export const blankEnterMetadataErrorObject: EnterMetadataErrorObject = {
  missingCustomer: true,
  missingAmount: true,
  invalidAmount: false,
  zeroAmount: false,
  invalidEndDate: true,
  invalidStartDate: true
};

export const initialUploadBackupState: UploadBackupState = {
  currentUploadStep: uploadSteps.ADD_FILES_AND_ASSIGN_PAGES,
  newInvoices: [cloneDeep(blankInvoiceObj)],
  newFiles: [],
  newFilePageCounts: [],
  viewedFileIdx: null,
  errors: {
    uploadFiles: [cloneDeep(blankUploadFilesErrorState)],
    assignPages: [cloneDeep(blankAssignPagesErrorObject)],
    enterMetadata: [cloneDeep(blankEnterMetadataErrorObject)]
  },
  showErrors: false,
  uploadError: false
};
