import { makeUseAxios } from "axios-hooks";
import Axios from "axios";
import { useCallback, useState } from "react";
import { format } from "date-fns";
import firebase from "firebase-init";
import {
  ForecastView,
  Option,
  ForecastType,
  ForecastMetadata,
  ForecastVersion,
  AVFView
} from "./types";
import { NotificationState } from "./Notifications";
import { baseURL, Routes } from "./routes";

const axios = Axios.create({
  baseURL
});

axios.interceptors.request.use(
  async config => {
    const token = await firebase.auth().currentUser?.getIdToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => response,
  e => {
    if (Axios.isAxiosError(e)) {
      NotificationState.addError(e.response?.data?.error);
    }

    return Promise.reject(e);
  }
);

const defaultOptions = { useCache: false, autoCancel: false };
export const useForecastRequest = makeUseAxios({ axios, defaultOptions });

// get_forecast
export interface GetForecastParams {
  company_id: string;
  forecast_type: ForecastType;
  year?: Option<string>;
  version?: Option<string>;
  override_version?: Option<number>;
  customer?: Option<string>;
  product?: Option<string>;
}

export const useGetForecast = (params: GetForecastParams) => {
  return useForecastRequest<ForecastView>({
    url: Routes.GET_FORECAST,
    method: "GET",
    params
  });
};

// get_forecast_versions
export interface GetForecastVersionsParams {
  company_id: string;
}

export interface GetForecastVersionsResponse {
  versions: ForecastVersion[];
}

export const useGetForecastVersions = (params: GetForecastVersionsParams) => {
  const response = useForecastRequest<any>({
    url: Routes.GET_FORECAST_VERSIONS,
    method: "GET",
    params
  });
  return response;
};

// get_metadata
export interface GetMetadataParams {
  company_id: string;
  version?: Option<string>;
  override_version?: Option<number>;
}

export const useGetMetadata = (params: GetMetadataParams) => {
  return useForecastRequest<ForecastMetadata>({
    url: Routes.GET_METADATA,
    method: "GET",
    params
  });
};

// create_forecast
type CreateForecastRequest = GetForecastParams;

export const useCreateForecast = () => {
  const [hook, execute] = useForecastRequest<{}, CreateForecastRequest>(
    {
      url: Routes.CREATE_FORECAST,
      method: "POST"
    },
    { manual: true }
  );

  const createForecast = useCallback(
    (payload: { data: CreateForecastRequest }) => {
      return execute(payload);
    },
    [execute]
  );

  return [hook, createForecast] as const;
};

// update overrides
export interface UpdateOverridesRequest {
  customer: string;
  product: string;
  week: string;
  stores?: Option<number>;
  velocity?: Option<number>;
  base_sales?: Option<number>;
  companyid: string;
  version: number;
}

export const useUpdateOverrides = () => {
  const [hook, execute] = useForecastRequest<{}, UpdateOverridesRequest>(
    {
      url: Routes.UPDATE_OVERRIDES,
      method: "POST"
    },
    { manual: true }
  );

  const updateOverrides = useCallback(
    (payload: { data: UpdateOverridesRequest }) => {
      return execute(payload);
    },
    [execute]
  );

  return [hook, updateOverrides] as const;
};

// delete overrides
export interface DeleteOverrideRequest {
  customer: string;
  product: string;
  week: string;
  override_type: OverrideType;
  companyid: string;
  version: number;
}

// delete version
export interface DeleteVersionRequest {
  version_num: number;
  company_id: string;
}

// copy version
export interface CopyVersionRequest {
  version_num: number;
  company_id: string;
}

export enum OverrideType {
  stores = "stores",
  velocity = "velocity",
  base_sales = "baseSales"
}

export const useDeleteOverride = () => {
  const [hook, execute] = useForecastRequest<{}, DeleteOverrideRequest>(
    {
      url: Routes.DELETE_OVERRIDE,
      method: "POST"
    },
    { manual: true }
  );

  const updateOverrides = useCallback(
    (payload: { data: DeleteOverrideRequest }) => {
      return execute(payload);
    },
    [execute]
  );

  return [hook, updateOverrides] as const;
};

export const useDeleteVersion = () => {
  const [hook, execute] = useForecastRequest<{}, DeleteVersionRequest>(
    {
      url: Routes.DELETE_VERSION,
      method: "POST"
    },
    { manual: true }
  );

  const deleteVersion = useCallback(
    (payload: { data: DeleteVersionRequest }) => {
      return execute(payload);
    },
    [execute]
  );

  return [hook, deleteVersion] as const;
};

export const useCopyVersion = () => {
  const [hook, execute] = useForecastRequest<{}, CopyVersionRequest>(
    {
      url: Routes.COPY_VERSION,
      method: "POST"
    },
    { manual: true }
  );

  const copyVersion = useCallback(
    (payload: { data: CopyVersionRequest }) => {
      return execute(payload);
    },
    [execute]
  );

  return [hook, copyVersion] as const;
};

// update forecast version
export interface UpdateForecastVersionRequest {
  company_id: string;
  new_name: Option<string>;
  version_num: number;
}

export const useEditForecastVersion = () => {
  const [hook, execute] = useForecastRequest<{}, UpdateForecastVersionRequest>(
    {
      url: Routes.EDIT_VERSION,
      method: "POST"
    },
    { manual: true }
  );

  const updateForecastVersions = useCallback(
    async (payload: { data: UpdateForecastVersionRequest }) => {
      return await execute(payload);
    },
    [execute]
  );

  return [hook, updateForecastVersions] as const;
};

// download forecast
export interface DownloadForecastParams {
  company_id: string;
  version_filename: string;
  override_version: number;
}

export const useDownloadForecast = (params: DownloadForecastParams) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const downloadExport = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios({
        url: Routes.DOWNLOAD_EXCEL,
        method: "GET",
        responseType: "blob",
        params
      });

      const blob = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = blob;
      const date = new Date();
      const formattedDate = format(date, "yyyy-MM-dd-HH-mm");
      link.setAttribute("download", `export-${formattedDate}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(e.message);
    }
  }, [params]);

  return {
    loading,
    error,
    downloadExport
  };
};

// download zip
export interface DownloadForecastZipParams {
  company_id: string;
  version_filename: string;
  override_version: number;
}

export const useDownloadForecastZip = (params: DownloadForecastZipParams) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const downloadExportZip = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios({
        url: Routes.DOWNLOAD_ZIP,
        method: "GET",
        responseType: "blob",
        params
      });

      const blob = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = blob;
      const date = new Date();
      const formattedDate = format(date, "yyyy-MM-dd-HH-mm");
      link.setAttribute("download", `zip-export-${formattedDate}.zip`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(e.message);
    }
  }, [params]);

  return {
    loading,
    error,
    downloadExportZip
  };
};

// avf
export interface GetAVFParams {
  version_filename: string;
  customer: string;
  product: string;
  company_id: string;
}

export const useGetAVF = (params: GetAVFParams) => {
  return useForecastRequest<AVFView>({
    url: "/api/get_avf",
    method: "GET",
    params
  });
};
