/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from "react";
import { useRouteMatch, Route, Switch, Redirect } from "react-router-dom";
import {
  setDRMFeatureState,
  DRMFeature
} from "components/Deductions/DeductionsReconciliation/redux/DRMSlice";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { RootState } from "store";
import ViewBackup from "./ViewData/ViewBackup/ViewBackup";
import ViewTransactions from "./ViewData/ViewTransactions/ViewTransactions";

export interface DeductionsReconciliationProps {
  rootFeature: DRMFeature;
  [prop: string]: any;
}

export default function DeductionsReconciliation(
  props: DeductionsReconciliationProps
) {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const { rootFeature, ...other } = props;

  const { drmFeatureState } = useSelector(
    (state: RootState) => state.drm,
    shallowEqual
  );

  useEffect(() => {
    if (drmFeatureState !== rootFeature) {
      dispatch(setDRMFeatureState(rootFeature));
    }
  }, [dispatch, rootFeature, drmFeatureState]);

  return (
    <div>
      <Switch>
        <Route exact path={`/${drmFeatureState}`}>
          <Redirect to={`/${drmFeatureState}/invoices/list`} />
        </Route>
        <Route path={`${path}/invoices`}>
          <ViewBackup db={props.db} openClose={props.openClose} {...other} />
        </Route>
        <Route path={`${path}/transactions`}>
          <ViewTransactions
            db={props.db}
            openClose={props.openClose}
            {...other}
          />
        </Route>
      </Switch>
    </div>
  );
}
