import React from "react";
import { cloneDeep } from "lodash";

import { Panel } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { SetImproved } from "helpers/Misc";

import withDb from "contexts/withDb";

/*
Input to this class will be of the form array of objects [{}, {}, ..., {}]
where each object {} consists of keys representing column names
*/

const MAX_DATA_LENGTH = 10000;

class Import extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      errors: [],
      warnings: [],
      errorRowsDisplay: false
    };
  }

  addCustomColumnsToData = data => {
    const modifiedData = cloneDeep(data);

    // TODO: Will need to refactor this code to get the company-specific transactionConfig dictionary from Postgres.
    const { meta = {} } = this.props.db;
    const { transaction_config: transactionConfig = {} } = meta;

    function areDeductionsPositive(accountingSource) {
      for (const key in transactionConfig) {
        if (transactionConfig[key].accountingSource === accountingSource) {
          return transactionConfig[key].deductionsIncomingPositive;
        }
      }

      // If not in transaction config, deductions are NOT positive.
      return false;
    }

    if (this.props.selectedDataType === "ERPTransactions") {
      const deductionsPositive = areDeductionsPositive(this.props.preset)
        ? 1
        : -1;
      for (const key in modifiedData) {
        const amount = parseFloat(modifiedData[key].Amount ?? 0);

        // redo order of columns
        // TODO: use better table component that can actually do this for us...
        const newRow = {};
        newRow["Transaction Type"] =
          amount * deductionsPositive > 0 ? "Deduction" : "Repayment";
        newRow.Amount =
          newRow["Transaction Type"] === "Deduction"
            ? Math.abs(amount)
            : -Math.abs(amount);
        for (const columnHeader in modifiedData[key]) {
          if (columnHeader !== "Amount") {
            newRow[columnHeader] = modifiedData[key][columnHeader];
          }
        }
        modifiedData[key] = newRow;
      }
    }
    return modifiedData;
  };

  UNSAFE_componentWillMount() {
    this.setState({
      data: Object.values(this.addCustomColumnsToData(this.props.data)),
      errors: new SetImproved(this.props.errors),
      errorIndices: this.props.errorIndices,
      warnings: new SetImproved(this.props.warnings),
      selectedRows: this.props.selectedRows,
      requiredColumns: this.props.requiredColumns,
      errorRowsDisplay: this.props.errorRowsDisplay,
      view: this.props.view
    });
  }

  componentDidMount() {
    this.setState({
      data: Object.values(this.addCustomColumnsToData(this.props.data)),
      errors: new SetImproved(this.props.errors),
      errorIndices: this.props.errorIndices,
      warnings: new SetImproved(this.props.warnings),
      selectedRows: this.props.selectedRows,
      requiredColumns: this.props.requiredColumns,
      errorRowsDisplay: this.props.errorRowsDisplay,
      view: this.props.view
    });
    this.props.onLoad();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      data: Object.values(this.addCustomColumnsToData(nextProps.data)),
      errors: new SetImproved(nextProps.errors),
      errorIndices: nextProps.errorIndices,
      warnings: new SetImproved(nextProps.warnings),
      selectedRows: nextProps.selectedRows,
      errorRowsDisplay: nextProps.errorRowsDisplay,
      view: nextProps.view
    });
  }

  createCustomButtonGroup = props => {
    const otherView =
      this.state.view == "View All Lines"
        ? "View Accumulated Lines"
        : "View All Lines";
    return (
      <ButtonGroup className="my-custom-class" sizeClass="btn-group-md">
        {props.exportCSVBtn}
        <button
          type="button"
          className="btn btn-primary"
          onClick={this.props.changeView}>
          {otherView}
        </button>
      </ButtonGroup>
    );
  };

  columnClassNameFormat = (colName, fieldValue, row, rowIdx, colIdx) => {
    if (this.state.errors.has(row.Row)) {
      return "td-column-error";
    }
    if (this.state.warnings.has(row.Row)) {
      return "td-column-warning";
    }
    if (this.state.errors.has([colName, row.Row])) {
      return "td-column-error";
    }
    if (this.state.warnings.has([colName, row.Row])) {
      return "td-column-warning";
    }
  };

  rowClassNameFormat = (row, rowIdx) => {
    const warningIndices = this.state.warnings.entries().map(entry => entry[1]);

    if (this.state.errorIndices.includes(rowIdx)) {
      return "tr-row-error";
    }
    if (warningIndices.includes(rowIdx)) {
      return "tr-row-warning";
    }
  };

  makeHeader = () => {
    const columns = [];
    const origColnames =
      this.state.data.length > 0 ? Object.keys(this.state.data[0]) : [];
    const colnames = [];
    // only keep columns that are not all blank
    for (var i = 0; i < origColnames.length; i++) {
      let allBlank = true;
      for (let j = 0; j < this.state.data.length; j++) {
        if (
          this.state.data[j][origColnames[i]] !== null &&
          this.state.data[j][origColnames[i]] !== ""
        ) {
          allBlank = false;
          break;
        }
      }
      if (
        !allBlank ||
        this.state.requiredColumns.indexOf(origColnames[i]) > -1
      ) {
        colnames.push(origColnames[i]);
      }
    }
    for (var i = 0; i < colnames.length; i++) {
      const item = colnames[i];
      columns.push(
        <TableHeaderColumn
          dataField={item}
          isKey={item == "Row"}
          columnClassName={this.columnClassNameFormat.bind(null, item)}
          key={item}
          width="200px">
          {item}
        </TableHeaderColumn>
      );
    }
    return columns;
  };

  selectWarningErrorRows = () => {
    const warningsList = Object.values(this.state.warnings.classDict);
    if (this.state.errorIndices.length == 0 && warningsList.length == 0) {
      return this.state.data;
    }
    const allProblemRows = JSON.parse(JSON.stringify(this.state.errorIndices));
    if (this.state.warnings) {
      for (let i = 0; i < warningsList.length; i++) {
        if (Number.isInteger(warningsList[i])) {
          allProblemRows.push(warningsList[i]);
        } else {
          allProblemRows.push(warningsList[i][1]);
        }
      }
    }

    const dataSubset = [];
    for (const row of allProblemRows) {
      dataSubset.push(this.state.data[row]);
    }
    return dataSubset;
  };

  render() {
    const options = {
      page: 1,
      hideSizePerPage: true,
      sizePerPage: 100,
      pageStartIndex: 1,
      paginationSize: 4,
      prePage: "Prev",
      nextPage: "Next",
      firstPage: "First",
      lastPage: "Last"
    };
    if (["Revenue", "Spend"].includes(this.props.selectedDataType)) {
      options.btnGroup = this.createCustomButtonGroup;
    }
    const selectRowProp = {
      mode: "checkbox",
      clickToSelect: true,
      selected: this.state.selectedRows,
      unselectable: this.state.errorIndices,
      onSelect: this.props.selectRow,
      onSelectAll: this.props.selectAll
    };
    const data = this.state.errorRowsDisplay
      ? this.selectWarningErrorRows()
      : this.state.data;
    const truncatedData =
      data.length > MAX_DATA_LENGTH ? data.slice(0, MAX_DATA_LENGTH) : data;
    return (
      <Panel header={<span>Uploaded Data</span>}>
        <div className="centering">
          {data.length > MAX_DATA_LENGTH && (
            <font color="red">Only displaying the first 10,000 rows.</font>
          )}
        </div>
        <div style={{ marginBottom: -32 }}>
          <BootstrapTable
            data={truncatedData}
            trClassName={this.rowClassNameFormat}
            scrollTop="Bottom"
            className="table"
            containerStyle={{ overflowX: "scroll", overflowY: "scroll" }}
            height={$(window).height() - 450}
            selectRow={!this.props.rejectOnError && selectRowProp}
            pagination
            exportCSV
            options={options}>
            {this.makeHeader()}
          </BootstrapTable>
        </div>
      </Panel>
    );
  }
}

export default withDb(Import);
