import React from "react";
import MenuItem from "ui-library/MenuItem";
import XLSX from "xlsx";

export const DSM_SETTINGS_DEFAULT = {
  basePrice: 750,
  basePageLimit: 1000,
  additionalPageIncrement: 100,
  additionalIncrementCharge: 50
};

export const getInvoiceAmount = (pagesScanned, settings) => {
  const { basePrice } = settings;
  const { additionalIncrementCharge } = settings;
  const { additionalPageIncrement } = settings;
  const pagesOverLimit = Math.max(pagesScanned - settings.basePageLimit, 0);
  return (
    basePrice +
    Math.ceil(pagesOverLimit / additionalPageIncrement) *
      additionalIncrementCharge
  );
};

export function addAllCompaniesDeductions(
  JSX,
  type,
  deductions,
  companyData,
  companyId,
  companyNames,
  selectedMonth,
  dsmCompanies,
  companyServiced = null,
  month = null
) {
  const isDSMCompany = dsmCompanies.includes(companyId);
  if (deductions || (selectedMonth !== "All Months" && isDSMCompany)) {
    const pagesScanned = deductions ? deductions.pages_scanned : 0;
    const filesScanned = deductions ? deductions.files_scanned : 0;
    const metaSettings = companyData?.meta[type] || {};
    const settings = { ...DSM_SETTINGS_DEFAULT, ...metaSettings };
    JSX["Company Name"].push(companyNames[companyId]);
    if (companyServiced) JSX["Company Serviced"].push(companyServiced);
    if (month) JSX.Month.push(month);
    JSX["Base Price"].push(settings.basePrice);
    JSX["Base Page Limit"].push(settings.basePageLimit);
    JSX["Add. Page Increment"].push(settings.additionalPageIncrement);
    JSX["Add. Increment Charge"].push(settings.additionalIncrementCharge);
    JSX["Pages Scanned"].push(pagesScanned);
    JSX["Files Scanned"].push(filesScanned);
    JSX["Invoice Amount"].push(getInvoiceAmount(pagesScanned, settings));
  }
}

export const getCresicorCompaniesJSX = (companyNames: {
  [s: string]: string;
}) => {
  const cresicorCompaniesJSX: JSX.Element[] = [];
  cresicorCompaniesJSX.push(
    <MenuItem value="All Companies" key="All Companies">
      All Companies
    </MenuItem>
  );
  // eslint-disable-next-line no-restricted-syntax
  for (const [cid, companyName] of Object.entries(companyNames).sort((s1, s2) =>
    s1[1].localeCompare(s2[1])
  )) {
    cresicorCompaniesJSX.push(
      <MenuItem value={cid} key={cid}>
        {companyName}
      </MenuItem>
    );
  }
  return cresicorCompaniesJSX;
};

interface DeductionsScanningServiceRecords {
  // eslint-disable-next-line camelcase
  pages_scanned: number;
  // eslint-disable-next-line camelcase
  files_scanned: number;
}
export const getDeductionsScanningServicingJSX = (
  deductionsScanningServiceRecords,
  deductionsScanningServiceSettings,
  companyNames
) => {
  const entries: [string, DeductionsScanningServiceRecords][] = Object.entries(
    deductionsScanningServiceRecords
  );
  const JSX = {
    "Company Serviced": entries.map(([id, _]) => companyNames[id]),
    "Pages Scanned": entries.map(([_, company]) => company.pages_scanned),
    "Files Scanned": entries.map(([_, company]) => company.files_scanned),
    "Invoice Amount": entries.map(([_, company]) =>
      getInvoiceAmount(company.pages_scanned, deductionsScanningServiceSettings)
    )
  };
  return JSX;
};

export const getDeductionsScanningJSX = (
  deductionsScanningRecords,
  deductionsScanningSettings
) => {
  if (Object.keys(deductionsScanningRecords).length > 0) {
    return {
      "Pages Scanned": [deductionsScanningRecords.pages_scanned],
      "Files Scanned": [deductionsScanningRecords.files_scanned],
      "Invoice Amount": [
        getInvoiceAmount(
          deductionsScanningRecords.pages_scanned,
          deductionsScanningSettings
        )
      ]
    };
  }
  return {
    "Pages Scanned": [],
    "Files Scanned": [],
    "Invoice Amount": []
  };
};

export const getSingleCompanyMonths = (
  selectedCompanyId,
  monthlyInvoicingData
) => {
  const companyData = monthlyInvoicingData[selectedCompanyId];
  let months = companyData?.meta?.months;
  if (months) {
    months = Object.keys(months);
    return months;
  }
  return [];
};

export const convertJSXtoJson = data => {
  const headers = Object.keys(data);
  const { length } = data[headers[0]];
  const jsonData: Object[] = [];
  for (let i = 0; i < length; i++) {
    const singleEntry: Object = {};
    headers.forEach(header => {
      singleEntry[header] = data[header][i];
    });
    jsonData.push(singleEntry);
  }
  return jsonData;
};

export const getAllCompaniesDeductionsScanningJSX = (
  selectedMonth,
  companyNames,
  monthlyInvoicingData,
  dsmCompanies
) => {
  const JSX = {
    "Company Name": [],
    Month: [],
    "Base Price": [],
    "Base Page Limit": [],
    "Add. Page Increment": [],
    "Add. Increment Charge": [],
    "Pages Scanned": [],
    "Files Scanned": [],
    "Invoice Amount": []
  };
  const type = "deductionsScanningSettings";
  const allMonths = selectedMonth === "All Months";
  if (!allMonths) delete JSX.Month;

  for (var selectedCompanyId in companyNames) {
    var companyData = monthlyInvoicingData[selectedCompanyId];
    if (allMonths) {
      var allDeductions = companyData?.deductionsScanning;
      if (allDeductions) {
        Object.keys(allDeductions).forEach(month => {
          addAllCompaniesDeductions(
            JSX,
            type,
            allDeductions[month],
            companyData,
            selectedCompanyId,
            companyNames,
            selectedMonth,
            dsmCompanies,
            null,
            month
          );
        });
      }
    } else {
      const deductions = companyData?.deductionsScanning?.[selectedMonth];
      addAllCompaniesDeductions(
        JSX,
        type,
        deductions,
        companyData,
        selectedCompanyId,
        companyNames,
        selectedMonth,
        dsmCompanies
      );
    }
  }
  return JSX;
};

export const getAllCompaniesDeductionsScanningServicingJSX = (
  selectedMonth,
  companyNames,
  monthlyInvoicingData,
  dsmCompanies
) => {
  const JSX = {
    "Company Name": [],
    "Company Serviced": [],
    Month: [],
    "Base Price": [],
    "Base Page Limit": [],
    "Add. Page Increment": [],
    "Add. Increment Charge": [],
    "Pages Scanned": [],
    "Files Scanned": [],
    "Invoice Amount": []
  };
  const type = "deductionsScanningServiceSettings";
  const allMonths = selectedMonth === "All Months";
  if (!allMonths) delete JSX.Month;

  for (var selectedCompanyId in companyNames) {
    var companyData = monthlyInvoicingData[selectedCompanyId];
    if (allMonths) {
      var allDeductionServices = companyData?.deductionsScanningService;
      if (allDeductionServices) {
        Object.keys(allDeductionServices).forEach(month => {
          const monthServiceData = allDeductionServices[month];
          Object.keys(monthServiceData).forEach(companyServicedId => {
            const companyServicedName = companyNames[companyServicedId];
            addAllCompaniesDeductions(
              JSX,
              type,
              monthServiceData[companyServicedId],
              companyData,
              selectedCompanyId,
              companyNames,
              selectedMonth,
              dsmCompanies,
              companyServicedName,
              month
            );
          });
        });
      }
    } else {
      const companiesServiced =
        companyData?.deductionsScanningService?.[selectedMonth];
      if (companiesServiced) {
        for (const companyServicedId of Object.keys(companiesServiced)) {
          const companyServicedName = companyNames[companyServicedId];
          const deductions = companiesServiced[companyServicedId];
          addAllCompaniesDeductions(
            JSX,
            type,
            deductions,
            companyData,
            selectedCompanyId,
            companyNames,
            selectedMonth,
            dsmCompanies,
            companyServicedName
          );
        }
      }
    }
  }
  return JSX;
};

export const exportDownloadData = (
  deductionsScanningJSON,
  deductionsScanningServicingJSON,
  selectedCompany,
  companyNames,
  selectedMonth
) => {
  const workbook = XLSX.utils.book_new();
  if (deductionsScanningJSON.length > 0) {
    workbook.SheetNames.push("Deductions Scanning");
    workbook.Sheets["Deductions Scanning"] = XLSX.utils.json_to_sheet(
      deductionsScanningJSON
    );
  }
  if (deductionsScanningServicingJSON.length > 0) {
    workbook.SheetNames.push("Deductions Scanning Servicing");
    workbook.Sheets["Deductions Scanning Servicing"] = XLSX.utils.json_to_sheet(
      deductionsScanningServicingJSON
    );
  }
  XLSX.writeFile(
    workbook,
    `${companyNames[selectedCompany]} Invoice ${selectedMonth}.xlsx`
  );
};

export const getDownloadData = (
  companyNames,
  selectedCompany,
  selectedMonth,
  monthlyInvoicingData,
  deductionsScanningRecords,
  deductionsScanningSettings,
  dsmCompanies
) => {
  const allCompanies = selectedCompany === "All Companies";
  const deductionsScanningJSX = allCompanies
    ? getAllCompaniesDeductionsScanningJSX(
        selectedMonth,
        companyNames,
        monthlyInvoicingData,
        dsmCompanies
      )
    : getDeductionsScanningJSX(
        deductionsScanningRecords,
        deductionsScanningSettings
      );
  const deductionsScanningServicingJSX = allCompanies
    ? getAllCompaniesDeductionsScanningServicingJSX(
        selectedMonth,
        companyNames,
        monthlyInvoicingData,
        dsmCompanies
      )
    : getDeductionsScanningServicingJSX(
        deductionsScanningRecords,
        deductionsScanningSettings,
        companyNames
      ); // FILL IN
  const deductionsScanningJSON = convertJSXtoJson(deductionsScanningJSX);
  const deductionsScanningServicingJSON = convertJSXtoJson(
    deductionsScanningServicingJSX
  );
  exportDownloadData(
    deductionsScanningJSON,
    deductionsScanningServicingJSON,
    selectedCompany,
    companyNames,
    selectedMonth
  );
};
