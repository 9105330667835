import React from "react";

import Button from "ui-library/Button";
import SelectField from "ui-library/Select";
import MenuItem from "ui-library/MenuItem";
import Subheader from "ui-library/Subheader";
import RadioButtonGroup from "@mui/material/RadioGroup";
import RadioButton from "ui-library/RadioButton";

import { getTimesJSX } from "helpers/Time";

import { grey } from "@mui/material/colors";
import { Stack } from "@mui/material";

const grey700 = grey["700"];

const styles = {
  header: {
    marginLeft: "-15px",
    marginRight: "-50px",
    fontSize: 20
  },
  subheader: {
    marginLeft: "-15px",
    fontSize: 15
  },
  checkbox: {
    marginBottom: 16
  },
  divider: {
    margin: "30px auto",
    width: "50%"
  },
  scrollbar: {
    height: 200,
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: grey700
  },
  radioButton: {
    marginBottom: 16
  }
};

class CalcAllOtherBuckets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      finished: false,
      loading: false,
      error: true,
      stepIndex: 0,
      defaultProm: {},
      db: null,
      allOtherBuckets: [],
      allOtherBucketsJSX: [],
      source: "None",
      revenueSourcesJSX: [],
      months: [new Date().getMonth() + 1],
      granularity: "weekly",
      ignoreWarning: false,
      doneLoading: false
    };
  }

  getErrorText = id => {};

  handleError = () => {};

  componentDidMount() {
    const allOtherBuckets = Object.keys(
      this.props.db.meta.forecast_all_other_corresponding_customers || {}
    );
    const allOtherBucketsJSX = [];
    for (const bucket of allOtherBuckets) {
      allOtherBucketsJSX.push(
        <MenuItem
          value={bucket}
          children={this.props.db.customers[bucket].name}
          key={bucket}
        />
      );
    }
    const revenueSources = this.props.db.meta.revenue_sources || [];
    const revenueSourcesJSX = [];
    for (const source of revenueSources) {
      revenueSourcesJSX.push(
        <MenuItem value={source} children={source} key={source} />
      );
    }
    const timesJSX = getTimesJSX(this.props.db.meta, true, true);
    this.setState({
      monthsJSX: timesJSX[0],
      yearsJSX: timesJSX[1],
      year: new Date().getFullYear(),
      allOtherBuckets,
      allOtherBucketsJSX,
      revenueSourcesJSX
    });
  }

  render() {
    return (
      <div
        style={{
          width: "100%",
          paddingLeft: 32,
          paddingRight: 32,
          margin: "auto"
        }}>
        <Subheader style={styles.header}>Details</Subheader>
        <Stack direction="column" alignItems="inherit">
          <Stack>
            <SelectField
              floatingLabelText="Month"
              value={this.state.months}
              onChange={(event, index, value) => {
                this.setState({ months: value, ignoreWarning: false });
              }}
              multiple
              fullWidth
              style={styles.input}>
              {this.state.monthsJSX}
            </SelectField>
            <SelectField
              floatingLabelText="Year"
              value={this.state.year}
              onChange={(event, index, value) => {
                this.setState({ year: value, ignoreWarning: false });
              }}
              fullWidth
              style={styles.input}>
              {this.state.yearsJSX}
            </SelectField>
          </Stack>
          <SelectField
            floatingLabelText="All-Other Buckets"
            value={this.state.allOtherBuckets}
            onChange={(event, index, value) => {
              this.setState({ allOtherBuckets: value, ignoreWarning: false });
            }}
            multiple
            fullWidth
            style={styles.input}>
            {this.state.allOtherBucketsJSX}
          </SelectField>
          <SelectField
            floatingLabelText="Data Source"
            value={this.state.source}
            onChange={(event, index, value) => {
              this.setState({ source: value, ignoreWarning: false });
            }}
            fullWidth
            style={styles.input}>
            <MenuItem value="None" children="None" key="None" />
            {this.state.revenueSourcesJSX}
          </SelectField>
        </Stack>
        <br />
        <div style={styles.radioButtonGroup}>
          <div style={{ fontSize: 18, marginBottom: 16 }}>Granularity</div>
          <RadioButtonGroup
            name="selectGranularity"
            valueSelected={this.state.granularity}
            onChange={(event, value) => {
              this.setState({ granularity: value, ignoreWarning: false });
            }}
            style={{ marginLeft: 16 }}>
            <RadioButton value="weekly" label="By Week" key="weekly" />
            <RadioButton value="monthly" label="By Month" key="monthly" />
          </RadioButtonGroup>
        </div>
        <br />
        <Button
          label="Done"
          disabled={this.state.allOtherBuckets.length == 0}
          primary
          style={{ marginTop: 20, width: "100%" }}
          onClick={this.props.onCalcAllOther.bind(
            null,
            this.state.months,
            this.state.year,
            this.state.allOtherBuckets,
            this.state.granularity,
            this.state.source,
            this.state.ignoreWarning
          )}
        />
      </div>
    );
  }
}

export default CalcAllOtherBuckets;
