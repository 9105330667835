import React, { useEffect, useState } from "react";
import FileSaver from "file-saver";

import makeStyles from "@mui/styles/makeStyles";
import SearchIcon from "@mui/icons-material/Search";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";
import CancelIcon from "@mui/icons-material/Cancel";
import Tooltip from "@mui/material/Tooltip";
import Button from "ui-library/Button";
import { Toolbar, ToolbarSeparator, ToolbarTitle } from "ui-library/Toolbar";
import ToolbarGroup from "ui-library/ToolbarGroup";
import { CircularProgress, Divider, Stack } from "@mui/material";
import IconButton from "ui-library/IconButton";
import TextField from "ui-library/TextField";

import { useBackupSources } from "components/Deductions/constants/BackupSources";

import { CircularProgressWithLabel } from "helpers/CircularProgressWithLabel";
import PaginationMUITable from "components/tables/PaginationMUITable";

import {
  getFirebase,
  removeFirebase,
  downloadFilesFromStorage,
  removeFilesFromStorage
} from "helpers/Firebase";
import Mixpanel from "helpers/Mixpanel";

import { purple, grey } from "@mui/material/colors";

const purple50 = purple["50"];
const grey400 = grey["400"];
const grey600 = grey["600"];

const useStyles = makeStyles(theme => ({
  miniIcon: {
    height: 15,
    width: 15,
    marginLeft: 16
  },
  progress: {
    height: 10,
    borderRadius: 5
  },

  loading: {
    display: "inline-block"
  },
  table: {
    maxHeight: 300,
    overflow: "scroll",
    marginLeft: 5,
    marginRight: 5,
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    borderStyle: "solid"
  },
  search: { marginBottom: 10 }
}));

const ProcessedScans = props => {
  const [processedScans, setProcessedScans] = useState({});
  const [showSearch, setShowSeearch] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(true);

  const { allBackupSources: backupSources, isLoading: isBackupSourceLoading } =
    useBackupSources();
  const classes = useStyles();
  const handleSearchButton = () => {
    setShowSeearch(true);
  };

  const handleSearch = event => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const deleteRow = (scanKey, currentFileName) => {
    const currentFilePath = `${props.db.companyid}/Deductions Scan Results/${scanKey}/${currentFileName}`;
    removeFirebase(23, scanKey);
    removeFilesFromStorage([currentFilePath], [currentFileName]);
    props.openClose.closeAppModal();
  };

  const handleDeleteRow = (_event, rowData) => {
    const scanKey = rowData.key;
    const currentFileName = rowData.storageFileName;
    props.openClose.setAppModal(
      "Confirm Cancel",
      <div className="centering">
        Are you sure you want to delete all scanned deductions from this check?
      </div>,

      <Stack>
        <Button
          label="Yes, I'm sure"
          color="error"
          variant="text"
          onClick={() => deleteRow(scanKey, currentFileName)}
        />
        <Button
          label="No, go back"
          variant="text"
          onClick={() => {
            props.openClose.closeAppModal();
          }}
        />
      </Stack>
    );
  };

  const handleDownload = (_event, rowData) => {
    const scanKey = rowData.key;
    const currentFileName = rowData.storageFileName;
    const currentFilePath = `${props.db.companyid}/Deductions Scan Results/${scanKey}/${currentFileName}`;
    downloadFilesFromStorage(
      [currentFilePath],
      [currentFileName],
      (blob, name) => FileSaver.saveAs(blob, name),
      null
    );
  };

  const handleView = (_event, rowData) => {
    const currentFileName = rowData.fileName;
    const currentFilePath = `${props.db.companyid}/Deductions Scan/${currentFileName}`;
    downloadFilesFromStorage(
      [currentFilePath],
      [currentFileName],
      (blob, name) => FileSaver.saveAs(blob, name),
      null
    );
  };

  const getScanStatus = val => {
    if (val.loading) {
      return (
        <Tooltip title="Loading">
          <div className={classes.loading}>
            <CircularProgressWithLabel value={val.progress} />
          </div>
        </Tooltip>
      );
    }
    if (val.success === "Success") {
      return (
        <Tooltip title="Success">
          <CheckCircleIcon sx={{ color: "primary.main" }} />
        </Tooltip>
      );
    }
    if (val.success === "Failure") {
      return (
        <Tooltip title="Failed">
          <ErrorIcon sx={{ color: "error.main" }} />
        </Tooltip>
      );
    }
    if (val.success === "Cancelled" || val.success === "Page Limit Exceeded") {
      return (
        <Tooltip title="Cancelled">
          <CancelIcon sx={{ color: "error.main" }} />
        </Tooltip>
      );
    }
    return (
      <Tooltip title="Warning">
        <WarningIcon sx={{ color: "warning.main" }} />
      </Tooltip>
    );
  };

  const getScansJSX = filteredItems => {
    return filteredItems.map(scanVal => {
      scanVal.status = getScanStatus(scanVal);
      scanVal.storageFileName = scanVal.check_number
        ? `All_Scans_${scanVal.check_number}.zip`
        : "All_Scans.zip";
      return scanVal;
    });
  };

  const getScansList = props => {
    const scansList: [] = [];
    for (const key in processedScans) {
      const s = { ...processedScans[key] };
      s.key = key;
      s.user = props.db.companyUsers[s.userid]?.name ?? "Unknown User";
      s.backup_source =
        backupSources[s.backup_source]?.DISPLAY_NAME || "Unknown";
      if (s.loading) {
        // fill all blank cells with "Loading..."
        for (const field of ["pages_scanned", "files_scanned_successfully"]) {
          s[field] = <div>Loading...</div>;
        }
      }
      if (
        searchQuery === "" ||
        (s.check_number &&
          s.check_number.toLowerCase().indexOf(searchQuery) != -1)
      ) {
        scansList.push(s);
      }
    }
    return scansList;
  };

  const getScans = props => {
    const scansList = getScansList(props);
    const scansJSX = getScansJSX(scansList);

    return scansJSX;
  };

  useEffect(() => {
    getFirebase(23, scans => {
      const reversedScans = Object.fromEntries(
        Object.entries(scans || {}).reverse()
      );
      setProcessedScans(reversedScans);
      setLoading(false);
    });
  }, []);

  const scansJSX = getScans(props);
  const actionParams = {
    download: rowData => {
      return rowData.loading ||
        rowData.pages_scanned === 0 ||
        rowData["Pages Scanned"] === 0
        ? {
            disabled: true,
            iconProps: { style: { color: grey400 } }
          }
        : {
            disabled: false,
            iconProps: { style: { color: grey600 } },
            onClick: handleDownload
          };
    }
  };
  if (props.db.permissions.includes("spend")) {
    actionParams.delete = _rowData => ({
      onClick: handleDeleteRow
    });
  }
  const columns = [
    {
      title: "Check Number",
      field: "check_number"
    },
    {
      title: "Processed Date",
      field: "processed_date"
    },
    {
      title: "Backup Source",
      field: "backup_source"
    },
    {
      title: "Processed By",
      field: "user"
    },
    {
      title: "Pages Scanned",
      field: "pages_scanned"
    },
    {
      title: "Successful Scans",
      field: "files_scanned_successfully"
    },
    {
      title: "Total Files",
      field: "total_files"
    },
    {
      title: "Status",
      field: "status"
    }
  ];
  const expandRow = [
    rowData => ({
      disabled: rowData.loading,
      render: () => {
        const scanKey = rowData.key;
        const files = rowData.files_metadata || [];
        const filesDisplayData = [];
        for (const fileObj of files) {
          filesDisplayData.push({
            key: scanKey,
            fileName: fileObj.file_name,
            storageFileName: fileObj.storage_file_name,
            "Pages Scanned": fileObj.pages_scanned,
            Status: getScanStatus(fileObj)
          });
        }
        const expansionActionParams = {
          download: rowData =>
            rowData.loading ||
            rowData.pages_scanned === 0 ||
            rowData["Pages Scanned"] === 0
              ? {
                  disabled: true,
                  iconProps: { style: { color: grey400 } }
                }
              : {
                  tooltip: "Download",
                  disabled: false,
                  iconProps: { style: { color: grey600 } },
                  onClick: handleDownload
                },
          view: _rowData => {
            return {
              onClick: handleView
            };
          }
        };
        const expansionColumns = [
          { title: "File Name", field: "fileName" },
          { title: "Pages Scanned", field: "Pages Scanned" },
          { title: "Status", field: "Status" }
        ];
        Mixpanel.track("Scan Viewed", {});
        return (
          <PaginationMUITable
            data={filesDisplayData}
            columns={expansionColumns}
            selection={false}
            allLoaded
            actionParams={expansionActionParams}
            className={classes.table}
          />
        );
      }
    })
  ];

  return (
    <div>
      <Divider />
      <Toolbar>
        <ToolbarGroup>
          <ToolbarTitle text="Processed Scans" />
          <ToolbarSeparator />
          <IconButton onClick={handleSearchButton} tooltip="Search">
            <SearchIcon />
          </IconButton>
          {showSearch && (
            <TextField
              placeholder={`Search ${
                Object.keys(processedScans).length
              } results`}
              onChange={handleSearch}
              className={classes.search}
            />
          )}
        </ToolbarGroup>
      </Toolbar>
      <div>
        {loading || isBackupSourceLoading ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ height: "80vh" }}>
            <CircularProgress size={80} thickness={5} />
          </Stack>
        ) : (
          <PaginationMUITable
            data={scansJSX}
            columns={columns}
            sorting={false}
            selection={false}
            actionParams={actionParams}
            expandRow={expandRow}
            rowStyle={rowData => {
              const style = {
                fontSize: 13,
                cursor: "pointer",
                "&:hover": { backgroundColor: "#EEE" }
              };
              if (rowData.loading) {
                style.backgroundColor = purple50;
              }
              return style;
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ProcessedScans;
