import { forecastCloudRunURL } from "config";

// const baseURL = "http://localhost:8080";
const baseURL = forecastCloudRunURL;

enum Routes {
  GET_FORECAST = "/api/get_forecast",
  GET_FORECAST_VERSIONS = "/api/get_forecast_versions",
  GET_METADATA = "/api/get_metadata",
  CREATE_FORECAST = "/api/create_forecast",
  UPDATE_OVERRIDES = "/api/update_overrides",
  DELETE_OVERRIDE = "/api/delete_override",
  EDIT_VERSION = "/api/edit_version",
  DELETE_VERSION = "/api/delete_version",
  COPY_VERSION = "/api/copy_version",
  DOWNLOAD_EXCEL = "/api/download_excel",
  DOWNLOAD_ZIP = "/api/download_zip",
  GET_FIGURES_FOR_BUDGET = "/api/get_forecast_figures_for_budget"
}

export { baseURL, Routes };
