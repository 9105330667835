import React, { useMemo } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "ui-library/IconButton";
import { Icon, Theme, Tooltip } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";
import { ColumnConfig } from "./CollapsibleTable";

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    "& > *": {
      borderBottom: "unset"
    }
  },
  button: {
    color: theme.palette.grey[600]
  },
  rowControl: {
    paddingTop: "0",
    paddingBottom: "0",
    marginTop: "0",
    marginBottom: "0"
  },
  flex: {
    display: "flex"
  },
  clickableRow: {
    cursor: "pointer"
  }
}));

export interface CollapsibleTableRowControls<T> {
  onClick?: (e: Event, rowData: T) => void;
  icon?: JSX.Element;
  label: string;
  render?: (rowData: T, rowIndex: number) => JSX.Element;
}

interface CollapsibleTableRowProps<T> {
  row: T;
  rowIndex: number;
  columnConfig: ColumnConfig<T>;
  renderCollapse?: (obj: T) => JSX.Element;
  expandProfile?: (obj: T) => void;
  isLast?: boolean;
  rowControls?: CollapsibleTableRowControls<T>[];
  fontSize?: string;
  withBorder?: boolean;
  onRowClick?: (event, rowData: T) => void;
}

export default function CollapsibleTableRow<T>(
  props: CollapsibleTableRowProps<T>
): JSX.Element {
  const {
    row,
    rowIndex,
    columnConfig,
    renderCollapse,
    expandProfile,
    isLast,
    rowControls,
    fontSize,
    withBorder,
    onRowClick
  } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const hasOnclickFunction = Boolean(onRowClick || renderCollapse);

  const columns = useMemo(
    () => Array.from(columnConfig, ([key, value]) => ({ key, value })),
    [columnConfig]
  );

  const handleRowClick = (e: React.MouseEvent<HTMLTableRowElement>): void => {
    if (onRowClick) {
      onRowClick(e, row);
    } else if (renderCollapse) {
      setOpen(!open);
    }
  };

  return (
    <>
      <TableRow
        hover
        className={classNames(
          hasOnclickFunction ? classes.clickableRow : undefined,
          withBorder ? undefined : classes.row
        )}
        onClick={handleRowClick}>
        {rowControls && (
          <TableCell
            className={classes.rowControl}
            onClick={e => e.stopPropagation()}>
            <div className={classes.flex}>
              {rowControls.map(rowControl => (
                <div key={rowControl.label}>
                  {rowControl.label && rowControl.onClick && rowControl.icon ? (
                    <Tooltip title={rowControl.label}>
                      <div>
                        <IconButton
                          onClick={e => rowControl.onClick?.(e, row)}
                          size="large">
                          {rowControl.icon}
                        </IconButton>
                      </div>
                    </Tooltip>
                  ) : (
                    <div>{rowControl.render?.(row, rowIndex)}</div>
                  )}
                </div>
              ))}
            </div>
          </TableCell>
        )}
        <TableCell>
          {renderCollapse && (
            <IconButton
              className={classes.button}
              tooltip="Expand Row"
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
          {expandProfile && (
            <IconButton
              className={classes.button}
              tooltip="View Profile"
              aria-label="view profile"
              size="small"
              onClick={e => {
                e.stopPropagation();
                expandProfile(row);
              }}>
              <Icon>open_in_new</Icon>
            </IconButton>
          )}
        </TableCell>
        {columns.map(({ key, value }, colIdx: number) => (
          <TableCell
            style={{
              fontSize,
              verticalAlign: value.verticalAlign ?? "center"
            }}
            align={value.align}
            component={colIdx === 0 ? "th" : undefined}
            key={key as string}>
            {value.render
              ? value.render(row[key], row, rowIndex)
              : row[key] ?? ""}
          </TableCell>
        ))}
      </TableRow>
      {renderCollapse && (
        <TableRow>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              borderBottom: isLast ? "unset" : undefined
            }}
            colSpan={columns.length + 1}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>{renderCollapse(row)}</Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
