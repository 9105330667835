import { Divider } from "pui-react-dividers";
import React from "react";

import { ListItem, ListItemSecondaryAction, ListItemText } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SaveIcon from "@mui/icons-material/Save";
import Button from "ui-library/Button";
import IconButton from "ui-library/IconButton";
import Subheader from "ui-library/Subheader";
import TextField from "ui-library/TextField";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import {
  getFirebase,
  removeFirebase,
  updateFirebase,
  getFirebaseConsoleURL,
  isUserCresicorEmployee
} from "helpers/Firebase";
import {
  productPastPerformanceGraph,
  productPastPromotionsTable
} from "helpers/DataProcessing";
import ModalContent from "components/WebsiteElements/ModalContent";
import { common, grey, orange } from "@mui/material/colors";
import ChipInput from "components/WebsiteElements/ChipInput";
import DataTable from "../tables/MuiDataTable";

const orange500 = orange["500"];
const orange700 = orange["700"];
const { white } = common;
const grey700 = grey["700"];

const styles = {
  header: {
    fontSize: 20,
    fontWeight: 300
  },
  divider: {
    margin: "30px auto",
    width: "50%"
  }
};

// TODO have a central location where CU links are stored, and/or integrate CU into Cresicor portal in a better way
const cresicorUniversityCustomerNameLink =
  "https://university.govividly.com/docs/contacts-section-guide#why-is-editing-a-customer-name-discouraged";

export default class ProductProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changes: false,
      plotDataObtained: false,
      product: {},
      newCodes: [],
      unitsErrorMessage: "",
      codeError: "",
      duplicateCodes: [],
      promptSave: false
    };

    this.ppRef = React.createRef();
  }

  handleTextChange = (field, event) => {
    const newProduct = this.state.product;
    newProduct[field] = event.target.value;
    this.setState({
      product: newProduct,
      changes: true
    });

    if (field == "name") {
      this.setState({
        productNameChanged: true
      });
    }
  };

  isValidCode = code => {
    const charset =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghikjlmnopqrstuvwxyz0123456789-";
    for (const digit of code) {
      if (!charset.includes(digit)) {
        return false;
      }
    }
    return true;
  };

  triggerInvalidCodeError = () => {
    const displayText =
      "Product code must only contain numbers, letters and dashes.";
    this.props.openClose.setAppModal(
      "Invalid Product Code",
      <div>{displayText}</div>,
      <div>
        <Button
          label="Okay"
          onClick={() => {
            this.props.openClose.closeAppModal();
          }}
        />
      </div>
    );
  };

  handleNewProductCodes = (operation, newCodeValues) => {
    if (operation === "add") {
      const isValid = newCodeValues.every(val => this.isValidCode(val));
      if (isValid) {
        this.setState(
          {
            newCodes: [...newCodeValues]
          },
          () => {
            this.updateDuplicateCodes(this.state.newCodes);
          }
        );
      } else {
        this.triggerInvalidCodeError();
      }
    } else {
      this.setState(
        {
          newCodes: [...newCodeValues]
        },
        () => {
          this.updateDuplicateCodes(this.state.newCodes);
        }
      );
    }
    this.setState({ changes: true });
  };

  handleChipInputPaste = ({ nativeEvent }) => {
    nativeEvent.preventDefault();
    const text = nativeEvent.clipboardData.getData("text");

    // Split paste text for new line or pipe
    const pastedChips = text.split(/\n|\|/g).map(text => text.trim());
    this.setState({
      newCodes: [...this.state.newCodes, ...pastedChips],
      changes: true
    });
  };

  getSaveJSX = () => {
    return (
      <div>
        <br />
        <div className="centering">
          You have unsaved changes. Would you like to save?
        </div>
        <br />
        <Stack justifyContent="center">
          <Button
            label="Yes"
            onClick={() => {
              const saveSuccess = this.handleSaveChanges();
              if (saveSuccess) {
                this.props.openClose.hideRightDrawer();
                this.props.openClose.setDrawerClose(null);
              }
              this.setState({
                promptSave: false
              });
            }}
          />
          <Button
            label="No, continue"
            variant="outlined"
            color="error"
            onClick={() => {
              this.props.openClose.hideRightDrawer();
              this.setState({
                promptSave: false
              });
              this.props.openClose.setDrawerClose(null);
            }}
          />
        </Stack>
      </div>
    );
  };

  checkDuplicateCodes = newCodes => {
    const duplicateCodes = {};
    for (const newCode of newCodes) {
      for (const productKey of Object.keys(this.props.db.products)) {
        const productCodes = this.props.db.products[productKey].codes;
        const productName = this.props.db.products[productKey].name;
        if (productCodes && productCodes.includes(newCode)) {
          duplicateCodes[newCode] = productName;
          break;
        }
      }
    }
    return duplicateCodes;
  };

  triggerDuplicateCodeError = duplicateCodes => {
    let displayText =
      "Some of the newly added product codes already exist under current products: ";
    for (const code of Object.keys(duplicateCodes)) {
      const product = duplicateCodes[code];
      displayText += `${code} (${product}), `;
    }
    displayText = displayText.substr(0, displayText.length - 2);
    displayText += ".";

    this.setState({
      codeError: displayText
    });
  };

  updateDuplicateCodes = newCodes => {
    const duplicateCodes = this.checkDuplicateCodes(newCodes);
    this.setState({
      duplicateCodes
    });
    if (Object.keys(duplicateCodes).length > 0) {
      this.triggerDuplicateCodeError(duplicateCodes);
      return false;
    }
    this.setState({
      codeError: ""
    });
    return true;
  };

  handleSaveChanges = () => {
    const { product, newCodes } = this.state;
    if (!("codes" in product) || product.codes == "") {
      product.codes = [];
    }
    if (newCodes.length) {
      const updated = this.updateDuplicateCodes(newCodes);
      if (!updated) {
        this.props.openClose.setAppModal(
          "Duplicate Product Code Error",
          <div>
            Some of the newly added product codes already exist under current
            products. Please resolve all conflicts before saving.
          </div>,
          <div>
            <Button
              label="Okay"
              onClick={() => {
                this.props.openClose.closeAppModal();
              }}
            />
          </div>
        );
        return false;
      }
      product.codes.push(...newCodes);
    }
    if (
      product.unitsPerCase &&
      (!parseInt(product.unitsPerCase) || parseInt(product.unitsPerCase) <= 0)
    ) {
      this.setState({
        unitsErrorMessage: "Must be a positive integer."
      });
      return false;
    }

    updateFirebase(1, product, this.props.product);
    this.setState({
      changes: false,
      productNameChanged: false,
      product,
      newCodes: [],
      unitsErrorMessage: ""
    });

    return true;
  };

  getProductData = productKey => {
    getFirebase(
      1,
      product => {
        this.setState({
          product,
          changes: false,
          productNameChanged: false
        });
      },
      productKey
    );
  };

  filterMoney = allMoney => {
    const filteredList = [];
    const currentYear = new Date().getFullYear();
    const pgs = this.props.db.meta.product_groups || {};
    for (const key in allMoney) {
      const s = allMoney[key];
      const line = this.props.db.allLines[s.lineKey];
      const pgKey = line.productGroup;
      const product =
        (pgKey == "custom" ? line.product : pgs[pgKey].products) ?? [];
      s.product = product;
      if (
        (s.productGroup == this.props.product ||
          s.product.indexOf(this.props.product) != -1) &&
        s.year == currentYear
      ) {
        filteredList.push(s);
      }
    }
    return filteredList;
  };

  getTableJSX = (field, readOnly) => {
    const list =
      this.state.product && this.state.product[field]
        ? this.state.product[field]
        : [];

    list.sort();

    return list.map(d => {
      return (
        <div
          className="rowRightAlign"
          style={{ width: "100%", marginBottom: -10 }}
          onMouseOver={() => {
            this.setState({ showDelete: d });
          }}
          onMouseOut={() => {
            this.setState({ showDelete: null });
          }}>
          <div>{d}</div>
          {this.state.showDelete == d && !readOnly ? (
            <IconButton
              onClick={this.handleDeleteFromTable.bind(null, field, d)}
              style={{ marginTop: -16, marginLeft: 30 }}
              size="large">
              <ClearIcon color={grey700} />
            </IconButton>
          ) : (
            <IconButton style={{ marginTop: -16, marginLeft: 30 }} size="large">
              <ClearIcon color={white} />
            </IconButton>
          )}
        </div>
      );
    });
  };

  handleDeleteFromTable = (field, d) => {
    const { product } = this.state;
    const list = product[field];
    list.splice(list.indexOf(d), 1);
    product[field] = list;
    this.setState({
      product,
      changes: true
    });
  };

  handleDelete = () => {
    removeFirebase(1, this.props.product);
  };

  renderDuplicateChips = (chipInfo, key) => {
    const code = chipInfo.chip;
    return (
      <Chip
        label={code}
        className={`${chipInfo.className} customChip`}
        style={{
          backgroundColor: chipInfo.isFocused && "#64b4f6"
        }}
        color={
          Object.keys(this.state.duplicateCodes).includes(code)
            ? "secondary"
            : "default"
        }
        clickable
        onClick={chipInfo.handleClick}
        onDelete={chipInfo.handleDelete}
      />
    );
  };

  componentDidMount() {
    this.getProductData(this.props.product);

    const actMoney = this.filterMoney(this.props.db.actMoney);

    const productPastPromotionsTableData = this.props.db.promotions
      ? productPastPromotionsTable("spend", actMoney, this.props.db.promotions)
      : null;
    const productPastPerformanceGraphData = productPastPerformanceGraph(
      "spend",
      actMoney
    );

    this.setState({
      productPastPromotionsTableData,
      productPastPerformanceGraphData
    });

    if (!this.props.fixedHeight && $(this.ppRef.current).offset()) {
      const ppHeight =
        document.documentElement.clientHeight -
        $(this.ppRef.current).offset().top +
        $(window).scrollTop();

      this.setState({
        ppHeight
      });
    }

    this.props.openClose.setDrawerClose(() => {
      if (this.state.changes) {
        this.setState({
          promptSave: true
        });
      } else {
        this.props.openClose.hideRightDrawer();
        this.setState({
          promptSave: false
        });
      }
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getProductData(nextProps.product);

    if (!nextProps.fixedHeight && $(this.ppRef.current).offset()) {
      const ppHeight =
        document.documentElement.clientHeight -
        $(this.ppRef.current).offset().top +
        $(window).scrollTop();
      this.setState({
        ppHeight
      });
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    const editPermission = this.props.db.permissions.includes("edit");
    const codesJSX = this.getTableJSX("codes", !editPermission);

    const firebaseConsoleURL = `${getFirebaseConsoleURL()}/companies/${
      this.props.db.companyid
    }/products/${this.props.product}`;

    return (
      <div>
        <ListItem
          sx={{ background: theme => theme.palette.readOnly.surface3 }}
          ContainerComponent="div">
          <ListItemText
            primary={this.props.db.products[this.props.product].name}
            secondary={
              <span>
                <span>Product Profile</span>
                {isUserCresicorEmployee() && (
                  <span>
                    {" "}
                    |{" "}
                    <a
                      href={firebaseConsoleURL}
                      target="_blank"
                      style={{ color: "#1b7acf" }}
                      rel="noreferrer">
                      View on Firebase Console
                    </a>
                  </span>
                )}
              </span>
            }
          />
          <ListItemSecondaryAction>
            {this.state.changes &&
            this.props.db.permissions.includes("edit") &&
            !this.props.readOnly ? (
              <Button
                label="Save Changes"
                onClick={this.handleSaveChanges}
                icon={<SaveIcon />}
                style={{
                  marginRight: 16,
                  marginTop: 5,
                  height: 36,
                  width: 175,
                  display: "flex"
                }}
              />
            ) : (
              <Button
                label="Save Changes"
                disabled
                icon={<SaveIcon />}
                style={{
                  marginRight: 16,
                  marginTop: 5,
                  height: 36,
                  width: 175,
                  display: "flex"
                }}
              />
            )}
          </ListItemSecondaryAction>
        </ListItem>
        {!this.state.promptSave ? (
          this.state.product ? (
            <ModalContent
              style={{
                paddingLeft: "16px",
                paddingRight: "16px",
                height: this.state.ppHeight,
                overflow: "auto"
              }}
              innerRef={this.ppRef}>
              <br />
              <TextField
                value={this.state.product.name || ""}
                onChange={this.handleTextChange.bind(null, "name")}
                floatingLabelText="Name"
                fullWidth
                disabled={!editPermission}
              />
              {this.state.productNameChanged && (
                <div>
                  <br />
                  <div className="rowC">
                    <font color="orange">
                      Editing the name of a product is discouraged.
                    </font>
                    <Button
                      label="More Info"
                      backgroundColor={orange500}
                      hoverColor={orange700}
                      onClick={() =>
                        window.open(
                          cresicorUniversityCustomerNameLink,
                          "_blank"
                        )
                      }
                      style={{
                        color: "white",
                        marginLeft: "5%",
                        transform: "translate(0%, -25%)"
                      }}
                    />
                  </div>
                </div>
              )}
              <br />
              <TextField
                value={this.state.product.size || ""}
                onChange={this.handleTextChange.bind(null, "size")}
                floatingLabelText="Size"
                fullWidth
                disabled={!editPermission}
              />
              <br />
              <TextField
                value={this.state.product.unitsPerCase || ""}
                onChange={this.handleTextChange.bind(null, "unitsPerCase")}
                floatingLabelText="Units Per Case"
                fullWidth
                errorText={this.state.unitsErrorMessage}
                disabled={!editPermission}
              />
              <br />
              <Divider
                style={styles.divider}
                size="large"
                className="centering"
              />
              <Subheader style={styles.header}>Product Groups</Subheader>
              <br />
              <div>These can be managed under the Product Groups section.</div>
              <br />
              <DataTable
                title="Product Groups"
                data={
                  this.state.product.productGroups
                    ? {
                        Name: this.state.product.productGroups
                          .map(
                            pgKey =>
                              this.props.db.meta.product_groups[pgKey].name
                          )
                          .sort()
                      }
                    : { Name: ["This product is not mapped in any groups"] }
                }
              />
              <br />
              <Divider
                style={styles.divider}
                size="large"
                className="centering"
              />
              <Subheader style={styles.header}>
                Associate Product Codes (for data matching)
              </Subheader>
              <br />
              <div className="rowC">
                <span>
                  Press Enter after typing a new code to create it. Make sure to
                  "Save Changes" once you're done.
                </span>
              </div>
              <ChipInput
                value={this.state.newCodes}
                label="New Code(s)"
                fullWidth
                disabled={!editPermission}
                error={this.state.codeError.length > 0}
                helperText={this.state.codeError}
                onAdd={code => this.handleNewProductCodes("add", code)}
                onDelete={code => this.handleNewProductCodes("delete", code)}
                onPaste={this.handleChipInputPaste}
                chipRenderer={this.renderDuplicateChips}
              />
              <br />
              <br />
              <DataTable
                title="Product Codes"
                data={
                  this.state.product.codes
                    ? { Name: codesJSX }
                    : { Name: ["No associated product codes stored"] }
                }
              />
            </ModalContent>
          ) : (
            <div className="centering" style={{ margin: 16 }}>
              Product no longer exists.
            </div>
          )
        ) : (
          this.getSaveJSX()
        )}
      </div>
    );
  }
}
