import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

class LoadingIndicator extends React.Component {
  constructor(props) {
    super(props);
    this.tableInstance = React.createRef();
    this.state = {
      loading: false,
      loadingText: "0% Complete"
    };
  }

  componentDidMount() {
    this.setState({
      loading: this.props.loading,
      loadingText: this.props.loadingText
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      loading: nextProps.loading,
      loadingText: nextProps.loadingText
    });
  }

  render() {
    return this.state.loading ? (
      <div className="-loading -active">
        <div className="-loading-inner">
          <CircularProgress />
          <div>{this.state.loadingText}</div>
        </div>
      </div>
    ) : null;
  }
}

export default LoadingIndicator;
