import "antd/dist/antd.min.css";
import "./styles.less";

import Button from "ui-library/Button";
import CompareIcon from "@mui/icons-material/Compare";
import LinearProgress from "@mui/material/LinearProgress";
import IconButton from "ui-library/IconButton";
import React from "react";
import { Table, Input, Space, Checkbox, Button as AntdButton } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { adjustDateForTrailingWeek } from "helpers/Time";
import { cloudRunURL } from "helpers/Firebase";

import axios from "axios";
import { blue, grey } from "@mui/material/colors";
import { EditableCell, EditableRow } from "./EditableCell";

const blue600 = blue["600"];
const grey600 = grey["600"];
const grey700 = grey["700"];

const types = {
  Stores: "stores",
  "Base Velocity (USWI)": "velocity",
  "Base Sales": "baseSales"
};

const TABLE_ORDER = [
  "Sales (Units)",
  "Revenue",
  "Promotion (Y/N)",
  "Lift",
  "Seasonality",
  "Base Sales",
  "Stores",
  "Base Velocity (USWI)"
];

const round = val => Math.round(parseFloat(val) * 100) / 100;

export default class DisplayTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      maxDataValue: 0,
      minDataValue: 0,
      expandedRows: [],
      searchText: "",
      searchedColumn: "",
      toggleRows: false
    };
  }

  pivot = () => (this.props.pivot === "customer" ? "product" : "customer");

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <AntdButton
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}>
            Search
          </AntdButton>
          <AntdButton
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}>
            Reset
          </AntdButton>
          <AntdButton
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex
              });
            }}>
            Filter
          </AntdButton>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    }
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  isValid = val => {
    const floatVal = parseFloat(val);
    return val === "" || (!isNaN(floatVal) && floatVal >= 0);
  };

  updateFirebaseValues = async (type, row, week) => {
    const value = row[week];
    if (this.props.pivot == "customer") {
      var customer = this.props.sectionKey;
      var { product } = row;
    } else {
      var product = this.props.sectionKey;
      var { customer } = row;
    }
    if (value) {
      if (type == "stores") {
        var update = parseInt(value);
      } else {
        var update = round(value);
      }
      const payload = {
        company_id: this.props.db.companyid,
        table_name: this.props.mode,
        customer_key: customer,
        product_key: product,
        type,
        week,
        value: update
      };

      this.setState({ editLoading: true });
      await axios
        .post(`${cloudRunURL}/api/write_forecast`, {
          data: payload
        })
        .catch(e => {
          console.error(e);
          this.props.openClose.showSnack(
            "Error writing value - please try again or contact support if this error persists."
          );
        });
      await this.props
        .fetchTable()
        .then(() => this.setState({ editLoading: false }));
    }
  };

  isEditable = (record, week) => {
    const rowType = this.pivot();
    const key = record[rowType];
    const forecastBeginDate = this.props.forecastBeginDates[key];
    if (
      [record.metric, record.name].some(metric =>
        [
          "Revenue",
          "Promotion (Y/N)",
          "Lift",
          "Sales (Units)",
          "Seasonality"
        ].includes(metric)
      ) ||
      new Date(week) < forecastBeginDate
    ) {
      return false;
    }
    if (this.props.db.permissions.includes("forecast")) {
      return true;
    }
    return false;
  };

  getHeatmapColor = (record, value) => {
    if (
      record.metric != "Promotion (Y/N)" &&
      this.state.minDataValue != this.state.maxDataValue &&
      (Boolean(record.children) || this.props.sectionKey === "Total")
    ) {
      return `rgba(0, 151, 230,
          ${
            1 -
            (this.state.maxDataValue - value) /
              (this.state.maxDataValue - this.state.minDataValue)
          })`;
    }
    if (
      record.metric == "Promotion (Y/N)" &&
      (Boolean(record.children) || this.props.sectionKey === "Total")
    ) {
      return `rgba(0, 151, 230,
          ${value == "Y" ? 1 : 0})`;
    }
    return undefined;
  };

  avfView = row => {
    if (!row.children && this.props.sectionKey !== "Total") {
      return <div />;
    }

    const [customer, product] =
      this.props.pivot == "customer"
        ? [this.props.sectionKey, row.product]
        : [row.customer, this.props.sectionKey];

    return (
      <div className="centering" style={{ marginTop: -10 }}>
        <IconButton
          style={{ height: "60%", backgroundColor: "transparent" }}
          onClick={this.props.openAVF.bind(null, customer, product)}
          size="large">
          <CompareIcon />
        </IconButton>
      </div>
    );
  };

  updateDataColumns = props => {
    const columns = [];
    const headerName = this.props.pivot === "customer" ? "Product" : "Customer";
    columns.push({
      title: "",
      dataIndex: "avf",
      key: "avf",
      fixed: "left",
      width: 40,
      render: (_text, row) => this.avfView(row)
    });
    columns.push({
      title: headerName,
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: 150,
      sorter: (row1, row2) => {
        if (!TABLE_ORDER.includes(row1.name)) {
          return row1.name?.localeCompare(row2.name);
        }
        return 0;
      },
      ...this.getColumnSearchProps("name")
    });
    for (const week of props.weeks) {
      if (
        props.year == "All" ||
        new Date(adjustDateForTrailingWeek(week)).getFullYear() == props.year
      ) {
        columns.push({
          title: week,
          dataIndex: week,
          key: week,
          editable: true,
          render: (text, record) => {
            const cellProps = { style: {} };
            const value = isNaN(text) ? text : round(text);
            if (value === "Y" || (record.key === "lift" && value > 0)) {
              cellProps.style.backgroundColor = grey600;
            }
            if (this.props.heatmap === "Show Coloring") {
              cellProps.style.backgroundColor = this.getHeatmapColor(
                record,
                value
              );
            }

            const rowType = props.pivot === "customer" ? "product" : "customer";
            const key = record[rowType];
            const type = types[record.metric];
            if (props.enteredValues?.[type]?.[key]?.has(week)) {
              cellProps.style.color = blue600;
            }
            const cellValue = value?.toLocaleString("en-US");
            return {
              children:
                record.metric === "Revenue" ? `$${cellValue}` : cellValue,
              props: cellProps
            };
          }
        });
      }
    }
    columns.push({
      title: "Total",
      dataIndex: "Total",
      key: "Total",
      fixed: "right",
      sorter: (row1, row2) => {
        const x = typeof row1.Total === "string" ? 0 : row1.Total;
        const y = typeof row2.Total === "string" ? 0 : row2.Total;
        if (!TABLE_ORDER.includes(row1.name)) {
          return x - y;
        }
        return 0;
      },
      render: (text, record) => {
        if (isNaN(text)) {
          return text;
        }
        const val = round(text).toLocaleString("en-US");
        return record.metric === "Revenue" ? `$${val}` : val;
      }
    });

    // get minimum and maximum values in data
    if (props.view != "isProm") {
      const allVals = [];
      for (const row of props.data[props.view]) {
        for (const week of props.weeks) {
          allVals.push(row[week]);
        }
      }
      var minDataValue = Math.min(...allVals);
      var maxDataValue = Math.max(...allVals);
    }
    // set state variables
    this.setState({
      minDataValue,
      maxDataValue,
      metrics: props.metrics,
      columns,
      pivot: props.pivot
    });
  };

  transformData = allData => {
    const data = allData[this.props.view];
    const pivot = this.pivot();

    return data
      ?.map(row => {
        const newRow = { ...row };
        newRow.key = row.name + row[pivot];
        const subData = Object.entries(allData)
          .filter(([name, _view]) => name !== this.props.view)
          .map(([name, view]) => {
            const subDataRow = {
              ...view.filter(subRow => newRow[pivot] === subRow[pivot])?.[0]
            };
            subDataRow.name = subDataRow.metric;
            subDataRow.key = name + subDataRow[pivot];
            if (!newRow.children) {
              newRow.children = [];
            }
            return subDataRow;
          });
        TABLE_ORDER.forEach(rowName => {
          const row = subData.filter(subRow => subRow.name === rowName);
          if (row.length > 0) {
            newRow.children.push(row[0]);
          }
        });
        return newRow;
      })
      .sort((row1, row2) => row1.name?.localeCompare(row2.name));
  };

  handleSave = week => async record => {
    const value = record[week];
    if (
      this.props.db.permissions.includes("forecast") &&
      !this.props.readOnly
    ) {
      if (this.isValid(value)) {
        const type = types[record.metric];
        await this.updateFirebaseValues(type, record, week);
      } else if (!this.isValid(value)) {
        this.props.openClose.setAppModal(
          "Invalid Value",
          <div className="centering">
            All entered values must be nonnegative numbers.
          </div>,
          <div className="centering">
            <Button
              label="Ok"
              onClick={() => {
                this.props.openClose.closeAppModal();
              }}
            />
          </div>
        );
      }
    }
  };

  componentDidMount() {
    this.updateDataColumns(this.props);
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.updateDataColumns(this.props);
    }
  }

  render() {
    const data = this.state.toggleRows
      ? this.transformData(this.props.data)
      : this.transformData(this.props.data).filter(row => {
          const salesTotal =
            this.props.view === "sales"
              ? row.Total
              : row?.children.filter(
                  subRow => subRow.metric === "Sales (Units)"
                )?.[0].Total;
          return !Number.isNaN(salesTotal) && parseFloat(salesTotal) > 0;
        });
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell
      }
    };
    const columns = this.state.columns.map(col => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: record => {
          return {
            record,
            editable: this.isEditable(record, col.title),
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: this.handleSave(col.title)
          };
        }
      };
    });

    const percentLoading =
      this.props.loading && this.props.percentComplete < 100;
    const switchLoading = this.props.getTableDataLoading;

    return (
      <>
        {this.state.editLoading && <LinearProgress />}
        <Table
          components={components}
          scroll={{ x: "max-content", y: 500 }}
          columns={columns}
          dataSource={data}
          expandable={{
            defaultExpandedRowKeys: this.state.expandedRows,
            onExpandedRowsChange: expandedRows => {
              this.setState({
                expandedRows
              });
            }
          }}
          pagination={{
            position: ["none", "bottomCenter"],
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["5", "10", "15", "20"]
          }}
          loading={
            (percentLoading || switchLoading) && {
              tip: percentLoading
                ? `${this.props.percentComplete}% complete`
                : "Loading..."
            }
          }
          size="small"
          bordered
          title={() => (
            <Checkbox
              onChange={e => this.setState({ toggleRows: e.target.checked })}>
              Include Rows With No Value
            </Checkbox>
          )}
        />
      </>
    );
  }
}
