import React from "react";
import * as jQuery from "jquery";
import parse from "html-react-parser";
import moment from "moment";

import AssignmentIcon from "@mui/icons-material/Assignment";

import MenuItem from "ui-library/MenuItem";
import Select from "ui-library/Select";
import Button from "ui-library/Button";
import Card from "ui-library/Card";

import { blue, yellow, grey } from "@mui/material/colors";
import { Stack } from "@mui/material";
import TimeframeEditor from "./TimeframeEditor";

import {
  timeframeType,
  getCurrentTimeframeIndex,
  checkConflicts
} from "./CustomerUtils";

const blue200 = blue["200"];
const yellow200 = yellow["200"];
const grey200 = grey["200"];

const titleColors = {
  present: grey200,
  past: yellow200,
  future: blue200
};

const topText = {
  present:
    "You are viewing the <b>present</b> version of this customer profile.",
  past: "You are viewing a <b>past</b> version of this customer profile.",
  future: "You are viewing a <b>future</b> version of this customer profile."
};

class TimeframeIndicator extends React.Component {
  constructor(props) {
    super(props);
  }

  getDateText = i => {
    let startDate = "the beginning of time";
    let endDate = "the end of time";

    const timeframe =
      this.props.customer.timeframes && this.props.customer.timeframes[i]
        ? this.props.customer.timeframes[i]
        : this.props.customer;
    if (this.props.customer.timeframes) {
      const { effectiveDate, expirationDate } = timeframe;
      if (effectiveDate) {
        startDate = moment(effectiveDate).format("MMM DD, YYYY");
      }
      if (expirationDate) {
        endDate = moment(expirationDate).format("MMM DD, YYYY");
      }
    }

    return [startDate, endDate];
  };

  getTimeframesJSX = () => {
    const timeframesJSX = [];
    if (this.props.customer.timeframes) {
      for (let i = 0; i < this.props.customer.timeframes.length; i++) {
        const [startDate, endDate] = this.getDateText(i);
        timeframesJSX.push(
          <MenuItem value={i} key={i} children={`${startDate} to ${endDate}`} />
        );
      }
    }
    return timeframesJSX;
  };

  changeTimeframe = i => {
    this.props.setTimeframe(i);
  };

  componentDidMount() {}

  render() {
    const timeframeIndex =
      this.props.timeframeIndex ??
      getCurrentTimeframeIndex(this.props.customer, new Date());

    const type = timeframeType(
      this.props.customer,
      timeframeIndex,
      new Date(),
      new Date()
    );
    const [startDate, endDate] = this.getDateText(timeframeIndex);

    // TODO: fix the tr and td stuff
    return (
      <Card title="Timeframe Indicator" titleColor={titleColors[type]}>
        {parse(
          `${topText[type]}<br />Valid from: <b>${startDate}</b> to <b>${endDate}</b>`
        )}
        <Stack sx={{ mt: 2 }}>
          <div style={{ flex: 1 }}>
            {this.props.customer.timeframes?.length ? (
              <Select
                floatingLabelText="Select Timeframe"
                onChange={event => this.changeTimeframe(event.target.value)}
                value={timeframeIndex}
                disabled={this.props.changes}>
                {this.getTimeframesJSX()}
              </Select>
            ) : (
              parse(
                '<font color="red">No timeframes have been set for this customer yet.</font>'
              )
            )}
          </div>
          <Button
            disabled={
              !this.props.editPermission ||
              this.props.presetCustomer ||
              this.props.changes
            }
            label="Manage Timeframes"
            onClick={() =>
              this.props.openClose.setAppModal(
                "Timeframe Editor",
                <TimeframeEditor
                  meta={this.props.meta}
                  obj={this.props.customer}
                  save={this.props.saveCustomer}
                  openClose={this.props.openClose}
                  checkConflicts={newCustomer => {
                    const customers = jQuery.extend(
                      true,
                      {},
                      this.props.customers
                    );
                    customers[newCustomer.key] = newCustomer;
                    return checkConflicts(customers);
                  }}
                  fields={{}}
                  isTimeframeEditable={tf => true}
                />,
                null,
                false,
                true,
                "md"
              )
            }
            primary
            icon={<AssignmentIcon />}
          />
        </Stack>
        {this.props.changes && (
          <font color="orange">
            Please save changes before switching or managing timeframes.
          </font>
        )}
      </Card>
    );
  }
}

export default TimeframeIndicator;
