import React from "react";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { displayUSCurrency } from "helpers/DataProcessing";
import { DisplayInvoiceObject } from "components/Deductions/DeductionsReconciliation/types/invoiceTypes";
import { DisplayERPTransactionObject } from "components/Deductions/DeductionsReconciliation/types/transactionTypes";

interface MultiEditCardProps {
  displayTransaction: DisplayERPTransactionObject;
  displayInvoice: DisplayInvoiceObject;
}

const ColumnHeader = styled(Typography)(({ theme }) => ({
  lineHeight: "14px",
  fontSize: "14px",
  color: theme.palette.text.primary,
  fontWeight: 500,
  marginTop: "2px",
  marginBottom: "2px"
}));

const ColumnText = styled(Typography)(() => ({
  lineHeight: "14px",
  fontSize: "14px",
  fontWeight: 400,
  marginTop: "2px",
  marginBottom: "2px"
}));

const CardColumn = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  padding: "10px 15px 10px 15px"
}));

const CardContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  width: "100%",
  height: "auto",
  padding: "15px 0px 35px 0px"
}));

const TransactionCard = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  border: `1px solid ${theme.palette.grey[400]}`,
  backgroundColor: theme.palette.background.paper,
  borderRadius: "8px",
  marginRight: 15
}));

const InvoiceCard = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  border: `1px solid ${theme.palette.grey[400]}`,
  backgroundColor: theme.palette.background.paper,
  borderRadius: "8px"
}));

export default function MultiEditCard(props: MultiEditCardProps): JSX.Element {
  const { displayTransaction, displayInvoice } = props;

  const transactionColumns = {
    "Transaction ID": displayTransaction.transactionDisplayId ?? "",
    "Total Amount": displayUSCurrency(displayTransaction.amount),
    "Open Amount": displayUSCurrency(displayTransaction.openAmount),
    Customer: displayTransaction.customerName
  };
  const invoiceColumns = {
    "Invoice #": displayInvoice.invoiceNumber ?? "",
    "Total Amount": displayUSCurrency(displayInvoice.amount),
    "Open Amount": displayUSCurrency(displayInvoice.openAmount),
    Customer: displayInvoice.customerName
  };

  return (
    <CardContainer>
      <TransactionCard>
        {Object.entries(transactionColumns).map(
          ([columnTitle, columnValue]) => (
            <CardColumn key={`column-${columnTitle}`}>
              <ColumnHeader>{columnTitle}</ColumnHeader>
              <ColumnText
                data-testid={`transaction-column-header-${columnTitle}`}>
                {columnValue}
              </ColumnText>
            </CardColumn>
          )
        )}
      </TransactionCard>
      <InvoiceCard>
        {Object.entries(invoiceColumns).map(([columnTitle, columnValue]) => (
          <CardColumn key={`column-${columnTitle}`}>
            <ColumnHeader>{columnTitle}</ColumnHeader>
            <ColumnText data-testid={`invoice-column-header-${columnTitle}`}>
              {columnValue}
            </ColumnText>
          </CardColumn>
        ))}
      </InvoiceCard>
    </CardContainer>
  );
}
