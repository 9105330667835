import { styled } from "@mui/system";

const ToolbarGroup = styled("div")`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default ToolbarGroup;
