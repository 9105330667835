// eslint-disable-next-line max-classes-per-file
import { Divider } from "pui-react-dividers";
import React from "react";
import classNames from "classnames";

import Typography from "@mui/material/Typography";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationItem from "@mui/material/BottomNavigationAction";
import RadioButtonGroup from "@mui/material/RadioGroup";
import RadioButton from "ui-library/RadioButton";
import { Toolbar, ToolbarSeparator, ToolbarTitle } from "ui-library/Toolbar";
import ToolbarGroup from "ui-library/ToolbarGroup";
import { sum, map } from "lodash-es";
import CalcIcon from "@mui/icons-material/SimCard";
import DownloadIcon from "@mui/icons-material/GetApp";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from "@mui/icons-material/Refresh";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ListIcon from "@mui/icons-material/List";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import Button from "ui-library/Button";
import IconButton from "ui-library/IconButton";
import IconMenu from "ui-library/IconMenu";
import MenuItem from "ui-library/MenuItem";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";

import SelectField from "ui-library/Select";
import SettingsIcon from "@mui/icons-material/Settings";
import TextField from "ui-library/TextField";
import Card from "ui-library/Card";
import Subheader from "ui-library/Subheader";

import { exportAnalytics } from "helpers/Export";
import {
  getUserDataFirebase,
  getJSXListsFirebase,
  updateMetaFirebase,
  firebase
} from "helpers/Firebase";
import { getTimesJSX } from "helpers/Time";
import { grey, common } from "@mui/material/colors";
import StyledToggleButtonGroup from "ui-library/ToggleButtonGroupStyled";
import ToggleButton from "@mui/material/ToggleButton";
import { tradeRateTable } from "./tradeRateCalcs";
import FinancePlots from "../Analytics/FinancePlots";
import MoneyTableExpandable from "../graphs/MoneyTableExpandable";

const grey700 = grey["700"];
const { black } = common;

const styles = {
  notifs: {
    width: "500px"
  },
  input: {
    margin: 5
  },
  loading: {
    height: $(window).height()
  },
  tabs: {
    backgroundColor: grey700
  },
  tabsBox: {
    borderColor: grey700,
    borderWidth: 2,
    borderStyle: "solid"
  },
  divider: {
    margin: "30px auto",
    width: "50%"
  },
  radioButton: {
    marginBottom: 16
  },
  textfield: {
    "white-space": "pre-line"
  }
};

function round(n) {
  return Math.round(n * 100) / 100;
}

class CommentsSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: props.comments
    };
  }

  saveComments = () => {
    const meta = { ...this.props.db.meta };
    meta.pnl_comments = this.state.comments;

    updateMetaFirebase(meta);
    this.setState({ editComments: false });
  };

  componentDidMount() {
    this.setState({ comments: this.props.db.meta.pnl_comments });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ comments: nextProps.db.meta.pnl_comments });
  }

  render() {
    return (
      <div style={{ fontFamily: "Oxygen" }}>
        <Divider style={styles.divider} size="large" />
        <Stack>
          <Subheader>Comments</Subheader>
          {this.state.editComments ? (
            <Button label="Save" onClick={this.saveComments} primary />
          ) : (
            <Button
              onClick={() => {
                this.setState({ editComments: true });
              }}
              sx={{
                "& .MuiButton-startIcon": {
                  margin: 0
                }
              }}
              icon={<EditIcon />}
            />
          )}
        </Stack>
        {this.state.editComments ? (
          <TextField
            style={styles.textfield}
            value={this.state.comments}
            onChange={event => {
              this.setState({ comments: event.target.value });
            }}
            floatingLabelText="Comments"
            fullWidth
            multiLine
            rows={5}
          />
        ) : (
          <div style={styles.textfield}>
            {this.state.comments ? this.state.comments : "None"}
          </div>
        )}
        <br />
        <br />
      </div>
    );
  }
}

class CustomerPnl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSearch: false,
      searchQuery: "",
      table: "Customer P&L",
      pivot: "customer",
      modalOpen: false,
      diffCutoff: -10000,
      percentCutoff: -2,
      category: 1,
      accounts: [],
      checkedAll: false,
      month: 0,
      year: new Date().getFullYear(),
      time: "month",
      byDate: false,
      mode: "tradeRate",
      pnlComments: "",
      pnlGroup: 0,
      groupBy: "pnlGroup",
      source: [0],
      sourcesJSX: [],
      tableRows: [],
      hideOptions: false,
      selectedIndex: 0,
      dateSource: "spend"
    };
  }

  saveToDatabase = () => {
    const savedKeys = [
      "pivot",
      "diffCutoff",
      "percentCutoff",
      "category",
      "accounts",
      "checkedAll",
      "month",
      "year",
      "time",
      "byDate",
      "mode",
      "pnlGroup",
      "groupBy",
      "source",
      "selectedIndex",
      "dateSource"
    ];
    const payload = {};

    for (const key in savedKeys) {
      if (this.state.hasOwnProperty(savedKeys[key])) {
        payload[savedKeys[key]] = this.state[savedKeys[key]];
      }
    }

    const { uid } = firebase.auth().currentUser;
    firebase.database().ref(`users/${uid}/insights/customer_pnl`).set(payload);
  };

  filterMoney = (moneyObj, type = null) => {
    const filteredMoney = [];
    if (moneyObj) {
      for (const key in moneyObj) {
        const s = { ...moneyObj[key] };
        const status = s.status ? this.props.db.meta.statuses[s.status] : null;
        if (this.state.dateSource == "promotion" && type == "spend") {
          // if the date source we want to use use is from promotions, change it
          s.month = this.props.db.promotions[s.promKey].month;
          s.year = this.props.db.promotions[s.promKey].year;
        }
        // for spend, get the customer from the promotion and not directly from the spend item
        if (type != "revenue") {
          s.customer = this.props.db.promotions[s.promKey].customer;
          s.account = this.props.db.allLines[s.lineKey].account;
        }
        const c = this.props.db.customers[s.customer];
        if (
          (!this.state.pnlGroup || c?.pnlGroup == this.state.pnlGroup) &&
          (!this.state.month || s.month == this.state.month) &&
          (!this.state.year || s.year == this.state.year) &&
          (!this.state.promotion || s.promKey == this.state.promotion) &&
          (!s.account || this.state.accounts.includes(s.account)) &&
          (type != "revenue" ||
            this.state.source.includes(0) ||
            this.state.source.includes(s.source)) &&
          (!status || ["Running", "Completed"].indexOf(status) != -1)
        ) {
          filteredMoney.push(s);
        }
      }
    }
    return filteredMoney;
  };

  getRowOrder = data => {
    function arraysEqual(arr1, arr2) {
      if (arr1.length !== arr2.length) return false;
      for (let i = arr1.length; i--; ) {
        if (arr1[i] !== arr2[i]) return false;
      }
      return true;
    }
    if (data.tradeRate) {
      // Get customer list from real table data
      const entries = data.tradeRate.map(e => {
        return e.customer;
      });
      // Get sorted customer list from table component, which may be irrelevant (from past render)
      const suggestedEntries = this.state.tableRows.map(e => {
        return e._original.customer;
      });

      // Check if they match
      if (
        arraysEqual(entries.slice().sort(), suggestedEntries.slice().sort())
      ) {
        return suggestedEntries;
      }
      return entries;
    }
    return [];
  };

  updateData = (table, sortedData) => {
    const state = {};
    state[table] = sortedData;
    this.setState(state);
  };

  changeMode = (event, mode) => {
    this.setState({ mode });
  };

  changeTime = (event, time) => {
    this.setState({ time });
  };

  handleResetFields = () => {
    this.setState({
      month: 0,
      year: new Date().getFullYear(),
      pnlGroup: 0,
      groupBy: "pnlGroup",
      source: [0]
    });
  };

  getSourcesJSX = props => {
    const sources = {};
    for (const key in props.db.revenue) {
      const r = props.db.revenue[key];
      if (r.source && !(r.source in sources)) {
        sources[r.source] = true;
      }
    }

    const sourcesJSX = [];
    for (const source in sources) {
      sourcesJSX.push(
        <MenuItem value={source} children={source} key={source} />
      );
    }

    return sourcesJSX;
  };

  selectCalc = event => {
    const meta = { ...this.props.db.meta };
    meta.retail_spend_calc = event.target.value;

    updateMetaFirebase(meta);
  };

  retailSpendCalc = () => {
    this.props.openClose.setAppModal(
      "Select Calculation Method",
      <div>
        <Subheader>Retail Spend Calculation</Subheader>
        <div style={{ fontFamily: "PT Sans", marginLeft: 10 }}>
          Select a method (optional) to allocate additional spend to retailers
          who are promoted to through one of their distributors.
        </div>
        <br />
        <RadioButtonGroup
          name="traderate"
          defaultValue={this.props.db.meta.retail_spend_calc || "None"}
          onChange={this.selectCalc}
          style={{ marginLeft: 30 }}>
          <RadioButton
            value="PASS_THROUGH"
            label="Pass through distributor level spend to retailers"
          />
          <RadioButton value="None" label="None" />
        </RadioButtonGroup>
      </div>
    );
  };

  trFooter = (data, mode, accessor) => {
    const totalRevenue = sum(map(data.revenue, d => d[accessor]));
    const totalSpend = sum(map(data.spend, d => d[accessor]));
    return round((totalSpend / totalRevenue) * 100);
  };

  getAccountsJSX = () => {
    const { fundTypes = {} } = this.props.db.meta;
    return Object.entries(fundTypes).map(([key, fundType]) => {
      return (
        <MenuItem
          value={fundType.accountKey}
          insetChildren={false}
          checked={this.state.accounts.includes(fundType.accountKey)}
          children={fundType.name}
          key={key}
        />
      );
    });
  };

  selectAll = () => {
    let accounts;
    if (this.state.checkedAll) {
      accounts = [];
    } else {
      const { fundTypes = {} } = this.props.db.meta;
      accounts = Array.from(
        new Set(Object.values(fundTypes).map(fundType => fundType.accountKey))
      );
    }
    this.setState({
      accounts,
      checkedAll: !this.state.checkedAll
    });
  };

  onAccountChange = (event, index, values) => {
    if (values.includes("Select All")) {
      this.selectAll();
    } else {
      this.setState({ accounts: values });
    }
  };

  componentDidMount() {
    const updates = { checkedAll: true };
    const { fundTypes = {} } = this.props.db.meta;
    const accounts = Array.from(
      new Set(Object.values(fundTypes).map(fundType => fundType.accountKey))
    );
    updates.accounts = accounts;
    const sourcesJSX = this.getSourcesJSX(this.props);
    updates.sourcesJSX = sourcesJSX;
    getJSXListsFirebase(this.props.db, allJSX => {
      updates.customersJSX = allJSX.customersJSX;
      updates.productsJSX = allJSX.productsJSX;
    });
    const timesJSX = getTimesJSX(this.props.db.meta, true);
    updates.monthsJSX = timesJSX[0];
    updates.yearsJSX = timesJSX[1];
    updates.doneLoading = true;
    this.setState(updates);
    getUserDataFirebase(customerPNL => {
      if (customerPNL) {
        this.setState(customerPNL);
      }
    }, "insights/customer_pnl");
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.sideBar != this.props.sideBar) {
      return false;
    }
    return true;
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    let { groupBy } = this.state;
    if (groupBy == "pnlGroup" && this.state.pnlGroup) {
      groupBy = null;
    }
    const revList = this.filterMoney(this.props.db.revenue, "revenue");
    const spendList = this.filterMoney(this.props.db.actMoney, "spend");
    const linesList = this.filterMoney(this.props.db.allLines, "lines");

    // add customer key to customers variable
    const allCustomers = this.props.db.customers;
    for (const key in allCustomers) {
      allCustomers[key].customer = key;
    }

    let trData = [];
    if (this.state.selectedIndex == 0) {
      trData =
        Object.keys(this.props.db.customers).length > 0
          ? tradeRateTable(
              revList,
              spendList,
              linesList,
              groupBy,
              this.state.pnlGroup,
              allCustomers,
              this.props.db.meta
            )
          : [];
    }

    return (
      <div>
        <Toolbar>
          <ToolbarGroup>
            <ToolbarTitle text="Finance Analytics" style={{ color: black }} />
            <ToolbarSeparator />
          </ToolbarGroup>
          <Stack>
            <StyledToggleButtonGroup
              value={this.state.mode}
              exclusive
              onChange={this.changeMode}>
              <ToggleButton value="tradeRate">Trade Rate (%) </ToggleButton>
              <ToggleButton value="revenue">Revenue</ToggleButton>
              <ToggleButton value="spend">Spend</ToggleButton>
            </StyledToggleButtonGroup>
            <StyledToggleButtonGroup
              value={this.state.time}
              exclusive
              onChange={this.changeTime}>
              <ToggleButton value="month">Month</ToggleButton>
              <ToggleButton value="quarter">Quarter</ToggleButton>
            </StyledToggleButtonGroup>
          </Stack>
          <ToolbarGroup>
            <IconButton
              tooltip="Calculation Methodology"
              onClick={this.retailSpendCalc}
              size="large">
              <CalcIcon />
            </IconButton>
            <IconMenu
              iconButtonElement={
                <IconButton tooltip="Settings" size="large">
                  <SettingsIcon />
                </IconButton>
              }
              anchorOrigin={{ horizontal: "right", vertical: "top" }}
              targetOrigin={{ horizontal: "right", vertical: "top" }}
              onChange={this.props.handleAdditionalDataDisplay}>
              <MenuItem
                children={<Typography variant="subtitle1">Export</Typography>}
                disabled
              />
              <MenuItem
                value={0}
                children="Download Data Insights"
                leftIcon={<DownloadIcon />}
                disabled={
                  this.state.selectedIndex != 0 ||
                  (Object.keys(this.props.db.revenue).length == 0 &&
                    Object.keys(this.props.db.actMoney).length == 0)
                }
                onClick={() => {
                  exportAnalytics(
                    firebase.auth().currentUser,
                    trData,
                    "CustomerP&L",
                    this.getRowOrder(trData),
                    "export_finance_analytics",
                    this.state.pivot
                  );
                  this.props.openClose.setAppModal(
                    "Export In Progress",
                    <div className="centering">
                      Your export is being prepared and will be emailed to you
                      shortly.
                    </div>,

                    <div className="centering">
                      <Button
                        label="Ok"
                        onClick={() => {
                          this.props.openClose.closeAppModal();
                        }}
                      />
                    </div>
                  );
                }}
              />
              <MenuItem
                value={1}
                children="Set as Default View"
                leftIcon={<SaveIcon />}
                onClick={this.saveToDatabase}
              />
            </IconMenu>
          </ToolbarGroup>
        </Toolbar>
        {this.state.hideOptions && <br />}
        <div style={{ height: $(window).height() - 130, overflow: "auto" }}>
          <div
            className={classNames("analytics-options", {
              hidden: this.state.hideOptions
            })}
            style={{ padding: 16 }}>
            <Stack>
              <Subheader>Filter Inputs</Subheader>
              <div>
                <IconButton
                  color="tonal"
                  isBackgroundColor
                  tooltip="Clear Filters"
                  onClick={this.handleResetFields}
                  size="large">
                  <RefreshIcon />
                </IconButton>
              </div>
            </Stack>
            <div style={{ fontFamily: "PT Sans" }}>
              Selecting a specific customer group breaks it down into individual
              customers
            </div>
            <br />
            <div className="row">
              <div className="col-lg-3">
                <Card title="Date">
                  <div className="rowC">
                    <SelectField
                      floatingLabelText="Year"
                      value={this.state.year}
                      onChange={(event, index, value) => {
                        this.setState({ year: value });
                      }}
                      fullWidth
                      style={styles.input}>
                      {this.state.yearsJSX}
                    </SelectField>
                    <SelectField
                      floatingLabelText="Date Source"
                      value={this.state.dateSource}
                      onChange={(event, index, value) => {
                        this.setState({ dateSource: value });
                      }}
                      fullWidth
                      style={styles.input}>
                      <MenuItem value="spend" children="Spend" key={0} />
                      <MenuItem
                        value="promotion"
                        children="Promotion"
                        key={1}
                      />
                    </SelectField>
                  </div>
                </Card>
              </div>
              <div className="col-lg-2">
                <Card title="Accounts">
                  <div className="rowC">
                    <SelectField
                      floatingLabelText="Accounts"
                      value={this.state.accounts}
                      multiple
                      onChange={this.onAccountChange}
                      fullWidth
                      style={styles.input}>
                      <MenuItem
                        checked={this.state.checkedAll}
                        value="Select All"
                        children="Select All"
                      />
                      {this.getAccountsJSX()}
                    </SelectField>
                  </div>
                </Card>
              </div>
              <div className="col-lg-2">
                <Card title="View By">
                  <SelectField
                    floatingLabelText="View By"
                    value={this.state.groupBy}
                    onChange={(event, index, value) => {
                      this.setState({ groupBy: value, pnlGroup: 0 });
                    }}
                    fullWidth
                    style={styles.input}>
                    <MenuItem
                      value="pnlGroup"
                      children="All Customer Groups (Retail)"
                      key="wildcard"
                    />
                    <MenuItem
                      value="distributor"
                      children="Distributor"
                      key="Distributor"
                    />
                    <MenuItem value="class" children="Class" key="Class" />
                    <MenuItem
                      value="channel"
                      children="Channel"
                      key="Channel"
                    />
                    <MenuItem
                      value="all"
                      children="All (Retail + Distributors)"
                      key="all"
                    />
                  </SelectField>
                </Card>
              </div>
              <div className="col-lg-3">
                <Card title="Customer Group">
                  <SelectField
                    floatingLabelText="Customer Group"
                    value={this.state.pnlGroup}
                    onChange={(event, index, value) => {
                      this.setState({ pnlGroup: value });
                    }}
                    fullWidth
                    disabled={this.state.groupBy != "pnlGroup"}
                    style={styles.input}>
                    <MenuItem value={0} children="All Groups" key="wildcard" />
                    {this.props.db.companyUsers &&
                      this.props.db.customerGroups &&
                      Object.keys(this.props.db.customerGroups)
                        .sort()
                        .map(cg => {
                          return <MenuItem value={cg} children={cg} key={cg} />;
                        })}
                  </SelectField>
                </Card>
              </div>
              <div className="col-lg-2">
                <Card title="Data Sources">
                  <SelectField
                    floatingLabelText="Data Source"
                    value={this.state.source}
                    onChange={(event, index, value) => {
                      if (
                        value == [] ||
                        (value.includes(0) && !this.state.source.includes(0))
                      )
                        value = [0];
                      if (value.length > 1) {
                        value = value.filter(x => x);
                      }
                      this.setState({ source: value });
                    }}
                    fullWidth
                    style={styles.input}
                    multiple>
                    <MenuItem value={0} children="All Sources" key="wildcard" />
                    {this.state.sourcesJSX}
                  </SelectField>
                </Card>
              </div>
            </div>
          </div>
          <Divider />
          <div className="centering">
            <div
              className="options-toggle-button"
              onClick={() => {
                this.setState({ hideOptions: !this.state.hideOptions });
              }}>
              {this.state.hideOptions ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowUpIcon />
              )}
            </div>
          </div>
          <br />
          <div style={{ padding: 16 }}>
            <Paper elevation={2}>
              <BottomNavigation value={this.state.selectedIndex} showLabels>
                <BottomNavigationItem
                  label="Customer P&L"
                  icon={<ListIcon />}
                  value={0}
                  onClick={() => this.setState({ selectedIndex: 0 })}
                />
                <BottomNavigationItem
                  label="Finance Plots"
                  icon={<EqualizerIcon />}
                  value={1}
                  onClick={() => this.setState({ selectedIndex: 1 })}
                />
              </BottomNavigation>
            </Paper>
            <br />
            {this.state.selectedIndex == 0 ? (
              <div>
                <MoneyTableExpandable
                  data={trData}
                  mode={this.state.mode}
                  time={this.state.time}
                  dateHeader="Customer"
                  header="Data by Customer"
                  pivot="customer"
                  metrics={["revenue", "spend", "tradeRate"]}
                  moneyMetrics={["Revenue", "Spend"]}
                  db={this.props.db}
                  updateData={this.updateData}
                  isMoney={["spend", "revenue"].indexOf(this.state.mode) != -1}
                  customFooter={
                    this.state.mode == "tradeRate" ? this.trFooter : null
                  } // if viewing tradeRate, footer is not simple sum
                />
                <CommentsSection db={this.props.db} />
              </div>
            ) : (
              <FinancePlots
                revList={revList}
                db={this.props.db}
                pnlGroup={this.state.pnlGroup}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default CustomerPnl;
