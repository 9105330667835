// eslint-disable-next-line max-classes-per-file
import React from "react";

import Typography from "@mui/material/Typography";
import { Divider } from "pui-react-dividers";
import { CSVLink } from "react-csv";
import SettingsIcon from "@mui/icons-material/Settings";
import DownloadIcon from "@mui/icons-material/GetApp";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import Button from "ui-library/Button";
import Select from "ui-library/Select";
import MenuItem from "ui-library/MenuItem";
import TextField from "ui-library/TextField";
import IconMenu from "ui-library/IconMenu";
import IconButton from "ui-library/IconButton";
import { arrayify, escapeQuotes } from "helpers/DataProcessing";
import { firebase, removeCompanyFromUser } from "helpers/Firebase";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Stack } from "@mui/material";
import DataTable from "../../tables/MuiDataTable";

const styles = {
  settingsButton: {
    float: "right",
    marginTop: "-36px"
  }
};

class PropDataUpdatedCSVLink extends CSVLink {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data, headers, separator, uFEFF } = nextProps;
    this.setState({ href: this.buildURI(data, uFEFF, headers, separator) });
  }
}

class AddCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newCompany: 0,
      newUserAccess: "",
      permissions: []
    };
  }

  handleAddCompany = (email, access, company, cid) => {
    const emailHash = email.replace(/\W/g, "");
    const validEmailRef = firebase.database().ref(`validEmails/${emailHash}`);
    if (this.state.newCompany === 0) {
      this.props.openClose.showSnack("Please enter a company");
      this.props.openClose.closeAppModal();
      return;
    }
    validEmailRef.once("value", snapshot => {
      if (snapshot.val()) {
        const validEmail = snapshot.val();
        if (arrayify(validEmail.company).includes(company)) {
          this.props.openClose.showSnack("User already has access to company");
          this.props.openClose.closeAppModal();
          return;
        }
        const validEmailUpdate = {
          access: arrayify(validEmail.access).concat(access),
          company: arrayify(validEmail.company).concat(company),
          cid: arrayify(validEmail.cid).concat(cid),
          email: validEmail.email,
          name: validEmail.name,
          selectedCompany: validEmail.selectedCompany
            ? validEmail.selectedCompany
            : 0
        };
        validEmailRef.update(validEmailUpdate);
        this.props.openClose.closeAppModal();
      }
    });
  };

  handlePermissionsClick = () => {
    if (this.state.permissions.length === 0) {
      this.props.openClose.showSnack("Please select company first");
    }
  };

  render() {
    return (
      <DataTable
        title="Add Company Access to User"
        data={{
          Email: [
            <TextField
              id="newUserEmail"
              value={this.props.email}
              disabled
              style={{ marginRight: "16px" }}
            />
          ],
          Company: [
            <Select
              value={this.state.newCompany}
              onChange={(event, index, value) => {
                this.setState({ newCompany: value });
                if (value === 0) {
                  this.setState({ permissions: [] });
                } else {
                  firebase
                    .database()
                    .ref(
                      `companies/${
                        Object.keys(this.props.companyNames)[value - 1]
                      }/meta/permissions`
                    )
                    .once("value", snapshot => {
                      if (snapshot.val()) {
                        this.setState({ permissions: snapshot.val() });
                      }
                    });
                }
              }}>
              <MenuItem value={0} children="Select Company" key="wildcard" />
              {Object.values(this.props.companyNames).map(
                (companyName, index) => (
                  <MenuItem
                    value={index + 1}
                    children={companyName}
                    key={index + 1}
                  />
                )
              )}
            </Select>
          ],
          Permissions: [
            <Select
              value={this.state.newUserAccess}
              onClick={this.handlePermissionsClick.bind(null)}
              onChange={(event, index, value) => {
                this.setState({ newUserAccess: value });
              }}>
              {Object.keys(this.state.permissions).map(level => {
                return <MenuItem value={level} children={level} key={level} />;
              })}
            </Select>
          ],
          Add: [
            <Button
              label="Add Company"
              icon={<AddIcon />}
              onClick={this.handleAddCompany.bind(
                null,
                this.props.email,
                this.state.newUserAccess,
                Object.values(this.props.companyNames)[
                  this.state.newCompany - 1
                ],
                Object.keys(this.props.companyNames)[this.state.newCompany - 1]
              )}
            />
          ]
        }}
      />
    );
  }
}

class ManageUsers extends React.Component {
  constructor(props) {
    super(props);
    this.csvLink = React.createRef();
    this.state = {
      companyAssignments: {},
      selectedCompanyMeta: {},
      downloadData: [],
      headers: [
        { label: "Name", key: "name" },
        { label: "Email", key: "email" },
        { label: "Company", key: "company" },
        { label: "Company ID", key: "cid" },
        { label: "Access", key: "access" }
      ]
    };
  }

  handleNewCompanyAddJSX = email => {
    firebase
      .database()
      .ref("companyNames")
      .on("value", snapshot => {
        if (snapshot.val()) {
          const companyNames = snapshot.val();
          this.props.openClose.setAppModal({
            title: "Add New Company",
            content: (
              <AddCompany
                email={email}
                companyNames={companyNames}
                openClose={this.props.openClose}
              />
            ),
            isFullWidth: true,
            maxWidth: "lg"
          });
        }
      });
  };

  getCompanyAssignments = () => {
    firebase
      .database()
      .ref("validEmails")
      .on("value", snapshot => {
        if (snapshot.val()) {
          const validEmails = snapshot.val();
          const companyAssignments = {};
          for (const [key, val] of Object.entries(validEmails)) {
            let { email, company, cid, access, name, referrer } = val;
            company = arrayify(company);
            cid = arrayify(cid);
            access = arrayify(access);
            companyAssignments[email] = {
              company,
              cid,
              access,
              name,
              referrer
            };
          }
          this.setState({ companyAssignments });
        }
      });
  };

  handleDeleteCompany = (email, companyId) => {
    const doneCallback = deletedField => {
      this.props.openClose.showSnack(`${deletedField} deleted`);
      this.props.openClose.closeAppModal();
    };
    removeCompanyFromUser(
      email,
      companyId,
      doneCallback,
      (deletedField, executeDelete) => {
        this.props.openClose.setAppModal(
          `Remove ${deletedField}`,
          <div className="centering">
            {`Are you sure you want to remove this ${deletedField.toLowerCase()}?`}
          </div>,
          <Stack>
            <Button
              label="Yes, I'm sure"
              variant="text"
              color="error"
              onClick={executeDelete}
            />
            <Button
              label="No, go back"
              variant="text"
              onClick={this.props.openClose.closeAppModal}
            />
          </Stack>
        );
      }
    );
  };

  getDownloadData = () => {
    const downloadData = [];
    for (const [email, validEmailObj] of Object.entries(
      this.state.companyAssignments
    )) {
      for (let i = 0; i < validEmailObj.company.length; i++) {
        downloadData.push({
          email,
          name: validEmailObj.name,
          company: validEmailObj.company[i],
          cid: validEmailObj.cid[i],
          access: validEmailObj.access[i],
          referrer: validEmailObj.referrer
        });
      }
    }
    this.setState({ downloadData: downloadData.map(escapeQuotes) }, () => {
      this.csvLink.current.link.click();
    });
  };

  getRowData = () => {
    const data = [];
    const assignedCompanies =
      Object.entries(this.state.companyAssignments) ?? [];
    const n = assignedCompanies.length;
    for (let i = 0; i < n; i++) {
      const email = assignedCompanies[i][0];
      const assignments = assignedCompanies[i][1];
      data.push({
        id: i,
        user: email,
        assignedCompanies: JSON.stringify({
          email,
          assignments
        }),
        addNew: email
      });
    }

    return data;
  };

  componentDidMount() {
    this.getCompanyAssignments();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getCompanyAssignments();
  }

  render() {
    const columns = [
      { field: "user", headerName: "User", flex: 0.25 },
      {
        field: "assignedCompanies",
        headerName: "Assigned Companies",
        sortable: false,
        flex: 0.7,
        renderCell: params => {
          const paramsJson = JSON.parse(params.value);
          const { email } = paramsJson;
          const { assignments } = paramsJson;
          return (
            <DataTable
              headless
              height={175}
              data={{
                Company: assignments.company.map(company => (
                  <TextField
                    id={company}
                    defaultValue={company}
                    disabled
                    style={{ marginRight: "16px" }}
                  />
                )),
                Permissions: assignments.access.map(access => (
                  <TextField
                    id={access}
                    defaultValue={access}
                    disabled
                    style={{ marginRight: "16px" }}
                  />
                )),
                Remove: assignments.cid.map(companyId => (
                  <Button
                    color="error"
                    variant="outlined"
                    label={<DeleteIcon />}
                    onClick={this.handleDeleteCompany.bind(
                      null,
                      email,
                      companyId
                    )}
                  />
                ))
              }}
            />
          );
        }
      },
      {
        field: "addNew",
        headerName: "Add New",
        sortable: false,
        flex: 0.18,
        renderCell: params => (
          <Button
            label="Company"
            icon={<AddIcon />}
            onClick={this.handleNewCompanyAddJSX.bind(null, params.value)}
          />
        )
      },
      { field: "referrer", headerName: "Referrer", flex: 0.15 }
    ];
    const rows = this.getRowData();

    return (
      <div>
        <Typography
          sx={{
            fontFamily: "Ubuntu",
            marginTop: 2,
            padding: 0,
            color: "primary.main"
          }}
          variant="h4">
          Manage All Users
        </Typography>
        <div style={styles.settingsButton}>
          <IconMenu
            iconButtonElement={
              <IconButton tooltip="Settings" size="large">
                <SettingsIcon />
              </IconButton>
            }>
            <MenuItem
              children={<Typography variant="subtitle1">Export</Typography>}
              disabled
            />
            <MenuItem
              value={0}
              children="Download User Login Metadata"
              leftIcon={<DownloadIcon />}
              onClick={this.getDownloadData}
            />
          </IconMenu>
        </div>
        <Divider />
        <div className="rowC">
          <Typography variant="h5" sx={{ fontSize: 20, color: "primary.main" }}>
            Assign Companies to Users
          </Typography>
        </div>
        <div style={{ height: 1875, margin: 10 }}>
          <DataGridPro
            disableSelectionOnClick
            rowHeight={175}
            rows={rows}
            columns={columns}
            pageSize={10}
          />
        </div>
        <PropDataUpdatedCSVLink
          data={this.state.downloadData}
          headers={this.state.headers}
          filename="All Users Metadata Export.csv"
          className="hidden"
          ref={this.csvLink}
          target="_blank"
        />
      </div>
    );
  }
}

export default ManageUsers;
