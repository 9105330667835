import {
  gridColumnVisibilityModelSelector,
  GridEvents
} from "@mui/x-data-grid-pro";
import { useEffect, useMemo, useRef } from "react";

export const useKeepGroupingColumnsHidden = (
  apiRef,
  columns,
  initialModel,
  leafField
) => {
  const prevModel = useRef(initialModel);
  useEffect(() => {
    apiRef.current.subscribeEvent?.(
      GridEvents.rowGroupingModelChange,
      newModel => {
        const columnVisibilityModel = {
          ...gridColumnVisibilityModelSelector(apiRef)
        };

        newModel.forEach(field => {
          if (!prevModel.current.includes(field)) {
            columnVisibilityModel[field] = false;
          }
        });
        prevModel.current.forEach(field => {
          if (!newModel.includes(field)) {
            columnVisibilityModel[field] = true;
          }
        });
        apiRef.current.setColumnVisibilityModel(columnVisibilityModel);
        prevModel.current = newModel;
      }
    );
  }, [apiRef]);

  return useMemo(
    () =>
      columns.map(colDef =>
        initialModel.includes(colDef.field) ||
        (leafField && colDef.field === leafField)
          ? { ...colDef, hide: true }
          : colDef
      ),
    [columns, initialModel, leafField]
  );
};
