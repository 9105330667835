import Typography from "@mui/material/Typography";
import { Divider } from "pui-react-dividers";
import React from "react";

import SettingsIcon from "@mui/icons-material/Settings";
import DownloadIcon from "@mui/icons-material/GetApp";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import ReorderIcon from "@mui/icons-material/Reorder";
import Dialog from "ui-library/Dialog";
import Button from "ui-library/Button";
import IconButton from "ui-library/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "ui-library/MenuItem";
import Select from "ui-library/Select";
import IconMenu from "ui-library/IconMenu";

import { getTimesJSX, getYearWeeks } from "helpers/Time";
import { seasonalityTable } from "helpers/DataProcessing";
import { exportFiles } from "helpers/Export";
import { firebase, getFirebase, batchUpdateFirebase } from "helpers/Firebase";
import { grey } from "@mui/material/colors";
import { Stack } from "@mui/material";
import SeasonalityTable from "./SeasonalityTable";
import SeasonalityBuckets from "./SeasonalityBuckets";

const grey200 = grey["200"];

const styles = {
  modalTitle: {
    borderStyle: "solid",
    borderWidth: 0,
    borderBottomWidth: 2,
    borderBottomColor: grey200
  },
  modalActions: {
    borderStyle: "solid",
    borderWidth: 0,
    borderTopWidth: 2,
    borderTopColor: grey200
  },
  dialogRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 0,
    paddingBottom: 10
  },
  dialogContent: {
    position: "relative",
    minWidth: "95vw",
    maxWidth: "95vw",
    transform: ""
  },
  dialogBody: {
    paddingBottom: 0,
    minHeight: 650
  },
  superheader: {
    fontFamily: "Ubuntu",
    marginTop: 2,
    padding: 0,
    color: "primary.main"
  },
  settingsButton: {
    position: "relative",
    float: "right",
    marginTop: "-12px"
  }
};

class Seasonality extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: {},
      currentlySelected: "",
      showSearch: false,
      searchQuery: "",
      openFilePicker: false,
      productGroupsJSX: [],
      productGroup: 0,
      modalOpen: false,
      selectedSeasonalityBucket: 0,
      year: new Date().getFullYear(),
      seasonality: {}
    };
  }

  openFilePicker = jsx => {
    this.setState({
      openFilePicker: true
    });
    this.props.showDropDownWindow(jsx);
  };

  closeFilePicker = () => {
    this.setState({
      openFilePicker: false
    });
  };

  handleSearchButton = () => {
    this.setState({
      showSearch: true
    });
  };

  handleSearch = event => {
    this.setState({
      searchQuery: event.target.value.toLowerCase()
    });
  };

  filterData = dataList => {
    const filteredList = [];
    for (let i = 0; i < dataList.length; i++) {
      const d = dataList[i];
      if (
        !this.state.searchQuery ||
        d.bucket.toLowerCase().indexOf(this.state.searchQuery) != -1
      ) {
        filteredList.push(d);
      }
    }
    return filteredList;
  };

  prettyPrint = list => {
    let result = "";
    for (let i = 0; i < list.length; i++) {
      if (i != 0) {
        result += ", ";
      }
      result += list[i];
    }
    return result;
  };

  openSeasonalityBucketSelection = () => {
    this.props.openClose.setAppModal(
      "Create Seasonality Buckets",
      <SeasonalityBuckets
        db={this.props.db}
        openClose={this.props.openClose}
      />,
      null,
      false,
      false,
      "lg"
    );
  };

  copyPreviousYearSeasonality = () => {
    const prevYearWeeks = getYearWeeks(this.state.year - 1);
    const currentYearWeeks = getYearWeeks(this.state.year);
    const prevYearSeasonality = {};
    for (var bucket in this.state.seasonality) {
      prevYearSeasonality[bucket] = Object.keys(this.state.seasonality[bucket])
        .filter(key => prevYearWeeks.includes(key))
        .reduce((obj, key) => {
          obj[key] = this.state.seasonality[bucket][key];
          return obj;
        }, {});
    }
    const currentYearSeasonality = {};
    for (var bucket in this.state.seasonality) {
      currentYearSeasonality[bucket] = {};
      for (let weekInd = 0; weekInd < currentYearWeeks.length; weekInd++) {
        if (weekInd < prevYearWeeks.length) {
          currentYearSeasonality[bucket][currentYearWeeks[weekInd]] =
            prevYearSeasonality[bucket][prevYearWeeks[weekInd]];
        } else {
          currentYearSeasonality[bucket][currentYearWeeks[weekInd]] =
            prevYearSeasonality[bucket][prevYearWeeks.slice(-1)];
        }
      }
    }
    // rewrite above into form that we can use to update the database
    const update = {};
    for (var bucket in currentYearSeasonality) {
      for (const week in currentYearSeasonality[bucket]) {
        if (currentYearSeasonality[bucket][week]) {
          update[`${bucket}/${week}/seasonality_index`] =
            currentYearSeasonality[bucket][week].seasonality_index;
        }
      }
    }
    batchUpdateFirebase(21, update);
  };

  componentDidMount() {
    getFirebase(21, seasonality => {
      this.setState({ seasonality });
    });
  }

  render() {
    const [monthsJSX, yearsJSX] = getTimesJSX(this.props.db.meta, true);
    const bucketNames = this.props.db.meta.seasonality_bucket_names || [];
    const seasonalityTableData = this.filterData(
      seasonalityTable(
        this.state.seasonality,
        bucketNames,
        this.state.selectedSeasonalityBucket
      )
    );
    const seasonalityBuckets = this.props.db.meta.seasonality_buckets || {};
    const user = firebase.auth().currentUser;
    return (
      <div>
        <div
          style={{
            paddingRight: 30,
            paddingLeft: 30,
            height: $(window).height() - 130,
            overflow: "auto"
          }}>
          <div>
            <Typography sx={styles.superheader} variant="h4">
              Manage Seasonality (%)
              <div style={styles.settingsButton} className="rowC">
                <IconButton
                  tooltip="Seasonality Buckets"
                  onClick={this.openSeasonalityBucketSelection}
                  style={{ marginTop: 15, color: "black" }}
                  size="large">
                  <ReorderIcon />
                </IconButton>
                <IconMenu
                  iconButtonElement={
                    <IconButton tooltip="Settings" size="large">
                      <SettingsIcon />
                    </IconButton>
                  }
                  anchorOrigin={{ horizontal: "right", vertical: "top" }}
                  targetOrigin={{ horizontal: "right", vertical: "top" }}
                  onChange={this.props.handleAdditionalDataDisplay}
                  style={{ marginTop: 15, color: "black" }}>
                  <MenuItem
                    value={0}
                    leftIcon={<DownloadIcon />}
                    onClick={() => {
                      exportFiles(user, this.props.db.companyid, "Seasonality");
                      this.props.openClose.setAppModal(
                        "Export In Progress",
                        <div className="centering">
                          Your export is being prepared and will be emailed to
                          you shortly.
                        </div>,

                        <div className="centering">
                          <Button
                            label="Ok"
                            onClick={() => {
                              this.props.openClose.closeAppModal();
                            }}
                          />
                        </div>
                      );
                    }}>
                    Download Seasonality
                  </MenuItem>
                  <MenuItem
                    value={1}
                    onClick={() => {
                      this.props.openClose.setAppModal(
                        "Confirm",
                        <div className="centering">
                          {`Please confirm that you wish to copy seasonality indices from ${
                            this.state.year - 1
                          } to ${this.state.year}. 
                              This will overwrite all entered values.`}
                        </div>,
                        <Stack>
                          <Button
                            label="Ok"
                            variant="text"
                            onClick={() => {
                              this.copyPreviousYearSeasonality();
                              this.props.openClose.closeAppModal();
                            }}
                          />
                          <Button
                            label="Cancel"
                            color="error"
                            variant="text"
                            onClick={() => {
                              this.props.openClose.closeAppModal();
                            }}
                          />
                        </Stack>
                      );
                    }}
                    leftIcon={<FileCopyIcon />}>
                    Copy Previous Year Seasonality
                  </MenuItem>
                </IconMenu>
              </div>
            </Typography>
            <Divider />
            <div className="rowC" style={{ paddingTop: 20 }}>
              <Button
                label={
                  <Tooltip title="Expand Seasonality Table">
                    <OpenWithIcon />
                  </Tooltip>
                }
                onClick={() => this.setState({ modalOpen: true })}
              />
              <Select
                value={0}
                onChange={(event, index, value) => {
                  this.setState({ year: value });
                }}
                value={this.state.year}
                style={{ marginTop: -10, marginLeft: 20 }}
                floatingLabelText="Select Year">
                <MenuItem value={0} key="wildcard">
                  Select
                </MenuItem>
                {yearsJSX}
              </Select>
            </div>
            <SeasonalityTable
              style={{ height: 500, overflow: "auto" }}
              data={seasonalityTableData}
              db={this.props.db}
              readOnly={this.props.readOnly}
              openClose={this.props.openClose}
              year={this.state.year}
            />
            <br />
            <br />
          </div>
        </div>
        <Dialog
          dialogTitle="Seasonality Table"
          open={this.state.modalOpen}
          onClose={() => this.setState({ modalOpen: false })}
          fullWidth
          maxWidth="lg"
          style={styles.dialogRoot}>
          <div
            style={{
              maxHeight: 650,
              display: "flex",
              flexDirection: "column"
            }}>
            <SeasonalityTable
              style={{ overflow: "auto" }}
              data={seasonalityTableData}
              db={this.props.db}
              openClose={this.props.openClose}
              year={this.state.year}
            />
          </div>
        </Dialog>
      </div>
    );
  }
}

export default Seasonality;
