import React, { useState, useEffect } from "react";

import { AVFView, GranularityOptions } from "./types";

import "./forecast.css";

import Select from "ui-library/Select";
import { Stack, MenuItem } from "@mui/material";
import Card from "ui-library/Card";
import { AVFTable } from "./AVFTable";
import { AVFGraph } from "./AVFGraph";

export const ActualsVsForecast = ({
  avfData,
  tableStart,
  tableEnd,
  loading
}: {
  avfData: AVFView;
  tableStart: string;
  tableEnd: string;
  loading: boolean;
}) => {
  const setTimeFilterCallback = (startWeek, endWeek) => {
    setTimeFilter([startWeek, endWeek]);
  };

  const [timeFilter, setTimeFilter] = useState<[number, number]>([
    0,
    avfData.actuals_values.length - 1
  ]);
  // the time filter should change when the avfData changes
  useEffect(
    () => setTimeFilter([0, avfData.actuals_values.length - 1]),
    [avfData]
  );

  const [granularity, setGranularity] = useState(GranularityOptions.week);
  const startYear = new Date(Date.parse(tableStart)).getFullYear();

  const getGraphHeader = () => {
    // TODO If we need a Smoothing filter, uncomment below
    return (
      <Stack justifyContent="space-between">
        <div>Comparison</div>
        {/* <div>
          <Select
            floatingLabelText="Forecast Smoothing"
            size="small"
            value={1}
            onChange={() => {}}
            fullWidth
            style={{ position: "relative", width: "100%" }}>
            <MenuItem value={1} key={1} children="No Smoothing" />
            <MenuItem value={13} key={13} children="13 Weeks Rolling Average" />
            <MenuItem value={26} key={26} children="26 Weeks Rolling Average" />
            <MenuItem value={52} key={52} children="52 Weeks Rolling Average" />
          </Select>
        </div> */}
      </Stack>
    );
  };

  const getComparisionTableHeader = granularity => {
    return (
      <Stack justifyContent="space-between">
        <div>Actuals vs. Forecast by Time Series</div>
        <div>
          <Select
            floatingLabelText="Granularity"
            size="small"
            value={granularity}
            onChange={event =>
              setGranularity(event.target.value as GranularityOptions)
            }
            fullWidth
            style={{ position: "relative", width: "100%" }}>
            <MenuItem
              value={GranularityOptions.week}
              key="week"
              children="Week"
            />
            <MenuItem
              value={GranularityOptions.month}
              key="month"
              children="Month"
            />
            <MenuItem
              value={GranularityOptions.quarter}
              key="quarter"
              children="Quarter"
            />
            <MenuItem
              value={GranularityOptions.year}
              key="year"
              children="Year"
            />
          </Select>
        </div>
      </Stack>
    );
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <div style={{ width: "45%", padding: "10px" }}>
          <Card title={getComparisionTableHeader(granularity)}>
            <AVFTable
              avfData={avfData}
              tableStart={tableStart}
              tableEnd={tableEnd}
              loading={loading}
              granularity={granularity}
              timeFilter={timeFilter}
            />
          </Card>
        </div>
        <div style={{ padding: "10px" }}>
          <Card title={getGraphHeader()}>
            <AVFGraph
              data={avfData}
              tableStart={tableStart}
              tableEnd={tableEnd}
              loading={loading}
              onZoom={setTimeFilterCallback}
            />
          </Card>
        </div>
      </div>
    </>
  );
};
