// eslint-disable-next-line max-classes-per-file
import React from "react";

import Typography from "@mui/material/Typography";
import { Toolbar, ToolbarTitle, ToolbarSeparator } from "ui-library/Toolbar";
import ToolbarGroup from "ui-library/ToolbarGroup";
import Button from "ui-library/Button";
import IconButton from "ui-library/IconButton";
import IconMenu from "ui-library/IconMenu";
import MenuItem from "ui-library/MenuItem";
import TextField from "ui-library/TextField";
import AddBoxIcon from "@mui/icons-material/AddBox";
import GetAppIcon from "@mui/icons-material/GetApp";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import PublishIcon from "@mui/icons-material/Publish";
import { CSVLink } from "react-csv";

import { removeFirebase, updateMetaFirebase, firebase } from "helpers/Firebase";
import { sortByField } from "helpers/sortByDate";
import { escapeQuotes } from "helpers/DataProcessing";
import { formatDependencies } from "helpers/Errors";
import { amber, common } from "@mui/material/colors";
import { Stack } from "@mui/material";
import PaginationMUITable from "../tables/PaginationMUITable";
import NewProduct from "./NewProduct";
import ProductProfile from "./ProductProfile";
import UploadFiles from "../UploadFiles/UploadFiles";

const amber500 = amber["500"];
const amber700 = amber["700"];
const { white } = common;
const { black } = common;

class PropDataUpdatedCSVLink extends CSVLink {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data, headers, separator, uFEFF } = nextProps;
    this.setState({ href: this.buildURI(data, uFEFF, headers, separator) });
  }
}

class Products extends React.PureComponent {
  constructor(props) {
    super(props);
    this.csvLink = React.createRef();
    this.state = {
      products: {},
      downloadData: [],
      headers: [
        { label: "Name", key: "name" },
        { label: "Size", key: "size" },
        { label: "Units Per Case", key: "unitsPerCase" },
        { label: "Product Groups(s)", key: "productGroupsField" },
        { label: "Associated Codes", key: "codesField" }
      ],
      currentlySelected: "",
      showSearch: false,
      searchQuery: "",
      openFilePicker: false,
      productGroupsJSX: [],
      productGroup: 0,
      productGroups: {},
      modalOpen: false,
      // https://github.com/mui-org/material-ui/issues/6496
      tableBodyRenderKey: 0
    };
  }

  openFilePicker = jsx => {
    this.setState({
      openFilePicker: true
    });
    this.props.showDropDownWindow(jsx);
  };

  closeFilePicker = () => {
    this.setState({
      openFilePicker: false
    });
  };

  addProduct = () => {
    this.props.openClose.setAppModal(
      "Add Product",
      <NewProduct openClose={this.props.openClose} db={this.props.db} />,
      null,
      false,
      true,
      "md"
    );
  };

  deleteProduct = (event, rowData) => {
    const productKey = rowData.key;
    this.props.openClose.setAppModal(
      "Delete Product Item",
      <div>Are you sure you want to delete this product item?</div>,

      <Stack>
        <Button
          label="Yes, I'm sure"
          variant="text"
          color="error"
          onClick={async () => {
            const dependencies = await this.getDependencies(productKey);
            if (dependencies.size === 1 && dependencies.has("forecast")) {
              this.props.openClose.closeAppModal();
              this.props.openClose.setAppModal(
                "Are you sure?",
                <div>
                  Product is used in forecast, are you sure you want to delete
                  this product? (If yes, please update forecast to see changes
                  reflected.)
                </div>,
                <Stack>
                  <Button
                    label="Yes, I'm sure."
                    variant="text"
                    color="error"
                    onClick={() => {
                      this.props.openClose.closeAppModal();
                      removeFirebase(1, productKey);
                    }}
                  />
                  <Button
                    label="No, go back"
                    variant="text"
                    onClick={() => {
                      this.props.openClose.closeAppModal();
                    }}
                  />
                </Stack>
              );
            } else if (dependencies.size) {
              const errorStr = formatDependencies("product", dependencies);
              this.props.openClose.closeAppModal();
              this.props.openClose.setAppModal(
                "Unable to delete product",
                <div>{errorStr}</div>,
                <div>
                  <Button
                    label="Okay"
                    onClick={() => {
                      this.props.openClose.closeAppModal();
                    }}
                  />
                </div>
              );
            } else {
              this.props.openClose.closeAppModal();
              removeFirebase(1, productKey);
            }
          }}
        />
        <Button
          label="No, go back"
          variant="text"
          onClick={() => {
            this.props.openClose.closeAppModal();
          }}
        />
      </Stack>
    );
  };

  getDependencies = async key => {
    const dependencies = new Set();

    // check forecast dependencies
    const firebaseForecast = firebase.app("forecast");
    const storesCustomerSnapshot = await firebase
      .database()
      .ref(
        `companies/${this.props.db.companyid}/forecast/stores_by_product/${key}`
      )
      .once("value");
    const velocityCustomerSnapshot = await firebase
      .database()
      .ref(
        `companies/${this.props.db.companyid}/forecast/velocity_by_product/${key}`
      )
      .once("value");
    if (storesCustomerSnapshot.val() || velocityCustomerSnapshot.val()) {
      dependencies.add("forecast");
    }
    for (const typeName of ["distributor", "non_distributor"]) {
      const refName1 = `/${typeName}_forecast_table_by_product/`;
      const refName2 = `/${typeName}_historical_forecast_by_product/`;
      const forecastSnapshot1 = await firebaseForecast
        .database()
        .ref(this.props.db.companyid + refName1 + key)
        .once("value");
      const forecastSnapshot2 = await firebaseForecast
        .database()
        .ref(this.props.db.companyid + refName2 + key)
        .once("value");
      if (forecastSnapshot1.val() || forecastSnapshot2.val()) {
        dependencies.add("forecast");
      }
    }

    // revenue
    for (var line in this.props.db.revenue) {
      if (key == this.props.db.revenue[line].product) {
        dependencies.add("revenue");
      }
    }

    // promotions (spend is accounted for here, since it is tied to promotions)
    const pgs = this.props.db.meta.product_groups || {};
    for (var line in this.props.db.allLines) {
      const pg = this.props.db.allLines[line].productGroup;
      var products = [];
      if (pg == "custom") {
        products = this.props.db.allLines[line].product;
      } else {
        products = pgs[pg].products;
      }
      if (products.includes(key)) {
        dependencies.add("promotions");
        dependencies.add("spend");
      }
    }

    // product groups
    for (var line in this.props.db.meta.product_groups) {
      var products = this.props.db.meta.product_groups[line].products || [];
      if (products.includes(key)) {
        dependencies.add("product groups");
      }
    }

    // pricing
    for (const customer in this.props.db.pricing) {
      if (Object.keys(this.props.db.pricing[customer]).includes(key)) {
        dependencies.add("pricing");
      }
    }

    const invoicesList = Object.values(this.props.db.invoices);
    if (
      invoicesList.some(invoice =>
        Object.values(invoice.invoiceLines || {}).some(
          invoiceLine => invoiceLine.productKey === key
        )
      )
    ) {
      dependencies.add("invoice lines");
    }

    return dependencies;
  };

  saveProductGroupChanges = () => {
    const { meta } = this.props.db;
    meta.product_groups = this.state.productGroups;
    this.setState({ saveStatus: false });
    updateMetaFirebase(meta);
  };

  handleSearchButton = () => {
    this.setState({
      showSearch: true
    });
  };

  handleSearch = event => {
    this.setState({
      searchQuery: event.target.value.toLowerCase(),
      currentlySelected: "",
      tableBodyRenderKey: this.state.tableBodyRenderKey + 1
    });
  };

  handleProfileOpen = (event, rowData) => {
    const productKey = rowData.key;
    this.props.showRightDrawer(
      <ProductProfile
        product={productKey}
        db={this.props.db}
        openClose={this.props.openClose}
      />
    );
  };

  getProductGroupsJSX = () => {
    const { meta } = this.props.db;
    const productGroupsJSX = [];
    for (const productGroup in meta.product_groups) {
      productGroupsJSX.push(
        <MenuItem
          value={productGroup}
          primaryText={meta.product_groups[productGroup].name}
          key={productGroup}
        />
      );
    }
    return productGroupsJSX;
  };

  prettyPrint = list => {
    let result = "";
    for (let i = 0; i < list.length; i++) {
      if (i != 0) {
        result += " | ";
      }
      result += list[i];
    }
    return result;
  };

  getProductList = () => {
    let productList = Object.entries(this.props.db.products).reduce(
      (acc, [productKey, product]) => {
        product.codesField = product.codes
          ? this.prettyPrint(product.codes)
          : "";
        product.productGroupsField = product?.productGroups
          ? this.prettyPrint(
              product.productGroups
                .filter(pgKey => this.props.db.meta.product_groups?.[pgKey])
                .map(pgKey => this.props.db.meta.product_groups[pgKey]?.name)
            )
          : "";

        if (
          (this.state.searchQuery === "" ||
            product.name.toLowerCase().indexOf(this.state.searchQuery) != -1 ||
            (product.codesField &&
              product.codesField
                .toLowerCase()
                .indexOf(this.state.searchQuery) != -1)) &&
          (!this.state.productGroup ||
            this.props.db.meta.product_groups[
              this.state.productGroup
            ].products.indexOf(key) != -1)
        ) {
          product.key = productKey;
          return [...acc, product];
        }
        return acc;
      },
      []
    );

    if (this.state.sortField) {
      productList = sortByField(
        this.state.sortField,
        productList,
        this.state.reverseSort
      );
    } else {
      productList = sortByField("name", productList, true);
    }

    return productList;
  };

  getDownloadData = () => {
    const productList = this.getProductList(); // csv formatting

    this.setState({ downloadData: productList.map(escapeQuotes) }, () => {
      this.csvLink.current.link.click();
    });
  };

  componentDidMount() {
    const productKey = this.props.match?.params?.key;
    const allProducts = this.props.db.products;
    if (productKey && productKey in allProducts) {
      this.handleProfileOpen(null, { key: productKey });
    }
  }

  render() {
    const productsJSX = this.getProductList();
    const actionParams = {
      openProfile: rowData => {
        this.handleProfileOpen;
      }
    };
    if (this.props.db.permissions.includes("admin")) {
      actionParams.delete = rowData => ({
        onClick: this.deleteProduct
      });
    }
    const columns = [
      {
        title: "Name",
        field: "name",
        width: 300,
        cellStyle: { paddingLeft: 50 },
        headerStyle: { paddingLeft: 50 }
      },
      { title: "Size", field: "size" },
      { title: "Units Per Case", field: "unitsPerCase" },
      {
        title: "Product Group(s)",
        field: "productGroupsField",
        cellStyle: {
          whiteSpace: "nowrap",
          overflow: "hidden",
          maxWidth: 500
        },
        multiple: true,
        unPrettify: true
      },
      {
        title: "Associated Codes",
        field: "codesField",
        cellStyle: {
          whiteSpace: "nowrap",
          overflow: "hidden",
          maxWidth: 500
        },
        multiple: true,
        unPrettify: true
      }
    ];

    return (
      <div>
        <Toolbar>
          <ToolbarGroup>
            <ToolbarTitle text="Products" style={{ color: black }} />
            <ToolbarSeparator />
            <IconButton
              onClick={this.handleSearchButton}
              tooltip="Search"
              size="large">
              <SearchIcon />
            </IconButton>
            {this.state.showSearch && (
              <div>
                <TextField
                  placeholder="Search..."
                  style={{ marginTop: 0 }}
                  onChange={this.handleSearch}
                />
              </div>
            )}
          </ToolbarGroup>

          <ToolbarGroup>
            <IconMenu
              iconButtonElement={
                <IconButton tooltip="Settings" size="large">
                  <SettingsIcon />
                </IconButton>
              }
              anchorOrigin={{ horizontal: "right", vertical: "top" }}
              targetOrigin={{ horizontal: "right", vertical: "top" }}
              onChange={this.props.handleAdditionalDataDisplay}>
              <MenuItem
                children={<Typography variant="subtitle1">Export</Typography>}
                disabled
              />
              <MenuItem
                value={0}
                children="Download Products"
                leftIcon={<GetAppIcon />}
                disabled={Object.keys(this.props.db.products).length == 0}
                onClick={this.getDownloadData}
              />
            </IconMenu>
            {this.props.db.permissions.includes("add") && !this.props.readOnly && (
              <IconButton
                onClick={this.addProduct}
                tooltip="Add Product"
                size="large">
                <AddBoxIcon />
              </IconButton>
            )}
            {this.props.db.permissions.includes("add") &&
              !this.props.readOnly && (
                <Button
                  label="Load from File"
                  onClick={this.openFilePicker.bind(
                    null,
                    <UploadFiles
                      selectedDataType="Products"
                      openClose={this.props.openClose}
                    />
                  )}
                  backgroundColor={amber500}
                  hoverColor={amber700}
                  style={{ color: white }}
                  icon={<PublishIcon color={white} />}
                />
              )}
          </ToolbarGroup>
        </Toolbar>
        <PaginationMUITable
          data={productsJSX}
          columns={columns}
          allLoaded
          handleProfileOpen={this.handleProfileOpen}
          actionParams={actionParams}
          selection={false}
        />
        <PropDataUpdatedCSVLink
          data={this.state.downloadData}
          headers={this.state.headers}
          filename="Product Export.csv"
          className="hidden"
          ref={this.csvLink}
          target="_blank"
        />
      </div>
    );
  }
}

export default Products;
