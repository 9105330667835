import { format } from "date-fns";
import { findIndex } from "lodash";
import { pivotTypes, aberrantPivotGroupTitles } from "./constants";

const makeNameFromRecord = (allRecords = {}, currentCollection = []) =>
  [...new Set(currentCollection)]
    .map(collectionId => allRecords?.[collectionId]?.name)
    .filter(Boolean)
    .sort()
    .join("|");

const getExportData = ({
  data,
  db: { allCustomers, allProductGroups, allContacts },
  combineEstimatesAndActuals
}) => {
  const pricingProductGroups = Object.entries(allProductGroups)
    .filter(([productGroupId, { isPricing }]) => Boolean(isPricing))
    .map(([productGroupId]) => productGroupId);

  const {
    [pivotTypes.PRODUCTS]: aberrantPGTitle,
    [pivotTypes.CONTACTS]: aberrantContactTitle
  } = aberrantPivotGroupTitles;

  const groupedData = [];
  data.forEach(record => {
    const {
      date,
      customer,
      productGroups,
      contacts,
      budgetedRevenue = 0,
      budgetedTradeSpend = 0,
      estimatedRevenue = 0,
      estimatedTradeSpend = 0,
      actualRevenue = 0,
      actualTradeSpend = 0,
      promotionClosed = false
    } = record;

    let adjustedEstimatedTradeSpend = estimatedTradeSpend;

    if (
      combineEstimatesAndActuals &&
      record.hasOwnProperty("actualTradeSpend") &&
      record.hasOwnProperty("estimatedTradeSpend")
    ) {
      // Replace trade spend expected with trade spend actual depending on the status of the promotion
      if (promotionClosed) {
        adjustedEstimatedTradeSpend = actualTradeSpend;
      } else {
        adjustedEstimatedTradeSpend = Math.max(
          actualTradeSpend,
          estimatedTradeSpend
        );
      }
    }

    // Force day to be the 1st
    date.setDate(1);
    const baseRecord = {
      date: format(date, "MMM-yyyy"),
      customer: makeNameFromRecord(allCustomers, [customer]),
      productGroups: productGroups.some(productGroupId =>
        pricingProductGroups.includes(productGroupId)
      )
        ? makeNameFromRecord(
            allProductGroups,
            productGroups.filter(productGroupId =>
              pricingProductGroups.includes(productGroupId)
            )
          )
        : aberrantPGTitle,
      contacts: contacts.length
        ? makeNameFromRecord(allContacts, contacts)
        : aberrantContactTitle
    };

    const existingRecordIndex = findIndex(groupedData, baseRecord);
    if (existingRecordIndex !== -1) {
      const {
        budgetedRevenue: existingBudgetedRevenue = 0,
        budgetedTradeSpend: existingBudgetedTradeSpend = 0,
        estimatedRevenue: existingEstimatedRevenue = 0,
        estimatedTradeSpend: existingEstimatedTradeSpend = 0,
        actualRevenue: existingActualRevenue = 0,
        actualTradeSpend: existingActualTradeSpend = 0
      } = groupedData[existingRecordIndex];
      const completeRecord = {
        ...baseRecord,
        dateObject: date,
        budgetedRevenue: existingBudgetedRevenue + budgetedRevenue,
        budgetedTradeSpend: existingBudgetedTradeSpend + budgetedTradeSpend,
        estimatedRevenue: existingEstimatedRevenue + estimatedRevenue,
        estimatedTradeSpend:
          existingEstimatedTradeSpend + adjustedEstimatedTradeSpend,
        actualRevenue: existingActualRevenue + actualRevenue,
        actualTradeSpend: existingActualTradeSpend + actualTradeSpend
      };
      groupedData[existingRecordIndex] = completeRecord;
    } else {
      const completeRecord = {
        ...baseRecord,
        dateObject: date,
        budgetedRevenue,
        budgetedTradeSpend,
        estimatedRevenue,
        estimatedTradeSpend: adjustedEstimatedTradeSpend,
        actualRevenue,
        actualTradeSpend
      };
      groupedData.push(completeRecord);
    }
  });

  // Replace revenue LE with actual revenue only if actual revenue exists (doing here)
  if (combineEstimatesAndActuals) {
    return groupedData.map(({ estimatedRevenue, actualRevenue, ...rest }) => ({
      ...rest,
      estimatedRevenue: actualRevenue || estimatedRevenue,
      actualRevenue
    }));
  }

  return groupedData;
};

export { getExportData };
