import React from "react";

import { useHistory } from "react-router-dom";
import Header from "./Header";
import SideBar from "./SideBar";

const SIDEBAR_CLOSED_STATE = {
  business: false,
  promotions: false,
  manage: false,
  commissions: false,
  insights: false
};

type HeaderSidebarContainerProps = {
  toggleSideBar: () => {};
  sideBar: boolean;
  pathname: string;
  // Refine it further
  childProps: Record<string, unknown>;
};

function HeaderSidebarContainer({
  toggleSideBar,
  sideBar,
  pathname,

  ...childProps
}: HeaderSidebarContainerProps) {
  const history = useHistory();
  const [toolTipOpen, setToolTipOpen] = React.useState(SIDEBAR_CLOSED_STATE);

  const handleTooltipClose = (itemName?: string) => {
    const obj = toolTipOpen;
    obj[itemName!] = false;

    setToolTipOpen(prev => {
      if (!itemName) {
        return {
          business: false,
          promotions: false,
          manage: false,
          commissions: false,
          insights: false
        };
      }

      const newState = { prev, ...obj };

      return newState;
    });
  };

  const handleTooltipOpen = item => {
    const itemName = item.name.toLowerCase();
    if (!item.subItems) {
      history.push(item.pathname);
    } else {
      const obj = toolTipOpen;
      obj[itemName] = true;
      setToolTipOpen(prev => {
        const newState = { prev, ...obj };
        return newState;
      });
    }
  };

  return (
    <>
      <Header
        toggleSideBar={toggleSideBar}
        sideBar={sideBar}
        handleTooltipClose={handleTooltipClose}
        {...childProps}
      />
      <SideBar
        open={sideBar}
        handleTooltipClose={handleTooltipClose}
        handleTooltipOpen={handleTooltipOpen}
        pathname={pathname}
        toolTipOpen={toolTipOpen}
        {...childProps}
      />
    </>
  );
}

export default HeaderSidebarContainer;
