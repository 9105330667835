import React, { useMemo, useCallback, useState } from "react";
import { Typography, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Cancel from "@mui/icons-material/Cancel";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { RootState } from "store";
import {
  ResolutionActivityTypeMap,
  ResolutionActivityTypes,
  ResolutionLineActivityObject
} from "components/Deductions/DeductionsReconciliation/types/resolutionLineTypes";
import { DisplayInvoiceObject } from "components/Deductions/DeductionsReconciliation/types/invoiceTypes";
import {
  ERPTransactionObject,
  InvoiceLineObject,
  InvoiceObject,
  ResolutionLineObject
} from "components/Deductions/models";
import { CompanyUser, Customer, FundType, Product } from "js/dbTypes";
import { DisplayERPTransactionObject } from "components/Deductions/DeductionsReconciliation/types/transactionTypes";
import {
  DisplayInvoiceLineObject,
  ResolveInvoiceLineObject
} from "components/Deductions/DeductionsReconciliation/types/invoiceLineTypes";
import CollapsibleTable, {
  ColumnConfigOptions
} from "ui-library/CollapsibleTable/CollapsibleTable";
import { displayUSCurrency } from "helpers/DataProcessing";
import {
  DRMEvent,
  DRMEventType
} from "components/Deductions/DeductionsReconciliation/ActivityLog/DRMEvent";
import { HumanReadableResolutionLineTypes } from "components/Deductions/DeductionsReconciliation/services/ResolutionLineServices/resolutionLineDisplay";
import { AddEventConfig } from "components/Deductions/DeductionsReconciliation/ActivityLog/DRMEventService";
import {
  InvoiceLineProcessingService,
  InvoiceProcessingService,
  TransactionProcessingService,
  ResolveMultipleInvoiceLinesErrorService,
  InvoiceLineDisplayService
} from "components/Deductions/DeductionsReconciliation/services";
import { useSnackbar } from "notistack";
import {
  CollapsibleTableSummaryType,
  SummaryConfigOptions
} from "ui-library/CollapsibleTable/CollapsibleTableSummary";
import { ResolutionLineActivityErrors } from "components/Deductions/DeductionsReconciliation/services/ResolveMultipleInvoiceLinesServices/resolveMultipleInvoiceLinesErrors";
import {
  toggleSelectInvoiceLine,
  setResolutionLineSorting,
  reset
} from "./redux/ResolveMultipleInvoiceLinesSlice";
import MultiEditCard from "./MultiEditCard";
import MultiEditScreenActivitiyAmount from "./MultiEditScreenActivityAmount";
import MultiEditScreenComment from "./MultiEditScreenComment";
import MultiEditScreenFundTypeField from "./MultiEditScreenFundTypeField";

interface MultiEditScreenProps {
  displayTransaction: DisplayERPTransactionObject;
  displayInvoice: DisplayInvoiceObject;
  onClose: () => void;
  addEvent: (
    config: AddEventConfig,
    tags?: Record<string, number[]> | undefined
  ) => Promise<Partial<DRMEvent>>;
  fundTypes: Record<string, FundType>;
  invoices: Record<string, InvoiceObject>;
  erpTransactions: Record<string, ERPTransactionObject>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  allLines: any;
  products: Record<string, Product>;
  customers: Record<string, Customer>;
  companyUsers: Record<string, CompanyUser>;
}

const MultiEditScreenContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  paddingLeft: 20,
  paddingRight: 20,
  paddingBottom: 20,
  backgroundColor: theme.palette.background.default
}));

const TableHeader = styled(Typography)(() => ({
  lineHeight: "20px",
  fontWeight: "bold",
  fontSize: "14px",
  marginBottom: "5px"
}));

const ButtonsContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginTop: 25
}));

const ScreenTitle = styled(Typography)(() => ({
  paddingTop: 30
}));

const ErrorContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column"
}));

interface InvoiceLineMap {
  displayInvoiceLine: DisplayInvoiceLineObject;
  resolutionLine: ResolutionLineObject;
}

export const ResolutionLinesErrorMessageMap: Record<
  keyof ResolutionLineActivityErrors,
  string
> = {
  amountLessThanOrEqualZero: "Must be a positive amount",
  missingFundTypeKey: "Select fund type",
  missingFundTypeAccount: "Select fund type",
  exceedsMaxInvoiceAmount: "Exceeds invoice open amount",
  exceedsMaxInvoiceLineAmount: "Exceeds invoice line open amount",
  exceedsMaxTransactionAmount: "Exceeds transaction open amount",
  missingComment: "Comment is required"
};

export default function MultiEditScreen(
  props: MultiEditScreenProps
): JSX.Element {
  const {
    displayTransaction,
    onClose,
    displayInvoice,
    invoices,
    erpTransactions,
    fundTypes,
    allLines,
    products,
    customers,
    addEvent,
    companyUsers
  } = props;
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { selectedInvoiceLines, resolutionLines, sortState, activityType } =
    useSelector((state: RootState) => {
      return state.resolveMultipleInvoiceLines;
    }, shallowEqual);
  const currentActivityType =
    HumanReadableResolutionLineTypes[ResolutionActivityTypeMap[activityType]];
  const [showErrors, setShowErrors] = useState<boolean>(false);

  const selectedInvoiceLinesList: ResolveInvoiceLineObject[] = useMemo(() => {
    const selectedInvoiceLinesByInvoice = Object.values(
      selectedInvoiceLines[displayInvoice.key] ?? {}
    );
    return selectedInvoiceLinesByInvoice
      .map(invoiceLine => {
        const editInvoiceLineObj =
          InvoiceLineDisplayService.processInvoiceLineForDisplay(
            displayInvoice,
            invoiceLine,
            invoiceLine.invoiceLineKey || invoiceLine.key,
            fundTypes,
            invoices,
            erpTransactions,
            allLines,
            products,
            customers
          );
        return editInvoiceLineObj;
      })
      .sort(
        (invoiceLine1, invoiceLine2) =>
          invoiceLine1.invoiceLineNumber - invoiceLine2.invoiceLineNumber
      );
  }, [
    allLines,
    customers,
    displayInvoice,
    erpTransactions,
    fundTypes,
    invoices,
    products,
    selectedInvoiceLines
  ]);

  /**
   * returns a `Record` that maps the key of an `ResolutionLineActivityObject` to a
   * `Record<string, boolean>` where each property maps to true if the error exists.
   * Each error is calculated via a service function from `ResolveMultipleInvoiceLinesErrorService`.
   *
   * @example const hasErrorsMap = {
   *            "-M212v9wq2asd2" : {
   *                amountLessThanOrEqualZero: false,
   *                exceedsMaxAmount: true,
   *                missingFundTypeKey: false,
   *                missingFundTypeAccount: false,
   *                missingComment: false
   *            }
   *          }
   */
  const hasErrorsMap: Record<string, ResolutionLineActivityErrors> =
    useMemo(() => {
      const { key: invoiceKey } = displayInvoice;
      const resolutionLinesByInvoice = resolutionLines[invoiceKey] ?? {};
      const selectedLinesByInvoice = selectedInvoiceLines[invoiceKey] ?? {};
      return Object.entries(resolutionLinesByInvoice)
        .map(([resolutionLineKey, resolutionLineObj]) => {
          const invoiceLineObj = selectedLinesByInvoice[resolutionLineKey];
          const { amount, fundTypeKey, comment } = resolutionLineObj;

          // calculate errors for each activity field
          const amountErrors =
            ResolveMultipleInvoiceLinesErrorService.hasActivityAmountErrors(
              amount,
              invoiceLineObj,
              displayInvoice,
              displayTransaction,
              invoices,
              erpTransactions
            );
          const fundTypeErrors =
            ResolveMultipleInvoiceLinesErrorService.hasWriteOffFundTypeErrors(
              activityType,
              fundTypeKey,
              fundTypes
            );
          const commentErrors =
            ResolveMultipleInvoiceLinesErrorService.hasCommentErrors(
              comment,
              activityType
            );
          return {
            resolutionLineKey,
            lineErrors: {
              amountLessThanOrEqualZero: amountErrors.amountLessThanOrEqualZero,
              exceedsMaxInvoiceAmount: amountErrors.exceedsMaxInvoiceAmount,
              exceedsMaxInvoiceLineAmount:
                amountErrors.exceedsMaxInvoiceLineAmount,
              exceedsMaxTransactionAmount:
                amountErrors.exceedsMaxTransactionAmount,
              missingFundTypeKey: fundTypeErrors.missingFundTypeKey,
              missingFundTypeAccount: fundTypeErrors.missingFundTypeAccount,
              missingComment: commentErrors.missingComment
            }
          };
        })
        .reduce((allLineErrorMessages, entry) => {
          const { resolutionLineKey, lineErrors } = entry;
          return {
            ...allLineErrorMessages,
            [resolutionLineKey]: lineErrors
          };
        }, {});
    }, [
      activityType,
      displayInvoice,
      displayTransaction,
      erpTransactions,
      fundTypes,
      invoices,
      resolutionLines,
      selectedInvoiceLines
    ]);

  /**
   * returns a `Record` that maps the key of an `ResolutionLineActivityObject` to a
   * `Record<string, string>` where each property maps to a unique message related to the error
   *
   * @example const lineErrorMessagesMap = {
   *            "-M212v9wq2asd2" : {
   *                amountLessThanOrEqualZero: "Must be non-zero",
   *                exceedsMaxAmount: "Exceeds open amount",
   *                missingFundTypeKey: "Select fund type",
   *                missingFundTypeAccount: "Select fund type",
   *                missingComment: "Comment is required"
   *            }
   *          }
   */
  const lineErrorMessagesMap: Record<
    string,
    Record<string, string>
  > = useMemo(() => {
    const { key: invoiceKey } = displayInvoice;
    const resolutionLinesByInvoice = resolutionLines[invoiceKey] ?? {};
    return Object.keys(resolutionLinesByInvoice)
      .map(invoiceLineKey => {
        const hasErrors = hasErrorsMap[invoiceLineKey];
        return {
          invoiceLineKey,
          lineError: {
            amount:
              (hasErrors.amountLessThanOrEqualZero &&
                ResolutionLinesErrorMessageMap.amountLessThanOrEqualZero) ||
              (hasErrors.exceedsMaxInvoiceAmount &&
                ResolutionLinesErrorMessageMap.exceedsMaxInvoiceAmount) ||
              (hasErrors.exceedsMaxInvoiceLineAmount &&
                ResolutionLinesErrorMessageMap.exceedsMaxInvoiceLineAmount) ||
              (hasErrors.exceedsMaxTransactionAmount &&
                ResolutionLinesErrorMessageMap.exceedsMaxTransactionAmount) ||
              "",
            fundTypeKey:
              (hasErrors.missingFundTypeKey &&
                ResolutionLinesErrorMessageMap.missingFundTypeKey) ||
              "",
            fundTypeAccount:
              (hasErrors.missingFundTypeAccount &&
                ResolutionLinesErrorMessageMap.missingFundTypeAccount) ||
              "",
            missingComment:
              (hasErrors.missingComment &&
                ResolutionLinesErrorMessageMap.missingComment) ||
              ""
          }
        };
      })
      .reduce((allLineErrorMessages, entry) => {
        const { invoiceLineKey, lineError } = entry;
        return {
          ...allLineErrorMessages,
          [invoiceLineKey]: lineError
        };
      }, {});
  }, [displayInvoice, resolutionLines, hasErrorsMap]);

  const createMultipleActivityLogs = () => {
    //  create an activity log for each resolution line we create
    const { key: invoiceKey } = displayInvoice;
    const { key: transactionKey } = displayTransaction;
    const currentResLines = resolutionLines[invoiceKey];
    const invoiceLines = selectedInvoiceLines[invoiceKey];
    Object.values(currentResLines).forEach(resolutionLine => {
      const {
        key,
        invoiceLineKey,
        comment,
        fundTypeKey = "",
        fundTypeAccount = "",
        amount: resolutionAmount,
        taggedUsers
      } = resolutionLine;
      const currentKey = invoiceLineKey || key;
      const currentInvoiceLine = invoiceLines[currentKey];

      const {
        amount: invoiceLineAmount,
        productGroupKey,
        customerKey
      } = currentInvoiceLine;
      const loggingMetadata = {
        invoiceLineKey: currentKey,
        invoiceKey,
        transactionKey,
        comment
      };

      const commentTags: Record<string, number[]> | undefined =
        taggedUsers?.reduce((allCommentTags, currentTag) => {
          return {
            ...allCommentTags,
            [currentTag.userId]: [currentTag.tagStart, currentTag.tagEnd]
          };
        }, {});

      switch (activityType) {
        case ResolutionActivityTypes.CLEAR: {
          const invoiceLineFundTypeKey =
            allLines[currentInvoiceLine.matchedPromLine || ""]?.type ||
            currentInvoiceLine.suggestedFundType ||
            "";
          addEvent(
            {
              type: DRMEventType.INVOICE_LINE_CLEARED,
              metadata: {
                clearedAmount: resolutionAmount,
                totalInvoiceLineAmount: invoiceLineAmount,
                productGroupKey,
                customerKey,
                clearedFundType: invoiceLineFundTypeKey,
                clearedGl: fundTypeAccount || ""
              },
              ...loggingMetadata
            },
            commentTags
          );
          break;
        }
        case ResolutionActivityTypes.DISPUTE: {
          addEvent(
            {
              type: DRMEventType.FLAG_DISPUTE,
              metadata: {
                totalInvoiceLineAmount: invoiceLineAmount,
                customerKey,
                productGroupKey
              },
              ...loggingMetadata
            },
            commentTags
          );
          break;
        }
        case ResolutionActivityTypes.SALES_REVIEW: {
          addEvent(
            {
              type: DRMEventType.SET_SALES_REVIEW,
              metadata: {
                totalInvoiceLineAmount: invoiceLineAmount,
                customerKey,
                productGroupKey
              },
              ...loggingMetadata
            },
            commentTags
          );
          break;
        }
        case ResolutionActivityTypes.WRITE_OFF: {
          addEvent(
            {
              type: DRMEventType.WRITE_OFF,
              metadata: {
                totalInvoiceLineAmount: invoiceLineAmount,
                customerKey,
                productGroupKey,
                writeOffAmount: resolutionAmount,
                writeOffFundType: fundTypeKey
              },
              ...loggingMetadata
            },
            commentTags
          );
          break;
        }
        default: {
          break;
        }
      }
    });
  };

  const masterConfig = useMemo(
    () =>
      new Map([
        [
          "key",
          {
            render: (_key, displayInvoiceLine) => {
              const { key, invoiceLineKey } = displayInvoiceLine;
              const currentKey = invoiceLineKey || key;
              return (
                <Button
                  data-testid={`deselect-button-${currentKey}`}
                  startIcon={<Cancel />}
                  onClick={() => {
                    dispatch(
                      toggleSelectInvoiceLine({
                        invoiceKey: displayInvoice.key,
                        invoiceLine: {
                          ...(displayInvoiceLine as InvoiceLineObject),
                          key: currentKey
                        }
                      })
                    );
                  }}
                />
              );
            }
          }
        ],
        [
          "productName",
          {
            name: "Product",
            sorting: {
              enabled: true
            }
          }
        ],
        [
          "amount",
          {
            name: "Original Amount",
            render: val => displayUSCurrency(val || 0),
            sorting: {
              enabled: true,
              customSort: (
                a: DisplayInvoiceObject,
                b: DisplayInvoiceObject
              ) => {
                return (a.amount || 0) - (b.amount || 0);
              }
            }
          }
        ],
        [
          "openAmount",
          {
            name: "Open Amount",
            render: val => displayUSCurrency(val || 0),
            sorting: {
              enabled: true,
              customSort: (
                a: DisplayInvoiceObject,
                b: DisplayInvoiceObject
              ) => {
                return (a.amount || 0) - (b.amount || 0);
              }
            }
          }
        ],
        [
          "matchedPromLine",
          {
            name: "Promotion Line ID",
            sorting: {
              enabled: true
            }
          }
        ],
        [
          "fundType",
          {
            name: "Assign",
            verticalAlign: "top",
            render: (_val, displayInvoiceLine) => (
              <MultiEditScreenFundTypeField
                displayInvoiceLine={displayInvoiceLine}
                displayInvoice={displayInvoice}
                hasErrorsMap={hasErrorsMap}
                showErrors={showErrors}
                allLines={allLines}
                activityType={activityType}
                fundTypes={fundTypes}
                lineErrorMessagesMap={lineErrorMessagesMap}
              />
            ),
            sorting: {
              enabled: true
            }
          }
        ],
        [
          "activityAmount",
          {
            name: "Activity Amount",
            verticalAlign: "top",
            render: (_val, displayInvoiceLine) => (
              <MultiEditScreenActivitiyAmount
                displayInvoiceLine={displayInvoiceLine}
                displayInvoice={displayInvoice}
                displayTransaction={displayTransaction}
                hasErrorsMap={hasErrorsMap}
                showErrors={showErrors}
                lineErrorMessagesMap={lineErrorMessagesMap}
              />
            ),
            sorting: {
              enabled: true
            }
          }
        ],
        [
          "comment",
          {
            name: "Comments",
            verticalAlign: "top",
            render: (_val, displayInvoiceLine) => (
              <MultiEditScreenComment
                addEvent={addEvent}
                companyUsers={companyUsers}
                displayInvoiceLine={displayInvoiceLine}
                displayInvoice={displayInvoice}
                hasErrorsMap={hasErrorsMap}
                showErrors={showErrors}
                lineErrorMessagesMap={lineErrorMessagesMap}
              />
            )
          }
        ]
      ] as [keyof ResolveInvoiceLineObject, ColumnConfigOptions<ResolveInvoiceLineObject>][]),
    [
      activityType,
      addEvent,
      allLines,
      companyUsers,
      dispatch,
      displayInvoice,
      displayTransaction,
      fundTypes,
      hasErrorsMap,
      lineErrorMessagesMap,
      showErrors
    ]
  );

  const getColumnConfig = useCallback(
    (activity: ResolutionActivityTypes) => {
      const columnConfig = new Map(masterConfig);
      switch (activity) {
        case ResolutionActivityTypes.CLEAR: {
          columnConfig.delete("fundType");
          const commentObj = columnConfig.get("comment");
          commentObj!.name = "Comments (optional)";
          break;
        }
        case ResolutionActivityTypes.WRITE_OFF: {
          const fundTypeObj = columnConfig.get("fundType");
          fundTypeObj!.name = "Write-Off Fund Type";
          break;
        }
        case ResolutionActivityTypes.SALES_REVIEW: {
          columnConfig.delete("activityAmount");
          columnConfig.delete("fundType");
          break;
        }
        case ResolutionActivityTypes.DISPUTE: {
          columnConfig.delete("activityAmount");
          columnConfig.delete("fundType");
          break;
        }
        default: {
          break;
        }
      }
      return columnConfig;
    },
    [masterConfig]
  );

  const summaryConfig = useMemo(
    () =>
      new Map([
        [
          "activityAmount",
          {
            label: `Total ${currentActivityType} Amount`,
            type: CollapsibleTableSummaryType.SUM,
            render: _val => {
              const resolutionLinesByInvoice =
                resolutionLines[displayInvoice.key] ?? {};
              const activityAmountSum = Object.values(
                resolutionLinesByInvoice
              ).reduce((existingAmountSum, resolutionLine) => {
                return existingAmountSum + (resolutionLine.amount ?? 0);
              }, 0);

              return displayUSCurrency(activityAmountSum);
            }
          }
        ],
        [
          "key",
          {
            label: "# of Invoice Lines",
            type: CollapsibleTableSummaryType.COUNT
          }
        ]
      ] as [keyof ResolveInvoiceLineObject, SummaryConfigOptions<ResolveInvoiceLineObject>][]),
    [currentActivityType, displayInvoice.key, resolutionLines]
  );

  const createNewResolutionLine = (
    resolutionActivityLine: ResolutionLineActivityObject
  ) => {
    const date = new Date().toISOString();
    const {
      key,
      transactionKey,
      invoiceLineKey,
      invoiceKey,
      comment,
      openedUser,
      amount,
      promLine,
      fundTypeKey,
      fundTypeAccount
    } = resolutionActivityLine;

    const newResolutionLine: ResolutionLineObject = {
      key,
      type: ResolutionActivityTypeMap[activityType],
      invoiceLineKey,
      invoiceKey: invoiceKey || displayInvoice.key,
      transactionKey: transactionKey || displayTransaction.key,
      openedUser,
      openedComment: comment,
      openedDate: date
    };

    // add in activity type specific fields
    if (activityType === ResolutionActivityTypes.CLEAR) {
      return {
        ...newResolutionLine,
        amount,
        fundTypeKey,
        fundTypeAccount,
        promLine,
        closedDate: date,
        closedUser: openedUser,
        closedComment: comment
      };
    }
    if (activityType === ResolutionActivityTypes.WRITE_OFF) {
      return {
        ...newResolutionLine,
        amount,
        fundTypeKey,
        fundTypeAccount,
        closedDate: date,
        closedUser: openedUser,
        closedComment: comment
      };
    }
    // includes SALES_REVIEW, DISPUTE
    return newResolutionLine;
  };

  const saveResolutionLine = (
    invoiceLinesMapping: Record<string, InvoiceLineMap>
  ) => {
    // updated invoice + strip of any display values
    const updatedInvoice = {
      ...displayInvoice,
      resolutionLines: {
        ...displayInvoice.resolutionLines
      },
      invoiceLines: {
        ...displayInvoice.invoiceLines
      }
    };

    Object.values(invoiceLinesMapping).forEach(invoiceLineMap => {
      const { displayInvoiceLine, resolutionLine } = invoiceLineMap;

      // update invoice line + strip of any display values
      const updatedInvoiceLine = {
        ...invoiceLineMap.displayInvoiceLine,
        resolutionLines: {
          ...displayInvoiceLine.resolutionLines,
          [resolutionLine.key]: resolutionLine.key
        },
        status: InvoiceLineProcessingService.getUpdatedInvoiceLineStatus(
          displayInvoiceLine,
          invoices,
          erpTransactions,
          resolutionLine
        )
      };

      const cleanedInvoiceLine =
        InvoiceLineProcessingService.getCleanedInvoiceLineObject(
          updatedInvoiceLine
        );

      updatedInvoice.resolutionLines[resolutionLine.key] = resolutionLine;
      updatedInvoice.invoiceLines[displayInvoiceLine.key] = cleanedInvoiceLine;
    });

    const cleanedInvoice = InvoiceProcessingService.getCleanedInvoiceObject({
      ...updatedInvoice,
      status: InvoiceProcessingService.getUpdatedInvoiceStatus(
        updatedInvoice,
        erpTransactions
      )
    });

    // update transaction + strip of any display values
    const updatedTransaction = {
      ...displayTransaction,
      status: TransactionProcessingService.getUpdatedTransactionStatus(
        displayTransaction,
        erpTransactions,
        {
          ...invoices,
          [displayInvoice.key]: cleanedInvoice as InvoiceObject
        }
      )
    };

    const cleanedTransaction =
      TransactionProcessingService.getCleanedTransactionObject(
        updatedTransaction
      );

    // update all to DB
    InvoiceProcessingService.updateFirebaseInvoice(
      cleanedInvoice,
      () => {
        TransactionProcessingService.updateFirebaseTransaction(
          cleanedTransaction,
          () => {
            enqueueSnackbar(
              `Successfully created ${
                Object.keys(invoiceLinesMapping).length
              } resolution lines`,
              { variant: "success" }
            );
            // create activity logs if upload to db was successful
            createMultipleActivityLogs();
          },
          () =>
            enqueueSnackbar(
              `Failed to create ${
                Object.keys(invoiceLinesMapping).length
              } resolution lines due to failure to upload updated Transaction object`,
              {
                variant: "error"
              }
            )
        );
      },
      () =>
        enqueueSnackbar(
          `Failed to create ${
            Object.keys(invoiceLinesMapping).length
          } resolution lines due to failure to upload updated Invoice object`,
          {
            variant: "error"
          }
        )
    );
  };

  const saveBatchResolutionLines = (
    resolutionLinesByInvoice: Record<string, ResolutionLineActivityObject>
  ) => {
    const invoiceLinesMapping: Record<string, InvoiceLineMap> =
      selectedInvoiceLinesList.reduce((invoiceLinesMap, displayInvoiceLine) => {
        const { key, invoiceLineKey } = displayInvoiceLine;
        const currentKey = invoiceLineKey || key;
        const resolutionActivityLine = resolutionLinesByInvoice[currentKey];
        const newResolutionLine = createNewResolutionLine(
          resolutionActivityLine
        );
        return {
          ...invoiceLinesMap,
          [currentKey]: {
            displayInvoiceLine,
            resolutionLine: newResolutionLine
          }
        };
      }, {});

    saveResolutionLine(invoiceLinesMapping);
  };

  const lineErrorsExist = useMemo(() => {
    const { key: invoiceKey } = displayInvoice;
    const resolutionLinesByInvoice = resolutionLines[invoiceKey] ?? {};
    return Object.keys(resolutionLinesByInvoice).some(resolutionLineKey => {
      const resolutionLineActivityErrors =
        ResolveMultipleInvoiceLinesErrorService.getResolutionLineActivityErrors(
          activityType,
          hasErrorsMap[resolutionLineKey]
        );
      if (Object.values(resolutionLineActivityErrors).some(error => error)) {
        return true;
      }
      return false;
    });
  }, [activityType, displayInvoice, hasErrorsMap, resolutionLines]);

  const lineSumErrorExists = useMemo(() => {
    const { key: invoiceKey, openAmount: invoiceOpenAmount } = displayInvoice;
    const { openAmount: transactionOpenAmount } = displayTransaction;
    const resolutionLinesByInvoice = resolutionLines[invoiceKey] ?? {};

    // check if sum of activity amounts exceeds invoice/transaction open amount
    const lineSum = Object.values(resolutionLinesByInvoice).reduce(
      (existingSum, resolutionLine) => {
        return existingSum + (resolutionLine.amount ?? 0);
      },
      0
    );

    return (
      lineSum > Math.min(invoiceOpenAmount, transactionOpenAmount) &&
      activityType !== ResolutionActivityTypes.SALES_REVIEW &&
      activityType !== ResolutionActivityTypes.DISPUTE
    );
  }, [activityType, displayInvoice, displayTransaction, resolutionLines]);

  const handleCreateBatchResolutionLines = (onSuccessCallback?: () => void) => {
    const { key: invoiceKey } = displayInvoice;
    const resolutionLinesByInvoice = resolutionLines[invoiceKey] ?? {};

    if (!lineErrorsExist && !lineSumErrorExists) {
      saveBatchResolutionLines(resolutionLinesByInvoice);
      onClose();
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    }
    setShowErrors(true);
  };

  const columnConfig = getColumnConfig(activityType);

  return (
    <MultiEditScreenContainer>
      <ScreenTitle variant="h4" data-testid="screen-title">
        Multi Select Editing: {currentActivityType}
      </ScreenTitle>
      <MultiEditCard
        displayTransaction={displayTransaction}
        displayInvoice={displayInvoice}
      />
      <TableHeader>Selected Invoice Lines</TableHeader>
      <CollapsibleTable<ResolveInvoiceLineObject>
        data={selectedInvoiceLinesList}
        columnConfig={columnConfig}
        summaryConfig={summaryConfig}
        index="key"
        onRowClick={() => {}}
        sortState={sortState}
        setSortState={payload => dispatch(setResolutionLineSorting(payload))}
        pagination={{
          enabled: true,
          pageSize: 5,
          rowsPerPageOptions: [5, 10]
        }}
      />
      <ButtonsContainer>
        <Button
          variant="outlined"
          onClick={() => {
            onClose();
          }}>
          Exit
        </Button>
        <ErrorContainer>
          {showErrors && lineErrorsExist && (
            <Typography sx={{ color: "error.light" }}>
              Error(s) performing batch action. Please resolve all errors
            </Typography>
          )}
          {showErrors && lineSumErrorExists && (
            <Typography sx={{ color: "error.light" }}>
              Sum of activity amounts exceeds invoice/transaction open amount
            </Typography>
          )}
        </ErrorContainer>
        <Button
          variant="outlined"
          onClick={() =>
            handleCreateBatchResolutionLines(() => dispatch(reset()))
          }>
          Save Changes
        </Button>
      </ButtonsContainer>
    </MultiEditScreenContainer>
  );
}
