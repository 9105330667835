import React from "react";
import Subheader from "ui-library/Subheader";

import { firebase } from "helpers/Firebase";
import Activate2FA from "./Activate2FA";
import Edit2FASettings from "./Edit2FASettings";

const styles = {
  subheader: {
    color: "primary.main"
  }
};

class TwoFactorAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      twoFactorAuth: false
    };
  }

  update2FAState = newState => {
    this.setState({ twoFactorAuth: newState });
  };

  get2FASettings = () => {
    const user = firebase.auth().currentUser;
    const options = user.multiFactor.enrolledFactors;
    // set settings - only support for 1 option right now
    if (options.length == 1) {
      this.setState({ twoFactorAuth: true });
    }
  };

  componentDidMount() {
    this.get2FASettings();
  }

  render() {
    return (
      <div>
        <Subheader style={styles.subheader}>
          Two-Factor Authentication
        </Subheader>
        <div style={{ maxWidth: "600px", padding: "0 20px" }}>
          {this.state.twoFactorAuth ? (
            <Edit2FASettings
              update2FAState={this.update2FAState}
              openClose={this.props.openClose}
            />
          ) : (
            <Activate2FA
              update2FAState={this.update2FAState}
              openClose={this.props.openClose}
            />
          )}
        </div>
      </div>
    );
  }
}

export default TwoFactorAuth;
