import React from "react";

import Button from "ui-library/Button";
import TextField from "ui-library/TextField";
import Stack from "@mui/material/Stack";
import { addFirebase } from "helpers/Firebase";

class NewCustomer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newCustomer: {
        name: ""
      }
    };
  }

  handleTextChange = (field, value) => {
    const { newCustomer } = this.state;

    if (field.indexOf("distributor") > -1) {
      newCustomer.distributors[field] = value.value;
    } else {
      newCustomer[field] = value.trim();
    }

    this.setState({
      newCustomer
    });
  };

  // returns existingType, otherCustomer] if there is a duplicate error
  checkDuplicateName = proposedName => {
    // strips string of all spaces and makes lower case
    function reduce(name) {
      const result = name.toLowerCase();
      return result.split(" ").join("");
    }

    const reducedName = reduce(proposedName);

    // check name against all other names in the db
    for (const key in this.props.db.customers) {
      const otherCustomer = this.props.db.customers[key].name;

      if (key !== this.props.customerKey) {
        if (reducedName == reduce(otherCustomer)) {
          return ["customer", otherCustomer];
        }

        if (this.props.db.customers[key].otherNames) {
          for (
            let i = 0;
            i < this.props.db.customers[key].otherNames.length;
            i++
          ) {
            const altName = this.props.db.customers[key].otherNames[i];
            if (reducedName == reduce(altName)) {
              return ["alternative", otherCustomer];
            }
          }
        }
      }
    }
    return null;
  };

  saveToDatabase = () => {
    // clear error messages
    this.setState({
      nameError: ""
    });

    // first, check for errors
    if (!this.state.newCustomer.name) {
      this.setState({
        nameError: "Name is required."
      });
      return;
    }

    // check duplicates in database
    const dupName = this.checkDuplicateName(this.state.newCustomer.name);
    if (dupName) {
      const errorMessage = `Customer name already exists in database as a(n) ${dupName[0]} name under: ${dupName[1]}`;
      this.setState({
        nameError: errorMessage
      });
      return;
    }

    addFirebase(2, this.state.newCustomer);

    this.setState({
      newCustomer: { name: "", distributors: {} }
    });
    this.props.openClose.closeAppModal();
  };

  componentDidMount() {}

  render() {
    return (
      <div>
        <TextField
          floatingLabelText="Name"
          onChange={event => {
            this.handleTextChange("name", event.target.value.trim());
          }}
          errorText={this.state.nameError}
          fullWidth
        />
        <br />
        <br />
        <font color="teal">
          Please create this customer first, then open its profile to manage
          additional details.
        </font>
        <br />
        <br />
        <Stack justifyContent="flex-end">
          <Button label="Save to Database" onClick={this.saveToDatabase} />
        </Stack>
        <br />
      </div>
    );
  }
}

export default NewCustomer;
