import React from "react";
import SplitPane from "react-split-pane";
import { grey } from "@mui/material/colors";

const grey500 = grey["500"];

function calculateWidth(props) {
  if (props.width) {
    return props.width;
  }
  let width = $(window).width() / 2;
  if (props.sideBar) {
    width -= 140;
  }
  return width;
}

class SplitScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: calculateWidth(props)
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      width: calculateWidth(nextProps)
    });
  }

  render() {
    return (
      <SplitPane
        split={this.props.split}
        allowResize={this.props.allowResize}
        defaultSize={this.state.width}
        className="split-screen"
        style={{
          paddingLeft: this.props.sideBar ? 256 : 80,
          height: this.props.height,
          marginBottom: this.props.marginBottom || 0,
          ...this.props.style
        }}
        paneStyle={{ overflow: "auto", height: this.props.height }}
        resizerStyle={{
          height: this.props.height,
          borderColor: grey500,
          width: 1
        }}>
        <div>{this.props.leftComponent}</div>
        <div>{this.props.rightComponent}</div>
      </SplitPane>
    );
  }
}

SplitScreen.defaultProps = {
  split: "vertical",
  allowResize: true
};

export default SplitScreen;
