import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import EnterInvoiceMetadata from "./EnterInvoiceMetadata";

const useStyles = makeStyles(() => ({
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    height: "100%"
  },
  columnContainer: {
    display: "flex",
    flexDirection: "column"
  }
}));

export default function AddMetadata() {
  const classes = useStyles();

  return (
    <div className={classes.rowContainer}>
      <div className={classes.columnContainer}>
        <EnterInvoiceMetadata />
      </div>
    </div>
  );
}
