import React from "react";

import AddIcon from "@mui/icons-material/Add";
import RadioButtonGroup from "@mui/material/RadioGroup";
import RadioButton from "ui-library/RadioButton";
import Subheader from "ui-library/Subheader";
import DatePicker from "ui-library/DatePicker";
import Button from "ui-library/Button";
import MenuItem from "ui-library/MenuItem";
import Select from "ui-library/Select";
import TextField from "ui-library/TextField";

import {
  addFirebase,
  getJSXListsFirebase,
  updateMetaFirebase
} from "helpers/Firebase";
import { objToArray, sortByField } from "helpers/sortByDate";
import { formatDate } from "helpers/Time";
import Mixpanel from "helpers/Mixpanel";
import { red, grey } from "@mui/material/colors";
import { Stack } from "@mui/material";
import { getCurrentTimeframe } from "../Customers/CustomerUtils";

const red400 = red["400"];
const grey700 = grey["700"];

const styles = {
  header: {
    fontSize: 18
  },
  radioButton: {
    marginBottom: 16
  }
};

class NewRevenue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numAddMoney: 0,
      addMoney: {},
      isError: false,
      errorText: "",
      promotion: 0,
      line: 0,
      source: "None"
    };
  }

  isValid = id => {
    if (!("date" in this.state.addMoney[id])) {
      return false;
    }
    const { actSales } = this.state.addMoney[id];
    const { revenue } = this.state.addMoney[id];
    if (actSales == null && revenue == null) {
      return false;
    }
    if (revenue != null) {
      return parseFloat(revenue) == revenue;
    }
    if (actSales != null) {
      return parseFloat(actSales) == actSales;
    }
  };

  checkError = id => {
    if (Object.keys(this.state.addMoney).length == 0) {
      return true;
    }

    return !this.isValid(id);
  };

  missingRegions = () => {
    const missingRegions = [];
    for (const key in this.state.addMoney) {
      var { region } = this.state.addMoney[key];
      if (!region || region == "" || region.trim() == "") continue;

      var groups = {};
      let ungrouped = [];
      if (this.props.db.region_mapping) {
        groups = this.props.db.region_mapping.groups || {};
        ungrouped = this.props.db.region_mapping.ungrouped_regions || [];
      }
      if (
        region &&
        ungrouped.indexOf(region) < 0 &&
        Object.keys(groups)
          .map(group => (groups[group].regions || []).indexOf(region))
          .filter(x => x >= 0).length == 0
      ) {
        missingRegions.push(region);
      }
    }
    return missingRegions;
  };

  handleDateChange = (id, event, value) => {
    const newAddMoney = this.state.addMoney;
    newAddMoney[id].date = formatDate(event);
    this.setState({
      addMoney: newAddMoney,
      isError: false,
      errorText: ""
    });
  };

  handleTextChange = (field, event) => {
    const newAddMoney = this.state.addMoney;
    let { value } = event.target;
    if (value == "") value = null;
    else if (field != "region") value = parseFloat(value);

    newAddMoney[event.target.id][field] = value;
    this.setState({
      addMoney: newAddMoney,
      errorText: ""
    });
  };

  getAddMoneyJSX = () => {
    const addMoneyJSX = [];
    for (let i = 0; i < this.state.numAddMoney; i++) {
      addMoneyJSX.push(
        <div className="rowC" style={{ marginTop: 10 }} key={i}>
          <DatePicker
            label="Date"
            firstDayOfWeek={0}
            onChange={this.handleDateChange.bind(null, `${i}`)}
            value={this.state.addMoney[`${i}`].date.split("-")}
            style={{ marginRight: 16 }}
            autoOk
            fullWidth
          />
          <TextField
            placeholder="Actual Unit Sales"
            onChange={this.handleTextChange.bind(null, "actSales")}
            id={`${i}`}
            style={{ marginLeft: 16, marginRight: 16, marginTop: 20 }}
            fullWidth
          />
          <TextField
            placeholder="Revenue ($)"
            onChange={this.handleTextChange.bind(null, "revenue")}
            id={`${i}`}
            style={{ marginRight: 16, marginTop: 20 }}
            fullWidth
          />
          <TextField
            placeholder="Region"
            onChange={this.handleTextChange.bind(null, "region")}
            id={`${i}`}
            style={{ marginRight: 16, marginTop: 20 }}
            fullWidth
          />
        </div>
      );
    }
    return addMoneyJSX;
  };

  handleAddMoneyNum = () => {
    const newAddMoney = this.state.addMoney;
    newAddMoney[this.state.numAddMoney] = { date: formatDate(Date.now()) };
    this.setState({
      numAddMoney: this.state.numAddMoney + 1,
      addMoney: newAddMoney
    });
  };

  getUnitPrice = key => {
    const { customer, product } = this.state;
    const productToGroups = {};
    for (var [pgKey, pg] of Object.entries(this.props.db.meta.product_groups)) {
      if ("products" in pg && pg.isPricing) {
        for (const pgProduct of pg.products) {
          if (!(pgProduct in productToGroups)) {
            productToGroups[pgProduct] = [];
          }
          productToGroups[pgProduct].push(pgKey);
        }
      }
    }

    if (!(customer in this.props.db.pricing) || !(product in productToGroups)) {
      this.setState({
        errorText: "Pricing data could not be found for customer/product pair.",
        isError: true
      });
    } else if (productToGroups[product].length == 0) {
      this.setState({
        errorText: "Pricing data could not be found for customer/product pair.",
        isError: true
      });
    } else {
      const prices = [];
      for (var pgKey of productToGroups[product]) {
        if (pgKey in this.props.db.pricing[customer]) {
          const curTimeframe = getCurrentTimeframe(
            this.props.db.pricing[customer][pgKey],
            new Date(this.state.addMoney[key].date.split("-"))
          );
          prices.push(curTimeframe.price);
        }
      }
      if (prices.length == 0) {
        this.setState({
          errorText:
            "Pricing data could not be found for customer/product pair.",
          isError: true
        });
      } else if (Array.from(new Set(prices)).length > 1) {
        this.setState({
          errorText: "Conflicting prices found for customer/product pair.",
          isError: true
        });
      } else {
        return prices[0];
      }
    }
  };

  handleSubmit = () => {
    const addMoney = jQuery.extend(true, {}, this.state.addMoney);
    const addMoneyList = [];
    if (Object.keys(addMoney).length == 0) {
      this.setState({ errorText: "Missing/invalid field(s)." });
      return;
    }
    for (const key in addMoney) {
      const newItem = addMoney[key];
      if (this.checkError(key)) {
        this.setState({ errorText: "Missing/invalid field(s)." });
        return;
      }
      if (this.state.product == "none" && newItem.revenue == null) {
        this.setState({ errorText: "Missing/invalid field(s)." });
        return;
      }
      if (
        !Number.isNaN(parseFloat(newItem.actSales)) &&
        !Number.isNaN(parseFloat(newItem.revenue))
      ) {
        this.setState({
          errorText:
            "You cannot enter both Actual Unit Sales and Revenue amounts for a single item.\
             Please fix the item(s) that have both."
        });
        return;
      }
      if (
        parseFloat(newItem.actSales) === 0 ||
        parseFloat(newItem.revenue) === 0
      ) {
        this.setState({
          errorText:
            "Some item(s) have zero revenue or units and cannot be uploaded."
        });
        return;
      }
      // set additional fields
      newItem.customer = this.state.customer;
      if (this.state.product != "none") {
        const unitPrice = this.getUnitPrice(key);
        if (unitPrice == null) {
          return;
        }
        newItem.unitPrice = unitPrice;
        if ("revenue" in newItem) {
          newItem.actSales = newItem.revenue / unitPrice;
        } else {
          newItem.revenue = newItem.actSales * unitPrice;
        }

        newItem.product = this.state.product;
      }
      newItem.source = this.state.source;

      addMoneyList.push(newItem);
    }

    const missingRegions = this.missingRegions();
    if (missingRegions.length > 0 && !this.state.alreadyCheckedRegions) {
      this.setState({
        errorText:
          "Warning: Some regions are not yet grouped.\
        Press Submit again to finish uploading, then proceed to the Region Mapping page to group these regions.",
        alreadyCheckedRegions: true
      });
      return;
    }
    if (missingRegions.length > 0) {
      const newUngroupedRegions =
        this.props.db.meta.region_mapping.ungrouped_regions || [];
      newUngroupedRegions.push(...(missingRegions || []));

      const update = {};
      update["region_mapping/ungrouped_regions"] = newUngroupedRegions;
      updateMetaFirebase(update);
    }

    Mixpanel.track("Manual Revenue Add", {
      "Revenue List": addMoneyList
    });
    addFirebase(6, addMoneyList);
    this.props.openClose.closeAppModal();
  };

  getPromotionsJSX = () => {
    const allPromotions = this.props.db.promotions;
    const promotionsJSX = [];
    const promotionsList = sortByField("name", objToArray(allPromotions), true);
    for (let i = 0; i < promotionsList.length; i++) {
      const promotion = promotionsList[i];
      if (
        this.props.db.meta.statuses[promotion.status] != "Declined" &&
        this.props.db.meta.statuses[promotion.status] != "Cancelled" &&
        (!this.state.customer || promotion.customer == this.state.customer)
      ) {
        promotionsJSX.push(
          <MenuItem
            value={promotion.key}
            primaryText={promotion.name}
            key={promotion.key}
          />
        );
      }
    }
    return promotionsJSX;
  };

  componentDidMount() {
    getJSXListsFirebase(
      this.props.db,
      allJSX => {
        const allProducts = this.props.db.products;
        const productsJSX = [
          <MenuItem value="none" key="none">
            None
          </MenuItem>
        ];
        const productsList = sortByField("name", objToArray(allProducts), true);
        for (let i = 0; i < productsList.length; i++) {
          const product = productsList[i];
          productsJSX.push(
            <MenuItem value={product.key} key={product.key}>
              {product.name}
            </MenuItem>
          );
        }
        this.setState({
          customersJSX: allJSX.customersJSX,
          customer: allJSX.customersJSX[0].key,
          productsJSX,
          product: productsJSX[0].key
        });
      },
      null,
      null
    );

    this.handleAddMoneyNum();
  }

  render() {
    const addMoneyJSX = this.getAddMoneyJSX();
    const revenueSources = this.props.db.meta.revenue_sources
      ? this.props.db.meta.revenue_sources
      : [];

    return (
      <div>
        <Subheader style={styles.header}>Specify Details</Subheader>
        <div className="rowC">
          <Select
            floatingLabelText="Customer"
            value={this.state.customer}
            onChange={(event, index, value) => {
              this.setState({ customer: value, isError: false });
            }}
            fullWidth
            style={{ marginRight: 16 }}>
            {this.state.customersJSX}
          </Select>
          <Select
            floatingLabelText="Product"
            value={this.state.product}
            onChange={(event, index, value) => {
              this.setState({ product: value, isError: false });
            }}
            fullWidth
            style={{ marginRight: 16 }}>
            {this.state.productsJSX}
          </Select>
        </div>
        <br />
        <br />
        <div style={styles.radioButtonGroup}>
          <div style={{ fontSize: 18, marginBottom: 16 }}>
            Select Data Source (if applicable)
          </div>
          <RadioButtonGroup
            name="selectPreset"
            defaultSelected="None"
            onChange={(event, value) => {
              this.setState({ source: value });
            }}
            style={{ marginLeft: 16 }}>
            {revenueSources.map(preset => {
              return <RadioButton value={preset} label={preset} key={preset} />;
            })}
            <RadioButton value="None" label="None" />
          </RadioButtonGroup>
        </div>
        <br />
        <Stack>
          <Subheader style={styles.header}>Input Items</Subheader>
          <Button
            onClick={this.handleAddMoneyNum}
            label={<AddIcon color={grey700} />}
          />
        </Stack>
        {addMoneyJSX}
        <br />
        <div className="centering">
          <Button label="Submit" onClick={this.handleSubmit} />
        </div>
        <br />
        <div style={{ color: red400 }} className="centering">
          {this.state.errorText}
        </div>
        <br />
      </div>
    );
  }
}

export default NewRevenue;
