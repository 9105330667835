import React from "react";
import Snackbar from "@mui/material/Snackbar";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "ui-library/IconButton";

class MinimizedToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      minimizedToolbarJSX: null
    };
  }

  componentDidMount() {
    this.setState({ minimizedToolbarJSX: this.props.minimizedToolbarJSX });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ minimizedToolbarJSX: nextProps.minimizedToolbarJSX });
  }

  render() {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        open
        message={this.state.minimizedToolbarJSX}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              onClick={this.props.openWindow}>
              <ExpandLessIcon style={{ color: "white" }} />
            </IconButton>
            <IconButton
              size="small"
              aria-label="close"
              onClick={this.props.closeWindow}>
              <CloseIcon style={{ color: "white" }} />
            </IconButton>
          </>
        }
      />
    );
  }
}

export default MinimizedToolbar;
