import React from "react";
import { connect } from "react-redux";

import { Divider } from "pui-react-dividers";

import Typography from "@mui/material/Typography";
import TextField from "ui-library/TextField";
import Select from "ui-library/Select";
import MenuItem from "ui-library/MenuItem";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "ui-library/IconButton";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import XIcon from "@mui/icons-material/HighlightOff";

import Button from "ui-library/Button";
import { getTimesJSX } from "helpers/Time";
import { sortByField } from "helpers/sortByDate";
import { firebase } from "helpers/Firebase";
import { grey, red, teal, common } from "@mui/material/colors";
import EntryCard from "./EntryCard";
import AddPromAutoComplete from "./AddPromAutoComplete";
import AddContact from "./AddContact";
import { updateField, addContact } from "./actions";
import {
  getTimeframeRange,
  getVisibleDistributors
} from "../Customers/CustomerUtils";

const grey100 = grey["100"];
const grey700 = grey["700"];
const red500 = red["500"];
const teal400 = teal["400"];
const { black } = common;

const styles = {
  superheader: {
    fontFamily: "Ubuntu",
    marginTop: 10,
    padding: 0
  },
  header: {
    marginLeft: "-15px",
    marginRight: "-50px",
    fontSize: 20,
    color: black
  },
  subheader: {
    marginLeft: "-15px",
    fontSize: 15,
    fontFamily: "Ubuntu"
  },
  checkbox: {
    marginBottom: 16
  },
  scrollbar: {
    height: 200,
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: grey700
  }
};

class AddPromStep0 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: {},
      selectCustomers: [],
      customerExists: false,
      direct: false,
      selectDates: 1,
      monthsJSX: [],
      yearsJSX: []
    };
  }

  setFirstReceiver = (selectDistributors, mon = null, yr = null) => {
    const autopopulateReceiver =
      this.state.customerExists && selectDistributors.length == 1;
    if (autopopulateReceiver) {
      const distributorValue = [selectDistributors?.[0]?.props?.value];
      this.props.dispatch(
        updateField(this.props.db, "distributor", distributorValue, mon, yr)
      );
    } else {
      this.props.dispatch(updateField(this.props.db, "distributor", null));
    }
  };

  // For customer autocomplete
  handleNewRequest = chosenRequest => {
    this.setState(
      {
        customerExists: true,
        customerName: chosenRequest.text,
        customerKey: chosenRequest.key
      },
      () => {
        const [selectDistributors, distributorNames] =
          this.getSelectDistributors();
        this.setFirstReceiver(selectDistributors);
      }
    );
  };

  handleCancel = () => {
    this.props.dispatch(updateField(this.props.db, "customer", {}));
    this.props.dispatch(updateField(this.props.db, "distributor", null));
    this.setState({
      customerExists: false
    });
  };

  getSelectDistributors = (month, year) => {
    const selectDistributors = [];
    const distributorNames = [];
    let timeframeRange = [];
    if (this.state.customerKey in this.props.db.customers) {
      const customer = this.props.db.customers[this.state.customerKey];
      customer.key = this.state.customerKey;
      timeframeRange = getTimeframeRange(
        customer,
        new Date(year, month - 1, 1),
        new Date(year, month, 0)
      );
      const distributors = getVisibleDistributors(
        customer.key,
        customer,
        new Date(year, month - 1)
      );
      if (distributors) {
        for (let i = 0; i < distributors.length; i++) {
          const distributor = distributors[i];
          const distributorName = this.props.db.customers[distributor]
            ? this.props.db.customers[distributor].name
            : distributor;
          selectDistributors.push(
            <MenuItem
              value={distributorName}
              checked={
                this.props.promState.distributor
                  ? this.props.promState.distributor.indexOf(distributorName) >
                    -1
                  : false
              }
              children={distributorName}
              key={i + 1}
            />
          );
          distributorNames.push(distributorName);
        }
      }
    }
    return [selectDistributors, distributorNames, timeframeRange];
  };

  fillExistingFields = promState => {
    // Set fields if they already exist
    if (promState.customer.key) {
      this.setState(prevState => ({
        customerExists: true,
        customerName: promState.customer.name || prevState.customerName,
        customerKey: promState.customer.key
      }));
    }
  };

  getErrorText = id => {
    const spl = id.split(".");
    if (spl == "month" && this.props.fieldErrors.promCreatedInPast) {
      return "You do not have permission to create a past promotion.";
    }
    if (spl.length == 1) {
      return this.props.promState[spl[0]] === ""
        ? "This field is required"
        : "";
    }
    if (spl.length == 2) {
      return this.props.promState[spl[0]][spl[1]] === ""
        ? "This field is required"
        : "";
    }
  };

  getContactErrorText = newContact => {
    const contactState = this.props.promState[newContact];
    if (contactState.name === "") {
      delete contactState.key;
    } else {
      return contactState.key === ""
        ? "You do not have permission to create a new contact. "
        : "";
    }
  };

  componentDidMount() {
    const metaData = this.props.db.meta;

    const { uid } = firebase.auth().currentUser;
    let userCustomerList = this.props.db.companyUsers[uid].customers;
    // 0-length array means every customer is allowed.
    if (!userCustomerList) userCustomerList = [];

    // Get customers
    const allCustomers = this.props.db.customers;
    let customerList = [];
    for (const key in allCustomers) {
      const customer = allCustomers[key];
      customer.key = key;
      customerList.push(customer);
    }

    customerList = sortByField("name", customerList, true);
    const selectCustomers = customerList
      .filter(customer => {
        // only include customers this user is allowed to see
        return (
          userCustomerList.length == 0 ||
          userCustomerList.includes(customer.key)
        );
      })
      .map(customer => {
        return {
          text: customer.name,
          value: <MenuItem children={customer.name} />,
          key: customer.key
        };
      });

    this.setState({
      selectCustomers
    });
    const { month } = this.props.promState;
    const { year } = this.props.promState;
    if (month && year) {
      this.props.dispatch(
        updateField(
          this.props.db,
          "distributor",
          this.props.promState.distributor,
          month,
          year
        )
      );
    }

    const timesJSX = getTimesJSX(this.props.db.meta, true);
    this.setState({
      monthsJSX: timesJSX[0],
      yearsJSX: timesJSX[1]
    });

    this.fillExistingFields(this.props.promState);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.fillExistingFields(nextProps.promState);
  }

  render() {
    const { month } = this.props.promState;
    const { year } = this.props.promState;
    let selectDistributors = [];
    let distributorNames = [];
    let timeframeRange = [];
    let multiTimeframesWarning = "";
    let helpText = "";

    if (month && year) {
      [selectDistributors, distributorNames, timeframeRange] =
        this.getSelectDistributors(month, year);
      const currentDists = this.props.promState.distributor || [];
      for (const dist of currentDists) {
        if (dist && !distributorNames.includes(dist)) {
          this.setFirstReceiver(selectDistributors, month, year);
          helpText =
            "One or more of these first receivers are no longer associated with this customer, but they were at a different point in time.";
        }
      }

      if (timeframeRange.length > 1) {
        multiTimeframesWarning =
          "This month spans multiple timeframes for this customer. Ensure that the first receivers selected are correct.";
      }
    }

    let { yearsJSX } = this.state;
    if (
      this.props.promState.year &&
      this.props.promState.year > new Date().getFullYear() + 2
    ) {
      yearsJSX = [
        <MenuItem
          value={this.props.promState.year}
          children={this.props.promState.year.toString()}
          key={this.props.promState.year.toString()}
        />
      ].concat(yearsJSX);
    }

    return (
      <div style={{ fontFamily: "Oxygen" }}>
        <Typography style={styles.superheader} variant="h4">
          Basic Info
        </Typography>
        <Divider />
        <div style={{ height: "400px", overflowY: "scroll" }}>
          <EntryCard
            title="Select Name & Month"
            key={this.props.promState.nameInit || ""}>
            <TextField
              floatingLabelText="Promotion Name"
              defaultValue={this.props.promState.name}
              onChange={event => {
                const { value } = event.target;
                this.props.dispatch(
                  updateField(this.props.db, "name", value?.trim())
                );
              }}
              errorText={
                this.props.fieldErrors.promNameExists
                  ? "A promotion of this name already exists"
                  : this.getErrorText("name")
              }
              fullWidth
            />
            <br />
            <br />
            <div className="rowC">
              <Select
                floatingLabelText="Month"
                value={this.props.promState.month}
                onChange={event => {
                  const monthValue = event.target.value;
                  this.props.dispatch(
                    updateField(this.props.db, "month", monthValue)
                  );
                  this.setFirstReceiver(selectDistributors, month, year);
                }}
                errorText={this.getErrorText("month")}
                fullWidth>
                {this.state.monthsJSX}
              </Select>
              <Select
                floatingLabelText="Year"
                value={this.props.promState.year}
                onChange={event => {
                  const yearValue = event.target.value;
                  this.props.dispatch(
                    updateField(this.props.db, "year", yearValue)
                  );
                  this.setFirstReceiver(selectDistributors, month, year);
                }}
                errorText={this.getErrorText("year")}
                fullWidth
                style={{ marginLeft: 16 }}>
                {yearsJSX}
              </Select>
            </div>
            <br />
            <br />
          </EntryCard>
          <EntryCard title="Select Retailer/First Receiver">
            <br />
            {this.state.customerExists ? (
              <List>
                <ListItem sx={{ bgcolor: "primary.container" }}>
                  <ListItemText
                    id="customer-name"
                    primary={this.state.customerName}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="close"
                      onClick={this.handleCancel}
                      size="large">
                      <XIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            ) : (
              <AddPromAutoComplete
                hintText="Customer"
                handleNewRequest={this.handleNewRequest}
                dataSource={this.state.selectCustomers}
                errorText={
                  this.props.promState.customer.name === "" ||
                  this.props.promState.customer.key === ""
                    ? "Select customer from list"
                    : ""
                } // checks if blank, or didn't match entry
                parent="customer"
                field="name"
                disabled={!month || !year}
              />
            )}
            <div>
              {!selectDistributors.length && this.state.customerExists ? (
                <div className="centering" style={{ color: red500 }}>
                  <br />
                  This customer has no first receivers assigned. Please
                  double-check the customer profile!
                </div>
              ) : (
                <div>
                  <Select
                    floatingLabelText="First Receiver"
                    value={this.props.promState.distributor || []}
                    multiple
                    onChange={event => {
                      const { value } = event.target;
                      this.props.dispatch(
                        updateField(
                          this.props.db,
                          "distributor",
                          value.length == 0 ? null : value,
                          this.props.promState.month,
                          this.props.promState.year
                        )
                      );
                    }}
                    disabled={!this.state.customerExists}
                    errorText={this.getErrorText("distributor")}
                    fullWidth>
                    {selectDistributors}
                  </Select>
                  <br />
                  <div className="centering" style={{ color: teal400 }}>
                    {helpText}
                  </div>
                  <br />
                  <div className="centering" style={{ color: teal400 }}>
                    {multiTimeframesWarning}
                  </div>
                </div>
              )}
            </div>
            <br />
          </EntryCard>
          <EntryCard title="Select or Add Contacts/Brokers">
            {this.props.newContacts.map(newContact => {
              return (
                <AddContact
                  contactState={this.props.promState[newContact]}
                  newContact={newContact}
                  key={newContact}
                  db={this.props.db}
                  errorText={this.getContactErrorText(newContact)}
                />
              );
            })}
            <div className="centering">
              <Button
                onClick={() => this.props.dispatch(addContact())}
                icon={<PersonAddIcon />}
                sx={{
                  "& .MuiButton-startIcon": {
                    margin: 0
                  }
                }}
              />
            </div>
            <br />
          </EntryCard>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const promFields = state.addPromotion;
  return {
    promState: promFields.prom,
    fieldErrors: promFields.fieldErrors,
    newContacts: promFields.newContacts
  };
};

export default connect(mapStateToProps)(AddPromStep0);
