import "./init-axios";

import React from "react";
import ReactDOM from "react-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { SnackbarProvider } from "notistack";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import CssBaseline from "@mui/material/CssBaseline";

import * as FullStory from "@fullstory/browser";
import * as Sentry from "@sentry/browser";

import {
  ThemeProvider as MuiThemeProvider,
  Theme,
  StyledEngineProvider
} from "@mui/material/styles";
import { sentryDSN, fullStoryOrgId } from "config";

import theme from "helpers/muiTheme";
import App from "./components/App";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// import CheckIcon from "@mui/icons-material/Check";

const history = createBrowserHistory();

FullStory.init({ orgId: fullStoryOrgId });

Sentry.init({
  dsn: sentryDSN,
  whitelistUrls: ["portal.govividly.com"],
  // eslint-disable-next-line no-undef
  release: GIT_SHA
});

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider maxSnack={3}>
        <DndProvider backend={HTML5Backend}>
          <Router history={history}>
            <Route component={App} />
          </Router>
        </DndProvider>
      </SnackbarProvider>
    </MuiThemeProvider>
  </StyledEngineProvider>,
  document.getElementById("app")
);

// Watch for changes from Webpack's HRM service; automatically patches code changes
if (module.hot) {
  console.info("Hot module replacement enabled.");
  module.hot.accept("./main.tsx", function () {
    console.info("Accepting the updated module!");
  });
}

// Inject hubspot script into our app
const script = document.createElement("script");
script.src = "https://js.hs-scripts.com/19646847.js";
script.async = true;
script.id = "hs-script-loader";
document.body.appendChild(script);
