import {
  BroadFigureType,
  ComparisonType,
  SubFigureType,
  TimeScaleType
} from "components/graphs/BudgetTool";
import { findIndex } from "lodash";
import {
  months,
  quarters,
  broadFigureTypes,
  comparisonTypes,
  diffTypes,
  Month,
  Quarter,
  REVENUE,
  TRADE_RATE,
  TRADE_SPEND,
  BUDGET,
  ESTIMATES,
  ACTUAL
} from "./constants";
import { numberRenderer } from "./dataRenderers";

const makeTableSubLegend = (typeA: BroadFigureType, typeB: SubFigureType) => {
  if (!broadFigureTypes.includes(typeA)) {
    throw new Error("Invalid first argument");
  }
  return {
    [REVENUE]: {
      [BUDGET]: "Budgeted Revenue",
      [ESTIMATES]: "Revenue LE",
      [ACTUAL]: "Revenue Actual"
    },
    [TRADE_RATE]: {
      [BUDGET]: "Budgeted Trade Rate",
      [ESTIMATES]: "LE Trade Rate",
      [ACTUAL]: "Trade Rate Actual"
    },
    [TRADE_SPEND]: {
      [BUDGET]: "Trade Spend Budget",
      [ESTIMATES]: "Trade Spend Expected",
      [ACTUAL]: "Trade Spend Actual"
    }
  }[typeA][typeB];
};

const getTimeData = (timeScale: TimeScaleType): Month[] | Quarter[] =>
  timeScale === "month" ? months : quarters;

const getQuarterFromMonth = (month: number) => Math.floor((month + 3) / 3);

const getTimeColumns = (timeScale: TimeScaleType) =>
  getTimeData(timeScale).map(timePeriod => ({
    Header: timePeriod,
    accessor: timePeriod,
    Cell: numberRenderer
  }));

const getColumns = (
  timeScale: TimeScaleType,
  showLegend = false,
  tableHeader = "Budget"
) => {
  const baseColumns = [
    {
      Header: "",
      accessor: "tableSubLegend",
      minWidth: 150
    },
    ...getTimeColumns(timeScale),
    {
      Header: "Total",
      accessor: "total",
      Cell: numberRenderer
    }
  ];

  const tableStructure = {
    Header: tableHeader,
    id: tableHeader.toLowerCase(),
    columns: baseColumns
  };

  if (showLegend) {
    tableStructure.columns = [
      {
        Header: "",
        accessor: "tableLegend",
        minWidth: 150
      },
      ...baseColumns
    ];
  }

  return [tableStructure];
};

const getTimePeriodFromMonth = (
  monthIndex: number,
  timeScale: TimeScaleType
) => {
  const month = months[monthIndex];
  const quarter = quarters[getQuarterFromMonth(monthIndex) - 1];

  return timeScale === "month" ? month : quarter;
};

const getExpandedIndicesFromIds = (data, sortColumns, expandedPivotGroups) => {
  const [{ id: columnToSort, desc } = {}] = sortColumns;
  let sortedData = data;

  if (columnToSort) {
    sortedData = data.sort(
      ({ [columnToSort]: columnA }, { [columnToSort]: columnB }) => {
        if (columnA > columnB) {
          return desc ? -1 : 1;
        }
        if (columnA < columnB) {
          return desc ? 1 : -1;
        }
        return 0;
      }
    );
  }
  return expandedPivotGroups.reduce((acc, expandedPivotGroupId) => {
    const activeIndex = findIndex(sortedData, { id: expandedPivotGroupId });
    if (activeIndex !== -1) {
      return { ...acc, [activeIndex]: true };
    }
    return acc;
  }, {});
};

const getRowDataDropdownItems = (
  tableSubLegend: string,
  comparisonType: ComparisonType
) => {
  const { title: comparisonTypeTitle } = comparisonTypes[comparisonType];

  return {
    [diffTypes.None]: tableSubLegend,
    [diffTypes.Absolute]: `${comparisonTypeTitle} Net`,
    [diffTypes.Relative]: `${comparisonTypeTitle} %`
  };
};

export {
  makeTableSubLegend,
  getTimeData,
  getQuarterFromMonth,
  getTimeColumns,
  getColumns,
  getTimePeriodFromMonth,
  getExpandedIndicesFromIds,
  getRowDataDropdownItems
};
