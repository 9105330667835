import React from "react";

import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Switch
} from "@mui/material";
import Subheader from "ui-library/Subheader";

import { getUserDataFirebase, updateUserDataFirebase } from "helpers/Firebase";

import { grey } from "@mui/material/colors";

const grey700 = grey["700"];

const styles = {
  notifs: {
    width: "60%"
  },
  subheader: {
    color: "primary.main"
  },
  productList: {
    borderColor: grey700,
    borderWidth: 1,
    borderStyle: "solid"
    // overflow: 'auto',
    // height: 300
  },
  moneyIcon: {
    width: 15,
    height: 15
  },
  thumbOff: {
    backgroundColor: "#b19cd9"
  },
  trackOff: {
    backgroundColor: "#ece6ff"
  },
  thumbSwitched: {
    backgroundColor: "#800080"
  },
  trackSwitched: {
    backgroundColor: "#b19cd9"
  },
  radioButton: {
    marginBottom: 16
  }
};

class NotifSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notifSettings: {
        addProm: false,
        editProm: false,
        approveProm: false,
        declineProm: false,
        commentsProm: false,
        adminApproval: false,
        email: false
      }
    };
  }

  handleToggleNotif = (notif, event) => {
    const { notifSettings } = this.state;
    notifSettings[notif] = event.target.checked;

    updateUserDataFirebase({ notif_settings: notifSettings });
    this.setState({
      notifSettings
    });
  };

  componentDidMount() {
    getUserDataFirebase(notifSettings => {
      this.setState({
        notifSettings
      });
    }, "notif_settings");
  }

  UNSAFE_componentWillReceiveProps(nextProps) {}

  render() {
    return (
      <div>
        <Subheader style={styles.subheader}>Notifications</Subheader>
        <div className="centering">
          <List style={styles.notifs}>
            <ListItem>
              <ListItemText primary="Promotion created" />
              <ListItemSecondaryAction>
                <Switch
                  edge="end"
                  checked={this.state.notifSettings.addProm}
                  onChange={this.handleToggleNotif.bind(null, "addProm")}
                  color="primary"
                />
              </ListItemSecondaryAction>
            </ListItem>

            <ListItem>
              <ListItemText primary="Promotion edited" />
              <ListItemSecondaryAction>
                <Switch
                  edge="end"
                  checked={this.state.notifSettings.editProm}
                  onChange={this.handleToggleNotif.bind(null, "editProm")}
                  color="primary"
                />
              </ListItemSecondaryAction>
            </ListItem>

            <ListItem>
              <ListItemText primary="Promotion approved" />
              <ListItemSecondaryAction>
                <Switch
                  edge="end"
                  checked={this.state.notifSettings.approveProm}
                  onChange={this.handleToggleNotif.bind(null, "approveProm")}
                  color="primary"
                />
              </ListItemSecondaryAction>
            </ListItem>

            <ListItem>
              <ListItemText primary="Promotion declined" />
              <ListItemSecondaryAction>
                <Switch
                  edge="end"
                  checked={this.state.notifSettings.declineProm}
                  onChange={this.handleToggleNotif.bind(null, "declineProm")}
                  color="primary"
                />
              </ListItemSecondaryAction>
            </ListItem>

            <ListItem>
              <ListItemText primary="Promotion comments added" />
              <ListItemSecondaryAction>
                <Switch
                  edge="end"
                  checked={this.state.notifSettings.commentsProm}
                  onChange={this.handleToggleNotif.bind(null, "commentsProm")}
                  color="primary"
                />
              </ListItemSecondaryAction>
            </ListItem>

            <ListItem>
              <ListItemText primary="Promotion needs approval" />
              <ListItemSecondaryAction>
                <Switch
                  edge="end"
                  checked={this.state.notifSettings.adminApproval}
                  onChange={this.handleToggleNotif.bind(null, "adminApproval")}
                  color="primary"
                />
              </ListItemSecondaryAction>
            </ListItem>

            <ListItem>
              <ListItemText primary="Daily e-mail notifications" />
              <ListItemSecondaryAction>
                <Switch
                  edge="end"
                  checked={this.state.notifSettings.email}
                  onChange={this.handleToggleNotif.bind(null, "email")}
                  color="primary"
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </div>
        <br />
      </div>
    );
  }
}

export default NotifSelection;
