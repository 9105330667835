import { gzip } from "pako";
import XLSX from "xlsx";

function confidenceFilter(tableData, confidence, confidenceThresholds, source) {
  switch (confidence) {
    case "High":
      return tableData.filter(row => {
        return (
          row["Matched Line Key"] != "-" &&
          (parseFloat(row["Spend Rate Difference"]) <=
            row["Spend Rate"] * (confidenceThresholds[source] || 0) * 0.01 ||
            row["Matched Line Spend Rate"] == "")
        );
      });
    case "Low":
      return tableData.filter(row => {
        return (
          row["Matched Line Key"] != "-" &&
          parseFloat(row["Spend Rate Difference"]) >
            row["Spend Rate"] * (confidenceThresholds[source] || 0) * 0.01
        );
      });
    case "Needs Attn.":
      return tableData.filter(row => {
        return (
          row["Matched Line Key"] == "-" ||
          row["Matched Line Key"].includes("|")
        );
      });
    default:
      return tableData;
  }
}

function makeExportJSON({
  allLinesDFTable,
  accumDFTable,
  confidence,
  confidenceThresholds,
  source
}) {
  const sheetNames = {
    Any: ["Accum. Match - All", "All Lines Match - All"],
    High: ["Accum. Match - High Conf.", "All Lines Match - High Conf."],
    Low: ["Accum. Match - Low Conf.", "All Lines Match - Low Conf."],
    "Needs Attn.": [
      "Accum. Match - Needs Attn.",
      "All Lines Match - Needs Attn."
    ]
  };
  const [accumSheetName, allSheetName] = sheetNames[confidence];
  const sheetData = {};
  sheetData[accumSheetName] = confidenceFilter(
    accumDFTable,
    confidence,
    confidenceThresholds,
    source
  );
  sheetData[allSheetName] = confidenceFilter(
    allLinesDFTable,
    confidence,
    confidenceThresholds,
    source
  );
  const data = new Blob([gzip(JSON.stringify(sheetData))], {
    type: "application/json"
  });
  return data;
}

function exportBothViews({
  allLinesDFTable,
  accumDFTable,
  confidence,
  confidenceThresholds,
  source,
  wsAccumOptions,
  wsAllLinesOptions
}) {
  const workbook = XLSX.utils.book_new();
  workbook.SheetNames.push("Accum. Match - High Conf.");
  workbook.Sheets["Accum. Match - High Conf."] = XLSX.utils.json_to_sheet(
    confidenceFilter(accumDFTable, "High", confidenceThresholds, source),
    wsAccumOptions
  );
  workbook.SheetNames.push("All Lines Match - High Conf.");
  workbook.Sheets["All Lines Match - High Conf."] = XLSX.utils.json_to_sheet(
    confidenceFilter(allLinesDFTable, "High", confidenceThresholds, source),
    wsAllLinesOptions
  );
  workbook.SheetNames.push("Accum. Match - Low Conf.");
  workbook.Sheets["Accum. Match - Low Conf."] = XLSX.utils.json_to_sheet(
    confidenceFilter(accumDFTable, "Low", confidenceThresholds, source),
    wsAccumOptions
  );
  workbook.SheetNames.push("All Lines Match - Low Conf.");
  workbook.Sheets["All Lines Match - Low Conf."] = XLSX.utils.json_to_sheet(
    confidenceFilter(allLinesDFTable, "Low", confidenceThresholds, source),
    wsAllLinesOptions
  );
  workbook.SheetNames.push("Accum. Match - Needs Attn.");
  workbook.Sheets["Accum. Match - Needs Attn."] = XLSX.utils.json_to_sheet(
    confidenceFilter(accumDFTable, "Needs Attn.", confidenceThresholds, source),
    wsAccumOptions
  );
  workbook.SheetNames.push("All Lines Match - Needs Attn.");
  workbook.Sheets["All Lines Match - Needs Attn."] = XLSX.utils.json_to_sheet(
    confidenceFilter(
      allLinesDFTable,
      "Needs Attn.",
      confidenceThresholds,
      source
    ),
    wsAllLinesOptions
  );
  workbook.SheetNames.push("Accumulated Match");
  const wsAccum = XLSX.utils.json_to_sheet(accumDFTable, wsAccumOptions);
  workbook.Sheets["Accumulated Match"] = wsAccum;

  workbook.SheetNames.push("All Lines Match");
  const wsAllLines = XLSX.utils.json_to_sheet(
    allLinesDFTable,
    wsAllLinesOptions
  );
  workbook.Sheets["All Lines Match"] = wsAllLines;
  const wbout = XLSX.write(workbook, {
    bookType: "xlsx",
    bookSST: false,
    type: "array"
  });
  return new Blob([wbout], { type: "application/octet-stream" });
}

export { confidenceFilter, makeExportJSON, exportBothViews };
