import { cloudRunURL } from "helpers/Firebase";
import { camelizeKeys, deepClone } from "helpers/DataProcessing";
import axios from "axios";
import {
  FREE_TRIAL_DAYS,
  SPECIAL_TRIAL_DAYS
} from "components/Deductions/constants/FreeTrial";
import XLSX from "xlsx";
import moment from "moment";

export const getDsmEngagementDownload = dsmTrialEngagementInfo => {
  const workbook = XLSX.utils.book_new();
  if (dsmTrialEngagementInfo.length > 0) {
    const formattedDsmTrialEngagementInfo = deepClone(
      dsmTrialEngagementInfo
    ).map(obj => {
      delete obj.id;
      return obj;
    });
    workbook.SheetNames.push("DSM Engagement Tracker");
    workbook.Sheets["DSM Engagement Tracker"] = XLSX.utils.json_to_sheet(
      formattedDsmTrialEngagementInfo
    );
  }
  XLSX.writeFile(workbook, `DsmFreeTrialEngagementTracker.xlsx`);
};

const getDayDiffBetweenDates = (date1, date2) => {
  const differences = date1.getTime() - date2.getTime();
  return Math.ceil(differences / (1000 * 3600 * 24));
};

export const getFilesScannedSeverityColor = filesScanned => {
  if (filesScanned >= 5) {
    return "green";
  }
  if (filesScanned > 1) {
    return "yellow";
  }
  return "red";
};

export const getLastUsageSeverityColor = lastScanDate => {
  if (Number.isNaN(Date.parse(lastScanDate))) {
    return "gray";
  }

  const remainingDates = getDayDiffBetweenDates(
    new Date(),
    new Date(lastScanDate)
  );
  if (remainingDates <= 7) {
    return "green";
  }
  if (remainingDates <= 14) {
    return "yellow";
  }
  return "red";
};

const getExpirationDate = (startDate, specialTrial) => {
  const trialLength = specialTrial ? SPECIAL_TRIAL_DAYS : FREE_TRIAL_DAYS;

  if (Number.isNaN(Date.parse(startDate))) {
    return "gray";
  }

  const expirationDate = new Date(startDate);
  expirationDate.setDate(expirationDate.getDate() + trialLength);
  return expirationDate;
};

const getRemainingDays = (startDate, specialTrial) => {
  const expirationDate = getExpirationDate(startDate, specialTrial);
  const now = new Date();
  return getDayDiffBetweenDates(expirationDate, now);
};

const isTrialExpired = (startDate, specialTrial) => {
  const remainingDates = getRemainingDays(startDate, specialTrial);
  // Trial has already expired if expirationDate is less than present date
  return remainingDates < 0;
};

export const getRemainingTimeSeverityColor = (startDate, specialTrial) => {
  const remainingDates = getRemainingDays(startDate, specialTrial);
  // Trial has already expired if expirationDate is less than present date
  if (remainingDates < 0) {
    return null;
  }
  if (remainingDates < 10) {
    return "red";
  }
  if (remainingDates < 24) {
    return "yellow";
  }
  return "green";
};

interface RemainingDateInfo {
  specialTrial: boolean;
  startDate: string | Date;
}

interface FormattedCompanyData {
  id: string;
  companyName: string;
  pagesScanned: number;
  filesScanned: number;
  lastScannedDate: string | Date;
  specialTrial: boolean;
  remainingDateInfo: RemainingDateInfo;
  isExpired: boolean;
}

export const getDsmTrialEngagementInfo = async () => {
  const { data: response } = await axios.get(
    `${cloudRunURL}/api/get_dsm_trial_engagement_info`
  );
  const trialData = response.data;
  const res: FormattedCompanyData[] = [];
  let indexKey = 0;
  trialData.forEach(companyObj => {
    const lastScannedDate =
      companyObj.last_scanned_date !== ""
        ? moment(companyObj.last_scanned_date).format("L")
        : "Unknown";
    const startDate =
      companyObj.start_date !== ""
        ? moment(companyObj.start_date).format("L")
        : "Unknown";

    const newData = camelizeKeys(companyObj);
    newData.id = indexKey;
    newData.lastScannedDate = lastScannedDate;
    newData.startDate = startDate;
    const specialTrial = newData.special_trial;
    const remainingDateInfo: RemainingDateInfo = {
      startDate,
      specialTrial
    };

    newData.remainingDateInfo = remainingDateInfo;
    newData.pagesScanned = newData.pagesIn;
    delete newData.pagesIn;

    newData.isExpired = isTrialExpired(startDate, specialTrial);
    res.push(newData);
    indexKey++;
  });
  return res;
};
