import React, { CSSProperties } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import moment from "moment";
import "../../../../helpers/datagridStyles.css";
import { getRemainingTimeSeverityColor } from "./DSMEngagementTrackerUtilities";

export const columns = [
  {
    field: "companyName",
    headerClassName: "datagrid-header",
    headerName: "Company",
    flex: 1,
    description: `Name of company.`
  },
  {
    field: "pagesScanned",
    headerClassName: "datagrid-header",
    headerName: "Pages Scanned",
    flex: 1,
    description: `Number of pages scanned.`
  },
  {
    field: "filesScanned",
    headerClassName: "datagrid-header",
    headerName: "Files Scanned",
    flex: 1
  },
  {
    field: "remainingDateInfo",
    headerClassName: "datagrid-header",
    headerName: "Start Date",
    flex: 1,
    description: `Starting Date for Free Trial.
        Coloration indications severity for sales reach out.
          Green indicated first 0 - 6 days of the trial.
          Yellow indicates days 7 - 21 of the trial.
          Red indicates days 21 - 30 of the trial (i.e. last 10 days).`,
    sortComparator: (_v1, _v2, param1, param2) =>
      moment(param1.value.startDate).diff(moment(param2.value.startDate)),
    renderCell: params => {
      const colorStyle: CSSProperties = {
        fill: getRemainingTimeSeverityColor(
          params.value.startDate,
          params.value.specialTrial
        )
      };
      return (
        <>
          <FiberManualRecordIcon style={colorStyle} />
          {params.value.startDate}
        </>
      );
    }
  },
  {
    field: "lastScannedDate",
    headerClassName: "datagrid-header",
    headerName: "Last Used",
    flex: 1,
    sortComparator: (_v1, _v2, param1, param2) => {
      if (param1.value === "Unknown") {
        return -1;
      }
      if (param2.value === "Unknown") {
        return 1;
      }
      return moment(param1.value).diff(moment(param2.value)) ?? -1;
    }
  }
];
