import { styled } from "@mui/material/styles";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    border: 0,
    borderRadius: "8px",
    "&.MuiToggleButton-root": {
      color: theme.palette.primary.main,
      border: "1px solid",
      borderColor: theme.palette.background.outline,
      padding: "6px 24px",
      textTransform: "none",
      lineHeight: "1.25rem",
      letterSpacing: "0.1px",
      "&:focus": {
        outline: "none"
      },

      "&:hover": {
        backgroundColor: "rgba(103, 80, 164, 0.08)"
      }
    },
    "&.Mui-focused": {},
    "&.Mui-selected": {
      border: 0,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.primary.light
      }
    },
    "&.Mui-disabled": {
      color: "#1A1C19",
      opacity: "0.38",
      border: "1px solid rgba(31, 31, 31, 0.12)"
    }
  }
}));

export default StyledToggleButtonGroup;
