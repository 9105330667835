import React from "react";
import { useSelector } from "react-redux";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { RootState } from "store";

const DRMInvoiceModalTitlebar = () => {
  const { stepIndex } = useSelector(
    (state: RootState) => state.invoiceLinesMatching
  );
  return (
    <Stepper activeStep={stepIndex}>
      <Step>
        <StepLabel>Match Invoice Line to Promotion</StepLabel>
      </Step>
      <Step>
        <StepLabel>Confirm Matches Preview</StepLabel>
      </Step>
    </Stepper>
  );
};

export default DRMInvoiceModalTitlebar;
