import React from "react";
import { isEqual } from "lodash-es";
import classNames from "classnames";

import Card from "ui-library/Card";
import IconButton from "ui-library/IconButton";
import Subheader from "ui-library/Subheader";
import MenuItem from "ui-library/MenuItem";
import Select from "ui-library/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Divider } from "pui-react-dividers";

import { sortByField, objToArray } from "helpers/sortByDate";
import { getJSXListsFirebase } from "helpers/Firebase";
import { getWeekEnd } from "helpers/Time";
import { grey } from "@mui/material/colors";
import { Stack } from "@mui/material";
import { getForecastData } from "../ForecastFirebase";
import ActualsVsForecastComparison from "./ActualsVsForecastComparison";

const grey700 = grey["700"];

const styles = {
  notifs: {
    width: "500px"
  },
  subheader: {
    fontSize: 20,
    marginLeft: "-16px"
  },
  input: {
    margin: 5
  },
  loading: {
    height: $(window).height()
  },
  tabs: {
    backgroundColor: grey700
  },
  tabsBox: {
    borderColor: grey700,
    borderWidth: 2,
    borderStyle: "solid"
  },
  toolbarButton: {
    marginLeft: 5,
    marginRight: 5
  },
  divider: {
    margin: "30px auto",
    width: "50%"
  },
  radioButton: {
    marginBottom: 16
  },
  textfield: {
    "white-space": "pre-line"
  }
};

class ActualsVsForecast extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customersJSX: [],
      mode: "retailers",
      hideOptions: false,
      weeks: [],
      loading: false,
      percentComplete: 0,
      subComponentKey: Math.random()
    };
  }

  handleResetFields = () => {
    this.setState({
      year: new Date().getFullYear(),
      pnlGroup: 0,
      source: 0,
      product: 0,
      customer: 0
    });
  };

  getCustomersJSX = customers => {
    const customersList = sortByField("name", objToArray(customers), true);
    const customersJSX = [];
    for (let i = 0; i < customersList.length; i++) {
      const customer = customersList[i];
      customersJSX.push(
        <MenuItem
          value={customer.key}
          key={customer.key}
          children={customer.name}
        />
      );
    }
    return customersJSX;
  };

  getPastYearWeeks = () => {
    const weeks = [];
    const date = new Date();
    date.setDate(date.getDate() + 52 * 7);
    for (let i = 0; i <= 104; i++) {
      weeks.push(getWeekEnd(date));
      date.setDate(date.getDate() - 7);
    }
    weeks.reverse();
    return weeks;
  };

  getData = props => {
    const { companyid } = props.db;
    getForecastData(companyid, "meta", metaSnapshot => {
      getJSXListsFirebase(
        props.db,
        allJSX => {
          this.setState({
            productsJSX: allJSX.productsJSX,
            product: "Total"
          });
        },
        null,
        true
      );
      let meta = metaSnapshot.val();
      const { loading } = meta;
      meta = meta || {};
      const weeks = meta.weeks || [];
      const tableNames = {
        retailer: "retailers",
        all_other: "all_other_buckets",
        distributor: "distributors"
      };
      const customerKeys = meta[tableNames[props.mode]] || [];
      const customers = {};
      for (const customerKey of customerKeys) {
        customers[customerKey] = props.db.customers[customerKey];
      }
      const customersJSX = this.getCustomersJSX(customers);
      this.setState({
        customer: "Total",
        customersJSX,
        weeks,
        mode: props.mode,
        loading,
        percentComplete: meta.percentComplete,
        subComponentKey: Math.random()
      });
    });
  };

  componentDidMount() {
    this.getData(this.props);
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props, prevProps)) {
      this.getData(this.props);
    }
  }

  render() {
    return (
      <div>
        <div style={{ height: $(window).height() - 130, overflow: "auto" }}>
          <div
            className={classNames("analytics-options", {
              hidden: this.state.hideOptions
            })}>
            <Stack>
              <Subheader>Filter Inputs</Subheader>
              <div>
                <IconButton
                  color="tonal"
                  isBackgroundColor
                  tooltip="Clear Filters"
                  size="large">
                  <RefreshIcon />
                </IconButton>
              </div>
            </Stack>
            <br />
            <div className="row">
              <div className="col-lg-6">
                <Card title="Customer">
                  <Select
                    floatingLabelText="Customer"
                    value={this.state.customer}
                    onChange={(event, index, value) => {
                      this.setState({
                        customer: value
                      });
                    }}
                    fullWidth
                    style={styles.input}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      getContentAnchorEl: null
                    }}>
                    <MenuItem children={<b>Total</b>} value="Total" />
                    {this.state.customersJSX}
                  </Select>
                </Card>
              </div>
              <div className="col-lg-6">
                <Card title="Product">
                  <Select
                    floatingLabelText="Product"
                    value={this.state.product}
                    onChange={(event, index, value) => {
                      this.setState({
                        product: value
                      });
                    }}
                    fullWidth
                    style={styles.input}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      getContentAnchorEl: null
                    }}>
                    <MenuItem children={<b>Total</b>} value="Total" />
                    {this.state.productsJSX}
                  </Select>
                </Card>
              </div>
            </div>
          </div>
          <Divider />
          <div className="centering">
            <div
              className="options-toggle-button"
              onClick={() => {
                this.setState({ hideOptions: !this.state.hideOptions });
              }}>
              {this.state.hideOptions ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowUpIcon />
              )}
            </div>
          </div>
          <br />
          <ActualsVsForecastComparison
            db={this.props.db}
            customer={this.state.customer}
            product={this.state.product}
            mode={this.state.mode}
            weeks={this.state.weeks}
            loading={this.state.loading}
            percentComplete={this.state.percentComplete}
            key={this.state.subComponentKey}
          />
        </div>
      </div>
    );
  }
}

export default ActualsVsForecast;
