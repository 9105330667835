import React from "react";
import Button from "ui-library/Button";
import EditIcon from "@mui/icons-material/Edit";

import { Stack } from "@mui/material";

const getColumns = (
  formik,
  setShowCustomerMappingTable,
  toggleMappingActiveState
) => [
  { headerName: "Portal", field: "portal", flex: 1 },
  { headerName: "Username", field: "username", flex: 1 },
  {
    headerName: "Deduction Positive?",
    field: "isDeductionPositive",
    flex: 1
  },
  {
    headerName: "Customer",
    field: "customerName",
    flex: 1
  },
  {
    headerName: "Company",
    field: "companyName",
    flex: 1
  },
  {
    headerName: " ",
    field: "",
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    resizable: false,
    width: 260,
    renderHeader: () => <></>,
    renderCell: params => {
      return (
        <Stack direction="row" spacing={2}>
          {!params.row.isInactive && (
            <Button
              label="Edit"
              icon={<EditIcon />}
              onClick={() => {
                formik.setValues({ ...params.row });
                setShowCustomerMappingTable(false);
              }}
            />
          )}
          <Button
            label={params.row.isInactive ? "Set Active" : "Set Inactive"}
            onClick={() => {
              toggleMappingActiveState(params.row, !!params.row.isInactive);
            }}
            disabled={!params.row.isInactive}
          />
        </Stack>
      );
    }
  }
];
export default getColumns;
