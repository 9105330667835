import React from "react";
import { TableItemTypes, isDefined } from "../utilities";
import {
  AllDetailFields,
  ForecastViewLabel,
  ForecastViewValue,
  EditableRow
} from "../types";
import {
  BASE_CELL,
  LINE_COLOR,
  CELL_VERTICAL_LINE,
  STICKY_CELL,
  CELL_WIDTH,
  DetailCell
} from "./DetailCell";

export type SubrowValues = string[];

export interface ISubtable {
  type: TableItemTypes.SUBTABLE;
  parentId: string;
  parentIndex: number;
  data: Record<string, SubrowValues>;
  forecastBegins?: Date;
}

export const SUBTABLE_ORDER: ForecastViewValue[] = [
  "sales",
  "revenue",
  "is_prom",
  "lift",
  "seasonality",
  "stores",
  "velocity",
  "base_sales"
];

type FieldName = ForecastViewLabel | "Forecast Begins";
export const FIELD_TO_NAME_MAPPING: Record<AllDetailFields, FieldName> = {
  revenue: "Revenue",
  is_prom: "Promotion",
  lift: "Lift",
  seasonality: "Seasonality",
  stores: "Stores",
  velocity: "Velocity",
  base_sales: "Base Sales",
  sales: "Sales",
  forecast_begins: "Forecast Begins"
};

export const fieldToName = (field: AllDetailFields) => {
  const label = FIELD_TO_NAME_MAPPING[field];
  return label ?? field;
};

const RIGHT_ENDCAP_DIVIDER = (
  <div
    style={{
      ...CELL_VERTICAL_LINE,
      right: 0,
      boxShadow: "2px 0 5px 0px rgba(0,0,0,0.5)"
    }}
  />
);

const LEFT_ENDCAP_DIVIDER = (
  <div
    style={{
      ...CELL_VERTICAL_LINE,
      left: 0,
      boxShadow: "-2px 0 5px 0px rgba(0,0,0,0.5)"
    }}
  />
);

const SUBTABLE_COLOR = "#f2f0ef";
const COLORED_BASE = { ...BASE_CELL, background: SUBTABLE_COLOR };

export const Subtable = ({
  subtable,
  editableRow
}: {
  subtable: ISubtable;
  editableRow: EditableRow;
}) => {
  const { data, forecastBegins } = subtable;

  return (
    <table
      style={{
        background: SUBTABLE_COLOR,
        borderRadius: "3px",
        borderCollapse: "collapse",
        borderTop: `1px solid ${LINE_COLOR}`,
        borderBottom: `1px solid ${LINE_COLOR}`
      }}>
      {SUBTABLE_ORDER.map(field =>
        data[field] ? { values: data[field], field } : undefined
      )
        .filter(isDefined)
        .map(({ field, values }, index) => {
          const [first, ...rest] = values;
          const last = rest.pop();
          const borderTop = index !== 0 ? `1px solid ${LINE_COLOR}` : undefined;
          return (
            <tr>
              <td
                style={{
                  ...COLORED_BASE,
                  ...STICKY_CELL,
                  minWidth: 240,
                  width: 240,
                  left: 0,
                  borderTop
                }}>
                {first}
                {RIGHT_ENDCAP_DIVIDER}
              </td>

              {rest.map((value, index) => {
                return (
                  <DetailCell
                    key={`${index}-${field}-${editableRow.customer}-${editableRow.product}`}
                    value={value}
                    columnIndex={index}
                    style={{ ...COLORED_BASE, borderTop }}
                    forecastBegins={forecastBegins}
                    field={field}
                    editableRow={editableRow}
                  />
                );
              })}

              <td
                style={{
                  ...COLORED_BASE,
                  ...STICKY_CELL,
                  width: CELL_WIDTH,
                  right: 0,
                  textAlign: "left",
                  borderTop
                }}>
                {last}
                {LEFT_ENDCAP_DIVIDER}
              </td>
            </tr>
          );
        })}
    </table>
  );
};
