import React from "react";

import InputIcon from "@mui/icons-material/Input";
import ToysIcon from "@mui/icons-material/Toys";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";

import ForecastParameters from "./ForecastParameters";
import Seasonality from "./Seasonality";

class ForecastSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      meta: {},
      userCompany: null,
      newRevSource: "",
      newDedSource: "",
      importLoading: false,
      customersJSX: [],
      selectedIndex: 0
    };
  }

  render() {
    const editPermission = this.props.db.permissions.includes("admin");
    return (
      <div>
        <Paper>
          <BottomNavigation
            value={this.state.selectedIndex}
            onChange={(event, value) => {
              this.setState({ selectedIndex: value });
            }}
            showLabels>
            <BottomNavigationAction
              label="Forecast Parameters"
              icon={<InputIcon />}
              value={0}
            />
            <BottomNavigationAction
              label="Seasonality"
              icon={<ToysIcon />}
              value={1}
            />
          </BottomNavigation>
        </Paper>
        <br />
        {this.state.selectedIndex == 0 && (
          <ForecastParameters
            db={this.props.db}
            openClose={this.props.openClose}
            editPermission={editPermission}
          />
        )}
        {this.state.selectedIndex == 1 && (
          <Seasonality
            db={this.props.db}
            openClose={this.props.openClose}
            editPermission={editPermission}
          />
        )}
      </div>
    );
  }
}

export default ForecastSettings;
