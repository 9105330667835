import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "ui-library/Button";
import Select from "ui-library/Select";
import MenuItem from "ui-library/MenuItem";
import { firebase } from "helpers/Firebase";
import {
  changeBudgetFieldToZero,
  removeDataForYears,
  WILDCARD
} from "helpers/Budget";
import { useOpenClose } from "contexts/OpenClose";

import { grey, common, red } from "@mui/material/colors";
import { Stack } from "@mui/material";

const {
  grey: { 700: grey700 },
  common: { white },
  red: { 400: red400, 600: red600 }
} = { grey, common, red };

const useStyles = makeStyles(theme =>
  createStyles({
    gridItem: {
      padding: theme.spacing(1)
    },
    gridItemButton: {
      textAlign: "right"
    }
  })
);

const getCompanyId = () =>
  new Promise(resolve => {
    const { uid } = firebase.auth().currentUser;
    firebase
      .database()
      .ref(`users/${uid}/company_id`)
      .once("value", snapshot => {
        resolve(snapshot.val());
      });
  });

const ConfirmDeletion = ({ label, itemOnClick, yearsJSX }) => {
  const [selectedYears, setSelectedYears] = useState([WILDCARD]);
  const { closeAppModal } = useOpenClose();
  return (
    <Stack direction="column" alignItems="inherit" spacing={2}>
      <Stack direction="column" alignItems="flex-start" spacing={2}>
        <Typography>{`Are you sure you want to delete ${label}?`}</Typography>
        <Select
          floatingLabelText="Select Year(s)"
          value={selectedYears}
          onChange={(event, index, value) => {
            let sanitizedValue = value;
            if (selectedYears.includes(WILDCARD)) {
              sanitizedValue = value.filter(year => year !== WILDCARD);
            } else if (value.includes(WILDCARD)) {
              sanitizedValue = [WILDCARD];
            }
            setSelectedYears(sanitizedValue);
          }}
          fullWidth
          multiple>
          <MenuItem value={WILDCARD}>All Years</MenuItem>
          {yearsJSX}
        </Select>
      </Stack>
      <Stack justifyContent="flex-end">
        <Button
          label="Yes, I'm sure"
          color="error"
          variant="text"
          onClick={() => {
            itemOnClick(selectedYears);
            closeAppModal();
          }}
        />
        <Button label="No, go back" variant="text" onClick={closeAppModal} />
      </Stack>
    </Stack>
  );
};

const DeleteBudgetData = ({ budget, latestEstimate, yearsJSX }) => {
  const { setAppModal } = useOpenClose();
  const classes = useStyles();
  const [companyId, setCompanyId] = useState(null);
  (async () => {
    const companyIdForUser = await getCompanyId();
    setCompanyId(companyIdForUser);
  })();
  const itemsForDeletion = [
    {
      label: "Revenue Budget Data",
      itemOnClick: selectedYears => {
        const newBudgetData = changeBudgetFieldToZero(
          budget,
          "revenueBudget",
          selectedYears
        );
        firebase
          .database()
          .ref(`companies/${companyId}/budget/budget`)
          .set(newBudgetData);
      }
    },
    {
      label: "Trade Budget Data",
      itemOnClick: selectedYears => {
        const newBudgetData = changeBudgetFieldToZero(
          budget,
          "tradeBudget",
          selectedYears
        );
        firebase
          .database()
          .ref(`companies/${companyId}/budget/budget`)
          .set(newBudgetData);
      }
    },
    {
      label: "Revenue LE Data",
      itemOnClick: selectedYears => {
        firebase
          .database()
          .ref(`companies/${companyId}/budget/latest_estimate`)
          .set(removeDataForYears(latestEstimate, selectedYears));
      }
    }
  ];
  const itemOnClickWrapper = (label, itemOnClick) => {
    setAppModal(
      "Delete Data",
      <ConfirmDeletion
        label={label}
        itemOnClick={itemOnClick}
        yearsJSX={yearsJSX}
      />
    );
  };

  return (
    <Grid container>
      {itemsForDeletion.map(({ label, itemOnClick }) => (
        <Grid container item className={classes.gridItem}>
          <Grid item md={8}>
            <Typography variant="subtitle1">{label}</Typography>
          </Grid>
          <Grid item md={4} className={classes.gridItemButton}>
            <Button
              variant="contained"
              label="Delete"
              secondary
              onClick={() => itemOnClickWrapper(label, itemOnClick)}
            />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export { DeleteBudgetData };
