import React from "react";
import { styled } from "@mui/material/styles";
import Stepper, {
  StepperProps as MuiStepperProps
} from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses
} from "@mui/material/StepConnector";
import { Typography } from "@mui/material";
import { StepIconProps } from "@mui/material/StepIcon";

const StyledConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 17,
    left: "calc(-50% + -60px)",
    right: "calc(50% + 127px)"
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderWidth: 4,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 2
  }
}));

const StyledStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  zIndex: 1,
  border: "2px solid",
  borderColor: "#ccc",
  color: "#ccc",
  width: 36,
  height: 36,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  fontSize: 18,
  ...(ownerState.active && {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main
  }),
  ...(ownerState.completed && {
    borderColor: theme.palette.primary.main,
    background: theme.palette.primary.main,
    color: "#ffffff"
  })
}));

const StyledStepLabel = styled(StepLabel)(() => ({
  alignItems: "flex-start",
  padding: 0,

  "& .MuiStepLabel-labelContainer": {
    textAlign: "left",
    width: "80%",
    "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
      display: "inline-block",
      textAlign: "left"
    }
  }
}));

function StyledStepIcon(props: StepIconProps) {
  const { active, completed, className, icon } = props;
  return (
    <StyledStepIconRoot
      ownerState={{ completed, active }}
      className={className}>
      {completed ? <Check /> : icon}
    </StyledStepIconRoot>
  );
}

type StepperProps = {
  style?: MuiStepperProps["sx"];
  steps: { label: string; icon: Element | React.ReactElement }[];
  activeStep: number;
};

export default function CustomizedHorizontalStepper({
  style,
  steps,
  activeStep
}: StepperProps) {
  return (
    <Stepper
      sx={{ width: "660px", my: 3, ...style }}
      alternativeLabel
      activeStep={activeStep}
      connector={<StyledConnector />}>
      {steps.map((item, index) => (
        <Step sx={{ p: 0 }} key={item.label}>
          <StyledStepLabel icon={item.icon} StepIconComponent={StyledStepIcon}>
            <Typography variant="labelLarge"> {`Step ${index + 1}`}</Typography>{" "}
            <Typography variant="bodyLarge">{`${item.label}`}</Typography>
          </StyledStepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
