// import party from "party-js";
import React from "react";

export const Confetti = props => {
  // party.resolvableShapes.CresicorLeaf = `<img src="/small-Logo.png"/>`;

  // const h3ref = useRef();

  // if (!props.failure) {
  //   useEffect(() => {
  //     party.confetti(h3ref.current, {
  //       shapes: ["CresicorLeaf"],
  //       count: party.variation.range(100, 140),
  //       speed: party.variation.range(600, 950)
  //     });
  //   }, []);
  // }

  return (
    <div style={{ marginBottom: "25px" }}>
      <h3> {props.failure ? "Failure..." : "Success!!"}</h3>
    </div>
  );
};
