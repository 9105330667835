function promSatisfiesSearch(prom, searchProm) {
  var searchProm = { ...searchProm };
  var prom = { ...prom };

  // Check if both the promKey or name contain what the
  // user entered, and the customer name and distributor
  // exactly match what the user entered.
  // (Case insensitive.)
  const textFields = [
    "promKey",
    "name",
    "customerName",
    "customer",
    "distributor",
    "product",
    "productGroup"
  ];
  const exactMatch = [false, false, true, true, true, true, true];
  for (var i = 0; i < textFields.length; i++) {
    var field = textFields[i];
    if (field in searchProm) {
      var searchPromField = searchProm[field]?.toString().toLowerCase();
      let promField = prom[field];

      if (!promField && !searchPromField) continue;
      if (!promField && searchPromField) return false;

      if (prom[field].constructor != Array) {
        promField = [prom[field]];
      }

      if (
        promField.every(x =>
          exactMatch[i]
            ? x.toLowerCase() != searchPromField
            : x.toLowerCase().indexOf(searchPromField) < 0
        )
      ) {
        return false;
      }
      if (
        !exactMatch[i] &&
        promField.every(
          x => x.toString().toLowerCase().indexOf(searchPromField) < 0
        )
      ) {
        return false;
      }
    }
  }

  const selectFields = ["status", "closed"];
  for (var i = 0; i < selectFields.length; i++) {
    var field = selectFields[i];
    if (searchProm[field] != null) {
      if (searchProm[field].constructor !== Array) {
        // enabled multiple options for select fields
        searchProm[field] = [searchProm[field]];
      }
      if (searchProm[field].every(x => x != prom[field])) {
        return false;
      }
    }
  }

  const arrayFields = ["tags"];
  for (var i = 0; i < arrayFields.length; i++) {
    var field = arrayFields[i];
    if (searchProm[field] != null) {
      if (searchProm[field].constructor !== Array) {
        // enabled multiple options for select fields
        searchProm[field] = [searchProm[field]];
      }
      if (searchProm[field].every(x => !prom[field]?.includes(x))) {
        return false;
      }
    }
  }

  const lineFields = ["type"];
  for (var i = 0; i < lineFields.length; i++) {
    var field = lineFields[i];
    if (
      searchProm[field] != null &&
      Object.values(prom.lines).every(line => searchProm[field] != line[field])
    ) {
      return false;
    }
  }

  const fileFields = ["attachments"];
  for (var i = 0; i < fileFields.length; i++) {
    var field = fileFields[i];
    // if user requests YES attachments or NO attachments
    if (searchProm[field] != null) {
      if (searchProm[field] && (!prom.fileInfo || prom.fileInfo.length <= 0)) {
        return false;
      }
      if (!searchProm[field] && prom.fileInfo && prom.fileInfo.length > 0) {
        return false;
      }
    }
  }

  // check if provided promotion has contract
  const expectedContract = searchProm.contracts;
  let hasContracts = false;
  if (expectedContract != null) {
    if (prom.fileInfo) {
      for (const fileName in prom.fileInfo) {
        if (prom.fileInfo[fileName].isContract) {
          // TODO VERY AD HOC
          // if we don't care about contracts, or if we don't care about product groups
          if (!(expectedContract && "productGroup" in searchProm)) {
            hasContracts = true;
            break;
          } else {
            // now we care about contracts and product groups
            const attachedLines = prom.fileInfo[fileName].attachedLine;
            for (var i = 0; i < attachedLines.length; i++) {
              // if any contracts are attached to entire promotion, we're good
              if (attachedLines[i] == 0) {
                hasContracts = true;
                break;
              } else {
                const group = prom.productGroup[attachedLines[i] - 1];
                if (group == searchProm.productGroup) {
                  // check individual line product group and match against search prom group
                  hasContracts = true;
                  break;
                }
              }
            }
            if (hasContracts) break;
          }
        }
      }
    }
    if (
      (hasContracts && !expectedContract) ||
      (!hasContracts && expectedContract)
    ) {
      return false;
    }
  }

  // if a promotion hasn't been starred/unstarred yet,
  // treat it as unstarred
  const starred = !!prom.starred;
  if (
    (searchProm.month != null && searchProm.month != prom.month) ||
    (searchProm.year != null && searchProm.year != prom.year) ||
    (searchProm.startMonth != null &&
      searchProm.startYear != null &&
      (searchProm.startYear > prom.year ||
        (searchProm.startYear == prom.year &&
          searchProm.startMonth > prom.month))) ||
    (searchProm.endMonth != null &&
      searchProm.endYear != null &&
      (searchProm.endYear < prom.year ||
        (searchProm.endYear == prom.year &&
          searchProm.endMonth < prom.month))) ||
    (searchProm.starred != null && searchProm.starred != starred)
  ) {
    return false;
  }

  // check if lines are open/closed
  const { lineStatus } = searchProm;
  let closed = false || prom.closed;
  if (prom.lines) {
    for (const lineKey in prom.lines) {
      if (prom.lines[lineKey].closed === true) {
        closed = true;
      }
    }
  }
  if (
    (lineStatus === "closed" && !closed) ||
    (lineStatus === "open" && closed)
  ) {
    return false;
  }

  // check assigned to
  const { assignedTo } = searchProm;
  if (prom.customer && assignedTo && !assignedTo.includes(prom.customer)) {
    return false;
  }

  return true;
}

function spendSatisfiesSearch(spend, searchSpend) {
  var searchSpend = { ...searchSpend };
  const fields = ["promKey", "name"];
  for (var i = 0; i < fields.length; i++) {
    var field = fields[i];
    if (
      field in searchSpend &&
      spend[field].indexOf(searchSpend[field]) == -1
    ) {
      return false;
    }
  }
  if (searchSpend.customer && spend.customer != searchSpend.customer) {
    return false;
  }

  const eqFields = ["month", "year"];
  for (var i = 0; i < eqFields.length; i++) {
    var field = eqFields[i];
    if (searchSpend[field] && spend[field] != searchSpend[field]) {
      return false;
    }
  }
  if (searchSpend.account && !searchSpend.account.includes(spend.account)) {
    return false;
  }

  if (searchSpend.product && spend.productGroup != searchSpend.product) {
    let contains = 0;
    for (var i = 0; i < spend.product.length; i++) {
      if (spend.product[i] == searchSpend.product) {
        contains = 1;
        break;
      }
    }
    if (!contains) {
      return false;
    }
  }

  const spendDate = new Date(spend.date);
  if (searchSpend.startDate && spendDate < searchSpend.startDate) {
    return false;
  }
  if (searchSpend.endDate && spendDate > searchSpend.endDate) {
    return false;
  }
  const promDate = new Date(`${spend.year}/${spend.month}/1`);
  if (searchSpend.promStartDate && promDate < searchSpend.promStartDate) {
    return false;
  }
  if (searchSpend.promEndDate && promDate > searchSpend.promEndDate) {
    return false;
  }
  const { checkNumber } = spend;
  if (searchSpend.checkNumber && searchSpend.checkNumber != checkNumber) {
    return false;
  }

  return true;
}

function revenueSatisfiesSearch(rev, searchRevenue) {
  var searchRevenue = { ...searchRevenue };

  if (searchRevenue.startDate && new Date(rev.date) < searchRevenue.startDate) {
    return false;
  }
  if (searchRevenue.endDate && new Date(rev.date) > searchRevenue.endDate) {
    return false;
  }

  const eqFields = ["month", "year", "customer", "product", "source"];
  for (let i = 0; i < eqFields.length; i++) {
    const field = eqFields[i];
    if (field in searchRevenue && rev[field] != searchRevenue[field]) {
      return false;
    }
  }

  return true;
}

function checkSatisfiesSearch(check, search) {
  const fields = ["name", "checkNumber"];
  for (var i = 0; i < fields.length; i++) {
    var field = fields[i];
    if (field in search && check[field].indexOf(search[field]) == -1) {
      return false;
    }
  }
  if (search.customer && check.customer != search.customer) {
    return false;
  }

  const eqFields = ["month", "year"];
  for (var i = 0; i < eqFields.length; i++) {
    var field = eqFields[i];
    if (search[field] && check[field] != search[field]) {
      return false;
    }
  }

  return true;
}

function invoiceSatisfiesSearch(invoice, search) {
  const fields = ["name"];
  for (var i = 0; i < fields.length; i++) {
    var field = fields[i];
    if (field in search && invoice[field].indexOf(search[field]) == -1) {
      return false;
    }
  }
  if (search.customer && invoice.customer != search.customer) {
    return false;
  }

  const eqFields = ["month", "year"];
  for (var i = 0; i < eqFields.length; i++) {
    var field = eqFields[i];
    if (search[field] && invoice[field] != search[field]) {
      return false;
    }
  }

  return true;
}

export {
  promSatisfiesSearch,
  spendSatisfiesSearch,
  revenueSatisfiesSearch,
  checkSatisfiesSearch,
  invoiceSatisfiesSearch
};
