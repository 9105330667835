import React, { useState, useEffect, useCallback } from "react";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import NumberFormat from "react-number-format";

export const EditableCell = ({
  value: remoteValue,
  update,
  del,
  showDel
}: {
  value: string;
  update: (v: string) => void;
  del: () => void;
  showDel?: boolean;
}) => {
  const [value, setValue] = useState(remoteValue);

  useEffect(() => {
    setValue(remoteValue);
  }, [remoteValue]);

  const onEnter: React.KeyboardEventHandler<HTMLInputElement> = useCallback(
    e => {
      if (e.key === "Enter" && remoteValue !== value) {
        update(value);
      }
    },
    [update, value, remoteValue]
  );

  const onBlur: React.FocusEventHandler<HTMLInputElement> = useCallback(() => {
    if (value !== remoteValue) {
      update(value);
    }
  }, [update, value, remoteValue]);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        background: "white",
        border: "1px solid #5577bb",
        borderRadius: "5px",
        display: "flex",
        alignItems: "center"
      }}>
      <NumberFormat
        style={{
          width: 0,
          flex: 1,
          border: "none",
          background: "transparent",
          outline: "none",
          textAlign: "right"
        }}
        min="0"
        max="99999999"
        value={value}
        onValueChange={values => {
          setValue(values.value);
        }}
        onKeyDown={onEnter}
        onBlur={onBlur}
        thousandSeparator
      />
      {!!showDel && (
        <button
          style={{ background: "transparent", border: "none" }}
          type="button"
          onClick={del}>
          <SettingsBackupRestoreIcon
            fontSize="small"
            htmlColor="rgb(30, 136, 229)"
          />
        </button>
      )}
    </div>
  );
};
