import React from "react";
import { Link } from "react-router-dom";
import { Divider, Typography } from "@mui/material";
import { firebase } from "helpers/Firebase";

class EmailRecovery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      params: this.props.params,
      page: "verifying"
    };
  }

  componentDidMount = () => {
    // validate onetime code passed in
    const actionCode = this.state.params.get("oobCode");
    if (!actionCode) {
      this.setState({
        page: "invalid"
      });
      return;
    }
    firebase
      .auth()
      .applyActionCode(actionCode)
      .then(resp => {
        this.setState({
          page: "success"
        });
      })
      .catch(error => {
        this.setState({
          page: "invalid"
        });
      });
  };

  successScreenJSX = () => {
    return (
      <div>
        <Typography color="textPrimary" gutterBottom variant="h4">
          Success!
        </Typography>
        <Divider style={{ marginTop: 24, marginBottom: 24 }} />
        <p>
          Your email has verified. Please <Link to="/">sign in</Link> to
          continue using Vividly.
        </p>
      </div>
    );
  };

  invalidLinkJSX = () => {
    return (
      <div>
        <Typography color="textPrimary" gutterBottom variant="h4">
          Invalid Link
        </Typography>
        <Divider style={{ marginTop: 24, marginBottom: 24 }} />
        <p>Your email verification link is invalid. Please try again.</p>
      </div>
    );
  };

  unknownErrorJSX = () => {
    return (
      <div>
        <Typography color="textPrimary" gutterBottom variant="h4">
          Unknown Error
        </Typography>
        <Divider style={{ marginTop: 24, marginBottom: 24 }} />
        <p>
          We're sorry, Vividly has encountered an unknown error with your
          request. Please try again.
        </p>
      </div>
    );
  };

  render = () => {
    switch (this.state.page) {
      case "verifying":
        return (
          <Typography color="textPrimary" gutterBottom variant="h4">
            Please Wait...
          </Typography>
        );
        break;
      case "invalid":
        return this.invalidLinkJSX();
        break;
      case "success":
        return this.successScreenJSX();
        break;
      default:
        return this.unknownErrorJSX();
    }
  };
}

export default EmailRecovery;
