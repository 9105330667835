import { Divider } from "pui-react-dividers";
import React from "react";
import classNames from "classnames";
import { intersection } from "lodash";

import Typography from "@mui/material/Typography";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationItem from "@mui/material/BottomNavigationAction";
import { Toolbar, ToolbarSeparator, ToolbarTitle } from "ui-library/Toolbar";
import ToolbarGroup from "ui-library/ToolbarGroup";
import CircularProgress from "@mui/material/CircularProgress";
import RefreshIcon from "@mui/icons-material/Refresh";
import DownloadIcon from "@mui/icons-material/GetApp";
import SaveIcon from "@mui/icons-material/Save";
import Button from "ui-library/Button";
import IconButton from "ui-library/IconButton";
import IconMenu from "ui-library/IconMenu";
import MenuItem from "ui-library/MenuItem";
import Paper from "@mui/material/Paper";
import SelectField from "ui-library/Select";
import SettingsIcon from "@mui/icons-material/Settings";
import Subheader from "ui-library/Subheader";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ListIcon from "@mui/icons-material/List";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import TimelineIcon from "@mui/icons-material/Timeline";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";

import Card from "ui-library/ClickableCard";

import {
  accountItemsTable,
  calculateLinesROI,
  convertDataForTableROI,
  customerItemsTable,
  contactItemsTable,
  dateItemsTable,
  productItemsTable,
  promItemsTable
} from "helpers/DataProcessing";
import { exportAnalytics } from "helpers/Export";
import {
  getUserDataFirebase,
  getFirebase,
  getJSXListsFirebase,
  firebase
} from "helpers/Firebase";
import { getTimesJSX } from "helpers/Time";
import { objToArray, sortByField } from "helpers/sortByDate";
import Mixpanel from "helpers/Mixpanel";
import { exportComponentAsPNG } from "react-component-export-image";
import { grey, common } from "@mui/material/colors";
import { ToggleButton, Stack } from "@mui/material";
import StyledToggleButtonGroup from "ui-library/ToggleButtonGroupStyled";
import ActVsExpPast from "../graphs/ActVsExpPast";
import CustomerAnalytics from "../Customers/CustomerAnalytics";
import DiffBrushGraph from "../graphs/DiffBrushGraph";
import MoneyTableByDate from "../graphs/MoneyTableByDate";
import MoneyTableByProm from "../graphs/MoneyTableByProm";
import MoneyTableExpandable from "../graphs/MoneyTableExpandable";
import ROITable from "../graphs/ROITable";

const grey700 = grey["700"];
const { black } = common;
const grey400 = grey["400"];

const styles = {
  notifs: {
    width: "500px"
  },
  input: {
    margin: 5
  },
  loading: {
    height: $(window).height()
  },
  tabs: {
    backgroundColor: grey700
  },
  tabsBox: {
    borderColor: grey700,
    borderWidth: 2,
    borderStyle: "solid"
  },
  toolbarButton: {
    marginLeft: 5,
    marginRight: 5
  },
  checkbox: {
    marginLeft: 16
  }
};

const wildcard = -1;
const isWildcard = arr => arr.length == 1 && arr[0] == wildcard;
/* eslint-disable */
class Analytics extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      bumpChart: {},
      showSearch: false,
      searchQuery: "",
      product: [wildcard],
      customer: [wildcard],
      contact: [wildcard],
      promotion: [wildcard],
      productGroup: [wildcard],
      pivot: "date",
      doneLoading: false,
      modalOpen: false,
      diffCutoff: -999999,
      percentCutoff: -70,
      account: [wildcard],
      month: wildcard,
      year: new Date().getFullYear(),
      time: "month",
      dateSource: "promotion",
      byDate: false,
      aore: "diff",
      openFilePicker: false,
      hideOptions: false,
      selectedIndex: 0
    };
    this.bumpRef = React.createRef();
    this.complianceRef = React.createRef();
  }

  saveToDatabase = () => {
    const savedKeys = [
      "showOnlyPast",
      "product",
      "customer",
      "contact",
      "promotion",
      "productGroup",
      "pivot",
      "diffCutoff",
      "percentCutoff",
      "account",
      "month",
      "year",
      "time",
      "dateSource",
      "byDate",
      "aore",
      "selectedIndex"
    ];
    const payload = {};

    for (const key in savedKeys) {
      if (this.state.hasOwnProperty(savedKeys[key])) {
        payload[savedKeys[key]] = this.state[savedKeys[key]];
      }
    }

    const { uid } = firebase.auth().currentUser;
    firebase
      .database()
      .ref(`users/${uid}/insights/prom_analytics`)
      .set(payload);
  };

  openFilePicker = jsx => {
    this.setState({
      openFilePicker: true
    });
    this.props.showDropDownWindow(jsx);
  };

  handleSearchButton = () => {
    this.setState({
      showSearch: true
    });
  };

  handleSearch = event => {
    this.setState({
      searchQuery: event.target.value
    });
  };

  handleExportChart = fileType => {
    switch (fileType) {
      case "png bump":
        exportComponentAsPNG(this.bumpRef, {
          fileName: "Bump Chart",
          pdfOptions: {
            unit: "px"
          }
        });
        break;
      case "png comp":
        exportComponentAsPNG(this.complianceRef, {
          fileName: "Compliance Chart",
          pdfOptions: {
            unit: "pt",
            pdfFormat: "a4"
          }
        });
        break;
      default:
        // fileType string is misspelled somewhere in the code
        //
        console.error("Enter a valid fileType for handleExportChart");
    }
  };

  getBothCases = (dict, key) => {
    const keyUpperCase = key.toUpperCase();
    const keyLowerCase = key.toLowerCase();
    return dict[keyUpperCase] || dict[keyLowerCase];
  };

  filterMoney = moneyObj => {
    const filteredMoney = [];

    if (moneyObj) {
      for (const key in moneyObj) {
        const s = { ...moneyObj[key] };
        // attempt to look for promotion data
        const promotion = this.getBothCases(this.props.db.promotions, s.promKey);
        if (promotion) {
          s.customer = promotion.customer;
          if (this.state.dateSource == "promotion") {
            // if the date source we want to use use is from promotions, change it
            s.month = promotion.month;
            s.year = promotion.year;
          }
        }
        // attempt to look for line data
        const line = this.getBothCases(this.props.db.allLines, s.lineKey);
        if (line) {
          s.account = line.account;
          // if product group is not specified, find the line
          if (!("productGroup" in s)) {
            s.productGroup = line.productGroup;
          }
        }
        s.contact = this.props.db.customers[s.customer]?.contact;
        const status = s.status ? this.props.db.meta.statuses[s.status] : null;
        if (
          (isWildcard(this.state.productGroup) ||
            this.state.productGroup?.includes(s.productGroup)) &&
          (isWildcard(this.state.customer) ||
            this.state.customer?.includes(s.customer)) &&
          (isWildcard(this.state.contact) ||
            intersection(this.state.contact, s.contact).length > 0) &&
          (this.state.month === wildcard ||
            Number(s.month) == Number(this.state.month)) &&
          (this.state.year === wildcard ||
            Number(s.year) == Number(this.state.year)) &&
          (isWildcard(this.state.promotion) ||
            this.state.promotion?.includes(s.promKey.toUpperCase()) ||
            this.state.promotion?.includes(s.promKey.toLowerCase())) &&
          (isWildcard(this.state.account) ||
            this.state.account?.includes(s.account)) &&
          !["Cancelled", "Declined"].includes(status) &&
          (!this.state.showOnlyPast ||
            ["Approved", "Running", "Completed"].includes(status))
        ) {
          filteredMoney.push(s);
        }
      }
    }
    return filteredMoney;
  };

  filterBumpChart = bumpObj => {
    const filteredList = [];
    if (bumpObj) {
      for (const key in bumpObj) {
        const s = bumpObj[key];
        if (
          (isWildcard(this.state.customer) ||
            this.state.customer?.includes(s.customerNameBumpChart)) &&
          (isWildcard(this.state.productGroup) ||
            this.state.productGroup?.includes(s.productGroupBumpChart)) &&
          (!this.state.startDate || new Date(s.date) >= this.state.startDate) &&
          (!this.state.endDate || new Date(s.date) <= this.state.endDate)
        ) {
          filteredList.push(s);
        }
      }
    }
    return filteredList;
  };

  handleChangePivot = newPivot => {
    this.setState({
      pivot: newPivot
    });
    Mixpanel.track("Promotion Analytics Pivot Change", {
      Pivot: newPivot
    });
  };

  handlePivotDropdownChange = (pivot, value, multi = true) => {
    if (multi) {
      const wasWildcard = this.state[pivot].includes(wildcard);
      const nowWildcard = value.includes(wildcard);
      if (!value?.length || (!wasWildcard && nowWildcard)) {
        this.setState({ [pivot]: [wildcard] });
      } else if (wasWildcard && nowWildcard) {
        const newValue = Array.from(value);
        newValue.splice(newValue.indexOf(wildcard), 1);
        this.setState({ [pivot]: newValue });
      } else {
        this.setState({ [pivot]: value });
      }
    } else {
      // here, value will always have 2 elements
      const oldValue = this.state[pivot][0];
      if (value[0] == oldValue) {
        this.setState({ [pivot]: [value[1]] });
      } else {
        this.setState({ [pivot]: [value[0]] });
      }
    }

    Mixpanel.track("Promotion Analytics Filter Change", {
      Filter: pivot
    });
  };

  getPromotionsJSX = () => {
    const allPromotions = this.props.db.promotions;
    const promotionsJSX = [];
    const promotionsList = sortByField("name", objToArray(allPromotions), true);
    for (let i = 0; i < promotionsList.length; i++) {
      const promotion = promotionsList[i];
      if (
        (isWildcard(this.state.customer) ||
          this.state.customer?.includes(promotion.customer)) &&
        promotion.year == this.state.year
      ) {
        promotionsJSX.push(
          <MenuItem
            value={promotion.key}
            children={promotion.name}
            key={promotion.key}
          />
        );
      }
    }
    return promotionsJSX;
  };

  getBumpFieldsJSX = (field, bumpObj) => {
    const bumpFields = new Set();
    const bumpFieldsJSX = [];
    if (bumpObj) {
      for (const key in bumpObj) {
        const s = bumpObj[key];
        bumpFields.add(s[field]);
      }
    }
    const bumpFieldsArr = Array.from(bumpFields).sort();
    for (let i = 0; i < bumpFieldsArr.length; i++) {
      bumpFieldsJSX.push(
        <MenuItem
          value={bumpFieldsArr[i]}
          children={bumpFieldsArr[i]}
          key={i}
        />
      );
    }

    return bumpFieldsJSX;
  };

  handleResetFields = () => {
    this.setState({
      month: wildcard,
      year: new Date().getFullYear(),
      startDate: null,
      endDate: null,
      customer: [wildcard],
      contact: [wildcard],
      product: [wildcard],
      promotion: [wildcard],
      account: [wildcard],
      productGroup: [wildcard]
    });
  };

  getRowOrder = (data, pivot) => {
    if (["customer", "product", "account"].indexOf(pivot) != -1) {
      let entries = data.actual;
      if (entries) {
        entries = sortByField("Total", entries);
        return entries.map(e => {
          return e[pivot];
        });
      }
    }
    return [];
  };

  convertForExport = (data) => {
    // convert only if fund type is selected as pivot
    if (this.state.pivot !== "account") {
      return data;
    }
    // bump chart: keep as is
    if (this.state.selectedIndex == 2) {
      return data;
    }
    // ROI analysis: convert fund type keys to names
    if (this.state.selectedIndex == 3) {
      const getFundTypeName = (accountKey) => {
        const found = this.state.accountsJSX.find((a) => a.key === accountKey);
        return found ? found.props.children : "Unknown";
      };
      data.forEach((row) => { row.key = getFundTypeName(row.key); });
      return data;
    }
    // spend/sales: convert account keys to fund types (same logic as table)
    for (const metric of ["actual", "expected", "diff", "percent"]) {
      for (const row of data[metric]) {
        row["account"] = this.renderPivot({ value: row["account"]});
      }
    }
    return data;
  };

  getPivotMappings = () => {
    // Determine which pivots to show
    let pivotToClassname = {
      date: "col-lg-2",
      productGroup: "col-lg-2",
      customer: "col-lg-2",
      contact: "col-lg-2",
      prom: "col-lg-2",
      account: "col-lg-2"
    };
    if (this.state.selectedIndex == 2) {
      // if viewing Bump Chart
      pivotToClassname = {
        date: "hidden",
        customer: "col-lg-6",
        contact: "hidden",
        prom: "hidden",
        account: "hidden",
        productGroup: "col-lg-6"
      };
    }

    return pivotToClassname;
  };

  getToolbarMiddleJSX = () => {
    if ([2, 3].includes(this.state.selectedIndex)) {
      // If viewing Bump Chart or ROI Analysis, show nothing
      return;
    }
    if (
      ["customer", "contact", "product", "account"].includes(this.state.pivot)
    ) {
      return (
        <Stack>
          <StyledToggleButtonGroup
            value={this.state.aore}
            exclusive
            onChange={(event, aore) => this.setState({ aore })}>
            <ToggleButton value="actual">Actual</ToggleButton>
            <ToggleButton value="expected">Expected</ToggleButton>
            <ToggleButton value="diff">Diff ($)</ToggleButton>
            <ToggleButton value="percent">Diff (%)</ToggleButton>
          </StyledToggleButtonGroup>
          <StyledToggleButtonGroup
            value={this.state.time}
            exclusive
            onChange={(event, time) => this.setState({ time })}>
            <ToggleButton value="month">Month</ToggleButton>
            <ToggleButton value="quarter">Quarter</ToggleButton>
          </StyledToggleButtonGroup>
        </Stack>
      );
    }
  };

  getData = (type, expType) => {
    if (type == "roi") {
      // get a filtered list of lines, then calculate ROIs for all relevant promos
      var allLines = this.filterMoney(this.props.db.allLines);
      const promKeySet = new Set();
      for (var i = 0; i < allLines.length; i++) {
        promKeySet.add(allLines[i].promKey);
      }
      const promKeyArr = Array.from(promKeySet);

      const promsDict = {};
      const roiDataByProm = {};
      for (var i = 0; i < promKeyArr.length; i++) {
        const promKey = promKeyArr[i];
        promsDict[promKey] = this.props.db.promotions[promKey];

        const actMoneyList = [];
        const spends = this.props.db.promotion_spend[promKey];
        for (const spendKey in spends) {
          if (spends[spendKey]) {
            actMoneyList.push(this.props.db.actMoney[spendKey]);
          }
        }

        roiDataByProm[promKey] = calculateLinesROI(
          this.props.db.promotions[promKey],
          actMoneyList,
          this.props.db.meta,
          this.props.db.pricing,
          false
        );
      }

      var promData = convertDataForTableROI(
        "prom",
        promsDict,
        roiDataByProm,
        this.props.db.meta
      );
      var dateData = convertDataForTableROI(
        "date",
        promsDict,
        roiDataByProm,
        this.props.db.meta
      );
      var customerData = convertDataForTableROI(
        "customer",
        promsDict,
        roiDataByProm,
        this.props.db.meta
      );
      var contactData = convertDataForTableROI(
        "contact",
        promsDict,
        roiDataByProm,
        this.props.db.meta,
        this.props.db.contacts
      );
      var accountData = convertDataForTableROI(
        "fundtype",
        promsDict,
        roiDataByProm,
        this.props.db.meta
      );
      var productData = convertDataForTableROI(
        "product",
        promsDict,
        roiDataByProm,
        this.props.db.meta
      );
      // Map pivots to data
      var pivotToData = {
        date: dateData,
        customer: customerData,
        contact: contactData,
        product: productData,
        prom: promData,
        account: accountData
      };

      return pivotToData;
    }
    const actMoney = this.filterMoney(this.props.db.actMoney);
    var allLines = this.filterMoney(this.props.db.allLines);
    const linesList = objToArray(allLines);
    var dateData = [];
    var customerData = [];
    var accountData = [];
    var promData = [];
    var productData = [];
    const bumpChartData = [];
    contactData = [];

    if (
      Object.keys(this.props.db.customers).length > 0 &&
      Object.keys(this.props.db.promotions).length > 0 &&
      Object.keys(this.props.db.products).length > 0 &&
      linesList.length > 0
    ) {
      dateData = dateItemsTable(
        type,
        actMoney,
        expType,
        linesList,
        this.state.byDate
      );
      customerData = customerItemsTable(
        type,
        actMoney,
        expType,
        linesList,
        this.props.db.customers
      );
      contactData = contactItemsTable(
        type,
        actMoney,
        expType,
        linesList,
        this.props.db.contacts,
        this.state.contact
      );
      accountData = accountItemsTable(type, actMoney, expType, linesList);
      promData = promItemsTable(
        type,
        actMoney,
        expType,
        linesList,
        this.props.db.promotions
      );
      productData = productItemsTable(
        type,
        actMoney,
        expType,
        linesList,
        this.props.db.products,
        this.props.db.meta
      );
    }
    // Map pivots to data
    var pivotToData = {
      date: dateData,
      customer: customerData,
      contact: contactData,
      product: productData,
      prom: promData,
      account: accountData
    };

    return pivotToData;
  };

  renderPivot = row => {
    const { fundTypes = {} } = this.props.db.meta;
    const typeKeysToAccountKeys = {};
    Object.entries(fundTypes).forEach(([key, type]) => {
      typeKeysToAccountKeys[key] = type.accountKey;
    });
    if (Object.values(typeKeysToAccountKeys).indexOf(row.value) != -1) {
      const allVals = [];
      for (const [typeKey, accountKey] of Object.entries(
        typeKeysToAccountKeys
      )) {
        if (accountKey === row.value) {
          allVals.push(fundTypes?.[typeKey]?.name);
        }
      }
      return allVals.join("/");
    }
    return "Unknown";
  };

  getTableJSX = pivotToData => {
    // Determine which data table to show
    let tableJSX = <div />;
    const useROI = this.state.selectedIndex == 3;
    switch (this.state.pivot) {
      case "customer":
        tableJSX = useROI ? (
          <ROITable
            data={pivotToData.customer}
            showRightDrawer={this.props.showRightDrawer}
            diffCutoff={this.state.diffCutoff}
            percentCutoff={this.state.percentCutoff}
            isMoney
            openClose={this.props.openClose}
            db={this.props.db}
            pivot="customer"
          />
        ) : (
          <MoneyTableExpandable
            data={pivotToData.customer}
            mode={this.state.aore}
            diffCutoff={this.state.diffCutoff}
            percentCutoff={this.state.percentCutoff}
            time={this.state.time}
            dateHeader="Customer"
            header="Data by Customer"
            pivot="customer"
            metrics={["actual", "expected", "diff", "percent"]}
            moneyMetrics={["Actual", "Expected", "Differential"]}
            isMoney={
              this.state.selectedIndex == 0 && this.state.aore != "percent"
            }
            db={this.props.db}
          />
        );
        break;
      case "contact":
        tableJSX = useROI ? (
          <ROITable
            data={pivotToData.contact}
            showRightDrawer={this.props.showRightDrawer}
            diffCutoff={this.state.diffCutoff}
            percentCutoff={this.state.percentCutoff}
            isMoney
            openClose={this.props.openClose}
            db={this.props.db}
            pivot="contact"
          />
        ) : (
          <MoneyTableExpandable
            data={pivotToData.contact}
            mode={this.state.aore}
            diffCutoff={this.state.diffCutoff}
            percentCutoff={this.state.percentCutoff}
            time={this.state.time}
            dateHeader="Contact"
            header="Data by Contact"
            pivot="contact"
            metrics={["actual", "expected", "diff", "percent"]}
            moneyMetrics={["Actual", "Expected", "Differential"]}
            isMoney={
              this.state.selectedIndex == 0 && this.state.aore != "percent"
            }
            db={this.props.db}
          />
        );
        break;
      case "product":
        tableJSX = useROI ? (
          <ROITable
            data={pivotToData.product}
            showRightDrawer={this.props.showRightDrawer}
            diffCutoff={this.state.diffCutoff}
            percentCutoff={this.state.percentCutoff}
            isMoney
            openClose={this.props.openClose}
            db={this.props.db}
            pivot="product"
          />
        ) : (
          <MoneyTableExpandable
            data={pivotToData.product}
            mode={this.state.aore}
            diffCutoff={this.state.diffCutoff}
            percentCutoff={this.state.percentCutoff}
            time={this.state.time}
            dateHeader="Product"
            header="Data by Product"
            pivot="product"
            metrics={["actual", "expected", "diff", "percent"]}
            moneyMetrics={["Actual", "Expected", "Differential"]}
            isMoney={
              this.state.selectedIndex == 0 && this.state.aore != "percent"
            }
            db={this.props.db}
          />
        );
        break;
      case "date":
        tableJSX = useROI ? (
          <ROITable
            data={pivotToData.date}
            showRightDrawer={this.props.showRightDrawer}
            diffCutoff={this.state.diffCutoff}
            percentCutoff={this.state.percentCutoff}
            isMoney
            openClose={this.props.openClose}
            db={this.props.db}
            pivot="date"
          />
        ) : (
          <MoneyTableByDate
            data={pivotToData.date}
            diffCutoff={this.state.diffCutoff}
            percentCutoff={this.state.percentCutoff}
            isMoney={this.state.selectedIndex == 0}
            db={this.props.db}
          />
        );
        break;
      case "prom":
        tableJSX = useROI ? (
          <ROITable
            data={pivotToData.prom}
            showRightDrawer={this.props.showRightDrawer}
            diffCutoff={this.state.diffCutoff}
            percentCutoff={this.state.percentCutoff}
            isMoney
            openClose={this.props.openClose}
            db={this.props.db}
            pivot="prom"
          />
        ) : (
          <MoneyTableByProm
            data={pivotToData.prom}
            showRightDrawer={this.props.showRightDrawer}
            diffCutoff={this.state.diffCutoff}
            percentCutoff={this.state.percentCutoff}
            isMoney={this.state.selectedIndex == 0}
            openClose={this.props.openClose}
            db={this.props.db}
          />
        );
        break;
      case "account":
        tableJSX = useROI ? (
          <ROITable
            data={pivotToData.account}
            showRightDrawer={this.props.showRightDrawer}
            diffCutoff={this.state.diffCutoff}
            percentCutoff={this.state.percentCutoff}
            isMoney
            openClose={this.props.openClose}
            db={this.props.db}
            pivot="account"
          />
        ) : (
          <MoneyTableExpandable
            data={pivotToData.account}
            mode={this.state.aore}
            diffCutoff={this.state.diffCutoff}
            percentCutoff={this.state.percentCutoff}
            dateHeader="Fund Type"
            header="Data by Fund Type"
            pivot="account"
            time={this.state.time}
            metrics={["actual", "expected", "diff", "percent"]}
            moneyMetrics={["Actual", "Expected", "Differential"]}
            isMoney={
              this.state.selectedIndex == 0 && this.state.aore != "percent"
            }
            renderPivot={this.renderPivot}
            db={this.props.db}
          />
        );
        break;
    }
    return tableJSX;
  };

  componentDidMount() {
    getJSXListsFirebase(
      this.props.db,
      allJSX => {
        const timesJSX = getTimesJSX(this.props.db.meta, true);
        getFirebase(9, bumpChart => {
          this.setState({
            bumpChart,
            customersJSX: allJSX.customersJSX,
            productGroupsJSX: allJSX.productGroupsJSX,
            accountsJSX: allJSX.accountsJSX,
            contactsJSX: allJSX.contactsJSX,
            monthsJSX: timesJSX[0],
            yearsJSX: timesJSX[1],
            doneLoading: true
          });
        });
      },
      null,
      true
    );

    getUserDataFirebase(promAnalytics => {
      if (promAnalytics) {
        this.setState(promAnalytics);
      }
    }, "insights/prom_analytics");
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.sideBar != this.props.sideBar) {
      return false;
    }
    return true;
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    // Determine which pivot dropdowns to show
    const pivotToClassname = this.getPivotMappings();

    // For promotion dropdown
    const filteredPromotionsJSX = this.props.db.promotions
      ? this.getPromotionsJSX()
      : [];

    // Get options to show in middle of toolbar
    const toolbarMiddleJSX = this.getToolbarMiddleJSX();

    // Dict mapping pivot label to data object
    const pivotToData =
      this.state.selectedIndex == 0
        ? this.getData("spend", "totalExpSpend")
        : this.state.selectedIndex != 3
        ? this.getData("sales", "totalExpSales")
        : this.getData("roi", null);

    const tableJSX = this.getTableJSX(pivotToData);

    const productGroupsJSX =
      this.state.selectedIndex != 2
        ? this.state.productGroupsJSX
        : this.getBumpFieldsJSX("productGroupBumpChart", this.state.bumpChart);
    const customersJSX =
      this.state.selectedIndex != 2
        ? this.state.customersJSX
        : this.getBumpFieldsJSX("customerNameBumpChart", this.state.bumpChart);
    const bumpList = this.filterBumpChart(this.state.bumpChart);

    const typeAccountsToKeys = {};
    for (const k in this.props.db.meta.type_accounts) {
      if (!(this.props.db.meta.type_accounts[k] in typeAccountsToKeys)) {
        typeAccountsToKeys[this.props.db.meta.type_accounts[k]] = [];
      }
      typeAccountsToKeys[this.props.db.meta.type_accounts[k]].push(k);
    }
    let mappedAccounts = [];
    for (const accountNumber of this.state.account) {
      if (
        this.props.db.meta.type_accounts &&
        Object.values(this.props.db.meta.type_accounts).includes(accountNumber)
      ) {
        mappedAccounts = mappedAccounts.concat(
          typeAccountsToKeys[accountNumber]
        );
      } else {
        mappedAccounts.push(accountNumber);
      }
    }

    return this.state.doneLoading ? (
      <div>
        <Toolbar>
          <ToolbarGroup>
            <ToolbarTitle text="Promotion Analytics" style={{ color: black }} />
            <ToolbarSeparator />
            <IconButton
              onClick={() => {
                this.setState({ showOnlyPast: !this.state.showOnlyPast });
              }}
              tooltip={
                this.state.showOnlyPast
                  ? "Showing only Approved, Running, Completed promotions"
                  : "Showing all promotions"
              }
              size="large">
              <CardGiftcardIcon
                sx={{
                  color: this.state.showOnlyPast ? "primary.main" : grey400
                }}
              />
            </IconButton>
          </ToolbarGroup>
          {toolbarMiddleJSX}
          <ToolbarGroup>
            <IconMenu
              iconButtonElement={
                <IconButton tooltip="Settings" size="large">
                  <SettingsIcon />
                </IconButton>
              }
              anchorOrigin={{ horizontal: "right", vertical: "top" }}
              targetOrigin={{ horizontal: "right", vertical: "top" }}
              onChange={this.props.handleAdditionalDataDisplay}>
              <MenuItem
                children={<Typography variant="subtitle1">Export</Typography>}
                disabled
              />
              <MenuItem
                value={0}
                children="Download Data Insights"
                leftIcon={<DownloadIcon />}
                disabled={
                  Object.keys(this.props.db.actMoney).length == 0 ||
                  (this.state.selectedIndex > 0 &&
                    this.props.db.meta.tier != "aspen")
                }
                onClick={() => {
                  exportAnalytics(
                    firebase.auth().currentUser,
                    this.state.selectedIndex == 2
                      ? this.state.bumpChart
                      : this.convertForExport(pivotToData[this.state.pivot]),
                    this.state.selectedIndex == 0
                      ? "Sales"
                      : this.state.selectedIndex == 1
                      ? "Spend"
                      : this.state.selectedIndex == 2
                      ? "BumpChart"
                      : "ROI",
                    this.getRowOrder(
                      pivotToData[this.state.pivot],
                      this.state.pivot
                    ),
                    "export_promotion_analytics",
                    this.state.pivot
                  );
                  this.props.openClose.setAppModal(
                    "Export In Progress",
                    <div>
                      Your export is being prepared and will be emailed to you
                      shortly.
                    </div>,

                    <div>
                      <Button
                        label="Ok"
                        onClick={() => {
                          this.props.openClose.closeAppModal();
                        }}
                      />
                    </div>
                  );
                }}
              />
              <MenuItem
                value={1}
                children="Set as Default View"
                leftIcon={<SaveIcon />}
                onClick={this.saveToDatabase}
              />
              {this.state.selectedIndex == 2 ? (
                <>
                  <MenuItem
                    value={2}
                    children="Download compliance chart as .png"
                    leftIcon={<DownloadIcon />}
                    disabled={false}
                    onClick={() => {
                      this.handleExportChart("png comp");
                    }}
                  />
                  <MenuItem
                    value={3}
                    children="Download bump chart as .png"
                    leftIcon={<DownloadIcon />}
                    disabled={false}
                    onClick={() => {
                      this.handleExportChart("png bump");
                    }}
                  />
                </>
              ) : null}
            </IconMenu>
          </ToolbarGroup>
        </Toolbar>
        <div style={{ overflow: "auto" }} ref="table">
          {this.state.hideOptions && <br />}
          <div
            className={classNames("analytics-options", {
              hidden: this.state.hideOptions
            })}
            style={{ padding: 16 }}>
            <Stack>
              <Subheader>Filter Inputs</Subheader>
              <div>
                <IconButton
                  color="tonal"
                  isBackgroundColor
                  tooltip="Clear Filters"
                  onClick={this.handleResetFields}
                  size="large">
                  <RefreshIcon />
                </IconButton>
              </div>
            </Stack>
            <div style={{ fontFamily: "Oxygen" }}>
              Click on a panel header to switch pivot column
            </div>
            <br />
            <div className="row" style={{ marginBottom: -32 }}>
              <div className={pivotToClassname.date}>
                <Card
                  onClick={this.handleChangePivot.bind(null, "date")}
                  title="Date"
                  selected={this.state.pivot == "date"}>
                  <div className="rowC">
                    <SelectField
                      floatingLabelText="Month"
                      value={this.state.month}
                      onChange={(event, index, value) => {
                        this.setState({ month: value });
                      }}
                      fullWidth
                      style={styles.input}>
                      <MenuItem value={wildcard} children="Any month" key={0} />
                      {this.state.monthsJSX}
                    </SelectField>
                    <SelectField
                      floatingLabelText="Year"
                      value={this.state.year}
                      onChange={(event, index, value) => {
                        this.setState({ year: value, promotion: [wildcard] });
                      }}
                      fullWidth
                      style={styles.input}>
                      <MenuItem value={wildcard} children="Any year" key={0} />
                      {this.state.yearsJSX}
                    </SelectField>
                  </div>
                  {[0, 1].includes(this.state.selectedIndex) && (
                    <SelectField
                      floatingLabelText="Date Source"
                      value={this.state.dateSource}
                      onChange={(event, index, value) => {
                        this.setState({ dateSource: value });
                      }}
                      fullWidth
                      style={styles.input}>
                      <MenuItem value="spend" children="Spend" key={0} />
                      <MenuItem
                        value="promotion"
                        children="Promotion"
                        key={1}
                      />
                    </SelectField>
                  )}
                </Card>
              </div>
              <div className={pivotToClassname.customer}>
                <Card
                  onClick={this.handleChangePivot.bind(null, "customer")}
                  title="Customer"
                  selected={this.state.pivot == "customer"}>
                  <SelectField
                    floatingLabelText="Customer"
                    value={this.state.customer}
                    onChange={(event, index, values) => {
                      this.handlePivotDropdownChange(
                        "customer",
                        values,
                        this.state.selectedIndex != 2
                      );
                    }}
                    fullWidth
                    style={styles.input}
                    multiple>
                    {[
                      <MenuItem
                        value={wildcard}
                        children="Any Customer"
                        key="wildcard"
                      />
                    ].concat(customersJSX)}
                  </SelectField>
                </Card>
              </div>
              <div className={pivotToClassname.contact}>
                <Card
                  onClick={this.handleChangePivot.bind(null, "contact")}
                  title="Contact"
                  selected={this.state.pivot == "contact"}>
                  <SelectField
                    floatingLabelText="Contact"
                    value={this.state.contact}
                    onChange={(event, index, values) => {
                      this.handlePivotDropdownChange(
                        "contact",
                        values,
                        this.state.selectedIndex != 2
                      );
                    }}
                    fullWidth
                    style={styles.input}
                    multiple>
                    {[
                      <MenuItem
                        value={wildcard}
                        children="Any Contact"
                        key="wildcard"
                      />
                    ].concat(this.state.contactsJSX)}
                  </SelectField>
                </Card>
              </div>
              <div className={pivotToClassname.prom}>
                <Card
                  onClick={this.handleChangePivot.bind(null, "prom")}
                  title="Promotion"
                  selected={this.state.pivot == "prom"}>
                  <SelectField
                    floatingLabelText="Promotion"
                    value={this.state.promotion}
                    onChange={(event, index, values) => {
                      this.handlePivotDropdownChange("promotion", values);
                    }}
                    fullWidth
                    style={styles.input}
                    multiple>
                    {[
                      <MenuItem
                        value={wildcard}
                        children="Any Promotion"
                        key="wildcard"
                      />
                    ].concat(filteredPromotionsJSX)}
                  </SelectField>
                </Card>
              </div>
              <div className={pivotToClassname.account}>
                <Card
                  onClick={this.handleChangePivot.bind(null, "account")}
                  title="Fund Types"
                  selected={this.state.pivot == "account"}>
                  <SelectField
                    floatingLabelText="Fund Type"
                    value={this.state.account}
                    onChange={(event, index, values) => {
                      this.handlePivotDropdownChange("account", values);
                    }}
                    fullWidth
                    style={styles.input}
                    multiple>
                    {[
                      <MenuItem
                        value={wildcard}
                        children="Any Fund Type"
                        key="wildcard"
                      />
                    ].concat(this.state.accountsJSX)}
                  </SelectField>
                </Card>
              </div>
              <div className={pivotToClassname.productGroup}>
                <Card
                  onClick={this.handleChangePivot.bind(null, "product")}
                  title="Product Grouping"
                  selected={this.state.pivot == "product"}>
                  <SelectField
                    floatingLabelText="Product Grouping"
                    value={this.state.productGroup}
                    onChange={(event, index, values) => {
                      this.handlePivotDropdownChange(
                        "productGroup",
                        values,
                        this.state.selectedIndex != 2
                      );
                    }}
                    fullWidth
                    style={styles.input}
                    multiple>
                    {[
                      <MenuItem
                        value={wildcard}
                        children="Any Product Grouping"
                        key="wildcard"
                      />
                    ].concat(productGroupsJSX)}
                  </SelectField>
                </Card>
              </div>
            </div>
          </div>
          <Divider />
          <div className="centering" style={{ width: "100%" }}>
            <div
              className="options-toggle-button"
              onClick={() => {
                this.setState({ hideOptions: !this.state.hideOptions });
              }}>
              {this.state.hideOptions ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowUpIcon />
              )}
            </div>
          </div>
          <br />
          <div style={{ padding: 16 }}>
            <Paper elevation={2}>
              <BottomNavigation value={this.state.selectedIndex} showLabels>
                <BottomNavigationItem
                  label="Spend Analysis"
                  icon={<ListIcon />}
                  value={0}
                  onClick={() => {
                    if (this.state.selectedIndex == 2) {
                      // if the current tab is bump chart
                      this.setState({
                        productGroup: [wildcard],
                        customer: [wildcard]
                      });
                    }
                    this.setState({ selectedIndex: 0 });
                  }}
                />
                <BottomNavigationItem
                  label="Unit Sales Analysis"
                  icon={<ViewHeadlineIcon />}
                  value={1}
                  onClick={() => {
                    if (this.state.selectedIndex == 2) {
                      // if the current tab is bump chart
                      this.setState({
                        productGroup: [wildcard],
                        customer: [wildcard]
                      });
                    }
                    this.setState({ selectedIndex: 1 });
                  }}
                />
                <BottomNavigationItem
                  label="Bump Chart"
                  icon={<TimelineIcon />}
                  value={2}
                  onClick={() => {
                    this.setState({
                      selectedIndex: 2,
                      productGroup: [wildcard],
                      customer: [wildcard]
                    });
                  }}
                />
                <BottomNavigationItem
                  label="ROI Analysis"
                  icon={<FlightTakeoffIcon />}
                  value={3}
                  onClick={() => {
                    if (this.state.selectedIndex == 2) {
                      // if the current tab is bump chart
                      this.setState({
                        productGroup: [wildcard],
                        customer: [wildcard]
                      });
                    }
                    this.setState({ selectedIndex: 3 });
                  }}
                />
              </BottomNavigation>
            </Paper>
            <br />
            {this.state.selectedIndex > 0 &&
            this.props.db.meta.tier != "aspen" ? (
              <div className="centering">
                Function disabled in Pine-Plus tier.
              </div>
            ) : [0, 1].includes(this.state.selectedIndex) ? (
              <div>
                {tableJSX}
                <div style={{ marginTop: 16 }}>
                  <div className="row">
                    <div className="col-lg-6">
                      <ActVsExpPast
                        data={pivotToData.date}
                        notMoney={this.state.selectedIndex == 1}
                      />
                    </div>
                    <div className="col-lg-6">
                      <DiffBrushGraph data={pivotToData.date} />
                    </div>
                  </div>
                </div>
              </div>
            ) : this.state.selectedIndex == 2 ? (
              <CustomerAnalytics
                db={this.props.db}
                openClose={this.props.openClose}
                bumpList={bumpList}
                bumpRef={this.bumpRef}
                complianceRef={this.complianceRef}
              />
            ) : (
              <div> {tableJSX} </div>
            )}
          </div>
          <br />
          <br />
        </div>
      </div>
    ) : (
      <div style={styles.loading} className="centering">
        <CircularProgress size={80} shrink={false} />
      </div>
    );
  }
}

export default Analytics;
