import { getUserDataFirebase, getValueFirebase } from "helpers/Firebase";
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState
} from "react";

type BackupRetrievalMeta = {
  companyId: string;
  customer: string;
  isDeductionPositive: string;
  password: string;
  portal: string;
  username: string;
  isInactive?: boolean;
};

const ScrapingContext = createContext<{
  companyMapping: Record<string, Record<string, BackupRetrievalMeta>>;
  currentCompany: unknown;
}>({
  companyMapping: {},
  currentCompany: null
});

function ScrapingContextProvider({ children }: PropsWithChildren<{}>) {
  const [companyMapping, setCompanyMapping] = useState({});
  const [currentCompany, setCurrentCompany] = useState(null);

  useEffect(() => {
    getValueFirebase(`backupRetrieval`, snapshot => {
      setCompanyMapping(snapshot.val());
    });
    getUserDataFirebase(company => setCurrentCompany(company), "company");
  }, []);

  return (
    <ScrapingContext.Provider value={{ companyMapping, currentCompany }}>
      {children}
    </ScrapingContext.Provider>
  );
}

function useCompanyMapping() {
  const { companyMapping, currentCompany } = useContext(ScrapingContext);

  return {
    companyMapping,
    currentCompany
  };
}

export default ScrapingContextProvider;
export { ScrapingContext, useCompanyMapping };
export type { BackupRetrievalMeta };
