import {
  TransactionDisplayService,
  TransactionFilterService,
  TransactionProcessingService
} from "components/Deductions/DeductionsReconciliation/services";
import { useDb } from "contexts/Db";
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { displayUSCurrency } from "helpers/DataProcessing";
import { DisplayERPTransactionObject } from "components/Deductions/DeductionsReconciliation/types/transactionTypes";
import CollapsibleTable, {
  ColumnConfigOptions
} from "ui-library/CollapsibleTable/CollapsibleTable";
import { DRMFeature } from "components/Deductions/DeductionsReconciliation/redux/DRMSlice";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import IconButton from "ui-library/IconButton";
import { ERPTransactionObject } from "components/Deductions/models";
import { useOpenClose } from "contexts/OpenClose";
import moment from "moment";
import { ERPTransactionTypes } from "components/Deductions/constants/ReconciliationTypes";
import {
  SummaryConfigOptions,
  CollapsibleTableSummaryType
} from "ui-library/CollapsibleTable/CollapsibleTableSummary";
import { useDRMEvents } from "contexts/DRMEvents";
import RepaymentLinesTable from "./RepaymentLinesTable";

const useStyles = makeStyles((theme: Theme) => ({
  actionButton: {
    color: theme.palette.grey[600]
  }
}));

export interface AttachedTransactionsTableProps {
  transactionKey: string;
}

// Shows all attached transactions of the opposite type (e.g. attached deductions of a repayment, or vice versa)
export default function AttachedTransactionsTable(
  props: AttachedTransactionsTableProps
) {
  const { transactionKey } = props;

  const classes = useStyles();
  const openClose = useOpenClose();
  const { addEvent } = useDRMEvents();
  const db = useDb();
  const {
    erpTransactions = {},
    invoices = {},
    customers = {},
    companyUsers = {}
  } = db;
  const history = useHistory();

  const transaction: ERPTransactionObject = useMemo(() => {
    return erpTransactions[transactionKey] || {};
  }, [transactionKey, erpTransactions]);

  const isRepayment: Boolean =
    transaction.type === ERPTransactionTypes.REPAYMENT;
  const isDeduction: Boolean =
    transaction.type === ERPTransactionTypes.DEDUCTION;

  const attachedTransactions: Record<string, ERPTransactionObject> =
    useMemo(() => {
      if (isRepayment) {
        return TransactionFilterService.findAttachedDeductions(transaction, db);
      }
      if (isDeduction) {
        return TransactionFilterService.findAttachedRepayments(transaction, db);
      }

      return {};
    }, [db, transaction, isRepayment, isDeduction]);

  const displayAttachedTransactionsList: DisplayERPTransactionObject[] =
    useMemo(() => {
      return Object.values(attachedTransactions).map(deduction =>
        TransactionDisplayService.processTransactionForDisplay(
          deduction,
          invoices,
          erpTransactions,
          customers,
          companyUsers
        )
      );
    }, [
      attachedTransactions,
      companyUsers,
      customers,
      erpTransactions,
      invoices
    ]);

  const columnConfig = useMemo(
    () =>
      new Map([
        [
          "transactionDisplayId",
          {
            name: "Transaction ID"
          }
        ],
        [
          "customerName",
          {
            name: "Customer"
          }
        ],
        [
          "transactionDate",
          {
            name: "Transaction Date",
            render: val => moment(val).format("MM/DD/YYYY")
          }
        ],
        [
          "amount",
          {
            align: "right",
            name: "Original Amount",
            render: val => displayUSCurrency(val)
          }
        ],
        [
          "openAmount",
          {
            align: "right",
            name: isDeduction ? "Available Amount" : "Open Amount",
            render: val => displayUSCurrency(val)
          }
        ],
        [
          "status",
          {
            name: "Status",
            render: val =>
              TransactionDisplayService.HumanReadableTransactionStatuses[
                val as string
              ]
          }
        ],
        [
          "key",
          {
            name: "Actions",
            render: key => {
              const attachedTransaction = erpTransactions[key as string];
              const displayAttachedTransaction =
                TransactionDisplayService.processTransactionForDisplay(
                  attachedTransaction,
                  invoices,
                  erpTransactions,
                  customers,
                  companyUsers
                );

              const repayment = isRepayment
                ? transaction
                : displayAttachedTransaction;

              const deduction = isRepayment
                ? displayAttachedTransaction
                : transaction;

              return (
                <IconButton
                  aria-label={`Unattach ${
                    isRepayment ? "Deduction" : "Repayment"
                  }`}
                  size="small"
                  tooltip={`Unattach ${
                    isRepayment ? "Deduction" : "Repayment"
                  }`}
                  className={classes.actionButton}
                  disabled={Object.values(repayment.repaymentLines || {}).some(
                    repaymentLine =>
                      repaymentLine.deductionKey === deduction.key
                  )}
                  onClick={e => {
                    e.stopPropagation();
                    TransactionProcessingService.unattachRepaymentFromDeduction(
                      repayment.key,
                      deduction.key,
                      db,
                      addEvent,
                      () => {
                        openClose.showSnack(
                          `Repayment ${repayment.transactionDisplayId} has been un-attached from Deduction ${deduction.transactionDisplayId}`
                        );
                      }
                    );
                  }}>
                  <DeleteOutlineIcon />
                </IconButton>
              );
            }
          }
        ]
      ] as [keyof DisplayERPTransactionObject, ColumnConfigOptions<DisplayERPTransactionObject>][]),
    [
      isDeduction,
      erpTransactions,
      invoices,
      customers,
      companyUsers,
      isRepayment,
      transaction,
      classes.actionButton,
      db,
      addEvent,
      openClose
    ]
  );

  const summaryConfig = useMemo(
    () =>
      new Map([
        [
          "amount",
          {
            label: "Total Original",
            type: CollapsibleTableSummaryType.SUM,
            render: val => displayUSCurrency(val)
          }
        ],
        [
          "openAmount",
          {
            label: "Total Open",
            type: CollapsibleTableSummaryType.SUM,
            render: val => displayUSCurrency(val)
          }
        ]
      ] as [keyof DisplayERPTransactionObject, SummaryConfigOptions<DisplayERPTransactionObject>][]),
    []
  );

  return (
    <CollapsibleTable<DisplayERPTransactionObject>
      data={displayAttachedTransactionsList}
      expandProfile={(displayDeduction: DisplayERPTransactionObject) => {
        history.push(
          `/${DRMFeature.RECONCILIATION}/transaction/${displayDeduction.key}`
        );
      }}
      renderCollapse={(displayTransaction: DisplayERPTransactionObject) => {
        return isRepayment ? (
          <RepaymentLinesTable
            deductionKey={displayTransaction.key}
            repaymentKey={transaction.key}
            showRepaidTransactionId
          />
        ) : (
          <RepaymentLinesTable
            deductionKey={transaction.key}
            repaymentKey={displayTransaction.key}
            showRepaidTransactionId
          />
        );
      }}
      columnConfig={columnConfig}
      summaryConfig={summaryConfig}
      index="key"
    />
  );
}
