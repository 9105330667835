/* eslint-disable max-classes-per-file */
import { randomColor } from "randomcolor";
import React from "react";
import { take } from "lodash";

import {
  isUserCresicorEmployee,
  batchUpdateFirebase,
  getCompanyUsers,
  removeFirebase,
  firebase
} from "helpers/Firebase";

import { Toolbar, ToolbarTitle, ToolbarSeparator } from "ui-library/Toolbar";
import ToolbarGroup from "ui-library/ToolbarGroup";
import Label from "@mui/icons-material/Label";

import AddBoxIcon from "@mui/icons-material/AddBox";
import DownloadIcon from "@mui/icons-material/GetApp";
import Button from "ui-library/Button";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import IconButton from "ui-library/IconButton";
import IconMenu from "ui-library/IconMenu";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import MenuItem from "ui-library/MenuItem";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { ListSubheader, Stack } from "@mui/material";
import TextField from "ui-library/TextField";
import { CSVLink } from "react-csv";
import DatePicker from "ui-library/DatePicker";

import { objToArray, sortByField } from "helpers/sortByDate";
import { escapeQuotes } from "helpers/DataProcessing";
import { formatDependencies } from "helpers/Errors";
import { common } from "@mui/material/colors";
import { graphPalette } from "helpers/ColorPalettes";
import PaginationMUITable from "../tables/PaginationMUITable";
import CustomerProfile from "./CustomerProfile";
import NewCustomer from "./NewCustomer";
import ImportPresetCustomers from "./ImportPresetCustomers";
import CustomTagsManager from "./CustomTagsManager";

const { black } = common;
const { blue70, purple70 } = graphPalette;

const styles = {
  miniIcon: {
    height: 15,
    width: 15,
    marginLeft: 16
  }
};

class PropDataUpdatedCSVLink extends CSVLink {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data, headers, separator, uFEFF } = nextProps;
    this.setState({ href: this.buildURI(data, uFEFF, headers, separator) });
  }
}

class Customers extends React.PureComponent {
  constructor(props) {
    super(props);
    this.csvLink = React.createRef();
    this.allSelected = React.createRef();
    this.state = {
      customers: {},
      downloadData: [],
      headers: [
        { label: "Name", key: "name" },
        { label: "First Receivers", key: "distributorsField" },
        { label: "Assigned To", key: "assignedToField" },
        { label: "Contact", key: "contactName" },
        { label: "Alternative Names", key: "otherNamesField" },
        { label: "Region", key: "region" },
        { label: "Is Direct", key: "isDirect" },
        { label: "Is Distributor", key: "isDistributor" },
        { label: "P&L Group", key: "pnlGroup" },
        { label: "Channel", key: "channel" },
        { label: "Agencies", key: "agencies" },
        { label: "Class", key: "class" },
        { label: "Weeks to Retail", key: "weeksToRetail" },
        { label: "Forecast Revenue Sources", key: "revenueSourcesField" }
      ],
      companyUsers: {},
      seasonality: {},
      currentlySelected: "",
      showSearch: false,
      searchQuery: "",
      openFilePicker: false,
      modalOpen: false,
      reverseSort: false,
      multi: false,
      snackbar: false,
      resetSelection: false,
      customersJSX: [],
      tableBodyRenderKey: 0,
      count: 0,
      pagelength: 20,
      curDate: new Date(new Date().setHours(0, 0, 0, 0))
    };
  }

  openFilePicker = jsx => {
    this.setState({
      openFilePicker: true
    });
    this.props.showDropDownWindow(jsx);
  };

  closeFilePicker = () => {
    this.setState({
      openFilePicker: false
    });
  };

  addCustomer = () => {
    this.props.openClose.setAppModal(
      "Add Customer",
      <NewCustomer openClose={this.props.openClose} db={this.props.db} />
    );
  };

  importPresetCustomers = () => {
    this.props.openClose.setAppModal(
      "Import Preset Customers",
      <ImportPresetCustomers
        openClose={this.props.openClose}
        db={this.props.db}
      />
    );
  };

  deleteCustomer = (event, rowData) => {
    const customerKey = rowData.key;
    this.props.openClose.setAppModal(
      "Delete Customer",
      <div className="centering">
        Are you sure you want to delete this customer?
      </div>,

      <Stack>
        <Button
          label="Yes, I'm sure"
          color="error"
          variant="text"
          onClick={async () => {
            const dependencies = await this.getDependencies(customerKey);
            if (dependencies.size === 1 && dependencies.has("forecast")) {
              this.props.openClose.closeAppModal();
              this.props.openClose.setAppModal(
                "Are you sure?",
                <div className="centering">
                  Customer is used in forecast, are you sure you want to delete
                  this customer? (If yes, please update forecast to see changes
                  reflected.)
                </div>,
                <Stack>
                  <Button
                    label="Yes, I'm sure."
                    variant="text"
                    color="error"
                    onClick={() => {
                      this.props.openClose.closeAppModal();
                      removeFirebase(2, customerKey);
                    }}
                  />
                  <Button
                    label="No, go back"
                    variant="text"
                    onClick={() => {
                      this.props.openClose.closeAppModal();
                    }}
                  />
                </Stack>
              );
            } else if (dependencies.size) {
              const errorStr = formatDependencies("customer", dependencies);
              this.props.openClose.closeAppModal();
              this.props.openClose.setAppModal(
                "Unable to delete customer",
                <div className="centering">{errorStr}</div>,
                <div className="centering">
                  <Button
                    label="Okay"
                    onClick={() => {
                      this.props.openClose.closeAppModal();
                    }}
                  />
                </div>
              );
            } else {
              this.props.openClose.closeAppModal();
              // make sure no bug removes all the customers
              removeFirebase(2, customerKey);
            }
          }}
        />
        <Button
          label="No, go back"
          variant="text"
          onClick={() => {
            this.props.openClose.closeAppModal();
          }}
        />
      </Stack>
    );
  };

  getDependencies = async key => {
    const dependencies = new Set();

    // check forecast dependencies
    const firebaseForecast = firebase.app("forecast");
    const storesCustomerSnapshot = await firebase
      .database()
      .ref(
        `companies/${this.props.db.companyid}/forecast/stores_by_customer/${key}`
      )
      .once("value");
    const velocityCustomerSnapshot = await firebase
      .database()
      .ref(
        `companies/${this.props.db.companyid}/forecast/velocity_by_customer/${key}`
      )
      .once("value");
    if (storesCustomerSnapshot.val() || velocityCustomerSnapshot.val()) {
      dependencies.add("forecast");
    }
    for (const typeName of ["distributor", "non_distributor"]) {
      const refName1 = `/${typeName}_forecast_table_by_customer/`;
      const refName2 = `/${typeName}_historical_forecast_by_customer/`;
      const forecastSnapshot1 = await firebaseForecast
        .database()
        .ref(this.props.db.companyid + refName1 + key)
        .once("value");
      const forecastSnapshot2 = await firebaseForecast
        .database()
        .ref(this.props.db.companyid + refName2 + key)
        .once("value");
      if (forecastSnapshot1.val() || forecastSnapshot2.val()) {
        dependencies.add("forecast");
      }
    }

    // pricing
    if (key in this.props.db.pricing) {
      dependencies.add("pricing");
    }

    // spend
    for (var line in this.props.db.actMoney) {
      if (key == this.props.db.actMoney[line].customer) {
        dependencies.add("spend");
      }
    }

    // revenue
    for (var line in this.props.db.revenue) {
      if (key == this.props.db.revenue[line].customer) {
        dependencies.add("revenue");
      }
    }

    // promotions
    for (var line in this.props.db.allLines) {
      if (key == this.props.db.allLines[line].customer) {
        dependencies.add("promotions");
      }
    }

    // customer groups
    for (var line in this.props.db.customerGroups) {
      if (this.props.db.customerGroups[line].includes(key)) {
        dependencies.add("customer groups");
      }
    }

    // brokers
    for (const brokerKey in this.props.db.brokers) {
      if (this.props.db.brokers[brokerKey].accounts?.includes(key)) {
        dependencies.add("broker accounts");
      }
    }

    // other customers
    for (const otherKey in this.props.db.customers) {
      if (key == otherKey) continue;
      const customer = this.props.db.customers[otherKey];
      if (customer.distributors && customer.distributors.includes(key)) {
        dependencies.add("other customers");
      }
    }

    // all other buckets
    const allOtherBuckets = this.props.db.meta.forecast_all_other || [];
    const correspondingCustomers = Object.values(
      this.props.db.meta.forecast_all_other_corresponding_customers || {}
    );
    if (allOtherBuckets.includes(key) || correspondingCustomers.includes(key)) {
      dependencies.add("all other buckets");
    }

    // customer assignment
    for (const user in this.props.db.companyUsers) {
      const assignedCustomers = this.props.db.companyUsers[user].customers;
      if (assignedCustomers && assignedCustomers.includes(key)) {
        dependencies.add("assigned company users");
      }
    }

    // Check for DRM related dependencies
    const invoicesList = Object.values(this.props.db.invoices);
    const transactionsList = Object.values(this.props.db.erpTransactions);
    if (invoicesList.some(invoice => invoice.customerKey === key)) {
      dependencies.add("invoices");
    }
    if (
      invoicesList.some(invoice =>
        Object.values(invoice.invoiceLines || {}).some(
          invoiceLine => invoiceLine.customerKey === key
        )
      )
    ) {
      dependencies.add("invoice lines");
    }
    if (transactionsList.some(transaction => transaction.customerKey === key)) {
      dependencies.add("transactions");
    }

    // DMM related dependencies
    const {
      drm_deductions_sources = {},
      deductions_sources_customers = {},
      matching_wildcards = {}
    } = this.props.db.meta || {};
    const {
      deductions_sources_customers: drm_deductions_sources_customers = {},
      matching_wildcards: drm_matching_wildcards = {}
    } = drm_deductions_sources;

    const sourcesIncludeCustomer = sources => {
      return (
        (typeof sources === "string" && sources === key) ||
        (Array.isArray(sources) &&
          typeof sources[0] === "string" &&
          sources.includes(key))
      );
    };

    if (
      Object.values(deductions_sources_customers).some(value =>
        sourcesIncludeCustomer(value)
      ) ||
      Object.values(matching_wildcards).some(value => value === key) ||
      Object.values(drm_deductions_sources_customers).some(value =>
        sourcesIncludeCustomer(value)
      ) ||
      Object.values(drm_matching_wildcards).some(value => value === key)
    ) {
      dependencies.add("deductions matching settings");
    }

    return dependencies;
  };

  handleSearchButton = () => {
    this.setState({
      showSearch: true
    });
  };

  handleSearch = event => {
    this.handleInfiniteLoad(true);
    this.setState({
      searchQuery: event.target.value.toLowerCase(),
      currentlySelected: "",
      allSelected: [],
      tableBodyRenderKey: this.state.tableBodyRenderKey + 1
    });
  };

  toggleMulti = () => {
    this.setState({
      multi: !this.state.multi,
      allSelected: [],
      currentlySelected: ""
    });
  };

  prettyPrint = list => {
    if (Array.isArray(list) && list.length) {
      return [...new Set(list)]
        .reduce((acc, listItem) => {
          const sanitizedListItem = listItem
            .split("|")
            .map(subListItem => subListItem.trim());
          return [...acc, ...sanitizedListItem];
        }, [])
        .join(" | ");
    }
    return "";
  };

  handleProfileOpen = (event, rowData) => {
    const customerKey = rowData.key;
    this.props.showRightDrawer(
      <div>
        <CustomerProfile
          customerKey={customerKey}
          db={this.props.db}
          readOnly={this.props.readOnly}
          openClose={this.props.openClose}
          curDate={this.state.curDate}
        />
      </div>
    );
  };

  handleRowSelection = rows => {
    const allSelected = rows.map(row => row.key);
    this.allSelected.current = allSelected;
  };

  onCreatePnlGroup = () => {
    if (this.allSelected.current.length == 0) return;
    this.props.openClose.setAppModal(
      "Confirm Group Creation",
      <div>
        <br />
        <div className="centering">
          Choose a name for your new group of customers, consisting of:
        </div>
        {this.allSelected.current.map(customerKey => {
          return (
            <div className="centering">
              <strong>{this.props.db.customers[customerKey].name}</strong>
            </div>
          );
        })}
        <div className="centering">
          <TextField
            onChange={event => {
              this.setState({
                pnlGroup: event.target.value
              });
            }}
            floatingLabelText="Customer Group Name"
          />
        </div>
        <br />
        <div className="centering">
          <Button
            label="Confirm"
            primary
            onClick={() => {
              this.batchChangePnlGroup(this.state.pnlGroup);
              this.allSelected.current = [];
              this.setState({ resetSelection: true });
              this.props.openClose.closeAppModal();
            }}
          />
        </div>
        <br />
        <br />
      </div>
    );
  };

  batchChangePnlGroup = newGroup => {
    if (this.allSelected.current.length == 0) return;
    const newCustomers = {};
    const groupColor = randomColor();
    for (let i = 0; i < this.allSelected.current.length; i++) {
      const customer = this.allSelected.current[i];
      const customerData = this.props.db.customers[customer];
      var newTimeframes = [];
      customerData.timeframes?.forEach(oldTimeframe => {
        const updatedTimeframe = {
          ...oldTimeframe,
          pnlGroup: newGroup,
          groupColor
        };
        newTimeframes.push(updatedTimeframe);
      });
      newCustomers[customer] = {
        ...customerData,
        pnlGroup: newGroup,
        groupColor,
        timeframes: newTimeframes
      };
    }
    batchUpdateFirebase(2, newCustomers);
  };

  onColumnSort = (field, direction) => {
    if (this.state.sortField == field) {
      this.setState({ reverseSort: !this.state.reverseSort });
    } else {
      this.setState({ sortField: field, reverseSort: false });
    }
  };

  getCustomerList = (props, cutoff = true) => {
    const { companyUsers } = this.state;
    const filteredCustomers = objToArray(props.db.customers);

    let customerList = [];
    for (let i = 0; i < filteredCustomers.length; i++) {
      var c = jQuery.extend(true, {}, filteredCustomers[i]);
      // find timeframe
      for (let j = 0; j < c.timeframes?.length; j++) {
        if (
          new Date(c.timeframes[j].effectiveDate ?? this.state.curDate) <=
            this.state.curDate &&
          this.state.curDate <=
            new Date(c.timeframes[j].expirationDate ?? this.state.curDate)
        ) {
          c = { key: c.key, ...c.timeframes[j] };
          break;
        }
      }

      const distributorNames = c.distributors
        ? c.distributors.map(key => {
            return this.props.db.customers[key]
              ? this.props.db.customers[key].name
              : key;
          })
        : [];
      if (c.isDirect) {
        distributorNames.push(c.name);
      }

      c.distributorsField = distributorNames
        ? this.prettyPrint(distributorNames)
        : "";
      if (c.isDistributor) {
        c.distributorsField = c.name; // if customer is distributor, make its field its own name
      }
      c.otherNamesField = c.otherNames ? this.prettyPrint(c.otherNames) : "";
      // Convert contact commas to pipes
      c.contactName =
        c.contactName
          ?.split(",")
          .map(name => name.trim())
          .join(" | ") || "";

      c.agencies = c.agencies ? this.prettyPrint(c.agencies) : "";

      // Get users specifically assigned to this company
      const assignedUsers = Object.keys(companyUsers)
        .filter(user => {
          return (
            companyUsers[user].customers &&
            companyUsers[user].customers.includes(c.key)
          );
        })
        .map(user => {
          return companyUsers[user].name;
        });
      c.numAssignedTo = assignedUsers.length;
      c.assignedToField =
        (c.numAssignedTo > 0 ? `(${c.numAssignedTo}) ` : "") +
        this.prettyPrint(assignedUsers);

      c.revenueSourcesField = c.isDistributor
        ? "N/A"
        : c.revenue_sources
        ? this.prettyPrint(c.revenue_sources)
        : "";

      if (
        this.state.searchQuery === "" ||
        c.name.toLowerCase().indexOf(this.state.searchQuery) != -1 ||
        c.distributorsField.toLowerCase().indexOf(this.state.searchQuery) != -1
      ) {
        customerList.push(c);
      }
    }

    if (this.state.sortField) {
      customerList = sortByField(
        this.state.sortField,
        customerList,
        this.state.reverseSort
      );
    } else {
      customerList = sortByField("name", customerList, true);
    }

    customerList = cutoff
      ? take(customerList, this.state.count + this.state.pagelength)
      : customerList;

    return customerList;
  };

  getCustomersJSX = filteredCustomers => {
    const selectedSet = new Set(this.allSelected.current);
    const customersJSX = [];
    for (let i = 0; i < filteredCustomers.length; i++) {
      const c = filteredCustomers[i];
      const customerVal = {
        key: c.key,
        name: (
          <span>
            {c.name}
            {c.pnlGroup && (
              <GroupWorkIcon
                style={{ color: c.groupColor, ...styles.miniIcon }}
              />
            )}
          </span>
        ),
        distributorsField: (
          <div
            style={
              c.isDistributor
                ? { color: purple70 }
                : c.isDirect
                ? { color: blue70 }
                : {}
            }>
            {c.distributorsField}
          </div>
        ),
        assignedToField: c.assignedToField,
        contactName: c.contactName,
        otherNamesField: c.otherNamesField
      };
      if (selectedSet.has(c.key)) {
        customerVal.tableData = { checked: true };
      }
      customersJSX.push(customerVal);
    }

    return customersJSX;
  };

  getCustomerData = () => {
    const customerList = this.getCustomerList(this.props);
    const customerLength = customerList.length;
    const customerJSX = this.getCustomersJSX(customerList);

    return [customerLength, customerJSX];
  };

  getDownloadData = () => {
    const customerList = this.getCustomerList(this.props, false);
    this.setState({ downloadData: customerList.map(escapeQuotes) }, () => {
      this.csvLink.current.link.click();
    });
  };

  openCustomTagsModal = () => {
    this.props.openClose.setAppModal(
      "Manage Tags for Alternative Names",
      <CustomTagsManager openClose={this.props.openClose} />
    );
  };

  handleInfiniteLoad = reset => {
    const count = reset
      ? this.state.pagelength
      : this.state.count + this.state.pagelength;
    this.setState({ count });
  };

  componentDidMount() {
    getCompanyUsers(companyUsers => {
      this.setState({ companyUsers });
    });
    const customerKey = this.props.match?.params?.key;
    const allCustomers = this.props.db.customers;
    if (customerKey && customerKey in allCustomers) {
      this.handleProfileOpen(null, { key: customerKey });
    }
  }

  render() {
    const [customerLength, customersJSX] = this.getCustomerData();
    const actionParams = {
      openProfile: rowData => ({
        onClick: this.handleProfileOpen
      })
    };
    if (this.props.db.permissions.includes("admin")) {
      actionParams.delete = rowData => ({
        onClick: this.deleteCustomer
      });
    }
    const columns = [
      {
        title: "Name",
        field: "name",
        width: 300,
        cellStyle: { paddingLeft: 50 },
        headerStyle: { paddingLeft: 50 }
      },
      { title: "First Receivers", field: "distributorsField", width: 300 },
      { title: "Assigned To", field: "assignedToField" },
      { title: "Contact", field: "contactName" },
      {
        title: "Alternative Names",
        field: "otherNamesField",
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          maxWidth: 300
        },
        multiple: true,
        unPrettify: true
      }
    ];
    const user = firebase.auth().currentUser;

    return (
      <div>
        <Toolbar>
          <ToolbarGroup>
            <ToolbarTitle text="All Customers" style={{ color: black }} />
            <ToolbarSeparator />
            {this.props.db.meta.tier !== "pine" &&
              this.props.db.meta.tier !== "pine-plus" &&
              (this.state.multi ? (
                <IconButton
                  onClick={this.toggleMulti}
                  tooltip="Viewing Mode"
                  style={styles.iconButton}
                  size="large">
                  <ViewAgendaIcon />
                </IconButton>
              ) : (
                <IconButton
                  onClick={this.toggleMulti}
                  tooltip="Selection Mode"
                  style={styles.iconButton}
                  size="large">
                  <DoneAllIcon />
                </IconButton>
              ))}
            <IconButton
              onClick={this.handleSearchButton}
              tooltip="Search"
              size="large">
              <SearchIcon />
            </IconButton>
            {this.state.showSearch && (
              <TextField
                placeholder="Search..."
                style={{ marginTop: 0 }}
                onChange={this.handleSearch}
              />
            )}
          </ToolbarGroup>

          {this.state.multi ? (
            <Stack>
              {this.props.db.meta.tier != "pine" && (
                <Button label="Group for PnL" onClick={this.onCreatePnlGroup} />
              )}
              <Button
                label="Remove Grouping"
                color="error"
                variant="outlined"
                onClick={this.batchChangePnlGroup.bind(null, "")}
              />
            </Stack>
          ) : (
            <Stack>
              <DatePicker
                disableToolbar
                floatingLabelText="View Customer List At"
                value={this.state.curDate}
                compact
                onChange={newDate => {
                  this.setState({ curDate: newDate });
                }}
                inputFormat="MMM dd, yyyy"
              />
              <IconMenu
                iconButtonElement={
                  <IconButton tooltip="Settings" size="large">
                    <SettingsIcon />
                  </IconButton>
                }
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
                targetOrigin={{ horizontal: "right", vertical: "top" }}
                onChange={this.props.handleAdditionalDataDisplay}>
                <MenuItem disabled>
                  <ListSubheader style={{ marginLeft: "-15px" }}>
                    Settings
                  </ListSubheader>
                </MenuItem>
                <MenuItem
                  value={0}
                  leftIcon={<DownloadIcon />}
                  disabled={Object.keys(this.props.db.customers).length == 0}
                  onClick={this.getDownloadData}>
                  Download Customers
                </MenuItem>
                <MenuItem
                  value={1}
                  leftIcon={<Label />}
                  onClick={this.openCustomTagsModal}>
                  Manage Tags for Alternative Names
                </MenuItem>
              </IconMenu>
              {this.props.db.permissions.includes("add") &&
                !this.props.readOnly && (
                  <IconButton
                    onClick={this.addCustomer}
                    tooltip="Add Customer"
                    size="large">
                    <AddBoxIcon />
                  </IconButton>
                )}
              {isUserCresicorEmployee() &&
                Object.keys(this.props.db.customers) == 0 && (
                  <IconButton
                    onClick={this.importPresetCustomers}
                    tooltip="Import Preset Customers"
                    size="large">
                    <ImportExportIcon />
                  </IconButton>
                )}
            </Stack>
          )}
        </Toolbar>
        <PaginationMUITable
          data={customersJSX}
          columns={columns}
          allLoaded={this.state.count >= customerLength}
          selection={this.state.multi}
          onColumnSort={this.onColumnSort}
          handleProfileOpen={this.handleProfileOpen}
          handleInfiniteLoad={this.handleInfiniteLoad}
          handleRowSelection={this.handleRowSelection}
          actionParams={actionParams}
        />
        <PropDataUpdatedCSVLink
          data={this.state.downloadData}
          headers={this.state.headers}
          filename="Customer Export.csv"
          className="hidden"
          ref={this.csvLink}
          target="_blank"
        />
      </div>
    );
  }
}

export default Customers;
