import Button from "ui-library/Button";
import { Chip, MenuItem, Stack, TextField } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useDb } from "contexts/Db";
import { useOpenClose } from "contexts/OpenClose";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import CresicorIconButton from "ui-library/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { StyledEngineProvider } from "@mui/material/styles";
import {
  InvoiceLineDisplayService,
  InvoiceProcessingService,
  InvoiceDisplayService,
  RepaymentLineFilterService
} from "components/Deductions/DeductionsReconciliation/services";
import { InvoiceLineObject, InvoiceObject } from "components/Deductions/models";
import PromProfile from "components/Planning/PromProfile";
import {
  updateFirebaseInvoice,
  removeInvoiceLine
} from "components/Deductions/DeductionsReconciliation/services/InvoiceServices/invoiceProcessing";
import { useSnackbar } from "notistack";
import { resolutionLineTypes } from "components/Deductions/constants/ReconciliationTypes";
import { DisplayInvoiceLineObject } from "reconciliation-types/invoiceLineTypes";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import SearchIcon from "@mui/icons-material/Search";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import { useDRMEvents } from "contexts/DRMEvents";
import { DRMEventType } from "components/Deductions/DeductionsReconciliation/ActivityLog/DRMEvent";
import { displayUSCurrency } from "helpers/DataProcessing";
import CollapsibleTable, {
  ColumnConfigOptions
} from "ui-library/CollapsibleTable/CollapsibleTable";
import InvoiceLineMatchEditor from "./InvoiceLineMatchEditor";

const grey300 = grey["300"];

interface InvoiceLineTableProps {
  invoiceKey: string;
  invoice: InvoiceObject;
  data: Record<string, InvoiceLineObject>;
}

enum LineValidity {
  INVALID = "INVALID",
  VALID_NOT_MATCHING = "VALID_NOT_MATCHING",
  VALID_MATCHING = "VALID_MATCHING"
}

const useStyles = makeStyles(_theme => ({
  invoiceLineKeyChip: {
    margin: 4,
    backgroundColor: grey300,
    float: "left",
    width: "116px"
  }
}));

export default function InvoiceLineTable({
  invoiceKey,
  invoice,
  data
}: InvoiceLineTableProps) {
  const db = useDb();
  const openClose = useOpenClose();
  const { enqueueSnackbar } = useSnackbar();
  const [fundTypeValueMap, setFundTypeValueMap] = useState<
    Record<string, string>
  >({});

  const classes = useStyles();
  const [manualPromoLineKey, setManualPromoLineKey] = useState<string>("");
  const [editingLineKey, setEditingLineKey] = useState<string>("");
  const { addEvent } = useDRMEvents();

  // eslint-disable-next-line camelcase
  const {
    meta: { fundTypes = {}, product_groups: productGroups = {} } = {},
    allLines = {},
    invoices = {},
    erpTransactions = {},
    customers = {},
    products = {}
  } = db;

  const fundTypeOptions = useMemo<Record<string, string>>(
    () =>
      Object.assign(
        {},
        ...Object.keys(fundTypes)
          .map(typeKey => ({
            [typeKey]: fundTypes[typeKey]?.name
          }))
          .concat([{ None: "None" }])
          .sort((obj1, obj2) => {
            return Object.values(obj1)[0].localeCompare(Object.values(obj2)[0]);
          })
      ),
    [fundTypes]
  );

  useEffect((): void => {
    if (invoice.invoiceLines) {
      setFundTypeValueMap(
        Object.values(invoice.invoiceLines).reduce((prev, cur) => {
          return {
            ...prev,
            [cur.key]: cur.suggestedFundType ?? fundTypeOptions.None
          };
        }, {})
      );
    }
  }, [fundTypeOptions, invoice.invoiceLines]);

  const tableData = useMemo(() => {
    return Object.keys(data)
      .map(invoiceLineKey => {
        const invoiceLineData = data[invoiceLineKey];
        return InvoiceLineDisplayService.processInvoiceLineForDisplay(
          invoice,
          invoiceLineData,
          invoiceLineKey,
          fundTypes,
          invoices,
          erpTransactions,
          allLines,
          products,
          customers
        );
      })
      .sort((a, b) => {
        return a.invoiceLineNumber - b.invoiceLineNumber;
      });
  }, [
    allLines,
    customers,
    data,
    erpTransactions,
    fundTypes,
    invoice,
    invoices,
    products
  ]);

  const isPromoLineIdValid = useCallback(
    (lineKey: string, row: DisplayInvoiceLineObject) => {
      if (!(lineKey in allLines)) {
        return LineValidity.INVALID;
      }

      const promLine = allLines[lineKey];

      if (promLine.customer !== row.customerKey)
        return LineValidity.VALID_NOT_MATCHING;
      if (promLine.productGroup !== "custom") {
        if (
          row.productGroupKey &&
          row.productGroupKey !== promLine.productGroup
        )
          return LineValidity.VALID_NOT_MATCHING;
        if (
          row.productKey &&
          !(productGroups[promLine.productGroup]?.products || []).includes(
            row.productKey
          )
        )
          return LineValidity.VALID_NOT_MATCHING;
      } else {
        const lineProductSet = new Set(promLine.product);
        if (
          row.productGroupKey &&
          (productGroups[row.productGroupKey]?.products || []).filter(x =>
            lineProductSet.has(x)
          ).length === 0
        )
          return LineValidity.VALID_NOT_MATCHING;
        if (row.productKey && !lineProductSet.has(row.productKey))
          return LineValidity.VALID_NOT_MATCHING;
      }

      return LineValidity.VALID_MATCHING;
    },
    [productGroups, allLines]
  );

  const canUserManuallyMatchInvoiceLine = useCallback(
    invoiceLine => {
      if (!(invoiceLine.customerKey in customers)) return false;
      if (invoiceLine.productKey && !(invoiceLine.productKey in products))
        return false;
      if (
        invoiceLine.productGroupKey &&
        !(invoiceLine.productGroupKey in (productGroups || {}))
      )
        return false;

      if (
        RepaymentLineFilterService.findInvoiceLineRepaymentLines(
          invoiceLine,
          invoices,
          erpTransactions
        ).length
      ) {
        return false;
      }

      if (!invoice.resolutionLines || !invoiceLine.resolutionLines) {
        return true;
      }

      return !Object.keys(invoiceLine.resolutionLines)
        .map(
          resolutionLineKey =>
            (invoice.resolutionLines || {})[resolutionLineKey] || {}
        )
        .some(
          resolutionLine =>
            resolutionLine.type === resolutionLineTypes.CLEAR ||
            resolutionLine.type === resolutionLineTypes.WRITE_OFF
        );
    },
    [
      customers,
      products,
      productGroups,
      invoices,
      erpTransactions,
      invoice.resolutionLines
    ]
  );

  const handleErrorText = (validity: LineValidity, isClosed: boolean) => {
    if (isClosed) {
      return "Promotion is closed.";
    }
    if (validity === LineValidity.VALID_MATCHING) {
      return "";
    }
    if (validity === LineValidity.VALID_NOT_MATCHING) {
      return "Wrong customer/product.";
    }
    return "Invalid promotion line.";
  };

  const handleErrorColor = (validity: LineValidity, isClosed: boolean) => {
    if (isClosed) {
      return "warning";
    }
    if (validity === LineValidity.VALID_NOT_MATCHING) {
      return "warning";
    }
    return undefined;
  };

  const isPromoLineClosed = useCallback(
    (lineKey: string) => {
      const currLine = allLines[lineKey ?? ""] || {};
      if ("closed" in currLine && currLine.closed) {
        return true;
      }
      return false;
    },
    [allLines]
  );

  const deleteInvoiceLine = useCallback(
    (invoiceKeyParam, invoiceLineKey, resolutionLines) => {
      openClose.setAppModal(
        "Delete Invoice Line",
        <div className="centering">
          Are you sure you want to delete this invoice line?
        </div>,

        <Stack>
          <Button
            label="Yes, I'm sure"
            color="error"
            variant="text"
            onClick={() => {
              if (resolutionLines) {
                openClose.closeAppModal();
                openClose.setAppModal(
                  "Unable to delete invoice line",
                  <div className="centering">
                    <Button
                      label="Okay"
                      onClick={() => {
                        openClose.closeAppModal();
                      }}
                    />
                  </div>
                );
              } else {
                openClose.closeAppModal();
                removeInvoiceLine(
                  invoiceKeyParam,
                  invoiceLineKey,
                  () => {
                    enqueueSnackbar("Removed invoice line.", {
                      variant: "success"
                    });
                    addEvent({
                      type: DRMEventType.INVOICE_LINE_DELETED,
                      invoiceKey: invoiceKeyParam,
                      invoiceLineKey
                    });
                  },
                  () => {
                    enqueueSnackbar("Unknown error occurred.", {
                      variant: "error"
                    });
                  }
                );
              }
            }}
          />
          <Button
            label="No, go back"
            variant="text"
            onClick={() => {
              openClose.closeAppModal();
            }}
          />
        </Stack>
      );
    },
    [addEvent, enqueueSnackbar, openClose]
  );

  const columnConfig = useMemo(
    () =>
      new Map([
        [
          "matchedPromLine",
          {
            name: "Promotion Line ID",
            render: (val, invoiceLine) => {
              const { invoiceLineKey, status } = invoiceLine;
              const promKey =
                InvoiceDisplayService.getPromKeyFromMatchedPromLine(
                  val as string
                );
              const lineKey = invoiceLine.matchedPromLine;
              const canMatch = canUserManuallyMatchInvoiceLine(invoiceLine);
              const validity = isPromoLineIdValid(
                manualPromoLineKey,
                invoiceLine
              );
              const isClosed = isPromoLineClosed(manualPromoLineKey);
              const invalidLineId = validity === LineValidity.INVALID;
              const onLineAttemptSave = (
                forceCancel: boolean = false
              ): void => {
                if (!forceCancel && !invalidLineId) {
                  InvoiceProcessingService.saveInvoicePromotionMatch(
                    invoiceKey,
                    invoiceLineKey,
                    allLines[manualPromoLineKey].promKey,
                    manualPromoLineKey,
                    () => {
                      if (manualPromoLineKey !== invoiceLine.matchedPromLine) {
                        addEvent({
                          type: DRMEventType.INVOICE_LINE_PROM_LINE_MANUAL_MATCH_ADDED,
                          invoiceLineKey: invoiceLine.key,
                          invoiceKey: invoice.key
                        });
                      }
                      openClose.closeAppModal();
                      enqueueSnackbar("Updated matched promotion line.", {
                        variant: "success"
                      });
                    },
                    () => {
                      openClose.closeAppModal();
                      enqueueSnackbar("Unknown error occurred.", {
                        variant: "error"
                      });
                    },
                    invoiceLine.customerKey,
                    invoiceLine.customerName,
                    invoiceLine.productKey,
                    invoiceLine.productName
                  );
                } else {
                  enqueueSnackbar("Canceled operation.", {
                    variant: "error"
                  });
                }
                setManualPromoLineKey("");
                setEditingLineKey("");
              };

              return (
                <>
                  {editingLineKey === invoiceLine.key && (
                    <>
                      <StyledEngineProvider injectFirst>
                        <TextField
                          variant="outlined"
                          value={manualPromoLineKey}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const { value }: { value: string } = e.target;
                            setManualPromoLineKey(value);
                          }}
                          color={handleErrorColor(validity, isClosed)}
                          error={invalidLineId}
                          helperText={handleErrorText(validity, isClosed)}
                          onKeyDown={event => {
                            const { key } = event;
                            if (key === "Enter") {
                              onLineAttemptSave();
                            } else if (key === "Escape") {
                              onLineAttemptSave(true);
                            }
                          }}
                          autoFocus
                        />
                        <br />
                      </StyledEngineProvider>
                    </>
                  )}

                  <Grid container wrap="nowrap" justifyContent="flex-end">
                    <Grid item>
                      {editingLineKey !== invoiceLine.key && lineKey && (
                        <Chip
                          className={classes.invoiceLineKeyChip}
                          clickable
                          onClick={() =>
                            promKey
                              ? openClose.showRightDrawer(
                                  <PromProfile
                                    promKey={promKey}
                                    defaultLine={invoiceLine.matchedPromLine}
                                    openClose={openClose}
                                    db={db}
                                  />
                                )
                              : undefined
                          }
                          label={lineKey}
                        />
                      )}
                    </Grid>
                    <Grid item>
                      {editingLineKey !== invoiceLine.key && status && (
                        <CresicorIconButton
                          onClick={() => {
                            setManualPromoLineKey(
                              invoiceLine.matchedPromLine ?? ""
                            );
                            setEditingLineKey(invoiceLine.key);
                          }}
                          disabled={!canMatch}>
                          <EditIcon />
                        </CresicorIconButton>
                      )}
                    </Grid>
                  </Grid>
                  {editingLineKey === invoiceLine.key && status && (
                    <>
                      <CresicorIconButton
                        disabled={!canMatch}
                        onClick={() => {
                          setManualPromoLineKey("");
                          setEditingLineKey("");
                          openClose.setAppModal({
                            title: "Edit Promotion Line Match",
                            content: (
                              <InvoiceLineMatchEditor
                                invoiceKey={invoiceKey}
                                invoiceLineKey={invoiceLineKey}
                                invoiceLine={invoiceLine}
                                invoice={invoice}
                                openClose={openClose}
                              />
                            ),
                            maxWidth: "lg"
                          });
                        }}
                        tooltip="Search Promotions">
                        <SearchIcon />
                      </CresicorIconButton>
                      <CresicorIconButton
                        onClick={() => onLineAttemptSave()}
                        tooltip={invalidLineId ? "Cancel" : "Confirm"}>
                        {invalidLineId ? <ClearIcon /> : <CheckIcon />}
                      </CresicorIconButton>
                      <CresicorIconButton
                        onClick={() => {
                          InvoiceProcessingService.resetSinglePromotionInvoiceMatches(
                            invoiceKey,
                            invoiceLineKey,
                            promKey!,
                            lineKey!,
                            () => {
                              addEvent({
                                type: DRMEventType.INVOICE_LINE_PROM_LINE_UNMATCHED,
                                invoiceLineKey: invoiceLine.key,
                                invoiceKey: invoice.key
                              });
                              enqueueSnackbar("Unmatched promotion line.", {
                                variant: "success"
                              });
                            },
                            () => {
                              enqueueSnackbar("Unknown error occurred.", {
                                variant: "error"
                              });
                            }
                          );
                          setManualPromoLineKey("");
                          setEditingLineKey("");
                        }}
                        tooltip="Unmatch">
                        <FavoriteBorderIcon />
                      </CresicorIconButton>
                    </>
                  )}
                </>
              );
            }
          }
        ],
        [
          "productName",
          {
            name: "Product"
          }
        ],
        [
          "customerName",
          {
            name: "Customer"
          }
        ],
        [
          "amount",
          {
            name: "Original Amount",
            render: val => displayUSCurrency(val)
          }
        ],
        [
          "fundType",
          {
            name: "Fund Type",
            render: (val, invoiceLine) => {
              const { key } = invoiceLine;
              const fundTypeKey = fundTypeValueMap[key] ?? ""; // Has to be defaulted to string otherwise TextField will think component is uncontrolled, thus it'll set up an internal state and ignore value updates
              if (invoiceLine.matchedPromLine) {
                return fundTypes?.[allLines[invoiceLine.matchedPromLine].type]
                  ?.name;
              }
              if (
                InvoiceLineDisplayService.HumanReadableInvoiceLineStatuses[
                  invoiceLine.status
                ] ===
                  InvoiceLineDisplayService.HumanReadableInvoiceLineStatuses
                    .match_not_found &&
                invoiceLine.key
              ) {
                return (
                  <TextField
                    variant="outlined"
                    size="small"
                    select
                    key={key}
                    value={fundTypeKey}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      let { value }: { value: string | null } = e.target;
                      if (value === "None") {
                        value = null;
                      }
                      // EW - we don't even have to set the model value here because firebase auto updates
                      updateFirebaseInvoice(
                        {
                          ...invoice,
                          invoiceLines: {
                            ...invoice.invoiceLines,
                            [invoiceLine.key]: {
                              ...invoiceLine,
                              suggestedFundType: value === "None" ? null : value
                            }
                          }
                        },
                        () =>
                          enqueueSnackbar("Updated fund type.", {
                            variant: "success"
                          })
                      );
                    }}>
                    {Object.entries(fundTypeOptions).map(
                      ([optionKey, option]) => (
                        <MenuItem
                          disabled={
                            !fundTypes?.[optionKey]?.writeoffAccountKey &&
                            option !== "None"
                          }
                          key={optionKey}
                          value={optionKey}>
                          {option}
                        </MenuItem>
                      )
                    )}
                  </TextField>
                );
              }
              return <div>&nbsp;</div>;
            }
          }
        ],
        [
          "date",
          {
            name: "Date",
            render: val =>
              new Date(val as string)?.toLocaleDateString() || "Unknown"
          }
        ],
        [
          "status",
          {
            name: "Status",
            render: val => {
              if (!val) {
                return "Pending";
              }
              return InvoiceLineDisplayService.HumanReadableInvoiceLineStatuses[
                val as string
              ];
            }
          }
        ],
        [
          "key",
          {
            name: "Actions",
            render: val => {
              const invoiceLine = data[val as string];
              const invoiceLineKey = val as string;
              const { resolutionLines } = invoiceLine;
              const deleteInvoiceLineTooltip = resolutionLines
                ? "Unable to delete invoice line - has attached resolution line"
                : "Delete Invoice Line";

              return (
                <CresicorIconButton
                  onClick={() => {
                    deleteInvoiceLine(
                      invoiceKey,
                      invoiceLineKey,
                      resolutionLines
                    );
                  }}
                  disabled={resolutionLines}
                  tooltip={deleteInvoiceLineTooltip}>
                  <DeleteIcon />
                </CresicorIconButton>
              );
            }
          }
        ]
      ] as [keyof DisplayInvoiceLineObject, ColumnConfigOptions<DisplayInvoiceLineObject>][]),
    [
      canUserManuallyMatchInvoiceLine,
      isPromoLineIdValid,
      manualPromoLineKey,
      isPromoLineClosed,
      editingLineKey,
      invoiceKey,
      allLines,
      openClose,
      enqueueSnackbar,
      addEvent,
      invoice,
      db,
      fundTypeValueMap,
      fundTypes,
      fundTypeOptions,
      deleteInvoiceLine,
      classes.invoiceLineKeyChip,
      data
    ]
  );

  return (
    <CollapsibleTable<DisplayInvoiceLineObject>
      data={tableData}
      columnConfig={columnConfig}
      index="key"
      pagination={{
        enabled: true,
        pageSize: 10,
        rowsPerPageOptions: [10, 20]
      }}
    />
  );
}
