import React, { useState, useMemo, useCallback } from "react";
import {
  Popover,
  Tooltip,
  IconButton,
  Paper,
  Button,
  TextField
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { ForecastFilter } from "components/ForecastRs/types";
import { green, grey } from "@mui/material/colors";

interface TableSearchProps {
  headerKey: string;
  productSearchQuery: string;
  customerSearchQuery: string;
  setProductSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  setCustomerSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  pivot: ForecastFilter;
}

export const TableSearch = (props: TableSearchProps) => {
  const {
    headerKey,
    productSearchQuery,
    customerSearchQuery,
    setProductSearchQuery,
    setCustomerSearchQuery,
    pivot
  } = props;
  const searchQuery = useMemo(
    () =>
      pivot === ForecastFilter.CUSTOMER
        ? productSearchQuery
        : customerSearchQuery,
    [pivot, productSearchQuery, customerSearchQuery]
  );
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [searchText, setSearchText] = useState<string>(searchQuery);

  const onTextSearch = useCallback(
    (value: string): void => {
      setSearchText(value);
      if (pivot === ForecastFilter.CUSTOMER) {
        setProductSearchQuery(value);
      } else {
        setCustomerSearchQuery(value);
      }
    },
    [pivot, setCustomerSearchQuery, setSearchText, setProductSearchQuery]
  );

  const onTextReset = useCallback(() => {
    if (pivot === ForecastFilter.CUSTOMER) {
      setProductSearchQuery("");
    } else {
      setCustomerSearchQuery("");
    }
    setSearchText("");
  }, [pivot, setCustomerSearchQuery, setProductSearchQuery]);

  const onSearchClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const searchClose = (): void => {
    setAnchorEl(null);
  };

  const searchActive = Boolean(searchQuery);

  const open = Boolean(anchorEl);
  const id = `${headerKey}-filter-popover`;

  return (
    <div>
      <Tooltip
        title={searchActive ? `Filtering on "${searchQuery}"` : "Search"}>
        <IconButton size="small" onClick={onSearchClick}>
          <FilterAltIcon
            htmlColor={searchActive ? green[500] : grey[500]}
            fontSize="inherit"
          />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={searchClose}
        onClick={e => e.stopPropagation()}>
        <Paper style={{ padding: 12 }}>
          <TextField
            autoFocus
            placeholder="Search"
            value={searchText}
            onChange={e => onTextSearch(e.target.value)}
          />
          <Button type="button" color="error" onClick={onTextReset}>
            Reset
          </Button>
        </Paper>
      </Popover>
    </div>
  );
};

export default TableSearch;
