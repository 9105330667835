import {
  ERPTransactionObject,
  InvoiceObject
} from "components/Deductions/models";
import { DbContextValues } from "contexts/Db";
import { TransactionFilterService } from "../ERPTransactionServices";

// eslint-disable-next-line import/prefer-default-export
export function findAttachedInvoices(
  repayment: ERPTransactionObject,
  db: DbContextValues
): Record<string, InvoiceObject> {
  const { invoices } = db;

  const attachedDeductions: Record<string, ERPTransactionObject> =
    TransactionFilterService.findAttachedDeductions(repayment, db);

  // filter for unique only
  const allInvoiceKeys: string[] = [
    ...new Set(
      Object.values(attachedDeductions)
        .map((deduction: ERPTransactionObject) =>
          Object.values(deduction.linkedInvoices || {})
        )
        .reduce(
          (partialInvoiceKeys, invoiceKeys) => [
            ...partialInvoiceKeys,
            ...invoiceKeys
          ],
          []
        )
    )
  ];

  return allInvoiceKeys
    .map((invoiceKey: string) => invoices[invoiceKey])
    .filter(invoice => invoice) // filter for only valid invoices
    .reduce((allInvoices, invoice) => {
      return {
        ...allInvoices,
        [invoice.key]: invoice
      };
    }, {});
}
