import React from "react";
import { connect, ConnectedProps } from "react-redux";

import Checkbox from "ui-library/Checkbox";
import { Dialog } from "@mui/material";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import RefreshIcon from "@mui/icons-material/Refresh";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import withStyles from "@mui/styles/withStyles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Button from "ui-library/Button";
import IconButton from "ui-library/IconButton";
import MenuItem from "ui-library/MenuItem";
import Select from "ui-library/Select";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { ButtonGroup } from "react-bootstrap-table";
import ReactTable from "react-table-v6";
import withDraggableColumns from "react-table-hoc-draggable-columns";
import FileSaver from "file-saver";
import { withSnackbar } from "notistack";

import { DbContextValues } from "contexts/Db";
import { InvoiceObject } from "deductions-models/index";
import {
  invoiceStatuses,
  invoiceLineStatuses
} from "components/Deductions/constants/ReconciliationStatuses";
import PromProfile from "components/Planning/PromProfile";
import Mixpanel from "helpers/Mixpanel";
import { updateCurrentUserCompany, updateFirebase } from "helpers/Firebase";
import { moveElementWithoutDeletion } from "helpers/Misc";
import { blueGrey, grey, common } from "@mui/material/colors";
import { RootState } from "js/store";
import { DRMEventType } from "components/Deductions/DeductionsReconciliation/ActivityLog/DRMEvent";
import { calculateSpendRateDelta, confidenceFilter, Confidence } from "./index";
import {
  updateField,
  addLineKey,
  deleteLineKey,
  getSuggestedMatches,
  reset
} from "./actions";
import IlmWorker from "./ilm.worker.js";

const blueGrey50 = blueGrey["50"];
const grey200 = grey["200"];
const grey400 = grey["400"];
const { white } = common;

const ReactTableDraggableColumns = withDraggableColumns(ReactTable);

const widths = {
  Row: 55,
  "Deductions Amount": 154,
  "Customer Name (accumulation)": 233,
  "Spend Rate": 98,
  "Month-Year": 101,
  "Matched Month-Year": 163,
  "Matched Promotion Customer": 225,
  "Matched Line Key": 260,
  "Matched Line Product Group": 214
};

const accumColsOrder = [
  "Matched Line Key",
  "Deductions Amount",
  "Matched Store Customer",
  "Customer Name",
  "Matched Promotion Customer",
  "Customer Name (accumulation)",
  "Matched Product",
  "Matched Line Product Group",
  "Spend Rate",
  "Matched Line Spend Rate",
  "Spend Rate Difference",
  "Month-Year",
  "Matched Month-Year",
  "Matched Promotion Type",
  "Matched Line Closed",
  "Matched Line Account"
];
const allLinesColsOrder = [
  "Matched Line Key",
  "Deductions Amount",
  "Matched Store Customer",
  "Customer Name",
  "Matched Promotion Customer",
  "Product Name",
  "Matched Product",
  "Matched Line Product Group",
  "Spend Rate",
  "Matched Line Spend Rate",
  "Spend Rate Difference",
  "Month-Year",
  "Matched Month-Year",
  "Promotion Type",
  "Matched Promotion Type",
  "Matched Line Closed",
  "Matched Line Account"
];

const styles = {
  col: {
    backgroundColor: blueGrey50
  },
  title: {
    fontWeight: "bold",
    textAlign: "center",
    paddingTop: "25px",
    width: $(window).width() / 4
  },
  scrollBar: {
    height: $(window).height() - 330,
    width: $(window).width() * 0.8
  },
  chip: {
    margin: 4,
    cursor: "pointer"
  }
};

const StyledAppBar = withStyles({
  root: {
    position: "relative",
    backgroundColor: "rgb(66 66 66)",
    boxShadow: "none"
  }
})(AppBar);

const StyledTypography = withStyles({
  root: {
    flex: 1
  }
})(Typography);

const StyledSlide = withStyles({
  root: {
    zIndex: "1500 !important"
  }
})(Slide);

const StyledDialog = withStyles({
  root: {
    zIndex: "1500 !important"
  }
})(Dialog);

const Transition = React.forwardRef((props, ref) => {
  return <StyledSlide direction="up" ref={ref} {...props} />;
});

// TODO tighten up types for state and props
interface MatchingResultState {
  view: string;
  order: string[];
  modalOpen: boolean;
  height: number;
  mouseX: number | null;
  mouseY: number | null;
  hiddenColumns: string[];
  clicked: string | null;
  dmmOrdering: Record<string, Record<string, string[]>>;
  confidenceLevel: string;
  multipleSuggestions: Record<string, MultipleSuggestionStyle>;
  checkboxOverrides: Record<number, boolean>;
}

interface MultipleSuggestionStyle {
  backgroundColor: string;
  selectedKey: string;
}

interface MatchedLineObject {
  key: string;
  status: string;
  openAmount: string;
  startDate: string;
  endDate: string;
  invoiceKey: string;
  invoiceLineKey: string;
  invoiceLineNumber: number;
  displayStatus: string;
  customerKey: string;
  productKey: string;
  date: string;
  Row: number;
  "Matched Line Key": string;
  "Deductions Amount": number;
  "Matched Store Customer": string;
  "Customer Name": string;
  "Matched Promotion Customer": string;
  "Product Name": string;
  "Matched Product": string;
  "Matched Line Product Group": string;
  "Spend Rate": number;
  "Matched Line Spend Rate": string;
  "Spend Rate Difference": string;
  "Month-Year": string;
  "Matched Month-Year": string;
  "Promotion Type": string;
  "Matched Promotion Type": string;
  "Matched Line Closed": string;
  "Matched Line Account": string;
}

interface MatchingResultProps extends PropsFromRedux {
  db: DbContextValues;
  showRightDrawer: (jsx: any, customStyles?: {}) => void;
  openClose: any;
  invoice: InvoiceObject;
  invoiceKey: string;
  source: string;
  stepIndex: number;
}

class MatchingResult extends React.Component<
  MatchingResultProps,
  MatchingResultState
> {
  constructor(props) {
    super(props);
    this.state = {
      view: "View All Lines",
      order: [],
      modalOpen: false,
      height: $(window).height(),
      mouseX: null,
      mouseY: null,
      hiddenColumns: [],
      clicked: null,
      dmmOrdering: props.db.dmmOrdering,
      confidenceLevel: Confidence.ANY,
      multipleSuggestions: {
        "-": {
          backgroundColor: "#FCEEA9",
          selectedKey: ""
        }
      },
      checkboxOverrides: {}
    };
  }

  UNSAFE_componentWillReceiveProps({ saveMatches }) {
    if (saveMatches) {
      this.saveMatchedLines();
    }
  }

  changeView = () => {
    const newView =
      this.state.view === "View All Lines"
        ? "View Accumulated Lines"
        : "View All Lines";
    let order = [];
    if (newView === "View All Lines") {
      order = this.getOrder(
        this.props.invoiceLines,
        allLinesColsOrder,
        newView
      );
    } else {
      order = this.getOrder(
        this.props.accumInvoiceLines,
        accumColsOrder,
        newView
      );
    }
    this.setState({ view: newView, order });

    Mixpanel.track("Toggle View All Lines/Accumulated Match");
  };

  snackbarAction = key => (
    <Button
      label="Dismiss"
      onClick={() => {
        this.props.closeSnackbar(key);
      }}
      style={{ color: white }}
    />
  );

  exportBothViews = () => {
    const fileName = Date.now();
    const confidenceThresholds =
      this.props.db.meta?.drm_deductions_sources
        ?.deductions_matching_thresholds || {};
    this.props.enqueueSnackbar(
      <div>
        Generating Export
        <RefreshIcon
          className="fa-spin"
          style={{ color: white, marginLeft: 10 }}
        />
      </div>,
      {
        variant: "success",
        key: fileName,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center"
        },
        action: this.snackbarAction
      }
    );
    const worker = new IlmWorker();
    worker.onerror = event => {
      this.props.closeSnackbar(fileName);
      this.props.enqueueSnackbar("Export Temporarily Unavailable", {
        variant: "error",
        autoHideDuration: 3000
      });
    };
    worker.onmessage = ({ data }) => {
      this.props.closeSnackbar(fileName);
      FileSaver.saveAs(data, "Invoice Line Matching Results.xlsx");
      worker.terminate();
    };
    worker.postMessage({
      type: "exportViews",
      args: {
        invoiceLines: this.props.invoiceLines,
        confidenceThresholds,
        source: this.props.source,
        wsAllLinesOptions: undefined
      }
    });
    Mixpanel.track("Export Matching Results");
  };

  unhideColumns = () => {
    this.setState({
      hiddenColumns: []
    });
  };

  createCustomButtonGroup = dialog => {
    const otherView =
      this.state.view === "View All Lines"
        ? "View Accumulated Lines"
        : "View All Lines";

    return (
      <ButtonGroup
        className="my-custom-class"
        sizeClass="btn-group-md"
        style={{
          paddingTop: "5px",
          paddingBottom: "4px",
          paddingRight: "5px"
        }}>
        {/* <ExportCSVButton
          btnText="Export Matching Data"
          onClick={this.exportBothViews}
          style={{
            height: "36px"
          }}
        /> */}
        {!dialog && (
          <Button
            label={
              <Tooltip title="Expand Result Table">
                <OpenWithIcon />
              </Tooltip>
            }
            backgroundColor={grey200}
            hoverColor={grey400}
            onClick={() => {
              this.setState({ modalOpen: true });
              Mixpanel.track("Match View Expanded");
            }}
            style={{ height: 36 }}
          />
        )}
        {this.state.hiddenColumns.length > 0 && (
          <button
            type="button"
            className="btn btn-default"
            onClick={this.unhideColumns}
            style={{
              height: "36px"
            }}>
            Unhide all
          </button>
        )}
      </ButtonGroup>
    );
  };

  columnClassNameFormat = (colName, fieldValue, row, rowIdx, colIdx) => {
    const data =
      this.state.view === "View All Lines"
        ? this.props.invoiceLinesDefault
        : this.props.accumInvoiceLinesDefault;
    const rowNumber = row._original.Row;
    const lineKeys = data[rowNumber]["Matched Line Key"];
    if (lineKeys === "-" && fieldValue === "-") {
      return "td-column-error";
    }
  };

  handleDelete = (row, lineKey, view) => {
    this.props.dispatch(deleteLineKey([row], lineKey, view));
  };

  handleMultipleSelectionChange = (event, row) => {
    const newKey = row.Row;
    const newlySelectedObj = {};
    newlySelectedObj[newKey] = {
      backgroundColor: "#FFFFFF",
      selectedKey: event.target.value
    };

    // the dropdown was changed for the first time
    if (!Object.keys(this.state.multipleSuggestions).includes(newKey)) {
      const newState = Object.assign(
        this.state.multipleSuggestions,
        newlySelectedObj
      );
      this.setState({ multipleSuggestions: newState });
    }

    // when the dropdown changes, the state of the checkbox should as well
    const newCheckboxStatus = {};
    newCheckboxStatus[newKey] = event.target.value !== "-";
    if (!Object.keys(this.state.checkboxOverrides).includes(newKey)) {
      const newCheckboxState = Object.assign(
        this.state.checkboxOverrides,
        newCheckboxStatus
      );
      this.setState({ checkboxOverrides: newCheckboxState });
    }
  };

  getVisibilityIcon = (lineKeys, lineKey, isDialog) => {
    return (
      <IconButton
        tooltip="View Promotion Line Details"
        onClick={() => {
          if (!lineKeys.includes(lineKey)) {
            this.props.dispatch(addLineKey([row], lineKey, this.state.view));
            Mixpanel.track("Line Key ID Added");
          } else {
            const promKey = lineKey.split("-").slice(0, -1).join("-");
            const showRightDrawerArgs = [
              <PromProfile
                promKey={promKey}
                defaultLine={lineKey}
                openClose={this.props.openClose}
                db={this.props.db}
              />
            ];
            if (isDialog) {
              showRightDrawerArgs.push({
                zIndex: 1500
              });
            }
            this.props.showRightDrawer(...showRightDrawerArgs);
            Mixpanel.track("Promotion Viewed", {
              View: "Deductions Scanner",
              Component: "MatchingResult"
            });
          }
        }}
        size="large">
        <VisibilityIcon />
      </IconButton>
    );
  };

  lineKeyColumnFormatter = (wrappedRow, isDialog = false) => {
    const { row } = wrappedRow;
    const rowNumber = row._original.Row;
    row.Row = rowNumber;
    const data =
      this.state.view === "View All Lines"
        ? this.props.invoiceLinesDefault
        : this.props.accumInvoiceLinesDefault;
    const origLineKeys = data[rowNumber]["Matched Line Key"].split("|");
    const lineKeys = wrappedRow.value.split("|");

    if (lineKeys[0] === "-" && origLineKeys[0] === "-") {
      return <div>-</div>;
    }

    const userHasChosenSelection = Object.keys(
      this.state.multipleSuggestions
    ).includes(String(row.Row));
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {lineKeys.length > 1 ? (
          <div>
            <FormControl
              style={{
                backgroundColor: userHasChosenSelection
                  ? this.state.multipleSuggestions[row.Row].backgroundColor
                  : this.state.multipleSuggestions["-"].backgroundColor
              }}>
              <InputLabel id="multiple-selections-dropdown">
                {userHasChosenSelection ? "" : "Multiple Suggestions"}
              </InputLabel>
              <Select
                labelId="multiple-selections-dropdown"
                value={
                  userHasChosenSelection
                    ? this.state.multipleSuggestions[row.Row].selectedKey
                    : this.state.multipleSuggestions["-"].selectedKey
                }
                onChange={e => this.handleMultipleSelectionChange(e, row)}>
                <MenuItem value="-">-</MenuItem>
                {lineKeys.map(lineKey => (
                  <MenuItem value={lineKey} key={lineKey}>
                    {lineKey}
                    {this.getVisibilityIcon(lineKeys, lineKey, isDialog)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        ) : (
          <div>
            {lineKeys[0]}
            {this.getVisibilityIcon(lineKeys, lineKeys[0], isDialog)}
          </div>
        )}
      </div>
    );
  };

  sortLineKeys = (a, b, order) => {
    const aLines = a.split("|");
    const bLines = b.split("|");
    if (order === "desc") {
      if (a === "-") {
        return 1;
      }
      if (b === "-") {
        return -1;
      }
      if (aLines.length === bLines.length) {
        return 0;
      }
      return aLines.length < bLines.length ? 1 : -1;
    }
    if (a === "-") {
      return -1;
    }
    if (b === "-") {
      return 1;
    }
    if (aLines.length === bLines.length) {
      return 0;
    }
    return aLines.length > bLines.length ? 1 : -1;
  };

  updateHeight = () => {
    this.setState({ height: $(window).height() });
  };

  setDMMOrdering = (order, view) => {
    let obj = {};
    if (!this.state.dmmOrdering || !this.state.dmmOrdering[this.props.source]) {
      obj[this.props.source] = {};
    } else {
      obj = { ...this.state.dmmOrdering };
    }
    obj[this.props.source][view] = order;
    this.setState({ dmmOrdering: obj });
  };

  getOrder = (data, initialOrdering, view) => {
    if (
      this.state.dmmOrdering &&
      this.state.dmmOrdering[this.props.source] &&
      this.state.dmmOrdering[this.props.source][view]
    ) {
      return [
        ...new Set([
          ...this.state.dmmOrdering[this.props.source][view],
          ...Object.keys(data[0])
        ])
      ];
    }
    let order = [];
    if (!data[0]) {
      return order;
    }
    order = [...new Set([...initialOrdering, ...Object.keys(data[0])])];
    updateFirebase(22, order, `${this.props.source}/${view}`);
    this.setDMMOrdering(order, view);
    return order;
  };

  updateLines = () => {
    const invoiceLinesDefault = Object.values(this.props.invoiceLinesDefault);
    const invoiceLines = JSON.parse(
      JSON.stringify(calculateSpendRateDelta(invoiceLinesDefault))
    );
    const accumInvoiceLinesDefault = Object.values(
      this.props.accumInvoiceLinesDefault
    );
    const accumInvoiceLines = JSON.parse(
      JSON.stringify(calculateSpendRateDelta(accumInvoiceLinesDefault))
    );

    const { accumToLines } = this.props;
    const order = this.getOrder(
      invoiceLines,
      allLinesColsOrder,
      this.state.view
    );
    this.setState({
      invoiceLinesDefault,
      invoiceLines,
      accumInvoiceLinesDefault,
      accumInvoiceLines,
      accumToLines,
      order
    });
  };

  UNSAFE_componentWillMount() {
    this.updateLines();
  }

  componentDidMount() {
    this.updateLines();
    window.addEventListener("resize", this.updateHeight.bind(this));
    this.props.dispatch(getSuggestedMatches(this.props.suggestMatches));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateHeight.bind(this));
  }

  handleClick = (event, key) => {
    event.preventDefault();
    this.setState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      clicked: key
    });
  };

  handleClose = () => {
    this.setState({
      mouseX: null,
      mouseY: null,
      clicked: null
    });
  };

  handleHide = key => {
    this.setState({
      mouseX: null,
      mouseY: null,
      clicked: null,
      hiddenColumns: [...this.state.hiddenColumns, key]
    });
  };

  filterDuplicates = (tableData, columns) => {
    columns.forEach(columnKey => {
      Object.values(tableData).forEach(row => {
        const values = row[columnKey].split("|");
        if (values.every(value => value === values[0])) {
          row[columnKey] = values[0];
        }
      });
    });
    return tableData;
  };

  saveMatchedLines = () => {
    const { invoice } = this.props;
    const { invoiceLines } = invoice;
    const invoiceLinesUpdate = {};
    const promotionsUpdate = {};
    let numInvoiceLinesUpdated = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const [ind, key] of Object.entries(Object.keys(invoiceLines))) {
      const lineKeys = this.props.invoiceLines[ind]["Matched Line Key"];
      const splitLineKeys = lineKeys.split("|");

      // filter the multiple potential line keys from dropdown rows to the one the user actually selected
      const keysSelectedByDropdown =
        splitLineKeys.length > 1 &&
        Object.keys(this.state.multipleSuggestions).includes(ind)
          ? splitLineKeys.filter(
              k => k === this.state.multipleSuggestions[ind].selectedKey
            )
          : splitLineKeys;

      // check if the matched or selected keys are "-" which means no match
      // keys selected by the dropdown could be len 0 if the user selected "-"
      if (
        !lineKeys ||
        lineKeys === "-" ||
        keysSelectedByDropdown.length === 0
      ) {
        invoiceLinesUpdate[`invoiceLines/${key}/matchedProm`] = null;
        invoiceLinesUpdate[`invoiceLines/${key}/matchedPromLine`] = null;
        invoiceLinesUpdate[`invoiceLines/${key}/status`] =
          invoiceLineStatuses.MATCH_NOT_FOUND;
      } else if (keysSelectedByDropdown.length > 1) {
        // if there are more than 1 keys still possible, tell the user to fix
        this.props.enqueueSnackbar(
          "Please resolve ambiguous matches before saving",
          {
            variant: "default",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center"
            }
          }
        );
        return;
      } else if (
        (!Object.keys(this.state.checkboxOverrides).includes(String(ind)) &&
          this.props.suggestMatches) ||
        this.state.checkboxOverrides[String(ind)]
      ) {
        // case 1: checkbox not overriden
        // case 2: checkbox overriden
        // a single key has been matched, but only save it if the checkbox is checked

        const promotionRoot = lineKeys.split("-").slice(0, 2).join("-");
        const path = `promotionInvoices/${promotionRoot}/lines/${lineKeys}/matchedInvoiceLines`;
        if (path in promotionsUpdate) {
          promotionsUpdate[path][key] = key;
        } else {
          promotionsUpdate[path] = { [key]: key };
        }
        invoiceLinesUpdate[`invoiceLines/${key}/matchedProm`] = promotionRoot;
        invoiceLinesUpdate[`invoiceLines/${key}/matchedPromLine`] =
          keysSelectedByDropdown[0];
        invoiceLinesUpdate[`invoiceLines/${key}/status`] =
          invoiceLineStatuses.MATCH_CONFIRMED;
        numInvoiceLinesUpdated += 1;
      }
    }

    if (numInvoiceLinesUpdated != Object.keys(invoiceLines).length) {
      const invoiceUpdate = { status: invoiceStatuses.NEEDS_ATTENTION };
      updateFirebase(
        26,
        invoiceUpdate,
        this.props.invoiceKey,
        () => {},
        () => {}
      );
    }

    updateCurrentUserCompany("reconciliation/", promotionsUpdate);
    updateFirebase(
      26,
      invoiceLinesUpdate,
      this.props.invoiceKey,
      () => {
        this.props.db.drmEventService.addEvent({
          type: DRMEventType.INVOICE_LINE_PROM_LINE_AUTO_MATCH_ADDED,
          invoiceKey: this.props.invoiceKey,
          comment: `Invoice # ${this.props.invoiceKey} had ${numInvoiceLinesUpdated} lines matched with Auto Matching`
        });
        this.props.enqueueSnackbar("Saved matched lines successfully", {
          variant: "default",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center"
          }
        });
        this.props.dispatch(reset());
        this.props.openClose.closeAppModal();
      },
      () => {
        this.props.enqueueSnackbar(
          "Unknown error occurred saving matched lines, please contact support if this error persists",
          {
            variant: "default",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center"
            }
          }
        );
      }
    );
  };

  handleCheckboxClick = (event, row, selectedLineKeys) => {
    // get the current checkbox state of the row
    const newCheckboxObj = {};
    const currentCheckboxOverrides = this.state.checkboxOverrides;
    const currentCheckboxState =
      (selectedLineKeys.length == 1 &&
        selectedLineKeys[0] &&
        selectedLineKeys[0] != "-") ||
      (Object.keys(this.state.multipleSuggestions).includes(String(row)) &&
        this.state.multipleSuggestions[String(row)].selectedKey != "-");

    // change the checkbox to the opposite state it is now
    newCheckboxObj[row] = Object.keys(this.state.checkboxOverrides).includes(
      String(row)
    )
      ? !this.state.checkboxOverrides[row]
      : !currentCheckboxState;

    // set the checkbox state
    const newState = Object.assign(currentCheckboxOverrides, newCheckboxObj);
    this.setState({ checkboxOverrides: newState });
  };

  resultJSX = dialog => {
    // get data
    const confidenceThresholds =
      this.props.db.meta?.drm_deductions_sources
        ?.deductions_matching_thresholds || {};
    let tableData =
      this.state.view === "View Accumulated Lines"
        ? this.props.accumInvoiceLines
        : this.props.invoiceLines;
    tableData = this.filterDuplicates(tableData, [
      "Matched Promotion Customer"
    ]);
    tableData = confidenceFilter(
      tableData,
      this.state.confidenceLevel,
      confidenceThresholds,
      this.props.source
    );

    const readOnly = this.props.stepIndex === 1;

    // base renderer
    const baseRenderer = (row, val) => (
      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor:
            row.value === "-" && row.row["Matched Line Key"] === "-"
              ? "#e57373"
              : "#FFFFFF",
          borderRadius: "2px",
          padding: "7px 5px"
        }}>
        {val}
      </div>
    );

    // custom renderer
    const getRenderer = (isDialog = false) => ({
      "Matched Line Key": [
        row => this.lineKeyColumnFormatter(row, isDialog),
        this.sortLineKeys
      ],
      "Deductions Amount": [
        row => baseRenderer(row, row.value.toFixed(2)),
        undefined
      ],
      "Spend Rate": [row => baseRenderer(row, row.value.toFixed(2)), undefined]
    });

    let columns = [];
    let draggable = [];
    if (tableData[0]) {
      if (this.props.stepIndex === 1) {
        // Filter out all unmatched if it's the page before confirmation
        const isSelectedRow = rowNumber => {
          return (
            (!(rowNumber in this.state.checkboxOverrides) &&
              this.props.suggestMatches) ||
            (rowNumber in this.state.checkboxOverrides &&
              this.state.checkboxOverrides[rowNumber])
          );
        };
        tableData = tableData.filter(row => isSelectedRow(row.Row));
      }
      const checkCol = {
        Header: "Status",
        Cell: row => {
          const rowNumber = row.original.Row;
          const selectedLineKeys =
            this.props.invoiceLines[rowNumber]["Matched Line Key"].split("|");
          return (
            <Checkbox
              color="primary"
              checked={
                Object.keys(this.state.checkboxOverrides).includes(
                  String(rowNumber)
                )
                  ? this.state.checkboxOverrides[rowNumber]
                  : selectedLineKeys.length === 1 &&
                    selectedLineKeys[0] &&
                    selectedLineKeys[0] !== "-" &&
                    this.props.suggestMatches
              }
              onChange={e =>
                this.handleCheckboxClick(e, rowNumber, selectedLineKeys)
              }
              disabled={
                !(
                  (selectedLineKeys.length === 1 &&
                    selectedLineKeys[0] &&
                    selectedLineKeys[0] !== "-") ||
                  Object.keys(this.state.checkboxOverrides).includes(
                    String(rowNumber)
                  )
                )
              }
            />
          );
        }
      };
      let columnNames = allLinesColsOrder;
      if (tableData.length) columnNames = Object.keys(tableData[0]);
      const fieldCols = columnNames
        .filter(key => allLinesColsOrder.includes(key))
        .map(key => {
          const header = (
            <div onContextMenu={event => this.handleClick(event, key)}>
              <b>{key}</b>
              <Menu
                keepMounted
                open={this.state.mouseY !== null && this.state.clicked === key}
                onClose={this.handleClose.bind(this)}
                anchorPosition={
                  this.state.mouseY !== null &&
                  this.state.mouseX !== null &&
                  this.state.clicked === key
                    ? { top: this.state.mouseY, left: this.state.mouseX }
                    : undefined
                }
                anchorReference="anchorPosition"
                style={{ zIndex: "1500" }}
                elevation={1}>
                <MenuItem
                  onClick={() => this.handleHide(key)}
                  style={{ zIndex: "1500" }}>
                  Hide
                </MenuItem>
              </Menu>
            </div>
          );
          let width = 200;
          let show = true;
          let cellRender = row => baseRenderer(row, row.value);
          let sortMethod;
          if (Object.keys(widths).includes(key)) {
            width = widths[key];
          }
          if (this.state.hiddenColumns.includes(key)) {
            show = false;
          }
          const renderers = getRenderer(dialog);
          if (Object.keys(renderers).includes(key)) {
            [cellRender, sortMethod] = renderers[key];
          }

          return {
            Header: header,
            accessor: key,
            Cell: cellRender,
            sortMethod,
            width,
            show
          };
        });
      fieldCols.sort((a, b) =>
        this.state.order.indexOf(a.accessor) >
        this.state.order.indexOf(b.accessor)
          ? 1
          : -1
      );
      columns = readOnly ? fieldCols : [checkCol, ...fieldCols];
      draggable = columnNames.filter(key => allLinesColsOrder.includes(key));
    }

    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 5
          }}>
          <Select
            floatingLabelText="Match Confidence"
            value={this.state.confidenceLevel}
            onChange={event => {
              this.setState({ confidenceLevel: event.target.value });
              Mixpanel.track("Change Match Confidence", {
                "Confidence Level": event.target.value
              });
            }}
            MenuProps={{
              style: { zIndex: 2000 }
            }}
            style={{
              margin: 0,
              width: "auto",
              minWidth: "145px"
            }}>
            <MenuItem value={Confidence.ANY}>Any</MenuItem>
            <MenuItem value={Confidence.HIGH}>High</MenuItem>
            <MenuItem value={Confidence.LOW}>Low</MenuItem>
            <MenuItem value={Confidence.UNMATCHED}>Unmatched</MenuItem>
            <MenuItem value={Confidence.AMBIGUOUS}>Ambiguous</MenuItem>
          </Select>
          {this.createCustomButtonGroup(dialog)}
        </div>
        <style>{`.ReactTable .rt-tbody {
          overflow: overlay !important;
        }`}</style>
        <div className="centering">
          {this.props.fileTooLarge && (
            <font color="red">{`Only displaying the first ${this.props.fileLineLimit.toLocaleString()} rows in read-only format.`}</font>
          )}
        </div>
        <ReactTableDraggableColumns
          draggableColumns={{
            mode: "reorder",
            draggable,
            onDropSuccess: (
              _draggedColumn,
              _targetColumn,
              oldIndex,
              newIndex,
              _oldOffset,
              _newOffset
            ) => {
              const arr = this.state.order.slice();
              const adjustedNewIndex = newIndex - 1;
              const adjustedOldIndex = oldIndex - 1;
              const newArr = moveElementWithoutDeletion(
                arr,
                adjustedOldIndex,
                adjustedNewIndex
              );
              this.setState({
                order: newArr
              });
              this.setDMMOrdering(newArr, this.state.view);
              updateFirebase(
                22,
                newArr,
                `${this.props.source}/${this.state.view}`
              );
            }
          }}
          minRows={0}
          defaultPageSize={100}
          data={Object.values(tableData)}
          columns={columns}
          style={{ maxHeight: "50vh" }}
        />
        {!readOnly && (
          <Checkbox
            color="primary"
            label="Use suggested matches"
            checked={this.props.suggestMatches}
            onChange={event => {
              const isInputChecked = event.target.checked;
              this.props.dispatch(
                updateField("suggestMatches", isInputChecked)
              );
              this.props.dispatch(getSuggestedMatches(isInputChecked));
              Mixpanel.track("Toggle Use Selected Matches");
            }}
            disabled={this.props.fileTooLarge}
          />
        )}
      </div>
    );
  };

  render() {
    return (
      <div
        style={{
          width: "100%",
          margin: "auto",
          paddingLeft: "25px",
          paddingRight: "25px"
        }}>
        {this.resultJSX(false)}
        <StyledDialog
          fullScreen
          open={this.state.modalOpen}
          onClose={() => this.setState({ modalOpen: false })}
          TransitionComponent={Transition}>
          <div
            style={{
              maxHeight: 2000,
              flexDirection: "column"
            }}>
            <StyledAppBar>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => this.setState({ modalOpen: false })}
                  aria-label="close"
                  size="large">
                  <CloseIcon />
                </IconButton>
                <StyledTypography variant="h5">
                  Invoice Matching Results
                </StyledTypography>
              </Toolbar>
            </StyledAppBar>
            {this.resultJSX(true)}
          </div>
        </StyledDialog>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  const matchingFields = state.invoiceLinesMatching;
  return {
    source: matchingFields.source,
    invoiceLinesDefault: matchingFields.invoiceLinesDefault,
    invoiceLines: matchingFields.invoiceLines,
    accumInvoiceLinesDefault: matchingFields.accumInvoiceLinesDefault,
    accumInvoiceLines: matchingFields.accumInvoiceLines,
    suggestMatches: matchingFields.suggestMatches,
    fileLineLimit: matchingFields.fileLineLimit,
    fileTooLarge: matchingFields.fileTooLarge,
    uploadPath: matchingFields.uploadPath,
    stepIndex: matchingFields.stepIndex,
    saveMatches: matchingFields.saveMatches
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withSnackbar(MatchingResult));
