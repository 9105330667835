import { sum } from "lodash-es";
import { getTotals, getItemsByCustomer, months } from "helpers/DataProcessing";

function round(n) {
  return Math.round(n * 100) / 100;
}

function addDateData(data1, data2, dateColumns) {
  const sumData = {};
  for (let i = 0; i < dateColumns.length; i++) {
    sumData[dateColumns[i]] =
      (data1[dateColumns[i]] || 0) + (data2[dateColumns[i]] || 0);
  }
  return sumData;
}

function getEmptyDateData(data, includeOther, dateColumns) {
  const empty = {};
  for (var i = 0; i < dateColumns.length; i++) empty[dateColumns[i]] = 0;
  const emptyData = {};
  const keyList = Object.keys(data).sort();
  for (var i = 0; i < keyList.length; i++) {
    emptyData[keyList[i]] = { ...empty };
  }
  if (includeOther) emptyData.Other = { ...empty };

  return emptyData;
}

function binarySlice(arr, startDate, endDate) {
  /*
  Binary search. Return slice of array between startDate and endDate. 
  */

  let startIndex;
  let endIndex;

  // find start index
  var minIndex = 0;
  var maxIndex = arr.length - 1;
  var currentIndex;
  var currentDate;
  var date = new Date(startDate);
  while (minIndex < maxIndex) {
    currentIndex = parseInt((minIndex + maxIndex) / 2);
    currentDate = new Date(arr[currentIndex].date);
    if (minIndex == currentIndex) {
      break;
    }
    if (currentDate < date) {
      minIndex = currentIndex + 1;
    } else if (currentDate >= date) {
      maxIndex = currentIndex;
    }
  }
  startIndex = minIndex;

  // find end index
  var minIndex = 0;
  var maxIndex = arr.length - 1;
  var currentIndex;
  var currentDate;
  var date = new Date(endDate);
  while (minIndex < maxIndex) {
    currentIndex = parseInt((minIndex + maxIndex) / 2);
    currentDate = new Date(arr[currentIndex].date);
    if (minIndex == currentIndex) {
      break;
    }
    if (currentDate > date) {
      maxIndex = currentIndex - 1;
    } else if (currentDate <= date) {
      minIndex = currentIndex;
    }
  }
  endIndex = maxIndex;
  return arr.slice(startIndex, endIndex + 1);
}

function convertDataForTableFinance(
  allRevData,
  allSpendData,
  pivot,
  allPivots,
  groupBy
) {
  // date entries we use here
  const dateColumns = months.concat(["Q1", "Q2", "Q3", "Q4", "Total"]);

  function getEntry(key, data, metric, hundredth) {
    const entry = {};
    entry[pivot] = allPivots?.[key]?.name ?? key;
    if (allPivots[key]) {
      entry[`${pivot}Key`] = key;
    }

    for (let i = 0; i < dateColumns.length; i++) {
      if (!(key in data)) {
        entry[dateColumns[i]] = 0;
      } else {
        entry[dateColumns[i]] = hundredth
          ? round(data[key][dateColumns[i]])
          : Math.round(data[key][dateColumns[i]]);
      }
    }

    entry.metric = metric;

    return entry;
  }

  // For example, out of all customers, get an obj of all the unique pnlGroups
  const pivotGroups = {};
  if (groupBy) {
    for (var key in allPivots) {
      const p = allPivots[key];
      if (p[groupBy]) {
        pivotGroups[p[groupBy]] = true;
      }
    }
  }
  const emptyData = getEmptyDateData(pivotGroups, true, dateColumns);

  function filterData(data) {
    // add data into empty data obj, if the key already exists in the obj (otherwise put into 'Other' row)
    const newData = { ...emptyData };
    for (const key in data) {
      const group = allPivots[key][groupBy];
      if (group && group in newData) {
        newData[group] = addDateData(newData[group], data[key], dateColumns);
      } else {
        newData.Other = addDateData(newData.Other, data[key], dateColumns);
      }
    }
    return newData;
  }

  const revData = groupBy ? filterData(allRevData) : allRevData;
  const spendData = groupBy ? filterData(allSpendData) : allSpendData;

  const trData = {};
  for (var key in revData) {
    trData[key] = {};
    if (!(key in spendData)) spendData[key] = {};
    for (const time in revData[key]) {
      // if same customer does not exist in spendData, set all its entries to 0
      if (!spendData[key][time]) spendData[key][time] = 0;
      trData[key][time] = revData[key][time]
        ? round((spendData[key][time] / revData[key][time]) * 100)
        : 0;
    }
  }

  const revEntries = [];
  const spendEntries = [];
  const trEntries = [];

  // Put data in format requried for SalesTable
  for (var key in revData) {
    revEntries.push(getEntry(key, revData, "Revenue", false));
    spendEntries.push(getEntry(key, spendData, "Spend", false));
    trEntries.push(getEntry(key, trData, "Trade Rate (%)", true));
  }

  const entriesObj = {
    revenue: revEntries,
    spend: spendEntries,
    tradeRate: trEntries
  };

  return entriesObj;
}

function filterLines(linesList, distributorsSet, meta) {
  // filter lines that we use in the distributor spend pass through calculation
  const typePassThrough = meta.passThroughTypes || [];
  const filteredLinesList = [];
  for (let i = 0; i < linesList.length; i++) {
    const line = linesList[i];
    if (!typePassThrough.includes(line.type)) {
      // only include promotion types we have selected
      continue;
    }
    if (!distributorsSet.has(line.customer)) {
      // only include distributor promotions
      continue;
    }
    filteredLinesList.push(line);
  }
  return filteredLinesList;
}

function tradeRateTable(
  revItemsList,
  spendItemsList,
  linesList,
  groupBy,
  pnlGroup,
  allCustomers,
  meta
) {
  // using calculation methods specified in Settings
  const USE_SALES_BASED_TR = meta.retail_spend_calc == "PASS_THROUGH";
  const productGroups = meta.product_groups;
  // divide allCustomers into sub-objects of distributors and retailers
  const customers = {};
  var distributors = {};
  for (var key in allCustomers) {
    var customer = allCustomers[key];
    if (pnlGroup && customer.pnlGroup != pnlGroup) continue;
    if (customer.isDistributor) {
      distributors[key] = customer;
    }
    customers[key] = customer;
  }
  // time periods we are concerned with
  const dateColumns = months.concat(["Q1", "Q2", "Q3", "Q4", "Total"]);

  // get data with empty month columns: i.e. objects with keys equal to customer/distributor keys
  const emptyDistributorData = getEmptyDateData(
    distributors,
    false,
    dateColumns
  );
  const emptyCustomerData = getEmptyDateData(customers, false, dateColumns);

  // prelim objects contain both customers and distributors (we will filter down below)
  const revDataPrelim = getItemsByCustomer("revenue", revItemsList);
  const spendDataPrelim = getItemsByCustomer("spend", spendItemsList);

  let entriesObj = {};
  if (["all", "distributor"].includes(groupBy)) {
    var revData = {};
    var spendData = {};
    let relevantCustomers = allCustomers;
    if (groupBy == "all") {
      // show customers & distributors together
      revData = { ...emptyCustomerData };
      spendData = { ...emptyCustomerData };
    } else if (groupBy == "distributor") {
      // Pivoting by distributor
      // initialize data objects to all 0's in all months
      revData = { ...emptyDistributorData };
      spendData = { ...emptyDistributorData };
      relevantCustomers = distributors;
    }

    for (var key in revDataPrelim) {
      if (key in revData)
        revData[key] = addDateData(
          revData[key],
          revDataPrelim[key],
          dateColumns
        );
    }
    for (var key in spendDataPrelim) {
      if (key in spendData)
        spendData[key] = addDateData(
          spendData[key],
          spendDataPrelim[key],
          dateColumns
        );
    }

    entriesObj = convertDataForTableFinance(
      revData,
      spendData,
      "customer",
      relevantCustomers,
      "customer"
    );
  } else {
    // Pivoting by retailer
    var revData = { ...emptyCustomerData };
    var spendData = { ...emptyCustomerData };
    // Filtered lines we use in spend calculation
    let distributorSet = [];
    for (var customer of Object.values(allCustomers)) {
      var distributors = customer.distributors || [];
      distributorSet.push(...distributors);
    }
    distributorSet = new Set(distributorSet);
    const filteredLinesList = filterLines(linesList, distributorSet, meta);
    // Revenue data that we use in spend calculation, split by customer
    const revItemsByCustomer = {};
    for (var i = 0; i < revItemsList.length; i++) {
      var { customer } = revItemsList[i];
      if (!(customer in revItemsByCustomer)) {
        revItemsByCustomer[customer] = [revItemsList[i]];
      } else {
        revItemsByCustomer[customer].push(revItemsList[i]);
      }
    }
    // sort items from least to greatest
    for (var customer in revItemsByCustomer) {
      revItemsByCustomer[customer].reverse();
    }
    // revenue data aggregation
    for (var key in allCustomers) {
      if (key in revData) {
        // if revenue entry belongs to customer, not distributor
        if (key in revDataPrelim) {
          revData[key] = addDateData(
            revData[key],
            revDataPrelim[key],
            dateColumns
          );
        }
      }
    }
    // spend data aggregation
    for (var key in allCustomers) {
      if (key in spendData) {
        // if spend entry belongs to customer, not distributor
        if (key in spendDataPrelim) {
          spendData[key] = addDateData(
            spendData[key],
            spendDataPrelim[key],
            dateColumns
          );
        }
        if (USE_SALES_BASED_TR) {
          // create additional spend object with just one entry, for this particular customer
          const additionalSpend = {};
          additionalSpend[key] = {};
          for (var i = 0; i < 12; i++) {
            additionalSpend[key][months[i]] = 0;
          }

          const customerX = key;
          // extract all lines whose customer is one of customerX's distributors
          var distributorsX = allCustomers[customerX].distributors || [];
          const linesX = filteredLinesList.filter(x =>
            distributorsX.includes(x.customer)
          );
          if (linesX.length == 0) continue;
          const revItemsSublist = revItemsByCustomer[customerX] || [];
          for (var i = 0; i < linesX.length; i++) {
            // look at instore performance dates of each line
            const line = linesX[i];
            const startDateX = new Date(line.year, line.month - 1, 1);
            const endDateX = new Date(line.year, line.month, 0);
            var productX = new Set(
              line.productGroup != "custom"
                ? productGroups[line.productGroup].products
                : line.product
            );
            // aggregate corresponding revenue within promotion month
            const revenueX = binarySlice(
              revItemsSublist,
              startDateX,
              endDateX
            ).filter(x => productX.has(x.product));
            // sum sales and take into account multiple distributors
            const totalLineSales = sum(revenueX.map(x => x.actSales || 0));
            const spendFromLine = totalLineSales * (line.spendRate || 0);
            let multiplier = 1.0 / distributorsX.length; // compute fractional multipler in case of multiple distributors
            if (allCustomers[customerX].spendPerDistributor) {
              multiplier =
                (allCustomers[customerX].spendPerDistributor[line.customer] ||
                  0) / 100;
            }
            additionalSpend[key][months[line.month - 1]] +=
              spendFromLine * multiplier;
          }
          spendData[key] = addDateData(
            spendData[key],
            getTotals(additionalSpend)[key],
            dateColumns
          );
        }
      }
    }

    entriesObj = convertDataForTableFinance(
      revData,
      spendData,
      "customer",
      allCustomers,
      groupBy
    );
  }

  return entriesObj;
}

export { tradeRateTable };
