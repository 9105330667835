import React from "react";

import Button from "ui-library/Button";
import CloseIcon from "@mui/icons-material/Close";

import { grey, common, red } from "@mui/material/colors";

const grey300 = grey["300"];
const grey700 = grey["700"];
const { white } = common;
const { black } = common;
const red400 = red["400"];
const red600 = red["600"];

class CloseButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Button
        label="Close"
        onClick={() => {
          this.props.openClose.closeAppModal();
          if (this.props.onClose) this.props.onClose();
        }}
        icon={<CloseIcon />}
        style={{ color: red400 }}
      />
    );
  }
}

export default CloseButton;
