/* eslint-disable no-nested-ternary */
import { Divider } from "pui-react-dividers";
import Typography from "@mui/material/Typography";
import React from "react";

import {
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Tabs,
  Tab,
  Stack,
  Box
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { TabPanel, a11yProps } from "ui-library/TabPanel";
import IconButton from "ui-library/IconButton";
import Button from "ui-library/Button";
import Select from "ui-library/Select";
import TextField from "ui-library/TextField";
import MenuItem from "ui-library/MenuItem";
import { PromotionSelect } from "ui-library/promotionTag";
import Card from "ui-library/Card";

import TimelineIcon from "@mui/icons-material/Timeline";
import CircularProgress from "@mui/material/CircularProgress";
import EditIcon from "@mui/icons-material/Edit";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ReorderIcon from "@mui/icons-material/Reorder";
import PublicIcon from "@mui/icons-material/Public";
import SaveIcon from "@mui/icons-material/Save";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";

import {
  actVsExpBarProm,
  actVsExpTimeSeriesProm,
  calculateLinesROI,
  displayMoney,
  underPromoApprovalLimit
} from "helpers/DataProcessing";
import {
  getCompanyUsers,
  getEnhancedPromsFirebase,
  getJSXListsFirebase,
  sendNotif,
  updateFirebase,
  updateStatusFirebase,
  isUserCresicorEmployee,
  getFirebaseConsoleURL,
  firebase
} from "helpers/Firebase";
import { getTimesJSX } from "helpers/Time";
import { objToArray } from "helpers/sortByDate";
import Mixpanel from "helpers/Mixpanel";
import ModalContent from "components/WebsiteElements/ModalContent";
import { green, common, red, grey, orange } from "@mui/material/colors";
import { CONTENT_OVERFLOW_OFFSET } from "components/appConstants";
import Link from "ui-library/Link";
import ActVsExpPast from "../graphs/ActVsExpPast";
import AddPromotion from "../AddPromotion/AddPromotion";
import BiggestPromsGraph from "../graphs/BiggestPromsGraph";
import DataTable from "../tables/MuiDataTable";
import LineList from "./LineList";
import LineProfile from "./LineProfile";
import ManagePromFiles from "./ManagePromFiles";
import StepButtons from "../AddPromotion/StepButtons";

const red400 = red["400"];
const red500 = red["500"];
const grey500 = grey["500"];
const grey700 = grey["700"];
const { black } = common;
const green100 = green["100"];
const orange400 = orange["400"];
// const green700 = green["700"];
// const green400 = green["400"];
// const deepOrange400 = deepOrange["400"];

const styles = {
  superheader: {
    fontFamily: "Ubuntu",
    marginTop: 20
  },
  header: {
    marginRight: "-50px",
    fontSize: 20,
    color: black
  },
  subheader: {
    fontSize: 20
  },
  divider: {
    margin: "30px auto",
    width: "50%"
  },
  paper: {
    padding: 16,
    marginBottom: 5,
    "white-space": "pre-line"
  }
};

function round(n) {
  return Math.round(n * 100) / 100;
}

export default class PromProfile extends React.Component {
  constructor(props) {
    super(props);
    this.promRef = React.createRef();
    this.state = {
      prom: {},
      promKey: "",
      promChanges: false,
      tab: 0,
      products: {},
      customers: {},
      editComments: false,
      chosenAnalyticsLineKey: 0,
      chosenLineKey: "",
      addedFiles: {},
      hasDeductions: false,
      selectStatuses: [],
      proposed: false
    };
  }

  getPromData = promKey => {
    const criteria = ["filteredStatuses", "types"];
    const user = firebase.auth().currentUser;
    // Get the user's access level
    if (user) {
      getEnhancedPromsFirebase(
        enhancedProm => {
          getCompanyUsers(companyUsers => {
            const { uid } = user;
            let currentProm;
            if (enhancedProm) currentProm = enhancedProm;
            else if (this.props.backupProm) currentProm = this.props.backupProm;
            if (currentProm) {
              const underLimit = underPromoApprovalLimit(
                companyUsers[uid],
                uid,
                this.props.db.meta.promo_limits,
                currentProm.totalExpSpend
              );

              if (!underLimit) {
                criteria.push("filteredStatusesOverLimit");
              }
            }

            getJSXListsFirebase(
              this.props.db,
              allJSX => {
                const selectStatuses = allJSX.filteredStatusesJSX;
                if (enhancedProm) {
                  this.setState({
                    chosenLineKey:
                      this.state.chosenLineKey in enhancedProm.lines
                        ? this.state.chosenLineKey
                        : Object.keys(enhancedProm.lines)[0],
                    prom: enhancedProm,
                    promKey,
                    proposed: false
                  });
                  // if default line given
                  if (this.props.defaultLine) {
                    this.setState({
                      tab: 1,
                      chosenLineKey: this.props.defaultLine,
                      chosenAnalyticsLineKey: this.props.defaultLine,
                      promKey
                    });
                  }
                  const hasDeductions =
                    enhancedProm.actMoneyList &&
                    enhancedProm.actMoneyList.length > 0;

                  selectStatuses.pop();
                  selectStatuses.push(
                    <MenuItem
                      value={7}
                      children="Cancelled"
                      secondary={
                        <small
                          style={{
                            color: "#282626",
                            fontWeight: 300,
                            marginLeft: 5
                          }}>
                          Cancelled
                        </small>
                      }
                      disabled={
                        hasDeductions ||
                        !(
                          this.props.db.permissions.includes("cancel") ||
                          this.props.db.permissions.includes("admin")
                        )
                      }
                      key={7}
                    />
                  );

                  this.setState({
                    selectStatuses,
                    hasDeductions
                  });
                } else if (this.props.backupProm) {
                  // in the case that the promotion has not yet been added, but still want to view details
                  this.setState({
                    chosenLineKey:
                      this.state.chosenLineKey in this.props.backupProm.lines
                        ? this.state.chosenLineKey
                        : Object.keys(this.props.backupProm.lines)[0],
                    prom: this.props.backupProm,
                    promKey,
                    proposed: true, // signified this promotion has not yet been added
                    hasDeductions: false
                  });
                }
              },
              criteria,
              true
            );
          });
        },
        promKey,
        true
      );
    }
  };

  handleTextChange = (field, event) => {
    this.updateField(field, event.target.value);
  };

  handleNumTextChange = (field, event) => {
    this.updateField(field, parseFloat(event.target.value));
  };

  // Trick is to pass in component id as an extra argument
  handleSelectChange = (field, event, index, value) => {
    this.updateField(field, value);
  };

  handleDateChange = (field, event, value) => {
    this.updateField(field, value.toDateString());
  };

  updateField = (field, value) => {
    const newProm = this.state.prom;
    // if non-comments were added
    if (field != "newComment") {
      this.setState({
        nonCommentsAdded: true
      });
    }
    const spl = field.split(".");
    if (spl.length == 1) {
      newProm[spl[0]] = value;
    } else if (spl.length == 2) {
      newProm[spl[0]][spl[1]] = value;
    } else if (spl.length == 3) {
      newProm[spl[0]][spl[1]][spl[2]] = value;
    }
    if (!this.state.promChanges) {
      this.props.openClose.setDrawerClose(() => {
        this.setState({
          promptSave: true,
          tab: 0,
          chosenAnalyticsLineKey: 0
        });
      });
    }
    this.setState({
      prom: newProm,
      promChanges: true
    });
  };

  handleUpdateStatus = value => {
    const { uid } = firebase.auth().currentUser;
    const companyUser = this.props.db.companyUsers[uid];
    const promoLimitsDict = this.props.db.meta.promo_limits;
    const underLimit =
      value != "Approved" ||
      underPromoApprovalLimit(
        companyUser,
        uid,
        promoLimitsDict,
        this.state.prom.totalExpSpend
      );

    if (underLimit) {
      // 'value' argument is the string form of a status
      const newStatus = this.props.db.meta.statuses.indexOf(value);
      if (newStatus != -1) {
        const newProm = this.state.prom;
        updateStatusFirebase(this.props.promKey, this.state.prom, value);

        newProm.status = newStatus;
        this.setState({
          prom: newProm
        });
      }
    }
  };

  handleTagChange = value => {
    updateFirebase(0, { tags: value }, this.props.promKey);
  };

  prettyPrint = itemArray => {
    let items = "";
    if (itemArray) {
      for (let i = 0; i < itemArray.length; i++) {
        if (i != 0) {
          items += ", ";
        }
        items += itemArray[i];
      }
    }
    return items;
  };

  getStaticFields = () => {
    const staticFields = {
      Field: [
        "Promotion ID",
        "Customer",
        "First Receiver",
        "Contact(s)",
        "Total Expected Spend",
        "Previous Total Spend Estimate",
        "Spend Rate",
        "Created By",
        "Last Modified"
      ],
      Value: []
    };
    staticFields.Value.push(
      <div className="rowC">
        {this.props.promKey}
        <IconButton
          tooltip="Copy ID"
          style={{ marginTop: -15, marginLeft: 10 }}
          onClick={() => {
            navigator.clipboard.writeText(this.props.promKey);
          }}
          size="large">
          <FileCopyIcon />
        </IconButton>
      </div>
    );
    staticFields.Value.push(this.state.prom.customerName);
    staticFields.Value.push(
      this.state.prom.distributor &&
        this.state.prom.distributor.constructor === Array
        ? this.prettyPrint(this.state.prom.distributor)
        : this.state.prom.distributor
    );
    staticFields.Value.push(
      this.props.db.contacts && this.state.prom.contact
        ? this.prettyPrint(
            this.state.prom.contact
              .filter(c => c in this.props.db.contacts)
              .map(c => {
                return this.props.db.contacts[c].name;
              })
          )
        : ""
    );
    staticFields.Value.push(
      displayMoney(Math.round(this.state.prom.totalExpSpend))
    );
    staticFields.Value.push(
      displayMoney(this.state.prom.prevTotalExpSpend || 0)
    );
    staticFields.Value.push(this.state.prom.spendRate);

    if (this.state.prom.created) {
      staticFields.Value.push(
        `${this.state.prom.created.user} on ${this.state.prom.created.date}, ${this.state.prom.created.time}`
      );
    }
    if (this.state.prom.modified) {
      staticFields.Value.push(
        `${this.state.prom.modified.user} on ${this.state.prom.modified.date}, ${this.state.prom.modified.time}`
      );
    }
    return staticFields;
  };

  handleSaveChanges = () => {
    const { prom } = this.state;
    var user = firebase.auth().currentUser;
    // Set new modified info
    prom.modified = {
      user: this.state.userName,
      uid: this.state.uid,
      date: new Date().toDateString(),
      time: new Date().toLocaleTimeString()
    };
    // Recompute total expected spent
    if (prom.totalExpSales && prom.spendRate) {
      prom.totalExpSpend = prom.totalExpSales * prom.spendRate;
    }
    // Set startDate and endDate values if month and year fields are filled
    if (prom.instoreStartDate) {
      prom.startDate = prom.instoreStartDate;
      prom.endDate = prom.instoreEndDate;
    } else {
      prom.startDate = new Date(
        parseInt(prom.year),
        prom.month - 1,
        1
      ).toDateString();
      prom.endDate = new Date(
        parseInt(prom.year),
        prom.month - 1,
        0
      ).toDateString();
    }
    // Update comments if there is a new comment
    let commentsAdded = false;
    if (prom.newComment) {
      if (!prom.commentList) {
        prom.commentList = [];
      }
      var user = firebase.auth().currentUser;
      prom.commentList.push({
        text: prom.newComment,
        user: this.state.userName,
        uid: this.state.uid,
        date: new Date().toDateString()
      });
      commentsAdded = true;
      prom.comments = prom.newComment; // comments field always contains most recent comment
      delete prom.newComment;
    }

    updateFirebase(0, prom, this.props.promKey, promKey => {
      const status = this.props.db.meta.statuses[prom.status];
      // move prom back to 'Submitted' if reapproval needed
      if (
        ["Finalized", "Approved"].indexOf(status) != -1 &&
        this.props.db.permissions.includes("edit") &&
        !this.props.readOnly &&
        this.state.nonCommentsAdded
      ) {
        updateStatusFirebase(promKey, prom, "Submitted");
      }
      if (this.state.nonCommentsAdded) {
        sendNotif(`Promotion has been edited: ${prom.name}`, "editProm", null, {
          promKey
        });
      }
      if (commentsAdded) {
        sendNotif(
          `Comments have been added to promotion: ${prom.name}`,
          "commentsProm",
          null,
          { promKey }
        );
      }
    });

    this.setState({
      promChanges: false,
      editComments: false,
      promptSave: false,
      nonCommentsAdded: false
    });
    this.props.openClose.setDrawerClose(null);
  };

  handleDuplicate = () => {
    this.props.openClose.setAppModal(
      "Duplicate Existing Promotion",
      <AddPromotion
        db={this.props.db}
        openClose={this.props.openClose}
        defaultProm={this.state.prom}
        callback={this.props.callback}
        mode="duplicate"
      />,
      <StepButtons
        db={this.props.db}
        openClose={this.props.openClose}
        proposed={this.state.proposed}
        mode="duplicate"
        windowClosed={this.props.promCreationWindowClosed}
      />,
      true,
      true,
      "lg"
    );
    this.setState({
      promChanges: false,
      editComments: false,
      promptSave: false,
      nonCommentsAdded: false
    });
    this.props.openClose.setDrawerClose(null);
    this.onDrawerClose(false);
  };

  handleMasterEdit = () => {
    this.props.openClose.setAppModal(
      "Edit Existing Promotion",
      <AddPromotion
        db={this.props.db}
        openClose={this.props.openClose}
        defaultProm={this.state.prom}
        mode="edit"
        callback={this.props.callback}
        proposed={this.state.proposed}
      />,
      <StepButtons
        db={this.props.db}
        openClose={this.props.openClose}
        mode="edit"
        proposed={this.state.proposed}
        windowClosed={this.props.promCreationWindowClosed}
      />,
      true,
      true,
      "lg"
    );
    this.setState({
      promChanges: false,
      editComments: false,
      promptSave: false,
      nonCommentsAdded: false
    });
    this.props.openClose.setDrawerClose(null);
    this.onDrawerClose(false);
  };

  handleTabChange = (event, value) => {
    this.setState({
      tab: value
    });
  };

  getNextStatusButton = promStatus => {
    const { uid } = firebase.auth().currentUser;
    const companyUser = this.props.db.companyUsers[uid];
    const promoLimitsDict = this.props.db.meta.promo_limits;
    const underLimit = underPromoApprovalLimit(
      companyUser,
      uid,
      promoLimitsDict,
      this.state.prom.totalExpSpend
    );

    switch (promStatus) {
      case "Pending":
        return (
          <Button
            label="Submit"
            primary
            onClick={this.handleUpdateStatus.bind(null, "Submitted")}
            style={{ marginTop: 25, marginLeft: 16 }}
          />
        );
      case "Submitted":
        return (
          <Stack>
            <Button
              label="Approve"
              primary
              onClick={this.handleUpdateStatus.bind(null, "Approved")}
              style={{ marginTop: 25, marginLeft: 16 }}
              disabled={!underLimit}
            />
            <Button
              label="Decline"
              onClick={this.handleUpdateStatus.bind(null, "Declined")}
              style={{ marginTop: 25, marginLeft: 16, color: red500 }}
              disabled={!underLimit}
            />
          </Stack>
        );
        break;
    }
  };

  filterMoney = moneyList => {
    const filteredList = [];
    for (let i = 0; i < moneyList.length; i++) {
      const s = moneyList[i];
      if (
        !this.state.chosenAnalyticsLineKey ||
        s.lineKey == this.state.chosenAnalyticsLineKey
      ) {
        filteredList.push(s);
      }
    }

    return filteredList;
  };

  filterLines = lines => {
    const hasKey = line => line.key === this.state.chosenAnalyticsLineKey;
    if (!lines.some(hasKey)) {
      return lines;
    }
    return lines.filter(hasKey);
  };

  getMoneyTable = moneyList => {
    const moneyTable = {};
    moneyTable.Date = [];
    moneyTable.Sales = [];
    moneyTable.Spend = [];
    moneyTable["Check #"] = [];
    moneyTable.Customer = [];
    moneyTable.Line = [];
    // var promTableEditable = [];
    for (let i = 0; i < moneyList.length; i++) {
      const item = moneyList[i];
      const line = this.props.db.allLines[item.lineKey];
      if (
        !this.state.chosenAnalyticsLineKey ||
        item.lineKey == this.state.chosenAnalyticsLineKey
      ) {
        moneyTable.Date.push(item.date);
        moneyTable.Sales.push(Math.round(item.sales));
        moneyTable.Spend.push(displayMoney(round(item.spend)));
        moneyTable["Check #"].push(item.checkNumber || "N/A");
        moneyTable.Customer.push(this.props.db.customers[item.customer].name);
        moneyTable.Line.push(line.name);
      }
    }

    return moneyTable;
  };

  handleLineLiftChange = (lineKey, event) => {
    let { value } = event.target;
    const { prom } = this.state;
    if (value === "") {
      value = 0;
    } else if (isNaN(value)) {
      return;
    }
    prom.lines[lineKey].lift = parseInt(value) / 100;
    this.setState({ prom, promChanges: true });
  };

  closePromAndLines = () => {
    const newProm = { ...this.state.prom };
    newProm.closed = true;
    if (newProm.lines) {
      for (const lineKey in newProm.lines) {
        newProm.lines[lineKey].closed = true;
      }
    }
    updateFirebase(0, newProm, this.props.promKey, promKey => {});
  };

  getLeftIcon = promStatus => {
    const viewerError =
      "You do not have sufficient permissions to \
                       perform this action.";
    if (this.state.prom.closed) {
      return (
        <IconButton
          tooltip="Promotion Closed"
          style={{ marginTop: 0, marginLeft: -5 }}
          onClick={() => {
            if (
              this.state.access == "Viewer" ||
              !this.props.db.permissions.includes("close")
            ) {
              this.props.openClose.showSnack(viewerError);
            } else {
              updateFirebase(
                0,
                { closed: false },
                this.props.promKey,
                promKey => {}
              );
              Mixpanel.track("Lock", { Type: "Promotion", Action: "Open" });
            }
          }}
          size="large">
          <LockIcon sx={{ color: "warning.main" }} />
        </IconButton>
      );
    }
    return (
      <IconButton
        tooltip="Promotion Open"
        style={{ marginTop: 0, marginLeft: -5 }}
        onClick={() => {
          if (
            this.state.access == "Viewer" ||
            !this.props.db.permissions.includes("close")
          ) {
            this.props.openClose.showSnack(viewerError);
          } else if (["Completed", "Cancelled"].includes(promStatus)) {
            this.closePromAndLines();
            Mixpanel.track("Lock", { Type: "Promotion", Action: "Close" });
          } else {
            this.props.openClose.showSnack(
              "Only Completed or Cancelled promotions may be closed"
            );
          }
        }}
        size="large">
        <LockOpenIcon sx={{ color: "success.main" }} />
      </IconButton>
    );
  };

  onDrawerClose = () => {
    this.setState({ tab: 0, chosenAnalyticsLineKey: 0, promKey: "" });
    this.props.openClose.defaultDrawerChange(false);
    this.props.openClose.setDrawerClose(null);
  };

  updateLine = (lineKey, newLine) => {
    const newProm = this.state.prom;
    newProm.lines[lineKey] = newLine;
    this.setState({
      prom: newProm
    });
  };

  componentDidMount() {
    const user = firebase.auth().currentUser;
    if (user) {
      const { uid } = user;
      const storedUser = this.props.db.companyUsers[uid];
      this.setState({
        uid,
        access: storedUser.access,
        userName: storedUser ? storedUser.name : "Unnamed"
      });
    }
    this.getPromData(this.props.promKey);

    const timesJSX = getTimesJSX(this.props.db.meta, true);
    this.setState({
      monthsJSX: timesJSX[0],
      yearsJSX: timesJSX[1]
    });

    if (!this.props.fixedHeight) {
      const ppHeight =
        document.documentElement.clientHeight -
        $(this.promRef.current).offset().top +
        $(window).scrollTop() -
        10; // need this to get rid of tiny scrollbar :(
      this.setState({
        ppHeight
      });
    }
  }

  // This component is unique in that new queries to the database are needed even after the initial mount,
  // every time new props are received.
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.proposed || this.state.promKey != nextProps.promKey) {
      this.getPromData(nextProps.promKey);
    }

    if (!nextProps.fixedHeight) {
      if (this.promRef?.current) {
        const ppHeight =
          document.documentElement.clientHeight -
          $(this.promRef.current).offset().top +
          $(window).scrollTop() -
          10; // need this to get rid of tiny scrollbar :(
        this.setState({
          ppHeight
        });
      }
    }
  }

  componentWillUnmount() {
    if (this.props.history) {
      const path = window.location.pathname.split("/")[1];
      this.props.history.push(`/${path}`);
    }
  }

  render() {
    const staticFields = this.getStaticFields();
    const actList = this.state.prom.actMoneyList
      ? this.filterMoney(this.state.prom.actMoneyList)
      : [];
    const linesList = this.state.prom.lines
      ? this.filterLines(objToArray(this.state.prom.lines))
      : [];

    const obj =
      this.state.chosenAnalyticsLineKey &&
      this.state.chosenAnalyticsLineKey in this.state.prom.lines
        ? this.state.prom.lines[this.state.chosenAnalyticsLineKey]
        : this.state.prom;
    const salesTimeData = actVsExpTimeSeriesProm(
      "sales",
      actList,
      obj.totalExpSales
    );
    const spendTimeData = actVsExpTimeSeriesProm(
      "spend",
      actList,
      obj.totalExpSpend
    );
    const spendBarData = actVsExpBarProm(
      "spend",
      actList,
      "totalExpSpend",
      linesList,
      this.props.db.meta
    );
    const salesBarData = actVsExpBarProm(
      "sales",
      actList,
      "totalExpSales",
      linesList,
      this.props.db.meta
    );

    const actMoneyList = [];
    const spends = this.props.db.promotion_spend[this.state.prom.promKey];
    for (const spendKey in spends) {
      if (spends[spendKey]) {
        actMoneyList.push(this.props.db.actMoney[spendKey]);
      }
    }

    const roiData = calculateLinesROI(
      this.state.prom,
      actMoneyList,
      this.props.db.meta,
      this.props.db.pricing,
      true
    );

    // allow user to edit lift
    if (this.props.db.permissions.includes("edit")) {
      for (let i = 0; i < roiData["Breakdown by Line"].Line.length; i++) {
        const lineKey = roiData["Breakdown by Line"].Line[i];
        const lift = roiData["Breakdown by Line"]["Assigned Lift (%)"][i];
        roiData["Breakdown by Line"]["Assigned Lift (%)"][i] = (
          <TextField
            underlineStyle={{ display: "none" }}
            underlineShow={false}
            value={parseInt(lift)}
            onChange={this.handleLineLiftChange.bind(null, lineKey)}
            style={{
              height: "66%"
            }}
            inputStyle={{
              backgroundColor: green100
            }}
          />
        );
      }
    }

    let promStatus = this.props.db.meta
      ? this.props.db.meta.statuses[this.state.prom.status]
      : "";
    if (this.state.proposed) promStatus = "Unconfirmed";

    let statusColor = "success.main";
    if (this.state.proposed) {
      statusColor = "info.main";
    } else if (["Cancelled", "Declined"].includes(promStatus)) {
      statusColor = "error.main";
    }

    const promType = this.props.db.meta
      ? this.props.db.meta.fundTypes?.[this.state.prom.type]?.name
      : "";

    const notRunningOrCompleted =
      this.state.access == "Admin" ||
      (promStatus != "Running" && promStatus != "Completed");

    const firebaseConsoleURL = `${getFirebaseConsoleURL()}/companies/${
      this.props.db.companyid
    }/promotions/${this.state.prom.promKey}`;

    return (
      <div style={{ fontFamily: "Oxygen" }}>
        <ListItem
          sx={{ background: theme => theme.palette.readOnly.surface3 }}
          ContainerComponent="div">
          <ListItemIcon>{this.getLeftIcon(promStatus)}</ListItemIcon>
          <ListItemText
            primary={
              this.state.prom.name === "" ? "None" : this.state.prom.name
            }
            secondary={
              this.props.db.meta && (
                <>
                  {`${this.state.prom.promKey} | `}
                  <Box component="span" sx={{ color: statusColor }}>
                    {promStatus}
                  </Box>
                  <Box component="span" sx={{ mx: "2px" }}>
                    |
                  </Box>
                  {isUserCresicorEmployee() && (
                    <Link
                      component="a"
                      href={firebaseConsoleURL}
                      target="_blank"
                      sx={{ color: "primary.inverse" }}
                      rel="noreferrer">
                      View on Firebase Console
                    </Link>
                  )}
                </>
              )
            }
          />
          <ListItemSecondaryAction>
            {this.state.promChanges ? (
              <Button
                label="Save Changes"
                onClick={this.handleSaveChanges}
                icon={<SaveIcon />}
              />
            ) : (
              <Button label="Save Changes" disabled icon={<SaveIcon />} />
            )}
          </ListItemSecondaryAction>
        </ListItem>
        <Tabs
          indicatorColor="primary"
          value={this.state.tab}
          onChange={this.handleTabChange}
          sx={{
            background: theme => theme.palette.readOnly.surface3,
            "& .MuiTabs-indicator": {
              height: "4px"
            }
          }}
          textColor="primary"
          variant="fullWidth">
          <Tab label="General Info" icon={<PublicIcon />} {...a11yProps(0)} />
          <Tab label="Lines" icon={<ReorderIcon />} {...a11yProps(1)} />
          <Tab label="Analytics" icon={<TimelineIcon />} {...a11yProps(2)} />
          <Tab
            label="Attachments"
            icon={<InsertDriveFileIcon />}
            {...a11yProps(3)}
          />
        </Tabs>
        <TabPanel value={this.state.tab} index={0}>
          <ModalContent
            offset={CONTENT_OVERFLOW_OFFSET * 2}
            style={{
              paddingLeft: "16px",
              paddingRight: "16px",
              height: this.props.fixedHeight ?? this.state.ppHeight,
              overflow: "auto"
            }}
            innerRef={this.promRef}>
            {this.state.prom.name ? (
              !this.state.promptSave ? (
                <div>
                  <Typography style={styles.superheader} variant="h4">
                    Promotion Details
                  </Typography>
                  <Divider />
                  <Stack>
                    <Select
                      floatingLabelText="Promotion Status"
                      value={this.state.prom.status}
                      disabled={
                        !this.props.readOnly &&
                        (this.state.prom.duplicatedFrom ||
                          !(
                            this.props.db.permissions &&
                            this.props.db.permissions.includes("masteredit") &&
                            notRunningOrCompleted
                          ))
                      }
                      labelStyle={{ color: black }}
                      onChange={(event, index, value) => {
                        this.handleUpdateStatus(
                          this.props.db.meta.statuses[value]
                        );
                      }}
                      fullWidth>
                      {this.state.selectStatuses}
                    </Select>
                    {!this.props.readOnly &&
                      this.props.db.permissions.includes("approve") &&
                      this.getNextStatusButton(promStatus)}
                  </Stack>
                  <PromotionSelect
                    tags={this.props.db.meta.promotionTags}
                    value={this.state.prom.tags}
                    onChange={this.handleTagChange}
                    disabled={this.state.proposed}
                  />
                  {this.state.hasDeductions && (
                    <div>
                      This promotion cannot be cancelled because it has
                      deductions attached to it.
                    </div>
                  )}
                  <br />
                  <br />
                  <DataTable title="Static Fields" data={staticFields} />
                  <br />
                  <Typography sx={{ color: "primary.inverse" }}>
                    Remember to save any changes made below with 'Save Changes'
                  </Typography>
                  <TextField
                    value={this.state.prom.name || ""}
                    onChange={this.handleTextChange.bind(null, "name")}
                    floatingLabelText="Name"
                    fullWidth
                    disabled
                  />
                  <br />
                  <div className="rowC" key="monthyear">
                    <Select
                      floatingLabelText="Month"
                      value={this.state.prom.month}
                      onChange={this.handleSelectChange.bind(null, "month")}
                      fullWidth
                      disabled>
                      {this.state.monthsJSX}
                    </Select>
                    <Select
                      floatingLabelText="Year"
                      value={this.state.prom.year}
                      onChange={this.handleSelectChange.bind(null, "year")}
                      fullWidth
                      style={{ marginLeft: 16 }}
                      disabled>
                      {this.state.yearsJSX}
                    </Select>
                  </div>
                  <br />

                  <br />
                  <br />
                  <Divider style={styles.divider} size="large" />
                  <ListItemText
                    disableTypography
                    style={styles.subheader}
                    primary="Individual Lines"
                  />
                  <LineList
                    lines={this.state.prom.lines}
                    height={160}
                    db={this.props.db}
                    handleSelectRow={key => {
                      this.setState({
                        chosenLineKey: key,
                        tab: 1
                      });
                    }}
                  />
                  <br />
                  <Divider style={styles.divider} size="large" />
                  <div className="rowC">
                    <ListItemText
                      disableTypography
                      style={styles.subheader}
                      primary="Comments"
                    />
                    <Button
                      onClick={() => {
                        this.setState({ editComments: true });
                      }}
                      label={<EditIcon color={grey700} />}
                    />
                  </div>
                  <div>
                    {this.state.prom.commentList ? (
                      this.state.prom.commentList.map((comment, index) => {
                        return (
                          <Paper key={index} style={styles.paper}>
                            <div>{comment.text}</div>
                            <div style={{ textAlign: "right", color: grey500 }}>
                              {`${comment.user}, ${comment.date}`}
                            </div>
                          </Paper>
                        );
                      })
                    ) : (
                      <div>{this.state.prom.comments || "None"}</div>
                    )}
                    {this.state.editComments && (
                      <TextField
                        onChange={this.handleTextChange.bind(
                          null,
                          "newComment"
                        )}
                        floatingLabelText="New Comment"
                        fullWidth
                        multiLine
                        rows={3}
                      />
                    )}
                  </div>

                  <br />
                  <Divider style={styles.divider} size="large" />
                  <ListItemText
                    disableTypography
                    style={styles.subheader}
                    primary="Options"
                  />
                  {this.state.hasDeductions && (
                    <font color="red">
                      This promotion has deductions attached. Editing it is
                      discouraged.
                    </font>
                  )}
                  <Stack spacing={2}>
                    <Button
                      label={`Master Edit${
                        this.state.hasDeductions ? " (Not Recommended)" : ""
                      }`}
                      variant={
                        this.state.hasDeductions ? "outlined" : "contained"
                      }
                      color={this.state.hasDeductions ? "error" : "primary"}
                      onClick={this.handleMasterEdit}
                      disabled={
                        !(
                          this.props.db.permissions.includes("masteredit") &&
                          !this.props.readOnly &&
                          notRunningOrCompleted
                        )
                      }
                    />
                    <Button
                      label="Duplicate Promotion"
                      onClick={this.handleDuplicate}
                      disabled={
                        this.props.fromSimilarPromos ||
                        (!this.props.readOnly &&
                          (!this.props.db.permissions.includes("promotions") ||
                            this.state.proposed))
                      }
                    />
                  </Stack>
                </div>
              ) : (
                <div>
                  <br />
                  <div className="centering">
                    You have unsaved changes. Would you like to save?
                  </div>
                  <br />
                  <Stack justifyContent="center">
                    <Button
                      label="Yes"
                      onClick={() => {
                        this.handleSaveChanges();
                        this.setState({
                          promptSave: false,
                          promChanges: false
                        });
                        this.onDrawerClose(false);
                      }}
                    />
                    <Button
                      label="No, continue"
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        this.setState({
                          promptSave: false,
                          promChanges: false
                        });
                        this.onDrawerClose(false);
                      }}
                    />
                  </Stack>
                </div>
              )
            ) : (
              <div className="centering" style={{ height: "100%", margin: 30 }}>
                <CircularProgress size={60} thickness={7} />
              </div>
            )}
          </ModalContent>
        </TabPanel>
        <TabPanel value={this.state.tab} index={1}>
          <ModalContent
            offset={CONTENT_OVERFLOW_OFFSET * 2}
            style={{
              paddingLeft: 16,
              paddingRight: 16,
              height: this.state.ppHeight,
              overflow: "auto"
            }}>
            <Typography style={styles.superheader} variant="h4">
              Specific Line Details
            </Typography>
            <Divider />
            <Select
              floatingLabelText="Select Line"
              value={this.state.chosenLineKey}
              onChange={(event, target, value) => {
                this.setState({ chosenLineKey: value });
              }}
              fullWidth>
              {this.state.prom.lines &&
                Object.keys(this.state.prom.lines)
                  .sort((a, b) => {
                    try {
                      const aIndex = parseInt(
                        a.substring(a.lastIndexOf("-") + 1)
                      );
                      const bIndex = parseInt(
                        b.substring(b.lastIndexOf("-") + 1)
                      );
                      return aIndex - bIndex;
                    } catch (err) {
                      return a - b;
                    }
                  })
                  .map(key => {
                    const line = this.state.prom.lines[key];
                    const pgName =
                      this.props.db.meta.product_groups &&
                      this.props.db.meta.product_groups[line.productGroup]
                        ? this.props.db.meta.product_groups[line.productGroup]
                            .name
                        : line.productGroup;
                    return (
                      <MenuItem
                        value={key}
                        children={`${
                          this.props.db.meta.fundTypes?.[line.type]?.name
                        } - ${pgName} (${key})`}
                        key={key}
                      />
                    );
                  })}
            </Select>
            <Divider style={styles.divider} size="large" />
            <ListItemText
              disableTypography
              style={styles.subheader}
              primary="Line Profile"
            />
            {this.state.prom.lines && (
              <LineProfile
                line={this.state.prom.lines[this.state.chosenLineKey]}
                lineKey={this.state.chosenLineKey}
                prom={this.state.prom}
                promKey={this.state.promKey}
                db={this.props.db}
                handleTextChange={this.handleTextChange}
                handleDateChange={this.handleDateChange}
                handleNumTextChange={this.handleNumTextChange}
                handleSelectChange={this.handleSelectChange}
                proposed={this.state.proposed}
                updateLine={this.updateLine}
              />
            )}
            <br />
            <br />
          </ModalContent>
        </TabPanel>
        <TabPanel value={this.state.tab} index={2}>
          <ModalContent
            style={{
              paddingLeft: "16px",
              paddingRight: "16px",
              height: this.state.ppHeight,
              overflow: "auto"
            }}>
            <Typography style={styles.superheader} variant="h4">
              Overall Performance
            </Typography>
            <Divider />
            <Select
              floatingLabelText="Select Line"
              value={this.state.chosenAnalyticsLineKey}
              onChange={(event, target, value) => {
                this.setState({ chosenAnalyticsLineKey: value });
              }}
              fullWidth>
              <MenuItem value={0} children="Entire Promotion" key="wildcard" />
              {this.state.prom.lines &&
                Object.keys(this.state.prom.lines).map(key => {
                  const line = this.state.prom.lines[key];
                  const pgName =
                    this.props.db.meta.product_groups &&
                    this.props.db.meta.product_groups[line.productGroup]
                      ? this.props.db.meta.product_groups[line.productGroup]
                          .name
                      : line.productGroup;
                  return (
                    <MenuItem
                      value={key}
                      children={`${
                        this.props.db.meta.fundTypes?.[line.type]?.name
                      } - ${pgName} (${key})`}
                      key={key}
                    />
                  );
                })}
            </Select>
            <br />
            <br />
            <Card title="Actual vs. Expected Spend ($) per Account">
              <BiggestPromsGraph data={spendBarData} />
            </Card>
            <br />
            <Card title="Actual vs. Expected Sales (units) per Account">
              <BiggestPromsGraph data={salesBarData} notMoney />
            </Card>
            <br />
            <br />
            <br />
            {this.props.db.meta.tier != "pine" && (
              <div>
                <Typography style={styles.superheader} variant="h4">
                  Specific Line Analytics
                </Typography>
                <Divider />
                <br />
                <DataTable
                  title="Actual Spend/Sales"
                  height={300}
                  data={
                    this.state.prom.actMoneyList &&
                    this.state.prom.actMoneyList.length > 0
                      ? this.getMoneyTable(this.state.prom.actMoneyList)
                      : {
                          Date: ["No actual spend recorded."],
                          Sales: [""],
                          Spend: [""],
                          Line: [""]
                        }
                  }
                />
                <br />
                <ActVsExpPast
                  data={spendTimeData}
                  title="Cumulative Actual vs. Expected Spend ($) Time Series"
                />
                <br />
                <ActVsExpPast
                  data={salesTimeData}
                  title="Cumulative Actual vs. Expected Sales (units) Time Series"
                  notMoney
                />
                <br />
                <br />
              </div>
            )}
            {this.props.db.meta.tier == "aspen" && (
              <div>
                <Typography style={styles.superheader} variant="h4">
                  ROI Analysis
                </Typography>
                <Divider />
                <br />
                <DataTable title="Totals" data={roiData.Totals} />
                <br />
                <DataTable
                  title="User Entered Data"
                  data={roiData["User Entered Data"]}
                />
                <br />
                <DataTable
                  title="Deductions - Received Data"
                  data={roiData["Deductions - Received Data"]}
                />
                <br />
                <DataTable
                  title="Breakdown by Line"
                  data={roiData["Breakdown by Line"]}
                />
              </div>
            )}
          </ModalContent>
        </TabPanel>
        <TabPanel value={this.state.tab} index={3}>
          <ModalContent
            style={{
              paddingLeft: 16,
              paddingRight: 16,
              height: this.state.ppHeight,
              overflow: "auto"
            }}>
            <Typography style={styles.superheader} variant="h4">
              Manage & Add Attachments
            </Typography>
            <Divider />
            <ManagePromFiles
              prom={this.state.prom}
              db={this.props.db}
              key={this.state.promKey}
              callback={this.props.callback}
              promKey={this.props.promKey}
              proposed={this.state.proposed}
              openClose={this.props.openClose}
            />
          </ModalContent>
        </TabPanel>
      </div>
    );
  }
}
