import {
  InvoiceObject,
  ResolutionLineObject
} from "components/Deductions/models";
import { DbContextValues } from "contexts/Db";

export function getTransactionResolutionLineList(
  transactionKey: string,
  db: DbContextValues
): ResolutionLineObject[] {
  const { erpTransactions, invoices } = db;

  const transaction = erpTransactions[transactionKey];

  const linkedInvoiceKeys = transaction.linkedInvoices || {};

  return Object.values(linkedInvoiceKeys)
    .map((invoiceKey: string) => invoices[invoiceKey] || undefined)
    .map((invoice: InvoiceObject) => invoice?.resolutionLines || {})
    .reduce(
      (allResLines, invoiceResLines) => [
        ...allResLines,
        ...Object.values(invoiceResLines)
      ],
      []
    )
    .filter(resLine => resLine.transactionKey === transactionKey); // Just for safe measure
}

export function getInvoiceResolutionLineList(
  invoiceKey: string,
  db: DbContextValues
): ResolutionLineObject[] {
  const { invoices } = db;
  const invoice = invoices[invoiceKey] || undefined;
  return Object.values(invoice?.resolutionLines || {});
}

export function getInvoiceLineResolutionLineList(
  invoiceKey: string,
  invoiceLineKey: string,
  db: DbContextValues
): ResolutionLineObject[] {
  const { invoices } = db;
  const invoice = invoices[invoiceKey] || undefined;
  return Object.values(invoice?.resolutionLines || {}).filter(
    (resolutionLine: ResolutionLineObject) =>
      resolutionLine.invoiceLineKey === invoiceLineKey
  );
}
