import { deductionTransactionStatuses } from "components/Deductions/constants/ReconciliationStatuses";
import { ERPTransactionTypes } from "components/Deductions/constants/ReconciliationTypes";
import {
  ERPTransactionObject,
  TransactionConfig
} from "components/Deductions/models";
import { DefaultTransactionConfig } from "deductions-models/TransactionConfig";
import { AddTransactionState } from "./AddTransactionSlice";

/* eslint-disable import/prefer-default-export */
export const AddTransactionErrorMessagesMap: Record<string, string> = {
  missingTransactionId: "Please enter a transaction ID.",
  duplicateTransactionId: "Transaction ID already exists in system.",
  missingInvoiceNumber: "Please enter an invoice number.",
  missingCustomerKey: "Please select a customer.",
  missingTransactionDate: "Please enter a transaction date.",
  invalidTransactionDate: "Please enter a valid transaction date",
  missingAccountingSource: "Please select an accounting source.",
  missingTransactionType: "Please select a transaction type.",
  amountNotPositive: "Amount must be positive.",
  amountNotNegative: "Amount must be negative.",
  amountZero: "Amount cannot be zero."
};

export function isTransactionIdUnique(
  newTransactionDisplayId: string,
  allTransactions: Record<string, ERPTransactionObject>
) {
  return !Object.values(allTransactions)
    .filter(
      transaction =>
        transaction.status !== deductionTransactionStatuses.CANCELLED
    )
    .map(transaction => transaction.transactionDisplayId)
    .some(displayId => displayId === newTransactionDisplayId);
}

export function updateAmountErrors(
  state: AddTransactionState,
  type: ERPTransactionTypes,
  amount: number,
  accountingSource: string,
  allTransactionConfigs: Record<string, TransactionConfig>
) {
  const { deductionsIncomingPositive } =
    Object.values(allTransactionConfigs).find(
      config => config.accountingSource === accountingSource
    ) || DefaultTransactionConfig;

  if (type === ERPTransactionTypes.DEDUCTION) {
    state.errors.amountNotPositive = deductionsIncomingPositive && amount < 0;
    state.errors.amountNotNegative = !deductionsIncomingPositive && amount > 0;
  }

  if (type === ERPTransactionTypes.REPAYMENT) {
    state.errors.amountNotPositive = !deductionsIncomingPositive && amount < 0;
    state.errors.amountNotNegative = deductionsIncomingPositive && amount > 0;
  }

  state.errors.amountZero = !amount;
}

export function resetCriticalDuplicateWarning(state: AddTransactionState) {
  state.duplicateWarning.criticalDuplicationFound = false;
}

export function resetAllDuplicateWarning(state: AddTransactionState) {
  state.duplicateWarning.criticalDuplicationFound = false;
  state.duplicateWarning.duplicateTransactionIdWarningShown = false;
  state.duplicateWarning.onlyDuplicateTransactionIdFound = false;
}
