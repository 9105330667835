import { repaymentLineTypes } from "components/Deductions/constants/ReconciliationTypes";
import { RepaymentLineObject } from "components/Deductions/models";
import { DbContextValues } from "contexts/Db";
import { displayUSCurrency } from "helpers/DataProcessing";
import { DisplayRepaymentLineObject } from "../../types/repaymentLineTypes";

export const HumanReadableRepaymentLineTypes: Record<
  repaymentLineTypes,
  string
> = {
  [repaymentLineTypes.REPAYMENT]: "Repayment",
  [repaymentLineTypes.CREDIT_WRITE_OFF]: "Credit Write-Off"
};

export function processRepaymentLineForDisplay(
  repaymentLine: RepaymentLineObject,
  db: DbContextValues
): DisplayRepaymentLineObject {
  const {
    repaymentKey,
    deductionKey,
    invoiceKey,
    invoiceLineKey,
    fundTypeKey,
    fundTypeAccount,
    amount
  } = repaymentLine;
  const { erpTransactions, invoices, meta, accounts = {} } = db;
  const { fundTypes = {} } = meta;

  const repayment = erpTransactions[repaymentKey];
  const repaidDeduction = erpTransactions[deductionKey];
  const repaidInvoice = invoices[invoiceKey];

  const { invoiceLines = {} } = repaidInvoice;

  const repaidInvoiceLine = invoiceLines[invoiceLineKey] || undefined;
  const invoiceLineNumber = repaidInvoiceLine?.invoiceLineNumber;

  return {
    ...repaymentLine,
    displayRepaymentId: repayment.transactionDisplayId,
    displayDeductionId: repaidDeduction.transactionDisplayId,
    displayInvoiceNumber: repaidInvoice?.invoiceNumber || "",
    displayInvoiceLineNumber:
      invoiceLineNumber || invoiceLineNumber === 0
        ? (invoiceLineNumber + 1).toString()
        : "",
    displayAmount: displayUSCurrency(amount),
    displayType: HumanReadableRepaymentLineTypes[repaymentLine.type],
    displayFundType: fundTypes[fundTypeKey]?.name,
    displayFundTypeAccount: accounts[fundTypeAccount]?.name
  };
}
