import React from "react";
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";

import { round, sum, map } from "lodash-es";

import { displayMoney } from "helpers/DataProcessing";
import Mixpanel from "helpers/Mixpanel";

import { blue, red } from "@mui/material/colors";
import PromProfile from "../Planning/PromProfile";

const ReactTableFixedColumns = withFixedColumns(ReactTable);
const blue600 = blue["600"];
const red700 = red["700"];

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

const styles = {
  row: {
    cursor: "pointer"
  }
};

class AccrualTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: []
    };
  }

  showPromotion = rowValues => {
    this.props.openClose.showRightDrawer(
      <PromProfile
        promKey={rowValues.promKey}
        defaultLine={rowValues.lineKey}
        openClose={this.props.openClose}
        db={this.props.db}
      />
    );
    Mixpanel.track("Promotion Viewed", {
      View: "Accruals",
      Component: "AccrualTable"
    });
  };

  getColor = row => {
    if (row.column.Header == row.original.monthyear) {
      return blue600;
    }
    if (row.value < 0) {
      return red700;
    }
    return null;
  };

  renderCell = row => {
    return (
      <div
        onClick={this.showPromotion.bind(null, row.original)}
        style={{ ...styles.row, color: this.getColor(row) }}>
        {row.value}
      </div>
    );
  };

  renderNumCell = row => {
    return (
      <div
        onClick={this.showPromotion.bind(null, row.original)}
        style={{ ...styles.row, color: this.getColor(row) }}>
        {row.value ? displayMoney(row.value) : 0}
      </div>
    );
  };

  getColumns = props => {
    const columns = [
      {
        Header: "Promotion Details",
        fixed: "left",
        columns: [
          {
            Header: "Name",
            accessor: "name",
            Cell: this.renderCell,
            minWidth: 200
          },
          {
            Header: "ID",
            accessor: "id",
            Cell: this.renderCell
          },
          {
            Header: "Month/Year",
            accessor: "monthyear",
            sortMethod: (monthyear1, monthyear2) => {
              let [month1, year1] = monthyear1.split(" ");
              let [month2, year2] = monthyear2.split(" ");
              year1 = parseInt(year1);
              year2 = parseInt(year2);
              if (year1 != year2) {
                return year1 > year2 ? 1 : -1;
              }
              const ind1 = months.indexOf(month1);
              const ind2 = months.indexOf(month2);
              if (ind1 == ind2) {
                return 0;
              }
              return ind1 > ind2 ? 1 : -1;
            },
            Cell: this.renderCell
          },
          {
            Header: "Forecasted Spend",
            accessor: "totalExpSpend",
            Cell: this.renderNumCell,
            minWidth: 150,
            Footer: (
              <span>
                {displayMoney(
                  round(sum(map(props.data, d => d.totalExpSpend)))
                )}
              </span>
            )
          },
          {
            Header: "First Receiver",
            accessor: "distributor",
            Cell: this.renderCell,
            minWidth: 150
          }
        ]
      }
    ];

    // add account column if selection is by line
    if (props.granularity == "lineKey") {
      columns[0].columns.splice(2, 0, {
        Header: "Account",
        accessor: "account",
        Cell: this.renderCell
      });
    }

    if (!props.db.meta.no_auto_close) {
      columns[0].columns.splice(4, 0, {
        Header: "Days until auto-close",
        accessor: "daysUntilClose",
        Cell: this.renderCell,
        minWidth: 150,
        Footer: (
          <span>
            <strong>Total</strong>
          </span>
        )
      });
    }

    const dateColumns = [];

    for (let i = 0; i < props.monthyears.length; i++) {
      var monthyear = props.monthyears[i];
      dateColumns.push({
        Header: monthyear,
        accessor: monthyear,
        Cell: this.renderNumCell,
        Footer: (
          <span>
            {displayMoney(round(sum(map(props.data, d => d[monthyear]))))}
          </span>
        )
      });
    }

    dateColumns.push({
      Header: "Differential to Accrual",
      accessor: "remaining",
      Cell: this.renderNumCell,
      minWidth: 150,
      Footer: (
        <span>
          {displayMoney(round(sum(map(props.data, d => d.remaining))))}
        </span>
      )
    });

    dateColumns.push({
      Header: "Proposed Reversal",
      accessor: "reversal",
      Cell: this.renderNumCell,
      minWidth: 150,
      Footer: (
        <span>
          {displayMoney(round(sum(map(props.data, d => d.reversal))))}
        </span>
      )
    });

    columns.push({
      Header: "Accrual Data",
      columns: dateColumns
    });

    this.setState({
      columns
    });
  };

  componentDidMount() {
    this.getColumns(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getColumns(nextProps);
  }

  render() {
    return (
      <ReactTableFixedColumns
        className="-striped-highlight"
        data={this.props.data}
        columns={this.state.columns}
        style={this.props.style}
      />
    );
  }
}

export default AccrualTable;
