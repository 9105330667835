import axios from "axios";

import firebase from "firebase-init";

axios.interceptors.request.use(
  async config => {
    const token = await firebase.auth().currentUser?.getIdToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
