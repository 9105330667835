import React from "react";
import MUIAutocomplete from "@mui/material/Autocomplete";
import makeStyles from "@mui/styles/makeStyles";
import TextField from "ui-library/TextField";
import { matchSorter } from "match-sorter";

const baseStyles = makeStyles({
  root: props => ({
    width: props.fullWidth ? "100%" : 256
  }),
  inputRoot: props => ({
    paddingTop: props.multiple ? 5 : 0
  }),
  focused: {}
});

const filterOptions = (options, { inputValue }) => {
  if (!inputValue) {
    return options.slice(0, 1000);
  }
  if (inputValue.slice(-1) === "/" && inputValue[0] === "/") {
    const expr = inputValue.slice(1, inputValue.length - 1);
    try {
      const re = new RegExp(expr, "i");
      return options.filter(item => re.test(item));
    } catch (e) {
      return options.slice(0, 1000);
    }
  }
  return matchSorter(options, inputValue);
};

const Autocomplete = props => {
  const baseClasses = baseStyles(props);
  const {
    floatingLabelText,
    error,
    helperText,
    value,
    variant = "outlined",
    placeholder,
    defaultValue,
    options,
    ...other
  } = props;

  options.sort((a, b) => {
    let res = 0;
    if (a.text && b.text) {
      res = a.text.toLowerCase().localeCompare(b.text.toLowerCase());
    }
    return res;
  });

  if (!other.ignoreFilterOptions) {
    other.filterOptions = filterOptions;
  }

  return (
    <MUIAutocomplete
      value={value ?? (props.multiple ? undefined : "")}
      classes={baseClasses}
      options={options}
      renderInput={params => (
        <TextField
          {...params}
          variant={variant}
          label={floatingLabelText}
          errorText={error}
          helperText={helperText}
          placeholder={placeholder}
          inputProps={{
            ...params.inputProps
          }}
        />
      )}
      {...other}
    />
  );
};

export default Autocomplete;
