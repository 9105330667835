import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// todo define types and initialState for reducer
interface BackupState {
  activeFilters: BackupFilterState;
  sortState: BackupSortState;
}

export interface BackupFilterState {
  customerName?: BackupTextColumn;
  invoiceNumber?: BackupTextColumn;
  backupSource?: BackupTextColumn;
  createdDate?: BackupDateColumn;
  amount?: BackupAmountColumn;
  checkNumber?: BackupTextColumn;
  type?: BackupTextColumn;
  status?: BackupTextColumn;
  source?: BackupTextColumn;
}

interface BackupTextColumn {
  value?: string;
}

interface BackupDateColumn {
  max?: Date;
  min?: Date;
}

interface BackupAmountColumn {
  max?: number;
  min?: number;
}

export interface BackupSortState {
  orderBy?: string;
  sortDir?: "asc" | "desc";
}

const initialState: BackupState = {
  activeFilters: {},
  sortState: {
    orderBy: "createdDate",
    sortDir: "asc"
  }
};

const slice = createSlice({
  name: "backupTable",
  initialState,
  reducers: {
    setBackupActiveFilters(
      state: BackupState,
      action: PayloadAction<BackupFilterState>
    ) {
      state.activeFilters = action.payload;
    },
    setBackupSorting(
      state: BackupState,
      action: PayloadAction<BackupSortState>
    ) {
      state.sortState = action.payload;
    }
  }
});

// export redux action
export const { setBackupActiveFilters, setBackupSorting } = slice.actions; // todo, actions

// export reducer as default import
export default slice.reducer;
