import React, { useState, useEffect } from "react";
import ScannerIcon from "@mui/icons-material/Scanner";
import ReorderIcon from "@mui/icons-material/Reorder";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import Badge from "@mui/material/Badge";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Alert from "@mui/material/Alert";
import { sum } from "lodash-es";
import { getFirebase, isUserCresicorEmployee } from "helpers/Firebase";
import { useHistory, useLocation } from "react-router";
import { FREE_TRIAL_DAYS, SPECIAL_TRIAL_DAYS } from "../constants/FreeTrial";
import { MS_DAY } from "../constants/Time";
import ScanAndMatch from "./ScanAndMatch";

import ProcessedScans from "./ProcessedScans";
import ScanHistory from "./ScanHistory/ScanHistory";

const DeductionsProcessing = props => {
  const [freeTrial, setFreeTrial] = useState({});
  const [activeScans, setActiveScans] = useState<Number>(0);
  const [selectedIndex, setSelectedIndex] = useState<Number>(0);
  const history = useHistory();
  const location = useLocation();

  const freeTrialProp = props.db.meta.free_trial;
  useEffect(() => {
    const trialDays = freeTrialProp?.special_trial
      ? SPECIAL_TRIAL_DAYS
      : FREE_TRIAL_DAYS;

    const freeTrial = freeTrialProp
      ? {
          maxDays: trialDays,
          onTrial: freeTrialProp.on_trial,
          startDate: new Date(freeTrialProp.start_date),
          pagesIn: freeTrialProp.pages_in
        }
      : {};
    setFreeTrial(freeTrial);
  }, [freeTrialProp]);

  getFirebase(23, scans => {
    const activeScans = sum(
      Object.values(scans).map(scan => scan.loading || 0)
    );
    setActiveScans(activeScans); // TODO: This needs to be a promise instead
  });

  const getFreeTrialBanner = freeTrial => {
    const daysIn = Math.floor((new Date() - freeTrial.startDate) / MS_DAY) + 1;
    const { pagesIn } = freeTrial;

    if (!isUserCresicorEmployee() && !location.search.includes("trial=true")) {
      history.replace({
        pathname: location.pathname,
        search: `${location.search}&trial=true`
      });
    }
    const emailLink =
      "mailto:dsm-trial@cresicor.com?Subject=Interest%20in%20Upgrading%20Deductions%20Scanner";

    if (daysIn < freeTrial.maxDays) {
      return (
        <Alert severity="info" style={{ justifyContent: "center" }}>
          {`You have ${
            freeTrial.maxDays - daysIn
          } more days left on your free trial and scanned a total of ${pagesIn} pages.`}
        </Alert>
      );
    }
    if (daysIn >= freeTrial.maxDays) {
      return (
        <Alert severity="warning" style={{ justifyContent: "center" }}>
          {"Your free trial has expired. Please contact us "}
          <a href={emailLink}>here</a>
          {" to upgrade to a paid plan."}
        </Alert>
      );
    }
  };

  return (
    <>
      {freeTrial?.onTrial && getFreeTrialBanner(freeTrial)}
      <BottomNavigation
        value={selectedIndex}
        onChange={(_event, value) => {
          setSelectedIndex(value);
        }}
        showLabels
        sx={{ bgcolor: "background.surfaceVariant" }}>
        <BottomNavigationAction
          label="Scan & Match"
          icon={<ScannerIcon />}
          value={0}
        />
        <BottomNavigationAction
          label="Processed Scans"
          icon={
            <Badge badgeContent={activeScans} color="error" style={{ top: 5 }}>
              <ReorderIcon />
            </Badge>
          }
          value={1}
        />
        {!freeTrial?.onTrial && ( // TODO: Make scan history for free trial (data is currently different for free vs otherwise)
          <BottomNavigationAction
            label="Scan History"
            icon={<EqualizerIcon />}
            value={2}
          />
        )}
      </BottomNavigation>
      {selectedIndex === 0 && <ScanAndMatch {...props} />}
      {selectedIndex === 1 && <ProcessedScans {...props} />}
      {selectedIndex === 2 && <ScanHistory companyId={props.companyid} />}
    </>
  );
};

export default DeductionsProcessing;
