import React from "react";
import { DropzoneArea } from "react-mui-dropzone";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { grey } from "@mui/material/colors";
import DownloadSample from "../UploadFiles/DownloadSample";

const grey300 = grey["300"];

const styles = theme =>
  createStyles({
    root: {
      height: "100%",
      minHeight: 100,
      maxHeight: 150,
      overflow: "auto",
      backgroundColor: grey300
    },
    text: {
      fontSize: 15,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    icon: {
      width: 40,
      height: 40
    },
    previewChip: {
      minWidth: 150,
      maxWidth: 240
    }
  });

class DeductionsReconciliationFilePicker extends React.Component {
  constructor(props) {
    super(props);
    this.dropzone = null;
    this.state = {
      files: [],
      acceptedFiles: []
    };
  }

  componentDidMount() {
    if (this.props.promotionUpload) {
      this.props.onLoad();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ message: nextProps.message });
  }

  render() {
    const { classes } = this.props;
    const {
      showPreviewsInDropzone,
      useChipsForPreview,
      message,
      componentStyle,
      acceptedFiles,
      initialFiles,
      filesLimit,
      maxFileSize,
      onChange,
      onDelete,
      onAdd,
      onDrop,
      dzKey
    } = this.props;
    return (
      <div style={componentStyle}>
        <DropzoneArea
          key={dzKey}
          dropzoneText={message}
          classes={classes}
          dropzoneClass={classes.root}
          dropzoneParagraphClass={classes.text}
          onChange={onChange}
          onDelete={onDelete}
          onAdd={onAdd}
          onDrop={onDrop}
          acceptedFiles={acceptedFiles}
          initialFiles={initialFiles}
          showPreviewsInDropzone={
            showPreviewsInDropzone === null ? true : showPreviewsInDropzone
          }
          useChipsForPreview
          previewChipProps={{ classes: { root: classes.previewChip } }}
          maxFileSize={maxFileSize}
          filesLimit={filesLimit}
          showAlerts={["error", "info"]}
          disableRejectionFeedback
        />
        {this.props.promotionUpload == true && (
          <DownloadSample sampleName={this.props.selectedDataType} />
        )}
      </div>
    );
  }
}

DeductionsReconciliationFilePicker.defaultProps = {
  message: "Upload file(s) here.",
  acceptedFiles: [],
  fileTypes: [],
  initialFiles: [],
  maxFileSize: 5 << 30,
  filesLimit: 1
};

export default withStyles(styles, { withTheme: true })(
  DeductionsReconciliationFilePicker
);
