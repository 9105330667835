import React, { useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { ViewColumn, List } from "@mui/icons-material";
import { Typography, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { RootState } from "store";
import {
  DRMGuiMode,
  DRMRouteState,
  setDRMGuiMode
} from "components/Deductions/DeductionsReconciliation/redux/DRMSlice";

const useStyles = makeStyles(() => ({
  buttonContainer: { display: "flex" }
}));

export default function ViewGuiControls() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { routeState, drmFeatureState } = useSelector(
    (state: RootState) => state.drm,
    shallowEqual
  );

  const switchToGui = (guiType: "board" | "list") => {
    history.push(
      `/${drmFeatureState}/${
        routeState === DRMRouteState.TRANSACTIONS ? "transactions" : "invoices"
      }/${guiType}`
    );
  };

  useEffect(() => {
    const { pathname } = history.location;
    if (pathname.includes(DRMGuiMode.LIST)) {
      dispatch(setDRMGuiMode(DRMGuiMode.LIST));
    } else if (pathname.includes(DRMGuiMode.BOARD)) {
      dispatch(setDRMGuiMode(DRMGuiMode.BOARD));
    }
  }, [dispatch, history.location]);

  return (
    <div className={classes.buttonContainer}>
      <Switch>
        <Route exact path={`/${drmFeatureState}/*/list`}>
          <Button onClick={() => switchToGui("board")}>
            <ViewColumn />
            &nbsp;
            <Typography variant="caption">View as Board</Typography>
          </Button>
        </Route>
        <Route exact path={`/${drmFeatureState}/*/board`}>
          <Button onClick={() => switchToGui("list")}>
            <List />
            &nbsp;
            <Typography variant="caption">View as List</Typography>
          </Button>
        </Route>
      </Switch>
    </div>
  );
}
