import { Divider } from "pui-react-dividers";
import React from "react";
import debounce from "lodash.debounce";

import Autocomplete from "ui-library/Autocomplete";
import ListItemText from "@mui/material/ListItemText";
import Chip from "@mui/material/Chip";
import Subheader from "ui-library/Subheader";
import Typography from "@mui/material/Typography";

import Label from "@mui/icons-material/Label";

// import Select from "@mui/material/Select";
// import MenuItem from "@mui/material/MenuItem";

import Button from "ui-library/Button";
import IconMenu from "ui-library/IconMenu";
import MenuItem from "ui-library/MenuItem";
import Select from "ui-library/Select";
import TextField from "ui-library/TextField";

import AssignmentIcon from "@mui/icons-material/Assignment";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

import { objToArray, sortByField } from "helpers/sortByDate";
import { isUserCresicorEmployee } from "helpers/Firebase";
import ModalContent from "components/WebsiteElements/ModalContent";
import { blue, red, orange } from "@mui/material/colors";
import { createFilterOptions } from "@mui/material/useAutocomplete";
import { Stack } from "@mui/material";
import ChipInput from "components/WebsiteElements/ChipInput";
import DataTable from "../tables/MuiDataTable";
import SpendSplit from "./SpendSplit";
// import CustomerEditLog from "./CustomerEditLog";
import TimeframeIndicator from "./TimeframeIndicator";

import { checkProposedCustomerSplits } from "./CustomerUtils";

const blue200 = blue["200"];
const red500 = red["500"];
const red600 = red["600"];
const orange500 = orange["500"];
const orange700 = orange["700"];

// TODO have a central location where CU links are stored, and/or integrate CU into Cresicor portal in a better way
const cresicorUniversityCustomerNameLink =
  "https://university.govividly.com/docs/contacts-section-guide#why-is-editing-a-customer-name-discouraged";

const filter = createFilterOptions();

export default class CustomerGeneralInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: [],
      channels: [],
      agencies: []
    };
  }

  buildMenuComponentsArr = initialArr => {
    const newJSX = [];
    for (const obj of initialArr) {
      newJSX.push(
        <MenuItem
          // checked={obj.checked}
          value={obj.key}
          key={obj.key}
          children={obj.props.children}
        />
      );
    }
    return newJSX;
  };

  getRevenueSourcesJSX = () => {
    const revenueSources = this.props.db.meta.revenue_sources;
    const revenueSourcesJSX = [];
    if (revenueSources) {
      for (const i in revenueSources) {
        revenueSourcesJSX.push(
          <MenuItem
            value={revenueSources[i]}
            key={i}
            children={revenueSources[i]}
          />
        );
      }
    }
    return revenueSourcesJSX;
  };

  getPresetCustomersJSX = () => {
    const { presetCustomers } = this.props;
    const presetCustomerList = sortByField(
      "name",
      objToArray(presetCustomers),
      true
    );
    const presetCustomersJSX = [];
    for (const customer of presetCustomerList) {
      presetCustomersJSX.push(
        <MenuItem
          value={customer.key}
          key={customer.key}
          children={customer.name}
        />
      );
    }
    return presetCustomersJSX;
  };

  performAltNameImport = importType => {
    const { customer } = this.props;
    const presetKey = customer.associatedPresetCustomer;
    const currentAltNames = customer.otherNames || [];
    const currentAltNameTags = customer.otherNamesMap || [];
    const presetAltNames =
      this.props.presetCustomers[presetKey].otherNames || [];
    const presetAltNameTags =
      this.props.presetCustomers[presetKey].otherNamesMap || [];
    let newAltNames = [];
    let newAltNameTags = [];
    if (importType == "Overwrite All") {
      newAltNames = presetAltNames;
      newAltNameTags = presetAltNameTags;
    } else if (importType == "Overwrite Common") {
      newAltNames = currentAltNames;
      newAltNameTags = currentAltNameTags;
      for (var i = 0; i < presetAltNames.length; i++) {
        var nameInd = newAltNames.indexOf(presetAltNames[i]);
        var tagInd = newAltNameTags.findIndex(
          tagObj => tagObj.tagName == presetAltNames[i]
        );
        const presetTagInd = presetAltNameTags.findIndex(
          tagObj => tagObj.tagName == presetAltNames[i]
        );
        if (nameInd != -1) {
          if (tagInd != -1 && presetTagInd != -1) {
            newAltNameTags[tagInd] = presetAltNameTags[presetTagInd];
          }
        } else {
          newAltNames.push(presetAltNames[i]);
          if (presetTagInd != -1) {
            newAltNameTags.push(presetAltNameTags[presetTagInd]);
          }
        }
      }
    } else {
      newAltNames = currentAltNames;
      newAltNameTags = currentAltNameTags;
      for (var i = 0; i < presetAltNames.length; i++) {
        var nameInd = newAltNames.indexOf(presetAltNames[i]);
        var tagInd = newAltNameTags.findIndex(
          tagObj => tagObj.tagName == presetAltNames[i]
        );
        if (nameInd == -1) {
          newAltNames.push(presetAltNames[i]);
          if (tagInd != -1) {
            newAltNameTags.push(newAltNameTags[tagInd]);
          }
        }
      }
    }
    customer.otherNames = newAltNames;
    customer.otherNamesMap = newAltNameTags;
    this.props.setCustomerProfileState({
      customer,
      changes: true
    });
    this.props.openClose.closeAppModal();
  };

  importPresetAltNames = () => {
    const presetKey = this.props.customer.associatedPresetCustomer;
    if (!(presetKey in this.props.presetCustomers)) {
      this.props.openClose.showSnack(
        "Associated Preset Customer No Longer Exists."
      );
    } else {
      this.props.openClose.setAppModal(
        "Import Alternative Names",
        <div className="centering">
          Choose the method by which the alternative names should be imported.
        </div>,
        <Stack>
          <Button
            label="Overwrite All Names"
            color="error"
            variant="text"
            onClick={this.performAltNameImport.bind(null, "Overwrite All")}
          />
          <Button
            label="Overwrite Common Names Only"
            color="error"
            variant="text"
            onClick={this.performAltNameImport.bind(null, "Overwrite Common")}
          />
          <Button
            label="Do Not Overwrite"
            variant="text"
            onClick={this.performAltNameImport.bind(null, "Do Not Overwrite")}
          />
        </Stack>
      );
    }
  };

  handleAssignRegionSplit = () => {
    const currentCustomer = jQuery.extend(true, {}, this.props.customer);

    // initialize proposed customer
    this.setState({ proposedCustomer: currentCustomer });

    const distributors = Array.from(currentCustomer.distributors ?? []);
    if (currentCustomer.isDirect || currentCustomer.isDistributor) {
      distributors.push(this.props.customerKey);
    }
    const subsplitEntries = distributors.map(d => [
      d,
      this.props.db.customers[d]
    ]);

    this.props.openClose.setAppModal(
      "Assign Region % Split",
      <SpendSplit
        customer={currentCustomer}
        updateProposedCustomer={customer => {
          this.setState({ proposedCustomer: customer });
        }}
        db={this.props.db}
        customerKey={this.props.customerKey}
        elements={(this.props.customer.regionGroups ?? []).map(r => [
          r,
          this.props.db.meta.region_mapping.groups[r]
        ])}
        subsplit="Distributors"
        splitField="splitByRegion"
        subsplitField="splitByRegionDist"
        subsplitEntries={subsplitEntries}
      />,
      <Stack>
        <Button
          label="Cancel"
          onClick={this.props.openClose.closeAppModal}
          color="error"
          variant="text"
        />
        <Button
          label="Save"
          variant="text"
          onClick={() => {
            const { proposedCustomer } = this.state;
            const error = checkProposedCustomerSplits(
              proposedCustomer,
              this.props.customer.regionGroups ?? [],
              subsplitEntries.map(e => e[0]),
              "splitByRegion",
              "splitByRegionDist"
            );

            if (!error) {
              this.props.setCustomerProfileState({
                customer: proposedCustomer,
                changes: true
              });
              this.setState({ proposedCustomer: {} });
              this.props.openClose.closeAppModal();
            } else {
              const errorName = `"${
                error == "all"
                  ? "All Distributors"
                  : this.props.db.customers[error].name
              }"`;
              this.props.openClose.showSnack(
                `${errorName} must have numeric values between 0 and 100 that sum to 100`
              );
            }
          }}
        />
      </Stack>
    );
  };

  getClassChannel = customers => {
    const classes = [];
    const channels = [];
    const agencies = [];

    Object.entries(customers).forEach(([_, customer]) => {
      if (customer.class) {
        classes.push(customer.class);
      }
      if (customer.channel) {
        channels.push(customer.channel);
      }
      if (customer.agencies) {
        agencies.push(...(customer.agencies || []));
      }
    });

    this.setState({
      classes: Array.from(new Set(classes)),
      channels: Array.from(new Set(channels)),
      agencies: Array.from(new Set(agencies))
    });
  };

  getAltNameOptionsJSX = () => {
    const list = Array.from(
      this.props.customer && this.props.customer.otherNames
        ? this.props.customer.otherNames
        : []
    );

    const altNameOptions = [];
    if (this.props.customer.otherNames) {
      list.forEach(d => {
        altNameOptions.push(
          <div>
            <IconMenu
              iconButtonElement={<MoreVertIcon />}
              anchorOrigin={{ horizontal: "right", vertical: "top" }}
              targetOrigin={{ horizontal: "right", vertical: "top" }}>
              <MenuItem
                children="Add/Edit/Remove Tags"
                leftIcon={<Label />}
                onClick={debounce(
                  this.props.handleTagAddition.bind(null, null, d),
                  500
                )}
              />
              <MenuItem
                children="Delete Row"
                leftIcon={<DeleteIcon color={red500} />}
                onClick={debounce(
                  this.props.handleDeleteFromTable.bind(null, "otherNames", d),
                  500
                )}
              />
            </IconMenu>
          </div>
        );
      });
    }

    return altNameOptions;
  };

  getAltNameData = () => {
    let altNameData = [];
    const regionNameData = [];
    if (this.props.customer.otherNamesMap && this.props.customer.otherNames) {
      altNameData = [];
      for (const alt of this.props.customer.otherNames) {
        let nameTags = [];
        let regionTag = "";
        if (
          this.props.customer.otherNamesMap
            .map(tagObj => tagObj.tagName)
            .includes(alt)
        ) {
          for (let i = 0; i < this.props.customer.otherNamesMap.length; i++) {
            if (this.props.customer.otherNamesMap[i].tagName == alt) {
              nameTags =
                this.props.customer.otherNamesMap[i].distributorTag || [];
              regionTag =
                this.props.customer.otherNamesMap[i].regionTag || null;
            }
          }
        } else {
          altNameData.push("");
          regionNameData.push("");
          continue;
        }
        // get all tag labels and region labels
        regionNameData.push(
          regionTag
            ? this.props.db.meta.region_mapping.groups[regionTag].name
            : ""
        );
        if (nameTags.length == 0) {
          altNameData.push("");
        } else {
          const tagLabels = nameTags.map(tag => (
            <Chip label={tag} style={this.props.styles.chip} />
          ));
          altNameData.push(
            <div style={{ display: "flex", flexDirection: "row" }}>
              {tagLabels}
            </div>
          );
        }
      }
    }

    return [altNameData, regionNameData];
  };

  getRegionTableData = () => {
    const dict = {};
    const percentSplit = "All Distributors";
    const allRegionGroups = this.props.customer.regionGroups;

    if (this.props.customer.splitByRegion) {
      dict[percentSplit] = [];
      for (const region of this.props.customer.regionGroups) {
        var split = this.props.customer.splitByRegion[region];
        if (split) {
          dict[percentSplit].push(`${split.toString()}%`);
        } else {
          dict[percentSplit].push("");
        }
      }
    }
    if (this.props.customer.splitByRegionDist) {
      for (const key in this.props.customer.splitByRegionDist) {
        const distName = this.props.db.customers[key].name;
        const distSplit = this.props.customer.splitByRegionDist[key];
        if (distSplit) {
          dict[distName] = [];
          for (let i = 0; i < allRegionGroups.length; i++) {
            const regionGroup = allRegionGroups[i];
            var split = distSplit[regionGroup];
            if (split) {
              dict[distName].push(`${split.toString()}%`);
            } else {
              dict[distName].push("");
            }
          }
        }
      }
    }

    return dict;
  };

  handleNewAltNames = operation => newAltNameValues => {
    this.props.setCustomerProfileState({
      newAltNames: [...newAltNameValues]
    });

    this.props.setCustomerProfileState({ changes: true });
  };

  handleChipInputPaste = ({ nativeEvent }) => {
    nativeEvent.preventDefault();
    const text = nativeEvent.clipboardData.getData("text");
    // Split paste text for new line or pipe
    const pastedChips = text.split(/\n|\|/g).map(text => text.trim());
    this.props.setCustomerProfileState({
      newAltNames: [...this.props.newAltNames, ...pastedChips],
      changes: true
    });
  };

  getRegionGroupInfo = regionGroup => {
    const regionGroups = this.props.regionGroupList.filter(
      option => option.value === regionGroup
    );
    return regionGroups?.[0] ?? {};
  };

  componentDidMount() {
    this.getClassChannel(this.props.db.customers);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getClassChannel(nextProps.db.customers);
  }

  render() {
    const [altNameData, regionNameData] = this.getAltNameData();
    const altNameOptions = this.getAltNameOptionsJSX();

    const presetCustomersJSX = this.getPresetCustomersJSX();
    const revenueSourcesJSX = this.getRevenueSourcesJSX();

    return !this.props.promptSave ? (
      <ModalContent
        style={{
          paddingLeft: "16px",
          paddingRight: "16px",
          height: this.props.ppHeight,
          overflow: "auto"
        }}
        innerRef={this.props.customerProfileRef}>
        <Stack spacing={2} direction="column" sx={{ marginTop: 2 }}>
          <TimeframeIndicator
            customer={this.props.customer}
            customers={this.props.db.customers}
            timeframeIndex={this.props.timeframeIndex}
            setTimeframe={this.props.setTimeframe}
            meta={this.props.db.meta}
            saveCustomer={customer => {
              this.props.setCustomerProfileState(
                {
                  customer,
                  changes: true
                },
                true
              );
              this.props.openClose.closeAppModal();
            }}
            openClose={this.props.openClose}
            editPermission={this.props.editPermission}
            presetCustomer={this.props.presetCustomer}
            changes={this.props.changes}
          />
          <TextField
            disabled={!this.props.editPermission}
            value={this.props.customer.name || ""}
            id="name"
            onChange={this.props.handleTextChange}
            floatingLabelText="Name"
            fullWidth
          />
          {this.props.customerNameChanged && !this.props.presetCustomer && (
            <div>
              <br />
              <div className="rowC">
                <font color="orange">
                  Editing the name of a customer is discouraged.
                </font>
                <Button
                  label="More Info"
                  backgroundColor={orange500}
                  hoverColor={orange700}
                  onClick={() =>
                    window.open(cresicorUniversityCustomerNameLink, "_blank")
                  }
                  style={{
                    color: "white",
                    marginLeft: "5%",
                    transform: "translate(0%, -25%)"
                  }}
                />
              </div>
            </div>
          )}
          <Select
            floatingLabelText="Contacts"
            value={this.props.customer.contact}
            multiple
            onChange={this.props.handleSelectChange.bind(null, "contact")}
            disabled={!this.props.editPermission}
            fullWidth>
            {this.props.contactsJSX.map(contact => {
              return (
                <MenuItem
                  checked={this.props.customer.contact?.includes(contact.key)}
                  value={contact.key}
                  key={contact.key}
                  children={contact.props.children}
                />
              );
            })}
          </Select>
          <Autocomplete
            disabled={!this.props.editPermission}
            ignoreFilterOptions
            options={this.state.agencies}
            getOptionLabel={option => option}
            multiple
            freeSolo
            getOptionDisabled={option => false}
            value={this.props.customer.agencies || []}
            onChange={(_, option) => {
              if (option.inputValue) {
                this.props.handleUpdateInput("agencies", option.inputValue);
              } else {
                this.props.handleUpdateInput("agencies", option);
              }
            }}
            openOnFocus
            fullWidth
            floatingLabelText="Agencies"
            renderOption={(props, option) => {
              return <li {...props}>{option.title || option}</li>;
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              // Suggest the creation of a new value
              if (params.inputValue !== "") {
                filtered.push(params.inputValue);
              }

              return filtered;
            }}
          />
          <TextField
            disabled={!this.props.editPermission}
            value={this.props.customer.region || ""}
            id="region"
            onChange={this.props.handleTextChange}
            floatingLabelText="Region"
            fullWidth
          />
          {["class", "channel"].map(field => (
            <Autocomplete
              disabled={!this.props.editPermission}
              options={
                field == "class"
                  ? [...this.state.classes, this.state.newClass]
                  : [...this.state.channels, this.state.newChannel]
              }
              getOptionLabel={option => option ?? ""}
              getOptionDisabled={option => false}
              value={this.props.customer[field]}
              onChange={(_, option) => {
                this.props.handleUpdateInput(field, option);
              }}
              onInputChange={(_, input) => {
                if (field == "class") this.setState({ newClass: input });
                else this.setState({ newChannel: input });
              }}
              openOnFocus
              fullWidth
              floatingLabelText={field.charAt(0).toUpperCase() + field.slice(1)}
              renderOption={(props, option) => {
                return <ListItemText {...props} primary={option} />;
              }}
            />
          ))}
          {isUserCresicorEmployee() && (
            <Select
              floatingLabelText="Associated Preset Customer"
              value={this.props.customer.associatedPresetCustomer}
              onChange={this.props.handleSelectChange.bind(
                null,
                "associatedPresetCustomer"
              )}
              fullWidth>
              {presetCustomersJSX}
            </Select>
          )}
          <Divider
            style={this.props.styles.divider}
            size="large"
            className="centering"
          />
          <Stack>
            <Typography variant="h5">
              Add Alternative Names (for data matching)
            </Typography>
            <Button
              disabled={!this.props.customer.associatedPresetCustomer}
              label="Import From Preset"
              onClick={this.importPresetAltNames}
              primary
              icon={<CloudDownloadIcon />}
            />
          </Stack>

          <div className="rowC">
            <span>
              Press Enter after typing an alternative name to create it. Make
              sure to "Save Changes" once you're done.
            </span>
          </div>
        </Stack>
        <ChipInput
          disabled={!this.props.editPermission}
          value={this.props.newAltNames}
          label="Alternative Name(s)"
          fullWidth
          onAdd={this.handleNewAltNames("add")}
          onDelete={this.handleNewAltNames("delete")}
          onPaste={this.handleChipInputPaste}
        />
        <br />
        <DataTable
          title="Alternative Names"
          widths={altNameData.length ? ["40%", "40%", "10%", "10%"] : ["100%"]}
          data={{
            ...(this.props.customer.otherNames
              ? { Name: this.props.otherNamesJSX }
              : { Name: ["No alternative names stored"] }),
            ...(altNameData.length ? { Tags: altNameData } : {}),
            ...(regionNameData.length ? { Region: regionNameData } : {}),
            ...(altNameOptions.length ? { Options: altNameOptions } : {})
          }}
        />
        <br />
        {!this.props.presetCustomer && this.props.db.meta.broker_commissions && (
          <div>
            <Divider
              style={this.props.styles.divider}
              size="large"
              className="centering"
            />
            <Subheader>Commissions - Assigned Region Groups</Subheader>
            <br />
            {(this.props.regionGroupList || []).length ? (
              <div>
                <div className="rowC">
                  <Autocomplete
                    disabled={
                      !this.props.editPermission || this.props.presetCustomer
                    }
                    options={this.props.regionGroupList || []}
                    getOptionLabel={option => option.text ?? ""}
                    value={this.props.newRegionGroup}
                    onChange={(_, option) => {
                      this.props.setCustomerProfileState({
                        newRegionGroup: option,
                        changes: true
                      });
                    }}
                    fullWidth
                    openOnFocus
                    floatingLabelText="Assigned Region Group (save changes to add)"
                    ignoreFilterOptions
                  />
                  <Button
                    disabled={!this.props.editPermission}
                    label="Assign Region % Split"
                    onClick={this.handleAssignRegionSplit}
                    primary
                    icon={<AssignmentIcon />}
                  />
                </div>
                <br />
                <DataTable
                  title="Assigned Region Groups"
                  data={{
                    ...(this.props.customer.regionGroups
                      ? { Name: this.props.regionGroupsJSX }
                      : { Name: ["No region groups stored"] }),
                    ...this.getRegionTableData()
                  }}
                />
              </div>
            ) : (
              <div>
                <div
                  className="centering"
                  style={{ fontSize: 16, color: red600 }}>
                  No region groups currently exist! Please resolve within Region
                  Mapping.
                </div>
                <br />
              </div>
            )}
            <Subheader>Commissions - Miscellaneous Options</Subheader>
            {!this.props.presetCustomer && (
              <div>
                <Select
                  floatingLabelText="Commissions Revenue Source"
                  value={
                    this.props.customer.commissions_sources
                      ? this.props.customer.commissions_sources[0]
                      : null
                  }
                  multiple={false}
                  onChange={this.props.handleSelectChange.bind(
                    null,
                    "commissions_sources"
                  )}
                  disabled={!this.props.editPermission}
                  fullWidth>
                  {revenueSourcesJSX}
                </Select>
                <Select
                  floatingLabelText="Commissions Deduction Exclusion Customers"
                  value={this.props.customer.exclusion_customers}
                  multiple
                  onChange={this.props.handleSelectChange.bind(
                    null,
                    "exclusion_customers"
                  )}
                  disabled={!this.props.editPermission}
                  fullWidth>
                  {this.props.customersJSX.map(cust => {
                    return (
                      <MenuItem
                        checked={this.props.customer.exclusion_customers?.includes(
                          cust.key
                        )}
                        value={cust.key}
                        key={cust.key}
                        children={cust.props.children}
                      />
                    );
                  })}
                </Select>
                <Select
                  floatingLabelText="Commissions Revenue Exclusion Customers"
                  value={this.props.customer.revenue_exclusion_customers}
                  multiple
                  onChange={this.props.handleSelectChange.bind(
                    null,
                    "revenue_exclusion_customers"
                  )}
                  disabled={!this.props.editPermission}
                  fullWidth>
                  {this.props.customersJSX.map(cust => {
                    return (
                      <MenuItem
                        checked={this.props.customer.revenue_exclusion_customers?.includes(
                          cust.key
                        )}
                        value={cust.key}
                        key={cust.key}
                        children={cust.props.children}
                      />
                    );
                  })}
                </Select>
              </div>
            )}
          </div>
        )}
        <br />
        <br />
        {!this.props.presetCustomer &&
          (this.props.customer.isDirect ||
            !this.props.customer.isDistributor) && (
            <div>
              <Subheader>Weeks To Retail</Subheader>
              <TextField
                disabled={!this.props.editPermission}
                value={this.props.weeksToRetail}
                onChange={event => {
                  this.props.setCustomerProfileState({
                    weeksToRetail: event.target.value,
                    changes: true
                  });
                }}
                floatingLabelText="Weeks To Retail"
                fullWidth
                style={{ marginTop: -16 }}
              />
              <br />
              <br />
              <br />
              <Subheader>Forecast Revenue Sources</Subheader>
              <Select
                floatingLabelText="Selected Sources"
                value={this.props.customer.revenue_sources}
                multiple
                onChange={this.props.handleRevSource}
                disabled={!this.props.editPermission}>
                {(this.props.db.meta.revenue_sources || []).map(
                  (source, index) => {
                    const allRevenueSources =
                      this.props.customer.revenue_sources || [];
                    return (
                      <MenuItem
                        value={source}
                        key={source}
                        checked={allRevenueSources.includes(source)}
                        children={source}
                      />
                    );
                  }
                )}
              </Select>
              <br />
              <br />
              <div style={this.props.styles.wrapper}>
                {this.props.customer.revenue_sources &&
                  this.props.customer.revenue_sources.map((source, index) => {
                    return (
                      <Chip
                        label={source}
                        // onClick={
                        //   this.props.editPermission &&
                        //   this.props.handleRevSource.bind(
                        //     null,
                        //     source,
                        //     "add"
                        //   )
                        // }
                        style={this.props.styles.chip}
                        backgroundColor={blue200}
                      />
                    );
                  })}
              </div>
              <br />
            </div>
          )}
      </ModalContent>
    ) : (
      this.props.getSaveJSX()
    );
  }
}
