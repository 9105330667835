import React, { useEffect, useState, useCallback } from "react";
import classNames from "classnames";
import { Divider, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import IconButton from "ui-library/IconButton";
import Button from "ui-library/Button";
import RadioButton from "ui-library/RadioButton";
import SelectField from "ui-library/Select";
import Subheader from "ui-library/Subheader";
import { useSnackbar } from "notistack";
import DataTableScrollable from "components/tables/DataTableScrollable";

import { months } from "helpers/Time";
import { getJSXListsFirebase } from "helpers/Firebase";
import { displayMoney } from "helpers/DataProcessing";
import { useDb } from "contexts/Db";
import { InvoiceLineObject, InvoiceObject } from "components/Deductions/models";
import PromProfile from "components/Planning/PromProfile";
import { InvoiceProcessingService } from "reconciliation-services";
import { useDRMEvents } from "contexts/DRMEvents";
import { DRMEventType } from "components/Deductions/DeductionsReconciliation/ActivityLog/DRMEvent";

const useStyles = makeStyles(() => ({
  invoiceHeader: {
    lineHeight: "20.2px",
    fontWeight: 700
  },
  info: {
    fontSize: "1.25rem",
    fontWeight: 400
  },
  amount: {
    lineHeight: "20.2px"
  },
  amountContainer: {
    display: "flex",
    paddingTop: "10px"
  },
  divider: {
    marginLeft: "15px",
    marginRight: "15px"
  },
  buttonBase: {
    display: "block",
    textAlign: "initial",
    width: "100%"
  }
}));

interface InvoiceLineMatchEditorProps {
  invoiceKey: string;
  invoiceLineKey: string;
  invoiceLine: InvoiceLineObject;
  invoice: InvoiceObject;
  openClose: any;
}

export default function InvoiceLineMatchEditor(
  props: InvoiceLineMatchEditorProps
) {
  const { invoiceKey, invoiceLineKey, invoiceLine, invoice, openClose } = props;
  const db = useDb();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useState({
    customer: invoiceLine.customerKey,
    product: invoiceLine.productKey || ""
  });
  const { addEvent } = useDRMEvents();
  const [itemJSX, setItemJSX] = useState({});
  const [selectedLineKey, setSelectedLineKey] = useState<string>("");
  useEffect(() => {
    getJSXListsFirebase(
      db,
      allJSX => {
        setItemJSX(allJSX);
      },
      null,
      true
    );
  }, [db, invoiceKey]);

  const satisfiesSearch = useCallback(
    (line, searchParams) => {
      let pgProducts: string[] = [];
      const { customer: customerKey, product: productKey = "" } = searchParams;
      if (!line) {
        return false;
      }
      if (line.productGroup !== "custom") {
        pgProducts = db.meta.product_groups[line.productGroup].products ?? [];
      } else {
        pgProducts = line.product;
      }
      if (line.customer !== customerKey) {
        return false;
      }
      if (
        (productKey in db.products && !pgProducts.includes(productKey)) ||
        (productKey in db.meta.product_groups &&
          line.productGroup !== productKey)
      ) {
        return false;
      }
      return true;
    },
    [db.meta.product_groups, db.products]
  );

  const handleSave = () => {
    const promKey = selectedLineKey.split("-").slice(0, 2).join("-");
    const customerName = db.customers[invoiceLine.customerKey].name;
    const productName =
      db.products[invoiceLine.productKey ?? ""]?.name ?? "Unknown";
    if (selectedLineKey.length > 0) {
      const matchChanged =
        selectedLineKey !== (invoiceLine.matchedPromLine || null);
      InvoiceProcessingService.saveInvoicePromotionMatch(
        invoiceKey,
        invoiceLineKey,
        promKey,
        selectedLineKey,
        () => {
          if (matchChanged) {
            addEvent({
              type: DRMEventType.INVOICE_LINE_PROM_LINE_MANUAL_MATCH_ADDED,
              invoiceLineKey,
              invoiceKey
            });
          }
          openClose.closeAppModal();
          enqueueSnackbar("Updated Invoice", {
            variant: "default",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center"
            }
          });
        },
        () => {
          openClose.closeAppModal();
          enqueueSnackbar("Unknown error occurred updating invoice", {
            variant: "default",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center"
            }
          });
        },
        invoiceLine.customerKey,
        customerName,
        invoiceLine.productKey,
        productName
      );
    } else {
      openClose.closeAppModal();
    }
  };

  const handleUnmatch = () => {
    const invoiceLines = invoice.invoiceLines ?? {};
    const invoiceLineObj = invoiceLines[invoiceLineKey] ?? {};
    const matchedProm = invoiceLineObj.matchedProm || null;
    const matchedPromLine = invoiceLineObj.matchedPromLine || null;

    InvoiceProcessingService.resetSinglePromotionInvoiceMatches(
      invoiceKey,
      invoiceLineKey,
      matchedProm,
      matchedPromLine,
      () => {
        addEvent({
          type: DRMEventType.INVOICE_LINE_PROM_LINE_UNMATCHED,
          invoiceLineKey,
          invoiceKey
        });
        openClose.closeAppModal();
        enqueueSnackbar("Unmatched Invoice", {
          variant: "default",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center"
          }
        });
      },
      () => {
        openClose.closeAppModal();
        enqueueSnackbar("Unknown error occurred while unmatching invoice", {
          variant: "default",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center"
          }
        });
      }
    );
  };

  const getFilteredPromotionsTableJSX = (
    searchParams,
    selectedLineKey,
    setSelectedLineKey
  ) => {
    const lines = db.allLines;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const openLines: Record<string, any> = {};
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Object.entries(lines).forEach(([key, lineObj]: [string, any]) => {
      if ("closed" in lineObj && !lineObj.closed) {
        openLines[key] = lineObj;
      }
    });

    let results = 0;
    const data = {
      Select: [],
      "Promotion Title": [],
      "Line ID": [],
      Month: [],
      "Customer Name": [],
      "Product Group": [],
      "View Details": []
    };
    for (const line of Object.values(openLines)) {
      if (satisfiesSearch(line, searchParams)) {
        results += 1;
        const {
          promKey,
          lineKey,
          promName,
          month,
          year,
          customer,
          productGroup
        } = line;
        if (
          db.customers[customer] &&
          (productGroup === "custom" || db.meta.product_groups[productGroup])
        ) {
          data.Select.push(
            <RadioButton
              checked={lineKey === selectedLineKey}
              onChange={setSelectedLineKey.bind(null, lineKey)}
            />
          );
          data["Promotion Title"].push(promName);
          data["Line ID"].push(lineKey);
          data.Month.push(`${months[month - 1]} ${year}`);
          data["Customer Name"].push(db.customers[customer].name);
          data["Product Group"].push(
            productGroup === "custom"
              ? line.product
                  .map(productKey => db.products[productKey]?.name ?? "Unknown")
                  .join(", ")
              : db.meta.product_groups[productGroup]?.name ?? "Unknown"
          );
          data["View Details"].push(
            <IconButton
              onClick={() =>
                openClose.showRightDrawer(
                  <PromProfile
                    promKey={promKey}
                    defaultLine={lineKey}
                    openClose={openClose}
                    db={db}
                  />
                )
              }
              size="large">
              <OpenInNewIcon />
            </IconButton>
          );
        }
      }
    }

    return (
      <DataTableScrollable
        title={`Search Results (Showing ${results} Results)`}
        data={data}
        height={570}
      />
    );
  };
  const tableJSX = getFilteredPromotionsTableJSX(
    searchParams,
    selectedLineKey,
    setSelectedLineKey
  );

  return (
    <div>
      <Subheader style={{ color: "black" }}>Invoice Line Fields</Subheader>
      <div className={classes.amountContainer}>
        <div>
          <Typography className={classNames(classes.info, classes.amount)}>
            {displayMoney(invoiceLine.amount)}
          </Typography>
          <Typography variant="button" color="textSecondary">
            Amount
          </Typography>
        </div>
        <div className={classes.divider}>
          <Divider orientation="vertical" />
        </div>
        <div>
          <Typography className={classNames(classes.info, classes.amount)}>
            {db.customers[invoiceLine.customerKey].name}
          </Typography>
          <Typography variant="button" color="textSecondary">
            Customer
          </Typography>
        </div>
        <div className={classes.divider}>
          <Divider orientation="vertical" />
        </div>
        <div>
          <Typography className={classNames(classes.info, classes.amount)}>
            {db.products[invoiceLine.productKey || ""]?.name || "None"}
          </Typography>
          <Typography variant="button" color="textSecondary">
            Product
          </Typography>
        </div>
      </div>
      <br />
      <div>
        Search available promotions below, then select one to match to the
        invoice line.
      </div>
      <br />
      <div className="rowC">
        <SelectField
          floatingLabelText="Customer"
          value={searchParams.customer}
          onChange={(event, index, value) => {
            setSearchParams({ ...searchParams, customer: value });
          }}
          fullWidth
          style={{ marginRight: 16 }}>
          {itemJSX.customersJSX}
        </SelectField>
        <SelectField
          floatingLabelText="Product"
          value={searchParams.product}
          onChange={(event, index, value) => {
            setSearchParams({ ...searchParams, product: value });
          }}
          fullWidth
          style={{ marginRight: 16 }}>
          {itemJSX.productsJSX}
        </SelectField>
      </div>
      <br />
      {tableJSX}
      <br />
      <Stack>
        <Button label="Save" variant="text" onClick={() => handleSave()} />
        <Button
          label="Unmatch"
          color="error"
          variant="text"
          onClick={() => handleUnmatch()}
        />
      </Stack>
    </div>
  );
}
