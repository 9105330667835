import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Close from "@mui/icons-material/Close";
import makeStyles from "@mui/styles/makeStyles";
import { DbContextValues } from "contexts/Db";
import { yellow } from "@mui/material/colors";
import CustomerAvatar from "./HeaderMenuIcons/CustomerAvatar";
import UniversityIcon from "./HeaderMenuIcons/UniversityIcon";
import GiftIcon from "./HeaderMenuIcons/GiftIcon";
import BellIcon from "./HeaderMenuIcons/BellIcon";

const yellow100 = yellow["100"];

const useStyles = makeStyles({
  logo: {
    maxWidth: 160
  },
  internetUnstablePadding: {
    position: "absolute",
    left: "50%",
    color: "black"
  },
  internetUnstableBox: {
    position: "relative",
    left: "-50%",
    backgroundColor: yellow100,
    padding: "0.5em"
  }
});

type HeaderProps = {
  toggleSideBar: () => void;
  sideBar: boolean;
  openClose: any;
  db: DbContextValues;
  handleTooltipClose: (itemName?: string) => void;
  internetUnstable: boolean;
};

export default function Header({
  toggleSideBar,
  sideBar,
  openClose,
  db,
  handleTooltipClose,
  internetUnstable
}: HeaderProps) {
  const classes = useStyles();

  return (
    <AppBar position="fixed" elevation={0}>
      <Toolbar
        sx={{
          backgroundColor: "#ffffff",
          borderBottom: "4px solid",
          borderImage: theme => `${theme.palette.vividlyGradients.gradient1} 1`,
          paddingLeft: "28px !important",
          color: "black"
        }}>
        <Stack justifyContent="space-between" sx={{ width: "100%" }}>
          <div>
            <IconButton
              aria-label="open drawer"
              onClick={() => {
                toggleSideBar();
                handleTooltipClose();
              }}
              edge="start"
              size="large"
              sx={{
                color: "black",
                mr: 4,
                ":focus": {
                  outline: "none !important"
                }
              }}>
              {sideBar ? <Close /> : <MenuIcon />}
            </IconButton>
            <img src="/vividly_logo.svg" alt="logo" className={classes.logo} />
          </div>

          {internetUnstable && (
            <div className={classes.internetUnstablePadding}>
              <div className={classes.internetUnstableBox}>
                Your Internet connection is unstable.
              </div>
            </div>
          )}

          {/* -------HEADER MENU ICONS------- */}

          <Stack spacing={2}>
            <UniversityIcon />
            <GiftIcon />
            <BellIcon db={db} openClose={openClose} />
            <CustomerAvatar
              handleLogout={openClose.showSnack.bind(
                null,
                "You have successfully logged out."
              )}
            />
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
