import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import MuiTableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

// Transposes a 2D array
function transpose(array: any[][]) {
  if (!array?.length) {
    return [];
  }
  const [row] = array;
  return row.map((value, column) => array.map(row => row[column]));
}

const styles = {
  toolbar: {
    backgroundColor: "#f5f5f5",
    padding: "0px 10px 0px"
  },
  title: {
    fontSize: 14,
    width: "100%"
  },
  tableHeader: {
    borderBottom: "2px solid rgb(168, 168, 168, 1)"
  },
  tableCell: {
    whiteSpace: "normal",
    wordBreak: "break-word"
  }
};

const TableCell = styled(MuiTableCell)(() => ({
  minHeight: "40px",
  padding: "10px"
}));

type CellData = JSX.Element | string | number;
type TTable = { [key: string]: CellData[][] | CellData[] };

interface IProps {
  title: string | JSX.Element;
  data: TTable;
  order?: string[];
  widths?: string[];
  headless?: boolean;
  height?: number;
  sortBy?: number;
}

interface IState {
  columns: string[];
  rows: CellData[][];
}

class DataTable extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      columns: [],
      rows: []
    };
  }

  updateTable = (data: TTable, order: string[]) => {
    let rows = [];
    if (order && data) {
      const orderedData = [];
      order.forEach(header => {
        orderedData.push(data[header] ?? []);
      });
      rows = transpose(orderedData);
    } else {
      rows = transpose(Object.values(data ?? []));
    }

    this.setState({
      columns: order ?? Object.keys(data ?? []),
      rows
    });
  };

  getHeadersJSX = (headers: string[]) => {
    const rowHeaders = [];
    for (let i = 0; i < headers.length; i++) {
      rowHeaders.push(
        <TableCell style={{ width: this.props?.widths?.[i] }}>
          <b>{headers[i]}</b>
        </TableCell>
      );
    }
    return rowHeaders;
  };

  componentDidMount() {
    this.updateTable(this.props.data, this.props.order);
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps !== this.props) {
      this.updateTable(this.props.data, this.props.order);
    }
  }

  render() {
    const {
      state: { columns: headers, rows },
      props: { widths, sortBy: sortIdx }
    } = this;
    const sortedRows = rows?.sort((a, b) =>
      (a?.[sortIdx] as string)?.localeCompare(b?.[sortIdx] as string)
    );
    const rowHeaders = this.getHeadersJSX(headers);

    return (
      <>
        <Paper variant="outlined" sx={{ width: "100%" }}>
          {!this.props.headless && (
            <Toolbar sx={styles.toolbar} variant="regular">
              <Typography sx={styles.title} component="div">
                {this.props.title}
              </Typography>
            </Toolbar>
          )}
          <TableContainer
            sx={{ maxHeight: this.props?.height, overflowX: "hidden" }}>
            <Table>
              <TableHead>
                <TableRow sx={styles.tableHeader}>{rowHeaders}</TableRow>
              </TableHead>
              <TableBody>
                {sortedRows.map(row => (
                  <TableRow>
                    {row.map((cell, index) => (
                      <TableCell
                        sx={styles.tableCell}
                        align="left"
                        width={widths?.length > index ? widths[index] : ""}>
                        {cell}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <br />
      </>
    );
  }
}

export default DataTable;
