import React, { useEffect, useCallback } from "react";

import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import { makeStyles } from "@mui/styles";

import CircularProgress from "@mui/material/CircularProgress";

import {
  invoiceUploadSources,
  NULL_INVOICE_FIELD_VALUE
} from "deductions-constants/ReconciliationTypes";
import * as reconciliationTypes from "components/Deductions/models";
import {
  addFirebase,
  firebase,
  uploadFilesToStorage,
  isUserCresicorEmployee
} from "helpers/Firebase";
import { useDb } from "contexts/Db";
import { DRMEventType } from "components/Deductions/DeductionsReconciliation/ActivityLog/DRMEvent";
import { getBackupFilePath } from "components/Deductions/helpers/FileStorage";
import { UploadBackupState, InputInvoiceObject } from "../redux/definitions";
import { changeUploadStep } from "../redux/actionCreators";
import { convertArrayToObject, createNewKeysForArray } from "../redux/helpers";

const useStyles = makeStyles(() => ({
  hFlexContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "90%",
    height: "90%",
    alignSelf: "center",
    padding: "1%"
  }
}));

export default function UploadInProgress() {
  const classes = useStyles();
  const db = useDb();
  const dispatch = useDispatch();

  const submitBackupUpload = useCallback(
    (state: UploadBackupState) => {
      const { newInvoices, newFiles } = state;
      const { invoices } = db;

      const user = firebase.auth().currentUser;
      const { uid } = user;
      const now = new Date().toISOString();

      function saveInvoicesToDatabase(createdFileBatchKey: string) {
        // create custom keys
        const invoiceKeysToSave = createNewKeysForArray(newInvoices, invoices);

        // fill out remaining details
        const invoicesToSave = newInvoices.map(
          (
            inputInvoiceObj: InputInvoiceObject,
            invoiceIdx: number
          ): reconciliationTypes.InvoiceObject => {
            const modifiedInvoiceObj = {
              ...{
                ...inputInvoiceObj,
                displayAmount: null, // get rid of displayAmount
                suggestedPromTypes: inputInvoiceObj.suggestedPromTypes ?? {} // try to squash
              },
              key: invoiceKeysToSave[invoiceIdx],
              originalFile: {
                ...inputInvoiceObj.originalFile,
                fileBatch: createdFileBatchKey
              },
              createdDate: now,
              createdUser: uid,
              startDate: inputInvoiceObj.startDate || inputInvoiceObj.endDate,
              needsCresicorApproval: isUserCresicorEmployee(),
              uploadSource: invoiceUploadSources.MANUAL // TODO: adjust this to be WEB_SCRAPER when appropriate
            };
            Object.keys(modifiedInvoiceObj).forEach(key => {
              if (modifiedInvoiceObj[key] === NULL_INVOICE_FIELD_VALUE)
                delete modifiedInvoiceObj[key];
            });
            return modifiedInvoiceObj;
          }
        );

        addFirebase(
          26,
          invoicesToSave,
          _createdInvoiceKeys => {
            Promise.all(
              invoicesToSave.map(inv => {
                // Success path!
                db.drmEventService.addEvent({
                  type: DRMEventType.INVOICE_CREATED,
                  invoiceKey: inv.key
                });
                dispatch(changeUploadStep(true, db));
              })
            );
            return true;
          },
          invoiceKeysToSave,
          error => {
            dispatch(changeUploadStep(false, db));
          }
        );
      }

      function saveFilesToStorage(createdFileBatchKey: string) {
        const filePaths = newFiles.map(file =>
          getBackupFilePath(db.companyid, createdFileBatchKey, file.name)
        );

        uploadFilesToStorage(
          filePaths,
          newFiles,
          () => {
            return saveInvoicesToDatabase(createdFileBatchKey);
          },
          null,
          error => {
            console.log("Error in saving files to storage: ", error);
            dispatch(changeUploadStep(false, db));
          }
        );
      }

      function saveFileBatchToDatabase() {
        // convert files into a FileBatchObject, save to DB and file storage
        const newFileBatchObject: reconciliationTypes.FileBatchObject = {
          key: null,
          files: convertArrayToObject(newFiles.map(file => file.name)),
          createdDate: now,
          createdUser: uid
        };

        addFirebase(
          28,
          newFileBatchObject,
          (createdFileBatchKey: string) => {
            return saveFilesToStorage(createdFileBatchKey);
          },
          null,
          error => {
            console.log("Error in saving file batch to DB: ", error);
            dispatch(changeUploadStep(false, db));
          }
        );
      }

      saveFileBatchToDatabase();
    },
    [db, dispatch]
  );

  const uploadBackupState = useSelector(
    (state: RootState) => state.uploadBackup
  );

  useEffect(() => {
    submitBackupUpload(uploadBackupState);
  }, [uploadBackupState]);

  return (
    <div>
      <CircularProgress />
      Upload in progress...
    </div>
  );
}
