/* eslint-disable no-param-reassign */
import React from "react";
import ReactTable from "react-table-v6";
import { parse, format } from "date-fns";
import { round } from "helpers/DataProcessing";
import {
  CustomerID,
  FigureType,
  DiffType,
  PivotType,
  ComparisonType,
  TimeScaleType,
  ActualRevenueItem,
  ActualTradeSpendItem,
  BudgetItem,
  DataEntry,
  DataEntryDict,
  Line,
  NonDatePivotType,
  ProductID,
  ProductGroupID,
  DataSourceType,
  AccountingSource,
  FirebaseDB,
  DBSlice,
  ContactID,
  BudgetTableRecord,
  PivotGroupType,
  PivotedBudgetTableRecord,
  BudgetTableSubRecord,
  PivotedTradeRate,
  ComparisonFigures,
  DatePivotData,
  OrganisedData,
  BroadFigureType,
  SubFigureType,
  TableColumn,
  PivotGroupMarker,
  DiffTableRecord,
  DiffTableData,
  DiffTableTimeData
} from "components/graphs/BudgetTool";
import { Customer, ProductGroup } from "js/dbTypes";
import {
  REVENUE,
  TRADE_SPEND,
  TRADE_RATE,
  BUDGET,
  ACTUAL,
  ESTIMATES,
  broadFigureTypes,
  pivotTypes,
  diffTypes,
  comparisonTypes,
  dataSources,
  isWildcard,
  thresholdForPivotCharts,
  aberrantPivotGroupTitles,
  Month,
  Quarter
} from "./constants";
import {
  makeTableSubLegend,
  getColumns,
  getTimeData,
  getTimePeriodFromMonth
} from "./tableHelpers";
import { sanitizeSplit } from "./dataRenderers";

const differenceForFigureType = (
  data: BudgetTableRecord,
  figureType: FigureType,
  comparisonType: ComparisonType
): ComparisonFigures => {
  if (broadFigureTypes.includes(figureType as BroadFigureType)) {
    const { valueToCompare, valueToCompareAgainst } =
      comparisonTypes[comparisonType];
    const {
      [valueToCompare]: targetValues,
      [valueToCompareAgainst]: baseValues
    } = data[figureType as BroadFigureType];
    const { absoluteComparison, relativeComparison } = Object.entries(
      baseValues
    ).reduce(
      (acc, [timePeriod, baseFigure]) => {
        const targetFigure = targetValues[timePeriod];
        const difference = targetFigure - baseFigure;
        return {
          absoluteComparison: {
            ...acc.absoluteComparison,
            [timePeriod]: difference
          },
          relativeComparison: {
            ...acc.relativeComparison,
            [timePeriod]: (difference / baseFigure) * 100
          }
        };
      },
      {
        absoluteComparison: {},
        relativeComparison: {}
      } as ComparisonFigures
    );
    return {
      absoluteComparison,
      relativeComparison
    };
  }

  return {} as ComparisonFigures;
};

const checkPivotField = (db: DBSlice) => ({
  [pivotTypes.CUSTOMER]: (customerId: CustomerID) => {
    const record: Customer = db[pivotTypes.CUSTOMER]?.[customerId];
    return record && (record?.isDistributor || record?.isDirect);
  },
  [pivotTypes.PRODUCTS]: (productGroupId: ProductGroupID) => {
    const record: ProductGroup = db[pivotTypes.PRODUCTS]?.[productGroupId];
    return record && record?.isPricing;
  },
  [pivotTypes.CONTACTS]: (contactId: ContactID) =>
    db[pivotTypes.CONTACTS]?.[contactId]
});

const organiseDataPerPivot = ({
  rawData,
  figureType,
  timeScale,
  comparisonType,
  pivot,
  db,
  combineEstimatesAndActuals
}: {
  rawData: DataEntry[];
  figureType: FigureType;
  timeScale: TimeScaleType;
  comparisonType: ComparisonType;
  pivot: PivotType;
  db: DBSlice;
  combineEstimatesAndActuals: boolean;
}): OrganisedData => {
  if (!rawData.length) {
    return {};
  }
  // Checks each pivot entry for inclusion
  const pivotFieldDbCheck = checkPivotField(db);
  // Makes an array of all unique customer IDs if customer is selected, and so on
  const pivotGroups =
    pivot === pivotTypes.DATE
      ? []
      : [
          ...new Set(
            rawData.reduce(
              (acc, { [pivot as NonDatePivotType]: pivotFields }) => {
                const sanitizedPivotFields = Array.isArray(pivotFields)
                  ? pivotFields
                  : [pivotFields];
                if (
                  pivot === pivotTypes.CONTACTS &&
                  !sanitizedPivotFields.length &&
                  !acc.includes(aberrantPivotGroupTitles[pivot])
                ) {
                  // Add "No Contacts Associated" if no contacts
                  return [...acc, aberrantPivotGroupTitles[pivot]];
                }
                if (
                  pivot === pivotTypes.PRODUCTS &&
                  !acc.includes(aberrantPivotGroupTitles[pivot])
                ) {
                  // Add "Non-pricing PGs" if record has some non-pricing product groups
                  const hasNonPricingPGs = sanitizedPivotFields.some(
                    pivotField => !pivotFieldDbCheck[pivot](pivotField)
                  );

                  if (hasNonPricingPGs) {
                    acc.push(aberrantPivotGroupTitles[pivot]);
                  }
                }
                const filteredPivotFields = sanitizedPivotFields.filter(
                  pivotFieldDbCheck[pivot]
                );
                return [...acc, ...filteredPivotFields];
              },
              [] as PivotGroupType[]
            )
          )
        ];
  let initialData: BudgetTableRecord | PivotedBudgetTableRecord;
  const timeData: Month[] | Quarter[] = getTimeData(timeScale);
  if (pivot === pivotTypes.DATE) {
    // For pivoting on date, initial data for the reduce method will have fields for data sub types only
    initialData = timeData.reduce(
      (acc, timePeriod) => {
        acc[REVENUE][BUDGET][timePeriod] = 0;
        acc[REVENUE][ESTIMATES][timePeriod] = 0;
        acc[REVENUE][ACTUAL][timePeriod] = 0;
        acc[TRADE_SPEND][BUDGET][timePeriod] = 0;
        acc[TRADE_SPEND][ESTIMATES][timePeriod] = 0;
        acc[TRADE_SPEND][ACTUAL][timePeriod] = 0;

        return acc;
      },
      {
        [REVENUE]: {
          [BUDGET]: {},
          [ESTIMATES]: {},
          [ACTUAL]: {}
        },
        [TRADE_SPEND]: {
          [BUDGET]: {},
          [ESTIMATES]: {},
          [ACTUAL]: {}
        }
      } as BudgetTableRecord
    );
  } else {
    // For pivoting on everything except date, initial data for the reduce method
    // will have data sub types grouped by the pivot group entry (IDs)
    initialData = timeData.reduce(
      (acc, timePeriod) => {
        pivotGroups.forEach(pivotGroup => {
          acc[pivotGroup][REVENUE][BUDGET][timePeriod] = 0;
          acc[pivotGroup][REVENUE][ESTIMATES][timePeriod] = 0;
          acc[pivotGroup][REVENUE][ACTUAL][timePeriod] = 0;
          acc[pivotGroup][TRADE_SPEND][BUDGET][timePeriod] = 0;
          acc[pivotGroup][TRADE_SPEND][ESTIMATES][timePeriod] = 0;
          acc[pivotGroup][TRADE_SPEND][ACTUAL][timePeriod] = 0;
        });

        return acc;
      },
      pivotGroups.reduce(
        (acc, pivotGroup) => ({
          ...acc,
          [pivotGroup]: {
            [REVENUE]: {
              [BUDGET]: {},
              [ESTIMATES]: {},
              [ACTUAL]: {}
            },
            [TRADE_SPEND]: {
              [BUDGET]: {},
              [ESTIMATES]: {},
              [ACTUAL]: {}
            }
          }
        }),
        {}
      ) as PivotedBudgetTableRecord
    );
  }
  // Sum up revenue and trade spend for each month
  const data = rawData.reduce((acc, record) => {
    const {
      date,
      [pivot as NonDatePivotType]: pivotFields,
      actualRevenue: currentActualRevenue = 0,
      budgetedRevenue: currentBudgetedRevenue = 0,
      estimatedRevenue: currentEstimatedRevenue = 0,
      actualTradeSpend: currentActualTradeSpend = 0,
      budgetedTradeSpend: currentBudgetedTradeSpend = 0,
      estimatedTradeSpend: currentEstimatedTradeSpend = 0,
      promotionClosed = false
    } = record;
    const monthIndex = date.getMonth();

    const timePeriod: Month | Quarter = getTimePeriodFromMonth(
      monthIndex,
      timeScale
    );

    if (pivot === pivotTypes.DATE) {
      const {
        [REVENUE]: {
          [BUDGET]: { [timePeriod]: budgetedRevenue },
          [ESTIMATES]: { [timePeriod]: estimatedRevenue },
          [ACTUAL]: { [timePeriod]: actualRevenue }
        },
        [TRADE_SPEND]: {
          [BUDGET]: { [timePeriod]: budgetedTradeSpend },
          [ESTIMATES]: { [timePeriod]: estimatedTradeSpend },
          [ACTUAL]: { [timePeriod]: actualTradeSpend }
        }
      } = acc as BudgetTableRecord;

      acc[REVENUE][BUDGET][timePeriod] =
        budgetedRevenue + currentBudgetedRevenue;
      acc[REVENUE][ESTIMATES][timePeriod] =
        estimatedRevenue + currentEstimatedRevenue;
      acc[REVENUE][ACTUAL][timePeriod] = actualRevenue + currentActualRevenue;
      acc[TRADE_SPEND][BUDGET][timePeriod] =
        budgetedTradeSpend + currentBudgetedTradeSpend;
      if (combineEstimatesAndActuals) {
        if (promotionClosed) {
          // Include trade spend actual in trade spend expected, accounting for date source
          acc[TRADE_SPEND][ESTIMATES][timePeriod] =
            estimatedTradeSpend + currentActualTradeSpend;
        } else {
          // Include higher of trade spend actual and expected in trade spend expected, accounting for date source
          const figureToInclude = Math.max(
            currentActualTradeSpend,
            currentEstimatedTradeSpend
          );
          acc[TRADE_SPEND][ESTIMATES][timePeriod] =
            estimatedTradeSpend + figureToInclude;
        }
      } else {
        acc[TRADE_SPEND][ESTIMATES][timePeriod] =
          estimatedTradeSpend + currentEstimatedTradeSpend;
      }
      acc[TRADE_SPEND][ACTUAL][timePeriod] =
        actualTradeSpend + currentActualTradeSpend;
    } else {
      let sanitizedPivotFields = Array.isArray(pivotFields)
        ? pivotFields
        : [pivotFields];

      if (pivot === pivotTypes.CONTACTS && !sanitizedPivotFields.length) {
        sanitizedPivotFields = [aberrantPivotGroupTitles[pivot]];
      }
      if (pivot === pivotTypes.PRODUCTS && sanitizedPivotFields.length) {
        const pricingSanitizedPGs = sanitizedPivotFields.filter(
          pivotFieldDbCheck[pivot]
        );
        sanitizedPivotFields = pricingSanitizedPGs.length
          ? pricingSanitizedPGs
          : [aberrantPivotGroupTitles[pivot]];
      }

      sanitizedPivotFields
        .filter(pivotField => pivotGroups.includes(pivotField))
        .forEach(pivotField => {
          const {
            [REVENUE]: {
              [BUDGET]: { [timePeriod]: budgetedRevenue },
              [ESTIMATES]: { [timePeriod]: estimatedRevenue },
              [ACTUAL]: { [timePeriod]: actualRevenue }
            },
            [TRADE_SPEND]: {
              [BUDGET]: { [timePeriod]: budgetedTradeSpend },
              [ESTIMATES]: { [timePeriod]: estimatedTradeSpend },
              [ACTUAL]: { [timePeriod]: actualTradeSpend }
            }
          } = (acc as PivotedBudgetTableRecord)[pivotField];

          acc[pivotField][REVENUE][BUDGET][timePeriod] =
            budgetedRevenue + currentBudgetedRevenue;
          acc[pivotField][REVENUE][ESTIMATES][timePeriod] =
            estimatedRevenue + currentEstimatedRevenue;
          acc[pivotField][REVENUE][ACTUAL][timePeriod] =
            actualRevenue + currentActualRevenue;
          acc[pivotField][TRADE_SPEND][BUDGET][timePeriod] =
            budgetedTradeSpend + currentBudgetedTradeSpend;
          if (combineEstimatesAndActuals) {
            if (promotionClosed) {
              // Include trade spend actual in trade spend expected, accounting for date source
              acc[pivotField][TRADE_SPEND][ESTIMATES][timePeriod] =
                estimatedTradeSpend + currentActualTradeSpend;
            } else {
              // Include higher of trade spend actual and expected in trade spend expected, accounting for date source
              const figureToInclude = Math.max(
                currentActualTradeSpend,
                currentEstimatedTradeSpend
              );
              acc[pivotField][TRADE_SPEND][ESTIMATES][timePeriod] =
                estimatedTradeSpend + figureToInclude;
            }
          } else {
            acc[pivotField][TRADE_SPEND][ESTIMATES][timePeriod] =
              estimatedTradeSpend + currentEstimatedTradeSpend;
          }
          acc[pivotField][TRADE_SPEND][ACTUAL][timePeriod] =
            actualTradeSpend + currentActualTradeSpend;
        });
    }

    return acc;
  }, initialData);

  // If actuals need to included in estimates
  // 1. Replace revenue LE with actual revenue only if actual revenue exists (doing here)
  // 2. Replace trade spend expected with trade spend actual depending on the status of the promotion (done in the step above)
  if (combineEstimatesAndActuals) {
    if (pivot === pivotTypes.DATE) {
      timeData.forEach(timePeriod => {
        const actualRevenue = (data as DatePivotData)[REVENUE][ACTUAL][
          timePeriod
        ];
        if (actualRevenue) {
          data[REVENUE][ESTIMATES][timePeriod] = actualRevenue;
        }
      });
    } else {
      Object.entries(data).forEach(([pivotGroup, pivotGroupData]) => {
        timeData.forEach(timePeriod => {
          const actualRevenue = (pivotGroupData as BudgetTableRecord)[REVENUE][
            ACTUAL
          ][timePeriod];
          if (actualRevenue) {
            data[pivotGroup][REVENUE][ESTIMATES][timePeriod] = actualRevenue;
          }
        });
      });
    }
  }

  // Calculate trade rate from revenue and trade spend figures
  let tradeRate: BudgetTableSubRecord | PivotedTradeRate = {};
  if (pivot === pivotTypes.DATE) {
    tradeRate = getTimeData(timeScale).reduce(
      (acc, timePeriod) => {
        const {
          [BUDGET]: { [timePeriod]: budgetedRevenue },
          [ESTIMATES]: { [timePeriod]: estimatedRevenue },
          [ACTUAL]: { [timePeriod]: actualRevenue }
        } = (data as BudgetTableRecord)[REVENUE];

        const {
          [BUDGET]: { [timePeriod]: budgetedTradeSpend },
          [ESTIMATES]: { [timePeriod]: estimatedTradeSpend },
          [ACTUAL]: { [timePeriod]: actualTradeSpend }
        } = (data as BudgetTableRecord)[TRADE_SPEND];

        acc[BUDGET][timePeriod] = (budgetedTradeSpend / budgetedRevenue) * 100;
        acc[ESTIMATES][timePeriod] =
          (estimatedTradeSpend / estimatedRevenue) * 100;
        acc[ACTUAL][timePeriod] = (actualTradeSpend / actualRevenue) * 100;

        return acc;
      },
      {
        [BUDGET]: {},
        [ESTIMATES]: {},
        [ACTUAL]: {}
      } as BudgetTableSubRecord
    );
  } else {
    tradeRate = getTimeData(timeScale).reduce(
      (acc, timePeriod) => {
        pivotGroups.forEach(pivotGroup => {
          const {
            [BUDGET]: { [timePeriod]: budgetedRevenue },
            [ESTIMATES]: { [timePeriod]: estimatedRevenue },
            [ACTUAL]: { [timePeriod]: actualRevenue }
          } = (data as PivotedBudgetTableRecord)[pivotGroup][REVENUE];

          const {
            [BUDGET]: { [timePeriod]: budgetedTradeSpend },
            [ESTIMATES]: { [timePeriod]: estimatedTradeSpend },
            [ACTUAL]: { [timePeriod]: actualTradeSpend }
          } = (data as PivotedBudgetTableRecord)[pivotGroup][TRADE_SPEND];

          acc[pivotGroup][BUDGET][timePeriod] =
            (budgetedTradeSpend / budgetedRevenue) * 100;
          acc[pivotGroup][ESTIMATES][timePeriod] =
            (estimatedTradeSpend / estimatedRevenue) * 100;
          acc[pivotGroup][ACTUAL][timePeriod] =
            (actualTradeSpend / actualRevenue) * 100;
        });

        return acc;
      },
      pivotGroups.reduce(
        (acc, pivotGroup) => ({
          ...acc,
          [pivotGroup]: {
            [BUDGET]: {},
            [ESTIMATES]: {},
            [ACTUAL]: {}
          }
        }),
        {}
      ) as PivotedTradeRate
    );
  }

  // Calculate totals for each sub field, along with total trade rate
  const calculateTotal = (dataType: BudgetTableSubRecord) => {
    Object.entries(dataType).forEach(([key, figures]) => {
      const total = Object.values(figures).reduce((sum, value) => sum + value);
      dataType[key as SubFigureType].total = total;
    });
  };
  if (pivot === pivotTypes.DATE) {
    Object.values(data as BudgetTableRecord).forEach(calculateTotal);
    const { [TRADE_SPEND]: tradeSpend, [REVENUE]: revenue } =
      data as BudgetTableRecord;
    Object.keys(tradeRate).forEach(key => {
      (tradeRate as BudgetTableSubRecord)[key as SubFigureType].total =
        (tradeSpend[key as SubFigureType].total /
          revenue[key as SubFigureType].total) *
        100;
    });
  } else {
    pivotGroups.forEach(pivotGroup => {
      const pivotGroupData = (data as PivotedBudgetTableRecord)[pivotGroup];
      Object.values(pivotGroupData).forEach(calculateTotal);
      const {
        [pivotGroup]: { [TRADE_SPEND]: tradeSpend, [REVENUE]: revenue }
      } = data as PivotedBudgetTableRecord;
      Object.keys((tradeRate as PivotedTradeRate)[pivotGroup]).forEach(key => {
        (tradeRate as PivotedTradeRate)[pivotGroup][
          key as SubFigureType
        ].total =
          (tradeSpend[key as SubFigureType].total /
            revenue[key as SubFigureType].total) *
          100;
      });
    });
  }

  let allData: BudgetTableRecord | PivotedBudgetTableRecord;
  if (pivot === pivotTypes.DATE) {
    allData = {
      ...data,
      [TRADE_RATE]: tradeRate as BudgetTableSubRecord
    } as BudgetTableRecord;
  } else {
    Object.entries(data as PivotedBudgetTableRecord).forEach(
      ([pivotGroup, pivotGroupData]) => {
        pivotGroupData[TRADE_RATE] = (tradeRate as PivotedTradeRate)[
          pivotGroup
        ];
      }
    );
    allData = data as PivotedBudgetTableRecord;
  }

  const { absoluteComparison = {}, relativeComparison = {} } =
    pivot === pivotTypes.DATE
      ? differenceForFigureType(
          allData as BudgetTableRecord,
          figureType,
          comparisonType
        )
      : {};

  if (pivot === pivotTypes.DATE) {
    return {
      ...allData,
      absoluteComparison,
      relativeComparison
    } as DatePivotData;
  }

  return allData as PivotedBudgetTableRecord;
};

const groupDataForTable = ({
  data,
  figureType,
  comparisonType,
  pivot,
  db,
  dataSubTypesForPivot: [typeA, typeB],
  rowDataType
}: {
  data: OrganisedData;
  figureType: BroadFigureType;
  comparisonType: ComparisonType;
  pivot: PivotType;
  db: DBSlice;
  dataSubTypesForPivot: [SubFigureType, BroadFigureType];
  rowDataType: DiffType;
}): TableColumn[] => {
  if (!Object.entries(data).length) {
    return [];
  }
  if (pivot === pivotTypes.DATE) {
    const {
      [REVENUE]: revenue,
      [TRADE_RATE]: tradeRate,
      [TRADE_SPEND]: tradeSpend,
      absoluteComparison,
      relativeComparison
    } = data as DatePivotData;
    const allTableData: TableColumn[] = [
      {
        tableSubLegend: makeTableSubLegend(REVENUE, BUDGET),
        types: [BUDGET, REVENUE],
        isMoney: true,
        ...revenue[BUDGET]
      },
      {
        tableLegend: "Revenue",
        tableSubLegend: makeTableSubLegend(REVENUE, ESTIMATES),
        types: [ESTIMATES, REVENUE],
        isMoney: true,
        ...revenue[ESTIMATES]
      },
      {
        tableSubLegend: makeTableSubLegend(REVENUE, ACTUAL),
        types: [ACTUAL, REVENUE],
        isMoney: true,
        ...revenue[ACTUAL]
      },
      {
        tableSubLegend: makeTableSubLegend(TRADE_RATE, BUDGET),
        types: [BUDGET, TRADE_RATE],
        isPercentage: true,
        ...tradeRate[BUDGET]
      },
      {
        tableLegend: "Trade Rate",
        tableSubLegend: makeTableSubLegend(TRADE_RATE, ESTIMATES),
        types: [ESTIMATES, TRADE_RATE],
        isPercentage: true,
        ...tradeRate[ESTIMATES]
      },
      {
        tableSubLegend: makeTableSubLegend(TRADE_RATE, ACTUAL),
        types: [ACTUAL, TRADE_RATE],
        isPercentage: true,
        ...tradeRate[ACTUAL]
      },
      {
        tableSubLegend: makeTableSubLegend(TRADE_SPEND, BUDGET),
        types: [BUDGET, TRADE_SPEND],
        isMoney: true,
        ...tradeSpend[BUDGET]
      },
      {
        tableLegend: "Trade Spend",
        tableSubLegend: makeTableSubLegend(TRADE_SPEND, ESTIMATES),
        types: [ESTIMATES, TRADE_SPEND],
        isMoney: true,
        ...tradeSpend[ESTIMATES]
      },
      {
        tableSubLegend: makeTableSubLegend(TRADE_SPEND, ACTUAL),
        types: [ACTUAL, TRADE_SPEND],
        isMoney: true,
        ...tradeSpend[ACTUAL]
      }
    ];

    if (broadFigureTypes.includes(figureType)) {
      const { title } = comparisonTypes[comparisonType];
      allTableData.push(
        {
          tableSubLegend: `${title} Net`,
          types: broadFigureTypes,
          isMoney: [REVENUE, TRADE_SPEND].includes(figureType),
          isPercentage: figureType === TRADE_RATE,
          ...absoluteComparison
        },
        {
          tableSubLegend: `${title} %`,
          types: broadFigureTypes,
          isPercentage: true,
          ...relativeComparison
        }
      );
    }

    return isWildcard(figureType)
      ? allTableData
      : allTableData.filter(dataSet => dataSet.types?.includes(figureType));
  }
  const isMoney = broadFigureTypes.includes(figureType)
    ? rowDataType !== diffTypes.Relative &&
      [REVENUE, TRADE_SPEND].includes(typeB)
    : [REVENUE, TRADE_SPEND].includes(typeB);
  const isPercentage =
    rowDataType === diffTypes.Relative || typeB === TRADE_RATE;

  return (
    Object.entries(data as PivotedBudgetTableRecord)
      // Some pivot groups will have no data
      .filter(([_pivotGroup, groupedData]) => Object.keys(groupedData).length)
      // Add pivot group name to ID
      .map<[PivotGroupMarker, BudgetTableRecord]>(
        ([pivotGroup, groupedData]) => {
          if (Object.values(aberrantPivotGroupTitles).includes(pivotGroup)) {
            return [{ id: pivotGroup, name: pivotGroup }, groupedData];
          }
          return [
            { id: pivotGroup, name: db[pivot]?.[pivotGroup]?.name },
            groupedData
          ];
        }
      )
      // Sort by pivot group name
      .sort(([{ name: pivotGroupNameA }], [{ name: pivotGroupNameB }]) =>
        pivotGroupNameA < pivotGroupNameB ? -1 : 1
      )
      .reduce(
        (acc, [{ id: pivotGroupId, name: pivotGroupName }, groupedData]) => {
          const figures = groupedData[typeB][typeA];
          // If total is a finite value more than zero, display in the table
          if (figures?.total && Number.isFinite(figures.total)) {
            // Use figures for sub types if Diff is not selected in the row data dropdown
            if (
              rowDataType === diffTypes.None ||
              !broadFigureTypes.includes(figureType)
            ) {
              return [
                ...acc,
                {
                  tableSubLegend: pivotGroupName,
                  id: pivotGroupId,
                  isMoney,
                  isPercentage,
                  ...figures
                }
              ];
            }
            // Use Diff figures per row data dropdown
            const { absoluteComparison, relativeComparison } =
              differenceForFigureType(groupedData, figureType, comparisonType);
            const rowFigures =
              rowDataType === diffTypes.Absolute
                ? absoluteComparison
                : relativeComparison;
            return [
              ...acc,
              {
                tableSubLegend: pivotGroupName,
                id: pivotGroupId,
                isMoney,
                isPercentage,
                ...rowFigures
              }
            ];
          }
          return acc;
        },
        [] as TableColumn[]
      )
  );
};

const renderInnerRow = (
  data: OrganisedData,
  pivot: PivotType,
  figureType: FigureType,
  comparisonType: ComparisonType,
  timeScale: TimeScaleType
) =>
  pivot !== pivotTypes.DATE && Object.entries(data).length
    ? ({ original: { id: pivotGroupId } }) => {
        const { [pivotGroupId]: pivotGroupData } =
          data as PivotedBudgetTableRecord;
        if (pivotGroupData) {
          let subTableData: TableColumn[] = [];
          if (broadFigureTypes.includes(figureType as BroadFigureType)) {
            const subData = pivotGroupData[figureType as BroadFigureType];
            subTableData = Object.entries(subData).map(
              ([subDataType, figures]) => ({
                tableSubLegend: makeTableSubLegend(
                  figureType as BroadFigureType,
                  subDataType as SubFigureType
                ),
                isMoney: [REVENUE, TRADE_SPEND].includes(
                  figureType as BroadFigureType
                ),
                isPercentage: figureType === TRADE_RATE,
                ...figures
              })
            );
            const { absoluteComparison, relativeComparison } =
              differenceForFigureType(
                pivotGroupData,
                figureType,
                comparisonType
              );
            const { title } = comparisonTypes[comparisonType];
            subTableData.push(
              {
                tableSubLegend: `${title} Net`,
                isMoney: [REVENUE, TRADE_SPEND].includes(
                  figureType as BroadFigureType
                ),
                isPercentage: figureType === TRADE_RATE,
                ...absoluteComparison
              },
              {
                tableSubLegend: `${title} %`,
                isPercentage: true,
                ...relativeComparison
              }
            );
          } else {
            // Group data by actual, budgeted and estimates
            const subData = Object.entries(pivotGroupData).reduce(
              (
                acc,
                [dataType, { [figureType as SubFigureType]: subTypeData }]
              ) => {
                acc[dataType] = subTypeData;
                return acc;
              },
              {
                [REVENUE]: {},
                [TRADE_RATE]: {},
                [TRADE_SPEND]: {}
              } as BudgetTableSubRecord<BroadFigureType>
            );
            subTableData = Object.entries(subData).map(
              ([subDataType, figures]) => ({
                tableSubLegend: makeTableSubLegend(
                  subDataType as BroadFigureType,
                  figureType as SubFigureType
                ),
                isMoney: [REVENUE, TRADE_SPEND].includes(
                  subDataType as BroadFigureType
                ),
                isPercentage: subDataType === TRADE_RATE,
                ...figures
              })
            );
          }
          return (
            <ReactTable
              showPagination={false}
              minRows={3}
              columns={getColumns(timeScale, false, "Sub-Budget Data")}
              data={subTableData}
            />
          );
        }
        return null;
      }
    : null;

const makeDiffTableData = ({
  data,
  diffType,
  pivot,
  diffThreshold
}: {
  data: OrganisedData;
  diffType: DiffType;
  pivot: PivotType;
  diffThreshold: number;
}): TableColumn[] => {
  if (!Object.entries(data).length) {
    return [];
  }

  if (pivot === pivotTypes.DATE) {
    const {
      [REVENUE]: revenue,
      [TRADE_RATE]: tradeRate,
      [TRADE_SPEND]: tradeSpend
    } = data as DatePivotData;

    const useAbsoluteFigures = diffType === diffTypes.Absolute;
    const {
      [REVENUE]: revenueDiff,
      [TRADE_RATE]: tradeRateDiff,
      [TRADE_SPEND]: tradeSpendDiff
    } = Object.entries({
      [REVENUE]: revenue,
      [TRADE_RATE]: tradeRate,
      [TRADE_SPEND]: tradeSpend
    }).reduce(
      (totalDiff, [figureType, figures]) => {
        const {
          [BUDGET]: budgeted,
          [ESTIMATES]: estimates,
          [ACTUAL]: actual
        } = figures;
        // Calculate actual and estimate differences with budget
        const { budgetVsActual, budgetVsEstimate } = Object.entries(
          budgeted
        ).reduce(
          (acc, [timePeriod, budgetedFigure]) => {
            const actualFigure = actual[timePeriod];
            const estimateFigure = estimates[timePeriod];

            const budgetVsActualAbsolute = actualFigure - budgetedFigure;
            const budgetVsEstimateAbsolute = estimateFigure - budgetedFigure;

            const budgetVsActualRelative =
              (budgetVsActualAbsolute / budgetedFigure) * 100;
            const budgetVsEstimateRelative =
              (budgetVsEstimateAbsolute / budgetedFigure) * 100;

            return {
              budgetVsActual: {
                ...acc.budgetVsActual,
                [timePeriod]: {
                  figure: useAbsoluteFigures
                    ? budgetVsActualAbsolute
                    : budgetVsActualRelative,
                  highlight: Math.abs(budgetVsActualRelative) >= diffThreshold
                }
              },
              budgetVsEstimate: {
                ...acc.budgetVsEstimate,
                [timePeriod]: {
                  figure: useAbsoluteFigures
                    ? budgetVsEstimateAbsolute
                    : budgetVsEstimateRelative,
                  highlight: Math.abs(budgetVsEstimateRelative) >= diffThreshold
                }
              }
            };
          },
          {
            budgetVsActual: {},
            budgetVsEstimate: {}
          } as DiffTableData
        );
        // Calculate difference between estimate and actual figures
        const estimateVsActual: DiffTableTimeData = Object.entries(
          estimates
        ).reduce((acc, [timePeriod, estimateFigure]) => {
          const actualFigure = actual[timePeriod];
          const estimateVsActualAbsolute = actualFigure - estimateFigure;
          const estimateVsActualRelative =
            (estimateVsActualAbsolute / estimateFigure) * 100;

          return {
            ...acc,
            [timePeriod]: {
              figure: useAbsoluteFigures
                ? estimateVsActualAbsolute
                : estimateVsActualRelative,
              highlight: Math.abs(estimateVsActualRelative) >= diffThreshold
            }
          };
        }, {});

        const subDifferences: DiffTableData = {
          budgetVsActual,
          budgetVsEstimate,
          estimateVsActual
        };
        return { ...totalDiff, [figureType]: subDifferences };
      },
      {
        [REVENUE]: {},
        [TRADE_RATE]: {},
        [TRADE_SPEND]: {}
      } as DiffTableRecord
    );

    return [
      {
        tableSubLegend: "LE vs. Budget",
        isMoney: useAbsoluteFigures,
        isPercentage: !useAbsoluteFigures,
        ...revenueDiff.budgetVsEstimate
      },
      {
        tableLegend: "Revenue",
        tableSubLegend: "Actual vs. Budget",
        isMoney: useAbsoluteFigures,
        isPercentage: !useAbsoluteFigures,
        ...revenueDiff.budgetVsActual
      },
      {
        tableSubLegend: "Actual vs. LE",
        isMoney: useAbsoluteFigures,
        isPercentage: !useAbsoluteFigures,
        ...revenueDiff.estimateVsActual
      },
      {
        tableSubLegend: "LE vs. Budget",
        isPercentage: true,
        ...tradeRateDiff.budgetVsEstimate
      },
      {
        tableLegend: "Trade Rate",
        tableSubLegend: "Actual vs. Budget",
        isPercentage: true,
        ...tradeRateDiff.budgetVsActual
      },
      {
        tableSubLegend: "Actual vs. LE",
        isPercentage: true,
        ...tradeRateDiff.estimateVsActual
      },
      {
        tableSubLegend: "LE vs. Budget",
        isMoney: useAbsoluteFigures,
        isPercentage: !useAbsoluteFigures,
        ...tradeSpendDiff.budgetVsEstimate
      },
      {
        tableLegend: "Trade Spend",
        tableSubLegend: "Actual vs. Budget",
        isMoney: useAbsoluteFigures,
        isPercentage: !useAbsoluteFigures,
        ...tradeSpendDiff.budgetVsActual
      },
      {
        tableSubLegend: "Actual vs. LE",
        isMoney: useAbsoluteFigures,
        isPercentage: !useAbsoluteFigures,
        ...tradeSpendDiff.estimateVsActual
      }
    ];
  }
  return [];
};

const makeChartData = ({
  data,
  figureType,
  timeScale,
  comparisonType,
  pivot,
  db,
  dataSubTypesForPivot: [typeA, typeB]
}: {
  data: OrganisedData;
  figureType: FigureType;
  timeScale: TimeScaleType;
  comparisonType: ComparisonType;
  pivot: PivotType;
  db: DBSlice;
  dataSubTypesForPivot: [SubFigureType, BroadFigureType];
}) => {
  if (Object.keys(data).length) {
    if (pivot === pivotTypes.DATE) {
      if (broadFigureTypes.includes(figureType as BroadFigureType)) {
        const {
          [figureType as BroadFigureType]: {
            [BUDGET]: budgetedData,
            [ACTUAL]: actualData,
            [ESTIMATES]: latestEstimateData
          },
          absoluteComparison,
          relativeComparison
        } = data as DatePivotData;
        const { title, valueToCompare, valueToCompareAgainst } =
          comparisonTypes[comparisonType];
        // Data for months/quarters
        const timeSeriesData = getTimeData(timeScale).map(timePeriod => ({
          timePeriod,
          Budget: budgetedData[timePeriod],
          Actual: actualData[timePeriod],
          "Latest Estimate": latestEstimateData[timePeriod],
          [`${title} Net`]: absoluteComparison?.[timePeriod] || 0,
          [`${title} %`]: relativeComparison?.[timePeriod] || 0
        }));
        // Data for total column
        const totalData = {
          [BUDGET]: budgetedData.total,
          [ACTUAL]: actualData.total,
          [ESTIMATES]: latestEstimateData.total
        };
        const titleForValueToCompare = makeTableSubLegend(
          figureType as BroadFigureType,
          valueToCompare
        );
        const titleForValueToCompareAgainst = makeTableSubLegend(
          figureType as BroadFigureType,
          valueToCompareAgainst
        );

        return [
          ...timeSeriesData,
          {
            timePeriod: "total",
            [titleForValueToCompare]: totalData[valueToCompare],
            [titleForValueToCompareAgainst]: totalData[valueToCompareAgainst],
            [`${title} Net`]: absoluteComparison?.total || 0
          }
        ];
      }
    } else {
      // Sort data as per the total for selected sub-types
      const sortedData = Object.entries(data as PivotedBudgetTableRecord)
        .map<[PivotGroupType, number]>(
          ([
            pivotGroup,
            {
              [typeB]: {
                [typeA]: { total: pivotGroupData }
              }
            }
          ]) => [pivotGroup, pivotGroupData]
        )
        .filter(
          ([_pivotGroup, pivotGroupTotal]) =>
            Number.isFinite(pivotGroupTotal) && pivotGroupTotal > 0
        )
        .sort(
          (
            [_pivotGroupA, pivotGroupTotalA],
            [_pivotGroupB, pivotGroupTotalB]
          ) => pivotGroupTotalB - pivotGroupTotalA
        );

      // Calculate total of totals of each selected sub-type
      const totalOfSelectedData = sortedData.reduce(
        (acc, [_pivotGroup, pivotGroupTotal]) => acc + pivotGroupTotal,
        0
      );

      // Group data by name of the pivot group
      // Add entries beyond the threshold to "All Others" group
      // Entries without contacts should go to "No Contact Associated"
      // Entries with non-pricing product groups should go to "Non-pricing PGs"
      const groupedData = sortedData.reduce(
        (acc, [pivotGroup, pivotGroupTotal], currentIndex) => {
          if (Object.values(aberrantPivotGroupTitles).includes(pivotGroup)) {
            return { ...acc, [pivotGroup]: pivotGroupTotal };
          }
          const addToOthers = currentIndex + 1 > thresholdForPivotCharts;
          const pivotGroupName = db[pivot]?.[pivotGroup]?.name as string;

          if (addToOthers) {
            const totalForAllOthers = acc?.["All Others"] || 0;
            return {
              ...acc,
              "All Others": totalForAllOthers + pivotGroupTotal
            };
          }

          return { ...acc, [pivotGroupName]: pivotGroupTotal };
        },
        {} as Record<PivotGroupType, number>
      );

      const figureTitle = makeTableSubLegend(typeB, typeA);

      const chartData = Object.entries(groupedData).map(
        ([pivotGroupName, pivotGroupTotal]) => {
          const percentageOfTotal =
            (pivotGroupTotal / totalOfSelectedData) * 100;

          return {
            Name: pivotGroupName,
            [figureTitle]: pivotGroupTotal,
            "Share Of Total": percentageOfTotal
          };
        }
      );

      return chartData;
    }
  }

  return [];
};

const changeBudgetFieldToZero = (
  budgetData: BudgetItem[],
  field: "tradeBudget" | "revenueBudget",
  years: number[]
) =>
  budgetData
    .map(budgetRecord => {
      if (years.length && !isWildcard(years)) {
        const parsedDate = parse(budgetRecord.date, "yyyy-MM-dd", new Date());
        const year = parsedDate.getFullYear();
        if (years.includes(year)) {
          return { ...budgetRecord, [field]: 0 };
        }
        return budgetRecord;
      }
      return { ...budgetRecord, [field]: 0 };
    })
    .filter(
      ({ revenueBudget, tradeBudget }) =>
        revenueBudget !== 0 || tradeBudget !== 0
    );

const removeDataForYears = (data: BudgetItem[], years: number[]) => {
  if (isWildcard(years)) {
    return [];
  }
  return data.filter(({ date }) => {
    const parsedDate = parse(date, "yyyy-MM-dd", new Date());
    return !years.includes(parsedDate.getFullYear());
  });
};

const makeLookupKey = ({ date, productGroups, contacts, ...rest }: DataEntry) =>
  JSON.stringify({
    date: format(date, "M-d-y"),
    productGroups: productGroups.sort().join(""),
    contacts: contacts.sort().join(""),
    ...rest
  });

const compileAllData = ({
  actualTradeSpend,
  actualRevenue,
  db,
  allLines,
  customerNamesToId,
  budget,
  productGroupsByProduct,
  pricingProductGroupsByProduct,
  accountingSources,
  dataSource
}: {
  actualTradeSpend: ActualTradeSpendItem[];
  actualRevenue: ActualRevenueItem[];
  db: FirebaseDB;
  allLines: Line[];
  customerNamesToId: { [key: string]: CustomerID };
  productGroupsByProduct: { [key: ProductID]: ProductGroupID[] };
  pricingProductGroupsByProduct: { [key: ProductID]: ProductGroupID[] };
  budget: BudgetItem[];
  accountingSources: AccountingSource[];
  dataSource: DataSourceType;
}): DataEntry[] => {
  let budgetData: DataEntry[] = [];
  // Trade Spend Actual
  const actualTradeSpendEntries: DataEntryDict = Object.values(
    actualTradeSpend
  ).reduce((acc, tradeSpendItem) => {
    const line = db.allLines?.[tradeSpendItem?.lineKey as string] || {};
    const status = db.meta.statuses?.[line?.status];
    if (!["Cancelled", "Declined"].includes(status)) {
      const promotion = db.promotions?.[line?.promKey] || {};
      const promotionClosed = !!promotion?.closed;
      const customer = db.customers?.[tradeSpendItem?.customer] || {};
      tradeSpendItem.date.setDate(1);
      const recordDate = tradeSpendItem.date;
      const promDate = new Date(line.year, line.month - 1, 1);
      const actualTradeSpendDate =
        dataSource === dataSources.SPEND ? recordDate : promDate;

      let actualTradeSpendFigure = round(tradeSpendItem.spend);

      const baseRecord: DataEntry = {
        date: actualTradeSpendDate,
        promKey: line.promKey,
        customer: tradeSpendItem.customer,
        productGroups: tradeSpendItem.productGroups,
        contacts: customer.contact || [],
        status,
        promotionClosed
      };
      const lookupKey = makeLookupKey(baseRecord);
      const actualTradeSpendRecord = acc[lookupKey];
      if (actualTradeSpendRecord) {
        if (actualTradeSpendRecord?.actualTradeSpend) {
          actualTradeSpendFigure += actualTradeSpendRecord.actualTradeSpend;
        }
        acc[lookupKey] = {
          ...actualTradeSpendRecord,
          actualTradeSpend: actualTradeSpendFigure
        };
      } else {
        acc[lookupKey] = {
          ...baseRecord,
          actualTradeSpend: actualTradeSpendFigure
        };
      }
    }

    return acc;
  }, {} as DataEntryDict);
  // Trade Spend Expected
  const actualAndExpectedTradeSpendEntries: DataEntryDict = Object.values(
    allLines
  ).reduce((allEntries, line) => {
    const status = db.meta.statuses?.[line?.status];
    if (!["Cancelled", "Declined"].includes(status)) {
      const promotion = db.promotions?.[line?.promKey] || {};
      const promotionClosed = !!promotion?.closed;
      const totalExpectedSpend = line.totalExpSpend;
      const { productGroup } = line;
      const customer = db.customers?.[promotion?.customer] || {};
      // Calculate split for product groups
      const promotionDistributors = promotion?.distributor
        ? promotion.distributor
            .map(distributorName => customerNamesToId?.[distributorName])
            .filter(Boolean)
        : [];

      let distributors: CustomerID[] = [];

      if (promotionDistributors.length) {
        distributors = promotionDistributors;
      } else {
        let customerDistributors = customer?.distributors || [];
        if (customer?.isDirect || customer?.isDistributor) {
          customerDistributors.push(promotion?.customer);
        }
        if (!customerDistributors.length && customer?.distributorsField) {
          customerDistributors = customer.distributorsField
            .split(" | ")
            .map(distributorName => customerNamesToId?.[distributorName])
            .filter(Boolean);
        }
        distributors = customerDistributors;
      }
      distributors = [...new Set(distributors)];
      let spendSplit: Record<CustomerID, number> = {};
      if (
        customer?.spendPerDistributorPG &&
        customer.spendPerDistributorPG?.[productGroup] &&
        distributors.length ===
          Object.keys(customer.spendPerDistributorPG[productGroup]).length
      ) {
        spendSplit = Object.entries(
          customer.spendPerDistributorPG[productGroup]
        ).reduce(
          (acc, [distributor, split]) => ({
            ...acc,
            [distributor]: sanitizeSplit(split)
          }),
          {}
        );
      } else if (
        customer?.spendPerDistributor &&
        distributors.length === Object.keys(customer.spendPerDistributor).length
      ) {
        spendSplit = Object.entries(customer.spendPerDistributor).reduce(
          (acc, [distributorId, split]) => ({
            ...acc,
            [distributorId]: sanitizeSplit(split)
          }),
          {}
        );
      } else if (distributors.length) {
        const evenSplit = 1 / distributors.length;
        spendSplit = distributors.reduce(
          (acc, distributorId) => ({
            ...acc,
            [distributorId]: evenSplit
          }),
          {}
        );
      } else {
        spendSplit = { [promotion.customer]: 1 };
      }

      // Split trade spend for each distributor
      Object.entries(spendSplit).forEach(([distributorId, split]) => {
        let estimatedTradeSpend = round(totalExpectedSpend * split);
        const date = new Date(line.year, line.month - 1, 1);
        const baseRecord: DataEntry = {
          date,
          promKey: line.promKey,
          customer: distributorId,
          productGroups: [productGroup],
          contacts: customer.contact || [],
          status,
          promotionClosed
        };
        const lookupKey = makeLookupKey(baseRecord);
        const estimatedTradeSpendRecord = allEntries[lookupKey];
        if (estimatedTradeSpendRecord) {
          if (estimatedTradeSpendRecord?.estimatedTradeSpend) {
            estimatedTradeSpend +=
              estimatedTradeSpendRecord.estimatedTradeSpend;
          }
          allEntries[lookupKey] = {
            ...estimatedTradeSpendRecord,
            estimatedTradeSpend
          };
        } else {
          allEntries[lookupKey] = {
            ...baseRecord,
            estimatedTradeSpend
          };
        }
      });
    }

    return allEntries;
  }, actualTradeSpendEntries);
  budgetData = Object.values(actualAndExpectedTradeSpendEntries);
  // Budgeted Revenue and Trade Spend Budget
  const budgetedRevenueAndTradeSpend = budget.reduce((acc, budgetItem) => {
    const customer = db.customers?.[budgetItem?.customer] || {};
    const product = db.products?.[budgetItem?.product] || {};
    const currentEntries: DataEntry[] = [];

    if (budgetItem?.revenueBudget) {
      currentEntries.push({
        date: parse(budgetItem.date, "yyyy-MM-dd", new Date()),
        customer: budgetItem.customer,
        productGroups:
          pricingProductGroupsByProduct?.[budgetItem?.product] || [],
        contacts: customer.contact || [],
        budgetedRevenue: round(budgetItem.revenueBudget)
      });
    }
    if (budgetItem?.tradeBudget) {
      currentEntries.push({
        date: parse(budgetItem.date, "yyyy-MM-dd", new Date()),
        customer: budgetItem.customer,
        productGroups:
          product?.productGroups ||
          productGroupsByProduct?.[budgetItem?.product] ||
          [],
        contacts: customer.contact || [],
        budgetedTradeSpend: round(budgetItem.tradeBudget)
      });
    }

    return [...acc, ...currentEntries];
  }, [] as DataEntry[]);
  budgetData = [...budgetData, ...budgetedRevenueAndTradeSpend];
  // Revenue Actual
  Object.values(actualRevenue).forEach(revenueItem => {
    if (accountingSources.includes(revenueItem?.source)) {
      const customer = db.customers?.[revenueItem?.customer] || {};
      budgetData.push({
        date: revenueItem.date,
        customer: revenueItem.customer,
        productGroups: revenueItem.productGroups,
        contacts: customer.contact || [],
        actualRevenue: round(revenueItem.revenue)
      });
    }
  });

  return budgetData;
};

export {
  differenceForFigureType,
  checkPivotField,
  organiseDataPerPivot,
  groupDataForTable,
  renderInnerRow,
  makeDiffTableData,
  makeChartData,
  changeBudgetFieldToZero,
  removeDataForYears,
  compileAllData
};
