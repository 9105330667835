import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { resolutionLineTypes } from "components/Deductions/constants/ReconciliationTypes";
import {
  ERPTransactionObject,
  InvoiceLineObject,
  InvoiceObject
} from "components/Deductions/models";
import { DbContextValues } from "contexts/Db";
import { getAllInvoiceLineAmounts } from "components/Deductions/DeductionsReconciliation/services/InvoiceLineServices/invoiceLineAmount";
import {
  ResolutionActivityTypes,
  ResolutionLineActivityObject
} from "components/Deductions/DeductionsReconciliation/types/resolutionLineTypes";
import { CommentTaggedUser } from "components/Deductions/DeductionsReconciliation/ActivityLog/ActivityLogCommentBox";
import { FundType } from "js/dbTypes";
import { round } from "lodash";
import { generateRandomKey } from "helpers/Firebase";
import { ActivityTypeToResolutionLineTypes } from "components/Deductions/DeductionsReconciliation/services/ResolutionLineServices/resolutionLineDisplay";

export interface ResolveMultipleInvoiceLinesState {
  selectedInvoiceLines: Record<string, Record<string, InvoiceLineObject>>; // maps invoiceLineKey: repayment Line
  resolutionLines: Record<string, Record<string, ResolutionLineActivityObject>>; // maps invoiceLineKey: cache for resolution line that will be created
  sortState: ResolutionLineSortState;
  activityType: ResolutionActivityTypes;
}

export const initialState: ResolveMultipleInvoiceLinesState = {
  selectedInvoiceLines: {},
  resolutionLines: {},
  activityType: ResolutionActivityTypes.CLEAR,
  sortState: {
    orderBy: "invoiceLineNumber",
    sortDir: "asc"
  }
};

export const blankResolutionLine: ResolutionLineActivityObject = {
  key: "",
  type: resolutionLineTypes.CLEAR,
  amount: 0.0,
  invoiceKey: "", // invoiceKey, aka the parent
  transactionKey: "", // key for parent transaction
  invoiceLineKey: "", // invoiceLineKey, which ResolutionLine either clears against / disputes
  openedDate: "", // datetime
  openedUser: "", // userKey
  comment: "",
  taggedUsers: []
};

export interface ResolutionLineSortState {
  orderBy?: string;
  sortDir?: "asc" | "desc";
}

const slice = createSlice({
  name: "ResolveMultipleInvoiceLines",
  initialState,
  reducers: {
    reset(state: ResolveMultipleInvoiceLinesState) {
      state.activityType = ResolutionActivityTypes.CLEAR;
      state.resolutionLines = {};
      state.selectedInvoiceLines = {};
      state.sortState = { orderBy: "invoiceLineNumber", sortDir: "asc" };
    },
    toggleSelectInvoiceLine(
      state: ResolveMultipleInvoiceLinesState,
      action: PayloadAction<{
        invoiceKey: string;
        invoiceLine: InvoiceLineObject;
      }>
    ) {
      const { selectedInvoiceLines, resolutionLines } = state;
      const { invoiceKey, invoiceLine } = action.payload;
      const { key = "", invoiceLineKey = "" } = invoiceLine;
      if (!key && !invoiceLineKey) {
        return;
      }

      const currentKey = key || invoiceLineKey;
      const updatedSelectedInvoiceLines = {
        ...selectedInvoiceLines
      };
      const updatedResolutionLines = {
        ...resolutionLines
      };

      if (!(invoiceKey in state.selectedInvoiceLines)) {
        updatedSelectedInvoiceLines[invoiceKey] = {};
        updatedResolutionLines[invoiceKey] = {};
      }

      const selectedInvoiceLinesByInvoice =
        updatedSelectedInvoiceLines[invoiceKey];
      const resolutionLinesByInvoice = updatedResolutionLines[invoiceKey];

      if (!Object.keys(selectedInvoiceLinesByInvoice).includes(currentKey)) {
        updatedSelectedInvoiceLines[invoiceKey] = {
          ...selectedInvoiceLinesByInvoice,
          [currentKey]: invoiceLine
        };
        updatedResolutionLines[invoiceKey] = {
          ...resolutionLinesByInvoice,
          [invoiceLineKey]: {
            ...blankResolutionLine
          }
        };

        state.selectedInvoiceLines = updatedSelectedInvoiceLines;
        state.resolutionLines = updatedResolutionLines;
      } else {
        delete selectedInvoiceLinesByInvoice[currentKey];
        delete resolutionLinesByInvoice[currentKey];
        state.selectedInvoiceLines[invoiceKey] = selectedInvoiceLinesByInvoice;
        state.resolutionLines[invoiceKey] = resolutionLinesByInvoice;
      }
    },
    toggleSelectAllInvoiceLines(
      state: ResolveMultipleInvoiceLinesState,
      action: PayloadAction<{
        invoiceKey: string;
        invoiceLines: [string, InvoiceLineObject][];
      }>
    ) {
      const { selectedInvoiceLines, resolutionLines } = state;
      const { invoiceKey, invoiceLines } = action.payload;

      if (!(invoiceKey in selectedInvoiceLines)) {
        state.selectedInvoiceLines = {
          ...selectedInvoiceLines,
          [invoiceKey]: {}
        };
      }
      if (!(invoiceKey in resolutionLines)) {
        state.resolutionLines = {
          ...resolutionLines,
          [invoiceKey]: {}
        };
      }

      const selectedInvoiceLinesByInvoice =
        selectedInvoiceLines[invoiceKey] || {};
      const resolutionLinesByInvoice = resolutionLines[invoiceKey] || {};

      const isDeselect = invoiceLines.every(([invoiceLineKey]) => {
        return invoiceLineKey in selectedInvoiceLinesByInvoice;
      });

      if (isDeselect) {
        invoiceLines.forEach(([invoiceLineKey]) => {
          if (invoiceLineKey in selectedInvoiceLinesByInvoice) {
            delete selectedInvoiceLinesByInvoice[invoiceLineKey];
          }
          if (invoiceLineKey in resolutionLinesByInvoice) {
            delete resolutionLinesByInvoice[invoiceLineKey];
          }
        });
      } else {
        invoiceLines.forEach(([invoiceLineKey, invoiceLine]) => {
          selectedInvoiceLinesByInvoice[invoiceLineKey] = invoiceLine;
          resolutionLinesByInvoice[invoiceLineKey] = { ...blankResolutionLine };
        });
      }
      state.selectedInvoiceLines[invoiceKey] = selectedInvoiceLinesByInvoice;
      state.resolutionLines[invoiceKey] = resolutionLinesByInvoice;
    },
    setResolutionLineActivityAmount(
      state: ResolveMultipleInvoiceLinesState,
      action: PayloadAction<{
        invoiceKey: string;
        invoiceLineKey: string;
        activityAmount: string;
        invoiceLineOpenAmount: number;
        invoiceOpenAmount: number;
        transactionOpenAmount: number;
      }>
    ) {
      const { resolutionLines } = state;
      const {
        invoiceKey,
        invoiceLineKey,
        activityAmount = "0.0"
      } = action.payload;
      const resolutionLinesByInvoice = resolutionLines[invoiceKey];
      const resolutionLine = resolutionLinesByInvoice[invoiceLineKey];

      const updatedResolutionLine = { ...resolutionLine };
      const floatActivityAmount = parseFloat(
        activityAmount !== "" ? activityAmount : "0.0"
      );
      const parsedAmount = round(floatActivityAmount, 2);

      updatedResolutionLine.amount = parsedAmount;

      state.resolutionLines = {
        ...resolutionLines,
        [invoiceKey]: {
          ...resolutionLinesByInvoice,
          [invoiceLineKey]: updatedResolutionLine
        }
      };
    },
    setResolutionLineFundTypeKey(
      state: ResolveMultipleInvoiceLinesState,
      action: PayloadAction<{
        fundTypeKey: string;
        fundTypes: Record<string, FundType>;
        activityType: ResolutionActivityTypes;
        invoiceKey: string;
        invoiceLineKey: string;
      }>
    ) {
      const { resolutionLines } = state;
      const {
        invoiceLineKey,
        fundTypeKey,
        invoiceKey,
        activityType,
        fundTypes = {}
      } = action.payload;

      const fundTypeAccount =
        activityType !== ResolutionActivityTypes.WRITE_OFF
          ? fundTypes?.[fundTypeKey]?.accountKey
          : fundTypes?.[fundTypeKey]?.writeoffAccountKey;

      const resolutionLinesByInvoice = resolutionLines[invoiceKey];
      const resolutionLine = resolutionLinesByInvoice[invoiceLineKey];

      const updatedResolutionLine: ResolutionLineActivityObject = {
        ...resolutionLine,
        fundTypeKey,
        fundTypeAccount
      };

      state.resolutionLines = {
        ...resolutionLines,
        [invoiceKey]: {
          ...resolutionLinesByInvoice,
          [invoiceLineKey]: updatedResolutionLine
        }
      };
    },
    setResolutionLineComment(
      state: ResolveMultipleInvoiceLinesState,
      action: PayloadAction<{
        comment: string;
        invoiceKey: string;
        invoiceLineKey: string;
        taggedUsers?: CommentTaggedUser[];
      }>
    ) {
      const { resolutionLines } = state;
      const {
        invoiceLineKey,
        comment,
        invoiceKey,
        taggedUsers = []
      } = action.payload;
      const resolutionLinesByInvoice = resolutionLines[invoiceKey];
      const resolutionLine = resolutionLinesByInvoice[invoiceLineKey];

      const updatedResolutionLine: ResolutionLineActivityObject = {
        ...resolutionLine,
        comment,
        taggedUsers
      };

      state.resolutionLines = {
        ...resolutionLines,
        [invoiceKey]: {
          ...resolutionLinesByInvoice,
          [invoiceLineKey]: updatedResolutionLine
        }
      };
    },
    setInitialResolutionLines(
      state: ResolveMultipleInvoiceLinesState,
      action: PayloadAction<{
        invoice: InvoiceObject;
        transaction: ERPTransactionObject;
        db: DbContextValues;
        activityType: ResolutionActivityTypes;
        userId: string;
      }>
    ) {
      const { resolutionLines, selectedInvoiceLines } = state;
      const { activityType, invoice, transaction, db, userId } = action.payload;
      const { invoices = {}, erpTransactions = {} } = db;
      const { key: invoiceKey } = invoice;
      const { key: transactionKey } = transaction;
      const selectedLinesByInvoice = selectedInvoiceLines[invoiceKey];
      const resolutionLinesByInvoice = resolutionLines[invoiceKey];

      const updatedResolutionLines: Record<
        string,
        ResolutionLineActivityObject
      > = {};

      Object.values(selectedLinesByInvoice).forEach(invoiceLine => {
        const { key, invoiceLineKey } = invoiceLine;
        const { meta: { fundTypes = {} } = {}, allLines = {} } = db;
        const allInvoiceLineAmounts = getAllInvoiceLineAmounts(
          selectedLinesByInvoice[invoiceLineKey || key],
          invoice,
          invoices,
          erpTransactions
        );
        const { openAmount } = allInvoiceLineAmounts;
        const roundedOpenAmount = round(openAmount, 2);

        const resolutionLine = resolutionLinesByInvoice[invoiceLineKey || key];

        // cache values for newly created resolution line
        let resLineKey;
        let unique = false;
        while (!unique) {
          resLineKey = generateRandomKey();
          unique = !(resLineKey in (invoice.resolutionLines || {}));
        }

        const updatedLine: ResolutionLineActivityObject = {
          ...resolutionLine,
          key: resLineKey,
          type: ActivityTypeToResolutionLineTypes[activityType],
          amount: roundedOpenAmount,
          invoiceKey,
          transactionKey,
          invoiceLineKey: invoiceLineKey || key,
          openedUser: userId
        };

        const fundTypeKey =
          allLines[invoiceLine.matchedPromLine || ""]?.type ||
          invoiceLine.suggestedFundType ||
          "";

        if (activityType === ResolutionActivityTypes.CLEAR) {
          updatedLine.fundTypeKey = fundTypeKey;
          updatedLine.fundTypeAccount = fundTypes?.[fundTypeKey]?.accountKey;
          updatedLine.promLine = invoiceLine.matchedPromLine ?? undefined;
        }

        if (activityType === ResolutionActivityTypes.WRITE_OFF) {
          updatedLine.fundTypeKey = fundTypeKey;
          updatedLine.fundTypeAccount =
            fundTypes?.[fundTypeKey]?.writeoffAccountKey;
        }

        updatedResolutionLines[invoiceLineKey || key] = updatedLine;
      });
      state.resolutionLines = {
        ...resolutionLines,
        [invoiceKey]: updatedResolutionLines
      };
    },
    setResolutionLineSorting(
      state: ResolveMultipleInvoiceLinesState,
      action: PayloadAction<ResolutionLineSortState>
    ) {
      state.sortState = action.payload;
    },
    setCurrentActivityType(
      state: ResolveMultipleInvoiceLinesState,
      action: PayloadAction<ResolutionActivityTypes>
    ) {
      state.activityType = action.payload;
    }
  }
});

export const {
  toggleSelectInvoiceLine,
  toggleSelectAllInvoiceLines,
  setResolutionLineActivityAmount,
  setResolutionLineFundTypeKey,
  setInitialResolutionLines,
  setResolutionLineComment,
  setResolutionLineSorting,
  setCurrentActivityType,
  reset
} = slice.actions;

export default slice.reducer;
