/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from "react";
import {
  DRMRouteState,
  DRMFeature,
  setDRMFeatureState,
  setDRMRouteState
} from "components/Deductions/DeductionsReconciliation/redux/DRMSlice";
import { useHistory } from "react-router-dom";
import { Divider, Tab, Tabs, Toolbar, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { RootState } from "store";
import UserMentionsButton from "./ActivityLog/UserMentionsButton";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  },
  innerContainer: { display: "flex", alignItems: "center" },
  divider: {
    marginTop: "5px",
    marginBottom: "5px",
    marginLeft: "10px",
    marginRight: "10px"
  },
  toolbarContents: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  }
}));

const StyledTabs = withStyles(theme => ({
  indicator: {
    backgroundColor: theme.palette.primary.main
  }
}))(Tabs);

const a11yProps = index => {
  return {
    id: `drm-tab-${index}`,
    "aria-controls": `drm-tabpanel-${index}`
  };
};

export interface DeductionsReconciliationToolbarProps {
  rootFeature: DRMFeature;
}

export default function DeductionsReconciliationToolbar(
  props: DeductionsReconciliationToolbarProps
): JSX.Element {
  const { rootFeature } = props;

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { routeState, guiMode, drmFeatureState, drmFeatureTitleState } =
    useSelector((state: RootState) => state.drm, shallowEqual);

  useEffect(() => {
    if (drmFeatureState !== rootFeature) {
      dispatch(setDRMFeatureState(rootFeature));
    }
  }, [dispatch, rootFeature, drmFeatureState]);

  useEffect(() => {
    const newRouteState = history.location.pathname.includes("transaction")
      ? DRMRouteState.TRANSACTIONS
      : DRMRouteState.INVOICES;
    dispatch(setDRMRouteState(newRouteState));
  }, [dispatch, history.location.pathname]);

  const handleChange = (event, newValue) => {
    switch (newValue) {
      case DRMRouteState.INVOICES: {
        dispatch(setDRMRouteState(DRMRouteState.INVOICES));
        history.push(`/${drmFeatureState}/invoices/${guiMode}`);
        break;
      }
      case DRMRouteState.TRANSACTIONS: {
        dispatch(setDRMRouteState(DRMRouteState.TRANSACTIONS));
        history.push(`/${drmFeatureState}/transactions/${guiMode}`);
        break;
      }
      default:
        break;
    }
  };

  return (
    <Toolbar sx={{ backgroundColor: "background.surfaceVariant" }}>
      <div className={classes.toolbarContents}>
        <div className={classes.container}>
          <div className={classes.innerContainer}>
            <Typography>{drmFeatureTitleState}</Typography>
            {rootFeature === DRMFeature.RECONCILIATION && (
              <>
                <Divider
                  orientation="vertical"
                  flexItem
                  className={classes.divider}
                />
                <StyledTabs
                  value={routeState}
                  onChange={handleChange}
                  aria-label="Deductions Navigation">
                  <Tab
                    value={DRMRouteState.TRANSACTIONS}
                    label="ERP TRANSACTIONS"
                    {...a11yProps(0)}
                  />
                  <Tab
                    value={DRMRouteState.INVOICES}
                    label="BACKUPS"
                    {...a11yProps(1)}
                  />
                </StyledTabs>
              </>
            )}
          </div>
          <div>
            <UserMentionsButton />
          </div>
        </div>
      </div>
    </Toolbar>
  );
}
