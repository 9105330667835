import React from "react";
import { Avatar as MUIAvatar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { common } from "@mui/material/colors";

const { white } = common;

const useStyles = makeStyles(theme => ({
  root: props => ({
    backgroundColor: props.backgroundColor,
    color: props.color,
    padding: theme.spacing(0.5),
    height: theme.spacing(props.size),
    width: theme.spacing(props.size)
  })
}));

const Avatar = props => {
  const { children, ...other } = props;
  const classes = useStyles(props);
  const avatar = (
    <MUIAvatar className={classes.root} {...other}>
      {children}
    </MUIAvatar>
  );
  return avatar;
};
Avatar.muiName = "Avatar";

export default Avatar;
