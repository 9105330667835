import React, { useState, useEffect, useMemo, useCallback } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Checkbox from "ui-library/Checkbox";
import { grey } from "@mui/material/colors";
import {
  InvoiceProcessingService,
  InvoiceDependenciesService
} from "reconciliation-services";
import { invoiceActions } from "reconciliation-types/invoiceTypes";
import InvoiceObject from "deductions-models/InvoiceObject";
import { useOpenClose } from "contexts/OpenClose";
import { infoDialog } from "helpers/OpenDialog";
import { Theme } from "@mui/material";

const getApprovalCheckboxColor = (
  permissions: string[],
  needsCresicorApproval: boolean,
  theme: Theme
): string => {
  if (permissions.includes("approveInvoices")) {
    return needsCresicorApproval ? grey["600"] : theme.palette.primary.main;
  }
  return needsCresicorApproval ? grey["400"] : grey["400"];
};

const useComputedStyles = props =>
  makeStyles(theme => ({
    label: {
      marginLeft: "initial",
      marginBottom: "0px"
    },
    approvalCheckbox: {
      color: `${getApprovalCheckboxColor(
        props.permissions,
        props.invoice.needsCresicorApproval,
        theme
      )} !important`
    }
  }));

interface BackupApprovalToggleProps {
  invoice: InvoiceObject;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  permissions: string[];
  hideLabel?: boolean;
}

export default function BackupApprovalToggle(
  props: BackupApprovalToggleProps
): JSX.Element {
  const { permissions, invoice, hideLabel } = props;
  const [needsCresicorApproval, setNeedsCresicorApproval] = useState<boolean>(
    invoice.needsCresicorApproval
  );
  const classes = useComputedStyles(props)();
  const openClose = useOpenClose();

  // recalculate dependencies if invoice changes
  const allInvoiceDependencies = useMemo(
    () => InvoiceDependenciesService.calculateInvoiceDependencies(invoice),
    [invoice]
  );

  useEffect(() => {
    setNeedsCresicorApproval(invoice.needsCresicorApproval);
  }, [invoice]);

  const onStatusChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const approvalStatus = !e.target.checked;
      const { actionAllowed, dependenciesMessage } =
        InvoiceDependenciesService.isSingleInvoiceActionAllowed(
          invoice,
          approvalStatus ? invoiceActions.UNAPPROVE : invoiceActions.APPROVE,
          allInvoiceDependencies
        );
      if (actionAllowed) {
        InvoiceProcessingService.updateFirebaseInvoice(
          { ...invoice, needsCresicorApproval: approvalStatus },
          () => {
            setNeedsCresicorApproval(approvalStatus);
            openClose.showSnack(
              `Invoice ${invoice.invoiceNumber} has been ${
                approvalStatus ? "unapproved" : "approved"
              }.`
            );
          }
        );
      } else {
        infoDialog(
          openClose,
          `Error - ${invoiceActions.APPROVE}`,
          `Invoice ${invoice.invoiceNumber} cannot be approved because: ${dependenciesMessage}. Please handle these dependencies before trying again.`,
          `Go Back`
        );
      }
    },
    [openClose, invoice, allInvoiceDependencies, needsCresicorApproval]
  );

  return (
    <Checkbox
      size="small"
      onLabelClick={e => e.stopPropagation()}
      disabled={!permissions.includes("approveInvoices")}
      onChange={onStatusChange}
      checked={!needsCresicorApproval}
      className={classes.approvalCheckbox}
      label={hideLabel ? "" : "Approved"}
    />
  );
}
