/* eslint-disable new-cap */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import DeductionsReconciliationFilePicker from "components/WebsiteElements/DeductionsReconciliationFilePicker";
import { makeStyles } from "@mui/styles";
import { useOpenClose } from "contexts/OpenClose";
import { Box, Button } from "@mui/material";
import { jsPDF } from "jspdf";
import { useDb } from "contexts/Db";
import {
  addBackupFiles,
  changeUploadStep,
  setFakeInvoiceMode,
  setInvoiceAssignPageField,
  updateFilePageCounts
} from "../redux/actionCreators";
import {
  getAllFilePageCounts,
  getFileNameWithoutExtension
} from "../redux/helpers";
import UploadedFilesTable from "./UploadedFilesTable";
import { invoiceInputFields } from "../redux/definitions";

const useStyles = makeStyles(() => ({
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    height: "100%"
  },
  columnContainer: {
    display: "flex",
    flexDirection: "column",
    marginRight: "1vh"
  }
}));

export const BACKUP_UPLOADER_FAKE_FILENAME = "___vividly_empty___.pdf";

export default function AddBackupFiles() {
  const dispatch = useDispatch();
  const openClose = useOpenClose();
  const { showSnack } = openClose;
  const classes = useStyles();
  const db = useDb();

  const addFilesToStore = (files: File[]) => () => {
    dispatch(addBackupFiles(files));

    return getAllFilePageCounts(files).then((filePageCounts: number[]) => {
      dispatch(updateFilePageCounts(filePageCounts));
    });
  };

  const { errors, newInvoices } = useSelector(
    (state: RootState) => state.uploadBackup
  );
  const { uploadFiles } = errors;
  const { duplicateFilesDetected, duplicateFileNames } = uploadFiles[0];

  useEffect(() => {
    if (duplicateFilesDetected && duplicateFileNames.length > 0) {
      showSnack(
        `Unable to upload files: ${duplicateFileNames.join(
          ", "
        )}. Please rename and try again`
      );
    }
  }, [duplicateFileNames, duplicateFilesDetected, showSnack]);

  return (
    <div className={classes.rowContainer}>
      <div className={classes.columnContainer}>
        {db.meta.featureGates?.skipBackupUpload && (
          <Box width="100%" display="flex" justifyContent="right">
            <Button
              sx={theme => ({ marginBottom: theme.spacing(1) })}
              onClick={() => {
                dispatch(setFakeInvoiceMode());
                const doc = new jsPDF();
                doc.text("empty", 10, 10);
                const blob = doc.output("blob");
                const fileName = BACKUP_UPLOADER_FAKE_FILENAME;
                const pdfFile = new File([blob], fileName, {
                  type: "application/pdf"
                });

                dispatch(addFilesToStore([pdfFile]));
                const invoiceIdx = 0;
                dispatch(
                  setInvoiceAssignPageField(
                    invoiceIdx,
                    invoiceInputFields.FILE_NAME,
                    fileName,
                    db
                  )
                );

                // Auto-set invoice number if not already exists
                if (
                  !newInvoices[invoiceIdx][invoiceInputFields.INVOICE_NUMBER]
                ) {
                  const fileNameWithoutExtension =
                    getFileNameWithoutExtension(fileName);
                  dispatch(
                    setInvoiceAssignPageField(
                      invoiceIdx,
                      invoiceInputFields.INVOICE_NUMBER,
                      fileNameWithoutExtension,
                      db
                    )
                  );
                }
                dispatch(changeUploadStep(true, db));
              }}
              variant="contained">
              Skip Upload
            </Button>
          </Box>
        )}
        <DeductionsReconciliationFilePicker
          message="Upload backup files here"
          fileTypes={["PDF", "CSV", "EXCEL"]}
          acceptedFiles={[
            ".pdf",
            ".PDF",
            "application/pdf",
            ".xlsx",
            ".xls",
            ".csv",
            ".CSV",
            ".xlsx",
            ".xls",
            ".csv",
            ".CSV",
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "text/csv"
          ]}
          filesLimit={250}
          onDrop={(files: File[]) => {
            dispatch(addFilesToStore(files));
          }}
          showPreviewsInDropzone={false}
        />
        <br />
        <UploadedFilesTable />
      </div>
    </div>
  );
}
