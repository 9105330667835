import React from "react";
import Typography from "@mui/material/Typography";
import { Toolbar, ToolbarTitle, ToolbarSeparator } from "ui-library/Toolbar";
import ToolbarGroup from "ui-library/ToolbarGroup";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AddBoxIcon from "@mui/icons-material/AddBox";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import BlockIcon from "@mui/icons-material/Block";
import CheckIcon from "@mui/icons-material/Check";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Button from "ui-library/Button";
import IconButton from "ui-library/IconButton";
import IconMenu from "ui-library/IconMenu";
import EmojiObjectsOutlinedIcon from "@mui/icons-material/EmojiObjectsOutlined";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
import MenuItem from "ui-library/MenuItem";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import SortIcon from "@mui/icons-material/Sort";
import StarIcon from "@mui/icons-material/Star";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LabelIcon from "@mui/icons-material/Label";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import TextField from "ui-library/TextField";
import { intersection, mapValues, capitalize, size } from "lodash";
import Mixpanel from "helpers/Mixpanel";
import { promotionTagMenu } from "ui-library/promotionTag";
import { infoDialog } from "helpers/OpenDialog";
import Stack from "@mui/material/Stack";
/* eslint-disable */
import {
  batchUpdateFirebase,
  getCompanyUsers,
  getEnhancedPromsFirebase,
  updateStatusFirebase,
  firebase
} from "helpers/Firebase";
import { exportPromotions } from "helpers/Export";
import { underPromoApprovalLimit } from "helpers/DataProcessing";
import {
  amber,
  red,
  deepOrange,
  grey,
  common,
  green,
  blue
} from "@mui/material/colors";
import AddPromotion from "../AddPromotion/AddPromotion";
import AddPromotionContinuously from "../AddPromotion/AddPromotionContinuously";
import ModalTitle from "../AddPromotion/ModalTitle";
import SearchPromotions from "./SearchPromotions";
import StepButtons from "../AddPromotion/StepButtons";
import PromModalTitlebar from "./PromModalTitlebar";
import PromModalFooter from "./PromModalFooter";

const deepOrange400 = deepOrange["400"];
const { white } = common;
const { black } = common;
const green400 = green["400"];

const styles = {
  iconButton: {
    zIndex: 100
  },
  rightButton: {
    color: white,
    marginRight: -10
  }
};

const MAX_PROMOTIONS = 24; // Prevent user from multi-tagging more than 24 promotions (approxo 2 years' worth) at a time, so as to prevent crashing.

class PromToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.searchPromotionsRef = React.createRef();
    this.state = {
      modalOpen: false,
      openFilePicker: false,
      query: ""
    };
  }

  addPromotion = () => {
    if (
      !this.props.db.meta.fundTypes ||
      !this.props.db.meta.product_groups ||
      !this.props.db.customers
    ) {
      infoDialog(
        this.props.openClose,
        "Error",
        "Before creating your first promotion, please create at least one promotion type, product group, and customer.",
        "Go Back"
      );
    } else {
      this.props.openClose.setAppModal({
        title: <ModalTitle modalTitle="Add New Promotion" />,
        content: (
          <AddPromotion db={this.props.db} openClose={this.props.openClose} />
        ),
        actions: (
          <StepButtons
            db={this.props.db}
            openClose={this.props.openClose}
            mode="add"
            windowClosed={this.props.promCreationWindowClosed}
          />
        ),
        staticModal: true,
        isFullWidth: true,
        maxWidth: "lg"
      });
    }
  };

  openFilePicker = jsx => {
    this.setState({
      openFilePicker: true
    });

    this.props.showDropDownWindow(jsx);
  };

  handleRunContinuously = () => {
    if (this.props.selectedProms.length > MAX_PROMOTIONS) {
      this.props.openClose.setAppModal(
        "Too Many Promotions Selected",
        <div>Cannot duplicate more than twelve promotions at once.</div>,
        <div>
          <Button
            label="Okay"
            onClick={() => {
              this.props.openClose.closeAppModal();
            }}
          />
        </div>
      );
    } else if (Object.keys(this.props.proposedProms).length > 0) {
      this.props.openClose.setAppModal(
        "Advanced Duplication Already In Progress",
        <div>
          An advanced duplication operation is already in progress. Please
          complete or cancel the current operation before starting a new one.
        </div>,
        <div>
          <Button
            label="Okay"
            onClick={() => {
              this.props.openClose.closeAppModal();
            }}
          />
        </div>
      );
    } else if (this.props.selectedProms.length > 0) {
      const selectedPromKeys = this.props.selectedProms;
      const proms = selectedPromKeys.map(key => this.props.db.promotions[key]);
      this.props.openClose.setAppModal({
        title: (
          <PromModalTitlebar
            title={
              proms.length > 1
                ? "Clone Multiple Promotions"
                : "Run Promotion Continuously"
            }
          />
        ),
        content: (
          <div
            style={{
              margin: "0 30px 10px"
            }}>
            <AddPromotionContinuously
              proms={proms}
              db={this.props.db}
              openClose={this.props.openClose}
              callback={this.props.autoDuplicateCallback}
            />
          </div>
        ),
        actions: <PromModalFooter openClose={this.props.openClose} />,
        staticModal: true,
        isFullWidth: true,
        maxWidth: "md"
      });
      this.props.openClose.hideRightDrawer();
      this.props.clearSelectedProms();
    }
  };

  batchStar = () => {
    const newProms = {};
    for (let i = 0; i < this.props.selectedProms.length; i++) {
      const promKey = this.props.selectedProms[i];
      const prom = this.props.db.promotions[promKey];
      newProms[promKey] = { ...prom, starred: !prom.starred };
    }
    batchUpdateFirebase(0, newProms);
    this.props.clearSelectedProms();
  };

  batchChangeStatus = (oldStatuses, newStatus) => {
    const proms = [];
    const promKeys = [];
    let batchUpdate = true;
    this.props.selectedProms.forEach(promKey => {
      const prom = this.props.db.promotions[promKey];
      // check for approving promotions
      const promoLimitsDict = this.props.db.meta.promo_limits;
      const { uid } = firebase.auth().currentUser;
      const companyUser = this.props.db.companyUsers[uid];
      const underLimit =
        newStatus != "Approved" ||
        underPromoApprovalLimit(
          companyUser,
          uid,
          promoLimitsDict,
          prom.totalExpSpend
        );
      if (newStatus == "Cancelled") {
        getEnhancedPromsFirebase(enhancedProm => {
          const hasDeductions =
            enhancedProm.actMoneyList && enhancedProm.actMoneyList.length > 0;
          if (!hasDeductions) {
            proms.push(prom);
            promKeys.push(promKey);
          } else {
            batchUpdate = false;
            this.props.openClose.setAppModal(
              "Confirm Cancel",
              <div className="centering">
                You cannot cancel a promotion that has deductions attached to
                it.
              </div>,

              <Button
                label="Go back"
                onClick={() => {
                  this.props.openClose.closeAppModal();
                }}
              />
            );
          }
        }, promKey);
      } else if (
        underLimit &&
        (!oldStatuses ||
          oldStatuses.includes(this.props.db.meta.statuses[prom.status]))
      ) {
        proms.push(prom);
        promKeys.push(promKey);
      } else {
        batchUpdate = false;
        this.props.openClose.setAppModal(
          "Error",
          <div>
            You do not have the permissions required to perform this operation.
            Please speak to an administrator.
          </div>,

          <div>
            <Button
              label="Go back"
              onClick={() => {
                this.props.openClose.closeAppModal();
              }}
            />
          </div>
        );
      }
    });
    if (batchUpdate && newStatus == "Cancelled") {
      this.props.openClose.setAppModal(
        "Confirm Cancel",
        this.props.db.permissions.includes("cancel") && !this.props.readOnly ? (
          <div>
            <div className="centering">
              {`Are you sure you want to cancel ${this.props.selectedProms.length} promotions?`}
            </div>
            <br />
            <Stack justifyContent="flex-end">
              <Button
                label="Yes, I'm sure"
                variant="text"
                color="error"
                onClick={() => {
                  this.props.openClose.closeAppModal();
                  updateStatusFirebase(promKeys, proms, newStatus);
                }}
              />
              <Button
                label="No, go back"
                variant="text"
                onClick={() => {
                  this.props.openClose.closeAppModal();
                }}
              />
            </Stack>
          </div>
        ) : (
          <div>
            <div>
              You do not have the permissions required to cancel promotions.
              Please speak to an administrator.
            </div>
            <br />
            <Stack justifyContent="flex-end">
              <Button
                label="Go back"
                onClick={() => {
                  this.props.openClose.closeAppModal();
                }}
              />
            </Stack>
          </div>
        )
      );
      this.props.clearSelectedProms();
    } else if (batchUpdate) {
      if (this.props.interface == 2) {
        Mixpanel.track("Promotions Submitted", {
          Quantity: this.props.selectedProms.length
        });
      }
      if (this.props.interface == 3) {
        Mixpanel.track("Promotions Approved", {
          Quantity: this.props.selectedProms.length
        });
      }
      updateStatusFirebase(promKeys, proms, newStatus);
      this.props.clearSelectedProms();
    }
  };

  getNewProms = value => {
    const newProms = {};
    this.props.selectedProms.forEach(promKey => {
      const prom = this.props.db.promotions[promKey];

      let updateInfo = {};

      if (value === "open") {
        updateInfo = { closed: false };
      } else if (value === "close") {
        const promStatus = this.props.db.meta
          ? this.props.db.meta.statuses[prom.status]
          : "";
        if (!["Completed", "Cancelled"].includes(promStatus)) {
          return;
        }
        updateInfo = {
          closed: true,
          lines: mapValues(prom.lines, value => ({ ...value, closed: true }))
        };
      }

      newProms[promKey] = { ...prom, ...updateInfo };
    });

    return newProms;
  };

  batchChangePromotion = (value, newProms) => {
    batchUpdateFirebase(0, newProms);

    if (value === "open") {
      Mixpanel.track("Promotions Opened", {
        Quantity: size(newProms)
      });
    }

    if (value === "close") {
      Mixpanel.track("Promotions Closed", {
        Quantity: size(newProms)
      });
    }

    this.props.clearSelectedProms();
    this.props.openClose.closeAppModal();
  };

  onTagClick = value => {
    // Limit multi-tagging to 24, around 2 years' worth of promos.
    const maxPromotions = 24;
    if (this.props.selectedProms.length > maxPromotions) {
      this.props.openClose.setAppModal(
        "Too Many Promotions Selected",
        <div>
          Cannot multi-tag more than twenty-four (24) promotions at once.
        </div>,
        <div>
          <Button
            label="Okay"
            onClick={() => {
              this.props.openClose.closeAppModal();
            }}
          />
        </div>
      );
    } else {
      // Proceed if fewer than 24 promotions selected to prevent crashing.
      const tagValue = this.getTagValue() || [];

      const newProms = this.props.selectedProms.map(promKey => {
        const prom = this.props.db.promotions[promKey];

        let promTags = prom.tags || [];

        if (tagValue?.includes(value)) {
          promTags = promTags.filter(val => val !== value);
        } else {
          promTags = [...promTags, value];
        }

        return { ...prom, tags: promTags };
      });

      const newPromsDict = {};
      for (const prom of newProms) newPromsDict[prom.promKey] = prom;

      batchUpdateFirebase(0, newPromsDict, () => {
        this.props.clearSelectedProms();
      });
    }
  };

  getTagValue = () => {
    const {
      db: { promotions },
      selectedProms
    } = this.props;

    return selectedProms.reduce(
      (acc, promKey) =>
        acc
          ? intersection(acc, promotions[promKey].tags || [])
          : promotions[promKey].tags,
      undefined
    );
  };

  onMarkAsMenuChange = value => () => {
    if (
      this.state.access == "Viewer" ||
      !this.props.db.permissions.includes("close")
    ) {
      this.props.openClose.showSnack(
        "You do not have sufficient permissions to \
      perform this action."
      );
      return;
    }

    const newProms = this.getNewProms(value);

    if (size(newProms) !== this.props.selectedProms.length) {
      this.props.openClose.setAppModal(
        `${capitalize(value)} Promotions`,
        <div>
          You have selected at least one promotion that is not Completed,
          Cancelled, or Closed. Only these types of promotions may be closed.
        </div>,

        <Button
          label="Go back"
          onClick={() => {
            this.props.openClose.closeAppModal();
          }}
        />
      );

      return;
    }

    this.props.openClose.setAppModal(
      `${capitalize(value)} Promotions`,
      <div>
        {`Are you sure you want to ${value} ${this.props.selectedProms.length} promotions?`}
      </div>,
      <Stack>
        <Button
          label="Yes, I'm sure"
          variant="text"
          onClick={() => {
            this.batchChangePromotion(value, newProms);
          }}
        />
        <Button
          label="No, go back"
          variant="text"
          onClick={() => {
            this.props.openClose.closeAppModal();
          }}
        />
      </Stack>
    );
  };

  getRightJSX = () => {
    const user = firebase.auth().currentUser;

    if (this.props.interface == 2) {
      // If in Submissions interface
      return (
        <Stack>
          <Button
            label="Submit"
            icon={<WatchLaterIcon />}
            onClick={this.batchChangeStatus.bind(
              null,
              ["Pending", "Declined"],
              "Submitted"
            )}
            disabled={!this.props.db.permissions.includes("submit")}
          />
          <Button
            label="Star"
            icon={<StarIcon />}
            onClick={this.batchStar}
            disabled={!this.props.db.permissions.includes("masteredit")}
          />
          <Button
            label="Cancel"
            icon={<BlockIcon />}
            onClick={this.batchChangeStatus.bind(null, null, "Cancelled")}
            disabled={!this.props.db.permissions.includes("cancel")}
          />
        </Stack>
      );
    }
    if (this.props.interface == 3) {
      // If in Approval interface
      return (
        <Stack>
          <Button
            label="Approve"
            icon={<ThumbUpIcon />}
            onClick={this.batchChangeStatus.bind(
              null,
              ["Submitted", "Declined", "Cancelled"],
              "Approved"
            )}
            disabled={!this.props.db.permissions.includes("approve")}
          />
          <Button
            label="Star"
            icon={<StarIcon />}
            onClick={this.batchStar}
            disabled={!this.props.db.permissions.includes("masteredit")}
          />
          <Button
            label="Decline"
            icon={<ThumbDownIcon />}
            onClick={this.batchChangeStatus.bind(
              null,
              ["Submitted", "Approved"],
              "Declined"
            )}
            disabled={!this.props.db.permissions.includes("approve")}
          />
          <Button
            label="Cancel"
            icon={<BlockIcon />}
            onClick={this.batchChangeStatus.bind(null, null, "Cancelled")}
            disabled={!this.props.db.permissions.includes("cancel")}
          />
        </Stack>
      );
    }
    if (this.props.interface == 4) {
      // If in Advanced interface
      return (
        <ToolbarGroup>
          <Button
            label="Duplicate Across Months"
            style={styles.rightButton}
            onClick={this.handleRunContinuously}
            disabled={
              !(
                !this.props.readOnly &&
                this.props.db.permissions.includes("promotions")
              )
            }
          />
        </ToolbarGroup>
      );
    }
    if (this.props.interface === 6) {
      return (
        <ToolbarGroup>
          <IconMenu
            iconButtonElement={
              <IconButton
                tooltip="Settings"
                style={styles.iconButton}
                size="large">
                <SettingsIcon />
              </IconButton>
            }
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
            targetOrigin={{ horizontal: "right", vertical: "top" }}>
            <MenuItem
              value={0}
              children="Download Promotions"
              leftIcon={<CloudDownloadIcon />}
              onClick={() => {
                Mixpanel.track("Promotions Exported");
                exportPromotions(
                  this.props.db.companyid,
                  this.props.openClose,
                  this.props.promKeyList
                );
                this.props.openClose.setAppModal(
                  "Export In Progress",
                  <div>
                    Your export is being prepared and will be emailed to you
                    shortly.
                  </div>,

                  <div>
                    <Button
                      label="Ok"
                      onClick={() => {
                        this.props.openClose.closeAppModal();
                      }}
                    />
                  </div>
                );
              }}
            />
            <MenuItem
              children="Download Calendar Filters as .png"
              leftIcon={<CloudDownloadIcon />}
              onClick={() => this.props.exportCalendar("filters-png")}
            />
            <MenuItem
              children="Download Calendar Filters as .pdf"
              leftIcon={<CloudDownloadIcon />}
              onClick={() => this.props.exportCalendar("filters-pdf")}
            />
            <MenuItem
              children="Download Calendar as .png"
              leftIcon={<CloudDownloadIcon />}
              onClick={() => this.props.exportCalendar("png")}
            />
            <MenuItem
              children="Download Calendar as .pdf"
              leftIcon={<CloudDownloadIcon />}
              onClick={() => this.props.exportCalendar("pdf")}
            />
          </IconMenu>
          {this.props.db.permissions.includes("promotions") &&
            !this.props.readOnly && (
              <IconButton
                onClick={this.addPromotion}
                tooltip="Add Promotion"
                style={styles.iconButton}
                size="large">
                <AddBoxIcon />
              </IconButton>
            )}
        </ToolbarGroup>
      );
    }
    if (this.props.multi && this.props.interface == 1) {
      // If in multi-selection in List interface
      return (
        <Stack>
          <Button
            icon={<WatchLaterIcon />}
            onClick={this.batchChangeStatus.bind(
              null,
              ["Pending", "Declined", "Cancelled"],
              "Submitted"
            )}
            disabled={!this.props.db.permissions.includes("submit")}
          />
          <Button
            icon={<ThumbUpIcon />}
            onClick={this.batchChangeStatus.bind(
              null,
              ["Submitted", "Declined", "Cancelled"],
              "Approved"
            )}
            disabled={!this.props.db.permissions.includes("approve")}
          />
          <Button
            icon={<StarIcon />}
            onClick={this.batchStar}
            disabled={!this.props.db.permissions.includes("masteredit")}
          />
          <Button
            icon={<ThumbDownIcon />}
            onClick={this.batchChangeStatus.bind(
              null,
              ["Submitted", "Approved"],
              "Declined"
            )}
            disabled={!this.props.db.permissions.includes("approve")}
          />
          <Button
            icon={<BlockIcon />}
            onClick={this.batchChangeStatus.bind(null, null, "Cancelled")}
            disabled={!this.props.db.permissions.includes("cancel")}
          />
        </Stack>
      );
    }
    return (
      <ToolbarGroup>
        {this.props.interface == 0 && (
          <IconMenu
            iconButtonElement={
              <IconButton
                tooltip="Sort By"
                style={styles.iconButton}
                size="large">
                <SortIcon />
              </IconButton>
            }
            anchorOrigin={{ horizontal: "left", vertical: "top" }}
            targetOrigin={{ horizontal: "left", vertical: "top" }}>
            <MenuItem
              value="date"
              children="Start Date"
              rightIcon={
                <CheckIcon
                  style={this.props.sortBy == "date" ? {} : { opacity: 0 }}
                />
              }
              onClick={this.props.handleSortBy.bind(null, "date")}
            />
            <MenuItem
              value="expectedSpend"
              children="Expected Spend"
              rightIcon={
                <CheckIcon
                  style={
                    this.props.sortBy == "expectedSpend" ? {} : { opacity: 0 }
                  }
                />
              }
              onClick={this.props.handleSortBy.bind(null, "expectedSpend")}
            />
          </IconMenu>
        )}
        {this.props.interface == 1 && this.props.selectedProms.length > 0 && (
          <IconMenu
            iconButtonElement={
              <IconButton tooltip="Tags" style={styles.iconButton} size="large">
                <LabelIcon />
              </IconButton>
            }
            anchorOrigin={{ horizontal: "left", vertical: "top" }}
            targetOrigin={{ horizontal: "left", vertical: "top" }}>
            {promotionTagMenu({
              tags: this.props.db.meta.promotionTags,
              value: this.getTagValue(),
              onClick: this.onTagClick
            })}
          </IconMenu>
        )}
        {this.props.interface == 1 &&
          this.props.selectedProms.length > 0 &&
          this.props.db.permissions.includes("close") && (
            <IconMenu
              iconButtonElement={
                <IconButton
                  tooltip="Mark as"
                  style={styles.iconButton}
                  size="large">
                  <LockIcon />
                </IconButton>
              }
              anchorOrigin={{ horizontal: "left", vertical: "top" }}
              targetOrigin={{ horizontal: "left", vertical: "top" }}>
              <MenuItem
                value="close"
                children="Close Promotions"
                onClick={this.onMarkAsMenuChange("close")}
                rightIcon={<LockIcon sx={{ color: "warning.main" }} />}
              />
              <MenuItem
                value="open"
                children="Open Promotions"
                onClick={this.onMarkAsMenuChange("open")}
                rightIcon={<LockOpenIcon sx={{ color: "success.main" }} />}
              />
            </IconMenu>
          )}
        <IconMenu
          iconButtonElement={
            <IconButton
              tooltip="Settings"
              style={styles.iconButton}
              size="large">
              <SettingsIcon />
            </IconButton>
          }
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
          targetOrigin={{ horizontal: "right", vertical: "top" }}>
          <MenuItem
            children={<Typography variant="subtitle1">Export</Typography>}
            disabled
          />
          <MenuItem
            value={0}
            children="Download Promotions"
            leftIcon={<CloudDownloadIcon />}
            onClick={() => {
              Mixpanel.track("Promotions Exported");
              exportPromotions(
                this.props.db.companyid,
                this.props.openClose,
                this.props.promKeyList
              );
              this.props.openClose.setAppModal(
                "Export In Progress",
                <div>
                  Your export is being prepared and will be emailed to you
                  shortly.
                </div>,

                <div>
                  <Button
                    label="Ok"
                    onClick={() => {
                      this.props.openClose.closeAppModal();
                    }}
                  />
                </div>
              );
            }}
          />
        </IconMenu>
        {this.props.db.permissions.includes("promotions") &&
          !this.props.readOnly && (
            <IconButton
              onClick={this.addPromotion}
              tooltip="Add Promotion"
              style={styles.iconButton}
              size="large">
              <AddBoxIcon />
            </IconButton>
          )}
      </ToolbarGroup>
    );
  };

  resetFields = () => {
    this.setState({ query: "" });
    if (this.searchPromotionsRef.current) {
      this.searchPromotionsRef.current.setState({ searchProm: {} });
    }
    this.props.setSearchProm({});
    this.props.clearSelectedProms();
    this.props.handleSearch("");
  };

  componentDidMount() {
    const user = firebase.auth().currentUser;

    // Get the user's access level
    if (user) {
      getCompanyUsers(companyUsers => {
        const { uid } = user;
        this.setState({
          access: companyUsers[uid].access,
          customPerms: companyUsers[uid].customPerms
        });
      });
    }
  }

  render() {
    // this.props.mode is 0 (Planning), or 1 (Execution)
    const rightJSX = this.getRightJSX();
    const modeToTitle = {
      0: "Planning",
      1: "Executing Promotions",
      2: "Closed Promotions",
      3: "Declined Promotions",
      4: "Cancelled Promotions"
    };

    return (
      <div>
        <Toolbar>
          <ToolbarGroup>
            <ToolbarTitle text={modeToTitle[this.props.mode]} />
            <ToolbarSeparator />
            {this.props.mode == 0 && ( // Only show in Planning mode
              <IconMenu
                iconButtonElement={
                  <IconButton
                    tooltip="Select Interface"
                    style={styles.iconButton}
                    size="large">
                    <DesktopWindowsIcon />
                  </IconButton>
                }
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
                targetOrigin={{ horizontal: "right", vertical: "top" }}>
                <MenuItem
                  value={0}
                  children="Columns"
                  leftIcon={<ViewColumnIcon />}
                  rightIcon={this.props.interface == 0 ? <CheckIcon /> : null}
                  onClick={this.props.handleSelectInterface.bind(null, 0)}
                />
                <MenuItem
                  value={1}
                  children="List"
                  leftIcon={<SortIcon />}
                  rightIcon={this.props.interface == 1 ? <CheckIcon /> : null}
                  onClick={this.props.handleSelectInterface.bind(null, 1)}
                />
                {this.props.db && this.props.db.meta.tier != "pine" && (
                  <MenuItem
                    value={2}
                    children="Submission"
                    leftIcon={<WatchLaterIcon />}
                    rightIcon={this.props.interface == 2 ? <CheckIcon /> : null}
                    onClick={this.props.handleSelectInterface.bind(null, 2)}
                  />
                )}
                {this.props.db && this.props.db.meta.tier != "pine" && (
                  <MenuItem
                    value={3}
                    children="Approval"
                    leftIcon={<ThumbUpIcon />}
                    rightIcon={this.props.interface == 3 ? <CheckIcon /> : null}
                    onClick={this.props.handleSelectInterface.bind(null, 3)}
                  />
                )}
                {this.props.db && (
                  <MenuItem
                    value={4}
                    children="Advanced Duplication"
                    leftIcon={<EmojiObjectsOutlinedIcon />}
                    rightIcon={this.props.interface == 4 ? <CheckIcon /> : null}
                    onClick={this.props.handleSelectInterface.bind(null, 4)}
                  />
                )}
                {this.props.db && (
                  <MenuItem
                    value={5}
                    children="Advanced Editing"
                    leftIcon={<FindReplaceIcon />}
                    rightIcon={this.props.interface == 5 ? <CheckIcon /> : null}
                    disabled={!this.props.db.permissions.includes("masteredit")}
                    onClick={this.props.handleSelectInterface.bind(null, 5)}
                  />
                )}
                {this.props.db && (
                  <MenuItem
                    value={6}
                    children="Calendar"
                    leftIcon={<CalendarTodayIcon />}
                    rightIcon={this.props.interface == 6 ? <CheckIcon /> : null}
                    onClick={this.props.handleSelectInterface.bind(null, 6)}
                  />
                )}
              </IconMenu>
            )}
            {this.props.showSearch ? (
              <div>
                <IconButton
                  onClick={() => {
                    this.props.showRightDrawer(
                      <SearchPromotions
                        setSearchProm={this.props.setSearchProm}
                        searchProm={this.props.searchProm}
                        db={this.props.db}
                        openClose={this.props.openClose}
                        ref={this.searchPromotionsRef}
                      />
                    );
                    this.props.clearSelectedProms();
                  }}
                  tooltip="Advanced Search"
                  style={styles.iconButton}
                  size="large">
                  <ZoomInIcon />
                </IconButton>
              </div>
            ) : (
              <IconButton
                onClick={this.props.handleSearchButton}
                tooltip="Search"
                style={styles.iconButton}
                disabled={this.props.interface == 5}
                size="large">
                <SearchIcon />
              </IconButton>
            )}
            {this.props.showSearch && (
              <Stack>
                <TextField
                  variant="standard"
                  placeholder="Enter Query"
                  style={{ marginTop: 0 }}
                  onChange={event => {
                    this.setState({ query: event.target.value });
                  }}
                  onKeyPress={ev => {
                    if (ev.key === "Enter") {
                      this.props.clearSelectedProms();
                      this.props.handleSearch(this.state.query);
                    }
                  }}
                  value={this.state.query}
                />
                <Button
                  label="Search"
                  onClick={() => {
                    this.props.clearSelectedProms();
                    this.props.handleSearch(this.state.query);
                  }}
                />
                <Button
                  variant="text"
                  color="error"
                  label="Reset Fields"
                  onClick={this.resetFields}
                />
              </Stack>
            )}
          </ToolbarGroup>

          {rightJSX}
        </Toolbar>
      </div>
    );
  }
}

export default PromToolbar;
