/* 
This is a running list of all of the backup sources supported by our DSM. 

Each entry maps: FORMAT_NAME : {
  DISPLAY_NAME: string --> name showed on front-end to user
  SCAN_NAME: string; --> name used in backend functions
  FILES: string; --> example files stored in Sample Files > Deductions Scanning Templates
}

DO NOT CHANGE SCAN_NAME, this must be held constant! Display name can be modified as desired.
*/
import { firebase } from "helpers/Firebase";
import { useState, useEffect } from "react";

export interface BackupSourceObject {
  DISPLAY_NAME: string;
  SCAN_NAME: string;
  FILES: string[];
  CAN_CLASSIFY: boolean;
  UUID_KEY: string;
  OLD_FIREBASE_KEY: string;
}

export interface BackupSourceCollection {
  [backupSource: string]: BackupSourceObject;
}

export const importBackupSources = async () => {
  try {
    const snapshot = await firebase
      .database()
      .ref("deduction_template_data/backupSource/")
      .get();
    const backupSources = snapshot.val();
    Object.keys(backupSources).forEach(key => {
      backupSources[key].UUID_KEY = key;
    });
    return { ...backupSources };
  } catch (e) {
    console.error(e);

    // return empty object in case we're unable to fetch backup sources from firebase
    return {};
  }
};

export const useBackupSources = (backupObj = {}) => {
  const [allBackupSources, setAllBackupSources] = useState(backupObj);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const asyncFun = async () => {
      const backupSource = await importBackupSources();
      setAllBackupSources(backupSource);
      setIsLoading(false);
    };
    asyncFun();
  }, []);

  return { allBackupSources, isLoading };
};
