import React from "react";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Subheader from "ui-library/Subheader";
import Button from "ui-library/Button";
import DatePicker from "ui-library/DatePicker";
import Select from "ui-library/Select";
import MenuItem from "ui-library/MenuItem";
import { Stack } from "@mui/material";

const styles = {
  header: {
    marginRight: "-50px",
    fontSize: 20
  }
};

class AdvancedDeletion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteAll: !this.props.deleteStartDate && !this.props.deleteEndDate,
      deleteStartDate: null,
      deleteEndDate: null,
      deleteRevenueSources: null
    };
  }

  componentDidMount() {
    this.setState({
      revenue: this.props.revenue,
      revenueSourcesJSX: this.getRevenueSourcesJSX()
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      revenue: nextProps.revenue,
      revenueSourcesJSX: this.getRevenueSourcesJSX()
    });
  }

  getRevenueSourcesJSX = () => {
    const revenueSources = this.props.db.meta.revenue_sources;
    const revenueSourcesJSX = [];
    revenueSourcesJSX.push(
      <MenuItem value="None" key="None">
        None
      </MenuItem>
    );
    if (revenueSources) {
      for (const i in revenueSources) {
        revenueSourcesJSX.push(
          <MenuItem value={revenueSources[i]} key={i}>
            {revenueSources[i]}
          </MenuItem>
        );
      }
    }
    return revenueSourcesJSX;
  };

  render() {
    return (
      <div>
        <div>
          <FormControlLabel
            sx={{ m: 0 }}
            control={
              <Checkbox
                checked={this.state.deleteAll}
                onChange={() =>
                  this.setState({
                    deleteAll: true,
                    deleteStartDate: null,
                    deleteEndDate: null,
                    deleteRevenueSources: null
                  })
                }
              />
            }
            label={`Delete all ${this.props.mode} entries.`}
          />
          <Subheader style={styles.header}>
            Delete Entries Between Two Dates
          </Subheader>
          <div className="rowC">
            <div style={{ width: "50%", paddingRight: 16 }}>
              <DatePicker
                firstDayOfWeek={0}
                id="startDate"
                floatingLabelText="Start Date"
                onChange={date => {
                  this.setState({ deleteStartDate: date, deleteAll: false });
                }}
                maxDate={this.state.deleteEndDate}
                value={this.state.deleteStartDate}
                fullWidth
                autoOk
              />
            </div>
            <div style={{ width: "50%" }}>
              <DatePicker
                firstDayOfWeek={0}
                id="endDate"
                floatingLabelText="End Date"
                onChange={date =>
                  this.setState({ deleteEndDate: date, deleteAll: false })
                }
                minDate={this.state.deleteStartDate}
                value={this.state.deleteEndDate}
                fullWidth
                autoOk
              />
            </div>
          </div>
          {this.state.revenue ? (
            <div>
              <Subheader style={styles.header}>
                Delete Certain Revenue Sources
              </Subheader>
              <br />
              <Select
                id="revenueSources"
                floatingLabelText="Revenue Sources"
                onChange={(event, index, value) => {
                  this.setState({
                    deleteRevenueSources: value,
                    deleteAll: false
                  });
                }}
                value={this.state.deleteRevenueSources}
                multiple
                fullWidth>
                {this.state.revenueSourcesJSX}
              </Select>
            </div>
          ) : (
            <div />
          )}
        </div>
        <br />
        <div className="centering">
          <Button
            label="Done"
            primary
            style={{ marginTop: 20, width: "100%" }}
            disabled={this.state.error}
            onClick={() => {
              this.props.openClose.setAppModal(
                "Confirm Deletion",
                <div className="centering">
                  Are you sure you want to perform this advanced deletion
                  operation?
                </div>,

                <Stack>
                  <Button
                    label="Yes, I'm sure"
                    variant="text"
                    color="error"
                    onClick={() => {
                      this.props.deletion(
                        this.state.deleteAll,
                        this.state.deleteStartDate,
                        this.state.deleteEndDate,
                        this.state.deleteRevenueSources
                      );
                    }}
                  />
                  <Button
                    label="No, go back"
                    variant="text"
                    onClick={() => {
                      this.props.openClose.closeAppModal();
                      this.props.noGoBack(
                        this.state.deleteStartDate,
                        this.state.deleteEndDate,
                        this.state.deleteRevenueSources
                      );
                    }}
                  />
                </Stack>
              );
            }}
          />
        </div>
      </div>
    );
  }
}

export default AdvancedDeletion;
