import React, { useMemo } from "react";
import { useDb } from "contexts/Db";
import { DisplayResolutionLineObject } from "reconciliation-types/resolutionLineTypes";
import { Grid, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ResolutionLineDisplayService } from "reconciliation-services";
import { resolutionLineTypes } from "deductions-constants/ReconciliationTypes";
import { displayUSCurrency } from "helpers/DataProcessing";
import CollapsibleTable, {
  ColumnConfigOptions
} from "ui-library/CollapsibleTable/CollapsibleTable";
import moment from "moment";
import { renderUTCDateTimeInLocalTimeZone } from "helpers/Time";
import RepaymentLineHistoryTable from "./RepaymentLineHistory";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  headerGrid: {
    display: "flex",
    justifyContent: "space-between"
  },
  headerGridContainer: {
    paddingBottom: theme.spacing(1)
  },
  tableGridContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
}));
export interface ResolutionLineHistoryProps {
  invoiceLineKey: string;
  invoiceKey: string;
}

export default function ResolutionLineHistory(
  props: ResolutionLineHistoryProps
): JSX.Element {
  const classes = useStyles();
  const db = useDb();
  const { invoices } = db;
  const { invoiceLineKey, invoiceKey } = props;

  const invoice = invoices[invoiceKey];
  const invoiceLine = (invoice.invoiceLines || {})[invoiceLineKey] || {};

  const resolutionLinesList: DisplayResolutionLineObject[] = useMemo(() => {
    return Object.values(invoiceLine.resolutionLines || {})
      .map(
        resLineKey => (invoice.resolutionLines || {})[resLineKey] || undefined
      )
      .filter(resLine => resLine)
      .map(resolutionLine =>
        ResolutionLineDisplayService.processResolutionLineForDisplay(
          resolutionLine,
          db
        )
      );
  }, [invoice.resolutionLines, invoiceLine.resolutionLines, db]);

  const needsAttentionLines = useMemo(() => {
    return resolutionLinesList
      .filter(
        (resolutionLine: DisplayResolutionLineObject) =>
          resolutionLine.type === resolutionLineTypes.DISPUTE ||
          resolutionLine.type === resolutionLineTypes.SALES_REVIEW
      )
      .sort((resolutionLine1, resolutionLine2) => {
        return moment(resolutionLine2.openedDate).diff(
          moment(resolutionLine1.openedDate)
        );
      });
  }, [resolutionLinesList]);

  const resolutionHistoryLines = useMemo(() => {
    return resolutionLinesList
      .filter(
        (resolutionLine: DisplayResolutionLineObject) =>
          resolutionLine.type === resolutionLineTypes.CLEAR ||
          resolutionLine.type === resolutionLineTypes.WRITE_OFF
      )
      .sort((resolutionLine1, resolutionLine2) => {
        return moment(resolutionLine2.closedDate).diff(
          moment(resolutionLine1.closedDate)
        );
      });
  }, [resolutionLinesList]);

  const resolutionColumnConfig = useMemo(
    () =>
      new Map([
        [
          "openedDate",
          {
            name: "Date",
            render: val =>
              !val ? "N/A" : renderUTCDateTimeInLocalTimeZone(val as string)
          }
        ],
        [
          "displayType",
          {
            name: "Activity",
            render: val => (!val ? "N/A" : val)
          }
        ],
        [
          "amount",
          {
            name: "Amount",
            render: val => (!val ? "N/A" : displayUSCurrency(val))
          }
        ],
        [
          "fundTypeName",
          {
            name: "Fund Type",
            render: val => (!val ? "N/A" : val)
          }
        ],
        [
          "fundTypeAccount",
          {
            name: "GL Account",
            render: val => (!val ? "N/A" : db.accounts?.[val]?.name)
          }
        ],
        [
          "openedUser",
          {
            name: "User",
            render: val => db.companyUsers[val as string]?.name || "Unknown"
          }
        ],
        [
          "openedComment",
          {
            name: "Comment"
          }
        ]
      ] as [keyof DisplayResolutionLineObject, ColumnConfigOptions<DisplayResolutionLineObject>][]),
    [db.companyUsers, db.accounts]
  );

  const needsAttentionColumnConfig = useMemo(
    () =>
      new Map([
        [
          "openedDate",
          {
            name: "Date",
            render: val =>
              !val ? "N/A" : renderUTCDateTimeInLocalTimeZone(val as string)
          }
        ],
        [
          "displayType",
          {
            name: "Activity",
            render: val => (!val ? "N/A" : val)
          }
        ],
        [
          "openedUser",
          {
            name: "User",
            render: val => db.companyUsers[val as string]?.name || "Unknown"
          }
        ],
        [
          "openedComment",
          {
            name: "Comment"
          }
        ]
      ] as [keyof DisplayResolutionLineObject, ColumnConfigOptions<DisplayResolutionLineObject>][]),
    [db.companyUsers]
  );

  return (
    <Grid container className={classes.headerGridContainer}>
      <Grid item xs={10} className={classes.headerGrid}>
        <Typography variant="h6">Needs Attention</Typography>
      </Grid>
      <Grid item xs={11} className={classes.tableGridContainer}>
        <CollapsibleTable<DisplayResolutionLineObject>
          data={needsAttentionLines}
          columnConfig={needsAttentionColumnConfig}
          index="key"
        />
      </Grid>
      <Grid item xs={10} className={classes.headerGrid}>
        <Typography variant="h6">Resolution History</Typography>
      </Grid>
      <Grid item xs={11} className={classes.tableGridContainer}>
        <CollapsibleTable<DisplayResolutionLineObject>
          data={resolutionHistoryLines}
          columnConfig={resolutionColumnConfig}
          index="key"
        />
      </Grid>
      <Grid item xs={10} className={classes.headerGrid}>
        <Typography variant="h6">Repayment History</Typography>
      </Grid>
      <Grid item xs={11} className={classes.tableGridContainer}>
        <RepaymentLineHistoryTable
          invoiceKey={invoiceKey}
          invoiceLineKey={invoiceLineKey}
        />
      </Grid>
    </Grid>
  );
}
