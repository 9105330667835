import React, { useMemo } from "react";
import {
  ButtonBase,
  Card,
  CardContent,
  Divider,
  Link,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { displayUSCurrency } from "helpers/DataProcessing";
import moment from "moment";
import { DisplayERPTransactionObject } from "components/Deductions/DeductionsReconciliation/types/transactionTypes";
import {
  isUserCresicorEmployee,
  getFirebaseConsoleURL
} from "helpers/Firebase";
import { useDb } from "contexts/Db";
import { ERPTransactionTypes } from "components/Deductions/constants/ReconciliationTypes";
import { renderUTCDateTimeInLocalTimeZone } from "helpers/Time";

const useStyles = makeStyles(() => ({
  card: {
    marginLeft: "25px",
    marginRight: "25px",
    marginTop: "15px",
    fontFamily: "Oxygen"
  },
  transactionHeader: {
    lineHeight: "20.2px",
    fontWeight: 700,
    marginBottom: "8px"
  },
  info: {
    fontSize: "1.25rem",
    fontWeight: 400
  },
  amount: {
    lineHeight: "20.2px"
  },
  amountContainer: {
    display: "flex",
    paddingTop: "10px"
  },
  divider: {
    marginLeft: "15px",
    marginRight: "15px"
  },
  buttonBase: {
    display: "block",
    textAlign: "initial",
    width: "100%"
  }
}));

interface TransactionCardProps {
  transaction: DisplayERPTransactionObject;
  interactive?: boolean;
}

export default function TransactionCard(
  props: TransactionCardProps
): JSX.Element {
  const classes = useStyles();
  const { transaction, interactive } = props;
  const history = useHistory();
  const db = useDb();

  const firebaseConsoleURL = `${getFirebaseConsoleURL()}/companies/${
    db.companyid
  }/reconciliation/transactions/${transaction.key}`;

  const displayTransactionHeader = useMemo(() => {
    switch (transaction.type) {
      case ERPTransactionTypes.REPAYMENT: {
        return "Repayment";
      }
      case ERPTransactionTypes.DEDUCTION: {
        return "Deduction";
      }
      default: {
        return "Transaction";
      }
    }
  }, [transaction.type]);

  const cardContent = (
    <div>
      <Typography variant="h6" className={classes.transactionHeader}>
        {interactive || !isUserCresicorEmployee() ? (
          <>
            {displayTransactionHeader}
            &nbsp;No.&nbsp;
            {transaction.transactionDisplayId}
          </>
        ) : (
          <Link href={firebaseConsoleURL} target="_blank" underline="hover">
            {displayTransactionHeader}
            &nbsp;No.&nbsp;
            {transaction.transactionDisplayId}
          </Link>
        )}
      </Typography>
      {!interactive && (
        <Typography color="textSecondary" variant="caption">
          Status: {transaction.displayStatus} | Type: {transaction.displayType}
        </Typography>
      )}
      <Typography className={classes.info}>
        {transaction.customerName}
      </Typography>
      <div className={classes.amountContainer}>
        <div>
          <Typography className={classNames(classes.info, classes.amount)}>
            {displayUSCurrency(transaction.amount)}
          </Typography>
          <Typography variant="button" color="textSecondary">
            Original Amount
          </Typography>
        </div>
        <div className={classes.divider}>
          <Divider orientation="vertical" />
        </div>
        <div>
          <Typography className={classNames(classes.info, classes.amount)}>
            {displayUSCurrency(transaction.openAmount)}
          </Typography>
          <Typography variant="button" color="textSecondary">
            {transaction.type === ERPTransactionTypes.REPAYMENT
              ? "Available"
              : "Open"}
            &nbsp;Amount
          </Typography>
        </div>
        {!interactive && (
          <>
            <div className={classes.divider}>
              <Divider orientation="vertical" />
            </div>
            <div>
              <Typography className={classNames(classes.info, classes.amount)}>
                {moment(transaction.transactionDate).format("MMM D, YYYY")}
              </Typography>
              <Typography variant="button" color="textSecondary">
                Transaction Date
              </Typography>
            </div>
          </>
        )}
      </div>
      {/* {!interactive && transaction.type === ERPTransactionTypes.DEDUCTION && (
        <div className={classes.amountContainer}>
          <div>
            <Typography className={classNames(classes.info, classes.amount)}>
              {displayUSCurrency(transaction.disputeAmount)}
            </Typography>
            <Typography variant="button" color="textSecondary">
              Disputed Amount
            </Typography>
          </div>
          <div className={classes.divider}>
            <Divider orientation="vertical" />
          </div>
          <div>
            <Typography className={classNames(classes.info, classes.amount)}>
              {displayUSCurrency(transaction.writtenOffAmount)}
            </Typography>
            <Typography variant="button" color="textSecondary">
              Written-Off Amount
            </Typography>
          </div>
        </div>
      )} */}
      <div>
        <Typography color="textSecondary" variant="caption">
          {interactive && (
            <>
              Transaction Date:&nbsp;
              {moment(transaction.transactionDate).format("MMM D, YYYY")}
            </>
          )}
        </Typography>
      </div>
      <div>
        <Typography color="textSecondary" variant="caption">
          Date Imported:&nbsp;
          {transaction.createdDate
            ? renderUTCDateTimeInLocalTimeZone(transaction.createdDate)
            : ""}
        </Typography>
      </div>
    </div>
  );

  return interactive ? (
    <Card className={classes.card}>
      <ButtonBase
        className={classes.buttonBase}
        onClick={(): void => {
          history.push(`/reconciliation/transaction/${transaction.key}`);
        }}>
        <CardContent>{cardContent}</CardContent>
      </ButtonBase>
    </Card>
  ) : (
    cardContent
  );
}
