import React from "react";
import { round, sum, map } from "lodash-es";

import ReactTable from "react-table";

import { displayMoney } from "helpers/DataProcessing";

import { red } from "@mui/material/colors";

const red100 = red["100"];

const months = [
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec"
];

class MoneyTableByDate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: []
    };
  }

  addCommas = num => {
    return num.toLocaleString();
  };

  renderCell = row => {
    const moneyCols = ["Actual", "Expected", "diff"];
    return (
      <div style={{ backgroundColor: this.getBackgroundColor(row.original) }}>
        {moneyCols.indexOf(row.column.id) != -1 && this.props.isMoney
          ? displayMoney(row.value)
          : row.value.toLocaleString()}
      </div>
    );
  };

  getBackgroundColor = rowValues => {
    if (
      false &&
      (rowValues.diff < this.props.diffCutoff ||
        rowValues.percent < this.props.percentCutoff)
    ) {
      // turning this off for now
      return red100;
    }
    return null;
  };

  getColumns = props => {
    const display = props.isMoney ? displayMoney : this.addCommas;
    this.setState({
      columns: [
        {
          Header: "Pivot Column",
          columns: [
            {
              Header: "Date",
              accessor: "date",
              render: row => <div>{row.value}</div>
            }
          ]
        },
        {
          Header: "Data",
          columns: [
            {
              Header: "Actual",
              accessor: "Actual",
              Cell: this.renderCell,
              Footer: (
                <span>
                  {display(round(sum(map(props.data, d => d.Actual))))}
                </span>
              )
            },
            {
              Header: "Expected",
              accessor: "Expected",
              Cell: this.renderCell,
              Footer: (
                <span>
                  {display(round(sum(map(props.data, d => d.Expected))))}
                </span>
              )
            },
            {
              Header: "Differential",
              accessor: "diff",
              Cell: this.renderCell,
              Footer: (
                <span>{display(round(sum(map(props.data, d => d.diff))))}</span>
              )
            },
            {
              Header: "Diff (%)",
              accessor: "percent",
              Cell: this.renderCell
            }
          ]
        }
      ]
    });
  };

  componentDidMount() {
    this.getColumns(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getColumns(nextProps);
  }

  render() {
    return (
      <ReactTable
        data={this.props.data}
        columns={this.state.columns}
        defaultPageSize={12}
        defaultSortMethod={(a, b) => {
          let first = a === null || a === undefined ? "" : a;
          let second = b === null || b === undefined ? "" : b;
          first = typeof a === "string" ? a.toLowerCase() : a;
          second = typeof b === "string" ? b.toLowerCase() : b;

          if (months.includes(first) && months.includes(second)) {
            return months.indexOf(first) > months.indexOf(second) ? 1 : -1;
          }

          if (first > second) {
            return 1;
          }
          if (first < second) {
            return -1;
          }

          return 0;
        }}
        style={{ fontFamily: "PT sans" }}
      />
    );
  }
}

export default MoneyTableByDate;
