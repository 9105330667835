import moment from "moment";
import { firebase } from "helpers/Firebase";

function getForecastData(companyid, key, callback, listen = true) {
  const firebaseForecast = firebase.app("forecast");
  const forecastRef = firebaseForecast.database().ref(`${companyid}/${key}`);
  if (listen) {
    const callbackWrapper = snapshot => {
      callback(snapshot);
    };
    forecastRef.on("value", callbackWrapper);
    // Use returned function to unsubscribe from update
    return () => forecastRef.off("value", callbackWrapper);
  }
  forecastRef.once("value", snapshot => {
    callback(snapshot);
  });
}

function getStores(companyid, pivot, key, callback, listen = true) {
  const storesRef = firebase
    .database()
    .ref(`companies/${companyid}/forecast/stores_by_${pivot}/${key}`);
  if (listen) {
    storesRef.on("value", snapshot => {
      callback(snapshot);
    });
  } else {
    storesRef.once("value", snapshot => {
      callback(snapshot);
    });
  }
}

function getVelocity(companyid, pivot, key, callback, listen = true) {
  const velocityRef = firebase
    .database()
    .ref(`companies/${companyid}/forecast/velocity_by_${pivot}/${key}`);
  if (listen) {
    velocityRef.on("value", snapshot => {
      callback(snapshot);
    });
  } else {
    velocityRef.once("value", snapshot => {
      callback(snapshot);
    });
  }
}

function getBaseSales(companyid, pivot, key, callback, listen = true) {
  const baseSalesRef = firebase
    .database()
    .ref(`companies/${companyid}/forecast/baseSales_by_${pivot}/${key}`);
  if (listen) {
    baseSalesRef.on("value", snapshot => {
      callback(snapshot);
    });
  } else {
    baseSalesRef.once("value", snapshot => {
      callback(snapshot);
    });
  }
}

function totalSalesUpdate(
  companyid,
  product,
  customer,
  weeks,
  oldSalesVals,
  salesUpdate,
  tableType
) {
  // update totals
  const firebaseForecast = firebase.app("forecast");
  const dataPaths = [
    `${companyid}/${tableType}_forecast_table_by_customer/Total/sales/${product}`,
    `${companyid}/${tableType}_forecast_table_by_customer/${customer}/sales/Total`,
    `${companyid}/${tableType}_forecast_table_by_product/Total/sales/${customer}`,
    `${companyid}/${tableType}_forecast_table_by_product/${product}/sales/Total`,
    `${companyid}/${tableType}_forecast_table_by_product/${product}/sales/Total`,
    `${companyid}/${tableType}_forecast_table_by_customer/Total/sales/Total`,
    `${companyid}/${tableType}_forecast_table_by_product/Total/sales/Total`
  ];
  Promise.all(
    dataPaths.map(path => firebaseForecast.database().ref(path).once("value"))
  ).then(dataRefs => {
    for (let i = 0; i < dataRefs.length; i++) {
      const totalData = dataRefs[i].val() || {};
      const updatedTotalData = {};
      for (const weekInd in salesUpdate) {
        const salesVal = salesUpdate[weekInd];
        const week = weeks[weekInd];
        const oldSalesVal = oldSalesVals[week] || 0;
        updatedTotalData[weekInd] =
          (totalData[weekInd] || 0) + (salesVal - oldSalesVal);
      }
      firebaseForecast.database().ref(dataPaths[i]).update(updatedTotalData);
    }
  });
}

function distributorSalesUpdate(
  companyid,
  product,
  customer,
  weeks,
  forecastByPivot,
  salesUpdate,
  companyMeta
) {
  // compute the update to the distributor sales forecast due to the changes to retailer/all-other forecast
  const firebaseForecast = firebase.app("forecast");
  // get distributor metadata from customer
  if (!("distributors" in customer)) {
    return;
  }
  let breakdowns = {};
  // default breakdowns
  const { distributors } = customer;
  for (var distributor of distributors) {
    breakdowns[distributor] = 1 / distributors.length;
  }
  // override by breakdown over all products
  if ("spendPerDistributor" in customer) {
    breakdowns = customer.spendPerDistributor;
    for (var distributor in breakdowns) {
      breakdowns[distributor] /= 100;
    }
  }
  // override by breakdown over specific product groups
  if ("spendPerDistributorPG" in customer) {
    const pgGroupBreakdowns = customer.spendPerDistributorPG;
    const productGroups = companyMeta.product_groups;
    for (const pgKey in pgGroupBreakdowns) {
      const pgProducts = productGroups[pgKey].products;
      if (product in pgProducts) {
        for (var distributor in pgGroupBreakdowns[pgKey]) {
          breakdowns[distributor] = pgGroupBreakdowns[pgKey][distributor] / 100;
        }
      }
    }
  }
  const weeksToRetail = customer.weeksToRetail || 0;
  // update the distributor forecast
  const updateByCustomer = {};
  const updateByProduct = {};
  const dataPaths = [];
  for (var distributor in breakdowns) {
    dataPaths.push(
      ...[
        `${companyid}/distributor_forecast_table_by_customer/${distributor}/forecastBegin/${product}`,
        `${companyid}/distributor_forecast_table_by_customer/${distributor}/sales/${product}`,
        `${companyid}/distributor_forecast_table_by_customer/${distributor}/lift/${product}`
      ]
    );
  }
  Promise.all(
    dataPaths.map(path => firebaseForecast.database().ref(path).once("value"))
  ).then(dataRefs => {
    for (const [i, distributor] of Object.entries(Object.keys(breakdowns))) {
      const percentage = breakdowns[distributor];
      const forecastBeginDistributor = new Date(
        dataRefs[3 * i].val() || weeks[0]
      );
      const salesDistributor = dataRefs[3 * i + 1].val() || {};
      const liftDistributor = dataRefs[3 * i + 2].val() || {};
      const oldSalesVals = {};
      const distributorSalesUpdate = {};
      for (const weekInd in salesUpdate) {
        const salesVal = salesUpdate[weekInd];
        const week = weeks[weekInd];
        const oldSalesVal = forecastByPivot.sales[product][week];
        oldSalesVals[week] = oldSalesVal;
        const distributorWeek = moment(week)
          .subtract("days", 7 * weeksToRetail)
          .toDate();
        const distributorWeekInd = weekInd - weeksToRetail;
        if (distributorWeek >= forecastBeginDistributor) {
          const liftVal = liftDistributor[distributorWeekInd] || 0;
          const updatedSalesVal = parseInt(
            (salesDistributor[distributorWeekInd] || 0) +
              (1 + liftVal) * percentage * (salesVal - oldSalesVal)
          );
          distributorSalesUpdate[weekInd] = updatedSalesVal;
          updateByCustomer[
            `${distributor}/sales/${product}/${distributorWeekInd}`
          ] = updatedSalesVal;
          updateByProduct[
            `${product}/sales/${distributor}/${distributorWeekInd}`
          ] = updatedSalesVal;
          updateByCustomer[
            `${distributor}/salesForecastHistorical/${product}/${distributorWeekInd}`
          ] = updatedSalesVal;
          updateByProduct[
            `${product}/salesForecastHistorical/${distributor}/${distributorWeekInd}`
          ] = updatedSalesVal;
        }
      }
      totalSalesUpdate(
        companyid,
        product,
        distributor,
        weeks,
        oldSalesVals,
        distributorSalesUpdate,
        "distributor"
      );
    }
    firebaseForecast
      .database()
      .ref(`${companyid}/distributor_forecast_table_by_customer/`)
      .update(updateByCustomer);
    firebaseForecast
      .database()
      .ref(`${companyid}/distributor_forecast_table_by_product/`)
      .update(updateByProduct);
  });
}

function updateStores(
  companyid,
  customerKey,
  product,
  customer,
  week,
  val,
  enteredStores,
  forecastData,
  companyMeta,
  pivot,
  tableType
) {
  const rowType = pivot == "customer" ? "product" : "customer";
  const pivotKey = pivot == "customer" ? product : customerKey;
  const storesByCustomerRef = firebase
    .database()
    .ref(
      `companies/${companyid}/forecast/stores_by_customer/${customerKey}/${product}`
    );
  const storesByProductRef = firebase
    .database()
    .ref(
      `companies/${companyid}/forecast/stores_by_product/${product}/${customerKey}`
    );
  const update = {};
  update[week] = val;
  storesByCustomerRef.update(update);
  storesByProductRef.update(update);
  getForecastData(
    companyid,
    "meta",
    metaSnapshot => {
      const meta = metaSnapshot.val();
      const { weeks } = meta;
      const weekIndex = weeks.indexOf(week);
      const forecastByPivot = {};
      for (const field in forecastData) {
        forecastByPivot[field] = {};
        for (const data of forecastData[field]) {
          const key = data[rowType];
          forecastByPivot[field][key] = data;
        }
      }
      // update stores and sales values changed by this update
      const firebaseForecast = firebase.app("forecast");
      const forecastStoresByCustomerRef = firebaseForecast
        .database()
        .ref(
          `${companyid}/${tableType}_forecast_table_by_customer/${customerKey}/stores/${product}`
        );
      const forecastStoresByProductRef = firebaseForecast
        .database()
        .ref(
          `${companyid}/${tableType}_forecast_table_by_product/${product}/stores/${customerKey}`
        );
      const forecastSalesByCustomerRef = firebaseForecast
        .database()
        .ref(
          `${companyid}/${tableType}_forecast_table_by_customer/${customerKey}/sales/${product}`
        );
      const forecastSalesByProductRef = firebaseForecast
        .database()
        .ref(
          `${companyid}/${tableType}_forecast_table_by_product/${product}/sales/${customerKey}`
        );
      // update stores/velocity/sales fields
      const storesUpdate = {};
      const salesUpdate = {};
      let entered = {};
      let velocityVals = {};
      let liftVals = {};
      let seasonalityVals = {};
      if (pivotKey in enteredStores) {
        entered = enteredStores[pivotKey];
      }
      if (
        "velocity" in forecastByPivot &&
        pivotKey in forecastByPivot.velocity
      ) {
        velocityVals = forecastByPivot.velocity[pivotKey];
      }
      if ("lift" in forecastByPivot && pivotKey in forecastByPivot.lift) {
        liftVals = forecastByPivot.lift[pivotKey];
      }
      if (
        "seasonality" in forecastByPivot &&
        pivotKey in forecastByPivot.seasonality
      ) {
        seasonalityVals = forecastByPivot.seasonality[pivotKey];
      }
      for (let i = weekIndex; i < weeks.length; i++) {
        if (weeks[i] in entered && i > weekIndex) {
          break;
        }
        const velocityVal = velocityVals[weeks[i]] || 0;
        const liftVal = liftVals[weeks[i]] || 0;
        const seasonalityVal = seasonalityVals[weeks[i]] || 1;
        storesUpdate[i] = val;
        salesUpdate[i] =
          val !== null
            ? Math.round(val * velocityVal * (1 + liftVal) * seasonalityVal)
            : null;
      }
      // update database for product/customer
      forecastStoresByCustomerRef.update(storesUpdate);
      forecastStoresByProductRef.update(storesUpdate);
      forecastSalesByCustomerRef.update(salesUpdate);
      forecastSalesByProductRef.update(salesUpdate);
      // update database for distributor
      distributorSalesUpdate(
        companyid,
        product,
        customer,
        weeks,
        forecastByPivot,
        salesUpdate,
        companyMeta
      );
      // update database for totals
      const oldSalesVals = forecastByPivot.sales[pivotKey] || {};
      totalSalesUpdate(
        companyid,
        product,
        customerKey,
        weeks,
        oldSalesVals,
        salesUpdate,
        tableType
      );
    },
    false
  );
}

function updateVelocity(
  companyid,
  customerKey,
  product,
  customer,
  week,
  val,
  enteredVelocity,
  forecastData,
  companyMeta,
  pivot,
  tableType
) {
  const rowType = pivot == "customer" ? "product" : "customer";
  const pivotKey = pivot == "customer" ? product : customerKey;
  const velocityByCustomerRef = firebase
    .database()
    .ref(
      `companies/${companyid}/forecast/velocity_by_customer/${customerKey}/${product}`
    );
  const velocityByProductRef = firebase
    .database()
    .ref(
      `companies/${companyid}/forecast/velocity_by_product/${product}/${customerKey}`
    );
  const update = {};
  update[week] = val;
  velocityByCustomerRef.update(update);
  velocityByProductRef.update(update);
  // update velocity and sales values changed by this update
  getForecastData(
    companyid,
    "meta",
    metaSnapshot => {
      const meta = metaSnapshot.val();
      const { weeks } = meta;
      const weekIndex = weeks.indexOf(week);
      const forecastByPivot = {};
      for (const field in forecastData) {
        forecastByPivot[field] = {};
        for (const data of forecastData[field]) {
          const key = data[rowType];
          forecastByPivot[field][key] = data;
        }
      }
      // update velocity and sales values changed by this update
      const firebaseForecast = firebase.app("forecast");
      const forecastVelocityByCustomerRef = firebaseForecast
        .database()
        .ref(
          `${companyid}/${tableType}_forecast_table_by_customer/${customerKey}/velocity/${product}`
        );
      const forecastVelocityByProductRef = firebaseForecast
        .database()
        .ref(
          `${companyid}/${tableType}_forecast_table_by_product/${product}/velocity/${customerKey}`
        );
      const forecastSalesByCustomerRef = firebaseForecast
        .database()
        .ref(
          `${companyid}/${tableType}_forecast_table_by_customer/${customerKey}/sales/${product}`
        );
      const forecastSalesByProductRef = firebaseForecast
        .database()
        .ref(
          `${companyid}/${tableType}_forecast_table_by_product/${product}/sales/${customerKey}`
        );
      const velocityUpdate = {};
      const salesUpdate = {};
      let entered = {};
      let storeVals = {};
      let liftVals = {};
      let seasonalityVals = {};
      if (pivotKey in enteredVelocity) {
        entered = enteredVelocity[pivotKey];
      }
      if ("stores" in forecastByPivot && pivotKey in forecastByPivot.stores) {
        storeVals = forecastByPivot.stores[pivotKey];
      }
      if ("lift" in forecastByPivot && pivotKey in forecastByPivot.lift) {
        liftVals = forecastByPivot.lift[pivotKey];
      }
      if (
        "seasonality" in forecastByPivot &&
        pivotKey in forecastByPivot.seasonality
      ) {
        seasonalityVals = forecastByPivot.seasonality[pivotKey];
      }
      for (let i = weekIndex; i < weeks.length; i++) {
        if (weeks[i] in entered && i > weekIndex) {
          break;
        }
        const storeVal = storeVals[weeks[i]] || 0;
        const liftVal = liftVals[weeks[i]] || 0;
        const seasonalityVal = seasonalityVals[weeks[i]] || 1;
        velocityUpdate[i] = val;
        salesUpdate[i] =
          val !== null
            ? Math.round(val * storeVal * (1 + liftVal) * seasonalityVal)
            : null;
      }
      // update database for product/customer
      forecastVelocityByCustomerRef.update(velocityUpdate);
      forecastVelocityByProductRef.update(velocityUpdate);
      forecastSalesByCustomerRef.update(salesUpdate);
      forecastSalesByProductRef.update(salesUpdate);
      // update database for distributor
      distributorSalesUpdate(
        companyid,
        product,
        customer,
        weeks,
        forecastByPivot,
        salesUpdate,
        companyMeta
      );
      // update database for totals
      const oldSalesVals = forecastByPivot.sales[pivotKey] || {};
      totalSalesUpdate(
        companyid,
        product,
        customerKey,
        weeks,
        oldSalesVals,
        salesUpdate,
        tableType
      );
    },
    false
  );
}

function updateBaseSales(
  companyid,
  customerKey,
  product,
  customer,
  week,
  val,
  enteredBaseSales,
  forecastData,
  companyMeta,
  pivot,
  tableType
) {
  const rowType = pivot == "customer" ? "product" : "customer";
  const pivotKey = pivot == "customer" ? product : customerKey;
  const baseSalesByCustomerRef = firebase
    .database()
    .ref(
      `companies/${companyid}/forecast/baseSales_by_customer/${customerKey}/${product}`
    );
  const baseSalesByProductRef = firebase
    .database()
    .ref(
      `companies/${companyid}/forecast/baseSales_by_product/${product}/${customerKey}`
    );
  const update = {};
  update[week] = val;
  baseSalesByCustomerRef.update(update);
  baseSalesByProductRef.update(update);
  // update velocity and sales values changed by this update
  getForecastData(
    companyid,
    "meta",
    metaSnapshot => {
      const meta = metaSnapshot.val();
      const { weeks } = meta;
      const weekIndex = weeks.indexOf(week);
      const forecastByPivot = {};
      for (const field in forecastData) {
        forecastByPivot[field] = {};
        for (const data of forecastData[field]) {
          const key = data[rowType];
          forecastByPivot[field][key] = data;
        }
      }
      // update velocity and sales values changed by this update
      const firebaseForecast = firebase.app("forecast");
      const forecastBaseSalesByCustomerRef = firebaseForecast
        .database()
        .ref(
          `${companyid}/${tableType}_forecast_table_by_customer/${customerKey}/baseSales/${product}`
        );
      const forecastBaseSalesByProductRef = firebaseForecast
        .database()
        .ref(
          `${companyid}/${tableType}_forecast_table_by_product/${product}/baseSales/${customerKey}`
        );
      const forecastSalesByCustomerRef = firebaseForecast
        .database()
        .ref(
          `${companyid}/${tableType}_forecast_table_by_customer/${customerKey}/sales/${product}`
        );
      const forecastSalesByProductRef = firebaseForecast
        .database()
        .ref(
          `${companyid}/${tableType}_forecast_table_by_product/${product}/sales/${customerKey}`
        );
      const baseSalesUpdate = {};
      const salesUpdate = {};
      let entered = {};
      let seasonalityVals = {};
      if (pivotKey in enteredBaseSales) {
        entered = enteredBaseSales[pivotKey];
      }
      if (
        "seasonality" in forecastByPivot &&
        pivotKey in forecastByPivot.seasonality
      ) {
        seasonalityVals = forecastByPivot.seasonality[pivotKey];
      }
      for (let i = weekIndex; i < weeks.length; i++) {
        if (weeks[i] in entered && i > weekIndex) {
          break;
        }
        const seasonalityVal = seasonalityVals[weeks[i]] || 1;
        baseSalesUpdate[i] = val;
        salesUpdate[i] = val !== null ? Math.round(val * seasonalityVal) : null;
      }
      // update database for product/customer
      forecastBaseSalesByCustomerRef.update(baseSalesUpdate);
      forecastBaseSalesByProductRef.update(baseSalesUpdate);
      forecastSalesByCustomerRef.update(salesUpdate);
      forecastSalesByProductRef.update(salesUpdate);
      // update database for distributor
      distributorSalesUpdate(
        companyid,
        product,
        customer,
        weeks,
        forecastByPivot,
        salesUpdate,
        companyMeta
      );
      // update database for totals
      const oldSalesVals = forecastByPivot.sales[pivotKey] || {};
      totalSalesUpdate(
        companyid,
        product,
        customerKey,
        weeks,
        oldSalesVals,
        salesUpdate,
        tableType
      );
    },
    false
  );
}

export {
  getStores,
  getVelocity,
  getBaseSales,
  updateStores,
  updateVelocity,
  updateBaseSales,
  getForecastData
};
