import React from "react";

import Checkbox from "ui-library/Checkbox";
import MenuItem from "ui-library/MenuItem";
import Select from "ui-library/Select";
import Subheader from "ui-library/Subheader";

import {
  getMetaFirebase,
  updateMetaFirebase,
  getJSXListsFirebase
} from "helpers/Firebase";
import { grey } from "@mui/material/colors";
import DataTable from "../../tables/DataTable";

const grey500 = grey["500"];
const grey700 = grey["700"];

const styles = {
  notifs: {
    width: "60%"
  },
  subheader: {
    fontSize: 20,
    color: "primary.main"
  },
  productList: {
    borderColor: grey700,
    borderWidth: 1,
    borderStyle: "solid"
    // overflow: 'auto',
    // height: 300
  },
  moneyIcon: {
    width: 15,
    height: 15
  },
  radioButton: {
    marginBottom: 16
  },
  textField: {
    color: grey500,
    borderColor: grey500
  },
  chip: {
    margin: 4
  },
  wrapper: {
    display: "flex",
    flexWrap: "wrap"
  }
};

class ForecastParameters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      meta: {},
      userCompany: null,
      newRevSource: "",
      newDedSource: "",
      importLoading: false,
      customersJSX: []
    };
  }

  changeDateRange = (promType, event, index, fieldKey) => {
    const fundTypes = this.props.db.meta.fundTypes ?? {};
    fundTypes[promType] = fundTypes?.[promType] ?? {};
    fundTypes[promType].forecastDateRange = fieldKey;
    if (!fieldKey) {
      fundTypes[promType].forecastLiftIncluded = false;
    }
    updateMetaFirebase({ fundTypes });
  };

  includePromTypeForecast = (promType, event) => {
    const isInputChecked = event.target.checked;
    const fundTypes = this.props.db.meta.fundTypes ?? {};
    fundTypes[promType] = fundTypes?.[promType] ?? {};
    fundTypes[promType].forecastLiftIncluded = isInputChecked;
    updateMetaFirebase({ fundTypes });
  };

  getForecastDateRangesJSX = () => {
    const forecastPromTypes = [];
    const checkLiftIncluded = [];
    const dateRangesJSX = [];
    const allPossibleDateRanges = ["buyin", "instore", "monthyear", "scanback"];
    const fundTypes = this.props.db.meta.fundTypes ?? {};
    Object.entries(fundTypes).forEach(([key, fundType]) => {
      const fieldList = fundType.promCreationFields;
      const { liftAssigned } = fundType;
      if (liftAssigned) {
        const promType = fundType.name;
        const possibleDateRanges = allPossibleDateRanges.filter(x =>
          fieldList.includes(x)
        );
        forecastPromTypes.push(promType);
        checkLiftIncluded.push(
          <Checkbox
            checked={fundType.forecastLiftIncluded}
            onChange={this.includePromTypeForecast.bind(null, key)}
            style={{ padding: 16 }}
            disabled={!fundType.forecastDateRange || !this.props.editPermission}
          />
        );
        dateRangesJSX.push(
          <Select
            value={fundType.forecastDateRange || null}
            onChange={this.changeDateRange.bind(null, key)}
            style={styles.input}
            disabled={!this.props.editPermission}>
            <MenuItem value={null} key="wildcard">
              Select Field
            </MenuItem>
            {possibleDateRanges.map(fieldKey => {
              return (
                <MenuItem value={fieldKey} key={fieldKey}>
                  {this.props.db.meta.possible_type_fields[fieldKey]}
                </MenuItem>
              );
            })}
          </Select>
        );
      }
    });

    return {
      "Promotion Type": forecastPromTypes,
      "Include in Forecast": checkLiftIncluded,
      "Date Range": dateRangesJSX
    };
  };

  changeForecastAlgoSettings = (entry, event, index, weeks) => {
    const forecastAveragedWeeks =
      this.props.db.meta.forecast_averaged_weeks || {};
    forecastAveragedWeeks[entry] = weeks;
    updateMetaFirebase({ forecast_averaged_weeks: forecastAveragedWeeks });
  };

  getForecastAlgoSettingsJSX = () => {
    const forecastAveragedWeeks =
      this.props.db.meta?.forecast_averaged_weeks ?? {};
    const forecastAlgoSettingsJSX = {};
    forecastAlgoSettingsJSX["Customer Type"] = [
      "Retailers",
      "All-Other Buckets"
    ];
    forecastAlgoSettingsJSX["Averaged Weeks"] = [];
    for (const entry of ["retailers", "all_other_buckets"]) {
      // 8 weeks is default averaging
      forecastAlgoSettingsJSX["Averaged Weeks"].push(
        <Select
          value={forecastAveragedWeeks[entry] || 8}
          onChange={this.changeForecastAlgoSettings.bind(null, entry)}
          style={styles.input}
          disabled={!this.props.editPermission}>
          <MenuItem value={4} key="4">
            4 Weeks
          </MenuItem>
          <MenuItem value={8} key="8">
            8 Weeks
          </MenuItem>
          <MenuItem value={12} key="12">
            12 Weeks
          </MenuItem>
          <MenuItem value={16} key="16">
            16 Weeks
          </MenuItem>
          <MenuItem value={26} key="26">
            26 Weeks
          </MenuItem>
          <MenuItem value={52} key="52">
            52 Weeks
          </MenuItem>
        </Select>
      );
    }

    return forecastAlgoSettingsJSX;
  };

  componentDidMount() {
    getMetaFirebase(meta => {
      this.setState({ meta });
    });
    getJSXListsFirebase(this.props.db, allJSX => {
      this.setState({ customersJSX: allJSX.customersJSX });
    });
  }

  render() {
    const forecastDateRangesJSX = this.getForecastDateRangesJSX();
    const forecastAlgoSettingsJSX = this.getForecastAlgoSettingsJSX();
    const notAllDateRangesSet = Object.entries(
      this.props.db.meta.fundTypes ?? {}
    ).some(
      ([key, fundType]) => fundType.liftAssigned && !fundType.forecastDateRange
    );
    return (
      <div>
        <div>
          <div className="rowC">
            <Subheader style={styles.subheader}>
              Forecasting Date Ranges
            </Subheader>
          </div>
          <div style={{ margin: 10 }}>
            Choose date range for each promotion type in forecasting. Note that
            a promotion type is only shown here if it generates lift and has an
            assigned rate per unit field. The date range will default to the
            earliest and latest entered dates in each promotion line.
          </div>
          <br />
          {notAllDateRangesSet && (
            <div style={{ margin: 10, color: "red" }}>
              At least one date range is not set. This may cause forecast values
              for some promotion lines to not update correctly.
            </div>
          )}
          <br />
          <div style={{ margin: 10 }}>
            <DataTable
              title="Select Date Ranges"
              data={forecastDateRangesJSX}
              widths={["50%", "50%"]}
            />
          </div>
          <br />
          <div className="rowC">
            <Subheader style={styles.subheader}>
              Forecast Prediction Parameters
            </Subheader>
          </div>
          <div style={{ margin: 10 }}>
            Choose parameters used in making the forecast predictions.
          </div>
          <br />
          <div style={{ margin: 10 }}>
            <DataTable
              title="Averaged Weeks"
              data={forecastAlgoSettingsJSX}
              widths={["50%", "50%"]}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ForecastParameters;
