import React from "react";
import {
  Checkbox as MuiCheckbox,
  FormControlLabel,
  CheckboxProps as MUICheckboxProps
} from "@mui/material";
import { styled } from "@mui/styles";

const StyledFormLabel = styled(FormControlLabel)(() => ({
  marginLeft: "0px"
}));

const StyledCheckbox = styled(MuiCheckbox)(({ theme }) => ({
  marginRight: theme.spacing(1)
}));

type CheckboxProps = MUICheckboxProps & {
  label: string;
  containerStyle?: Record<string, number | string>;
  onLabelClick?: (e: React.MouseEvent<HTMLLabelElement>) => void;
};

const Checkbox = ({
  label = "",
  containerStyle,
  checked,
  onLabelClick,
  ...other
}: CheckboxProps) => {
  return (
    <StyledFormLabel
      control={
        <StyledCheckbox color="primary" checked={Boolean(checked)} {...other} />
      }
      label={label}
      style={containerStyle}
      onClick={onLabelClick}
    />
  );
};

export default Checkbox;
