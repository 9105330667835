import React from "react";
import { Switch as MuiSwitch, FormControlLabel } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  root: {
    justifyContent: "center",
    margin: "0px",
    display: "flex",
    alignItems: "center"
  },
  label: {
    padding: "0px"
  }
});

const Toggle = props => {
  const { label, labelPlacement, ...other } = props;

  const classes = useStyles();

  return (
    <FormControlLabel
      control={
        <MuiSwitch
          {...other}
          classes={{
            root: classes.root
          }}
        />
      }
      label={label}
      labelPlacement={labelPlacement}
      classes={{
        root: classes.root,
        label: classes.label
      }}
    />
  );
};

export default Toggle;
