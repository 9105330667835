import React from "react";

import {
  isUserCresicorEmployee,
  getCompanyUsers,
  removeFirebase,
  firebase
} from "helpers/Firebase";

import AddBoxIcon from "@mui/icons-material/AddBox";
import SearchIcon from "@mui/icons-material/Search";

import Button from "ui-library/Button";
import IconButton from "ui-library/IconButton";
import TextField from "ui-library/TextField";
import { Toolbar, ToolbarSeparator, ToolbarTitle } from "ui-library/Toolbar";
import ToolbarGroup from "ui-library/ToolbarGroup";

import { sortByField } from "helpers/sortByDate";
import { grey, common, red } from "@mui/material/colors";
import { Stack } from "@mui/material";
import ReportProfile from "./ReportProfile";
import NewReport from "./NewReport";
import PaginationMUITable from "../tables/PaginationMUITable";

const grey700 = grey["700"];
const { white } = common;
const { black } = common;
const red400 = red["400"];
const red600 = red["600"];

const pagelength = 20;

class DistributorReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reports: {},
      companyUsers: {},
      currentlySelected: "",
      showSearch: false,
      searchQuery: "",
      openFilePicker: false,
      modalOpen: false,
      reverseSort: false,
      snackbar: false,
      allSelected: [],
      count: pagelength,
      reportsJSX: [],
      tableBodyRenderKey: 0
    };
  }

  openFilePicker = jsx => {
    this.setState({
      openFilePicker: true
    });
    this.props.showDropDownWindow(jsx);
  };

  closeFilePicker = () => {
    this.setState({
      openFilePicker: false
    });
  };

  addReport = () => {
    this.props.openClose.setAppModal(
      "New Report",
      <NewReport openClose={this.props.openClose} db={this.props.db} />,
      null,
      true,
      false,
      "md"
    );
  };

  deleteReport = (event, rowData) => {
    const reportKey = rowData.key;
    this.props.openClose.setAppModal(
      "Delete Report",
      <div className="centering">
        Are you sure you want to delete this report?
      </div>,

      <Stack>
        <Button
          label="Yes, I'm sure"
          color="error"
          variant="text"
          onClick={() => {
            if (this.hasDependencies(reportKey)) {
              this.props.openClose.closeAppModal();
              this.props.openClose.setAppModal(
                "Unable to delete report",
                <div className="centering">
                  This report is associated with at least one broker. Please
                  resolve these dependencies before deleting this report.
                </div>,
                <div className="centering">
                  <Button
                    label="Okay"
                    onClick={() => {
                      this.props.openClose.closeAppModal();
                    }}
                  />
                </div>
              );
            } else {
              this.props.openClose.closeAppModal();
              removeFirebase(16, reportKey);
            }
          }}
        />
        <Button
          label="No, go back"
          variant="text"
          onClick={() => {
            this.props.openClose.closeAppModal();
          }}
        />
      </Stack>
    );
  };

  hasDependencies = key => {
    for (const brokerKey in this.props.db.brokers) {
      if (
        this.props.db.brokers[brokerKey].reports &&
        this.props.db.brokers[brokerKey].reports.includes(key)
      ) {
        return true;
      }
    }

    return false;
  };

  handleSearchButton = () => {
    this.setState({
      showSearch: true
    });
  };

  handleSearch = event => {
    this.handleInfiniteLoad(true);
    this.setState({
      searchQuery: event.target.value.toLowerCase(),
      currentlySelected: "",
      tableBodyRenderKey: this.state.tableBodyRenderKey + 1
    });
  };

  handleProfileOpen = (event, rowData) => {
    const reportKey = rowData.key;
    this.props.showRightDrawer(
      <div>
        <ReportProfile
          reportKey={reportKey}
          db={this.props.db}
          openClose={this.props.openClose}
        />
      </div>
    );
  };

  getReports = () => {
    let filteredReports = [];
    for (const key in this.props.db.reports) {
      const b = this.props.db.reports[key];
      if (
        this.state.searchQuery === "" ||
        b.name.toLowerCase().indexOf(this.state.searchQuery) != -1
      ) {
        b.key = key;
        filteredReports.push(b);
      }
    }

    if (this.state.sortField) {
      filteredReports = sortByField(
        this.state.sortField,
        filteredReports,
        this.state.reverseSort
      );
    } else {
      filteredReports = sortByField("name", filteredReports, true);
    }

    return filteredReports;
  };

  getReportsJSX = (filteredReports, reset) => {
    const reportsJSX = [];
    for (let i = 0; i < filteredReports.length && i < this.state.count; i++) {
      const b = filteredReports[i];
      reportsJSX.push({
        key: b.key,
        name: b.name,
        type: b.type,
        distributor:
          b.distributor in this.props.db.customers
            ? this.props.db.customers[b.distributor].name
            : "N/A",
        dateRange: `${new Date(b.startDate).toLocaleDateString()} to ${new Date(
          b.endDate
        ).toLocaleDateString()}`
      });
    }

    return reportsJSX;
  };

  handleInfiniteLoad = reset => {
    // limit page to 20 lines for rendering speed
    const count = reset ? pagelength : this.state.count + pagelength;

    this.setState({
      count
    });
  };

  componentDidMount() {
    getCompanyUsers(companyUsers => {
      this.setState({ companyUsers });
    });
  }

  render() {
    const user = firebase.auth().currentUser;
    const filteredReports = this.getReports();
    const reportsJSX = this.getReportsJSX(filteredReports);

    const actionParams = {
      openProfile: rowData => ({
        onClick: this.handleProfileOpen
      })
    };
    if (isUserCresicorEmployee()) {
      actionParams.delete = rowData => ({
        onClick: this.deleteReport
      });
    }
    const columns = [
      { title: "Name", field: "name", width: 300 },
      { title: "Type", field: "type" },
      { title: "Distributor", field: "distributor" },
      { title: "Date Range", field: "dateRange" }
    ];

    return (
      <div>
        <Toolbar
          style={{ justifyContent: "space-between", minHeight: "unset" }}>
          <ToolbarGroup>
            <ToolbarTitle text="Distributor Reports" style={{ color: black }} />
            <ToolbarSeparator flexItem />
            <IconButton
              onClick={this.handleSearchButton}
              tooltip="Search"
              size="large">
              <SearchIcon />
            </IconButton>
            {this.state.showSearch && (
              <TextField
                placeholder="Search..."
                style={{ marginTop: 0 }}
                variant="standard"
                onChange={this.handleSearch}
              />
            )}
          </ToolbarGroup>

          <ToolbarGroup>
            {this.props.db.permissions.includes("add") && !this.props.readOnly && (
              <IconButton
                onClick={this.addReport}
                tooltip="New Report"
                size="large">
                <AddBoxIcon />
              </IconButton>
            )}
          </ToolbarGroup>
        </Toolbar>
        <PaginationMUITable
          selection={false}
          allLoaded={this.state.count >= filteredReports.length}
          handleInfiniteLoad={this.handleInfiniteLoad.bind(null, false)}
          data={reportsJSX}
          columns={columns}
          handleProfileOpen={this.handleProfileOpen}
          actionParams={actionParams}
        />
      </div>
    );
  }
}

export default DistributorReports;
