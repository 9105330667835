import React, { useState, useEffect, Dispatch } from "react";
import {
  broadFigureTypes,
  comparisonTypes,
  pivotTypes,
  makeTableSubLegend,
  getRowDataDropdownItems,
  isWildcard
} from "helpers/Budget";
import Select from "ui-library/Select";
import MenuItem from "ui-library/MenuItem";
import { DataTable } from "./DataTable";
import { BudgetCharts } from "./BudgetCharts";
import BudgetWorker from "../../../helpers/Budget/budget.worker.js";
import {
  ComparisonType,
  DataEntry,
  DiffType,
  FigureType,
  PivotType,
  TimeScaleType,
  DBSlice,
  OrganisedData,
  SubFigureType,
  BroadFigureType
} from "./types";

const InnerLoadingContext = React.createContext<{
  innerLoading: boolean;
  setInnerLoading: Dispatch<boolean>;
}>({
  innerLoading: false,
  setInnerLoading: () => {}
});

interface BudgetToolProps {
  data: DataEntry[];
  db: DBSlice;
  pivot: PivotType;
  figureType: FigureType;
  diffType: DiffType;
  timeScale: TimeScaleType;
  pivotDate: Record<string, number>;
  loading: boolean;
  dataSubTypesForPivot: [SubFigureType, BroadFigureType];
  reset: number;
  diffThreshold: number;
  combineEstimatesAndActuals: boolean;
}

const BudgetTool = ({
  data,
  db,
  pivot,
  figureType,
  diffType,
  timeScale,
  pivotDate,
  loading,
  dataSubTypesForPivot,
  reset,
  diffThreshold,
  combineEstimatesAndActuals
}: BudgetToolProps) => {
  const [innerLoading, setInnerLoading] = useState(false);
  const [allData, setAllData] = useState<OrganisedData>({});

  const tableSubLegend = makeTableSubLegend(
    dataSubTypesForPivot[1],
    dataSubTypesForPivot[0]
  );
  const [comparisonType, setComparisonType] = useState<ComparisonType>(
    Object.keys(comparisonTypes)[0] as ComparisonType
  );
  const rowDataDropdownItems = getRowDataDropdownItems(
    tableSubLegend,
    comparisonType
  );
  const [rowDataType, setRowDataType] = useState<DiffType>(
    Object.keys(rowDataDropdownItems)[0] as DiffType
  );

  useEffect(() => {
    const calculationWorker = new BudgetWorker();
    calculationWorker.onmessage = ({ data }) => {
      setAllData(data);
      calculationWorker.terminate();
    };

    calculationWorker.postMessage({
      type: "organiseDataPerPivot",
      args: {
        rawData: data,
        figureType,
        timeScale,
        comparisonType,
        pivot,
        db,
        combineEstimatesAndActuals
      }
    });

    setInnerLoading(true);

    return () => calculationWorker.terminate();
  }, [
    data,
    pivot,
    figureType,
    diffType,
    timeScale,
    dataSubTypesForPivot,
    comparisonType,
    rowDataType
  ]);

  // Change number of charts to show if a month is selected
  const showPieChartOnly =
    pivot === pivotTypes.DATE && !isWildcard(pivotDate.month);

  return (
    <InnerLoadingContext.Provider value={{ innerLoading, setInnerLoading }}>
      {broadFigureTypes.includes(figureType as BroadFigureType) ? (
        <>
          {pivot !== pivotTypes.DATE ? (
            <Select
              floatingLabelText="Row Data"
              value={rowDataType}
              onChange={(_event, _index, value) => setRowDataType(value)}>
              {Object.entries(rowDataDropdownItems).map(
                ([rowDataTypeValue, rowDataTypeTitle]) => (
                  <MenuItem key={rowDataTypeValue} value={rowDataTypeValue}>
                    {rowDataTypeTitle}
                  </MenuItem>
                )
              )}
            </Select>
          ) : null}
          <Select
            floatingLabelText="Comparison Type"
            value={comparisonType}
            onChange={(_event, _index, value) => setComparisonType(value)}>
            {Object.entries(comparisonTypes).map(
              ([comparisonTypeValue, { title: comparisonTypeTitle }]) => (
                <MenuItem key={comparisonTypeValue} value={comparisonTypeValue}>
                  {comparisonTypeTitle}
                </MenuItem>
              )
            )}
          </Select>
        </>
      ) : null}
      <DataTable
        allData={allData}
        loading={loading}
        timeScale={timeScale}
        figureType={figureType}
        pivot={pivot}
        diffType={diffType}
        comparisonType={comparisonType}
        dataSubTypesForPivot={dataSubTypesForPivot}
        db={db}
        reset={reset}
        diffThreshold={diffThreshold}
        rowDataType={rowDataType}
      />
      <BudgetCharts
        allData={allData}
        timeScale={timeScale}
        figureType={figureType}
        pivot={pivot}
        comparisonType={comparisonType}
        dataSubTypesForPivot={dataSubTypesForPivot}
        db={db}
        showPieChartOnly={showPieChartOnly}
      />
    </InnerLoadingContext.Provider>
  );
};

export { BudgetTool, InnerLoadingContext };
