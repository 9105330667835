import React from "react";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Chip from "@mui/material/Chip";
import Button from "ui-library/Button";
import IconButton from "ui-library/IconButton";
import MenuItem from "ui-library/MenuItem";
import Select from "ui-library/Select";
import TextField from "ui-library/TextField";

import {
  removeFirebase,
  getMetaFirebase,
  updateMetaFirebase
} from "helpers/Firebase";
import { sortByField, objToArray } from "helpers/sortByDate";
import { grey } from "@mui/material/colors";
import { Stack } from "@mui/material";
import DataTable from "../../tables/DataTable";

const grey300 = grey["300"];
const grey700 = grey["700"];

const styles = {
  notifs: {
    width: "60%"
  },
  productList: {
    borderColor: grey700,
    borderWidth: 1,
    borderStyle: "solid"
    // overflow: 'auto',
    // height: 300
  },
  moneyIcon: {
    width: 15,
    height: 15
  },
  radioButton: {
    marginBottom: 16
  },
  chip: {
    margin: 4
  },
  checkbox: {
    marginLeft: 4
  },
  wrapper: {
    display: "flex",
    flexWrap: "wrap"
  }
};

class SeasonalityBuckets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newSeasonalityBucket: "",
      accounts: [],
      meta: {}
    };
  }

  handleAddSeasonalityBucket = () => {
    if (this.state.newSeasonalityBucket) {
      const seasonalityBucketNames =
        this.state.meta.seasonality_bucket_names || [];
      seasonalityBucketNames.push(this.state.newSeasonalityBucket);
      updateMetaFirebase({ seasonality_bucket_names: seasonalityBucketNames });
    }
  };

  handleAddField = (bucket, event, index, value) => {
    const sanitizedValue = Array.isArray(value) ? value : [value];
    const seasonalityBuckets = this.state.meta.seasonality_buckets || {};
    if (bucket in seasonalityBuckets) {
      seasonalityBuckets[bucket].push(...sanitizedValue);
    } else {
      seasonalityBuckets[bucket] = [...sanitizedValue];
    }
    updateMetaFirebase({ seasonality_buckets: seasonalityBuckets });
  };

  handleRequestDelete = (bucket, index) => {
    const seasonalityBuckets = this.state.meta.seasonality_buckets;
    seasonalityBuckets[bucket].splice(index, 1);
    updateMetaFirebase({ seasonality_buckets: seasonalityBuckets });
  };

  deleteSeasonalityBucket = name => {
    this.props.openClose.setAppModal(
      "Delete Seasonality Bucket",
      <div className="centering">
        Are you sure you want to delete this seasonality bucket?
      </div>,

      <Stack>
        <Button
          label="Yes, I'm sure"
          color="error"
          variant="text"
          onClick={() => {
            this.props.openClose.closeAppModal();
            // make sure no bug removes all the products
            const seasonalityBucketNames =
              this.state.meta.seasonality_bucket_names;
            seasonalityBucketNames.splice(
              seasonalityBucketNames.indexOf(name),
              1
            );
            const update = {};
            update.seasonality_bucket_names = seasonalityBucketNames;
            update[`seasonality_buckets/${name}`] = null;
            updateMetaFirebase(update);
            removeFirebase(21, name);
          }}
        />
        <Button
          label="No, go back"
          variant="text"
          onClick={() => {
            this.props.openClose.closeAppModal();
          }}
        />
      </Stack>
    );
  };

  getBucketJSX = () => {
    const bucketNames = this.state.meta.seasonality_bucket_names || [];
    const sortedProductList = sortByField(
      "name",
      objToArray(this.props.db.products),
      true
    );
    const productKeys = sortedProductList.map(product => product.key);
    const newProductJSX = [];
    const assignedProductsJSX = [];
    const deleteBucketJSX = [];
    const seasonalityBuckets = this.state.meta.seasonality_buckets || {};
    let bucketAssigned = [];
    if (Object.keys(seasonalityBuckets).length > 0) {
      bucketAssigned = bucketAssigned.concat(
        ...Object.values(seasonalityBuckets)
      );
    }
    const notBucketAssigned = productKeys.filter(
      x => !bucketAssigned.includes(x)
    );
    for (var name of bucketNames) {
      const bucketProducts = seasonalityBuckets[name] || [];
      newProductJSX.push(
        <Select
          onChange={this.handleAddField.bind(null, name)}
          style={styles.input}
          multiple
          renderValue={selected => "Select Products"}
          displayEmpty>
          {notBucketAssigned.map(key => {
            return (
              <MenuItem value={key} key={key}>
                {this.props.db.products[key].name}
              </MenuItem>
            );
          })}
        </Select>
      );
      assignedProductsJSX.push(
        bucketProducts.length > 0 ? (
          bucketProducts.map((key, index) => {
            return (
              <Chip
                onDelete={this.handleRequestDelete.bind(null, name, index)}
                style={styles.chip}
                label={this.props.db.products[key].name}
              />
            );
          })
        ) : (
          <Chip
            style={Object.assign(jQuery.extend(true, {}, styles.chip), {
              backgroundColor: grey300
            })}
            label="None"
          />
        )
      );
      deleteBucketJSX.push(
        <IconButton
          tooltip="Delete Seasonality Bucket"
          style={{ marginTop: 5 }}
          color="error"
          onClick={this.deleteSeasonalityBucket.bind(null, name)}
          size="large">
          <DeleteIcon />
        </IconButton>
      );
    }

    return [bucketNames, newProductJSX, assignedProductsJSX, deleteBucketJSX];
  };

  componentDidMount() {
    getMetaFirebase(metaData => {
      this.setState({ meta: metaData });
    });
  }

  render() {
    const [bucketNames, newPromTypeJSX, assignedPromTypesJSX, deleteBucketJSX] =
      this.getBucketJSX();

    return (
      <div>
        <div style={{ margin: 10 }}>
          Products in the same bucket have the same assigned seasonality values.
          Only alphanumeric characters, spaces, and dashes are allowed in bucket
          names.
        </div>
        <br />
        <div style={{ margin: 10 }}>
          <DataTable
            title="Select Products for Each Bucket"
            data={{
              Bucket: bucketNames,
              "Add New Products": newPromTypeJSX,
              Products: assignedPromTypesJSX,
              "Delete Bucket": deleteBucketJSX
            }}
            widths={["20%", "30%", "30%", "20%"]}
          />
        </div>
        <div className="rowC">
          <TextField
            floatingLabelText="New Seasonality Bucket"
            onChange={event => {
              const newSeasonalityBucket = event.target.value.replace(
                /[^a-zA-Z0-9\s-]/g,
                ""
              );
              this.setState({ newSeasonalityBucket });
            }}
            onKeyPress={ev => {
              if (ev.key === "Enter") {
                this.handleAddSeasonalityBucket();
                ev.preventDefault();
                this.setState({ newSeasonalityBucket: "" });
              }
            }}
            value={this.state.newSeasonalityBucket}
            style={{ marginLeft: 10, marginRight: 10, marginTop: -10 }}
          />
          <Button
            icon={<AddIcon />}
            onClick={this.handleAddSeasonalityBucket}
          />
        </div>
        <div style={{ marginBottom: 20 }} />
      </div>
    );
  }
}

export default SeasonalityBuckets;
