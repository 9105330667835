import React from "react";
import { Toolbar as MUIToolbar } from "@mui/material";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

const styles = {
  toolbar: {
    justifyContent: "space-between",
    backgroundColor: "background.surfaceVariant",
    height: "56px",
    padding: "0px 24px"
  },
  title: {
    marginRight: "30px",
    color: "inherit"
  }
};

const Toolbar = ({ style, ...props }) => (
  <MUIToolbar sx={{ ...styles.toolbar, ...style }} {...props} />
);

const ToolbarTitle = ({ style, text, ...props }) => (
  <Typography variant="h6" sx={{ ...styles.title }} {...props}>
    {text}
  </Typography>
);

const ToolbarSeparator = props => (
  <Divider orientation="vertical" flexItem {...props} />
);

export { Toolbar, ToolbarTitle, ToolbarSeparator };
