import React from "react";

import Checkbox from "ui-library/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "ui-library/Button";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack
} from "@mui/material";

import { sortByField } from "helpers/sortByDate";
import { batchUpdateFirebase, firebase } from "helpers/Firebase";

import { grey } from "@mui/material/colors";

const grey700 = grey["700"];

const styles = {
  presetCustomers: {
    height: 500,
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: grey700,
    overflow: "auto"
  }
};

class ImportPresetCustomers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      presetCustomers: {},
      selected: new Set()
    };
  }

  onSelectionCheck = (customerKey, event) => {
    const isInputChecked = event.target.checked;
    const { selected } = this.state;
    if (isInputChecked) {
      selected.add(customerKey);
    } else {
      selected.delete(customerKey);
    }
    this.setState({ selected });
  };

  importPresetCustomers = () => {
    const { selected, presetCustomers } = this.state;
    const importedCustomers = {};
    for (var key of selected) {
      importedCustomers[key] = presetCustomers[key];
      importedCustomers[key].associatedPresetCustomer = key;
    }

    const user = firebase.auth().currentUser;
    if (user) {
      var { uid } = user;
      const storedUser = this.props.db.companyUsers[uid];
      var { name } = storedUser;
    }
    const date = new Date().getTime();

    for (var [key, customer] of Object.entries(importedCustomers)) {
      // add edit log
      const edit_log = [];

      edit_log.push({
        modifiedBy: {
          name,
          uid,
          date
        },
        snapshot: JSON.parse(JSON.stringify(customer))
      });

      customer.editLog = edit_log;
    }

    batchUpdateFirebase(2, importedCustomers);
    this.props.openClose.closeAppModal();
  };

  componentDidMount() {
    firebase
      .database()
      .ref("presetCustomers")
      .on("value", snapshot => {
        if (snapshot.val()) {
          const presetCustomers = snapshot.val();
          let customers = [];
          for (const [key, customer] of Object.entries(presetCustomers)) {
            customer.key = key;
            customers.push(customer);
          }
          customers = sortByField("name", customers, true);
          const selected = new Set(Object.keys(presetCustomers));
          this.setState({
            customers,
            presetCustomers,
            selected
          });
        }
      });
  }

  render() {
    return (
      <div>
        <div>
          <br />
          {this.state.customers.length == 0 ? (
            <div style={styles.loading} className="centering">
              <CircularProgress size={40} thickness={5} />
            </div>
          ) : (
            <List style={styles.presetCustomers}>
              {this.state.customers.map(customer => (
                <ListItem key={customer.key}>
                  <ListItemIcon>
                    <Checkbox
                      color="primary"
                      checked={this.state.selected.has(customer.key)}
                      onChange={this.onSelectionCheck.bind(null, customer.key)}
                    />
                  </ListItemIcon>
                  <ListItemText>{customer.name}</ListItemText>
                </ListItem>
              ))}
            </List>
          )}
          <br />
          <br />
          <Stack justifyContent="flex-end">
            <Button label="Import" onClick={this.importPresetCustomers} />
          </Stack>
          <br />
          <br />
        </div>
      </div>
    );
  }
}

export default ImportPresetCustomers;
