import React from "react";
import Checkbox from "ui-library/Checkbox";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import MenuItem from "ui-library/MenuItem";
import Select from "ui-library/Select";
import TextField from "ui-library/TextField";

const circleIcon = <RadioButtonUncheckedIcon />;

export default class SpendSplit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      customer: props.customer,
      elements: props.elements,
      selectedSubsplit: "all"
    };
  }

  handleSelectChange = (event, index, value) => {
    this.setState({ selectedSubsplit: value });
  };

  getSubsplitsJSX = () => {
    const subsplitsJSX = [];
    this.props.subsplitEntries.forEach(e => {
      const key = e[0];
      const val = e[1];
      const subsplit = this.state.customer[this.props.subsplitField];
      const fulfilled = subsplit?.[key];
      subsplitsJSX.push(
        <MenuItem
          value={key}
          children={val.name}
          key={key}
          leftIcon={fulfilled && circleIcon}
        />
      );
    });

    return subsplitsJSX;
  };

  componentDidMount() {
    this.setState({ selectedSubsplit: "all" });
  }

  render() {
    const subsplitsJSX = this.getSubsplitsJSX();
    const isSplit = this.state.selectedSubsplit == "all";

    const field = isSplit ? this.props.splitField : this.props.subsplitField;
    const fieldDict = isSplit
      ? this.props.customer[field]
      : this.props.customer[field]?.[this.state.selectedSubsplit];
    const flavortext = isSplit
      ? `Evenly split across all ${this.props.subsplit}`
      : `Use "All ${this.props.subsplit}" Split`;
    return (
      <div>
        <br />
        <div>
          {`${this.props.subsplit} have a circle if they are using a non-default percentage split.`}
          <br />
          <Select
            floatingLabelText={this.props.subsplit.substring(
              0,
              this.props.subsplit.length - 1
            )}
            value={this.state.selectedSubsplit}
            onChange={this.handleSelectChange}
            autoWidth>
            <MenuItem
              value="all"
              children={`All ${this.props.subsplit}`}
              key="all"
              leftIcon={
                this.props.customer[this.props.splitField] && circleIcon
              }
            />
            {subsplitsJSX}
          </Select>
          <br />
        </div>
        <Checkbox
          color="primary"
          label={flavortext}
          checked={!fieldDict}
          onChange={event => {
            const isInputChecked = event.target.checked;
            const { customer } = this.state;
            if (isInputChecked) {
              if (!isSplit) {
                delete customer[field][this.state.selectedSubsplit];
                let shouldKeepField = false;
                Object.values(customer[field]).forEach(v => {
                  shouldKeepField = shouldKeepField || Boolean(v);
                });
                if (!shouldKeepField) customer[field] = null;
              } else {
                customer[field] = null;
              }
            } else {
              if (!customer?.[field]) customer[field] = {};
              if (!isSplit) {
                customer[field][this.state.selectedSubsplit] = {};
              }
            }
            this.setState({ customer }, () =>
              this.props.updateProposedCustomer(this.state.customer)
            );
          }}
        />
        {this.state.elements.map((e, index) => {
          const key = e[0];
          const { name } = e[1];
          return (
            <div key={this.state.selectedProductGroup + key + index}>
              <TextField
                value={fieldDict?.[key] ?? ""}
                disabled={!fieldDict}
                onChange={event => {
                  const { value } = event.target;
                  const { customer } = this.state;
                  if (isSplit) {
                    customer[field][key] = value;
                  } else {
                    customer[field][this.state.selectedSubsplit][key] = value;
                  }
                  this.setState({ customer }, () =>
                    this.props.updateProposedCustomer(this.state.customer)
                  );
                }}
                floatingLabelText={`${name} split (%)`}
                errorText={
                  fieldDict && !fieldDict[key] ? "This field is required." : ""
                }
              />
            </div>
          );
        })}
      </div>
    );
  }
}
