import React, { useState, useEffect } from "react";

import Button from "ui-library/Button";
import TextField from "ui-library/TextField";

import { firebase } from "helpers/Firebase";

function NewPresetCustomer({ openClose, db }) {
  const [newCustomer, setNewCustomer] = useState({});
  const [customers, setCustomers] = useState({});
  const [nameError, setNameError] = useState("");

  const handleTextChange = (field, value) => {
    const customerCurrent = { ...newCustomer };

    if (field.indexOf("distributor") > -1) {
      customerCurrent.distributors[field] = value.value;
    } else {
      customerCurrent[field] = value.trim();
    }

    setNewCustomer(customerCurrent);
  };

  // returns existingType, otherCustomer] if there is a duplicate error
  const checkDuplicateName = proposedName => {
    // strips string of all spaces and makes lower case
    function reduce(name) {
      return name?.toLowerCase().split(" ").join("");
    }

    const reducedName = reduce(proposedName);

    // check name against all other names in the db
    Object.keys(customers).forEach(key => {
      const otherCustomer = db.customers[key]?.name;

      if (reducedName === reduce(otherCustomer)) {
        return ["customer", otherCustomer];
      }

      if (customers[key]?.otherNames) {
        for (let i = 0; i < customers[key].otherNames.length; i++) {
          const altName = customers[key].otherNames[i];
          if (reducedName === reduce(altName)) {
            return ["alternative", otherCustomer];
          }
        }
      }

      return null;
    });

    return null;
  };

  const saveToDatabase = () => {
    // clear error messages
    setNameError("");

    // first, check for errors
    if (!newCustomer.name) {
      setNameError("Name is required.");
      return;
    }

    // check duplicates in database
    const dupName = checkDuplicateName(newCustomer.name);
    if (dupName) {
      const errorMessage = `Customer name already exists in database as a(n) ${dupName[0]} name under: ${dupName[1]}`;
      setNameError(errorMessage);
      return;
    }

    const presetCustomerRef = firebase.database().ref("presetCustomers");
    const newCustomerRef = presetCustomerRef.push();

    newCustomerRef.update(newCustomer);
    setNewCustomer({
      name: "",
      distributors: {}
    });
    openClose.closeAppModal();
  };

  useEffect(() => {
    firebase
      .database()
      .ref("presetCustomers")
      .on("value", snapshot => {
        if (snapshot.val()) {
          const presetCustomers = snapshot.val();
          setCustomers(presetCustomers);
        }
      });
  }, []);

  return (
    <div>
      <TextField
        floatingLabelText="Name"
        onChange={event => {
          handleTextChange("name", event.target.value.trim());
        }}
        errorText={nameError}
        fullWidth
      />
      <br />
      <br />
      <font color="teal">
        Please create this customer first, then open its profile to manage
        additional details.
      </font>
      <br />
      <br />
      <div className="centering">
        <Button label="Save to Database" onClick={saveToDatabase} />
      </div>
      <br />
    </div>
  );
}

export default NewPresetCustomer;
