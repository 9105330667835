import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 10
  }
}));

const Invalid = () => {
  const classes = useStyles();
  const history = useHistory();
  const handleClick = () => {
    history.push("/");
  };
  return (
    <div className={classes.page}>
      <h3>Oops! It looks like the page you are looking for does not exist.</h3>
      <Button onClick={handleClick} type="button">
        Click here to go home
      </Button>
    </div>
  );
};

export default Invalid;
