import {
  Button,
  Chip,
  List,
  ListItem,
  ListItemText,
  Popover,
  TextField,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Face from "@mui/icons-material/Face";
import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "store";
import { CompanyUser } from "js/dbTypes";
import { DRMEvent, DRMEventType } from "./DRMEvent";
import { AddEventConfig } from "./DRMEventService";

const useStyles = makeStyles(theme => ({
  textField: {
    width: "100%"
  },
  buttonContainer: { display: "flex", justifyContent: "center" },
  button: {
    marginTop: theme.spacing(1)
  },
  tagContainer: {
    marginTop: theme.spacing(1)
  },
  tagRec: { display: "flex", justifyContent: "center" }
}));

interface ActivityLogCommentBoxProps {
  title?: string;
  subtitle?: string;
  reloadEvents?: () => void;
  inputOnly?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TextFieldProps?: any;
  onCommentChange?: (comment: string, tags?: CommentTaggedUser[]) => void;
  /**
   * An optional boolean state setter/value. When flipped to true, clears/resets this component's state
   */
  addEvent: (
    config: AddEventConfig,
    tags?: Record<string, number[]> | undefined
  ) => Promise<Partial<DRMEvent>>;
  resetState?: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  initialValue?: string;
  companyUsers: Record<string, CompanyUser>;
}

export interface CommentTaggedUser {
  userId: string;
  tagStart: number;
  tagEnd: number;
}

const MAX_USER_LIST_LENGTH = 7;
export default function ActivityLogCommentBox(
  props: ActivityLogCommentBoxProps
) {
  const classes = useStyles();
  const {
    title,
    subtitle,
    reloadEvents,
    inputOnly,
    TextFieldProps,
    onCommentChange,
    resetState,
    addEvent,
    initialValue = "",
    companyUsers
  } = props;
  const { filters } = useSelector(
    (state: RootState) => state.activityLog,
    shallowEqual
  );
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [comment, setComment] = useState<string>(initialValue);
  const [anchorEl, setAnchorEl] = React.useState<HTMLInputElement | null>(null);
  const [currentAtPos, setCurrentAtPos] = useState<number | undefined>(
    undefined
  );
  const [substr, setSubstr] = useState<string>("");
  const [selectedUserIdx, setSelectedUserIdx] = useState<number>(0);
  const [tags, setTags] = useState<CommentTaggedUser[]>([]);
  const showList = Boolean(anchorEl);

  const users = Object.entries(companyUsers).map(([key, user]) => ({
    ...user,
    key
  }));

  const filteredUsers = users.filter(
    user =>
      user.name.toUpperCase().includes(substr.toUpperCase()) &&
      !tags.find(tag => comment.slice(tag.tagStart, tag.tagEnd) === user.name)
  );

  useEffect(() => {
    if (resetState?.length) {
      const [resetStateVal, setResetStateVal] = resetState;
      if (resetStateVal) {
        setResetStateVal(false);
        setTags([]);
        setComment("");
        setCurrentAtPos(undefined);
        setSubstr("");
        setSelectedUserIdx(0);
        setAnchorEl(null);
      }
    }
  }, [resetState]);

  useEffect(() => {
    onCommentChange?.(comment, tags);
    // TODO: FORGIVE ME DAN ABRAMOV FOR I HAVE SINNED
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comment, tags]);

  const addComment = () => {
    if (!comment) {
      return;
    }
    addEvent(
      {
        type: DRMEventType.NEW_COMMENT_ADDED,
        comment,
        ...filters
      },
      // TODO: replace with map/reduce
      (() => {
        const record = {};
        tags.forEach(tag => {
          record[tag.userId] = [tag.tagStart, tag.tagEnd];
        });
        return record;
      })()
    ).then(() => {
      reloadEvents?.();
      setComment("");
      setTags([]);
    });
  };

  const onTextChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    e.persist();
    const { value } = e.target;
    setComment(value);
    const currentValueLength = value[value.length - 1];
    const latestChar = currentValueLength;
    const atExists = currentAtPos !== undefined;

    if (atExists && value.charAt(currentAtPos) !== "@") {
      setAnchorEl(null);
      setCurrentAtPos(undefined);
    }

    if (atExists && latestChar !== " " && latestChar !== "@") {
      const curSubstr = value.slice(currentAtPos + 1, value.length);
      setSubstr(curSubstr);
    }

    if (latestChar === "@") {
      setAnchorEl(inputRef?.current || null);
      setCurrentAtPos(value.length - 1);
      setTimeout(() => {
        inputRef?.current?.focus();
      }, 1000);
    }
  };

  const onUserSelect = idx => {
    const { name } = filteredUsers[idx];
    const slicedStr = comment.slice(0, currentAtPos);
    const newStr = slicedStr + name;
    setComment(newStr);
    setAnchorEl(null);
    setSubstr("");

    // solution to cursor not staying at end of text when selecting a tag
    setTimeout(() => {
      const elem: HTMLInputElement = document.getElementById(
        "comment-input"
      ) as HTMLInputElement;
      elem?.setSelectionRange(newStr.length, newStr.length);
    });
    setTags([
      ...tags,
      {
        userId: filteredUsers[idx].key,
        tagStart: slicedStr.length,
        tagEnd: newStr.length
      }
    ]);
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!anchorEl) {
      return;
    }
    if (e.key === "Enter") {
      e.preventDefault();
      onUserSelect(selectedUserIdx);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.persist();
    if (e.key === "Backspace" && comment.length > 1 && tags.length) {
      // this is dumb: https://stackoverflow.com/questions/66710037/property-selectionstart-does-not-exist-on-type-eventtarget
      const { selectionStart } = e.target as HTMLInputElement;
      const tagEnd = selectionStart || comment.length;
      const targetTag = tags.find(tag => tag.tagEnd === tagEnd);
      if (targetTag) {
        e.preventDefault();
        const newStr = comment.replace(
          comment.slice(targetTag.tagStart, targetTag.tagEnd),
          ""
        );
        const newTags = tags
          .filter(tag => tag.userId !== targetTag.userId)
          .map(newTag => {
            // eslint-disable-next-line no-param-reassign
            newTag.tagEnd -= comment.length - newStr.length;
            // eslint-disable-next-line no-param-reassign
            newTag.tagStart -= comment.length - newStr.length;
            return newTag;
          });
        setComment(newStr);
        setTags(newTags);
      }
    } else if (anchorEl && e.key === "Escape") {
      setAnchorEl(null);
    } else if (anchorEl && e.key === " " && filteredUsers[selectedUserIdx]) {
      // check if end of tag
      const currentSuggestedTag = filteredUsers[selectedUserIdx];
      const suggestedNameLen = currentSuggestedTag.name.length;
      const endOfTag = comment.slice(
        comment.length - suggestedNameLen,
        comment.length
      );
      if (
        comment.length > suggestedNameLen &&
        endOfTag === currentSuggestedTag.name
      ) {
        onUserSelect(selectedUserIdx);
      } else {
        setAnchorEl(null);
      }
    } else if (anchorEl && e.key === "ArrowUp") {
      e.preventDefault();
      const newVal = selectedUserIdx - 1;
      if (newVal >= 0) {
        setSelectedUserIdx(newVal);
      }
    } else if (anchorEl && e.key === "ArrowDown") {
      e.preventDefault();
      const newVal = selectedUserIdx + 1;
      if (newVal < filteredUsers.length) {
        setSelectedUserIdx(newVal);
      }
    }
  };

  return (
    <div>
      <div>
        {title && <Typography>{title}</Typography>}
        {subtitle && <Typography variant="subtitle2">{subtitle}</Typography>}
        {showList && (
          <Popover
            disableAutoFocus
            disableEnforceFocus
            open={showList}
            onClose={() => setAnchorEl(null)}
            anchorEl={anchorEl}
            transformOrigin={{ vertical: "bottom", horizontal: "left" }}>
            <List>
              {filteredUsers.length === 0 ? (
                <ListItem>
                  <ListItemText primary={<i>None Found</i>} />
                </ListItem>
              ) : null}
              {users &&
                filteredUsers
                  .slice(0, MAX_USER_LIST_LENGTH)
                  .map((user, userIdx) => (
                    <ListItem
                      onMouseOver={() => setSelectedUserIdx(userIdx)}
                      button
                      key={user.key}
                      selected={userIdx === selectedUserIdx}
                      onClick={() => onUserSelect(userIdx)}>
                      <ListItemText primary={`${user.name} (${user.email})`} />
                    </ListItem>
                  ))}
            </List>
          </Popover>
        )}
        <TextField
          inputProps={{
            label: "Comment Input",
            id: "comment-input"
          }}
          onKeyDown={onKeyDown}
          onKeyPress={onKeyPress}
          ref={inputRef}
          className={classes.textField}
          multiline
          label="Comment"
          id="comment-input"
          variant="outlined"
          value={comment}
          onChange={e => onTextChange(e)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...TextFieldProps}
        />
        {tags.length > 0 ? (
          <div className={classes.tagContainer}>
            {tags.map(tag => (
              <Chip
                icon={<Face />}
                clickable
                onDelete={() => {
                  const newTags = tags.filter(
                    curTag => curTag.userId !== tag.userId
                  );
                  setTags(newTags);
                }}
                size="small"
                key={tag.userId}
                label={users.find(user => tag.userId === user.key)?.name}
              />
            ))}
          </div>
        ) : (
          <div className={classes.tagRec}>
            <i>Type &quot;@&quot; to Tag a User</i>
          </div>
        )}
        <div className={classes.buttonContainer}>
          {!inputOnly && (
            <Button
              size="small"
              variant="contained"
              disabled={!comment}
              className={classes.button}
              onClick={addComment}>
              Add Comment
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
