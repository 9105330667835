import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDb } from "contexts/Db";

import makeStyles from "@mui/styles/makeStyles";
import { useBackupSources } from "components/Deductions/constants/BackupSources";
import TextField from "ui-library/TextField";
import { InputAdornment, IconButton, Tooltip, Box } from "@mui/material";
import Select from "ui-library/Select";
import DatePicker from "ui-library/DatePicker";
import CresicorIconButton from "ui-library/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DataTableScrollable from "components/tables/DataTableScrollable";
import { RootState } from "store";
import { useOpenClose } from "contexts/OpenClose";
import ExampleTemplateViewer from "components/Deductions/DeductionsProcessing/ExampleTemplateViewer";
import ClearIcon from "@mui/icons-material/Clear";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import NotListedLocationIcon from "@mui/icons-material/NotListedLocation";
import { invoiceTypes } from "components/Deductions/constants/ReconciliationTypes";
import { debounce } from "lodash";
import { AddCircle, Delete } from "@mui/icons-material";
import { invoiceInputFields, InputInvoiceObject } from "../redux/definitions";
import {
  addInvoiceObject,
  removeInvoiceObject,
  setInvoiceMetadataField,
  setViewedFile
} from "../redux/actionCreators";
import {
  getInvoiceTypesMenu,
  getCustomersMenu,
  getBackupSourcesMenu,
  getSuggestedPromTypesMenu
} from "./MetadataMenuItems";
import BackupFilePreview from "../BackupFilePreview";

const useStyles = makeStyles(theme => ({
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  columnContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center"
  },
  tableCell: {
    alignSelf: "center",
    wordWrap: "break-word"
  },
  textField: {
    marginBottom: "8px"
  },
  dropdown: {
    marginBottom: "10px"
  },
  tableHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  backupSourceIconButton: {
    marginLeft: theme.spacing(1)
  }
}));

export default function EnterInvoiceMetadata() {
  const db = useDb();
  const { allBackupSources } = useBackupSources();

  const classes = useStyles();

  const dispatch = useDispatch();
  const { showRightDrawer, setAppModal } = useOpenClose();

  const openFilePreview = fileId => () => {
    dispatch(setViewedFile(fileId));
    showRightDrawer(<BackupFilePreview />);
  };
  const { newInvoices, newFiles, fakeInvoiceMode } = useSelector(
    (state: RootState) => state.uploadBackup
  );

  useEffect(() => {
    // if fake invoice mode, reset invoice number to blank once we arrive on metadata screen
    if (fakeInvoiceMode) {
      dispatch(
        setInvoiceMetadataField(0, invoiceInputFields.INVOICE_NUMBER, "", db)
      );
    }
  }, [db, dispatch, fakeInvoiceMode]);

  function getInvoiceMetadataTable() {
    const { customers, meta } = db;
    const { fundTypes = {} } = meta;

    const actionsColumn = newInvoices.map(
      (invoiceObj: InputInvoiceObject, invoiceIdx: number) => {
        const invoiceFileName = invoiceObj.originalFile.fileName;
        const fileIdx =
          invoiceFileName !== null
            ? newFiles.map(file => file.name).indexOf(invoiceFileName)
            : -1;
        return (
          <div className={classes.rowContainer} key={`actions_${invoiceIdx}`}>
            {fakeInvoiceMode && (
              <CresicorIconButton
                tooltip="Delete Row"
                disabled={newInvoices.length === 1}
                onClick={() => {
                  dispatch(removeInvoiceObject(invoiceIdx));
                }}>
                <Delete />
              </CresicorIconButton>
            )}
            <CresicorIconButton
              tooltip="Clear"
              onClick={() => {
                // Clear all current invoice's fields.
                // TODO: we probably want to have a better way of deciding which fields to clear?
                const fieldsToSet = {
                  [invoiceInputFields.CHECK_NUMBER]: "",
                  [invoiceInputFields.CUSTOMER_KEY]: "",
                  [invoiceInputFields.BACKUP_SOURCE]: "",
                  [invoiceInputFields.TOTAL_DISPLAY_AMOUNT]: "",
                  // [invoiceInputFields.INVOICE_TYPE]: "",
                  [invoiceInputFields.START_DATE]: null,
                  [invoiceInputFields.END_DATE]: null,
                  [invoiceInputFields.SUGGESTED_PROM_TYPES]: undefined
                };
                Object.entries(fieldsToSet).forEach(([key, val]) => {
                  dispatch(setInvoiceMetadataField(invoiceIdx, key, val, db));
                });
              }}>
              <ClearIcon />
            </CresicorIconButton>
            <CresicorIconButton
              tooltip="Copy Previous"
              disabled={invoiceIdx === 0}
              onClick={() => {
                // Set all current invoice's fields to previous invoice's fields.
                const prevRowIdx = invoiceIdx - 1;
                Object.entries(newInvoices[prevRowIdx]).forEach(
                  ([key, val]) => {
                    // Do not copy if previous invoice's value doesn't exist, or if current value exists
                    if (!val || invoiceObj[key]) return;
                    if (key === invoiceInputFields.SUGGESTED_PROM_TYPES)
                      val = Object.values(val);
                    dispatch(setInvoiceMetadataField(invoiceIdx, key, val, db));
                  }
                );
              }}>
              <FileCopyOutlinedIcon />
            </CresicorIconButton>
            {!fakeInvoiceMode && (
              <CresicorIconButton
                tooltip="Preview Assigned File"
                disabled={fileIdx === -1}
                onClick={openFilePreview(fileIdx)}>
                <VisibilityIcon />
              </CresicorIconButton>
            )}
          </div>
        );
      }
    );
    const invoiceNumbersColumn = newInvoices.map(
      (invoiceObj: InputInvoiceObject, invoiceIdx: number) => (
        <TextField
          id={invoiceInputFields.INVOICE_NUMBER}
          key={`${invoiceInputFields.INVOICE_NUMBER}_${invoiceIdx}`}
          className={classes.textField}
          value={invoiceObj.invoiceNumber}
          disabled={!fakeInvoiceMode}
          onChange={e => {
            if (!fakeInvoiceMode) {
              return;
            }
            const { value } = e.target;
            dispatch(
              setInvoiceMetadataField(
                invoiceIdx,
                invoiceInputFields.INVOICE_NUMBER,
                value,
                db
              )
            );
          }}
          fullWidth
        />
      )
    );
    const checkNumbersColumn = newInvoices.map(
      (invoiceObj: InputInvoiceObject, invoiceIdx: number) => (
        <TextField
          id={invoiceInputFields.CHECK_NUMBER}
          key={`${invoiceInputFields.CHECK_NUMBER}_${invoiceIdx}`}
          className={classes.textField}
          value={invoiceObj.checkNumber}
          fullWidth
          onChange={e =>
            dispatch(
              setInvoiceMetadataField(
                invoiceIdx,
                invoiceInputFields.CHECK_NUMBER,
                e.target.value,
                db
              )
            )
          }
        />
      )
    );
    const customersColumn = newInvoices.map(
      (invoiceObj: InputInvoiceObject, invoiceIdx: number) => (
        <Select
          id={invoiceInputFields.CUSTOMER_KEY}
          key={`${invoiceInputFields.CUSTOMER_KEY}_${invoiceIdx}`}
          className={classes.dropdown}
          value={invoiceObj.customerKey}
          fullWidth
          onChange={e =>
            dispatch(
              setInvoiceMetadataField(
                invoiceIdx,
                invoiceInputFields.CUSTOMER_KEY,
                e.target.value,
                db
              )
            )
          }>
          {getCustomersMenu(customers)}
        </Select>
      )
    );
    const backupSourcesColumn = newInvoices.map(
      (invoiceObj: InputInvoiceObject, invoiceIdx: number) => (
        <Select
          id={invoiceInputFields.BACKUP_SOURCE}
          key={`${invoiceInputFields.BACKUP_SOURCE}_${invoiceIdx}`}
          className={classes.dropdown}
          value={invoiceObj.backupSource}
          fullWidth
          onChange={e =>
            dispatch(
              setInvoiceMetadataField(
                invoiceIdx,
                invoiceInputFields.BACKUP_SOURCE,
                e.target.value,
                db
              )
            )
          }>
          {getBackupSourcesMenu(allBackupSources)}
        </Select>
      )
    );
    const invoiceAmountsColumn = newInvoices.map(
      (invoiceObj: InputInvoiceObject, invoiceIdx: number) => (
        <TextField
          id={invoiceInputFields.TOTAL_DISPLAY_AMOUNT}
          key={`${invoiceInputFields.TOTAL_DISPLAY_AMOUNT}${invoiceIdx}`}
          className={classes.textField}
          value={invoiceObj.displayAmount}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {invoiceObj.type === invoiceTypes.REPAYMENT ? "-" : ""}$
              </InputAdornment>
            )
          }}
          fullWidth
          onChange={e =>
            dispatch(
              setInvoiceMetadataField(
                invoiceIdx,
                invoiceInputFields.TOTAL_DISPLAY_AMOUNT,
                e.target.value,
                db
              )
            )
          }
        />
      )
    );
    const invoiceTypesColumn = newInvoices.map(
      (invoiceObj: InputInvoiceObject, invoiceIdx: number) => (
        <Select
          id={invoiceInputFields.INVOICE_TYPE}
          key={`${invoiceInputFields.INVOICE_TYPE}_${invoiceIdx}`}
          className={classes.dropdown}
          value={invoiceObj.type}
          fullWidth
          onChange={e =>
            dispatch(
              setInvoiceMetadataField(
                invoiceIdx,
                invoiceInputFields.INVOICE_TYPE,
                e.target.value,
                db
              )
            )
          }>
          {getInvoiceTypesMenu()}
        </Select>
      )
    );

    const today = new Date();
    const epoch = new Date(1970, 1, 1);

    const isValidDate = (date: Date) => date && !Number.isNaN(date.getTime());

    // NOTE [DRM-825]:
    // When the user first comes upon this screen, the date must start out as null.
    // If the date is not yet defined, instead of returning `Invalid Date`
    // (which is the result of parsing an invalid date), we want to return `null`.

    // Importantly, this also makes copying invoice metadata in the table work,
    // as only null values may be overwritten from previous rows!
    const parseDateOrNull = (dateString: string | undefined | null) => {
      // Return null if the date string is falsey.
      if (!dateString) {
        return null;
      }
      const date = new Date(dateString);
      // Return null if the date string cannot be parsed to a valid date.
      return isValidDate(date) ? date : null;
    };

    const invoiceStartDatesColumn = newInvoices.map(
      (invoiceObj: InputInvoiceObject, invoiceIdx: number) => (
        <DatePicker
          inputStyle={{ minWidth: "120px" }}
          id={invoiceInputFields.START_DATE}
          // eslint-disable-next-line react/no-array-index-key
          key={`${invoiceInputFields.START_DATE}_${invoiceIdx}`}
          className={classes.dropdown}
          value={parseDateOrNull(invoiceObj.startDate)}
          minDate={new Date(1970, 1, 1)}
          maxDate={
            invoiceObj.endDate ? new Date(invoiceObj.endDate) : new Date()
          }
          fullWidth
          onChange={debounce(
            (date, keyboardInputValue) =>
              dispatch(
                setInvoiceMetadataField(
                  invoiceIdx,
                  invoiceInputFields.START_DATE,
                  isValidDate(date as Date)
                    ? (date as Date).toISOString()
                    : keyboardInputValue,
                  db
                )
              ),
            500
          )}
        />
      )
    );

    const invoiceEndDatesColumn = newInvoices.map(
      (invoiceObj: InputInvoiceObject, invoiceIdx: number) => {
        return (
          <DatePicker
            inputStyle={{ minWidth: "120px" }}
            id={invoiceInputFields.END_DATE}
            // eslint-disable-next-line react/no-array-index-key
            key={`${invoiceInputFields.END_DATE}_${invoiceIdx}`}
            className={classes.dropdown}
            value={parseDateOrNull(invoiceObj.endDate)}
            minDate={
              invoiceObj.startDate ? new Date(invoiceObj.startDate) : epoch
            }
            maxDate={new Date()}
            fullWidth
            onChange={debounce((date, keyboardInputValue) => {
              dispatch(
                setInvoiceMetadataField(
                  invoiceIdx,
                  invoiceInputFields.END_DATE,
                  isValidDate(date as Date)
                    ? (date as Date).toISOString()
                    : keyboardInputValue,
                  db
                )
              );
            }, 500)}
          />
        );
      }
    );

    const suggestedPromTypesColumn = newInvoices.map(
      (invoiceObj: InputInvoiceObject, invoiceIdx: number) => (
        <Select
          multiple
          id={invoiceInputFields.SUGGESTED_PROM_TYPES}
          key={`${invoiceInputFields.SUGGESTED_PROM_TYPES}_${invoiceIdx}`}
          className={classes.dropdown}
          value={Object.values(invoiceObj.suggestedPromTypes ?? {})}
          fullWidth
          onChange={event => {
            dispatch(
              setInvoiceMetadataField(
                invoiceIdx,
                invoiceInputFields.SUGGESTED_PROM_TYPES,
                event.target.value,
                db
              )
            );
          }}>
          {getSuggestedPromTypesMenu(
            fundTypes,
            Object.values(invoiceObj.suggestedPromTypes ?? {})
          )}
        </Select>
      )
    );

    return {
      Actions: actionsColumn,
      "Invoice Number": invoiceNumbersColumn,
      "Check Number": checkNumbersColumn,
      "Customer Name": customersColumn,
      "Backup Source": backupSourcesColumn,
      "Total Amount": invoiceAmountsColumn,
      "Invoice Type": invoiceTypesColumn,
      "Start Date": invoiceStartDatesColumn,
      "End Date": invoiceEndDatesColumn,
      "Promotion Types": suggestedPromTypesColumn
    };
  }

  const tableTitle = "Enter Invoice Details";

  return (
    <div>
      <DataTableScrollable
        title={
          fakeInvoiceMode ? (
            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              alignItems="center">
              <Box>{tableTitle}</Box>
              <Box>
                <Tooltip title="Add New Invoice">
                  <IconButton onClick={() => dispatch(addInvoiceObject())}>
                    <AddCircle />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          ) : (
            tableTitle
          )
        }
        data={getInvoiceMetadataTable()}
        columnConfig={{
          "Backup Source": {
            actionJsx: (
              <div className={classes.backupSourceIconButton}>
                <Tooltip title="View Example Templates">
                  <IconButton
                    size="small"
                    color="inherit"
                    onClick={() => {
                      setAppModal(
                        "Example File Templates",
                        <ExampleTemplateViewer />,
                        null,
                        false,
                        true,
                        "lg"
                      );
                    }}
                    aria-label="close">
                    <NotListedLocationIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </div>
            )
          }
        }}
      />
    </div>
  );
}
