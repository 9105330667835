import React from "react";
import MenuItem from "ui-library/MenuItem";
import SelectField from "ui-library/Select";
import Autocomplete from "ui-library/Autocomplete";
import { firebase } from "helpers/Firebase";

export default class CustomerAltNameTags extends React.Component {
  constructor(props) {
    super(props);

    const distributorsList = Array.from(props.customer.distributors || []);
    const regionsList = Array.from(props.customer.regionGroups || []);

    const { customer } = props;
    customer.otherNamesMap = customer.otherNamesMap || [];
    if (
      customer.otherNamesMap.filter(tagObj => tagObj.tagName == props.altname)
        .length == 0
    ) {
      customer.otherNamesMap.push({ tagName: props.altname });
    }
    const altNameFields = customer.otherNamesMap.filter(
      tagObj => tagObj.tagName == props.altname
    )[0];

    this.state = {
      customer,
      altname: props.altname,
      distributors: distributorsList,
      regions: regionsList,
      selectedTags: altNameFields.distributorTag || [],
      customTags: [],
      selectedRegion: altNameFields.regionTag || null
    };
  }

  componentDidMount() {
    const { uid } = firebase.auth().currentUser;

    firebase
      .database()
      .ref(`users/${uid}/company_id`)
      .once("value", snapshot => {
        const companyId = snapshot.val();

        firebase
          .database()
          .ref(`companies/${companyId}/meta/customTags`)
          .once("value", snapshot => {
            if (snapshot.exists()) {
              this.setState({
                customTags: snapshot.val()
              });
            }
          });
      });
  }

  getTagsJSX = () => {
    const tagsJSX = [];
    // distributor
    if (this.state.distributors.length > 0) {
      tagsJSX.push("Distributor");
      for (const dist of this.state.distributors) {
        tagsJSX.push(this.props.db.customers[dist].name);
      }
    }
    // direct
    if (this.state.customer.isDirect) {
      tagsJSX.push("Direct");
      tagsJSX.push(this.state.customer.name);
    }
    // defaults
    for (const tag of this.props.defaultTags) {
      tagsJSX.push(tag);
    }
    // custom tags
    if (this.state.customTags.length) {
      tagsJSX.push(...this.state.customTags);
    }

    return tagsJSX;
  };

  getRegionsJSX = () => {
    const regs = [];

    this.state.regions.map(key => {
      const reg = this.props.db.meta.region_mapping.groups[key];
      regs.push(<MenuItem value={key} children={reg.name} key={key} />);
    });

    return regs;
  };

  handleTag = (event, tags) => {
    let { customer, selectedTags } = this.state;
    if (selectedTags.length >= 5) {
      return;
    }
    selectedTags = tags;
    for (let i = 0; i < customer.otherNamesMap.length; i++) {
      if (customer.otherNamesMap[i].tagName == this.state.altname) {
        customer.otherNamesMap[i].distributorTag = selectedTags;
      }
    }
    this.setState({ selectedTags, customer }, () =>
      this.props.updateTaggedCustomer(this.state.customer)
    );
  };

  handleSelectChange = (field, event) => {
    const { value } = event.target;
    const { customer } = this.state;

    if (field == "regionTag") {
      // business logic here
      for (let i = 0; i < customer.otherNamesMap.length; i++) {
        if (customer.otherNamesMap[i].tagName == this.state.altname) {
          customer.otherNamesMap[i][field] = value;
        }
      }

      this.setState({ selectedRegion: value, customer }, () =>
        this.props.updateTaggedCustomer(this.state.customer)
      );
    }
  };

  render() {
    const regionsJSX = this.getRegionsJSX();
    const tagsJSX = this.getTagsJSX();
    return (
      <div>
        <br />
        Select up to 5 tags. Press Enter after typing a custom tag to create it.
        <Autocomplete
          multiple
          floatingLabelText="Selected Tag(s)"
          fullWidth
          value={this.state.selectedTags}
          onChange={this.handleTag}
          options={tagsJSX}
          openOnFocus
          freeSolo
        />
        <SelectField
          floatingLabelText="Region"
          fullWidth
          value={this.state.selectedRegion}
          onChange={this.handleSelectChange.bind(null, "regionTag")}>
          {regionsJSX}
        </SelectField>
      </div>
    );
  }
}
