import { Divider } from "pui-react-dividers";
import React from "react";

import { ListItem, ListItemText, ListItemSecondaryAction } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";

import Chip from "@mui/material/Chip";
import Button from "ui-library/Button";
import TextField from "ui-library/TextField";
import Card from "ui-library/Card";

import {
  updateMetaFirebase,
  getFirebaseConsoleURL,
  isUserCresicorEmployee
} from "helpers/Firebase";
import ModalContent from "components/WebsiteElements/ModalContent";

import { common, grey, green } from "@mui/material/colors";

const { white } = common;
const grey700 = grey["700"];
const { black } = common;
const grey400 = grey["400"];
const greenA700 = green.A700;

const styles = {
  divider: {
    margin: "30px auto",
    width: "50%"
  },
  wrapper: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 4
  }
};

class ProductGroupProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changes: false,
      productGroup: {},
      nameErrorMessage: ""
    };
  }

  handleTextChange = (field, event) => {
    const { productGroup } = this.state;
    productGroup[field] = event.target.value;
    this.setState({
      productGroup,
      changes: true
    });
  };

  handleSaveChanges = () => {
    if (this.state.productGroup.name == "") {
      this.setState({
        nameErrorMessage: "Product Group name cannot be blank."
      });
      return;
    }
    const update = {};
    update[`product_groups/${this.props.pgKey}`] = this.state.productGroup;
    updateMetaFirebase(update);
    this.setState({ changes: false, nameErrorMessage: "" });
  };

  getProductGroupData = pgKey => {
    this.setState({
      changes: false,
      productGroup: this.props.db.meta.product_groups[pgKey]
    });
  };

  componentDidMount() {
    this.getProductGroupData(this.props.pgKey);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getProductGroupData(nextProps.pgKey);
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    const pgProducts =
      this.props.db.meta.product_groups[this.props.pgKey].products || [];

    const editPermission = this.props.db.permissions.includes("edit");

    const firebaseConsoleURL = `${getFirebaseConsoleURL()}/companies/${
      this.props.db.companyid
    }/meta/product_groups/${this.props.pgKey}`;

    return (
      <div>
        <ListItem
          sx={{ background: theme => theme.palette.readOnly.surface3 }}
          ContainerComponent="div">
          <ListItemText
            primary={this.props.db.meta.product_groups[this.props.pgKey].name}
            secondary={
              <span>
                <span>Product Group Profile</span>
                {isUserCresicorEmployee() && (
                  <span>
                    {" "}
                    |{" "}
                    <a
                      href={firebaseConsoleURL}
                      target="_blank"
                      style={{ color: "#1b7acf" }}
                      rel="noreferrer">
                      View on Firebase Console
                    </a>
                  </span>
                )}
              </span>
            }
          />
          <ListItemSecondaryAction>
            {this.state.changes && editPermission && !this.props.readOnly ? (
              <Button
                label="Save Changes"
                onClick={this.handleSaveChanges}
                icon={<SaveIcon color={greenA700} />}
                style={{
                  marginRight: 16,
                  marginTop: 5,
                  height: 36,
                  width: 175,
                  color: greenA700,
                  display: "flex"
                }}
              />
            ) : (
              <Button
                label="Save Changes"
                disabled
                icon={<SaveIcon color={grey400} />}
                style={{
                  marginRight: 16,
                  marginTop: 5,
                  height: 36,
                  width: 175,
                  color: grey400,
                  display: "flex"
                }}
              />
            )}
          </ListItemSecondaryAction>
        </ListItem>
        <br />
        <ModalContent
          style={{
            paddingLeft: "16px",
            paddingRight: "16px",
            height: "100%",
            overflow: "auto"
          }}>
          <TextField
            value={this.state.productGroup.name || ""}
            onChange={this.handleTextChange.bind(null, "name")}
            floatingLabelText="Name"
            fullWidth
            errorText={this.state.nameErrorMessage}
            disabled={!editPermission}
          />
          <br />
          <TextField
            value={this.state.productGroup.altName || ""}
            onChange={this.handleTextChange.bind(null, "altName")}
            floatingLabelText="Alternative Name"
            fullWidth
            disabled={!editPermission}
          />
          <Divider style={styles.divider} size="large" />
          <Card title="Products">
            <div style={styles.wrapper}>
              {pgProducts.map((productKey, index) => {
                return (
                  <Chip
                    style={styles.chip}
                    label={
                      productKey in this.props.db.products
                        ? this.props.db.products[productKey].name
                        : "Unknown"
                    }
                  />
                );
              })}
            </div>
          </Card>
          <br />
          <TextField
            value={this.state.productGroup.grossMargin || ""}
            onChange={this.handleTextChange.bind(null, "grossMargin")}
            floatingLabelText="Product Margin"
            fullWidth
            disabled={!editPermission}
          />
        </ModalContent>
      </div>
    );
  }
}

export default ProductGroupProfile;
