import React from "react";

import StarIcon from "@mui/icons-material/Star";

import { displayMoney } from "helpers/DataProcessing";
import { printMonthYear } from "helpers/Time";
import { promSatisfiesSearch } from "helpers/satisfiesSearch";
import { sortByField, sortByDate } from "helpers/sortByDate";
import Mixpanel from "helpers/Mixpanel";
import { PromotionChip } from "ui-library/promotionTag";
import { amber, blueGrey, grey } from "@mui/material/colors";
import PromProfile from "./PromProfile";
import PaginationMUITable from "../tables/PaginationMUITable";

const amber600 = amber["600"];
const blueGrey50 = blueGrey["50"];
const grey600 = grey["600"];

const styles = {
  col: {
    backgroundColor: blueGrey50
  },
  title: {
    fontWeight: "bold",
    textAlign: "center",
    paddingTop: "25px",
    width: $(window).width() / 4
  },
  scrollBar: {
    height: $(window).height() / 2
  },
  miniIcon: {
    height: 15,
    width: 15,
    marginLeft: 8,
    marginTop: -3,
    color: amber600
  },
  nameColumn: {
    width: "35%"
  },
  tags: {
    margin: "5px"
  }
};

function round(n) {
  return Math.round(n * 100) / 100;
}
const pagelength = 20;

class PromList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reverseSort: false,
      clist: {},
      filteredProms: {},
      currentlySelected: ""
    };
  }

  handleProfileOpen = (event, rowData) => {
    const { promKey } = rowData;
    this.props.addDataDisplay(
      <PromProfile
        readOnly={this.props.readOnly}
        promKey={promKey}
        backupProm={
          promKey in this.props.db.promotions
            ? this.props.db.promotions[promKey]
            : this.props.proposedProms[promKey]
        }
        openClose={this.props.openClose}
        db={this.props.db}
        fixedHeight={
          this.props.addDataDisplaySplit
            ? $(window).height() - 141 - 75 - 56
            : null
        }
        callback={promo => {
          if (!(promKey in this.props.db.promotions)) {
            this.props.proposedProms[promKey] = promo;
            this.props.clist[promKey] = promo;
          }
        }}
        promCreationWindowClosed={this.props.promCreationWindowClosed}
      />
    );
    Mixpanel.track("Promotion Viewed", {
      View: "Planning",
      Interface: "List",
      Component: "PromList"
    });
  };

  getSearchInfo = additionalSearch => {
    let info = "";
    for (const key in additionalSearch) {
      info += key;
      info += additionalSearch[key].toString();
    }
    return info;
  };

  prettyPrint = productsArray => {
    let products = "";
    if (productsArray) {
      for (let i = 0; i < productsArray.length; i++) {
        if (i != 0) {
          products += ", ";
        }
        products += productsArray[i];
      }
    }
    return products;
  };

  getProms = props => {
    let filteredProms = props.additionalSearch
      ? props.clist.filter(p => {
          return promSatisfiesSearch(p, props.additionalSearch);
        })
      : props.clist;
    // remove cancelled proms unless this is the cancelled mode and remove declined proms unless this is the declined mode
    filteredProms = filteredProms.filter(
      p =>
        (this.props.db.meta.statuses[p.status] !== "Cancelled" ||
          this.props.mode === 4) &&
        (this.props.db.meta.statuses[p.status] !== "Declined" ||
          this.props.mode === 3)
    );

    // return filteredProms;
    this.setState({ filteredProms });
  };

  onColumnSort = (field, direction) => {
    if (this.state.sortField == field) {
      this.setState({ reverseSort: !this.state.reverseSort });
    } else {
      this.setState({ sortField: field, reverseSort: false });
    }
  };

  getSortedFilteredProms = () => {
    let { filteredProms } = this.state;
    if (
      this.state.sortField === "lastModified" ||
      this.state.sortField === "dateField"
    ) {
      filteredProms = sortByDate(
        filteredProms,
        this.state.reverseSort,
        this.state.sortField,
        false
      );
    } else if (this.state.sortField) {
      filteredProms = sortByField(
        this.state.sortField,
        filteredProms,
        this.state.reverseSort
      );
    }
    return filteredProms;
  };

  getPromJSX = p => {
    const allSelected = this.props.allSelected || [];

    const tagJsx = p.tags?.map(tagKey => (
      <PromotionChip
        tag={this.props.db.meta.promotionTags[tagKey]}
        style={styles.tags}
        size="small"
        onClick={event => {
          event.stopPropagation();
          this.props.onTagClick(tagKey);
        }}
      />
    ));

    if (this.props.abridged) {
      var promVal = {
        promKey: p.promKey,
        name: p.name,
        id: p.name,
        status: this.props.db.meta.statuses[p.status],
        lastModified: `${p.modified.date}, ${p.modified.time}`,
        totalExpSpend: displayMoney(Math.round(p.totalExpSpend))
      };
    } else {
      var promVal = {
        promKey: p.promKey,
        name: (
          <span>
            {p.name}
            {p.starred && <StarIcon style={styles.miniIcon} />}
            {tagJsx}
          </span>
        ),
        id: p.name,
        customerName: p.customerName,
        dateField: printMonthYear(p.month, p.year),
        status: this.props.db.meta.statuses[p.status],
        lastModified: `${p.modified.date}, ${p.modified.time}`,
        totalExpSpend: displayMoney(Math.round(p.totalExpSpend))
      };
    }
    if (allSelected.includes(p.key)) {
      promVal.tableData = { checked: true };
    }
    return promVal;
  };

  getPromList = () => {
    const filteredProms = this.getSortedFilteredProms();
    const promsJSX = [];
    for (
      let i = 0;
      i < filteredProms.length && i < this.props.count + pagelength;
      i++
    ) {
      const p = filteredProms[i];
      promsJSX.push(this.getPromJSX(p));
    }
    return [promsJSX.length, promsJSX];
  };

  handleLineProfileOpen = (event, rowData) => {
    const { promKey } = rowData;
    const { lineKey } = rowData;
    this.props.addDataDisplay(
      <PromProfile
        readOnly={this.props.readOnly}
        promKey={promKey}
        defaultLine={lineKey}
        openClose={this.props.openClose}
        db={this.props.db}
        fixedHeight={
          this.props.addDataDisplaySplit
            ? $(window).height() - 141 - 75 - 56
            : null
        }
      />
    );
  };

  expandRow = rowData => {
    // callback on expansion of row in promotion table
    const { promKey } = rowData;
    const { lines } = this.props.db.promotions[promKey];
    const linesDisplayData = [];
    for (const [lineKey, line] of Object.entries(lines)) {
      linesDisplayData.push({
        promKey,
        lineKey,
        "Line ID": lineKey,
        "Product(s)":
          line.productGroup != "custom"
            ? this.props.db.meta.product_groups[line.productGroup].name
            : "Custom",
        Type: this.props.db.meta.fundTypes?.[line.type]?.name,
        Account: this.props.db.accounts?.[line.account]?.name,
        Duration: `${line.startDate} - ${line.endDate}`
      });
    }
    const actionParams = {
      openProfile: rowData => {
        this.handleLineProfileOpen;
      }
    };
    const expansionColumns = [
      { title: "Line ID", field: "Line ID" },
      { title: "Product(s)", field: "Product(s)" },
      { title: "Type", field: "Type" },
      { title: "Account", field: "Account" },
      { title: "Duration", field: "Duration" }
    ];
    return (
      <PaginationMUITable
        data={linesDisplayData}
        columns={expansionColumns}
        handleProfileOpen={this.handleLineProfileOpen}
        actionParams={actionParams}
        selection={false}
        allLoaded
        modalOpen={this.props.modalOpen}
      />
    );
  };

  componentDidMount() {
    this.getProms(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getProms(nextProps);
  }

  render() {
    const [promLength, promsJSX] = this.getPromList();
    const sortedFilteredProms = this.getSortedFilteredProms();
    const searchInfo = this.getSearchInfo(this.props.additionalSearch);
    const actionParams = {
      openProfile: rowData => {
        this.handleProfileOpen;
      }
    };
    const columns = this.props.abridged
      ? [
          {
            title: "Name",
            field: "name",
            cellStyle: { maxWidth: 500 }
          },
          { title: "Status", field: "status" },
          {
            title: "Last Modified",
            field: "lastModified"
          },
          {
            title: "Total Expected Spend",
            field: "totalExpSpend"
          }
        ]
      : [
          { title: "Name", field: "name", cellStyle: { maxWidth: 500 } },
          { title: "Customer", field: "customerName" },
          // Passing customSort as no-op to supress material-table internal sorting
          { title: "Month", field: "dateField" },
          { title: "Status", field: "status" },
          {
            title: "Last Modified",
            field: "lastModified"
          },
          {
            title: "Total Expected Spend",
            field: "totalExpSpend",
            cellStyle: { maxWidth: 200 }
          }
        ];
    return (
      <div>
        <PaginationMUITable
          data={promsJSX}
          columns={columns}
          selection={this.props.selection}
          showSelectAllCheckbox={this.props.showSelectAllCheckbox}
          handleRowSelection={this.props.handleRowSelection.bind(
            null,
            sortedFilteredProms,
            promLength
          )}
          idSynonym="promKey"
          allLoaded={this.props.count >= promLength}
          handleInfiniteLoad={this.props.handleInfiniteLoad}
          searchInfo={searchInfo}
          handleProfileOpen={this.handleProfileOpen}
          allSelected={this.props.allSelected}
          expandRow={this.props.allowExpandRows && this.expandRow}
          onColumnSort={this.onColumnSort}
          modalOpen={this.props.modalOpen}
          actionParams={actionParams}
        />
      </div>
    );
  }
}

export default PromList;
