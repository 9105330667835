import React, { useState } from "react";
import Link from "ui-library/Link";
import SchoolIcon from "@mui/icons-material/School";
import MenuItem from "@mui/material/MenuItem";
import { Tooltip } from "@mui/material";
import useTooltip from "helpers/useTooltip";
import {
  StyledArrowDropDownIcon,
  StyledArrowDropUpIcon,
  StyledBox,
  StyledMenu
} from "./StyledComponents";

export default function UniversityIcon() {
  const [openUniversityMenu, setOpenUniversityMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const { open, handleTooltipClose, handleTooltipOpen } = useTooltip();

  const handleOpen = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpenUniversityMenu(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenUniversityMenu(false);
  };

  return (
    <div>
      <Tooltip
        open={open}
        onOpen={handleTooltipOpen}
        onClose={handleTooltipClose}
        disableInteractive
        disableFocusListener
        title="Vividly University">
        <div>
          <StyledBox
            onClick={event => {
              handleTooltipClose();
              handleOpen(event);
            }}>
            <SchoolIcon />
            {openUniversityMenu ? (
              <StyledArrowDropUpIcon />
            ) : (
              <StyledArrowDropDownIcon />
            )}
          </StyledBox>
        </div>
      </Tooltip>

      <StyledMenu
        anchorEl={anchorEl}
        open={openUniversityMenu}
        onClose={() => handleClose()}>
        <MenuItem onClick={handleClose}>
          <Link
            component="a"
            href="https://university.govividly.com/docs"
            target="_blank"
            variant="labelMedium">
            Vividly University
          </Link>
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
