import React from "react";

import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import DatePicker from "ui-library/DatePicker";
import Button from "ui-library/Button";
import MenuItem from "ui-library/MenuItem";
import SelectField from "ui-library/Select";
import ZoomInIcon from "@mui/icons-material/ZoomIn";

import { getJSXListsFirebase, getMetaFirebase } from "helpers/Firebase";
import { getTimesJSX } from "helpers/Time";

import { common, grey } from "@mui/material/colors";

const { white } = common;
const grey700 = grey["700"];

class SearchRevenue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchRevenue: {
        startDate: null,
        endDate: null,
        ...this.props.searchRevenue
      },
      promKey: "",
      productsJSX: [],
      accountsJSX: [],
      customersJSX: [],
      sourcesJSX: []
    };
  }

  handleTextChange = (field, event) => {
    const newSearchRevenue = this.state.searchRevenue;
    newSearchRevenue[field] = event.target.value;
    this.setState({
      searchRevenue: newSearchRevenue
    });
  };

  handleSelectChange = (field, event, index, value) => {
    const newSearchRevenue = this.state.searchRevenue;
    newSearchRevenue[field] = value;
    this.setState({
      searchRevenue: newSearchRevenue
    });
  };

  handleDateChange = (field, value) => {
    const newSearchRevenue = this.state.searchRevenue;
    newSearchRevenue[field] = value;
    this.setState({
      searchRevenue: newSearchRevenue
    });
  };

  handleSubmit = () => {
    this.props.setSearchRevenue(this.state.searchRevenue);
    this.props.openClose.hideRightDrawer();
  };

  resetFields = () => {
    this.setState({
      searchRevenue: {
        startDate: null,
        endDate: null,
        ...this.props.searchRevenue
      }
    });
    this.props.setSearchRevenue({});
  };

  componentDidMount() {
    getMetaFirebase(metaData => {
      const selectTypes = [];
      const selectStatuses = [];
      const { fundTypes = {} } = metaData;
      for (var key in fundTypes) {
        selectTypes.push(
          <MenuItem
            value={parseInt(key)}
            primaryText={fundTypes[key].name}
            key={key}
          />
        );
      }
      for (var key in metaData.statuses) {
        const status = metaData.statuses[key];
        selectStatuses.push(
          <MenuItem value={parseInt(key)} primaryText={status} key={key} />
        );
      }
      this.setState({
        meta: metaData,
        selectTypes,
        selectStatuses
      });
    });
    getJSXListsFirebase(
      this.props.db,
      allJSX => {
        const { productsJSX, accountsJSX, customersJSX } = allJSX;
        this.setState({
          productsJSX,
          accountsJSX,
          customersJSX
        });
      },
      null,
      true,
      true
    );
    // add sources
    const sourcesJSX = [];
    const revenueSources = this.props.db.meta.revenue_sources || [];
    for (const source of revenueSources) {
      sourcesJSX.push(
        <MenuItem value={source} children={source} key={source} />
      );
    }
    this.setState({ sourcesJSX });

    const timesJSX = getTimesJSX(this.props.db.meta);
    this.setState({
      monthsJSX: timesJSX[0],
      yearsJSX: timesJSX[1]
    });
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    return (
      <div key={this.props.contactKey}>
        <ListItem style={{ backgroundColor: grey700, color: white }}>
          <ListItemIcon>
            <ZoomInIcon />
          </ListItemIcon>
          <ListItemText primary="Advanced Search for Revenue" />
        </ListItem>
        <div style={{ marginLeft: "16px", marginRight: "16px" }}>
          <br />
          <div>
            Enter keyword or select from dropdown for any field. Search results
            contain revenue entries that match all fields.
          </div>
          <SelectField
            value={this.state.searchRevenue.customer}
            onChange={this.handleSelectChange.bind(null, "customer")}
            floatingLabelText="Customer"
            fullWidth>
            {this.state.customersJSX}
          </SelectField>
          <br />
          <SelectField
            value={this.state.searchRevenue.product}
            onChange={this.handleSelectChange.bind(null, "product")}
            floatingLabelText="Product or Product Group"
            fullWidth>
            {this.state.productsJSX}
          </SelectField>
          <br />
          {this.props.db.meta.tier == "aspen" && (
            <div>
              <SelectField
                value={this.state.searchRevenue.source}
                onChange={this.handleSelectChange.bind(null, "source")}
                floatingLabelText="Source"
                fullWidth>
                {this.state.sourcesJSX}
              </SelectField>
              <br />
            </div>
          )}
          <div className="rowC">
            <SelectField
              floatingLabelText="Month"
              value={this.state.searchRevenue.month}
              onChange={this.handleSelectChange.bind(null, "month")}
              fullWidth>
              {this.state.monthsJSX}
            </SelectField>
            <SelectField
              floatingLabelText="Year"
              value={this.state.searchRevenue.year}
              onChange={this.handleSelectChange.bind(null, "year")}
              fullWidth
              style={{ marginLeft: 16 }}>
              {this.state.yearsJSX}
            </SelectField>
          </div>
          <br />
          <div className="rowC">
            <div style={{ width: "50%", paddingRight: "1%" }}>
              <DatePicker
                floatingLabelText="Starts after"
                autoOk
                onChange={this.handleDateChange.bind(null, "startDate")}
                value={this.state.searchRevenue.startDate}
                fullWidth
              />
            </div>
            <div style={{ width: "50%", paddingLeft: "1%" }}>
              <DatePicker
                floatingLabelText="Ends before"
                autoOk
                onChange={this.handleDateChange.bind(null, "endDate")}
                value={this.state.searchRevenue.endDate}
                fullWidth
              />
            </div>
          </div>
          <br />
          <br />
          <div className="centering">
            <Button label="Search" onClick={this.handleSubmit} />
            <Button
              label="Reset Fields"
              variant="text"
              color="error"
              onClick={this.resetFields}
            />
          </div>
          <br />
        </div>
      </div>
    );
  }
}

export default SearchRevenue;
