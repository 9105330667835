import { createTheme } from "@mui/material";
import { muiPaletteDark, muiPaletteLight } from "./ColorPalettes";

const themeMode = "light";
const selectedPalette =
  themeMode === "light" ? muiPaletteLight : muiPaletteDark;

const originalTheme = createTheme({
  typography: {
    fontFamily: "Monument Grotesk Regular"
  },
  palette: {
    mode: themeMode
  }
});
const theme = createTheme(originalTheme, {
  typography: {
    displayLarge: {
      fontSize: originalTheme.typography.pxToRem(57),
      fontFamily: "Monument Grotesk Regular",
      fontWeight: 400,
      lineHeight: originalTheme.typography.pxToRem(64),
      letterSpacing: "-0.25px"
    },
    displayMedium: {
      fontSize: originalTheme.typography.pxToRem(45),
      fontFamily: "Monument Grotesk Regular",
      fontWeight: 400,
      lineHeight: originalTheme.typography.pxToRem(52)
    },
    displaySmall: {
      fontSize: originalTheme.typography.pxToRem(36),
      fontFamily: "Monument Grotesk Regular",
      fontWeight: 400,
      lineHeight: originalTheme.typography.pxToRem(44)
    },
    h1: {
      fontSize: originalTheme.typography.pxToRem(36),
      fontFamily: "Monument Grotesk Regular",
      fontWeight: 400,
      lineHeight: originalTheme.typography.pxToRem(44)
    },
    h2: {
      fontSize: originalTheme.typography.pxToRem(32),
      fontFamily: "Monument Grotesk Regular",
      fontWeight: 400,
      lineHeight: originalTheme.typography.pxToRem(40)
    },
    h3: {
      fontSize: originalTheme.typography.pxToRem(28),
      fontFamily: "Monument Grotesk Regular",
      fontWeight: 400,
      lineHeight: originalTheme.typography.pxToRem(36)
    },
    h4: {
      fontSize: originalTheme.typography.pxToRem(24),
      fontFamily: "Monument Grotesk Regular",
      fontWeight: 400,
      lineHeight: originalTheme.typography.pxToRem(32)
    },
    h5: {
      fontSize: originalTheme.typography.pxToRem(22),
      fontFamily: "Monument Grotesk Regular",
      fontWeight: 400,
      lineHeight: originalTheme.typography.pxToRem(28)
    },
    h6: {
      fontSize: originalTheme.typography.pxToRem(18),
      fontFamily: "Monument Grotesk Regular",
      fontWeight: 400,
      lineHeight: originalTheme.typography.pxToRem(24)
    },
    subtitle1: {
      fontSize: originalTheme.typography.pxToRem(16),
      fontFamily: "Monument Grotesk Regular",
      fontWeight: 500,
      lineHeight: originalTheme.typography.pxToRem(24),
      letterSpacing: "0.1px"
    },
    subtitle2: {
      fontSize: originalTheme.typography.pxToRem(14),
      fontFamily: "Monument Grotesk Regular",
      fontWeight: 500,
      lineHeight: originalTheme.typography.pxToRem(20),
      letterSpacing: "0.1px"
    },
    button: {
      fontSize: originalTheme.typography.pxToRem(14),
      fontFamily: "Monument Grotesk Regular",
      fontWeight: 500,
      lineHeight: originalTheme.typography.pxToRem(20),
      letterSpacing: "0.1px"
    },
    caption: {
      fontSize: originalTheme.typography.pxToRem(12),
      fontFamily: "Monument Grotesk Regular",
      fontWeight: 400,
      lineHeight: originalTheme.typography.pxToRem(16),
      letterSpacing: "0.4px"
    },
    overline: {
      fontSize: originalTheme.typography.pxToRem(12),
      fontFamily: "Monument Grotesk Regular",
      fontWeight: 500,
      lineHeight: originalTheme.typography.pxToRem(16),
      letterSpacing: "0.5px"
    },
    labelLarge: {
      fontSize: originalTheme.typography.pxToRem(14),
      fontFamily: "Monument Grotesk Regular",
      fontWeight: 500,
      lineHeight: originalTheme.typography.pxToRem(20),
      letterSpacing: "0.1px"
    },
    labelMedium: {
      fontSize: originalTheme.typography.pxToRem(12),
      fontFamily: "Monument Grotesk Regular",
      fontWeight: 500,
      lineHeight: originalTheme.typography.pxToRem(16),
      letterSpacing: "0.5px"
    },
    labelSmall: {
      fontSize: originalTheme.typography.pxToRem(11),
      fontFamily: "Monument Grotesk Regular",
      fontWeight: 500,
      lineHeight: originalTheme.typography.pxToRem(16),
      letterSpacing: "0.5px"
    },
    labelLargeCondensed: {
      fontSize: originalTheme.typography.pxToRem(14),
      fontFamily: "Monument Grotesk Regular",
      fontWeight: 400,
      lineHeight: originalTheme.typography.pxToRem(16),
      letterSpacing: "0.1px"
    },
    labelMediumCondensed: {
      fontSize: originalTheme.typography.pxToRem(12),
      fontFamily: "Monument Grotesk Regular",
      fontWeight: 400,
      lineHeight: originalTheme.typography.pxToRem(16),
      letterSpacing: "0.1px"
    },
    labelSmallCondensed: {
      fontSize: originalTheme.typography.pxToRem(11),
      fontFamily: "Monument Grotesk Regular",
      fontWeight: 400,
      lineHeight: originalTheme.typography.pxToRem(16),
      letterSpacing: "0.1px"
    },
    bodyLarge: {
      fontSize: originalTheme.typography.pxToRem(16),
      fontFamily: "Monument Grotesk Regular",
      fontWeight: 400,
      lineHeight: originalTheme.typography.pxToRem(24),
      letterSpacing: "0.5px"
    },
    bodyMedium: {
      fontSize: originalTheme.typography.pxToRem(14),
      fontFamily: "Monument Grotesk Regular",
      fontWeight: 400,
      lineHeight: originalTheme.typography.pxToRem(20),
      letterSpacing: "0.25px"
    },
    bodySmall: {
      fontSize: originalTheme.typography.pxToRem(12),
      fontFamily: "Monument Grotesk Regular",
      fontWeight: 400,
      lineHeight: originalTheme.typography.pxToRem(16),
      letterSpacing: "0.4px"
    },
    bodyLargeBold: {
      fontSize: originalTheme.typography.pxToRem(16),
      fontFamily: "Monument Grotesk Bold",
      fontWeight: 700,
      lineHeight: originalTheme.typography.pxToRem(24),
      letterSpacing: "0.5px"
    },
    bodyMediumBold: {
      fontSize: originalTheme.typography.pxToRem(14),
      fontFamily: "Monument Grotesk Bold",
      fontWeight: 700,
      lineHeight: originalTheme.typography.pxToRem(20),
      letterSpacing: "0.25px"
    },
    bodySmallBold: {
      fontSize: originalTheme.typography.pxToRem(12),
      fontFamily: "Monument Grotesk Bold",
      fontWeight: 700,
      lineHeight: originalTheme.typography.pxToRem(16),
      letterSpacing: "0.4px"
    },
    tabularLarge: {
      fontSize: originalTheme.typography.pxToRem(16),
      fontFamily: "Monument Grotesk Mono",
      fontWeight: 400,
      lineHeight: originalTheme.typography.pxToRem(24),
      letterSpacing: "0.15px"
    },
    tabularMedium: {
      fontSize: originalTheme.typography.pxToRem(14),
      fontFamily: "Monument Grotesk Mono",
      fontWeight: 400,
      lineHeight: originalTheme.typography.pxToRem(20),
      letterSpacing: "0.4px"
    },
    tabularSmall: {
      fontSize: originalTheme.typography.pxToRem(11),
      fontFamily: "Monument Grotesk Mono",
      fontWeight: 400,
      lineHeight: originalTheme.typography.pxToRem(16),
      letterSpacing: "0.4px"
    }
  },
  palette: selectedPalette,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFeatureSettings: "normal !important",
          fontVariantCaps: "initial !important",
          fontVariantEastAsian: "initial !important",
          fontVariantLigaturesNormal: "initial !important",
          fontVariantNumeric: "normal !important"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "100px",
          "&:focus": {
            outline: "none"
          }
        },
        containedPrimary: {
          "&:hover": {
            backgroundColor: selectedPalette.primary.light
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:focus": {
            outline: "none"
          }
        }
      }
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          displayLarge: "h2",
          displayMedium: "h3",
          displaySmall: "h3",
          labelLarge: "p",
          labelMedium: "p",
          labelSmall: "p",
          labelLargeCondensed: "p",
          labelMediumCondensed: "p",
          labelSmallCondensed: "p",
          bodyLarge: "p",
          bodyMedium: "p",
          bodySmall: "p",
          bodyLargeTabular: "p",
          bodyMediumTabular: "p",
          bodySmallTabular: "p"
        }
      }
      // styleOverrides: {
      //   root: {
      //     fontFamily: "Monument Grotesk Regular"
      //   }
      // }
    },
    MuiTableBody: {
      defaultProps: {
        size: "small"
      },
      styleOverrides: {
        root: {
          fontFamily: "Monument Grotesk Regular"
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          lineHeight: "16px",
          letterSpacing: "0.4px",
          padding: "12px 16px",
          fontSize: "14px",
          borderBottomColor: "#E2E3DC",
          fontFamily: "Monument Grotesk Regular"
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: "8px",
          ...(ownerState.color === "default" &&
            ownerState.variant === "outlined" && {
              backgroundColor: selectedPalette.background.surfaceVariant
            })
        })
      }
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: "8px"
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          background: selectedPalette.readOnly.surface2
        }
      }
    },
    // MuiSelect: {
    //   defaultProps: {
    //     variant: "standard"
    //   }
    // },
    MuiTextField: {
      defaultProps: {
        variant: "standard"
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          minWidth: 80
        },
        input: {
          // padding: "16.5px 14px !important",
          fontFamily: "Monument Grotesk Regular"
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          "& legend": {
            width: "auto"
          }
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: selectedPalette.background.surfaceVariant,
          "&:hover": {
            backgroundColor: "#C6D1D5"
          },
          "&.Mui-focused": {
            backgroundColor: "#C6D1D5"
          }
        },
        input: {
          "&:focus": {
            backgroundColor: "transparent"
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          // color: "inherit",
          "&:focus": {
            outline: "none"
          }
        }
      }
    },
    MuiLink: {
      defaultProps: {
        underline: "none",
        color: "inherit"
      }
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          "&:focus": {
            outline: "none"
          }
        }
      }
    },
    MuiStack: {
      defaultProps: {
        direction: "row",
        spacing: 1,
        alignItems: "center"
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
          margin: 0
        }
      }
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          "& .Mui-selected": {
            backgroundColor: selectedPalette.primary.main,
            borderColor: `${selectedPalette.primary.onhover} !important`,
            "&:hover, &:focus": {
              backgroundColor: `${selectedPalette.primary.onhover} !important`,
              borderColor: `${selectedPalette.primary.onhover} !important`,
              outline: "none !important"
            }
          }
        }
      }
    },
    MuiDateRangePickerDay: {
      styleOverrides: {
        root: {
          "& .Mui-selected": {
            backgroundColor: selectedPalette.primary.main,
            borderColor: `${selectedPalette.primary.onhover} !important`,

            "&:hover, &:focus": {
              backgroundColor: `${selectedPalette.primary.onhover} !important`,
              borderColor: `${selectedPalette.primary.onhover} !important`,
              outline: "none !important"
            }
          }
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          backgroundColor:
            originalTheme.palette.mode === "light"
              ? "rgb(226, 226, 226)"
              : "black",
          p: {
            margin: "0px"
          }
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paperFullWidth: {
          maxWidth: 1300
        }
      }
    }
  }
});

export default theme;
