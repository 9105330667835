import { invoiceLineStatuses } from "components/Deductions/constants/ReconciliationStatuses";
import { resolutionLineTypes } from "components/Deductions/constants/ReconciliationTypes";
import {
  ERPTransactionObject,
  InvoiceLineObject,
  InvoiceObject,
  RepaymentLineObject,
  ResolutionLineObject
} from "components/Deductions/models";
import { DisplayInvoiceLineObject } from "reconciliation-types/invoiceLineTypes";
import { InvoiceLineAmountService } from ".";

// eslint-disable-next-line import/prefer-default-export
export function getCleanedInvoiceLineObject(
  displayInvoiceLine: DisplayInvoiceLineObject | InvoiceLineObject
) {
  return {
    ...displayInvoiceLine,
    fundType: null,
    displayStatus: null,
    customerName: null,
    productName: null,

    clearedAmount: null,
    totalWriteOffAmount: null,
    creditedWriteOffAmount: null,
    openWriteOffAmount: null,
    totalDisputeAmount: null,
    repaidDisputeAmount: null,
    openDisputeAmount: null,
    resolvedAmount: null,
    openAmount: null
  };
}

export function getUpdatedInvoiceLineStatus(
  invoiceLine: DisplayInvoiceLineObject,
  invoices: Record<string, InvoiceObject>,
  erpTransactions: Record<string, ERPTransactionObject>,
  newResolutionLine?: ResolutionLineObject,
  newRepaymentLine?: RepaymentLineObject
) {
  const invoice = invoices[invoiceLine.invoiceKey];

  if (newResolutionLine) {
    switch (newResolutionLine.type) {
      case resolutionLineTypes.DISPUTE: {
        return invoiceLineStatuses.DISPUTE;
      }
      case resolutionLineTypes.SALES_REVIEW: {
        return invoiceLineStatuses.SALES_REVIEW;
      }
      default: {
        // handles both clear and write-off types
        if (
          InvoiceLineAmountService.getInvoiceLineOpenAmount(
            invoiceLine,
            invoice,
            invoices,
            erpTransactions
          ) -
            Math.abs(newResolutionLine.amount || 0) ===
          0
        ) {
          return invoiceLineStatuses.CLEARED;
        }
        return invoiceLine.status;
      }
    }
  }

  if (newRepaymentLine) {
    const { openDisputeAmount, openAmount } = invoiceLine;
    // crediting write-offs does not change the status
    if (openDisputeAmount === 0.0 && openAmount === 0.0) {
      return invoiceLineStatuses.CLEARED;
    }
  }

  return invoiceLine.status;
}
