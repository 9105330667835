import clsx from "clsx";
import { CONTENT_OVERFLOW_OFFSET, CONTENT_PADDING } from "components/App";
import mergeRefs from "helpers/mergeRefs";
import React, { RefObject, useLayoutEffect, useRef } from "react";

type ModalContentType = {
  children: React.ReactNode;
  className?: string;
  style: Record<string, any>;
  innerRef: RefObject<any>;
  offset?: number;
};

function ModalContent({
  children,
  className = "",
  style,
  innerRef,
  offset = CONTENT_OVERFLOW_OFFSET
}: ModalContentType) {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (
      containerRef.current &&
      containerRef.current.scrollHeight >= window.innerHeight - offset
    ) {
      containerRef.current.style.paddingBottom = `${CONTENT_PADDING}px`;
    }
  });

  return (
    <div
      ref={mergeRefs(containerRef, innerRef)}
      style={style}
      className={clsx(className)}>
      {children}
    </div>
  );
}

export default ModalContent;
