import React from "react";

import Chip from "@mui/material/Chip";

import MaterialTable, { MTableHeader } from "@material-table/core";
import { Typography } from "@mui/material";

import { blue, grey } from "@mui/material/colors";

const grey600 = grey["600"];
const blue50 = blue["50"];

const styles = {
  miniIcon: {
    height: 15,
    width: 15,
    marginLeft: 16
  },
  chip: {
    margin: "3px",
    backgroundColor: blue50,
    color: "black",
    textSize: ".75rem",
    fontFamily: "Roboto Mono, monospace"
  }
};

class PaginationMUITable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      allLoaded: false,
      allSelected: []
    };
  }

  componentDidMount() {
    this.setState({
      allLoaded: this.props.allLoaded
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      allLoaded: nextProps.allLoaded
    });
  }

  processMultipleValues(columns) {
    const newColumns = [];

    for (let i = 0; i < columns.length; i++) {
      const col = columns[i];
      const fieldName = col.field;
      const newCol = {
        ...col
      };

      if (col.multiple) {
        newCol.render = rowData => {
          let values = rowData[fieldName];
          if (col.unPrettify) {
            values = values == "" ? [] : values.split(" | ");
          }

          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row"
              }}>
              {values.map((value, index) => {
                return <Chip label={value} sx={styles.chip} />;
              })}
            </div>
          );
        };
      }

      newColumns.push(newCol);
    }

    return newColumns;
  }

  getActions = () => {
    const actionDefault = {
      openProfile: {
        icon: "open_in_new",
        tooltip: "Open Profile",
        iconProps: {
          style: {
            color: grey600
          }
        },
        position: "row"
      },
      delete: {
        icon: "delete",
        tooltip: "Delete Item",
        iconProps: {
          style: {
            color: grey600
          }
        },
        position: "row"
      },
      download: {
        icon: "file_download",
        tooltip: "Download",
        iconProps: {
          style: {
            color: grey600
          }
        },
        position: "row"
      },
      openFolder: {
        icon: "folder_open",
        tooltip: "Open Details",
        iconProps: {
          style: {
            color: grey600
          }
        },
        position: "row"
      },
      view: {
        icon: "insert_drive_file",
        tooltip: "View Original",
        iconProps: {
          style: {
            color: grey600
          }
        },
        position: "row"
      }
    };

    return Object.entries(this.props.actionParams ?? {}).map(
      ([actionName, actionParams]) => {
        const defaultParams = actionDefault[actionName] ?? {};

        return rowData => {
          const actionParamsResult = actionParams?.(rowData) ?? {};
          return {
            ...defaultParams,
            ...actionParamsResult
          };
        };
      }
    );
  };

  render() {
    const options = {
      toolbar: false,
      selection: this.props.selection,
      headerStyle: {
        fontSize: 13,
        height: 50
      },
      rowStyle: this.props.rowStyle,
      showSelectAllCheckbox: this.props.showSelectAllCheckbox,
      pageSize: this.props.data.length,
      paging: !this.state.allLoaded,
      sorting: this.props.sorting,
      thirdSortClick: false,

      // The MaterialTable component now expects an Id per row which it uses internally
      // as key for each row. In place of ID per row and idSynonym override, it would
      // assume any random column to be unique and generate an UUID based on it;
      // which is very prone to break things.
      // https://material-table-core.com/docs/breaking-changes#id
      idSynonym: this.props.idSynonym,
      actionsCellStyle: this.props.actionsCellStyle
    };
    const components = {
      Pagination: props => (
        <div
          style={{
            height: 25,
            cursor: "pointer"
          }}
          className="centering"
          onClick={() => this.props.handleInfiniteLoad(false)}>
          <Typography variant="labelMedium" sx={{ fontWeight: 600 }}>
            {" "}
            Load more...{" "}
          </Typography>
        </div>
      ),
      Header: props => (
        <MTableHeader
          {...props}
          onOrderChange={(orderBy, orderDirection) => {
            if (this.props.onColumnSort) {
              const column = this.props.columns[orderBy].field;
              this.props.onColumnSort(column);
            }
            props.onOrderChange(orderBy, orderDirection);
          }}
        />
      )
    };
    const localization = {
      header: {
        actions: ""
      }
    };
    const actions = this.getActions();
    const updatedColumns = this.processMultipleValues(this.props.columns);

    // The MaterialTable library now sorts data by default using in-built sorting;
    // Hence we need to disable per column (no global boolean unfortunately)
    const columnsWithoutLibrarySort = updatedColumns?.map(column => ({
      ...column,
      customSort: () => 0
    }));

    const detailsPanel = Array.isArray(this.props.expandRow)
      ? this.props.expandRow
      : ({ rowData }) => this.props.expandRow(rowData);

    return (
      <div style={this.props.style}>
        <MaterialTable
          key={this.props.data.length}
          data={this.props.data}
          columns={columnsWithoutLibrarySort}
          onRowClick={this.props.handleProfileOpen}
          options={options}
          actions={actions}
          components={components}
          localization={localization}
          onSelectionChange={this.props.handleRowSelection}
          detailPanel={this.props.expandRow && detailsPanel}
          tableLayout={this.props.tableLayout}
          tableRef={this.tableRef}
        />
      </div>
    );
  }
}

PaginationMUITable.defaultProps = {
  selection: true,
  actionsParams: {},
  actionsCellStyle: {},
  tableLayout: "fixed",
  rowStyle: {
    fontSize: 13,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#EEE"
    }
  }
};

export default PaginationMUITable;
