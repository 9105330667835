import moment from "moment";

const commonColumns = [
  {
    field: "pagesScanned",
    headerClassName: "datagrid-header",
    headerName: "Pages Scanned",
    flex: 1,
    description: `Number of pages scanned. (Only includes successful scans)`
  },
  {
    field: "filesScanned",
    headerClassName: "datagrid-header",
    headerName: "Files Scanned",
    flex: 1,
    description: `Number of files scanned. (Only includes successful scans)`
  }
];

export const scanningColumns = [
  {
    field: "date",
    headerClassName: "datagrid-header",
    headerName: "Date",
    flex: 1,
    description: `Month & Year of Scans.`,
    sortComparator: (_v1, _v2, param1, param2) =>
      moment(param1.value).diff(param2.value)
  },
  ...commonColumns
];

export const scanningByBackupSourceColumns = [
  {
    field: "date",
    headerClassName: "datagrid-header",
    headerName: "Date",
    flex: 1,
    description: `Month & Year of Scans.`,
    sortComparator: (_v1, _v2, param1, param2) =>
      moment(param1.value).diff(param2.value)
  },
  {
    field: "backupSource",
    headerClassName: "datagrid-header",
    headerName: "Backup Source",
    flex: 1,
    description: `Deduction file template.`
  },
  ...commonColumns
];
