import React from "react";
import { useDb } from "./Db";

const withDb = (Component: typeof React.Component) => {
  return function WrappedComponent(props) {
    const hook = useDb();
    return <Component {...props} {...{ db: useDb() }} />;
  };
};

export default withDb;
