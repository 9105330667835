import React from "react";

import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import Checkbox from "ui-library/Checkbox";
import Chip from "@mui/material/Chip";
import Subheader from "ui-library/Subheader";
import IconButton from "ui-library/IconButton";
import Button from "ui-library/Button";
import TextField from "ui-library/TextField";
import MenuItem from "ui-library/MenuItem";
import Select from "ui-library/Select";
import { infoDialog, confirmDialog } from "helpers/OpenDialog";
import {
  getMetaFirebase,
  updateMetaFirebase,
  getJSXListsFirebase,
  makeKeys
} from "helpers/Firebase";
import { importBackupSources } from "components/Deductions/constants/BackupSources";
import { grey } from "@mui/material/colors";
import { Stack } from "@mui/material";
import {
  deductionTransactionStatuses,
  repaymentTransactionStatuses
} from "components/Deductions/constants/ReconciliationStatuses";
import DataTable from "../tables/MuiDataTable";

const grey300 = grey["300"];
const grey500 = grey["500"];
const grey700 = grey["700"];

const styles = {
  notifs: {
    width: "60%"
  },
  subheader: {
    color: "primary.main"
  },
  productList: {
    borderColor: grey700,
    borderWidth: 1,
    borderStyle: "solid"
    // overflow: 'auto',
    // height: 300
  },
  moneyIcon: {
    width: 15,
    height: 15
  },
  radioButton: {
    marginBottom: 16
  },
  textField: {
    color: grey500,
    borderColor: grey500
  },
  chip: {
    margin: 4
  },
  wrapper: {
    display: "flex",
    flexWrap: "wrap"
  }
};

class DeductionsRevenue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      meta: {},
      userCompany: null,
      newRevSource: "",
      newDedSource: "",
      importLoading: false,
      customersJSX: [],
      budgetDiffThreshold: null,
      budgetDiffChanged: false,
      backupSources: {}
    };
  }

  handleAddRevSource = () => {
    if (this.state.newRevSource) {
      const revenueSources = this.state.meta.revenue_sources
        ? this.state.meta.revenue_sources
        : [];
      revenueSources.push(this.state.newRevSource);
      updateMetaFirebase({ revenue_sources: revenueSources });
      this.setState({
        newRevSource: ""
      });
    }
  };

  handleAddDedSource = () => {
    if (this.state.newDedSource) {
      const deductionSources = this.state.meta.deductions_sources
        ? this.state.meta.deductions_sources
        : [];
      deductionSources.push(this.state.newDedSource);
      updateMetaFirebase({ deductions_sources: deductionSources });
      this.setState({
        newDedSource: ""
      });
    }
  };

  sourceHasDependencies = source => {
    const { revenue } = this.props.db;

    const dependencies = [];

    if (Object.values(revenue).some(moneyItem => moneyItem.source === source)) {
      dependencies.push("revenue");
    }

    if (this.hasTransactionDependency(source)) {
      dependencies.push("active transactions");
    }

    return dependencies;
  };

  handleDeleteRevSource = source => {
    const dependencies = this.sourceHasDependencies(source);

    if (dependencies.length > 0) {
      infoDialog(
        this.props.openClose,
        "Error - Delete Revenue Source",
        `${source} cannot deleted because it has the following dependencies: ${dependencies.join(
          ", "
        )}. Please reconcile these before trying again.`
      );
      return;
    }

    confirmDialog(
      this.props.openClose,
      "Delete Revenue Source",
      "Are you sure you want to delete this revenue source?",
      () => {
        this.props.openClose.closeAppModal();
        const revenueSources = this.state.meta.revenue_sources;
        const accountingSources = this.state.meta.accounting_sources
          ? this.state.meta.accounting_sources
          : [];
        revenueSources.splice(revenueSources.indexOf(source), 1);
        if (accountingSources.includes(source)) {
          accountingSources.splice(accountingSources.indexOf(source), 1);
        }
        updateMetaFirebase({
          revenue_sources: revenueSources,
          accounting_sources: accountingSources
        });
      }
    );
  };

  handleDeleteDedSource = source => {
    this.props.openClose.setAppModal(
      "Delete Revenue Source",
      <div className="centering">
        Are you sure you want to delete this deductions source?
      </div>,

      <Stack>
        <Button
          label="Yes, I'm sure"
          variant="text"
          color="error"
          onClick={() => {
            this.props.openClose.closeAppModal();
            const deductionsSources = this.state.meta.deductions_sources;
            deductionsSources.splice(deductionsSources.indexOf(source), 1);
            updateMetaFirebase({
              deductions_sources: deductionsSources
            });
          }}
        />
        <Button
          label="No, go back"
          variant="text"
          onClick={() => {
            this.props.openClose.closeAppModal();
          }}
        />
      </Stack>
    );
  };

  handleAccountingSourceCheck = (source, event) => {
    const isInputChecked = event.target.checked;
    if (source == null) {
      if (isInputChecked) {
        const revenueSources = this.state.meta.revenue_sources || [];
        updateMetaFirebase({ accounting_sources: revenueSources });
      } else {
        updateMetaFirebase({ accounting_sources: [] });
      }
    } else {
      const accountingSources = this.state.meta.accounting_sources || [];
      if (isInputChecked) {
        accountingSources.push(source);
      } else {
        accountingSources.splice(accountingSources.indexOf(source), 1);
      }
      updateMetaFirebase({ accounting_sources: accountingSources });
    }
  };

  getAccountingSourcesJSX = () => {
    const accountingSources = this.state.meta.accounting_sources || [];
    const revenueSources = this.state.meta.revenue_sources || [];
    const accountingSourcesJSX = {
      "Source Name": [
        <div style={{ fontWeight: "bold" }}>Use All Revenue In Accounting</div>
      ],
      "Is Accounting Source": [
        <Checkbox
          checked={accountingSources.length === revenueSources.length}
          onChange={event => {
            if (!event.target.checked) {
              const sourcesWithTransactions = accountingSources.filter(source =>
                this.hasTransactionDependency(source)
              );
              if (sourcesWithTransactions.length > 0) {
                infoDialog(
                  this.props.openClose,
                  `Error - `,
                  `Cannot set all to non-accounting source types because the following have at least one active transaction dependency: ${sourcesWithTransactions.join(
                    ", "
                  )}`,
                  `Go Back`
                );
                return;
              }
            }
            this.handleAccountingSourceCheck(null, event);
          }}
          disabled={
            !this.props.editPermission ||
            !this.props.db.permissions.includes("admin") ||
            this.props.db.meta.tier == "pine"
          }
        />
      ]
    };
    for (const source of revenueSources) {
      const isChecked = accountingSources.includes(source);
      accountingSourcesJSX["Source Name"].push(source);
      accountingSourcesJSX["Is Accounting Source"].push(
        <Checkbox
          checked={isChecked}
          onChange={event => {
            if (this.hasTransactionDependency(source)) {
              infoDialog(
                this.props.openClose,
                `Error - Edit Accounting Source`,
                `There are active transactions in the system from ${source}. Please cancel all of them before editing the accounting source.`,
                `Go Back`
              );
              return;
            }
            this.handleAccountingSourceCheck(source, event);
          }}
          disabled={
            !this.props.editPermission ||
            !this.props.db.permissions.includes("admin") ||
            this.props.db.meta.tier == "pine"
          }
        />
      );
    }

    return accountingSourcesJSX;
  };

  selectWildcard = (source, matchType, event, index, value) => {
    let matchingWildCards = {};
    if (matchType === "dmm") {
      matchingWildCards = this.state.meta.matching_wildcards || {};
    } else {
      matchingWildCards =
        this.state.meta?.drm_deductions_sources?.matching_wildcards || {};
    }
    matchingWildCards[source] = value;
    if (matchType === "dmm") {
      updateMetaFirebase({ matching_wildcards: matchingWildCards });
    } else {
      updateMetaFirebase({
        "drm_deductions_sources/matching_wildcards": matchingWildCards
      });
    }
  };

  selectedCorrespondingCustomer = (source, matchType, event, index, value) => {
    let deductionsSourcesCustomers = {};
    if (matchType === "dmm") {
      deductionsSourcesCustomers =
        this.state.meta.deductions_sources_customers || {};
    } else {
      deductionsSourcesCustomers =
        this.state.meta?.drm_deductions_sources?.deductions_sources_customers ||
        {};
    }
    deductionsSourcesCustomers[source] = value;
    if (matchType === "dmm") {
      updateMetaFirebase({
        deductions_sources_customers: deductionsSourcesCustomers
      });
    } else {
      updateMetaFirebase({
        "drm_deductions_sources/deductions_sources_customers":
          deductionsSourcesCustomers
      });
    }
  };

  checkEnforceSourceMatch = (source, matchType, event) => {
    let enforceSourceMatch = {};
    if (matchType === "dmm") {
      enforceSourceMatch = this.state.meta.enforce_source_match || {};
    } else {
      enforceSourceMatch =
        this.state.meta?.drm_deductions_sources?.enforce_source_match || {};
    }
    enforceSourceMatch[source] = event.target.checked;
    if (matchType === "dmm") {
      updateMetaFirebase({
        enforce_source_match: enforceSourceMatch
      });
    } else {
      updateMetaFirebase({
        "drm_deductions_sources/enforce_source_match": enforceSourceMatch
      });
    }
  };

  changeMatchConfidenceThreshold = (source, matchType, event) => {
    const { value } = event.target;
    let update = {};
    if (matchType === "dmm") {
      update = this.state.meta.deductions_matching_thresholds || {};
    } else {
      update =
        this.state.meta?.drm_deductions_sources
          ?.deductions_matching_thresholds || {};
    }
    update[source] = parseInt(value) || 0;
    if (matchType === "dmm") {
      updateMetaFirebase({
        deductions_matching_thresholds: update
      });
    } else {
      updateMetaFirebase({
        "drm_deductions_sources/deductions_matching_thresholds": update
      });
    }
  };

  getDeductionsSourcesDMMJSX = () => {
    const deductionsSources = this.state.meta.deductions_sources || [];
    const deductionsSourcesCustomers =
      this.state.meta.deductions_sources_customers || {};
    const confidenceThresholds =
      this.state.meta.deductions_matching_thresholds || {};
    const enforceSourceMatch = this.state.meta.enforce_source_match || {};
    const wildcards = this.state.meta.matching_wildcards || {};
    const deductionsSourcesJSX = {
      "Source Name": [<div style={{ fontWeight: "bold" }}>Other</div>],
      "Corresponding Customer": [
        <Select
          value={deductionsSourcesCustomers.other}
          onChange={this.selectedCorrespondingCustomer.bind(
            null,
            "other",
            "dmm"
          )}
          disabled>
          <MenuItem value={null} children="Other" key="Other" />
          {this.state.customersJSX}
        </Select>
      ],
      "Enforce Source Match": [
        <Checkbox
          checked={false}
          disabled
          style={{ fontFamily: "PT Sans", marginTop: 10 }}
        />
      ],
      "Matching Wildcard": [
        <Select
          value={wildcards.other}
          onChange={this.selectWildcard.bind(null, "other", "dmm")}
          disabled={!this.props.editPermission}>
          <MenuItem value={null} children="Other" key="Other" />
          {this.state.customersJSX}
        </Select>
      ],
      "Confidence Threshold (%)": [
        <TextField
          fullWidth
          value={confidenceThresholds.Other || 0}
          onChange={this.changeMatchConfidenceThreshold.bind(
            null,
            "Other",
            "dmm"
          )}
          disabled={!this.props.editPermission}
        />
      ]
    };
    for (const source of deductionsSources) {
      const sources = deductionsSourcesCustomers[source] || [];
      const threshold = confidenceThresholds[source] || 0;
      deductionsSourcesJSX["Source Name"].push(source);
      deductionsSourcesJSX["Corresponding Customer"].push(
        <Select
          value={Array.isArray(sources) ? sources : [sources]}
          onChange={this.selectedCorrespondingCustomer.bind(
            null,
            source,
            "dmm"
          )}
          disabled={!this.props.editPermission}
          multiple>
          <MenuItem value={null} children="None" key="None" />
          {this.state.customersJSX}
        </Select>
      );
      deductionsSourcesJSX["Enforce Source Match"].push(
        <Checkbox
          checked={enforceSourceMatch[source]}
          onChange={this.checkEnforceSourceMatch.bind(null, source, "dmm")}
          style={{ fontFamily: "PT Sans", marginTop: 10 }}
          disabled={!this.props.editPermission}
        />
      );
      deductionsSourcesJSX["Matching Wildcard"].push(
        <Select
          value={wildcards[source]}
          onChange={this.selectWildcard.bind(null, source, "dmm")}
          disabled={!this.props.editPermission}>
          <MenuItem value={null} children="None" key="None" />
          {this.state.customersJSX}
        </Select>
      );
      deductionsSourcesJSX["Confidence Threshold (%)"].push(
        <TextField
          fullWidth
          value={threshold}
          onChange={this.changeMatchConfidenceThreshold.bind(
            null,
            source,
            "dmm"
          )}
          disabled={!this.props.editPermission}
        />
      );
    }

    return (
      <div>
        <Subheader style={styles.subheader}>Deduction Sources</Subheader>
        <Stack>
          {this.state.meta.deductions_sources &&
          this.state.meta.deductions_sources.length > 0 ? (
            this.state.meta.deductions_sources.map((source, index) => {
              return this.props.editPermission ? (
                <Chip
                  style={styles.chip}
                  label={source}
                  onDelete={this.handleDeleteDedSource.bind(null, source)}
                />
              ) : (
                <Chip style={styles.chip} label={source} />
              );
            })
          ) : (
            <Chip
              style={Object.assign(jQuery.extend(true, {}, styles.chip), {
                backgroundColor: grey300
              })}
              label="None"
            />
          )}
        </Stack>
        <br />
        {this.props.editPermission && (
          <div className="rowC">
            <TextField
              floatingLabelText="New Source"
              onChange={event => {
                this.setState({ newDedSource: event.target.value });
              }}
              onKeyPress={ev => {
                if (ev.key === "Enter") {
                  this.handleAddDedSource();
                  ev.preventDefault();
                  this.setState({ newDedSource: "" });
                }
              }}
              value={this.state.newDedSource}
              style={{ marginTop: -5 }}
            />
            <IconButton onClick={this.handleAddDedSource} size="large">
              <AddIcon />
            </IconButton>
          </div>
        )}
        <br />
        <Subheader style={styles.subheader}>
          Deductions Matching - Sources
        </Subheader>
        <div style={{ margin: 10 }}>
          <DataTable
            title="Assign fields to each deduction source."
            data={deductionsSourcesJSX}
          />
        </div>
      </div>
    );
  };

  hasTransactionDependency = accountingSource => {
    const { erpTransactions = {} } = this.props.db;
    return Object.values(erpTransactions)
      .filter(
        transaction =>
          transaction.status !== deductionTransactionStatuses.CANCELLED &&
          transaction.status !== repaymentTransactionStatuses.CANCELLED
      )
      .some(transaction => transaction.accountingSource === accountingSource);
  };

  getTransactionConfigJSX = () => {
    const { meta } = this.props.db;
    const allTransactionConfigs = meta.transaction_config || {};
    const allAccountingSources = meta.accounting_sources || [];

    const data = {
      "Accounting Source": [],
      "Incoming Deductions Positive": []
    };

    allAccountingSources.forEach(source => {
      const [configKey, config] = Object.entries(allTransactionConfigs).find(
        entry => entry[1].accountingSource === source
      ) || [null, {}];
      data["Accounting Source"].push(source);
      data["Incoming Deductions Positive"].push(
        <Checkbox
          checked={config.deductionsIncomingPositive}
          onChange={event => {
            if (this.hasTransactionDependency(source)) {
              infoDialog(
                this.props.openClose,
                `Error - Edit Accounting Source`,
                `${source} cannot be changed because at least one active transaction was uploaded from it. Please cancel all relevant transactions and try again.`,
                `Go Back`
              );
              return;
            }
            let newConfigKey = configKey;
            if (!newConfigKey) {
              while (
                !newConfigKey ||
                Object.keys(allTransactionConfigs).includes(newConfigKey)
              ) {
                [newConfigKey] = makeKeys(1, null, 10);
              }
            }

            updateMetaFirebase({
              transaction_config: {
                ...allTransactionConfigs,
                [newConfigKey]: {
                  accountingSource: source,
                  deductionsIncomingPositive: event.target.checked
                }
              }
            });
          }}
          style={{ fontFamily: "PT Sans", marginTop: 10 }}
          disabled={!this.props.editPermission}
        />
      );
    });

    return (
      <div>
        <Subheader style={styles.subheader}>
          Deductions and Repayments
        </Subheader>
        <div style={{ margin: 10 }}>
          <DataTable
            title="Configure deductions vs repayments"
            data={data}
            height={400}
          />
        </div>
      </div>
    );
  };

  getDeductionsSourcesDRMJSX = () => {
    const deductionsSourcesCustomers =
      this.state.meta?.drm_deductions_sources?.deductions_sources_customers ||
      {};
    const confidenceThresholds =
      this.state.meta?.drm_deductions_sources?.deductions_matching_thresholds ||
      {};
    const enforceSourceMatch =
      this.state.meta?.drm_deductions_sources?.enforce_source_match || {};
    const wildcards =
      this.state.meta?.drm_deductions_sources?.matching_wildcards || {};
    const deductionsSourcesJSX = {
      "Source Name": [],
      "Corresponding Customer": [],
      "Enforce Source Match": [],
      "Matching Wildcard": [],
      "Confidence Threshold (%)": []
    };
    for (const [source, sourceObj] of Object.entries(
      this.state.backupSources
    )) {
      const sourceDisplayName = sourceObj.DISPLAY_NAME;
      const sources = deductionsSourcesCustomers[source] || [];
      const threshold = confidenceThresholds[source] || 0;
      deductionsSourcesJSX["Source Name"].push(sourceDisplayName);
      deductionsSourcesJSX["Corresponding Customer"].push(
        <Select
          value={Array.isArray(sources) ? sources : [sources]}
          onChange={this.selectedCorrespondingCustomer.bind(
            null,
            source,
            "drm"
          )}
          disabled={!this.props.editPermission}
          multiple>
          <MenuItem value={null} children="None" key="None" />
          {this.state.customersJSX}
        </Select>
      );
      deductionsSourcesJSX["Enforce Source Match"].push(
        <Checkbox
          checked={enforceSourceMatch[source]}
          onChange={this.checkEnforceSourceMatch.bind(null, source, "drm")}
          style={{ fontFamily: "PT Sans", marginTop: 10 }}
          disabled={!this.props.editPermission}
        />
      );
      deductionsSourcesJSX["Matching Wildcard"].push(
        <Select
          value={wildcards[source]}
          onChange={this.selectWildcard.bind(null, source, "drm")}
          disabled={!this.props.editPermission}>
          <MenuItem value={null} children="None" key="None" />
          {this.state.customersJSX}
        </Select>
      );
      deductionsSourcesJSX["Confidence Threshold (%)"].push(
        <TextField
          fullWidth
          value={threshold}
          onChange={this.changeMatchConfidenceThreshold.bind(
            null,
            source,
            "drm"
          )}
          disabled={!this.props.editPermission}
        />
      );
    }

    return (
      <div>
        <Subheader style={styles.subheader}>
          Deductions Matching - Sources
        </Subheader>
        <div style={{ margin: 10 }}>
          <DataTable
            title="Assign fields to each deduction source."
            data={deductionsSourcesJSX}
            height={400}
          />
        </div>
      </div>
    );
  };

  changeCutoff = (source, promType, event) => {
    const { value } = event.target;
    const { meta } = this.state;
    const update = meta.discountCutoffs;
    update[source][promType].cutoff = parseInt(value) || 0;
    updateMetaFirebase({ discountCutoffs: update });
  };

  changeComparison = (source, promType, event, index, value) => {
    const { meta } = this.state;
    const update = meta.discountCutoffs || {};
    update[source][promType].comparison = value;
    updateMetaFirebase({ discountCutoffs: update });
  };

  getDiscountCutoffTableJSX = (source, tableData) => {
    const discountCutoffTableJSX = {
      "Promotion Type": [],
      Comparison: [],
      "Cutoff (%)": []
    };

    for (const [promType, data] of Object.entries(tableData)) {
      discountCutoffTableJSX["Promotion Type"].push(
        this.props.db.meta.fundTypes?.[promType]?.name
      );
      discountCutoffTableJSX.Comparison.push(
        <Select
          value={data.comparison}
          onChange={this.changeComparison.bind(null, source, promType)}
          disabled={!this.props.editPermission}>
          <MenuItem value="ge" key="ge">
            <div>&ge;</div>
          </MenuItem>
          <MenuItem value="lt" key="lt">
            <div>&lt;</div>
          </MenuItem>
        </Select>
      );
      discountCutoffTableJSX["Cutoff (%)"].push(
        <TextField
          fullWidth
          value={data.cutoff}
          onChange={this.changeCutoff.bind(null, source, promType)}
          disabled={!this.props.editPermission}
        />
      );
    }

    return discountCutoffTableJSX;
  };

  sourcePromTypeChanged = (source, event, index, values) => {
    const { meta } = this.state;
    const update = meta.discountCutoffs || {};
    for (var promType of values) {
      if (!(source in update)) {
        update[source] = {};
      }
      if (!(promType in update[source])) {
        update[source][promType] = {
          comparison: "ge",
          cutoff: 0
        };
      }
    }
    for (var promType in update[source]) {
      if (!values.includes(promType)) {
        delete update[source][promType];
      }
    }
    updateMetaFirebase({ discountCutoffs: update });
  };

  getDiscountCutoffsJSX = () => {
    const sources = ["UNFI East", "UNFI West", "KeHE Bulk MCB"];
    const { discountCutoffs = {}, fundTypes = {} } = this.state.meta;

    const discountCutoffsJSX = [];
    for (const source of sources) {
      const cutoffData = discountCutoffs[source] || {};
      var selectedProms = Object.keys(cutoffData);

      discountCutoffsJSX.push(
        <DataTable
          title={
            <div className="rowC" style={{ alignItems: "center" }}>
              <div>{source}</div>
              <div style={{ minWidth: "150px", marginLeft: "100px" }}>
                <Select
                  floatingLabelText="Promotion Types"
                  value={selectedProms}
                  onChange={this.sourcePromTypeChanged.bind(null, source)}
                  multiple
                  size="small"
                  style={{ position: "relative", width: "100%" }}
                  disabled={!this.props.editPermission}>
                  {Object.entries(fundTypes).map(([k, type]) => {
                    return (
                      <MenuItem
                        value={k}
                        checked={selectedProms.includes(k)}
                        key={k}>
                        {type.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
            </div>
          }
          data={this.getDiscountCutoffTableJSX(source, cutoffData)}
        />
      );
    }

    return discountCutoffsJSX;
  };

  selectForecastBucketCustomer = (customer, event, index, value) => {
    const correspondingCustomers =
      this.state.meta.forecast_all_other_corresponding_customers || {};
    correspondingCustomers[customer] = value;
    updateMetaFirebase(
      correspondingCustomers,
      "forecast_all_other_corresponding_customers"
    );
  };

  updateForecastAllOther = newBuckets => {
    const {
      meta: {
        forecast_all_other_corresponding_customers: allOtherBucketsDistributors
      }
    } = this.state;
    const filteredAllOtherBucketsDistributors = Object.fromEntries(
      Object.entries(allOtherBucketsDistributors ?? {}).filter(
        ([allOtherBucket]) => newBuckets.includes(allOtherBucket)
      )
    );
    updateMetaFirebase({
      forecast_all_other: newBuckets,
      forecast_all_other_corresponding_customers:
        filteredAllOtherBucketsDistributors
    });
    this.props.openClose.closeAppModal();
  };

  handleDeleteAllOtherBucket = bucket => {
    this.props.openClose.setAppModal(
      "Delete All Other Bucket",
      <div className="centering">
        Are you sure you want to delete this bucket?
      </div>,

      <Stack>
        <Button
          label="Yes, I'm sure"
          variant="text"
          color="error"
          onClick={() => {
            const {
              meta: { forecast_all_other: allOtherBuckets }
            } = this.state;
            this.updateForecastAllOther(
              allOtherBuckets.filter(
                allOtherBucket => allOtherBucket !== bucket
              )
            );
          }}
        />
        <Button
          label="No, go back"
          variant="text"
          onClick={() => {
            this.props.openClose.closeAppModal();
          }}
        />
      </Stack>
    );
  };

  getForecastAllOtherBucketsJSX = () => {
    const forecastAllOtherBuckets = this.state.meta.forecast_all_other || [];
    const correspondingCustomers =
      this.state.meta.forecast_all_other_corresponding_customers || {};
    const forecastAllOtherBucketsJSX = {
      "Bucket Name": [],
      "Corresponding Distributor": []
    };
    const allCustomers = this.props.db.customers;
    for (const customer of forecastAllOtherBuckets) {
      const correspondingCustomerKey = correspondingCustomers[customer];
      forecastAllOtherBucketsJSX["Bucket Name"].push(
        this.props.db.customers[customer].name
      );
      forecastAllOtherBucketsJSX["Corresponding Distributor"].push(
        <Select
          value={correspondingCustomerKey}
          onChange={this.selectForecastBucketCustomer.bind(null, customer)}
          disabled={
            !this.props.editPermission || this.props.db.meta.tier == "pine"
          }>
          <MenuItem value={null} key="None">
            None
          </MenuItem>
          {this.state.customersJSX.filter(
            x =>
              allCustomers[x.props.value].isDistributor &&
              !allCustomers[x.props.value].isDirect
          )}
        </Select>
      );
    }

    return forecastAllOtherBucketsJSX;
  };

  getTypePassThroughJSX = () => {
    const fundTypes = this.state.meta.fundTypes ?? {};
    const passThroughTypes = this.state.meta.passThroughTypes ?? [];
    return (
      <Select
        floatingLabelText="Promotion Types"
        value={passThroughTypes}
        onChange={(event, index, values) => {
          updateMetaFirebase({ passThroughTypes: values });
        }}
        multiple
        disabled={
          !this.props.editPermission || this.props.db.meta.tier == "pine"
        }>
        {Object.entries(fundTypes).map(([k, type]) => {
          return (
            <MenuItem value={k} checked={passThroughTypes.includes(k)} key={k}>
              {type.name}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  componentDidMount() {
    getMetaFirebase(meta => {
      this.setState({ meta });
    });
    getJSXListsFirebase(
      this.props.db,
      allJSX => {
        this.setState({ customersJSX: allJSX.customersJSX });
      },
      null,
      true
    );
    importBackupSources()
      .then(backupSources => {
        this.setState(prev => ({ ...prev, backupSources }));
      })
      .catch(error => {
        console.log(error);
      });
  }

  handleBudgetThresholdChange = () => {
    const { budgetDiffThreshold } = this.state;
    updateMetaFirebase({ budget_diff_threshold: Number(budgetDiffThreshold) });
    this.setState({ budgetDiffChanged: false });
  };

  render() {
    const accountingSourcesJSX = this.getAccountingSourcesJSX();
    const transactionConfigJSX = this.getTransactionConfigJSX();
    const deductionsSourcesJSX = this.props.db.meta.deductions_reconciliation
      ? this.getDeductionsSourcesDRMJSX()
      : this.getDeductionsSourcesDMMJSX();
    const discountCutoffsJSX = this.getDiscountCutoffsJSX();
    const forecastAllOtherBuckets = this.state.meta.forecast_all_other || [];
    const forecastAllOtherBucketsJSX = this.getForecastAllOtherBucketsJSX();
    const typePassThroughJSX = this.getTypePassThroughJSX();
    return (
      <div>
        <Subheader style={styles.subheader}>Revenue Sources</Subheader>
        <div style={styles.wrapper}>
          {this.state.meta.revenue_sources &&
          this.state.meta.revenue_sources.length > 0 ? (
            this.state.meta.revenue_sources.map((source, index) => {
              return this.props.editPermission &&
                this.props.db.meta.tier != "pine" ? (
                <Chip
                  style={styles.chip}
                  label={source}
                  onDelete={this.handleDeleteRevSource.bind(null, source)}
                />
              ) : (
                <Chip style={styles.chip} label={source} />
              );
            })
          ) : (
            <Chip
              style={Object.assign(jQuery.extend(true, {}, styles.chip), {
                backgroundColor: grey300
              })}
              label="None"
            />
          )}
        </div>
        <br />
        {this.props.editPermission && this.props.db.meta.tier != "pine" && (
          <div className="rowC">
            <TextField
              floatingLabelText="New Source"
              onChange={event => {
                this.setState({ newRevSource: event.target.value });
              }}
              onKeyPress={ev => {
                if (ev.key === "Enter") {
                  this.handleAddRevSource();
                  ev.preventDefault();
                  this.setState({ newRevSource: "" });
                }
              }}
              value={this.state.newRevSource}
              style={{ marginTop: -5 }}
            />
            <IconButton onClick={this.handleAddRevSource} size="large">
              <AddIcon />
            </IconButton>
          </div>
        )}
        <br />
        <Subheader style={styles.subheader}>
          Select Accounting Sources
        </Subheader>
        <div style={{ margin: 10 }}>
          <DataTable
            title="Select accounting sources (used in Promotion Timing Analysis filter)"
            data={accountingSourcesJSX}
          />
        </div>
        {this.props.db.meta.deductions_reconciliation && transactionConfigJSX}
        {deductionsSourcesJSX}
        <Subheader style={styles.subheader}>
          Forecast - All Other Bucket Assignment
        </Subheader>
        <Stack direction="column" alignItems="flex-start">
          <div style={styles.wrapper}>
            {forecastAllOtherBuckets.length > 0 ? (
              forecastAllOtherBuckets.map((bucket, index) => {
                return this.props.editPermission &&
                  this.props.db.meta.tier != "pine" ? (
                  <Chip
                    style={styles.chip}
                    onDelete={this.handleDeleteAllOtherBucket.bind(
                      null,
                      bucket
                    )}
                    label={this.props.db.customers[bucket].name}
                  />
                ) : (
                  <Chip
                    style={styles.chip}
                    label={this.props.db.customers[bucket].name}
                  />
                );
              })
            ) : (
              <Chip
                style={Object.assign(jQuery.extend(true, {}, styles.chip), {
                  backgroundColor: grey300
                })}>
                None
              </Chip>
            )}
          </div>
          <div>
            <Select
              floatingLabelText="Select All Other Buckets"
              value={forecastAllOtherBuckets}
              onChange={(event, index, values) => {
                this.updateForecastAllOther(values);
              }}
              multiple
              disabled={
                !this.props.editPermission || this.props.db.meta.tier == "pine"
              }>
              {this.state.customersJSX}
            </Select>
          </div>
        </Stack>

        <br />
        <DataTable
          title="Assign corresponding customer to each All Other bucket"
          data={forecastAllOtherBucketsJSX}
        />
        <Subheader style={styles.subheader}>
          Deductions Matching - Discount Cutoffs
        </Subheader>
        <br />
        {discountCutoffsJSX}
        <br />
        <Subheader style={styles.subheader}>
          Trade Rates - Promotion Type Pass Through
        </Subheader>
        {typePassThroughJSX}
        <Subheader style={styles.subheader}>
          Budget - Threshold for differential values (in %)
        </Subheader>
        <div className="rowC">
          <TextField
            onChange={event =>
              this.setState({
                budgetDiffThreshold: event.target.value,
                budgetDiffChanged: true
              })
            }
            onKeyPress={event => {
              if (event.key === "Enter") {
                event.preventDefault();
                this.handleBudgetThresholdChange();
              }
            }}
            value={
              this.state.budgetDiffThreshold ??
              (this.state.meta?.budget_diff_threshold || 20)
            }
          />
          <IconButton
            onClick={this.handleBudgetThresholdChange}
            disabled={!this.state.budgetDiffChanged}
            size="large">
            <CheckIcon />
          </IconButton>
        </div>
      </div>
    );
  }
}

export default DeductionsRevenue;
