import React, { useState, useCallback, useRef } from "react";
import { Button, Card, Stack, Typography, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AxiosPromise } from "axios";
import { RefetchOptions } from "axios-hooks";
import { useOpenClose } from "contexts/OpenClose";
import { ForecastVersion } from "./types";
import {
  useEditForecastVersion,
  useDeleteVersion,
  useCopyVersion
} from "./api";
import { renderVersionToString } from "./utilities";

interface EditForecastCardProps {
  version: ForecastVersion;
  showCopyButton: boolean;
  refetchVersions: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    config?: any,
    options?: RefetchOptions | undefined
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => AxiosPromise<any>;
}

const useStyles = makeStyles(() => ({
  card: {
    marginTop: "15px",
    fontFamily: "Oxygen"
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingTop: "10px",
    paddingBottom: "15px"
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "150px"
  },
  inputContainer: {
    width: "100%",
    marginBottom: "15px"
  },
  textContainer: {
    marginBottom: "15px"
  },
  input: {
    width: "100%",
    borderWidth: 1,
    borderStye: "solid"
  },
  cardTitle: {
    lineHeight: "20.2px",
    fontWeight: 700
  }
}));

const EditForecastCard = (props: EditForecastCardProps): JSX.Element => {
  const { version, showCopyButton, refetchVersions } = props;
  const openClose = useOpenClose();
  const {
    version_name,
    filename,
    companyid,
    creation_time,
    version_num,
    upload_user
  } = version;

  const [inputName, setInputName] = useState<string>(version_name ?? "");
  const [isEditable, setIsEditable] = useState<boolean>(false);

  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);

  const createdDate = new Date(creation_time).toLocaleDateString();

  const [{ loading }, executeEditForecastVersion] = useEditForecastVersion();
  const updateForecastVersion = useCallback(async () => {
    await executeEditForecastVersion({
      data: {
        company_id: companyid,
        new_name: inputName,
        version_num: version_num ?? 0
      }
    });
  }, [companyid, version_num, executeEditForecastVersion, inputName]);

  const handleVersionInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInputName(value);
  };

  const handleOnEditClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsEditable(true);
  };

  const [_delHook, deleteVersion] = useDeleteVersion();
  const handleOnDeleteClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    openClose.setAppModal(
      "Delete Version",
      <div>
        Are you sure you want to delete{" "}
        {version_name ?? `version ${version_num}`}?
      </div>,
      <Stack>
        <Button variant="text" color="error" onClick={handleDelete}>
          Yes, I'm sure
        </Button>
        <Button
          variant="text"
          onClick={() => {
            openClose.closeAppModal();
          }}>
          No, go back
        </Button>
      </Stack>
    );
  };

  const [_copyHook, copyVersion] = useCopyVersion();
  const handleOnCopyClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    openClose.setAppModal(
      "Make Copy",
      <div>
        Are you sure you want to make a copy of{" "}
        {version_name ?? `version ${version_num}`}?
      </div>,
      <Stack>
        <Button variant="text" onClick={handleCopy}>
          Yes, I'm sure
        </Button>
        <Button
          variant="text"
          color="error"
          onClick={() => {
            openClose.closeAppModal();
          }}>
          No, go back
        </Button>
      </Stack>
    );
  };

  const handleSave = async () => {
    await updateForecastVersion();
    await refetchVersions();
    setIsEditable(false);
  };

  const handleDelete = async () => {
    openClose.closeAppModal();
    await deleteVersion({
      data: {
        version_num,
        company_id: companyid
      }
    });
    await refetchVersions();
  };

  const handleCopy = async () => {
    openClose.closeAppModal();
    await copyVersion({
      data: {
        version_num,
        company_id: companyid
      }
    });
    await refetchVersions();
  };

  const handleEnter = async (e: React.KeyboardEvent<HTMLDivElement>) => {
    const { key } = e;
    if (key === "Enter") {
      e.preventDefault();
      if (inputRef.current === document.activeElement && inputName !== "") {
        await handleSave();
      }
      inputRef.current?.blur();
    }
  };

  const cardContent = (
    <div className={classes.cardContainer}>
      {isEditable ? (
        <div className={classes.inputContainer}>
          <TextField
            label="Version Name"
            inputRef={inputRef}
            className={classes.input}
            placeholder={renderVersionToString(version)}
            value={inputName}
            autoFocus
            onKeyPress={handleEnter}
            onChange={handleVersionInput}
            onBlur={() => {
              setIsEditable(false);
            }}
          />
        </div>
      ) : (
        <div className={classes.textContainer}>
          <Typography variant="h6" className={classes.cardTitle}>
            {renderVersionToString(version)}
          </Typography>
          <Typography>{`Created on ${createdDate} by ${upload_user}`}</Typography>
        </div>
      )}
      {isEditable ? (
        <div className={classes.buttonsContainer}>
          <Button
            variant="outlined"
            onMouseDown={(e: React.MouseEvent<HTMLButtonElement>) =>
              e.preventDefault()
            }
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault();
              handleSave();
            }}>
            Save
          </Button>
          <Button
            onMouseDown={(e: React.MouseEvent<HTMLButtonElement>) =>
              e.preventDefault()
            }
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault();
              setIsEditable(false);
            }}>
            Cancel
          </Button>
        </div>
      ) : (
        <div>
          <Button variant="outlined" size="small" onClick={handleOnEditClick}>
            Rename
          </Button>
          &nbsp;
          <Button
            variant="outlined"
            color="error"
            size="small"
            onClick={handleOnDeleteClick}>
            Delete
          </Button>
          {showCopyButton && (
            <>
              &nbsp;
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                onClick={handleOnCopyClick}>
                Make Copy
              </Button>
            </>
          )}
        </div>
      )}
    </div>
  );

  return <Card className={classes.card}>{cardContent}</Card>;
};

export default EditForecastCard;
