import DateParser from "fast-date-parse";

// 'data' is an array of objects, each of which has a date field from which a date object is created
function sortByDate(
  data,
  reverse = false,
  dateField = "date",
  convertLocale = true
) {
  if (!data) return data;
  var sorted = data.slice(); // create shallow copy
  for (var i = 0; i < sorted.length; i++) {
    sorted[i][dateField] = new Date(sorted[i][dateField]);
  }

  var sorted = sorted.sort(function (a, b) {
    // compare the dates
    const aTime = a[dateField].getTime();
    const bTime = b[dateField].getTime();
    return reverse ? bTime - aTime : aTime - bTime;
  });

  if (convertLocale) {
    for (var i = 0; i < sorted.length; i++) {
      sorted[i][dateField].setTime(
        sorted[i][dateField].getTime() +
          sorted[i][dateField].getTimezoneOffset() * 60000
      );
      sorted[i][dateField] = sorted[i][dateField].toLocaleDateString();
    }
  }

  return sorted;
}

function sortObjByDate(
  data,
  reverse = false,
  dateField = "date",
  convertLocale = true
) {
  const parser = new DateParser("YYYY-MM-DD");
  let sorted = Object.entries(data);
  for (var i = 0; i < sorted.length; i++) {
    sorted[i][1][dateField] = parser.parse(sorted[i][1][dateField]);
  }
  sorted = sorted.sort(function (a, b) {
    const aTime = a[1][dateField];
    const bTime = b[1][dateField];
    return reverse ? bTime - aTime : aTime - bTime;
  });
  if (convertLocale) {
    for (var i = 0; i < sorted.length; i++) {
      sorted[i][1].month = sorted[i][1][dateField].getMonth() + 1;
      sorted[i][1].year = sorted[i][1][dateField].getFullYear();
    }
  }
  sorted = Object.fromEntries(sorted);

  return sorted;
}

function sortByField(field, data, reverse) {
  if (!data) return data;
  var sorted = data.slice(); // create shallow copy

  function toLower(x) {
    return typeof x === "string" ? x.toLowerCase() : x;
  }

  var sorted = sorted.sort(function (a, b) {
    if (!(field in a)) return 1;
    if (!(field in b)) return -1;

    if (toLower(a[field]) < toLower(b[field])) {
      return reverse ? -1 : 1;
    }
    if (toLower(a[field]) > toLower(b[field])) {
      return reverse ? 1 : -1;
    }
    return 0;
  });

  return sorted;
}

function objToArray(obj) {
  return Object.keys(obj).map(key => {
    const value = { ...obj[key] };
    value.key = key;
    return value;
  });
}

export { sortByDate, sortObjByDate, sortByField, objToArray };
