import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import IconButton from "ui-library/IconButton";

const styles = {
  close: {
    position: "fixed",
    top: 0,
    right: 0
  }
};

class DropDownWindow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return this.props.rendered ? (
      <div style={{ display: this.props.open ? "block" : "none" }}>
        {this.props.JSX}
        <div style={styles.close}>
          {/* <IconButton
          edge="start"
          color="inherit"
          onClick={this.props.minimizeWindow}
          aria-label="close"
          style={{ marginTop: -5 }}
        >
          <MinimizeIcon />
        </IconButton> */}
          <IconButton
            edge="start"
            color="inherit"
            onClick={this.props.closeWindow}
            aria-label="close"
            size="large">
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    ) : null;
  }
}

export default DropDownWindow;
