import React from "react";

import Chip from "@mui/material/Chip";
import Subheader from "ui-library/Subheader";
import MenuItem from "ui-library/MenuItem";
import Select from "ui-library/Select";
import { sortByField, objToArray } from "helpers/sortByDate";

import { updateCompanyFirebase } from "helpers/Firebase";
import { grey } from "@mui/material/colors";
import CustomerProfile from "../Customers/CustomerProfile";
import DataTable from "../tables/MuiDataTable";

const grey700 = grey["700"];

const styles = {
  notifs: {
    width: "60%"
  },
  productList: {
    borderColor: grey700,
    borderWidth: 1,
    borderStyle: "solid"
    // overflow: 'auto',
    // height: 300
  },
  moneyIcon: {
    width: 15,
    height: 15
  },
  chip: {
    margin: 4,
    cursor: "pointer"
  },
  wrapper: {
    display: "flex",
    flexWrap: "wrap"
  }
};

class CustomerAssign extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyUsers: {}
    };
  }

  handleAddCustomer = (user, customerList, event, index, customerKey) => {
    // NB(daniel): the desired customer key will always be the only element of the array
    const customerSet = new Set(customerList);
    if (Array.isArray(customerKey)) customerKey = customerKey[0];
    if (customerSet.has(customerKey)) {
      return;
    }
    if (customerKey in this.props.db.customers) {
      const { companyUsers } = this.props.db;
      const userCustomers = companyUsers[user].customers || [];
      userCustomers.push(customerKey);
      companyUsers[user].customers = userCustomers;

      const update = {};
      update[`companyUsers/${user}/customers`] = userCustomers;
      updateCompanyFirebase(update);
      this.setState({
        companyUsers
      });
    }
  };

  handleRequestDelete = (user, customerKey) => {
    const { companyUsers } = this.props.db;
    const userCustomers = companyUsers[user].customers || [];
    userCustomers.splice(userCustomers.indexOf(customerKey), 1);
    companyUsers[user].customers = userCustomers;

    const update = {};
    update[`companyUsers/${user}/customers`] = userCustomers;
    updateCompanyFirebase(update);
    this.setState({
      companyUsers
    });
  };

  getCustomerAssignments = () => {
    const customerAssignments = [];
    for (var user in this.props.db.companyUsers) {
      const customerList = this.props.db.companyUsers[user].customers || [];
      customerAssignments.push(
        <div style={styles.wrapper}>
          {customerList.length > 0 ? (
            customerList.map((customerKey, index) => {
              return (
                <Chip
                  onDelete={
                    this.props.editPermission
                      ? this.handleRequestDelete.bind(null, user, customerKey)
                      : null
                  }
                  onDoubleClick={this.props.openClose.showRightDrawer.bind(
                    null,
                    <CustomerProfile
                      customerKey={customerKey}
                      db={this.props.db}
                    />
                  )}
                  style={styles.chip}
                  label={
                    this.props.db.customers[customerKey]?.name ?? "Unknown"
                  }
                />
              );
            })
          ) : (
            <Chip color="tonal" label="All Customers" />
          )}
        </div>
      );
    }
    return customerAssignments;
  };

  getAddCustomerJSX = () => {
    const addCustomerJSX = [];
    for (const user in this.props.db.companyUsers) {
      const customerList = this.props.db.companyUsers[user].customers || [];
      addCustomerJSX.push(
        <Select
          onChange={this.handleAddCustomer.bind(null, user, customerList)}
          style={styles.input}
          disabled={!this.props.editPermission}
          multiple
          renderValue={selected => "Select Customers"}
          displayEmpty>
          {this.getFilteredCustomersJSX(customerList)}
        </Select>
      );
    }
    return addCustomerJSX;
  };

  getFilteredCustomersJSX = customerList => {
    const allCustomers = this.props.db.customers;
    const customersList = sortByField("name", objToArray(allCustomers), true);
    const customerSet = new Set(customerList);

    return customersList.map(customer => (
      <MenuItem
        value={customer.key}
        key={customer.key}
        disabled={customerSet.has(customer.key)}
        checked={customerSet.has(customer.key)}>
        {customer.name}
      </MenuItem>
    ));
  };

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {}

  render() {
    const customerAssignments = this.props.db.meta
      ? this.getCustomerAssignments()
      : [];
    const addCustomerJSX = this.props.db.meta ? this.getAddCustomerJSX() : [];
    const userNames = Object.values(this.props.db.companyUsers).map(
      user => user.name
    );
    return (
      <div>
        <div className="rowC">
          <Subheader style={{ color: "primary.main" }}>
            Assign Customers to Users
          </Subheader>
        </div>
        <div style={{ margin: 10 }}>
          <div style={{ marginBottom: 10 }}>
            A user must login before showing up in the listing.
          </div>
          <DataTable
            title="Customers by User"
            data={{
              User: userNames,
              "Assigned Customers": customerAssignments,
              "Add New": addCustomerJSX
            }}
            widths={["20%", "55%", "25%"]}
            sortBy={0}
          />
        </div>
      </div>
    );
  }
}

export default CustomerAssign;
