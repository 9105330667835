import { useEffect, useState } from "react";

/**
 * React Hook that receives an instance of `File`, `Blob` or `MediaSource` and
 * creates an URL representing it, providing a state object containing the file
 * with a set function to change the file object. It releases URL when component
 * unmount or parameter changes.
 * @param initialObject - `null` or an instance of `File`, `Blob` or `MediaSource`.
 */
const useObjectURL = (initialObject: null | File | Blob | MediaSource) => {
  const [objectURL, setObjectURL] = useState<null | string>(null);

  const [object, setObject] = useState<null | File | Blob | MediaSource>(
    initialObject
  );

  useEffect(() => {
    if (object) {
      const newObjectURL = URL.createObjectURL(object);
      setObjectURL(newObjectURL);
      return () => {
        URL.revokeObjectURL(newObjectURL);
        setObjectURL(null);
      };
    }
    return undefined;
  }, [object]);

  return {
    objectURL,
    object,
    setObject
  };
};

export default useObjectURL;
