import { Month, Quarter } from "helpers/Budget";
import { Customer, Meta, ProductGroup } from "js/dbTypes";

export type CustomerID = string;
export type ProductID = string;
export type ProductGroupID = string;
export type ContactID = string;
export type PromotionKey = string;
export type LineKey = string;
export type AccountingSource = string;

export type BroadFigureType = "revenue" | "tradeSpend" | "tradeRate";

export type SubFigureType = "budget" | "estimates" | "actual";

export type FigureType = BroadFigureType | SubFigureType;

export type DiffType = "none" | "absolute" | "relative";

export type NonDatePivotType = "customer" | "productGroups" | "contacts";

export type PivotType = "date" | NonDatePivotType;

export type PivotGroupType = CustomerID | ProductGroupID | ContactID;

export type ComparisonType =
  | "budgetVsActual"
  | "budgetVsEstimate"
  | "estimateVsActual";

export type TimeScaleType = "month" | "quarter";

export type DataSourceType = "spend" | "promotion";

export type ComparisonKeys = "absoluteComparison" | "relativeComparison";

export type TimeData<T = number> = Record<Month | Quarter | "total", T>;

export type BudgetTableSubRecord<T extends string = SubFigureType> = Record<
  T,
  TimeData
>;

export type BudgetTableRecord = Record<BroadFigureType, BudgetTableSubRecord>;

export type PivotedBudgetTableRecord = Record<
  PivotGroupType,
  BudgetTableRecord
>;

export type DiffTableTimeData = TimeData<{
  figure: number;
  highlight: boolean;
}>;

export type DiffTableData = Record<ComparisonType, DiffTableTimeData>;

export type DiffTableRecord = Record<BroadFigureType, DiffTableData>;

export type PivotedTradeRate = Record<PivotGroupType, BudgetTableSubRecord>;

export type ComparisonFigures = Record<ComparisonKeys, TimeData>;

export type DatePivotData = BudgetTableRecord & ComparisonFigures;

export type OrganisedData = DatePivotData | PivotedBudgetTableRecord;

export interface ActualTradeSpendItem {
  date: Date;
  customer: CustomerID;
  productGroups: ProductGroupID[];
  lineKey?: LineKey;
  spend: number;
}

export interface ActualRevenueItem {
  date: Date;
  customer: CustomerID;
  productGroups: ProductGroupID[];
  source: AccountingSource;
  revenue: number;
}

export interface BudgetItem {
  date: string;
  customer: CustomerID;
  product: ProductID;
  revenueBudget?: number;
  tradeBudget?: number;
}

export interface Line {
  closed: boolean;
  month: number;
  year: number;
  lineKey: LineKey;
  promKey: PromotionKey;
  productGroup: ProductGroupID;
  status: number;
  totalExpSpend: number;
}

export interface DataEntry {
  date: Date;
  customer: CustomerID;
  productGroups: ProductGroupID[];
  contacts: ContactID[];
  promKey?: PromotionKey;
  promotionClosed?: boolean;
  status?: string;
  actualRevenue?: number;
  budgetedRevenue?: number;
  estimatedRevenue?: number;
  actualTradeSpend?: number;
  budgetedTradeSpend?: number;
  estimatedTradeSpend?: number;
}

export type DataEntryDict = Record<string, DataEntry>;

export interface FirebaseDB {
  allLines: Record<LineKey, Line>;
  meta: Meta;
  customers: Record<CustomerID, Customer>;
}

export interface DBSlice {
  customer: Record<CustomerID, Customer>;
  productGroups: Record<ProductGroupID, ProductGroup>;
  contacts: Record<ContactID, string>;
}
