import React from "react";
import { round, sum, map } from "lodash-es";

import ReactTable from "react-table";

import { displayMoney } from "helpers/DataProcessing";

import { red, green, common } from "@mui/material/colors";
import { graphPalette } from "helpers/ColorPalettes";
import { Stack, Typography } from "@mui/material";

const red100 = red["100"];
const green700 = green["700"];
const { black } = common;
const { blue70, purple70 } = graphPalette;

const styles = {
  legend: {
    display: "inline-block",
    width: "16px",
    height: "16px",
    borderRadius: "4px"
  }
};

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];
const quarters = ["Q1", "Q2", "Q3", "Q4"];

class MoneyTableExpandable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: []
    };
  }

  addCommas = num => {
    return num.toLocaleString();
  };

  defaultFooter = (data, mode, accessor) => {
    return round(sum(map(data[mode], d => d[accessor])));
  };

  getBackgroundColor = row => {
    const col = row.column.Header;
    if (
      false &&
      this.props.diffCutoff &&
      this.props.data.diff[row.index][col] < this.props.diffCutoff
    ) {
      // turning this off for now
      return red100;
    }
    return null;
  };

  getColumns = props => {
    const columns = [];
    const data = props.data[props.mode];

    const display = props.isMoney ? displayMoney : this.addCommas;
    const computeFooter = props.customFooter
      ? props.customFooter
      : this.defaultFooter;

    const dateColumns = [];
    dateColumns.push({
      Header: props.dateHeader,
      accessor: props.pivot,
      width: 200,
      Cell: row => {
        let customerStyle = {};
        if (row.original.customerKey) {
          const customer = this.props.db.customers[row.original.customerKey];
          customerStyle = customer.isDistributor
            ? { color: purple70 }
            : customer.isDirect
            ? { color: blue70 }
            : {};
        }
        return (
          <div style={customerStyle}>
            {props.renderPivot ? props.renderPivot(row) : row.value}
          </div>
        );
      },
      Footer: (
        <span>
          <strong>Total</strong>
        </span>
      )
    });

    if (props.time == "week") {
      for (var week of props.weeks) {
        dateColumns.push({
          Header: week,
          accessor: week,
          Cell: this.renderCell,
          Footer: (
            <span>{display(computeFooter(props.data, props.mode, week))}</span>
          )
        });
      }
    } else if (props.time == "quarter") {
      for (var quarter of quarters) {
        dateColumns.push({
          Header: quarter,
          accessor: quarter,
          Cell: this.renderCell,
          Footer: (
            <span>
              {display(computeFooter(props.data, props.mode, quarter))}
            </span>
          )
        });
      }
    } else {
      for (var month of months) {
        dateColumns.push({
          Header: month,
          accessor: month,
          Cell: this.renderCell,
          Footer: (
            <span>{display(computeFooter(props.data, props.mode, month))}</span>
          )
        });
      }
    }
    dateColumns.push({
      Header: "Total",
      accessor: "Total",
      Cell: this.renderCell,
      Footer: (
        <span>{display(computeFooter(props.data, props.mode, "Total"))}</span>
      )
    });

    columns.push({
      Header: props.header,
      columns: dateColumns
    });

    // columns for subComponent table
    const subColumns = [];
    subColumns.push({
      Header: "Metric",
      accessor: "metric"
    });
    if (props.time == "week") {
      for (var week of props.weeks) {
        subColumns.push({
          Header: week,
          accessor: week,
          Cell: this.renderSubcell
          // Footer: (
          //   <span>{display(computeFooter(props.data, props.mode, week))}</span>
          // )
        });
      }
    } else if (props.time == "quarter") {
      for (var quarter of quarters) {
        subColumns.push({
          Header: quarter,
          accessor: quarter,
          Cell: this.renderSubcell
          // Footer: (
          //   <span>
          //     {display(computeFooter(props.data, props.mode, quarter))}
          //   </span>
          // )
        });
      }
    } else {
      for (var month of months) {
        subColumns.push({
          Header: month,
          accessor: month,
          Cell: this.renderSubcell
          // Footer: (
          //   <span>{display(computeFooter(props.data, props.mode, month))}</span>
          // )
        });
      }
    }
    subColumns.push({
      Header: "Total",
      accessor: "Total",
      Cell: this.renderSubcell
    });

    this.setState({
      columns,
      subColumns
    });
  };

  getSubData = index => {
    const subData = [];
    for (const metric of this.props.metrics) {
      const data = this.props.data?.[metric]?.[index];
      if (data) subData.push(data);
    }
    return subData;
  };

  renderCell = row => {
    return (
      <div style={{ backgroundColor: this.getBackgroundColor(row) }}>
        {this.props.isMoney
          ? displayMoney(row.value)
          : row.value
          ? row.value.toLocaleString()
          : 0}
      </div>
    );
  };

  renderSubcell = row => {
    return (
      <div>
        {this.props.isMoney &&
        this.props.moneyMetrics &&
        this.props.moneyMetrics.includes(row.original.metric)
          ? displayMoney(row.value)
          : row.value
          ? row.value.toLocaleString()
          : 0}
      </div>
    );
  };

  onChange = () => {
    if (this.props.updateData) {
      this.props.updateData(
        "tableRows",
        this.tableInstance.getResolvedState().sortedData
      );
    } // log the sorted+filtered row model
  };

  componentDidMount() {
    this.getColumns(this.props);
    if (this.props.updateData) {
      this.props.updateData(
        "tableRows",
        this.tableInstance.getResolvedState().sortedData
      );
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getColumns(nextProps);
  }

  render() {
    return (
      <div>
        <Stack spacing={2} justifyContent="end" sx={{ mb: 1 }}>
          <Typography>Customer Kind:</Typography>
          <Stack>
            <div style={{ backgroundColor: `${purple70}`, ...styles.legend }} />
            <Typography> Distributor </Typography>
          </Stack>
          <Stack>
            <div
              style={{
                backgroundColor: `${blue70}`,
                ...styles.legend
              }}
            />
            <Typography> Direct </Typography>
          </Stack>
          <Stack>
            <div
              style={{
                backgroundColor: `${black}`,
                ...styles.legend
              }}
            />
            <Typography> Indirect </Typography>
          </Stack>
        </Stack>
        <div className="table-wrap">
          <ReactTable
            defaultPageSize={
              this.props.defaultPageSize ? this.props.defaultPageSize : 10
            }
            data={this.props.data[this.props.mode]}
            columns={this.state.columns}
            style={{ fontFamily: "PT sans" }}
            onSortedChange={this.onChange}
            ref={instance => (this.tableInstance = instance)}
            SubComponent={row => {
              return (
                <div
                  style={{
                    margin: "10px",
                    borderColor: green700,
                    borderWidth: 2,
                    borderStyle: "solid"
                  }}>
                  <ReactTable
                    data={this.getSubData(row.index)}
                    columns={this.state.subColumns}
                    defaultPageSize={this.props.metrics.length}
                    showPagination={false}
                    style={{ fontFamily: "PT sans" }}
                  />
                </div>
              );
            }}
          />
        </div>
      </div>
    );
  }
}

export default MoneyTableExpandable;
