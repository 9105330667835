import { UploadBackupActionType } from "./actionTypes";

export function reset() {
  return { type: UploadBackupActionType.RESET };
}

export function changeUploadStep(moveForward: boolean, db: any) {
  return {
    type: UploadBackupActionType.CHANGE_UPLOAD_STEP,
    moveForward,
    db
  };
}

export function addBackupFiles(addedFiles: File[]) {
  return { type: UploadBackupActionType.ADD_BACKUP_FILES, addedFiles };
}

export function updateFilePageCounts(addedFilePageCounts: number[]) {
  return {
    type: UploadBackupActionType.UPDATE_FILE_PAGE_COUNTS,
    addedFilePageCounts
  };
}

export function removeBackupFile(fileIdx: number) {
  return { type: UploadBackupActionType.REMOVE_BACKUP_FILE, fileIdx };
}

export function setViewedFile(fileIdx: number) {
  return { type: UploadBackupActionType.SET_VIEWED_FILE, fileIdx };
}

export function addInvoiceObject() {
  return { type: UploadBackupActionType.ADD_INVOICE_OBJECT };
}

export function removeInvoiceObject(invoiceIdx: number | string) {
  return { type: UploadBackupActionType.REMOVE_INVOICE_OBJECT, invoiceIdx };
}

export function setFakeInvoiceMode() {
  return { type: UploadBackupActionType.SET_FAKE_INVOICE_MODE };
}

export function setInvoiceAssignPageField(
  invoiceIdx: number,
  field: string,
  value: any,
  db: any
) {
  return {
    type: UploadBackupActionType.SET_INVOICE_ASSIGN_PAGE_FIELD,
    invoiceIdx,
    field,
    value,
    db
  };
}

export function setInvoiceMetadataField(
  invoiceIdx: number,
  field: string,
  value: any,
  db: any
) {
  return {
    type: UploadBackupActionType.SET_INVOICE_METADATA_FIELD,
    invoiceIdx,
    field,
    value,
    db
  };
}
