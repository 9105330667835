import React from "react";

import Button from "ui-library/Button";
import EditIcon from "@mui/icons-material/Edit";

import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";

import { sortByField } from "helpers/sortByDate";
import { batchUpdatePricingFirebase, firebase } from "helpers/Firebase";
import { grey, indigo } from "@mui/material/colors";
import { graphPalette } from "helpers/ColorPalettes";
import {
  calculateRetailerPricing,
  getCurrentTimeframe
} from "../Customers/CustomerUtils";
import TimeframeEditor from "../Customers/TimeframeEditor";

const grey700 = grey["700"];
const indigo900 = indigo["900"];

const { blue70, purple70 } = graphPalette;

const ReactTableFixedColumns = withFixedColumns(ReactTable);

function isPriceBlank(price) {
  if (!price) return true;
  if (price?.price) return false;
  const timeframes = price?.timeframes ?? [price];
  let blank = true;
  timeframes.forEach(tf => {
    if (tf.price) blank = false;
  });
  return blank;
}

function isPriceValid(price) {
  // Null price objects are never valid.
  if (!price) return false;

  // Invalid numbers and negative numbers are invalid prices.
  // Blanks and positive numbers are valid prices.
  // Price objects with any invalid prices are invalid.

  const isPriceValBlank = priceVal =>
    priceVal === "" || priceVal === null || priceVal === undefined;

  const isPriceValValid = priceVal => {
    if (isPriceValBlank(priceVal)) return true;
    const floatVal = Number(priceVal);
    return !(isNaN(floatVal) || floatVal <= 0);
  };

  const timeframes = price?.timeframes ?? [price];
  let valid = true;
  timeframes.forEach(tf => {
    if (!isPriceValValid(tf.price)) valid = false;
    else if (isPriceValBlank(tf.price)) delete tf.price;
    else tf.price = Number(tf.price);
  });
  return valid;
}

const style = {
  table: {
    fontFamily: "Monument Grotesk Regular"
  }
};

class PricingTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      data: [],
      tableKey: 0
    };
  }

  updateNewPrice = (cellInfo, newPrice) => {
    if (this.props.db.permissions.includes("pricing") && !this.props.readOnly) {
      if (!isPriceBlank(newPrice) && isPriceValid(newPrice)) {
        var pricingData = jQuery.extend(true, {}, this.props.db.pricing);
        var customerKey = cellInfo.original.customer;
        var pgKey = cellInfo.column.id;
        if (!(customerKey in pricingData)) pricingData[customerKey] = {};
        pricingData[customerKey][pgKey] = newPrice;
        var allPricing = calculateRetailerPricing(
          pricingData,
          this.props.db.customers,
          Object.keys(this.props.db.meta?.product_groups ?? {})
        );
        batchUpdatePricingFirebase(allPricing.pricing);
        this.props.openClose.closeAppModal();
      } else if (!isPriceBlank(newPrice) && !isPriceValid(newPrice)) {
        this.props.openClose.setAppModal(
          "Invalid Value",
          <div className="centering">All prices must be positive numbers.</div>,

          <div className="centering">
            <Button
              label="Ok"
              onClick={() => {
                this.props.openClose.closeAppModal();
              }}
            />
          </div>
        );
      } else {
        // price has been deleted
        var pricingData = jQuery.extend(true, {}, this.props.db.pricing);
        var customerKey = cellInfo.original.customer;
        var pgKey = cellInfo.column.id;
        pricingData[customerKey][pgKey] = { price: null };
        var allPricing = calculateRetailerPricing(
          pricingData,
          this.props.db.customers,
          Object.keys(this.props.db.meta?.product_groups ?? {})
        );
        batchUpdatePricingFirebase(allPricing.pricing);
        this.props.openClose.closeAppModal();
      }
    }
  };

  renderCell = cellInfo => {
    const data = [...this.state.data];
    let oldData = "";
    const cellRef = React.createRef();
    const isError = false;
    const customer = this.props.db.customers[cellInfo.original.customer];
    const priceStyle =
      customer.isDistributor || customer.isDirect
        ? { color: indigo900, fontWeight: 600 }
        : { color: grey700 };
    const customerStyle = customer.isDistributor
      ? { color: purple70, fontWeight: 800 }
      : customer.isDirect
      ? { color: blue70, fontWeight: 800 }
      : { fontWeight: 800 };
    return cellInfo.column.Header !== "Customer" &&
      this.props.db.permissions.includes("pricing") ? (
      <div
        className="td"
        ref={cellRef}
        contentEditable={false}
        suppressContentEditableWarning
        onClick={e => {
          const disabled = !customer.isDistributor && !customer.isDirect;
          oldData = isPriceValid(cellInfo.value)
            ? Number(parseFloat(cellInfo.value.price).toFixed(3))
            : "";
          const editRef = React.createRef();
          this.props.openClose.setAppModal(
            "Timeframe Editor",
            <TimeframeEditor
              meta={this.props.db.meta}
              obj={cellInfo.value}
              save={this.updateNewPrice.bind(null, cellInfo)}
              openClose={this.props.openClose}
              checkConflicts={obj => false}
              fields={{ price: "Price" }}
              isTimeframeEditable={tf => {
                const customerTf = getCurrentTimeframe(
                  customer,
                  new Date(tf.expirationDate ?? "12/31/9999")
                );
                return customerTf.isDirect || customerTf.isDistributor;
              }}
            />,
            null,
            false,
            true,
            "md"
          );
        }}
        key={cellInfo.index + cellInfo.column.id}
        style={priceStyle}>
        {isPriceValid(cellInfo.value) && cellInfo.value.price ? (
          Number(parseFloat(cellInfo.value.price).toFixed(3))
        ) : (
          <br />
        )}
        <EditIcon className="edit" />
      </div>
    ) : cellInfo.column.Header == "Customer" ? (
      <div style={customerStyle}>{cellInfo.value}</div>
    ) : (
      <div>{cellInfo.value?.price}</div>
    );
  };

  updateDataColumns = props => {
    const columns = [];

    columns.push({
      Header: "",
      fixed: "left",
      columns: [
        {
          Header: "Customer",
          accessor: "customerName",
          Cell: this.renderCell,
          fixed: "left",
          minWidth: 200
        }
      ]
    });
    const productColumns = [];
    let pgList = [];
    for (const key in this.props.db.meta.product_groups) {
      var pg = this.props.db.meta.product_groups[key];
      pg.key = key;
      if (pg.isPricing) {
        // if product group has been marked to be used for pricing
        pgList.push(pg);
      }
    }
    pgList = sortByField("name", pgList, true);
    for (var pg of pgList) {
      productColumns.push({
        Header: pg.name,
        accessor: pg.key,
        Cell: this.renderCell,
        minWidth: 200
      });
    }

    columns.push({
      Header: "Product Groups (click cell to edit)",
      columns: productColumns
    });

    this.setState({
      data: props.data,
      columns
    });
  };

  checkPricing = () => {
    if (this.props.db.meta?.should_update_pricing) {
      const pricingData = jQuery.extend(true, {}, this.props.db.pricing);
      const allPricing = calculateRetailerPricing(
        pricingData,
        this.props.db.customers,
        Object.keys(this.props.db.meta?.product_groups ?? {})
      );
      if (
        allPricing.changed &&
        !this.props.outOfDate &&
        this.props.db.permissions.includes("pricing") &&
        !this.props.readOnly
      ) {
        batchUpdatePricingFirebase(allPricing.pricing);
      }
      firebase
        .database()
        .ref(`companies/${this.props.db.companyid}/meta/should_update_pricing`)
        .remove();
    }
  };

  componentDidMount() {
    this.interval = setInterval(() => this.checkPricing(), 5000);
    this.updateDataColumns(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    clearInterval(this.interval);
    this.interval = setInterval(() => this.checkPricing(), 5000);
    this.updateDataColumns(nextProps);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const columns = [];
    columns.push({
      Header: "",
      fixed: "left",
      columns: [
        {
          Header: "Customer",
          accessor: "customerName",
          Cell: this.renderCell,
          minWidth: 200
        }
      ]
    });
    const productColumns = [];
    let pgList = [];
    for (const key in this.props.db.meta.product_groups) {
      var pg = this.props.db.meta.product_groups[key];
      pg.key = key;
      if (pg.isPricing) {
        // if product group has been marked to be used for pricing
        pgList.push(pg);
      }
    }
    pgList = sortByField("name", pgList, true);
    for (var pg of pgList) {
      productColumns.push({
        Header: pg.name,
        accessor: pg.key,
        Cell: this.renderCell,
        minWidth: 200
      });
    }
    columns.push({
      Header: "Product Groups (click cell to edit)",
      columns: productColumns
    });
    return (
      <ReactTableFixedColumns
        ref={r => (this.table = r)}
        style={{ ...style.table, ...this.props.style }}
        className="-striped"
        data={this.state.data}
        columns={this.state.columns}
      />
    );
  }
}

export default PricingTable;
