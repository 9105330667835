import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Badge from "@mui/material/Badge";
import Divider from "@mui/material/Divider";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  getUserNotifDataFirebase,
  updateUserNotifDataFirebase,
  firebase
} from "helpers/Firebase";
import { sortByDate } from "helpers/sortByDate";
import { timeSince } from "helpers/Time";
import Typography from "@mui/material/Typography";
import Mixpanel from "helpers/Mixpanel";
import { DbContextValues } from "contexts/Db";
import Link from "ui-library/Link";
import useTooltip from "helpers/useTooltip";
import { Tooltip } from "@mui/material";
import {
  StyledArrowDropDownIcon,
  StyledArrowDropUpIcon,
  StyledBox,
  StyledMenu
} from "./StyledComponents";
import PromProfile from "../../Planning/PromProfile";

type BellIconProps = {
  openClose: any;
  db: DbContextValues;
};

export default function BellIcon({ db, openClose }: BellIconProps) {
  const [openBellMenu, setOpenBellMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [numUnreadNotifs, setNumUnreadNotifs] = useState(0);
  const [notifs, setNotifs] = useState([]);
  const [notifsArray, setNotifsArray] = useState([]);
  const { open, handleTooltipClose, handleTooltipOpen } = useTooltip();

  const handleOpen = event => {
    setAnchorEl(event.currentTarget);
    setOpenBellMenu(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenBellMenu(false);
  };

  const truncateMessage = message => {
    return message.length < 50 ? message : `${message.slice(0, 50)}...`;
  };

  const clearUnreadNotifs = () => {
    updateUserNotifDataFirebase({ numUnreadNotifs: null }, null);
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        // Obtain notifs particular to user subtree from database and save to this.state
        // Can we use the Notif Types here?
        getUserNotifDataFirebase(userData => {
          const notifUpdates = {};
          if (userData.notifs && Object.keys(userData.notifs).length > 0) {
            notifUpdates.notifs = sortByDate(
              Object.values(userData.notifs),
              true,
              "time",
              false
            );
          } else {
            notifUpdates.notifs = [];
          }
          notifUpdates.numUnreadNotifs = userData.numUnreadNotifs
            ? Object.keys(userData.numUnreadNotifs).length
            : 0;
          setNumUnreadNotifs(notifUpdates.numUnreadNotifs);
          setNotifs([...notifUpdates.notifs]);
        }, null);
      }
    });
  }, []);

  useEffect(() => {
    const newArray = notifs.map((item, index) => {
      const then = Date.parse(item.time);
      const getRightDrawer = openClose.showRightDrawer.bind(
        null,
        <PromProfile promKey={item.promKey} openClose={openClose} db={db} />
      );
      return (
        <MenuItem
          key={index}
          onClick={() => {
            if (item.promKey) {
              getRightDrawer();
              Mixpanel.track("Promotion Viewed", {
                View: "Header",
                Component: "Notifications"
              });
              handleClose();
            }
          }}>
          <Stack
            justifyContent="space-between"
            spacing={2}
            sx={{ width: "100%" }}>
            <Typography variant="labelMedium">
              {truncateMessage(item.content)}
            </Typography>
            <Typography variant="labelMedium">
              {`${timeSince(then)} ago`}
            </Typography>
          </Stack>
        </MenuItem>
      );
    });
    setNotifsArray([...newArray]);
  }, [notifs, db, openClose]);

  return (
    <div>
      <Tooltip
        open={open}
        onOpen={handleTooltipOpen}
        onClose={handleTooltipClose}
        disableInteractive
        disableFocusListener
        title="Notifications">
        <StyledBox
          onClick={event => {
            handleOpen(event);
            clearUnreadNotifs();
          }}>
          <Badge
            badgeContent={numUnreadNotifs}
            color="tertiary"
            sx={{
              "& .MuiBadge-badge": {
                right: "2px"
              }
            }}>
            <NotificationsIcon />
          </Badge>
          {openBellMenu ? (
            <StyledArrowDropUpIcon />
          ) : (
            <StyledArrowDropDownIcon />
          )}
        </StyledBox>
      </Tooltip>
      <StyledMenu
        anchorEl={anchorEl}
        open={openBellMenu}
        onClose={() => handleClose()}>
        {notifsArray}
        <Divider />
        <MenuItem onClick={() => handleClose()}>
          <Link variant="labelLarge" to="/notifications">
            View All
          </Link>
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
