import React from "react";

import DeleteIcon from "@mui/icons-material/DeleteForever";
import Button from "ui-library/Button";
import ResetIcon from "@mui/icons-material/Autorenew";
import UploadIcon from "@mui/icons-material/Publish";

import {
  bumpChartTimeSeries,
  complianceTimeSeries
} from "helpers/DataProcessing";
import { removeFirebase } from "helpers/Firebase";
import Card from "ui-library/Card";
import { grey, common, red } from "@mui/material/colors";
import Stack from "@mui/material/Stack";
import BumpChartTimeSeries from "../graphs/BumpChartTimeSeries";
import ComplianceTimeSeries from "../graphs/ComplianceTimeSeries";
import DataTable from "../tables/MuiDataTable";
import UploadFiles from "../UploadFiles/UploadFiles";

const grey700 = grey["700"];
const { white } = common;
const red400 = red["400"];
const red600 = red["600"];

function add(a, b) {
  return a + b;
}

class CustomerAnalytics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  openFilePicker = jsx => {
    this.setState({
      openFilePicker: true
    });
    this.props.openClose.showDropDownWindow(jsx);
  };

  handleClick = index => {
    if (
      this.state.startIndex != null &&
      this.state.endIndex == this.state.startIndex &&
      index > this.state.startIndex
    ) {
      this.setState({ endIndex: index });
    } else {
      this.setState({ startIndex: index, endIndex: index });
    }
  };

  handleClearOut = () => {
    this.props.openClose.setAppModal(
      "Confirm Delete",
      <div className="centering">
        Are you sure you want to clear out all existing data? You may re-upload
        data into a clean database.
      </div>,

      <Stack justifyContent="center">
        <Button
          label="Confirm"
          color="error"
          variant="text"
          onClick={() => {
            removeFirebase(9);
            this.props.openClose.closeAppModal();
          }}
        />
        <Button
          label="Go back"
          variant="text"
          onClick={() => {
            this.props.openClose.closeAppModal();
          }}
        />
      </Stack>
    );
  };

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {}

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    // Bump chart data
    const { bumpList } = this.props;
    const bumpChartData =
      bumpList.length > 0 ? bumpChartTimeSeries(bumpList) : [];
    const complianceData =
      bumpList.length > 0 ? complianceTimeSeries(bumpList) : [];

    const subData = [];
    let startDate = "None";
    let endDate = "None";
    let totalBase = "N/A";
    let totalIncr = "N/A";
    let lift = "N/A";
    let avgCompliance = "N/A";
    if (this.state.startIndex != null && this.state.endIndex != null) {
      for (let i = this.state.startIndex; i <= this.state.endIndex; i++) {
        subData.push({ ...bumpChartData[i], ...complianceData[i] });
      }

      totalBase = subData.map(x => x["Base Units"]).reduce(add);
      totalIncr = subData.map(x => x["Incr Units"]).reduce(add);
      lift = Math.round((totalIncr / totalBase) * 100);
      avgCompliance = Math.round(
        subData.map(x => x.Compliance).reduce(add) / subData.length
      );
    }
    if (subData.length > 0) {
      startDate = subData[0].date;
      endDate = subData.slice(-1)[0].date;
    }

    const fields = {
      Field: [
        "Start Date",
        "End Date",
        "Total Base Units",
        "Total Incr Units",
        "Lift (%)",
        "Avg Compliance"
      ],
      Value: [
        startDate,
        endDate,
        totalBase.toLocaleString(),
        totalIncr.toLocaleString(),
        lift,
        avgCompliance
      ]
    };

    return (
      <div>
        <div className="row">
          <div className="col-lg-9">
            <div>
              <Card title="Time Series">
                <ComplianceTimeSeries
                  data={complianceData}
                  handleClick={this.handleClick}
                  startIndex={this.state.startIndex}
                  endIndex={this.state.endIndex}
                  syncId="bump_chart"
                  ref={this.props.complianceRef}
                />
                <br />
                <br />
                <BumpChartTimeSeries
                  data={bumpChartData}
                  startIndex={this.state.startIndex}
                  endIndex={this.state.endIndex}
                  syncId="bump_chart"
                  ref={this.props.bumpRef}
                />
              </Card>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="row-lg-8">
              <DataTable
                title="Statistics Over Specified Period"
                data={fields}
              />
            </div>
            <div className="row-lg-4">
              <Card title="Options">
                <Stack direction="column">
                  <Button
                    label="Upload Bump Chart Data"
                    onClick={this.openFilePicker.bind(
                      null,
                      <UploadFiles
                        selectedDataType="bumpChart"
                        openClose={this.props.openClose}
                      />
                    )}
                    variant="text"
                    icon={<UploadIcon />}
                  />
                  <Button
                    label="Clear Specified Dates"
                    onClick={() => {
                      this.setState({ startIndex: null, endIndex: null });
                    }}
                    variant="text"
                    icon={<ResetIcon />}
                  />
                  <Button
                    label="Delete All Existing Data"
                    onClick={this.handleClearOut}
                    variant="text"
                    icon={<DeleteIcon />}
                  />
                </Stack>
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomerAnalytics;
