import {
  ERPTransactionObject,
  InvoiceObject
} from "components/Deductions/models";
import {
  updateCurrentUserCompany,
  getFromCurrentUserCompany,
  updateFirebaseTS,
  getFirebaseTSOnce,
  CATEGORIESMAP,
  removeFirebase
} from "helpers/Firebase";
import { DisplayInvoiceObject } from "reconciliation-types/invoiceTypes";
import {
  invoiceLineStatuses,
  invoiceStatuses
} from "deductions-constants/ReconciliationStatuses";
import { InvoiceAmountService } from "reconciliation-services";

export function getUpdatedInvoiceStatus(
  updatedInvoice: DisplayInvoiceObject | InvoiceObject,
  erpTransactions: Record<string, ERPTransactionObject>
) {
  const invoiceLines = updatedInvoice.invoiceLines || {};

  if (
    InvoiceAmountService.getInvoiceOpenAmount(
      updatedInvoice,
      erpTransactions
    ) === 0.0
  ) {
    return invoiceStatuses.RESOLVED;
  }

  if (
    Object.values(invoiceLines).some(invoiceLine => {
      return (
        invoiceLine.status === invoiceLineStatuses.SALES_REVIEW ||
        invoiceLine.status === invoiceLineStatuses.DISPUTE
      );
    })
  ) {
    return invoiceStatuses.NEEDS_ATTENTION;
  }

  return invoiceStatuses.IN_PROGRESS;
}

export function getCleanedInvoiceObject(
  displayInvoice: DisplayInvoiceObject | InvoiceObject
) {
  return {
    ...displayInvoice,
    customerName: null,
    displayAssignedUsers: null,

    clearedAmount: null,
    totalWriteOffAmount: null,
    creditedWriteOffAmount: null,
    openWriteOffAmount: null,
    totalDisputeAmount: null,
    repaidDisputeAmount: null,
    openDisputeAmount: null,
    resolvedAmount: null,
    openAmount: null
  };
}

export function updateFirebaseInvoice(
  displayInvoice: DisplayInvoiceObject | InvoiceObject,
  callback?: () => void,
  errorCallback?: () => void
) {
  // don't write display values to db
  const invoice = getCleanedInvoiceObject(displayInvoice);
  updateFirebaseTS(
    CATEGORIESMAP.RECON_INVOICES,
    invoice,
    invoice.key,
    () => {
      if (callback) {
        callback();
      }
    },
    () => {
      if (errorCallback) {
        errorCallback();
      }
    }
  );
}

interface InvoiceLineObjectUpdate {
  matchedProm: string | null;
  matchedPromLine: string | null;
  suggestedFundType?: string | null;
  customerKey?: string;
  customerName?: string;
  productKey?: string;
  productName?: string;
  status: invoiceLineStatuses;
}

export function saveInvoicePromotionMatch(
  invoiceKey: string,
  invoiceLineKey: string,
  matchedPromKey: string,
  matchedPromLineKey: string,
  callback: () => void,
  errorCallback: () => void,
  customerKey?: string,
  customerName?: string,
  productKey?: string,
  productName?: string
) {
  // TODO: We had a try/catch here that didn't actually do anything
  // because Firebase calls don't throw errors themselves,
  // they return a promise that we need to .catch().
  const invoiceLineUpdate: InvoiceLineObjectUpdate = {
    matchedProm: matchedPromKey,
    matchedPromLine: matchedPromLineKey,
    status: invoiceLineStatuses.MATCH_CONFIRMED
  };
  if (customerKey) {
    invoiceLineUpdate.customerKey = customerKey;
  }
  if (customerName) {
    invoiceLineUpdate.customerName = customerName;
  }
  if (productKey) {
    invoiceLineUpdate.productKey = productKey;
  }
  if (productName) {
    invoiceLineUpdate.productName = productName;
  }

  // check that the invoice exists before we write anything to it
  getFirebaseTSOnce(
    CATEGORIESMAP.RECON_INVOICES,
    invoice => {
      if (invoice) {
        const exec = () => {
          updateCurrentUserCompany(
            `reconciliation/invoices/${invoiceKey}/invoiceLines/${invoiceLineKey}/`,
            invoiceLineUpdate
          ).then(() => {
            const valuesToAdd: { [key: string]: Object } = {
              [`${matchedPromKey}/lines/${matchedPromLineKey}`]: {
                [invoiceKey]: { [invoiceLineKey]: invoiceLineKey }
              }
            };
            updateCurrentUserCompany(
              "reconciliation/promotionInvoices/",
              valuesToAdd
            ).then(() => {
              callback();
            });
          });
        };

        if (
          invoice.status === invoiceStatuses.IN_PROGRESS ||
          invoice.status === invoiceStatuses.NEW
        ) {
          updateCurrentUserCompany(`reconciliation/invoices/${invoiceKey}`, {
            status: invoiceStatuses.NEEDS_ATTENTION
          }).then(() => {
            exec();
          });
        } else {
          exec();
        }
      } else {
        errorCallback();
      }
    },
    invoiceKey
  );
}

export function removeInvoiceLine(
  invoiceKey: string,
  invoiceLineKey: string,
  callback: () => void,
  errorCallback: () => void
) {
  // check that the invoice exists before we write anything to it
  getFirebaseTSOnce(
    CATEGORIESMAP.RECON_INVOICES,
    invoice => {
      if (invoice) {
        removeFirebase(
          26,
          `/${invoiceKey}/invoiceLines/${invoiceLineKey}`,
          null
        );
        callback();
      } else {
        errorCallback();
      }
    },
    invoiceKey
  );
}

export function resetPromotionInvoiceMatches(
  baseInvoice: InvoiceObject,
  callback: () => void,
  errorCallback: () => void
) {
  // TODO: We had a try/catch here that didn't actually do anything
  // because Firebase calls don't throw errors themselves,
  // they return a promise that we need to .catch().
  const valuesToReset = [];
  const lines = Object.assign(
    {},
    ...Object.entries(baseInvoice.invoiceLines ?? {}).map(([key, row]) => {
      if (row.matchedProm) {
        const keyPair = {
          matchedProm: row.matchedProm,
          matchedPromLine: row.matchedPromLine
        };
        valuesToReset.push(keyPair);
      }

      const cleanedRow = { ...row };
      cleanedRow.status = invoiceLineStatuses.MATCH_NOT_FOUND;
      delete cleanedRow.matchedPromLine;
      delete cleanedRow.matchedProm;
      return { [key]: cleanedRow };
    })
  );

  const newInvoice = { ...baseInvoice, invoiceLines: lines };
  const valuesToDelete: { [key: string]: null } = {};
  Object.entries(valuesToReset).forEach(([_, keyPair]) => {
    const { matchedProm, matchedPromLine } = keyPair;
    valuesToDelete[`${matchedProm}/lines/${matchedPromLine}`] = null;
  });

  // check that the invoice exists before we write anything to it
  getFirebaseTSOnce(
    CATEGORIESMAP.RECON_INVOICES,
    invoice => {
      if (invoice) {
        updateCurrentUserCompany(
          `reconciliation/invoices/${baseInvoice.key}/`,
          newInvoice
        );
        updateCurrentUserCompany(
          "reconciliation/promotionInvoices/",
          valuesToDelete
        );
        if (invoice.status === invoiceStatuses.NEEDS_ATTENTION) {
          updateCurrentUserCompany(
            `reconciliation/invoices/${baseInvoice.key}`,
            { status: invoiceStatuses.IN_PROGRESS }
          );
        }
        // TODO: Need to put the callback in an actual callback, not after some async Firebase calls
        callback();
      } else {
        errorCallback();
      }
    },
    baseInvoice.key
  );
}

export function resetSinglePromotionInvoiceMatches(
  invoiceKey,
  invoiceLineKey,
  matchedPromKey,
  matchedPromLine,
  callback: () => void,
  errorCallback: () => void
) {
  try {
    const updatedInvoiceLineObj: InvoiceLineObjectUpdate =
      getFromCurrentUserCompany(
        `reconciliation/invoices/${invoiceKey}/invoiceLines/${invoiceLineKey}`
      );
    updatedInvoiceLineObj.status = invoiceLineStatuses.MATCH_NOT_FOUND;
    updatedInvoiceLineObj.matchedPromLine = null;
    updatedInvoiceLineObj.matchedProm = null;
    updatedInvoiceLineObj.suggestedFundType = null;

    updateCurrentUserCompany(
      `reconciliation/invoices/${invoiceKey}/invoiceLines/${invoiceLineKey}`,
      updatedInvoiceLineObj
    );

    // allows pending invoice lines to be marked as match not found
    if (matchedPromKey && matchedPromLine) {
      const valuesToDelete: { [key: string]: null } = {
        [`${matchedPromKey}/lines/${matchedPromLine}`]: null
      };
      updateCurrentUserCompany(
        "reconciliation/promotionInvoices/",
        valuesToDelete
      );
    }

    callback();
  } catch {
    errorCallback();
  }
}
