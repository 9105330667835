import React, { useEffect, useState } from "react";

import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useDb } from "contexts/Db";
import { Tooltip, TooltipProps, tooltipClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Link from "ui-library/Link";
import { getUserDataFirebase, updateUserDataFirebase } from "helpers/Firebase";
import isEqual from "lodash/isEqual";
import { menuListArray } from "./sideBarConstants";

const drawerWidth = 256;

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    borderRadius: "0px 8px 8px 0px",
    padding: theme.spacing(0.5, 0, 0, 0.5),
    margin: "0px !important",
    boxShadow: theme.shadows[3],
    minWidth: "200px",
    position: "relative",
    "&::before": {
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "6px",
      borderTopRightRadius: "8px",
      background: theme.palette.vividlyGradients.gradient1
    }
    // borderTop: "6px solid",
    // borderImage: `${theme.palette.vividlyGradients.gradient1} 1`
  }
}));

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  menuButton: {
    marginRight: "36px !important"
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    backgroundColor: theme.palette.neutralVariants.neutral0
  },
  shiftDrawer: {
    [theme.breakpoints.down("xl")]: {
      top: `${theme.spacing(7)}!important`
    },
    [theme.breakpoints.up("sm")]: {
      top: `${theme.spacing(8)}!important`
    },
    backgroundColor: `${theme.palette.neutralVariants.neutral0} !important`,
    height: "93% !important",
    color: `${theme.palette.neutralVariants.neutral100} !important`
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard
    }),
    overflowX: "hidden",
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(10)
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1
  },
  menuItemContainer: {
    height: theme.spacing(6),
    display: "flex",
    alignItems: "center"
  },
  menuItemContainerClosed: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 0,
    paddingRight: 0
  },
  selectedMenuItem: {
    color: `${theme.palette.neutralVariants.neutral100} !important`
  },
  selectedMenuIcon: {
    color: `${theme.palette.neutralVariants.neutral100} !important`
  },
  menuIconBackground: {
    height: "25px"
  },
  selectedMenuIconBackground: {
    background: `${theme.palette.vividlyGradients.gradient2} !important`,
    width: "40px",
    height: "25px",
    borderRadius: "100px",
    display: "flex",
    justifyContent: "center"
  },
  menuIcon: {
    color: `${theme.palette.neutralVariants.neutral100} !important`
  }
}));

export function HeaderSlot() {
  const classes = useStyles();

  return <div className={classes.toolbar} />;
}

type SideBarProps = {
  open: boolean;
  allPermissions: any;
  pathname: string;
  handleTooltipOpen: (item?: Record<string, unknown>) => void;
  handleTooltipClose: (itemName?: string) => void;
  toolTipOpen: Record<string, boolean>;
};

const SIDEBAR_INITIAL_STATE = {
  business: false,
  promotions: false,
  manage: false,
  commissions: false,
  insights: false
};

export default function SideBar({
  open,
  allPermissions,
  pathname,
  handleTooltipOpen,
  handleTooltipClose,
  toolTipOpen
}: SideBarProps) {
  const classes = useStyles();
  const db = useDb();
  const [openSubSection, setOpenSubSection] = useState({
    ...SIDEBAR_INITIAL_STATE
  });

  const handleOpenSubSection = itemName => {
    const obj = openSubSection;
    obj[itemName] = !openSubSection[itemName];
    setOpenSubSection(prevState => {
      const newState = { ...prevState, ...obj };
      return { ...newState };
    });
  };

  const getSubMenuItems = item => {
    if (item.subItems) {
      return (
        <div>
          <List component="div" disablePadding>
            {item.subItems.map(subItem => {
              if (
                item.name === "Manage" ||
                item.name === "Promotions" ||
                subItem.permissions(db)
              ) {
                return (
                  <Link
                    to={subItem.pathname}
                    onClick={() => handleTooltipClose(item.name.toLowerCase())}>
                    <ListItemButton>
                      <ListItemText
                        primary={subItem.name}
                        primaryTypographyProps={{
                          variant: "labelMedium"
                        }}
                      />
                    </ListItemButton>
                  </Link>
                );
              }
              return <></>;
            })}
          </List>
        </div>
      );
    }
    return "";
  };

  useEffect(() => {
    getUserDataFirebase(openSidebar => {
      setOpenSubSection(({ prevState }) => {
        return openSidebar ?? prevState;
      });
    }, "openSidebar");
  }, []);

  useEffect(() => {
    if (!isEqual(SIDEBAR_INITIAL_STATE, openSubSection)) {
      updateUserDataFirebase({ openSidebar: openSubSection });
    }
  }, [openSubSection]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx(classes.shiftDrawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}>
        <List>
          {menuListArray.map(item => {
            if (open) {
              return (
                <div>
                  {db.meta.cresicor_tpm &&
                  item.subItems?.length &&
                  item.permission(db, allPermissions) > 0 ? (
                    <>
                      <ListItemButton
                        onClick={() =>
                          handleOpenSubSection(item.name.toLowerCase())
                        }
                        sx={{ py: 1, pl: 3 }}>
                        <ListItemIcon classes={{ root: classes.menuIcon }}>
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={item.name}
                          primaryTypographyProps={{ variant: "subtitle1" }}
                        />
                        {openSubSection[item.name.toLowerCase()] ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </ListItemButton>
                      <Collapse
                        in={!!openSubSection[item.name.toLowerCase()]}
                        timeout="auto"
                        unmountOnExit
                        sx={{
                          borderLeft: "2px solid",
                          borderColor: "neutralVariants.neutral95",
                          margin: "15px 0 20px 27px"
                        }}>
                        <List component="div" disablePadding>
                          {item.subItems.map(subItem => {
                            if (
                              item.name === "Manage" ||
                              item.name === "Promotions" ||
                              subItem.permissions(db)
                            ) {
                              return (
                                <Link to={subItem.pathname}>
                                  <ListItemButton
                                    sx={{
                                      pl: 4,
                                      "&:hover": {
                                        backgroundColor: "primary.light",
                                        color: "primary.contrastText"
                                      },
                                      ...(pathname.startsWith(
                                        subItem.pathname
                                      ) && {
                                        backgroundColor: "primary.main",
                                        color: "primary.contrastText",
                                        "&:hover": {
                                          backgroundColor: "primary.light"
                                          // color: "initial"
                                        }
                                      })
                                    }}>
                                    <ListItemText
                                      primary={subItem.name}
                                      primaryTypographyProps={{
                                        variant: "subtitle2"
                                      }}
                                    />
                                  </ListItemButton>
                                </Link>
                              );
                            }
                            return <></>;
                          })}
                        </List>
                      </Collapse>
                    </>
                  ) : (
                    <>
                      {item.name === "Overview" && (
                        <Link to={item.pathname}>
                          <ListItemButton sx={{ py: 1, pl: 3 }}>
                            <ListItemIcon classes={{ root: classes.menuIcon }}>
                              {item.icon}
                            </ListItemIcon>
                            <ListItemText
                              primary={item.name}
                              primaryTypographyProps={{
                                variant: "subtitle1"
                              }}
                            />
                          </ListItemButton>
                        </Link>
                      )}
                    </>
                  )}
                </div>
              );
            }

            return (
              db.meta.cresicor_tpm &&
              item.permission(db, allPermissions) && (
                <ClickAwayListener
                  onClickAway={() =>
                    handleTooltipClose(item.name.toLowerCase())
                  }>
                  <div>
                    <StyledTooltip
                      title={getSubMenuItems(item)}
                      placement="right"
                      onClose={() => handleTooltipClose()}
                      open={toolTipOpen[item.name.toLowerCase()]}
                      disableFocusListener
                      disableHoverListener
                      leaveDelay={20000000}
                      disableTouchListener>
                      <ListItemButton
                        className={classes.menuItemContainerClosed}
                        sx={{ py: 2 }}
                        onClick={() => handleTooltipOpen(item)}>
                        <div
                          className={clsx({
                            [classes.selectedMenuIconBackground]:
                              toolTipOpen[item.name.toLowerCase()],
                            [classes.menuIconBackground]:
                              !toolTipOpen[item.name.toLowerCase()]
                          })}>
                          <ListItemIcon
                            className={clsx({
                              [classes.selectedMenuIcon]:
                                toolTipOpen[item.name.toLowerCase()],
                              [classes.menuIcon]:
                                !toolTipOpen[item.name.toLowerCase()]
                            })}
                            sx={{
                              minWidth: "auto"
                            }}>
                            {item.icon}
                          </ListItemIcon>
                        </div>
                        <Typography
                          className={clsx({
                            [classes.selectedMenuItem]:
                              toolTipOpen[item.name.toLowerCase()]
                          })}
                          variant="labelSmall">
                          {" "}
                          {item.name}
                        </Typography>
                      </ListItemButton>
                    </StyledTooltip>
                  </div>
                </ClickAwayListener>
              )
            );
          })}
        </List>
      </Drawer>
    </div>
  );
}
