import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Drawer from "@mui/material/Drawer";
import AddIcon from "@mui/icons-material/Add";
import { updateMetaFirebase } from "helpers/Firebase";
import { objToArray, sortByField } from "helpers/sortByDate";
import { find } from "lodash";
import React, { useEffect, useState } from "react";
import Button from "ui-library/Button";
import { PromotionChip } from "ui-library/promotionTag";
import { Stack } from "@mui/material";
import PaginationMUITable from "../../../tables/PaginationMUITable";
import { ModifyTag } from "./modifyTag";

const MAX_COUNT = 11;

interface Sorter {
  field: string;
  reverseSort: boolean;
}

const styles = {
  subheader: {
    color: "primary.main"
  },
  drawer: {
    width: "400px"
  },
  card: {
    marginTop: "20px"
  }
};

const columns = [
  {
    title: "Name",
    field: "name",
    width: 300
  },
  { title: "Description", field: "description", width: 500, sorting: false }
];

const Tags: React.FC<{ db: any; openClose: any }> = props => {
  const [data, setData] = useState([]);
  const [sorter, setSorter] = useState<Sorter>();
  const [selectedRow, setSelectedRow] = useState<any>();

  useEffect(() => {
    let tagList = objToArray(props.db.meta.promotionTags || []);

    if (sorter?.field) {
      tagList = sortByField(sorter.field, tagList, sorter.reverseSort);
    }

    const tagListJsx = tagList.map(tag => {
      return {
        ...tag,
        name: <PromotionChip tag={tag} />
      };
    });

    setData(tagListJsx);
  }, [props.db.meta, sorter]);

  const onColumnSort = (field: string) => {
    if (sorter?.field === field) {
      setSorter(prevState => ({ field, reverseSort: !prevState?.reverseSort }));
    } else {
      setSorter({ field, reverseSort: false });
    }
  };

  const handleProfileOpen = (event, rowData) => {
    setSelectedRow({
      ...props.db.meta.promotionTags[rowData.key],
      key: rowData.key
    });
  };

  const hasDependency = (tagKey: string) => {
    return !!find(props.db.promotions, prom => prom.tags?.includes(tagKey));
  };

  const deleteTag = (key: string) => () => {
    const update = {
      [`promotionTags/${key}`]: null
    };
    updateMetaFirebase(update);
    props.openClose.closeAppModal();
  };

  const handleOnDelete = (event, rowData) => {
    if (hasDependency(rowData.key)) {
      props.openClose.setAppModal(
        "Unable to delete tag",
        <div className="centering">
          {
            "This tag is used in one or more promotions. \
               Please reconcile these dependencies before deleting this tag."
          }
        </div>,
        <div className="centering">
          <Button onClick={props.openClose.closeAppModal} label="Okay" />
        </div>
      );
      return;
    }

    props.openClose.setAppModal(
      "Delete Tag",
      <div className="centering">
        Are you sure you want to delete this tag?
      </div>,

      <Stack>
        <Button
          color="error"
          variant="text"
          onClick={deleteTag(rowData.key)}
          label="Yes, I'm sure"
        />
        <Button
          variant="text"
          onClick={props.openClose.closeAppModal}
          label="No, go back"
        />
      </Stack>
    );
  };

  const closeDrawer = () => setSelectedRow(null);
  const openDrawer = () => setSelectedRow({});
  const actionParams = {
    openProfile: rowData => {
      handleProfileOpen;
    }
  };
  if (props.db.permissions.includes("admin")) {
    actionParams.delete = rowData => ({
      onClick: handleOnDelete
    });
  }

  return (
    <>
      <Card style={styles.card}>
        <CardHeader
          sx={styles.subheader}
          title="Promotion Tags"
          action={
            data.length < MAX_COUNT && (
              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<AddIcon />}
                onClick={openDrawer}
                label="New Tag"
              />
            )
          }
        />
        <CardContent>
          <PaginationMUITable
            data={data}
            columns={columns}
            allLoaded
            selection={false}
            handleProfileOpen={handleProfileOpen}
            onColumnSort={onColumnSort}
            actionParams={actionParams}
          />
        </CardContent>
      </Card>
      <Drawer
        anchor="right"
        open={!!selectedRow}
        onClose={closeDrawer}
        style={styles.drawer}>
        <ModifyTag
          onUpdate={closeDrawer}
          selectedRow={selectedRow}
          assignedColors={data.map(tag => tag.color)}
        />
      </Drawer>
    </>
  );
};

export { Tags };
