import { ERPTransactionObject } from "components/Deductions/models";
import {
  invoiceTypes,
  ERPTransactionTypes
} from "deductions-constants/ReconciliationTypes";

export interface DisplayERPTransactionObject extends ERPTransactionObject {
  customerName: string;
  displayStatus: string;
  displayType: string;
  displayAssignedUser: string;

  clearedAmount: number;

  totalWriteOffAmount: number;
  creditedWriteOffAmount: number;
  openWriteOffAmount: number;

  totalDisputeAmount: number;
  repaidDisputeAmount: number;
  openDisputeAmount: number;

  resolvedAmount: number; // cleared + totalWriteOff + repaid
  openAmount: number; // pending + total dispute - repaid = pending + open dispute
}

export enum transactionFilters {
  ACTIVE = "Active Transactions",
  INACTIVE = "Inactive/Cancelled Transactions"
}

export enum transactionActions {
  CANCEL = "Cancel Transactions",
  LINK_INVOICE = "Link Invoice",
  CLEAR_INVOICE_LINE = "Clear Linked Invoice Line",
  PROCESS_REPAYMENT = "Process Repayment",
  REPAY_DEDUCTION = "Repay Deduction",
  ATTACH_REPAYMENT = "Attach Repayment",
  ATTACH_DEDUCTION = "Attach Deduction"
}

export enum transactionDependencies {
  TRANSACTION_CANCELLED = "TRANSACTION_CANCELLED",
  LINKED_INVOICES = "LINKED_INVOICES",
  RESOLUTION_LINES = "RESOLUTION_LINES",
  REPAYMENT_LINES = "REPAYMENT_LINES",
  ATTACHED_DEDUCTIONS = "ATTACHED_DEDUCTIONS",
  ATTACHED_REPAYMENTS = "ATTACHED_REPAYMENTS"
}

export enum transactionDependencyDescriptions {
  TRANSACTION_CANCELLED = "this transaction is cancelled",
  LINKED_INVOICES = "there is at least one linked invoice",
  RESOLUTION_LINES = "at least one linked invoice has been partially or fully resolved",
  REPAYMENT_LINES = "this repayment has been applied to at least one disputed/written-off invoice line",
  ATTACHED_DEDUCTIONS = "at least one deduction is attached to this repayment",
  ATTACHED_REPAYMENTS = "at least one repayment is attached to this deduction"
}

export const invoiceTypeToTransactionTypeMap: Record<
  invoiceTypes,
  ERPTransactionTypes
> = {
  [invoiceTypes.TRADE_DEDUCTION]: ERPTransactionTypes.DEDUCTION,
  [invoiceTypes.NON_TRADE_DEDUCTION]: ERPTransactionTypes.DEDUCTION,
  [invoiceTypes.BILLPAY]: ERPTransactionTypes.BILLPAY,
  [invoiceTypes.REPAYMENT]: ERPTransactionTypes.REPAYMENT
};
