import {
  ERPTransactionObject,
  InvoiceObject,
  InvoiceLineObject,
  RepaymentLineObject
} from "deductions-models/index";
import { DbContextValues } from "contexts/Db";
import {
  deductionTransactionStatuses,
  repaymentTransactionStatuses
} from "components/Deductions/constants/ReconciliationStatuses";
import {
  ApplyRepaymentObjects,
  ApplyRepaymentState,
  RepaymentLineErrors
} from "./applyRepaymentSlice";

export function generateNewRepaymentLineAndErrors(
  state: ApplyRepaymentState,
  invoiceLine: InvoiceLineObject,
  db: DbContextValues
): {
  newRepaymentLine: RepaymentLineObject;
  newLineError: RepaymentLineErrors;
} {
  // eslint-disable-next-line camelcase
  const { meta: { fundTypes = {} } = {}, allLines = {}, accounts = {} } = db;
  const {
    key = "",
    invoiceLineKey = "",
    matchedPromLine = "",
    suggestedFundType = ""
  } = invoiceLine;

  const { currentObjects, repayableInvoiceLineAmounts, activityType } = state;
  const {
    invoice: currentInvoice,
    deduction: currentDeduction,
    repayment: currentRepayment
  } = currentObjects;

  const fundTypeKey =
    allLines[matchedPromLine || ""]?.type || suggestedFundType || "";
  const fundTypeAccount =
    accounts[fundTypes[fundTypeKey]?.accountKey]?.name || "";

  const newRepaymentLine: RepaymentLineObject = {
    key: "",
    createdDate: "",
    createdUser: "",
    type: activityType,
    repaymentKey: currentRepayment?.key || "",
    deductionKey: currentDeduction?.key || "",
    invoiceKey: currentInvoice?.key || "",
    invoiceLineKey: key || invoiceLineKey,
    amount: repayableInvoiceLineAmounts[key || invoiceLineKey][activityType],
    fundTypeKey,
    fundTypeAccount
  };

  const newLineError: RepaymentLineErrors = {
    exceedsMaxAmount: false,
    missingFundTypeKey: !fundTypeKey,
    missingFundTypeAccount: !fundTypeAccount,
    amountZero: false
  };

  return { newRepaymentLine, newLineError };
}

// Returns collection of repayments transactions that can be selected from the AddRepaymentLine interface
export function getAvailableRepayments(
  initialRepaymentKey: string | undefined,
  filters: ApplyRepaymentObjects,
  db: DbContextValues
): Record<string, ERPTransactionObject> {
  const { deduction, invoice } = filters;
  const { erpTransactions } = db;

  if (initialRepaymentKey) {
    return {
      [initialRepaymentKey]: erpTransactions[initialRepaymentKey]
    };
  }

  if (deduction) {
    const { attachedRepayments = {} } = deduction;
    return Object.values(attachedRepayments)
      .map(repaymentKey => erpTransactions[repaymentKey || ""] || undefined)
      .filter(attachedRepayment => attachedRepayment)
      .filter(
        attachedRepayment =>
          attachedRepayment.status !== repaymentTransactionStatuses.CANCELLED
      )
      .reduce((allAttachedRepayments, attachedRepayment) => {
        return {
          ...allAttachedRepayments,
          [attachedRepayment.key]: attachedRepayment
        };
      }, {});
  }

  if (invoice) {
    const { linkedERPTransactions = {} } = invoice;
    return [
      ...new Set(
        Object.values(linkedERPTransactions)
          .map(deductionKey => erpTransactions[deductionKey])
          .map(linkedDeduction =>
            Object.keys(
              getAvailableRepayments(
                initialRepaymentKey,
                { deduction: linkedDeduction },
                db
              )
            )
          )
          .reduce((allRepaymentKeys, repaymentKeys) => {
            return [...allRepaymentKeys, ...repaymentKeys];
          }, [])
      )
    ].reduce((allRepayments, uniqueRepaymentKey) => {
      return {
        ...allRepayments,
        [uniqueRepaymentKey]: erpTransactions[uniqueRepaymentKey]
      };
    }, {});
  }

  return {};
}

// Returns collection of deduction transactions that can be selected from the AddRepaymentLine interface
export function getAvailableDeductions(
  initialDeductionKey: string | undefined,
  filters: ApplyRepaymentObjects,
  db: DbContextValues
): Record<string, ERPTransactionObject> {
  const { repayment, invoice } = filters;
  const { erpTransactions } = db;

  if (initialDeductionKey) {
    return {
      [initialDeductionKey]: erpTransactions[initialDeductionKey]
    };
  }

  if (invoice) {
    const { linkedERPTransactions = {} } = invoice;
    return Object.values(linkedERPTransactions)
      .map(deductionKey => erpTransactions[deductionKey])
      .reduce((allLinkedDeductions, linkedDeduction) => {
        return {
          ...allLinkedDeductions,
          [linkedDeduction.key]: linkedDeduction
        };
      }, {});
  }

  if (repayment) {
    const { repaidDeductions = {} } = repayment;
    return Object.values(repaidDeductions)
      .map(deductionKey => erpTransactions[deductionKey || ""] || undefined)
      .filter(attachedDeduction => attachedDeduction)
      .filter(
        attachedDeduction =>
          attachedDeduction.status !== deductionTransactionStatuses.CANCELLED
      )
      .reduce((allRepaidDeductions, repaidDeduction) => {
        return {
          ...allRepaidDeductions,
          [repaidDeduction.key]: repaidDeduction
        };
      }, {});
  }

  return {};
}

// Returns collection of invoices that can be selected from the AddRepaymentLine interface
export function getAvailableInvoices(
  filters: ApplyRepaymentObjects,
  db: DbContextValues
): Record<string, InvoiceObject> {
  const { deduction } = filters;
  const { invoices } = db;

  if (!deduction) {
    return {};
  }

  const { linkedInvoices = {} } = deduction;

  return Object.values(linkedInvoices)
    .map(invoiceKey => invoices[invoiceKey])
    .reduce((allLinkedInvoices, linkedInvoice) => {
      return {
        ...allLinkedInvoices,
        [linkedInvoice.key]: linkedInvoice
      };
    }, {});
}

// Returns collection of invoice lines that can be selected from the AddRepaymentLine interface
export function getAvailableInvoiceLines(
  filters: ApplyRepaymentObjects
): Record<string, InvoiceLineObject> {
  const { invoice } = filters;

  if (invoice) {
    const { invoiceLines = {} } = invoice;
    return invoiceLines;
  }

  // Can't select an invoice line until an invoice is selected
  return {};
}

export const applyRepaymentErrorMessages: Record<string, string> = {
  missingRepaymentKey: "Select a repayment ID",
  missingDeductionKey: "Select a deduction ID",
  amountNotNegative: "Must be negative",
  amountOverWriteOffAmount: "Exceeds written-off amount",
  amountOverOpenAmount: "Exceeds disputed amount",
  amountOverRepaymentOpenAmount: "Exceeds available repayment",
  missingFundTypeKey: "Select a fund type",
  missingFundTypeAccount: "Select a fund type",
  noInvoiceLinesSelected: "Must select one or all invoice lines to proceed"
};

export const ApplyRepaymentErrorMap: Record<string, string> = {
  missingRepaymentKey: "Select a repayment.",
  missingDeductionKey: "Select a deduction.",
  missingInvoiceKey: "Select an invoice.",
  totalExceedsAvailableAmount: "Subtotal exceeds available amount.",
  noInvoiceLinesSelected: "Must select at least one invoice line.",
  totalZero: "Subtotal must be non-zero.",
  lineErrors: "At least one selected invoice line has an error."
};

export const ApplyRepaymentLineErrorMap: Record<string, string> = {
  amountZero: "Must be non-zero",
  missingFundTypeKey: "Select fund type",
  missingFundTypeAccount: "Select fund type",
  exceedsMaxAmount: "Exceeds open amount"
};
