import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import Grid from "@mui/material/Grid";
import AddFiles from "./Step1/AddFiles";
import AssignPages from "./Step2/AssignPages";
import AddMetadata from "./Step3/AddMetadata";
import UploadInProgress from "./Step4/UploadInProgress";
import UploadComplete from "./Step5/UploadComplete";

import { uploadSteps } from "./redux/definitions";

export default function UploadBackupSteps() {
  const { currentUploadStep } = useSelector(
    (state: RootState) => state.uploadBackup
  );

  return (
    <div>
      {currentUploadStep === uploadSteps.ADD_FILES_AND_ASSIGN_PAGES && (
        <Grid container>
          <Grid item xs={5}>
            <AddFiles />
          </Grid>
          <Grid item xs={7}>
            <AssignPages />
          </Grid>
        </Grid>
      )}
      {currentUploadStep === uploadSteps.ADD_METADATA && <AddMetadata />}
      {currentUploadStep === uploadSteps.UPLOADING && <UploadInProgress />}
      {currentUploadStep === uploadSteps.DONE && <UploadComplete />}
    </div>
  );
}
