import { deductionTransactionStatuses } from "components/Deductions/constants/ReconciliationStatuses";
import {
  ERPTransactionObject,
  InvoiceObject
} from "components/Deductions/models";
import { InvoiceDependenciesService } from "reconciliation-services";
import {
  DisplayERPTransactionObject,
  transactionDependencies,
  transactionActions,
  transactionDependencyDescriptions
} from "components/Deductions/DeductionsReconciliation/types/transactionTypes";
import { DisplayInvoiceObject } from "components/Deductions/DeductionsReconciliation/types/invoiceTypes";

// get all existing dependencies at once
export function calculateTransactionDependencies(
  transaction: DisplayERPTransactionObject | ERPTransactionObject,
  allInvoices: { [invoiceKey: string]: InvoiceObject | DisplayInvoiceObject }
): Record<transactionDependencies, boolean> {
  const {
    linkedInvoices = {},
    status,
    repaymentLines = {},
    repaidDeductions = {},
    attachedRepayments = {}
  } = transaction;

  return {
    TRANSACTION_CANCELLED: status === deductionTransactionStatuses.CANCELLED,
    LINKED_INVOICES: Object.keys(linkedInvoices).length > 0,
    RESOLUTION_LINES: linkedInvoices
      ? Object.values(linkedInvoices)
          .map((invoiceKey: string) => {
            const invoice = allInvoices[invoiceKey];
            const calculatedInvoiceDependencies =
              InvoiceDependenciesService.calculateInvoiceDependencies(invoice);
            return calculatedInvoiceDependencies.RESOLUTION_LINES;
          })
          .some(d => d)
      : false,
    REPAYMENT_LINES: Object.keys(repaymentLines).length > 0,
    ATTACHED_DEDUCTIONS: Object.keys(repaidDeductions).length > 0,
    ATTACHED_REPAYMENTS: Object.keys(attachedRepayments).length > 0
  };
}

// determine whether a specific invoice action is allowed given the current invoice object
export function isSingleTransactionActionAllowed(
  transaction: DisplayERPTransactionObject | ERPTransactionObject,
  action: transactionActions,
  allInvoices: { [invoiceKey: string]: InvoiceObject | DisplayInvoiceObject },
  precalculatedDependencies?: Record<transactionDependencies, boolean>
): {
  actionAllowed: boolean;
  dependenciesMessage: string;
} {
  // recalculate dependencies if not given
  const calculatedDependencies =
    precalculatedDependencies ||
    calculateTransactionDependencies(transaction, allInvoices);

  // convert a subset of dependencies into descriptions of valid dependencies
  function filterDependencies(
    dependencies: transactionDependencies[]
  ): transactionDependencyDescriptions[] {
    return dependencies
      .filter(d => calculatedDependencies[d])
      .map(d => transactionDependencyDescriptions[d]);
  }

  // get subset of dependency checks for a single action
  let subset: transactionDependencies[] = [];

  switch (action) {
    case transactionActions.CANCEL: {
      subset = [
        transactionDependencies.TRANSACTION_CANCELLED,
        transactionDependencies.LINKED_INVOICES,
        transactionDependencies.RESOLUTION_LINES,
        transactionDependencies.REPAYMENT_LINES,
        transactionDependencies.ATTACHED_DEDUCTIONS,
        transactionDependencies.ATTACHED_REPAYMENTS
      ];
      break;
    }
    case transactionActions.LINK_INVOICE: {
      subset = [transactionDependencies.TRANSACTION_CANCELLED];
      break;
    }
    case transactionActions.REPAY_DEDUCTION: {
      subset = [transactionDependencies.TRANSACTION_CANCELLED];
      break;
    }
    case transactionActions.PROCESS_REPAYMENT: {
      subset = [transactionDependencies.TRANSACTION_CANCELLED];
      break;
    }
    case transactionActions.ATTACH_DEDUCTION: {
      subset = [transactionDependencies.TRANSACTION_CANCELLED];
      break;
    }
    case transactionActions.ATTACH_REPAYMENT: {
      subset = [transactionDependencies.TRANSACTION_CANCELLED];
      break;
    }
    default: {
      break;
    }
  }

  // calculate which ones evaluate to true and map to their descriptions
  const actionDependencies = filterDependencies(subset);
  const dependenciesMessage = actionDependencies.join(", ");

  return {
    actionAllowed: actionDependencies.length === 0,
    dependenciesMessage
  };
}
