import React from "react";
import { connect } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Stack from "@mui/material/Stack";
import Button from "ui-library/Button";
import { common } from "@mui/material/colors";
import { changeStep, reset, setDefault, changeMode } from "./actions";

const { white } = common;

class StepButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = { mode: this.props.mode };
  }

  componentDidMount() {
    this.props.dispatch(changeMode(this.props.mode));
  }

  render() {
    const noLinesError =
      this.props.stepIndex === 1 &&
      this.props.lines &&
      this.props.lines.length === 0;
    const lineError =
      !noLinesError && this.props.stepIndex === 1 && this.props.allGood === 0;
    const stillUploadingError =
      this.props.stepIndex === 2 && this.props.allGood === 0;
    const { removeLineSpendLineError, removeLineInvoiceLineError } = this.props;
    const unconfirmed = Boolean(this.props.promState.duplicatedFrom);
    return (
      <div
        className="rowRL"
        style={{
          width: "100%",
          borderTop: "1px solid #cbcbcb",
          paddingTop: "8px"
        }}>
        <div className="rowC">
          <Button
            label="Close"
            variant="text"
            color="error"
            onClick={() => {
              this.props.dispatch(reset());
              this.props.openClose.closeAppModal();
              if (this.props.windowClosed) {
                this.props.windowClosed();
              }
            }}
            icon={<CloseIcon />}
          />
        </div>
        {noLinesError && (
          <div style={{ marginTop: 8 }}>
            <font color="red">A promotion must have one or more lines!</font>
          </div>
        )}
        {lineError && (
          <div style={{ marginTop: 8 }}>
            <font color="red">
              One or more lines have errors. Please fix these errors before
              continuing!
            </font>
          </div>
        )}
        {removeLineSpendLineError && (
          <div style={{ marginTop: 8 }}>
            <font color="red">
              This line cannot be deleted because at least one spend item is
              attached to the promotion.
            </font>
          </div>
        )}
        {removeLineInvoiceLineError && (
          <div style={{ marginTop: 8 }}>
            <font color="red">
              This line cannot be deleted because at least one invoice item is
              attached to the promotion.
            </font>
          </div>
        )}
        {stillUploadingError && (
          <div style={{ marginTop: 8 }}>
            <font color="red">
              Your file(s) are still uploading. Please wait a moment, then click
              Finish again!
            </font>
          </div>
        )}
        {this.props.fieldWarnings && (
          <div style={{ marginTop: 8 }}>
            <font color="orange">{this.props.fieldWarnings}</font>
          </div>
        )}
        {this.props.finished ? (
          <div className="rowC">
            <Button
              label="Duplicate Previous"
              onClick={() => {
                this.props.dispatch(reset(this.props.newProm));
                this.props.dispatch(
                  setDefault(
                    this.props.newProm,
                    null,
                    this.props.db,
                    "duplicate"
                  )
                ); // set default to a copy of the old promotion
                this.setState({ mode: "duplicate" });
                this.props.dispatch(changeMode("duplicate"));
              }}
              style={{ marginRight: 12, color: white }}
              disabled={this.props.proposed}
            />
            <Button
              label="Create New"
              onClick={() => {
                this.props.dispatch(reset());
                this.setState({ mode: "add" });
                this.props.dispatch(changeMode("add"));
              }}
              style={{ marginRight: 12, color: white }}
            />
            <Button
              label="Edit Previous"
              onClick={() => {
                this.props.dispatch(reset(this.props.newProm));
                this.props.dispatch(
                  setDefault(this.props.newProm, null, this.props.db, "edit")
                ); // set default to a copy of the old promotion, but in edit mode
                this.setState({ mode: "edit" });
                this.props.dispatch(changeMode("edit"));
              }}
              style={{ color: white }}
            />
          </div>
        ) : (
          <Stack>
            <Button
              label="Back"
              disabled={this.props.stepIndex === 0}
              onClick={() =>
                this.props.dispatch(
                  changeStep(false, this.props.db, this.state.mode)
                )
              }
              color="tonal"
              style={{ marginRight: 12 }}
              icon={<KeyboardArrowLeftIcon />}
              disabled={this.props.stepIndex === 3}
            />
            <Button
              label={this.props.stepIndex >= 2 ? "Finish" : "Next"}
              labelPosition="before"
              icon={<KeyboardArrowRightIcon />}
              onClick={() =>
                this.props.dispatch(
                  changeStep(true, this.props.db, this.state.mode)
                )
              }
              disabled={
                unconfirmed
                  ? false
                  : this.props.stepIndex === 3 ||
                    (this.props.stepIndex === 2 &&
                      !this.props.allFilesDownloaded)
              }
            />
          </Stack>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const promFields = state.addPromotion;
  return {
    promState: promFields.prom,
    stepIndex: promFields.stepIndex,
    finished: promFields.finished,
    newProm: promFields.newProm,
    allGood: promFields.allGood,
    removeLineInvoiceLineError: promFields.removeLineInvoiceLineError,
    removeLineSpendLineError: promFields.removeLineSpendLineError,
    lines: promFields.lines,
    allFilesDownloaded: promFields.allFilesDownloaded,
    fieldWarnings: promFields.fieldWarnings
  };
};
export default connect(mapStateToProps)(StepButtons);
