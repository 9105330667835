import React from "react";
import { useSelector, useDispatch } from "react-redux";
import FileSaver from "file-saver";
import { makeStyles } from "@mui/styles";
import IconButton from "ui-library/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ClearIcon from "@mui/icons-material/Clear";
import GetAppIcon from "@mui/icons-material/GetApp";
import DataTableScrollable from "components/tables/DataTableScrollable";
import { RootState } from "store";
import { useOpenClose } from "contexts/OpenClose";
import BackupFilePreview from "../BackupFilePreview";
import { removeBackupFile, setViewedFile } from "../redux/actionCreators";

const useStyles = makeStyles(() => ({
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  columnContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center"
  },
  tableCell: {
    alignSelf: "center",
    wordWrap: "break-word"
  },
  textField: {
    marginBottom: "12px"
  },
  tableHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  }
}));

export default function UploadedFilesTable() {
  const { newFiles, newFilePageCounts } = useSelector(
    (state: RootState) => state.uploadBackup
  );

  const classes = useStyles();

  const dispatch = useDispatch();
  const { showRightDrawer } = useOpenClose();

  const openFilePreview = fileId => () => {
    dispatch(setViewedFile(fileId));
    showRightDrawer(<BackupFilePreview />);
  };

  function getUploadedFilesTable() {
    const namesColumn = newFiles.map((newFile: File, _fileIdx: number) => (
      <div className={classes.tableCell} key={`actions_${newFile.name}`}>
        {newFile.name}
      </div>
    ));

    const numPagesColumn = newFiles.map((newFile: File, fileIdx: number) => (
      <div className={classes.tableCell} key={`actions_${newFile.name}`}>
        {newFilePageCounts[fileIdx] || "N/A"}
      </div>
    ));

    const sizesColumn = newFiles.map((newFile: File, _fileIdx: number) => (
      <div className={classes.tableCell} key={`actions_${newFile.name}`}>
        {formatFileSize(newFile.size)}
      </div>
    ));

    const actionsColumn = newFiles.map((newFile: File, fileIdx: number) => (
      <div key={`actions_${newFile.name}`}>
        <IconButton
          tooltip="Preview File"
          onClick={openFilePreview(fileIdx)}
          size="large">
          <VisibilityIcon />
        </IconButton>
        <IconButton
          tooltip="Download File"
          onClick={() => FileSaver.saveAs(newFile, newFile.name)}
          size="large">
          <GetAppIcon />
        </IconButton>
        <IconButton
          tooltip="Delete File"
          onClick={() => dispatch(removeBackupFile(fileIdx))}
          size="large">
          <ClearIcon />
        </IconButton>
      </div>
    ));

    return {
      Name: namesColumn,
      "# Pages": numPagesColumn,
      Size: sizesColumn,
      Actions: actionsColumn
    };
  }

  return (
    <div>
      <DataTableScrollable
        data={getUploadedFilesTable()}
        widths={["45%", "12.5%", "12.5%", "30%"]}
        height={300}
      />
    </div>
  );
}

function formatFileSize(bytes: number, decimalPoint: number = 2): string {
  if (bytes == 0) {
    return "0 KB";
  }
  const k = 1000;
  const dm = decimalPoint || 2;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}
