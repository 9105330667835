import React from "react";
import debounce from "lodash.debounce";

import {
  Tab,
  Tabs,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Stack
} from "@mui/material";

// import AssignmentIcon from "@mui/icons-material/Assignment";
import ClearIcon from "@mui/icons-material/Clear";
import PublicIcon from "@mui/icons-material/Public";
import SaveIcon from "@mui/icons-material/Save";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";

import Button from "ui-library/Button";
import IconButton from "ui-library/IconButton";
import { TabPanel, a11yProps } from "ui-library/TabPanel";

import {
  getFirebase,
  getJSXListsFirebase,
  updateFirebase,
  batchUpdatePricingFirebase,
  updateMetaFirebase,
  isUserCresicorEmployee,
  getFirebaseConsoleURL,
  firebase
} from "helpers/Firebase";
import { infoDialog } from "helpers/OpenDialog";
import { blue, green, common, grey } from "@mui/material/colors";
import CustomerAltNameTags from "./CustomerAltNameTags";
// import CustomerEditLog from "./CustomerEditLog";
// import TimeframeIndicator from "./TimeframeIndicator";
// import TimeframeEditor from "./TimeframeEditor";
import CustomerGeneralInfo from "./CustomerGeneralInfo";
import ManageDistribution from "./ManageDistribution";
import {
  getCurrentTimeframeIndex,
  checkConflicts,
  checkDuplicateName,
  checkDistributorCycle,
  calculateRetailerPricing
} from "./CustomerUtils";

// import MenuItem from "@mui/material/MenuItem";

const blue50 = blue["50"];
const { black } = common;
const grey700 = grey["700"];
const green400 = green["400"];

const styles = {
  superheader: {
    fontFamily: "Ubuntu",
    marginTop: 20
  },
  header: {
    marginLeft: "-15px",
    marginRight: "-50px",
    fontSize: 20,
    color: black
  },
  tabs: {
    backgroundColor: grey700
  },
  divider: {
    margin: "30px auto",
    width: "50%"
  },
  wrapper: {
    display: "flex",
    marginRight: 50
  },
  chip: {
    margin: 3,
    cursor: "pointer",
    backgroundColor: blue50,
    textSize: ".875rem",
    fontFamily: "Oxygen"
  }
};

export default class CustomerProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: {},
      changes: false,
      tab: 0,
      messages: {},
      searchQuery: "",
      newAltNames: [],
      newBasketCustomer: "",
      pnlBreakdown: {},
      channels: [],
      classes: [],
      customersJSX: [],
      contactsJSX: [],
      distributorsJSX: [],
      presetCustomers: {},
      buyInCalendar: props.db.meta?.buyin_calendar?.[props.customerKey],
      useBuyIn: props.db.meta.use_buyin_calendar,
      refs: {
        newDistributorRef: React.createRef(),
        newRegionGroupRef: React.createRef(),
        customerProfileRef: React.createRef(),
        manageDistributionRef: React.createRef()
      },
      hasError: false,
      timeframeIndex: undefined
    };
  }

  setCustomerProfileState = (newState, saveChanges = false) => {
    this.setState(newState, () => {
      if (saveChanges) this.handleSaveChanges();
    });
  };

  getCustomerData = customerKey => {
    if (this.props.presetCustomer) {
      firebase
        .database()
        .ref(`presetCustomers/${customerKey}`)
        .on("value", snapshot => {
          const customer = snapshot.val();
          this.setState(
            {
              customer,
              changes: false
            },
            this.setTimeframe(
              getCurrentTimeframeIndex(customer, this.props.curDate)
            )
          );
        });
    } else {
      getFirebase(
        2,
        customer => {
          if (customer.contact && !Array.isArray(customer.contact)) {
            customer.contact = [customer.contact];
          } else if (!customer.contact) {
            customer.contact = [];
          }
          this.setState(
            {
              customer,
              weeksToRetail: customer.weeksToRetail,
              changes: false
            },
            this.setTimeframe(
              getCurrentTimeframeIndex(customer, this.props.curDate)
            )
          );
        },
        customerKey
      );
    }
  };

  handleTabChange = (event, value) => {
    this.setState({
      tab: value
    });
  };

  handleTextChange = event => {
    const newCustomer = this.state.customer;
    newCustomer[event.target.id] = event.target.value;

    if (event.target.id == "name") {
      this.setState({ customerNameChanged: true });
    }

    this.setState({
      customer: newCustomer,
      changes: true
    });
  };

  handleRegionGroupChange = value => {
    // add checks here
    this.setState({
      newRegionGroup: value,
      changes: true
    });
  };

  handleSelectChange = (field, event) => {
    const newCustomer = this.state.customer;
    const { value } = event.target;
    if (field == "commissions_sources") {
      if (newCustomer[field]?.[0] == value) {
        newCustomer[field] = [];
      } else {
        newCustomer[field] = [value];
      }
    } else if (field != "contact") {
      newCustomer[field] = value;
    } else {
      const sanitisedValues = value.filter(id => id in this.props.db.contacts);
      newCustomer[field] = sanitisedValues;
      newCustomer.contactName = sanitisedValues
        .sort()
        .map(x => this.props.db.contacts?.[x].name ?? "Unknown")
        .join(", ");
    }
    this.setState({
      customer: newCustomer,
      changes: true
    });
  };

  getSaveJSX = () => {
    return (
      <div>
        <br />
        <div className="centering">
          You have unsaved changes. Would you like to save?
        </div>
        <br />
        <Stack justifyContent="center">
          <Button
            label="Yes"
            onClick={() => {
              this.handleSaveChanges();
              this.props.openClose.hideRightDrawer();
              this.setState({
                promptSave: false
              });
              this.props.openClose.setDrawerClose(null);
            }}
          />
          <Button
            label="No, continue"
            variant="outlined"
            color="error"
            onClick={() => {
              this.props.openClose.hideRightDrawer();
              this.setState({
                promptSave: false
              });
              this.props.openClose.setDrawerClose(null);
            }}
          />
        </Stack>
      </div>
    );
  };

  handleSaveChanges = () => {
    const formatCustomer = (customer, keepTimeframeDates, timeframe) => {
      const newCustomer = jQuery.extend(true, {}, timeframe ?? customer);
      const newDefaultTags =
        this.props.db.meta.defaultCustomerAltNameTags.map(x => x.tag) || [];

      if (!("distributors" in newCustomer) || newCustomer.distributors == "") {
        newCustomer.distributors = [];
      }
      if (
        this.state.newDistributor &&
        !newCustomer.distributors.includes(this.state.newDistributor.value)
      ) {
        newCustomer.distributors.push(this.state.newDistributor.value);
      }

      if (!("regionGroups" in newCustomer) || newCustomer.regionGroups == "") {
        newCustomer.regionGroups = [];
      }
      if (
        this.state.newRegionGroup &&
        !newCustomer.regionGroups.includes(this.state.newRegionGroup)
      ) {
        newCustomer.regionGroups.push(this.state.newRegionGroup.value);
      }

      if (!("otherNames" in newCustomer) || newCustomer.otherNames == "") {
        newCustomer.otherNames = [];
      }

      if (this.state.newAltNames.length) {
        // Allow only unique values
        newCustomer.otherNames = [
          ...new Set([...newCustomer.otherNames, ...this.state.newAltNames])
        ];
      }
      if (this.state.weeksToRetail) {
        newCustomer.weeksToRetail = parseInt(this.state.weeksToRetail) || null;
      }

      if (newCustomer.effectiveDate) {
        newCustomer.effectiveDate = new Date(
          newCustomer.effectiveDate
        ).toDateString();
      }
      if (newCustomer.expirationDate) {
        newCustomer.expirationDate = new Date(
          newCustomer.expirationDate
        ).toDateString();
      }

      return newCustomer;
    };

    let customer = jQuery.extend(true, {}, this.state.customer);

    if (!this.props.presetCustomer) {
      // Check customer name and alt names
      // This needs to be done before timeframe data is attached to customer
      const newCustomerDict = jQuery.extend(true, {}, this.props.db.customers);
      newCustomerDict[this.props.customerKey] = customer;

      if (checkDistributorCycle(newCustomerDict)) {
        this.triggerDistributorLoopError();
        return;
      }

      const dupName = checkDuplicateName(
        newCustomerDict,
        this.props.customerKey,
        "customer",
        customer.name
      );
      if (dupName) {
        this.triggerDuplicateNameError(
          customer.name,
          "customer",
          dupName[0],
          dupName[1]
        );
        return;
      }
      if (this.state.newAltNames.length) {
        let duplicateNameErrorTriggered = false;
        this.state.newAltNames.forEach(newAltName => {
          const dupAltName = checkDuplicateName(
            newCustomerDict,
            this.props.customerKey,
            "alternative",
            newAltName
          );
          if (dupAltName) {
            this.triggerDuplicateNameError(
              newAltName,
              "alternative",
              dupAltName[0],
              dupAltName[1]
            );
            duplicateNameErrorTriggered = true;
          }
        });
        if (duplicateNameErrorTriggered) {
          return;
        }
      }
    }

    let prevIndex =
      this.state.timeframeIndex ??
      getCurrentTimeframeIndex(customer, new Date());

    if (customer.timeframes) {
      const { timeframes } = customer;
      if (prevIndex >= timeframes.length) {
        prevIndex = timeframes.length - 1;
      }

      const customerData = jQuery.extend(true, {}, customer);
      delete customerData.timeframes;
      customerData.effectiveDate = timeframes[prevIndex].effectiveDate ?? null;
      customerData.expirationDate =
        timeframes[prevIndex].expirationDate ?? null;
      timeframes[prevIndex] = customerData;

      const newTf = formatCustomer(
        customer,
        true,
        customer.timeframes[prevIndex]
      );
      if (!newTf) return;
      customer.timeframes[prevIndex] = newTf;

      const curTf = getCurrentTimeframeIndex(customer, new Date());
      const newCustomer = jQuery.extend(true, {}, customer.timeframes[curTf]);
      newCustomer.timeframes = timeframes;
      customer = newCustomer;
    } else {
      const newCustomer = formatCustomer(customer, false);
      if (!newCustomer) return;
      customer = newCustomer;
    }

    if (!this.props.presetCustomer) {
      // perform checks for customers
      const newCustomerDict = jQuery.extend(true, {}, this.props.db.customers);
      newCustomerDict[this.props.customerKey] = customer;

      const conflicts = checkConflicts(newCustomerDict);
      if (conflicts) {
        infoDialog(this.props.openClose, conflicts[0], conflicts[1], "Go Back");
        return;
      }
    }

    if (this.props.presetCustomer) {
      firebase
        .database()
        .ref(`presetCustomers/${this.props.customerKey}`)
        .update(customer);
    } else {
      // TODO: Need to fix some issues with edit log before we let customers use it.
      /*
      if ("editLog" in customer) {
        var user = firebase.auth().currentUser;
        if (user) {
          var uid = user.uid;
          var storedUser = this.props.db.companyUsers[uid];
          var name = storedUser.name;
        }
        var date = new Date();

        var edit_log = customer["editLog"];

        delete customer.editLog;

        edit_log.push({
          modifiedBy: {
            name: name,
            uid: uid,
            date: date
          },
          snapshot: JSON.parse(JSON.stringify(customer))
        });

        customer["editLog"] = edit_log;
      }
      */

      updateFirebase(2, customer, this.props.customerKey);
    }

    // Update buy in calendar
    if (this.state.buyInCalendar) {
      updateMetaFirebase(
        { [this.props.customerKey]: this.state.buyInCalendar },
        "buyin_calendar"
      );
    }

    if (this.state.useBuyIn) {
      updateMetaFirebase(this.state.useBuyIn, "use_buyin_calendar");
    }

    this.setState(
      {
        changes: false,
        customer,
        newAltNames: [],
        newOtherCommissionsName: "",
        newDistributor: undefined,
        newRegionGroup: undefined,
        newBasketCustomer: "",
        customerNameChanged: false
      },
      () => {
        if (!this.props.presetCustomer) {
          this.recalculatePricingTable();
        }
        this.setTimeframe(prevIndex);
      }
    );

    // clear textboxes
    if (this.state.refs.newDistributor?.current) {
      this.state.refs.newDistributor.current.setState({ searchText: "" });
    }
    if (this.state.refs.newRegionGroup?.current) {
      this.state.refs.newRegionGroup.current.setState({ searchText: "" });
    }
  };

  openNonzeroSplitPopup = (d, scenario) => {
    var { customer } = this.state;
    const splitDict = customer.spendPerDistributor || {};
    const nonzeroSplit = d in splitDict && parseFloat(splitDict[d]);
    let nonzeroSplitPG = false;
    const pgSplitDict = customer.spendPerDistributorPG || {};
    for (const pg in pgSplitDict) {
      const n = d in pgSplitDict[pg] && parseFloat(pgSplitDict[pg][d]);
      nonzeroSplitPG = nonzeroSplitPG || n;
    }

    let isStillDistributor = false;
    for (const key in this.props.db.customers) {
      var customer = this.props.db.customers[key];
      if (
        customer.distributors &&
        customer.distributors.includes(this.props.customerKey)
      ) {
        isStillDistributor = true;
        break;
      }
    }

    // NB(daniel): Scenario 3 is no longer used. It is now handled by handleSaveChanges.

    // scenario: 1
    const deletingFirstReceiverError =
      "This first receiver cannot be deleted because it is assigned at least one nonzero % split value. \
      Ensure that all % splits for this first receiver are cleared out before deleting.";
    // scenario: 2
    const uncheckingDirectError =
      "This customer is currently marked as direct but is assigned at least one nonzero % split value. \
      Ensure that all % splits for this customer are cleared out before un-marking as direct.";
    // scenario: 3
    const uncheckingEitherError =
      "This customer is stored as a first receiver for at least one other customer. \
      Please resolve these dependencies before marking this customer as indirect.";

    let errorMessage = "";
    if (nonzeroSplit || nonzeroSplitPG) {
      errorMessage =
        scenario == 1 ? deletingFirstReceiverError : uncheckingDirectError;
    } else if (scenario == 3 && isStillDistributor) {
      errorMessage = uncheckingEitherError;
    }

    if (errorMessage != "") {
      this.props.openClose.setAppModal(
        "Unable to complete operation",
        <div className="centering">{errorMessage}</div>,
        <div className="centering">
          <Button
            label="Okay"
            onClick={() => {
              this.props.openClose.closeAppModal();
            }}
          />
        </div>
      );
      return true;
    }
    return false;
  };

  openNonzeroRegionSplitPopup = d => {
    const { customer } = this.state;
    const splitDict = customer.splitByRegion || {};
    const nonzeroSplit = splitDict && splitDict[d];

    const deletingRegionError =
      "This region cannot be deleted because it is assigned a nonzero % split value. \
      Ensure that the split for this region is cleared out before deleting.";

    let errorMessage = "";
    if (nonzeroSplit) {
      errorMessage = deletingRegionError;
    }

    if (errorMessage != "") {
      this.props.openClose.setAppModal(
        "Unable to complete operation",
        <div className="centering">{errorMessage}</div>,
        <div className="centering">
          <Button
            label="Okay"
            onClick={() => {
              this.props.openClose.closeAppModal();
            }}
          />
        </div>
      );
      return true;
    }
    return false;
  };

  triggerDistributorLoopError = () => {
    this.props.openClose.setAppModal(
      "Distributor Loop Error",
      <div className="centering">
        This operation will cause a cyclic distributor dependency between two or
        more customers in the system. Please resolve this issue before
        attempting to add this distributor.
      </div>,
      <div className="centering">
        <Button
          label="Okay"
          onClick={() => {
            this.props.openClose.closeAppModal();
          }}
        />
      </div>
    );
  };

  triggerDuplicateNameError = (
    proposedName,
    proposedType,
    otherType,
    otherCustomerName
  ) => {
    let displayText = `The proposed ${proposedType} name, "${proposedName}", matches an existing ${otherType} name. `;

    displayText += `This can be found under the customer: "${otherCustomerName}".`;

    displayText += " Please resolve this issue before saving changes.";

    this.props.openClose.setAppModal(
      "Duplicate Customer Name Error",
      <div className="centering">{displayText}</div>,
      <div className="centering">
        <Button
          label="Okay"
          onClick={() => {
            this.props.openClose.closeAppModal();
          }}
        />
      </div>
    );
  };

  handleDeleteSplit = (customer, d) => {
    if (customer.spendPerDistributor && d in customer.spendPerDistributor) {
      delete customer.spendPerDistributor[d];
    }

    const pgSplit = customer.spendPerDistributorPG;
    if (pgSplit) {
      for (const pg in pgSplit) {
        if (d in pgSplit[pg]) {
          delete customer.spendPerDistributorPG[pg][d];
        }
      }
    }

    return customer;
  };

  handleDeleteFromTable = (field, d) => {
    if (field == "distributors" && this.openNonzeroSplitPopup(d, 1)) {
      return;
    }
    if (field == "regionGroups" && this.openNonzeroRegionSplitPopup(d)) {
      return;
    }

    let { customer } = this.state;
    const list = customer[field];
    list.splice(list.indexOf(d), 1);
    customer[field] = list;

    if (field == "otherNames") {
      const tags = customer.otherNamesMap;
      if (tags) {
        const tagIndex = tags.map(tagObj => tagObj.tagName).indexOf(d);
        if (tagIndex >= 0) {
          tags.splice(tagIndex, 1);
        }
      }
      customer.otherNamesMap = tags;
    }

    if (field == "distributors") {
      customer = this.handleDeleteSplit(customer, d);
    } else if (field == "regionGroups") {
      if (customer.splitByRegion && d in customer.splitByRegion) {
        delete customer.splitByRegion[d];
      }
    }

    this.setState({
      customer,
      changes: true
    });
  };

  getTableJSX = (field, readOnly) => {
    const list = Array.from(
      this.state.customer && this.state.customer[field]
        ? this.state.customer[field]
        : []
    );

    if (field == "distributors" && this.state.customer.isDirect) {
      list.push(this.props.customerKey);
    }

    return list.map(d => {
      // depending on field, transform this key -> value
      let value = d;
      if (field == "distributors") {
        value = this.props.db.customers[d].name;
      } else if (field == "regionGroups") {
        value = this.props.db.meta.region_mapping.groups[d].name;
      }
      // NB(daniel): do not show delete button for the Direct customer
      return (
        <div className="rowRightAlign" style={{ width: "100%" }}>
          <div>
            <span
              style={{
                fontFamily: "Roboto Mono, monospace",
                backgroundColor: blue50
              }}>
              {value.replace(/ /g, "\u00a0")}
            </span>
          </div>
          {d != this.props.customerKey && field != "otherNames" && !readOnly && (
            <IconButton
              onClick={this.handleDeleteFromTable.bind(null, field, d)}
              style={{ marginTop: -16, marginLeft: 30 }}
              size="large">
              <ClearIcon color={grey700} />
            </IconButton>
          )}
        </div>
      );
    });
  };

  recalculatePricingTable = () => {
    const pricingData = jQuery.extend(true, {}, this.props.db.pricing);
    const customerData = jQuery.extend(true, {}, this.props.db.customers);
    customerData[this.props.customerKey] = this.state.customer;
    const allPricing = calculateRetailerPricing(
      pricingData,
      customerData,
      Object.keys(this.props.db.meta?.product_groups ?? {})
    );
    batchUpdatePricingFirebase(allPricing.pricing);
  };

  handleTagAddition = (field, d) => {
    const currentCustomer = { ...this.state.customer };
    const defaultTags =
      this.props.db.meta.defaultCustomerAltNameTags.map(x => x.tag) || [];

    // initialize tagged customer
    this.setState({ taggedCustomer: currentCustomer });

    this.props.openClose.setAppModal(
      "Add Tags for Alternative Name",
      <CustomerAltNameTags
        customer={currentCustomer}
        altname={d}
        updateTaggedCustomer={customer => {
          this.setState({ taggedCustomer: customer });
        }}
        db={this.props.db}
        defaultTags={defaultTags}
      />,
      <Stack>
        <Button
          label="Cancel"
          onClick={this.props.openClose.closeAppModal}
          color="error"
          variant="text"
        />
        ,
        <Button
          label="Save"
          onClick={debounce(() => {
            const { taggedCustomer } = this.state;
            this.setState({
              customer: taggedCustomer,
              taggedCustomer: {},
              changes: true
            });
            this.props.openClose.closeAppModal();
          }, 500)}
        />
      </Stack>
    );
  };

  handleNewRequest = (id, chosenRequest, index) => {
    const newCustomer = this.state.customer;
    newCustomer[id] = chosenRequest;
    this.setState({ customer: newCustomer, changes: true });
  };

  handleUpdateInput = (id, updatedValue) => {
    const newCustomer = this.state.customer;
    newCustomer[id] = updatedValue;
    this.setState({ customer: newCustomer, changes: true });
  };

  getDistributorList = customers => {
    // get a list of all current distributors
    // for the auto-completing field
    const distributorList = [];
    for (const key in customers) {
      const customer = customers[key];
      if (customer.isDistributor || customer.isDirect) {
        distributorList.push({ text: customer.name, value: key });
      }
    }
    distributorList.sort((x, y) => x.text.toLowerCase() < y.text.toLowerCase());
    this.setState({ distributorList });
  };

  getRegionGroupList = regionMapping => {
    const regionGroupList = [];
    if (regionMapping && regionMapping.groups) {
      for (const key in regionMapping.groups) {
        const text = regionMapping.groups[key].name;
        regionGroupList.push({ text, value: key });
      }
    }
    regionGroupList.sort((x, y) => x.text.toLowerCase() < y.text.toLowerCase());
    this.setState({ regionGroupList });
  };

  handleRevSource = (event, index, value) => {
    const { customer } = this.state;
    customer.revenue_sources = value;
    this.setState({ customer, changes: true });
  };

  setTimeframe = i => {
    if (i === undefined) return;

    const prevIndex =
      this.state.timeframeIndex ??
      getCurrentTimeframeIndex(this.state.customer, new Date());
    const customerData = jQuery.extend(true, {}, this.state.customer);
    delete customerData.timeframes;

    const { timeframes } = this.state.customer;
    const newCustomer = jQuery.extend(
      true,
      {},
      timeframes ? timeframes[i] : this.state.customer
    );
    newCustomer.timeframes = timeframes;

    this.setState({ timeframeIndex: i, customer: newCustomer });
  };

  runMountTasks = props => {
    this.getCustomerData(props.customerKey);
    if (!this.props.presetCustomer) {
      getJSXListsFirebase(
        props.db,
        allJSX => {
          this.setState({
            contactsJSX: allJSX.contactsJSX,
            customersJSX: allJSX.customersJSX
          });
        },
        null,
        false,
        true
      );
      if (!props.fixedHeight && this.state.refs.customerProfileRef?.current) {
        const ppHeight =
          document.documentElement.clientHeight -
          $(this.state.refs.customerProfileRef?.current).offset().top +
          $(window).scrollTop() -
          30;
        this.setState({
          ppHeight
        });
      }
      this.getDistributorList(props.db.customers);
      this.getRegionGroupList(props.db.meta.region_mapping);
      this.setState({
        newDistributor: "",
        newRegionGroup: "",
        customerNameChanged: false
      });
    }
    if (this.state.refs.newDistributor?.current) {
      this.state.refs.newDistributor.current.setState({ searchText: "" });
    }
    if (this.state.refs.newRegionGroup?.current) {
      this.state.refs.newRegionGroup.current.setState({ searchText: "" });
    }

    props.openClose.setDrawerClose(() => {
      if (this.state.changes) {
        this.setState({
          promptSave: true
        });
      } else {
        props.openClose.hideRightDrawer();
        this.setState({
          promptSave: false
        });
      }
    });
  };

  componentDidMount() {
    this.runMountTasks(this.props);
    firebase
      .database()
      .ref("presetCustomers")
      .on("value", snapshot => {
        if (snapshot.val()) {
          const presetCustomers = snapshot.val();
          this.setState({
            presetCustomers
          });
        }
      });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.runMountTasks(nextProps);
    this.setState({
      newAltNames: []
    });
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    const editPermission = this.props.presetCustomer
      ? true
      : this.props.db.permissions.includes("edit");

    const distributorsJSX = this.getTableJSX("distributors", !editPermission);
    const otherNamesJSX = this.getTableJSX("otherNames", !editPermission);
    const regionGroupsJSX = this.getTableJSX("regionGroups", !editPermission);

    if (this.props.presetCustomer) {
      var firebaseConsoleURL = `${getFirebaseConsoleURL()}/presetCustomers/${
        this.props.customerKey
      }`;
    } else {
      var firebaseConsoleURL = `${getFirebaseConsoleURL()}/companies/${
        this.props.db.companyid
      }/customers/${this.props.customerKey}`;
    }

    const tabProps = {
      db: this.props.db,
      editPermission,
      openClose: this.props.openClose,
      ppHeight: this.state.ppHeight,
      timeframeIndex: this.state.timeframeIndex,
      customerKey: this.props.customerKey,
      customer: this.state.customer,
      presetCustomers: this.state.presetCustomers,
      promptSave: this.state.promptSave,
      useBuyIn: this.state.useBuyIn,
      buyInCalendar: this.state.buyInCalendar,
      distributorList: this.state.distributorList,
      distributorsJSX,
      regionGroupList: this.state.regionGroupList,
      customersJSX: this.state.customersJSX,
      contactsJSX: this.state.contactsJSX,
      weeksToRetail: this.state.weeksToRetail,
      newAltNames: this.state.newAltNames,
      otherNamesJSX,
      regionGroupsJSX,
      getSaveJSX: this.getSaveJSX,
      handleTagAddition: this.handleTagAddition,
      handleDeleteFromTable: this.handleDeleteFromTable,
      handleTextChange: this.handleTextChange,
      handleSelectChange: this.handleSelectChange,
      handleNewRequest: this.handleNewRequest,
      handleUpdateInput: this.handleUpdateInput,
      handleRegionGroupChange: this.handleRegionGroupChange,
      handleRevSource: this.handleRevSource,
      setCustomerProfileState: this.setCustomerProfileState,
      openNonzeroRegionSplitPopup: this.openNonzeroRegionSplitPopup,
      openNonzeroSplitPopup: this.openNonzeroSplitPopup,
      handleDeleteSplit: this.handleDeleteSplit,
      customerNameChanged: this.state.customerNameChanged,
      setTimeframe: this.setTimeframe,
      changes: this.state.changes,
      newRegionGroup: this.state.newRegionGroup,
      newDistributor: this.state.newDistributor,
      styles
    };

    return (
      <div style={{ fontFamily: "Oxygen" }}>
        <ListItem
          sx={{ background: theme => theme.palette.readOnly.surface3 }}
          ContainerComponent="div">
          <ListItemText
            primary={
              !this.state.customer || this.state.customer.name === ""
                ? "None"
                : this.state.customer.name
            }
            secondary={
              <span>
                {" "}
                Customer Profile{" "}
                {this.state.customer.pnlGroup && (
                  <span>
                    {" "}
                    | Member of PnL Grouping:{" "}
                    <span style={{ color: green400 }}>
                      {this.state.customer.pnlGroup}
                    </span>
                  </span>
                )}
                {isUserCresicorEmployee() && (
                  <span>
                    {" "}
                    |{" "}
                    <a
                      href={firebaseConsoleURL}
                      target="_blank"
                      style={{ color: "#1b7acf" }}
                      rel="noreferrer">
                      View on Firebase Console
                    </a>
                  </span>
                )}
              </span>
            }
          />
          <ListItemSecondaryAction>
            {this.state.changes && editPermission && !this.props.readOnly ? (
              <Button
                label="Save Changes"
                onClick={this.handleSaveChanges}
                icon={<SaveIcon />}
              />
            ) : (
              <Button label="Save Changes" disabled icon={<SaveIcon />} />
            )}
          </ListItemSecondaryAction>
        </ListItem>
        {this.state.customer ? (
          <div>
            <Tabs
              indicatorColor="primary"
              value={this.state.tab}
              onChange={this.handleTabChange}
              sx={{
                background: theme => theme.palette.readOnly.surface3,
                "& .MuiTabs-indicator": {
                  height: "4px"
                }
              }}
              textColor="primary"
              variant="fullWidth">
              <Tab
                label="General Info"
                icon={<PublicIcon />}
                {...a11yProps(0)}
              />
              <Tab
                label="Manage Distribution"
                icon={<LocalShippingIcon />}
                {...a11yProps(1)}
              />
            </Tabs>

            <TabPanel value={this.state.tab} index={0}>
              <CustomerGeneralInfo {...tabProps} {...this.state.refs} />
            </TabPanel>
            <TabPanel value={this.state.tab} index={1}>
              <ManageDistribution {...tabProps} {...this.state.refs} />
            </TabPanel>
            {/*! this.props.presetCustomer && (
              <Tab label="Edit Log" value="c" icon={<AssignmentIcon />}>
                {!this.state.promptSave ? (
                  <CustomerEditLog
                    customerKey={this.props.customerKey}
                    db={this.props.db}
                  />
                ) : (
                  this.getSaveJSX()
                )}
              </Tab>
            ) */}
          </div>
        ) : (
          <div className="centering" style={{ margin: 16 }}>
            Customer no longer exists.
          </div>
        )}
      </div>
    );
  }
}
