import { repaymentLineTypes } from "deductions-constants/ReconciliationTypes";
import {
  ERPTransactionObject,
  InvoiceLineObject,
  InvoiceObject,
  RepaymentLineObject
} from "components/Deductions/models";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _, { round } from "lodash";
import { DbContextValues } from "contexts/Db";
import { generateNewRepaymentLineAndErrors } from "./applyRepaymentHelpers";

// true for testing purposes, false otherwise
const DEFAULT_SHOW_ERRORS = false;

export interface RepaymentLineErrors {
  exceedsMaxAmount: boolean;
  missingFundTypeKey: boolean;
  missingFundTypeAccount: boolean;
  amountZero: boolean;
}

export interface ApplyRepaymentErrorState {
  totalZero: boolean;
  noInvoiceLinesSelected: boolean;
  missingRepaymentKey: boolean;
  missingDeductionKey: boolean;
  missingInvoiceKey: boolean;
  totalExceedsAvailableAmount: boolean;
}

export interface ApplyRepaymentObjects {
  repayment?: ERPTransactionObject;
  deduction?: ERPTransactionObject;
  invoice?: InvoiceObject;
}

export interface MaxAmounts {
  [repaymentLineTypes.REPAYMENT]: number;
  [repaymentLineTypes.CREDIT_WRITE_OFF]: number;
}

export interface ApplyRepaymentState {
  repaymentLines: Record<string, RepaymentLineObject>; // maps invoiceLineKey: repayment Line
  showErrors: boolean;
  errors: ApplyRepaymentErrorState;
  currentObjects: ApplyRepaymentObjects;
  activityType: repaymentLineTypes;
  availableRepaymentAmount: number;
  repayableInvoiceLineAmounts: Record<string, MaxAmounts>;
  repayableInvoiceLines: string[];
  lineErrors: Record<string, RepaymentLineErrors>; // maps invoiceLineKey: errors
}

export const blankLineError: RepaymentLineErrors = {
  exceedsMaxAmount: false,
  missingFundTypeKey: false,
  missingFundTypeAccount: false,
  amountZero: false
};

export const blankRepaymentLine: RepaymentLineObject = {
  repaymentKey: "",
  deductionKey: "",
  invoiceKey: "",
  invoiceLineKey: "",
  amount: 0,
  fundTypeKey: "",
  fundTypeAccount: "",
  type: repaymentLineTypes.REPAYMENT,
  key: "",
  createdDate: "",
  createdUser: ""
};

export const initialErrorState: ApplyRepaymentErrorState = {
  totalZero: true,
  missingDeductionKey: true,
  missingRepaymentKey: true,
  missingInvoiceKey: true,
  noInvoiceLinesSelected: true,
  totalExceedsAvailableAmount: false
};

export const initialState: ApplyRepaymentState = {
  repaymentLines: {},
  showErrors: DEFAULT_SHOW_ERRORS,
  currentObjects: {
    repayment: undefined,
    deduction: undefined,
    invoice: undefined
  },
  repayableInvoiceLines: [],
  errors: initialErrorState,
  activityType: repaymentLineTypes.REPAYMENT,
  availableRepaymentAmount: 0.0,
  repayableInvoiceLineAmounts: {},
  lineErrors: {}
};

const slice = createSlice({
  name: "applyRepayment",
  initialState,
  reducers: {
    reset(state: ApplyRepaymentState) {
      state.errors = _.cloneDeep(initialErrorState);
      state.showErrors = false;
      state.lineErrors = {};
      state.currentObjects = {
        repayment: undefined,
        deduction: undefined,
        invoice: undefined
      };
      state.repaymentLines = {};
      state.availableRepaymentAmount = 0.0;
      state.repayableInvoiceLineAmounts = {};
      state.repayableInvoiceLines = [];
    },
    setShowErrors(state: ApplyRepaymentState, action: PayloadAction<boolean>) {
      state.showErrors = action.payload;
    },
    setActivityType(
      state: ApplyRepaymentState,
      action: PayloadAction<repaymentLineTypes>
    ) {
      const { repaymentLines } = state;

      const updatedRepaymentLines = {};
      Object.entries(repaymentLines).forEach(entry => {
        const [invoiceLineKey, repaymentLine] = entry;
        updatedRepaymentLines[invoiceLineKey] = {
          ...repaymentLine,
          type: action.payload
        };
      });

      state.repaymentLines = updatedRepaymentLines;
      state.activityType = action.payload;
    },
    setRepaymentObject(
      state: ApplyRepaymentState,
      action: PayloadAction<ERPTransactionObject | undefined>
    ) {
      const { key: repaymentKey = "" } = action.payload || {};
      const { repaymentLines = {} } = state;

      const updatedRepaymentLines = {};
      Object.entries(repaymentLines).forEach(entry => {
        const [invoiceLineKey, repaymentLine] = entry;
        updatedRepaymentLines[invoiceLineKey] = {
          ...repaymentLine,
          repaymentKey
        };
      });

      state.repaymentLines = updatedRepaymentLines;
      state.currentObjects.repayment = action.payload;
      state.errors.missingRepaymentKey = !action.payload;
    },
    setDeductionObject(
      state: ApplyRepaymentState,
      action: PayloadAction<ERPTransactionObject | undefined>
    ) {
      // RESET
      state.repaymentLines = {};
      state.lineErrors = {};

      state.currentObjects.deduction = action.payload;
      state.errors.missingDeductionKey = !action.payload;

      state.currentObjects.invoice = undefined;
      state.errors.missingInvoiceKey = true;
      state.errors.noInvoiceLinesSelected = true;
      state.showErrors = false;
    },
    setInvoiceObject(
      state: ApplyRepaymentState,
      action: PayloadAction<InvoiceObject | undefined>
    ) {
      state.repaymentLines = {};
      state.lineErrors = {};

      state.currentObjects.invoice = action.payload;
      state.errors.missingInvoiceKey = !action.payload;
      state.errors.noInvoiceLinesSelected = true;
      state.showErrors = false;
    },
    setRepayableInvoiceLines(
      state: ApplyRepaymentState,
      action: PayloadAction<string[]>
    ) {
      state.repayableInvoiceLines = action.payload;
    },
    setRepayableInvoiceLineAmounts(
      state: ApplyRepaymentState,
      action: PayloadAction<Record<string, MaxAmounts>>
    ) {
      state.repayableInvoiceLineAmounts = action.payload;
    },
    setAvailableRepaymentAmount(
      state: ApplyRepaymentState,
      action: PayloadAction<number>
    ) {
      state.availableRepaymentAmount = action.payload;
    },
    selectInvoiceLine(
      state: ApplyRepaymentState,
      action: PayloadAction<{
        invoiceLine: InvoiceLineObject;
        db: DbContextValues;
      }>
    ) {
      const { invoiceLine, db } = action.payload;
      const { key = "", invoiceLineKey = "" } = invoiceLine;

      const { repaymentLines, lineErrors } = state;

      if (
        (key || invoiceLineKey) &&
        !Object.keys(repaymentLines).includes(key || invoiceLineKey) &&
        !Object.keys(lineErrors).includes(key || invoiceLineKey)
      ) {
        const { newRepaymentLine, newLineError } =
          generateNewRepaymentLineAndErrors(state, invoiceLine, db);

        state.repaymentLines = {
          ...repaymentLines,
          [key || invoiceLineKey]: newRepaymentLine
        };

        state.lineErrors = {
          ...lineErrors,
          [key || invoiceLineKey]: newLineError
        };

        state.errors.noInvoiceLinesSelected = false;
      }
    },
    deselectInvoiceLine(
      state: ApplyRepaymentState,
      action: PayloadAction<InvoiceLineObject>
    ) {
      const { key = "", invoiceLineKey = "" } = action.payload;

      const { repaymentLines, lineErrors } = state;

      if (
        (key || invoiceLineKey) &&
        Object.keys(repaymentLines).includes(key || invoiceLineKey) &&
        Object.keys(lineErrors).includes(key || invoiceLineKey)
      ) {
        delete repaymentLines[key || invoiceLineKey];
        delete lineErrors[key || invoiceLineKey];

        state.repaymentLines = repaymentLines;
        state.lineErrors = lineErrors;

        if (Object.keys(repaymentLines).length === 0) {
          state.errors.noInvoiceLinesSelected = true;
        }
      }
    },
    selectAllInvoiceLines(
      state: ApplyRepaymentState,
      action: PayloadAction<DbContextValues>
    ) {
      const {
        repaymentLines,
        repayableInvoiceLines,
        lineErrors,
        currentObjects
      } = state;
      const db = action.payload;
      const { invoice: { invoiceLines = {} } = {} } = currentObjects;

      const newRepaymentLines = {};
      const newLineErrors = {};

      repayableInvoiceLines.forEach(invoiceLineKey => {
        const invoiceLine = invoiceLines[invoiceLineKey];
        if (
          invoiceLine &&
          !Object.keys(repaymentLines).includes(invoiceLineKey)
        ) {
          const { newRepaymentLine, newLineError } =
            generateNewRepaymentLineAndErrors(state, invoiceLine, db);

          newRepaymentLines[invoiceLineKey] = newRepaymentLine;
          newLineErrors[invoiceLineKey] = newLineError;
        }
      });

      state.repaymentLines = {
        ...repaymentLines,
        ...newRepaymentLines
      };
      state.lineErrors = {
        ...lineErrors,
        ...newLineErrors
      };

      state.errors.noInvoiceLinesSelected = false;
    },
    deselectAllInvoiceLines(state: ApplyRepaymentState) {
      state.errors.noInvoiceLinesSelected = true;
      state.lineErrors = {};
      state.repaymentLines = {};
    },
    setRepaymentLineAmount(
      state: ApplyRepaymentState,
      action: PayloadAction<{ amount: string; invoiceLineKey: string }>
    ) {
      const { repaymentLines } = state;
      const { amount, invoiceLineKey } = action.payload;

      // make negative for repayment lines
      const parsedAmount = parseFloat(amount);

      const updatedRepaymentLine = {
        ...repaymentLines[invoiceLineKey],
        amount: parsedAmount
      };

      state.repaymentLines = {
        ...repaymentLines,
        [invoiceLineKey]: updatedRepaymentLine
      };
    },
    checkAmountErrors(state: ApplyRepaymentState) {
      const {
        activityType,
        repaymentLines,
        repayableInvoiceLineAmounts,
        availableRepaymentAmount,
        lineErrors
      } = state;

      const totalAmount = Object.values(repaymentLines).reduce(
        (total, repaymentLine) => total + Math.abs(repaymentLine.amount),
        0.0
      );

      const updatedLineErrors = {};
      Object.entries(repaymentLines).forEach(entry => {
        const [invoiceLineKey, repaymentLine] = entry;
        const maxAmount =
          repayableInvoiceLineAmounts[invoiceLineKey][activityType];
        updatedLineErrors[invoiceLineKey] = {
          ...lineErrors[invoiceLineKey],
          exceedsMaxAmount:
            round(Math.abs(repaymentLine.amount), 2) >
            round(Math.abs(maxAmount), 2),
          amountZero: round(Math.abs(repaymentLine.amount), 2) === 0.0
        };
      });

      state.errors.totalZero = totalAmount === 0.0;
      state.errors.totalExceedsAvailableAmount =
        round(Math.abs(totalAmount), 2) >
        round(Math.abs(availableRepaymentAmount), 2);
      state.lineErrors = updatedLineErrors;
    },
    setRepaymentLineFundTypeKey(
      state: ApplyRepaymentState,
      action: PayloadAction<{
        fundTypeKey: string;
        db: DbContextValues;
        invoiceLineKey: string;
      }>
    ) {
      const { invoiceLineKey, fundTypeKey, db } = action.payload;
      // eslint-disable-next-line camelcase
      const { fundTypes = {} } = db.meta;
      const { accounts = {} } = db;
      const { repaymentLines, lineErrors } = state;

      const fundTypeAccount =
        accounts[fundTypes[fundTypeKey]?.accountKey]?.name || "";

      const updatedRepaymentLine: RepaymentLineObject = {
        ...repaymentLines[invoiceLineKey],
        fundTypeKey,
        fundTypeAccount
      };

      state.repaymentLines = {
        ...repaymentLines,
        [invoiceLineKey]: updatedRepaymentLine
      };

      state.lineErrors = {
        ...lineErrors,
        [invoiceLineKey]: {
          ...lineErrors[invoiceLineKey],
          missingFundTypeAccount: !fundTypeAccount,
          missingFundTypeKey: !fundTypeKey
        }
      };
    }
  }
});

export const {
  reset,
  setShowErrors,
  setRepaymentObject,
  setDeductionObject,
  setInvoiceObject,
  setActivityType,
  selectAllInvoiceLines,
  selectInvoiceLine,
  deselectAllInvoiceLines,
  deselectInvoiceLine,
  setRepaymentLineAmount,
  setRepaymentLineFundTypeKey,
  checkAmountErrors,
  setAvailableRepaymentAmount,
  setRepayableInvoiceLineAmounts,
  setRepayableInvoiceLines
} = slice.actions;

// export reducer as default import
export default slice.reducer;
