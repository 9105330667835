import { InvoiceLineObject } from "deductions-models/index";

export interface DisplayInvoiceLineObject extends InvoiceLineObject {
  fundType: string;
  productName: string;
  customerName: string;
  displayStatus: string;
  invoiceLineKey: string;

  clearedAmount: number;

  totalWriteOffAmount: number;
  creditedWriteOffAmount: number;
  openWriteOffAmount: number;

  totalDisputeAmount: number;
  repaidDisputeAmount: number;
  openDisputeAmount: number;

  resolvedAmount: number; // cleared + totalWriteOff + repaid
  openAmount: number; // pending + total dispute - repaid = pending + open dispute
}

export interface ResolveInvoiceLineObject extends DisplayInvoiceLineObject {
  activityAmount?: string;
  comment?: string;
}

export enum invoiceLineActions {
  CLEAR = "Clear Invoice Line",
  MANUAL_MATCH = "Manually Match to Promotion Line",
  DISPUTE = "Dispute Invoice Line"
}

export enum invoiceLineDependencies {
  INVOICE_LINE_PENDING = "INVOICE_LINE_PENDING",
  FULLY_CLEARED = "FULLY_CLEARED",
  PARTIALLY_CLEARED = "PARTIALLY_CLEARED"
}

export enum invoiceLineDependencyDescriptions {
  INVOICE_LINE_PENDING = "invoice line is pending",
  FULLY_CLEARED = "invoice line is fully cleared",
  PARTIALLY_CLEARED = "invoice line is at least partially cleared"
}
