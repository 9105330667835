import { useDb } from "contexts/Db";
import React, { useMemo } from "react";
import Select from "react-select";
import { add, differenceInCalendarYears, startOfYear, format } from "date-fns";
import { useGetMetadata, useGetForecastVersions } from "./api";
import {
  ForecastDetail,
  ForecastType,
  AnyDetails,
  ForecastVersion
} from "./types";
import { renderVersionToString, useGetDbName } from "./utilities";

const PLURALS = {
  product: "products",
  customer: "customers"
} as const;

export interface SelectOption {
  value: string;
  label: string;
}

export const useVersionSelectOptions = () => {
  const { companyid } = useDb();
  const [{ data }, refetch] = useGetForecastVersions({
    company_id: companyid
  });

  const options: SelectOption[] = useMemo(
    () =>
      (data?.versions ?? []).map((version: ForecastVersion) => {
        return {
          value: version.filename,
          label: renderVersionToString(version)
        };
      }),
    [data]
  );

  return { options, refetch };
};

export const useMetadataSelectOptions = <T extends AnyDetails>(
  subkey: keyof ForecastDetail<T>["key"],
  type: ForecastType,
  version?: string,
  versionNumber?: number
) => {
  const { companyid } = useDb();
  const getName = useGetDbName(PLURALS[subkey]);
  const [{ data: metadata }] = useGetMetadata({
    company_id: companyid,
    version,
    override_version: versionNumber
  });

  const ids = useMemo(() => {
    if (!metadata) {
      return [];
    }
    const mapping: { [key in keyof ForecastDetail<T>["key"]]: string[] } = {
      product: metadata?.products,
      customer: metadata?.customers?.[type]
    };
    return mapping[subkey] ?? [];
  }, [metadata, subkey, type]);

  const filterOptions: SelectOption[] = useMemo(() => {
    const names = ids
      .map(id => {
        const label = getName(id);
        return { value: id, label: label ?? id };
      })
      .sort((a, b) => (a.label < b.label ? -1 : 1));
    const total = { value: "Total", label: "Total" };
    names.splice(0, 0, total);
    return names;
  }, [getName, ids]);

  const yearOptions: SelectOption[] = useMemo(() => {
    if (!metadata) {
      return [];
    }

    const { table_start, table_end } = metadata;
    const tableStart = new Date(table_start);
    const tableEnd = new Date(table_end);
    const totalYears =
      Math.abs(differenceInCalendarYears(tableStart, tableEnd)) + 1;
    const years = Array.from({ length: totalYears }, (_, year) =>
      add(startOfYear(tableStart), { years: year })
    );
    return years.map(yearDate => ({
      value: format(yearDate, "yyyy-MM-dd"),
      label: yearDate.getFullYear().toString()
    }));
  }, [metadata]);

  return { filterOptions, yearOptions };
};

export const FilterSelector = ({
  name,
  options,
  value,
  onChange
}: {
  name: string;
  options: SelectOption[];
  value: SelectOption;
  onChange: (v: SelectOption) => void;
}) => {
  return (
    <Select
      menuPortalTarget={document.body}
      styles={{
        menuPortal: provided => ({ ...provided, zIndex: 9999 })
      }}
      value={value}
      onChange={onChange}
      getOptionLabel={(option: SelectOption) => option.label}
      getOptionValue={(option: SelectOption) => option.value}
      options={options}
      name={name}
      isClearable={false}
      isSearchable
      backspaceRemovesValue
    />
  );
};

export default FilterSelector;
