import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { invoiceLineStatuses } from "components/Deductions/constants/ReconciliationStatuses";
import { InvoiceLineObject } from "components/Deductions/models";
import { round } from "lodash";
import {
  updateInvoiceLines,
  updateInvoiceLinesErrors,
  updateProductCodes,
  updateSearchByProductName
} from "./addInvoiceLinesHelpers";

export interface NewInvoiceLineErrorState {
  missingCustomerKey: boolean;
  amountZero: boolean;
  missingEndDate: boolean;
}

const blankInvoiceLineObject: InvoiceLineObject = {
  productKey: "",
  productGroupKey: "",
  customerKey: "",
  amount: 0,
  invoiceLineKey: "",
  invoiceLineNumber: 0,
  invoiceKey: "",
  spendRate: 0,
  key: "",
  status: invoiceLineStatuses.PENDING,
  startDate: "",
  endDate: "",
  createdDate: "",
  createdUser: "",
  date: ""
};

const blankInvoiceLineError: NewInvoiceLineErrorState = {
  missingCustomerKey: true,
  amountZero: true,
  missingEndDate: true
};

export interface AddInvoiceLinesState {
  newInvoiceLines: InvoiceLineObject[];
  newInvoiceLinesErrors: NewInvoiceLineErrorState[];
  invoiceKey: string;
  showErrors: boolean;
  searchByProductName: boolean[];
  productCodes: string[];
}

const initialState: AddInvoiceLinesState = {
  newInvoiceLines: [{ ...blankInvoiceLineObject }],
  newInvoiceLinesErrors: [{ ...blankInvoiceLineError }],
  invoiceKey: "",
  showErrors: false,
  searchByProductName: [true],
  productCodes: [""]
};

const slice = createSlice({
  name: "addInvoiceLines",
  initialState,
  reducers: {
    resetAddInvoiceLines(state: AddInvoiceLinesState) {
      state.invoiceKey = "";
      state.newInvoiceLines = [{ ...blankInvoiceLineObject }];
      state.newInvoiceLinesErrors = [{ ...blankInvoiceLineError }];
      state.showErrors = false;
      state.searchByProductName = [true];
      state.productCodes = [""];
    },
    setShowErrors(state: AddInvoiceLinesState, action: PayloadAction<boolean>) {
      state.showErrors = action.payload;
    },
    setInvoiceKey(state: AddInvoiceLinesState, action: PayloadAction<string>) {
      const { newInvoiceLines } = state;
      const invoiceKey = action.payload;
      state.invoiceKey = invoiceKey;
      const updatedInvoiceLines = newInvoiceLines.reduce(
        (allUpdated, invoiceLine) => {
          return [
            ...allUpdated,
            {
              ...invoiceLine,
              invoiceKey
            }
          ];
        },
        []
      );
      state.newInvoiceLines = updatedInvoiceLines;
    },
    resetInvoiceLineNumbers(
      state: AddInvoiceLinesState,
      action: PayloadAction<{
        existingInvoiceLineCount?: number;
      }>
    ) {
      const { existingInvoiceLineCount = 0 } = action.payload;
      const { newInvoiceLines } = state;
      const updatedInvoiceLines: InvoiceLineObject[] = newInvoiceLines
        .map((invoiceLine, index) => {
          return {
            index: index + existingInvoiceLineCount,
            invoiceLine
          };
        })
        .reduce((allUpdated, invoiceLineEntry) => {
          const { index, invoiceLine } = invoiceLineEntry;
          return [
            ...allUpdated,
            {
              ...invoiceLine,
              invoiceLineNumber: index
            }
          ];
        }, []);

      state.newInvoiceLines = updatedInvoiceLines;
    },
    addInvoiceLines(
      state: AddInvoiceLinesState,
      action: PayloadAction<number>
    ) {
      const count = action.payload;
      const {
        newInvoiceLines,
        newInvoiceLinesErrors,
        invoiceKey,
        searchByProductName,
        productCodes
      } = state;
      const previousInvoiceLineCount = Object.values(newInvoiceLines).length;
      state.newInvoiceLines = [
        ...newInvoiceLines,
        ...[...Array(count).keys()].map(key => {
          return {
            ...blankInvoiceLineObject,
            invoiceKey,
            invoiceLineNumber: previousInvoiceLineCount + key
          };
        })
      ];
      state.newInvoiceLinesErrors = [
        ...newInvoiceLinesErrors,
        ...[...Array(count).keys()].map(_key => {
          return {
            ...blankInvoiceLineError
          };
        })
      ];
      state.searchByProductName = [
        ...searchByProductName,
        ...[...Array(count).keys()].map(_key => true)
      ];
      state.productCodes = [
        ...productCodes,
        ...[...Array(count).keys()].map(_key => "")
      ];
    },
    removeSingleInvoiceLine(
      state: AddInvoiceLinesState,
      action: PayloadAction<number>
    ) {
      const index = action.payload;
      updateInvoiceLines(state, index, undefined, true);
      updateInvoiceLinesErrors(state, index, undefined, true);
      updateSearchByProductName(state, index, undefined, true);
      updateProductCodes(state, index, undefined, true);
    },
    copyFieldDownwards(
      state: AddInvoiceLinesState,
      action: PayloadAction<keyof InvoiceLineObject>
    ) {
      const field = action.payload;
      const {
        newInvoiceLines,
        newInvoiceLinesErrors,
        searchByProductName,
        productCodes
      } = state;
      const firstInvoiceLine = newInvoiceLines[0];
      const value = firstInvoiceLine[field];

      state.newInvoiceLines = newInvoiceLines.map(invoiceLine => {
        return {
          ...invoiceLine,
          [field]: firstInvoiceLine[field]
        };
      });

      state.newInvoiceLinesErrors = newInvoiceLinesErrors.map(
        invoiceLineError => {
          return {
            ...invoiceLineError,
            ...(field === "amount"
              ? {
                  amountZero: !value
                }
              : {}),
            ...(field === "customerKey"
              ? {
                  missingCustomerKey: !value
                }
              : {}),
            ...(field === "endDate"
              ? {
                  missingEndDate: !value
                }
              : {})
          };
        }
      );

      if (field === "productKey") {
        state.searchByProductName = searchByProductName.map(
          () => searchByProductName[0]
        );
        state.productCodes = productCodes.map(() => productCodes[0]);
      }
    },
    setInvoiceLineProductKey(
      state: AddInvoiceLinesState,
      action: PayloadAction<{
        index: number;
        productKey: string;
        productCode?: string;
      }>
    ) {
      const { newInvoiceLines, newInvoiceLinesErrors } = state;
      const { index, productKey, productCode } = action.payload;
      if (index < newInvoiceLines.length && index >= 0) {
        const invoiceLineToUpdate: InvoiceLineObject = {
          ...newInvoiceLines[index],
          productKey
        };
        updateInvoiceLines(state, index, invoiceLineToUpdate);

        const invoiceLineErrorToUpdate: NewInvoiceLineErrorState = {
          ...newInvoiceLinesErrors[index]
        };
        updateInvoiceLinesErrors(state, index, invoiceLineErrorToUpdate);

        updateProductCodes(state, index, productCode || "");
      }
    },
    setInvoiceLineCustomerKey(
      state: AddInvoiceLinesState,
      action: PayloadAction<{
        index: number;
        customerKey: string;
      }>
    ) {
      const { newInvoiceLines, newInvoiceLinesErrors } = state;
      const { index, customerKey } = action.payload;
      if (index < newInvoiceLines.length && index >= 0) {
        const invoiceLineToUpdate: InvoiceLineObject = {
          ...newInvoiceLines[index],
          customerKey
        };
        updateInvoiceLines(state, index, invoiceLineToUpdate);

        const invoiceLineErrorToUpdate: NewInvoiceLineErrorState = {
          ...newInvoiceLinesErrors[index],
          missingCustomerKey: !customerKey
        };
        updateInvoiceLinesErrors(state, index, invoiceLineErrorToUpdate);
      }
    },
    setInvoiceLineAmount(
      state: AddInvoiceLinesState,
      action: PayloadAction<{
        index: number;
        amount: string;
      }>
    ) {
      const { newInvoiceLines, newInvoiceLinesErrors } = state;
      const { index, amount } = action.payload;
      const parsedAmount = parseFloat(amount) || 0.0;
      if (index < newInvoiceLines.length && index >= 0) {
        const invoiceLineToUpdate: InvoiceLineObject = {
          ...newInvoiceLines[index],
          amount: round(parsedAmount, 2)
        };
        updateInvoiceLines(state, index, invoiceLineToUpdate);

        const invoiceLineErrorToUpdate: NewInvoiceLineErrorState = {
          ...newInvoiceLinesErrors[index],
          amountZero: parsedAmount === 0.0
        };
        updateInvoiceLinesErrors(state, index, invoiceLineErrorToUpdate);
      }
    },
    setInvoiceLineSpendRate(
      state: AddInvoiceLinesState,
      action: PayloadAction<{
        index: number;
        spendRate: string;
      }>
    ) {
      const { newInvoiceLines } = state;
      const { index, spendRate } = action.payload;
      const parsedSpendRate = parseFloat(spendRate);
      if (index < newInvoiceLines.length && index >= 0) {
        const invoiceLineToUpdate: InvoiceLineObject = {
          ...newInvoiceLines[index],
          spendRate: parsedSpendRate
        };
        updateInvoiceLines(state, index, invoiceLineToUpdate);
      }
    },
    setInvoiceLineEndDate(
      state: AddInvoiceLinesState,
      action: PayloadAction<{
        index: number;
        dateString: string | undefined;
        date: Date | undefined;
      }>
    ) {
      const { newInvoiceLines, newInvoiceLinesErrors } = state;
      const { index, dateString = "", date } = action.payload;
      if (index < newInvoiceLines.length && index >= 0) {
        const invoiceLineToUpdate: InvoiceLineObject = {
          ...newInvoiceLines[index],
          endDate: date && date.getTime() ? date.toISOString() : dateString
        };
        updateInvoiceLines(state, index, invoiceLineToUpdate);

        const invoiceLineErrorToUpdate: NewInvoiceLineErrorState = {
          ...newInvoiceLinesErrors[index],
          missingEndDate: !(date && date.getTime())
        };
        updateInvoiceLinesErrors(state, index, invoiceLineErrorToUpdate);
      }
    },
    setSearchByProductName(
      state: AddInvoiceLinesState,
      action: PayloadAction<{
        index: number;
        value: boolean;
      }>
    ) {
      const { index, value } = action.payload;
      const { searchByProductName } = state;
      if (index < searchByProductName.length && index >= 0) {
        updateSearchByProductName(state, index, value);
      }
    }
  }
});

// export redux action
export const {
  resetAddInvoiceLines,
  setInvoiceKey,
  resetInvoiceLineNumbers,
  addInvoiceLines,
  removeSingleInvoiceLine,
  setInvoiceLineAmount,
  setInvoiceLineCustomerKey,
  setInvoiceLineEndDate,
  setInvoiceLineProductKey,
  setInvoiceLineSpendRate,
  setShowErrors,
  copyFieldDownwards,
  setSearchByProductName
} = slice.actions; // todo, actions

// export reducer as default import
export default slice.reducer;
