import { MenuItem, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import Card from "ui-library/Card";
import SelectField from "ui-library/Select";
import Button from "ui-library/Button";
import { httpsCallable } from "helpers/httpsCallable";
import {
  getUserDataFirebase,
  getValueFirebase,
  cloudRunFunctionURL
} from "helpers/Firebase";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useSnackbar } from "notistack";
import axios from "axios";

import { useCompanyMapping } from "./ScrapingContext";

const QUEUE_NAME = "test-queue";
const LOCATION = "us-central1";
const SCRAPING_URI = "/process_scraping";
const BACKUPS_URI = "/process_backups";

const useStyles = makeStyles(theme => ({
  pageContainer: {
    padding: 20,
    "& > *": {
      marginTop: 20
    }
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 20
  },
  heading: {
    fontFamily: "Ubuntu",
    padding: "10px 0px",
    color: theme.palette.primary.main
  },
  subheader: {
    fontSize: 20,
    color: theme.palette.primary.main,
    lineHeight: "48px",
    letterSpacing: "initial"
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  accordion: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    boxShadow: "none",
    borderRadius: 3,

    "&::before": {
      display: "none"
    }
  },
  accordionSummary: {
    maxHeight: 50,
    backgroundColor: "rgb(245, 245, 245)"
  }
}));

type ScrapingFormType = {
  companyNames: string[] | null;
};

function ScrapingForm({ companyNames }: ScrapingFormType) {
  const classes = useStyles();
  const [isScraping, setIsScraping] = useState(false);
  const [isProcessingFiles, setIsProcessingFiles] = useState(false);

  const { companyMapping: companies } = useCompanyMapping();

  const { enqueueSnackbar: snackbar } = useSnackbar();

  const enqueueSnackbar = useCallback(
    (message: string) => {
      snackbar(message, {
        variant: "default",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center"
        }
      });
    },
    [snackbar]
  );

  const formik = useFormik({
    initialValues: {
      company: "",
      companyId: ""
    },
    onSubmit: async values => {
      const isCompanyInactive =
        companies[values.companyId]?.[values.company]?.isInactive;

      if (isCompanyInactive) {
        return enqueueSnackbar(
          "Company mapping is inactive. Cannot start scraping"
        );
      }

      enqueueSnackbar("Data is being scraped. Please wait");
      await axios.post(
        `${cloudRunFunctionURL}/api/scraper_cloud_task_push_queue`,
        {
          data: {
            ...values,
            companyName: companyNames?.[values.companyId],
            queue: QUEUE_NAME,
            location: LOCATION,
            uri: SCRAPING_URI,
            service: "scraper"
          }
        }
      );

      return getValueFirebase(
        `companies/${values.companyId}/meta/backup_scraping/scraping_error`,
        async snapshot => {
          const scrapingError = snapshot.val();
          if (scrapingError) {
            enqueueSnackbar(scrapingError);
          }
        }
      );
    }
  });

  const handleFiles = useFormik({
    initialValues: {
      company: "",
      companyId: ""
    },
    onSubmit: async values => {
      const isCompanyInactive =
        companies[values.companyId]?.[values.company]?.isInactive;

      if (isCompanyInactive) {
        return enqueueSnackbar(
          "Company mapping is inactive. Cannot start scraping"
        );
      }

      const processTask = httpsCallable("scraper_cloud_task_push_queue");
      enqueueSnackbar("Data is being processed. Please wait");
      await processTask({
        ...values,
        companyName: companyNames?.[values.companyId],
        queue: QUEUE_NAME,
        location: LOCATION,
        uri: BACKUPS_URI,
        service: "scraper"
      });
      return null;
    }
  });

  useEffect(() => {
    getUserDataFirebase(() => {
      getValueFirebase(
        `companies/${formik.values.companyId}/meta/backup_scraping/is_scraping`,
        async snapshot => {
          const backupRetrivalLoadingStatus = snapshot.val();
          setIsScraping(prev => {
            if (prev && backupRetrivalLoadingStatus === false) {
              enqueueSnackbar("Scraping finished");
            }
            return backupRetrivalLoadingStatus;
          });
        }
      );

      getValueFirebase(
        `companies/${formik.values.companyId}/meta/backup_scraping/is_processing`,
        async snapshot => {
          const backupProcessingLoadingStatus = snapshot.val();
          setIsProcessingFiles(prev => {
            if (prev && backupProcessingLoadingStatus === false) {
              enqueueSnackbar("Processing finished");
            }
            return backupProcessingLoadingStatus;
          });
        }
      );
    }, null);
    // Formik references aren't referentially equal
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueSnackbar, formik.values.companyId]);

  return (
    <>
      <Typography variant="h6" className={classes.subheader}>
        Start scraping
      </Typography>
      <Card title="Login Details">
        <form onSubmit={formik.handleSubmit}>
          <div className={classes.rowContainer}>
            <SelectField
              labelId="backup-source"
              id="backup-source"
              value={formik.values.company}
              floatingLabelText="Backup Source"
              name="company"
              onChange={formik.handleChange}>
              <MenuItem value="kehe">KEHE</MenuItem>
              <MenuItem value="costco">Costco</MenuItem>
            </SelectField>
            <SelectField
              floatingLabelText="Select Company"
              value={formik.values.companyId}
              name="companyId"
              onChange={formik.handleChange}
              style={{ marginTop: 0 }}>
              {Object.entries(companies || {}).map(([companyId]) => (
                <MenuItem value={companyId} key={companyId}>
                  {companyNames?.[companyId]}
                </MenuItem>
              ))}
            </SelectField>
            <Button
              label={isScraping ? "Scraping Data" : "Start scraping"}
              type="submit"
              icon={<RefreshIcon className={isScraping ? "fa-spin" : ""} />}
              disabled={isScraping}
            />
          </div>
        </form>
      </Card>

      <Typography variant="h6" className={classes.subheader}>
        Process scraped files into Firebase
      </Typography>
      <Card title="Company Details">
        <form onSubmit={handleFiles.handleSubmit}>
          <div className={classes.rowContainer}>
            <SelectField
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={handleFiles.values.company}
              floatingLabelText="Backup Source"
              name="company"
              onChange={handleFiles.handleChange}>
              <MenuItem value="kehe">KEHE</MenuItem>
              <MenuItem value="costco">Costco</MenuItem>
            </SelectField>
            <SelectField
              floatingLabelText="Select Company"
              value={handleFiles.values.companyId}
              name="companyId"
              onChange={handleFiles.handleChange}
              style={{ marginTop: 0 }}>
              {Object.entries(companies || {}).map(([companyId]) => (
                <MenuItem value={companyId} key={companyId}>
                  {companyNames?.[companyId]}
                </MenuItem>
              ))}
            </SelectField>
            <Button
              label={isProcessingFiles ? "Processing Data" : "Start processing"}
              type="submit"
              icon={
                <RefreshIcon className={isProcessingFiles ? "fa-spin" : ""} />
              }
              disabled={isProcessingFiles}
            />
          </div>
        </form>
      </Card>
    </>
  );
}

export default ScrapingForm;
