import React from "react";

import {
  ComposedChart,
  Line,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceArea
} from "recharts";

import { graphPalette } from "helpers/ColorPalettes";

const { blue70, purple70, purple80, red70, green70 } = graphPalette;

class CustomTooltip extends React.Component {
  render() {
    const { active } = this.props;
    if (active) {
      const { payload, label } = this.props;
      let arpObj;
      let baseUnitsObj;
      let incrUnitsObj;
      for (let i = 0; i < payload.length; i++) {
        if (payload[i].name == "ARP") {
          arpObj = payload[i];
        } else if (payload[i].name == "Base Units") {
          baseUnitsObj = payload[i];
        } else if (payload[i].name == "Incr Units") {
          incrUnitsObj = payload[i];
        }
      }

      return (
        <div className="custom-tooltip">
          <p className="desc">{label}</p>
          <p className="desc" style={{ color: arpObj.color }}>
            {`${arpObj.name}: $${arpObj.value}`}
          </p>
          <p className="desc" style={{ color: baseUnitsObj.color }}>
            {`${baseUnitsObj.name}: ${baseUnitsObj.value}`}
          </p>
          <p className="desc" style={{ color: incrUnitsObj.color }}>
            {`${incrUnitsObj.name}: ${incrUnitsObj.value}`}
          </p>
          <p className="desc" style={{ color: blue70 }}>
            {`Lift: ${Math.round(
              (incrUnitsObj.value / baseUnitsObj.value) * 100
            )}%`}
          </p>
        </div>
      );
    }

    return null;
  }
}

class BumpChartTimeSeries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animation: true
    };
  }

  componentDidMount() {
    this.setState({
      animation: false
    });
  }

  render() {
    return this.props.data && this.props.data.length > 0 ? (
      <ResponsiveContainer width="100%" aspect={4}>
        <ComposedChart
          width={650}
          height={400}
          data={this.props.data}
          margin={{ top: 20, right: 10, bottom: 0, left: 15 }}
          syncId={this.props.syncId}>
          <XAxis dataKey="date" scale="point" domain={["dataMin", "dataMax"]} />
          <YAxis
            yAxisId="left"
            orientation="left"
            stroke={blue70}
            label={{
              value: "Weekly Units",
              angle: -90,
              position: "insideLeft",
              dy: 40,
              dx: -12
            }}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            stroke={green70}
            label={{
              value: "Average Retail Price ($)",
              angle: 90,
              position: "insideRight",
              dy: 70
            }}
          />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Line
            yAxisId="right"
            dataKey="ARP"
            type="monotone"
            stroke={green70}
            strokeWidth={2}
            isAnimationActive={this.state.animation}
          />
          <Area
            type="monotone"
            yAxisId="left"
            stackId="1"
            dataKey="Base Units"
            stroke={blue70}
            fill={blue70}
            isAnimationActive={this.state.animation}
          />
          <Area
            type="monotone"
            yAxisId="left"
            stackId="1"
            dataKey="Incr Units"
            fill={purple80}
            stroke={purple70}
            isAnimationActive={this.state.animation}
          />
          <ReferenceArea
            yAxisId="left"
            x1={
              this.props.startIndex != null &&
              this.props.startIndex in this.props.data
                ? this.props.data[this.props.startIndex].date
                : ""
            }
            x2={
              this.props.endIndex != null &&
              this.props.endIndex in this.props.data
                ? this.props.data[this.props.endIndex].date
                : ""
            }
            stroke={red70}
            strokeOpacity={0.3}
          />
        </ComposedChart>
      </ResponsiveContainer>
    ) : (
      <div className="centering" style={{ margin: 16 }}>
        Please upload time series data to view chart.
      </div>
    );
  }
}

export default BumpChartTimeSeries;
