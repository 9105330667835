const projectId = "cresicor-db1c9";
const cloudRunURLSuffix = "cloudrun-service-pgdg6htzjq-uc.a.run.app";
const forecastCloudRunURLSuffix = "forecast-rs-pgdg6htzjq-uc.a.run.app";
const cloudRunFunctionSuffix = "upload-service-pgdg6htzjq-uc.a.run.app";
const forecastServiceURLSuffix = "forecast-rs-pgdg6htzjq-uc.a.run.app";
const cresicorNextURLSuffix = "cresicor-next-pgdg6htzjq-uc.a.run.app";
const firebaseAPIKey = "AIzaSyA9-fvI93agMrR5gzltTaZnF0x1Ni2jtAM";
const firebaseAuthDomain = "cresicor-db1c9.firebaseapp.com";
const firebaseAppId = "1:266564883341:web:af838822a22c577af423c4";

export default {
  projectId,
  cloudRunURLSuffix,
  forecastCloudRunURLSuffix,
  cloudRunFunctionSuffix,
  forecastServiceURLSuffix,
  cresicorNextURLSuffix,
  firebaseAPIKey,
  firebaseAuthDomain,
  firebaseAppId
};
