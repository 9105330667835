import React from "react";

import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import DownloadIcon from "@mui/icons-material/GetApp";
import { CSVLink } from "react-csv";
import Button from "ui-library/Button";
import IconButton from "ui-library/IconButton";
import IconMenu from "ui-library/IconMenu";
import MenuItem from "ui-library/MenuItem";
import TextField from "ui-library/TextField";
import { Toolbar, ToolbarSeparator, ToolbarTitle } from "ui-library/Toolbar";
import ToolbarGroup from "ui-library/ToolbarGroup";
import { firebase } from "helpers/Firebase";
import { objToArray, sortByField } from "helpers/sortByDate";
import { escapeQuotes } from "helpers/DataProcessing";
import { common, green, yellow } from "@mui/material/colors";
import { Stack } from "@mui/material";
import PaginationMUITable from "../../tables/PaginationMUITable";
import CustomerProfile from "../../Customers/CustomerProfile";
import NewPresetCustomer from "./NewPresetCustomer";

const { black } = common;
const green700 = green["700"];

const yellow900 = yellow["900"];

class PropDataUpdatedCSVLink extends CSVLink {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data, headers, separator, uFEFF } = nextProps;
    this.setState({ href: this.buildURI(data, uFEFF, headers, separator) });
  }
}

class PresetCustomers extends React.Component {
  constructor(props) {
    super(props);
    this.csvLink = React.createRef();
    this.state = {
      customers: {},
      downloadData: [],
      headers: [
        { label: "Name", key: "name" },
        { label: "First Receivers", key: "distributorsField" },
        { label: "Assigned To", key: "assignedToField" },
        { label: "Contact", key: "contactName" },
        { label: "Alternative Names", key: "otherNamesField" },
        { label: "Region", key: "region" },
        { label: "Is Direct", key: "isDirect" },
        { label: "P&L Group", key: "pnlGroup" },
        { label: "Channel", key: "channel" },
        { label: "Class", key: "class" }
      ],
      showSearch: false,
      searchQuery: "",
      openFilePicker: false,
      modalOpen: false,
      reverseSort: false,
      multi: false,
      snackbar: false,
      allSelected: [],
      count: 0,
      pagelength: 20,
      customersJSX: [],
      tableBodyRenderKey: 0
    };
  }

  openFilePicker = jsx => {
    this.setState({
      openFilePicker: true
    });
    this.props.showDropDownWindow(jsx);
  };

  closeFilePicker = () => {
    this.setState({
      openFilePicker: false
    });
  };

  addCustomer = () => {
    this.props.openClose.setAppModal(
      "Add Preset Customer",
      <NewPresetCustomer openClose={this.props.openClose} db={this.props.db} />
    );
  };

  deleteCustomer = (event, rowData) => {
    const customerKey = rowData.key;
    this.props.openClose.setAppModal(
      "Delete Preset Customer",
      <div className="centering">
        Are you sure you want to delete this customer?
      </div>,

      <Stack>
        <Button
          label="Yes, I'm sure"
          color="error"
          variant="text"
          onClick={() => {
            this.props.openClose.closeAppModal();
            firebase.database().ref(`presetCustomers/${customerKey}`).remove();
          }}
        />
        <Button
          label="No, go back"
          variant="text"
          onClick={() => {
            this.props.openClose.closeAppModal();
          }}
        />
      </Stack>
    );
  };

  handleSearchButton = () => {
    this.setState({
      showSearch: true
    });
  };

  handleSearch = event => {
    this.handleInfiniteLoad(true);
    this.setState({
      searchQuery: event.target.value.toLowerCase(),
      allSelected: []
    });
  };

  toggleMulti = () => {
    this.setState({
      multi: !this.state.multi,
      allSelected: []
    });
  };

  prettyPrint = list => {
    if (Array.isArray(list) && list.length) {
      return [...new Set(list)]
        .reduce((acc, listItem) => {
          const sanitizedListItem = listItem
            .split("|")
            .map(subListItem => subListItem.trim());
          return [...acc, ...sanitizedListItem];
        }, [])
        .join(" | ");
    }
    return "";
  };

  handleProfileOpen = (event, rowData) => {
    const customerKey = rowData.key;
    this.props.showRightDrawer(
      <div>
        <CustomerProfile
          db={this.props.db}
          customerKey={customerKey}
          presetCustomer
          openClose={this.props.openClose}
        />
      </div>
    );
  };

  handleRowSelection = rows => {
    const allSelected = rows.map(row => row.key);
    this.setState({
      allSelected
    });
  };

  getCustomerList = (props, cutoff = true) => {
    if (this.state.sortField) {
      var filteredCustomers = sortByField(
        this.state.sortField,
        objToArray(props.db.customers),
        this.state.reverseSort
      );
    } else {
      var filteredCustomers = sortByField(
        "name",
        objToArray(props.db.customers),
        true
      );
    }
    let num = 0;
    const customerList = [];
    for (const key in this.state.customers) {
      const c = this.state.customers[key];
      const distributorNames = c.distributors
        ? c.distributors.map(key => {
            return this.state.customers[key]
              ? this.state.customers[key].name
              : key;
          })
        : [];
      if (c.isDirect) {
        distributorNames.push(c.name);
      }

      c.distributorsField = distributorNames
        ? this.prettyPrint(distributorNames)
        : "";
      if (c.isDistributor) {
        c.distributorsField = c.name; // if customer is distributor, make its field its own name
      }
      c.otherNamesField = c.otherNames ? this.prettyPrint(c.otherNames) : "";

      if (
        this.state.searchQuery === "" ||
        c.name.toLowerCase().indexOf(this.state.searchQuery) != -1 ||
        c.distributorsField.toLowerCase().indexOf(this.state.searchQuery) != -1
      ) {
        c.key = key;
        customerList.push(c);
        num++;
      }
      if (cutoff && num > this.state.count + this.state.pagelength) {
        break;
      }
    }

    return customerList;
  };

  getCustomersJSX = filteredCustomers => {
    const selectedSet = new Set(this.state.allSelected);
    const customersJSX = [];
    for (let i = 0; i < filteredCustomers.length; i++) {
      const c = filteredCustomers[i];
      const customerVal = {
        key: c.key,
        name: c.name,
        distributorsField: (
          <div
            style={
              c.isDistributor
                ? { color: green700 }
                : c.isDirect
                ? { color: yellow900 }
                : {}
            }>
            {c.distributorsField}
          </div>
        ),
        otherNamesField: c.otherNamesField
      };
      if (selectedSet.has(c.key)) {
        customerVal.tableData = { checked: true };
      }
      customersJSX.push(customerVal);
    }

    return customersJSX;
  };

  getCustomerData = () => {
    const customerList = this.getCustomerList(this.props);
    const customerLength = customerList.length;
    const customerJSX = this.getCustomersJSX(customerList);

    return [customerLength, customerJSX];
  };

  getDownloadData = () => {
    const customerList = this.getCustomerList(this.props, false);
    this.setState({ downloadData: customerList.map(escapeQuotes) }, () => {
      this.csvLink.current.link.click();
    });
  };

  handleInfiniteLoad = reset => {
    const count = reset
      ? this.state.pagelength
      : this.state.count + this.state.pagelength;
    this.setState({ count });
  };

  componentDidMount() {
    const tbHeight =
      document.documentElement.clientHeight -
      $(this.refs.table).offset().top +
      200;
    this.setState({
      tbHeight,
      snackbar: true
    });
    firebase
      .database()
      .ref("presetCustomers")
      .on("value", snapshot => {
        if (snapshot.val()) {
          const presetCustomers = snapshot.val();
          this.setState({ customers: presetCustomers });
        }
      });
  }

  render() {
    const [customerLength, customersJSX] = this.getCustomerData();
    const actionParams = {
      openProfile: rowData => ({
        onClick: this.handleProfileOpen
      }),
      delete: rowData => ({
        onClick: this.deleteCustomer
      })
    };
    const columns = [
      {
        title: "Name",
        field: "name",
        width: 300,
        cellStyle: { paddingLeft: 50 },
        headerStyle: { paddingLeft: 50 }
      },
      { title: "First Receivers", field: "distributorsField", width: 300 },
      {
        title: "Alternative Names",
        field: "otherNamesField",
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          maxWidth: 300
        },
        multiple: true,
        unPrettify: true
      }
    ];

    return (
      <div>
        <Toolbar style={{ justifyContent: "space-between" }}>
          <ToolbarGroup>
            <ToolbarTitle text="Preset Customers" style={{ color: black }} />
            <ToolbarSeparator flexItem />
            <IconButton
              onClick={this.handleSearchButton}
              tooltip="Search"
              size="large">
              <SearchIcon />
            </IconButton>
            {this.state.showSearch && (
              <TextField
                placeholder="Search..."
                onChange={this.handleSearch}
                style={{ marginTop: 0 }}
              />
            )}
          </ToolbarGroup>
          <ToolbarGroup>
            <IconMenu
              iconButtonElement={
                <IconButton tooltip="Settings" size="large">
                  <SettingsIcon />
                </IconButton>
              }
              onChange={this.props.handleAdditionalDataDisplay}>
              <MenuItem
                children={<Typography variant="subtitle1">Export</Typography>}
                disabled
              />
              <MenuItem
                value={0}
                children="Download Customers"
                leftIcon={<DownloadIcon />}
                onClick={this.getDownloadData}
              />
            </IconMenu>
            <IconButton
              onClick={this.addCustomer}
              tooltip="Add Customer"
              size="large">
              <AddIcon />
            </IconButton>
          </ToolbarGroup>
        </Toolbar>
        <div
          ref="table"
          style={{ height: this.state.tbHeight, overflow: "auto" }}>
          <PaginationMUITable
            data={customersJSX}
            columns={columns}
            allLoaded={this.state.count >= customerLength}
            selection={this.state.multi}
            handleProfileOpen={this.handleProfileOpen}
            handleInfiniteLoad={this.handleInfiniteLoad}
            handleRowSelection={this.handleRowSelection}
            actionParams={actionParams}
          />
        </div>
        <PropDataUpdatedCSVLink
          data={this.state.downloadData}
          headers={this.state.headers}
          filename="Preset Customers Export.csv"
          className="hidden"
          ref={this.csvLink}
          target="_blank"
        />
      </div>
    );
  }
}

export default PresetCustomers;
