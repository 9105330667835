export default interface TransactionConfig {
  deductionsIncomingPositive: boolean;
  accountingSource?: string;
  // displayDeductionsPositive: boolean;
}

export const DefaultTransactionConfig: TransactionConfig = {
  deductionsIncomingPositive: true
  // displayDeductionsPositive: false,
};
