export enum UploadBackupActionType {
  RESET = "RESET_BACKUP_UPLOAD",
  CHANGE_UPLOAD_STEP = "CHANGE_BACKUP_UPLOAD_STEP",
  ADD_BACKUP_FILES = "ADD_BACKUP_FILES",
  UPDATE_FILE_PAGE_COUNTS = "UPDATE_BACKUP_FILE_PAGE_COUNTS",
  REMOVE_BACKUP_FILE = "REMOVE_BACKUP_FILE",
  SET_VIEWED_FILE = "SET_VIEWED_BACKUP_FILE",
  ADD_INVOICE_OBJECT = "ADD_INVOICE_OBJECT",
  REMOVE_INVOICE_OBJECT = "REMOVE_INVOICE_OBJECT",
  SET_INVOICE_ASSIGN_PAGE_FIELD = "SET_INVOICE_ASSIGN_PAGE_FIELD",
  SET_INVOICE_METADATA_FIELD = "SET_INVOICE_METADATA_FIELD",
  SET_FAKE_INVOICE_MODE = "SET_FAKE_INVOICE_MODE"
}

export type UploadBackupAction = {
  type: UploadBackupActionType;
  [arg: string]: any;
};
