import React from "react";
import PropTypes from "prop-types";

import PollIcon from "@mui/icons-material/Poll";
import {
  Area,
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from "recharts";

import { displayMoney } from "helpers/DataProcessing";

import { graphPalette } from "helpers/ColorPalettes";
import { grey } from "@mui/material/colors";

const grey500 = grey["500"];
const { blue80, pink80, blue70, pink70 } = graphPalette;

class CustomTooltip extends React.Component {
  render() {
    const { active } = this.props;
    if (active) {
      const { payload, label, monthTopCustomer } = this.props;
      const topCustomer = monthTopCustomer[label];
      let topCustomerRev;
      let otherRev;
      let totalRev;
      let topCustomerColor;
      let totalColor;
      for (let i = 0; i < payload.length; i++) {
        if (payload[i].name == "Top Customer Revenue") {
          topCustomerRev = payload[i].value;
          topCustomerColor = payload[i].color;
        } else if (payload[i].name == "Other Revenue") {
          otherRev = payload[i].value;
          totalColor = payload[i].color;
        }
        totalRev = otherRev + topCustomerRev;
      }

      return (
        <div className="custom-tooltip">
          <p className="desc">{label}</p>
          <p className="desc" style={{ color: totalColor }}>
            {`Total Revenue: ${displayMoney(totalRev)}`}
          </p>
          <p className="desc" style={{ color: topCustomerColor }}>
            {`${topCustomer} (Highlighted Customer): ${displayMoney(
              topCustomerRev
            )}`}
          </p>
        </div>
      );
    }

    return null;
  }
}

CustomTooltip.propTypes = {
  type: PropTypes.string,
  payload: PropTypes.array,
  label: PropTypes.string
};

class RevenueTimeSeries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const monthTopCustomer = {};
    for (const x of this.props.data) {
      monthTopCustomer[x.month] = x["Top Customer"];
    }
    return (
      <div>
        {this.props.data && this.props.data.length > 0 ? (
          <ResponsiveContainer
            height="100%"
            width="100%"
            aspect={this.props.aspect || 2}>
            <AreaChart
              data={this.props.data}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
              <XAxis dataKey="month" />
              <YAxis tickFormatter={d3.format(".2s")} />
              <CartesianGrid stroke="#ccc" />
              <Tooltip
                content={<CustomTooltip monthTopCustomer={monthTopCustomer} />}
              />
              <Area
                type="monotone"
                dataKey="Top Customer Revenue"
                stackId="1"
                stroke={blue70}
                fill={blue80}
              />
              <Area
                type="monotone"
                dataKey="Other Revenue"
                stackId="1"
                stroke={pink70}
                fill={pink80}
              />
            </AreaChart>
          </ResponsiveContainer>
        ) : (
          <div style={{ height: 350, textAlign: "center", paddingTop: 150 }}>
            No revenue data matches selected filters. <br />
            <PollIcon style={{ color: grey500, fontSize: 60 }} />
          </div>
        )}
      </div>
    );
  }
}

export default RevenueTimeSeries;
