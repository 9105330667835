import React, { useCallback, useMemo, useState } from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import {
  Button,
  TablePagination,
  TableSortLabel,
  Typography
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import moment from "moment";
import CollapsibleTableFilter, { FilterConfig } from "./CollapsibleTableFilter";
import CollapsibleTableSummary, {
  CollapsibleTableSummaryConfig
} from "./CollapsibleTableSummary";
import CollapsibleTableRow, {
  CollapsibleTableRowControls
} from "./CollapsibleTableRow";

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    backgroundColor: theme.palette.grey[300]
  },
  noData: {
    textAlign: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  nowrap: {
    whiteSpace: "nowrap"
  },
  flex: {
    display: "flex"
  },
  clearAllButton: {
    backgroundColor: theme.palette.action.disabled,
    whiteSpace: "nowrap"
  },
  summaryContainer: {
    backgroundColor: theme.palette.background.default,
    width: "100%",
    marginLeft: theme.spacing(1),
    // marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

export interface ColumnConfigOptions<T> {
  render?: (
    data: T[keyof T],
    row: T,
    index: number
  ) => string | JSX.Element | number;
  width?: number | string;
  filter?: FilterConfig;
  label?: string;
  name?: string;
  sorting?: {
    enabled: boolean;
    customSort?: (a: T, b: T) => number;
  };
  align?: "left" | "right" | "inherit" | "center" | "justify" | undefined;
  verticalAlign?: "top" | "center" | "bottom" | undefined;
}

export type ColumnConfig<T> = Map<keyof T, ColumnConfigOptions<T>>;

export type FilterRecord =
  | {}
  | Record<
      string,
      { max?: Date | number; min?: Date | number; value?: string }
    >;

export type SortState = { orderBy?: string; sortDir?: "asc" | "desc" };

interface CollapsibleTableProps<T> {
  data: T[];
  columnConfig: ColumnConfig<T>;
  index?: keyof T;
  renderCollapse?: (obj: T) => JSX.Element;
  expandProfile?: (obj: T) => void;
  pagination?: {
    enabled: boolean;
    pageSize: number;
    rowsPerPageOptions: number[];
  };
  rowControls?: CollapsibleTableRowControls<T>[];
  onRowClick?: (event, rowData: T) => void;
  fontSize?: string;
  withBorder?: boolean;
  activeFilters?: FilterRecord;
  sortState?: SortState;
  setActiveFilters?: (payload: FilterRecord) => void;
  setSortState?: (payload: SortState) => void;
  clearAllEnabled?: boolean;
  resetToDefaults?: () => void;
  maxWidth?: string;
  maxHeight?: string;
  summaryConfig?: CollapsibleTableSummaryConfig<T>;
  summaryStyles?: {
    leftIndent: boolean;
  };
}

const DEFAULT_PAGE = 0;

export default function CollapsibleTable<T>(props: CollapsibleTableProps<T>) {
  const classes = useStyles();
  const {
    data,
    columnConfig,
    index,
    renderCollapse,
    expandProfile,
    pagination,
    rowControls,
    onRowClick,
    fontSize,
    withBorder,
    activeFilters,
    sortState,
    setActiveFilters,
    setSortState,
    clearAllEnabled,
    resetToDefaults,
    maxHeight,
    maxWidth,
    summaryConfig,
    summaryStyles
  } = props;
  const trackingIndex = index || "key";
  const [currentPage, setCurrentPage] = useState<number>(DEFAULT_PAGE);
  const [pageSize, setPageSize] = useState<number>(pagination?.pageSize || 0);
  const { orderBy = "", sortDir = "asc" } = sortState || {
    orderBy: "",
    sortDir: "asc"
  };

  const toggleSort = key => {
    if (sortState && setSortState) {
      const newSortState: SortState = { ...sortState };
      if (key === orderBy) {
        newSortState.sortDir = sortDir === "asc" ? "desc" : "asc";
      } else {
        newSortState.orderBy = key;
      }
      setSortState(newSortState);
    }
  };

  const configValues = Array.from(columnConfig, ([key, value]) => ({
    value,
    key
  }));

  const defaultSort = useCallback(
    (itemA: T, itemB: T) => {
      // in default sort, we set a falsey value with string "z" for sorting purposes
      const valueA = (itemA[orderBy] ?? "z").toUpperCase?.() || itemA[orderBy];
      const valueB = (itemB[orderBy] ?? "z").toUpperCase?.() || itemB[orderBy];
      if (valueA < valueB) {
        return -1;
      }
      if (valueA > valueB) {
        return 1;
      }
      return 0;
    },
    [orderBy]
  );

  const getSortedData = useCallback(
    (rawData: T[]): T[] => {
      const asc = sortDir === "asc";
      const sort =
        columnConfig.get(orderBy as keyof T)?.sorting?.customSort ||
        defaultSort;
      const sortedData = [...rawData];
      sortedData.sort((a, b) => (asc ? sort(a, b) : sort(b, a)));
      return sortedData;
    },
    [columnConfig, defaultSort, orderBy, sortDir]
  );

  const onFilter = useCallback(
    (key, value) => {
      if (activeFilters && setActiveFilters) {
        setActiveFilters({
          ...activeFilters,
          [key]: {
            value
          }
        });
      }
      setCurrentPage(DEFAULT_PAGE);
    },
    [activeFilters, setActiveFilters]
  );

  const onNumericFilter = (key: keyof T, min: number, max: number): void => {
    if (activeFilters && setActiveFilters) {
      setActiveFilters({
        ...activeFilters,
        [key]: {
          min,
          max
        }
      });
    }
    setCurrentPage(DEFAULT_PAGE);
  };

  const onDateFilter = useCallback(
    (key: keyof T, min: Date, max: Date): void => {
      if (activeFilters && setActiveFilters) {
        setActiveFilters({
          ...activeFilters,
          [key]: {
            min,
            max
          }
        });
      }
      setCurrentPage(DEFAULT_PAGE);
    },
    [activeFilters, setActiveFilters]
  );

  const getFilteredData = useCallback(
    (rawData: T[]): T[] => {
      let filteredData = [...rawData];
      if (activeFilters) {
        Object.keys(activeFilters).forEach(key => {
          const conf = columnConfig.get(key as keyof T);
          if (conf) {
            filteredData = filteredData.filter(item => {
              if (conf.filter?.daterange) {
                const { min, max } = activeFilters[key];
                if (!min || !max) {
                  return true;
                }
                const date = moment(item[key]);
                return date.isBetween(moment(min), moment(max));
              }
              if (conf.filter?.range) {
                const { min, max } = activeFilters[key];
                return (item[key] || 0) >= min && (item[key] || 0) <= max;
              }
              const { value } = activeFilters[key];
              if (conf.filter?.textFilterOverride) {
                return conf.filter?.textFilterOverride(value, item);
              }
              return (item[key] ?? "")
                .toLowerCase()
                .includes(value.toLowerCase());
            });
          }
        });
      }
      return filteredData;
    },
    [activeFilters, columnConfig]
  );

  const tableData = useMemo(() => {
    const sortedData = orderBy === "" ? data : getSortedData(data);
    const filteredData = getFilteredData(sortedData);
    return filteredData;
  }, [getFilteredData, orderBy, data, getSortedData]);

  const start = pageSize * currentPage;
  const slicedData = pagination?.enabled
    ? tableData.slice(start, start + pageSize)
    : tableData;

  return (
    <>
      {summaryConfig && (
        <div
          className={classes.summaryContainer}
          style={!summaryStyles?.leftIndent ? { marginLeft: 0 } : {}}>
          <CollapsibleTableSummary
            summaryConfig={summaryConfig}
            data={tableData}
          />
        </div>
      )}
      <TableContainer component={Paper} style={{ maxHeight, maxWidth }}>
        <Table
          size="small"
          aria-label="collapsible table"
          stickyHeader={!!maxHeight}>
          <TableHead className={classes.header}>
            <TableRow>
              {clearAllEnabled && (
                <TableCell>
                  <Button
                    size="small"
                    className={classes.clearAllButton}
                    variant="contained"
                    onClick={() => {
                      setActiveFilters?.({});
                      resetToDefaults?.();
                    }}>
                    Reset Filters
                  </Button>
                </TableCell>
              )}
              {!clearAllEnabled && <TableCell />}
              {rowControls && <TableCell>&nbsp;</TableCell>}
              {configValues.map(({ value: col, key }) => {
                const label = (
                  <div className={classes.nowrap}>{col.name || col.label}</div>
                );
                return (
                  <TableCell key={col.name || col.label} align={col.align}>
                    <div className={classes.flex}>
                      {col?.sorting?.enabled ? (
                        <TableSortLabel
                          onClick={() => toggleSort(key)}
                          active={orderBy === key}
                          direction={sortDir}>
                          {label}
                        </TableSortLabel>
                      ) : (
                        label
                      )}
                      {col?.filter?.enabled && (
                        <CollapsibleTableFilter
                          onFilter={onFilter}
                          onNumericFilter={onNumericFilter}
                          onDateFilter={onDateFilter}
                          headerKey={key}
                          filterConfig={col.filter}
                          activeFilters={activeFilters}
                        />
                      )}
                    </div>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {slicedData.map((row, rowIndex) => (
              <React.Fragment key={row[trackingIndex as string]}>
                <CollapsibleTableRow<T>
                  withBorder={withBorder}
                  fontSize={fontSize}
                  rowControls={rowControls}
                  row={row}
                  columnConfig={columnConfig}
                  renderCollapse={renderCollapse}
                  expandProfile={expandProfile}
                  onRowClick={onRowClick}
                  isLast={rowIndex === slicedData.length - 1}
                  rowIndex={rowIndex}
                />
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
        {tableData.length === 0 && (
          <div className={classes.noData}>
            <Typography>No data found.</Typography>
          </div>
        )}
      </TableContainer>
      {pagination?.enabled && tableData.length > pageSize && (
        <TablePagination
          component="div"
          rowsPerPageOptions={pagination.rowsPerPageOptions}
          count={tableData.length}
          rowsPerPage={pageSize}
          page={currentPage}
          onPageChange={(_ev, page) => {
            setCurrentPage(page);
          }}
          onRowsPerPageChange={ev => {
            setPageSize(parseInt(ev.target.value, 10));
          }}
        />
      )}
    </>
  );
}
