import { connect } from "react-redux";
import React from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import DatePicker from "ui-library/DatePicker";
import Button from "ui-library/Button";
import RemoveIcon from "@mui/icons-material/Remove";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import RestoreIcon from "@mui/icons-material/Restore";
import UndoIcon from "@mui/icons-material/Undo";
import IconButton from "ui-library/IconButton";
import MenuItem from "ui-library/MenuItem";
import Select from "ui-library/Select";
import TextField from "ui-library/TextField";
import Tooltip from "@mui/material/Tooltip";

import { getCurrentTimeframe } from "components/Customers/CustomerUtils";
import { getTimesJSX } from "helpers/Time";
import { objToArray, sortByField } from "helpers/sortByDate";
import { firebase } from "helpers/Firebase";
import { baseURL } from "components/ForecastRs/routes";
import { liftEnabled } from "helpers/Permissions";
import Loading from "react-loading";
import axios from "axios";
import { teal, green, grey, common, red } from "@mui/material/colors";
import { updateField, incrementLine, clearLine } from "./actions";
import EntryCard from "./EntryCard";
import {
  SelectProductGroup,
  SelectProductGroupLarge
} from "./SelectProductGroup";

const teal400 = teal["400"];
const green500 = green["500"];
const grey500 = grey["500"];
const grey700 = grey["700"];
const { black } = common;
const { white } = common;
const red500 = red["500"];

const MAX_PRODUCT_GROUPS = 50;

const styles = {
  header: {
    marginLeft: "-15px",
    marginRight: "-50px",
    fontSize: 20,
    color: black
  },
  subheader: {
    color: "rgba(0, 0, 0, 0.54)",
    lineHeight: "48px",
    fontSize: 15
  },
  divider: {
    marginTop: 30,
    marginBottom: 30,
    width: "50%"
  },
  paper: {
    padding: "16px 32px",
    marginBottom: 20,
    fontFamily: "Oxygen"
  },
  customSelect: {
    height: 300,
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: grey700,
    overflow: "auto"
  }
};

const Subheader = props => <div {...props} />;

function round(n) {
  return Math.round(n * 100) / 100;
}

class AddLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectProductGroups: [],
      customProducts: {},
      selectProductsJSX: [],
      typeFieldsJSX: [],
      selectedType: null,
      spendTooLow: "",
      loading: false
    };
  }

  getNumProductGroups = () => {
    const numProductGroups = Object.values(
      this.props.db.meta?.product_groups
    ).length;
    return numProductGroups;
  };

  isValid = value => {
    return (
      value == 0 || parseFloat(value) || value === undefined || value === null
    );
  };

  // Used for all fields where an error can display.
  // Includes all fields into which a number can be entered.
  // (e.g., if enter "$3" for a number field, displays "Please enter a valid number.")
  getErrorText = id => {
    const typeFields =
      this.props.db.meta.fundTypes?.[this.props.lineState.type]
        ?.promCreationFields ?? [];
    const potentialCostConflict =
      typeFields.includes("rate") && typeFields.includes("lumpsum");
    const spl = id.split(".");

    const fieldRequiredError = "This field is required";
    const eitherNoneOrBoth =
      "Either none or both of these fields should be filled in.";
    const empty =
      this.props.lineState.spendRate ||
      this.props.lineState.totalExpSales ||
      this.props.lineState.lumpSumSpend
        ? null
        : " "; // necessary for red line to show up
    if (spl.length == 1) {
      return this.props.promState[spl[0]] === "" ? fieldRequiredError : "";
    }
    if (spl.length == 2) {
      if (
        this.props.lineState.rateError &&
        (spl[1] == "spendRate" || spl[1] == "totalExpSales")
      ) {
        return potentialCostConflict ? empty : fieldRequiredError;
      }
      if (this.props.lineState.lumpSumError && spl[1] == "lumpSumSpend") {
        return potentialCostConflict ? empty : fieldRequiredError;
      }
      return this.props.promState[spl[0]][spl[1]] === ""
        ? fieldRequiredError
        : !this.isValid(this.props.promState[spl[0]][spl[1]])
        ? "Please enter a valid number."
        : "";
    }
  };

  selectCustomProduct = (productKey, event) => {
    const isInputChecked = event.target.checked;
    // set default customProducts obj
    const customProducts = {};
    if (this.props.lineState.product) {
      for (let i = 0; i < this.props.lineState.product.length; i++) {
        customProducts[this.props.lineState.product[i]] = true;
      }
    }

    customProducts[productKey] = isInputChecked;
    const products = [];
    for (const key in customProducts) {
      if (customProducts[key]) {
        products.push(key);
      }
    }
    this.props.dispatch(
      updateField(this.props.db, `${this.props.line}.product`, products)
    );
    this.props.dispatch(
      updateField(this.props.db, `${this.props.line}.productGroup`, "custom")
    );
  };

  handleSelectProductGroup = key => {
    if (key != "custom") {
      this.props.dispatch(
        updateField(this.props.db, `${this.props.line}.productGroup`, key)
      );
      // check if default lift value can be initialized
      const initLiftVal = this.getInitialLiftVal(key);
      if (initLiftVal !== "" && !isNaN(initLiftVal)) {
        this.props.dispatch(
          updateField(
            this.props.db,
            `${this.props.line}.lift`,
            parseFloat(initLiftVal) / 100
          )
        );
      }
    } else {
      this.props.dispatch(
        updateField(this.props.db, `${this.props.line}.product`, [])
      );
      this.props.dispatch(
        updateField(this.props.db, `${this.props.line}.productGroup`, "custom")
      );
    }
    this.setState(
      {
        productGroup: key
      },
      () => this.setSpendRate(this.props.line)
    );
  };

  autoFillDates = (line, tf) => {
    const tfs = ["buyin", "instore", "scanback"];
    for (var i = 0; i < tfs.length; i++) {
      var prevTf = tfs[i];
      if (prevTf != tf && this.props.defaultDates[`${prevTf}StartDate`]) {
        this.props.dispatch(
          updateField(
            this.props.db,
            `${line}.${tf}StartDate`,
            this.props.lineState[`${prevTf}StartDate`]
          )
        );
      }
    }

    for (var i = 0; i < tfs.length; i++) {
      var prevTf = tfs[i];
      if (prevTf != tf && this.props.defaultDates[`${prevTf}EndDate`]) {
        this.props.dispatch(
          updateField(
            this.props.db,
            `${line}.${tf}EndDate`,
            this.props.lineState[`${prevTf}EndDate`]
          )
        );
      }
    }
  };

  updateDate = (field, date) => {
    this.props.dispatch(updateField(this.props.db, field, date.toDateString()));
  };

  prettyPrint = productsArray => {
    let products = "";
    if (productsArray) {
      for (let i = 0; i < productsArray.length; i++) {
        if (i != 0) {
          products += ", ";
        }
        products += productsArray[i];
      }
    }
    return products;
  };

  checkSpendVal = spendVal => {
    const spendTooLowMessage =
      "Please make sure this line's spend should actually be under $100!";

    if (Number.isNaN(spendVal) || spendVal < 100) {
      this.setState({ spendTooLow: spendTooLowMessage });
    } else {
      this.setState({ spendTooLow: "" });
    }
  };

  getInitialLiftVal = productGroup => {
    const customer = this.props.promState.customer.key;
    const liftBuckets = this.props.db.meta.lift_buckets || {};
    const { lift } = this.props.db;
    let liftBucket = null;
    if (this.state.selectedType != null) {
      for (const bucket in liftBuckets) {
        if (liftBuckets[bucket].includes(this.state.selectedType.toString())) {
          liftBucket = lift[bucket];
          break;
        }
      }
    }
    if (
      liftBucket &&
      customer in liftBucket &&
      productGroup in liftBucket[customer]
    ) {
      return liftBucket[customer][productGroup].lift;
    }
    return "";
  };

  getLiftVal = () => {
    const customer = this.props.promState.customer.key;
    const { productGroup } = this.props.lineState;
    const liftBuckets = this.props.db.meta.lift_buckets || {};
    const { lift } = this.props.db;
    let liftBucket = null;
    if (this.state.selectedType != null) {
      for (const bucket in liftBuckets) {
        if (liftBuckets[bucket].includes(this.state.selectedType.toString())) {
          liftBucket = lift[bucket];
          break;
        }
      }
    }
    if (!isNaN(this.props.lineState.lift * 100)) {
      return this.props.lineState.lift * 100;
    }
    if (
      liftBucket &&
      customer in liftBucket &&
      productGroup in liftBucket[customer]
    ) {
      const liftVal = liftBucket[customer][productGroup].lift;
      this.props.dispatch(
        updateField(
          this.props.db,
          `${this.props.line}.lift`,
          parseFloat(liftVal) / 100
        )
      );
      return liftVal;
    }
    return "";
  };

  canForecastBeGenerated = () => {
    const today = new Date();
    // javascript month starts at 0
    const endForecast = new Date(
      this.props.promState.year,
      this.props.promState.month,
      1
    );
    const { productGroup } = this.props.lineState;
    if (
      today < endForecast &&
      productGroup &&
      this.props.db.meta.fundTypes?.[this.props.lineState.type]
        ?.forecastDateRange &&
      this.props.db.permissions.includes("forecast")
    ) {
      return true;
    }
    return false;
  };

  generateForecast = line => {
    const user = firebase.auth().currentUser;
    const customerKey = this.props.promState.customer.key;
    const products =
      this.props.lineState.productGroup == "custom"
        ? this.props.lineState.product
        : this.props.db.meta.product_groups[this.props.lineState.productGroup]
            .products;
    const fundTypes = this.props.db.meta.fundTypes || {};
    let lift = this.getLiftVal() / 100;

    // see update_line_forecasts in cronjobs.py
    if (!fundTypes[this.props.lineState.type]?.forecastLiftIncluded) {
      // if lift is not supposed to be included in forecast, just set it to 0 here
      lift = 0;
    }

    const lineStateNew = { ...this.props.lineState };
    lineStateNew.lift = lift;
    lineStateNew.products = products;
    lineStateNew.startDate = lineStateNew.instoreStartDate;
    lineStateNew.endDate = lineStateNew.instoreEndDate;

    if (lineStateNew.productGroup !== "custom") {
      delete lineStateNew.product;
    }

    const overrideVersioning =
      !!this.props.db.meta.featureGates?.overrideVersioning;

    const formData = {
      company_id: this.props.db.companyid,
      customer: customerKey,
      line: lineStateNew,
      override_versioning: overrideVersioning
    };
    this.setState({ loading: true });
    axios
      .get(`${baseURL}/api/get_forecasted_sales`, {
        params: formData
      })
      .then(response => {
        const totalExpSales = response.data.forecasted_sales; // forecasted value
        if (this.props.defaultProm) {
          const origLine = this.props.defaultProm[line] || {};
          this.props.dispatch(
            updateField(
              this.props.db,
              `${line}.prevTotalExpSales`,
              origLine.totalExpSales
            )
          );
        }

        this.props.dispatch(
          updateField(
            this.props.db,
            `${line}.totalExpSales`,
            parseInt(totalExpSales)
          )
        );
        this.props.openClose.showSnack("Forecast generated.");
        this.setState({ loading: false });
      })
      .catch(error => {
        this.setState({ loading: false });
        this.props.openClose.showSnack(
          "Error generating forecast - please contact support if this issue persists."
        );
      });
  };

  updateExpectedSales = (line, event) => {
    this.setState({ totalExpSales: undefined });
    let { value } = event.target;
    if (this.props.defaultProm) {
      const origLine = this.props.defaultProm[line] || {};
      value = value.replaceAll(",", "");
      this.props.dispatch(
        updateField(
          this.props.db,
          `${line}.prevTotalExpSales`,
          origLine.totalExpSales
        )
      );
    }
    this.props.dispatch(
      updateField(
        this.props.db,
        `${line}.totalExpSales`,
        value == "" && value !== 0 ? 0 : parseFloat(value)
      )
    );
    const newSpendVal = parseFloat(value ?? 0) * this.props.lineState.spendRate;
    this.checkSpendVal(newSpendVal);
    this.props.dispatch(
      updateField(
        this.props.db,
        `${line}.totalExpSpend`,
        newSpendVal ? parseFloat(newSpendVal) : 0
      )
    );
  };

  resetExpectedSales = line => {
    if (this.props.defaultProm) {
      var origLine = this.props.defaultProm[line] || {};
      this.props.dispatch(
        updateField(
          this.props.db,
          `${line}.totalExpSales`,
          origLine.totalExpSales
        )
      );
    }
    this.props.dispatch(
      updateField(
        this.props.db,
        `${line}.prevTotalExpSales`,
        origLine.prevTotalExpSales
      )
    );
  };

  setSpendRate = line => {
    const typeFields =
      this.props.db.meta.fundTypes?.[this.props.lineState.type]
        ?.promCreationFields ?? [];

    if (!typeFields.includes("rate")) return;

    const customer = this.props.promState.customer.key;
    const { productGroup } = this.state;
    const price = getCurrentTimeframe(
      this.props.db.pricing?.[customer]?.[productGroup] ?? {},
      new Date(this.props.promState.year, this.props.promState.month - 1, 1)
    )?.price;

    let newSpendRate = this.state.spendRate ?? this.props.lineState.spendRate;

    if (this.state.discountOffListCost) {
      newSpendRate = (this.state.discountOffListCost / 100) * parseFloat(price);
    } else if (this.state.spendRate) {
      newSpendRate = this.state.spendRate;
    } else if (!newSpendRate && !Number.isNaN(newSpendRate)) {
      newSpendRate = "";
    }

    if (newSpendRate !== "") {
      if (!Number.isNaN(newSpendRate)) {
        newSpendRate =
          Math.round((parseFloat(newSpendRate) || 0 + Number.EPSILON) * 100) /
          100;
      }

      this.setState({ spendRate: undefined });

      this.props.dispatch(
        updateField(this.props.db, `${line}.spendRate`, newSpendRate)
      );
      const newSpendVal =
        this.props.lineState.totalExpSales * (parseFloat(newSpendRate) || 0);
      this.props.dispatch(
        updateField(this.props.db, `${line}.totalExpSpend`, newSpendRate)
      );
      this.checkSpendVal(newSpendVal);
    }
  };

  getTypeFields = type => {
    // get fields present for a line type's *KEY*
    const typeFields = ["productGroup"];
    // lift
    const isLift =
      liftEnabled(this.props.db) &&
      this.props.db.meta.fundTypes?.[this.props.lineState.type]?.liftAssigned;
    if (isLift) {
      typeFields.push("lift");
    }
    // type specific fields
    const fields =
      this.props.db.meta.fundTypes?.[type]?.promCreationFields ?? [];
    const potentialCostConflict =
      fields.includes("rate") && fields.includes("lumpsum");
    for (const tf of fields) {
      typeFields.push(...[`${tf}StartDate`, `${tf}EndDate`]);
      if (tf == "rate") {
        typeFields.push("prevTotalExpSales");
        const ratePresent = !(
          potentialCostConflict && this.props.lineState.lumpSumSpend
        );
        if (ratePresent) {
          typeFields.push(...["spendRate", "totalExpSales"]);
        }
      } else if (tf == "lumpsum") {
        const lumpsumPresent = !(
          (this.props.lineState.spendRate ||
            this.props.lineState.totalExpSales) &&
          potentialCostConflict
        );
        if (lumpsumPresent) {
          typeFields.push("lumpsumSpend");
        }
      }
    }
    // pricing fields
    const showPricing =
      this.props.db.meta.fundTypes?.[type]?.promCreationShowPricing;
    if (showPricing) {
      typeFields.push(...["basePrice", "retailPrice"]);
    }

    return typeFields;
  };

  getTypeFieldsJSX = line => {
    const typeFieldsJSX = [];

    // Product fields -- included in all types
    const allProducts = this.props.db.products;
    let productsList = objToArray(allProducts);
    productsList = sortByField("name", productsList, true);
    const selectProductsJSX = [];
    for (var i = 0; i < productsList.length; i++) {
      const product = productsList[i];
      selectProductsJSX.push(
        <ListItem key={product.key}>
          <ListItemIcon>
            <Checkbox
              color="primary"
              edge="start"
              onChange={this.selectCustomProduct.bind(null, product.key)}
              checked={
                this.props.lineState.product &&
                this.props.lineState.product.indexOf(product.key) != -1
              }
              inputProps={{ "aria-labelledby": product.key }}
            />
          </ListItemIcon>
          <ListItemText id={product.key} primary={product.name} />
        </ListItem>
      );
    }

    typeFieldsJSX.push(
      <div key={`product${this.state.selectedType}`}>
        <Subheader style={styles.subheader}>Select Product(s)</Subheader>
        {this.props.lineState.productGroup === "" && (
          <div>
            <div style={{ color: red500 }}>
              Please select Product Group or a custom nonempty set of products
            </div>
            <br />
          </div>
        )}
        {this.getNumProductGroups() > MAX_PRODUCT_GROUPS ? (
          <SelectProductGroupLarge
            selectProductGroups={this.state.selectProductGroups}
            handleSelectProductGroup={this.handleSelectProductGroup}
            lineStateProductGroup={this.props.lineState.productGroup}
          />
        ) : (
          <SelectProductGroup
            selectProductGroups={this.state.selectProductGroups}
            handleSelectProductGroup={this.handleSelectProductGroup}
            lineStateProductGroup={this.props.lineState.productGroup}
          />
        )}
        <br />
        {this.state.productGroup === "custom" && (
          <div style={styles.customSelect}>
            <List>{selectProductsJSX}</List>
          </div>
        )}
      </div>
    );

    // Lift amount, if it is assigned to the promotion type
    const isLift =
      liftEnabled(this.props.db) &&
      this.props.db.meta.fundTypes?.[this.props.lineState.type]?.liftAssigned;

    const shouldShowForecastButton =
      !this.props.db.meta.forecast_all_other?.includes(
        this.props.promState.customer.key
      ) && this.props.db.meta.forecast;

    if (isLift) {
      var forecastGenerated = this.canForecastBeGenerated();
      typeFieldsJSX.push(
        <div key="lift">
          <Subheader style={styles.subheader}>Lift</Subheader>
          <div style={{ padding: 16, marginTop: -30 }}>
            <TextField
              floatingLabelText="Lift (%)"
              value={
                this.state.liftVal ??
                Math.round((this.getLiftVal() + Number.EPSILON) * 100) / 100
              }
              onChange={event => {
                this.setState({ liftVal: event.target.value });
              }}
              onBlur={event => {
                this.setState({ liftVal: undefined });
                const { value } = event.target;
                const liftVal = parseFloat(value.replaceAll(",", ""));
                this.props.dispatch(
                  updateField(
                    this.props.db,
                    `${line}.lift`,
                    (isNaN(liftVal) ? 0 : liftVal) / 100
                  )
                );
              }}
              errorText={this.getErrorText(`${line}.lift`)}
              fullWidth
            />
          </div>
        </div>
      );
    }

    // Type field -- specific to each type
    const distributorName = this.props.promState.distributor?.[0];
    const distributorId = Object.entries(this.props.db.customers)
      .filter(([key, field]) => field.name === distributorName)?.[0]?.[0]
      .toString();
    const useBuyIn = this.props.db.meta?.use_buyin_calendar?.[distributorId];
    const year = this.props.promState?.year?.toString?.();
    const hasBuyIn =
      this.props.db.meta?.buyin_calendar?.[distributorId]?.[year];
    const buyInConflict = this.props.promState.distributor?.length !== 1;

    const typeFields =
      this.props.db.meta.fundTypes?.[this.props.lineState.type]
        ?.promCreationFields ?? [];
    const potentialCostConflict =
      typeFields.includes("rate") && typeFields.includes("lumpsum");
    for (var i = 0; i < typeFields.length; i++) {
      const tf = typeFields[i];
      if (tf == "buyin" || tf == "instore" || tf == "scanback") {
        let label = "";
        switch (tf) {
          case "buyin":
            label = "Retailer/Distributor Buy In Dates";
            break;
          case "instore":
            label = "In Store Performance Dates";
            break;
          case "scanback":
            label = "Scan Back Performance Dates";
            break;
        }

        typeFieldsJSX.push(
          <div
            key={
              this.props.defaultDateRemount + tf + i + this.state.selectedType
            }>
            <div className="rowC">
              <Subheader style={styles.subheader}>{label}</Subheader>
              <IconButton
                tooltip="Auto-fill dates"
                tooltipPosition="bottom-center"
                color="primary"
                onClick={this.autoFillDates.bind(null, line, tf)}
                size="large">
                <RestoreIcon />
              </IconButton>
            </div>
            {tf === "buyin" &&
              this.props.mode !== "edit" &&
              !buyInConflict &&
              useBuyIn &&
              (hasBuyIn ? (
                <p style={{ color: green500 }}>
                  These dates are based on the buy-in window calendar. Editing
                  them is discouraged.
                </p>
              ) : (
                <p style={{ color: red500 }}>
                  You have not set a buy-in window for this year. Default
                  options have been chosen.
                </p>
              ))}
            {tf === "buyin" && this.props.mode !== "edit" && buyInConflict && (
              <p style={{ color: red500 }}>
                You have selected multiple first receivers. Default options have
                been chosen for the buy-in window.
              </p>
            )}
            <div className="rowC">
              <div style={{ width: "50%", padding: 16, marginTop: -35 }}>
                <DatePicker
                  floatingLabelText="Start Date"
                  onChange={this.updateDate.bind(
                    null,
                    `${line}.${tf}StartDate`
                  )}
                  value={
                    this.props.lineState[`${tf}StartDate`] &&
                    new Date(this.props.lineState[`${tf}StartDate`])
                  }
                  autoOk
                />
              </div>
              <div style={{ width: "50%", padding: 16, marginTop: -35 }}>
                <DatePicker
                  floatingLabelText="End Date"
                  onChange={this.updateDate.bind(null, `${line}.${tf}EndDate`)}
                  value={
                    this.props.lineState[`${tf}EndDate`] &&
                    new Date(this.props.lineState[`${tf}EndDate`])
                  }
                  minDate={
                    this.props.lineState[`${tf}StartDate`]
                      ? new Date(this.props.lineState[`${tf}StartDate`])
                      : new Date(1970, 1, 1)
                  }
                  minDateMessage="End Date should not be before Start Date"
                  autoOk
                />
              </div>
            </div>
          </div>
        );
      } else if (tf == "rate") {
        typeFieldsJSX.push(
          <div key={i + this.state.selectedType}>
            <Subheader style={styles.subheader}>Spend Amount</Subheader>
            <div style={{ padding: 16, marginTop: -15 }}>
              <div className="rowC">
                <TextField
                  floatingLabelText="Spend Rate ($ per item)"
                  value={this.state.spendRate ?? this.props.lineState.spendRate}
                  onChange={event => {
                    this.setState({ spendRate: event.target.value });
                  }}
                  onBlur={event => {
                    this.setState(
                      {
                        spendRate:
                          Math.round(
                            (parseFloat(
                              event.target.value.replaceAll(",", "")
                            ) +
                              Number.EPSILON) *
                              100
                          ) / 100,
                        spendRateExists: Boolean(event.target.value)
                      },
                      () => this.setSpendRate(line)
                    );
                  }}
                  errorText={this.getErrorText(`${line}.spendRate`)}
                  helperText={
                    this.state.discountOffListCostExists &&
                    "Disabled because a % discount is entered."
                  }
                  fullWidth
                  disabled={
                    (potentialCostConflict &&
                      this.props.lineState.lumpSumSpend) ||
                    this.state.discountOffListCostExists
                  }
                />
                {this.props.db.meta.featureGates?.allowPercentSpendRates && (
                  <TextField
                    floatingLabelText="% Discount Off List Cost"
                    value={this.state.discountOffListCost}
                    onChange={event => {
                      this.setState({
                        discountOffListCost: event.target.value,
                        doneEnteringDiscount: false
                      });
                    }}
                    onBlur={event => {
                      const discountPct =
                        Math.round(
                          (parseFloat(this.state.discountOffListCost) +
                            Number.EPSILON) *
                            100
                        ) / 100;
                      if (Number.isNaN(discountPct)) {
                        this.setState(
                          {
                            discountOffListCost: undefined,
                            discountOffListCostExists: false,
                            doneEnteringDiscount: true
                          },
                          () => this.setSpendRate(line)
                        );
                      } else {
                        this.setState(
                          {
                            discountOffListCost: discountPct,
                            discountOffListCostExists: true,
                            doneEnteringDiscount: true
                          },
                          () => this.setSpendRate(line)
                        );
                      }
                    }}
                    errorText={this.getErrorText(`${line}.spendRate`)}
                    helperText={
                      this.state.spendRateExists &&
                      "Disabled because a spend rate is entered."
                    }
                    fullWidth
                    disabled={
                      (potentialCostConflict &&
                        this.props.lineState.lumpSumSpend) ||
                      this.state.spendRateExists
                    }
                    style={{
                      marginLeft: 20,
                      color: white
                    }}
                  />
                )}
              </div>
              {this.state.discountOffListCost &&
                Number.isNaN(parseFloat(this.props.lineState.spendRate)) &&
                this.state.doneEnteringDiscount && (
                  <>
                    <font color="orange">
                      {`WARNING: Possibly no pricing data in Vividly for "${
                        this.props.promState.customer?.name
                      }" / "${
                        this.props.db.meta.product_groups?.[
                          this.props.lineState.productGroup
                        ]?.name ?? "Custom"
                      }"`}
                    </font>
                    <br />
                  </>
                )}
              {this.state.discountOffListCostExists && (
                <>
                  <font color="orange">
                    Please note that ONLY the spend rate will be saved when you
                    finish creating this promotion!
                  </font>
                  <br />
                </>
              )}
              <br />
              <div className="rowC">
                <TextField
                  floatingLabelText="Total Expected Unit Sales"
                  value={
                    this.state.totalExpSales ??
                    this.props.lineState.totalExpSales
                  }
                  onChange={event => {
                    this.setState({ totalExpSales: event.target.value });
                  }}
                  onBlur={this.updateExpectedSales.bind(null, line)}
                  errorText={this.getErrorText(`${line}.totalExpSales`)}
                  disabled={
                    potentialCostConflict && this.props.lineState.lumpSumSpend
                  }
                />
                <TextField
                  floatingLabelText="Previous Estimate (units)"
                  value={
                    this.props.lineState.prevTotalExpSales != null
                      ? this.props.lineState.prevTotalExpSales
                      : "N/A"
                  }
                  disabled
                  style={{
                    marginLeft: 20,
                    color: white
                  }}
                />
                <IconButton
                  tooltip="Undo changes"
                  tooltipPosition="bottom-center"
                  style={{
                    marginLeft: 20,
                    marginTop: 20,
                    color: white
                  }}
                  onClick={this.resetExpectedSales.bind(null, line)}
                  disabled={
                    !(this.props.defaultProm && this.props.defaultProm[line])
                  }
                  size="large">
                  <UndoIcon style={{ color: grey500 }} />
                </IconButton>
              </div>
              {potentialCostConflict &&
                !isNaN(this.props.lineState.lumpSumSpend) &&
                Boolean(this.props.lineState.lumpSumSpend) && (
                  <font color="orange">
                    These fields are disabled since you have entered a lump sum
                    estimate.
                  </font>
                )}
              {shouldShowForecastButton && (
                <>
                  <div className="rowC">
                    <Button
                      label="Forecast Sales"
                      onClick={this.generateForecast.bind(null, line)}
                      style={{
                        marginLeft: 0,
                        marginTop: 10,
                        width: "35%"
                      }}
                      icon={<CloudQueueIcon />}
                      disabled={!forecastGenerated}
                    />
                    <Tooltip
                      title="Selecting this box causes volume estimates to be continously updated from the forecast"
                      arrow>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={event => {
                              const isInputChecked = event.target.checked;
                              this.props.dispatch(
                                updateField(
                                  this.props.db,
                                  `${line}.autoUpdateForecast`,
                                  isInputChecked
                                )
                              );
                            }}
                            checked={this.props.lineState.autoUpdateForecast}
                            color="primary"
                          />
                        }
                        disabled={!forecastGenerated}
                        label="Auto-update Forecast"
                        style={{
                          marginLeft: 20,
                          marginTop: 15
                        }}
                      />
                    </Tooltip>
                  </div>
                  {/* {this.props.lineState.liftUpdated && (
                    <div style={{ color: teal400 }}>
                      Before submitting this promotion, the results of
                      generating forecasted sales may not be correct.
                    </div>
                  )} */}
                </>
              )}
              <div>
                {this.state.loading && (
                  <Loading type="bubbles" color="#000000" />
                )}
              </div>
              <br />
              <TextField
                floatingLabelText="Total Expected Spend ($)"
                value={
                  Math.round(
                    (this.props.lineState.totalExpSales *
                      this.props.lineState.spendRate +
                      Number.EPSILON) *
                      100
                  ) / 100
                }
                disabled
                fullWidth
              />
            </div>
          </div>
        );
        if (!(potentialCostConflict && this.props.lineState.lumpSumSpend)) {
          typeFieldsJSX.push(
            <div>
              <div className="centering" style={{ color: teal400 }}>
                {this.state.spendTooLow}
              </div>
              <br />
            </div>
          );
        }
      } else if (tf == "lumpsum") {
        typeFieldsJSX.push(
          <div key={i + this.state.selectedType}>
            <Subheader style={styles.subheader}>Spend Amount</Subheader>
            <div style={{ padding: 16, marginTop: -30 }}>
              <TextField
                floatingLabelText="Expected Lump Sum Spend ($)"
                value={
                  this.state.lumpSumSpend ?? this.props.lineState.lumpSumSpend
                }
                onChange={event => {
                  this.setState({ lumpSumSpend: event.target.value });
                }}
                onBlur={event => {
                  this.setState({ lumpSumSpend: undefined });
                  let { value } = event.target;
                  value = value.replaceAll(",", "");
                  this.props.dispatch(
                    updateField(
                      this.props.db,
                      `${line}.lumpSumSpend`,
                      value ? parseFloat(value) : 0
                    )
                  );
                  this.props.dispatch(
                    updateField(
                      this.props.db,
                      `${line}.totalExpSpend`,
                      value ? parseFloat(value) : 0
                    )
                  );
                  this.checkSpendVal(parseFloat(value ?? 0));
                }}
                errorText={this.getErrorText(`${line}.lumpSumSpend`)}
                fullWidth
                disabled={
                  (this.props.lineState.spendRate ||
                    this.props.lineState.totalExpSales) &&
                  potentialCostConflict
                }
              />
            </div>
          </div>
        );
        if (
          potentialCostConflict &&
          (this.props.lineState.spendRate || this.props.lineState.totalExpSales)
        ) {
          typeFieldsJSX.push(
            <font color="orange">
              This field is disabled since you have entered a rate estimate.
            </font>
          );
        }
        if (
          !(
            potentialCostConflict &&
            (this.props.lineState.spendRate ||
              this.props.lineState.totalExpSales)
          )
        ) {
          typeFieldsJSX.push(
            <div>
              <div className="centering" style={{ color: teal400 }}>
                {this.state.spendTooLow}
              </div>
              <br />
            </div>
          );
        }
      }
    }

    const showPricing =
      this.props.db.meta.fundTypes?.[this.props.lineState.type]
        ?.promCreationShowPricing;

    if (showPricing) {
      typeFieldsJSX.push(
        <div key={`pricing${this.state.selectedType}`}>
          <Subheader style={styles.subheader}>Pricing</Subheader>
          <div style={{ padding: 16, marginTop: -30 }}>
            <TextField
              floatingLabelText="Base Price ($)"
              defaultValue={this.props.lineState.basePrice}
              onChange={event => {
                let { value } = event.target;
                value = value.replaceAll(",", "");
                this.props.dispatch(
                  updateField(
                    this.props.db,
                    `${line}.basePrice`,
                    value == "" && value !== 0 ? null : parseFloat(value)
                  )
                );
              }}
              errorText={
                this.getErrorText(`${line}.basePrice`) ||
                (this.props.fieldErrors[`${line}Discount`] &&
                  "Promoted price cannot exceed base price")
              }
              fullWidth
            />
            <br />
            <TextField
              floatingLabelText="Retail Promoted Price ($)"
              defaultValue={this.props.lineState.retailPrice}
              onChange={event => {
                let { value } = event.target;
                value = value.replaceAll(",", "");
                this.props.dispatch(
                  updateField(
                    this.props.db,
                    `${line}.retailPrice`,
                    value == "" && value !== 0 ? null : parseFloat(value)
                  )
                );
              }}
              errorText={
                this.getErrorText(`${line}.retailPrice`) ||
                (this.props.fieldErrors[`${line}Discount`] &&
                  "Promoted price cannot exceed base price")
              }
              fullWidth
            />
            <br />
            <TextField
              floatingLabelText="Discount (%)"
              disabled
              value={round(
                ((this.props.lineState.basePrice -
                  this.props.lineState.retailPrice) *
                  100) /
                  this.props.lineState.basePrice
              )}
              fullWidth
            />
          </div>
        </div>
      );
    }

    return typeFieldsJSX;
  };

  getSelectProductGroups = (allProducts, meta) => {
    const numPgs = this.getNumProductGroups();
    const selectProductGroups = [];
    const pgs = meta.product_groups
      ? sortByField("name", objToArray(meta.product_groups), true)
      : [];
    pgs.forEach(pg => {
      const { key } = pg;
      let prodNames = [];
      let containProducts = false;

      if (pg.products) {
        containProducts = true;
        prodNames = pg.products.map(productKey => {
          if (productKey && productKey in allProducts) {
            return allProducts[productKey].name;
          }
        });
      }

      selectProductGroups.push({
        key,
        value: pg.name,
        tooltip: containProducts
          ? prodNames.join(", ")
          : "No assigned products",
        disabled: !containProducts
      });
    });
    return selectProductGroups;
  };

  componentDidMount() {
    const { meta } = this.props.db;
    const selectProductGroups = this.getSelectProductGroups(
      this.props.db.products,
      meta
    );

    const selectTypes = [];
    const typesToKeys = {};
    for (var key in meta.fundTypes) {
      typesToKeys[meta.fundTypes?.[key]?.name] = key;
    }
    const sortedTypes = Object.keys(typesToKeys).sort();
    for (let i = 0; i < sortedTypes.length; i++) {
      const type = sortedTypes[i];
      var key = typesToKeys[type];
      selectTypes.push(<MenuItem value={type} children={type} key={key} />);
    }
    const timesJSX = getTimesJSX(this.props.db.meta);

    const fieldsToSet = {
      selectProductGroups,
      selectTypes,
      monthsJSX: timesJSX[0],
      yearsJSX: timesJSX[1],
      selectedType: this.props.lineState.type,
      spendRate: this.props.lineState.spendRate,
      spendRateExists: Boolean(this.props.lineState.spendRate),
      productGroup: this.props.lineState.productGroup
    };
    if (this.props.lineState.productGroup) {
      fieldsToSet.productGroup = this.props.lineState.productGroup;
    }
    this.setState(fieldsToSet);
  }

  render() {
    const lineTypeKey = this.props.lineState.type;
    const typesToKeys = {};
    for (const key in this.props.db.meta.fundTypes) {
      typesToKeys[this.props.db.meta.fundTypes?.[key]?.name] = key;
    }
    return (
      <EntryCard
        title={
          <div className="rowRL">
            {`Line #${this.props.index}`}
            <Button
              onClick={() =>
                this.props.dispatch(
                  incrementLine(false, this.props.line, this.props.db)
                )
              }
              label="Remove Line"
              // style={{ color: grey500, marginTop: -5 }}
              variant="text"
              color="error"
              icon={<RemoveIcon />}
            />
          </div>
        }
        key={this.props.index}>
        <Select
          floatingLabelText="Select Type"
          value={this.props.db.meta.fundTypes?.[lineTypeKey]?.name ?? ""}
          errorText={
            this.props.lineState.typeSelected === false &&
            "Please select a promotion type."
          }
          onChange={event => {
            const { value } = event.target;
            const keyFromValue = typesToKeys[value];
            const newFields = this.getTypeFields(keyFromValue);
            this.props.dispatch(
              clearLine(this.props.db, this.props.line, newFields)
            );
            this.props.dispatch(
              updateField(
                this.props.db,
                `${this.props.line}.type`,
                keyFromValue
              )
            );
            this.setState({ selectedType: keyFromValue });
          }}
          fullWidth>
          {this.state.selectTypes}
        </Select>
        <br />
        <div style={{ color: teal400 }}>
          {this.props.db.meta.fundTypes?.[lineTypeKey]?.promCreationReminder ??
            ""}
        </div>
        <br />
        <div>{this.getTypeFieldsJSX(this.props.line)}</div>
      </EntryCard>
    );
  }
}

const mapStateToProps = state => {
  const promFields = state.addPromotion;
  return {
    promState: promFields.prom,
    defaultProm: promFields.defaultProm,
    fieldErrors: promFields.fieldErrors,
    defaultDateRemount: promFields.defaultDateRemount,
    defaultDates: promFields.defaultDates
  };
};

export default connect(mapStateToProps)(AddLine);
