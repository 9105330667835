import React, { useState } from "react";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";

import { downloadFilesFromStorage } from "helpers/Firebase";
import SplitScreen from "components/WebsiteElements/SplitScreen";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  ListItemButton,
  TextField,
  Typography
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { useDebouncedCallback } from "use-debounce";
import FileViewer from "./FileViewer";
import { importBackupSources } from "../constants/BackupSources";
import { NONE } from "./DeductionsToolPanel";

interface FileSearchFieldProps {
  internalFiles: { DISPLAY_NAME: string; UUID_KEY: string }[];
  setInternalFiles: Function;
}
const FileSearchField = (props: FileSearchFieldProps) => {
  const { setInternalFiles, internalFiles } = props;

  const [fileSearch, setFileSearch] = useState("");

  const debouncedOnSearch = useDebouncedCallback((val: string): void => {
    setInternalFiles(
      internalFiles.map(internalFile => ({
        ...internalFile,
        hide: !(internalFile.DISPLAY_NAME || "")
          .toUpperCase()
          .includes(val.toUpperCase())
      }))
    );
  }, 400);

  return (
    <>
      <TextField
        autoFocus
        sx={theme => ({
          marginBottom: theme.spacing(1)
        })}
        InputProps={{
          startAdornment: <Search color="info" />
        }}
        placeholder="Search..."
        variant="outlined"
        size="small"
        value={fileSearch}
        onChange={e => {
          const { value } = e.target;
          setFileSearch(value);
          debouncedOnSearch(value);
        }}
      />
      <Divider />
    </>
  );
};

class ExampleTemplateViewerSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      availableBackupSources: [],
      customers: {},
      companyUsers: {},
      servicedCompanies: {},
      currentlySelected: "",
      showSearch: false,
      searchQuery: "",
      openFilePicker: false,
      modalOpen: false,
      reverseSort: false,
      multi: false,
      snackbar: false,
      allSelected: [],
      customersJSX: [],
      tableBodyRenderKey: 0,
      pageNumber: 1,
      numPages: 1,
      company: "None",
      loading: false,
      promType: [],
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      spendDate: new Date(),
      checkNumber: ""
    };
  }

  downloadTemplateFiles = format => {
    this.setState({ selectedFormat: format });
    const fileNames = this.state.availableBackupSources.find(
      source => source.DISPLAY_NAME === format
    ).FILES;
    const filePaths = fileNames.map(
      name => `Samples/Deductions Scanning Templates/${name}`
    );
    const files = {};
    downloadFilesFromStorage(filePaths, fileNames, (blob, fileName) => {
      blob.name = fileName;
      files[fileName] = blob;
      if (Object.keys(files).length == fileNames.length) {
        this.setState({ files: Object.values(files) });
      }
    });
  };

  componentWillMount = () => {
    importBackupSources()
      .then(backupSources => {
        const availableBackupSources = Object.values(backupSources)
          .sort((source1, source2) => {
            return source1.DISPLAY_NAME.localeCompare(source2.DISPLAY_NAME);
          })
          .filter(source => {
            return "FILES" in source && source.FILES.length > 0;
          });
        this.setState({
          availableBackupSources
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={() => this.props.setOpen(false)}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: {
            height: "90vh"
          }
        }}>
        <DialogTitle variant="h5" color="primary">
          Select Backup Source
        </DialogTitle>
        <DialogContent>
          <div>
            <SplitScreen
              style={{ paddingLeft: "16px" }}
              allowResize={false}
              width="25%"
              rightComponent={
                <FileViewer
                  title={
                    <Typography variant="h6" color="primary">
                      Template Sample
                    </Typography>
                  }
                  files={this.state.files}
                />
              }
              leftComponent={
                <Box
                  sx={{
                    overflowY: "scroll",
                    maxHeight: `${window.innerHeight * 0.75}px`
                  }}>
                  <FileSearchField
                    setInternalFiles={files => {
                      this.setState({
                        availableBackupSources: files
                      });
                    }}
                    internalFiles={this.state.availableBackupSources}
                  />
                  <List
                    component="nav"
                    dense
                    disablePadding
                    aria-labelledby="nested-list-subheader">
                    {this.state.availableBackupSources.map(source =>
                      source.hide ? (
                        <React.Fragment key={source.DISPLAY_NAME} />
                      ) : (
                        <ListItemButton
                          onKeyDown={e => {
                            if (e.key === "Enter") {
                              if (!this.state.selectedFormat) {
                                return;
                              }
                              const backupSourceObject =
                                this.state.availableBackupSources.find(
                                  abs =>
                                    abs.DISPLAY_NAME ===
                                    this.state.selectedFormat
                                );
                              this.props.selector.onBackupSourceSelect({
                                key: backupSourceObject.UUID_KEY,
                                displayName: backupSourceObject.DISPLAY_NAME
                              });
                              this.props.setOpen(false);
                            }
                          }}
                          key={source.DISPLAY_NAME}
                          divider
                          selected={
                            source.DISPLAY_NAME == this.state.selectedFormat
                          }
                          onClick={this.downloadTemplateFiles.bind(
                            null,
                            source.DISPLAY_NAME
                          )}>
                          <ListItemText primary={source.DISPLAY_NAME} />
                        </ListItemButton>
                      )
                    )}
                  </List>
                </Box>
              }
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            disabled={!this.state.selectedFormat}
            onClick={() => {
              if (!this.state.selectedFormat) {
                return;
              }
              const backupSourceObject = this.state.availableBackupSources.find(
                abs => abs.DISPLAY_NAME === this.state.selectedFormat
              );
              this.props.selector.onBackupSourceSelect({
                key: backupSourceObject.UUID_KEY,
                displayName: backupSourceObject.DISPLAY_NAME
              });
              this.props.setOpen(false);
            }}>
            {`Select Source: ${
              this.state.availableBackupSources.find(
                abs => abs.DISPLAY_NAME === this.state.selectedFormat
              )?.DISPLAY_NAME || NONE
            }`}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ExampleTemplateViewerSelector;
