import React from "react";
import SplitScreen from "components/WebsiteElements/SplitScreen";

import Mixpanel from "helpers/Mixpanel";
import { common } from "@mui/material/colors";
import FileViewer from "./FileViewer";
import DeductionsToolPanel from "./DeductionsToolPanel";
import { FREE_TRIAL_DAYS, SPECIAL_TRIAL_DAYS } from "../constants/FreeTrial";

import { MS_DAY } from "../constants/Time";

const { black } = common;

const styles = {
  miniIcon: {
    height: 15,
    width: 15,
    marginLeft: 16
  },
  subheader: {
    fontSize: 20,
    color: black
  },
  progress: {
    height: 10,
    borderRadius: 5
  }
};

const pagelength = 20;

class ScanAndMatch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: {},
      companyUsers: {},
      currentlySelected: "",
      showSearch: false,
      searchQuery: "",
      openFilePicker: false,
      modalOpen: false,
      reverseSort: false,
      multi: false,
      snackbar: false,
      allSelected: [],
      count: pagelength,
      customersJSX: [],
      tableBodyRenderKey: 0,
      file: null,
      files: [],
      pageNumber: 1,
      numPages: 1,
      company: "None",
      loading: false,
      promType: [],
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      spendDate: new Date(),
      checkNumber: "",
      freeTrial: {}
    };
  }

  openFilePicker = jsx => {
    this.setState({
      openFilePicker: true
    });
    this.props.showDropDownWindow(jsx);
  };

  closeFilePicker = () => {
    this.setState({
      openFilePicker: false
    });
  };

  getDropzone = dropzone => {
    this.setState({ dropzone });
  };

  addedfile = files => {
    this.setState({ files });
    if (files.length > 0) {
      Mixpanel.track("Deductions Scanner File Upload");
    }
  };

  onDocumentLoadSuccess = document => {
    const { numPages } = document;
    this.setState({
      numPages,
      pageNumber: 1
    });
  };

  changePage = offset =>
    this.setState(prevState => ({
      pageNumber: prevState.pageNumber + offset
    }));

  previousPage = () => this.changePage(-1);

  nextPage = () => this.changePage(1);

  componentDidMount() {
    const freeTrial = this.props.db.meta.free_trial;
    if (!freeTrial) {
      return;
    }
    this.setState({
      freeTrial: {
        maxDays: freeTrial?.special_trial
          ? SPECIAL_TRIAL_DAYS
          : FREE_TRIAL_DAYS,
        onTrial: freeTrial.on_trial,
        startDate: new Date(freeTrial.start_date),
        pagesIn: freeTrial.pages_in
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    const freeTrial = nextProps.db.meta.free_trial;
    if (!freeTrial) {
      return;
    }
    this.setState({
      freeTrial: {
        maxDays: freeTrial?.special_trial
          ? SPECIAL_TRIAL_DAYS
          : FREE_TRIAL_DAYS,
        onTrial: freeTrial.on_trial,
        startDate: new Date(freeTrial.start_date),
        pagesIn: freeTrial.pages_in
      }
    });
  }

  render() {
    const { freeTrial } = this.state;
    const daysIn = Math.floor((new Date() - freeTrial?.startDate) / MS_DAY) + 1;

    return (
      <div>
        <SplitScreen
          sideBar={this.props?.sideBar}
          allowResize={false}
          height={$(window).height() - 75 - (freeTrial?.onTrial ? 48 : 0)} // 75px is navbar height, alert height
          leftComponent={
            <DeductionsToolPanel
              db={this.props.db}
              companyid={this.props.companyid}
              getDropzone={this.getDropzone}
              dropzone={this.state.dropzone}
              addedfile={this.addedfile}
              removedfile={this.removedfile}
              files={this.state.files}
              showDropDownWindow={this.props.showDropDownWindow}
              showRightDrawer={this.props.showRightDrawer}
              openClose={this.props.openClose}
              standalone={this.props.standalone}
              addPagesScanned={this.addPagesScanned}
              freeTrialActive={daysIn < freeTrial.maxDays}
            />
          }
          rightComponent={<FileViewer files={this.state.files} />}
        />
      </div>
    );
  }
}

export default ScanAndMatch;
