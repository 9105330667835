import {
  RepaymentLineDisplayService,
  RepaymentLineFilterService
} from "components/Deductions/DeductionsReconciliation/services";
import { useDb } from "contexts/Db";
import React, { useMemo } from "react";
import { displayUSCurrency } from "helpers/DataProcessing";
import CollapsibleTable, {
  ColumnConfigOptions
} from "ui-library/CollapsibleTable/CollapsibleTable";
import { DisplayRepaymentLineObject } from "components/Deductions/DeductionsReconciliation/types/repaymentLineTypes";
import { RepaymentLineObject } from "components/Deductions/models";
import { Typography } from "@mui/material";
import { renderUTCDateTimeInLocalTimeZone } from "helpers/Time";

export interface RepaymentLinesTableProps {
  repaymentKey: string;
  deductionKey?: string;
  showRepaidTransactionId?: boolean;
}

export default function RepaymentLinesTable(props: RepaymentLinesTableProps) {
  const { repaymentKey, deductionKey, showRepaidTransactionId } = props;
  const db = useDb();
  const { erpTransactions, accounts = {} } = db;
  const repayment = useMemo(() => {
    return erpTransactions[repaymentKey] || {};
  }, [repaymentKey, erpTransactions]);

  const deduction = useMemo(() => {
    return erpTransactions[deductionKey || ""] || {};
  }, [deductionKey, erpTransactions]);

  const repaymentLines: RepaymentLineObject[] = useMemo(() => {
    if (!deductionKey) {
      return Object.values(repayment.repaymentLines || {});
    }

    return RepaymentLineFilterService.findDeductionRepaymentLines(
      deduction,
      erpTransactions,
      repayment
    );
  }, [deduction, repayment, erpTransactions, deductionKey]);

  const displayRepaymentLines = useMemo(() => {
    return repaymentLines.map(repaymentLine =>
      RepaymentLineDisplayService.processRepaymentLineForDisplay(
        repaymentLine,
        db
      )
    );
  }, [db, repaymentLines]);

  const columnConfig = useMemo(
    () =>
      new Map([
        [
          "createdDate",
          {
            align: "left",
            name: "Date",
            render: val => renderUTCDateTimeInLocalTimeZone(val as string)
          }
        ],
        [
          "displayType",
          {
            name: "Type"
          }
        ],
        ...(showRepaidTransactionId
          ? [
              [
                "displayDeductionId",
                {
                  name: "Deduction ID"
                }
              ]
            ]
          : []),
        [
          "displayInvoiceNumber",
          {
            name: "Invoice #"
          }
        ],
        [
          "displayInvoiceLineNumber",
          {
            name: "Invoice Line #"
          }
        ],
        [
          "amount",
          {
            align: "right",
            name: "Amount",
            render: val => displayUSCurrency(val)
          }
        ],
        [
          "displayFundType",
          {
            name: "Fund Type",
            render: val => (!val ? "N/A" : val)
          }
        ],
        [
          "displayFundTypeAccount",
          {
            name: "Account",
            render: val => (!val ? "N/A" : val)
          }
        ]
      ] as [keyof DisplayRepaymentLineObject, ColumnConfigOptions<DisplayRepaymentLineObject>][]),
    [showRepaidTransactionId]
  );

  return (
    <>
      {deductionKey && (
        <Typography gutterBottom component="div">
          Applied Repayment Details ({displayRepaymentLines.length || "0"})
        </Typography>
      )}
      <CollapsibleTable<DisplayRepaymentLineObject>
        data={displayRepaymentLines}
        columnConfig={columnConfig}
        index="key"
      />
    </>
  );
}
