import { Link as MuiLink, LinkProps as MuiLinkProps } from "@mui/material";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from "react-router-dom";
import React from "react";

export type LinkProps = MuiLinkProps & Partial<RouterLinkProps>;

export default function Link(props: LinkProps) {
  return <MuiLink component={RouterLink} {...props} />;
}
