import React from "react";
import { sortBy } from "lodash";

import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import Autocomplete from "ui-library/Autocomplete";
import Button from "ui-library/Button";
import MenuItem from "ui-library/MenuItem";
import Select from "ui-library/Select";
import { PromotionSelect } from "ui-library/promotionTag";

import { getJSXListsFirebase, getCompanyUsers } from "helpers/Firebase";
import { getTimesJSX } from "helpers/Time";
import Mixpanel from "helpers/Mixpanel";

import { common, grey } from "@mui/material/colors";

const { white } = common;

const grey700 = grey["700"];

const styles = {
  subheader: {
    marginLeft: "-16px",
    fontSize: 15
  },
  divider: {
    width: "50%"
  },
  leftTextField: {
    width: "49.5%"
  },
  rightTextField: {
    width: "49.5%",
    marginLeft: 16
  }
};

const validFields = [
  "showPromKey",
  "showName",
  "showCustomerName",
  "showDistributor",
  "showType",
  "showStatus",
  "showAttachments",
  "showStarred",
  "showMonth",
  "showYear"
];

export default class SearchPromotions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchProm: { ...this.props.searchProm },
      promKey: "",
      customers: [],
      distributors: [],
      products: [],
      productGroups: []
    };
  }

  handleTextChange = (field, event) => {
    const newSearchProm = this.state.searchProm;
    newSearchProm[field] = event.target.value;
    this.setState({
      searchProm: newSearchProm
    });
  };

  handleAutoCompleteRequest = (field, event, value) => {
    const newSearchProm = this.state.searchProm;
    newSearchProm[field] = value;
    this.setState({
      searchProm: newSearchProm
    });
  };

  handleSelectChange = (field, event, index, value) => {
    const { searchProm } = this.state;
    searchProm[field] = value;

    this.setState({
      searchProm
    });
  };

  handleDateChange = (field, event, value) => {
    const newSearchProm = this.state.searchProm;
    newSearchProm[field] = value;
    this.setState({
      searchProm: newSearchProm
    });
  };

  handleSubmit = () => {
    const newSearchProm = { ...this.state.searchProm };
    if (newSearchProm.assignedTo) {
      const value = newSearchProm.assignedTo;
      newSearchProm.assignedTo = this.state.companyUsers[value].customers || [];
    }
    if (
      newSearchProm.type &&
      !(newSearchProm.type in (this.props.db.meta.fundTypes ?? {}))
    ) {
      const { fundTypes = {} } = this.props.db.meta;
      const nameToTypes = Object.fromEntries(
        Object.entries(fundTypes).map(([key, value]) => [value.name, key])
      );
      newSearchProm.type = nameToTypes[newSearchProm.type];
    }
    this.props.setSearchProm(newSearchProm);
    this.props.openClose.hideRightDrawer();

    const mixpanelProps = { ...newSearchProm };
    if (mixpanelProps.status) {
      const statusNames = [];
      this.state.statusesJSX.forEach(status => {
        if (mixpanelProps.status.includes(status.props.value)) {
          statusNames.push(status.props.children);
        }
      });
      mixpanelProps.status = statusNames;
    }
    if (mixpanelProps.type) {
      mixpanelProps.type =
        this.state.typesJSX[mixpanelProps.type].props.primaryText;
    }
    Mixpanel.track("Advanced Search", mixpanelProps);
  };

  resetFields = () => {
    this.setState({
      searchProm: {}
    });
    this.props.setSearchProm({});
  };

  toSortedArray = values => {
    return sortBy(Array.from(new Set(values)), [value => value?.toLowerCase()]);
  };

  getCustomersAndDistributors = customers => {
    let customerNames = [];
    let distributorNames = [];
    for (const [key, customer] of Object.entries(customers)) {
      customerNames.push(customer.name);
      if (customer.isDistributor) {
        distributorNames.push(customer.name);
      }
    }
    customerNames = this.toSortedArray(customerNames);
    distributorNames = this.toSortedArray(distributorNames);
    this.setState({ customers: customerNames, distributors: distributorNames });
  };

  getProductsAndGroups = (products, pgs) => {
    let productNames = [];
    let productGroupNames = ["Custom"];
    for (var [key, product] of Object.entries(products)) {
      productNames.push(product.name);
    }
    for (var [key, pg] of Object.entries(pgs)) {
      productGroupNames.push(pg.name);
    }
    productNames = this.toSortedArray(productNames);
    productGroupNames = this.toSortedArray(productGroupNames);
    this.setState({ products: productNames, productGroups: productGroupNames });
  };

  getCompanyUsersJSX = () => {
    const companyUsersJSX = [];
    for (const user in this.state.companyUsers) {
      const cu = this.state.companyUsers[user];
      companyUsersJSX.push(
        <MenuItem value={user} children={cu.name} key={user} />
      );
    }
    return companyUsersJSX;
  };

  // Determine whether to show only certain search fields set in props.
  // True if this.props[k] is true for any k in validFields.
  showSetFields = () => {
    for (let i = 0; i < validFields.length; i++) {
      if (this.props[validFields[i]]) return true;
    }
    return false;
  };

  componentDidMount() {
    getCompanyUsers(companyUsers => {
      this.setState({ companyUsers });
    });

    getJSXListsFirebase(
      this.props.db,
      allJSX => {
        this.setState({
          meta: this.props.db.meta,
          statusesJSX: allJSX.statusesJSX,
          typesJSX: allJSX.typesJSX
        });
      },
      null,
      true
    );

    const timesJSX = getTimesJSX(this.props.db.meta, true);
    this.setState({
      monthsJSX: timesJSX[0],
      yearsJSX: timesJSX[1]
    });
    this.getCustomersAndDistributors(this.props.db.customers);
    this.getProductsAndGroups(
      this.props.db.products,
      this.props.db.meta.product_groups
    );
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    const showSetFields = this.showSetFields();
    const companyUsersJSX = this.getCompanyUsersJSX();
    return (
      <div key={this.props.contactKey}>
        <ListItem style={{ backgroundColor: grey700, color: white }}>
          <ListItemIcon>
            <ZoomInIcon />
          </ListItemIcon>
          <ListItemText primary="Advanced Search" />
        </ListItem>
        <div style={{ marginLeft: "16px", marginRight: "16px" }}>
          <br />
          <div>
            Enter keyword or select from dropdown for any field. Search results
            contain entries that match all fields.
          </div>
          <div className="rowC">
            {(this.props.showCustomerName || !showSetFields) && (
              <Autocomplete
                value={this.state.searchProm.customerName}
                options={this.state.customers}
                onChange={this.handleAutoCompleteRequest.bind(
                  null,
                  "customerName"
                )}
                floatingLabelText="Customer"
                openOnFocus
                style={styles.leftTextField}
              />
            )}
            {(this.props.showDistributor || !showSetFields) && (
              <Autocomplete
                value={this.state.searchProm.distributor}
                options={this.state.distributors}
                onChange={this.handleAutoCompleteRequest.bind(
                  null,
                  "distributor"
                )}
                floatingLabelText="First Receiver"
                openOnFocus
                style={styles.rightTextField}
              />
            )}
          </div>
          <br />
          <div className="rowC">
            {(this.props.showProduct || !showSetFields) && (
              <Autocomplete
                value={this.state.searchProm.product}
                options={this.state.products}
                onChange={this.handleAutoCompleteRequest.bind(null, "product")}
                floatingLabelText="Product"
                openOnFocus
                style={styles.leftTextField}
              />
            )}
            {(this.props.showProductGroup || !showSetFields) && (
              <Autocomplete
                value={this.state.searchProm.productGroup}
                options={this.state.productGroups}
                onChange={this.handleAutoCompleteRequest.bind(
                  null,
                  "productGroup"
                )}
                floatingLabelText="Product Group"
                openOnFocus
                style={
                  this.props.showProduct || !showSetFields
                    ? styles.rightTextField
                    : styles.leftTextField
                }
              />
            )}
          </div>
          <br />
          <div className="rowC">
            {(this.props.showType || !showSetFields) && (
              <Select
                floatingLabelText="Promotion Type"
                value={this.state.searchProm.type}
                onChange={this.handleSelectChange.bind(null, "type")}
                fullWidth
                style={styles.leftTextField}>
                {this.state.typesJSX}
              </Select>
            )}

            {(this.props.showAssignedTo || !showSetFields) && (
              <Select
                floatingLabelText="Assigned To"
                value={this.state.searchProm.assignedTo}
                onChange={this.handleSelectChange.bind(null, "assignedTo")}
                fullWidth
                style={styles.rightTextField}>
                {companyUsersJSX}
              </Select>
            )}
          </div>
          <br />
          <div className="rowC">
            {(this.props.showStatus || !showSetFields) && (
              <Select
                floatingLabelText="Promotion Status"
                value={this.state.searchProm.status}
                onChange={this.handleSelectChange.bind(null, "status")}
                fullWidth
                multiple
                style={styles.leftTextField}>
                {this.state.statusesJSX}
              </Select>
            )}
            {(this.props.showStatus || !showSetFields) && (
              <PromotionSelect
                tags={this.props.db.meta.promotionTags}
                value={this.state.searchProm.tags}
                onChange={this.handleSelectChange.bind(
                  null,
                  "tags",
                  null,
                  null
                )}
                style={styles.rightTextField}
              />
            )}
          </div>
          <br />
          <br />
          <div className="rowC">
            {(this.props.showAttachments || !showSetFields) && (
              <Select
                floatingLabelText="Has attachments?"
                value={this.state.searchProm.attachments}
                onChange={this.handleSelectChange.bind(null, "attachments")}
                style={styles.leftTextField}>
                <MenuItem value children="With attachments" />
                <MenuItem
                  value={false}
                  children="Without attachments"
                  key={false}
                />
              </Select>
            )}
            {(this.props.showAttachments || !showSetFields) && (
              <Select
                floatingLabelText="Has contracts?"
                value={this.state.searchProm.contracts}
                onChange={this.handleSelectChange.bind(null, "contracts")}
                style={styles.rightTextField}>
                <MenuItem value children="Yes" />
                <MenuItem value={false} children="No" key={false} />
              </Select>
            )}
          </div>
          <br />
          <div className="rowC">
            {(this.props.showStarred || !showSetFields) && (
              <Select
                floatingLabelText="Starred?"
                value={this.state.searchProm.starred}
                onChange={this.handleSelectChange.bind(null, "starred")}
                style={styles.leftTextField}>
                <MenuItem value children="Yes" />
                <MenuItem value={false} children="No" key={false} />
              </Select>
            )}
            {(this.props.showLineStatus || !showSetFields) && (
              <Select
                floatingLabelText="Line Status"
                value={this.state.searchProm.lineStatus}
                onChange={this.handleSelectChange.bind(null, "lineStatus")}
                style={styles.rightTextField}>
                <MenuItem value="open" children="Open" key="open" />
                <MenuItem value="closed" children="Closed" key="closed" />
              </Select>
            )}
          </div>
          <br />
          <div className="rowC">
            {(this.props.showMonth || !showSetFields) && (
              <Select
                floatingLabelText="Month"
                value={this.state.searchProm.month}
                onChange={this.handleSelectChange.bind(null, "month")}
                style={styles.leftTextField}>
                {this.state.monthsJSX}
              </Select>
            )}
            {(this.props.showYear || !showSetFields) && (
              <Select
                floatingLabelText="Year"
                value={this.state.searchProm.year}
                onChange={this.handleSelectChange.bind(null, "year")}
                style={styles.rightTextField}>
                {this.state.yearsJSX}
              </Select>
            )}
          </div>
          <br />
          <br />
          <div className="centering">
            <Button label="Search" onClick={this.handleSubmit} />
            <Button
              label="Reset Fields"
              variant="text"
              color="error"
              onClick={this.resetFields}
            />
          </div>
          <br />
        </div>
      </div>
    );
  }
}
