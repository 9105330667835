import React from "react";
import Menu from "@mui/material/Menu";

const IconMenu = props => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { iconButtonElement, children, style, ...other } = props;
  return (
    <>
      <div onClick={handleClick} style={style}>
        {iconButtonElement}
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClick={handleClose}
        onClose={handleClose}
        {...other}>
        {children}
      </Menu>
    </>
  );
};
IconMenu.muiName = "IconMenu";

export default IconMenu;
