import React from "react";
import { useHistory } from "react-router-dom";
import NavigationPrompt from "react-router-navigation-prompt";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "store";
import { styles } from "components/App";
import Button from "ui-library/Button";
import Dialog from "ui-library/Dialog";
import { Stack } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { DRMFeature, DRMRouteState } from "../../redux/DRMSlice";
import UploadBackupStepButtons from "./UploadBackupStepButtons";
import UploadBackupSteps from "./UploadBackupSteps";
import UploadBackupHeader from "./UploadBackupHeader";

const UploadBackup = (props: { outOfDate: boolean }) => {
  const history = useHistory();
  const { drmFeatureState, guiMode } = useSelector(
    (state: RootState) => state.drm,
    shallowEqual
  );
  const { newFiles } = useSelector(
    (state: RootState) => state.uploadBackup,
    shallowEqual
  );
  const backToBoard = () =>
    history.push(`/${drmFeatureState}/invoices/${guiMode}`);

  const confirmLeavePageDialog = (onConfirm, onCancel) => (
    <Dialog
      dialogTitle="Confirm Leave Page"
      open={newFiles.length}
      actions={
        <Stack>
          <Button
            label={"Yes, I'm sure"}
            color="error"
            variant="text"
            onClick={onConfirm}
          />
          <Button label="No, go back" variant="text" onClick={onCancel} />
        </Stack>
      }>
      <div>
        All invoices will be lost if you leave the page. Are you sure you want
        to continue?
      </div>
    </Dialog>
  );

  const hardRefreshRequiredDialog = (onConfirm, onCancel) => (
    <Dialog
      dialogTitle="Hard Refresh Required"
      open={props.outOfDate}
      actions={<Button label="Ok" onClick={onCancel} />}
      style={styles.dialogRoot}>
      <div>
        Your version of Vividly is out of date.
        <br />
        <br />
        Please save your work, then perform a hard refresh by holding Shift then
        clicking the Reload button in your browser.
      </div>
    </Dialog>
  );

  return (
    <Stack direction="column" alignItems="flex-start" sx={{ m: 2, pl: 1 }}>
      <Button
        color="primary"
        variant="text"
        startIcon={<ArrowBack />}
        label="VIEW ALL BACKUPS"
        onClick={() =>
          history.push(
            `/${DRMFeature.RECONCILIATION}/${DRMRouteState.INVOICES}/${guiMode}`
          )
        }
        sx={{ p: 0 }}
      />

      <UploadBackupHeader />
      <UploadBackupSteps />
      <UploadBackupStepButtons backToBoard={backToBoard} />

      <NavigationPrompt when={newFiles.length || props.outOfDate}>
        {({ onConfirm, onCancel }) =>
          props.outOfDate ? (
            hardRefreshRequiredDialog(onConfirm, onCancel)
          ) : newFiles.length ? (
            confirmLeavePageDialog(onConfirm, onCancel)
          ) : (
            <></>
          )
        }
      </NavigationPrompt>
    </Stack>
  );
};

export default UploadBackup;
