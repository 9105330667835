import React from "react";
import { makeKeys, isUserCresicorEmployee } from "helpers/Firebase";
import {
  Grid,
  Typography,
  Checkbox,
  Chip,
  MenuItem,
  TextField
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import CresicorButton from "ui-library/Button";
import CresicorCard from "ui-library/Card";
import CresicorDatePicker from "ui-library/DatePicker";
import CresicorMenuItem from "ui-library/MenuItem";
import {
  useBackupSources,
  BackupSourceObject
} from "deductions-constants/BackupSources";
import { Customer } from "js/dbTypes";
import _ from "lodash";
import { grey } from "@mui/material/colors";
import {
  invoiceActions,
  invoiceDependencies
} from "components/Deductions/DeductionsReconciliation/types/invoiceTypes";
import { useDb } from "contexts/Db";
import { InvoiceObject } from "components/Deductions/models";
import SaveIcon from "@mui/icons-material/Save";
import { InvoiceDisplayService } from "components/Deductions/DeductionsReconciliation/services";
import { invoiceUploadSources } from "components/Deductions/constants/ReconciliationTypes";
import CancelInvoiceButton from "../CancelInvoiceButton";

const grey500 = grey["500"];

const useStyles = makeStyles(theme => ({
  checkbox: {
    color: `${theme.palette.primary.main} !important`
  },
  unapproved: {
    color: "white",
    backgroundColor: theme.palette.error.light
  },
  approved: {
    color: "white",
    backgroundColor: theme.palette.success.light
  },
  standardChip: {
    color: "white",
    backgroundColor: grey500
  },
  buttonContainer: {
    display: "flex",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  button: {
    marginRight: theme.spacing(1)
  },
  field: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "96%"
  },
  dataDisplay: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5)
  },
  mainCard: {
    marginRight: theme.spacing(1)
  },
  fieldContainer: {
    marginTop: theme.spacing(1)
  },
  flex: {
    display: "flex"
  },
  topGrid: { display: "flex", justifyContent: "space-between" },
  link: {
    color: theme.palette.primary.main
  },
  chipLabel: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2)
  },
  profileField: {
    width: "90%"
  }
}));

interface InvoiceProfileControlsProps {
  setChangedInvoiceFields: React.Dispatch<
    React.SetStateAction<Partial<InvoiceObject>>
  >;
  invoice: InvoiceObject;
  isInvoiceCancelled: boolean;
  handleAttemptInvoiceAction: (action: invoiceActions) => void;
  canUserApproveInvoice: boolean;
  suggestedPromTypes: Record<string, string>;
  scanButtonDisabled: boolean;
  uploadInvoiceLinesButtonDisabled: boolean;
  assignedUsers: Record<string, string>;
  onSave: () => void;
  allInvoiceDependencies: Record<invoiceDependencies, boolean>;
}

export default function InvoiceProfileControls(
  props: InvoiceProfileControlsProps
) {
  const { allBackupSources } = useBackupSources();
  const db = useDb();
  const classes = useStyles();
  const {
    setChangedInvoiceFields,
    invoice,
    isInvoiceCancelled,
    handleAttemptInvoiceAction,
    canUserApproveInvoice,
    suggestedPromTypes,
    scanButtonDisabled,
    uploadInvoiceLinesButtonDisabled,
    assignedUsers,
    allInvoiceDependencies,
    onSave
  } = props;

  const backupRetrievalOnly =
    db.meta.backup_retrieval && !db.meta.deductions_reconciliation;

  const { companyUsers } = db;

  const epoch = new Date(1970, 1, 1);

  return (
    <CresicorCard
      cardContentStyle={{ paddingBottom: "10px" }}
      className={classes.mainCard}
      title="Invoice Details"
      action={
        <div className={classes.flex}>
          <div className={classes.button}>
            <CancelInvoiceButton
              invoice={invoice}
              allInvoiceDependencies={allInvoiceDependencies}
              isInvoiceCancelled={isInvoiceCancelled}
            />
          </div>
          <div className={classes.button}>
            <CresicorButton
              size="small"
              label="Save Changes"
              onClick={onSave}
              icon={<SaveIcon />}
            />
          </div>
        </div>
      }>
      <Grid container>
        <Grid item xs={12} className={classes.topGrid}>
          <Grid container>
            <Grid item xs={12} className={classes.flex}>
              {isUserCresicorEmployee() && (
                <div className={classes.flex}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={!invoice.needsCresicorApproval}
                    disabled={!canUserApproveInvoice}
                    onChange={() => {
                      handleAttemptInvoiceAction(
                        invoice.needsCresicorApproval
                          ? invoiceActions.APPROVE
                          : invoiceActions.UNAPPROVE
                      );
                    }}
                  />
                  <div className={classes.chipLabel}>
                    <Typography>Approval Status:&nbsp;</Typography>
                    <Chip
                      size="small"
                      className={
                        invoice.needsCresicorApproval
                          ? classes.unapproved
                          : classes.approved
                      }
                      label={
                        invoice.needsCresicorApproval
                          ? "Unapproved"
                          : "Approved"
                      }
                    />
                  </div>
                </div>
              )}
              <div className={classes.chipLabel}>
                <Typography>Date Imported:&nbsp;</Typography>
                <Chip
                  size="small"
                  className={classes.standardChip}
                  label={new Date(invoice.createdDate).toLocaleDateString()}
                />
              </div>
              <div className={classes.chipLabel}>
                <Typography>Upload Source:&nbsp;</Typography>
                <Chip
                  size="small"
                  className={classes.standardChip}
                  label={
                    InvoiceDisplayService.HumanReadableInvoiceUploadSources[
                      invoice.uploadSource || invoiceUploadSources.MANUAL
                    ]
                  }
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container alignItems="center" className={classes.fieldContainer}>
        <Grid xs={10} sm={6} lg={4}>
          <TextField
            className={classes.field}
            disabled
            label="Invoice Number"
            value={invoice.invoiceNumber}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid xs={10} sm={6} lg={4}>
          <TextField
            className={classes.field}
            label="Check Number"
            value={invoice.checkNumber}
            variant="outlined"
            size="small"
            disabled
          />
        </Grid>
        <Grid xs={10} sm={6} lg={4}>
          <TextField
            className={classes.field}
            select
            variant="outlined"
            size="small"
            label="Backup Source"
            onChange={event => {
              setChangedInvoiceFields(fields => ({
                ...fields,
                backupSource: event.target.value
              }));
            }}
            value={invoice.backupSource}
            disabled={Boolean(Object.keys(invoice.invoiceLines ?? {}).length)}>
            {invoice.needsCresicorApproval && (
              <CresicorMenuItem
                // eslint-disable-next-line react/no-children-prop
                children={<i>None</i>}
                value={null}
                key={null}
              />
            )}
            {Object.entries(allBackupSources)
              .sort(
                (
                  a: [string, BackupSourceObject],
                  b: [string, BackupSourceObject]
                ) => {
                  return a[1].DISPLAY_NAME < b[1].DISPLAY_NAME ? -1 : 1;
                }
              )
              .map(([sourceKey, sourceObj]: [string, BackupSourceObject]) => (
                <CresicorMenuItem
                  // eslint-disable-next-line react/no-children-prop
                  children={sourceObj.DISPLAY_NAME}
                  value={sourceKey}
                  key={sourceKey}
                />
              ))}
          </TextField>
        </Grid>
        <Grid xs={10} sm={6} lg={4}>
          <TextField
            className={classes.field}
            select
            label="Customer"
            variant="outlined"
            size="small"
            onChange={event => {
              setChangedInvoiceFields(fields => ({
                ...fields,
                customerKey: event.target.value
              }));
            }}
            value={invoice.customerKey}>
            {invoice.needsCresicorApproval && (
              <CresicorMenuItem
                // eslint-disable-next-line react/no-children-prop
                children={<i>None</i>}
                value={null}
                key={null}
              />
            )}
            {Object.entries(db.customers)
              .sort((a: [string, Customer], b: [string, Customer]) => {
                return a[1].name < b[1].name ? -1 : 1;
              })
              .map(([key, customer]: [string, Customer]) => (
                <CresicorMenuItem
                  // eslint-disable-next-line react/no-children-prop
                  children={customer.name}
                  value={key}
                  key={key}
                />
              ))}
          </TextField>
        </Grid>
        {["startDate", "endDate"].map(field => (
          <Grid xs={10} sm={6} lg={4}>
            <CresicorDatePicker
              size="small"
              className={classes.field}
              inputVariant="outlined"
              inputSize="small"
              inputStyle={{
                width: "96%"
              }}
              floatingLabelText={
                field === "startDate" ? "Start Date" : "End Date"
              }
              onChange={(date: Date) =>
                setChangedInvoiceFields(fields => ({
                  ...fields,
                  [field]: date.toLocaleDateString()
                }))
              }
              value={invoice[field] ? new Date(invoice[field]) : null}
              minDate={
                field === "startDate"
                  ? epoch
                  : new Date(invoice.startDate ?? epoch)
              }
              maxDate={
                field === "startDate"
                  ? _.min([new Date(), new Date(invoice.endDate)])
                  : new Date()
              }
              autoOk
            />
          </Grid>
        ))}
        <Grid xs={10} sm={6} lg={4}>
          <TextField
            select
            className={classes.field}
            label="Suggested Promotion Types"
            SelectProps={{
              multiple: true,
              value: Object.values(suggestedPromTypes),
              renderValue: (v: string[]) =>
                `Promotion Types (${v?.length ?? 0})`
            }}
            variant="outlined"
            size="small"
            onChange={event => {
              const selectVal = event.target.value;
              const newPromTypes = Object.assign(
                {},
                ...(makeKeys(selectVal.length) || []).map((key, index) => ({
                  [key]: selectVal[index]
                }))
              );
              setChangedInvoiceFields(fields => ({
                ...fields,
                suggestedPromTypes: newPromTypes
              }));
            }}>
            {Object.entries(db.meta.fundTypes ?? {})
              .map(([key, fundType]) => ({ key, name: fundType.name }))
              .sort((fundType1, fundType2) =>
                fundType1.name.localeCompare(fundType2.name)
              )
              .map((fundType, _fundTypeIdx) => {
                return (
                  <MenuItem
                    value={fundType.key}
                    selected={Object.values(suggestedPromTypes).includes(
                      fundType.key
                    )}
                    key={fundType.key}>
                    {fundType.name}
                  </MenuItem>
                );
              })}
          </TextField>
        </Grid>
        <Grid xs={10} sm={6} lg={4}>
          <TextField
            select
            className={classes.field}
            variant="outlined"
            size="small"
            label="Assigned User(s)"
            SelectProps={{
              multiple: true,
              value: Object.values(assignedUsers),
              renderValue: (v: string[]) =>
                v.map(uid => companyUsers[uid]?.name).join(", ")
            }}
            onChange={event => {
              const selectVal = event.target.value;
              const newAssignedUsers = Object.assign(
                {},
                ...(makeKeys(selectVal.length) || []).map((key, index) => ({
                  [key]: selectVal[index]
                }))
              );
              setChangedInvoiceFields(fields => ({
                ...fields,
                assignedUsers: newAssignedUsers
              }));
            }}>
            {Object.entries(companyUsers)
              .sort((entry1, entry2) =>
                entry1[1].name.localeCompare(entry2[1].name)
              )
              .map(entry => {
                const uid = entry[0];
                const { name, email } = entry[1];
                return (
                  <MenuItem value={uid} key={uid}>
                    {name} ({email})
                  </MenuItem>
                );
              })}
          </TextField>
        </Grid>
        {invoice.originalFile.startPage && invoice.originalFile.endPage && (
          <>
            <Grid xs={10} sm={6} lg={4}>
              <TextField
                className={classes.field}
                label="File Name"
                value={invoice.originalFile.fileName}
                variant="outlined"
                size="small"
                disabled
              />
            </Grid>
            <Grid xs={10} sm={6} lg={4}>
              <TextField
                className={classes.field}
                label="Start Page"
                value={invoice.originalFile.startPage ?? ""}
                variant="outlined"
                size="small"
                disabled
              />
            </Grid>
            <Grid xs={10} sm={6} lg={4}>
              <TextField
                className={classes.field}
                label="End Page"
                value={invoice.originalFile.endPage ?? ""}
                variant="outlined"
                size="small"
                disabled
              />
            </Grid>
          </>
        )}
      </Grid>
    </CresicorCard>
  );
}
