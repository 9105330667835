import { Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ArrowBack } from "@mui/icons-material";
import React from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    margin: "1vh"
  },
  backLinkContent: {
    display: "flex",
    marginTop: "10px",
    marginBottom: "5px"
  },
  backButton: {
    marginLeft: "-10px",
    marginBottom: theme.spacing(1)
  },
  text: {
    fontSize: "12px",
    display: "flex",
    alignItems: "center"
  }
}));

interface DRMBackButtonProps {
  text: string;
  toUrl: string;
}

export default function DRMBackButton(props: DRMBackButtonProps) {
  const { text, toUrl } = props;
  const classes = useStyles();

  return (
    <Button
      size="small"
      component={Link}
      to={toUrl}
      className={classes.backButton}>
      <div className={classes.backLinkContent}>
        <ArrowBack fontSize="small" />
        <Typography className={classes.text}>{text}</Typography>
      </div>
    </Button>
  );
}
