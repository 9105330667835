import React, { useEffect } from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { List, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import MenuItem from "ui-library/MenuItem";
import Menu from "@mui/material/Menu";

interface MenuOptions {
  label: string;
  value: any;
  icon?: JSX.Element;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper
    }
  })
);

export default function SimpleListMenu(props: {
  options: MenuOptions[];
  currentIndex: number;
}) {
  const { options, currentIndex, ...other } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(currentIndex);

  useEffect(() => {
    setSelectedIndex(currentIndex);
  }, [currentIndex]);

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <List component="nav" aria-label="Device settings">
        <ListItem
          button
          aria-haspopup="true"
          aria-controls="menu"
          aria-label="menu-label"
          onClick={handleClickListItem}>
          {options[selectedIndex]?.icon && (
            <ListItemIcon>{options[selectedIndex]?.icon}</ListItemIcon>
          )}
          <ListItemText primary={options[selectedIndex]?.label} />
        </ListItem>
      </List>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        {...other}>
        {options.map((option, index) => (
          <MenuItem
            key={index}
            value={option.value}
            leftIcon={option?.icon}
            onClick={event => handleMenuItemClick(event, index)}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
