import { TableSortLabel } from "@mui/material";
import React from "react";
import TableSearch from "../TableSearch";
import {
  BASE_CELL,
  STICKY_CELL,
  HEADER_COLOR,
  CELL_VERTICAL_LINE,
  CELL_HORIZONTAL_LINE
} from "./DetailCell";

export type HeaderValues = Readonly<string[]>;

const HEADER_HORIZONTAL_LINE = (
  <div
    style={{
      ...CELL_HORIZONTAL_LINE,
      bottom: 0,
      boxShadow: "0 2px 5px 0px rgba(0,0,0,0.5)",
      zIndex: 5
    }}
  />
);

export const Header = ({
  values,
  toggleSort,
  orderBy,
  sortDir,
  searchProps,
  style,
  avf
}: {
  values: HeaderValues;
  toggleSort?: (key: string) => void;
  orderBy?: string;
  sortDir?: "asc" | "desc";
  searchProps?: React.ComponentProps<typeof TableSearch>;
  style?: React.CSSProperties;
  avf?: boolean;
}) => {
  const [first, ...rest] = values;
  const last = rest.pop();
  const coloredBase = {
    ...BASE_CELL,
    background: HEADER_COLOR
  };

  return (
    <tr>
      <th
        style={{
          ...coloredBase,
          ...STICKY_CELL,
          minWidth: 240,
          width: 240,
          top: 0,
          left: 0,
          textAlign: "left",
          fontWeight: avf ? "bold" : "normal",
          ...style
        }}>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}>
          <TableSortLabel
            onClick={() => toggleSort && toggleSort(first)}
            active={orderBy === first}
            direction={sortDir}>
            {first}
          </TableSortLabel>
          {searchProps && <TableSearch {...searchProps} />}
          {!avf && (
            <tr
              style={{
                ...CELL_VERTICAL_LINE,
                right: 0,
                boxShadow: "2px 0 5px 0px rgba(0,0,0,0.5)"
              }}
            />
          )}
        </div>
      </th>

      {rest.map(value => (
        <th
          key={value}
          style={{
            ...coloredBase,
            fontWeight: avf ? "bold" : "normal",
            ...style
          }}>
          {value}
          {!avf && (
            <div
              style={{
                ...CELL_VERTICAL_LINE,
                right: 0
              }}
            />
          )}
        </th>
      ))}

      <th
        style={
          style || {
            ...coloredBase,
            ...STICKY_CELL,
            top: 0,
            right: 0,
            fontWeight: "normal"
          }
        }>
        <TableSortLabel
          onClick={() => last && toggleSort && toggleSort(last)}
          active={orderBy === last}
          direction={sortDir}>
          {last}
        </TableSortLabel>
        {!avf && (
          <div
            style={{
              ...CELL_VERTICAL_LINE,
              left: 0,
              boxShadow: "-2px 0 5px 0px rgba(0,0,0,0.5)"
            }}
          />
        )}
      </th>
      {HEADER_HORIZONTAL_LINE}
    </tr>
  );
};
