import React, { useMemo } from "react";
import { useDb } from "contexts/Db";
import { Grid, Theme, Typography, FormControl, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import { ERPTransactionTypes } from "components/Deductions/constants/ReconciliationTypes";
import MenuItem from "ui-library/MenuItem";
import DollarFormatter from "ui-library/DollarFormatter";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import TransactionConfig from "deductions-models/TransactionConfig";
import { TransactionDisplayService } from "reconciliation-services";
import {
  setTransactionDisplayId,
  setTransactionType,
  setAccountingSource,
  setAmount,
  setInvoiceNumber,
  setCheckNumber,
  setCustomerKey,
  setTransactionDate
} from "./redux/AddTransactionSlice";
import { AddTransactionErrorMessagesMap } from "./redux/addTransactionHelpers";
import { getCustomersMenu } from "../UploadBackup/Step3/MetadataMenuItems";

const useStyles = makeStyles((theme: Theme) => ({
  headerGrid: {
    display: "flex",
    paddingBottom: theme.spacing(1)
  },
  headerGridContainer: {
    paddingBottom: theme.spacing(1)
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "90%",
    height: 60
  },
  inputGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  }
}));

export default function AddTransaction() {
  const db = useDb();
  const { customers, meta } = db;
  const accountingSources: string[] = meta.accounting_sources || [];
  const allTransactionConfigs: Record<string, TransactionConfig> =
    meta.transaction_config || {};
  const dispatch = useDispatch();
  const classes = useStyles();

  const { showErrors, errors, transaction, duplicateWarning } = useSelector(
    (state: RootState) => state.addTransaction,
    shallowEqual
  );

  const showErrorsMap = useMemo(() => {
    return {
      accountingSource: showErrors && errors.missingAccountingSource,
      type: showErrors && errors.missingTransactionType,
      transactionDisplayId:
        showErrors &&
        (errors.missingTransactionId || errors.duplicateTransactionId),
      amount:
        showErrors &&
        (errors.amountZero ||
          errors.amountNotPositive ||
          errors.amountNotNegative),
      invoiceNumber: showErrors && errors.missingInvoiceNumber,
      customerKey: showErrors && errors.missingCustomerKey,
      transactionDate:
        showErrors &&
        (errors.missingTransactionDate || errors.invalidTransactionDate)
    };
  }, [showErrors, errors]);

  const duplicationWarningMap = useMemo(() => {
    return {
      transactionDisplayId:
        duplicateWarning.criticalDuplicationFound ||
        duplicateWarning.onlyDuplicateTransactionIdFound,
      amount: duplicateWarning.criticalDuplicationFound,
      customerKey: duplicateWarning.criticalDuplicationFound,
      transactionDate: duplicateWarning.criticalDuplicationFound
    };
  }, [
    duplicateWarning.criticalDuplicationFound,
    duplicateWarning.onlyDuplicateTransactionIdFound
  ]);

  const errorTextMap = useMemo(() => {
    return {
      accountingSource:
        (showErrors &&
          errors.missingAccountingSource &&
          AddTransactionErrorMessagesMap.missingAccountingSource) ||
        null,
      type:
        (showErrors &&
          errors.missingTransactionType &&
          AddTransactionErrorMessagesMap.missingTransactionType) ||
        null,
      transactionDisplayId:
        (showErrors &&
          ((errors.missingTransactionId &&
            AddTransactionErrorMessagesMap.missingTransactionId) ||
            (errors.duplicateTransactionId &&
              AddTransactionErrorMessagesMap.duplicateTransactionId))) ||
        null,
      amount:
        (showErrors &&
          ((errors.amountZero && AddTransactionErrorMessagesMap.amountZero) ||
            (errors.amountNotPositive &&
              AddTransactionErrorMessagesMap.amountNotPositive) ||
            (errors.amountNotNegative &&
              AddTransactionErrorMessagesMap.amountNotNegative))) ||
        null,
      invoiceNumber:
        (showErrors &&
          errors.missingInvoiceNumber &&
          AddTransactionErrorMessagesMap.missingInvoiceNumber) ||
        null,
      customerKey:
        (showErrors &&
          errors.missingCustomerKey &&
          AddTransactionErrorMessagesMap.missingCustomerKey) ||
        null,
      transactionDate:
        (showErrors &&
          ((errors.missingTransactionDate &&
            AddTransactionErrorMessagesMap.missingTransactionDate) ||
            (errors.invalidTransactionDate &&
              AddTransactionErrorMessagesMap.invalidTransactionDate))) ||
        null
    };
  }, [showErrors, errors]);

  return (
    <Grid container className={classes.headerGridContainer}>
      <Grid item xs={12} className={classes.headerGrid}>
        <Typography variant="subtitle1">Transaction Metadata</Typography>
      </Grid>
      <Grid item xs={12} className={classes.headerGrid}>
        <Grid item xs={6} sm={5} md={4} lg={3} className={classes.inputGrid}>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              select
              label="Accounting Source"
              variant="outlined"
              id="erp-system"
              size="small"
              value={transaction.accountingSource}
              helperText={errorTextMap.accountingSource}
              error={showErrorsMap.accountingSource}
              onChange={event =>
                dispatch(setAccountingSource(event.target.value))
              }>
              {accountingSources.map(accountingSource => (
                <MenuItem value={accountingSource}>{accountingSource}</MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={5} md={4} lg={3} className={classes.inputGrid}>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              select
              label="Transaction Type"
              variant="outlined"
              id="transaction-type"
              size="small"
              value={transaction.type}
              helperText={errorTextMap.type}
              error={showErrorsMap.type}
              onChange={event =>
                dispatch(
                  setTransactionType({
                    type: event.target.value as ERPTransactionTypes,
                    allTransactionConfigs
                  })
                )
              }>
              {Object.values(ERPTransactionTypes)
                .filter(
                  type =>
                    ![
                      ERPTransactionTypes.BILLPAY,
                      ERPTransactionTypes.UNKNOWN
                    ].includes(type)
                ) // filter out for now
                .map(type => (
                  <MenuItem value={type} key={type} id={type}>
                    {
                      TransactionDisplayService.HumanReadableTransactionTypes[
                        type
                      ]
                    }
                  </MenuItem>
                ))}
            </TextField>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.headerGrid}>
        <Typography variant="subtitle1">Transaction Fields</Typography>
      </Grid>
      <Grid item xs={12} className={classes.headerGrid}>
        <Grid item xs={6} sm={5} md={4} lg={3} className={classes.inputGrid}>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              label="Transaction ID"
              variant="outlined"
              id="transaction-id"
              size="small"
              value={transaction.transactionDisplayId}
              helperText={errorTextMap.transactionDisplayId}
              error={
                showErrorsMap.transactionDisplayId ||
                duplicationWarningMap.transactionDisplayId
              }
              onChange={event =>
                dispatch(
                  setTransactionDisplayId({
                    displayId: event.target.value,
                    allTransactions: db.erpTransactions
                  })
                )
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={5} md={4} lg={3} className={classes.inputGrid}>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              label="Amount"
              variant="outlined"
              id="amount"
              size="small"
              InputLabelProps={{ shrink: true }}
              value={transaction.amount}
              helperText={errorTextMap.amount}
              error={showErrorsMap.amount || duplicationWarningMap.amount}
              onChange={event =>
                dispatch(
                  setAmount({
                    amount: event.target.value,
                    allTransactionConfigs
                  })
                )
              }
              InputProps={{
                inputComponent: DollarFormatter
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.headerGrid}>
        <Grid item xs={6} sm={5} md={4} lg={3} className={classes.inputGrid}>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              label="Invoice Number"
              variant="outlined"
              id="invoiceNumber"
              size="small"
              value={transaction.invoiceNumber}
              helperText={errorTextMap.invoiceNumber}
              error={showErrorsMap.invoiceNumber}
              onChange={event => dispatch(setInvoiceNumber(event.target.value))}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={5} md={4} lg={3} className={classes.inputGrid}>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              label="Check Number"
              variant="outlined"
              id="checkNumber"
              size="small"
              value={transaction.checkNumber}
              onChange={event => dispatch(setCheckNumber(event.target.value))}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.headerGrid}>
        <Grid item xs={6} sm={5} md={4} lg={3} className={classes.inputGrid}>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              select
              label="Customer Name"
              variant="outlined"
              id="customerName"
              size="small"
              value={transaction.customerKey}
              helperText={errorTextMap.customerKey}
              error={
                showErrorsMap.customerKey || duplicationWarningMap.customerKey
              }
              onChange={event => dispatch(setCustomerKey(event.target.value))}>
              {getCustomersMenu(customers)}
            </TextField>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={5} md={4} lg={3} className={classes.inputGrid}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Transaction Date"
              value={transaction.transactionDate}
              onChange={date => {
                dispatch(setTransactionDate(date));
              }}
              renderInput={params => (
                <FormControl className={classes.formControl}>
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    variant="outlined"
                    size="small"
                    id="transactionDate"
                    error={
                      showErrorsMap.transactionDate ||
                      duplicationWarningMap.transactionDate
                    }
                    helperText={errorTextMap.transactionDate}
                  />
                </FormControl>
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </Grid>
  );
}
