enum checkStatuses {
  NEW = "new",
  IN_PROGRESS = "in_progress",
  NEEDS_ATTENTION = "needs_attention", // catch all for at least one invoice is marked as "needs attention"
  RESOLVED = "resolved", // all invoices are resolved
  CANCELLED = "cancelled" // aka delete
}

enum invoiceStatuses {
  NEW = "new",
  IN_PROGRESS = "in_progress",
  NEEDS_ATTENTION = "needs_attention", // catch all for at least one invoice line is in dispute or sales review
  RESOLVED = "resolved", // all child invoice lines are resolved
  CANCELLED = "cancelled"
}

enum invoiceLineStatuses {
  PENDING = "pending",
  MATCH_CONFIRMED = "match_confirmed",
  MATCH_NOT_FOUND = "match_not_found",
  SALES_REVIEW = "sales_review",
  DISPUTE = "dispute",
  CLEARED = "cleared"
}

enum deductionTransactionStatuses {
  NEW = "new",
  PENDING = "pending",
  NEEDS_ATTENTION = "needs_attention",
  RESOLVED = "resolved",
  CANCELLED = "cancelled"
}

enum repaymentTransactionStatuses {
  NEW = "new",
  PENDING = "pending",
  NEEDS_ATTENTION = "needs_attention",
  RESOLVED = "resolved",
  CANCELLED = "cancelled"
}

enum billpayTransactionStatuses {
  NEW = "new",
  PENDING = "pending",
  PAID = "paid",
  CANCELLED = "cancelled"
}

enum unknownTransactionStatuses {
  NEW = "new",
  CANCELLED = "cancelled"
}

export {
  checkStatuses,
  invoiceStatuses,
  invoiceLineStatuses,
  deductionTransactionStatuses,
  repaymentTransactionStatuses,
  billpayTransactionStatuses,
  unknownTransactionStatuses
};
