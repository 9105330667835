import {
  TextField,
  Stack,
  Button,
  Typography,
  Autocomplete
} from "@mui/material";
import { InvoiceLineObject, InvoiceObject } from "components/Deductions/models";
import { Customer, Product } from "js/dbTypes";
import React, { useCallback, useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import CollapsibleTable, {
  ColumnConfigOptions
} from "ui-library/CollapsibleTable/CollapsibleTable";
import DollarFormatter from "ui-library/DollarFormatter";
import { useOpenClose } from "contexts/OpenClose";
import IconButton from "ui-library/IconButton";
import {
  Delete as DeleteIcon,
  ContentCopy as CopyIcon,
  Abc as LetterIcon,
  Numbers as NumberIcon
} from "@mui/icons-material";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { styled } from "@mui/styles";
import { makeKeys, firebase } from "helpers/Firebase";
import { updateFirebaseInvoice } from "components/Deductions/DeductionsReconciliation/services/InvoiceServices/invoiceProcessing";
import { useSnackbar } from "notistack";
import { useDRMEvents } from "contexts/DRMEvents";
import { DRMEventType } from "components/Deductions/DeductionsReconciliation/ActivityLog/DRMEvent";
import {
  addInvoiceLines,
  copyFieldDownwards,
  NewInvoiceLineErrorState,
  removeSingleInvoiceLine,
  resetAddInvoiceLines,
  resetInvoiceLineNumbers,
  setInvoiceKey,
  setInvoiceLineAmount,
  setInvoiceLineCustomerKey,
  setInvoiceLineEndDate,
  setInvoiceLineProductKey,
  setInvoiceLineSpendRate,
  setSearchByProductName,
  setShowErrors
} from "./redux/addInvoiceLinesSlice";
import { addInvoiceLinesErrorsMessages } from "./redux/addInvoiceLinesHelpers";

const SpendRateAmountTextField = styled(TextField)(() => ({
  width: 120
}));

const CustomerProductTextField = styled(TextField)(() => ({
  width: 200
}));

const EndDateTextField = styled(TextField)(() => ({
  width: 160
}));

const MAX_NUM_INVOICE_LINES = 20;
const ADD_INVOICE_LINES_BATCH_SIZE = 5;

// Used primarily for indexing into the redux store
export interface AddInvoiceLineObject {
  invoiceLineNumber: number;
  spendRate?: number;
  amount?: number;
  productKey?: string;
  customerKey?: string;
  endDate?: string;
}

export interface AddInvoiceLinesProps {
  invoice: InvoiceObject;
  customers: Record<string, Customer>;
  products: Record<string, Product>;
}

export interface ProductOption {
  code: string;
  key: string;
}

export default function AddInvoiceLines(
  props: AddInvoiceLinesProps
): JSX.Element {
  const { invoice, products = {}, customers = {} } = props;

  const dispatch = useDispatch();
  const openClose = useOpenClose();
  const { closeAppModal } = openClose;
  const { enqueueSnackbar } = useSnackbar();
  const { addEvent } = useDRMEvents();

  const {
    newInvoiceLines,
    newInvoiceLinesErrors,
    showErrors,
    searchByProductName,
    productCodes
  } = useSelector((state: RootState) => state.addInvoiceLines, shallowEqual);

  useEffect(() => {
    dispatch(setInvoiceKey(invoice.key));
  }, [invoice.key, dispatch]);

  // TODO: Maybe get rid of useMemo but eslint complains...
  const existingInvoiceLines: Record<string, InvoiceLineObject> =
    useMemo(() => {
      return invoice.invoiceLines || {};
    }, [invoice]);

  const newAddInvoiceLines: AddInvoiceLineObject[] = useMemo(() => {
    return newInvoiceLines.map((invoiceLine: InvoiceLineObject) => {
      return {
        invoiceLineNumber: invoiceLine.invoiceLineNumber
      };
    });
  }, [newInvoiceLines]);

  const showErrorsMaps: Record<keyof NewInvoiceLineErrorState, boolean>[] =
    useMemo(() => {
      return newInvoiceLinesErrors.map(errorState => {
        return {
          amountZero: showErrors && errorState.amountZero,
          missingCustomerKey: showErrors && errorState.missingCustomerKey,
          missingEndDate: showErrors && errorState.missingEndDate
        };
      });
    }, [newInvoiceLinesErrors, showErrors]);

  const errorMessagesMaps: Record<
    keyof NewInvoiceLineErrorState,
    string | null
  >[] = useMemo(() => {
    return showErrorsMaps.map(showErrorRecord => {
      return {
        amountZero: showErrorRecord.amountZero
          ? addInvoiceLinesErrorsMessages.amountZero
          : null,
        missingCustomerKey: showErrorRecord.missingCustomerKey
          ? addInvoiceLinesErrorsMessages.missingCustomerKey
          : null,
        missingEndDate: showErrorRecord.missingEndDate
          ? addInvoiceLinesErrorsMessages.missingEndDate
          : null
      };
    });
  }, [showErrorsMaps]);

  const customerOptions: string[] = useMemo(() => {
    return Object.entries(customers)
      .sort((entry1, entry2) => {
        const [_key1, customer1] = entry1;
        const [_key2, customer2] = entry2;
        return customer1.name.localeCompare(customer2.name);
      })
      .map(entry => {
        const [customerKey, _customer] = entry;
        return customerKey;
      });
  }, [customers]);

  const productOptions: ProductOption[] = useMemo(() => {
    return Object.entries(products)
      .sort((entry1, entry2) => {
        const [_key1, product1] = entry1;
        const [_key2, product2] = entry2;
        return product1.name.localeCompare(product2.name);
      })
      .map(entry => {
        const [key, _product] = entry;
        return {
          key,
          code: ""
        };
      });
  }, [products]);

  const productCodeOptions: ProductOption[] = useMemo(() => {
    return Object.entries(products)
      .map(entry => {
        const [key, product] = entry;
        const { codes = [] } = product;
        return codes.map(code => {
          return {
            key,
            code
          };
        });
      })
      .reduce((allCodes, someCodes) => {
        return [...allCodes, ...someCodes];
      })
      .sort((entry1, entry2) => {
        return entry1.code.localeCompare(entry2.code);
      });
  }, [products]);

  const addInvoiceLinesColumnConfig = useMemo(() => {
    return new Map([
      [
        "customerKey",
        {
          name: (
            <Stack direction="row" justifyContent="space-between" width="200px">
              Customer
              <IconButton
                tooltip="Copy first to all rows"
                size="small"
                onClick={e => {
                  e.stopPropagation();
                  dispatch(copyFieldDownwards("customerKey"));
                }}
                data-testid="copy customer key">
                <CopyIcon />
              </IconButton>
            </Stack>
          ),
          verticalAlign: "top",
          render: (_v, addInvoiceLine) => {
            const { invoiceLineNumber: index } = addInvoiceLine;
            const { customerKey: val = "" } = newInvoiceLines[index];
            return (
              <Autocomplete
                selectOnFocus
                openOnFocus
                options={customerOptions}
                getOptionLabel={customerKey =>
                  customerKey ? customers[customerKey].name : ""
                }
                value={val}
                onChange={(_event, newValue) => {
                  dispatch(
                    setInvoiceLineCustomerKey({
                      index,
                      customerKey: newValue || ""
                    })
                  );
                }}
                renderInput={params => (
                  <CustomerProductTextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    variant="outlined"
                    size="small"
                    error={showErrorsMaps[index].missingCustomerKey}
                    helperText={errorMessagesMaps[index].missingCustomerKey}
                  />
                )}
              />
            );
          }
        }
      ],
      [
        "productKey",
        {
          name: (
            <Stack direction="row" justifyContent="space-between" width="200px">
              Product
              <IconButton
                tooltip="Copy first to all rows"
                size="small"
                onClick={e => {
                  e.stopPropagation();
                  dispatch(copyFieldDownwards("productKey"));
                }}
                data-testid="copy product key">
                <CopyIcon />
              </IconButton>
            </Stack>
          ),
          verticalAlign: "top",
          render: (_v, addInvoiceLine) => {
            const { invoiceLineNumber: index } = addInvoiceLine;
            const { productKey: val = "" } = newInvoiceLines[index];
            return (
              <Stack direction="row" justifyContent="space-between">
                <Autocomplete
                  selectOnFocus
                  openOnFocus
                  options={
                    searchByProductName[index]
                      ? productOptions
                      : productCodeOptions
                  }
                  getOptionLabel={entry => {
                    const { key: productKey, code } = entry;
                    if (!productKey) {
                      return "";
                    }
                    return searchByProductName[index] || !code
                      ? products[productKey].name
                      : `${code} (${products[productKey].name})`;
                  }}
                  value={{
                    key: val,
                    code: searchByProductName[index] ? "" : productCodes[index]
                  }}
                  onChange={(_event, newValue) => {
                    const { key: productKey = "", code: productCode = "" } =
                      newValue || {};
                    dispatch(
                      setInvoiceLineProductKey({
                        index,
                        productKey,
                        productCode
                      })
                    );
                  }}
                  renderInput={params => (
                    <CustomerProductTextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
                <IconButton
                  size="small"
                  tooltip={`Search by ${
                    searchByProductName[index] ? "Code" : "Name"
                  }`}
                  onClick={event => {
                    event.stopPropagation();
                    dispatch(
                      setSearchByProductName({
                        index,
                        value: !searchByProductName[index]
                      })
                    );
                  }}>
                  {searchByProductName[index] ? <NumberIcon /> : <LetterIcon />}
                </IconButton>
              </Stack>
            );
          }
        }
      ],
      [
        "spendRate",
        {
          name: (
            <Stack direction="row" justifyContent="space-between" width="120px">
              Spend Rate
              <IconButton
                tooltip="Copy first to all rows"
                size="small"
                onClick={e => {
                  e.stopPropagation();
                  dispatch(copyFieldDownwards("spendRate"));
                }}
                data-testid="copy spend rate">
                <CopyIcon />
              </IconButton>
            </Stack>
          ),
          verticalAlign: "top",
          render: (_v, addInvoiceLine) => {
            const { invoiceLineNumber: index } = addInvoiceLine;
            const { spendRate: val = 0 } = newInvoiceLines[index];
            return (
              <SpendRateAmountTextField
                variant="outlined"
                size="small"
                value={val}
                InputProps={{
                  inputComponent: DollarFormatter,
                  inputProps: { showNegative: false }
                }}
                onChange={event => {
                  const spendRate = parseFloat(event.target.value).toString();
                  dispatch(setInvoiceLineSpendRate({ index, spendRate }));
                }}
              />
            );
          }
        }
      ],
      [
        "amount",
        {
          name: (
            <Stack direction="row" justifyContent="space-between" width="120px">
              Amount
              <IconButton
                tooltip="Copy first to all rows"
                size="small"
                onClick={e => {
                  e.stopPropagation();
                  dispatch(copyFieldDownwards("amount"));
                }}
                data-testid="copy amount">
                <CopyIcon />
              </IconButton>
            </Stack>
          ),
          verticalAlign: "top",
          render: (_v, addInvoiceLine) => {
            const { invoiceLineNumber: index } = addInvoiceLine;
            const { amount: val = 0 } = newInvoiceLines[index];
            return (
              <SpendRateAmountTextField
                name="amount"
                variant="outlined"
                size="small"
                value={val}
                error={showErrorsMaps[index].amountZero}
                helperText={errorMessagesMaps[index].amountZero}
                InputProps={{
                  inputComponent: DollarFormatter,
                  inputProps: { showNegative: false }
                }}
                onChange={event => {
                  const amount = event.target.value as string;
                  dispatch(setInvoiceLineAmount({ index, amount }));
                }}
              />
            );
          }
        }
      ],
      [
        "endDate",
        {
          name: (
            <Stack direction="row" justifyContent="space-between" width="160px">
              Date
              <IconButton
                tooltip="Copy first to all rows"
                size="small"
                onClick={e => {
                  e.stopPropagation();
                  dispatch(copyFieldDownwards("endDate"));
                }}
                data-testid="copy end date">
                <CopyIcon />
              </IconButton>
            </Stack>
          ),
          verticalAlign: "top",
          render: (_v, addInvoiceLine) => {
            const { invoiceLineNumber: index } = addInvoiceLine;
            const { endDate: val = "" } = newInvoiceLines[index];
            return (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={(val as string) || null}
                  onChange={(date, dateString) => {
                    dispatch(
                      setInvoiceLineEndDate({
                        index,
                        date: date || undefined,
                        dateString: dateString || ""
                      })
                    );
                  }}
                  maxDate={new Date()}
                  renderInput={params => (
                    <EndDateTextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      variant="outlined"
                      size="small"
                      id="endDate"
                      error={showErrorsMaps[index].missingEndDate}
                      helperText={errorMessagesMaps[index].missingEndDate}
                    />
                  )}
                />
              </LocalizationProvider>
            );
          }
        }
      ],
      [
        "invoiceLineNumber",
        {
          name: "Delete",
          verticalAlign: "top",
          render: val => {
            const index = val;
            return (
              <IconButton
                disabled={newInvoiceLines.length === 1}
                onClick={e => {
                  e.stopPropagation();
                  dispatch(removeSingleInvoiceLine(index as number));
                  dispatch(resetInvoiceLineNumbers({}));
                }}
                data-testid={`delete row ${index}`}>
                <DeleteIcon />
              </IconButton>
            );
          }
        }
      ]
    ] as [keyof AddInvoiceLineObject, ColumnConfigOptions<AddInvoiceLineObject>][]);
  }, [
    dispatch,
    customers,
    products,
    newInvoiceLines,
    showErrorsMaps,
    errorMessagesMaps,
    searchByProductName,
    productCodes,
    productCodeOptions,
    customerOptions,
    productOptions
  ]);

  const errorExists = newInvoiceLinesErrors.some(errorState => {
    return Object.values(errorState).some(error => error);
  });

  const saveNewInvoiceLines = useCallback(() => {
    const processedNewInvoiceLines: Record<string, InvoiceLineObject> =
      newInvoiceLines.reduce((someInvoiceLines, invoiceLine) => {
        let newKey = "";
        const existingKeys = [
          ...Object.keys(existingInvoiceLines),
          ...Object.keys(someInvoiceLines)
        ];
        while (!newKey || existingKeys.includes(newKey)) {
          [newKey] = makeKeys(1, null, 10);
        }

        return {
          ...someInvoiceLines,
          [newKey]: {
            ...invoiceLine,
            createdDate: new Date().toISOString(),
            createdUser: firebase.auth().currentUser?.uid || "",
            startDate: invoiceLine.endDate,
            date: invoiceLine.endDate,
            invoiceLineNumber:
              invoiceLine.invoiceLineNumber +
              Object.keys(existingInvoiceLines).length,
            invoiceLineKey: newKey,
            key: newKey
          }
        };
      }, {});

    const updatedInvoice = {
      ...invoice,
      invoiceLines: {
        ...existingInvoiceLines,
        ...processedNewInvoiceLines
      }
    };

    updateFirebaseInvoice(
      updatedInvoice,
      () => {
        openClose.closeAppModal();
        enqueueSnackbar(
          `Added ${newInvoiceLines.length} new invoice line(s).`,
          {
            variant: "success"
          }
        );
      },
      () => {
        enqueueSnackbar(
          `Error with adding new invoice lines. Please try again.`,
          {
            variant: "error"
          }
        );
      }
    );
  }, [
    newInvoiceLines,
    enqueueSnackbar,
    existingInvoiceLines,
    invoice,
    openClose
  ]);

  return (
    <Stack
      width="100%"
      height="500px"
      direction="column"
      alignItems="self-start"
      justifyContent="space-between">
      <Stack
        width="100%"
        direction="column"
        alignItems="self-start"
        spacing={2}>
        <Typography>
          You may only add 20 invoice lines at a time on this page. To add more,
          submit your added lines first and then return to this screen.
        </Typography>
        <Stack width="50%" alignItems="center" spacing={2}>
          <Button
            disabled={
              newInvoiceLines.length >
              MAX_NUM_INVOICE_LINES - ADD_INVOICE_LINES_BATCH_SIZE
            }
            onClick={event => {
              event.stopPropagation();
              dispatch(addInvoiceLines(ADD_INVOICE_LINES_BATCH_SIZE));
            }}>
            Add {ADD_INVOICE_LINES_BATCH_SIZE} Lines
          </Button>
          <Button
            disabled={newInvoiceLines.length > MAX_NUM_INVOICE_LINES - 1}
            onClick={event => {
              event.stopPropagation();
              dispatch(addInvoiceLines(1));
            }}>
            Add Single Line
          </Button>
        </Stack>
        <Stack width="100%">
          <CollapsibleTable
            maxHeight="360px"
            columnConfig={addInvoiceLinesColumnConfig}
            data={newAddInvoiceLines}
          />
        </Stack>
      </Stack>
      <Stack
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}>
        <Button
          onClick={() => {
            dispatch(resetAddInvoiceLines());
            closeAppModal();
          }}>
          Cancel
        </Button>
        <Button
          onClick={event => {
            event.stopPropagation();
            if (errorExists) {
              dispatch(setShowErrors(true));
            } else {
              dispatch(setShowErrors(false));
              saveNewInvoiceLines();
              addEvent({
                type: DRMEventType.INVOICE_LINES_SAVED,
                invoiceKey: invoice.key
              });
            }
          }}>
          Submit
        </Button>
      </Stack>
    </Stack>
  );
}
