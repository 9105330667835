import React from "react";
import _ from "lodash";

/*
Input to this class will be of the form {Key1: [...], ..., Keyn: [...]}.
Output table will have Key1, ..., Keyn be the headers, and row i consists
of the ith elements of the lists corresponding to the keys.
*/

export default class CachingRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deps: this.props.deps || []
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (_.isEqual(nextProps.deps, this.state.deps)) {
      return false;
    }
    this.setState({ deps: nextProps.deps });
    return true;
  }

  render() {
    const rendered_row = this.props.row.map((item, index2) => (
      <td
        key={`td${index2}`}
        width={
          this.props.widths &&
          this.props.widths.length > index2 &&
          this.props.widths[index2]
        }>
        {item}
      </td>
    ));
    return rendered_row;
  }
}
