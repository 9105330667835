import React from "react";

import { useSelector } from "react-redux";
import { RootState } from "store";
import Card from "@mui/material/Card";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  hFlexContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "90%",
    height: "90%",
    alignSelf: "center",
    padding: "1%"
  }
}));

export default function UploadComplete() {
  const classes = useStyles();

  const { uploadError } = useSelector((state: RootState) => state.uploadBackup);

  return (
    <div>
      {!uploadError && (
        <Card className={classes.hFlexContainer}>
          <Typography variant="h4">Upload complete!</Typography>
        </Card>
      )}
      {uploadError && (
        <Card className={classes.hFlexContainer}>
          <Typography variant="h4">
            Error with upload. Please try again.
          </Typography>
        </Card>
      )}
    </div>
  );
}
