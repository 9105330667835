import { ResolutionActivityTypes } from "components/Deductions/DeductionsReconciliation/types/resolutionLineTypes";
import InvoiceLineObject from "components/Deductions/models/InvoiceLineObject";
import { FundType } from "js/dbTypes";
import { round } from "lodash";
import {
  ERPTransactionObject,
  InvoiceObject
} from "components/Deductions/models";
import { getAllInvoiceLineAmounts } from "components/Deductions/DeductionsReconciliation/services/InvoiceLineServices/invoiceLineAmount";
import { DisplayInvoiceObject } from "../../types/invoiceTypes";
import { DisplayERPTransactionObject } from "../../types/transactionTypes";

export interface ResolutionActivityAmountErrors {
  amountLessThanOrEqualZero: boolean; // activity amount is less than or equal to zero
  exceedsMaxInvoiceAmount: boolean; // activity amount exceeds the invoice open amount
  exceedsMaxTransactionAmount: boolean; // activity amount exceeds the transaction open amount
  exceedsMaxInvoiceLineAmount: boolean; // activity amount exceeds the invoice line open amount
}

export interface ResolutionActivityFundTypeErrors {
  missingFundTypeKey: boolean;
  missingFundTypeAccount: boolean;
}

export interface ResolutionActivityCommentErrors {
  missingComment: boolean;
}

export interface ResolutionLineActivityErrors {
  amountLessThanOrEqualZero?: boolean;
  exceedsMaxInvoiceAmount?: boolean;
  exceedsMaxInvoiceLineAmount?: boolean;
  exceedsMaxTransactionAmount?: boolean;
  missingFundTypeKey?: boolean;
  missingFundTypeAccount?: boolean;
  missingComment?: boolean;
}

/**
 * Function calculates which errors exist for a resolution line activity amount, and returns an
 * enum-like object where each key-value pair is an error that maps to true if an error exists
 *
 * @param activityAmount resolution amount that user specifies when trying to creating a resolution line
 * @param invoiceLine an object of type `InvoiceLineObject` that represents an invoice line
 * @param displayInvoice an object of type `DisplayInvoiceObject` that represents an invoice with display information
 * @param displayTransaction an object of type `DisplayERPTransactionObject` that represents an invoice with display information
 * @param db an object of type `DbContextValues` that represents the database models stored in Firebase
 * @return an enum-like object containing errors that map to true if they exist with the activity amount
 */
export function hasActivityAmountErrors(
  activityAmount: number | undefined = 0.0,
  invoiceLine: InvoiceLineObject,
  displayInvoice: DisplayInvoiceObject,
  displayTransaction: DisplayERPTransactionObject,
  invoices: Record<string, InvoiceObject>,
  erpTransactions: Record<string, ERPTransactionObject>
): ResolutionActivityAmountErrors {
  const updatedLineErrors = {
    amountLessThanOrEqualZero: false,
    exceedsMaxInvoiceAmount: false,
    exceedsMaxInvoiceLineAmount: false,
    exceedsMaxTransactionAmount: false
  };

  const parsedAmount = round(activityAmount, 2);

  const allInvoiceLineAmounts = getAllInvoiceLineAmounts(
    invoiceLine,
    displayInvoice,
    invoices,
    erpTransactions
  );
  const { openAmount: invoiceLineOpenAmount } = allInvoiceLineAmounts;
  const { openAmount: invoiceOpenAmount } = displayInvoice;
  const { openAmount: transactionOpenAmount } = displayTransaction;
  const smallestMaximumOpenAmount = Math.min(
    invoiceLineOpenAmount,
    invoiceOpenAmount,
    transactionOpenAmount
  );

  if (parsedAmount <= 0) {
    updatedLineErrors.amountLessThanOrEqualZero = true;
  }
  // TODO refactor this logic to be more rigid since openAmount can be negative
  else if (parsedAmount > smallestMaximumOpenAmount) {
    updatedLineErrors.exceedsMaxInvoiceAmount =
      smallestMaximumOpenAmount === invoiceOpenAmount;
    updatedLineErrors.exceedsMaxInvoiceLineAmount =
      smallestMaximumOpenAmount === invoiceLineOpenAmount;
    updatedLineErrors.exceedsMaxTransactionAmount =
      smallestMaximumOpenAmount === transactionOpenAmount;
  }

  return updatedLineErrors;
}

/**
 * Function calculates which errors exist for a resolution line fund type, and returns an
 * enum-like object where each key-value pair is an error that maps to true if an error exists
 *
 * @param activityType the activity selected by the user for resolving a line (eg: Clear, Sales Review, etc)
 * @param fundTypeKey 'undefined' or a 'string' key that maps to a fund type
 * @param fundTypes a `Record` where each string maps to a `FundType`
 * @return an enum-like object containing errors that map to true if they exist with the activity fund type
 */
export function hasWriteOffFundTypeErrors(
  activityType: ResolutionActivityTypes,
  fundTypeKey: string | undefined,
  fundTypes: Record<string, FundType>
): ResolutionActivityFundTypeErrors {
  const fundTypeAccount =
    activityType !== ResolutionActivityTypes.WRITE_OFF
      ? fundTypes?.[fundTypeKey ?? ""]?.accountKey
      : fundTypes?.[fundTypeKey ?? ""]?.writeoffAccountKey;

  const writeOffFundTypeErrors = {
    missingFundTypeKey: false,
    missingFundTypeAccount: false
  };

  if (!fundTypeKey) {
    writeOffFundTypeErrors.missingFundTypeKey = true;
  } else if (!fundTypeAccount) {
    writeOffFundTypeErrors.missingFundTypeAccount = true;
  }
  return writeOffFundTypeErrors;
}

/**
 * Function calculates which errors exist for a resolution line comment, and returns an
 * enum-like object where each key-value pair is an error that maps to true if an error exists
 *
 * @param comment `undefined` or a `string` comment that the user added when creating a resolution line
 * @param activityType the activity selected by the user for resolving a line (eg: Clear, Sales Review, etc)
 * @returns an enum-like object containing errors that map to true if they exist with the activity comment
 */
export function hasCommentErrors(
  comment: string | undefined,
  activityType: ResolutionActivityTypes
): ResolutionActivityCommentErrors {
  const commentErrors = {
    missingComment:
      activityType !== ResolutionActivityTypes.CLEAR
        ? !(comment && comment.length)
        : false
  };

  return commentErrors;
}

/**
 * Function returns an error enum object with the appropriate errors that apply for that activity type
 *
 * @param activityType the activity selected by the user for resolving a line (eg: Clear, Sales Review, etc)
 * @param resolutionLineErrors an enum-like object with all possible errors a Resolution Line Object can have
 * @returns an enum-like object containing errors that map to true if they exist with the activity type
 *
 */
export function getResolutionLineActivityErrors(
  activityType: ResolutionActivityTypes,
  resolutionLineErrors: ResolutionLineActivityErrors
): ResolutionLineActivityErrors {
  const {
    exceedsMaxInvoiceAmount,
    exceedsMaxInvoiceLineAmount,
    exceedsMaxTransactionAmount,
    missingComment,
    missingFundTypeAccount,
    missingFundTypeKey,
    amountLessThanOrEqualZero
  } = resolutionLineErrors;

  switch (activityType) {
    case ResolutionActivityTypes.CLEAR: {
      return {
        exceedsMaxInvoiceAmount,
        exceedsMaxInvoiceLineAmount,
        exceedsMaxTransactionAmount,
        amountLessThanOrEqualZero
      };
    }
    case ResolutionActivityTypes.DISPUTE: {
      return {
        missingComment
      };
    }
    case ResolutionActivityTypes.SALES_REVIEW: {
      return {
        missingComment
      };
    }
    case ResolutionActivityTypes.WRITE_OFF: {
      return {
        missingComment,
        missingFundTypeAccount,
        missingFundTypeKey,
        exceedsMaxInvoiceAmount,
        exceedsMaxInvoiceLineAmount,
        exceedsMaxTransactionAmount,
        amountLessThanOrEqualZero
      };
    }
    default: {
      return {};
    }
  }
}
