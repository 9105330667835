import React from "react";

import PriceIcon from "@mui/icons-material/MonetizationOn";

import Button from "ui-library/Button";
import IconButton from "ui-library/IconButton";
import TextField from "ui-library/TextField";

import { updateMetaFirebase, firebase } from "helpers/Firebase";
import { Formik } from "formik";
import * as yup from "yup";

import { green, grey } from "@mui/material/colors";
import { Stack } from "@mui/material";

const green700 = green["700"];
const grey500 = grey["500"];

class productGroupName extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newPg: "",
      grossMargin: "",
      newPgAltName: "",
      isPricing: false,
      nameExists: ""
    };
  }

  getValidationSchema() {
    return yup.object({
      productGroupName: yup.string().required("Product group name is required"),
      alternateProductGroupName: yup.string(),
      productMargin: yup
        .number()
        .typeError("product margin must be a number between 0.0 to 1.0")
        .min(0, "Accepts value in range of 0.0 to 1.0")
        .max(1, "Accepts value in range of 0.0 to 1.0")
    });
  }

  saveToDatabase = vals => {
    const { newPg, grossMargin, isPricing, newPgAltName } = vals;

    const { productGroups } = this.state;
    for (var key in productGroups) {
      const pg = productGroups[key];
      if (pg.name == newPg) {
        this.setState({ nameExists: "A product group with this name exists." });
        return;
      }
    }
    const productGroup = {
      name: newPg,
      grossMargin,
      isPricing,
      products: [],
      altName: newPgAltName
    };

    if (newPg !== "") {
      var { key } = firebase.database().ref().push();
      const update = {};
      update[`product_groups/${key}`] = productGroup;
      this.setState({ newPg: "" });
      updateMetaFirebase(update);
    }
    this.props.openClose.closeAppModal();
  };

  render() {
    return (
      <div>
        <Formik
          initialValues={{
            productGroupName: "",
            alternateProductGroupName: "",
            productMargin: 0,
            useForPricing: false
          }}
          validationSchema={this.getValidationSchema()}
          onSubmit={values => {
            // alert(JSON.stringify(values, null, 2));
            this.saveToDatabase({
              newPg: values.productGroupName.trim(),
              newPgAltName: values.alternateProductGroupName.trim(),
              grossMargin: values.productMargin,
              isPricing: values.useForPricing
            });
          }}>
          {formikProps => {
            return (
              <form onSubmit={formikProps.handleSubmit}>
                <div>
                  <TextField
                    floatingLabelText="New Product Group"
                    name="productGroupName"
                    fullWidth
                    value={formikProps.values.productGroupName}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    error={
                      formikProps.touched.productGroupName &&
                      Boolean(formikProps.errors.productGroupName)
                    }
                    helperText={formikProps.errors.productGroupName}
                  />
                  <TextField
                    floatingLabelText="Alternative Name"
                    name="alternateProductGroupName"
                    fullWidth
                    value={formikProps.values.alternateProductGroupName}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    error={
                      formikProps.touched.alternateProductGroupName &&
                      Boolean(formikProps.errors.alternateProductGroupName)
                    }
                    helperText={formikProps.errors.alternateProductGroupName}
                  />
                  <TextField
                    floatingLabelText="Product Margin"
                    name="productMargin"
                    fullWidth
                    value={formikProps.values.productMargin}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    error={
                      formikProps.touched.productMargin &&
                      Boolean(formikProps.errors.productMargin)
                    }
                    helperText={formikProps.errors.productMargin}
                  />
                  <br />
                  <Stack>
                    <div>Use for Pricing</div>
                    <IconButton
                      tooltip="Use for pricing"
                      onClick={() =>
                        formikProps.setFieldValue(
                          "useForPricing",
                          !formikProps.values.useForPricing
                        )
                      }
                      size="large">
                      <PriceIcon
                        style={{
                          color: formikProps.values.useForPricing
                            ? green700
                            : grey500
                        }}
                      />
                    </IconButton>
                  </Stack>
                  <br />
                  <div className="centering">
                    <Button label="Save to Database" type="submit" />
                  </div>
                  <br />
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

export default productGroupName;
