import Loading from "react-loading";
import React from "react";
import axios from "axios";
import levenshtein from "fast-levenshtein";
import RadioButtonGroup from "@mui/material/RadioGroup";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import LinearProgress from "@mui/material/LinearProgress";
import Button from "ui-library/Button";
import Checkbox from "ui-library/Checkbox";
import RadioButton from "ui-library/RadioButton";
import {
  uploadFilesToStorage,
  downloadFilesFromStorage,
  firebase,
  getFirebaseTSOnce,
  updateFirebase,
  cloudRunFunctionURL,
  CATEGORIESMAP
} from "helpers/Firebase";
import { Confetti } from "ui-library/Confetti";
import { red } from "@mui/material/colors";
import { DRMEventsContext } from "contexts/DRMEvents";
import { DRMEventType } from "components/Deductions/DeductionsReconciliation/ActivityLog/DRMEvent";
import { invoiceStatuses } from "components/Deductions/constants/ReconciliationStatuses";
import { Stack } from "@mui/material";
import FilePicker from "../WebsiteElements/FilePicker";
import Import from "./Import";
import MapData from "./MapData";
import PricingUploadCard from "./PricingUploadCard";

const red400 = red["400"];

const styles = {
  subheader: {
    marginLeft: "-15px",
    marginRight: "-50px",
    fontSize: 20
  },
  radioButtonGroup: {
    marginLeft: 100
  }
};

const ACCUMULATED_DATA_TYPES = ["Revenue", "Spend", "InvoiceLines"];

class UploadFiles extends React.Component {
  // eslint-disable-next-line react/static-property-placement
  static contextType = DRMEventsContext;

  constructor(props) {
    super(props);
    this.state = {
      selectedDataType: "Promotions", // equal to either Promotions or Sales
      finished: false,
      stepIndex: 0,
      loading: false, // status of AJAX request; to be used in loading icon
      file: null, // file we send to backend
      header: [], // the header for the file we send to backend
      revenueSources: [],
      failure: false,
      fieldColumns: {
        Spend: [
          "Line Key",
          "Customer Name",
          "Product Name",
          "Date",
          "Account",
          "Actual Spend",
          "Region",
          "Check Number"
        ],
        Products: ["Product Name", "Size", "Units Per Case", "Codes"],
        Customers: [
          "Customer Name",
          "Distributors",
          "Contact Name",
          "Other Names",
          "Class",
          "Channel",
          "P&L Group"
        ],
        Contacts: ["Company", "Email", "Name", "Title", "Phone", "Address"],
        Revenue: [
          "Date",
          "Customer Name",
          "Product Name",
          "Revenue",
          "Actual Unit Sales",
          "Stores",
          "Region",
          "Purchase Order"
        ],
        Pricing: ["Customer Name", "Product Group", "Price"],
        Lift: ["Customer Name", "Product Group", "Lift %", "Lift Bucket"],
        bumpChart: [
          "Bump Chart Customer Name",
          "Date",
          "Incr Units",
          "Base Units",
          "ARP",
          "TDP",
          "TDP Any Promo",
          "Product Grouping"
        ],
        reconciledSpend: [
          "Invoice Name",
          "Check Number",
          "Date",
          "Customer Name",
          "Product Name",
          "Actual Spend",
          "Spend Rate",
          "Line Key"
        ],
        Dispute: [
          "Date",
          "Customer Name",
          "Product Name",
          "Actual Spend",
          "Spend Rate",
          "Promotion Type",
          "Line Key",
          "Check Number"
        ],
        Seasonality: ["Product Name", "Date", "Index"],
        Budget: [
          "Customer Name",
          "Product Name",
          "Date",
          "Revenue Budget",
          "Trade Budget"
        ],
        "Latest Estimate": [
          "Customer Name",
          "Product Name",
          "Date",
          "Revenue Latest Estimate"
        ],
        "Syndicated Data": [
          "Customer Name",
          "Product Name",
          "Date",
          "Incr Units",
          "Base Units",
          "ARP"
        ],
        InvoiceLines: [
          "Customer Name",
          "Product Name",
          "Date",
          "Actual Spend",
          "Spend Rate",
          "Spend Rate (%)",
          "Month-Year"
        ],
        ERPTransactions: [
          "Transaction ID",
          "Customer Name",
          "Invoice Number",
          "Amount",
          "Transaction Date",
          "Check Number"
        ]
      }, // fields for each of the different categories of uploads
      requiredColumns: {
        Spend: ["Line Key", "Date", "Actual Spend", "Customer Name"],
        reconciledSpend: [
          "Invoice Name",
          "Date",
          "Customer Name",
          "Product Name",
          "Actual Spend"
        ],
        Products: ["Product Name"],
        Customers: ["Customer Name"],
        Contacts: ["Name"],
        Revenue: ["Date", "Customer Name", "Revenue", "Actual Unit Sales"],
        Pricing: ["Customer Name", "Product Group", "Price"],
        Lift: ["Customer Name", "Product Group", "Lift %", "Lift Bucket"],
        bumpChart: [
          "Incr Units",
          "Base Units",
          "ARP",
          "Date",
          "Bump Chart Customer Name",
          "Product Grouping"
        ],
        Dispute: [
          "Date",
          "Customer Name",
          "Product Name",
          "Actual Spend",
          "Spend Rate",
          "Promotion Type"
        ],
        Seasonality: ["Product Name", "Date", "Index"],
        Budget: ["Customer Name", "Date", "Revenue Budget", "Trade Budget"],
        "Latest Estimate": ["Customer Name", "Date", "Revenue Latest Estimate"],
        "Syndicated Data": [
          "Customer Name",
          "Product Name",
          "Date",
          "Incr Units",
          "Base Units",
          "ARP"
        ],
        InvoiceLines: [
          "Customer Name",
          "Product Name",
          "Date",
          "Actual Spend",
          "Spend Rate",
          "Spend Rate (%)",
          "Month-Year"
        ],
        ERPTransactions: [
          "Transaction ID",
          "Customer Name",
          "Invoice Number",
          "Amount",
          "Transaction Date"
        ]
      }, // required fields for each of the different categories of uploads
      converter: {
        "Expected Spend": "expSpend",
        Discount: "discount",
        "Spend Variance": "spendVariance",
        "Retail Price": "retailPrice",
        Email: "email",
        "Actual Spend": "actSpend",
        "End Date": "endDate",
        Name: "name",
        Company: "company",
        Seller: "seller",
        Status: "status",
        "Expected Sales": "expSales",
        "Total Expected Unit Sales": "totalExpSales",
        "Customer Name": "customerName",
        "Product Name": "productName",
        "Product Group": "productGroup",
        "Actual Unit Sales": "actSales",
        Title: "title",
        "Start Date": "startDate",
        "Base Price": "basePrice",
        "Total Expected Spend": "totalExpSpend",
        Date: "date",
        "Promotion Name": "name",
        "Promotion Key": "promKey",
        "Line Key": "lineKey",
        "Invoice Name": "invoiceName",
        Type: "type",
        "Spend Rate": "spendRate",
        "Spend Rate (%)": "spendRatePct",
        Distributors: "distributors",
        "Buy In Start Date": "buyinStartDate",
        "Buy In End Date": "buyinEndDate",
        "In Store Start Date": "instoreStartDate",
        "In Store End Date": "instoreEndDate",
        "Scan Back Start Date": "scanbackStartDate",
        "Scan Back End Date": "scanbackEndDate",
        Month: "month",
        "Lump Sum Spend": "lumpSumSpend",
        Year: "year",
        Size: "size",
        Phone: "phone",
        Account: "account",
        "Contact Name": "contactName",
        Revenue: "revenue",
        Codes: "codes",
        "Other Names": "otherNames",
        Sales: "sales",
        "Unit Price": "unitPrice",
        Product: "product",
        "Incr Units": "incrUnits",
        "Base Units": "baseUnits",
        ARP: "ARP",
        Price: "price",
        Channel: "channel",
        "P&L Group": "pnlGroup",
        Class: "class",
        TDP: "TDP",
        "TDP Any Promo": "TDPAnyPromo",
        Address: "address",
        "Product Grouping": "productGroupBumpChart",
        Stores: "stores",
        Index: "index",
        Region: "region",
        "Bump Chart Customer Name": "customerNameBumpChart",
        "Check Number": "checkNumber",
        "Promotion Type": "promType",
        "Lift Bucket": "liftBucket",
        "Lift %": "liftPct",
        "Units Per Case": "unitsPerCase",
        "Revenue Budget": "revenueBudget",
        "Trade Budget": "tradeBudget",
        "Revenue Latest Estimate": "revenueLatestEstimate",
        "Purchase Order": "purchaseOrder",
        "Month-Year": "monthYear",
        "Transaction ID": "transactionId",
        "Invoice Number": "invoiceNumber",
        "Transaction Date": "transactionDate",
        Amount: "amount"
      }, // input column names converted to firebase entry names
      uploadProgress: 0,
      preset: null,
      missingColumns: [],
      requiredColumnsForUpload: [], // required columns needed for upload to work
      mapData: {},
      dataFrame: {}, // the dataframe data we get from the backend
      dataFrameUpload: {}, // the dataframe data that we will upload
      selectedRows: [], // the rows of the table that are checked
      errors: [], // entries that will be highlighted in red indicating an error
      errorIndices: [], // indices of rows that contain errors
      warnings: [], // rows that will be highlighted in yellow indicating a warning
      displayUploadError: false, // display upload error message
      displayUploadWarning: false, // display upload warning message
      ignoreWarning: [], // ignore warning message and upload anyways
      highlightAllProblemRows: false, // whether to highlight all rows when displaying a warning/error message
      errorMessage: "", // error message to display
      warningMessage: "", // warning message to display
      userMatchedColumns: false, // whether or not the user matched the columns
      errorRowsDisplay: false, // whether to display rows with warnings/errors
      view: "View Accumulated Lines",
      pricingUploadDate: new Date(new Date().setHours(0, 0, 0, 0)),
      createNewTimeframes: true
    };
  }

  bottomMessage = () => {
    const matchReminder =
      {
        Revenue:
          "(*) denotes a required column. Here, at least one of 'Actual Unit Sales' or 'Revenue' must be included.",
        Budget:
          "(*) denotes a required column. Here, at least one of 'Revenue Budget' or 'Trade Budget' must be included.",
        InvoiceLines:
          "(*) denotes a required column. Here, at least one of 'Spend Rate' or 'Spend Rate (%)' must be included."
      }?.[this.state.selectedDataType] || "(*) denotes a required column.";
    if (this.state.stepIndex == 1) {
      return (
        <div className="centeringPad">
          {this.state.displayUploadError
            ? this.state.errorMessage
            : matchReminder}
        </div>
      );
    }
    return (
      <>
        <div
          className="centeringPad"
          style={{
            color: red400,
            marginTop: -20
          }}>
          <b>
            {(this.state.displayUploadError && this.state.errorMessage) ||
              (this.state.displayUploadWarning && this.state.warningMessage)}
          </b>
        </div>
        <div
          className="centeringPad"
          style={{
            color: red400,
            marginTop: -20
          }}>
          {this.state.displayUploadError && this.props.rejectOnError && (
            <>
              <br />
              This upload has errors and will NOT be saved to the database. You
              may click Finish to view further errors if any. Please fix the
              errors and re-upload.
            </>
          )}
        </div>
      </>
    );
  };

  addAnother = () => {
    this.setState({
      finished: false,
      stepIndex: 0,
      loading: false,
      file: null,
      header: [],
      dataFrame: {},
      dataFrameUpload: {},
      errors: [],
      errorIndices: [],
      selectedRows: [],
      failure: false
    });
  };

  onLoad = () => {
    // called when child component loads, and we set loading to false
    this.setState({ loading: false });
  };

  getBestMatch = header => {
    const { mapData } = this.state;
    for (const col in mapData) {
      let minDist = Infinity;
      let argMin = -1;
      for (let i = 0; i < header.length; i++) {
        const dist = levenshtein.get(col, header[i]);
        if (dist < minDist && dist < col.length / 2) {
          minDist = dist;
          argMin = i;
        }
      }
      mapData[col] = argMin >= 0 ? header[argMin] : "No Match";
    }
    this.setState({ mapData });
  };

  convertColumns = df => {
    const converted = {};
    for (const i in df) {
      const entry = df[i];
      const newEntry = {};
      for (const key in this.state.converter) {
        if (key in entry) {
          newEntry[this.state.converter[key]] = entry[key];
        }
      }
      converted[i] = newEntry;
    }

    return converted;
  };

  afterSuccess = () => {
    const { addEvent } = this.context;
    switch (this.state.selectedDataType) {
      case "InvoiceLines": {
        addEvent({
          type: DRMEventType.INVOICE_LINES_SAVED,
          invoiceKey: this.props.extraArgs?.invoiceKey ?? null
        });
        getFirebaseTSOnce(
          CATEGORIESMAP.RECON_INVOICES,
          invoice => {
            if (invoice) {
              if (this.props.extraArgs?.invoiceKey) {
                invoice.status = invoiceStatuses.IN_PROGRESS;
                updateFirebase(26, invoice, this.props.extraArgs?.invoiceKey);
              }
            }
          },
          this.props.extraArgs?.invoiceKey
        );
        break;
      }
      default:
        break;
    }

    if (this.props.afterSuccessCallback) {
      this.props.afterSuccessCallback();
    }
  };

  changeView = () => {
    const { allLinesDF, accumLinesDF } = this.state;
    if (this.state.view === "View Accumulated Lines") {
      this.resetErrorsWarnings();
      this.setState({
        view: "View All Lines",
        dataFrame: JSON.parse(JSON.stringify(allLinesDF)),
        dataFrameUpload: JSON.parse(JSON.stringify(allLinesDF)),
        selectedRows: Object.keys(allLinesDF).map(x => parseInt(x, 10))
      });
    } else {
      this.resetErrorsWarnings();
      this.setState({
        view: "View Accumulated Lines",
        dataFrame: JSON.parse(JSON.stringify(accumLinesDF)),
        dataFrameUpload: JSON.parse(JSON.stringify(accumLinesDF)),
        selectedRows: Object.keys(accumLinesDF).map(x => parseInt(x, 10))
      });
    }
  };

  getFilePickerMessage = () => {
    if (this.state.loading && this.state.uploadProgress < 100) {
      return (
        <div>
          <div>
            {`Upload ${Math.round(this.state.uploadProgress)}% Complete`}
          </div>
          <LinearProgress
            style={{ width: "60%", marginTop: 10, marginLeft: "20%" }}
            variant="determinate"
            value={Math.round(this.state.uploadProgress)}
          />
        </div>
      );
    }
    if (this.state.loading && this.state.uploadProgress === 100) {
      return <div>Processing Uploaded File...</div>;
    }
    return (
      <div>{`Drop file here to upload (${this.props.maxFileSizeMB} MB maximum size)`}</div>
    );
  };

  getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        const hasRevenueSources =
          this.state.revenueSources && this.state.revenueSources.length;
        return (
          <div>
            <FilePicker
              message={this.getFilePickerMessage()}
              onChange={files => this.setState({ file: files[0] })}
              fileTypes={["CSV", "Excel"]}
              acceptedFiles={[
                ".xlsx",
                ".xls",
                ".csv",
                ".CSV",
                "application/vnd.ms-excel",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                "text/csv"
              ]}
              maxFileSize={this.props.maxFileSizeMB << 20}
              onLoad={this.onLoad}
              selectedDataType={this.props.selectedDataType}
              promotionUpload
              componentStyle={{
                paddingLeft: "100px",
                paddingRight: "100px",
                paddingBottom: "50px",
                width: "100%"
              }}
            />
            {this.props.usePresets && hasRevenueSources ? (
              <div style={styles.radioButtonGroup}>
                <div
                  style={{
                    fontSize: 18,
                    fontFamily: "PT Sans",
                    marginBottom: 16
                  }}>
                  Select Data Source
                </div>
                <RadioButtonGroup
                  name="selectPreset"
                  onChange={(event, value) => {
                    this.setState({ preset: value });
                  }}
                  style={{ marginLeft: 16 }}>
                  {this.state.revenueSources.map(source => {
                    return (
                      <RadioButton value={source} label={source} key={source} />
                    );
                  })}
                </RadioButtonGroup>
              </div>
            ) : (
              this.props.usePresets && (
                <div
                  style={{
                    fontSize: 18,
                    fontFamily: "PT Sans",
                    marginBottom: 16,
                    color: red400
                  }}>
                  No revenue sources exist! Please add one within Settings.
                </div>
              )
            )}
          </div>
        );
      case 1:
        return (
          <MapData
            header={this.state.header}
            handleChange={this.handleDropDownChange}
            selectedDataType={this.props.selectedDataType}
            requiredColumns={
              this.state.requiredColumns[this.props.selectedDataType]
            }
            missingColumns={this.state.missingColumns}
            mapData={this.state.mapData}
            columns={this.state.fieldColumns[this.props.selectedDataType]}
            onLoad={this.onLoad}
          />
        );
      case 2:
        return (
          <Import
            data={this.state.dataFrame}
            mapData={this.state.mapData}
            selectRow={this.handleBoxCheck}
            selectedRows={this.state.selectedRows}
            selectAll={this.handleSelectAll}
            selectedDataType={this.props.selectedDataType}
            onLoad={this.onLoad}
            errors={this.state.errors}
            errorIndices={this.state.errorIndices}
            warnings={this.state.warnings}
            highlightAllProblemRows={this.state.highlightAllProblemRows}
            requiredColumns={
              this.state.requiredColumns[this.state.selectedDataType]
            }
            errorRowsDisplay={this.state.errorRowsDisplay}
            view={this.state.view}
            changeView={this.changeView}
            rejectOnError={this.props.rejectOnError}
            preset={this.state.preset}
          />
        );
      default:
        break;
    }
  };

  handleDropDownChange = (id, event) => {
    // handles change in drop down menu for MapData component
    const { mapData } = this.state;
    mapData[id] = event.target.value;
    this.setState({ mapData, userMatchedColumns: true });
  };

  handleBoxCheck = (row, isSelected, e) => {
    const { dataFrameUpload, selectedRows } = this.state;
    const index = parseInt(row.Row);
    if (isSelected) {
      dataFrameUpload[index] = row;
      selectedRows.push(index);
    } else {
      delete dataFrameUpload[index];
      selectedRows.splice(selectedRows.indexOf(index), 1);
    }
    this.setState({
      dataFrameUpload,
      selectedRows
    });
  };

  handleSelectAll = (isSelected, rows) => {
    let { dataFrameUpload, selectedRows, errorIndices } = this.state;
    const errorIndicesSet = new Set(errorIndices);
    const rowIndices = rows
      .map(x => parseInt(x.Row))
      .filter(x => !errorIndicesSet.has(x)); // only select rows that do not contain errors during upload
    const rowIndicesSet = new Set(rowIndices);
    const rowSubset = rows.filter(x => rowIndicesSet.has(parseInt(x.Row)));
    if (!isSelected) {
      dataFrameUpload = {};
      selectedRows = [];
    } else {
      dataFrameUpload = Object.assign(dataFrameUpload, rowSubset);
      selectedRows = selectedRows.concat(rowIndices);
    }
    this.setState({
      dataFrameUpload,
      selectedRows
    });
    return selectedRows;
  };

  handleErpUploadPayload(errorPayload) {
    const unknownError =
      "Unknown error occurred. Please try again or contact support if this error persists.";
    switch (errorPayload.error_type) {
      case "warning":
        if (errorPayload.warning_id === "erp_critical_duplicate_warning") {
          this.setState(prevState => ({
            loading: false,
            warnings: errorPayload.entries,
            warningMessage:
              "The highlighted rows contain duplicate transactions that already exist in the system and won't be uploaded.",
            displayUploadWarning: true,
            ignoreWarning: prevState.ignoreWarning.concat([
              errorPayload.warning_id
            ])
          }));
        } else if (errorPayload.warning_id === "erp_id_duplicate_warning") {
          this.setState(prevState => ({
            loading: false,
            warnings: errorPayload.entries,
            warningMessage:
              "The highlighted rows contain Transaction IDs that already exist in the system - Are you sure you want to add more records with the same ID?",
            displayUploadWarning: true,
            ignoreWarning: prevState.ignoreWarning.concat([
              errorPayload.warning_id
            ])
          }));
        }
        break;
      case "error":
        if (errorPayload.warning_id === "no_valid_rows_to_upload") {
          console.log("Upload contained only duplicate entries. Skipping.");
          this.setState({ loading: false, finished: true }, this.afterSuccess);
        } else {
          this.setState({
            loading: false,
            displayUploadError: true,
            errorMessage: unknownError
          });
        }
        break;
      default:
        this.setState({
          loading: false,
          displayUploadError: true,
          errorMessage: unknownError
        });
        break;
    }
  }

  resetErrorsWarnings = () => {
    this.setState({
      errors: [],
      errorIndices: [],
      warnings: [],
      displayUploadError: false,
      displayUploadWarning: false,
      errorMessage: "",
      warningMessage: ""
    });
  };

  handleNext = () => {
    const { stepIndex } = this.state;
    const unknownError =
      "Unknown error occurred. Please try again or contact support if this error persists.";
    if (stepIndex === 0) {
      this.setState({ loading: true });

      const afterUploadCallback = fileName => {
        const formData = {};
        formData.userid = this.state.userid;
        formData.file_name = fileName;
        formData.file_type = this.props.selectedDataType;
        formData.companyid = this.state.companyid;
        formData.preset = this.state.preset ? this.state.preset : "n/a";

        // call upload cloud function step 1
        axios
          .post(`${cloudRunFunctionURL}/api/upload_files_1`, {
            data: formData
          })
          .then(response => {
            let { data } = response.data;
            if ("errorMultiSheets" in data) {
              this.setState(
                {
                  loading: false,
                  displayUploadError: true,
                  errorMessage:
                    "You cannot upload files with more than one sheet."
                },
                () => {
                  if (this.props.stealthMode) {
                    this.props.openClose.setDropDownWindowVisible(true);
                  }
                }
              );
            } else {
              const { header } = data;
              const { matchColumns } = data;
              if (matchColumns) {
                this.setState({
                  mapData: Object.assign(this.state.mapData, matchColumns)
                });
              } else {
                this.getBestMatch(header); // choose best fits for the columns
                this.setState({ userMatchedColumns: true });
              }
              data = ["No Match"].concat(header);
              this.setState(
                {
                  header: data,
                  stepIndex: stepIndex + 1,
                  displayUploadError: false,
                  errorMessage: ""
                },
                () => {
                  if (this.props.stealthMode) {
                    this.handleNext();
                  }
                }
              );
            }
          })
          .catch(err => {
            this.setState(
              {
                loading: false,
                displayUploadError: true,
                errorMessage: unknownError
              },
              () => {
                if (this.props.stealthMode) {
                  this.props.openClose.setDropDownWindowVisible(true);
                }
              }
            );
          });
      };

      if (this.props.stealthMode) {
        afterUploadCallback(this.props.fileName);
      } else {
        const filePath = `${this.state.companyid}/${this.state.selectedDataType} User Uploaded Files/${this.state.file.name}`;
        uploadFilesToStorage(
          [filePath],
          [this.state.file],
          () => afterUploadCallback(this.state.file.name),
          snapshot => {
            const uploadProgress = parseInt(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            this.setState({ uploadProgress });
          }
        );
      }
    } else if (this.state.stepIndex == 1) {
      const requiredColumnsPrelim =
        this.state.requiredColumns[this.props.selectedDataType];
      let missingColumns = [];
      let requiredColumnsForUpload = [...requiredColumnsPrelim];

      for (let i = 0; i < requiredColumnsPrelim.length; i++) {
        if (this.state.mapData[requiredColumnsPrelim[i]] === "No Match") {
          missingColumns.push(requiredColumnsPrelim[i]);
        }
      }
      const specialColumnDict = {
        Revenue: ["Revenue", "Actual Unit Sales"],
        Budget: ["Revenue Budget", "Trade Budget"],
        InvoiceLines: ["Spend Rate", "Spend Rate (%)"]
      };

      if (this.props.selectedDataType in specialColumnDict) {
        specialColumnDict[this.props.selectedDataType].forEach(
          (currentColumn, index, allSpecialColumns) => {
            const otherSpecialColumns = allSpecialColumns.filter(
              specialColumn => specialColumn !== currentColumn
            );
            if (
              missingColumns.includes(currentColumn) &&
              !otherSpecialColumns.every(specialColumn =>
                missingColumns.includes(specialColumn)
              )
            ) {
              missingColumns = missingColumns.filter(
                missingColumn => missingColumn !== currentColumn
              );
              requiredColumnsForUpload = requiredColumnsForUpload.filter(
                requiredColumn => requiredColumn !== currentColumn
              );
            }
          }
        );
      }
      this.setState({
        missingColumns,
        requiredColumnsForUpload
      });
      if (missingColumns.length == 0) {
        var formData = {};
        formData.userid = this.state.userid;
        formData.companyid = this.state.companyid;
        formData.map_data = this.state.mapData;
        formData.file_type = this.props.selectedDataType;
        formData.file_name = this.state.file
          ? this.state.file.name
          : this.props.fileName;
        formData.preset = this.state.preset ? this.state.preset : "n/a";
        this.setState({ loading: true });

        // call upload cloud function step 2
        axios
          .post(`${cloudRunFunctionURL}/api/upload_files_2`, { data: formData })
          .then(response => {
            const { data } = response.data;
            if ("accumFail" in data) {
              this.setState(
                {
                  loading: false,
                  displayUploadError: true,
                  errorMessage:
                    "Accumulation operation failed. Please check to make sure the selected columns are correct."
                },
                () => {
                  if (this.props.stealthMode) {
                    this.props.openClose.setDropDownWindowVisible(true);
                  }
                }
              );
              return;
            }

            const jsonFilePath = data.json_file_path;
            const jsonFileName = data.json_file_name;
            downloadFilesFromStorage(
              [jsonFilePath],
              [jsonFileName],
              (blob, _) => {
                const fileReader = new FileReader();
                fileReader.onload = function (event) {
                  try {
                    const jsonData = JSON.parse(event.target.result);
                    if (
                      ACCUMULATED_DATA_TYPES.includes(
                        this.props.selectedDataType
                      )
                    ) {
                      var allLinesDF = jsonData["View All Lines"];
                      const accumLinesDF = jsonData["View Accumulated Lines"];
                      this.setState(
                        {
                          allLinesDF,
                          accumLinesDF,
                          dataFrame: accumLinesDF,
                          dataFrameUpload: JSON.parse(
                            JSON.stringify(accumLinesDF)
                          ), // break reference to data
                          selectedRows: Object.keys(accumLinesDF).map(x =>
                            parseInt(x)
                          ),
                          stepIndex: stepIndex + 1,
                          jsonFilePath
                        },
                        () => {
                          if (this.props.stealthMode) {
                            this.handleNext();
                          }
                        }
                      );
                    } else {
                      var allLinesDF = jsonData["View All Lines"];
                      this.setState(
                        {
                          dataFrame: allLinesDF,
                          dataFrameUpload: JSON.parse(
                            JSON.stringify(allLinesDF)
                          ), // break reference to data
                          selectedRows: Object.keys(allLinesDF).map(x =>
                            parseInt(x)
                          ),
                          stepIndex: stepIndex + 1,
                          jsonFilePath
                        },
                        () => {
                          if (this.props.stealthMode) {
                            this.handleNext();
                          }
                        }
                      );
                    }
                  } catch (err) {
                    this.setState(
                      {
                        loading: false,
                        displayUploadError: true,
                        errorMessage: unknownError
                      },
                      () => {
                        if (this.props.stealthMode) {
                          this.props.openClose.setDropDownWindowVisible(true);
                        }
                      }
                    );
                  }
                }.bind(this);
                fileReader.onerror = function (event) {
                  this.setState(
                    {
                      loading: false,
                      displayUploadError: true,
                      errorMessage: unknownError
                    },
                    () => {
                      if (this.props.stealthMode) {
                        this.props.openClose.setDropDownWindowVisible(true);
                      }
                    }
                  );
                }.bind(this);
                fileReader.readAsText(blob);
              },
              () => {
                this.setState(
                  {
                    loading: false,
                    displayUploadError: true,
                    errorMessage: unknownError
                  },
                  () => {
                    if (this.props.stealthMode) {
                      this.props.openClose.setDropDownWindowVisible(true);
                    }
                  }
                );
              }
            );
          })
          .catch(err => {
            this.setState(
              {
                loading: false,
                displayUploadError: true,
                errorMessage: unknownError
              },
              () => {
                if (this.props.stealthMode) {
                  this.props.openClose.setDropDownWindowVisible(true);
                }
              }
            );
          });
      } else {
        // At least one "no match" entry, and stealth mode is on
        if (this.props.stealthMode) {
          this.props.openClose.setDropDownWindowVisible(true);
        }
      }
    } else {
      // step === 2
      const df = this.convertColumns(this.state.dataFrameUpload);

      const extraArgs = this.props.extraArgs ?? {};
      extraArgs.pricing_upload_date = (
        this.state.pricingUploadDate ?? new Date()
      ).toLocaleDateString();
      extraArgs.create_new_timeframes = this.state.createNewTimeframes ?? true;

      var formData = {
        userid: this.state.userid,
        companyid: this.state.companyid,
        preset: this.state.preset,
        user_matched_columns: this.state.userMatchedColumns,
        map_data: this.state.mapData,
        required_columns: this.state.requiredColumnsForUpload,
        file_type: this.props.selectedDataType,
        json_file_path: this.state.jsonFilePath,
        dataType: this.state.selectedDataType,
        ignoreWarning: this.state.ignoreWarning,
        failure:
          Boolean(this.props.rejectOnError) && Boolean(this.state.failure),
        extra_args: extraArgs,
        header: this.state.header.slice(1) // forget the "No Match" entry
      };
      this.setState({
        loading: true,
        displayUploadError: false,
        displayUploadWarning: false
      });

      uploadFilesToStorage(
        [this.state.jsonFilePath],
        [new Blob([JSON.stringify(df)], { type: "application/json" })],
        () => {
          // call upload cloud function step 3
          axios
            .post(`${cloudRunFunctionURL}/api/upload_files_3`, {
              data: formData
            })
            .then(response => {
              const errorPayload = response.data.data;
              if ("error_type" in errorPayload) {
                this.resetErrorsWarnings();

                // Determine if should handle ERP Transactions special cases
                const isERPTransactions =
                  this.state.selectedDataType === "ERPTransactions";
                const isERPSpecialCase =
                  errorPayload.error_type === "warning" ||
                  (errorPayload.error_type === "error" &&
                    errorPayload.warning_id === "no_valid_rows_to_upload");

                if (isERPTransactions && isERPSpecialCase) {
                  this.handleErpUploadPayload(errorPayload);
                } else if (errorPayload.error_type == "warning_duplicate") {
                  // special warning for duplicate entries
                  this.setState(
                    {
                      loading: false,
                      warnings: errorPayload.entries,
                      warningMessage:
                        this.state.selectedDataType == "Revenue" ||
                        this.state.selectedDataType == "Spend"
                          ? "The highlighted rows contain values already entered into the database. \
                       Press Finish again to finish uploading."
                          : "The highlighted rows contain values already entered into the database. \
                       Press Finish again to overwrite.",
                      displayUploadWarning: true,
                      ignoreWarning: this.state.ignoreWarning.concat([
                        errorPayload.warning_id
                      ])
                    },
                    () => {
                      if (this.props.stealthMode) {
                        this.props.openClose.setDropDownWindowVisible(true);
                      }
                    }
                  );
                } else if (errorPayload.error_type == "warning") {
                  // general warning message
                  this.setState(
                    {
                      loading: false,
                      warnings: errorPayload.entries,
                      warningMessage: errorPayload.message,
                      highlightAllProblemRows: false,
                      displayUploadWarning: true,
                      ignoreWarning: this.state.ignoreWarning.concat([
                        errorPayload.warning_id
                      ])
                    },
                    () => {
                      if (this.props.stealthMode) {
                        this.props.openClose.setDropDownWindowVisible(true);
                      }
                    }
                  );
                } else {
                  const errorIndices = !errorPayload.highlightAllProblemRows
                    ? new Set(errorPayload.entries.map(x => x[1]))
                    : new Set(errorPayload.entries);
                  const dataFrameUpload = {};
                  for (const key in this.state.dataFrameUpload) {
                    if (!errorIndices.has(parseInt(key))) {
                      dataFrameUpload[key] = this.state.dataFrame[key];
                    }
                  }
                  this.setState(
                    {
                      loading: false,
                      errors: errorPayload.entries,
                      errorIndices: Array.from(errorIndices),
                      selectedRows: this.state.selectedRows.filter(
                        x => !errorIndices.has(x)
                      ),
                      dataFrameUpload,
                      errorMessage: errorPayload.message,
                      displayUploadError: true,
                      failure: true
                    },
                    () => {
                      if (this.props.stealthMode) {
                        this.props.openClose.setDropDownWindowVisible(true);
                      }
                    }
                  );
                }
              } else {
                this.setState(
                  { loading: false, finished: true },
                  this.afterSuccess
                );
              }
            })
            .catch(err => {
              this.setState(
                {
                  loading: false,
                  displayUploadError: true,
                  errorMessage: unknownError
                },
                () => {
                  if (this.props.stealthMode) {
                    this.props.openClose.setDropDownWindowVisible(true);
                  }
                }
              );
            });
        },
        null,
        err => {
          this.setState(
            {
              loading: false,
              displayUploadError: true,
              errorMessage: unknownError
            },
            () => {
              if (this.props.stealthMode) {
                this.props.openClose.setDropDownWindowVisible(true);
              }
            }
          );
        }
      );
    }
  };

  handlePrev = () => {
    const { stepIndex } = this.state;
    this.setState({ displayUploadError: false, errors: [], errorIndices: [] });
    if (stepIndex > 0) {
      this.setState({
        stepIndex: stepIndex - 1
      });
    }
    if (stepIndex < 2) {
      // failures can only happen on step 2
      this.setState({
        failure: false
      });
    }
  };

  componentDidMount() {
    this.setState({ selectedDataType: this.props.selectedDataType });
    if (this.props.usePresets && this.props.revenueSources) {
      this.setState({ revenueSources: this.props.revenueSources });
    }
    const user = firebase.auth().currentUser;
    firebase
      .database()
      .ref(`users/${user.uid}/company_id`)
      .on("value", snapshot => {
        if (snapshot.val()) {
          const companyid = snapshot.val();
          this.setState({ userid: user.uid, companyid }, () => {
            if (this.props.stealthMode) {
              this.handleNext();
            }
          });
        }
      });
    // set default columns
    const mapData = {};
    const columns = this.state.fieldColumns[this.props.selectedDataType];
    for (let i = 0; i < columns.length; i++) {
      mapData[columns[i]] = "No Match";
    }
    this.setState({ mapData });

    if (this.props.skipMapData) {
      this.setState({
        allLinesDF: this.props.allLinesDF,
        accumLinesDF: this.props.accumLinesDF,
        dataFrame: this.props.accumLinesDF,
        dataFrameUpload: JSON.parse(JSON.stringify(this.props.accumLinesDF)), // break reference to data
        selectedRows: Object.keys(this.props.accumLinesDF).map(x =>
          parseInt(x)
        ),
        stepIndex: 2,
        jsonFilePath: this.props.jsonFilePath
      });
    }
    // handle minimization
    this.props.openClose.setMinimizedWindowToolbar(
      <div style={{ color: "white" }}>
        {`${this.props.selectedDataType} Upload`}
      </div>
    );
  }

  componentWillUnmount() {
    this.setState({ finished: false });
  }

  render() {
    const contentStyle = {
      margin: "0 16px"
    };
    const hasRevenueSources =
      this.state.revenueSources && this.state.revenueSources.length;
    const nextButtonDisabled =
      this.state.loading ||
      (this.state.stepIndex == 0 && this.state.file == null) ||
      (this.state.stepIndex == 0 &&
        this.props.usePresets &&
        (!hasRevenueSources || !this.state.preset)) ||
      (this.state.stepIndex == 2 &&
        Object.keys(this.state.dataFrameUpload).length == 0);
    return (
      <div
        style={{
          width: "100%",
          margin: "20px auto",
          paddingLeft: "100px",
          paddingRight: "100px"
        }}>
        <Stepper activeStep={this.state.stepIndex}>
          <Step>
            <StepLabel>Upload File</StepLabel>
          </Step>
          <Step>
            <StepLabel>Map File</StepLabel>
          </Step>
          <Step>
            <StepLabel>Submit</StepLabel>
          </Step>
        </Stepper>
        <div style={contentStyle}>
          {this.props.selectedDataType === "Pricing" && (
            <>
              <PricingUploadCard
                pricingUploadDate={this.state.pricingUploadDate}
                setPricingUploadDate={newDate =>
                  this.setState({ pricingUploadDate: newDate })
                }
                createNewTimeframes={this.state.createNewTimeframes}
                setCreateNewTimeframes={value =>
                  this.setState({ createNewTimeframes: value })
                }
                stepIndex={this.state.stepIndex}
              />
              <br />
            </>
          )}
          {this.state.finished ? (
            <div>
              <div style={{ paddingLeft: "16px" }}>
                <Confetti
                  failure={this.props.rejectOnError && this.state.failure}
                />
              </div>
              <Button
                label="Click Here to add another."
                primary
                variant="text"
                onClick={this.addAnother}
              />
            </div>
          ) : (
            <div>
              {this.getStepContent(this.state.stepIndex)}
              {(this.state.displayUploadWarning ||
                this.state.displayUploadError) && (
                <Checkbox
                  label="Show rows with Warnings/Errors only"
                  disabled={
                    this.state.warnings.length == 0 &&
                    this.state.errors.length == 0
                  }
                  checked={this.state.errorRowsDisplay}
                  onChange={event => {
                    this.setState({ errorRowsDisplay: event.target.checked });
                  }}
                  color="primary"
                />
              )}
              <Stack justifyContent="center">
                <Button
                  label="Back"
                  disabled={this.state.stepIndex === 0 || this.state.loading}
                  onClick={this.handlePrev}
                  style={{
                    marginRight: 12,
                    color: "black"
                  }}
                />
                <Button
                  label={this.state.stepIndex === 2 ? "Finish" : "Next"}
                  disabled={nextButtonDisabled}
                  onClick={this.handleNext}
                />
              </Stack>
              {this.bottomMessage()}
              <div
                className="centering"
                style={{
                  marginTop: -30
                }}>
                {this.state.loading && (
                  <Loading type="bubbles" color="#000000" />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

UploadFiles.defaultProps = {
  maxFileSizeMB: 10
};

export default UploadFiles;
