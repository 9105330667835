import {
  InvoiceLineObject,
  InvoiceObject,
  ERPTransactionObject,
  RepaymentLineObject
} from "components/Deductions/models";
import { DbContextValues } from "contexts/Db";
import { ERPTransactionTypes } from "components/Deductions/constants/ReconciliationTypes";

// Returns list of repayment lines that have paid off the specific transaction of type == DEDUCTION
export function findDeductionRepaymentLines(
  deduction: ERPTransactionObject,
  erpTransactions: Record<string, ERPTransactionObject>,
  parentRepayment?: ERPTransactionObject // optional parent to filter
): RepaymentLineObject[] {
  const attachedRepayments: ERPTransactionObject[] = Object.values(
    deduction.attachedRepayments || {}
  )
    .map(repaymentKey => erpTransactions[repaymentKey || ""] || undefined)
    .filter(repayment => repayment?.type === ERPTransactionTypes.REPAYMENT)
    .filter(repayment =>
      parentRepayment ? repayment.key === parentRepayment.key : true
    ); // optional filtering

  const allRepaymentLines: RepaymentLineObject[] = attachedRepayments
    .map((repayment: ERPTransactionObject) =>
      Object.values(repayment.repaymentLines || {})
    )
    .reduce(
      (prevRepaymentLines, repaymentLines) => [
        ...prevRepaymentLines,
        ...repaymentLines
      ],
      []
    )
    .filter(repaymentLine => repaymentLine.deductionKey === deduction.key);

  return allRepaymentLines;
}

export function findRepaymentLines(db: DbContextValues) {
  const { erpTransactions } = db;
  const allRepaymentLines: RepaymentLineObject[] = Object.values(
    erpTransactions
  )
    .map(erpTransaction =>
      findDeductionRepaymentLines(erpTransaction, erpTransactions)
    )
    .reduce(
      (prevRepaymentLines, repaymentLines) => [
        ...prevRepaymentLines,
        ...repaymentLines
      ],
      []
    );
  return allRepaymentLines;
}

// Returns list of repayment lines that have paid off the specific invoice
export function findInvoiceRepaymentLines(
  invoice: InvoiceObject,
  erpTransactions: Record<string, ERPTransactionObject>,
  parentRepayment?: ERPTransactionObject // optional parent to filter
): RepaymentLineObject[] {
  const { linkedERPTransactions = {} } = invoice;

  const repaidDeductions: ERPTransactionObject[] = Object.values(
    linkedERPTransactions
  )
    .map(transactionKey => erpTransactions[transactionKey || ""] || undefined)
    .filter(transaction => transaction)
    .filter(transaction => transaction.type === ERPTransactionTypes.DEDUCTION);

  const allRepaymentLines = repaidDeductions
    .map(deduction =>
      findDeductionRepaymentLines(deduction, erpTransactions, parentRepayment)
    )
    .reduce(
      (prevRepaymentLines, repaymentLines) => [
        ...prevRepaymentLines,
        ...repaymentLines
      ],
      []
    )
    .filter(repaymentLine => repaymentLine.invoiceKey === invoice.key);

  return allRepaymentLines;
}

// Returns list of repayment lines that have paid off the specific invoice line
export function findInvoiceLineRepaymentLines(
  invoiceLine: InvoiceLineObject,
  invoices: Record<string, InvoiceObject>,
  erpTransactions: Record<string, ERPTransactionObject>,
  parentRepayment?: ERPTransactionObject // optional parent to filter
): RepaymentLineObject[] {
  const invoice: InvoiceObject = invoices[invoiceLine.invoiceKey];

  const allRepaymentLines = findInvoiceRepaymentLines(
    invoice,
    erpTransactions,
    parentRepayment
  ).filter(
    repayment =>
      repayment.invoiceLineKey === invoiceLine.invoiceLineKey ||
      repayment.invoiceLineKey === invoiceLine.key
    // TODO: Using both invoiceLineKey and key because of issues with the original schema, see InvoiceLineObject.ts
  );

  return allRepaymentLines;
}

// Returns list of deduction transactions that have been paid off by child repayment lines
export function findRepaidDeductions(
  repayment: ERPTransactionObject,
  db: DbContextValues
): ERPTransactionObject[] {
  const { erpTransactions } = db;
  const repaymentLines: Record<string, RepaymentLineObject> =
    repayment.repaymentLines || {};

  const deductionKeys = [
    ...new Set(
      Object.values(repaymentLines)
        .filter(repaymentLine => repaymentLine.deductionKey)
        .map(repaymentLine => repaymentLine.deductionKey)
    )
  ];

  return deductionKeys.map(
    deductionKey => erpTransactions[deductionKey || ""] || {}
  );
}

// Returns list of invoices that have been paid off by children repayment lines
export function findRepaidInvoices(
  repayment: ERPTransactionObject,
  db: DbContextValues
): InvoiceObject[] {
  const { invoices } = db;
  const repaymentLines: Record<string, RepaymentLineObject> =
    repayment.repaymentLines || {};

  const invoiceKeys = [
    ...new Set(
      Object.values(repaymentLines)
        .filter(repaymentLine => repaymentLine.invoiceKey)
        .map(repaymentLine => repaymentLine.invoiceKey)
    )
  ];

  return invoiceKeys.map(invoiceKey => invoices[invoiceKey || ""] || {});
}

// Returns list of invoice lines that have been paid off by children repayment lines
export function findRepaidInvoiceLines(
  repayment: ERPTransactionObject,
  db: DbContextValues
): InvoiceLineObject[] {
  const { invoices } = db;
  const repaymentLines: Record<string, RepaymentLineObject> =
    repayment.repaymentLines || {};

  const invoiceLineInfoList = [
    ...new Set(
      Object.values(repaymentLines)
        .filter(
          repaymentLine =>
            repaymentLine.invoiceKey && repaymentLine.invoiceLineKey
        )
        .map(repaymentLine => {
          return {
            invoiceKey: repaymentLine.invoiceKey || "",
            invoiceLineKey: repaymentLine.invoiceLineKey || ""
          };
        })
    )
  ];

  return invoiceLineInfoList.map(invoiceLineInfo => {
    const { invoiceKey, invoiceLineKey } = invoiceLineInfo;
    const invoice = invoices[invoiceKey || ""] || {};
    const invoiceLine = (invoice.invoiceLines || {})[invoiceLineKey] || {};

    return invoiceLine;
  });
}
