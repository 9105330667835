import {
  Button,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useDb } from "contexts/Db";
import { useDRMEvents } from "contexts/DRMEvents";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import moment from "moment";
import ActivityLogComment from "./ActivityLogComment";
import { DisplayDRMEvent } from "./DRMEvent";

const useStyles = makeStyles(theme => ({
  buttonLink: {
    textTransform: "initial"
  },
  buttonContainer: {
    margin: theme.spacing(1, 0)
  }
}));
interface UserMentionsProps {
  tags: Record<string, Record<string, number[]>>;
  tagsForUser: [string, Record<string, number[]>][];
  onLinkClick?: () => void;
  unseenEventKeys: string[];
}

export default function UserMentions(props: UserMentionsProps) {
  const classes = useStyles();
  const { tags, tagsForUser, onLinkClick, unseenEventKeys } = props;
  const { drmEvents, invoices, erpTransactions } = useDb();
  const { processEventForDisplay } = useDRMEvents();

  const [sortDir, setSortDir] = useState<"asc" | "desc">("desc");
  const [filter, setFilter] = useState<string>("");

  const onFilter = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setFilter(e.target.value);

  const getLink = (displayEvent: DisplayDRMEvent) => {
    let text = "";
    let url = "#";
    if (displayEvent.invoiceKey) {
      url = `/reconciliation/invoice/${displayEvent.invoiceKey}`;
      text = `Invoice No. ${invoices[displayEvent.invoiceKey].invoiceNumber}`;
    } else if (displayEvent.transactionKey) {
      url = `/reconciliation/transaction/${displayEvent.transactionKey}`;
      text = `Transaction ID # ${
        erpTransactions[displayEvent.transactionKey].transactionDisplayId
      }`;
    }
    return (
      <Button
        size="large"
        onClick={() => onLinkClick?.()}
        className={classes.buttonLink}
        to={url}
        component={Link}
        color="secondary">
        {text}
      </Button>
    );
  };

  const sortedTagsForUser = useMemo(
    () =>
      [...tagsForUser]
        .sort(([eventKeyA], [eventKeyB]) => {
          const a = drmEvents[sortDir === "asc" ? eventKeyA : eventKeyB];
          const b = drmEvents[sortDir === "asc" ? eventKeyB : eventKeyA];
          return moment.utc(a.createDate).diff(moment.utc(b.createDate));
        })
        .filter(([eventKey]) =>
          (drmEvents[eventKey].comment || "")
            .toUpperCase()
            .includes(filter.toUpperCase())
        ),
    [drmEvents, filter, sortDir, tagsForUser]
  );

  return (
    <div>
      <div className={classes.buttonContainer}>
        <Button
          startIcon={sortDir === "asc" ? <ArrowDownward /> : <ArrowUpward />}
          variant="outlined"
          onClick={() => setSortDir(sortDir === "asc" ? "desc" : "asc")}>
          Sort By Date
        </Button>
        &nbsp;
        <TextField
          label="Filter Mentions"
          size="small"
          variant="outlined"
          value={filter}
          onChange={onFilter}
        />
      </div>
      <List>
        {sortedTagsForUser.map(([eventKey]) => {
          const displayEvent = processEventForDisplay(drmEvents[eventKey]);
          return (
            <ListItem
              key={eventKey}
              selected={unseenEventKeys.includes(eventKey)}>
              <ListItemText
                primary={
                  <div>
                    <Typography variant="body2">
                      {moment(displayEvent.createDate).format("L hh:mm:ss a")}
                    </Typography>
                    <strong>
                      {displayEvent.user.name} mentioned you on{" "}
                      {getLink(displayEvent)}
                    </strong>
                  </div>
                }
                secondary={
                  <ActivityLogComment
                    tags={tags[eventKey]}
                    event={displayEvent}
                  />
                }
              />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
}
