import React from "react";
import { connect } from "react-redux";

import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Divider } from "pui-react-dividers";

import Button from "ui-library/Button";
import { grey } from "@mui/material/colors";
import { incrementLine, setSelectedLineIndex } from "./actions";
import AddLine from "./AddLine";

const grey700 = grey["700"];

const styles = {
  superheader: {
    fontFamily: "Ubuntu",
    marginTop: 10,
    padding: 0
  },
  subheader: {
    marginLeft: "-15px",
    fontSize: 15,
    textTransform: "uppercase"
  },
  divider: {
    margin: "30px auto",
    width: "50%"
  },
  addLine: {
    borderColor: grey700,
    borderWidth: 1,
    borderStyle: "solid",
    padding: 16,
    marginBottom: 16
  },
  paper: {
    padding: "16px 32px",
    marginBottom: 20
  },
  lineButton: {
    display: "block",
    height: 50,
    width: 50,
    minWidth: 50,
    marginLeft: -25
  }
};

function round(n) {
  return Math.round(n * 100) / 100;
}

class AddPromStep1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const lineIndex = this.props.lines.length - 1;
    if (lineIndex >= 0) {
      this.props.dispatch(setSelectedLineIndex(lineIndex));
    }
  }

  render() {
    const linesList = [];
    for (let i = 0; i < this.props.lines.length; i++) {
      var line = this.props.lines[i];
      if (this.props.promState[line]) {
        linesList.push(line);
      }
    }
    linesList.reverse();
    const selectedIndex =
      this.props.selectedIndex < linesList.length
        ? this.props.selectedIndex
        : linesList.length - 1;
    var line = linesList[selectedIndex];

    return (
      <div style={{ fontFamily: "Oxygen" }}>
        <Typography style={styles.superheader} variant="h4">
          Promotion Details
        </Typography>
        <Divider />
        <div className="row">
          <div
            className="col-lg-11"
            style={{ height: "400px", overflowY: "scroll" }}>
            {linesList.length > 0 ? (
              <AddLine
                line={line}
                index={linesList.length - selectedIndex}
                lineState={this.props.promState[line]}
                openClose={this.props.openClose}
                db={this.props.db}
                key={line}
                mode={this.props.mode}
              />
            ) : (
              <div style={{ marginBottom: 50 }}>Add line(s) to begin.</div>
            )}
          </div>
          <div className="col-lg-1">
            <div className="sticky" style={{ paddingTop: 5, marginBottom: 50 }}>
              <Stack direction="column">
                <Button
                  label={<AddIcon sx={{ fontSize: "20px" }} />}
                  onClick={() =>
                    this.props.dispatch(
                      incrementLine(true, null, this.props.db)
                    )
                  }
                  key="add"
                  color="tonal"
                />

                {linesList.map((line, index) => {
                  const lineIndex = linesList.length - index - 1; // 0-indexed
                  return index == selectedIndex ? (
                    <Button
                      label={`L${lineIndex + 1}`}
                      onClick={() =>
                        this.props.dispatch(setSelectedLineIndex(index))
                      }
                      color={
                        this.props.errorLines.has(lineIndex)
                          ? "error"
                          : "primary"
                      }
                      key={index}
                    />
                  ) : (
                    <Button
                      variant="outlined"
                      label={`L${lineIndex + 1}`}
                      color={
                        this.props.errorLines.has(lineIndex)
                          ? "error"
                          : "primary"
                      }
                      onClick={() =>
                        this.props.dispatch(setSelectedLineIndex(index))
                      }
                      key={index}
                    />
                  );
                })}
              </Stack>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const promFields = state.addPromotion;
  return {
    promState: promFields.prom,
    lines: promFields.lines,
    selectedIndex: promFields.selectedLineIndex,
    errorLines: promFields.errorLines
  };
};

export default connect(mapStateToProps)(AddPromStep1);
