import React from "react";
import "./RankedList.scss";

import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import WbSunnyIcon from "@mui/icons-material/WbSunny";

export default class RankedList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <List>
        {this.props.data && this.props.data.length > 0 ? (
          this.props.data.map((entry, index) => {
            return (
              <ListItem
                key={index}
                onClick={this.props.onClick.bind(null, entry)}>
                <div className="list-numbers"> {index + 1} </div>
                <div className="list-item-text">
                  <ListItemText
                    primary={
                      this.props.getName
                        ? this.props.getName(entry)
                        : entry.name
                    }
                    inset
                  />
                </div>
              </ListItem>
            );
          })
        ) : (
          <ListItem>
            <ListItemIcon>
              <WbSunnyIcon />
            </ListItemIcon>
            <ListItemText primary="Nothing here!" />
          </ListItem>
        )}
      </List>
    );
  }
}
