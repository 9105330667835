import React from "react";
import { sum, isEqual } from "lodash-es";
import Card from "ui-library/Card";
import MenuItem from "ui-library/MenuItem";
import Select from "ui-library/Select";
import { isAfter, isBefore, parse } from "date-fns";
import axios from "axios";
import { cloudRunURL } from "helpers/Firebase";
import { Stack } from "@mui/material";
import DataTableScrollable from "../../tables/DataTableScrollable";
import ActualsVsForecastTimeSeries from "./ActualsVsForecastTimeSeries";
import LoadingIndicator from "../LoadingIndicator";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];
const quarters = ["Q1", "Q2", "Q3", "Q4"];

const round = n => Math.round(n * 100) / 100;

class ActualsVsForecastComparison extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      granularity: "week",
      mode: "retailers",
      smoothingWeeks: 1,
      avfWeek: { Week: [], Forecast: [], Actuals: [] },
      avfMonth: {
        Month: [],
        Forecast: [],
        Actuals: [],
        Difference: [],
        Ratio: []
      },
      avfQuarter: {
        Quarter: [],
        Forecast: [],
        Actuals: [],
        Difference: [],
        Ratio: []
      },
      avfYear: {
        Year: [],
        Forecast: [],
        Actuals: [],
        Difference: [],
        Ratio: []
      },
      graphData: [],
      weeks: [],
      loading: false,
      percentComplete: 0,
      chartStartingWeek: null,
      chartEndingWeek: null
    };
  }

  smoothActuals = (actualVals, forecastBeginProduct) => {
    let smoothedVals = [];
    const forecastBeginInd = this.state.weeks.indexOf(forecastBeginProduct);
    for (let i = 0; i < forecastBeginInd; i++) {
      const smoothBeginInd = Math.max(0, i - this.state.smoothingWeeks + 1);
      const smoothedVal = Math.round(
        sum(actualVals.slice(smoothBeginInd, i + 1)) / (i - smoothBeginInd + 1)
      );
      smoothedVals.push(smoothedVal);
    }
    smoothedVals = smoothedVals.concat(actualVals.slice(forecastBeginInd));
    return smoothedVals;
  };

  numSundaysInMonth = date => {
    let sundays = 0;
    const curDate = new Date(date.getFullYear(), date.getMonth(), 1);
    while (date.getMonth() === curDate.getMonth()) {
      if (curDate.getDay() === 0) {
        sundays += 1;
        curDate.setDate(curDate.getDate() + 7);
      } else {
        curDate.setDate(curDate.getDate() + 1);
      }
    }
    return sundays;
  };

  accumulateData = (avf, forecastBeginProduct) => {
    // accumulate by week, month and quarter
    const graphData = [];
    const avfWeek = {
      Week: [],
      Forecast: [],
      Actuals: [],
      Difference: [],
      Ratio: []
    };
    const avfMonth = {
      Month: [],
      Forecast: [],
      Actuals: [],
      Difference: [],
      Ratio: []
    };
    const avfQuarter = {
      Quarter: [],
      Forecast: [],
      Actuals: [],
      Difference: [],
      Ratio: []
    };
    const avfYear = {
      Year: [],
      Forecast: [],
      Actuals: [],
      Difference: [],
      Ratio: []
    };
    const accumulatedData = {
      graphData,
      avfWeek,
      avfMonth,
      avfQuarter,
      avfYear
    };
    if (avf == null) {
      return accumulatedData;
    }
    // first accumulate by week
    const forecastSeries = avf.forecast;
    const actualsSeries = avf.actuals;
    const { weeks, chartStartingWeek, chartEndingWeek } = this.state;
    const graphWeeks = [...weeks];
    const graphActuals = graphWeeks.map(week => actualsSeries[week]);
    const graphForecast = graphWeeks.map(week => forecastSeries[week]);
    // perform smoothing on actuals
    const smoothedGraphActuals = this.smoothActuals(
      graphActuals,
      forecastBeginProduct
    );
    // calculate difference, ratio, and make graph data
    // Graph data will be for all weeks
    const graphDifference = [];
    const graphRatio = [];
    graphWeeks.forEach((_, index) => {
      const difference = smoothedGraphActuals[index] - graphForecast[index];
      const ratio = smoothedGraphActuals[index] / graphForecast[index];
      graphDifference.push(difference);
      graphRatio.push(ratio);
      graphData.push({
        Index: index,
        Week: graphWeeks[index],
        Actuals: smoothedGraphActuals[index],
        Forecast: graphForecast[index],
        Difference: difference,
        Ratio: ratio
      });
    });
    // Filter weekly data if chart has boundaries
    if (chartStartingWeek && chartEndingWeek) {
      const startingDate = parse(chartStartingWeek, "yyyy-MM-dd", new Date());
      const endingDate = parse(chartEndingWeek, "yyyy-MM-dd", new Date());
      weeks
        .filter(week => {
          const date = parse(week, "yyyy-MM-dd", new Date());
          return (
            (chartStartingWeek === week || isAfter(date, startingDate)) &&
            (chartEndingWeek === week || isBefore(date, endingDate))
          );
        })
        .forEach(week => {
          avfWeek.Week.push(week);
          const actual = actualsSeries[week];
          const forecast = forecastSeries[week];
          avfWeek.Actuals.push(actual);
          avfWeek.Forecast.push(forecast);
          const difference = actual - forecast;
          const ratio = actual / forecast;
          avfWeek.Difference.push(difference);
          avfWeek.Ratio.push(ratio);
        });
      avfWeek.Actuals = this.smoothActuals(
        avfWeek.Actuals,
        forecastBeginProduct
      );
    } else {
      // Assign data that was used for graph if no boundaries
      avfWeek.Week = graphWeeks;
      avfWeek.Actuals = smoothedGraphActuals;
      avfWeek.Forecast = graphForecast;
      avfWeek.Difference = graphDifference;
      avfWeek.Ratio = graphRatio;
    }
    // accumulate by month, quarter, and year
    const monthAccum = {};
    const quarterAccum = {};
    const yearAccum = {};
    for (let i = 0; i < avfWeek.Week.length; i++) {
      const week = avfWeek.Week[i];
      const date = new Date(week);
      const numSundaysInMonth = this.numSundaysInMonth(date);
      const monthYear = `${months[date.getMonth()]} ${date.getFullYear()}`;
      const quarterYear = `${
        quarters[parseInt(date.getMonth() / 3)]
      } ${date.getFullYear()}`;
      const year = date.getFullYear();
      for (var [increment, accumVals] of [
        [monthYear, monthAccum],
        [quarterYear, quarterAccum],
        [year, yearAccum]
      ]) {
        if (!(increment in accumVals)) {
          accumVals[increment] = {};
        }
        for (var field of ["Forecast", "Actuals"]) {
          if (!(field in accumVals[increment])) {
            accumVals[increment][field] = "N/A";
          }
          if (avfWeek[field][i] != "N/A") {
            let dataVal = avfWeek[field][i];
            if (increment === monthYear) {
              dataVal = Math.round((dataVal * 52) / 12 / numSundaysInMonth);
            }
            if (accumVals[increment][field] != "N/A") {
              accumVals[increment][field] += dataVal;
            } else {
              accumVals[increment][field] = dataVal;
            }
          }
        }
      }
    }
    // add commas to weekly table values
    // avfWeek.Week.forEach((week, i) => {
    //   avfWeek.Forecast[i] = avfWeek.Forecast[i].toLocaleString();
    //   avfWeek.Actuals[i] = avfWeek.Actuals[i].toLocaleString();
    //   avfWeek.Difference[i] = avfWeek.Difference[i].toLocaleString();
    //   avfWeek.Ratio[i] = avfWeek.Ratio[i].toLocaleString();
    // });
    // build month, quarter, and year table values
    for (var [field, granularity, accumVals] of [
      ["avfMonth", "Month", monthAccum],
      ["avfQuarter", "Quarter", quarterAccum],
      ["avfYear", "Year", yearAccum]
    ]) {
      for (var increment in accumVals) {
        const forecast = accumVals[increment].Forecast;
        const actuals = accumVals[increment].Actuals;
        accumulatedData[field][granularity].push(increment.toLocaleString());
        accumulatedData[field].Forecast.push(forecast.toLocaleString());
        accumulatedData[field].Actuals.push(actuals);
        if (forecast == "N/A" || actuals == "N/A") {
          accumulatedData[field].Difference.push("N/A");
          accumulatedData[field].Ratio.push("N/A");
        } else {
          const diff = (forecast - actuals).toLocaleString();
          const ratio = round(actuals / forecast);
          accumulatedData[field].Difference.push(diff.toLocaleString());
          accumulatedData[field].Ratio.push(ratio);
        }
      }
    }
    return accumulatedData;
  };

  createAvf = (forecastTable, historicalForecast) => {
    const { product, weeks } = this.state;
    // get products we need to add up together to generate the actuals vs. forecast
    const productGroup = this.props.db.meta.product_groups[product];
    const products = productGroup?.products ?? [product];

    const forecastBegin = forecastTable.forecastBegin || {};
    const forecastBeginProduct = new Date(forecastBegin[product] || weeks[0])
      .toISOString()
      .split("T")[0];

    const actualsFromTable = forecastTable.sales || {};

    const actuals = Object.fromEntries(
      weeks
        .map(week => [week, 0])
        .map(([week, value]) => {
          let newValue = value;
          products.forEach(p => {
            const actualTableProduct = actualsFromTable[p];
            const beforeForecastBegin =
              new Date(week) < new Date(forecastBeginProduct);

            newValue =
              actualTableProduct && beforeForecastBegin
                ? value + (actualTableProduct[week] ?? 0)
                : value;
          });
          return [week, newValue];
        })
    );

    const forecast = Object.fromEntries(
      weeks
        .map(week => [week, 0])
        .map(([week, value]) => {
          let newValue = value;
          products.forEach(p => {
            const actualTableProduct = actualsFromTable[p];
            const afterForecastBegin =
              new Date(week) >= new Date(forecastBeginProduct);

            newValue =
              actualTableProduct && afterForecastBegin
                ? value + (actualTableProduct[week] ?? 0)
                : value;

            if (!afterForecastBegin) {
              const productInHistorical = historicalForecast[p];
              newValue += productInHistorical?.[week] ?? 0;
            }
          });
          return [week, newValue];
        })
    );

    const avf = { actuals, forecast };
    return [avf, forecastBeginProduct];
  };

  getTableData = async () => {
    const { companyid } = this.props.db;
    const { customer, mode } = this.state;
    const payload = {
      company_id: companyid,
      table_name: mode,
      filter_key: customer,
      filter_by: "customer"
    };
    const forecastResponse = await axios.post(
      `${cloudRunURL}/api/read_forecast`,
      { data: payload }
    );
    const forecastTable = forecastResponse.data.data.forecast_table;
    const historicalResponse = await axios.post(
      `${cloudRunURL}/api/read_forecast`,
      {
        data: { ...payload, get_historical: true }
      }
    );
    const historicalForecast = historicalResponse.data.data.forecast_table;
    const [avf, forecastBeginProduct] = this.createAvf(
      forecastTable,
      historicalForecast
    );
    const { graphData, avfWeek, avfMonth, avfQuarter, avfYear } =
      this.accumulateData(avf, forecastBeginProduct);
    this.setState({
      graphData,
      avfWeek,
      avfMonth,
      avfQuarter,
      avfYear
    });
  };

  getSmoothingHeader = () => {
    return (
      <Stack justifyContent="space-between">
        <div>Comparison</div>
        <div>
          <Select
            floatingLabelText="Forecast Smoothing"
            size="small"
            value={this.state.smoothingWeeks}
            onChange={(event, index, value) => {
              this.setState(
                {
                  smoothingWeeks: value
                },
                this.getTableData
              );
            }}
            fullWidth
            style={{ position: "relative", width: "100%", marginTop: "4px" }}>
            <MenuItem value={1} key={1} children="No Smoothing" />
            <MenuItem value={13} key={13} children="13 Weeks Rolling Average" />
            <MenuItem value={26} key={26} children="26 Weeks Rolling Average" />
            <MenuItem value={52} key={52} children="52 Weeks Rolling Average" />
          </Select>
        </div>
      </Stack>
    );
  };

  getGranularityHeader = () => {
    return (
      <Stack justifyContent="space-between">
        <div>Actuals vs. Expected by Time Series</div>
        <div>
          <Select
            floatingLabelText="Granularity"
            size="small"
            value={this.state.granularity}
            onChange={(event, index, value) => {
              this.setState({ granularity: value });
            }}
            fullWidth
            style={{ position: "relative", width: "100%", marginTop: "4px" }}>
            <MenuItem value="week" key="week" children="Week" />
            <MenuItem value="month" key="month" children="Month (Averaged)" />
            <MenuItem value="quarter" key="quarter" children="Quarter" />
            <MenuItem value="year" key="year" children="Year" />
          </Select>
        </div>
      </Stack>
    );
  };

  componentDidMount() {
    this.setState(
      {
        customer: this.props.customer,
        product: this.props.product,
        weeks: this.props.weeks,
        mode: this.props.mode,
        loading: this.props.loading,
        percentComplete: this.props.percentComplete
      },
      this.getTableData
    );
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props, prevProps)) {
      this.setState(
        {
          customer: this.props.customer,
          product: this.props.product,
          weeks: this.props.weeks,
          mode: this.props.mode,
          loading: this.props.loading,
          percentComplete: this.props.percentComplete
        },
        this.getTableData
      );
    }
  }

  onChartZoom = ({
    startingWeek: chartStartingWeek,
    endingWeek: chartEndingWeek
  }) =>
    this.setState(
      {
        chartStartingWeek,
        chartEndingWeek
      },
      this.getTableData
    );

  render() {
    const {
      weeks,
      loading,
      percentComplete,
      granularity,
      avfWeek,
      avfMonth,
      avfQuarter,
      avfYear,
      graphData
    } = this.state;
    const data =
      {
        week: avfWeek,
        month: avfMonth,
        quarter: avfQuarter,
        year: avfYear
      }?.[granularity] || avfYear;
    let content = <></>;
    if (loading) {
      content = (
        <div className="centering">
          <LoadingIndicator
            loading
            loadingText={`${percentComplete}% Complete`}
          />
        </div>
      );
    } else if (avfWeek.Week.length === 0) {
      content = (
        <div className="centering">No data to display at this time.</div>
      );
    } else {
      content = (
        <div>
          <div className="col-lg-5">
            <DataTableScrollable
              title={this.getGranularityHeader()}
              data={data}
              height={550}
              fontSize={14}
            />
          </div>
          <div className="col-lg-7">
            <Card title={this.getSmoothingHeader()}>
              <ActualsVsForecastTimeSeries
                data={graphData}
                weeks={weeks}
                onChartZoom={this.onChartZoom}
              />
            </Card>
          </div>
        </div>
      );
    }
    return (
      <div style={{ padding: 16 }}>
        <br />
        {content}
      </div>
    );
  }
}

export default ActualsVsForecastComparison;
