import React from "react";
import { connect } from "react-redux";
import ClearIcon from "@mui/icons-material/Clear";
import Button from "ui-library/Button";
import { Stack } from "@mui/material";
import { reset } from "../AddPromotion/actions";
import { setStepIndex, proceedFromAdvDuplicationModal } from "./actions";

const styles = {
  footerContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "8px",
    borderTop: "1px solid #cbcbcb"
  }
};

const PromModalFooter = ({
  openClose: { closeAppModal },
  error,
  stepIndex,
  setStepIndex,
  reset,
  proceed
}) => {
  const stepsCompleted = stepIndex === 2;
  const changeStepperStep =
    (back, newStepIndex = null) =>
    () =>
      setStepIndex(back ? Math.max(stepIndex - 1, 0) : newStepIndex);
  return (
    <div style={styles.footerContainer}>
      <div>
        <Button
          label="Close"
          onClick={() => {
            reset();
            closeAppModal();
          }}
          icon={<ClearIcon />}
          color="error"
          variant="text"
        />
      </div>
      <Stack>
        <Button
          label="Back"
          color="tonal"
          disabled={stepIndex === 0}
          onClick={changeStepperStep(true)}
        />
        <Button
          label={stepsCompleted ? "Done" : "Next"}
          disabled={error}
          onClick={
            stepsCompleted ? proceed : changeStepperStep(false, stepIndex + 1)
          }
        />
      </Stack>
    </div>
  );
};

const mapStateToProps = state => {
  const promFields = state.addPromotion;
  return {
    stepIndex: promFields.stepIndex,
    error: promFields.advDuplicationError
  };
};

const mapDispatchToProps = dispatch => ({
  setStepIndex: (...args) => dispatch(setStepIndex(...args)),
  reset: () => dispatch(reset()),
  proceed: () => dispatch(proceedFromAdvDuplicationModal)
});

export default connect(mapStateToProps, mapDispatchToProps)(PromModalFooter);
