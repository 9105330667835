import React, { useState, useMemo, useCallback } from "react";
import {
  Grid,
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useDRMEvents } from "contexts/DRMEvents";
import { RootState } from "store";
import { firebase, makeKeys } from "helpers/Firebase";
import {
  ResolutionActivityTypes,
  ResolutionLineActivityObject
} from "components/Deductions/DeductionsReconciliation/types/resolutionLineTypes";
import {
  ERPTransactionObject,
  InvoiceLineObject,
  InvoiceObject,
  ResolutionLineObject
} from "components/Deductions/models";
import { useDb } from "contexts/Db";
import { DisplayInvoiceLineObject } from "components/Deductions/DeductionsReconciliation/types/invoiceLineTypes";
import { DisplayInvoiceObject } from "components/Deductions/DeductionsReconciliation/types/invoiceTypes";
import { DisplayERPTransactionObject } from "components/Deductions/DeductionsReconciliation/types/transactionTypes";
import CollapsibleTableSummary, {
  SummaryConfigOptions,
  CollapsibleTableSummaryType
} from "ui-library/CollapsibleTable/CollapsibleTableSummary";
import { displayUSCurrency } from "helpers/DataProcessing";
import { getInvoiceLineOpenAmount } from "components/Deductions/DeductionsReconciliation/services/InvoiceLineServices/invoiceLineAmount";
import { invoiceLineStatuses } from "components/Deductions/constants/ReconciliationStatuses";
import {
  getUpdatedInvoiceStatus,
  updateFirebaseInvoice
} from "components/Deductions/DeductionsReconciliation/services/InvoiceServices/invoiceProcessing";
import {
  getUpdatedTransactionStatus,
  updateFirebaseTransaction
} from "components/Deductions/DeductionsReconciliation/services/ERPTransactionServices/transactionProcessing";
import { useSnackbar } from "notistack";
import { round } from "lodash";
import { DRMEventType } from "components/Deductions/DeductionsReconciliation/ActivityLog/DRMEvent";
import { AddEventConfig } from "components/Deductions/DeductionsReconciliation/ActivityLog/DRMEventService";
import { getInvoiceOpenAmount } from "components/Deductions/DeductionsReconciliation/services/InvoiceServices/invoiceAmount";
import { getTransactionOpenAmount } from "components/Deductions/DeductionsReconciliation/services/ERPTransactionServices/transactionAmount";
import MultiEditScreen from "../ResolveMultipleInvoiceLines/MultiEditScreen";
import {
  setInitialResolutionLines,
  setCurrentActivityType,
  reset
} from "../ResolveMultipleInvoiceLines/redux/ResolveMultipleInvoiceLinesSlice";

interface LinkedInvoiceLinesTableControlsProps {
  invoice: InvoiceObject;
  transaction: ERPTransactionObject;
  displayInvoice: DisplayInvoiceObject;
  displayTransaction: DisplayERPTransactionObject;
  displayInvoiceLines: DisplayInvoiceLineObject[];
}

interface BatchOperationButtonElements {
  variant: "text" | "contained" | "outlined" | undefined;
  text: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  operationDisabled?: () => string | null;
}

interface InvoiceLineWithOpenAmount {
  invoiceLine: InvoiceLineObject;
  openAmount: number;
}

interface NewResolutionLinesUpdatedInvoiceLines {
  newResolutionLines: Record<string, ResolutionLineObject>;
  updatedInvoiceLines: Record<string, InvoiceLineObject>;
  activityLogEntries: Record<string, AddEventConfig>;
}

const GridContainer = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%"
}));

const ButtonGroup = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  paddingBottom: theme.spacing(1)
}));

const BatchActionButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(1)
}));

const MultiEditDialog = styled(Dialog)(() => ({
  padding: "0, 10, 0, 10"
}));

export default function LinkedInvoiceLinesTableControls(
  props: LinkedInvoiceLinesTableControlsProps
) {
  const [openMultiEditScreen, setOpenMultiEditScreen] =
    useState<boolean>(false);

  const userId = firebase.auth().currentUser?.uid || "";
  const {
    invoice,
    transaction,
    displayInvoice,
    displayTransaction,
    displayInvoiceLines
  } = props;

  const { key: invoiceKey } = invoice;
  const { key: transactionKey } = transaction;

  const db = useDb();

  const [showClearExactAmountsDialog, setShowClearExactAmountsDialog] =
    useState(false);
  const [disableClearExactAmounts, setDisableClearExactAmounts] =
    useState(false);

  const [showDialog, setShowDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  const { addEvent } = useDRMEvents();

  const {
    allLines = {},
    meta: { fundTypes = {} } = {},
    invoices = {},
    erpTransactions = {},
    companyUsers = {},
    products = {},
    customers = {}
  } = db;

  const { selectedInvoiceLines, resolutionLines } = useSelector(
    (state: RootState) => state.resolveMultipleInvoiceLines,
    shallowEqual
  );

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const disableBatchActions =
    Object.values(selectedInvoiceLines).length === 0 ||
    (selectedInvoiceLines[invoice.key] &&
      Object.values(selectedInvoiceLines[invoice.key]).length === 0);

  const initializeClearExactAmountState = () => {
    dispatch(setCurrentActivityType(ResolutionActivityTypes.CLEAR));
    dispatch(
      setInitialResolutionLines({
        invoice,
        transaction,
        activityType: ResolutionActivityTypes.CLEAR,
        userId: firebase.auth().currentUser?.uid || "",
        db
      })
    );
    setShowClearExactAmountsDialog(true);
  };

  const handleBatchActions = (activityType: ResolutionActivityTypes) => {
    dispatch(setCurrentActivityType(activityType));
    dispatch(
      setInitialResolutionLines({
        invoice,
        transaction,
        activityType,
        userId: firebase.auth().currentUser?.uid || "",
        db
      })
    );
    setOpenMultiEditScreen(true);
  };

  const generateResolutionLines: (
    clearableInvoiceLines: InvoiceLineWithOpenAmount[]
  ) => NewResolutionLinesUpdatedInvoiceLines = useCallback(
    clearableInvoiceLines => {
      return clearableInvoiceLines.reduce(
        (
          some: NewResolutionLinesUpdatedInvoiceLines,
          entry: InvoiceLineWithOpenAmount
        ) => {
          const {
            newResolutionLines: someResolutionLines,
            updatedInvoiceLines: someUpdatedInvoiceLines,
            activityLogEntries: someActivityLogEntries
          } = some;
          let newKey = "";
          const existingKeys = Object.keys(someResolutionLines);
          while (!newKey || existingKeys.includes(newKey)) {
            [newKey] = makeKeys(1, null, 10);
          }

          const { invoiceLine, openAmount } = entry;
          const invoiceLineKey =
            invoiceLine.invoiceLineKey || invoiceLine.key || "";
          const matchedPromLine = invoiceLine.matchedPromLine || "";
          const partialResolutionLine: ResolutionLineActivityObject =
            resolutionLines[invoiceKey][invoiceLineKey] || undefined;
          const fundTypeKey = allLines[matchedPromLine || ""]?.type || "";
          const fundTypeAccount = fundTypes[fundTypeKey]?.accountKey || "";
          const activityDate = new Date().toISOString();

          const newResolutionLine: ResolutionLineObject = {
            ...(partialResolutionLine || {}),
            invoiceKey,
            invoiceLineKey,
            transactionKey,
            amount: openAmount,
            promLine: matchedPromLine,
            fundTypeKey,
            fundTypeAccount,
            openedUser: userId,
            closedUser: userId,
            openedDate: activityDate,
            closedDate: activityDate,
            key: newKey
          };

          return {
            newResolutionLines: {
              ...someResolutionLines,
              [newKey]: newResolutionLine
            },
            updatedInvoiceLines: {
              ...someUpdatedInvoiceLines,
              [invoiceLineKey]: {
                ...invoiceLine,
                resolutionLines: {
                  ...invoiceLine.resolutionLines,
                  [newKey]: newKey
                },
                status: invoiceLineStatuses.CLEARED
              }
            },
            activityLogEntries: {
              ...someActivityLogEntries,
              [invoiceLineKey]: {
                invoiceKey,
                invoiceLineKey,
                transactionKey,
                type: DRMEventType.INVOICE_LINE_CLEARED,
                metadata: {
                  clearedAmount: openAmount,
                  totalInvoiceLineAmount: invoiceLine.amount,
                  clearedFundType: fundTypeKey,
                  customerKey: invoiceLine.customerKey || "",
                  productKey: invoiceLine.productKey || "",
                  productGroupKey: invoiceLine.productGroupKey || "",
                  clearedGl: fundTypeAccount
                }
              }
            }
          };
        },
        {
          newResolutionLines: {},
          updatedInvoiceLines: {},
          activityLogEntries: {}
        }
      );
    },
    [fundTypes, invoiceKey, transactionKey, userId, allLines, resolutionLines]
  );

  const clearableSelectedInvoiceLines: InvoiceLineWithOpenAmount[] =
    useMemo(() => {
      return Object.entries(selectedInvoiceLines)
        .filter(entry => {
          const [selectedInvoiceKey, _selectedInvoiceLinesGroup] = entry;
          return invoiceKey === selectedInvoiceKey;
        })
        .map(entry => {
          const [_selectedInvoiceKey, selectedInvoiceLinesGroup] = entry;
          return Object.values(selectedInvoiceLinesGroup);
        })
        .reduce((someInvoiceLines, currentInvoiceLines) => {
          return [...someInvoiceLines, ...currentInvoiceLines];
        }, [])
        .map(invoiceLine => {
          return {
            invoiceLine,
            openAmount: getInvoiceLineOpenAmount(
              invoiceLine,
              invoice,
              invoices,
              erpTransactions
            )
          };
        })
        .filter(entry => {
          const { invoiceLine, openAmount } = entry;
          const { matchedPromLine } = invoiceLine;
          return openAmount !== 0 && matchedPromLine;
        });
    }, [erpTransactions, invoice, invoiceKey, invoices, selectedInvoiceLines]);

  const handleClearExactAmounts = useCallback(() => {
    const { newResolutionLines, updatedInvoiceLines, activityLogEntries } =
      generateResolutionLines(clearableSelectedInvoiceLines);

    const totalClearedAmount = round(
      Object.values(newResolutionLines).reduce(
        (total, resolutionLine) => total + (resolutionLine.amount || 0.0),
        0.0
      ),
      2
    );

    const partialUpdatedInvoice = {
      ...invoice,
      invoiceLines: {
        ...invoice.invoiceLines,
        ...updatedInvoiceLines
      },
      resolutionLines: {
        ...(invoice.resolutionLines || {}),
        ...newResolutionLines
      }
    };

    const updatedInvoice = {
      ...partialUpdatedInvoice,
      status: getUpdatedInvoiceStatus(partialUpdatedInvoice, erpTransactions)
    };

    const updatedTransaction = {
      ...transaction,
      status: getUpdatedTransactionStatus(transaction, erpTransactions, {
        ...invoices,
        [invoiceKey]: updatedInvoice
      })
    };

    updateFirebaseInvoice(
      updatedInvoice,
      () => {
        updateFirebaseTransaction(
          updatedTransaction,
          () => {
            enqueueSnackbar(
              clearableSelectedInvoiceLines.length
                ? `Successfully cleared remaining ${displayUSCurrency(
                    totalClearedAmount
                  )} of ${clearableSelectedInvoiceLines.length} invoice line(s)`
                : `No invoice lines were cleared due to missing promotion line matches or $0.00 open amounts.`,
              {
                variant: clearableSelectedInvoiceLines.length
                  ? "success"
                  : "warning"
              }
            );

            Object.values(newResolutionLines).forEach(resolutionLine => {
              const { invoiceLineKey } = resolutionLine;
              addEvent(activityLogEntries[invoiceLineKey]);
            });

            setDisableClearExactAmounts(false);
            dispatch(reset());
          },
          () => {
            enqueueSnackbar(
              `Error with clearing selected invoice lines. Please try again`,
              { variant: "error" }
            );
            setDisableClearExactAmounts(false);
          }
        );
      },
      () => {
        enqueueSnackbar(
          `Error with clearing selected invoice lines. Please try again`,
          { variant: "error" }
        );

        setDisableClearExactAmounts(false);
      }
    );
  }, [
    generateResolutionLines,
    clearableSelectedInvoiceLines,
    invoice,
    erpTransactions,
    transaction,
    invoices,
    invoiceKey,
    enqueueSnackbar,
    dispatch,
    addEvent
  ]);

  const verifyOpenAmount = useMemo(
    () =>
      Object.values(selectedInvoiceLines[invoiceKey] || {}).every(
        invoiceLine => {
          return !!getInvoiceLineOpenAmount(
            invoiceLine,
            invoice,
            invoices,
            erpTransactions
          );
        }
      )
        ? null
        : "One or more selected invoice lines has an open amount of $0.00.",
    [selectedInvoiceLines, invoiceKey, invoice, invoices, erpTransactions]
  );

  const verifyMatch = useMemo(() => {
    const allowed = Object.values(selectedInvoiceLines[invoiceKey] || {}).every(
      invoiceLine => invoiceLine.matchedPromLine
    );
    return allowed
      ? null
      : "One or more selected invoice lines has not been matched to a promotion.";
  }, [selectedInvoiceLines, invoiceKey]);

  const verifyExceedsOpenAmount: {
    invoice: boolean;
    transaction: boolean;
  } = useMemo(() => {
    const totalAmount: number = round(
      clearableSelectedInvoiceLines.reduce((total, entry) => {
        const { openAmount = 0 } = entry;
        return total + openAmount;
      }, 0.0),
      2
    );

    return {
      invoice: totalAmount > getInvoiceOpenAmount(invoice, erpTransactions),
      transaction:
        totalAmount >
        getTransactionOpenAmount(transaction, invoices, erpTransactions)
    };
  }, [
    clearableSelectedInvoiceLines,
    invoice,
    erpTransactions,
    transaction,
    invoices
  ]);

  const getClearExactAmountsDialog = () => {
    const dialogTitle = (() => {
      if (
        verifyExceedsOpenAmount.transaction ||
        verifyExceedsOpenAmount.invoice
      ) {
        return "Error: Exceeds Open Amount";
      }
      if (verifyMatch || verifyOpenAmount) {
        return "Warning";
      }
      return "Confirm Clear All Open Amounts";
    })();

    const dialogContent = (() => {
      if (
        verifyExceedsOpenAmount.transaction ||
        verifyExceedsOpenAmount.invoice
      ) {
        return `The total clearable amount of the selected invoice lines exceeds the transaction and/or invoice open amount. 
        Revise your selection or use the batch action options to partially clear each line.`;
      }
      if (verifyMatch || verifyOpenAmount) {
        return `One or more of the selected invoice lines is missing a promotion line match or has been fully cleared. 
        Press confirm to clear the remaining lines by their exact open amount.`;
      }
      return `The selected invoice lines will be cleared by their exact open amount. Confirm to proceed.`;
    })();

    return (
      <Dialog open={showClearExactAmountsDialog}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>{dialogContent}</DialogContent>
        <DialogActions>
          <Button
            sx={
              !verifyExceedsOpenAmount.transaction &&
              !verifyExceedsOpenAmount.invoice
                ? { color: "error.light" }
                : {}
            }
            onClick={() => setShowClearExactAmountsDialog(false)}>
            {verifyExceedsOpenAmount.transaction ||
            verifyExceedsOpenAmount.invoice
              ? "OK"
              : "Cancel"}
          </Button>
          {!verifyExceedsOpenAmount.transaction &&
            !verifyExceedsOpenAmount.invoice && (
              <Button
                disabled={disableClearExactAmounts}
                onClick={() => {
                  setDisableClearExactAmounts(true);
                  setShowClearExactAmountsDialog(false);
                  handleClearExactAmounts();
                }}>
                Confirm
              </Button>
            )}
        </DialogActions>
      </Dialog>
    );
  };

  const buttonConfs: BatchOperationButtonElements[] = [
    {
      variant: "contained",
      text: "Clear Exact Amounts",
      onClick: () => initializeClearExactAmountState()
    },
    {
      variant: "outlined",
      text: "Clear",
      operationDisabled: () => {
        return verifyMatch || verifyOpenAmount;
      },
      onClick: () => handleBatchActions(ResolutionActivityTypes.CLEAR)
    },
    {
      variant: "outlined",
      text: "Sales Review",
      operationDisabled: () => verifyOpenAmount,
      onClick: () => handleBatchActions(ResolutionActivityTypes.SALES_REVIEW)
    },
    {
      variant: "outlined",
      text: "Dispute",
      operationDisabled: () => verifyOpenAmount,
      onClick: () => handleBatchActions(ResolutionActivityTypes.DISPUTE)
    },
    {
      variant: "outlined",
      text: "Write-Off",
      operationDisabled: () => verifyOpenAmount,
      onClick: () => handleBatchActions(ResolutionActivityTypes.WRITE_OFF)
    }
  ];

  const summaryConfig = useMemo(
    () =>
      new Map([
        [
          "amount",
          {
            label: "Total Original",
            type: CollapsibleTableSummaryType.SUM,
            render: val => displayUSCurrency(val)
          }
        ],
        [
          "openAmount",
          {
            label: "Total Open",
            type: CollapsibleTableSummaryType.SUM,
            render: val => displayUSCurrency(val)
          }
        ]
      ] as [keyof DisplayInvoiceLineObject, SummaryConfigOptions<DisplayInvoiceLineObject>][]),
    []
  );

  return (
    <>
      <GridContainer container>
        <Grid item xs={5}>
          <CollapsibleTableSummary
            data={displayInvoiceLines}
            summaryConfig={summaryConfig}
            size="small"
          />
        </Grid>
        <ButtonGroup item xs={7}>
          {buttonConfs.map(conf => (
            <BatchActionButton
              key={conf.text}
              variant={conf.variant}
              color="inherit"
              size="small"
              onClick={e => {
                const msg = conf.operationDisabled?.();
                if (msg) {
                  setShowDialog(true);
                  setDialogMessage(`Unable to complete operation: ${msg}`);
                } else {
                  conf.onClick(e);
                }
              }}
              disabled={disableBatchActions}>
              {conf.text}
            </BatchActionButton>
          ))}
        </ButtonGroup>
      </GridContainer>
      {showClearExactAmountsDialog && getClearExactAmountsDialog()}
      {showDialog && (
        <Dialog
          open={showDialog}
          onClose={() => {
            setShowDialog(false);
          }}>
          <DialogTitle>Warning</DialogTitle>
          <DialogContent>{dialogMessage}</DialogContent>
          <DialogActions>
            <Button onClick={() => setShowDialog(false)}>OK</Button>
          </DialogActions>
        </Dialog>
      )}
      <MultiEditDialog
        fullWidth
        maxWidth="lg"
        open={openMultiEditScreen}
        onClose={() => {
          setOpenMultiEditScreen(false);
        }}>
        <MultiEditScreen
          fundTypes={fundTypes}
          invoices={invoices}
          erpTransactions={erpTransactions}
          allLines={allLines}
          companyUsers={companyUsers}
          products={products}
          customers={customers}
          displayInvoice={displayInvoice}
          onClose={() => setOpenMultiEditScreen(false)}
          addEvent={addEvent}
          displayTransaction={displayTransaction}
        />
      </MultiEditDialog>
    </>
  );
}
