import { invoiceStatuses } from "deductions-constants/ReconciliationStatuses";
import { InvoiceLineObject, InvoiceObject } from "deductions-models/index";
import {
  DisplayInvoiceObject,
  invoiceDependencies,
  invoiceActions,
  invoiceDependencyDescriptions
} from "components/Deductions/DeductionsReconciliation/types/invoiceTypes";

// get all existing dependencies at once
export function calculateInvoiceDependencies(
  invoice: DisplayInvoiceObject | InvoiceObject
): Record<invoiceDependencies, boolean> {
  const {
    invoiceLines,
    linkedERPTransactions,
    resolutionLines,
    status,
    needsCresicorApproval,
    customerKey,
    startDate,
    endDate
  } = invoice ?? {};

  return {
    INVOICE_CANCELLED: status === invoiceStatuses.CANCELLED,
    INVOICE_UNAPPROVED: needsCresicorApproval,
    INVOICE_LINES: invoiceLines ? Object.keys(invoiceLines).length > 0 : false,
    NO_INVOICE_LINES: invoiceLines
      ? Object.keys(invoiceLines).length === 0
      : true,
    LINKED_TRANSACTIONS: linkedERPTransactions
      ? Object.keys(linkedERPTransactions).length > 0
      : false,
    RESOLUTION_LINES: resolutionLines
      ? Object.keys(resolutionLines).length > 0
      : false,
    MATCHED_PROMOTION_LINES: invoiceLines
      ? Object.values(invoiceLines)
          .map((invoiceLine: InvoiceLineObject) => invoiceLine.matchedPromLine)
          .some(match => match)
      : false,
    INVOICE_HAS_NULL_VALUES: !customerKey || !startDate || !endDate
  };
}

// determine whether a specific invoice action is allowed given the current invoice object
export function isSingleInvoiceActionAllowed(
  invoice: DisplayInvoiceObject | InvoiceObject,
  action: invoiceActions,
  precalculatedDependencies?: Record<invoiceDependencies, boolean>
): {
  actionAllowed: boolean;
  dependenciesMessage: string;
} {
  // recalculate dependencies if not given
  const calculatedDependencies =
    precalculatedDependencies || calculateInvoiceDependencies(invoice);

  // convert a subset of dependencies into descriptions of valid dependencies
  function filterDependencies(
    dependencies: invoiceDependencies[]
  ): invoiceDependencyDescriptions[] {
    return dependencies
      .filter(d => calculatedDependencies[d])
      .map(d => invoiceDependencyDescriptions[d]);
  }

  // get subset of dependency checks for a single action
  let subset: invoiceDependencies[] = [];

  switch (action) {
    case invoiceActions.CANCEL: {
      subset = [
        invoiceDependencies.INVOICE_LINES,
        invoiceDependencies.LINKED_TRANSACTIONS,
        invoiceDependencies.RESOLUTION_LINES
      ];
      break;
    }
    case invoiceActions.APPROVE: {
      // only users with approval permission allowed
      subset = [
        invoiceDependencies.INVOICE_CANCELLED,
        invoiceDependencies.INVOICE_HAS_NULL_VALUES
      ];
      break;
    }
    case invoiceActions.UNAPPROVE: {
      // only users with approval permission allowed
      subset = [
        invoiceDependencies.INVOICE_CANCELLED,
        invoiceDependencies.INVOICE_LINES,
        invoiceDependencies.LINKED_TRANSACTIONS,
        invoiceDependencies.RESOLUTION_LINES
      ];
      break;
    }
    case invoiceActions.EDIT: {
      subset = [
        invoiceDependencies.INVOICE_CANCELLED,
        invoiceDependencies.INVOICE_LINES,
        invoiceDependencies.LINKED_TRANSACTIONS,
        invoiceDependencies.RESOLUTION_LINES
      ];
      break;
    }
    case invoiceActions.SCAN: {
      subset = [
        invoiceDependencies.INVOICE_UNAPPROVED,
        invoiceDependencies.INVOICE_CANCELLED,
        invoiceDependencies.RESOLUTION_LINES
      ];
      break;
    }
    case invoiceActions.UPLOAD_INVOICE_LINES: {
      subset = [
        invoiceDependencies.INVOICE_UNAPPROVED,
        invoiceDependencies.INVOICE_CANCELLED,
        invoiceDependencies.INVOICE_LINES
      ];
      break;
    }
    case invoiceActions.ADD_INVOICE_LINES: {
      subset = [
        invoiceDependencies.INVOICE_UNAPPROVED,
        invoiceDependencies.INVOICE_CANCELLED
      ];
      break;
    }
    case invoiceActions.SCAN_AND_UPLOAD_INVOICE_LINES: {
      subset = [
        invoiceDependencies.INVOICE_UNAPPROVED,
        invoiceDependencies.INVOICE_CANCELLED,
        invoiceDependencies.INVOICE_LINES,
        invoiceDependencies.RESOLUTION_LINES
      ];
      break;
    }
    case invoiceActions.DELETE_INVOICE_LINES: {
      subset = [
        invoiceDependencies.INVOICE_UNAPPROVED,
        invoiceDependencies.INVOICE_CANCELLED,
        invoiceDependencies.RESOLUTION_LINES,
        invoiceDependencies.NO_INVOICE_LINES
      ];
      break;
    }
    case invoiceActions.AUTO_MATCH: {
      subset = [
        invoiceDependencies.INVOICE_UNAPPROVED,
        invoiceDependencies.INVOICE_CANCELLED,
        invoiceDependencies.NO_INVOICE_LINES,
        invoiceDependencies.RESOLUTION_LINES
      ];
      break;
    }
    // TODO: move logic to separate invoice line dependency function
    // case invoiceActions.MANUAL_MATCH: {
    //   subset = [
    //     invoiceDependencies.INVOICE_UNAPPROVED,
    //     invoiceDependencies.INVOICE_CANCELLED,
    //     invoiceDependencies.NO_INVOICE_LINES
    //   ];
    //   break;
    // }
    case invoiceActions.LINK_TRANSACTION: {
      // should be filtered out already
      subset = [
        invoiceDependencies.INVOICE_UNAPPROVED,
        invoiceDependencies.INVOICE_CANCELLED,
        invoiceDependencies.LINKED_TRANSACTIONS, // cannot link invoice to multiple transactions
        invoiceDependencies.RESOLUTION_LINES
      ];
      break;
    }
    case invoiceActions.UNLINK_TRANSACTION: {
      subset = [invoiceDependencies.RESOLUTION_LINES];
      break;
    }
    default: {
      break;
    }
  }

  // calculate which ones evaluate to true and map to their descriptions
  const actionDependencies = filterDependencies(subset);
  const dependenciesMessage = actionDependencies.join(", ");

  return {
    actionAllowed: actionDependencies.length === 0,
    dependenciesMessage
  };
}
