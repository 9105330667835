import {
  Button,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
  Drawer
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useMemo, useCallback, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { infoDialog } from "helpers/OpenDialog";
import {
  DRMFeature,
  DRMRouteState
} from "components/Deductions/DeductionsReconciliation/redux/DRMSlice";
import {
  ERPTransactionObject,
  InvoiceObject
} from "components/Deductions/models";
import { useOpenClose } from "contexts/OpenClose";
import {
  LinkInvoice,
  SimplifiedInvoice
} from "components/Deductions/DeductionsReconciliation/ViewData/ViewTransactions/LinkInvoice/LinkInvoice";
import { useGetCustomerName } from "helpers/customer-hooks";
import { useDb } from "contexts/Db";
import { RootState } from "store";
import {
  deductionTransactionStatuses,
  invoiceStatuses
} from "deductions-constants/ReconciliationStatuses";
import { ERPTransactionTypes } from "deductions-constants/ReconciliationTypes";
import {
  TransactionDisplayService,
  TransactionDependenciesService,
  TransactionProcessingService,
  InvoiceDisplayService
} from "reconciliation-services";
import {
  transactionActions,
  invoiceTypeToTransactionTypeMap
} from "reconciliation-types/transactionTypes";
import { updateFirebaseTransaction } from "components/Deductions/DeductionsReconciliation/services/ERPTransactionServices/transactionProcessing";
import { updateFirebaseInvoice } from "components/Deductions/DeductionsReconciliation/services/InvoiceServices/invoiceProcessing";
import DRMBackButton from "components/Deductions/components/DRMBackButton";
import { useDRMEvents } from "contexts/DRMEvents";
import { DRMEventType } from "components/Deductions/DeductionsReconciliation/ActivityLog/DRMEvent";
import ActivityLogButton from "components/Deductions/DeductionsReconciliation/ActivityLog/ActivityLogButton";
import TransactionCard from "../TransactionCard";
import LinkedInvoicesTable from "../LinkInvoice/LinkedInvoicesTable";
import RepaymentLinesTable from "../Repayments/RepaymentLinesTable";
import { reset } from "../ApplyRepayment/redux/applyRepaymentSlice";
import AttachedTransactionsTable from "../Repayments/AttachedTransactionsTable";
import AttachTransaction from "../Repayments/AttachTransaction";
import ApplyRepayment, {
  ApplyRepaymentEntryPoint
} from "../ApplyRepayment/ApplyRepayment";

const useStyles = makeStyles(theme => ({
  card: {
    border: "1px solid #BBB"
  },
  container: {
    marginLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  headerGrid: {
    display: "flex",
    justifyContent: "space-between"
  },
  headerGridContainer: {
    paddingBottom: theme.spacing(1)
  },
  footerGridContainer: {
    paddingBottom: theme.spacing(2)
  },
  activityLogButtonContainer: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "flex-end"
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  textField: {
    height: 48,
    width: "80%",
    maxWidth: 280
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end"
  },
  button: {
    marginLeft: theme.spacing(1)
  }
}));

const CURRENCY_FORMATTER = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD"
});

export interface TransactionProfileProps {
  transactionKey: string;
}

export default function TransactionProfile({
  transactionKey
}: TransactionProfileProps): JSX.Element {
  const getCustomerName = useGetCustomerName();
  const classes = useStyles();
  const db = useDb();
  const dispatch = useDispatch();
  const openClose = useOpenClose();
  const { addEvent } = useDRMEvents();

  const [showDrawer, setShowDrawer] = useState(false);

  const {
    erpTransactions = {},
    invoices = {},
    companyUsers = {},
    meta,
    customers = {}
  } = db;
  const { featureGates } = meta;
  const { drmRepayments: repaymentsEnabled = false } = featureGates ?? {};
  const { setAppModal, closeAppModal } = useOpenClose();

  const transaction = erpTransactions[transactionKey];
  const isRepayment = transaction.type === ERPTransactionTypes.REPAYMENT;
  const isDeduction = transaction.type === ERPTransactionTypes.DEDUCTION;
  const isUnknown = transaction.type === ERPTransactionTypes.UNKNOWN;

  const invoiceList = Object.values(invoices);

  const { guiMode } = useSelector(
    (state: RootState) => state.drm,
    shallowEqual
  );

  // recalculate dependencies if transaction changes
  const allTransactionDependencies = useMemo(
    () =>
      TransactionDependenciesService.calculateTransactionDependencies(
        transaction,
        invoices
      ),
    [transaction, invoices]
  );

  const displayTransaction = useMemo(
    () =>
      TransactionDisplayService.processTransactionForDisplay(
        transaction,
        invoices,
        erpTransactions,
        customers,
        companyUsers
      ),
    [companyUsers, customers, erpTransactions, invoices, transaction]
  );

  const linkableInvoices: SimplifiedInvoice[] = useMemo(
    () =>
      invoiceList
        .filter(
          (invoice: InvoiceObject) =>
            !(
              invoice.needsCresicorApproval ||
              invoice.status === invoiceStatuses.CANCELLED ||
              invoice.linkedERPTransactions
            ) &&
            (transaction.type === ERPTransactionTypes.UNKNOWN ||
              invoiceTypeToTransactionTypeMap[invoice.type] ===
                transaction.type)
        )
        .map((invoice, id) => {
          return {
            id: String(id),
            fileName: invoice.originalFile.fileName,
            invoiceNumber: invoice.invoiceNumber,
            invoiceKey: invoice.key,
            date: new Date(invoice.endDate ?? invoice.startDate),
            customer: getCustomerName(invoice.customerKey ?? ""),
            amount: CURRENCY_FORMATTER.format(invoice.amount),
            status:
              InvoiceDisplayService.HumanReadableInvoiceStatuses[
                invoice.status
              ],
            type: invoice.type
          };
        }),
    [getCustomerName, invoiceList, transaction]
  );

  const customerNames = useMemo(
    () => Array.from(new Set(linkableInvoices.map(({ customer }) => customer))),
    [linkableInvoices]
  );

  const fileNamesAndInvoiceNumbers = useMemo(
    () => [
      ...Array.from(new Set(linkableInvoices.map(({ fileName }) => fileName))),
      ...Array.from(
        new Set(linkableInvoices.map(({ invoiceNumber }) => invoiceNumber))
      )
    ],
    [linkableInvoices]
  );

  const handleLinkInvoice = useCallback(
    (linkInvoiceList: SimplifiedInvoice[]) => {
      let currentLinkedInvoices = transaction.linkedInvoices || {};
      linkInvoiceList.forEach(invoice => {
        const updatedTransaction: ERPTransactionObject = {
          ...transaction,
          key: transaction.key,
          status:
            transaction.status === deductionTransactionStatuses.NEW
              ? deductionTransactionStatuses.PENDING
              : transaction.status,
          type: invoiceTypeToTransactionTypeMap[invoice.type],
          linkedInvoices: {
            ...currentLinkedInvoices,
            [invoice.invoiceKey]: invoice.invoiceKey
          }
        };
        currentLinkedInvoices = updatedTransaction.linkedInvoices || {};
        const currentInvoice = invoices[invoice.invoiceKey];
        const currentLinkedTransactions =
          currentInvoice.linkedERPTransactions || {};
        const updatedInvoice: InvoiceObject = {
          ...currentInvoice,
          status:
            currentInvoice.status === invoiceStatuses.NEW
              ? invoiceStatuses.IN_PROGRESS
              : currentInvoice.status,
          linkedERPTransactions: {
            ...currentLinkedTransactions,
            [transaction.key]: transaction.key
          }
        };

        updateFirebaseTransaction(updatedTransaction, () => {
          updateFirebaseInvoice(updatedInvoice, () => {
            addEvent({
              type: DRMEventType.INVOICE_LINKED,
              transactionKey: updatedTransaction.key,
              invoiceLineKey: updatedInvoice.key
            });
          });
        });
      });
      closeAppModal();
    },
    [addEvent, closeAppModal, invoices, transaction]
  );

  const handleAttemptLinkInvoice = useCallback(() => {
    const newOpenClose = {
      setAppModal,
      closeAppModal
    };

    const { actionAllowed, dependenciesMessage } =
      TransactionDependenciesService.isSingleTransactionActionAllowed(
        transaction,
        transactionActions.LINK_INVOICE,
        invoices,
        allTransactionDependencies
      );

    if (actionAllowed) {
      setAppModal({
        title: "Link Invoices",
        content: (
          <LinkInvoice
            invoices={linkableInvoices}
            fileNamesAndInvoiceNumbers={fileNamesAndInvoiceNumbers}
            customers={customerNames}
            displayTransaction={displayTransaction}
            onLinkInvoice={handleLinkInvoice}
            onCancel={() => closeAppModal()}
          />
        ),
        maxWidth: "lg",
        backgroundColor: "white"
      });
    } else {
      infoDialog(
        newOpenClose,
        `Error - ${transactionActions.LINK_INVOICE}`,
        `Invoices cannot be linked to transaction ${transaction.transactionDisplayId} because: ${dependenciesMessage}. Please handle these dependencies before trying again.`,
        `Go Back`
      );
    }
  }, [
    setAppModal,
    closeAppModal,
    transaction,
    invoices,
    allTransactionDependencies,
    linkableInvoices,
    fileNamesAndInvoiceNumbers,
    customerNames,
    handleLinkInvoice,
    displayTransaction
  ]);

  const handleAttemptAttachTransaction = useCallback(() => {
    const newOpenClose = {
      setAppModal,
      closeAppModal
    };
    const action = isRepayment
      ? transactionActions.ATTACH_DEDUCTION
      : transactionActions.ATTACH_REPAYMENT;

    const { actionAllowed, dependenciesMessage } =
      TransactionDependenciesService.isSingleTransactionActionAllowed(
        transaction,
        action,
        invoices,
        allTransactionDependencies
      );

    if (!repaymentsEnabled) {
      infoDialog(
        newOpenClose,
        action,
        "This feature is not yet available. Please contact support with any questions or concerns."
      );
    } else if (actionAllowed) {
      setAppModal({
        title: action,
        content: <AttachTransaction transactionKey={transaction.key} />,
        maxWidth: "lg",
        backgroundColor: "white"
      });
    } else {
      infoDialog(
        newOpenClose,
        `Error - ${action}`,
        `Cannot ${(action as string).toLowerCase()} to ${
          transaction.transactionDisplayId
        } because: ${dependenciesMessage}. Please handle these dependencies before trying again.`,
        `Go Back`
      );
    }
  }, [
    setAppModal,
    closeAppModal,
    transaction,
    invoices,
    allTransactionDependencies,
    repaymentsEnabled,
    isRepayment
  ]);

  const handleAssignUser = event => {
    const { value: userId } = event.target;
    const updatedTransaction: ERPTransactionObject = {
      ...transaction
    };
    updatedTransaction.assignedUser = userId === "" ? null : userId;
    TransactionProcessingService.updateFirebaseTransaction(
      updatedTransaction,
      () => {
        openClose.showSnack(
          userId
            ? `Assigned transaction ${transaction.transactionDisplayId} to ${companyUsers[userId].name}.`
            : `Transaction ${transaction.transactionDisplayId} has been unassigned.`
        );
        if (!userId) {
          addEvent({
            type: DRMEventType.TRANSACTION_UNASSIGNED,
            metadata: {
              userId: transaction.assignedUser || undefined
            },
            transactionKey
          });
        } else {
          addEvent({
            type: DRMEventType.TRANSACTION_ASSIGNED,
            metadata: {
              userId
            },
            transactionKey
          });
        }
      }
    );
  };

  const openApplyRepayment = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      const newOpenClose = {
        setAppModal,
        closeAppModal
      };

      const action = isRepayment
        ? transactionActions.PROCESS_REPAYMENT
        : transactionActions.REPAY_DEDUCTION;

      const { actionAllowed, dependenciesMessage } =
        TransactionDependenciesService.isSingleTransactionActionAllowed(
          transaction,
          action,
          invoices,
          allTransactionDependencies
        );

      if (!repaymentsEnabled) {
        infoDialog(
          newOpenClose,
          action,
          "This feature is not yet available. Please contact support with any questions or concerns."
        );
      } else if (actionAllowed) {
        dispatch(reset());
        setShowDrawer(true);
      } else {
        infoDialog(
          newOpenClose,
          `Error - ${action}`,
          `Cannot ${(
            action as string
          ).toLowerCase()} because: ${dependenciesMessage}. Please handle these dependencies before trying again.`,
          `Go Back`
        );
      }
    },
    [
      setAppModal,
      closeAppModal,
      transaction,
      invoices,
      allTransactionDependencies,
      repaymentsEnabled,
      isRepayment,
      dispatch
    ]
  );

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item md={4}>
          <DRMBackButton
            text="VIEW ALL TRANSACTIONS"
            toUrl={`/${DRMFeature.RECONCILIATION}/${DRMRouteState.TRANSACTIONS}/${guiMode}`}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6} lg={5}>
          <TransactionCard transaction={displayTransaction} />
        </Grid>
        <Grid item xs={5} className={classes.activityLogButtonContainer}>
          <ActivityLogButton
            title="Transaction Activity Log"
            subtitle={`Transaction ID: ${
              db.erpTransactions[transaction.key].transactionDisplayId
            }`}
            filters={{
              transactionKey
            }}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={11} lg={10} className={classes.divider}>
          <Divider orientation="horizontal" />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={10} sm={8} md={6} lg={4}>
          <Typography variant="subtitle1">Assigned to:</Typography>
          <TextField
            select
            variant="outlined"
            size="small"
            className={classes.textField}
            value={displayTransaction.assignedUser}
            onChange={handleAssignUser}>
            <MenuItem value="">None</MenuItem>
            {Object.entries(companyUsers)
              .sort((entry1, entry2) =>
                entry1[1].name.localeCompare(entry2[1].name)
              )
              .map(entry => {
                const { email, name } = entry[1];
                const userId = entry[0];
                return (
                  <MenuItem value={userId} key={userId}>
                    {name} ({email})
                  </MenuItem>
                );
              })}
          </TextField>
        </Grid>
      </Grid>
      {(isUnknown || isDeduction) && (
        <>
          <Grid container>
            <Grid item xs={11} lg={10} className={classes.divider}>
              <Divider orientation="horizontal" />
            </Grid>
          </Grid>
          <Grid container className={classes.headerGridContainer}>
            <Grid item xs={11} lg={10} className={classes.headerGrid}>
              <Typography variant="h6">
                Linked Invoices (
                {Object.keys(transaction.linkedInvoices || {}).length || 0})
              </Typography>
              <Button
                variant="contained"
                color="inherit"
                size="small"
                onClick={handleAttemptLinkInvoice}>
                Link Invoice
              </Button>
            </Grid>
          </Grid>
          <Grid container className={classes.footerGridContainer}>
            <Grid item xs={11} lg={10}>
              <LinkedInvoicesTable transactionKey={transactionKey} />
            </Grid>
          </Grid>
        </>
      )}
      {isDeduction && (
        <>
          <Grid container className={classes.headerGridContainer}>
            <Grid item xs={11} lg={10} className={classes.headerGrid}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="h6">
                    Attached Repayments (
                    {Object.keys(transaction.attachedRepayments || {}).length ||
                      0}
                    )
                  </Typography>
                </Grid>
                <Grid item xs={6} className={classes.buttonGroup}>
                  <Button
                    variant="contained"
                    color="inherit"
                    size="small"
                    className={classes.button}
                    onClick={openApplyRepayment}>
                    {transactionActions.REPAY_DEDUCTION}
                  </Button>
                  <Button
                    variant="contained"
                    color="inherit"
                    size="small"
                    className={classes.button}
                    onClick={handleAttemptAttachTransaction}>
                    Attach Repayment
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={classes.footerGridContainer}>
            <Grid item xs={11} lg={10}>
              <AttachedTransactionsTable transactionKey={transaction.key} />
            </Grid>
          </Grid>
        </>
      )}
      {isRepayment && (
        <>
          <Grid container>
            <Grid item xs={11} lg={10} className={classes.divider}>
              <Divider orientation="horizontal" />
            </Grid>
          </Grid>
          <Grid container className={classes.headerGridContainer}>
            <Grid item xs={11} lg={10} className={classes.headerGrid}>
              <Typography variant="h6">
                Attached Deductions (
                {Object.keys(transaction.repaidDeductions || {}).length || 0})
              </Typography>
              <Button
                variant="contained"
                color="inherit"
                size="small"
                onClick={handleAttemptAttachTransaction}>
                Attach Deduction
              </Button>
            </Grid>
          </Grid>
          <Grid container className={classes.footerGridContainer}>
            <Grid item xs={11} lg={10}>
              <AttachedTransactionsTable transactionKey={transaction.key} />
            </Grid>
          </Grid>
          <Grid container className={classes.headerGridContainer}>
            <Grid item xs={11} lg={10} className={classes.headerGrid}>
              <Typography variant="h6">
                Repayment Details (
                {Object.values(transaction.repaymentLines || {}).length})
              </Typography>
              <Button
                variant="contained"
                color="inherit"
                size="small"
                className={classes.button}
                onClick={openApplyRepayment}>
                {transactionActions.PROCESS_REPAYMENT}
              </Button>
            </Grid>
          </Grid>
          <Grid container className={classes.footerGridContainer}>
            <Grid item xs={11} lg={10}>
              <RepaymentLinesTable
                repaymentKey={transaction.key}
                showRepaidTransactionId
              />
            </Grid>
          </Grid>
        </>
      )}
      <Drawer
        anchor="right"
        open={showDrawer}
        onClose={() => setShowDrawer(false)}>
        {isRepayment ? (
          <ApplyRepayment
            initialRepaymentKey={transactionKey}
            featureEntryPoint={ApplyRepaymentEntryPoint.REPAYMENT}
          />
        ) : (
          <ApplyRepayment
            initialDeductionKey={transactionKey}
            featureEntryPoint={ApplyRepaymentEntryPoint.DEDUCTION}
          />
        )}
      </Drawer>
    </div>
  );
}
