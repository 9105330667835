import {
  UPDATE_FIELD,
  SET_DEFAULT,
  ADD_LINE_KEY,
  DELETE_LINE_KEY,
  GET_SUGGESTED_MATCHES,
  RESET
} from "./actions";

const initialState = {
  source: "none",
  stepIndex: 0,
  accumToLines: [],
  accumDF: {},
  allLinesDF: {},
  accumDFTable: {},
  allLinesDFTable: {},
  suggestMatches: true,
  fileTooLarge: false,
  uploadPath: null
};

function updateField(newState, field, value) {
  const update = {};
  update[field] = value;
  Object.assign(newState, update);
  return newState;
}

function setDefault(
  newState,
  accumToLines,
  accumDF,
  allLinesDF,
  accumDFTable,
  allLinesDFTable,
  fileLineLimit,
  fileTooLarge,
  uploadPath,
  matchKey,
  suggestMatches
) {
  newState.accumToLines = accumToLines;
  newState.accumDF = JSON.parse(JSON.stringify(accumDF));
  newState.allLinesDF = JSON.parse(JSON.stringify(allLinesDF));
  newState.accumDFTable = JSON.parse(JSON.stringify(accumDFTable));
  newState.allLinesDFTable = JSON.parse(JSON.stringify(allLinesDFTable));
  newState.fileLineLimit = fileLineLimit;
  newState.fileTooLarge = fileTooLarge;
  newState.uploadPath = uploadPath;
  newState.matchKey = matchKey;
  newState.suggestMatches = suggestMatches;
  return newState;
}

function calculateSpendRateDelta(data) {
  for (const row of data) {
    const rowNumber = row.Row;
    const spendRate = data[rowNumber]["Spend Rate"];
    const matchedSpendRate = data[rowNumber]["Matched Line Spend Rate"];
    let spendDelta = "";
    if (
      !(
        matchedSpendRate === "" ||
        matchedSpendRate.includes("|") ||
        matchedSpendRate === "-"
      )
    ) {
      spendDelta = Math.abs(
        parseFloat(spendRate) - parseFloat(matchedSpendRate)
      ).toFixed(2);
    }
    row["Spend Rate Difference"] = spendDelta;
  }
  return data;
}

function addLineKey(newState, rows, lineKey, tableType) {
  if (tableType == "View All Lines") {
    var data = newState.allLinesDF;
    var tableData = newState.allLinesDFTable;
  } else {
    var data = newState.accumDF;
    var tableData = newState.accumDFTable;
  }
  for (const row of rows) {
    const rowNumber = row.Row;
    if (tableType == "View Accumulated Lines") {
      // if we are selecting line keys for accumulated lines, make same changes on individual lines as well
      var correspondingRowInd = new Set(newState.accumToLines[rowNumber]);
      const correspondingRows = Object.values(newState.allLinesDFTable).filter(
        x => correspondingRowInd.has(x.Row)
      );
      newState = addLineKey(
        newState,
        correspondingRows,
        lineKey,
        "View All Lines"
      );
    }
    const lineKeys =
      row["Matched Line Key"][0] == "-"
        ? []
        : row["Matched Line Key"].split("|");
    lineKeys.push(lineKey);
    const origLineKeys = data[rowNumber]["Matched Line Key"].split("|");
    const origPromTypes = data[rowNumber]["Matched Promotion Type"].split("|");
    const origSpendRates =
      data[rowNumber]["Matched Line Spend Rate"].split("|");
    const origLineClosed = data[rowNumber]["Matched Line Closed"].split("|");
    const origLineProductGroup =
      data[rowNumber]["Matched Line Product Group"].split("|");
    const origAccount = data[rowNumber]["Matched Line Account"].split("|");
    const newLineKeys = [];
    const newPromTypes = [];
    const newSpendRates = [];
    const newLineClosed = [];
    const newLineProductGroup = [];
    const newAccount = [];
    for (let i = 0; i < origLineKeys.length; i++) {
      if (lineKeys.includes(origLineKeys[i])) {
        newLineKeys.push(origLineKeys[i]);
        newPromTypes.push(origPromTypes[i]);
        newSpendRates.push(origSpendRates[i]);
        newLineClosed.push(origLineClosed[i]);
        newLineProductGroup.push(origLineProductGroup[i]);
        newAccount.push(origAccount[i]);
      }
    }
    row["Matched Line Key"] = newLineKeys.join("|");
    row["Matched Promotion Type"] = newPromTypes.join("|");
    row["Matched Line Spend Rate"] = newSpendRates.join("|");
    row["Matched Line Closed"] = newLineClosed.join("|");
    row["Matched Line Product Group"] = newLineProductGroup.join("|");
    row["Matched Line Account"] = newAccount.join("|");
    tableData[rowNumber] = row;
  }
  tableData = calculateSpendRateDelta(tableData);
  if (tableType == "View All Lines") {
    Object.assign(newState, {
      allLinesDFTable: tableData
    });
  } else {
    Object.assign(newState, {
      accumDFTable: tableData
    });
  }
  return newState;
}

function deleteLineKey(newState, rows, lineKey, tableType) {
  if (tableType == "View All Lines") {
    var data = newState.allLinesDF;
    var tableData = newState.allLinesDFTable;
  } else {
    var data = newState.accumDF;
    var tableData = newState.accumDFTable;
  }
  for (const row of rows) {
    const rowNumber = row.Row;
    if (tableType == "View Accumulated Lines") {
      // if we are selecting line keys for accumulated lines, make same changes on individual lines as well
      var correspondingRowInd = new Set(newState.accumToLines[rowNumber]);
      const correspondingRows = Object.values(newState.allLinesDFTable).filter(
        x => correspondingRowInd.has(x.Row)
      );
      newState = deleteLineKey(
        newState,
        correspondingRows,
        lineKey,
        "View All Lines"
      );
    }
    const lineKeys = row["Matched Line Key"].split("|");
    lineKeys.splice(lineKeys.indexOf(lineKey), 1);
    if (lineKeys.length == 0) {
      var newLineKeys = ["-"];
      var newPromTypes = ["-"];
      var newSpendRates = ["-"];
      var newLineClosed = ["-"];
      var newLineProductGroup = ["-"];
      var newAccount = ["-"];
    } else {
      const origLineKeys = data[rowNumber]["Matched Line Key"].split("|");
      const origPromTypes =
        data[rowNumber]["Matched Promotion Type"].split("|");
      const origSpendRates =
        data[rowNumber]["Matched Line Spend Rate"].split("|");
      const origLineClosed = data[rowNumber]["Matched Line Closed"].split("|");
      const origLineProductGroup =
        data[rowNumber]["Matched Line Product Group"].split("|");
      const origAccount = data[rowNumber]["Matched Line Account"].split("|");
      var newLineKeys = [];
      var newPromTypes = [];
      var newSpendRates = [];
      var newLineClosed = [];
      var newLineProductGroup = ["-"];
      var newAccount = [];
      for (let i = 0; i < origLineKeys.length; i++) {
        if (lineKeys.includes(origLineKeys[i])) {
          newLineKeys.push(origLineKeys[i]);
          newPromTypes.push(origPromTypes[i]);
          newSpendRates.push(origSpendRates[i]);
          newLineClosed.push(origLineClosed[i]);
          newLineProductGroup.push(origLineProductGroup[i]);
          newAccount.push(origAccount[i]);
        }
      }
    }
    row["Matched Line Key"] = newLineKeys.join("|");
    row["Matched Promotion Type"] = newPromTypes.join("|");
    row["Matched Line Spend Rate"] = newSpendRates.join("|");
    row["Matched Line Closed"] = newLineClosed.join("|");
    row["Matched Line Product Group"] = newLineProductGroup.join("|");
    row["Matched Line Account"] = newAccount.join("|");
    tableData[rowNumber] = row;
  }
  tableData = calculateSpendRateDelta(tableData);
  if (tableType == "View All Lines") {
    Object.assign(newState, {
      allLinesDFTable: tableData
    });
  } else {
    Object.assign(newState, {
      accumDFTable: tableData
    });
  }
  return newState;
}

function getSuggestedMatches(newState, isInputChecked) {
  const { allLinesDF, allLinesDFTable, accumDF, accumDFTable } = newState;
  if (!isInputChecked) {
    Object.assign(newState, {
      allLinesDFTable: JSON.parse(JSON.stringify(allLinesDF)),
      accumDFTable: JSON.parse(JSON.stringify(accumDF))
    });
    return newState;
  }
  for (let [data, tableData, tableType] of [
    [allLinesDF, allLinesDFTable, "All Lines"],
    [accumDF, accumDFTable, "Accumulated Lines"]
  ]) {
    for (let i = 0; i < data.length; i++) {
      const row = { ...data[i] };
      const spendRate = row["Spend Rate"];
      const origLineKeys = row["Matched Line Key"].split("|");
      const origSpendRates = row["Matched Line Spend Rate"].split("|");
      const origPromTypes = row["Matched Promotion Type"].split("|");
      const origLineClosed = row["Matched Line Closed"].split("|");
      const origAccount = row["Matched Line Account"].split("|");
      const newLineKeys = [];
      const newPromTypes = [];
      const newSpendRates = [];
      const newLineClosed = [];
      const newAccount = [];
      let minDiff = Infinity;
      for (var j = 0; j < origLineKeys.length; j++) {
        if (!isNaN(parseFloat(origSpendRates[j]))) {
          var matchedSpendRate = parseFloat(origSpendRates[j]);
          var diff = Math.abs(matchedSpendRate - spendRate);
          if (diff < minDiff) {
            minDiff = diff;
          }
        }
      }
      for (var j = 0; j < origLineKeys.length; j++) {
        var matchedSpendRate = parseFloat(origSpendRates[j]);
        const isSpendNaN = isNaN(matchedSpendRate);
        var diff = Math.abs(matchedSpendRate - spendRate);
        if (isSpendNaN || diff == minDiff) {
          newLineKeys.push(origLineKeys[j]);
          newPromTypes.push(origPromTypes[j]);
          newSpendRates.push(origSpendRates[j]);
          newLineClosed.push(origLineClosed[j]);
          newAccount.push(origAccount[j]);
        }
      }
      row["Matched Line Key"] = newLineKeys.join("|");
      row["Matched Promotion Type"] = newPromTypes.join("|");
      row["Matched Line Spend Rate"] = newSpendRates.join("|");
      row["Matched Line Closed"] = newLineClosed.join("|");
      row["Matched Line Account"] = newAccount.join("|");
      tableData[i] = row;
    }
    tableData = calculateSpendRateDelta(tableData);
    if (tableType == "View All Lines") {
      Object.assign(newState, {
        allLinesDFTable: tableData
      });
    } else {
      Object.assign(newState, {
        accumDFTable: tableData
      });
    }
  }
  return newState;
}

function reset(newState) {
  return initialState;
}

function deductionsMatchingReducer(state = initialState, action) {
  const newState = jQuery.extend(true, {}, state);
  switch (action.type) {
    case UPDATE_FIELD:
      return updateField(newState, action.field, action.value);
    case SET_DEFAULT:
      return setDefault(
        newState,
        action.accumToLines,
        action.accumDF,
        action.allLinesDF,
        action.accumDFTable,
        action.allLinesDFTable,
        action.fileLineLimit,
        action.fileTooLarge,
        action.uploadPath,
        action.matchKey,
        action.suggestMatches
      );
    case ADD_LINE_KEY:
      return addLineKey(
        newState,
        action.rows,
        action.lineKey,
        action.tableType
      );
    case DELETE_LINE_KEY:
      return deleteLineKey(
        newState,
        action.rows,
        action.lineKey,
        action.tableType
      );
    case GET_SUGGESTED_MATCHES:
      return getSuggestedMatches(newState, action.isInputChecked);
    case RESET:
      return reset(newState);
    default:
      return newState;
  }
}

export default deductionsMatchingReducer;
