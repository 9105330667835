import { connect } from "react-redux";
import React from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import PortraitIcon from "@mui/icons-material/Portrait";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import Looks4Icon from "@mui/icons-material/Looks4";
import Looks5Icon from "@mui/icons-material/Looks5";
import Looks6Icon from "@mui/icons-material/Looks6";

import IconButton from "ui-library/IconButton";
import TextField from "ui-library/TextField";
import XIcon from "@mui/icons-material/Cancel";

import { grey, common } from "@mui/material/colors";
import { updateField } from "./actions";
import AddPromAutoComplete from "./AddPromAutoComplete";

const grey100 = grey["100"];
const { black } = common;

const styles = {};

const LooksIcon = {
  one: <LooksOneIcon style={{ color: black }} />,
  two: <LooksTwoIcon style={{ color: black }} />,
  3: <Looks3Icon style={{ color: black }} />,
  4: <Looks4Icon style={{ color: black }} />,
  5: <Looks5Icon style={{ color: black }} />,
  6: <Looks6Icon style={{ color: black }} />
};

class AddContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contactNames: [],
      contactExists: false
    };
  }

  handleNewRequest = chosenRequest => {
    this.setState({
      contactExists: true,
      name: chosenRequest.text,
      email: chosenRequest.email,
      avatar: chosenRequest.avatar
    });
  };

  handleCancel = () => {
    this.setState({
      contactExists: false
    });
    this.props.dispatch(updateField(this.props.db, this.props.newContact, {}));
  };

  fillExistingFields = contactState => {
    if (contactState.key) {
      this.setState(prevState => ({
        contactExists: true,
        name: contactState.name || prevState.name,
        email: contactState.email || prevState.email,
        avatar: contactState.avatar || prevState.avatar
      }));
    }
  };

  getContactFieldJSX = () => {
    return (
      <AddPromAutoComplete
        hintText="Contact Name"
        handleNewRequest={this.handleNewRequest}
        dataSource={this.state.contactNames}
        searchText={
          this.props.contactState.name ? this.props.contactState.name : ""
        }
        errorText={this.props.errorText}
        parent={this.props.newContact}
        field="name"
      />
    );
  };

  componentDidMount() {
    const allContacts = this.props.db.contacts;
    const contactNames = [];
    for (const key in allContacts) {
      const contact = allContacts[key];
      contactNames.push({
        text: contact.name,
        value: (
          <ListItem style={{ color: black }}>
            <ListItemText
              id="customer-name"
              primary={contact.name}
              secondary={contact.email}
            />
          </ListItem>
        ),
        email: contact.email,
        avatar: contact.avatar,
        key
      });
    }
    this.setState({
      contactNames
    });

    this.fillExistingFields(this.props.contactState);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.fillExistingFields(nextProps.contactState);
  }

  render() {
    let num = this.props.newContact ? this.props.newContact.slice(7) : "none";
    if (num == "1") num = "one";
    if (num == "2") num = "two";
    return (
      <div>
        {this.state.contactExists ? (
          <List>
            <ListItem sx={{ bgcolor: "primary.container" }}>
              <ListItemIcon>{LooksIcon[num] || <PortraitIcon />}</ListItemIcon>
              <ListItemText
                id="customer-name"
                primary={this.state.name}
                secondary={
                  <span style={{ color: black }}>{this.state.email}</span>
                }
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="close"
                  onClick={this.handleCancel}
                  size="large">
                  <XIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        ) : this.props.db.permissions.includes("addContacts") ? (
          <div>
            {this.getContactFieldJSX()}
            <br />
            <div className="centering">
              <TextField
                placeholder="Company"
                id={`${this.props.newContact}.company`}
                defaultValue={this.props.contactState.company}
                onChange={event =>
                  this.props.dispatch(
                    updateField(
                      this.props.db,
                      `${this.props.newContact}.company`,
                      event.target.value
                    )
                  )
                }
                fullWidth
                style={{ marginRight: "16px" }}
              />
              <TextField
                placeholder="Title"
                id={`${this.props.newContact}.title`}
                defaultValue={this.props.contactState.title}
                onChange={event =>
                  this.props.dispatch(
                    updateField(
                      this.props.db,
                      `${this.props.newContact}.title`,
                      event.target.value
                    )
                  )
                }
                fullWidth
              />
            </div>
            <TextField
              placeholder="Contact Email"
              id={`${this.props.newContact}.email`}
              defaultValue={this.props.contactState.email}
              onChange={event =>
                this.props.dispatch(
                  updateField(
                    this.props.db,
                    `${this.props.newContact}.email`,
                    event.target.value
                  )
                )
              }
              fullWidth
            />
            <br />
            <TextField
              placeholder="Phone Number"
              id={`${this.props.newContact}.phone`}
              defaultValue={this.props.contactState.phone}
              onChange={event =>
                this.props.dispatch(
                  updateField(
                    this.props.db,
                    `${this.props.newContact}.phone`,
                    event.target.value
                  )
                )
              }
              fullWidth
            />
            <br />
            <br />
          </div>
        ) : (
          <div> {this.getContactFieldJSX()} </div>
        )}
      </div>
    );
  }
}

export default connect()(AddContact);
