import { sum, forEach, cloneDeep } from "lodash";
import { getCurrentTimeframe } from "components/Customers/CustomerUtils";
import { Meta } from "js/dbTypes";
import { sortByDate, sortByField } from "./sortByDate";
import { printMonthYear, daysBetween, getYearWeeks } from "./Time";

/** ************************************************************************ */
/** ************************** General Utils ******************************** */
/** ************************************************************************ */

// remove all instances of val (string) from arr
function removeVal(arr, val) {
  const index = arr.indexOf(val.toLowerCase());
  if (index > -1) {
    arr.splice(index, 1);
    removeVal(arr, val);
  }
}

function arrayify(val) {
  if (Array.isArray(val)) {
    return val;
  }
  return [val];
}

function round(n) {
  return Math.round(n * 100) / 100;
}

function displayMoney(num) {
  if (!num && num !== 0) return num;
  if (num < 0) {
    return `-$${(-num).toLocaleString()}`;
  }
  if (num > 0) {
    return `$${num.toLocaleString()}`;
  }
  if (num === 0) {
    return "$0";
  }
  return `$${num.toLocaleString()}`;
}

function displayUSCurrency(num, keepDollarSign = true, useGrouping = true) {
  const dollarUSLocale = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    useGrouping
  });
  return keepDollarSign
    ? `${dollarUSLocale.format(num)}`
    : dollarUSLocale.format(num);
}

function cleanFileName(fileName) {
  let res = "";
  for (let i = 0; i < fileName.length; i++) {
    if (![".", "#", "$", "/", "[", "]"].includes(fileName.charAt(i))) {
      res += fileName.charAt(i);
    }
  }
  return res;
}

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];
const quarters = ["Q1", "Q2", "Q3", "Q4"];

function generalCollapse(pivot, field, itemsList) {
  const data = {};
  for (let i = 0; i < itemsList.length; i++) {
    const s = itemsList[i];
    if (pivot in s && field in s) {
      if (s[pivot] in data) {
        data[s[pivot]] += parseFloat(s[field]) || 0;
      } else {
        data[s[pivot]] = parseFloat(s[field]) || 0;
      }
    }
  }

  return data;
}

function getTotals(data) {
  for (const key in data) {
    let custTotal = 0;
    for (let q = 0; q < 4; q++) {
      data[key][`Q${q + 1}`] =
        data[key][months[3 * q]] +
        data[key][months[3 * q + 1]] +
        data[key][months[3 * q + 2]];
      custTotal += data[key][`Q${q + 1}`];
    }
    data[key].Total = custTotal;
  }

  return data;
}

function collapseWithMonth(pivot, field, itemsList) {
  // returns object indexed by pivot where each value is another object indexed by months
  const data = {};

  for (let i = 0; i < itemsList.length; i++) {
    var s = itemsList[i];
    var m = s.month - 1; // index from jan = 0
    let groups = s[pivot] || "Others";
    // for contacts, contact = 0 or undefined when no contacts are linked
    if (pivot == "contact" && (groups == 0 || groups == "Others")) {
      groups = "No Contact Associated";
    }
    arrayify(groups).forEach(key => {
      if (key in data) {
        data[key][months[m]] += parseFloat(s[field]) || 0;
      } else {
        data[key] = {};
        for (let j = 0; j < 12; j++) data[key][months[j]] = 0;
        data[key][months[m]] = parseFloat(s[field]) || 0;
      }
    });
  }

  return getTotals(data);
}

function addDicts(dict1, dict2) {
  const combinedDict = {};
  const dicts = [dict1, dict2];
  for (let i = 0; i < dicts.length; i++) {
    for (const key in dicts[i]) {
      if (key in combinedDict) {
        combinedDict[key] += dicts[i][key];
      } else {
        combinedDict[key] = dicts[i][key];
      }
    }
  }
  return combinedDict;
}

function escapeQuotes(object) {
  const res = {};
  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      res[key] = object[key];
      if (typeof object[key] === "string") {
        res[key] = object[key].replace(/"/g, '""');
      }
    }
  }
  return res;
}

/** ************************************************************************ */
/** ********************** Pivot by Specific Field **************************** */
/** ************************************************************************ */

function getItemsByMonth(field, itemsList, byDate) {
  const data = {};

  if (!byDate) {
    for (var i = 0; i < months.length; i++) {
      data[months[i]] = 0;
    }
    for (var i = 0; i < itemsList.length; i++) {
      var s = itemsList[i];
      const month = months[s.month - 1]; // index from jan = 0
      if (month in data) {
        data[month] += parseFloat(s[field]) || 0;
      }
    }
  } else {
    for (var i = 0; i < itemsList.length; i++) {
      var s = itemsList[i];
      const date = new Date(s.date).toDateString();
      if (date in data) {
        data[date] += parseFloat(s[field]) || 0;
      } else {
        data[date] = parseFloat(s[field]) || 0;
      }
    }
  }

  return data;
}

function getItemsByProm(field, itemsList) {
  return generalCollapse("promKey", field, itemsList);
}

function getItemsByCustomer(field, itemsList) {
  const data = collapseWithMonth("customer", field, itemsList);

  return data;
}

function getItemsByContact(field, itemsList) {
  const data = collapseWithMonth("contact", field, itemsList);
  return data;
}

function getItemsByProduct(field, itemsList) {
  const data = collapseWithMonth("productGroup", field, itemsList);
  if ("" in data) {
    // aggregate data with no product group
    data.Other = data[""];
    delete data[""];
  }

  return data;
}

function getItemsByAccount(field, itemsList) {
  const data = collapseWithMonth("account", field, itemsList);

  return data;
}

/** ************************************************************************ */
/** ********************** Convert Data for Tables **************************** */
/** ************************************************************************ */

// Example: date and prom pivots in Promotion Analytics
function convertDataForTable1(actData, expData, pivot, allPivots) {
  const entries = [];
  const allKeys = Array.from(
    new Set(Object.keys(actData).concat(Object.keys(expData)))
  );
  for (const key of allKeys) {
    const actual = actData[key] ? Math.round(actData[key]) : 0;
    const expected = expData[key] ? Math.round(expData[key]) : 0;
    const diff = Math.round(actual - expected);
    const percent = expected
      ? round(((actual - expected) * 100) / expected)
      : 0;
    const s = {
      diff,
      percent
    };
    s.Actual = actual;
    s.Expected = expected;
    s[pivot] = allPivots ? allPivots[key].name : key;
    s.dateIndex = months.indexOf(key);
    s.key = key;
    entries.push(s);
  }
  return entries;
}

// Example: customer, product, account pivots in Promotion Analytics
function convertDataForTable2(actData, expData, pivot, allPivots) {
  const dateColumns = months.concat(quarters).concat(["Total"]);
  function getEntry(key, data, metric) {
    const entry = {};

    entry[pivot] = allPivots && allPivots[key] ? allPivots[key].name : key;

    for (let i = 0; i < dateColumns.length; i++) {
      if (!(key in data)) {
        entry[dateColumns[i]] = 0;
      } else {
        entry[dateColumns[i]] = Math.round(data[key][dateColumns[i]]) || 0;
      }
    }
    entry.metric = metric;

    return entry;
  }

  const diffData = {};
  const percentData = {};
  const allKeys = Array.from(
    new Set(Object.keys(actData).concat(Object.keys(expData)))
  );
  for (var key of allKeys) {
    diffData[key] = {};
    percentData[key] = {};
    // create empty entries
    if (!(key in actData)) {
      actData[key] = {};
      for (const date of dateColumns) actData[key][date] = 0;
    }
    if (!(key in expData)) {
      expData[key] = {};
      for (const date of dateColumns) expData[key][date] = 0;
    }

    for (const time in actData[key]) {
      diffData[key][time] = actData[key][time] - expData[key][time];
      percentData[key][time] =
        ((actData[key][time] - expData[key][time]) * 100) / expData[key][time];
    }
  }

  const actEntries = [];
  const expEntries = [];
  const diffEntries = [];
  const percentEntries = [];
  // Put data in format requried for SalesTable
  for (var key of allKeys) {
    actEntries.push(getEntry(key, actData, "Actual"));
    expEntries.push(getEntry(key, expData, "Expected"));
    diffEntries.push(getEntry(key, diffData, "Differential"));
    percentEntries.push(getEntry(key, percentData, "Diff (%)"));
  }

  const entriesObj = {
    actual: actEntries,
    expected: expEntries,
    diff: diffEntries,
    percent: percentEntries
  };
  return entriesObj;
}

function convertSplitsToNames(split, splitByPG, customers) {
  // do not want to make non-null if null
  let newSplit = null;
  let newSplitByPG = null;

  if (splitByPG) {
    newSplitByPG = {};
    for (const pg in splitByPG) {
      newSplitByPG[pg] = {};
      for (var key in splitByPG[pg]) {
        newSplitByPG[pg][customers[key].name] = parseFloat(splitByPG[pg][key]);
      }
    }
  }

  if (split) {
    newSplit = {};
    for (var key in split) {
      // possibly we still have legacy "Self"s and "Direct"s in the system
      if (!customers[key]) continue;
      newSplit[customers[key].name] = parseFloat(split[key]);
    }
  }

  return [newSplit, newSplitByPG];
}

// input: prom
// output: forecasted spend by line
function forecastedSpendSplit(prom, promKey, customerDict) {
  let { distributor } = prom;
  if (typeof distributor === "string") {
    distributor = [distributor];
  }

  const customer = customerDict[prom.customer];
  if (!customer) return {};

  let splitByPG = customer.spendPerDistributorPG;
  let split = customer.spendPerDistributor;
  const fixedSplits = convertSplitsToNames(split, splitByPG, customerDict);
  split = fixedSplits[0];
  splitByPG = fixedSplits[1];

  function getSplit(productGroup) {
    let totalPercent = 0;
    const percentByDist = {};

    for (let i = 0; i < distributor.length; i++) {
      let percent = 0;

      if (splitByPG && productGroup in splitByPG) {
        percent = parseFloat(splitByPG[line.productGroup][distributor[i]]) || 0;
      } else if (split) {
        percent = parseFloat(split[distributor[i]]) || 0;
      } else {
        percent = 100.0 / (customer.distributors || [0]).length;
      }

      percentByDist[distributor[i]] = percent;
      totalPercent += percent;
    }

    // NB(daniel): if totalPercent (denominator) is 0,
    // then all the numerators are likely to be 0 as well
    if (totalPercent == 0) totalPercent = 100;

    return { totalPercent, percentByDist };
  }

  prom.forecastedSpendSplit = {};

  const spendByDist = {};
  let totalSpend = 0;

  for (const lineKey in prom.lines) {
    var line = prom.lines[lineKey];
    var { totalPercent, percentByDist } = getSplit(line.productGroup);

    for (var key in percentByDist) {
      const thisDistSpend =
        (parseFloat(line.totalExpSpend) * percentByDist[key]) / totalPercent;
      totalSpend += thisDistSpend;
      percentByDist[key] /= totalPercent;

      if (key in spendByDist) spendByDist[key] += thisDistSpend;
      else spendByDist[key] = thisDistSpend;
    }

    prom.forecastedSpendSplit[lineKey] = percentByDist;
  }

  var { totalPercent, percentByDist } = getSplit(null);
  for (var key in spendByDist) {
    spendByDist[key] =
      totalSpend == 0
        ? percentByDist[key] / totalPercent
        : spendByDist[key] / totalSpend;
  }
  prom.forecastedSpendSplit[promKey] = spendByDist;

  return prom.forecastedSpendSplit;
}

function collapseByMonthYear(pivot, field, itemsList, monthyears) {
  // returns object indexed by pivot where each value is another object indexed by fields in dateColumns (i.e. months + quarters)
  const data = {};

  for (let i = 0; i < itemsList.length; i++) {
    const s = itemsList[i];
    const monthyear = `${months[s.month - 1]} ${s.year}`; // index from jan = 0
    if (s[pivot] in data) {
      data[s[pivot]][monthyear] += parseFloat(s[field]) || 0;
    } else {
      data[s[pivot]] = {};
      for (let j = 0; j < 12; j++) data[s[pivot]][monthyears[j]] = 0;
      data[s[pivot]][monthyear] = parseFloat(s[field]) || 0;
    }
  }

  return data;
}

function convertDataForTableAccrual(
  pivot,
  spendByProm,
  linesByProm,
  promotions,
  allLines,
  monthyears,
  accrualMonth,
  customers,
  meta,
  searchProm,
  accounts
) {
  // spendByProm and linesByProm are objects indexed by promKey that map to sub-objects of respective values
  const entries = [];
  for (const promKey in linesByProm) {
    const linesData = collapseByMonthYear(
      pivot,
      "totalExpSpend",
      linesByProm[promKey],
      monthyears
    );
    const spendData = spendByProm[promKey]
      ? collapseByMonthYear(pivot, "spend", spendByProm[promKey], monthyears)
      : null;
    const prom = promotions[promKey];
    if (!prom) continue;

    const spendSplit = forecastedSpendSplit(prom, promKey, customers);

    for (const key in linesData) {
      let { distributor } = prom;
      if (typeof distributor === "string") {
        distributor = [distributor];
      }

      for (let distIndex = 0; distIndex < distributor.length; distIndex++) {
        if (
          searchProm &&
          searchProm.distributor &&
          distributor[distIndex] != searchProm.distributor
        ) {
          continue;
        }

        const curDist = distributor[distIndex];
        const multiplier = spendSplit[key][curDist];

        const entry = {};

        entry.spendSplit = spendSplit; // for debugging purposes only

        // record total expected spend
        let totalExpSpend = 0;
        for (var m = 0; m < 12; m++)
          totalExpSpend += linesData[key][monthyears[m]];
        entry.totalExpSpend = Math.round(multiplier * totalExpSpend);

        for (var m = 0; m < 12; m++) {
          const spend =
            spendData && key in spendData ? spendData[key][monthyears[m]] : 0;
          entry[monthyears[m]] = Math.round(
            multiplier * (linesData[key][monthyears[m]] - spend)
          );
        }
        // promotion time's position
        const monthyear = `${months[prom.month - 1]} ${prom.year}`;
        const monthyearInd = monthyears.indexOf(monthyear);

        entry.name = pivot != "lineKey" ? prom.name : allLines[key].name;
        entry.monthyear = monthyear;
        entry.id = key;
        entry.promKey = promKey;
        entry.distributor = curDist;

        // add account if selection is by line
        if (pivot == "lineKey") {
          entry.account = accounts?.[allLines[key].account]?.name;
        }

        let promEndDate = Math.max.apply(
          null,
          Object.values(prom.lines).map(x => {
            const fields = [
              "endDate",
              "instoreEndDate",
              "scanbackEndDate",
              "buyinEndDate"
            ];
            let correctField = fields[0];
            for (let f = 0; f < fields.length; f++) {
              if (fields[f] in x) correctField = fields[f];
            }
            return new Date(x[correctField]);
          })
        );
        if (pivot == "lineKey") {
          promEndDate = allLines[key].endDate;
        }
        const daysSince = daysBetween(new Date(promEndDate), new Date());
        if (daysSince >= 0) {
          entry.daysUntilClose =
            meta.autoCloseDays && !meta.no_auto_close
              ? Math.max(0, meta.autoCloseDays - daysSince)
              : "Not set";
        } else {
          entry.daysUntilClose = "Not completed yet";
        }

        let remaining = 0;
        for (var m = 0; m < 12; m++) {
          remaining += entry[monthyears[m]];
        }
        entry.remaining = remaining;

        entry.reversal = getProposedReversal(
          monthyearInd,
          accrualMonth,
          entry,
          monthyears
        );

        entries.push(entry);
      }
    }
  }

  return sortByField("name", entries, true);
}

function getProposedReversal(startMonth, accrualMonth, entry, monthyears) {
  if (accrualMonth <= startMonth) {
    return 0;
  }

  // the amount for the current accrual month
  const currAmount = entry[monthyears[accrualMonth]];
  if (currAmount == 0) {
    return 0;
  }

  // total from start to accrual month
  let balance = 0;
  for (let i = startMonth; i <= accrualMonth; i++) {
    balance += entry[monthyears[i]];
  }

  // non-negative balance: reverse current amount but ONLY if it's negative
  if (balance >= 0) {
    return Math.abs(Math.min(0, currAmount));
  }

  // negative balance AND negative current amount
  if (balance > currAmount && currAmount < 0) {
    return balance - currAmount;
  }

  return 0;
}

/** ******************************************************************************************************************* */
/** ********************************************** PUBLIC FUNCTIONS ********************************************* */
/** ******************************************************************************************************************* */

/** ************************************************************************ */
/** *************************** Tables ********************************* */
/** ************************************************************************ */

function convertDataForTableROI(
  pivot,
  promList,
  roiDataByProm,
  meta,
  allPivots
) {
  const entriesDict = {};
  // for promotion, just spit out the ROIs
  // for customer and date, go through promos
  // for product and fund type, go through lines
  for (var key in roiDataByProm) {
    const prom = promList[key];
    var s = {
      key,
      spend: roiDataByProm[key].Totals.Value[1],
      revenue: roiDataByProm[key].Totals.Value[2],
      grossSales: roiDataByProm[key].Totals.Value[3]
    };
    if (pivot == "prom") {
      if (!(s.key in entriesDict)) {
        entriesDict[s.key] = [];
      }
      entriesDict[s.key].push(s);
    }
    if (pivot == "date") {
      s.key = months[promList[key].month - 1];
      if (!(s.key in entriesDict)) {
        entriesDict[s.key] = [];
      }
      entriesDict[s.key].push(s);
    } else if (pivot == "customer") {
      s.key = promList[key].customerName;
      if (!(s.key in entriesDict)) {
        entriesDict[s.key] = [];
      }
      entriesDict[s.key].push(s);
    } else if (pivot == "contact") {
      if (!promList[key].contact) {
        s.key = "Others";
        if (!(s.key in entriesDict)) {
          entriesDict[s.key] = [];
        }
        entriesDict[s.key].push(s);
      }

      forEach(promList[key].contact, contact => {
        s.key = allPivots?.[contact]?.name;
        if (!(s.key in entriesDict)) {
          entriesDict[s.key] = [];
        }
        entriesDict[s.key].push(s);
      });
    } else if (pivot == "fundtype") {
      for (var line of Object.values(prom.lines)) {
        s.key = line.type;
        if (!(s.key in entriesDict)) {
          entriesDict[s.key] = [];
        }
        entriesDict[s.key].push(s);
      }
    } else if (pivot == "product") {
      for (var line of Object.values(prom.lines)) {
        if (line.productName) {
          for (let i = 0; i < line.productName.length; i++) {
            s.key = line.productName[i];
            if (!(s.key in entriesDict)) {
              entriesDict[s.key] = [];
            }
            entriesDict[s.key].push(s);
          }
        }
        if (line.productGroup in meta.product_groups) {
          s.key = `*${meta.product_groups[line.productGroup].name}`;
          if (!(s.key in entriesDict)) {
            entriesDict[s.key] = [];
          }
          entriesDict[s.key].push(s);
        }
      }
    }
  }
  let entries = [];
  for (var [key, vals] of Object.entries(entriesDict)) {
    var vals = entriesDict[key];
    const item = {
      key,
      spend: sum(vals.map(x => x.spend)),
      revenue: sum(vals.map(x => x.revenue)),
      grossSales: sum(vals.map(x => x.grossSales))
    };
    // custom fields needed
    if (pivot == "date") {
      item.dateIndex = months.indexOf(key);
    }
    if (pivot == "prom") {
      item.promotion = promList[key].name;
    }
    item.roiLessCOGS = item.revenue / item.spend;
    item.roiGrossSales = item.grossSales / item.spend;
    entries.push(item);
  }

  if (pivot == "date") {
    entries = sortByField("dateIndex", entries, true);
  } else {
    entries = sortByField("key", entries, true);
  }
  return entries;
}

function dateItemsTable(
  actField,
  actItemsList,
  expField,
  expItemsList,
  byDate
) {
  const actData = getItemsByMonth(actField, actItemsList, byDate);
  let expData = getItemsByMonth(expField, expItemsList);
  if (byDate) {
    const newExpData = {};
    for (const key in actData) {
      const month = months[new Date(key).getMonth()];
      newExpData[key] = expData[month] || 0;
    }
    expData = newExpData;
  }
  const entries = convertDataForTable1(actData, expData, "date");
  return byDate ? sortByDate(entries) : sortByField("dateIndex", entries, true);
}

function productItemsTable(
  actField,
  actItemsList,
  expField,
  expItemsList,
  allPivots,
  meta
) {
  const actData = getItemsByProduct(actField, actItemsList);
  const expData = getItemsByProduct(expField, expItemsList);
  const entriesObj = convertDataForTable2(
    actData,
    expData,
    "product",
    meta.product_groups
  );
  return entriesObj;
}

function customerItemsTable(
  actField,
  actItemsList,
  expField,
  expItemsList,
  allPivots
) {
  const actData = getItemsByCustomer(actField, actItemsList);
  const expData = getItemsByCustomer(expField, expItemsList);
  const entriesObj = convertDataForTable2(
    actData,
    expData,
    "customer",
    allPivots
  );
  return entriesObj;
}

function contactItemsTable(
  actField,
  actItemsList,
  expField,
  expItemsList,
  allPivots,
  selectedContacts
) {
  const actData = getItemsByContact(actField, actItemsList);
  const expData = getItemsByContact(expField, expItemsList);
  // filter linked contacts that are not in selected contacts
  if (selectedContacts[0] != -1) {
    for (var contact of Object.keys(actData)) {
      if (!selectedContacts.includes(contact)) {
        delete actData[contact];
      }
    }
    for (var contact of Object.keys(expData)) {
      if (!selectedContacts.includes(contact)) {
        delete expData[contact];
      }
    }
  }

  const entriesObj = convertDataForTable2(
    actData,
    expData,
    "contact",
    allPivots
  );
  return entriesObj;
}

function promItemsTable(
  actField,
  actItemsList,
  expField,
  expItemsList,
  allPivots
) {
  const actData = getItemsByProm(actField, actItemsList);
  const expData = getItemsByProm(expField, expItemsList);
  const entries = convertDataForTable1(
    actData,
    expData,
    "promotion",
    allPivots
  );
  return entries;
}

function accountItemsTable(actField, actItemsList, expField, expItemsList) {
  const actData = getItemsByAccount(actField, actItemsList);
  const expData = getItemsByAccount(expField, expItemsList);
  const entriesObj = convertDataForTable2(actData, expData, "account");
  return entriesObj;
}

function accrualTable(
  pivot,
  spendItemsList,
  linesList,
  promotions,
  allLines,
  monthyears,
  accrualMonth,
  customers,
  meta,
  searchProm,
  accounts
) {
  // For accruals, we tie spend to the date's month, instead of that of the promotion
  spendItemsList = spendItemsList.map(spendItem => {
    return { ...spendItem, month: new Date(spendItem.date).getMonth() + 1 };
  });

  function getByProm(itemsList) {
    const itemsByProm = {};
    for (let i = 0; i < itemsList.length; i++) {
      // break itemsList into subarrays by promKey
      const { promKey } = itemsList[i];
      if (promKey in itemsByProm) {
        itemsByProm[promKey].push(itemsList[i]);
      } else {
        itemsByProm[promKey] = [itemsList[i]];
      }
    }
    return itemsByProm;
  }

  const spendByProm = getByProm(spendItemsList);
  const linesByProm = getByProm(linesList);

  const entries = convertDataForTableAccrual(
    pivot,
    spendByProm,
    linesByProm,
    promotions,
    allLines,
    monthyears,
    accrualMonth,
    customers,
    meta,
    searchProm,
    accounts
  );
  return entries;
}

function pricingTable(pricingData, allCustomers, curDate) {
  let entries = [];
  for (const customerKey in allCustomers) {
    const entry = {};
    entry.customer = customerKey;
    entry.customerName = allCustomers[customerKey]
      ? allCustomers[customerKey].name
      : customerKey;
    entry.isRetailer =
      !allCustomers[customerKey].isDistributor &&
      !allCustomers[customerKey].isDirect;
    if (customerKey in pricingData) {
      for (const productKey in pricingData[customerKey]) {
        // Here, 'productKey' iterates across all products and productGroups
        const timeframe = getCurrentTimeframe(
          pricingData[customerKey][productKey],
          curDate
        );
        entry[productKey] = pricingData[customerKey][productKey];
        entry[productKey].price = timeframe.price;
      }
    }

    entries.push(entry);
  }

  entries = sortByField("customerName", entries, true);
  return sortByField("isRetailer", entries, true);
}

function liftTable(liftData, allCustomers, liftBucket) {
  const entries = [];
  const liftDataForBucket = liftData[liftBucket] || {};
  for (const customerKey in allCustomers) {
    const entry = {};
    entry.customer = customerKey;
    entry.customerName = allCustomers[customerKey]
      ? allCustomers[customerKey].name
      : customerKey;
    if (customerKey in liftDataForBucket) {
      for (const productKey in liftDataForBucket[customerKey]) {
        // Here, 'productKey' iterates across all products and productGroups
        entry[productKey] = liftDataForBucket[customerKey][productKey].lift;
      }
    }

    entries.push(entry);
  }

  return sortByField("customerName", entries, true);
}

function seasonalityTable(seasonalityData, bucketNames, year) {
  const entries = [];
  const weeks = getYearWeeks(year);
  for (const bucket of bucketNames) {
    const entry = {};
    entry.bucket = bucket;
    if (bucket in seasonalityData) {
      for (const week in seasonalityData[bucket]) {
        entry[week] = seasonalityData[bucket][week].seasonality_index;
      }
    }
    entries.push(entry);
  }

  return sortByField("bucket", entries, true);
}

function promotionTimingAnalyticsData(
  year,
  proms,
  spendItemsList,
  revenueItemsList,
  byQuarter = false
) {
  if (byQuarter) {
    var timingYear = quarters.map(quarter => `${quarter}-${year}`);
    var periodConverter = [
      timingYear[0],
      timingYear[0],
      timingYear[0],
      timingYear[1],
      timingYear[1],
      timingYear[1],
      timingYear[2],
      timingYear[2],
      timingYear[2],
      timingYear[3],
      timingYear[3],
      timingYear[3]
    ];
    var periodIndex = [0, 0, 0, 1, 1, 1, 2, 2, 2, 3, 3, 3];
  } else {
    var timingYear = months.map(month => `${month}-${year}`);
    var periodConverter = { ...timingYear };
    var periodIndex = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  }

  let allPromTimingData = [];
  const prevYearPromSpend = { timeField: "Past Years" }; // spend entered in current year tied to promotions from last year
  const nextYearPromSpend = { timeField: "Future Years" };
  const totalSpend = { timeField: "Total Deductions" };
  const totalRevenue = { timeField: "Total Revenue" };
  const tradeRate = { timeField: "Trade Rates" };
  for (const val of timingYear) {
    prevYearPromSpend[val] = 0;
    nextYearPromSpend[val] = 0;
    totalSpend[val] = 0;
    totalRevenue[val] = 0;
  }
  const currentYearPromSpend = []; // spend entered in current year tied to promotions from current year
  for (var i = 0; i < timingYear.length; i++) {
    const promSpend = {};
    for (const val2 of timingYear) {
      promSpend[val2] = 0;
    }
    promSpend.timeField = timingYear[i];
    currentYearPromSpend.push(promSpend);
  }

  // update chart values
  for (const revenueItem of revenueItemsList) {
    const { revenue } = revenueItem;
    const revenueMonth = revenueItem.month - 1; // months are 1 larger than the month index
    const revenueYear = revenueItem.year;
    if (revenueYear == year) {
      totalRevenue[periodConverter[revenueMonth]] += parseFloat(revenue) || 0;
    }
  }
  for (const spendItem of spendItemsList) {
    const { spend } = spendItem;
    const spendMonth = spendItem.month - 1; // months are 1 larger than the month index
    const spendYear = spendItem.year;
    const { promKey } = spendItem;
    const prom = proms[promKey];
    const promMonth = prom.month - 1;
    const promYear = prom.year;
    if (promYear == year && spendYear == year) {
      currentYearPromSpend[periodIndex[promMonth]][
        periodConverter[spendMonth]
      ] += spend;
      totalSpend[periodConverter[spendMonth]] += spend;
    } else if (promYear < year && spendYear == year) {
      prevYearPromSpend[periodConverter[spendMonth]] += spend;
    } else if (promYear > year && spendYear == year) {
      nextYearPromSpend[periodConverter[spendMonth]] += spend;
    }
  }

  // add total field
  prevYearPromSpend.Total = sum(timingYear.map(val => prevYearPromSpend[val]));
  nextYearPromSpend.Total = sum(timingYear.map(val => nextYearPromSpend[val]));
  for (var i = 0; i < currentYearPromSpend.length; i++) {
    currentYearPromSpend[i].Total = sum(
      timingYear.map(val => currentYearPromSpend[i][val])
    );
  }
  totalSpend.Total = sum(timingYear.map(val => totalSpend[val]));
  totalRevenue.Total = sum(timingYear.map(val => totalRevenue[val]));
  for (var field of timingYear.concat(["Total"])) {
    prevYearPromSpend[field] = Math.round(prevYearPromSpend[field]);
    nextYearPromSpend[field] = Math.round(nextYearPromSpend[field]);
    totalSpend[field] = Math.round(totalSpend[field]);
    totalRevenue[field] = Math.round(totalRevenue[field]);
    if (totalSpend[field] === 0 && totalRevenue[field] === 0) {
      tradeRate[field] = 0;
    } else {
      tradeRate[field] = round(100 * (totalSpend[field] / totalRevenue[field]));
    }
  }
  for (var i = 0; i < currentYearPromSpend.length; i++) {
    for (var field of timingYear.concat(["Total"])) {
      currentYearPromSpend[i][field] = Math.round(
        currentYearPromSpend[i][field]
      );
    }
  }

  // concatenate all data together
  allPromTimingData.push(prevYearPromSpend);
  allPromTimingData.push(nextYearPromSpend);
  allPromTimingData = allPromTimingData.concat(currentYearPromSpend);
  allPromTimingData.push(totalSpend);
  allPromTimingData.push(totalRevenue);
  allPromTimingData.push(tradeRate);
  return allPromTimingData;
}

/** ************************************************************************ */
/** *************************** Charts ********************************* */
/** ************************************************************************ */

function productPastPerformanceGraph(actField, actItemsList) {
  const data = {};

  for (var i = 1; i <= months.length; i++) {
    data[i] = { value: 0, customers: {} };
  }
  for (var i = 0; i < actItemsList.length; i++) {
    const s = actItemsList[i];
    var key = `${s.year} ${s.month}`;
    data[s.month].value += parseFloat(s[actField]);
    data[s.month].customers[s.customer] = true;
  }

  const entries = [];
  for (var key in data) {
    const m = parseInt(key);
    entries.push({
      month: m,
      date: months[m - 1],
      "Total Spend": Math.round(data[key].value),
      "Number Customers": Object.keys(data[key].customers).length
    });
  }
  return sortByField("month", entries, true);
}

function productPastPromotionsTable(actField, actItemsList, allPromotions) {
  const data = getItemsByProm(actField, actItemsList);
  const result = {
    "M/Y": [],
    Promotion: [],
    Total: []
  };
  let entries = [];

  for (const key in data) {
    const p = allPromotions[key];
    entries.push({
      dateField: new Date(p.year, p.month - 1, 1).getTime(),
      total: data[key],
      prom: p.name,
      month: p.month,
      year: p.year
    });
  }
  entries = sortByField("dateField", entries);
  for (let i = 0; i < entries.length; i++) {
    result["M/Y"].push(printMonthYear(entries[i].month, entries[i].year));
    result.Total.push(entries[i].total.toFixed(2));
    result.Promotion.push(entries[i].prom);
  }
  if (entries.length == 0) {
    result["M/Y"].push("No previous promotions with this product.");
    result.Total.push("");
    result.Promotion.push("");
  }

  return result;
}

function bumpChart(actField, actItemsList, allLines) {
  const data = {};
  const discountData = {};

  for (let i = 0; i < actItemsList.length; i++) {
    const s = actItemsList[i];
    if (s.date in data) {
      data[s.date] += parseFloat(s[actField]);
      discountData[s.date] += parseFloat(allLines[s.lineKey].spendRate);
    } else {
      data[s.date] = parseFloat(s[actField]);
      discountData[s.date] = parseFloat(allLines[s.lineKey].spendRate);
    }
  }

  const entries = [];
  for (const key in data) {
    const entry = {
      date: key,
      discount: discountData[key]
    };
    entry[actField] = data[key];
    entries.push(entry);
  }

  return sortByDate(entries);
}

// Args 'singleCustomer' and 'hideTotal' are optional
function revenueTimeSeries(
  field,
  revenueList,
  allCustomers,
  singleCustomer,
  hideTotal
) {
  const entries = [];
  // get only current year
  if (revenueList.length == 0) return entries;

  const totalRevDataByMonth = generalCollapse("month", field, revenueList);
  const customerDataByMonth = collapseWithMonth("customer", field, revenueList);
  const topCustomerByMonth = [];

  if (singleCustomer) {
    for (var m = 0; m < 12; m++) {
      topCustomerByMonth.push(singleCustomer);
    }
  } else {
    for (var month of months) {
      var topCustomer = Object.keys(customerDataByMonth).reduce((a, b) => {
        return customerDataByMonth[a][month] > customerDataByMonth[b][month]
          ? a
          : b;
      });
      topCustomerByMonth.push(topCustomer);
    }
  }

  for (var m = 1; m <= 12; m++) {
    var month = months[m - 1];
    var topCustomer = topCustomerByMonth[m - 1];
    const topCustomerMonthRev = customerDataByMonth[topCustomer] || {};
    const topCustomerRev = Math.round(topCustomerMonthRev[month]) || 0;
    const otherData =
      (Math.round(totalRevDataByMonth[m]) || 0) - topCustomerRev;
    entries.push({
      month,
      "Other Revenue": hideTotal ? 0 : otherData,
      "Top Customer Revenue": topCustomerRev,
      "Top Customer": allCustomers[topCustomer].name
    });
  }

  return entries;
}

function topRevenueProducts(revenueList, allProducts) {
  const filteredList = [];
  for (let i = 0; i < revenueList.length; i++) {
    if (revenueList[i].date.getYear() == new Date().getYear()) {
      filteredList.push(revenueList[i]);
    }
  }

  const dataByProduct = generalCollapse("product", "revenue", filteredList);

  const entries = [];
  for (const key in dataByProduct) {
    entries.push({
      Product: allProducts[key] ? allProducts[key].name : "Unspecified",
      Revenue: dataByProduct[key]
    });
  }

  return sortByField("Revenue", entries).slice(0, 5);
}

function biggestPromsGraph(
  actField,
  actItemsList,
  expField,
  allPromotions,
  meta
) {
  const actData = getItemsByProm(actField, actItemsList);

  const entries = [];
  const now = new Date();

  for (const key in allPromotions) {
    const prom = allPromotions[key];
    if (meta.statuses[prom.status] == "Running") {
      const s = {
        name: prom.name,
        discount: prom.discount,
        Expected: Math.round(prom[expField]),
        Actual: Math.round(actData[key] || 0),
        promKey: prom.promKey
      };
      entries.push(s);
    }
  }

  return sortByField("Expected", entries).slice(0, 5);
}

function salesVsRateScatter(actField, actItemsList, allPromotions) {
  const data = getItemsByProm(actField, actItemsList);
  const entries = [];
  for (const key in data) {
    entries.push({
      spendRate: round(allPromotions[key].spendRate),
      sales: Math.round(data[key]),
      prom: allPromotions[key].name
    });
  }

  return entries;
}

function topCustomersRadial(allPromotions) {
  const data = {};
  const entries = [];
  const now = new Date();

  for (const promKey in allPromotions) {
    const prom = allPromotions[promKey];
    if (prom.month == now.getMonth() + 1 && prom.year == now.getYear() + 1900) {
      const customer = prom.customerName;
      if (customer in data) {
        data[customer] += prom.totalExpSpend;
      } else {
        data[customer] = prom.totalExpSpend;
      }
    }
  }

  for (const key in data) {
    entries.push({
      customer: key,
      totalExpSpend: data[key]
    });
  }

  return sortByField(
    "customer",
    sortByField("totalExpSpend", entries).slice(0, 6)
  );
}

function actVsExpTimeSeriesProm(actField, actItemsList, expValue) {
  // Collapse items by date
  const data = getItemsByMonth(actField, actItemsList, true);
  let entries = [];

  for (const key in data) {
    entries.push({
      date: key,
      Actual: data[key],
      Expected: expValue
    });
  }

  entries = sortByDate(entries);

  let cumul = 0;
  for (let i = 0; i < entries.length; i++) {
    cumul += entries[i].Actual;
    entries[i].Actual = cumul;
  }

  return entries;
}

function actVsExpBarProm(actField, actItemsList, expField, expItemsList, meta) {
  const actData = generalCollapse("type", actField, actItemsList);
  const expData = generalCollapse("type", expField, expItemsList);
  const entries = [];
  let actTotal = 0;
  let expTotal = 0;
  for (const key in actData) {
    entries.push({
      name: meta.fundTypes?.[key]?.name ?? "Unknown",
      Actual: actData[key] || 0,
      Expected: expData[key] || 0
    });
    actTotal += actData[key] || 0;
    expTotal += expData[key] || 0;
  }

  entries.push({
    name: "Total",
    Actual: actTotal,
    Expected: expTotal
  });

  return entries;
}

function bumpChartTimeSeries(bumpList) {
  const baseData = generalCollapse("date", "baseUnits", bumpList);
  const incrData = generalCollapse("date", "incrUnits", bumpList);
  const arpData = generalCollapse("date", "ARP", bumpList);

  const entries = [];
  for (const date in baseData) {
    const entry = {
      "Base Units": Math.round(baseData[date]),
      "Incr Units": Math.round(incrData[date]),
      ARP: round(arpData[date]),
      date
    };
    entries.push(entry);
  }

  return sortByDate(entries);
}

function complianceTimeSeries(bumpList) {
  const tdpData = generalCollapse("date", "TDP", bumpList);
  const tdpapData = generalCollapse("date", "TDPAnyPromo", bumpList);

  const entries = [];
  for (const date in tdpData) {
    const tdp = tdpData[date];
    const tdpap = tdpapData[date] || 0;
    const entry = {
      Compliance: Math.round((tdpap / tdp) * 100),
      date
    };
    entries.push(entry);
  }

  return sortByDate(entries);
}

function promotionTodos(allPromotions, meta) {
  function diffDays(date1, date2) {
    const timeDiff = Math.abs(date2.getTime() - date1.getTime());
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return diffDays;
  }

  const entries = [];
  const now = new Date();

  for (const key in allPromotions) {
    const prom = allPromotions[key];
    // if any of the prom's lines start within 30 days...
    if (
      meta.statuses[prom.status] == "Submitted" &&
      Object.values(prom.lines).some(
        line => diffDays(new Date(line.startDate), now) < 30
      )
    ) {
      const s = {
        name: prom.name,
        date: prom.startDate, // needs to be replaced by the startDate of the line
        promKey: key
      };
      entries.push(s);
    }
  }

  return sortByDate(entries);
}

function lastModifiedPromotions(allPromotions) {
  const entries = [];
  for (const key in allPromotions) {
    const prom = allPromotions[key];
    const s = {
      name: prom.name,
      date: `${prom.modified?.date} ${prom.modified?.time}`,
      promKey: key
    };
    entries.push(s);
  }

  return sortByDate(entries, true).slice(0, 5);
}

function biggestSpendDiffCustomers(customers, actItemsList, expItemsList) {
  const actSpend = generalCollapse("customer", "spend", actItemsList);
  const expSpend = generalCollapse("customer", "spend", expItemsList);
  const customerKeys = Array.from(
    new Set(Object.keys(actSpend).concat(Object.keys(expSpend)))
  );
  const customerDiff = {};
  for (var key of customerKeys) {
    if (key in customers) {
      customerDiff[customers[key].name] = 0;
    }
  }
  for (var key of customerKeys) {
    if (key in actSpend && key in customers) {
      customerDiff[customers[key].name] += actSpend[key];
    }
    if (key in expSpend && key in customers) {
      customerDiff[customers[key].name] -= expSpend[key];
    }
  }
  const entries = [];
  for (const [name, diff] of Object.entries(customerDiff)) {
    entries.push({ name, diff });
  }

  return sortByField("diff", entries).slice(0, 5);
}

function underPromoApprovalLimit(
  companyUser,
  uid,
  promoLimitsDict,
  totalExpSpend
) {
  const customPerms = companyUser.customPerms ? companyUser.customPerms : {};
  if (customPerms.hasOwnProperty("approvalLevel") && promoLimitsDict.custom) {
    return (
      !promoLimitsDict ||
      (customPerms.approvalLevel === 1 &&
        totalExpSpend < promoLimitsDict.custom[uid])
    );
  }

  return (
    !promoLimitsDict ||
    companyUser.access === "Admin" ||
    (companyUser.access === "Director" &&
      totalExpSpend < promoLimitsDict.Director) ||
    totalExpSpend < promoLimitsDict.Manager
  );
}

function calculateLinesROI(prom, actMoneyList, meta: Meta, pricing, prettify) {
  let { lines } = prom;
  const productGroups = meta.product_groups;
  const promTypes = {};
  Object.keys(meta.fundTypes ?? {}).forEach(key => {
    promTypes[key] = meta.fundTypes?.[key]?.name;
  });
  lines = lines || {};
  actMoneyList = actMoneyList || [];
  const roiData = {};

  // compute deduction totals for each line
  const actSpendByLine = {};
  for (const spendItem of actMoneyList) {
    if (spendItem.lineKey in actSpendByLine) {
      actSpendByLine[spendItem.lineKey] += spendItem.spend;
    } else {
      actSpendByLine[spendItem.lineKey] = spendItem.spend;
    }
  }

  // fill out fields for each line
  const userEnteredData = {
    Line: [],
    "Promotion Type": [],
    "Spend Rate": [],
    "Expected Units": [],
    "Expected Spend": []
  };
  const receivedData = {
    Line: [],
    "Product Group": [],
    "Spend Rate": [],
    "Actual Units": [],
    "Actual Spend": []
  };
  for (var [lineKey, line] of Object.entries(lines)) {
    var totalLineSpend = actSpendByLine[lineKey] || 0;
    var spendRate = line.spendRate || 0;
    const expectedUnits = line.totalExpSales || 0;
    userEnteredData.Line.push(lineKey);
    userEnteredData["Promotion Type"].push(promTypes[line.type]);
    userEnteredData["Spend Rate"].push(`$${spendRate}`);
    userEnteredData["Expected Units"].push(
      parseInt(expectedUnits).toLocaleString()
    );
    userEnteredData["Expected Spend"].push(
      displayMoney(Math.round(line.totalExpSpend))
    );
    receivedData.Line.push(lineKey);
    if (line.productGroup == "custom") {
      receivedData["Product Group"].push("Custom");
    } else {
      receivedData["Product Group"].push(productGroups[line.productGroup].name);
    }
    receivedData["Spend Rate"].push(`$${spendRate}`);
    const actualUnits = spendRate ? totalLineSpend / spendRate : 0;
    receivedData["Actual Units"].push(parseInt(actualUnits).toLocaleString());
    receivedData["Actual Spend"].push(displayMoney(Math.round(totalLineSpend)));
  }
  roiData["User Entered Data"] = userEnteredData;
  roiData["Deductions - Received Data"] = receivedData;

  // calculate ROI and profit for each line
  const breakdownByLine = {
    Line: [],
    "Assigned Lift (%)": [],
    "Incremental Units": [],
    "Product Margin": [],
    Price: [],
    "Product Margin ($)": [],
    "Gross Sales": []
  };
  if (!prettify) {
    breakdownByLine["Total Line Spend"] = [];
  }

  let totalProfit = 0;
  let totalGrossSales = 0;
  for (var [lineKey, line] of Object.entries(lines)) {
    var totalLineSpend = actSpendByLine[lineKey] || 0;
    var spendRate = line.spendRate || 0;
    const lift = line.lift || 0;
    const lineUnits = parseInt(spendRate != 0 ? totalLineSpend / spendRate : 0);
    const typeFields = meta.fundTypes?.[line.type]?.promCreationFields;
    const performanceWindow = typeFields.includes("instore")
      ? new Date(line.instoreEndDate).getTime() -
        new Date(line.instoreStartDate).getTime()
      : undefined;
    const buyinWindow = typeFields.includes("buyin")
      ? new Date(line.buyinEndDate).getTime() -
        new Date(line.buyinStartDate).getTime()
      : undefined;
    const scanbackWindow = typeFields.includes("scanback")
      ? new Date(line.scanbackEndDate).getTime() -
        new Date(line.scanbackStartDate).getTime()
      : undefined;
    const performanceRatio =
      performanceWindow / buyinWindow ||
      performanceWindow / scanbackWindow ||
      scanbackWindow / buyinWindow ||
      1;
    const incUnits = parseInt(
      (performanceRatio * (lineUnits * lift)) / (1 + lift)
    );
    const pgKey = line.productGroup;
    let price = 0;
    let productMargin = 0;
    if (prom.customer in pricing && pgKey in pricing[prom.customer]) {
      const timeframe = getCurrentTimeframe(
        pricing[prom.customer][pgKey],
        new Date(line.startDate)
      );
      price = timeframe?.price || pricing[prom.customer][pgKey].price;
      productMargin = productGroups[pgKey].grossMargin || 0;
    }
    const profit = incUnits * price * productMargin;
    const grossSales = incUnits * price;
    breakdownByLine.Line.push(lineKey);
    breakdownByLine["Assigned Lift (%)"].push(
      prettify ? parseInt(lift * 100) : lift
    );
    breakdownByLine["Incremental Units"].push(
      prettify ? incUnits.toLocaleString() : incUnits
    );
    breakdownByLine["Product Margin"].push(productMargin);
    breakdownByLine.Price.push(prettify ? `$${price}` : price);
    breakdownByLine["Product Margin ($)"].push(
      prettify ? displayMoney(Math.round(profit)) : profit
    );
    breakdownByLine["Gross Sales"].push(
      prettify ? displayMoney(Math.round(grossSales)) : grossSales
    );
    if (!prettify) {
      breakdownByLine["Total Line Spend"].push(totalLineSpend);
    }
    totalProfit += prettify ? Math.round(profit) : profit;
    totalGrossSales += prettify ? Math.round(grossSales) : grossSales;
  }
  roiData["Breakdown by Line"] = breakdownByLine;

  // calculate totals
  const totalExpSpendDollars = prettify
    ? displayMoney(prom.totalExpSpend)
    : prom.totalExpSpend;
  const totalActSpend = round(sum(Object.values(actSpendByLine)));
  const totalActSpendDollars = prettify
    ? displayMoney(totalActSpend)
    : totalActSpend;
  const totalProfitDollars = prettify ? displayMoney(totalProfit) : totalProfit;
  const ROILessCOGS = round(totalProfit / totalActSpend);
  const ROIGrossSales = round(totalGrossSales / totalActSpend);
  totalGrossSales = prettify ? displayMoney(totalGrossSales) : totalGrossSales;
  const totals = {
    Field: [
      "Total Expected Spend",
      "Total Actual Spend",
      "Product Margin ($)",
      "Total Gross Sales",
      "ROI (Less COGS)",
      "ROI (Gross Sales)"
    ],
    Value: [
      totalExpSpendDollars,
      totalActSpendDollars,
      totalProfitDollars,
      totalGrossSales,
      ROILessCOGS,
      ROIGrossSales
    ]
  };
  roiData.Totals = totals;

  return roiData;
}

function deepClone<T>(obj: T): T {
  return cloneDeep(obj);
}

const camelizeKeys = dataObj => {
  return JSON.parse(
    JSON.stringify(dataObj)
      .trim()
      .replace(/("\w+":)/g, keys => {
        return keys.replace(/(.(\_|-|\s)+.)/g, subStr => {
          return subStr[0] + subStr[subStr.length - 1].toUpperCase();
        });
      })
  );
};

export {
  camelizeKeys,
  deepClone,
  contactItemsTable,
  arrayify,
  removeVal,
  getTotals,
  getItemsByCustomer,
  displayMoney,
  cleanFileName,
  productPastPromotionsTable,
  productPastPerformanceGraph,
  bumpChart,
  revenueTimeSeries,
  biggestPromsGraph,
  salesVsRateScatter,
  topCustomersRadial,
  convertDataForTableROI,
  calculateLinesROI,
  customerItemsTable,
  dateItemsTable,
  productItemsTable,
  promItemsTable,
  accountItemsTable,
  actVsExpTimeSeriesProm,
  actVsExpBarProm,
  accrualTable,
  pricingTable,
  liftTable,
  seasonalityTable,
  topRevenueProducts,
  bumpChartTimeSeries,
  complianceTimeSeries,
  promotionTodos,
  lastModifiedPromotions,
  biggestSpendDiffCustomers,
  promotionTimingAnalyticsData,
  underPromoApprovalLimit,
  months,
  addDicts,
  escapeQuotes,
  round,
  displayUSCurrency
};
