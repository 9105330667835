import React from "react";
import axios from "axios";
import { connect } from "react-redux";

import { withSnackbar } from "notistack";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography
} from "@mui/material";
import DatePicker from "ui-library/DatePicker";
import Button from "ui-library/Button";
import MenuItem from "ui-library/MenuItem";
import Select from "ui-library/Select";
import TextField from "ui-library/TextField";
import PublishIcon from "@mui/icons-material/Publish";
import ScannerIcon from "@mui/icons-material/Scanner";

import MUIButton from "@mui/material/Button";

import FilePicker from "components/WebsiteElements/FilePicker";
import {
  firebase,
  cloudRunURL,
  uploadFilesToStorage,
  getCompanyNamesFirebase,
  getAllowedCompanies
} from "helpers/Firebase";
import { getTimesJSX } from "helpers/Time";
import Mixpanel from "helpers/Mixpanel";
import { common } from "@mui/material/colors";
import { CancelOutlined } from "@mui/icons-material";
import { reset } from "./DeductionsMatching/actions";
import MatchDeductions from "./DeductionsMatching/MatchDeductions";
import { importBackupSources } from "../constants/BackupSources";
import ExampleTemplateViewerSelector from "./ExampleTemplateViewerSelector";

const { white } = common;
const { black } = common;

const styles = {
  miniIcon: {
    height: 15,
    width: 15,
    marginLeft: 16
  },
  subheader: {
    fontSize: 20,
    color: black,
    padding: 0
  },
  divider: {
    margin: "30px auto",
    width: "100%",
    color: black
  },
  centerColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  fieldContainer: {
    width: "50%",
    padding: "1%"
  }
};

const Section = ({
  step,
  header,
  subheader,
  content
}: {
  step: number;
  header: JSX.Element | string;
  subheader: JSX.Element | string;
  content: JSX.Element;
}) => (
  <Grid container mb={4}>
    <Grid item md={1}>
      <Avatar
        sx={theme => ({
          bgcolor: theme.palette.primary.main,
          height: "1.2em",
          width: "1.2em",
          fontSize: "16px"
        })}>
        {step}
      </Avatar>
    </Grid>
    <Grid item md={11}>
      <Typography color="primary" variant="h6">
        {header}
      </Typography>
      <Typography variant="caption">{subheader}</Typography>
      {content}
    </Grid>
  </Grid>
);

const pagelength = 20;

export const NONE = "None Selected";

class DeductionsToolPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: {},
      companyUsers: {},
      servicedCompanies: {},
      currentlySelected: "",
      showSearch: false,
      searchQuery: "",
      openFilePicker: false,
      modalOpen: false,
      reverseSort: false,
      multi: false,
      snackbar: false,
      allSelected: [],
      count: pagelength,
      customersJSX: [],
      tableBodyRenderKey: 0,
      file: null,
      pageNumber: 1,
      numPages: 1,
      backupSource: "None",
      numScans: 0,
      promType: [],
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      spendDate: new Date(),
      checkNumber: "",
      errorText: {},
      matchDeductionsOpen: false,
      availableBackupSources: [],

      // new stuff
      backupSourceName: undefined,
      backupSource: NONE,
      showBackupSourceSelector: false
    };
  }

  openFilePicker = jsx => {
    Mixpanel.track("Deductions Matching Window Open", {});
    this.setState({
      openFilePicker: true
    });
    this.props.showDropDownWindow(jsx, () => this.props.dispatch(reset()));
  };

  onDocumentLoadSuccess = document => {
    const { numPages } = document;
    this.setState({
      numPages,
      pageNumber: 1
    });
  };

  changePage = offset =>
    this.setState(prevState => ({
      pageNumber: prevState.pageNumber + offset
    }));

  snackbarAction = key => (
    <>
      <Button
        label="Dismiss"
        onClick={() => {
          this.props.closeSnackbar(key);
        }}
        style={{ color: white }}
      />
    </>
  );

  hasScanDependencies = () => {
    // ensure that the user has selected all required fields
    let hasDependencies = true;
    const errorText = {};
    if (
      this.props.db.meta.free_trial?.on_trial &&
      !this.props.freeTrialActive
    ) {
      this.props.enqueueSnackbar("You Have Exceeded Your Free Trial Quota", {
        variant: "default",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center"
        },
        action: this.snackbarAction
      });
      return false;
    }
    if (this.props.files.length == 0) {
      this.props.enqueueSnackbar("Please Upload At Least One File", {
        variant: "default",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center"
        },
        action: this.snackbarAction
      });
      return false;
    }
    if (this.state.deductionsServicing && !this.state.servicedCompany) {
      hasDependencies = false;
      errorText["Deductions Servicing"] = "Required Field";
    }
    if (this.state.backupSource == "None") {
      hasDependencies = false;
      errorText["Backup Source"] = "Required Field";
    }
    this.setState({ errorText });
    return hasDependencies;
  };

  scanFile = () => {
    if (!this.hasScanDependencies()) {
      return;
    }
    const user = firebase.auth().currentUser;
    const { spendDate, checkNumber } = this.state;
    const { files } = this.props;
    const filePaths = files.map(
      file => `${this.props.companyid}/Deductions Scan/${file.name}`
    );
    this.setState({ numScans: this.state.numScans + 1 });
    this.props.enqueueSnackbar(`Scanning: Check ${checkNumber}`, {
      variant: "default",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center"
      },
      action: this.snackbarAction
    });
    uploadFilesToStorage(filePaths, files, () => {
      // data sent to backend
      const formData = {};
      formData.userid = user.uid;
      formData.file_paths = filePaths;
      formData.template_fb_key = this.state.backupSource;
      formData.prom_type =
        this.state.promType.length == 0
          ? "None"
          : this.state.promType.join("|"); // TODO: why?
      formData.companyid = this.state.deductionsServicing
        ? this.state.servicedCompany
        : this.props.companyid;
      formData.userid = firebase.auth().currentUser.uid;
      formData.email = user.email;
      formData.month = this.state.month;
      formData.year = this.state.year;
      formData.spend_date = `${spendDate.getFullYear()}/${
        spendDate.getMonth() + 1
      }/${spendDate.getDate()}`;
      formData.check_number = this.state.checkNumber;
      formData.servicer = this.state.deductionsServicing
        ? this.props.companyid
        : null;
      // track in Mixpanel
      Mixpanel.track("Deductions Scan", {
        "Backup Source": formData.template_fb_key,
        "Promotion Type": formData.prom_type,
        Pages: this.state.numPages
      });

      // send data to scanning service
      axios
        .post(`${cloudRunURL}/api/scan_deductions_file`, {
          data: formData
        })
        .then(response => {
          const { success } = response.data.data;
          if (success == "Success") {
            this.props.enqueueSnackbar(
              `Succesfully scanned ${checkNumber}. Please check out the Processed  Scans tab to download your file(s).`,
              {
                variant: "success",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center"
                },
                action: this.snackbarAction
              }
            );
          } else if (success == "Failure") {
            this.props.enqueueSnackbar(`Failed: Check ${checkNumber}`, {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center"
              },
              action: this.snackbarAction
            });
          } else if (success == "Partial Success") {
            this.props.enqueueSnackbar(
              `Partial Success: Check ${checkNumber}`,
              {
                variant: "warning",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center"
                },
                action: this.snackbarAction
              }
            );
          } else if (success == "Maybe Success") {
            this.props.enqueueSnackbar(`Maybe Success: Check ${checkNumber}`, {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center"
              },
              action: this.snackbarAction
            });
          }
        })
        .catch(error => {
          console.error("error", error.response);
        });
    });
  };

  componentDidMount() {
    importBackupSources()
      .then(backupSources => {
        const availableBackupSources = Object.values(backupSources).sort(
          (source1, source2) => {
            return source1.DISPLAY_NAME.localeCompare(source2.DISPLAY_NAME);
          }
        );
        this.setState({
          deductionsServicing: this.props.db.meta.deductions_scanning_servicing,
          availableBackupSources
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          deductionsServicing: this.props.db.meta.deductions_scanning_servicing
        });
      });

    getCompanyNamesFirebase(companies => {
      /* 
      "companies" denotes companies that the user is assigned to, but companies 
      that the user can scan may be a subset of this 
      */
      const deductionsServicedCompanies =
        this.props.db.meta.deductions_scanning_serviced_companies || [];
      const companiesFiltered = {};
      for (const [companyId, companyName] of Object.entries(companies)) {
        if (deductionsServicedCompanies.includes(companyId)) {
          companiesFiltered[companyName] = companyId;
        }
      }
      this.setState(
        { servicedCompanies: companiesFiltered },
        getAllowedCompanies.bind(null, validEmailObj => {
          this.setState({
            servicedCompanies: _.zipObject(
              arrayify(validEmailObj.company),
              arrayify(validEmailObj.cid)
            )
          });
        })
      );
    });
  }

  render() {
    const fundTypes = this.props.standalone
      ? {}
      : this.props.db.meta.fundTypes ?? {};
    const [monthsJSX, yearsJSX] = getTimesJSX(this.props.db.meta, true);

    return (
      <div>
        {this.state.showBackupSourceSelector && (
          <ExampleTemplateViewerSelector
            selector={{
              selectedBackupSource: this.state.backupSource,
              onBackupSourceSelect: (bs: {
                key: string;
                displayName: string;
              }): void => {
                this.setState({
                  backupSource: bs.key,
                  backupSourceName: bs.displayName
                });
              }
            }}
            open={this.state.showBackupSourceSelector}
            setOpen={open => {
              this.setState({
                showBackupSourceSelector: open
              });
            }}
          />
        )}
        <Stack m={4} direction="column" alignItems="flex-start">
          <Grid container>
            <Grid item md={12} pb={4}>
              <Typography variant="h3">Deduction Scanning</Typography>
            </Grid>
            <Section
              step={1}
              header="Select Backup Source Template"
              subheader={
                // TODO: add actual URL from CST
                <>
                  Browse and select the template that matches your backup file.
                  {/* Click&nbsp;
                  <a href="#">
                    <span style={{ textDecoration: "underline" }}>here</span>
                  </a>
                  &nbsp;to learn more about templates. */}
                </>
              }
              content={
                <Box
                  mt={2}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}>
                  <TextField
                    label="Template Name"
                    value={this.state.backupSourceName || NONE}
                    variant="outlined"
                    fullWidth
                    onClick={e => {
                      if (!e.defaultPrevented) {
                        this.setState({
                          showBackupSourceSelector: true
                        });
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <Tooltip title="Clear">
                          <IconButton
                            onClick={e => {
                              e.preventDefault();
                              this.setState({
                                backupSource: NONE,
                                backupSourceName: undefined
                              });
                            }}>
                            <CancelOutlined />
                          </IconButton>
                        </Tooltip>
                      )
                    }}
                  />
                  <div>
                    <MUIButton
                      variant="contained"
                      color="primary"
                      sx={theme => ({
                        whiteSpace: "nowrap",
                        marginLeft: theme.spacing(1)
                      })}
                      onClick={() => {
                        this.setState({
                          backupSource: NONE,
                          backupSourceName: undefined,
                          showBackupSourceSelector: true
                        });
                      }}>
                      Select Template
                    </MUIButton>
                  </div>
                </Box>
              }
            />
            <Section
              step={2}
              header="Upload & Scan Backups"
              subheader="Upload your backup files to scan and extract them into Excel/CSV files."
              content={
                <Box mt={1}>
                  <FilePicker
                    fileTypes={["PDF", "CSV", "EXCEL"]}
                    acceptedFiles={[
                      ".pdf",
                      ".PDF",
                      "application/pdf",
                      ".xlsx",
                      ".xls",
                      ".csv",
                      ".CSV",
                      ".xlsx",
                      ".xls",
                      ".csv",
                      ".CSV",
                      "application/vnd.ms-excel",
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                      "text/csv"
                    ]}
                    onChange={this.props.addedfile}
                    filesLimit={250}
                    dzKey={this.state.numScans}
                  />
                  <br />
                  {this.state.deductionsServicing && (
                    <div className="rowC" style={{ padding: "1%" }}>
                      <Select
                        floatingLabelText="Serviced Company"
                        value={this.state.servicedCompany}
                        onChange={event => {
                          const { value } = event.target;
                          this.setState({ servicedCompany: value });
                        }}
                        fullWidth
                        errorText={
                          this.state.errorText["Deductions Servicing"]
                        }>
                        {Object.keys(this.state.servicedCompanies).map(
                          company => {
                            return (
                              <MenuItem
                                children={company}
                                value={this.state.servicedCompanies[company]}
                                key={company}
                              />
                            );
                          }
                        )}
                      </Select>
                    </div>
                  )}
                  <div className="rowC">
                    <div style={styles.fieldContainer}>
                      <Select
                        floatingLabelText="Promotion Month"
                        fullWidth
                        value={this.state.month}
                        onChange={event => {
                          const { value } = event.target;
                          this.setState({ month: value });
                        }}>
                        {monthsJSX}
                      </Select>
                    </div>
                    <div style={styles.fieldContainer}>
                      <Select
                        floatingLabelText="Promotion Year"
                        fullWidth
                        value={this.state.year}
                        onChange={event => {
                          const { value } = event.target;
                          this.setState({ year: value });
                        }}>
                        {yearsJSX}
                      </Select>
                    </div>
                  </div>
                  <div className="rowC">
                    <div style={styles.fieldContainer}>
                      <DatePicker
                        inputVariant="outlined"
                        floatingLabelText="Spend Date"
                        onChange={date => {
                          this.setState({ spendDate: date });
                        }}
                        value={this.state.spendDate}
                        fullWidth
                      />
                    </div>
                    <div style={styles.fieldContainer}>
                      <TextField
                        variant="outlined"
                        floatingLabelText="Check Number"
                        value={this.state.checkNumber}
                        onChange={event => {
                          const { value } = event.target;
                          this.setState({ checkNumber: value });
                        }}
                        fullWidth
                        style={{ margin: "0px" }}
                      />
                    </div>
                  </div>
                  <div className="rowC">
                    {this.props.db.meta.cresicor_tpm && (
                      <div style={{ ...styles.fieldContainer, width: "100%" }}>
                        <Select
                          floatingLabelText="Promotion Type"
                          value={this.state.promType}
                          onChange={event => {
                            const { value } = event.target;
                            this.setState({ promType: value });
                          }}
                          fullWidth
                          multiple>
                          {Object.entries(fundTypes)
                            .map(([key, fundType]) => ({
                              key,
                              name: fundType.name
                            }))
                            .sort((fundType1, fundType2) =>
                              fundType1.name.localeCompare(fundType2.name)
                            )
                            .map((fundType, _fundTypeIdx) => {
                              return (
                                <MenuItem
                                  value={fundType.key}
                                  checked={this.state.promType.includes(
                                    fundType.key
                                  )}
                                  key={fundType.key}>
                                  {fundType.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </div>
                    )}
                    <Box
                      justifyContent="right"
                      display="flex"
                      sx={{ width: "100%" }}>
                      <MUIButton
                        variant="contained"
                        color="primary"
                        disabled={
                          !this.props.files.length ||
                          this.state.backupSource === NONE
                        }
                        onClick={this.scanFile}
                        startIcon={<ScannerIcon />}>
                        Scan & Extract
                      </MUIButton>
                    </Box>
                  </div>
                </Box>
              }
            />
            <Section
              step={3}
              header="Match Deductions"
              subheader="Upload your CSV deduction file to find promotion matches against Vividly promotions."
              content={
                <Box>
                  <Grid container>
                    <Grid md={12} justifyContent="right" display="flex">
                      {this.props.standalone ||
                      !this.props.db.meta.deductions_matching ? (
                        <Typography>Not available here.</Typography>
                      ) : (
                        <MUIButton
                          variant="contained"
                          color="primary"
                          startIcon={<PublishIcon />}
                          // eslint-disable-next-line react/jsx-no-bind
                          onClick={this.openFilePicker.bind(
                            null,
                            <MatchDeductions
                              selectedDataType="matchDeductions"
                              db={this.props.db}
                              showRightDrawer={this.props.showRightDrawer}
                              openClose={this.props.openClose}
                            />
                          )}>
                          Upload Deductions
                        </MUIButton>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              }
            />
          </Grid>
        </Stack>
      </div>
    );
  }
}

export default connect()(withSnackbar(DeductionsToolPanel));
