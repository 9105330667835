// File storage paths for different files stored for DRM

export const RECONCILIATION_FOLDER = "Deductions Reconciliation/";

export const BACKUP_FILES_FOLDER = `${RECONCILIATION_FOLDER}Backup Files/`;
export const SCAN_RESULTS_FOLDER = `${RECONCILIATION_FOLDER}Scan Results/`;
export const UPLOADED_INVOICE_LINES_FOLDER = `${RECONCILIATION_FOLDER}Uploaded Invoice Lines/`;
export const UPLOADED_ERP_TRANSACTIONS_FOLDER = `${RECONCILIATION_FOLDER}Uploaded ERP Transactions/`;

export function getBackupFilePath(
  company_id: string,
  fileBatchKey: string,
  fileName: string
): string {
  return `${company_id}/${BACKUP_FILES_FOLDER}/${fileBatchKey}/${fileName}`;
}

export function getScanResultFilePath(
  company_id: string,
  invoiceKey: string,
  fileName: string
): string {
  return `${company_id}/${SCAN_RESULTS_FOLDER}/${invoiceKey}/${fileName}`;
}

export function getUploadedInvoiceLinesFilePath(
  company_id: string,
  invoiceKey: string,
  fileName: string
): string {
  return `${company_id}/${UPLOADED_INVOICE_LINES_FOLDER}/${invoiceKey}/${fileName}`;
}

export function getUploadedERPTransactionsFilePath(
  company_id: string,
  fileBatchKey: string,
  fileName: string
): string {
  return `${company_id}/${UPLOADED_ERP_TRANSACTIONS_FOLDER}/${fileBatchKey}/${fileName}`;
}
