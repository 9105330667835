/* eslint-disable max-classes-per-file */
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import Checkbox from "ui-library/Checkbox";
import Chip from "@mui/material/Chip";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import Button from "ui-library/Button";
import IconButton from "ui-library/IconButton";
import Subheader from "ui-library/Subheader";
import MenuItem from "ui-library/MenuItem";
import Select from "ui-library/Select";
import TextField from "ui-library/TextField";

import {
  addFirebase,
  updateMetaFirebase,
  firebase,
  generateLongRandomKey
} from "helpers/Firebase";
import { formatDependencies } from "helpers/Errors";
import { liftEnabled } from "helpers/Permissions";
import { grey, red } from "@mui/material/colors";
import { Stack } from "@mui/material";
import DataTableSmart from "../tables/DataTableSmart";

const grey300 = grey["300"];
const grey700 = grey["700"];
const red500 = red["500"];
const red700 = red["700"];

const styles = {
  notifs: {
    width: "60%"
  },
  productList: {
    borderColor: grey700,
    borderWidth: 1,
    borderStyle: "solid"
    // overflow: 'auto',
    // height: 300
  },
  moneyIcon: {
    width: 15,
    height: 15
  },
  radioButton: {
    marginBottom: 16
  },
  chip: {
    margin: 4
  },
  checkbox: {
    marginLeft: 4
  },
  wrapper: {
    display: "flex",
    flexWrap: "wrap"
  }
};

class EditPromoType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newTypeName: "",
      newTypeError: false
    };
  }

  runMountTasks = () => {
    this.setState({
      newTypeName: "",
      newTypeError: false
    });
  };

  componentDidMount() {
    this.runMountTasks();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.runMountTasks();
  }

  render() {
    return (
      <div>
        <div>
          The current name for this type is:&nbsp;
          <b>{this.props.db.meta.fundTypes?.[this.props.type]?.name}</b>. Enter
          a new name below.
        </div>
        <br />
        <div className="centering">
          <TextField
            floatingLabelText="New Type Name"
            value={this.state.newTypeName}
            onChange={event => {
              const newTypeName = event.target.value.replace(
                /[^a-zA-Z0-9\s-]/g,
                ""
              );
              this.setState({ newTypeName });
            }}
            errorText={
              this.state.newTypeError ? "Type name cannot be blank!" : null
            }
          />
        </div>

        <Stack justifyContent="flex-end" sx={{ mt: 2 }}>
          <Button
            label="Save"
            variant="text"
            onClick={() => {
              if (this.state.newTypeName) {
                this.setState({ newTypeError: false });
                this.props.close();
                const metaRef = firebase
                  .database()
                  .ref(`companies/${this.props.db.companyid}/meta`);
                metaRef.once("value", snapshot => {
                  const update = {
                    fundTypes: this.props.db.meta.fundTypes ?? {}
                  };
                  update.fundTypes[this.props.type] =
                    update.fundTypes?.[this.props.type] ?? {};
                  update.fundTypes[this.props.type].name =
                    this.state.newTypeName;
                  metaRef.update(update);
                });
              } else {
                this.setState({ newTypeError: true });
              }
            }}
          />
          <Button
            label="Cancel"
            color="error"
            variant="text"
            onClick={() => {
              this.props.close();
            }}
          />
        </Stack>
      </div>
    );
  }
}

class AccountSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newAccount: "",
      newPromType: "",
      accounts: []
    };
  }

  updateFundType = (type, field, event) => {
    const fundTypeUpdate = {
      [type]: this.props.db.meta.fundTypes?.[type] ?? {}
    };
    if (event?.target?.value) {
      fundTypeUpdate[type][field] = event.target.value;
    } else {
      delete fundTypeUpdate[type][field];
    }
    updateMetaFirebase(fundTypeUpdate, "fundTypes");
  };

  updateFundTypeCheckbox = (type, field, forceChecked, event) => {
    const fundTypeUpdate = {
      [type]: this.props.db.meta.fundTypes?.[type] ?? {}
    };
    fundTypeUpdate[type][field] = forceChecked ? true : event.target.checked;
    updateMetaFirebase(fundTypeUpdate, "fundTypes");
  };

  getAccountSelections = () => {
    const accountSelections = [];
    const accountSelectionsDeps = [];
    const { fundTypes = {} } = this.props.db.meta;
    const { accounts = {} } = this.props.db;
    Object.entries(fundTypes).forEach(([key, fundType]) => {
      const value = String(accounts[fundType.accountKey]?.name) ?? null;
      accountSelectionsDeps.push(value);
      accountSelections.push(
        <Select
          key={key}
          value={fundType.accountKey}
          onChange={this.updateFundType.bind(null, key, "accountKey")}
          disabled={!this.props.editPermission}>
          {Object.entries(accounts)
            .sort(([key1, value1], [key2, value2]) =>
              value1.name < value2.name ? -1 : 1
            )
            .map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {String(value.name)}
              </MenuItem>
            ))}
        </Select>
      );
    });
    return [accountSelections, accountSelectionsDeps];
  };

  getWriteOffAccountSelections = () => {
    const { fundTypes = {} } = this.props.db.meta;
    const writeOffAccountSelections = Object.keys(fundTypes).map(typeKey => {
      const accountValue = fundTypes[typeKey]?.writeoffAccountKey ?? null;
      const accounts = this.props.db.accounts ?? {};
      return (
        <Select
          key={typeKey}
          value={accountValue}
          onChange={this.updateFundType.bind(
            null,
            typeKey,
            "writeoffAccountKey"
          )}
          disabled={!this.props.editPermission}>
          <MenuItem key={null} value={null}>
            <i>None</i>
          </MenuItem>
          {Object.entries(accounts)
            .sort(([key1, value1], [key2, value2]) =>
              value1.name < value2.name ? -1 : 1
            )
            .map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {String(value.name)}
              </MenuItem>
            ))}
        </Select>
      );
    });

    const writeOffAccountSelectionDeps = Object.keys(fundTypes).map(typeKey =>
      String(fundTypes[typeKey]?.writeoffAccountKey)
    );

    return [writeOffAccountSelections, writeOffAccountSelectionDeps];
  };

  getAccountComments = () => {
    const accountComments = [];
    const accountCommentsDeps = [];
    const { fundTypes = {} } = this.props.db.meta;
    Object.entries(fundTypes).forEach(([key, fundType]) => {
      const reminder = fundType.promCreationReminder ?? "";
      accountCommentsDeps.push(reminder);
      accountComments.push(
        <TextField
          onChange={this.updateFundType.bind(null, key, "promCreationReminder")}
          value={reminder}
          multiline
          disabled={!this.props.editPermission}
        />
      );
    });
    return [accountComments, accountCommentsDeps];
  };

  handleAddPromType = () => {
    if (this.state.newPromType) {
      const { fundTypes = {} } = this.props.db.meta;

      const newKey = generateLongRandomKey(Object.keys(fundTypes));
      const accountKeys = Object.keys(this.props.db.accounts ?? {});

      const newFundTypes = {
        [newKey]: {
          name: this.state.newPromType,
          promCreationFields: Object.keys(
            this.props.db.meta.possible_type_fields
          ).sort(),
          promCreationShowPricing: true
        }
      };

      if (accountKeys.length) {
        newFundTypes[newKey].accountKey = accountKeys[0];
      }

      updateMetaFirebase(newFundTypes, "fundTypes");
      this.setState({ newPromType: "" });
    }
  };

  handleAddAccount = () => {
    if (this.state.newAccount) {
      addFirebase(8, { name: this.state.newAccount });
      this.setState({
        newAccount: ""
      });
    }
  };

  handleRequestDelete = (fundTypeKey, index) => {
    const meta = { ...this.props.db.meta };
    if (meta.fundTypes[fundTypeKey].promCreationFields.length === 1) return;
    meta.fundTypes[fundTypeKey].promCreationFields.splice(index, 1);
    updateMetaFirebase(meta);
  };

  getFieldAssignment = () => {
    const fieldAssignments = [];
    const fieldAssignmentsDeps = [];
    const { fundTypes = {} } = this.props.db.meta;
    Object.entries(fundTypes).forEach(([key, fundType]) => {
      const fieldList = fundType.promCreationFields ?? [];
      const fieldAssignmentDep = [];
      fieldAssignments.push(
        <div style={styles.wrapper}>
          {fieldList.map((field, index) => {
            const typeField = this.props.db.meta.possible_type_fields[field];
            fieldAssignmentDep.push(typeField);
            return this.props.editPermission ? (
              <Chip
                onDelete={this.handleRequestDelete.bind(null, key, index)}
                style={styles.chip}
                label={typeField}
              />
            ) : (
              <Chip style={styles.chip} label={typeField} />
            );
          })}
        </div>
      );
      fieldAssignmentsDeps.push(fieldAssignmentDep);
    });

    return [fieldAssignments, fieldAssignmentsDeps];
  };

  handleAddField = (fundTypeKey, fieldList, event, index, fieldKey) => {
    if (fieldList.includes(fieldKey)) {
      return;
    }
    if (fieldKey in this.props.db.meta.possible_type_fields) {
      const meta = { ...this.props.db.meta };
      meta.fundTypes[fundTypeKey].promCreationFields.push(fieldKey);
      updateMetaFirebase(meta);
    }
  };

  getDependencies = account => {
    const dependencies = new Set();

    const typeAccounts = Object.values(this.props.db.meta.fundTypes ?? {}).map(
      val => val?.accountKey
    );
    if (typeAccounts.includes(account)) {
      dependencies.add("promotion types");
    }
    for (const prom of Object.values(this.props.db.promotions)) {
      for (const line of Object.values(prom.lines)) {
        if (account === line.account) {
          dependencies.add("promotions");
          break;
        }
      }
    }

    let reconciliationDependency = false;
    const { drmEvents, invoices } = this.props.db;
    for (const event of Object.values(drmEvents)) {
      if (event.metadata?.clearedGl === account) {
        reconciliationDependency = true;
        break;
      }
    }
    for (const invoice of Object.values(invoices)) {
      for (const resolutionLine of Object.values(
        invoice.resolutionLines ?? {}
      )) {
        if (resolutionLine.fundTypeAccount === account) {
          reconciliationDependency = true;
          break;
        }
      }
      if (reconciliationDependency) break;
    }
    if (reconciliationDependency) dependencies.add("reconciliation");

    return dependencies;
  };

  hasTypeDependencies = type => {
    for (const prom of Object.values(this.props.db.promotions)) {
      for (const line of Object.values(prom.lines)) {
        if (type === line.type) {
          return true;
        }
      }
    }
    return false;
  };

  deleteAccount = account => {
    this.props.openClose.setAppModal(
      "Delete Account",
      <div className="centering">
        Are you sure you want to delete this account?
      </div>,

      <Stack>
        <Button
          label="Yes, I'm sure"
          variant="text"
          color="error"
          onClick={() => {
            const dependencies = this.getDependencies(account);
            if (dependencies.size) {
              const errorStr = formatDependencies("account", dependencies);
              this.props.openClose.closeAppModal();
              this.props.openClose.setAppModal(
                "Unable to delete account",
                <div className="centering">{errorStr}</div>,
                <div className="centering">
                  <Button
                    label="Okay"
                    onClick={() => {
                      this.props.openClose.closeAppModal();
                    }}
                  />
                </div>
              );
            } else {
              this.props.openClose.closeAppModal();
              // make sure no bug removes all the customers
              const accountsRef = firebase
                .database()
                .ref(`companies/${this.props.db.companyid}/accounts`);
              accountsRef.once("value", snapshot => {
                const accounts = snapshot.val() || {};
                for (const accountKey in accounts) {
                  // TODO: This is just a hack, but I'm worried about changing more code.
                  if (accountKey == account) {
                    const update = {};
                    update[accountKey] = null;
                    accountsRef.update(update);
                  }
                }
              });
            }
          }}
        />
        <Button
          label="No, go back"
          variant="text"
          onClick={() => {
            this.props.openClose.closeAppModal();
          }}
        />
      </Stack>
    );
  };

  editType = type => {
    this.props.openClose.setAppModal(
      "Edit Promotion Type",
      <EditPromoType
        db={this.props.db}
        close={() => this.props.openClose.closeAppModal()}
        type={type}
      />
    );
  };

  deleteType = type => {
    this.props.openClose.setAppModal(
      "Delete Promotion Type",
      <div className="centering">
        Are you sure you want to delete this promotion type?
      </div>,

      <Stack>
        <Button
          label="Yes, I'm sure"
          color="error"
          variant="text"
          onClick={() => {
            if (this.hasTypeDependencies(type)) {
              this.props.openClose.closeAppModal();
              this.props.openClose.setAppModal(
                "Unable to delete promotion type",
                <div>
                  {
                    "This promotion type is used in one or more promotions or lines. \
                         Please reconcile these dependencies before deleting this type."
                  }
                </div>,
                <div>
                  <Button
                    label="Okay"
                    onClick={() => {
                      this.props.openClose.closeAppModal();
                    }}
                  />
                </div>
              );
            } else {
              this.props.openClose.closeAppModal();
              const metaRef = firebase
                .database()
                .ref(`companies/${this.props.db.companyid}/meta`);
              metaRef.once("value", snapshot => {
                const update = { deleted_types: {} };
                update.deleted_types[type] = true;
                metaRef.update(update);
              });
            }
          }}
        />
        <Button
          label="No, go back"
          variant="text"
          onClick={() => {
            this.props.openClose.closeAppModal();
          }}
        />
      </Stack>
    );
  };

  getAddFieldJSX = () => {
    const addFieldJSX = [];
    const type_field_keys = Object.keys(
      this.props.db.meta.possible_type_fields
    );
    const { possible_type_fields, fundTypes = {} } = this.props.db.meta;
    Object.entries(fundTypes).forEach(([key, fundType]) => {
      const fieldList = fundType.promCreationFields ?? [];
      addFieldJSX.push(
        <Select
          onChange={this.handleAddField.bind(null, key, fieldList)}
          style={styles.input}
          disabled={!this.props.editPermission}
          renderValue={selected => "Select Fields"}
          displayEmpty>
          {type_field_keys.map(fieldKey => {
            return (
              <MenuItem value={fieldKey} key={fieldKey}>
                {possible_type_fields[fieldKey]}
              </MenuItem>
            );
          })}
        </Select>
      );
    });

    return addFieldJSX;
  };

  getShowPricingByType = () => {
    const showPricingByType = [];
    const showPricingByTypeDeps = {};
    const { fundTypes = {} } = this.props.db.meta;
    Object.entries(fundTypes).forEach(([key, fundType]) => {
      const showPricing = fundType?.promCreationShowPricing;
      // TODO(daniel): Clean this up, was probably written due to weird undefined behavior previously
      const neitherFalseNorTrue = showPricing !== false && showPricing !== true;
      const checked = neitherFalseNorTrue ? true : showPricing;
      if (neitherFalseNorTrue) {
        this.updateFundTypeCheckbox.bind(
          null,
          key,
          "promCreationShowPricing",
          true
        );
      }
      showPricingByTypeDeps[key] = checked;
      showPricingByType.push(
        <Checkbox
          checked={checked}
          onChange={this.updateFundTypeCheckbox.bind(
            null,
            key,
            "promCreationShowPricing",
            false
          )}
          style={styles.checkbox}
          disabled={!this.props.editPermission}
        />
      );
    });
    return [showPricingByType, showPricingByTypeDeps];
  };

  getLiftJSX = () => {
    const liftJSX = [];
    const liftJSXDeps = [];
    const { fundTypes = {} } = this.props.db.meta;
    Object.entries(fundTypes).forEach(([key, fundType]) => {
      const isChecked = fundType.liftAssigned ?? false;
      liftJSXDeps.push(isChecked);
      liftJSX.push(
        <Checkbox
          checked={isChecked}
          onChange={this.updateFundTypeCheckbox.bind(
            null,
            key,
            "liftAssigned",
            false
          )}
          style={{ padding: 16 }}
          disabled={!this.props.editPermission}
        />
      );
    });
    return [liftJSX, liftJSXDeps];
  };

  getDeleteJSX = () => {
    const deleteJSX = [];
    const { fundTypes = {} } = this.props.db.meta;
    for (const typeKey in fundTypes) {
      deleteJSX.push(
        <IconButton
          tooltip="Delete Type"
          style={{ marginTop: 5 }}
          onClick={this.deleteType.bind(null, typeKey)}
          size="large">
          <DeleteIcon color={red500} hoverColor={red700} />
        </IconButton>
      );
    }
    return deleteJSX;
  };

  getEditJSX = () => {
    const editJSX = [];
    const editJSXDeps = [];
    const { fundTypes = {} } = this.props.db.meta;
    for (const typeKey in fundTypes) {
      editJSXDeps.push(true);
      editJSX.push(
        <IconButton
          tooltip="Edit Type"
          style={{ marginTop: 5 }}
          color="primary"
          onClick={this.editType.bind(null, typeKey)}
          disabled={!this.props.editPermission}
          size="large">
          <EditIcon />
        </IconButton>
      );
    }
    return [editJSX, editJSXDeps];
  };

  componentDidMount() {
    // TODO: this.state.accounts is no longer used anywhere, should remove.
    if (this.props.db.accounts) {
      this.setState({
        accounts: Object.values(this.props.db.accounts)
          .map(val => val.name)
          .sort()
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // TODO: this.state.accounts is no longer used anywhere, should remove.
    this.setState({
      accounts: Object.values(nextProps.db.accounts)
        .map(val => val.name)
        .sort()
    });
  }

  render() {
    const { fundTypes = {} } = this.props.db.meta;
    const { accounts = {} } = this.props.db;

    // TODO: This whole DataTableSmart business needs refactoring.
    const [accountSelections, accountSelectionsDeps] =
      this.getAccountSelections();
    const [accountComments, accountCommentsDeps] = this.getAccountComments();
    const [fieldAssignments, fieldAssignmentsDeps] = this.getFieldAssignment();
    const addFieldJSX = this.getAddFieldJSX();
    const [editJSX, editJSXDeps] = this.getEditJSX();

    const promotionTypeMetadataJSX = {
      Type: Object.values(this.props.db.meta.fundTypes ?? {}).map(
        type => type.name
      ),
      Edit: editJSX,
      "Associated Account": accountSelections,
      "Type Reminders": accountComments
    };

    const [showPricingByType, showPricingByTypeDeps] =
      this.getShowPricingByType();
    let writeOffAccountSelectionsDeps = [];
    if (this.props.db.meta.deductions_reconciliation) {
      [
        promotionTypeMetadataJSX["Write-off Account"],
        writeOffAccountSelectionsDeps
      ] = this.getWriteOffAccountSelections();
    }
    let liftJSXDeps = [];
    if (liftEnabled(this.props.db)) {
      [promotionTypeMetadataJSX["Includes Lift"], liftJSXDeps] =
        this.getLiftJSX();
    }

    const promotionTypeMetadataJSXDeps = [
      accountSelectionsDeps,
      writeOffAccountSelectionsDeps,
      editJSXDeps,
      accountCommentsDeps,
      promotionTypeMetadataJSX.Type,
      liftJSXDeps
    ];
    return (
      <div>
        <div>
          <Subheader style={{ color: "primary.main" }}>All Accounts</Subheader>
          <div style={styles.wrapper}>
            {Object.keys(accounts).length ? (
              Object.entries(accounts).map(([key, value]) => {
                return this.props.editPermission ? (
                  <Chip
                    style={styles.chip}
                    label={value.name}
                    onDelete={this.deleteAccount.bind(null, key)}
                  />
                ) : (
                  <Chip style={styles.chip} label={value.name} />
                );
              })
            ) : (
              <Chip
                style={Object.assign(jQuery.extend(true, {}, styles.chip), {
                  backgroundColor: grey300
                })}
                label="None"
              />
            )}
          </div>
          <br />
          {this.props.editPermission && (
            <div className="rowC">
              <TextField
                floatingLabelText="New Account"
                onChange={event => {
                  this.setState({ newAccount: event.target.value });
                }}
                onKeyPress={ev => {
                  if (ev.key === "Enter") {
                    this.handleAddAccount();
                    ev.preventDefault();
                    this.setState({ newAccount: "" });
                  }
                }}
                value={this.state.newAccount}
                style={{ marginTop: -5 }}
              />
              <IconButton onClick={this.handleAddAccount} size="large">
                <AddIcon />
              </IconButton>
            </div>
          )}
        </div>
        <div className="rowC">
          <Subheader style={{ color: "primary.main" }}>
            Promotion Type Metadata
          </Subheader>
        </div>
        <div style={{ marginLeft: 5 }}>
          Only alphanumeric characters, spaces, and dashes are allowed in
          promotion types.
        </div>
        <br />
        {this.props.editPermission && (
          <div className="rowC">
            <TextField
              floatingLabelText="New Promotion Type"
              onChange={event => {
                const newPromType = event.target.value.replace(
                  /[^a-zA-Z0-9\s-]/g,
                  ""
                );
                this.setState({ newPromType });
              }}
              onKeyPress={ev => {
                if (ev.key === "Enter") {
                  this.handleAddPromType();
                  ev.preventDefault();
                  this.setState({ newPromType: "" });
                }
              }}
              value={this.state.newPromType}
              style={{ marginTop: -5 }}
            />
            <IconButton onClick={this.handleAddPromType} size="large">
              <AddIcon />
            </IconButton>
          </div>
        )}
        <div style={{ margin: 10 }}>
          <DataTableSmart
            title="Promotion Types"
            data={promotionTypeMetadataJSX}
            deps={promotionTypeMetadataJSXDeps}
          />
        </div>
        <br />
        <div className="rowC">
          <Subheader style={{ color: "primary.main" }}>
            Assign Fields to Promotion Type
          </Subheader>
        </div>
        <div style={{ margin: 10 }}>
          The order of the fields in the "Assigned Fields" section is reflected
          in promotion creation.
        </div>
        <br />
        <div style={{ margin: 10 }}>
          <DataTableSmart
            title="Select Fields for each Promotion Type"
            data={{
              "Promotion Type": Object.values(fundTypes ?? {}).map(
                type => type.name
              ),
              "Show Pricing Section": showPricingByType,
              "Assigned Fields": fieldAssignments,
              "Add New": addFieldJSX
            }}
            widths={["20%", "20%", "40%", "20%"]}
            deps={[
              fundTypes,
              Object.values(showPricingByTypeDeps),
              fieldAssignmentsDeps
            ]}
          />
        </div>
      </div>
    );
  }
}

export default AccountSelection;
