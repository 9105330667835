import React from "react";
import axios from "axios";
import { Divider } from "pui-react-dividers";

import {
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Tabs,
  Tab,
  Stack
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Checkbox from "ui-library/Checkbox";
import DatePicker from "ui-library/DatePicker";

import GetAppIcon from "@mui/icons-material/GetApp";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PublicIcon from "@mui/icons-material/Public";
import SaveIcon from "@mui/icons-material/Save";
import RefreshIcon from "@mui/icons-material/Refresh";
import SettingsIcon from "@mui/icons-material/Settings";

import Button from "ui-library/Button";
import Select from "ui-library/Select";
import TextField from "ui-library/TextField";
import Input from "@mui/material/Input";
import { TabPanel, a11yProps } from "ui-library/TabPanel";

import { exportBroker } from "helpers/Export";
import {
  getFirebase,
  getJSXListsFirebase,
  removeFirebase,
  updateFirebase,
  firebase,
  getCompanyNamesFirebase,
  isUserCresicorEmployee,
  getFirebaseConsoleURL,
  cloudRunFunctionURL
} from "helpers/Firebase";
import ModalContent from "components/WebsiteElements/ModalContent";
import { common, grey, green, red, teal } from "@mui/material/colors";
import DataTable from "../tables/MuiDataTable";
import ManageBrokerFiles from "./ManageBrokerFiles";

const grey700 = grey["700"];
const { black } = common;
const red100 = red["100"];
const green100 = green["100"];
const teal400 = teal["400"];

const styles = {
  header: {
    marginLeft: "-15px",
    marginRight: "-50px",
    fontSize: 20,
    color: black
  },
  tabs: {
    backgroundColor: grey700
  },
  divider: {
    margin: "30px auto",
    width: "50%"
  },
  superheader: {
    fontFamily: "Ubuntu",
    marginTop: 20
  }
};

const defaultCarveoutTable = {
  Customer: [],
  "% Carveout": [],
  "Implied Distributor": [],
  "% Carveout ": []
};
const defaultProposedPaymentsTable = {
  Account: [],
  "Total Net Sales": [],
  "Commission %": [],
  "Extended Commission": []
};

const carveoutTableOrder = Object.keys(defaultCarveoutTable);

const proposedPaymentTableOrder = Object.keys(defaultProposedPaymentsTable);

const staleDays = 7 * 1000 * 3600 * 24;

export default class BrokerProfile extends React.Component {
  constructor(props) {
    super(props);
    this.brokerRef = React.createRef();
    this.state = {
      tab: 0,
      broker: {
        carveoutTable: defaultCarveoutTable,
        proposedPaymentsTable: defaultProposedPaymentsTable
      },
      changes: false,
      recalculated: false,
      showCalculations: false
    };
  }

  fixBrokerTables = () => {
    // fixing broker tables makes things not undefined

    const { broker } = this.state;
    let { carveoutTable } = this.state.broker;
    if (!carveoutTable) carveoutTable = defaultCarveoutTable;
    var { proposedPaymentsTable } = this.state.broker;
    if (!proposedPaymentsTable)
      proposedPaymentsTable = defaultProposedPaymentsTable;

    broker.carveoutTable = carveoutTable;
    broker.proposedPaymentsTable = proposedPaymentsTable;

    // TODO: MAKE THESE GLOBAL VARIABLES
    const carveoutFields = [
      "Customer",
      "% Carveout",
      "Implied Distributor",
      "% Carveout "
    ];
    const proposedFields = [
      "Account",
      "Total Net Sales",
      "Commission %",
      "Extended Commission"
    ];

    for (var i = 0; i < carveoutFields.length; i++) {
      if (!(carveoutFields[i] in this.state.broker.carveoutTable)) {
        carveoutTable[carveoutFields[i]] = [];
      }
    }

    for (var i = 0; i < proposedFields.length; i++) {
      if (!(proposedFields[i] in this.state.broker.proposedPaymentsTable)) {
        proposedPaymentsTable[proposedFields[i]] = [];
      }
    }

    var { proposedPaymentsTable } = this.state.broker;
    const proposedPaymentsEditable = JSON.parse(
      JSON.stringify(proposedPaymentsTable)
    );
    // intentionally shallow copy last column
    proposedPaymentsEditable["Extended Commission"] =
      proposedPaymentsTable["Extended Commission"];
    const ppLen = proposedPaymentsTable["Commission %"].length;
    [...Array(Math.max(ppLen - 1, 0)).keys()]
      .filter(i => Boolean(proposedPaymentsTable.editable?.[i]))
      .forEach(i => {
        const invalid =
          !proposedPaymentsTable["Commission %"][i] &&
          Boolean(
            this.unformatNumber(proposedPaymentsTable["Total Net Sales"][i])
          );
        proposedPaymentsEditable["Commission %"][i] = (
          <Input
            disableUnderline
            value={proposedPaymentsEditable["Commission %"][i] || ""}
            onChange={event => {
              this.handleCommissionChange(i, event.target.value);
            }}
            fullWidth
            style={{
              height: "22px",
              background: invalid ? red100 : green100
            }}
          />
        );
      });

    let allBlank = true;
    if (proposedPaymentsTable) {
      const commPercent = "Commission %";
      for (var i = 0; i < proposedPaymentsTable[commPercent].length - 1; i++) {
        if (!Number.isNaN(parseFloat(proposedPaymentsTable[commPercent][i]))) {
          allBlank = false;
          break;
        }
      }
    }

    this.setState({
      proposedPaymentsEditable,
      broker,
      percentEntered: !allBlank
    });
  };

  getBrokerData = (key, db) => {
    getFirebase(
      14,
      broker => {
        if (!broker) return;
        // one or more tables not present:
        // this isn't a change, but set them to defaults.
        const tableNotPresent =
          !broker.carveoutTable || !broker.proposedPaymentsTable;
        if (tableNotPresent) {
          broker.carveoutTable = defaultCarveoutTable;
          broker.proposedPaymentsTable = defaultProposedPaymentsTable;
        }

        let changes = false;

        // go through each date field
        // if present, don't change, otherwise set to a default
        const dateDefaults = this.getCurrentMonth();
        const dateFields = [
          "carveoutStartDate",
          "carveoutEndDate",
          "proposedPaymentStartDate",
          "proposedPaymentEndDate"
        ];
        for (let i = 0; i < dateFields.length; i++) {
          if (broker[dateFields[i]]) {
            broker[dateFields[i]] = new Date(
              broker[dateFields[i]]
            ).toLocaleDateString();
          } else {
            broker[dateFields[i]] = new Date(
              dateDefaults[i % 2]
            ).toLocaleDateString();
            changes = true;
          }
        }
        this.setState(
          {
            broker,
            changes
          },
          () => {
            this.checkMultiDists();
            this.fixBrokerTables();
          }
        );
      },
      key
    );
  };

  handleTextChange = event => {
    const newBroker = this.state.broker;
    newBroker[event.target.id] = event.target.value;

    this.setState({
      broker: newBroker,
      changes: true
    });
  };

  handleTabChange = (event, value) => {
    this.setState({
      tab: value
    });
  };

  formatNumber = (number, money = false) => {
    return (
      (money ? "$" : "") +
      number.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    );
  };

  unformatNumber = text => {
    return parseFloat(text.replace(/[,\$]/g, ""));
  };

  checkMultiDists = () => {
    const { accounts } = this.state.broker;

    // check for multiple distributors
    let multiDists = false;
    if (accounts) {
      for (let i = 0; i < accounts.length; i++) {
        const dists = this.props.db.customers[accounts[i]]?.distributors;
        if (dists && dists.length > 1) {
          multiDists = true;
        }
      }
    }

    this.setState({ multiDists });
  };

  handleSelectChange = (field, event, index, value) => {
    const newBroker = this.state.broker;
    newBroker[field] = value;

    this.setState(
      {
        broker: newBroker,
        changes: true
      },
      () => {
        this.checkMultiDists();
      }
    );
  };

  handleSaveChanges = () => {
    const broker = { ...this.state.broker };

    let errors = false;
    if (!broker.name) {
      errors = true;
      this.setState({ nameError: "Name is required" });
    }
    if (
      !this.state.percentEntered &&
      (!parseFloat(broker.commission) || parseFloat(broker.commission) < 0)
    ) {
      errors = true;
      this.setState({ commError: "Commission must be a positive percentage" });
    }
    if (broker.fixedPayment && !parseFloat(broker.fixedPayment)) {
      errors = true;
      this.setState({
        fixedPaymentError: "Fixed payment must be a valid, nonzero number"
      });
    }

    for (var k in broker.proposedPaymentsTable) {
      for (var i = 0; i < broker.proposedPaymentsTable[k].length; i++) {
        if (Number.isNaN(broker.proposedPaymentsTable[k][i])) {
          broker.proposedPaymentsTable[k][i] = "";
        }
      }
    }

    for (var k in broker.carveoutTable) {
      for (var i = 0; i < broker.carveoutTable[k].length; i++) {
        if (Number.isNaN(broker.carveoutTable[k][i])) {
          broker.carveoutTable[k][i] = "";
        }
      }
    }

    if (broker.accounts) {
      broker.accounts.sort((a, b) => {
        if (
          a in this.props.db.customers &&
          b in this.props.db.customers &&
          this.props.db.customers[a].name > this.props.db.customers[b].name
        ) {
          return 1;
        }
        return -1;
      });
    }

    const dateFields = [
      "carveoutStartDate",
      "carveoutEndDate",
      "proposedPaymentStartDate",
      "proposedPaymentEndDate"
    ];
    for (var i = 0; i < dateFields.length; i++) {
      broker[dateFields[i]] = broker[dateFields[i]].toString();
    }

    if (!errors) {
      updateFirebase(14, broker, this.props.brokerKey);
      this.setState({
        changes: false,
        nameError: "",
        commError: "",
        fixedPaymentError: ""
      });
      return true;
    }
    this.props.openClose.showSnack(
      "One or more fields have errors; please fix before continuing!"
    );
    return false;
  };

  handleDelete = () => {
    removeFirebase(14, this.props.key);
  };

  getCurrentMonth = () => {
    // TODO specify a setting for this
    const month = new Date().getMonth();
    const year = new Date().getFullYear();
    return [new Date(year, month, 1), new Date(year, month + 1, 0)];
  };

  handleCommissionChange = (ii, value) => {
    const totalNetSales = "Total Net Sales";
    const commPercent = "Commission %";
    const extendComm = "Extended Commission";
    const subtract = "subtract";

    const ppTable = this.state.broker.proposedPaymentsTable;
    const ppEditable = this.state.proposedPaymentsEditable;

    const newBroker = this.state.broker;

    if (ii < 0) {
      newBroker.commission = value;
      this.setState({ broker: newBroker });
    } else {
      ppTable[commPercent][ii] = parseFloat(value);

      const invalid =
        !value && Boolean(this.unformatNumber(ppTable[totalNetSales][ii]));
      ppEditable[commPercent][ii] = (
        <Input
          disableUnderline
          value={value}
          onChange={event => {
            this.handleCommissionChange(ii, event.target.value);
          }}
          fullWidth
          style={{
            height: "22px",
            background: invalid ? red100 : green100
          }}
        />
      );
    }

    let allBlank = true;
    let revisedComm = 0;
    let revisedDenom = 0;
    let totalDenom = 0;
    for (var i = 0; i < ppTable[commPercent].length - 1; i++) {
      if (ppTable.editable && !ppTable.editable[i]) {
        revisedComm += this.unformatNumber(ppTable[extendComm][i]);
        continue;
      }

      if (!Number.isNaN(parseFloat(ppTable[commPercent][i]))) {
        allBlank = false;

        const thisNetSales = this.unformatNumber(ppTable[totalNetSales][i]);
        const thisComm =
          (thisNetSales * parseFloat(ppTable[commPercent][i])) / 100;
        ppTable[extendComm][i] = this.formatNumber(thisComm, true);

        revisedComm += thisComm;
        revisedDenom += this.unformatNumber(ppTable[totalNetSales][i]);
      } else {
        ppTable[extendComm][i] = "";
        totalDenom += this.unformatNumber(ppTable[totalNetSales][i]);
      }
    }

    for (var i = 0; i < ppTable[commPercent].length - 1; i++) {
      if (!ppTable[subtract]) continue;
      const toSubtract = ppTable[subtract][i];
      if (!toSubtract) continue;
      for (const j of toSubtract) {
        ppTable[extendComm][i] = this.formatNumber(
          this.unformatNumber(ppTable[extendComm][i]) -
            this.unformatNumber(ppTable[extendComm][j]),
          true
        );
      }
    }

    const lastIndex = ppTable[commPercent].length - 1;
    if (!allBlank) {
      this.setState({ percentEntered: true });
      ppTable[extendComm][lastIndex] = this.formatNumber(revisedComm, true);
      ppTable[commPercent][lastIndex] = this.formatNumber(
        (revisedComm / revisedDenom) * 100
      );
      ppTable[totalNetSales][lastIndex] = this.formatNumber(revisedDenom, true);
    } else {
      this.setState({ percentEntered: false });
      ppTable[extendComm][lastIndex] = this.formatNumber(
        (totalDenom * newBroker.commission) / 100,
        true
      );
      ppTable[commPercent][lastIndex] = newBroker.commission;
      ppTable[totalNetSales][lastIndex] = this.formatNumber(totalDenom, true);
    }

    ppEditable[extendComm][lastIndex] = ppTable[extendComm][lastIndex];
    ppEditable[commPercent][lastIndex] = ppTable[commPercent][lastIndex];
    ppEditable[totalNetSales][lastIndex] = ppTable[totalNetSales][lastIndex];

    newBroker.proposedPaymentsTable = ppTable;

    this.setState({
      broker: newBroker,
      proposedPaymentsEditable: ppEditable,
      changes: true
    });
  };

  makeTables = data => {
    // data is directly obtained from python function
    const { carveouts } = data;
    const carveoutsByRegion = data.carveouts_by_region;
    const proposedPayments = data.proposed_payments;
    const proposedPaymentsByRegion = data.proposed_payments_by_region;
    const revenueExclusions = data.revenue_exclusions;

    // mapping from account name -> commission % in the previous table
    const prevPercentInfo = {};
    if (this.state.broker.proposedPaymentsTable) {
      const prevTable = this.state.broker.proposedPaymentsTable;
      for (var i = 0; i < prevTable.Account.length; i++) {
        prevPercentInfo[prevTable.Account[i]] = prevTable["Commission %"][i];
      }
    }

    const cAcc = [];
    const cCarv = [];
    const cDist = [];
    const cCarv2 = [];
    const carveoutTable = {
      Customer: cAcc,
      "% Carveout": cCarv,
      "Implied Distributor": cDist,
      "% Carveout ": cCarv2
    };

    const ppAcc = [];
    const ppSales = [];
    const ppComm = [];
    const ppExt = [];
    const ppEdit = [];
    const info = [];
    const subtract = [];
    const proposedPaymentsTable = {
      Account: ppAcc,
      "Total Net Sales": ppSales,
      "Commission %": ppComm,
      "Extended Commission": ppExt,
      editable: ppEdit,
      info,
      subtract
    };

    let revisedTotal = 0;

    const customerDict = this.props.db.customers;

    const customerKeys = [
      ...new Set([...Object.keys(carveouts), ...Object.keys(proposedPayments)])
    ];
    customerKeys.sort((a, b) => {
      if (
        a in this.props.db.customers &&
        b in this.props.db.customers &&
        this.props.db.customers[a].name > this.props.db.customers[b].name
      ) {
        return 1;
      }
      return -1;
    });

    for (const customerKey of customerKeys) {
      const currentIndex = ppAcc.length;
      const total = "TOTAL";
      const sep = " - ";
      const customerName = customerDict[customerKey]?.name;

      var accountName = customerName + sep + total;
      var carveoutName = customerName;

      // TODO(daniel): cannot just do direct/distributor since otherwise
      // we'd ignore customers like Whole Foods
      // find a fix for this that reduces the zeros in broker profile tables
      const notIndirect = true;
      // customerDict[customerKey].isDirect ||
      // customerDict[customerKey].isDistributor;

      if (customerKey in carveouts && carveouts[customerKey]) {
        if (customerDict[customerKey]?.isDistributor) {
          cDist.push(carveoutName);
          cCarv2.push((100 * carveouts[customerKey]).toFixed(2));
        } else {
          cAcc.push(carveoutName);
          cCarv.push((100 * carveouts[customerKey]).toFixed(2));
        }
      }

      if (customerKey in proposedPayments && notIndirect) {
        ppAcc.push(accountName);
        ppSales.push(proposedPayments[customerKey]);
        ppComm.push(
          accountName in prevPercentInfo
            ? parseFloat(prevPercentInfo[accountName])
            : ""
        );
        ppExt.push("");
        ppEdit.push(true);
        info.push({ customer: customerKey, region: 0 });
        subtract.push([]);
      }

      // === REVISED TOTAL ===
      revisedTotal += proposedPayments[customerKey];
      // =====================

      if (customerKey in carveoutsByRegion) {
        for (var g in carveoutsByRegion[customerKey]) {
          if (g == "NaN") continue;
          if (!carveoutsByRegion[customerKey][g]) continue;

          var carveoutName = customerName + sep + g;
          if (customerDict[customerKey]?.isDistributor) {
            cDist.push(carveoutName);
            cCarv2.push((100 * carveoutsByRegion[customerKey][g]).toFixed(2));
          } else {
            cAcc.push(carveoutName);
            cCarv.push((100 * carveoutsByRegion[customerKey][g]).toFixed(2));
          }
        }
      }

      if (customerKey in proposedPaymentsByRegion && notIndirect) {
        for (var g in proposedPaymentsByRegion[customerKey]) {
          if (g == "NaN") continue;
          var accountName = customerName + sep + g;
          ppAcc.push(accountName);
          ppSales.push(proposedPaymentsByRegion[customerKey][g]);
          ppComm.push(
            accountName in prevPercentInfo
              ? parseFloat(prevPercentInfo[accountName])
              : ""
          );
          ppExt.push("");
          ppEdit.push(true);
          info.push({ customer: customerKey, region: g });
          subtract.push([]);
        }
      }
    }

    // weird situation 1: fewer customers than distributors
    const numCusts = cAcc.length;
    const numDists = cDist.length;
    if (numCusts < numDists) {
      for (var i = 0; i < numDists - numCusts; i++) {
        cAcc.push("");
        cCarv.push("");
      }
    }

    // weird situation 2: no SPINS data at all for any customer
    if (cAcc.length == 0) {
      cAcc.push("No Carveouts Stored");
    }

    let fixedPayment = 0;
    // deal with fixed payment, if present
    if (this.state.broker.fixedPayment) {
      fixedPayment = parseFloat(this.state.broker.fixedPayment);
      ppAcc.push("*Broker Fixed Payment");
      ppSales.push("");
      ppComm.push("");
      ppExt.push(this.formatNumber(fixedPayment, true));
      ppEdit.push(false);
      info.push({ customer: 0, region: 0 });
    }

    // format everything as strings
    for (var i = 0; i < ppSales.length; i++) {
      if (ppSales[i] === "") {
        continue;
      }
      ppSales[i] = this.formatNumber(ppSales[i], true);
    }

    // deal with exclusion customers if needed
    if (this.state.broker.revenueExclusionsAfterBoth) {
      for (var i = 0; i < ppAcc.length; i++) {
        for (let j = 0; j < ppAcc.length; j++) {
          const base = info[i].customer;
          const check = info[j].customer;
          const allowed =
            (this.props.db.customers[base] || {}).revenue_exclusion_customers ||
            [];
          if (allowed.includes(check) && info[i].region === info[j].region) {
            // subtract value at index j from value at index i
            subtract[i].push(j);
          }
        }
      }
    }

    const commission = parseFloat(this.state.broker.commission);

    ppAcc.push("REVISED TOTAL");
    ppSales.push(this.formatNumber(revisedTotal, true));
    ppComm.push(this.formatNumber(commission));
    ppExt.push(
      this.formatNumber(revisedTotal * commission * 0.01 + fixedPayment, true)
    );
    ppEdit.push(false);
    info.push({ customer: 0, region: 0 });
    subtract.push([]);

    return {
      carveoutTable,
      proposedPaymentsTable
    };
  };

  recalculateCarveouts = recalculating => {
    const user = firebase.auth().currentUser;

    if (recalculating && !this.handleSaveChanges()) {
      return;
    }

    this.setState({ loading: true });

    const broker = jQuery.extend({}, true, this.state.broker);

    // change date format into strings
    broker.carveoutStartDate = broker.carveoutStartDate.toString();
    broker.carveoutEndDate = broker.carveoutEndDate.toString();
    broker.proposedPaymentStartDate =
      broker.proposedPaymentStartDate.toString();
    broker.proposedPaymentEndDate = broker.proposedPaymentEndDate.toString();

    delete broker.revSpendByCustomer;

    const formData = {
      broker,
      companyid: this.props.db.companyid,
      userid: user.uid
    };

    axios
      .post(`${cloudRunFunctionURL}/api/broker_commissions_data`, {
        data: formData
      })
      .then(response => {
        const { data } = response.data;
        const tables = this.makeTables(data);
        const { carveoutTable } = tables;
        const { proposedPaymentsTable } = tables;

        // deep copy entire table via JSON
        // TODO(daniel): better way to do this?
        const proposedPaymentsEditable = JSON.parse(
          JSON.stringify(proposedPaymentsTable)
        );
        // intentionally shallow copy last column
        proposedPaymentsEditable["Extended Commission"] =
          proposedPaymentsTable["Extended Commission"];
        const ppLen = proposedPaymentsTable["Commission %"].length;
        [...Array(Math.max(ppLen - 1, 0)).keys()]
          .filter(i => Boolean(proposedPaymentsTable.editable?.[i]))
          .forEach(i => {
            const invalid =
              !proposedPaymentsTable["Commission %"][i] &&
              Boolean(
                this.unformatNumber(proposedPaymentsTable["Total Net Sales"][i])
              );
            proposedPaymentsEditable["Commission %"][i] = (
              <Input
                disableUnderline
                value={proposedPaymentsEditable["Commission %"][i] || ""}
                onChange={event => {
                  this.handleCommissionChange(i, event.target.value);
                }}
                fullWidth
                style={{
                  height: "22px",
                  background: invalid ? red100 : green100
                }}
              />
            );
          });

        const newBroker = this.state.broker;
        newBroker.carveoutTable = carveoutTable;
        newBroker.proposedPaymentsTable = proposedPaymentsTable;
        newBroker.revSpendByCustomer = data.rev_spend_by_customer;
        newBroker.revenueExclusions = data.revenue_exclusions;
        newBroker.failedReports = data.failed_reports;
        newBroker.failedProducts = data.failed_products;
        newBroker.failedCustomers = data.failed_customers;
        newBroker.lastUpdated = new Date();

        const errors =
          newBroker.failedReports.length ||
          Object.keys(newBroker.failedProducts).length ||
          Object.keys(newBroker.failedCustomers).length;

        this.setState(
          {
            broker: newBroker,
            proposedPaymentsEditable,
            recalculated: recalculating,
            changes: true,
            carveoutsDone: false,
            loading: false
          },
          () => {
            this.handleCommissionChange(-1, this.state.broker.commission);
          }
        );

        if (recalculating) {
          this.props.openClose.showSnack(
            errors
              ? "Recalculation completed with errors. Please export to view errors."
              : "Successfully recalculated all broker data!"
          );
        }
      })
      .catch(err => {
        console.error(err);
        this.props.openClose.showSnack(
          "Unknown error occurred. Please contact support if this persists."
        );
        this.setState({
          loading: false
        });
      });
  };

  exportData = () => {
    if (
      !this.state.broker.lastUpdated ||
      new Date().getTime() - new Date(this.state.broker.lastUpdated).getTime() >
        staleDays
    ) {
      this.props.openClose.setAppModal(
        "Export Unavailable",
        <div className="centering">
          An export cannot be performed because the current data in this broker
          profile is out of date. Please perform a recalculation first.
        </div>,

        <div className="centering">
          <Button
            label="Ok"
            onClick={() => {
              this.props.openClose.closeAppModal();
            }}
          />
        </div>
      );
      return;
    }

    let companyName = "";
    for (const cid in this.state.companyNames) {
      if (this.props.db.companyid == cid) {
        companyName = this.state.companyNames[cid];
        break;
      }
    }

    const table = jQuery.extend(
      true,
      {},
      this.state.broker.proposedPaymentsTable
    );
    if (table) {
      delete table.editable;
      delete table.info;
      delete table.subtract;
    }

    const data = {
      carveouts: this.state.broker.carveoutTable,
      proposedPayments: table,
      revSpendByCustomer: this.state.broker.revSpendByCustomer,
      broker: this.state.broker,
      companyName,
      companyid: this.props.db.companyid,
      productGroups: this.props.db.meta.product_groups
    };
    exportBroker(firebase.auth().currentUser, data);
    this.props.openClose.setAppModal(
      "Export In Progress",
      <div className="centering">
        Your export is being prepared and will be emailed to you shortly.
      </div>,

      <div className="centering">
        <Button
          label="Ok"
          onClick={() => {
            this.props.openClose.closeAppModal();
          }}
        />
      </div>
    );
  };

  getRegionGroupList = regionMapping => {
    const regionGroupList = [];
    if (regionMapping && regionMapping.groups) {
      for (const key in regionMapping.groups) {
        const text = regionMapping.groups[key].name;
        regionGroupList.push({ text, value: key });
      }
    }
    regionGroupList.sort((x, y) => x.text < y.text);
    this.setState({ regionGroupList });
  };

  getRegionMappingJSX = () => {
    if (!this.state.regionGroupList) {
      return [];
    }
    return this.state.regionGroupList.map(d => {
      return (
        <MenuItem value={d.value} key={d.value}>
          {d.text}
        </MenuItem>
      );
    });
  };

  getSaveJSX = () => {
    return [
      <div>
        <br />
        <div className="centering">
          You have unsaved changes. Would you like to save?
        </div>
        <br />
        <Stack justifyContent="center">
          <Button
            label="Yes"
            onClick={() => {
              this.handleSaveChanges();
              this.props.openClose.hideRightDrawer();
              this.setState({
                promptSave: false,
                promChanges: false
              });
              this.props.openClose.setDrawerClose(null);
            }}
          />
          <Button
            label="No, continue"
            color="error"
            variant="outlined"
            onClick={() => {
              this.props.openClose.hideRightDrawer();
              this.setState({
                promptSave: false,
                promChanges: false
              });
              this.props.openClose.setDrawerClose(null);
            }}
          />
        </Stack>
      </div>
    ];
  };

  runMountTasks = props => {
    const initialBroker = {
      carveoutStartDate: new Date(),
      carveoutEndDate: new Date(),
      proposedPaymentStartDate: new Date(),
      proposedPaymentEndDate: new Date()
    };
    getJSXListsFirebase(
      props.db,
      allJSX => {
        this.setState({
          customersJSX: allJSX.customersJSX,
          contactsJSX: allJSX.contactsJSX,
          accountsJSX: allJSX.accountsJSX,
          reportsJSX: allJSX.reportsJSX,
          broker: initialBroker
        });
      },
      null,
      true
    );
    this.getBrokerData(props.brokerKey, props.db);
    this.getRegionGroupList(props.db.meta.region_mapping);

    props.openClose.setDrawerClose(() => {
      // don't do anything if loading
      if (this.state.loading) {
        this.props.openClose.showSnack("Recalculation is not yet done!");
        return;
      }
      if (this.state.changes) {
        this.setState({
          promptSave: true
        });
      } else {
        props.openClose.hideRightDrawer();
        this.setState({
          promptSave: false,
          tab: 0
        });
      }
    });

    getCompanyNamesFirebase(companies =>
      this.setState({ companyNames: companies })
    );

    this.setState({
      nameError: "",
      commError: "",
      fixedPaymentError: ""
    });
  };

  componentDidMount() {
    this.runMountTasks(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.runMountTasks(nextProps);
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    const uppercaseWord = this.state.recalculated ? "Recalculate" : "Calculate";
    const lowercaseWord = this.state.recalculated ? "recalculate" : "calculate";
    const ppEditable = this.state.proposedPaymentsEditable;
    if (ppEditable) {
      delete ppEditable.editable;
    }
    const firebaseConsoleURL = `${getFirebaseConsoleURL()}/companies/${
      this.props.db.companyid
    }/brokers/${this.props.brokerKey}`;
    return (
      <div>
        <ListItem
          sx={{ background: theme => theme.palette.readOnly.surface3 }}
          ContainerComponent="div">
          <ListItemText
            primary={
              !this.state.broker || this.state.broker.name === ""
                ? "None"
                : this.state.broker.name
            }
            secondary={
              <span>
                Broker Profile
                {isUserCresicorEmployee() && (
                  <span>
                    {" "}
                    |{" "}
                    <a
                      href={firebaseConsoleURL}
                      target="_blank"
                      style={{ color: "#1b7acf" }}
                      rel="noreferrer">
                      View on Firebase Console
                    </a>
                  </span>
                )}
              </span>
            }
          />
          <ListItemSecondaryAction>
            {this.state.changes &&
            this.props.db.permissions.includes("broker") &&
            !this.props.readOnly ? (
              <Button
                label="Save Changes"
                onClick={this.handleSaveChanges}
                icon={<SaveIcon />}
              />
            ) : (
              <Button label="Save Changes" disabled icon={<SaveIcon />} />
            )}
          </ListItemSecondaryAction>
        </ListItem>
        {this.state.broker ? (
          <>
            <Tabs
              indicatorColor="primary"
              value={this.state.tab}
              onChange={this.handleTabChange}
              sx={{
                backgroundColor: theme => theme.palette.readOnly.surface3,
                "& .MuiTabs-indicator": {
                  height: "4px"
                }
              }}
              textColor="primary"
              variant="fullWidth">
              <Tab
                label="General Info"
                icon={<PublicIcon />}
                {...a11yProps(0)}
              />
              <Tab
                label="Advanced Options"
                icon={<SettingsIcon />}
                {...a11yProps(1)}
              />
              <Tab
                label="Attachments"
                icon={<InsertDriveFileIcon />}
                {...a11yProps(2)}
              />
            </Tabs>
            <TabPanel value={this.state.tab} index={0}>
              <ModalContent
                style={{
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  overflow: "auto"
                }}
                innerRef={this.brokerRef}>
                {!this.state.promptSave ? (
                  <div>
                    <Typography style={styles.superheader} variant="h4">
                      Broker Details
                    </Typography>
                    <Divider />
                    After you've verified that all fields are entered correctly,
                    click the "{uppercaseWord} Table Data" button to{" "}
                    {lowercaseWord} carveout and proposed payment data. Once the
                    system is finished processing, make sure to export this data
                    for documentation purposes!
                    <Stack
                      direction="column"
                      alignItems="inherit"
                      spacing={1.5}>
                      <TextField
                        disabled={!this.props.db.permissions.includes("broker")}
                        value={this.state.broker.name || ""}
                        id="name"
                        onChange={this.handleTextChange}
                        floatingLabelText="Name"
                        fullWidth
                        errorText={this.state.nameError}
                      />
                      <TextField
                        disabled={!this.props.db.permissions.includes("broker")}
                        value={this.state.broker.abbr || ""}
                        id="abbr"
                        onChange={this.handleTextChange}
                        floatingLabelText="Abbreviation"
                        fullWidth
                      />
                      <Select
                        floatingLabelText="Accounts"
                        value={this.state.broker.accounts}
                        multiple
                        onChange={this.handleSelectChange.bind(
                          null,
                          "accounts"
                        )}
                        disabled={!this.props.db.permissions.includes("broker")}
                        fullWidth>
                        {this.state.customersJSX}
                      </Select>
                      {this.state.multiDists && (
                        <font color="orange">
                          Some accounts are serviced by more than one
                          distributor. Please use distributor reports to verify
                          payouts.
                        </font>
                      )}
                      <Select
                        floatingLabelText="Contacts"
                        value={this.state.broker.contacts}
                        multiple
                        onChange={this.handleSelectChange.bind(
                          null,
                          "contacts"
                        )}
                        disabled={!this.props.db.permissions.includes("broker")}
                        fullWidth>
                        {this.state.contactsJSX}
                      </Select>

                      <Select
                        floatingLabelText="Deductions Allowed"
                        value={this.state.broker.fundTypes}
                        multiple
                        onChange={this.handleSelectChange.bind(
                          null,
                          "fundTypes"
                        )}
                        disabled={!this.props.db.permissions.includes("broker")}
                        fullWidth>
                        {this.state.accountsJSX}
                      </Select>

                      <TextField
                        disabled={
                          !this.props.db.permissions.includes("broker") ||
                          this.state.percentEntered
                        }
                        value={this.state.broker.commission || ""}
                        id="commission"
                        onChange={event => {
                          this.handleTextChange(event);
                          this.handleCommissionChange(-1, event.target.value);
                        }}
                        floatingLabelText="Commission %"
                        fullWidth
                        errorText={this.state.commError}
                      />
                      {this.state.percentEntered && (
                        <font color="orange">
                          You have entered at least one commission percentage in
                          the table below. This commission percentage is no
                          longer being used in calculation.
                        </font>
                      )}
                      <Select
                        floatingLabelText="Distributor Reports"
                        value={this.state.broker.reports}
                        multiple
                        onChange={this.handleSelectChange.bind(null, "reports")}
                        disabled={!this.props.db.permissions.includes("broker")}
                        fullWidth>
                        {this.state.reportsJSX}
                      </Select>
                      {this.state.broker.reports &&
                        this.state.broker.reports.length > 0 && (
                          <font color="orange">
                            You have entered at least one distributor report to
                            use. SPINS data will no longer be used in
                            calculation.
                          </font>
                        )}
                    </Stack>
                    Showing <b>proposed payments</b> between these dates (click
                    to edit).
                    <div className="rowC">
                      <div style={{ width: "50%", paddingRight: 16 }}>
                        <DatePicker
                          id="startDate"
                          floatingLabelText="Start Date"
                          onChange={date => {
                            date = date.toLocaleDateString(); // stores date as timezone agnostic
                            const { broker } = this.state;
                            broker.proposedPaymentStartDate = date;
                            this.setState({ broker, changes: true });
                          }}
                          value={this.state.broker.proposedPaymentStartDate}
                          fullWidth
                        />
                      </div>
                      <div style={{ width: "50%" }}>
                        <DatePicker
                          id="endDate"
                          floatingLabelText="End Date"
                          onChange={date => {
                            date = date.toLocaleDateString(); // stores date as timezone agnostic
                            const { broker } = this.state;
                            broker.proposedPaymentEndDate = date;
                            this.setState({ broker, changes: true });
                          }}
                          minDate={this.state.broker.proposedPaymentStartDate}
                          value={this.state.broker.proposedPaymentEndDate}
                          fullWidth
                        />
                      </div>
                    </div>
                    <br />
                    <DataTable
                      title="Proposed Payments"
                      data={ppEditable}
                      order={proposedPaymentTableOrder}
                    />
                    <br />
                    Showing <b>carveouts</b> between these dates (click to
                    edit).
                    <div className="rowC">
                      <div style={{ width: "50%", paddingRight: 16 }}>
                        <DatePicker
                          firstDayOfWeek={0}
                          id="startDate"
                          floatingLabelText="Start Date"
                          onChange={date => {
                            date = date.toLocaleDateString(); // stores date as timezone agnostic
                            const { broker } = this.state;
                            broker.carveoutStartDate = date;
                            this.setState({ broker, changes: true });
                          }}
                          value={this.state.broker.carveoutStartDate}
                          fullWidth
                        />
                      </div>
                      <div style={{ width: "50%" }}>
                        <DatePicker
                          firstDayOfWeek={0}
                          id="endDate"
                          floatingLabelText="End Date"
                          onChange={date => {
                            date = date.toLocaleDateString(); // stores date as timezone agnostic
                            const { broker } = this.state;
                            broker.carveoutEndDate = date;
                            this.setState({ broker, changes: true });
                          }}
                          minDate={this.state.broker.carveoutStartDate}
                          value={this.state.broker.carveoutEndDate}
                          fullWidth
                        />
                      </div>
                    </div>
                    <br />
                    <DataTable
                      title="Broker Carveouts"
                      data={this.state.broker.carveoutTable}
                      order={carveoutTableOrder}
                    />
                    <div className="centering">
                      <Button
                        label={
                          this.state.loading
                            ? "Loading..."
                            : this.state.recalculated
                            ? "Recalculate Table Data"
                            : "Calculate Table Data"
                        }
                        onClick={this.recalculateCarveouts.bind(null, true)}
                        icon={
                          <RefreshIcon
                            className={this.state.loading && "fa-spin"}
                          />
                        }
                        disabled={this.state.loading}
                      />
                    </div>
                    <br />
                    <div className="centering">
                      <Button
                        label="Export Broker Data"
                        onClick={this.exportData}
                        icon={<GetAppIcon />}
                      />
                    </div>
                  </div>
                ) : (
                  this.getSaveJSX()
                )}
                <br />
              </ModalContent>
            </TabPanel>
            <TabPanel value={this.state.tab} index={1}>
              <ModalContent
                style={{
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  overflow: "auto"
                }}
                innerRef={this.brokerRef}>
                {!this.state.promptSave ? (
                  <div>
                    <Typography style={styles.superheader} variant="h4">
                      Broker Advanced Options
                    </Typography>
                    <Divider />
                    <div style={{ color: teal400 }}>
                      These changes are saved even if you click into another
                      tab.
                    </div>
                    <TextField
                      floatingLabelText="Fixed Payment"
                      value={this.state.broker.fixedPayment || ""}
                      id="fixedPayment"
                      onChange={this.handleTextChange}
                      disabled={!this.props.db.permissions.includes("broker")}
                      fullWidth
                      errorText={this.state.fixedPaymentError}
                    />
                    <Select
                      floatingLabelText="Assigned Region Group"
                      value={this.state.broker.regionGroup}
                      multiple={false}
                      onChange={this.handleSelectChange.bind(
                        null,
                        "regionGroup"
                      )}
                      disabled={!this.props.db.permissions.includes("broker")}
                      fullWidth>
                      {this.getRegionMappingJSX()}
                    </Select>
                    <br />
                    <br />
                    <Stack direction="column" alignItems="inherit">
                      <Checkbox
                        color="primary"
                        disabled={!this.props.db.permissions.includes("broker")}
                        label="Apply revenue exclusions after carveout % multiplied"
                        checked={this.state.broker.revenueExclusionsAfter}
                        onChange={(event, isInputChecked) => {
                          const newBroker = { ...this.state.broker };
                          newBroker.revenueExclusionsAfter = isInputChecked;
                          newBroker.revenueExclusionsAfterBoth = false;
                          this.setState({
                            broker: newBroker,
                            changes: true
                          });
                        }}
                      />
                      <Checkbox
                        color="primary"
                        sx={{ ml: 0 }}
                        disabled={!this.props.db.permissions.includes("broker")}
                        label="Apply revenue exclusions after carveout AND commission % multiplied"
                        checked={this.state.broker.revenueExclusionsAfterBoth}
                        onChange={(event, isInputChecked) => {
                          const newBroker = { ...this.state.broker };
                          newBroker.revenueExclusionsAfterBoth = isInputChecked;
                          newBroker.revenueExclusionsAfter = false;
                          this.setState({
                            broker: newBroker,
                            changes: true
                          });
                        }}
                      />
                    </Stack>
                  </div>
                ) : (
                  this.getSaveJSX()
                )}
              </ModalContent>
            </TabPanel>
            <TabPanel value={this.state.tab} index={2}>
              <ModalContent
                style={{
                  paddingLeft: 16,
                  paddingRight: 16,
                  overflow: "auto"
                }}
                innerRef={this.brokerRef}>
                <Typography style={styles.superheader} variant="h4">
                  Manage & Add Attachments
                </Typography>
                <Divider />
                <ManageBrokerFiles
                  broker={this.state.broker}
                  brokerKey={this.props.brokerKey}
                  db={this.props.db}
                  readOnly={this.props.readOnly}
                />
              </ModalContent>
              <br />
            </TabPanel>
          </>
        ) : (
          <div className="centering" style={{ margin: 16 }}>
            Broker no longer exists.
          </div>
        )}
      </div>
    );
  }
}
