import React from "react";
import { Card as MuiCard, Typography } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";

const styles = {
  divider: {
    margin: "0px 0px"
  },
  content: {
    marginTop: "-10px"
  },
  default: {
    height: 40
  },
  selected: {
    // backgroundColor: "neutralVariants.neutral95",
    background: theme => theme.palette.readOnly.surface5,
    height: 40
  }
};

const Card = props => {
  const { title, children, onClick, selected, ...other } = props;
  return (
    <MuiCard variant="outlined" {...other}>
      <CardActionArea onClick={onClick}>
        <CardHeader
          disableTypography
          sx={selected ? styles.selected : styles.default}
          title={<Typography variant="labelLarge">{title}</Typography>}
        />
        <Divider />
      </CardActionArea>
      <CardContent style={styles.content}>{children}</CardContent>
    </MuiCard>
  );
};

export default Card;
