import React from "react";
import { connect } from "react-redux";
import axios from "axios";

import Loading from "react-loading";
import levenshtein from "fast-levenshtein";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import LinearProgress from "@mui/material/LinearProgress";

import { Step, Stepper, StepLabel } from "@mui/material";

import Button from "ui-library/Button";
import {
  firebase,
  uploadFilesToStorage,
  cloudRunFunctionURL
} from "helpers/Firebase";
import MapData from "components/UploadFiles/MapData";
import FilePicker from "components/WebsiteElements/FilePicker";
import Mixpanel from "helpers/Mixpanel";
import { red } from "@mui/material/colors";
import MatchingResult from "./MatchingResult";
import { updateField, setDefault } from "./actions";

const red400 = red["400"];

const styles = {
  subheader: {
    marginLeft: "-15px",
    marginRight: "-50px",
    fontSize: 20
  },
  radioButtonGroup: {
    marginLeft: 100,
    marginTop: 100
  }
};

class MatchDeductions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDataType: "matchDeductions", // equal to either Promotions or Sales
      finished: false,
      loading: false, // status of AJAX request; to be used in loading icon
      uploadProgress: 0,
      file: null, // file we send to backend
      header: [], // the header for the file we send to backend
      deductionsSources: [],
      deductionsSourcesCustomers: {},
      fieldColumns: [
        "Customer Name",
        "Customer Name (accumulation)",
        "Product Name",
        "Month-Year",
        "Promotion Type",
        "Spend Rate",
        "Deductions Amount"
      ],
      // fields for each of the different categories of uploads
      requiredColumns: [
        "Customer Name",
        "Product Name",
        "Month-Year",
        "Promotion Type",
        "Spend Rate",
        "Deductions Amount"
      ],
      // input column names converted to firebase entry names
      missingColumns: [],
      requiredColumnsForUpload: [], // required columns needed for upload to work
      mapData: {},
      displayUploadError: false, // display upload error message
      displayUploadWarning: false, // display upload warning message
      errorMessage: "", // error message to display
      warningMessage: "", // warning message to display
      userMatchedColumns: false // whether or not the user matched the columns
    };
  }

  bottomMessage = () => {
    const matchReminder = "(*) denotes a required column.";
    if (this.props.stepIndex == 1) {
      return (
        <div className="centeringPad">
          {this.state.displayUploadError
            ? this.state.errorMessage
            : matchReminder}
        </div>
      );
    }
    return (
      <div
        className="centeringPad"
        style={{
          color: red400,
          marginTop: -20
        }}>
        {(this.state.displayUploadError && this.state.errorMessage) ||
          (this.state.displayUploadWarning && this.state.warningMessage)}
      </div>
    );
  };

  onLoad = () => {
    // called when child component loads, and we set loading to false
    this.setState({ loading: false });
  };

  getBestMatch = header => {
    const { mapData } = this.state;
    for (const col in mapData) {
      let minDist = Infinity;
      let argMin = -1;
      for (let i = 0; i < header.length; i++) {
        const dist = levenshtein.get(col, header[i]);
        if (dist < minDist && dist < col.length / 2) {
          minDist = dist;
          argMin = i;
        }
      }
      mapData[col] = argMin >= 0 ? header[argMin] : "No Match";
    }
    this.setState({ mapData });
  };

  getFilePickerMessage = () => {
    if (this.state.loading && this.state.uploadProgress < 100) {
      return (
        <div>
          <div>
            {`Upload ${Math.round(this.state.uploadProgress)}% Complete`}
          </div>
          <LinearProgress
            style={{ width: "60%", marginTop: 10, marginLeft: "20%" }}
            variant="determinate"
            value={Math.round(this.state.uploadProgress)}
          />
        </div>
      );
    }
    if (this.state.loading && this.state.uploadProgress == 100) {
      return <div>Processing Uploaded File...</div>;
    }
    return <div>Drop file here to upload (30 MB maximum size)</div>;
  };

  getStepContent = stepIndex => {
    window.location.hash = stepIndex;
    switch (stepIndex) {
      case 0:
        return (
          <div>
            <FilePicker
              message={this.getFilePickerMessage()}
              onChange={files => {
                if (files.length > 0) {
                  Mixpanel.track("Deductions Matching File Upload", {});
                }
                this.setState({ file: files[0] });
              }}
              fileTypes={["CSV", "Excel"]}
              maxFileSize={30 << 20}
              onLoad={this.onLoad}
              selectDataType={this.selectDataType}
              selectedDataType={this.props.selectedDataType}
              promotionUpload
            />
            <div style={styles.radioButtonGroup}>
              <div
                style={{
                  fontSize: 18,
                  fontFamily: "PT Sans",
                  marginBottom: 16
                }}>
                Select Deductions Source (if applicable)
              </div>
              <FormControl component="fieldset">
                <RadioGroup
                  name="selectSource"
                  onChange={(event, value) => {
                    this.props.dispatch(updateField("source", value));
                  }}
                  style={{ marginLeft: 16 }}>
                  {this.state.deductionsSources.map(source => {
                    return (
                      <FormControlLabel
                        value={source}
                        control={<Radio color="primary" />}
                        label={source}
                        key={source}
                      />
                    );
                  })}
                  <FormControlLabel
                    value="other"
                    control={<Radio color="primary" />}
                    label="Other"
                    key="Other"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        );
      case 1:
        return (
          <MapData
            header={this.state.header}
            handleChange={this.handleDropDownChange}
            selectedDataType={this.props.selectedDataType}
            requiredColumns={this.state.requiredColumns}
            missingColumns={this.state.missingColumns}
            mapData={this.state.mapData}
            columns={this.state.fieldColumns}
            onLoad={this.onLoad}
          />
        );
      case 2:
        return (
          <MatchingResult
            onLoad={this.onLoad}
            db={this.props.db}
            showRightDrawer={this.props.showRightDrawer}
            openClose={this.props.openClose}
            confidenceThresholds={
              this.props.db.meta.deductions_matching_thresholds || {}
            }
          />
        );
    }
  };

  handleDropDownChange = (id, event) => {
    const { header } = this.state;
    // handles change in drop down menu for MapData component
    const { mapData } = this.state;
    mapData[id] = event.target.value;
    this.setState({
      id: event.target.value,
      mapData,
      userMatchedColumns: true
    });
  };

  handleFirstStep = () => {
    const unknownError =
      "Unknown error occurred. Please try again or contact support if this error persists.";
    const filePath = `${this.props.db.companyid}/${this.state.selectedDataType} User Uploaded Files/${this.state.file.name}`;
    Mixpanel.track("Deductions Matching Step 1 Submit", {});
    this.setState({ loading: true });
    uploadFilesToStorage(
      [filePath],
      [this.state.file],
      () => {
        const formData = {
          userid: this.state.userid,
          file_name: this.state.file.name,
          file_type: this.props.selectedDataType,
          companyid: this.props.db.companyid,
          preset: "n/a"
        };
        // call upload cloud function step 1
        axios
          .post(`${cloudRunFunctionURL}/api/upload_files_1`, { data: formData })
          .then(response => {
            let { data } = response.data;
            if ("errorMultiSheets" in data) {
              this.setState({
                loading: false,
                displayUploadError: true,
                errorMessage:
                  "You cannot upload files with more than one sheet."
              });
            } else {
              const { header } = data;
              const { matchColumns } = data;
              if (matchColumns) {
                this.setState({ mapData: matchColumns });
              } else {
                this.getBestMatch(header); // choose best fits for the columns
                this.setState({ userMatchedColumns: true });
              }
              data = ["No Match"].concat(header);
              this.props.dispatch(
                updateField("stepIndex", this.props.stepIndex + 1)
              );
              this.setState({
                header: data,
                displayUploadError: false,
                errorMessage: ""
              });
            }
          })
          .catch(err => {
            this.setState({
              loading: false,
              displayUploadError: true,
              errorMessage: unknownError
            });
          });
      },
      snapshot => {
        const uploadProgress = parseInt(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        this.setState({ uploadProgress });
      }
    );
  };

  handleSecondStep = () => {
    const unknownError =
      "Unknown error occurred. Please try again or contact support if this error persists.";
    const requiredColumnsPrelim = this.state.requiredColumns;
    const missingColumns = [];
    let requiredColumnsForUpload = [];
    requiredColumnsForUpload = requiredColumnsPrelim;
    for (let i = 0; i < requiredColumnsPrelim.length; i++) {
      if (this.state.mapData[requiredColumnsPrelim[i]] == "No Match") {
        missingColumns.push(requiredColumnsPrelim[i]);
      }
    }
    this.setState({
      missingColumns,
      requiredColumnsForUpload
    });
    if (missingColumns.length == 0) {
      const formData = {
        userid: this.state.userid,
        companyid: this.props.db.companyid,
        map_data: this.state.mapData,
        user_matched_columns: this.state.userMatchedColumns,
        header: this.state.header.slice(1),
        file_type: this.props.selectedDataType,
        file_name: this.state.file.name,
        source: this.props.source,
        use_distributor_source:
          this.state.enforceSourceMatch[this.props.source] || false
      };

      this.setState({ loading: true });

      // match deductions
      axios
        .post(`${cloudRunFunctionURL}/api/match_deductions`, { data: formData })
        .then(response => {
          const { data } = response.data;
          if (data.code && data.code === 400) {
            this.setState({
              loading: false,
              displayUploadError: true,
              errorMessage: `Mapping error occurred between ${data.message} and Your Field. Please check your mappings or contact support if this error persists.`
            });
            Mixpanel.track("Deductions Matching Columns Attempt", {
              Result: "Mapping Error"
            });
          } else {
            const allLinesDF = Object.values(data["All Lines"]);
            const accumDF = Object.values(data.Accumulation);
            const accumToLines = data["Accumulation to Lines"];
            const fileLineLimit = data.FILE_LINE_LIMIT;
            const fileTooLarge = data.FILE_TOO_LARGE;
            const uploadPath = data.upload_path;
            this.props.dispatch(
              setDefault(
                accumToLines,
                accumDF,
                allLinesDF,
                accumDF,
                allLinesDF,
                fileLineLimit,
                fileTooLarge,
                uploadPath,
                null,
                true
              )
            );
            this.props.dispatch(
              updateField("stepIndex", this.props.stepIndex + 1)
            );
            this.setState({
              errorMessage: ""
            });
            Mixpanel.track("Deductions Matching Columns Attempt", {
              Result: "Success"
            });
            Mixpanel.track("Deductions Matching Result Viewed", {});
          }
        })
        .catch(err => {
          console.log("error", err);
          this.setState({
            loading: false,
            displayUploadError: true,
            errorMessage: unknownError
          });
        });
    }
  };

  handleLastStep = () => {};

  handleNext = () => {
    const { stepIndex } = this.props;
    if (stepIndex == 0) {
      this.handleFirstStep();
    } else if (stepIndex == 1) {
      this.handleSecondStep();
    } else {
      this.handleLastStep();
    }
  };

  handlePrev = () => {
    const { stepIndex } = this.props;
    this.setState({ displayUploadError: false });
    if (stepIndex > 0) {
      this.props.dispatch(updateField("stepIndex", this.props.stepIndex - 1));
      this.setState({
        errorMessage: ""
      });
    }
  };

  componentDidMount() {
    this.setState({
      selectedDataType: this.props.selectedDataType,
      deductionsSources: this.props.db.meta.deductions_sources || [],
      deductionsSourcesCustomers:
        this.props.db.meta.deductions_sources_customers || {},
      enforceSourceMatch: this.props.db.meta.enforce_source_match || {},
      userid: firebase.auth().currentUser?.uid || ""
    });
    // set default columns
    const mapData = {};
    const columns = this.state.fieldColumns;
    for (let i = 0; i < columns.length; i++) {
      mapData[columns[i]] = "No Match";
    }
    this.setState({ mapData });
  }

  render() {
    const contentStyle = {
      margin: "0 16px"
    };
    const nextButtonDisabled =
      (this.props.stepIndex == 0 && this.state.file == null) ||
      this.props.stepIndex == 2;
    return (
      <div
        style={{
          width: "100%",
          margin: "auto",
          paddingLeft: "100px",
          paddingRight: "100px"
        }}>
        <Stepper activeStep={this.props.stepIndex} style={styles.stepper}>
          <Step>
            <StepLabel>Upload File</StepLabel>
          </Step>
          <Step>
            <StepLabel>Map File</StepLabel>
          </Step>
          <Step>
            <StepLabel>View Matching Result</StepLabel>
          </Step>
        </Stepper>
        <div style={contentStyle}>
          <div>
            {this.getStepContent(this.props.stepIndex)}
            <div
              style={{
                marginTop: -10
              }}
              className="centeringPad">
              <Button
                label="Back"
                disabled={this.props.stepIndex === 0}
                onClick={this.handlePrev}
                style={{
                  marginRight: 12
                }}
              />
              {this.props.stepIndex !== 2 && (
                <Button
                  label={this.props.stepIndex === 2 ? "Save" : "Next"}
                  disabled={nextButtonDisabled}
                  onClick={this.handleNext}
                />
              )}
            </div>
            {this.bottomMessage()}
            <div
              className="centering"
              style={{
                marginTop: -30
              }}>
              {this.state.loading && <Loading type="bubbles" color="#000000" />}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const matchingFields = state.deductionsMatching;
  return {
    source: matchingFields.source,
    stepIndex: matchingFields.stepIndex
  };
};

export default connect(mapStateToProps)(MatchDeductions);
