import React from "react";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import "./main.scss";

import moment from "moment";
import Mixpanel from "helpers/Mixpanel";
import PromProfile from "../PromProfile";

const styles = {
  calendarFull: {
    marginTop: 40,
    marginLeft: 100,
    marginRight: 100,
    marginBottom: 50,
    fontFamily: "PT Sans"
  }
};

const resources = [
  { id: "jan", title: "Jan" },
  { id: "feb", title: "Feb" },
  { id: "mar", title: "Mar" },
  { id: "apr", title: "Apr" },
  { id: "may", title: "May" },
  { id: "jun", title: "Jun" },
  { id: "jul", title: "Jul" },
  { id: "aug", title: "Aug" },
  { id: "sep", title: "Sep" },
  { id: "oct", title: "Oct" },
  { id: "nov", title: "Nov" },
  { id: "dec", title: "Dec" }
];

class YearView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      year: new Date().getFullYear()
    };
    this.calendarComponentRef = React.createRef();
  }

  disableCellDayInMonth = () => {
    const disableDayInMonth = (_monthIndex, _numOfDay) => {
      const lastCol = document.querySelector(
        "div.fc-view.fc-resourceTimelineMonth-view.fc-timeline.fc-flat > table > thead > tr > td.fc-time-area.fc-widget-header > div > div > div > div.fc-content > table > tbody > tr > th:nth-child(31)"
      );
      const lastColWidth = lastCol.clientWidth;

      const normalCol = document.querySelector(
        "div.fc-view.fc-resourceTimelineMonth-view.fc-timeline.fc-flat > table > thead > tr > td.fc-time-area.fc-widget-header > div > div > div > div.fc-content > table > tbody > tr > th:nth-child(30)"
      );
      const normalColWidth = normalCol.clientWidth + 1;

      const currentRow = document.querySelector(
        `div.fc-view.fc-resourceTimelineMonth-view.fc-timeline.fc-flat > table > tbody > tr > td.fc-time-area.fc-widget-content > div > div > div > div.fc-content > div > table > tbody > tr:nth-child(${_monthIndex}) > td`
      );
      const rowHeight = currentRow.clientHeight;
      currentRow.setAttribute("style", "position: relative;");
      const child = document.createElement("div");
      child.setAttribute(
        "style",
        `height: ${rowHeight}px; width: ${
          (_numOfDay - 1) * normalColWidth + lastColWidth
        }px;`
      );
      child.classList.add("disable-cells");
      currentRow.append(child);
    };

    const year = new Date().getFullYear();
    if (new Date(year, 1, 29).getDate() == 29) {
      // leap year
      disableDayInMonth(2, 2);
    } else {
      disableDayInMonth(2, 3);
    }
    disableDayInMonth(4, 1);
    disableDayInMonth(6, 1);
    disableDayInMonth(9, 1);
    disableDayInMonth(11, 1);
  };

  componentDidMount() {
    const calendarApi = this.calendarComponentRef.current.getApi();
    const date = `${new Date().getFullYear()}-12-01`;
    calendarApi.gotoDate(date); // call a method on the Calendar object
    // this.disableCellDayInMonth();
  }

  UNSAFE_componentWillReceiveProps() {}

  render() {
    return (
      <div style={styles.calendarFull}>
        <div>
          <FullCalendar
            slotWidth={40}
            resourceAreaWidth="5%"
            defaultView="resourceTimelineMonth"
            aspectRatio={1.5}
            year={this.state.year}
            header={{
              left: "prevYear",
              center: "title",
              right: "nextYear"
            }}
            titleFormat={date => {
              return `Promotion Calendar ${moment(date.date).year()}`;
            }}
            plugins={[resourceTimelinePlugin, interactionPlugin]}
            ref={this.calendarComponentRef}
            resourceLabelText=" "
            resources={resources}
            events={this.props.events}
            eventLimit={5}
            slotLabelFormat={[
              {
                day: "numeric"
              }
            ]}
            eventClick={(calEvent, jsEvent, view) => {
              this.props.openClose.showRightDrawer(
                <PromProfile
                  promKey={calEvent.event.extendedProps.promKey}
                  openClose={this.props.openClose}
                  db={this.props.db}
                />
              );
              Mixpanel.track("Promotion Viewed", {
                View: "Planning",
                Interface: "Calendar",
                Component: "YearView"
              });
            }}
            height="auto"
          />
        </div>
      </div>
    );
  }
}

export default YearView;
