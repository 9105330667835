import { Divider, Grid, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ResolutionActivityTypes } from "components/Deductions/DeductionsReconciliation/types/resolutionLineTypes";
import React from "react";
import { useDb } from "contexts/Db";
import { useDRMEvents } from "contexts/DRMEvents";
import { useOpenClose } from "contexts/OpenClose";
import { useSnackbar } from "notistack";
import AddResolutionLine from "./AddResolutionLine";
import InvoiceLineCard from "./InvoiceLineCard";
import ResolutionLineHistory from "./ResolutionLineHistory";

const useStyles = makeStyles((theme: Theme) => ({
  cardContainer: {
    marginLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  },
  container: {
    marginLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  scrollableContainer: {
    overflow: "auto"
  }
}));

export interface ResolveInvoiceLineProps {
  invoiceLineKey: string;
  invoiceKey: string;
  transactionKey: string;
}

export default function ResolveInvoiceLine(
  props: ResolveInvoiceLineProps
): JSX.Element {
  const { invoiceLineKey, invoiceKey, transactionKey } = props;

  const classes = useStyles();
  const db = useDb();
  const openClose = useOpenClose();
  const {
    allLines = {},
    meta: { fundTypes = {} },
    invoices = {},
    erpTransactions = {},
    customers = {},
    products = {},
    companyUsers = {}
  } = db;

  const invoice = invoices[invoiceKey];
  const invoiceLine = (invoice?.invoiceLines || {})[invoiceLineKey];
  const { matchedPromLine } = invoiceLine;

  const { addEvent } = useDRMEvents();
  const { enqueueSnackbar } = useSnackbar();
  const { hideRightDrawer } = openClose;
  return (
    <>
      <div className={classes.cardContainer}>
        <Grid container>
          <InvoiceLineCard
            invoiceLineKey={invoiceLineKey}
            invoiceKey={invoiceKey}
            interactive={false}
          />
        </Grid>
      </div>
      <Divider variant="middle" />
      <div className={classes.scrollableContainer}>
        <div className={classes.container}>
          <Grid container>
            <AddResolutionLine
              hideRightDrawer={hideRightDrawer}
              enqueueSnackbar={enqueueSnackbar}
              addEvent={addEvent}
              allLines={allLines}
              fundTypes={fundTypes}
              invoices={invoices}
              companyUsers={companyUsers}
              erpTransactions={erpTransactions}
              customers={customers}
              products={products}
              invoiceLineKey={invoiceLineKey}
              invoiceKey={invoiceKey}
              transactionKey={transactionKey}
              initialActivityType={
                matchedPromLine
                  ? ResolutionActivityTypes.CLEAR
                  : ResolutionActivityTypes.WRITE_OFF
              }
            />
          </Grid>
        </div>
        <Divider variant="middle" />
        <div className={classes.container}>
          <Grid container>
            <ResolutionLineHistory
              invoiceLineKey={invoiceLineKey}
              invoiceKey={invoiceKey}
            />
          </Grid>
        </div>
      </div>
    </>
  );
}
