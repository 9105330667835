import React from "react";

import PollIcon from "@mui/icons-material/Poll";
import {
  Bar,
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Text
} from "recharts";

import { displayMoney } from "helpers/DataProcessing";

import { grey } from "@mui/material/colors";
import { graphPalette } from "helpers/ColorPalettes";

const grey500 = grey["500"];
const { blue80, pink80 } = graphPalette;

class CustomizedAxisTick extends React.Component {
  render() {
    const { x, y, payload, width, maxChars, lineHeight, fontSize, fill } =
      this.props;

    let displayText = payload.value.toString();
    if (payload.value.toString().length > 25) {
      displayText = `${payload.value.toString().substring(0, 25)}...`;
    }

    return (
      <g transform={`translate(${x},${y})`}>
        <Text
          width={100}
          textAnchor="middle"
          verticalAnchor="start"
          angle={0}
          fill="#333">
          {displayText}
        </Text>
      </g>
    );
  }
}

class CustomTooltip extends React.Component {
  render() {
    const { active } = this.props;
    if (active) {
      const { payload, label } = this.props;

      return (
        <div className="custom-tooltip">
          <p className="desc">{label}</p>
          {payload.map(pl => {
            return (
              <p className="desc" style={{ color: pl.color }} key={pl.name}>
                {`${pl.name}: ${
                  this.props.notMoney
                    ? pl.value.toLocaleString()
                    : displayMoney(pl.value)
                }`}
              </p>
            );
          })}
        </div>
      );
    }

    return null;
  }
}

export default class BiggestPromsGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const xAxisHeight = 60;
    return (
      <div>
        {this.props.data && this.props.data.length > 0 ? (
          <ResponsiveContainer width="100%" aspect={2}>
            <BarChart
              data={this.props.data}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
              <CartesianGrid stroke="#ccc" />
              <XAxis
                dataKey="name"
                height={xAxisHeight}
                interval={0}
                tick={<CustomizedAxisTick />}
              />
              <YAxis
                yAxisId="left"
                orientation="left"
                tickFormatter={d3.format(".2s")}
              />
              <Tooltip
                cursor={{ fill: "transparent" }}
                content={<CustomTooltip notMoney={this.props.notMoney} />}
              />
              <Bar
                type="monotone"
                dataKey="Actual"
                yAxisId="left"
                fill={blue80}
              />
              <Bar
                type="monotone"
                dataKey="Expected"
                yAxisId="left"
                fill={pink80}
              />
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <div style={{ height: 200, textAlign: "center", paddingTop: 50 }}>
            Graph will render upon execution of promotions. <br />
            <PollIcon style={{ color: grey500, fontSize: 60 }} />
          </div>
        )}
      </div>
    );
  }
}
