import axios from "axios";
import { camelizeKeys, deepClone } from "helpers/DataProcessing";
import XLSX from "xlsx";
import { cloudRunURL } from "../../../../firebase-init";
import { DsmScannedInfo } from "./ScanHistoryTypes";

export const getData = (companyId: string, byBackupSource: boolean) => {
  return axios.get(`${cloudRunURL}/api/get_dsm_scanning_info`, {
    params: {
      company_id: companyId,
      by_backup_source: byBackupSource
    }
  });
};

const isUUID = (id: string) =>
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/.test(
    id
  );
export const getDsmScanningInfo = async (
  companyId: string,
  backupSources: object,
  byBackupSource: boolean = false
) => {
  const { data: response } = await getData(companyId, byBackupSource);
  const res: DsmScannedInfo[] = [];
  let indexKey = 0;
  response.data.forEach(scannedInfoObj => {
    const formattedObj = camelizeKeys(scannedInfoObj);
    if (byBackupSource) {
      formattedObj.backupSource = isUUID(formattedObj.backupSource)
        ? backupSources[formattedObj.backupSource]?.DISPLAY_NAME || "Unknown"
        : formattedObj.backupSource;
    }
    formattedObj.id = indexKey;
    res.push(formattedObj);
    indexKey++;
  });

  return res;
};

export const getPageTrackerInfoDownload = (
  pagesScannedInfo: DsmScannedInfo[],
  backupSourcePagesScannedInfo: DsmScannedInfo[]
) => {
  const workbook = XLSX.utils.book_new();
  if (pagesScannedInfo.length > 0) {
    const formattedPagesScannedInfo = deepClone(pagesScannedInfo).map(obj => {
      delete obj.id;
      return obj;
    });
    workbook.SheetNames.push("Pages Scanned");
    workbook.Sheets["Pages Scanned"] = XLSX.utils.json_to_sheet(
      formattedPagesScannedInfo
    );
  }
  if (backupSourcePagesScannedInfo.length > 0) {
    const formattedBackupSourcePagesScannedInfo: DsmScannedInfo[] = deepClone(
      backupSourcePagesScannedInfo
    ).map((obj: DsmScannedInfo) => {
      delete obj.id;
      return obj;
    });
    workbook.SheetNames.push("Pages Scanned By Backup Source");
    workbook.Sheets["Pages Scanned By Backup Source"] =
      XLSX.utils.json_to_sheet(formattedBackupSourcePagesScannedInfo);
  }
  XLSX.writeFile(workbook, `PageTrackerInfo.xlsx`);
};
