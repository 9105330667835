import React, { useEffect, useState } from "react";
import IconButton from "ui-library/IconButton";
import { useDRMEvents } from "contexts/DRMEvents";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
import { useOpenClose } from "contexts/OpenClose";
import { Badge, Button } from "@mui/material";
import { firebase, updateFirebase } from "helpers/Firebase";
import { useDb } from "contexts/Db";
import UserMentions from "./UserMentions";

export default function UserMentionsButton() {
  const { getTags } = useDRMEvents();
  const openClose = useOpenClose();
  const { drmEvents } = useDb();
  const userId = firebase.auth().currentUser?.uid ?? "";
  const [tagsForUser, setTagsForUser] = useState<
    [string, Record<string, number[]>][]
  >([]);
  const [tags, setTags] = useState<Record<string, Record<string, number[]>>>(
    {}
  );

  const unseenEventKeys = tagsForUser
    .filter(([eventKey]) => drmEvents[eventKey].new)
    .map(([eventKey]) => eventKey);

  useEffect(() => {
    getTags().then(newTags => {
      setTags(newTags);
      const newTagsForUser = Object.entries(newTags).filter(
        ([_, tag]) => userId in tag
      );
      setTagsForUser(newTagsForUser);
    });
  }, [getTags, userId]);

  return (
    <IconButton
      tooltip="User Mentions"
      onClick={() => {
        unseenEventKeys.forEach(eventKey => {
          const ev = drmEvents[eventKey];
          const seenEvent = { ...ev, new: false };
          updateFirebase(
            30,
            seenEvent,
            eventKey,
            () => {},
            _err => {}
          );
        });
        openClose.setAppModal({
          title: "Deductions Reconciliation User Mentions",
          content: (
            <UserMentions
              onLinkClick={() => openClose.closeAppModal()}
              tags={tags}
              tagsForUser={tagsForUser}
              unseenEventKeys={unseenEventKeys}
            />
          ),
          actions: (
            <div>
              <Button onClick={() => openClose.closeAppModal()}>Close</Button>
            </div>
          ),
          maxWidth: "md"
        });
      }}
      size="large">
      <Badge color="secondary" badgeContent={unseenEventKeys.length}>
        <MarkUnreadChatAltIcon />
      </Badge>
    </IconButton>
  );
}
