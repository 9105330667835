import React, { useEffect } from "react";

import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import { saveAs } from "file-saver";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { makeStyles, StylesOptions } from "@mui/styles";
import Button from "@mui/material/Button";
import { Typography, Theme } from "@mui/material";
import { MimeTypes } from "helpers/Files";
import useObjectURL from "helpers/useObjectUrl";

const useStyles = makeStyles((theme: Theme) => ({
  container: { display: "flex", flexDirection: "column", height: "100%" },
  previewWarningContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%"
  },
  center: { textAlign: "center" },
  downloadText: {
    marginBottom: theme.spacing(1)
  }
}));

export interface FilePreviewProps {
  file: File;
  style?: StylesOptions;
}

export default function FilePreview(
  props: FilePreviewProps
): JSX.Element | null {
  const classes = useStyles();
  const { file } = props;
  const { type } = file;

  const { objectURL: fileURL, setObject } = useObjectURL(file);

  useEffect((): void => {
    setObject(file);
  }, [file, setObject]);

  const downloadFile = (): void => {
    const blob = new Blob([file]);
    saveAs(blob, file.name);
  };

  return fileURL ? (
    <div className={classes.container}>
      {type === MimeTypes.PDF ? (
        <Viewer fileUrl={fileURL} />
      ) : (
        <div className={classes.previewWarningContainer}>
          <div className={classes.center}>
            <Typography className={classes.downloadText} variant="h5">
              Download to Preview
            </Typography>
            <Button variant="contained" onClick={downloadFile}>
              Download
            </Button>
          </div>
        </div>
      )}
    </div>
  ) : null;
}
