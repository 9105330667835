import React from "react";
import clsx from "clsx";

import { Card, CardHeader } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles(theme => ({
  root: props => ({
    backgroundColor: props.backgroundColor,
    "&:hover": {
      backgroundColor: props.hoverColor,
      outline: "none"
    },
    "&:focus": {
      outline: "none"
    }
  }),
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  }
}));

const PromCard = props => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const {
    title,
    subheader,
    actions,
    collapsableContent,
    showExpand,
    ...other
  } = props;

  return (
    <Card
      style={{
        boxShadow:
          "rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px"
      }}>
      <CardHeader
        title={title}
        subheader={subheader}
        titleTypographyProps={{ variant: "subtitle2" }}
        subheaderTypographyProps={{ variant: "body2" }}
        action={
          showExpand && (
            <IconButton
              className={clsx(classes.root, classes.expand, {
                [classes.expandOpen]: expanded
              })}
              onClick={handleExpandClick}
              size="large">
              <ExpandMoreIcon />
            </IconButton>
          )
        }
      />
      {actions}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {collapsableContent}
      </Collapse>
    </Card>
  );
};

export default PromCard;
