import { MutableRefObject } from "react";

type Ref = MutableRefObject<any> | ((node: any) => void);

const mergeRefs = (...refs: Ref[]) => {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;

  // Only one ref, use it directly
  if (filteredRefs.length === 0) return filteredRefs[0];

  // Multiple refs, use a callback ref
  return node => {
    filteredRefs.forEach(ref => {
      if (typeof ref === "function") {
        ref(node);
      } else if (ref) {
        ref.current = node;
      }
    });
  };
};

export default mergeRefs;
