import { useCallback } from "react";

import { useDb } from "contexts/Db";

export const useGetCustomerName = () => {
  const db = useDb();
  // TODO(swey): improve types, i guess
  const { customers } = db;

  return useCallback(
    (customerKey: string, defaultName: string = "") =>
      customers[customerKey]?.name ?? defaultName,
    [customers]
  );
};
