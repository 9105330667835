import React from "react";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Paper from "@mui/material/Paper";
import ViewListIcon from "@mui/icons-material/ViewList";
import PersonIcon from "@mui/icons-material/Person";
import MailIcon from "@mui/icons-material/Mail";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import ReceiptIcon from "@mui/icons-material/Receipt";
import BackupIcon from "@mui/icons-material/Backup";

import StyledCSVLink from "ui-library/StyledCSVLink";
import DsmEngagementTracker from "./DsmEngagementTracker/DsmEngagementTracker";
import PresetCustomers from "./PresetCustomers";
import ManageUsers from "./ManageUsers";
import CompanyInvoicing from "./CompanyInvoicing";
import ManageDomains from "./ManageDomains";
import BackupScraping from "./BackupScraping";
import ManageCompanies from "./ManageCompanies";

class AdminPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0
    };
  }

  getActivityLog = () => {
    return Object.values(this.props.db.adminPanelEvents ?? {});
  };

  getTabJSX = () => {
    switch (this.state.selectedIndex) {
      case 0:
        return (
          <ManageCompanies
            db={this.props.db}
            openClose={this.props.openClose}
            showRightDrawer={this.props.openClose.showRightDrawer}
          />
        );
      case 1:
        return (
          <ManageUsers
            db={this.props.db}
            openClose={this.props.openClose}
            showRightDrawer={this.props.openClose.showRightDrawer}
          />
        );
      case 2:
        return (
          <PresetCustomers
            db={this.props.db}
            openClose={this.props.openClose}
            showRightDrawer={this.props.openClose.showRightDrawer}
          />
        );
      case 3:
        return (
          <CompanyInvoicing
            db={this.props.db}
            openClose={this.props.openClose}
            showRightDrawer={this.props.openClose.showRightDrawer}
          />
        );
      case 4:
        return (
          <DsmEngagementTracker
            db={this.props.db}
            openClose={this.props.openClose}
            showRightDrawer={this.props.openClose.showRightDrawer}
          />
        );
      case 5:
        return (
          <ManageDomains
            db={this.props.db}
            openClose={this.props.openClose}
            showRightDrawer={this.props.openClose.showRightDrawer}
          />
        );
      case 6:
        return (
          <BackupScraping
            db={this.props.db}
            openClose={this.props.openClose}
            showRightDrawer={this.props.openClose.showRightDrawer}
          />
        );
    }
  };

  render() {
    return (
      <div>
        <Paper elevation={2}>
          <BottomNavigation
            value={this.state.selectedIndex}
            onChange={(event, value) => {
              this.setState({ selectedIndex: value });
            }}
            showLabels>
            <BottomNavigationAction
              label="Manage Companies"
              icon={<ViewListIcon />}
              value={0}
            />
            <BottomNavigationAction
              label="Manage Users"
              icon={<PersonIcon />}
              value={1}
            />
            <BottomNavigationAction
              label="Preset Customers"
              icon={<ViewHeadlineIcon />}
              value={2}
            />
            <BottomNavigationAction
              label="Company Invoicing"
              icon={<ReceiptIcon />}
              value={3}
            />
            <BottomNavigationAction
              label="DSM Engagement Tracker"
              icon={<ReceiptIcon />}
              value={4}
            />
            <BottomNavigationAction
              label="Email Domains"
              icon={<MailIcon />}
              value={5}
            />
            <BottomNavigationAction
              label="Backup Scraping"
              icon={<BackupIcon />}
              value={6}
            />
          </BottomNavigation>
        </Paper>
        {this.getTabJSX()}
        <br />
        <StyledCSVLink
          data={this.getActivityLog()}
          filename="admin_panel_activity_log.csv"
          target="_blank">
          <>
            <CloudDownloadIcon />
            &nbsp; Download Activity Log
          </>
        </StyledCSVLink>
      </div>
    );
  }
}

export default AdminPanel;
