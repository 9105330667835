import Card from "ui-library/Card";
import React from "react";
import _ from "lodash";
import CachingRow from "./CachingRow";

/*
Input to this class will be of the form {Key1: [...], ..., Keyn: [...]}.
Output table will have Key1, ..., Keyn be the headers, and row i consists
of the ith elements of the lists corresponding to the keys.
*/

export default class DataTableSmart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: [],
      rows: [],
      deps: props.deps || []
    };
  }

  updateTable = (data, order) => {
    const title = []; // header for the table
    const rows = []; // data for rows of table

    if (!order) {
      for (var key in data) {
        title.push(key);
      }
    } else if (order && data) {
      for (var i = 0; i < order.length; i++) {
        if (order[i] in data) {
          title.push(order[i]);
        }
      }
    }

    if (data) {
      for (var i = 0; i < data[title[0]].length; i++) {
        const row = [];
        for (var key in title) {
          row.push(data[title[key]][i]);
        }
        rows.push({ order: i, row });
      }
    }
    rows.sort((a, b) => (a.row < b.row ? -1 : 1));
    this.setState({
      title,
      rows
    });
  };

  componentDidMount() {
    this.updateTable(this.props.data, this.props.order);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      _.isEqual(this.state.title, []) ||
      !_.isEqual(nextProps.deps || [], this.state.deps)
    ) {
      this.setState({ deps: nextProps.deps });
      this.updateTable(nextProps.data, nextProps.order);
      return true;
    }
    return false;
  }

  getRowDeps(index) {
    const rowDeps = [];
    this.state.deps.forEach(depArr => {
      rowDeps.push(depArr[index]);
    });
    return rowDeps;
  }

  render() {
    return (
      <Card title={this.props.title}>
        <div
          className="table-responsive"
          style={{
            maxHeight: this.props.height ? this.props.height : null,
            fontSize: this.props.fontSize ? this.props.fontSize : 16,
            overflow: "auto"
          }}>
          <table className="table">
            <thead>
              <tr>
                {this.state.title.map((item, index) => (
                  <th key={`th${index}`}>{item}</th>
                ))}
              </tr>
            </thead>
            <tbody style={{ overflow: "auto" }}>
              {this.state.rows.map(rowObj => {
                return (
                  <tr
                    key={`tr${rowObj.order}${
                      this.props.idAddition ? this.props.idAddition : ""
                    }`}>
                    <CachingRow
                      widths={this.props.widths}
                      row={rowObj.row}
                      deps={this.getRowDeps(rowObj.order)}
                    />
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Card>
    );
  }
}
