import React from "react";
import clsx from "clsx";
import { Card as MuiCard } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import MuiDivider from "@mui/material/Divider";

const baseStyles = makeStyles(() => ({
  divider: {
    margin: "0px"
  }
}));

const newStyles = makeStyles(() => ({
  divider: props => props.dividerStyle || {},
  content: props => props.contentStyle || {}
}));

const Card = props => {
  const {
    icon,
    title,
    children,
    footer,
    customContent,
    titleColor,
    action,
    cardContentStyle,
    ...other
  } = props;
  const baseClasses = baseStyles(props);
  const newClasses = newStyles(props);
  return (
    <MuiCard variant="outlined" {...other}>
      {title && (
        <>
          <CardHeader
            disableTypography
            avatar={icon}
            // style={{ backgroundColor: titleColor ?? grey["100"], height: 60 }}
            title={title}
            action={action}
          />
          <MuiDivider
            className={clsx(baseClasses.divider, newClasses.divider)}
          />
        </>
      )}
      {customContent ?? (
        <CardContent
          className={clsx(baseClasses.content, newClasses.content)}
          style={cardContentStyle || {}}>
          {children}
        </CardContent>
      )}
      {footer && (
        <>
          <MuiDivider
            className={clsx(baseClasses.divider, newClasses.divider)}
          />
          <CardActions>{footer}</CardActions>
        </>
      )}
    </MuiCard>
  );
};

export default Card;
