import { set, cloneDeep } from "lodash";
import { invoiceStatuses } from "deductions-constants/ReconciliationStatuses";
import { InvoiceObject } from "deductions-models/index";
import moment from "moment";
import { UploadBackupAction, UploadBackupActionType } from "./actionTypes";
import {
  MIN_PAGE_NUMBER,
  partition,
  checkValidIndex,
  checkUniqueField,
  convertArrayToObject,
  handleNextToEnterMetadata,
  handleNextToAttemptSave,
  handleNextToCompleteUpload,
  isFilePDFType,
  DOLLAR_REGEX,
  INTEGER_REGEX
} from "./helpers";

import {
  blankInvoiceObj,
  UploadBackupState,
  initialUploadBackupState,
  blankAssignPagesErrorObject,
  blankEnterMetadataErrorObject,
  uploadSteps,
  invoiceInputFields
} from "./definitions";

export default function uploadBackupReducer(
  state: UploadBackupState = initialUploadBackupState,
  action: UploadBackupAction
): UploadBackupState {
  switch (action.type) {
    case UploadBackupActionType.RESET: {
      return cloneDeep(initialUploadBackupState);
    }

    case UploadBackupActionType.CHANGE_UPLOAD_STEP: {
      const { currentUploadStep } = state;
      const { moveForward, db } = action;

      if (!moveForward) {
        switch (currentUploadStep) {
          case uploadSteps.ADD_METADATA: {
            return {
              ...state,
              currentUploadStep: Math.max(
                uploadSteps.ADD_FILES_AND_ASSIGN_PAGES,
                currentUploadStep - 1
              ),
              showErrors: false
            };
          }
          case uploadSteps.UPLOADING: {
            return {
              ...state,
              currentUploadStep: Math.max(
                uploadSteps.ADD_METADATA,
                currentUploadStep - 1
              ),
              showErrors: false,
              showUnknownError: true
            };
          }
        }
      }

      switch (currentUploadStep) {
        case uploadSteps.ADD_FILES_AND_ASSIGN_PAGES: {
          return handleNextToEnterMetadata(state);
        }
        case uploadSteps.ADD_METADATA: {
          return handleNextToAttemptSave(state, db);
        }
        case uploadSteps.UPLOADING: {
          return handleNextToCompleteUpload(state, db);
        }
        default: {
          return state;
        }
      }
    }

    case UploadBackupActionType.ADD_BACKUP_FILES: {
      const { addedFiles } = action;
      const { newFiles, errors } = state;

      // check for duplicate names
      const newFileNames = newFiles.map(file => file.name);
      const [pass, fail] = partition(
        addedFiles,
        file => !newFileNames.includes(file.name)
      );

      const updatedNewFiles = [...newFiles, ...pass];

      return {
        ...state,
        newFiles: updatedNewFiles,
        errors: {
          ...errors,
          uploadFiles: [
            {
              noFilesUploaded: !(updatedNewFiles.length > 0),
              duplicateFilesDetected: fail.length > 0,
              duplicateFileNames: fail.map(file => file.name)
            }
          ]
        }
      };
    }

    case UploadBackupActionType.UPDATE_FILE_PAGE_COUNTS: {
      const { addedFilePageCounts } = action;
      const { newFilePageCounts } = state;

      return {
        ...state,
        newFilePageCounts: [...newFilePageCounts, ...addedFilePageCounts]
      };
    }

    case UploadBackupActionType.REMOVE_BACKUP_FILE: {
      const {
        newFiles,
        viewedFileIdx,
        newFilePageCounts,
        errors,
        newInvoices
      } = state;
      const { fileIdx } = action;

      if (!checkValidIndex(newFiles, fileIdx)) {
        return state;
      }

      // remove file reference and page ranges from any new invoices
      const removedFileName = newFiles[fileIdx].name;
      for (let idx = 0; idx < newInvoices.length; idx++) {
        const invoiceObj = newInvoices[idx];
        let { fileName, startPage, endPage } = invoiceObj.originalFile;
        if (fileName === removedFileName) {
          startPage = null;
          endPage = null;
          fileName = null;

          // check errors for this invoice
          errors.assignPages[idx].noAssignedFile = true;
        }
      }

      // remove file itself
      newFiles.splice(fileIdx, 1);
      if (checkValidIndex(newFilePageCounts, fileIdx)) {
        newFilePageCounts.splice(fileIdx, 1);
      }

      // update affected state
      const newViewedFileIdx =
        newFiles.length > 0 && viewedFileIdx < fileIdx ? viewedFileIdx : null;

      // check for missing files error
      const { uploadFiles } = errors;
      if (newFiles.length === 0) {
        uploadFiles[0].noFilesUploaded = true;
      }

      return {
        ...state,
        newFiles,
        viewedFileIdx: newViewedFileIdx,
        newFilePageCounts,
        errors,
        newInvoices
      };
    }

    case UploadBackupActionType.SET_VIEWED_FILE: {
      const { newFiles } = state;
      const { fileIdx } = action;

      if (!checkValidIndex(newFiles, fileIdx)) {
        return state;
      }

      return { ...state, viewedFileIdx: fileIdx };
    }

    case UploadBackupActionType.ADD_INVOICE_OBJECT: {
      const { newInvoices, errors } = state;
      const { assignPages, enterMetadata } = errors;

      return {
        ...state,
        newInvoices: [...newInvoices, cloneDeep(blankInvoiceObj)],
        errors: {
          ...errors,
          assignPages: [...assignPages, cloneDeep(blankAssignPagesErrorObject)],
          enterMetadata: [
            ...enterMetadata,
            cloneDeep(blankEnterMetadataErrorObject)
          ]
        }
      };
    }

    case UploadBackupActionType.REMOVE_INVOICE_OBJECT: {
      const { newInvoices, errors } = state;
      const { invoiceIdx } = action;

      if (!checkValidIndex(newInvoices, invoiceIdx)) {
        return state;
      }
      newInvoices.splice(invoiceIdx, 1);
      errors.assignPages.splice(invoiceIdx, 1);
      errors.enterMetadata.splice(invoiceIdx, 1);

      return { ...state, newInvoices, errors };
    }

    case UploadBackupActionType.SET_INVOICE_ASSIGN_PAGE_FIELD: {
      const { newFiles, newInvoices, errors, newFilePageCounts } = state;
      const { invoiceIdx, field, value, db } = action;

      const currentInvoice = newInvoices[invoiceIdx];
      const currentErrors = errors.assignPages[invoiceIdx];

      switch (field) {
        case invoiceInputFields.FILE_NAME: {
          const fileIdx = newFiles.map(file => file.name).indexOf(value);

          const { originalFile } = currentInvoice;

          // autopopulate start/end pages
          if (isFilePDFType(value)) {
            originalFile.startPage = MIN_PAGE_NUMBER;
            originalFile.endPage = newFilePageCounts[fileIdx];
          } else {
            originalFile.startPage = null;
            originalFile.endPage = null;
          }

          currentErrors.noAssignedFile = false;
          set(currentInvoice, invoiceInputFields.FILE_NAME, value);
          break;
        }

        case invoiceInputFields.INVOICE_NUMBER: {
          const { invoices } = db;
          // DRM-790: Removed trimming to allow for spaces in invoice number as you type.
          const trimmedValue = value;

          // error if missing
          currentErrors.missingInvoiceNumber = trimmedValue === "";

          // error if duplicate of existing DB invoice number
          currentErrors.invoiceNumberExists = !checkUniqueField(
            Object.values(invoices).filter(
              (invoice: InvoiceObject) =>
                invoice.status !== invoiceStatuses.CANCELLED
            ),
            invoiceInputFields.INVOICE_NUMBER,
            trimmedValue
          );

          // error if duplicate with another invoice in batch
          currentErrors.duplicateInvoiceNumber = !checkUniqueField(
            newInvoices,
            invoiceInputFields.INVOICE_NUMBER,
            trimmedValue
          );

          set(currentInvoice, invoiceInputFields.INVOICE_NUMBER, trimmedValue);
          break;
        }

        case invoiceInputFields.START_PAGE: {
          const { endPage, fileName } = currentInvoice.originalFile;
          const fileIdx = newFiles.map(file => file.name).indexOf(fileName);
          const trimmedValue = value.trim();

          // check string pattern first
          if (!INTEGER_REGEX.test(trimmedValue)) {
            // not a valid text entry
            currentErrors.invalidStartPage = true;
            break;
          }
          currentErrors.invalidStartPage = false;

          const numValue = parseInt(trimmedValue) || null;

          // check if start > end
          currentErrors.reversedPageRange = numValue > endPage;

          // check if btw 1 and total page count
          currentErrors.invalidStartPage =
            numValue < MIN_PAGE_NUMBER || numValue > newFilePageCounts[fileIdx];

          set(currentInvoice, invoiceInputFields.START_PAGE, numValue);
          break;
        }

        case invoiceInputFields.END_PAGE: {
          const { startPage, fileName } = currentInvoice.originalFile;
          const fileIdx = newFiles.map(file => file.name).indexOf(fileName);
          const trimmedValue = value.trim();

          // error if not integer string pattern
          if (!INTEGER_REGEX.test(trimmedValue)) {
            currentErrors.invalidEndPage = true;
            break;
          }
          currentErrors.invalidEndPage = false;

          const numValue = parseInt(trimmedValue, 10) || null;

          if (numValue) {
            // error if start > end
            currentErrors.reversedPageRange = numValue < startPage;

            // error if not btw 1 and total page count
            currentErrors.invalidEndPage =
              numValue < MIN_PAGE_NUMBER ||
              numValue > newFilePageCounts[fileIdx];
          }

          set(currentInvoice, invoiceInputFields.END_PAGE, numValue);
          break;
        }
        default: {
          return state;
        }
      }

      return { ...state, newInvoices, errors };
    }

    case UploadBackupActionType.SET_INVOICE_METADATA_FIELD: {
      const { newInvoices, errors } = state;
      const { invoiceIdx, field, value } = action;

      const currentInvoice = newInvoices[invoiceIdx];
      const currentErrors = errors.enterMetadata[invoiceIdx];

      // TODO: This should really be a helper function somewhere...
      const isValidDate = (date: Date | string | null | undefined) => {
        const parseableDate = date && !Number.isNaN(new Date(date).getTime());
        if (!parseableDate) {
          return false;
        }

        // Only treat dates within 10 years of today as valid
        const dateDiff = moment(new Date(date)).diff(moment(new Date()));
        const years = moment.duration(dateDiff).asYears();
        return Math.abs(years) < 10;
      };

      switch (field) {
        case invoiceInputFields.INVOICE_NUMBER: {
          set(currentInvoice, invoiceInputFields.INVOICE_NUMBER, value.trim());
          if (!currentInvoice.originalFile.endPage) {
            currentInvoice.originalFile.startPage = 1;
            currentInvoice.originalFile.endPage = 1;
          }
          currentErrors.missingInvoiceNumber = !currentInvoice.invoiceNumber;
          break;
        }
        case invoiceInputFields.CHECK_NUMBER: {
          set(currentInvoice, invoiceInputFields.CHECK_NUMBER, value.trim());
          break;
        }
        case invoiceInputFields.CUSTOMER_KEY: {
          // error if missing
          currentErrors.missingCustomer = !value;

          set(currentInvoice, invoiceInputFields.CUSTOMER_KEY, value);
          break;
        }
        case invoiceInputFields.BACKUP_SOURCE: {
          set(currentInvoice, invoiceInputFields.BACKUP_SOURCE, value);
          break;
        }
        case invoiceInputFields.TOTAL_DISPLAY_AMOUNT: {
          const trimmedValue = value.trim();

          // error if not dollar string pattern
          currentErrors.invalidAmount = !DOLLAR_REGEX.test(trimmedValue);

          // error if zero
          currentErrors.zeroAmount = trimmedValue === "0";

          // error if empty string
          currentErrors.missingAmount = trimmedValue === "";

          const numValue = parseFloat(trimmedValue) || null;

          set(currentInvoice, invoiceInputFields.TOTAL_AMOUNT, numValue);
          set(
            currentInvoice,
            invoiceInputFields.TOTAL_DISPLAY_AMOUNT,
            trimmedValue
          );

          break;
        }
        case invoiceInputFields.INVOICE_TYPE: {
          set(currentInvoice, invoiceInputFields.INVOICE_TYPE, value);
          break;
        }
        case invoiceInputFields.START_DATE: {
          currentErrors.invalidStartDate = !isValidDate(value);
          set(currentInvoice, invoiceInputFields.START_DATE, value);
          break;
        }
        case invoiceInputFields.END_DATE: {
          // error if missing
          const startDate = currentInvoice[invoiceInputFields.START_DATE];

          // NOTE [DRM-825]: If end date is selected, and start date is null, set start date = end date.
          const invalidStartDate = !isValidDate(startDate);
          currentErrors.invalidEndDate = !isValidDate(value);
          if (invalidStartDate && !currentErrors.invalidEndDate) {
            set(currentInvoice, invoiceInputFields.START_DATE, value);
            currentErrors.invalidStartDate = false;
          }
          set(currentInvoice, invoiceInputFields.END_DATE, value);
          break;
        }
        case invoiceInputFields.SUGGESTED_PROM_TYPES: {
          set(
            currentInvoice,
            invoiceInputFields.SUGGESTED_PROM_TYPES,
            !value ? value : convertArrayToObject(value)
          );
          break;
        }
        default: {
          return state;
        }
      }

      // NOTE [DRM-825]: Show error if start date > end date.
      const startDate = currentInvoice[invoiceInputFields.START_DATE];
      const endDate = currentInvoice[invoiceInputFields.END_DATE];
      const invalidStartDate = !isValidDate(startDate);
      const invalidEndDate = !isValidDate(endDate);

      currentErrors.endDateBeforeStartDate =
        !invalidEndDate && !invalidStartDate && endDate < startDate;

      return { ...state, newInvoices, errors };
    }

    case UploadBackupActionType.SET_FAKE_INVOICE_MODE: {
      return {
        ...state,
        fakeInvoiceMode: true
      };
    }
    default: {
      return state;
    }
  }
}
