import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box as V5Box,
  TextField as V5TextField
} from "@mui/material";
import { ListAlt } from "@mui/icons-material";
import { CSVLink } from "react-csv";
import { useDb } from "contexts/Db";
import moment from "moment";
// eslint-disable-next-line import/order
import { DisplayDRMEvent } from "./DRMEvent";

// v5 imports
import DateRangePicker, { DateRange } from "@mui/lab/DateRangePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { renderUTCDateTimeInLocalTimeZone } from "helpers/Time";

interface ActivityLogExportProps {
  events: DisplayDRMEvent[];
}

export interface ExportHeader {
  label: string;
  key: string;
}

export const activityLogExportHeaders: ExportHeader[] = [
  { label: "Date", key: "createDate" },
  { label: "Event Type", key: "type" },
  { label: "User Name", key: "userName" },
  { label: "User Email", key: "userEmail" },
  { label: "Transaction ID", key: "transactionId" },
  { label: "Repaid/Credited Transaction ID", key: "repaidTransactionId" },
  { label: "Invoice #", key: "invoiceNumber" },
  { label: "Invoice Line #", key: "invoiceLineNumber" },
  { label: "Comment", key: "comment" }
];

const getDefaultDateRange = (): DateRange<Date> => {
  const today = new Date();
  const todayMinusThreeMonths = new Date();
  todayMinusThreeMonths.setMonth(todayMinusThreeMonths.getMonth() - 3);
  return [todayMinusThreeMonths, today];
};

export default function ActivityLogExport(props: ActivityLogExportProps) {
  const { events } = props;
  const { invoices, erpTransactions: transactions } = useDb();
  const csvLink = useRef<{ link: { click: () => void } }>();
  const [visible, setVisible] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const [dateRangeValue, setDateRangeValue] = useState<DateRange<Date>>(
    getDefaultDateRange()
  );

  const processEventsForExport = () => {
    const slicedEvents = events.filter(event => {
      const [start, end] = dateRangeValue;
      const newEnd = new Date(end!);
      newEnd.setDate(newEnd.getDate() + 1);
      return moment(event.createDate).isBetween(moment(start), moment(newEnd));
    });
    const processedEvents = slicedEvents.map(event => {
      const invoice = invoices[event.invoiceKey || ""] || undefined;
      const invoiceLines = invoice?.invoiceLines || {};
      const transaction = transactions[event.transactionKey || ""] || undefined;
      const repaidTransaction =
        transactions[event.repaidTransactionKey || ""] || undefined;

      const processed = {
        ...event,
        userName: event.user.name,
        createDate: renderUTCDateTimeInLocalTimeZone(event.createDate),
        userEmail: event.user.email,
        transactionId: transaction?.transactionDisplayId || "",
        repaidTransactionId: repaidTransaction?.transactionDisplayId || "",
        invoiceNumber: invoice?.invoiceNumber || "",
        invoiceLineNumber:
          invoiceLines[event.invoiceLineKey || ""]?.invoiceLineNumber || ""
      };

      return processed;
    });
    return processedEvents;
  };

  const [exported, setExported] = useState<
    ReturnType<typeof processEventsForExport>
  >([]);

  // useEffect #2: once events array state is set, virtually click on the CSVlink, and then reset state.
  useEffect((): void => {
    if (visible) {
      csvLink.current?.link.click();
      setVisible(false);
      setExported([]);
      setDateRangeValue(getDefaultDateRange());
    }
  }, [visible]);

  // useEffect #1: when download button is clicked, generate events array for export and set it in state
  useEffect((): void => {
    if (exported.length) {
      setVisible(true);
    }
  }, [exported]);

  const onModalClose = () => {
    setShowModal(false);
    setVisible(false);
    setExported([]);
    setDateRangeValue(getDefaultDateRange());
  };

  return (
    <>
      {showModal && (
        <Dialog open={showModal} onClose={() => onModalClose()}>
          <DialogTitle>Export Activity Log to CSV</DialogTitle>
          <DialogContent>
            <Typography>Please Select a Date Range to Export</Typography>
            <Typography component="div" variant="caption">
              <i>Default: Past Three Months</i>
            </Typography>
            <br />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateRangePicker
                startText="Start Date"
                endText="End Date"
                value={dateRangeValue}
                onChange={newDateRangeValue =>
                  setDateRangeValue(newDateRangeValue)
                }
                renderInput={(startProps, endProps) => (
                  <>
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <V5TextField {...startProps} />
                    <V5Box sx={{ mx: 2 }}> to </V5Box>
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <V5TextField {...endProps} />
                  </>
                )}
              />
            </LocalizationProvider>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => onModalClose()}>Go Back</Button>
            <Button
              onClick={() => {
                setExported(processEventsForExport());
                setShowModal(false);
              }}>
              Export
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Button
        onClick={(): void => {
          setShowModal(true);
        }}
        startIcon={<ListAlt />}
        variant="outlined"
        size="small">
        Export to CSV
      </Button>
      <CSVLink
        data={exported}
        headers={activityLogExportHeaders}
        filename="drm_activity_log.csv"
        className="hidden"
        ref={csvLink}
        target="_blank"
      />
    </>
  );
}
