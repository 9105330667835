import React from "react";
import clsx from "clsx";
import { TextField as MUITextField, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { grey } from "@mui/material/colors";

const grey300 = grey["300"];

const baseStyles = makeStyles({
  root: props => ({
    "& .MuiTextField-root": {
      margin: props.theme.spacing(1),
      width: "25ch"
    },
    width: props.fullWidth ? "100%" : 256,
    marginTop: 3,
    marginBottom: 6
  }),
  input: {},
  underline: {
    "&:before": {
      borderColor: grey300
    },
    "&:hover:not($disabled):not($focused):not($error):before": {
      borderColor: grey300
    }
  },
  disabled: {},
  focused: {},
  error: {}
});

const newStyles = makeStyles({
  root: props => props.style || {},
  input: props => props.input || {}
});

const TextField = props => {
  const theme = useTheme();

  const baseClasses = baseStyles({ ...props, theme });
  const newClasses = newStyles(props);
  const { floatingLabelText, helperText, errorText, endAdornment, ...other } =
    props;

  return (
    <MUITextField
      className={clsx(baseClasses.root, newClasses.root)}
      InputProps={{
        classes: {
          underline: baseClasses.underline,
          disabled: baseClasses.disabled,
          focused: baseClasses.focused,
          error: baseClasses.error
        },
        endAdornment
      }}
      // shrink doesn't work properly with external change of input value,
      // hence we need to pass it manually.
      // see: https://mui.com/components/text-fields/#shrink
      InputLabelProps={{ shrink: other.value !== undefined ? true : undefined }}
      label={floatingLabelText}
      error={errorText}
      helperText={errorText || helperText}
      {...other}
    />
  );
};

export default TextField;
