import React from "react";
import { Divider } from "pui-react-dividers";
import classNames from "classnames";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import RefreshIcon from "@mui/icons-material/Refresh";
import SaveIcon from "@mui/icons-material/Save";
import DownloadIcon from "@mui/icons-material/GetApp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SettingsIcon from "@mui/icons-material/Settings";
import Button from "ui-library/Button";
import IconButton from "ui-library/IconButton";
import IconMenu from "ui-library/IconMenu";
import MenuItem from "ui-library/MenuItem";
import Select from "ui-library/Select";
import Card from "ui-library/Card";
import { Toolbar, ToolbarSeparator, ToolbarTitle } from "ui-library/Toolbar";
import StyledToggleButtonGroup from "ui-library/ToggleButtonGroupStyled";
import ToggleButton from "@mui/material/ToggleButton";

import ToolbarGroup from "ui-library/ToolbarGroup";

import Stack from "@mui/material/Stack";

import { exportAnalytics } from "helpers/Export";
import { getUserDataFirebase, firebase } from "helpers/Firebase";
import { getTimesJSX } from "helpers/Time";
import { promotionTimingAnalyticsData } from "helpers/DataProcessing";
import { grey, common } from "@mui/material/colors";
import MoneyTableTiming from "../graphs/MoneyTableTiming";

const grey700 = grey["700"];
const { black } = common;

const styles = {
  notifs: {
    width: "500px"
  },
  subheader: {
    fontSize: 20,
    color: "inherit"
  },
  input: {
    margin: 5,
    marginTop: 20
  },
  loading: {
    height: $(window).height()
  },
  tabs: {
    backgroundColor: grey700
  },
  tabsBox: {
    borderColor: grey700,
    borderWidth: 2,
    borderStyle: "solid"
  },
  toolbarButton: {
    marginLeft: 5,
    marginRight: 5
  },
  checkbox: {
    marginLeft: 16
  }
};

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];
const quarters = ["Q1", "Q2", "Q3", "Q4"];
class PromotionTimingAnalysis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bumpChart: {},
      showSearch: false,
      product: 0,
      customer: 0,
      source: 0,
      accounts: [],
      checkedAll: false,
      doneLoading: false,
      modalOpen: false,
      year: new Date().getFullYear(),
      quarter: false,
      byDate: false,
      hideOptions: false,
      selectedDistributors: [],
      productsJSX: []
    };
  }

  saveToDatabase = () => {
    const savedKeys = [
      "product",
      "customer",
      "source",
      "accounts",
      "checkedAll",
      "year",
      "quarter",
      "byDate",
      "selectedDistributors"
    ];
    const payload = {};

    for (const key in savedKeys) {
      if (this.state.hasOwnProperty(savedKeys[key])) {
        payload[savedKeys[key]] = this.state[savedKeys[key]];
      }
    }

    const { uid } = firebase.auth().currentUser;
    firebase
      .database()
      .ref(`users/${uid}/insights/prom_timing_analysis`)
      .set(payload);
  };

  notTiedToCancelled = spendItem => {
    // ensure spendItem is not attached to cancelled promotion
    if (!("promKey" in spendItem)) return true;
    const { promKey } = spendItem;
    const prom = this.props.db.promotions[promKey];
    const status = this.props.db.meta.statuses[prom.status];
    if (status == "Cancelled") {
      return false;
    }
    return true;
  };

  filterProms = proms => {
    // filter promotions by date, customer, and account
    const filteredProms = {};
    if (proms) {
      for (const key in proms) {
        const s = proms[key];
        const status = this.props.db.meta.statuses[s.status];
        if (status != "Cancelled") {
          filteredProms[key] = s;
        }
      }
    }
    return filteredProms;
  };

  findCustomers = () => {
    // for each deductions item, assign it an entry consisting the distributors of its promotions
    // and if the promotion has no customers, then the deductions' customer
    // also, get all valid distributors to use for the "Customer" filter
    let allCustomers = new Set();
    const customerToKey = {};
    for (var key in this.props.db.actMoney) {
      var s = this.props.db.actMoney[key];
      const line = this.props.db.allLines[s.lineKey];
      var customerKey = line.customer;
      var customerName = this.props.db.customers[customerKey].name;
      allCustomers.add(customerName);
      customerToKey[customerName] = customerKey;
    }
    for (var key in this.props.db.revenue) {
      var s = this.props.db.revenue[key];
      var customerKey = s.customer;
      var customerName = this.props.db.customers[customerKey].name;
      allCustomers.add(customerName);
      customerToKey[customerName] = customerKey;
    }
    allCustomers = Array.from(allCustomers);
    allCustomers.sort();

    // create JSX values for each of the above distributors
    const customersJSX = [];
    for (let i = 0; i < allCustomers.length; i++) {
      var customerName = allCustomers[i];
      customersJSX.push(
        <MenuItem
          value={customerToKey[customerName]}
          children={customerName}
          key={customerToKey[customerName]}
        />
      );
    }

    return customersJSX;
  };

  filterMoney = (moneyObj, type = null) => {
    // filter revenue and deductions by date, customer, and account
    const filteredMoney = [];
    const pgs = this.props.db.meta.product_groups || {};
    const productToGroup = {};
    for (var [pgKey, pg] of Object.entries(pgs)) {
      if (pg.products) {
        for (var product of pg.products) {
          if (!(product in productToGroup)) {
            productToGroup[product] = [];
          }
          productToGroup[product].push(pgKey);
        }
      }
    }
    if (moneyObj) {
      const accountingSources = this.props.db.meta.accounting_sources || [];
      for (const key in moneyObj) {
        const s = { ...moneyObj[key] };
        if (type == "spend") {
          // add all products in product group
          const line = this.props.db.allLines[s.lineKey];
          var pgKey = line.productGroup;
          var product = pgKey == "custom" ? line.product : pgs[pgKey].products;
          s.product = product;
          s.productGroup = line.productGroup;
          s.account = this.props.db.allLines[line.lineKey].account;
        }
        if (type == "revenue") {
          // add all product groups the item is in
          s.productGroup = productToGroup[s.product];
        }
        if (
          (!this.state.customer || s.customer == this.state.customer) &&
          (!this.state.product ||
            s.productGroup == this.state.product ||
            (Array.isArray(s.productGroup) &&
              s.productGroup.includes(this.state.product))) &&
          s.year == this.state.year &&
          (!s.account || this.state.accounts.includes(s.account)) &&
          (type != "revenue" ||
            accountingSources.length == 0 ||
            (!this.state.source && accountingSources.includes(s.source)) ||
            s.source == this.state.source) &&
          this.notTiedToCancelled(s)
        ) {
          filteredMoney.push(s);
        }
      }
    }

    return filteredMoney;
  };

  handleResetFields = () => {
    const accounts = this.setAllAccounts(this.props);
    this.setState({
      year: new Date().getFullYear(),
      startDate: null,
      endDate: null,
      customer: 0,
      product: 0,
      accounts
    });
  };

  getData = () => {
    const customersJSX = this.findCustomers();
    const proms = this.filterProms(this.props.db.promotions);
    const actItems = this.filterMoney(this.props.db.actMoney, "spend");
    const revenueItems = this.filterMoney(this.props.db.revenue, "revenue");
    const promTimingData = promotionTimingAnalyticsData(
      this.state.year,
      proms,
      actItems,
      revenueItems,
      this.state.quarter
    );

    return [customersJSX, promTimingData];
  };

  getRowOrder = () => {
    const rowOrder = [""];
    if (this.state.quarter) {
      for (const quarter of quarters) {
        rowOrder.push(`${quarter}-${this.state.year}`);
      }
    } else {
      for (const month of months) {
        rowOrder.push(`${month}-${this.state.year}`);
      }
    }
    rowOrder.push("Total");
    return rowOrder;
  };

  getAccountsJSX = () => {
    const { meta, accounts } = this.props.db;
    return Object.entries(meta.fundTypes ?? {}).map(([k, type]) => {
      return (
        <MenuItem
          value={type?.accountKey}
          insetChildren={false}
          checked={this.state.accounts.includes(type?.accountKey)}
          children={type?.name}
          key={k}
        />
      );
    });
  };

  setAllAccounts = props => {
    const { meta, accounts } = props.db;
    return Array.from(
      new Set(
        Object.values(meta.fundTypes ?? {}).map(fundType => fundType.accountKey)
      )
    );
  };

  getSourcesJSX = props => {
    const accountingSources = this.props.db.meta.accounting_sources
      ? this.props.db.meta.accounting_sources
      : [];
    const sourcesJSX = [];
    for (const source of accountingSources) {
      sourcesJSX.push(
        <MenuItem value={source} children={source} key={source} />
      );
    }

    return sourcesJSX;
  };

  selectAll = () => {
    if (this.state.checkedAll) {
      this.setState({ accounts: [] });
    } else {
      this.setState({ accounts: this.setAllAccounts(this.props) });
    }
    this.setState({
      checkedAll: !this.state.checkedAll
    });
  };

  onAccountChange = (event, index, values) => {
    if (values.includes("Select All")) {
      this.selectAll();
    } else {
      this.setState({ accounts: values });
    }
  };

  componentDidMount() {
    const productsJSX = [];
    for (const key in this.props.db.meta.product_groups) {
      const pg = this.props.db.meta.product_groups[key];
      productsJSX.push(<MenuItem value={key} children={pg.name} key={key} />);
    }
    const accounts = this.setAllAccounts(this.props);
    const sourcesJSX = this.getSourcesJSX(this.props);
    const timesJSX = getTimesJSX(this.props.db.meta, false);
    this.setState({
      checkedAll: true,
      accounts,
      productsJSX,
      yearsJSX: timesJSX[1],
      sourcesJSX,
      doneLoading: true
    });

    getUserDataFirebase(promTimingAnalysis => {
      if (promTimingAnalysis) {
        this.setState(promTimingAnalysis);
      }
    }, "insights/prom_timing_analysis");
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.sideBar != this.props.sideBar) {
      return false;
    }
    return true;
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    // get timing data to display
    const [customersJSX, promTimingData] = this.getData();

    return this.state.doneLoading ? (
      <div>
        <Toolbar
          style={{ justifyContent: "space-between", minHeight: "unset" }}>
          <ToolbarGroup>
            <ToolbarTitle
              text="Promotion Timing Analysis"
              style={{ color: black }}
              flexItem
            />
            <ToolbarSeparator flexItem />
          </ToolbarGroup>
          <Stack>
            <StyledToggleButtonGroup
              value={this.state.quarter}
              exclusive
              onChange={(_, value) => {
                this.setState({ quarter: value });
              }}>
              <ToggleButton value={false}>Month</ToggleButton>
              <ToggleButton value>Quarter</ToggleButton>
            </StyledToggleButtonGroup>
          </Stack>
          <ToolbarGroup>
            <IconMenu
              iconButtonElement={
                <IconButton tooltip="Settings" size="large">
                  <SettingsIcon />
                </IconButton>
              }
              anchorOrigin={{ horizontal: "right", vertical: "top" }}
              targetOrigin={{ horizontal: "right", vertical: "top" }}
              onChange={this.props.handleAdditionalDataDisplay}>
              <MenuItem
                children={<Typography variant="subtitle1">Export</Typography>}
                disabled
              />
              <MenuItem
                value={0}
                children="Download Analysis"
                leftIcon={<DownloadIcon />}
                disabled={Object.keys(this.props.db.actMoney).length == 0}
                onClick={() => {
                  exportAnalytics(
                    firebase.auth().currentUser,
                    promTimingData,
                    "Timing",
                    this.getRowOrder(),
                    "export_timing_analysis",
                    this.state.quarter ? "Quarter" : "Month"
                  );
                  this.props.openClose.setAppModal(
                    "Export In Progress",
                    <div className="centering">
                      Your export is being prepared and will be emailed to you
                      shortly.
                    </div>,

                    <div className="centering">
                      <Button
                        label="Ok"
                        onClick={() => {
                          this.props.openClose.closeAppModal();
                        }}
                      />
                    </div>
                  );
                }}
              />
              <MenuItem
                value={1}
                children="Set as Default View"
                leftIcon={<SaveIcon />}
                onClick={this.saveToDatabase}
              />
            </IconMenu>
          </ToolbarGroup>
        </Toolbar>
        <div>
          {this.state.hideOptions && <br />}
          <div
            className={classNames("analytics-options", {
              hidden: this.state.hideOptions
            })}
            style={{ padding: 16 }}>
            <Stack>
              <Typography variant="h5" style={styles.subheader}>
                Filter Inputs
              </Typography>
              <div>
                <IconButton
                  color="tonal"
                  isBackgroundColor
                  tooltip="Clear Filters"
                  onClick={this.handleResetFields}
                  size="large">
                  <RefreshIcon />
                </IconButton>
              </div>
            </Stack>
            <br />
            <div className="row" style={{ marginBottom: -32 }}>
              <div className="col-lg-3">
                <Card contentStyle={{ marginTop: 2 }} title="Date">
                  <Select
                    floatingLabelText="Year"
                    value={this.state.year}
                    onChange={(event, index, value) => {
                      this.setState({ year: value });
                    }}
                    fullWidth
                    style={styles.input}>
                    {this.state.yearsJSX}
                  </Select>
                </Card>
              </div>
              <div className="col-lg-2">
                <Card contentStyle={{ marginTop: 2 }} title="Product Group">
                  <Select
                    floatingLabelText="Product Group"
                    value={this.state.product}
                    onChange={(event, index, value) => {
                      this.setState({ product: value });
                    }}
                    fullWidth
                    style={styles.input}>
                    <MenuItem
                      value={0}
                      children="Any Product Group"
                      key="wildcard"
                    />
                    {this.state.productsJSX}
                  </Select>
                </Card>
              </div>
              <div className="col-lg-2">
                <Card contentStyle={{ marginTop: 2 }} title="Customer">
                  <Select
                    floatingLabelText="Customer"
                    value={this.state.customer}
                    onChange={(event, index, value) => {
                      this.setState({ customer: value });
                    }}
                    fullWidth
                    style={styles.input}>
                    <MenuItem
                      value={0}
                      children="Any Customer"
                      key="wildcard"
                    />
                    {customersJSX}
                  </Select>
                </Card>
              </div>
              <div className="col-lg-3">
                <Card contentStyle={{ marginTop: 2 }} title="Accounts">
                  <Select
                    floatingLabelText="Accounts"
                    value={this.state.accounts}
                    onChange={this.onAccountChange}
                    fullWidth
                    style={styles.input}
                    multiple>
                    <MenuItem
                      checked={this.state.checkedAll}
                      value="Select All"
                      children="Select All"
                    />
                    {this.getAccountsJSX()}
                  </Select>
                </Card>
              </div>
              <div className="col-lg-2">
                <Card contentStyle={{ marginTop: 2 }} title="Data Sources">
                  <Select
                    floatingLabelText="Data Source"
                    value={this.state.source}
                    onChange={(event, index, value) => {
                      this.setState({ source: value });
                    }}
                    fullWidth
                    style={styles.input}>
                    <MenuItem value={0} children="All Sources" key="wildcard" />
                    {this.state.sourcesJSX}
                  </Select>
                </Card>
              </div>
            </div>
          </div>
          <Divider />
          <div className="centering" style={{ width: "100%" }}>
            <div
              className="options-toggle-button"
              onClick={() => {
                this.setState({ hideOptions: !this.state.hideOptions });
              }}>
              {this.state.hideOptions ? (
                <KeyboardArrowDownIcon style={{ color: grey700 }} />
              ) : (
                <KeyboardArrowUpIcon style={{ color: grey700 }} />
              )}
            </div>
          </div>
          <br />
          <div style={{ padding: 16 }}>
            <MoneyTableTiming
              data={promTimingData}
              year={this.state.year}
              quarter={this.state.quarter}
              openClose={this.props.openClose}
              childProps={this.props}
              filters={{
                customer: this.state.customer,
                product: this.state.product,
                accounts: this.state.accounts
              }}
            />
          </div>
          <br />
          <br />
        </div>
      </div>
    ) : (
      <div style={styles.loading} className="centering">
        <CircularProgress size={80} shrink={false} />
      </div>
    );
  }
}

export default PromotionTimingAnalysis;
