import { Divider, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { getValueFirebase } from "helpers/Firebase";
import Accordion from "ui-library/Accordion";
import MapCompanyPortal from "components/Settings/AdminPanel/BackupScraping/MapCompanyPortal";
import ScrapingForm from "./ScrapingForm";
import ScrapingContextProvider from "./ScrapingContext";

const useStyles = makeStyles(theme => ({
  pageContainer: {
    padding: 20,
    "& > *": {
      marginTop: 20
    }
  },
  heading: {
    fontFamily: "Ubuntu",
    padding: "10px 0px",
    color: theme.palette.primary.main
  }
}));

function BackupScraping() {
  const classes = useStyles();
  const [companyNames, setCompanyNames] = useState<string[] | null>(null);

  useEffect(() => {
    getValueFirebase("companyNames", snapshot => {
      const companyNamesCurrent = snapshot.val();

      setCompanyNames(companyNamesCurrent);
    });
  }, []);

  return (
    <ScrapingContextProvider>
      <div className={classes.pageContainer}>
        <Typography className={classes.heading} variant="h4">
          Backup Scraping
        </Typography>
        <Divider />

        <Accordion summary="Company Mapping" id="mapping">
          <Accordion.Content>
            <MapCompanyPortal companyNames={companyNames} />
          </Accordion.Content>
        </Accordion>

        <Accordion summary="Scraping" id="scraping">
          <Accordion.Content>
            <ScrapingForm companyNames={companyNames} />
          </Accordion.Content>
        </Accordion>
      </div>
    </ScrapingContextProvider>
  );
}

export default BackupScraping;
