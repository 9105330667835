import Typography from "@mui/material/Typography";
import { Divider } from "pui-react-dividers";
import React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import FreeTrialIcon from "@mui/icons-material/LocalOffer";
import Paper from "@mui/material/Paper";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import PeopleIcon from "@mui/icons-material/People";
import SettingsIcon from "@mui/icons-material/Settings";
import SecurityIcon from "@mui/icons-material/Security";
import { isUserCresicorEmployee } from "helpers/Firebase";

import ManageUsers from "./ManageUsers";
import FreeTrial from "./FreeTrial";
import Miscellaneous from "../../../Settings/Miscellaneous";
import Security from "../../../Settings/Security/Security";
import AdminPanel from "../../../Settings/AdminPanel/AdminPanel";

const styles = {
  paper: {
    padding: 16
  },
  superheader: {
    fontFamily: "Ubuntu",
    marginTop: 20
  }
};

class ScanSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0
    };
  }

  componentDidMount() {
    // placeholder for now
  }

  render() {
    const freeTrial =
      "free_trial" in this.props.db.meta
        ? this.props.db.meta.free_trial.on_trial
        : false;
    return (
      <div style={{ marginLeft: 75, marginRight: 75, marginBottom: 16 }}>
        <Typography style={styles.superheader} variant="h4">
          DSM Settings
        </Typography>
        <Divider />
        <Paper>
          <BottomNavigation
            value={this.state.selectedIndex}
            onChange={(event, value) => {
              this.setState({ selectedIndex: value });
            }}
            showLabels>
            <BottomNavigationAction
              label="Manage Users"
              icon={<PeopleIcon />}
              value={0}
            />
            {freeTrial && (
              <BottomNavigationAction
                label="Free Trial"
                icon={<FreeTrialIcon />}
                value={1}
              />
            )}
            <BottomNavigationAction
              label="Miscellaneous"
              icon={<SettingsIcon />}
              value={2}
            />
            <BottomNavigationAction
              label="Security"
              icon={<SecurityIcon />}
              value={3}
            />
            {isUserCresicorEmployee() && (
              <BottomNavigationAction
                label="Admin Panel"
                icon={<AssignmentIndIcon />}
                value={4}
              />
            )}
          </BottomNavigation>
        </Paper>
        <br />
        <Paper style={styles.paper}>
          {this.state.selectedIndex == 0 && (
            <ManageUsers db={this.props.db} openClose={this.props.openClose} />
          )}
          {this.state.selectedIndex == 1 && (
            <FreeTrial db={this.props.db} openClose={this.props.openClose} />
          )}
          {this.state.selectedIndex == 2 && (
            <Miscellaneous
              db={this.props.db}
              openClose={this.props.openClose}
            />
          )}
          {this.state.selectedIndex == 3 && (
            <Security db={this.props.db} openClose={this.props.openClose} />
          )}
          {this.state.selectedIndex == 4 && (
            <AdminPanel db={this.props.db} openClose={this.props.openClose} />
          )}
        </Paper>
      </div>
    );
  }
}

export default ScanSettings;
