import React from "react";
import StyledToggleButtonGroup from "ui-library/ToggleButtonGroupStyled";
import ToggleButton from "@mui/material/ToggleButton";
import {
  WILDCARD,
  diffTypes,
  pivotTypes,
  REVENUE,
  TRADE_RATE,
  TRADE_SPEND,
  BUDGET,
  ESTIMATES,
  ACTUAL
} from "helpers/Budget";
import { Stack } from "@mui/material";

export default function BudgetButtonGroup({
  pivot,
  figureType,
  dataSubTypesForPivot,
  diffType,
  changeStateOnButtonClick
}) {
  const toolbarButtons = {
    budget: [
      {
        label: "Budget",
        isPrimary:
          pivot === pivotTypes.DATE
            ? figureType === BUDGET
            : dataSubTypesForPivot.includes(BUDGET),
        stateChange: {
          figureType: BUDGET,
          dataSubTypesForPivot: [BUDGET, dataSubTypesForPivot[1]],
          diffType: diffTypes.None
        }
      },
      {
        label: "LE",
        isPrimary:
          pivot === pivotTypes.DATE
            ? figureType === ESTIMATES
            : dataSubTypesForPivot.includes(ESTIMATES),
        stateChange: {
          figureType: ESTIMATES,
          dataSubTypesForPivot: [ESTIMATES, dataSubTypesForPivot[1]],
          diffType: diffTypes.None
        }
      },
      {
        label: "Actual",
        isPrimary:
          pivot === pivotTypes.DATE
            ? figureType === ACTUAL
            : dataSubTypesForPivot.includes(ACTUAL),
        stateChange: {
          figureType: ACTUAL,
          dataSubTypesForPivot: [ACTUAL, dataSubTypesForPivot[1]],
          diffType: diffTypes.None
        },
        hasSeparator: true
      }
    ],
    diff: [
      {
        label: "Diff (Net)",
        isPrimary: diffType === diffTypes.Absolute,
        stateChange: {
          pivot: pivotTypes.DATE,
          diffType: diffTypes.Absolute,
          figureType: WILDCARD
        }
      },
      {
        label: "Diff (%)",
        isPrimary: diffType === diffTypes.Relative,
        stateChange: {
          pivot: pivotTypes.DATE,
          diffType: diffTypes.Relative,
          figureType: WILDCARD
        },
        hasSeparator: true
      }
    ],
    revenue: [
      {
        label: "Revenue",
        isPrimary:
          pivot === pivotTypes.DATE
            ? figureType === REVENUE
            : dataSubTypesForPivot.includes(REVENUE),
        stateChange: {
          figureType: REVENUE,
          dataSubTypesForPivot: [dataSubTypesForPivot[0], REVENUE],
          diffType: diffTypes.None
        }
      },
      {
        label: "Trade Rate",
        isPrimary:
          pivot === pivotTypes.DATE
            ? figureType === TRADE_RATE
            : dataSubTypesForPivot.includes(TRADE_RATE),
        stateChange: {
          figureType: TRADE_RATE,
          dataSubTypesForPivot: [dataSubTypesForPivot[0], TRADE_RATE],
          diffType: diffTypes.None
        }
      },
      {
        label: "Trade Spend",
        isPrimary:
          pivot === pivotTypes.DATE
            ? figureType === TRADE_SPEND
            : dataSubTypesForPivot.includes(TRADE_SPEND),
        stateChange: {
          figureType: TRADE_SPEND,
          dataSubTypesForPivot: [dataSubTypesForPivot[0], TRADE_SPEND],
          diffType: diffTypes.None
        },
        hasSeparator: true
      }
    ]
  };

  const handleCategoryChange = (event, toggleType) => {
    const item = toolbarButtons[toggleType][event.target.id];
    changeStateOnButtonClick(item.stateChange);
    // setSelectedCategories(newCategories);
  };

  const selectedCategoriesArray = toolbarButtons.budget
    .map(item => (item.isPrimary ? item.label : null))
    .filter(name => name !== null);

  const selectedRevenueTogglesArray = toolbarButtons.revenue
    .map(item => (item.isPrimary ? item.label : null))
    .filter(name => name !== null);

  const selectedDiffTogglesArray = toolbarButtons.diff
    .map(item => (item.isPrimary ? item.label : null))
    .filter(name => name !== null);

  return (
    <Stack>
      <StyledToggleButtonGroup
        value={selectedCategoriesArray}
        onChange={e => handleCategoryChange(e, "budget")}>
        {toolbarButtons.budget.map(({ label }, index) => {
          return (
            <ToggleButton id={index} value={label}>
              {label}
            </ToggleButton>
          );
        })}
      </StyledToggleButtonGroup>
      <StyledToggleButtonGroup
        value={selectedDiffTogglesArray}
        onChange={e => handleCategoryChange(e, "diff")}>
        {toolbarButtons.diff.map(({ label }, index) => {
          return (
            <ToggleButton id={index} value={label}>
              {label}
            </ToggleButton>
          );
        })}
      </StyledToggleButtonGroup>
      <StyledToggleButtonGroup
        value={selectedRevenueTogglesArray}
        onChange={e => handleCategoryChange(e, "revenue")}>
        {toolbarButtons.revenue.map(({ label }, index) => {
          return (
            <ToggleButton id={index} value={label}>
              {label}
            </ToggleButton>
          );
        })}
      </StyledToggleButtonGroup>
    </Stack>
  );
}
