import { Toolbar, ToolbarSeparator, ToolbarTitle } from "ui-library/Toolbar";
import {
  cloudRunURL,
  firebase,
  isUserCresicorEmployee
} from "helpers/Firebase";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Button from "ui-library/Button";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import CompareIcon from "@mui/icons-material/Compare";
import GetAppIcon from "@mui/icons-material/GetApp";
import IconButton from "ui-library/IconButton";
import IconMenu from "ui-library/IconMenu";
import MenuItem from "ui-library/MenuItem";
import Paper from "@mui/material/Paper";
import React from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import CancelIcon from "@mui/icons-material/Cancel";
import SettingsIcon from "@mui/icons-material/Settings";
import ToolbarGroup from "ui-library/ToolbarGroup";
import axios from "axios";
import { exportForecast } from "helpers/Export";
import { infoDialog, confirmDialog } from "helpers/OpenDialog";
import { common } from "@mui/material/colors";
import { ToggleButton, Stack } from "@mui/material";
import StyledToggleButtonGroup from "ui-library/ToggleButtonGroupStyled";
import { getForecastData } from "./ForecastFirebase";
import ForecastTable from "./ForecastTable";
import { ActualsVsForecast } from "./ActualsVsForecast";

const { black } = common;

class Forecast extends React.Component {
  constructor(props) {
    super(props);
    this.ftRef = React.createRef();
    this.state = {
      showSearch: false,
      searchQuery: "",
      modalOpen: false,
      category: 1,
      breakdown: "customer",
      accounts: [],
      customer: "All",
      year: new Date().getFullYear(),
      time: "week",
      pivot: "customer",
      mode: "retailer",
      byDate: false,
      tableRows: [],
      selectedIndex: 0,
      forecastData: {},
      weeks: [],
      forecastLoading: false
    };
  }

  openFilePicker = jsx => {
    this.setState({
      openFilePicker: true
    });
    this.props.showDropDownWindow(jsx);
  };

  closeFilePicker = () => {
    this.setState({
      openFilePicker: false
    });
  };

  changePivot = (event, pivotValue) => {
    this.setState({ pivot: pivotValue });
  };

  changeMode = (event, mode) => {
    this.setState({ mode });
  };

  setLoadingFalse = () => {
    const firebaseForecast = firebase.app("forecast");
    const forecastRef = firebaseForecast
      .database()
      .ref(`${this.props.db.companyid}/meta`);
    forecastRef.update({
      loading: false
    });
    this.setState({ forecastLoading: false });
  };

  staleForecastWarn = meta => {
    // if forecast is more than a day old, show warning snackbar
    const forecastUpdated = new Date(meta.forecastUpdated);
    if ((new Date() - forecastUpdated) / (1000 * 60 * 60 * 24) > 1) {
      this.props.openClose.showSnack(
        "Forecast is out of date - please refresh."
      );
    }
  };

  updateForecast = () => {
    const user = firebase.auth().currentUser;
    if (Object.keys(this.props.db.revenue).length == 0) {
      this.props.openClose.showSnack(
        "Please enter revenue data in order to create forecast."
      );
    } else {
      this.setState({ forecastLoading: true });
      const formData = {
        companyid: this.props.db.companyid,
        userid: user.uid
      };
      axios
        .post(`${cloudRunURL}/api/generate_forecast`, { data: formData })
        .then(response => {
          this.props.openClose.showSnack("Finished generating forecast.");
        })
        .catch(error => {
          console.error(error);
          this.props.openClose.showSnack(
            "Error generating forecast - please contact support if this issue persists."
          );
          this.setLoadingFalse();
          // leave empty for now
        })
        .finally(() => this.setState({ forecastLoading: false }));
    }
  };

  componentDidMount() {
    const { companyid } = this.props.db;
    getForecastData(companyid, "meta", metaSnapshot => {
      const meta = metaSnapshot.val() || {};
      this.staleForecastWarn(meta);
      const forecastLoading = meta.loading;
      this.setState({
        forecastUpdated: meta.forecastUpdated,
        forecastLoading
      });
    });
    const tbHeight =
      document.documentElement.clientHeight - $(this.refs.table).offset().top;
    this.setState({
      tbHeight
    });
  }

  render() {
    return (
      <div>
        <Toolbar>
          <ToolbarGroup>
            <ToolbarTitle text="Forecast" style={{ color: black }} />
            <ToolbarSeparator />
          </ToolbarGroup>
          <Stack>
            <StyledToggleButtonGroup
              value={this.state.pivot}
              exclusive
              onChange={this.changePivot}
              disabled={this.state.forecastLoading}>
              <ToggleButton value="customer"> By Customers</ToggleButton>
              <ToggleButton value="product"> By Products</ToggleButton>
            </StyledToggleButtonGroup>
            <ToolbarSeparator />
            <StyledToggleButtonGroup
              value={this.state.mode}
              exclusive
              onChange={this.changeMode}
              disabled={this.state.forecastLoading}>
              <ToggleButton value="retailer"> Retailers</ToggleButton>
              <ToggleButton value="all_other"> All-Other Buckets</ToggleButton>
              <ToggleButton value="distributor">Distributors</ToggleButton>
            </StyledToggleButtonGroup>
          </Stack>
          <ToolbarGroup>
            <IconMenu
              iconButtonElement={
                <IconButton tooltip="Settings" size="large">
                  <SettingsIcon />
                </IconButton>
              }
              anchorOrigin={{ horizontal: "right", vertical: "top" }}
              targetOrigin={{ horizontal: "right", vertical: "top" }}
              onChange={this.props.handleAdditionalDataDisplay}>
              <MenuItem
                value={0}
                onClick={() => {
                  if (
                    (new Date() - new Date(this.state.forecastUpdated)) /
                      (1000 * 60 * 60 * 24) >
                    1
                  ) {
                    confirmDialog(
                      this.props.openClose,
                      "Confirm Stale Export",
                      "The current forecast is more than 24 hours out of date and may be stale. Are you sure you still wish to export it?",
                      () => {
                        exportForecast(
                          this.props.db.companyid,
                          this.props.openClose
                        );
                        infoDialog(
                          this.props.openClose,
                          "Export In Progress",
                          "Your export is being prepared and will be emailed to you shortly.",
                          "Ok"
                        );
                      }
                    );
                  } else {
                    exportForecast(
                      this.props.db.companyid,
                      this.props.openClose
                    );
                    infoDialog(
                      this.props.openClose,
                      "Export In Progress",
                      "Your export is being prepared and will be emailed to you shortly.",
                      "Ok"
                    );
                  }
                }}
                leftIcon={<GetAppIcon />}>
                Export Forecast
              </MenuItem>
              {isUserCresicorEmployee() && this.state.forecastLoading && (
                <MenuItem
                  value={1}
                  onClick={this.setLoadingFalse}
                  leftIcon={<CancelIcon />}>
                  Disable Loading
                </MenuItem>
              )}
            </IconMenu>
            {!this.props.readOnly && (
              <Button
                label="Update Forecast"
                onClick={this.updateForecast}
                disabled={
                  this.state.forecastLoading ||
                  !this.props.db.permissions.includes("forecast") ||
                  Object.keys(this.props.db.customers).length == 0 ||
                  Object.keys(this.props.db.products).length == 0
                }
                icon={
                  <RefreshIcon
                    className={this.state.forecastLoading && "fa-spin"}
                  />
                }
              />
            )}
          </ToolbarGroup>
        </Toolbar>
        <div style={{ padding: 16 }}>
          <Paper>
            <BottomNavigation
              value={this.state.selectedIndex}
              onChange={(event, value) => {
                this.setState({ selectedIndex: value });
              }}
              showLabels>
              <BottomNavigationAction
                label="Forecast Table"
                icon={<CloudQueueIcon />}
                value={0}
              />
              <BottomNavigationAction
                label="Actuals vs. Forecast"
                icon={<CompareIcon />}
                value={1}
              />
            </BottomNavigation>
          </Paper>
        </div>
        <div
          style={{ height: this.state.tbHeight, overflow: "auto", padding: 40 }}
          ref="table">
          {this.state.selectedIndex == 0 ? (
            <ForecastTable
              ref={this.ftRef}
              db={this.props.db}
              openClose={this.props.openClose}
              readOnly={this.props.readOnly}
              mode={this.state.mode}
              pivot={this.state.pivot}
              loading={this.state.forecastLoading}
            />
          ) : (
            <ActualsVsForecast db={this.props.db} mode={this.state.mode} />
          )}
        </div>
      </div>
    );
  }
}

export default Forecast;
