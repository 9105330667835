import { useContext, createContext } from "react";
import DRMEventService from "components/Deductions/DeductionsReconciliation/ActivityLog/DRMEventService";

export const DRMEventsContext = createContext<DRMEventService | undefined>(
  undefined
);

export const useDRMEvents = () => {
  const drmEventsState = useContext(DRMEventsContext);

  if (!drmEventsState) {
    throw new Error(
      "No DbContext Provider found in tree. Is it present in the component tree?"
    );
  }

  return drmEventsState;
};
