import React from "react";

import DatePicker from "ui-library/DatePicker";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import IconButton from "ui-library/IconButton";
import MenuItem from "ui-library/MenuItem";
import Subheader from "ui-library/Subheader";
import TextField from "ui-library/TextField";
import DropDownMenu from "ui-library/DropDownMenu";

import { updateLineFirebase } from "helpers/Firebase";
import Mixpanel from "helpers/Mixpanel";
import { green, common, deepOrange } from "@mui/material/colors";
import DataTable from "../tables/MuiDataTable";

const green700 = green["700"];
const { black } = common;
const deepOrange400 = deepOrange["400"];

const styles = {
  header: {
    marginRight: "-50px",
    fontSize: 20,
    color: black
  },
  subheader: {
    marginLeft: "-15px",
    marginRight: "-50px",
    fontSize: 20
  },
  divider: {
    margin: "30px auto",
    width: "50%"
  },
  autoFillButton: {
    marginTop: 16
  }
};

function round(n) {
  return Math.round(n * 100) / 100;
}

export default class LineProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changes: false,
      plotDataObtained: false,
      product: {},
      closed: props.line.closed || false
    };
  }

  handleTextChange = (field, event) => {
    this.props.handleTextChange(`lines.${this.props.lineKey}.${field}`, event);
  };

  handleDateChange = (field, event, value) => {
    this.props.handleDateChange(
      `lines.${this.props.lineKey}.${field}`,
      event,
      value
    );
    // change general date fields if necessary
    if (field == "instoreStartDate") {
      this.props.handleDateChange(
        `lines.${this.props.lineKey}.startDate`,
        event,
        value
      );
    } else if (field == "instoreEndDate") {
      this.props.handleDateChange(
        `lines.${this.props.lineKey}.endDate`,
        event,
        value
      );
    }
  };

  handleNumTextChange = (field, event) => {
    this.props.handleNumTextChange(
      `lines.${this.props.lineKey}.${field}`,
      event
    );
  };

  handleSelectChange = (field, event, index, value) => {
    this.props.handleSelectChange(
      `lines.${this.props.lineKey}.${field}`,
      event,
      index,
      value
    );
  };

  prettyPrint = itemArray => {
    let items = "";
    if (itemArray) {
      for (let i = 0; i < itemArray.length; i++) {
        if (i != 0) {
          items += ", ";
        }
        items += itemArray[i];
      }
    }
    return items;
  };

  autoFillDates = tf => {
    const tfs = ["buyin", "instore", "scanback"];

    for (var i = 0; i < tfs.length; i++) {
      var prevTf = tfs[i];
      if (prevTf != tf && this.props.line[`${prevTf}StartDate`]) {
        this.handleDateChange(
          `${tf}StartDate`,
          null,
          new Date(this.props.line[`${prevTf}StartDate`])
        );
      }
    }

    for (var i = 0; i < tfs.length; i++) {
      var prevTf = tfs[i];
      if (prevTf != tf && this.props.line[`${prevTf}EndDate`]) {
        this.handleDateChange(
          `${tf}EndDate`,
          null,
          new Date(this.props.line[`${prevTf}EndDate`])
        );
      }
    }
  };

  getTypeFieldsJSX = () => {
    const typeFieldsJSX = [];
    const typeFields =
      this.props.db.meta.fundTypes?.[this.props.line.type]?.promCreationFields;
    const showPricing =
      this.props.db.meta.fundTypes?.[this.props.line.type]
        ?.promCreationShowPricing ?? true;
    const isLift =
      this.props.db.meta.tier == "aspen" &&
      this.props.db.meta.fundTypes?.[this.props.line.type]?.liftAssigned;

    if (isLift) {
      typeFieldsJSX.push(
        <div key="lift">
          <TextField
            floatingLabelText="Lift (%)"
            value={
              this.props.line.lift ? parseInt(100 * this.props.line.lift) : 0
            }
            fullWidth
            disabled
          />
        </div>
      );
    }

    if (typeFields.includes("buyin")) {
      typeFieldsJSX.push(
        <div className="rowC" key="buyin">
          <div style={{ width: "50%" }}>
            <DatePicker
              floatingLabelText="Buy-in Start Date"
              value={
                new Date(
                  this.props.line.buyinStartDate || this.props.line.startDate
                )
              }
              minDate={
                this.props.db.permissions.indexOf("add") != -1
                  ? new Date(1970, 1, 1)
                  : new Date()
              }
              fullWidth
              disabled
            />
          </div>
          <div style={{ width: "48%", marginLeft: "16px" }}>
            <DatePicker
              floatingLabelText="Buy-in End Date"
              value={
                new Date(
                  this.props.line.buyinEndDate || this.props.line.endDate
                )
              }
              minDate={
                this.props.line.buyinStartDate
                  ? new Date(this.props.line.buyinStartDate)
                  : new Date(1970, 1, 1)
              }
              fullWidth
              style={{ marginLeft: 16 }}
              disabled
            />
          </div>
        </div>
      );
    }

    if (typeFields.includes("scanback")) {
      typeFieldsJSX.push(
        <div className="rowC" key="scanback">
          <div style={{ width: "50%" }}>
            <DatePicker
              floatingLabelText="Scan-Back Start Date"
              value={
                new Date(
                  this.props.line.scanbackStartDate || this.props.line.startDate
                )
              }
              onChange={this.handleDateChange.bind(null, "scanbackStartDate")}
              minDate={
                this.props.db.permissions.indexOf("add") != -1
                  ? new Date(1970, 1, 1)
                  : new Date()
              }
              fullWidth
              disabled
            />
          </div>
          <div style={{ width: "48%", marginLeft: "16px" }}>
            <DatePicker
              floatingLabelText="Scan-Back End Date"
              value={
                new Date(
                  this.props.line.scanbackEndDate || this.props.line.endDate
                )
              }
              minDate={
                this.props.line.scanbackStartDate
                  ? new Date(this.props.line.scanbackStartDate)
                  : new Date(1970, 1, 1)
              }
              fullWidth
              style={{ marginLeft: 16 }}
              disabled
            />
          </div>
        </div>
      );
    }

    if (typeFields.includes("instore")) {
      typeFieldsJSX.push(
        <div className="rowC" key="instore">
          <div style={{ width: "50%" }}>
            <DatePicker
              floatingLabelText="In-Store Start Date"
              value={
                new Date(
                  this.props.line.instoreStartDate || this.props.line.startDate
                )
              }
              minDate={
                this.props.db.permissions.indexOf("add") != -1
                  ? new Date(1970, 1, 1)
                  : new Date()
              }
              fullWidth
              disabled
            />
          </div>
          <div style={{ width: "48%", marginLeft: "16px" }}>
            <DatePicker
              floatingLabelText="In-Store End Date"
              value={
                new Date(
                  this.props.line.instoreEndDate || this.props.line.endDate
                )
              }
              minDate={
                this.props.line.instoreStartDate
                  ? new Date(this.props.line.instoreStartDate)
                  : new Date(1970, 1, 1)
              }
              fullWidth
              style={{ marginLeft: 16 }}
              disabled
            />
          </div>
        </div>
      );
    }
    if (
      typeFields.includes("rate") ||
      this.props.line.spendRate * this.props.line.totalExpSales > 0
    ) {
      typeFieldsJSX.push(
        <div key="spendRate">
          <TextField
            value={this.props.line.spendRate || ""}
            onChange={this.handleNumTextChange.bind(null, "spendRate")}
            floatingLabelText="Spend Rate ($/unit)"
            fullWidth
            disabled
          />
          <br />
          <div className="rowC">
            <div style={{ width: "50%" }}>
              <TextField
                value={this.props.line.totalExpSales || ""}
                onChange={this.handleNumTextChange.bind(null, "totalExpSales")}
                floatingLabelText="Total Expected Unit Sales"
                disabled
                fullWidth
              />
            </div>
            <div style={{ width: "48%" }}>
              <TextField
                value={
                  this.props.line.prevTotalExpSales != null
                    ? this.props.line.prevTotalExpSales
                    : "N/A"
                }
                floatingLabelText="Previous Sales Estimate (units)"
                style={{ marginLeft: 16 }}
                disabled
                fullWidth
              />
            </div>
          </div>
          <br />
          <TextField
            value={
              round(
                this.props.line.spendRate * this.props.line.totalExpSales
              ) || ""
            }
            floatingLabelText="Total Expected Spend ($)"
            disabled
            fullWidth
          />
        </div>
      );
    }
    if (typeFields.includes("lumpsum") || this.props.line.lumpSumSpend > 0) {
      typeFieldsJSX.push(
        <div key="lumpSumSpend">
          <TextField
            value={this.props.line.lumpSumSpend || ""}
            onChange={this.handleNumTextChange.bind(null, "totalExpSpend")}
            floatingLabelText="Total Expected Spend (Lump Sum $)"
            fullWidth
            disabled
          />
        </div>
      );
    }

    if (showPricing) {
      typeFieldsJSX.push(
        <div key="pricing">
          <br />
          <br />
          <Subheader style={styles.subheader}>Pricing</Subheader>
          <TextField
            value={this.props.line.basePrice ? this.props.line.basePrice : ""}
            onChange={this.handleNumTextChange.bind(null, "basePrice")}
            floatingLabelText="Base Price ($)"
            fullWidth
            disabled
          />
          <br />
          <TextField
            value={
              this.props.line.retailPrice ? this.props.line.retailPrice : ""
            }
            onChange={this.handleNumTextChange.bind(null, "retailPrice")}
            floatingLabelText="Retail Price ($)"
            fullWidth
            disabled
          />
          <br />
          <TextField
            value={round(
              ((this.props.line.basePrice - this.props.line.retailPrice) *
                100) /
                this.props.line.basePrice
            )}
            disabled
            floatingLabelText="Discount (%)"
            fullWidth
            disabled
          />
        </div>
      );
    }

    return typeFieldsJSX;
  };

  getStaticFields = () => {
    const staticFields = {
      Field: ["Line ID", "Product(s)", "Type", "Account", "Duration"],
      Value: []
    };

    const pg =
      this.props.line.productGroup != "custom"
        ? this.props.db.meta.product_groups[this.props.line.productGroup]
        : "custom";
    staticFields.Value.push(
      <div className="rowC">
        {this.props.lineKey}
        <IconButton
          tooltip="Copy ID"
          style={{ marginTop: -10, marginLeft: 10 }}
          onClick={() => {
            navigator.clipboard.writeText(this.props.lineKey);
          }}
          size="large">
          <FileCopyIcon />
        </IconButton>
      </div>
    );
    staticFields.Value.push(
      this.props.line.productGroup == "custom"
        ? `Custom: ${this.prettyPrint(
            this.props.line.product.map(
              product => this.props.db.products[product].name
            )
          )}`
        : pg.name
    );
    staticFields.Value.push(
      this.props.db.meta.fundTypes?.[this.props.line.type]?.name
    );
    staticFields.Value.push(
      this.props.db.accounts?.[this.props.line.account]?.name
    );
    staticFields.Value.push(
      `${this.props.line.startDate} - ${this.props.line.endDate}`
    );

    return staticFields;
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ closed: nextProps.line.closed });
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    const staticFields = this.getStaticFields();
    const typeFieldsJSX = this.getTypeFieldsJSX();

    const openLock = <LockOpenIcon sx={{ color: "success.main" }} />;
    const closedLock = <LockIcon sx={{ color: "warning.main" }} />;

    const type = this.props.db.meta
      ? this.props.db.meta.fundTypes?.[this.props.line.type]?.name
      : "";

    const options = [
      {
        label: "This line is open",
        value: false,
        icon: openLock
      },
      {
        label: "This line is closed",
        value: true,
        icon: closedLock
      }
    ];
    return (
      <div style={{ marginLeft: "16px" }}>
        {!this.props.proposed ? (
          <DropDownMenu
            currentIndex={Number(this.state.closed ?? 0)}
            options={options}
            // labelStyle={{ fontWeight: "bold" }}
            style={{ bottom: "5px" }}
            disabled={
              !this.props.db.permissions.includes("close") ||
              this.props.proposed
            }
            onClick={event => {
              // HACK: If the user clicks "out" of the menu (i.e. not open or closed), quit this callback function
              const open = event.target.innerText === options[0].label;
              const closed = event.target.innerText === options[1].label;
              if (!open && !closed) return;
              this.setState({ closed });
              const newLine = { ...this.props.line };
              newLine.closed = closed;
              this.props.updateLine(this.props.lineKey, newLine);
              updateLineFirebase(
                newLine,
                this.props.promKey,
                this.props.lineKey
              );
              Mixpanel.track("Lock", {
                Type: "Line",
                Action: closed ? "Close" : "Open"
              });
            }}
            // underlineStyle={{ borderColor: "transparent" }}
          />
        ) : (
          <div>
            <MenuItem
              leftIcon={openLock}
              value={false}
              children="Unconfirmed lines are always open"
              ContainerComponent="div"
            />
            <br />
          </div>
        )}
        {/* <Select
          value={this.state.showClosed}
          labelStyle={{ fontWeight: "bold" }}
          style={{ bottom: "5px" }}
          disabled={
            !this.props.db.permissions.includes("close") || this.props.proposed
          }
          onChange={(event, index, value) => {
            this.setState({ showClosed: value });
            var newLine = Object.assign({}, this.props.line);
            newLine.closed = value;
            updateLineFirebase(newLine, this.props.promKey, this.props.lineKey);
            Mixpanel.track("Lock", {
              Type: "Line",
              Action: value ? "Close" : "Open"
            });
          }}
          iconButton={null}
          underlineStyle={{ borderColor: "transparent" }}
        >
          <MenuItem
            leftIcon={openLock}
            value={false}
            children={"This line is open"}
            label={dummyOpenLabel}
          />
          <MenuItem
            leftIcon={closedLock}
            value={true}
            children={"This line is closed"}
            label={dummyClosedLabel}
          />
        </Select> */}
        <DataTable title="Static Fields" data={staticFields} />
        <Subheader style={styles.subheader}>{`${type} Fields`}</Subheader>
        {typeFieldsJSX}
      </div>
    );
  }
}
