import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";

import Mixpanel from "helpers/Mixpanel";
import PromProfile from "../PromProfile";

const styles = {
  calendarFull: {
    marginTop: 40,
    marginLeft: 100,
    marginRight: 100,
    marginBottom: 50,
    fontFamily: "PT Sans"
  }
};

class MonthView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={styles.calendarFull}>
        <FullCalendar
          defaultView="dayGridMonth"
          events={this.props.events}
          calendarDate={this.props.calendarDate}
          firstDay={1}
          header={{
            left: "today prev,next",
            center: "title",
            right: "month,basicWeek,listMonth"
          }}
          plugins={[dayGridPlugin, interactionPlugin]}
          eventLimit={5}
          eventClick={(calEvent, jsEvent, view) => {
            this.props.openClose.showRightDrawer(
              <PromProfile
                promKey={calEvent.event.extendedProps.promKey}
                openClose={this.props.openClose}
                db={this.props.db}
              />
            );
            Mixpanel.track("Promotion Viewed", {
              View: "Planning",
              Interface: "Calendar",
              Component: "MonthView"
            });
          }}
        />
      </div>
    );
  }
}

export default MonthView;
