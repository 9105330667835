import React from "react";
import { AllDetailFields, EditableRow, Option } from "../types";
import { isNumeric, renderDate } from "../utilities";
import { EditableCell } from "./EditableCell";
import { OverrideType } from "../api";

export const CELL_HEIGHT = 50;
export const CELL_WIDTH = 120;
export const HEADER_COLOR = "#fff";
export const LINE_COLOR = "rgba(0, 0, 0, 0.25)";

export const BASE_CELL: React.CSSProperties = {
  display: "table-cell",
  padding: "8px",
  background: "white",
  color: "black",
  textAlign: "right",
  minWidth: CELL_WIDTH,
  minHeight: CELL_HEIGHT,
  width: CELL_WIDTH,
  height: CELL_HEIGHT,
  position: "relative"
};

export const CELL_VERTICAL_LINE: React.CSSProperties = {
  position: "absolute",
  height: "100%",
  width: "1px",
  background: LINE_COLOR,
  top: 0
};

const EDITABLE_METRICS: AllDetailFields[] = [
  "stores",
  "velocity",
  "base_sales"
];

export const CELL_HORIZONTAL_LINE: React.CSSProperties = {
  position: "absolute",
  width: "100%",
  height: "1px",
  background: LINE_COLOR,
  left: 0
};

const VERTICAL_DIVIDER = (
  <div
    style={{
      ...CELL_VERTICAL_LINE,
      right: 0
    }}
  />
);

export const STICKY_CELL: React.CSSProperties = {
  position: "sticky",
  zIndex: 1
};

const CURRENCY_FORMATTER = new Intl.NumberFormat("en", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0
});

export const renderCellValue = (
  value: Option<number | string | boolean>,
  type?: string
) => {
  if (value === undefined || value === null) {
    return "-";
  }

  if (typeof value === "boolean") {
    return value ? "Y" : "N";
  }

  if (typeof value === "number") {
    if (type === "revenue") {
      return CURRENCY_FORMATTER.format(value);
    }

    return value.toLocaleString("en-US", { maximumFractionDigits: 2 });
  }

  return value;
};

export const bg = (i: number) => {
  return i % 2 === 0 ? "#f3f3f8" : "white";
};

interface DetailCellProps {
  value: string;
  columnIndex: number;
  style: React.CSSProperties;
  editableRow?: EditableRow;
  forecastBegins?: Date;
  field?: AllDetailFields;
  avf?: boolean;
}
export const DetailCell = ({
  value,
  columnIndex,
  editableRow,
  forecastBegins,
  field,
  avf,
  style
}: DetailCellProps) => {
  const getEditableCellOrValue = () => {
    if (!editableRow) return value;
    const {
      dates,
      customer,
      product,
      overrides,
      companyid,
      version,
      del,
      update,
      refetch
    } = editableRow;
    const date = dates[columnIndex];
    const week = renderDate(date);
    const isActual = forecastBegins ? forecastBegins > date : false;
    const isEditableMetric = field && EDITABLE_METRICS.includes(field);
    const isEditableCell = isEditableMetric && !isActual;
    if (!isEditableCell) return value;
    const showDel = overrides.some(
      override =>
        customer === override.customer &&
        product === override.product &&
        week === override.week &&
        version === override.version &&
        field &&
        override[field] != null
    );

    return (
      <EditableCell
        showDel={showDel}
        value={isNumeric(value) ? value : "0"}
        update={async (v: string) => {
          await update({
            data: {
              [field]: parseFloat(v),
              week,
              companyid,
              version,
              customer,
              product
            }
          });
          refetch();
        }}
        del={async () => {
          await del({
            data: {
              week,
              companyid,
              version,
              customer,
              product,
              override_type: OverrideType[field]
            }
          });
          refetch();
        }}
      />
    );
  };

  return (
    <td style={style}>
      {getEditableCellOrValue()}
      {!avf && VERTICAL_DIVIDER}
    </td>
  );
};
