import { cloneDeep } from "lodash";
import { PDFDocument } from "pdf-lib";
import { DbContextValues } from "contexts/Db";

import { generateRandomKey } from "helpers/Firebase";
import {
  uploadSteps,
  UploadBackupState,
  AssignPagesErrorObject,
  EnterMetadataErrorObject,
  initialUploadBackupState
} from "./definitions";

export const MIN_PAGE_NUMBER = 1;
export const INTEGER_REGEX = /^[\s]*[0-9\b]*$/;
export const DOLLAR_REGEX = /^[\s]*\-?[0-9]*.?[0-9]?[0-9]?$/;

// TODO [DRM-613]: Many functions here are Firebase specific / need to be refactored as part of our migration to Postgres.
// TODO [DRM-613]: Need to add types and limit the use of `any`.

export function partition(array, filter): any[][] {
  const pass = [];
  const fail = [];

  array.forEach((elem, idx, arr) =>
    (filter(elem, idx, arr) ? pass : fail).push(elem)
  );

  return [pass, fail];
}

export function checkValidIndex(newObjects: any[], objectIdx: number): boolean {
  if (!newObjects) {
    return false;
  }
  if (objectIdx < 0 || objectIdx >= newObjects.length) {
    return false;
  }

  return true;
}

export function isFilePDFType(fileName: string): boolean {
  return fileName.split(".").pop().toLowerCase() === "pdf";
}

async function getArrayBufferPageCount(fileData: string | ArrayBuffer) {
  const pdfDoc = await PDFDocument.load(fileData, { ignoreEncryption: true });
  return pdfDoc.getPageCount();
}

export async function getAllFilePageCounts(files: File[]) {
  const filePageCounts = await Promise.all(
    files.map(file => {
      if (isFilePDFType(file.name)) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onload = async e => {
            const fileData = e.target.result;
            resolve(await getArrayBufferPageCount(fileData));
          };

          reader.readAsArrayBuffer(file);
        });
      }
      return new Promise((resolve, _reject) => {
        resolve(null);
      });
    })
  );

  return filePageCounts;
}

// returns if a value is unique as a property among a list of objects
export function checkUniqueField(
  allObjectsArray: any[],
  field: string,
  value: any
) {
  const allFieldValues = allObjectsArray.map(obj => obj[field]);
  return !allFieldValues.includes(value);
}

export function convertArrayToObject(array: any[]) {
  return array.reduce((prevObject, currentItem) => {
    let key = null;
    let unique = false;
    while (!unique) {
      key = generateRandomKey();
      unique = !(key in prevObject);
    }

    return { ...prevObject, [key]: currentItem };
  }, {});
}

export function getFileNameWithoutExtension(fileName: string) {
  let lastDot = fileName.lastIndexOf(".");
  if (lastDot < 0) lastDot = fileName.length;
  return fileName.substring(0, lastDot);
}

export function createNewKeysForArray(
  newItemArray: any[],
  existingItems: { [key: string]: any }
) {
  return newItemArray.reduce((createdKeys, _newItem) => {
    let key = null;
    let unique = false;
    while (!unique) {
      key = generateRandomKey();
      unique = !(createdKeys.includes(key) || key in existingItems);
    }
    return [...createdKeys, key];
  }, []);
}

// ALL HANDLE GO-TO-NEXT-STEP REDUCES

export function handleNextToEnterMetadata(
  state: UploadBackupState
): UploadBackupState {
  const { errors } = state;
  const { uploadFiles, assignPages } = errors;

  // if no files uploaded
  if (uploadFiles[0].noFilesUploaded) {
    return {
      ...state,
      showErrors: true
    };
  }

  // if any outstanding errors
  const shouldShowErrors = assignPages
    .map((errorObject: AssignPagesErrorObject) => {
      return Object.values(errorObject).includes(true);
    })
    .includes(true);

  return {
    ...state,
    errors: {
      ...errors,
      uploadFiles: [
        {
          duplicateFileNames: [], // reset these
          duplicateFilesDetected: false,
          noFilesUploaded: false
        }
      ]
    },
    currentUploadStep: shouldShowErrors
      ? uploadSteps.ADD_FILES_AND_ASSIGN_PAGES
      : uploadSteps.ADD_METADATA,
    viewedFileIdx: null,
    showErrors: shouldShowErrors
  };
}

// TODO [DRM-613]: This helper function is only used in one place so the logic should be in the reducer, not here.
export function handleNextToAttemptSave(
  state: UploadBackupState,
  db: DbContextValues
): UploadBackupState {
  const { errors } = state;
  const { enterMetadata } = errors;

  // if any outstanding errors
  const shouldShowErrors = enterMetadata
    .map((errorObject: EnterMetadataErrorObject) => {
      return Object.values(errorObject).includes(true);
    })
    .includes(true);

  return {
    ...state,
    currentUploadStep: shouldShowErrors
      ? uploadSteps.ADD_METADATA
      : uploadSteps.UPLOADING,
    viewedFileIdx: null,
    showErrors: shouldShowErrors,
    uploadError: false
  };
}

// TODO [DRM-613]: This helper function is only used in one place so the logic should be in the reducer, not here.
export function handleNextToCompleteUpload(
  state: UploadBackupState,
  db: DbContextValues
): UploadBackupState {
  console.log("RESETTING");
  console.log(initialUploadBackupState);
  return {
    ...cloneDeep(initialUploadBackupState),
    currentUploadStep: uploadSteps.DONE
  };
}
