import React, { Suspense } from "react";
import axios from "axios";
import NavigationPrompt from "react-router-navigation-prompt";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { Provider } from "react-redux";
import { Route, Redirect, Switch } from "react-router-dom";
import classNames from "classnames";
import { debounce, memoize } from "lodash";
import { MUILicenseKey } from "config";
import isObject from "lodash/isObject";

import * as FullStory from "@fullstory/browser";
import Mixpanel from "helpers/Mixpanel";
import { arrayify } from "helpers/DataProcessing";

import CircularProgress from "@mui/material/CircularProgress";
import Drawer from "ui-library/Drawer";
import Dialog from "ui-library/Dialog";
import Button from "ui-library/Button";
import MinimizedToolbar from "components/WebsiteElements/MinimizedToolbar";
import { Snackbar } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";
import { Worker } from "@react-pdf-viewer/core";
import * as Sentry from "@sentry/browser";
import LogRocket from "logrocket";
import DRMEventService from "components/Deductions/DeductionsReconciliation/ActivityLog/DRMEventService";
import AdminPanelActivityLogService from "components/Settings/AdminPanel/AdminPanelActivityLogService";
import { DbContext } from "contexts/Db";

import { OpenCloseContext } from "contexts/OpenClose";
import {
  getAdminPanelEventsFirebase,
  getArchiveMetadata,
  getCompanyNamesFirebase,
  getCompanyUsers,
  getFirebase,
  getLinesFirebase,
  getMetaFirebase,
  getPermissions,
  getPromotionsFirebase,
  getSoftwareVersionFirebase,
  getSoftwareVersionFirebaseOnce,
  isEmailValid,
  firebase,
  cloudRunFunctionURL,
  isUserCresicorEmployee
} from "helpers/Firebase";
import { sortObjByDate } from "helpers/sortByDate";
import {
  isForecastEnabled,
  isForecastv2FeatureGateEnabled
} from "helpers/Misc";
import { proTips } from "helpers/ProTips";
import { liftEnabled } from "helpers/Permissions";
import { StylesProvider, createGenerateClassName } from "@mui/styles";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { DRMEventsContext } from "contexts/DRMEvents";
import { grey } from "@mui/material/colors";
import VividlyLoader from "helpers/VividlyLoader";
import DropDownWindow from "./WebsiteElements/DropDownWindow";
import Login from "./WebsiteElements/Login";
import AuthenticationHandling from "./AuthenticationHandling/AuthenticationHandling";
import { HeaderSlot } from "./WebsiteElements/SideBar";
import store from "../store";
import InvoiceProfilePage from "./Deductions/DeductionsReconciliation/ViewData/ViewBackup/InvoiceProfile/InvoiceProfilePage";
import TransactionProfilePage from "./Deductions/DeductionsReconciliation/ViewData/ViewTransactions/TransactionProfile/TransactionProfilePage";
import ReconciliationToolbar from "./Deductions/DeductionsReconciliation/DeductionsReconciliationToolbar";
import { DRMFeature } from "./Deductions/DeductionsReconciliation/redux/DRMSlice";
import Invalid from "./WebsiteElements/Invalid";
import { CONTENT_OVERFLOW_OFFSET, CONTENT_PADDING } from "./appConstants";
import HeaderSidebarContainer from "./WebsiteElements/HeaderSidebarContainer";

LicenseInfo.setLicenseKey(MUILicenseKey);

const grey200 = grey["200"];
const grey700 = grey["700"];

const generateClassName = createGenerateClassName({
  // By enabling this option, if you have non-MUI elements (e.g. `<div />`)
  // using MUI classes (e.g. `.MuiButton`) they will lose styles.
  // Make sure to convert them to use `styled()` or `<Box />` first.
  disableGlobal: true,
  // Class names will receive this seed to avoid name collisions.
  seed: "mui-jss"
});

function retry(fn, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            console.error(error);
            reject(error);
            return;
          }
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

const Accruals = React.lazy(() => retry(() => import("./Analytics/Accruals")));
const AllNotifications = React.lazy(() =>
  retry(() => import("./WebsiteElements/AllNotifications"))
);
const Analytics = React.lazy(() =>
  retry(() => import("./Analytics/Analytics"))
);
const Brokers = React.lazy(() => retry(() => import("./Brokers/Brokers")));
const Budget = React.lazy(() => retry(() => import("./Budget/Budget")));
const Contacts = React.lazy(() => retry(() => import("./Contacts/Contacts")));
const CustomerPnl = React.lazy(() =>
  retry(() => import("./Customers/CustomerPnl"))
);
const Customers = React.lazy(() =>
  retry(() => import("./Customers/Customers"))
);
const Dashboard = React.lazy(() =>
  retry(() => import("./WebsiteElements/Dashboard"))
);
const DistributorReports = React.lazy(() =>
  retry(() => import("./Brokers/DistributorReports"))
);
const Forecast = React.lazy(() => retry(() => import("./Forecast/Forecast")));
const ForecastRs = React.lazy(() => retry(() => import("./ForecastRs")));
const EditForecast = React.lazy(() =>
  retry(() => import("./ForecastRs/EditForecast"))
);
const Planning = React.lazy(() => retry(() => import("./Planning/Planning")));
const Pricing = React.lazy(() => retry(() => import("./Products/Pricing")));
const Lift = React.lazy(() => retry(() => import("./Products/Lift")));
const ProductGroups = React.lazy(() =>
  retry(() => import("./Products/ProductGroups"))
);
const Products = React.lazy(() => retry(() => import("./Products/Products")));
const PromotionTimingAnalysis = React.lazy(() =>
  retry(() => import("./Analytics/PromotionTimingAnalysis"))
);
const RegionMapping = React.lazy(() =>
  retry(() => import("./Brokers/RegionMapping"))
);
const Revenue = React.lazy(() => retry(() => import("./RevDed/Revenue")));
const Referrals = React.lazy(() =>
  retry(() => import("./WebsiteElements/Referrals"))
);
const ScanSettings = React.lazy(() =>
  retry(() =>
    import("./Deductions/DeductionsProcessing/ScanSettings/ScanSettings")
  )
);
const Settings = React.lazy(() => retry(() => import("./Settings/Settings")));
const Spend = React.lazy(() => retry(() => import("./RevDed/Spend")));
const DeductionsReconciliation = React.lazy(() =>
  retry(() =>
    import("./Deductions/DeductionsReconciliation/DeductionsReconciliation.tsx")
  )
);
const DeductionsProcessing = React.lazy(() =>
  retry(() => import("./Deductions/DeductionsProcessing/DeductionsProcessing"))
);

const styles = {
  loading: {
    height: $(window).height()
  },
  dialogRoot: {
    zIndex: 1300
  },
  mainContainer: {
    display: "flex"
  },
  leftContainer: {
    flexGrow: 1,
    overflow: "scroll"
  }
};

function ErrorFallback({ error }) {
  return (
    <div
      style={{
        position: "absolute",
        marginTop: "25%",
        marginLeft: "50%",
        transform: "translate(-50%, -50%)",
        fontFamily: "Oxygen"
      }}>
      <div className="centering">
        <font size="+1" color="#0000BF">
          Vividly encountered an error. Sorry about that! Please Shift + Reload
          to continue using the system.
        </font>
      </div>
      <br />
      <div className="centering">Contact support if the issue persists!</div>
    </div>
  );
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auth_received: false,
      logged: true,
      rightDrawerOpen: false,
      rightDrawerStyles: {},
      sideBar: false,
      dropDownWindowOpen: false,
      minimizedToolbarOpen: false,
      snack: false,
      user: {},
      unvalidated: false,
      uploadComplete: true,
      modalOpen: false,
      minimizedDropDownJSX: null,
      archiveMetadata: {},
      archiveKey: "cresicor",
      promotions: {},
      promotionInvoices: {},
      products: {},
      customers: {},
      brokers: {},
      contacts: {},
      actMoney: {},
      allLines: {},
      pricing: {},
      lift: {},
      reports: {},
      revenue: {},
      invoices: {},
      erpTransactions: {},
      drmEvents: {},
      meta: {},
      permissions: [],
      companyUsers: {},
      promotion_spend: {},
      modalStep: null,
      statusUpdated: null,
      outOfDate: false,
      loginDone: true
    };
    this.statusUpdated = null;
    this.proTip = proTips[Math.floor(Math.random() * proTips.length)];
    this.contentRef = React.createRef();
  }

  toggleSideBar = () => {
    this.setState({
      sideBar: !this.state.sideBar
    });
  };

  showSnack = message => {
    this.setState({
      snack: true,
      snackMessage: message
    });
  };

  hideSnack = () => {
    this.setState({
      snack: false
    });
  };

  showOrHideRightDrawer = debounce((state, jsx, customStyles) => {
    if (state) {
      this.setState({
        rightDrawerOpen: true,
        rightDrawerJSX: jsx,
        rightDrawerStyles: customStyles
      });
    } else {
      this.setState({
        rightDrawerOpen: false
      });
    }
  }, 500);

  showRightDrawer = (jsx, customStyles = {}) => {
    this.showOrHideRightDrawer(true, jsx, customStyles);
  };

  hideRightDrawer = () => {
    this.showOrHideRightDrawer(false);
    // reset to original drawer settings
    this.setState({
      rightDrawerStyles: {
        overflow: "hidden",
        position: "relative",
        zIndex: 1300
      }
    });
  };

  defaultDrawerChange = open => {
    this.setState({
      rightDrawerOpen: open
    });
  };

  onDrawerClose = () => {
    if (this.state.onDrawerClose) {
      this.state.onDrawerClose();
    } else {
      this.defaultDrawerChange(false);
    }
  };

  setDrawerClose = onDrawerClose => {
    this.setState({
      onDrawerClose
    });
  };

  showDropDownWindow = (jsx, onCloseCallback) => {
    this.setState({
      dropDownWindowRendered: true,
      dropDownWindowOpen: true,
      dropDownJSX: jsx,
      onCloseCallback,
      minimizedToolbarJSX: null
    });
  };

  showStealthDropDownWindow = (jsx, onCloseCallback) => {
    this.setState({
      dropDownWindowRendered: true,
      dropDownWindowOpen: false,
      dropDownJSX: jsx,
      onCloseCallback,
      minimizedToolbarJSX: null
    });
  };

  setDropDownWindowVisible = isVisible => {
    this.setState({
      dropDownWindowOpen: isVisible
    });
  };

  reopenMinimizedDropDownWindow = () => {
    this.setState({
      dropDownWindowOpen: true,
      minimizedToolbarOpen: false
    });
  };

  minimizeDropDownWindow = () => {
    this.setState({
      dropDownWindowOpen: false,
      minimizedToolbarOpen: true
    });
  };

  closeDropDownWindow = () => {
    this.setState({
      dropDownWindowRendered: false,
      dropDownWindowOpen: false,
      minimizedToolbarOpen: false,
      minimizedToolbarJSX: null,
      onCloseCallback: null
    });
  };

  openWindow = windowType => {
    const update = {
      minimizedToolbarOpen: false
    };
    if (windowType == "modal") {
      update.modalOpen = true;
    } else {
      update.dropDownWindowOpen = true;
    }
    this.setState(update);
  };

  minimizeWindow = windowType => {
    const update = {
      minimizedToolbarOpen: true,
      minimizedObj: windowType
    };
    if (windowType == "modal") {
      update.modalOpen = false;
    } else {
      update.dropDownWindowOpen = false;
    }
    this.setState(update);
  };

  closeWindow = (windowType, staticModal, event, reason) => {
    if (reason === "backdropClick" && staticModal) {
      return null;
    }
    const update = {
      minimizedToolbarOpen: false
    };
    if (windowType == "modal") {
      update.modalOpen = false;
    } else {
      update.dropDownWindowOpen = false;
      update.dropDownWindowRendered = false;
    }
    this.setState(update, () => {
      if (this.state.onCloseCallback) {
        this.state.onCloseCallback();
      }
    });
    return null;
  };

  setMinimizedWindowToolbar = minimizedToolbarJSX => {
    this.setState({
      minimizedToolbarJSX
    });
  };

  // TODO: Change all implementations to object based params
  setAppModal = (
    title,
    content,
    actions,
    staticModal,
    isFullWidth = false,
    maxWidth = "sm",
    backgroundColor = "white"
  ) => {
    if (isObject(title) && !!title.content) {
      this.setState({
        modalOpen: true,
        modalTitle: title.title,
        modalActions: title.actions,
        modalContentJSX: title.content,
        isFullWidth: title.isFullWidth,
        maxWidth: title.maxWidth,
        staticModal: title.staticModal,
        backgroundColor: title.backgroundColor
      });
    } else {
      this.setState({
        modalOpen: true,
        modalTitle: title,
        modalActions: actions,
        modalContentJSX: content,
        isFullWidth,
        maxWidth,
        staticModal,
        backgroundColor
      });
    }
  };

  checkStatusUpdated = () => {
    if (!this.internetChecked || Date.now() - this.internetChecked > 5000) {
      this.checkInternet();
    }

    if (this.statusUpdated && Date.now() - this.statusUpdated > 500) {
      this.statusUpdated = null;
      this.setState({ statusUpdated: null, promotions: this.proms });
    }

    if (this.state.softwareVersionOnLoad !== this.state.softwareVersion) {
      this.setAppModal(
        "Hard Refresh Required",
        <div>
          Your version of Vividly is out of date.
          <br />
          Please save your work, then perform a hard refresh by holding Shift
          then clicking the Reload button in your browser.
        </div>,

        <div>
          <Button
            label="Ok"
            style={{ color: grey700, margin: 16 }}
            onClick={this.closeWindow.bind(null, "modal")}
          />
        </div>
      );
      this.setState({
        softwareVersionOnLoad: this.state.softwareVersion,
        outOfDate: true
      });
    }
  };

  resetTimeout = () => {
    const logoutTime = this.state.meta.logoutTime
      ? 1000 * 60 * this.state.meta.logoutTime
      : 1000 * 60 * 30;
    const warningTime = logoutTime - 1000 * 60;

    if (this.warnTimeout) clearTimeout(this.warnTimeout);
    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);

    this.warnTimeout = setTimeout(this.warnLogout, warningTime);
    this.logoutTimeout = setTimeout(this.logout, logoutTime);
  };

  warnLogout = () => {
    if (this.state.logged)
      this.showSnack(
        'You will be logged out soon due to inactivity. Click "OK" to stay logged in.'
      );
  };

  logout = () => {
    if (this.state.logged) {
      firebase
        .auth()
        .signOut()
        .then(
          () => {
            this.showSnack("You have been logged out due to inactivity.");
          },
          error => {
            // Nothing
          }
        );
    }
  };

  checkInternet = () => {
    fetch("//google.com", {
      mode: "no-cors"
    })
      .then(() => {
        if (this.state.internetUnstable) {
          this.setState({ internetUnstable: false });
        }
        this.internetChecked = Date.now();
      })
      .catch(() => {
        if (!this.state.internetUnstable) {
          this.showSnack("Your Internet connection is unstable.");
          this.setState({ internetUnstable: true });
        }
      });
  };

  trackPageView = path => {
    const eventMap = {
      "/": {
        Category: "Home",
        View: "Home"
      },
      "/planning": {
        Category: "Promotions",
        View: "Planning",
        Interface: "Columns"
      },
      "/execution": {
        Category: "Promotions",
        View: "Execution"
      },
      "/closed": {
        Category: "Promotions",
        View: "Closed"
      },
      "/declined": {
        Category: "Promotions",
        View: "Declined"
      },
      "/cancelled": {
        Category: "Promotions",
        View: "Cancelled"
      },
      "/contacts": {
        Category: "Manage",
        View: "Contacts"
      },
      "/customers": {
        Category: "Manage",
        View: "Customers"
      },
      "/brokers": {
        Category: "Commissions",
        View: "Brokers"
      },
      "/reports": {
        Category: "Commissions",
        View: "Distributor Reports"
      },
      "/analytics": {
        Category: "Insights",
        View: "Promotion Analytics"
      },
      "/finance": {
        Category: "Insights",
        View: "Finance Analytics"
      },
      "/accruals": {
        Category: "Insights",
        View: "Accruals"
      },
      "/timing": {
        Category: "Insights",
        View: "Promotion Timing Analysis"
      },
      "/forecast": {
        Category: "Insights",
        View: "Forecast"
      },
      "/notifications": {
        Category: "Other",
        View: "Notifications"
      },
      "/products": {
        Category: "Manage",
        View: "Products"
      },
      "/product_groups": {
        Category: "Manaage",
        View: "Product Groups"
      },
      "/pricing": {
        Category: "Manage",
        View: "Pricing"
      },
      "/lift": {
        Category: "Manage",
        View: "Lift"
      },
      "/settings": {
        Category: "Other",
        View: "Settings"
      },
      "/revenue": {
        Category: "Business",
        View: "Revenue Dollars"
      },
      "/spend": {
        Category: "Business",
        View: "Deductions Spend"
      },
      "/reconciliation": {
        Category: "Business",
        View: "Deductions Reconciliation"
      },
      "/backup_retrieval": {
        Category: "Business",
        View: "Backup Retrieval"
      },
      "/budget": {
        Category: "Business",
        View: "Budgeting"
      },
      "/region_mapping": {
        Category: "Business",
        View: "Region Mapping"
      },
      "/scan_and_match": {
        Category: "Business",
        View: "Deductions Scanner"
      },
      "/referrals": {
        Category: "Business",
        View: "Referrals"
      }
    };

    const eventProps = eventMap[path] || {
      "Bad URL": path
    };
    if (Mixpanel) {
      Mixpanel.track("Page View", eventProps);
    }
  };

  manageRoutes = (loadingScreen, childProps) => {
    const allPermissions = this.getAllPermissions();
    const viewBusiness = allPermissions.has("viewBusiness");
    const viewManage = allPermissions.has("viewManage");
    const viewCommissions = allPermissions.has("viewCommissions");
    const viewInsights = allPermissions.has("viewInsights");

    const routeInvalid = [
      <Route
        path="/*"
        render={() => (
          <Suspense fallback={loadingScreen}>
            <Invalid />
          </Suspense>
        )}
      />
    ];

    let accessibleRoutes = [];
    if (this.state.meta.cresicor_tpm) {
      const routesNoPermission = [
        <Route
          exact
          path="/"
          render={() => (
            <Suspense fallback={loadingScreen}>
              <Dashboard {...childProps} />
            </Suspense>
          )}
        />,
        <Route
          key="planning-1"
          path="/planning/:key?"
          render={routeProps => (
            <Suspense fallback={loadingScreen}>
              <Planning {...routeProps} {...childProps} />
            </Suspense>
          )}
        />,
        <Route
          key="planning-2"
          path="/execution/:key?"
          render={routeProps => (
            <Suspense fallback={loadingScreen}>
              <Planning
                mode={1}
                interface={1}
                additionalSearch={{ status: 4 }}
                {...routeProps}
                {...childProps}
              />
            </Suspense>
          )}
        />,
        <Route
          key="planning-3"
          path="/closed/:key?"
          render={routeProps => (
            <Suspense fallback={loadingScreen}>
              <Planning
                mode={2}
                interface={1}
                additionalSearch={{ closed: true }}
                {...routeProps}
                {...childProps}
              />
            </Suspense>
          )}
        />,
        <Route
          key="planning-4"
          path="/declined/:key?"
          render={routeProps => (
            <Suspense fallback={loadingScreen}>
              <Planning
                mode={3}
                interface={1}
                additionalSearch={{ status: 6 }}
                {...routeProps}
                {...childProps}
              />
            </Suspense>
          )}
        />,
        <Route
          key="planning-5"
          path="/cancelled/:key?"
          render={routeProps => (
            <Suspense fallback={loadingScreen}>
              <Planning
                mode={4}
                interface={1}
                additionalSearch={{ status: 7 }}
                {...routeProps}
                {...childProps}
              />
            </Suspense>
          )}
        />,
        <Route
          path="/notifications"
          render={() => (
            <Suspense fallback={loadingScreen}>
              <AllNotifications {...childProps} />
            </Suspense>
          )}
        />,
        <Route
          path="/settings"
          render={() => (
            <Suspense fallback={loadingScreen}>
              <Settings {...childProps} />
            </Suspense>
          )}
        />,
        <Route
          path="/referrals"
          render={() => (
            <Suspense fallback={loadingScreen}>
              <Referrals {...childProps} />
            </Suspense>
          )}
        />
      ];

      const routesViewManage = [
        <Route
          path="/products/:key?"
          render={routeProps => (
            <Suspense fallback={loadingScreen}>
              <Products {...routeProps} {...childProps} />
            </Suspense>
          )}
        />,
        <Route
          path="/product_groups"
          render={() => (
            <Suspense fallback={loadingScreen}>
              <ProductGroups {...childProps} />
            </Suspense>
          )}
        />,
        <Route
          path="/pricing"
          render={() => (
            <Suspense fallback={loadingScreen}>
              <Pricing {...childProps} />
            </Suspense>
          )}
        />,
        ...(childProps.db && liftEnabled(childProps.db)
          ? [
              <Route
                path="/lift"
                render={() => (
                  <Suspense fallback={loadingScreen}>
                    <Lift {...childProps} />
                  </Suspense>
                )}
              />
            ]
          : []),
        <Route
          path="/contacts/:key?"
          render={routeProps => (
            <Suspense fallback={loadingScreen}>
              <Contacts {...routeProps} {...childProps} />
            </Suspense>
          )}
        />,
        <Route
          path="/customers/:key?"
          render={routeProps => (
            <Suspense fallback={loadingScreen}>
              <Customers {...routeProps} {...childProps} />
            </Suspense>
          )}
        />
      ];

      const routesViewCommissions = [
        ...(childProps.db?.meta.broker_commissions
          ? [
              <Route
                path="/brokers/:key?"
                render={routeProps => (
                  <Suspense fallback={loadingScreen}>
                    <Brokers {...routeProps} {...childProps} />
                  </Suspense>
                )}
              />,
              <Route
                path="/reports"
                render={() => (
                  <Suspense fallback={loadingScreen}>
                    <DistributorReports {...childProps} />
                  </Suspense>
                )}
              />
            ]
          : [])
      ];

      const routesViewInsights = [
        ...(childProps.db?.meta.tier !== "pine"
          ? [
              <Route
                path="/analytics"
                render={() => (
                  <Suspense fallback={loadingScreen}>
                    <Analytics {...childProps} />
                  </Suspense>
                )}
              />,
              <Route
                path="/timing"
                render={() => (
                  <Suspense fallback={loadingScreen}>
                    <PromotionTimingAnalysis {...childProps} />
                  </Suspense>
                )}
              />
            ]
          : []),
        ...(childProps.db?.meta.tier === "aspen"
          ? [
              <Route
                path="/finance"
                render={() => (
                  <Suspense fallback={loadingScreen}>
                    <CustomerPnl {...childProps} />
                  </Suspense>
                )}
              />,
              <Route
                path="/accruals"
                render={() => (
                  <Suspense fallback={loadingScreen}>
                    <Accruals {...childProps} />
                  </Suspense>
                )}
              />
            ]
          : []),
        ...(isForecastEnabled(childProps.db) &&
        !isForecastv2FeatureGateEnabled(childProps.db)
          ? [
              <Route
                path="/forecast"
                render={() => (
                  <Suspense fallback={loadingScreen}>
                    <Forecast {...childProps} />
                  </Suspense>
                )}
              />
            ]
          : []),
        ...(isForecastEnabled(childProps.db) &&
        isForecastv2FeatureGateEnabled(childProps.db)
          ? [
              <Route
                path="/forecast2/manage/"
                render={() => (
                  <Suspense fallback={loadingScreen}>
                    <EditForecast />
                  </Suspense>
                )}
              />,
              <Route
                path="/forecast2/:type?/:filter?"
                render={() => (
                  <Suspense fallback={loadingScreen}>
                    <ForecastRs />
                  </Suspense>
                )}
              />
            ]
          : [])
      ];

      const routesViewBusiness = [
        ...(childProps.db?.meta.tier !== "pine"
          ? [
              <Route
                path="/revenue/:key?"
                render={routeProps => (
                  <Suspense fallback={loadingScreen}>
                    <Revenue {...routeProps} {...childProps} />
                  </Suspense>
                )}
              />,
              <Route
                path="/spend/:key?"
                render={routeProps => (
                  <Suspense fallback={loadingScreen}>
                    <Spend {...routeProps} {...childProps} />
                  </Suspense>
                )}
              />,
              <Route
                path="/dispute"
                render={() => (
                  <Suspense fallback={loadingScreen}>
                    <DeductionsDispute {...childProps} />
                  </Suspense>
                )}
              />
            ]
          : []),
        ...(!childProps.db?.meta.deductions_reconciliation &&
        childProps.db?.meta.backup_retrieval
          ? [
              <Route
                exact
                path={`/${DRMFeature.BACKUP_RETRIEVAL}/invoice/:key`}
                render={() => (
                  <>
                    <Suspense fallback={loadingScreen}>
                      <InvoiceProfilePage {...childProps} />
                    </Suspense>
                  </>
                )}
              />,
              <Route
                path={`/${DRMFeature.BACKUP_RETRIEVAL}`}
                render={() => (
                  <>
                    <ReconciliationToolbar
                      rootFeature={DRMFeature.BACKUP_RETRIEVAL}
                    />
                    <Suspense fallback={loadingScreen}>
                      <DeductionsReconciliation
                        rootFeature={DRMFeature.BACKUP_RETRIEVAL}
                        {...childProps}
                      />
                    </Suspense>
                  </>
                )}
              />
            ]
          : []),
        ...(childProps.db?.meta.deductions_reconciliation
          ? [
              <Route
                exact
                path={`/${DRMFeature.RECONCILIATION}/invoice/:key`}
                render={() => (
                  <>
                    <Suspense fallback={loadingScreen}>
                      <InvoiceProfilePage {...childProps} />
                    </Suspense>
                  </>
                )}
              />,
              <Route
                exact
                path={`/${DRMFeature.RECONCILIATION}/transaction/:key`}
                render={() => (
                  <>
                    <Suspense fallback={loadingScreen}>
                      <TransactionProfilePage {...childProps} />
                    </Suspense>
                  </>
                )}
              />,
              <Route
                path={`/${DRMFeature.RECONCILIATION}`}
                render={() => (
                  <>
                    <ReconciliationToolbar
                      rootFeature={DRMFeature.RECONCILIATION}
                    />
                    <Suspense fallback={loadingScreen}>
                      <DeductionsReconciliation
                        rootFeature={DRMFeature.RECONCILIATION}
                        {...childProps}
                      />
                    </Suspense>
                  </>
                )}
              />
            ]
          : []),
        ...(childProps.db?.meta.cresicor_budgeting
          ? [
              <Route
                path="/budget"
                render={() => (
                  <Suspense fallback={loadingScreen}>
                    <Budget {...childProps} />
                  </Suspense>
                )}
              />
            ]
          : []),
        ...(childProps.db?.meta.tier !== "pine"
          ? [
              <Route
                path="/region_mapping"
                render={() => (
                  <Suspense fallback={loadingScreen}>
                    <RegionMapping {...childProps} />
                  </Suspense>
                )}
              />
            ]
          : []),
        ...(childProps.db?.meta.cresicor_deductions_scanning
          ? [
              <Route
                path="/scan_and_match"
                render={() => (
                  <Suspense fallback={loadingScreen}>
                    <DeductionsProcessing {...childProps} standalone={false} />
                  </Suspense>
                )}
              />
            ]
          : [])
      ];

      accessibleRoutes = accessibleRoutes
        .concat(routesNoPermission)
        .concat(viewManage ? routesViewManage : [])
        .concat(viewCommissions ? routesViewCommissions : [])
        .concat(viewInsights ? routesViewInsights : [])
        .concat(viewBusiness ? routesViewBusiness : []);
    }

    if (this.state.meta.cresicor_deductions_scanning) {
      const routesNoPermission = [
        <Route
          exact
          path="/"
          render={() => (
            <Suspense fallback={loadingScreen}>
              <DeductionsProcessing {...childProps} standalone />
            </Suspense>
          )}
        />,
        <Route
          path="/settings"
          render={() => (
            <Suspense fallback={loadingScreen}>
              <ScanSettings {...childProps} />
            </Suspense>
          )}
        />,
        <Route
          path="/referrals"
          render={() => (
            <Suspense fallback={loadingScreen}>
              <Referrals {...childProps} />
            </Suspense>
          )}
        />
      ];
      accessibleRoutes = accessibleRoutes.concat(routesNoPermission);
    }

    accessibleRoutes = accessibleRoutes.concat(routeInvalid);
    return <Switch>{accessibleRoutes}</Switch>;
  };

  getAllPermissions = memoize(() => {
    const {
      state: {
        user: { uid: userId },
        companyUsers: { [userId]: { access, customPerms = {} } = {} },
        meta: { permissions: { [access]: defaultPerms = [] } = {} }
      }
    } = this;

    const sanitizedCustomPerms = Object.entries(customPerms)
      .filter(([_, isPermitted]) => isPermitted)
      .map(([permissionType]) => permissionType);
    const allPermissions = new Set([...defaultPerms, ...sanitizedCustomPerms]);

    return allPermissions;
  });

  componentDidMount() {
    window.addEventListener(
      "resize",
      debounce(() => this.setState(this.state), 500)
    );

    store.subscribe(() => {
      const { modalStep: oldStep } = this.state;
      const { stepIndex: newStep } = store.getState();

      if (oldStep !== newStep) {
        this.setState({
          modalStep: newStep
        });
      }
    });

    this.trackPageView(this.props.location.pathname);
    this.interval = setInterval(() => this.checkStatusUpdated(), 500);

    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        const urlpath = window.location.href;
        if (urlpath.indexOf("redirectToReadMe=True") !== -1) {
          const userDetails = {
            name: user.displayName,
            email: user.email
          };
          axios
            .post(`${cloudRunFunctionURL}/api/get_readme_jwt`, {
              data: userDetails
            })
            .then(result => {
              const readMeUrl = result.data.data;
              window.location.replace(readMeUrl);
            });
        }

        if (isUserCresicorEmployee() && urlpath.indexOf("employee=") === -1) {
          const newUrl = `${urlpath}?employee=true`;
          window.location.replace(newUrl);
        } else if (
          !isUserCresicorEmployee() &&
          urlpath.indexOf("employee=") === -1
        ) {
          const newUrl = `${urlpath}?employee=false`;
          window.location.replace(newUrl);
        }
        isEmailValid(
          user,
          userData => {
            const selectedCompany = userData.selectedCompany
              ? userData.selectedCompany
              : 0;
            FullStory.identify(userData.email, {
              displayName: userData.name,
              email: userData.email,
              company: arrayify(userData.company)[selectedCompany]
            });
            const userAgent = navigator.userAgent;
            Mixpanel.register({"User Agent": userAgent})
            if (userAgent.includes("qawolf")) {
              Mixpanel.register({"$ignore": true});
            }
            else if (!userData.email) {
              Mixpanel.register({"$ignore": true});
            }
            Mixpanel.identify(userData.email);
            Mixpanel.people.set({
              $name: userData.name,
              $email: userData.email,
              "User ID": user.uid,
              Company: arrayify(userData.company)[selectedCompany],
              "Company ID": arrayify(userData.cid)[selectedCompany]
            });
            this.setState(
              {
                logged: true,
                auth_received: true,
                user,
                unvalidated: false
              },
              () => this.getAllPermissions.cache.delete()
            );
            if (
              this.state.companyid &&
              this.state.companyid !== arrayify(userData.cid)[selectedCompany]
            ) {
              location.reload(false);
              location.href = "/";
            }
            this.setState({
              companyid: arrayify(userData.cid)[selectedCompany]
            });
            // initialize Sentry
            Sentry.configureScope(scope => {
              scope.setUser(user);
            });
            // initialize LogRocket for only hosted website sessions (beta, portal)
            if (
              window.location.href.includes(firebase.app().options.authDomain)
            ) {
              LogRocket.init("r2ovgn/cresicor");
              LogRocket.identify(user.uid, user);
            }
            // Get all entries from firebase
            getArchiveMetadata(metadata => {
              this.setState({ archiveMetadata: metadata });
            });
            getPromotionsFirebase((promotions, promKey = null) => {
              if (promKey) {
                const proms = this.state.promotions;
                const prom = promotions;
                proms[promKey] = prom;
                this.proms = proms;
                if (!this.statusUpdated) {
                  this.statusUpdated = Date.now();
                  this.setState({ statusUpdated: this.statusUpdated });
                }
              } else {
                this.setState({ promotions, promotionsDone: true });
              }
            });
            getFirebase(1, products => {
              this.setState({ products, productsDone: true });
            });
            getFirebase(2, customers => {
              const customerGroups = {};
              for (const key in customers) {
                const cg = customers[key].pnlGroup;
                if (cg && cg in customerGroups) {
                  customerGroups[cg].push(key);
                } else if (cg) {
                  customerGroups[cg] = [key];
                }
              }
              this.setState({
                customers,
                customerGroups,
                customersDone: true
              });
            });
            getFirebase(3, contacts => {
              this.setState({ contacts, contactsDone: true });
            });
            getFirebase(4, actMoney => {
              this.setState({
                actMoney: sortObjByDate(actMoney, true),
                actMoneyDone: true
              });
            });
            getFirebase(6, revenue => {
              this.setState({
                revenue: sortObjByDate(revenue, true),
                revenueDone: true
              });
            });
            getFirebase(7, pricing => {
              this.setState({ pricing, pricingDone: true });
            });
            getFirebase(8, accounts => {
              this.setState({ accounts, accountsDone: true });
            });
            getFirebase(12, promotion_spend => {
              this.setState({
                promotion_spend,
                promotionSpendDone: true
              });
            });
            getFirebase(14, brokers => {
              this.setState({ brokers, brokersDone: true });
            });
            getFirebase(15, lift => {
              this.setState({ lift, liftDone: true });
            });
            getFirebase(16, reports => {
              this.setState({ reports, reportsDone: true });
            });
            getFirebase(22, dmmOrdering => {
              this.setState({
                dmmOrdering,
                dmmOrderingDone: true
              });
            });
            getFirebase(26, invoices => {
              this.setState({
                invoices,
                invoicesDone: true
              });
            });
            getFirebase(27, erpTransactions => {
              this.setState({
                erpTransactions,
                erpTransactionsDone: true
              });
            });
            getFirebase(29, promotionInvoices => {
              this.setState({
                promotionInvoices,
                promotionInvoicesDone: true
              });
            });
            getFirebase(30, drmEvents => {
              this.setState({
                drmEvents,
                drmEventsDone: true
              });
            });
            getAdminPanelEventsFirebase(adminPanelEvents => {
              this.setState({ adminPanelEvents, adminPanelEventsDone: true });
            });
            getLinesFirebase(allLines => {
              this.setState({ allLines, allLinesDone: true });
            });
            getMetaFirebase(meta => {
              meta.tiers = {
                pine: "Pine",
                "pine-plus": "Pine Plus",
                aspen: "Aspen"
              };
              this.setState({ meta, metaDone: true }, () =>
                this.getAllPermissions.cache.delete()
              );
              FullStory.setUserVars({ tier: meta.tier });
            });
            getPermissions(permissions => {
              this.setState({ permissions });
            });
            // Get company names => get company users
            getCompanyNamesFirebase(companyNames => {
              this.setState({ companyNames, companyNamesDone: true }, () => {
                getCompanyUsers(allCompanyUsers => {
                  // get the archive the current user is in
                  const currentUser = allCompanyUsers[user.uid];
                  let archiveKey = "cresicor";
                  if (currentUser && currentUser.archiveKey) {
                    archiveKey = currentUser.archiveKey;
                  }
                  this.setState(
                    {
                      access: currentUser.access,
                      companyUsers: allCompanyUsers,
                      archiveKey,
                      // TODO: Combine activity logs into a single Postgres table.
                      drmEventService: new DRMEventService({
                        users: allCompanyUsers,
                        db: this.state.db, // TODO: This doesn't seem like it's being used in DRMEventService, and is always null.
                        companyid: this.state.companyid,
                        userId: this.state.user.uid
                      }),
                      adminPanelActivityLogService:
                        new AdminPanelActivityLogService({
                          users: allCompanyUsers,
                          companyid: this.state.companyid,
                          userId: this.state.user.uid,
                          companyNames: this.state.companyNames
                        })
                    },
                    () => this.getAllPermissions.cache.delete()
                  );
                });
              });
            });
            getSoftwareVersionFirebaseOnce(softwareVersion => {
              this.setState({
                softwareVersionOnLoad: softwareVersion
              });
            });
            getSoftwareVersionFirebase(softwareVersion => {
              this.setState({
                softwareVersion
              });
            });
          },
          () => {
            this.setState({
              logged: false,
              auth_received: true,
              unvalidated: true
            });
          }
        );
      } else {
        this.setState({
          logged: false,
          auth_received: true,
          unvalidated: false
        });
      }
    });

    const active_events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress"
    ];

    for (const i in active_events) {
      window.addEventListener(active_events[i], this.resetTimeout);
    }

    this.resetTimeout();

    if (
      this.contentRef.current &&
      this.contentRef.current.scrollHeight >=
        window.innerHeight - CONTENT_OVERFLOW_OFFSET
    ) {
      this.contentRef.current.style.paddingBottom = `${CONTENT_PADDING}px`;
    }
  }

  componentDidUpdate(prevProps) {
    const currentPage = prevProps.location.pathname;
    const nextPage = this.props.location.pathname;

    if (currentPage !== nextPage) {
      this.trackPageView(nextPage);
    }

    if (
      this.contentRef.current &&
      this.contentRef.current.scrollHeight >= window.innerHeight - 100
    ) {
      this.contentRef.current.style.paddingBottom = "85px";
    }
  }

  render() {
    // Feed props into App's children
    const allPermissions = this.getAllPermissions();
    const childProps = {
      showRightDrawer: this.showRightDrawer,
      showDropDownWindow: this.showDropDownWindow,
      openClose: {
        setAppModal: this.setAppModal,
        closeAppModal: this.closeWindow.bind(null, "modal"),
        minimizeAppModal: this.minimizeWindow.bind(null, "modal"),
        showRightDrawer: this.showRightDrawer,
        hideRightDrawer: this.hideRightDrawer,
        defaultDrawerChange: this.defaultDrawerChange,
        setDrawerClose: this.setDrawerClose,
        showDropDownWindow: this.showDropDownWindow,
        showStealthDropDownWindow: this.showStealthDropDownWindow,
        setDropDownWindowVisible: this.setDropDownWindowVisible,
        closeDropDownWindow: this.closeDropDownWindow,
        showSnack: this.showSnack,
        hideSnack: this.hideSnack,
        setMinimizedWindowToolbar: this.setMinimizedWindowToolbar
      },
      db: {
        archiveMetadata: this.state.archiveMetadata,
        archiveKey: this.state.archiveKey,
        promotions: this.state.promotions,
        products: this.state.products,
        customers: this.state.customers,
        customerGroups: this.state.customerGroups,
        brokers: this.state.brokers,
        contacts: this.state.contacts,
        actMoney: this.state.actMoney,
        revenue: this.state.revenue,
        meta: this.state.meta,
        permissions: this.state.permissions,
        accounts: this.state.accounts,
        allLines: this.state.allLines,
        pricing: this.state.pricing,
        lift: this.state.lift,
        reports: this.state.reports,
        companyUsers: this.state.companyUsers,
        companyid: this.state.companyid,
        promotion_spend: this.state.promotion_spend,
        access: this.state.access,
        dmmOrdering: this.state.dmmOrdering,
        invoices: this.state.invoices,
        promotionInvoices: this.state.promotionInvoices,
        erpTransactions: this.state.erpTransactions,
        drmEventService: this.state.drmEventService,
        adminPanelActivityLogService: this.state.adminPanelActivityLogService,
        drmEvents: this.state.drmEvents,
        companyNames: this.state.companyNames,
        adminPanelEvents: this.state.adminPanelEvents
      },
      doneLoading:
        this.state.loginDone &&
        this.state.promotionsDone &&
        this.state.productsDone &&
        this.state.customersDone &&
        this.state.actMoneyDone &&
        this.state.revenueDone &&
        this.state.allLinesDone &&
        this.state.promotionSpendDone &&
        this.state.brokersDone &&
        this.state.reportsDone &&
        this.state.dmmOrderingDone &&
        this.state.invoicesDone &&
        this.state.erpTransactionsDone &&
        this.state.promotionInvoicesDone &&
        this.state.drmEventsDone &&
        this.state.companyNamesDone &&
        this.state.adminPanelEventsDone,
      sideBar: this.state.sideBar,
      modalOpen: this.state.modalOpen,
      rightDrawerOpen: this.state.rightDrawerOpen,
      onDrawerClose: this.state.onDrawerClose,
      companyUsers: this.state.companyUsers,
      companyid: this.state.companyid,
      readOnly: this.state.archiveKey !== "cresicor",
      outOfDate: this.state.outOfDate,
      internetUnstable: this.state.internetUnstable,
      allPermissions
    };
    const loadingScreen = (
      <div style={{ height: $(window).height() }} className="centering">
        <CircularProgress disableShrink />
      </div>
    );

    const url = this.proTip.url || "https://university.govividly.com";
    const cuText = this.proTip.url
      ? "the Vividly University page"
      : "Vividly University";

    return (
      <StylesProvider generateClassName={generateClassName}>
        {this.state.logged ? (
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <OpenCloseContext.Provider value={childProps.openClose}>
              <DbContext.Provider value={childProps.db}>
                <DRMEventsContext.Provider
                  value={childProps.db.drmEventService}>
                  <div>
                    {childProps.doneLoading ? (
                      <Provider store={store}>
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                          <div>
                            <ReactCSSTransitionGroup
                              transitionName="cover"
                              transitionEnterTimeout={500}
                              transitionLeaveTimeout={300}>
                              <div
                                key="coverScreen"
                                className={classNames("coverScreen", {
                                  visible: this.state.dropDownWindowOpen
                                })}>
                                <DropDownWindow
                                  JSX={this.state.dropDownJSX}
                                  minimizeWindow={this.minimizeWindow.bind(
                                    null,
                                    "dropdown"
                                  )}
                                  closeWindow={this.closeWindow.bind(
                                    null,
                                    "dropdown"
                                  )}
                                  open={this.state.dropDownWindowOpen}
                                  rendered={this.state.dropDownWindowRendered}
                                />
                              </div>
                            </ReactCSSTransitionGroup>
                            <div style={styles.mainContainer}>
                              <HeaderSidebarContainer
                                toggleSideBar={this.toggleSideBar}
                                sideBar={this.state.sideBar}
                                open={this.state.sideBar}
                                pathname={this.props.location.pathname}
                                {...childProps}
                              />
                              <div style={styles.leftContainer}>
                                <HeaderSlot />
                                {this.manageRoutes(loadingScreen, childProps)}
                                <Drawer
                                  anchor="right"
                                  open={this.state.rightDrawerOpen}
                                  onClose={this.onDrawerClose}
                                  style={{
                                    overflow: "hidden",
                                    position: "relative",
                                    zIndex: 1300,
                                    ...this.state.rightDrawerStyles
                                  }}>
                                  {this.state.rightDrawerJSX}
                                </Drawer>
                              </div>
                            </div>
                            <Dialog
                              contentScrollRefreshKey={this.state.modalStep}
                              dialogTitle={this.state.modalTitle}
                              actions={this.state.modalActions}
                              open={this.state.modalOpen}
                              fullWidth={this.state.isFullWidth}
                              maxWidth={this.state.maxWidth}
                              backgroundColor={this.state.backgroundColor}
                              onClose={this.closeWindow.bind(
                                null,
                                "modal",
                                this.state.staticModal
                              )}
                              style={styles.dialogRoot}>
                              {this.state.modalContentJSX}
                            </Dialog>
                            {this.state.minimizedToolbarOpen && (
                              <MinimizedToolbar
                                openWindow={this.openWindow.bind(
                                  null,
                                  this.state.minimizedObj
                                )}
                                closeWindow={this.closeWindow.bind(
                                  null,
                                  this.state.minimizedObj
                                )}
                                minimizedToolbarJSX={
                                  this.state.minimizedToolbarJSX
                                }
                              />
                            )}
                            <NavigationPrompt when={this.state.outOfDate}>
                              {({ onConfirm, onCancel }) => (
                                <Dialog
                                  dialogTitle="Hard Refresh Required"
                                  open={this.state.outOfDate}
                                  titleStyle={styles.modalTitle}
                                  actions={
                                    <div>
                                      <Button label="Ok" onClick={onCancel} />
                                    </div>
                                  }
                                  style={styles.dialogRoot}>
                                  <div>
                                    Your version of Vividly is out of date.
                                    <br />
                                    Please save your work, then perform a hard
                                    refresh by holding Shift then clicking the
                                    Reload button in your browser.
                                  </div>
                                </Dialog>
                              )}
                            </NavigationPrompt>
                          </div>
                        </Worker>
                      </Provider>
                    ) : (
                      <VividlyLoader
                        tip={this.proTip.text}
                        cuText={cuText}
                        url={url}
                      />
                    )}
                    <Snackbar
                      open={this.state.snack}
                      message={this.state.snackMessage || ""}
                      autoHideDuration={4000}
                      action={
                        <Button
                          color="tonal"
                          onClick={this.hideSnack}
                          label="OK"
                        />
                      }
                      onActionClick={this.hideSnack}
                    />
                  </div>
                </DRMEventsContext.Provider>
              </DbContext.Provider>
            </OpenCloseContext.Provider>
          </ErrorBoundary>
        ) : (
          <OpenCloseContext.Provider value={childProps.openClose}>
            <DbContext.Provider value={this.state}>
              <div>
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={() => (
                      <Login
                        unvalidated={this.state.unvalidated}
                        startLogin={() => this.setState({ loginDone: false })}
                        completeLogin={() => this.setState({ loginDone: true })}
                        {...childProps}
                      />
                    )}
                  />
                  <Route
                    path="/usermgmt"
                    render={routeProps => (
                      <AuthenticationHandling {...routeProps} {...childProps} />
                    )}
                  />
                  <Redirect to="/" />
                </Switch>
                <Snackbar
                  open={this.state.snack}
                  message={this.state.snackMessage || ""}
                  autoHideDuration={4000}
                  action={
                    <Button color="tonal" onClick={this.hideSnack} label="OK" />
                  }
                  onActionClick={this.hideSnack}
                />
              </div>
            </DbContext.Provider>
          </OpenCloseContext.Provider>
        )}
      </StylesProvider>
    );
  }
}

export { styles };
export { CONTENT_PADDING, CONTENT_OVERFLOW_OFFSET };
export default App;
