import React, { useCallback, useState, useEffect } from "react";
import { capitalize } from "lodash";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { useDb } from "contexts/Db";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import {
  ToggleButton,
  Stack,
  IconButton,
  Paper,
  Popover,
  Typography
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import DownloadIcon from "@mui/icons-material/Download";

import { Toolbar, ToolbarSeparator, ToolbarTitle } from "ui-library/Toolbar";
import StyledToggleButtonGroup from "ui-library/ToggleButtonGroupStyled";
import ToolbarGroup from "ui-library/ToolbarGroup";

import {
  ForecastType,
  ForecastFilter,
  ForecastViewType,
  Option,
  RetailerOption,
  DistributorOption,
  AllOtherOption,
  ForecastViewOption,
  ForecastViewValue,
  DistributorOptionValue,
  RetailerOptionValue,
  TotalOptionValue,
  OtherOptionValue,
  FilterIdKey
} from "./types";
import {
  useCreateForecast,
  useDownloadForecast,
  useDownloadForecastZip,
  useGetAVF,
  useGetForecast
} from "./api";
import { stringToEnumOrDefault, useQuery } from "./utilities";
import { Table } from "./Table";
import {
  FilterSelector,
  useMetadataSelectOptions,
  useVersionSelectOptions,
  SelectOption
} from "./FilterSelector";
import { NotificationState } from "./Notifications";
import { ActualsVsForecast } from "./ActualsVsForecast";
import { SUBTABLE_ORDER } from "./TableComponents/SubTable";

const FORECAST_VIEW_POLLING_RATE = 300000;

const Forecast = ({
  type,
  filter,
  filterId,
  secondaryFilterId,
  companyid,
  version,
  versionNumber,
  year,
  loading: wrapperLoading,
  avfView,
  selectedView
}: {
  type: ForecastType;
  filter: ForecastFilter;
  secondaryFilter: ForecastFilter;
  filterId: string;
  secondaryFilterId: string;
  companyid: string;
  version: string;
  versionNumber: number;
  year: string;
  loading: boolean;
  avfView: ForecastViewType;
  selectedView: ForecastViewValue;
}) => {
  const [{ data: view, loading: viewLoading }, refetch] = useGetForecast({
    company_id: companyid,
    forecast_type: type,
    version,
    override_version: versionNumber,
    year,
    [filter]: filterId
  });

  useEffect(() => {
    const interval = setInterval(() => refetch(), FORECAST_VIEW_POLLING_RATE);

    return () => clearInterval(interval);
  }, [refetch]);

  const customer =
    filter == ForecastFilter.CUSTOMER ? filterId : secondaryFilterId ?? "Total";
  const product =
    filter == ForecastFilter.PRODUCT ? filterId : secondaryFilterId ?? "Total";

  const [{ data: avfData, loading: loadingAVF }] = useGetAVF({
    version_filename: version,
    customer,
    product,
    company_id: companyid
  });

  const loading = viewLoading || wrapperLoading;
  const showAVF = avfView.valueOf() === ForecastViewType.AVF;

  return (
    <>
      {loading && (
        <div style={{ position: "fixed", bottom: 20, left: 20 }}>
          <CircularProgress thickness={15} />
        </div>
      )}

      {!!view && !showAVF && (
        <div
          style={{
            height: "100%",
            maxWidth: "100%",
            minWidth: "100%"
          }}>
          <Table
            view={view}
            selectedView={selectedView}
            filter={filter}
            loading={loading}
            companyid={companyid}
            version={version}
            versionNumber={versionNumber}
            refetch={refetch}
          />
        </div>
      )}
      {!!view && showAVF && !!avfData && (
        <Stack justifyContent="space-between">
          <div
            style={{
              height: "100%",
              maxWidth: "100%",
              minWidth: "100%"
            }}>
            <ActualsVsForecast
              avfData={avfData}
              tableStart={view.meta.table_start}
              tableEnd={view.meta.table_end}
              loading={loadingAVF}
            />
          </div>
        </Stack>
      )}
    </>
  );
};

interface PurifiedQueryState {
  [key: string]: string;
}
interface QueryState {
  forecastType: ForecastType;
  forecastFilter: ForecastFilter;
  secondaryForecastFilter: ForecastFilter;
  customersRetailersId: string;
  customersOtherId: string;
  customersDistributorsId: string;
  productsRetailersId: string;
  productsOtherId: string;
  productsDistributorsId: string;
  forecastYear: Option<string>;
  forecastVersion: Option<string>;
  forecastView: ForecastViewValue;
}

function forecastViewOrDefault(queryString: Option<string>): ForecastViewValue {
  if (
    typeof queryString === "string" &&
    (SUBTABLE_ORDER as Array<string>).includes(queryString)
  ) {
    return queryString as ForecastViewValue;
  }
  return "sales";
}

const defaultFilterId = "Total";
const ForecastWrapper = () => {
  const history = useHistory();
  const queryParams = useQuery();
  const [queryState, setQueryState] = useState<QueryState>({
    forecastType: stringToEnumOrDefault(
      queryParams.get("forecastType"),
      ForecastType,
      ForecastType.RETAILERS
    ),
    forecastFilter: stringToEnumOrDefault(
      queryParams.get("forecastFilter"),
      ForecastFilter,
      ForecastFilter.CUSTOMER
    ),
    secondaryForecastFilter: stringToEnumOrDefault(
      queryParams.get("secondaryForecastFilter"),
      ForecastFilter,
      ForecastFilter.PRODUCT
    ),
    customersRetailersId:
      queryParams.get("customersRetailersId") ?? defaultFilterId,
    customersOtherId: queryParams.get("customersOtherId") ?? defaultFilterId,
    customersDistributorsId:
      queryParams.get("customersDistributorsId") ?? defaultFilterId,
    productsRetailersId:
      queryParams.get("productsRetailersId") ?? defaultFilterId,
    productsOtherId: queryParams.get("productsOtherId") ?? defaultFilterId,
    productsDistributorsId:
      queryParams.get("productsDistributorsId") ?? defaultFilterId,
    forecastYear:
      queryParams.get("year") || new Date().getFullYear().toString(),
    forecastVersion: queryParams.get("version"),
    forecastView: forecastViewOrDefault(queryParams.get("forecastView"))
  });

  const [showSettings, setShowSettings] = useState(true);
  const [toolbarAnchorElement, setToolbarAnchorElement] =
    useState<HTMLButtonElement | null>(null);

  const onToolbarExtraClick = (
    e: React.MouseEvent<HTMLButtonElement>
  ): void => {
    e.stopPropagation();
    setToolbarAnchorElement(e.currentTarget);
  };

  const [notifications, setNotifications] = useState(
    NotificationState.notifications
  );

  useEffect(() => {
    const unsubscribe = NotificationState.subscribe(ns => setNotifications(ns));

    return unsubscribe;
  }, []);

  const { companyid, meta } = useDb();

  // Whenever queryState is updated, update the url params.
  useEffect(() => {
    const purifiedQueryState: PurifiedQueryState = {};
    for (const [key, value] of Object.entries(queryState)) {
      if (typeof value === "string") {
        purifiedQueryState[key] = value;
      }
    }
    const urlParams = new URLSearchParams(purifiedQueryState).toString();
    history.push(`/forecast2/?${urlParams}`);
  }, [queryState]);

  // avf
  const [avfView, setAVFView] = useState<ForecastViewType>(
    ForecastViewType.TABLE
  );

  // version things
  const { options: versionOptions, refetch: refetchVersions } =
    useVersionSelectOptions();
  const sanitizedParamVersion = versionOptions.find(
    versionOption =>
      versionOption.label === queryState.forecastVersion?.replace(/\$/g, ".")
  );

  const versionItem: SelectOption | undefined =
    sanitizedParamVersion ?? versionOptions[0];

  // view things
  let viewOptions = [] as Array<ForecastViewOption>;

  // assign the correct viewOptions for the selected forecastType
  switch (queryState.forecastType) {
    case ForecastType.RETAILERS: {
      const { forecastFilter, customersRetailersId, productsRetailersId } =
        queryState;
      let isTotal = true;
      if (forecastFilter === ForecastFilter.CUSTOMER) {
        isTotal = customersRetailersId === "Total";
      } else if (forecastFilter === ForecastFilter.PRODUCT) {
        isTotal = productsRetailersId === "Total";
      }
      if (!isTotal) {
        const retailerViewOptions: Array<RetailerOption> = [
          { value: "sales", label: "Sales" },
          { value: "revenue", label: "Revenue" },
          { value: "is_prom", label: "Promotion" },
          { value: "lift", label: "Lift" },
          { value: "seasonality", label: "Seasonality" },
          { value: "stores", label: "Stores" },
          { value: "velocity", label: "Velocity" }
        ];
        viewOptions = retailerViewOptions;
      } else {
        viewOptions = [
          { value: "sales", label: "Sales" },
          { value: "revenue", label: "Revenue" }
        ];
      }
      break;
    }
    case ForecastType.DISTRIBUTORS: {
      const {
        forecastFilter,
        customersDistributorsId,
        productsDistributorsId
      } = queryState;
      let isTotal = true;
      if (forecastFilter === ForecastFilter.CUSTOMER) {
        isTotal = customersDistributorsId === "Total";
      } else if (forecastFilter === ForecastFilter.PRODUCT) {
        isTotal = productsDistributorsId === "Total";
      }
      if (!isTotal) {
        const distributorViewOptions: Array<DistributorOption> = [
          { value: "sales", label: "Sales" },
          { value: "revenue", label: "Revenue" },
          { value: "is_prom", label: "Promotion" },
          { value: "lift", label: "Lift" }
        ];
        viewOptions = distributorViewOptions;
      } else {
        viewOptions = [
          { value: "sales", label: "Sales" },
          { value: "revenue", label: "Revenue" }
        ];
      }
      break;
    }
    case ForecastType.ALL_OTHERS: {
      const { forecastFilter, customersOtherId, productsOtherId } = queryState;
      let isTotal = true;
      if (forecastFilter === ForecastFilter.CUSTOMER) {
        isTotal = customersOtherId === "Total";
      } else if (forecastFilter === ForecastFilter.PRODUCT) {
        isTotal = productsOtherId === "Total";
      }
      if (!isTotal) {
        const allOtherViewOptions: Array<AllOtherOption> = [
          { value: "sales", label: "Sales" },
          { value: "revenue", label: "Revenue" },
          { value: "seasonality", label: "Seasonality" },
          { value: "base_sales", label: "Base Sales" }
        ];
        viewOptions = allOtherViewOptions;
      } else {
        viewOptions = [
          { value: "sales", label: "Sales" },
          { value: "revenue", label: "Revenue" }
        ];
      }
      break;
    }
  }
  const viewItem: ForecastViewOption =
    viewOptions.find(option => option.value === queryState.forecastView) ??
    viewOptions[0];

  const version = versionItem?.value;
  const forecastCsvExports = !!meta.featureGates?.forecastCsvExports;

  const overrideVersioning = !!meta.featureGates?.overrideVersioning;
  // TODO: caller should be able to pass a number in directly.
  // hacky way for now by extracting it from the filename
  const versionNumber =
    overrideVersioning && version
      ? parseInt(version.split("_")[1].substring(1))
      : 0;

  // create forecast things
  const [{ loading }, executeCreateForecast] = useCreateForecast();

  const createForecast = useCallback(
    versionNumber => async () => {
      await executeCreateForecast({
        data: {
          company_id: companyid,
          forecast_type: queryState.forecastType,
          override_version: versionNumber
        }
      });
      await refetchVersions();

      // this will set version selection to the newest generated forecast
      setQueryState(prevQueryState => {
        return { ...prevQueryState, forecastVersion: null };
      });
    },
    [companyid, queryState.forecastType]
  );

  // metadata things
  const { filterOptions, yearOptions } = useMetadataSelectOptions(
    queryState.forecastFilter,
    queryState.forecastType,
    version,
    versionNumber
  );

  // for avf
  const secondaryFilterOptions = useMetadataSelectOptions(
    queryState.secondaryForecastFilter,
    queryState.forecastType,
    version,
    versionNumber
  ).filterOptions;

  // filter id things
  let filterId: Option<string>;
  let secondaryFilterId: Option<string>;
  let updateFilterId: (id: string) => void;
  let updateSecondaryFilterId: (id: string) => void;
  const getUpdateFilterId = (filterIdKey: FilterIdKey) => {
    return (id: string) => {
      const { forecastView } = queryState;
      let newForecastView = forecastView;
      // total customer or product ids can only have sales or revenue view
      if (
        id === "Total" &&
        forecastView !== "sales" &&
        forecastView !== "revenue"
      ) {
        newForecastView = "sales";
      }
      setQueryState(prevQueryState => ({
        ...prevQueryState,
        forecastView: newForecastView,
        [filterIdKey]: id
      }));
    };
  };
  if (queryState.forecastFilter === ForecastFilter.CUSTOMER) {
    switch (queryState.forecastType) {
      case ForecastType.RETAILERS:
        filterId = queryState.customersRetailersId;
        secondaryFilterId = queryState.productsRetailersId;
        updateFilterId = getUpdateFilterId("customersRetailersId");
        updateSecondaryFilterId = getUpdateFilterId("productsRetailersId");
        break;
      case ForecastType.DISTRIBUTORS:
        filterId = queryState.customersDistributorsId;
        secondaryFilterId = queryState.productsDistributorsId;
        updateFilterId = getUpdateFilterId("customersDistributorsId");
        updateSecondaryFilterId = getUpdateFilterId("productsDistributorsId");
        break;
      case ForecastType.ALL_OTHERS:
        filterId = queryState.customersOtherId;
        secondaryFilterId = queryState.productsOtherId;
        updateFilterId = getUpdateFilterId("customersOtherId");
        updateSecondaryFilterId = getUpdateFilterId("productsOtherId");
        break;
    }
  } else if (queryState.forecastFilter === ForecastFilter.PRODUCT) {
    switch (queryState.forecastType) {
      case ForecastType.RETAILERS:
        filterId = queryState.productsRetailersId;
        secondaryFilterId = queryState.customersRetailersId;
        updateFilterId = getUpdateFilterId("productsRetailersId");
        updateSecondaryFilterId = getUpdateFilterId("customersRetailersId");
        break;
      case ForecastType.DISTRIBUTORS:
        filterId = queryState.productsDistributorsId;
        secondaryFilterId = queryState.customersDistributorsId;
        updateFilterId = getUpdateFilterId("productsDistributorsId");
        updateSecondaryFilterId = getUpdateFilterId("customersDistributorsId");
        break;
      case ForecastType.ALL_OTHERS:
        filterId = queryState.productsOtherId;
        secondaryFilterId = queryState.customersOtherId;
        updateFilterId = getUpdateFilterId("productsOtherId");
        updateSecondaryFilterId = getUpdateFilterId("customersOtherId");
        break;
    }
  }

  const sanitizedParamFilter = filterOptions.find(
    filterOption => filterOption.label === filterId
  );

  const secondarySantitizedParamFilter = secondaryFilterOptions.find(
    filterOption => filterOption.label === secondaryFilterId
  );

  const filterItem: SelectOption | undefined =
    sanitizedParamFilter ?? filterOptions[0];

  const secondaryFilterItem: SelectOption | undefined =
    secondarySantitizedParamFilter ?? secondaryFilterOptions[0];

  // year selector things
  const yearItem = yearOptions.find(
    yearOption => yearOption.label === queryState.forecastYear
  );

  const { loading: loadingExport, downloadExport } = useDownloadForecast({
    company_id: companyid,
    version_filename: version ?? "",
    override_version: versionNumber
  });

  const { loading: loadingExportZip, downloadExportZip } =
    useDownloadForecastZip({
      company_id: companyid,
      version_filename: version ?? "",
      override_version: versionNumber
    });

  // forecast update change handlers
  const updateForecastType = (
    _e: React.MouseEvent<HTMLElement, MouseEvent>,
    v: Option<ForecastType>
  ) => {
    if (v) {
      const totalOptionValues: Array<TotalOptionValue> = ["sales", "revenue"];
      const retailerOptionValues: Array<RetailerOptionValue> = [
        "sales",
        "revenue",
        "is_prom",
        "lift",
        "seasonality",
        "stores",
        "velocity"
      ];
      const distributorOptionValues: Array<DistributorOptionValue> = [
        "sales",
        "revenue",
        "is_prom",
        "lift"
      ];
      const allOtherOptionValues: Array<OtherOptionValue> = [
        "sales",
        "revenue",
        "seasonality",
        "base_sales"
      ];
      const { forecastView, forecastFilter } = queryState;
      let newForecastView = forecastView;
      let filterId = "Total";
      let validViews: Array<ForecastViewValue> = totalOptionValues;
      if (forecastFilter === ForecastFilter.CUSTOMER) {
        switch (v) {
          case ForecastType.RETAILERS:
            filterId = queryState.customersRetailersId ?? filterId;
            validViews = retailerOptionValues;
            break;
          case ForecastType.ALL_OTHERS:
            filterId = queryState.customersOtherId ?? filterId;
            validViews = allOtherOptionValues;
            break;
          case ForecastType.DISTRIBUTORS:
            filterId = queryState.customersDistributorsId ?? filterId;
            validViews = distributorOptionValues;
            break;
        }
      } else if (forecastFilter === ForecastFilter.PRODUCT) {
        switch (v) {
          case ForecastType.RETAILERS:
            filterId = queryState.productsRetailersId ?? filterId;
            validViews = retailerOptionValues;
            break;
          case ForecastType.ALL_OTHERS:
            filterId = queryState.productsOtherId ?? filterId;
            validViews = allOtherOptionValues;
            break;
          case ForecastType.DISTRIBUTORS:
            filterId = queryState.productsDistributorsId ?? filterId;
            validViews = distributorOptionValues;
            break;
        }
      }
      if (
        (filterId === "Total" &&
          forecastView !== "sales" &&
          forecastView !== "revenue") ||
        !validViews.includes(forecastView)
      ) {
        newForecastView = "sales";
      }
      setQueryState(prevQueryState => {
        return {
          ...prevQueryState,
          forecastView: newForecastView,
          forecastType: v
        };
      });
    }
  };

  const updateForecastFilter = (
    _e: React.MouseEvent<HTMLElement, MouseEvent>,
    v: Option<ForecastFilter>
  ) => {
    if (v) {
      const { forecastView, forecastType } = queryState;
      let newForecastView = forecastView;
      if (v === ForecastFilter.CUSTOMER) {
        switch (forecastType) {
          case ForecastType.RETAILERS:
            filterId = queryState.customersRetailersId ?? filterId;
            break;
          case ForecastType.ALL_OTHERS:
            filterId = queryState.customersOtherId ?? filterId;
            break;
          case ForecastType.DISTRIBUTORS:
            filterId = queryState.customersDistributorsId ?? filterId;
            break;
        }
      } else if (v === ForecastFilter.PRODUCT) {
        switch (forecastType) {
          case ForecastType.RETAILERS:
            filterId = queryState.productsRetailersId ?? filterId;
            break;
          case ForecastType.ALL_OTHERS:
            filterId = queryState.productsOtherId ?? filterId;
            break;
          case ForecastType.DISTRIBUTORS:
            filterId = queryState.productsDistributorsId ?? filterId;
            break;
        }
      }
      if (
        filterId === "Total" &&
        forecastView !== "sales" &&
        forecastView !== "revenue"
      ) {
        newForecastView = "sales";
      }
      setQueryState(prevQueryState => {
        const currentForecastFilter = prevQueryState.forecastFilter;
        return {
          ...prevQueryState,
          forecastView: newForecastView,
          forecastFilter: v,
          secondaryForecastFilter: currentForecastFilter
        };
      });
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          height: "calc(100vh - 140px)",
          width: "100%",
          position: "relative"
        }}>
        <Toolbar style={{ width: "100%" }}>
          <ToolbarGroup>
            <ToolbarTitle text="Forecast" style={undefined} />
            <ToolbarSeparator style={{ margin: "0 8px" }} />
          </ToolbarGroup>
          <Stack>
            <StyledToggleButtonGroup
              value={queryState.forecastType}
              exclusive
              onChange={updateForecastType}
              disabled={loading}>
              <ToggleButton value={ForecastType.RETAILERS}>
                Retailers
              </ToggleButton>
              <ToggleButton value={ForecastType.ALL_OTHERS}>
                All-Other Buckets
              </ToggleButton>
              <ToggleButton value={ForecastType.DISTRIBUTORS}>
                Distributors
              </ToggleButton>
            </StyledToggleButtonGroup>
            <ToolbarSeparator style={{ margin: "0 8px" }} />
            <StyledToggleButtonGroup
              value={queryState.forecastFilter}
              exclusive
              onChange={updateForecastFilter}
              disabled={loading}>
              <ToggleButton value={ForecastFilter.CUSTOMER}>
                Customers
              </ToggleButton>
              <ToggleButton value={ForecastFilter.PRODUCT}>
                Products
              </ToggleButton>
            </StyledToggleButtonGroup>
            <ToolbarSeparator style={{ margin: "0 8px" }} />
            <StyledToggleButtonGroup
              value={avfView}
              exclusive
              onChange={(_e, v) => {
                if (v) {
                  setAVFView(v);
                }
              }}
              disabled={loading}>
              <ToggleButton value={ForecastViewType.TABLE}>Table</ToggleButton>
              <ToggleButton value={ForecastViewType.AVF}>
                Actuals vs. Forecast
              </ToggleButton>
            </StyledToggleButtonGroup>
          </Stack>
          <ToolbarGroup>
            <Button
              variant="contained"
              endIcon={
                loading ? (
                  <CircularProgress size={20} thickness={10} />
                ) : (
                  <UpgradeIcon />
                )
              }
              onClick={createForecast(versionNumber)}
              disabled={loading}>
              Generate Forecast
            </Button>
            <IconButton onClick={onToolbarExtraClick}>
              <SettingsIcon color="action" />
            </IconButton>
            <Popover
              open={!!toolbarAnchorElement}
              anchorEl={toolbarAnchorElement}
              onClose={() => setToolbarAnchorElement(null)}
              onClick={e => e.stopPropagation()}>
              <Paper style={{ padding: 12 }}>
                <Button
                  variant="outlined"
                  style={{ display: "block", margin: "4px 0", width: "100%" }}
                  disabled={loading}
                  onClick={() => {
                    history.push("/forecast2/manage/", {
                      companyid,
                      versionOptions
                    });
                  }}>
                  Manage Versions
                </Button>
                <Button
                  endIcon={
                    loadingExport ? (
                      <CircularProgress size={20} thickness={10} />
                    ) : (
                      <DownloadIcon />
                    )
                  }
                  variant="contained"
                  style={{ margin: "4px 0", width: "100%" }}
                  onClick={downloadExport}
                  disabled={loadingExport}>
                  {forecastCsvExports ? "Export to Excel" : "Export"}
                </Button>
                {forecastCsvExports && (
                  <Button
                    endIcon={
                      loadingExportZip ? (
                        <CircularProgress size={20} thickness={10} />
                      ) : (
                        <DownloadIcon />
                      )
                    }
                    variant="contained"
                    style={{ margin: "4px 0", width: "100%" }}
                    onClick={downloadExportZip}
                    disabled={loadingExportZip}>
                    Export to CSVs
                  </Button>
                )}
              </Paper>
            </Popover>
          </ToolbarGroup>
        </Toolbar>

        <Stack p={2} direction="row">
          {!!filterItem && (
            <Stack alignItems="flex-start" direction="column">
              <Typography
                sx={{ verticalAlign: "bottom" }}
                variant="labelMediumCondensed"
                display="block">
                {capitalize(queryState.forecastFilter)}
              </Typography>
              <div style={{ width: "250px" }}>
                <FilterSelector
                  name={queryState.forecastFilter}
                  options={filterOptions}
                  value={filterItem}
                  onChange={filterIdItem => {
                    updateFilterId(filterIdItem.label);
                  }}
                />
              </div>
            </Stack>
          )}
          {!!secondaryFilterItem &&
            !!(avfView.valueOf() === ForecastViewType.AVF) && (
              <Stack alignItems="flex-start" direction="column">
                <Typography
                  sx={{ verticalAlign: "bottom" }}
                  variant="labelMediumCondensed"
                  display="block">
                  {capitalize(queryState.secondaryForecastFilter)}
                </Typography>
                <div style={{ width: "250px" }}>
                  <FilterSelector
                    name={queryState.secondaryForecastFilter}
                    options={secondaryFilterOptions}
                    value={secondaryFilterItem}
                    onChange={filterIdItem => {
                      updateSecondaryFilterId(filterIdItem.label);
                    }}
                  />
                </div>
              </Stack>
            )}
          {!!yearItem && !!(avfView.valueOf() === ForecastViewType.TABLE) && (
            <Stack alignItems="flex-start" direction="column">
              <Typography
                sx={{ verticalAlign: "bottom" }}
                variant="labelMediumCondensed"
                display="block">
                Year
              </Typography>
              <FilterSelector
                name="year"
                options={yearOptions}
                value={yearItem}
                onChange={yearSelection => {
                  setQueryState(prevQueryState => {
                    return {
                      ...prevQueryState,
                      forecastYear: yearSelection.label
                    };
                  });
                }}
              />
            </Stack>
          )}
          {!!versionItem && (
            <Stack alignItems="flex-start" direction="column">
              <Typography
                sx={{ verticalAlign: "bottom" }}
                variant="labelMediumCondensed"
                display="block">
                Version
              </Typography>
              <div style={{ width: "350px" }}>
                <FilterSelector
                  name="version"
                  options={versionOptions}
                  value={versionItem}
                  onChange={versionSelection => {
                    setQueryState(prevQueryState => {
                      return {
                        ...prevQueryState,
                        forecastVersion: versionSelection.label.replace(
                          /\./g,
                          "$"
                        )
                      };
                    });
                  }}
                />
              </div>
            </Stack>
          )}
          {avfView.valueOf() === ForecastViewType.TABLE && (
            <Stack alignItems="flex-start" direction="column">
              <Typography
                sx={{ verticalAlign: "bottom" }}
                variant="labelMediumCondensed"
                display="block">
                View
              </Typography>
              <div style={{ width: "150px" }}>
                <Select
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: provided => ({ ...provided, zIndex: 9999 })
                  }}
                  onChange={(
                    viewSelection:
                      | DistributorOption
                      | AllOtherOption
                      | RetailerOption
                  ) => {
                    setQueryState(prevQueryState => {
                      return {
                        ...prevQueryState,
                        forecastView: viewSelection.value
                      };
                    });
                  }}
                  options={viewOptions}
                  value={viewItem}
                />
              </div>
            </Stack>
          )}
          {avfView.valueOf() === ForecastViewType.TABLE && (
            <Typography
              sx={{
                fontStyle: "italic",
                paddingLeft: "10px"
              }}
              variant="bodyLarge"
              display="inline">
              (Displayed dates are week-ending)
            </Typography>
          )}
        </Stack>

        <div style={{ padding: "8px 12px 48px 12px", width: "100%", flex: 1 }}>
          {!!filterItem && !!versionItem && !!yearItem && (
            <Forecast
              companyid={companyid}
              type={queryState.forecastType}
              filter={queryState.forecastFilter}
              secondaryFilter={queryState.secondaryForecastFilter}
              filterId={filterItem.value}
              secondaryFilterId={secondaryFilterItem.value}
              version={versionItem.value}
              versionNumber={versionNumber}
              year={yearItem.value}
              loading={loading}
              avfView={avfView}
              selectedView={queryState.forecastView}
            />
          )}
        </div>
      </div>
      {notifications.map((notification, i) => (
        <div
          style={{
            position: "fixed",
            margin: " 0 10px",
            bottom: 10,
            borderRadius: 5,
            background: "#ff4f44",
            color: "white",
            padding: "10px 30px",
            fontWeight: "bold"
          }}>
          <div style={{ marginBottom: 10 }}>{notification.message}</div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse"
            }}>
            <Button
              size="small"
              style={{
                color: "black",
                background: "white",
                fontWeight: "bold"
              }}
              onClick={() => NotificationState.remove(i)}>
              DISMISS
            </Button>
          </div>
        </div>
      ))}
    </>
  );
};

export default ForecastWrapper;
