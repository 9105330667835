import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Map, Upgrade, Preview } from "@mui/icons-material/";
import CustomizedHorizontalStepper from "ui-library/HorizontalStepper";

const UploadBackupModalHeader = () => {
  const { currentUploadStep } = useSelector((state: RootState) => ({
    currentUploadStep: state.uploadBackup.currentUploadStep
  }));
  const steps = [
    { label: "Upload File and Map Invoice(s)", icon: <Upgrade /> },
    { label: "Enter Invoice Details", icon: <Map /> },
    { label: "Done", icon: <Preview /> }
  ];

  return (
    <div>
      <CustomizedHorizontalStepper
        steps={steps}
        activeStep={currentUploadStep - 1}
      />
    </div>
  );
};

export default UploadBackupModalHeader;
