import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Checkbox from "ui-library/Checkbox";
import makeStyles from "@mui/styles/makeStyles";
import DownloadIcon from "@mui/icons-material/GetApp";
import IconButton from "ui-library/IconButton";
import IconMenu from "ui-library/IconMenu";
import MenuItem from "ui-library/MenuItem";
import SettingsIcon from "@mui/icons-material/Settings";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { Stack } from "@mui/material";
import { useBackupSources } from "components/Deductions/constants/BackupSources";
import { DsmScannedInfo } from "./ScanHistoryTypes";

import {
  getDsmScanningInfo,
  getPageTrackerInfoDownload
} from "./ScanHistoryUtilities";
import {
  scanningColumns,
  scanningByBackupSourceColumns
} from "./ScanHistoryColumnConfig";
import { useKeepGroupingColumnsHidden } from "./ScanHistoryHooks";

const useStyles = makeStyles(theme => ({
  superheader: {
    fontFamily: "Ubuntu",
    color: theme.palette.primary.main
  },
  dataGrid: {
    height: "80vh",
    width: "100%"
  },
  displayGroupByBackupSource: {
    display: "flex"
  },
  scanHistory: {
    marginLeft: 16,
    width: "98%",
    paddingTop: 20
  }
}));

interface ScanHistoryProps {
  companyId: string;
}

export const ScanHistory = (props: ScanHistoryProps) => {
  const classes = useStyles();

  const [displayTotal, setDisplayTotal] = useState(false);

  const [pagesScannedLoading, setPagesScannedLoading] = useState(true);
  const [pagesScannedWithBackupLoading, setPagesScannedWithBackupLoading] =
    useState(true);

  const { allBackupSources: backupSources, isLoading: isBackupSourcesLoading } =
    useBackupSources();

  const [pagesScannedInfo, setPagesScannedInfo] = useState<DsmScannedInfo[]>(
    []
  );
  const [backupSourcePagesScannedInfo, setBackupSourcePagesScannedInfo] =
    useState<DsmScannedInfo[]>([]);

  useEffect(() => {
    getDsmScanningInfo(props.companyId, backupSources).then(response => {
      setPagesScannedLoading(false);
      setPagesScannedInfo(response);
    });
    getDsmScanningInfo(props.companyId, backupSources, true).then(response => {
      setPagesScannedWithBackupLoading(false);
      setBackupSourcePagesScannedInfo(response);
    });
  }, [props.companyId, backupSources]);

  const loading =
    pagesScannedLoading ||
    pagesScannedWithBackupLoading ||
    isBackupSourcesLoading;

  const apiRef = useGridApiRef();
  const INITIAL_GROUPING_COLUMN_MODEL = ["date"];
  const groupedScanningByBackupSourceColumns = useKeepGroupingColumnsHidden(
    apiRef,
    scanningByBackupSourceColumns,
    INITIAL_GROUPING_COLUMN_MODEL,
    null
  );

  return (
    <div className={classes.scanHistory}>
      <Stack justifyContent="space-between">
        <Typography className={classes.superheader} variant="h4">
          Successful Scan History
        </Typography>
        <Stack>
          <Checkbox
            color="primary"
            label="View Totals"
            onChange={e => setDisplayTotal(e.target.checked)}
            checked={displayTotal}
          />
          <div>
            <IconMenu
              iconButtonElement={
                <IconButton tooltip="Settings" size="large">
                  <SettingsIcon />
                </IconButton>
              }>
              <MenuItem
                value={0}
                leftIcon={<DownloadIcon />}
                onClick={() =>
                  getPageTrackerInfoDownload(
                    pagesScannedInfo,
                    backupSourcePagesScannedInfo
                  )
                }
                disabled={
                  Object.keys(pagesScannedInfo).length === 0 ||
                  Object.keys(backupSourcePagesScannedInfo).length === 0
                }>
                Download Report
              </MenuItem>
            </IconMenu>
          </div>
        </Stack>
      </Stack>

      <br />

      <div className={classes.dataGrid}>
        {!displayTotal ? (
          <DataGridPro // TODO: Warning, table group by is experimental and this sucks. Refactor this w/ collapsible table
            experimentalFeatures={{ rowGrouping: true }}
            rowGroupingColumnMode="single"
            initialState={{
              rowGrouping: {
                model: INITIAL_GROUPING_COLUMN_MODEL
              }
            }}
            groupingColDef={{
              headerClassName: "datagrid-header"
            }}
            rows={backupSourcePagesScannedInfo}
            columns={
              pagesScannedWithBackupLoading
                ? scanningByBackupSourceColumns
                : groupedScanningByBackupSourceColumns
            }
            pageSize={10}
            loading={loading}
          />
        ) : (
          <DataGridPro
            rows={pagesScannedInfo}
            columns={scanningColumns}
            pageSize={10}
            loading={loading}
          />
        )}
      </div>
    </div>
  );
};

export default ScanHistory;
