export const SET_STEP_INDEX = "SET_STEP_INDEX";
export const ADV_DUP_ERROR = "ADV_DUP_ERROR";
export const ADV_DUP_PROCEED = "ADV_DUP_PROCEED";

export const setStepIndex = stepIndex => ({
  type: SET_STEP_INDEX,
  stepIndex
});

export const setAdvDuplicationError = hasError => ({
  type: ADV_DUP_ERROR,
  advDuplicationError: hasError
});

export const proceedFromAdvDuplicationModal = {
  type: ADV_DUP_PROCEED
};
