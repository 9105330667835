import React, { useState, useEffect, useContext } from "react";
import ReactTable from "react-table-v6";
import {
  isWildcard,
  pivotTypes,
  diffTypes,
  getColumns,
  getExpandedIndicesFromIds,
  renderInnerRow
} from "helpers/Budget";
import LoadingIndicator from "components/Forecast/LoadingIndicator";
import { InnerLoadingContext } from "./BudgetTool";
import BudgetWorker from "../../../helpers/Budget/budget.worker.js";
import {
  ComparisonType,
  DiffType,
  FigureType,
  PivotType,
  TimeScaleType,
  DBSlice,
  OrganisedData,
  TableColumn
} from "./types";

interface DataTableProps {
  allData: OrganisedData;
  loading: boolean;
  timeScale: TimeScaleType;
  figureType: FigureType;
  pivot: PivotType;
  diffType: DiffType;
  comparisonType: ComparisonType;
  dataSubTypesForPivot: FigureType[];
  db: DBSlice;
  reset: number;
  diffThreshold: number;
  rowDataType: DiffType;
}

const DataTable = ({
  allData,
  loading,
  timeScale,
  figureType,
  pivot,
  diffType,
  comparisonType,
  dataSubTypesForPivot,
  db,
  reset,
  diffThreshold,
  rowDataType
}: DataTableProps) => {
  const [tableData, setTableData] = useState<TableColumn[]>([]);
  const [showLegends, setShowLegends] = useState(
    (isWildcard(figureType) && pivot === pivotTypes.DATE) ||
      diffType !== diffTypes.None
  );
  const [showPagination, setShowPagination] = useState(
    pivot !== pivotTypes.DATE
  );
  const [sortColumns, setSortColumns] = useState([]);
  const [expandedPivotGroups, setExpandedPivotGroups] = useState([]);
  const { innerLoading, setInnerLoading } = useContext(InnerLoadingContext);

  const handleRowExpansion = data => expandedRowState => {
    const openRows = Object.entries(expandedRowState)
      .filter(([_rowIndex, isExpanded]) => isExpanded)
      .map(([rowIndex]) => data[rowIndex].id);
    setExpandedPivotGroups(openRows);
  };

  const handleSortChange = newSortState => {
    setSortColumns(newSortState);
    setShowLegends(false);
  };
  useEffect(() => {
    setShowLegends(
      (isWildcard(figureType) && pivot === pivotTypes.DATE) ||
        diffType !== diffTypes.None
    );
    setShowPagination(pivot !== pivotTypes.DATE);
  }, [figureType, diffType, pivot]);

  useEffect(() => {
    setExpandedPivotGroups([]);
    setSortColumns([]);
  }, [reset, diffType, pivot]);

  useEffect(() => {
    const calculationWorker = new BudgetWorker();
    calculationWorker.onmessage = ({ data }) => {
      setTableData(data);
      setInnerLoading(false);
      calculationWorker.terminate();
    };

    const workerData =
      diffType === diffTypes.None
        ? {
            type: "groupDataForTable",
            args: {
              data: allData,
              figureType,
              comparisonType,
              pivot,
              db,
              dataSubTypesForPivot,
              rowDataType
            }
          }
        : {
            type: "makeDiffTableData",
            args: { data: allData, diffType, pivot, diffThreshold }
          };

    calculationWorker.postMessage(workerData);

    return () => calculationWorker.terminate();
  }, [allData]);

  return (
    <>
      <ReactTable
        className={`budget-table${showLegends ? " show-separator" : ""}`}
        showPagination={showPagination}
        minRows={1}
        columns={getColumns(timeScale, showLegends)}
        data={tableData}
        sorted={sortColumns}
        onSortedChange={handleSortChange}
        onExpandedChange={handleRowExpansion(tableData)}
        loading={loading || innerLoading}
        LoadingComponent={LoadingIndicator}
        sortable={diffType === diffTypes.None}
        expanded={getExpandedIndicesFromIds(
          tableData,
          sortColumns,
          expandedPivotGroups
        )}
        SubComponent={renderInnerRow(
          allData,
          pivot,
          figureType,
          comparisonType,
          timeScale
        )}
      />
      {diffType !== diffTypes.None ? (
        <>
          <br />
          <div className="diff-table-legend-container">
            <span className="diff-color-indicator" />
            &nbsp; &nbsp;
            <span className="diff-text">
              Diff values beyond +/- {diffThreshold}%
            </span>
          </div>
        </>
      ) : null}
      <br />
    </>
  );
};

export { DataTable };
