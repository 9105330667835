import React from "react";
import { DisplayInvoiceLineObject } from "components/Deductions/DeductionsReconciliation/types/invoiceLineTypes";
import { setResolutionLineComment } from "components/Deductions/DeductionsReconciliation/ViewData/ViewTransactions/ResolveMultipleInvoiceLines/redux/ResolveMultipleInvoiceLinesSlice";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DisplayInvoiceObject } from "components/Deductions/DeductionsReconciliation/types/invoiceTypes";
import ActivityLogCommentBox, {
  CommentTaggedUser
} from "components/Deductions/DeductionsReconciliation/ActivityLog/ActivityLogCommentBox";
import { RootState } from "store";
import { CompanyUser } from "js/dbTypes";
import { AddEventConfig } from "components/Deductions/DeductionsReconciliation/ActivityLog/DRMEventService";
import { DRMEvent } from "components/Deductions/DeductionsReconciliation/ActivityLog/DRMEvent";
import { ResolutionLineActivityErrors } from "components/Deductions/DeductionsReconciliation/services/ResolveMultipleInvoiceLinesServices/resolveMultipleInvoiceLinesErrors";

interface MultiEditScreenCommentProps {
  displayInvoiceLine: DisplayInvoiceLineObject;
  displayInvoice: DisplayInvoiceObject;
  hasErrorsMap: Record<string, ResolutionLineActivityErrors>;
  showErrors: boolean;
  addEvent: (
    config: AddEventConfig,
    tags?: Record<string, number[]> | undefined
  ) => Promise<Partial<DRMEvent>>;
  companyUsers: Record<string, CompanyUser>;
  lineErrorMessagesMap: Record<string, Record<string, string>>;
}

const MultiEditScreenComment = (props: MultiEditScreenCommentProps) => {
  const {
    displayInvoiceLine,
    displayInvoice,
    hasErrorsMap,
    showErrors,
    companyUsers,
    addEvent,
    lineErrorMessagesMap
  } = props;
  const { resolutionLines } = useSelector(
    (state: RootState) => state.resolveMultipleInvoiceLines,
    shallowEqual
  );
  const dispatch = useDispatch();

  const { key: invoiceKey } = displayInvoice;
  const { key, invoiceLineKey } = displayInvoiceLine;
  const currentKey = key || invoiceLineKey;
  const resolutionLinesInvoice = resolutionLines[invoiceKey] ?? {};
  const resolutionLine = resolutionLinesInvoice[currentKey] ?? {};
  const { comment: resolutionComment } = resolutionLine;

  return (
    <ActivityLogCommentBox
      inputOnly
      addEvent={addEvent}
      companyUsers={companyUsers}
      initialValue={resolutionComment}
      TextFieldProps={{
        error: showErrors && hasErrorsMap[currentKey].missingComment,
        helperText:
          showErrors &&
          hasErrorsMap[currentKey].missingComment &&
          lineErrorMessagesMap[currentKey].missingComment
      }}
      onCommentChange={(comment: string, tags: CommentTaggedUser[]) => {
        dispatch(
          setResolutionLineComment({
            comment,
            invoiceKey,
            invoiceLineKey: currentKey,
            taggedUsers: tags
          })
        );
      }}
    />
  );
};

export default MultiEditScreenComment;
