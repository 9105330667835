import makeStyles from "@mui/styles/makeStyles";
import { useDb } from "contexts/Db";
import { InvoiceObject } from "components/Deductions/models";
import React from "react";
import { useParams } from "react-router-dom";
import DRMNoDataState from "components/Deductions/DeductionsReconciliation/NoData/DRMNoDataState";
import DRMBackButton from "components/Deductions/components/DRMBackButton";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "store";
import {
  DRMFeature,
  DRMRouteState
} from "components/Deductions/DeductionsReconciliation/redux/DRMSlice";
import DRMInvoiceProfile from "./DRMInvoiceProfile";

const useStyles = makeStyles(() => ({
  container: { fontFamily: "Oxygen" }
}));

const InvoiceProfilePage = (props: any): JSX.Element => {
  const classes = useStyles();
  const { key }: { key: string } = useParams<{ key?: string }>();
  const { guiMode } = useSelector(
    (state: RootState) => state.drm,
    shallowEqual
  );
  const db = useDb();
  const invoice: InvoiceObject = db.invoices[key];

  const noDataState = Boolean(!db.invoices[key]);

  return (
    <div className={classes.container}>
      {noDataState ? (
        <DRMNoDataState
          text="Sorry, we were unable to find this invoice!"
          backButton={
            <DRMBackButton
              text="View All Backups"
              toUrl={`/${DRMFeature.RECONCILIATION}/${DRMRouteState.INVOICES}/${guiMode}`}
            />
          }
        />
      ) : (
        <DRMInvoiceProfile
          invoice={invoice}
          invoiceKey={key}
          openClose={props.openClose}
        />
      )}
    </div>
  );
};

export default InvoiceProfilePage;
