import {
  ERPTransactionObject,
  InvoiceLineObject,
  InvoiceObject
} from "components/Deductions/models";
import { DbContextValues } from "contexts/Db";
import RepaymentLineObject from "deductions-models/RepaymentLineObject";
import { makeKeys, firebase, CATEGORIESMAP } from "helpers/Firebase";
import { DisplayRepaymentLineObject } from "reconciliation-types/repaymentLineTypes";
import { TransactionProcessingService } from "../ERPTransactionServices";
import {
  InvoiceLineDisplayService,
  InvoiceLineProcessingService
} from "../InvoiceLineServices";
import { InvoiceProcessingService } from "../InvoiceServices";

export function getCleanedRepaymentLine(
  repaymentLine: RepaymentLineObject | DisplayRepaymentLineObject
): DisplayRepaymentLineObject {
  return {
    ...repaymentLine,
    displayRepaymentId: null,
    displayDeductionId: null,
    displayInvoiceNumber: null,
    displayInvoiceLineNumber: null,
    displayAmount: null,
    displayFundType: null,
    displayFundTypeAccount: null,
    displayType: null
  };
}

export function createFirebaseRepaymentLines(
  newRepaymentLines: RepaymentLineObject[],
  repaymentKey: string,
  db: DbContextValues,
  callback?: (result: Record<string, RepaymentLineObject>) => void,
  errorCallback?: (message: string) => void
) {
  const { erpTransactions, companyid } = db;
  const repayment = erpTransactions[repaymentKey] || {};
  const { repaymentLines: existingRepaymentLines = {} } = repayment;

  let existingKeys = [...Object.keys(existingRepaymentLines)];
  const repaymentLinesUpdate: Record<string, RepaymentLineObject> = {};

  newRepaymentLines.forEach(repaymentLine => {
    const { amount } = repaymentLine;

    let [key] = makeKeys(1, null, 10);
    while (existingKeys.includes(key)) {
      [key] = makeKeys(1, null, 10);
    }

    existingKeys = [...existingKeys, key];

    const negAmount = amount > 0.0 ? -1 * amount : amount;

    repaymentLinesUpdate[key] = {
      ...repaymentLine,
      amount: negAmount,
      key,
      createdDate: new Date().toISOString(),
      createdUser: firebase.auth().currentUser?.uid || ""
    };
  });

  const companyDataUrl = `companies/${companyid}`;
  const url = `${companyDataUrl}${CATEGORIESMAP.TRANSACTIONS}${repaymentKey}/repaymentLines`;

  firebase
    .database()
    .ref(url)
    .update(repaymentLinesUpdate)
    .then(() => {
      if (callback) {
        callback(repaymentLinesUpdate);
      }
    })
    .catch(() => {
      if (errorCallback) {
        errorCallback(
          "Error in submitting repayment activity. Please try again."
        );
      }
    });
}

export function updateStatusesAfterRepaymentActivity(
  savedRepaymentLines: Record<string, RepaymentLineObject>,
  db: DbContextValues,
  currentInvoice: InvoiceObject,
  currentDeduction: ERPTransactionObject,
  currentRepayment: ERPTransactionObject,
  errorCallback?: (message: string) => void
) {
  const { erpTransactions = {} } = db;
  const { key: invoiceKey, invoiceLines = {} } = currentInvoice;
  const { key: repaymentKey } = currentRepayment;

  // update repayment first
  const updatedRepaymentLines: Record<string, RepaymentLineObject> = {
    ...currentRepayment.repaymentLines,
    ...savedRepaymentLines
  };

  const updatedRepayment: ERPTransactionObject = {
    ...currentRepayment,
    repaymentLines: updatedRepaymentLines,
    status: TransactionProcessingService.getUpdatedTransactionStatus(
      { ...currentRepayment, repaymentLines: updatedRepaymentLines },
      erpTransactions,
      db.invoices
    )
  };

  // simulate an updated db since that will affect how we update invoices / deductions
  const updatedDb: DbContextValues = {
    ...db,
    erpTransactions: {
      ...db.erpTransactions,
      [repaymentKey]: updatedRepayment
    }
  };

  // update each invoice line
  const invoiceLinesUpdate: Record<string, InvoiceLineObject> = {};
  Object.values(savedRepaymentLines).forEach(repaymentLine => {
    const { invoiceLineKey } = repaymentLine;
    const invoiceLine = invoiceLines[invoiceLineKey];
    const updatedInvoiceLine = {
      ...invoiceLine,
      status: InvoiceLineProcessingService.getUpdatedInvoiceLineStatus(
        InvoiceLineDisplayService.processInvoiceLineForDisplay(
          currentInvoice,
          invoiceLine,
          invoiceLineKey,
          updatedDb.meta.fundTypes ?? {},
          updatedDb.invoices,
          updatedDb.erpTransactions,
          updatedDb.allLines,
          updatedDb.products,
          updatedDb.customers
        ),
        updatedDb.invoices,
        updatedDb.erpTransactions,
        undefined,
        repaymentLine
      )
    };

    invoiceLinesUpdate[invoiceLineKey] = updatedInvoiceLine;
  });

  // update invoice itself
  const updatedInvoice = {
    ...currentInvoice,
    invoiceLines: {
      ...invoiceLines,
      ...invoiceLinesUpdate
    },
    status: InvoiceProcessingService.getUpdatedInvoiceStatus(
      {
        ...currentInvoice,
        invoiceLines: {
          ...invoiceLines,
          ...invoiceLinesUpdate
        }
      },
      updatedDb.erpTransactions
    )
  };

  // update the deduction itself
  const updatedDeduction = {
    ...currentDeduction,
    status: TransactionProcessingService.getUpdatedTransactionStatus(
      currentDeduction,
      updatedDb.erpTransactions,
      { ...db.invoices, [invoiceKey]: updatedInvoice }
    )
  };

  // write to Firebase in chain manner, need to refactor this to use async/await
  InvoiceProcessingService.updateFirebaseInvoice(
    updatedInvoice,
    () => {
      TransactionProcessingService.updateFirebaseTransaction(
        updatedDeduction,
        () => {
          TransactionProcessingService.updateFirebaseTransaction(
            updatedRepayment,
            undefined,
            () => {
              if (errorCallback) {
                errorCallback("Error in updating repayment status.");
              }
            }
          );
        },
        () => {
          if (errorCallback) {
            errorCallback("Error in updating repayment status.");
          }
        }
      );
    },
    () => {
      if (errorCallback) {
        errorCallback("Error in updating repayment status.");
      }
    }
  );
}
