import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compact } from "lodash";

import { displayMoney } from "helpers/DataProcessing";
import { processProm } from "helpers/Firebase";

import DataTable from "../tables/MuiDataTable";

const dateTypes = ["buyin", "scanback", "instore"];
const startFields = ["buyinStartDate", "scanbackStartDate", "instoreStartDate"];
const endFields = ["buyinEndDate", "scanbackEndDate", "instoreEndDate"];

const mapLine = (prom, fundTypes) => (lineKey, index) => {
  let earliestDate;
  let latestDate;

  const line = prom[lineKey];

  dateTypes.forEach((dateType, i) => {
    if (
      !fundTypes[line?.type]?.promCreationFields?.includes(dateType) ||
      !line[startFields[i]]
    ) {
      return;
    }
    if (
      !earliestDate ||
      new Date(line[startFields[i]]) < new Date(earliestDate)
    ) {
      earliestDate = line[startFields[i]];
    }

    if (!latestDate || new Date(line[endFields[i]]) > new Date(latestDate)) {
      latestDate = line[endFields[i]];
    }
  });

  earliestDate =
    earliestDate ||
    new Date(parseInt(prom.year), prom.month - 1, 1).toDateString();

  latestDate =
    latestDate || new Date(parseInt(prom.year), prom.month, 0).toDateString();

  return {
    ...line,
    startDate: earliestDate,
    endDate: latestDate,
    key: `L${index + 1}`
  };
};

export default function PromotionOverviewTable({ db }) {
  const [tableData, setTableData] = useState({ Field: [], Value: [] });

  const { lines, newContacts, prom } = useSelector(state => state.addPromotion);
  const dispatch = useDispatch();

  const overviewTableDetails = {
    "Promotion Name": "",
    Customer: "",
    "First Receiver": "",
    "Contact(s)": "",
    "Total Expected Spend": "",
    "Previous Total Spend Estimate": "",
    "Spend Rate": ""
  };

  useEffect(() => {
    const list = lines.map(mapLine(prom, db.meta.fundTypes ?? {}));

    list.reverse();

    const newProm = processProm({
      ...prom,
      lines: list
    });

    overviewTableDetails["Promotion Name"] = prom.name ? prom.name : "";
    overviewTableDetails.Customer = prom.customer?.name;
    overviewTableDetails["First Receiver"] =
      prom.distributor?.constructor === Array
        ? prom.distributor.join(", ")
        : prom.distributor;
    overviewTableDetails["Spend Rate"] = newProm.spendRate
      ? newProm.spendRate
      : "";
    overviewTableDetails["Total Expected Spend"] = newProm.totalExpSpend
      ? displayMoney(Math.round(newProm.totalExpSpend))
      : "";
    overviewTableDetails["Previous Total Spend Estimate"] =
      newProm.prevTotalExpSpend
        ? displayMoney(newProm.prevTotalExpSpend || 0)
        : "";

    const contacts = compact(newContacts?.map(c => prom[c]?.name));
    overviewTableDetails["Contact(s)"] = contacts.join(", ");

    const data = {
      Field: Object.keys(overviewTableDetails),
      Value: Object.values(overviewTableDetails)
    };

    setTableData(data);
  }, [prom, lines, newContacts]);

  return (
    <div>
      <DataTable title="Overview" data={tableData} />
    </div>
  );
}
