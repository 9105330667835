import React, { useState } from "react";
import RedeemIcon from "@mui/icons-material/Redeem";
import MenuItem from "@mui/material/MenuItem";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import Link from "ui-library/Link";
import useTooltip from "helpers/useTooltip";
import {
  StyledArrowDropDownIcon,
  StyledArrowDropUpIcon,
  StyledBox,
  StyledMenu
} from "./StyledComponents";

// const StyledRedeemIconWithBreaksInbetween = styled(RedeemIcon)(
//   ({ theme, stop }) => ({
//     "@keyframes jiggleEffect": {
//       "0%": { transform: "translateY(0%) scale(1.10, 0.90)" },
//       "25%": { transform: "translateY(-55%) scale(1, 1)" },
//       "27.5%": { transform: "translateY(-55%) rotate(15deg)" },
//       "30%": { transform: "translateY(-55%) rotate(-15deg)" },
//       "32.5%": { transform: "translateY(-55%) rotate(15deg)" },
//       "35%": { transform: "translateY(-55%) rotate(-15deg)" },
//       "50%": { transform: "translateY(0%) scale(1.10, 0.90)" },
//       100: { transform: "translateY(0%) scale(1, 1)" }
//     },
//     animation: stop ? "" : "jiggleEffect 3s infinite"
//   })
// );

type IconProp = {
  stop: boolean;
};

const StyledRedeemIcon = styled(RedeemIcon, {
  shouldForwardProp: prop => prop !== "stop"
})(({ stop }: IconProp) => ({
  "@keyframes jiggleEffect": {
    "0%": { transform: "translateY(0%) scale(1.10, 0.90)" },
    "50%": { transform: "translateY(-55%) scale(1, 1)" },
    "55%": { transform: "translateY(-55%) rotate(15deg)" },
    "60%": { transform: "translateY(-55%) rotate(-15deg)" },
    "65%": { transform: "translateY(-55%) rotate(15deg)" },
    "70%": { transform: "translateY(-55%) rotate(-15deg)" },
    "100%": { transform: "translateY(0%) scale(1.10, 0.90)" }
  },
  animation: stop ? "" : "jiggleEffect 1.5s 14",
  animationDelay: "2s"
}));

export default function GiftIcon() {
  const [openGiftMenu, setOpenGiftMenu] = useState(false);
  const [stopJiggle, setStopJiggle] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { open, handleTooltipClose, handleTooltipOpen } = useTooltip();

  const handleOpen = event => {
    setAnchorEl(event.currentTarget);
    setOpenGiftMenu(true);
    setStopJiggle(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenGiftMenu(false);
  };
  return (
    <div>
      <Tooltip
        open={open}
        onOpen={handleTooltipOpen}
        onClose={handleTooltipClose}
        disableInteractive
        disableFocusListener
        title="Gift Card">
        <StyledBox onClick={event => handleOpen(event)}>
          <StyledRedeemIcon stop={stopJiggle} />
          {openGiftMenu ? (
            <StyledArrowDropUpIcon />
          ) : (
            <StyledArrowDropDownIcon />
          )}
        </StyledBox>
      </Tooltip>
      <StyledMenu
        anchorEl={anchorEl}
        open={openGiftMenu}
        onClose={() => handleClose()}>
        <MenuItem onClick={() => handleClose()}>
          <Link variant="labelMedium" to="/referrals">
            Get a $1,000 Gift Card
          </Link>
        </MenuItem>
        <MenuItem onClick={() => handleClose()}>
          <Link
            variant="labelMedium"
            href="https://university.govividly.com/changelog"
            component="a"
            target="_blank">
            Recent Updates
          </Link>
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
