import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Divider, Typography, TextField } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import CheckIcon from "@mui/icons-material/Check";
import { firebase } from "helpers/Firebase";

class PasswordReset extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      params: this.props.params,
      page: "verifying",
      newPass: "",
      newPassConfirm: "",
      nonMatching: false,
      requirementsNotMet: false
    };

    this.requirementList = [
      "At least 8 characters",
      "At least one upper case letter",
      "At least one lower case letter",
      "At least one number",
      "At least one special character"
    ];
  }

  componentDidMount = () => {
    // validate onetime code passed in
    const actionCode = this.state.params.get("oobCode");
    if (!actionCode) {
      this.setState({
        page: "invalid"
      });
      return;
    }
    firebase
      .auth()
      .verifyPasswordResetCode(actionCode)
      .then(email => {
        this.setState({
          email,
          page: "passwordReset"
        });
      })
      .catch(error => {
        this.setState({
          page: "invalid"
        });
      });
  };

  verifyRequirements = str => {
    return [
      str.length >= 8,
      str.match(/[A-Z]/) != null,
      str.match(/[a-z]/) != null,
      str.match(/[0-9]/) != null,
      str.match(/[^a-zA-Z\d]/) != null
    ];
  };

  submitNewPassword = () => {
    this.setState({ nonMatching: false, requirementsNotMet: false });
    if (this.state.newPass != this.state.newPassConfirm) {
      this.setState({ nonMatching: true });
      return;
    }
    if (!this.verifyRequirements(this.state.newPass).every(e => e)) {
      this.setState({ requirementsNotMet: true });
      return;
    }

    const actionCode = this.state.params.get("oobCode");
    firebase
      .auth()
      .confirmPasswordReset(actionCode, this.state.newPass)
      .then(resp => {
        this.setState({ page: "success" });
      })
      .catch(error => {
        this.setState({ page: "unknown" });
      });
  };

  requirementsJSX = () => {
    const satisfiedReqs = this.verifyRequirements(this.state.newPass);
    const elements = [<h4 key="header"> Password Requirements: </h4>];
    for (const i in satisfiedReqs) {
      if (satisfiedReqs[i]) {
        elements.push(
          <p key={i}>
            <CheckIcon />
            {this.requirementList[i]}
          </p>
        );
      } else {
        elements.push(
          <p key={i}>
            <ClearIcon />
            {this.requirementList[i]}
          </p>
        );
      }
    }

    return elements;
  };

  resetPasswordJSX = () => {
    return (
      <div>
        <Typography color="textPrimary" gutterBottom variant="h4">
          Reset Password
        </Typography>
        <Divider style={{ marginTop: 24, marginBottom: 24 }} />
        {this.requirementsJSX()}
        <TextField
          autoFocus
          fullWidth
          label="Enter New Password"
          margin="normal"
          name="password"
          type="password"
          variant="outlined"
          value={this.state.newPass}
          onChange={event => this.setState({ newPass: event.target.value })}
          error={this.state.requirementsNotMet}
          helperText={
            this.state.requirementsNotMet
              ? "Your password must satisfy all the requirements above."
              : null
          }
        />
        <TextField
          autoFocus
          fullWidth
          label="Confirm New Password"
          margin="normal"
          name="passwordconfirm"
          type="password"
          variant="outlined"
          value={this.state.newPassConfirm}
          onChange={event =>
            this.setState({ newPassConfirm: event.target.value })
          }
          error={this.state.nonMatching}
          helperText={this.state.nonMatching ? "Passwords must match." : null}
        />
        <Box style={{ marginTop: 16 }}>
          <Button
            color="primary"
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={this.submitNewPassword}
            style={{
              textTransform: "none",
              fontWeight: 400,
              fontFamily: "Arial",
              fontSize: 16
            }}>
            Reset Password
          </Button>
        </Box>
      </div>
    );
  };

  successScreenJSX = () => {
    return (
      <div>
        <Typography color="textPrimary" gutterBottom variant="h4">
          Success!
        </Typography>
        <Divider style={{ marginTop: 24, marginBottom: 24 }} />
        <p>
          Your password has been reset. Please <Link to="/">sign in</Link> to
          continue using Vividly.
        </p>
      </div>
    );
  };

  invalidLinkJSX = () => {
    return (
      <div>
        <Typography color="textPrimary" gutterBottom variant="h4">
          Invalid Link
        </Typography>
        <Divider style={{ marginTop: 24, marginBottom: 24 }} />
        <p>Your password reset link is invalid. Please request another one.</p>
      </div>
    );
  };

  unknownErrorJSX = () => {
    return (
      <div>
        <Typography color="textPrimary" gutterBottom variant="h4">
          Unknown Error
        </Typography>
        <Divider style={{ marginTop: 24, marginBottom: 24 }} />
        <p>
          We're sorry, Vividly has encountered an unknown error with your
          request. Please try requesting a new reset link.
        </p>
      </div>
    );
  };

  render = () => {
    switch (this.state.page) {
      case "verifying":
        return (
          <Typography color="textPrimary" gutterBottom variant="h4">
            Please Wait...
          </Typography>
        );
        break;
      case "invalid":
        return this.invalidLinkJSX();
        break;
      case "passwordReset":
        return this.resetPasswordJSX();
        break;
      case "success":
        return this.successScreenJSX();
        break;
      default:
        return this.unknownErrorJSX();
    }
  };
}

export default PasswordReset;
