import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import MuiAccordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles(() => ({
  summary: {
    padding: "10px 0px",
    fontSize: "0.9rem"
  },
  secondarySummary: {
    padding: "10px 0px",
    fontSize: "0.8rem"
  },
  sectionContainer: {
    padding: 10,
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  accordion: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    boxShadow: "none",
    borderRadius: 3,

    "&::before": {
      display: "none"
    }
  },
  accordionSummary: {
    maxHeight: 50,
    backgroundColor: "rgb(245, 245, 245)",

    "& > div": {
      display: "flex",
      justifyContent: "space-between"
    }
  }
}));

function Accordion({ summary, id, children }) {
  const classes = useStyles();

  return (
    <MuiAccordion className={classes.accordion}>
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${id}-content`}
        id={`${id}-header`}>
        <Typography className={classes.summary}>{summary}</Typography>
      </AccordionSummary>
      {children}
    </MuiAccordion>
  );
}

function AccordionContent({ children }) {
  const classes = useStyles();

  return (
    <AccordionDetails>
      <div className={classes.sectionContainer}>{children}</div>
    </AccordionDetails>
  );
}

Accordion.Content = AccordionContent;

export default Accordion;
