import React from "react";
import Button from "ui-library/Button";
import Authenticate from "components/WebsiteElements/Authenticate";

class Activate2FA extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stepIndex: 0
    };
  }

  getStepContent = () => {
    const { stepIndex } = this.state;
    switch (stepIndex) {
      case 0:
        return (
          <div>
            <p>Protect your account with two-factor authentication.</p>
            <p>
              Each time you sign in, you'll need your password and a
              verification code sent to you through SMS.
            </p>
            <Button
              label="ACTIVATE"
              onClick={() => {
                this.setState({ stepIndex: stepIndex + 1 });
              }}
            />
          </div>
        );
      case 1:
        return (
          <Authenticate
            type="enroll"
            cancelCallback={() => {
              this.setState({ stepIndex: 0 });
            }}
            successCallback={() => {
              this.props.update2FAState(true);
            }}
            openClose={this.props.openClose}
          />
        );
    }
  };

  render() {
    return <div>{this.getStepContent()}</div>;
  }
}

export default Activate2FA;
