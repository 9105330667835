import config from "./config";

const {
  projectId,
  cloudRunURL,
  forecastCloudRunURL,
  mainFirebaseApp,
  forecastFirebaseApp,
  cloudRunFunctionURL
} = config;
const MUILicenseKey =
  "05c90e79c607255eb2188a814bdf5482T1JERVI6MzAyNTQsRVhQSVJZPTE2NjQ3Mzg5OTEwMDAsS0VZVkVSU0lPTj0x";
const sentryDSN =
  "https://bd664d5abac745dea77c02191117b05f@o75046.ingest.sentry.io/6075010";
const intercomAppID = "fad3bvij";
const fullStoryOrgId = "o-1D6G72-na1";

export {
  projectId,
  cloudRunURL,
  forecastCloudRunURL,
  cloudRunFunctionURL,
  mainFirebaseApp,
  forecastFirebaseApp,
  MUILicenseKey,
  sentryDSN,
  intercomAppID,
  fullStoryOrgId
};
