import React from "react";
import classNames from "classnames";

import Tooltip from "@mui/material/Tooltip";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import RefreshIcon from "@mui/icons-material/Refresh";

import Subheader from "ui-library/Subheader";
import Dialog from "ui-library/Dialog";
import MenuItem from "ui-library/MenuItem";
import Button from "ui-library/Button";
import IconButton from "ui-library/IconButton";
import Select from "ui-library/Select";

import { Divider } from "pui-react-dividers";

import axios from "axios";
import { sum } from "lodash-es";
import { adjustDateForTrailingWeek, getDateTime } from "helpers/Time";
import { sortByField, objToArray } from "helpers/sortByDate";
import {
  cloudRunURL,
  getJSXListsFirebase,
  getFirebase
} from "helpers/Firebase";
import Card from "ui-library/Card";
import { LinearProgress, Stack } from "@mui/material";
import { grey } from "@mui/material/colors";
import {
  getStores,
  getVelocity,
  getBaseSales,
  getForecastData
} from "./ForecastFirebase";

import { ActualsVsForecastComparison } from "./ActualsVsForecast";
import DisplayTable from "./DisplayTable";

const grey200 = grey["200"];
const grey400 = grey["400"];
const grey700 = grey["700"];

const styles = {
  notifs: {
    width: "500px"
  },
  input: {
    margin: 5
  },
  loading: {
    height: $(window).height()
  },
  tabs: {
    backgroundColor: grey700
  },
  tabsBox: {
    borderColor: grey700,
    borderWidth: 2,
    borderStyle: "solid"
  },
  toolbarButton: {
    marginLeft: 5,
    marginRight: 5
  },
  divider: {
    margin: "10px auto",
    width: "50%"
  },
  radioButton: {
    marginBottom: 16
  },
  textfield: {
    "white-space": "pre-line"
  },
  modalTitle: {
    borderStyle: "solid",
    borderWidth: 0,
    borderBottomWidth: 2,
    borderBottomColor: grey200
  },
  modalActions: {
    borderStyle: "solid",
    borderWidth: 0,
    borderTopWidth: 2,
    borderTopColor: grey200
  },
  dialogRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 0,
    paddingBottom: 10,
    zIndex: 1200
  },
  dialogContent: {
    position: "relative",
    minWidth: "95vw",
    maxWidth: "95vw",
    transform: ""
  },
  dialogBody: {
    paddingBottom: 0,
    minHeight: Math.min(750, $(window).height())
  }
};

class ForecastTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSearch: false,
      searchQuery: "",
      forecastModalOpen: false,
      avfModalOpen: false,
      category: 1,
      accounts: [],
      customersJSX: [],
      distributorsJSX: [],
      year: new Date().getFullYear(),
      time: "week",
      mode: "retailer",
      view: "sales",
      pivot: "customer",
      byDate: false,
      tableRows: [],
      hideOptions: false,
      selectedIndex: 0,
      forecastData: {},
      stores: {},
      velocity: {},
      baseSales: {},
      seasonality: {},
      weeks: [],
      header: [],
      yearsJSX: [],
      dbRefs: [],
      // order: [],
      percentComplete: 0,
      heatmap: "None"
    };
  }

  closeFilePicker = () => {
    this.setState({
      openFilePicker: false
    });
  };

  updateData = (table, sortedData) => {
    const state = {};
    state[table] = sortedData;
    this.setState(state);
  };

  handleResetFields = () => {
    this.setState(
      {
        year: new Date().getFullYear(),
        customer: "All"
        // order: []
      },
      this.getTableData
    );
  };

  updateData = (table, sortedData) => {
    const state = {};
    state[table] = sortedData;
    this.setState(state);
  };

  getCustomersJSX = customers => {
    const customersList = sortByField("name", objToArray(customers), true);
    const customersJSX = [];
    for (let i = 0; i < customersList.length; i++) {
      const customer = customersList[i];
      customersJSX.push(
        <MenuItem value={customer.key} key={customer.key}>
          {customer.name}
        </MenuItem>
      );
    }
    return customersJSX;
  };

  sortTable = (table, order) => {
    const entry = this.state.pivot == "customer" ? "product" : "customer";
    const sortedTable = {};
    for (const field in table) {
      const tableField = table[field];
      var tableDict = {};
      for (const row of tableField) {
        tableDict[row[entry]] = row;
      }
      sortedTable[field] = order.map(val => tableDict[val]);
    }
    return sortedTable;
  };

  getMetrics = () => {
    if (this.state.sectionKey == "Total") {
      return ["revenue", "sales"];
    }
    if (this.state.mode == "retailer") {
      return [
        "sales",
        "is_promotion",
        "lift",
        "seasonality",
        "stores",
        "velocity",
        "revenue"
      ];
    }
    if (this.state.mode == "all_other") {
      return ["sales", "seasonality", "baseSales", "revenue"];
    }
    return ["sales", "is_promotion", "lift", "revenue"];
  };

  getFieldNames = () => {
    if (this.state.sectionKey == "Total") {
      return {
        sales: "Sales (Units)",
        revenue: "Revenue"
      };
    }
    if (this.state.mode == "retailer") {
      return {
        revenue: "Revenue",
        is_promotion: "Promotion (Y/N)",
        lift: "Lift",
        stores: "Stores",
        velocity: "Base Velocity (USWI)",
        sales: "Sales (Units)",
        seasonality: "Seasonality"
      };
    }
    if (this.state.mode == "all_other") {
      return {
        revenue: "Revenue",
        baseSales: "Base Sales",
        seasonality: "Seasonality",
        sales: "Sales (Units)"
      };
    }
    return {
      revenue: "Revenue",
      is_promotion: "Promotion (Y/N)",
      lift: "Lift",
      sales: "Sales (Units)"
    };
  };

  createForecastTable = () => {
    const {
      weeks,
      productList,
      customerList,
      year,
      forecastTable,
      stores,
      velocity,
      baseSales,
      sectionKey,
      pivot
    } = this.state;
    // a bunch of variables we need to define
    const itemsList = pivot == "customer" ? productList : customerList;
    const fieldNames = this.getFieldNames();

    const missingValues = {
      revenue: "-",
      velocity: 0,
      sales: "-",
      stores: 0,
      is_promotion: "-",
      lift: "-",
      seasonality: "-"
    };
    const forecastBeginDates = {};
    const parsedForecastData = {};
    const enteredValues = { velocity: {}, stores: {}, baseSales: {} };
    if (forecastTable === undefined) {
      return [forecastBeginDates, undefined, enteredValues];
    }
    if ("error" in forecastTable || weeks.length === 0) {
      return [
        forecastBeginDates,
        { error: forecastTable.error },
        enteredValues
      ];
    }
    // weeks used in selected forecast
    const usedWeeks =
      year === "All"
        ? weeks
        : weeks.filter(
            week =>
              new Date(adjustDateForTrailingWeek(week)).getFullYear() === year
          );

    const forecastBegin = forecastTable.forecastBegin || {};
    // create data to be passed to DisplayTable
    for (const field in fieldNames) {
      const fieldData = forecastTable[field] || [];
      parsedForecastData[field] = [];
      for (const key of itemsList) {
        // create row to be displayed in forecast table
        var parsedRow = {};
        parsedRow[pivot == "customer" ? "product" : "customer"] = key;
        if (field == "seasonality") {
          const itemSeasonality =
            this.state.seasonality[pivot == "customer" ? key : sectionKey] ||
            {};
          for (const week of weeks) {
            parsedRow[week] =
              itemSeasonality[week] ||
              fieldData?.[key]?.[week] ||
              missingValues[field];
          }
        } else {
          const row = fieldData[key] || {};
          var forecastBegins = new Date(forecastBegin[key] || null);
          weeks.forEach(week => {
            if (week in row) {
              if (field === "is_promotion") {
                parsedRow[week] = row[week] ? "Y" : "N";
              } else {
                parsedRow[week] = row[week];
              }
            } else {
              parsedRow[week] = missingValues[field];
            }
          });
        }
        // compute additional fields that we need for the table
        for (const [tableField, dataObj] of [
          ["stores", stores],
          ["velocity", velocity],
          ["baseSales", baseSales]
        ]) {
          if (field == tableField && key in dataObj) {
            const enteredData = dataObj[key];
            enteredValues[field][key] = new Set(Object.keys(enteredData));
          }
        }
        if (field == "sales") {
          forecastBeginDates[key] = forecastBegins;
        }
        parsedRow.metric = fieldNames[field];
        parsedRow.name =
          pivot == "customer"
            ? this.props.db.products[key]?.name
            : this.props.db.customers[key]?.name;
        // compute Total field
        if (["stores", "sales", "revenue"].includes(field)) {
          parsedRow.Total = sum(
            usedWeeks.map(week => parseInt(parsedRow[week]) || 0)
          );
          if (
            typeof parsedRow.Total === "string" &&
            parsedRow.Total.includes("-")
          ) {
            parsedRow.Total = "N/A";
          }
        } else {
          parsedRow.Total = "N/A";
        }
        parsedForecastData[field].push(parsedRow);
      }
    }

    return [forecastBeginDates, parsedForecastData, enteredValues];
  };

  fetchForecastTable = async () => {
    const { companyid } = this.props.db;
    const { sectionKey, mode, pivot } = this.state;
    const payload = {
      company_id: companyid,
      table_name: mode,
      filter_key: sectionKey,
      filter_by: pivot
    };
    axios
      .post(`${cloudRunURL}/api/read_forecast`, { data: payload })
      .then(response => {
        const { data } = response.data;
        this.setState({
          forecastTable: data.forecast_table,
          getTableDataLoading: false
        });
      })
      .catch(e => {
        console.error(e);
        this.props.openClose.showSnack(
          "Error loading forecast - please refresh the page or contact support if this error persists."
        );
      });
  };

  getTableData = async () => {
    const { companyid } = this.props.db;
    const { sectionKey, dbRefs, mode, pivot, forecastTable } = this.state;
    if (this.props.loading && forecastTable) return;
    await this.fetchForecastTable();
    // disconnect previous references to database to prevent additional data retrieval
    dbRefs.forEach(ref => ref.off());
    // retrieve non-distributor data
    if (mode === "retailer") {
      getStores(companyid, pivot, sectionKey, storesSnapshot => {
        const stores = storesSnapshot.val();
        const storesRef = storesSnapshot.ref;
        getVelocity(companyid, pivot, sectionKey, velocitySnapshot => {
          const velocity = velocitySnapshot.val();
          const velocityRef = velocitySnapshot.ref;
          this.setState({
            velocity: velocity || {},
            stores: stores || {},
            baseSales: {},
            dbRefs: [storesRef, velocityRef]
          });
        });
      });
    } else if (mode === "all_other") {
      getBaseSales(companyid, pivot, sectionKey, baseSalesSnapshot => {
        const baseSales = baseSalesSnapshot.val();
        const baseSalesRef = baseSalesSnapshot.ref;
        this.setState({
          stores: {},
          velocity: {},
          baseSales: baseSales || {},
          dbRefs: [baseSalesRef]
        });
      });
    } else {
      this.setState({
        velocity: {},
        stores: {},
        dbRefs: []
      });
    }
  };

  getSectionKey = (pivot, mode) => {
    /*
    remember key for each section of the table,
    depending on view (product/customer) and mode (retailer, all-other, distributor)
    */
    const key = this.state[`${pivot}_${mode}_key`];
    if (!key) {
      return "Total";
    }
    return key;
  };

  openAVF = (customer, product) => {
    const customerName =
      customer == "Total" ? "Total" : this.props.db.customers[customer].name;
    const productName =
      product == "Total" ? "Total" : this.props.db.products[product].name;
    this.props.openClose.setAppModal(
      `Actuals vs. Forecast - ${customerName}/${productName}`,
      <ActualsVsForecastComparison
        db={this.props.db}
        customer={customer}
        product={product}
        mode={this.state.mode}
        weeks={this.state.weeks}
        loading={this.props.loading}
      />
    );
  };

  processSeasonality = enteredSeasonality => {
    const seasonality = {};
    const seasonalityBuckets = this.props.db.meta.seasonality_buckets || {};
    for (const bucket in enteredSeasonality) {
      const bucketProds = seasonalityBuckets[bucket];
      for (const product of bucketProds) {
        seasonality[product] = {};
        for (const week in enteredSeasonality[bucket]) {
          seasonality[product][week] =
            enteredSeasonality[bucket][week].seasonality_index;
        }
      }
    }
    return seasonality;
  };

  getForecastToolbarJSX = metrics => {
    const fieldNames = this.getFieldNames();
    return (
      <div className="rowC" style={{ marginTop: -20 }}>
        <Button
          label={
            <Tooltip title="Expand Forecast Table">
              <OpenWithIcon />
            </Tooltip>
          }
          backgroundColor={grey200}
          hoverColor={grey400}
          onClick={() => this.setState({ forecastModalOpen: true })}
          style={{ height: 36, marginTop: 20 }}
        />
        <Select
          floatingLabelText="View"
          value={this.state.view}
          onChange={(event, index, value) => {
            this.setState({ view: value });
          }}
          style={{ marginLeft: 15, marginBottom: 5 }}>
          {metrics.map(metric => (
            <MenuItem key={metric} value={metric}>
              {fieldNames[metric]}
            </MenuItem>
          ))}
        </Select>
        <Select
          floatingLabelText="Heatmap"
          value={this.state.heatmap}
          onChange={(event, index, value) => {
            this.setState({ heatmap: value });
          }}
          style={{ marginLeft: 15, marginBottom: 5 }}>
          {["None", "Show Coloring"].map(view => (
            <MenuItem key={view} value={view}>
              {view}
            </MenuItem>
          ))}
        </Select>
        <div
          style={{
            marginTop: 30,
            marginLeft: 10
          }}>
          Displayed dates are week-ending
        </div>
        <div
          style={{
            marginLeft: "40%",
            marginTop: 30
          }}>
          {this.state.forecastUpdated}
        </div>
      </div>
    );
  };

  updateFields = props => {
    // retrieve forecast metadata
    const { companyid } = props.db;
    getFirebase(21, seasonality => {
      this.setState({ seasonality: this.processSeasonality(seasonality) });
    });
    getForecastData(companyid, "meta", metaSnapshot => {
      let meta = metaSnapshot.val();
      meta = meta || {};
      getJSXListsFirebase(
        props.db,
        allJSX => {
          const { productsJSX } = allJSX;
          this.setState({
            productsJSX: allJSX.productsJSX
          });
          const tableNames = {
            retailer: "retailers",
            all_other: "all_other_buckets",
            distributor: "distributors"
          };
          const customerKeys = meta[tableNames[props.mode]] || [];
          const customers = {};
          for (const customerKey of customerKeys) {
            customers[customerKey] = props.db.customers[customerKey];
          }
          const customersJSX = this.getCustomersJSX(customers);
          const sectionKey = this.getSectionKey(props.pivot, props.mode);
          // process weeks
          const weeks = meta.weeks || [];
          const years = Array.from(
            new Set(weeks.map(x => new Date(x).getFullYear()))
          );
          years.sort();
          const yearsJSX = [];
          for (const year of years) {
            yearsJSX.push(
              <MenuItem value={year} key={year}>
                {year}
              </MenuItem>
            );
          }
          const productList = meta.products;
          if (meta.forecastUpdated) {
            var forecastUpdated = `Last Updated: ${getDateTime(
              new Date(meta.forecastUpdated)
            )}`;
          } else {
            var forecastUpdated =
              "Click 'Update Forecast' to generate a forecast";
          }
          // set state
          const update = {
            weeks,
            customersJSX,
            productsJSX,
            yearsJSX,
            productList,
            customerList: customerKeys,
            forecastUpdated,
            sectionKey,
            mode: props.mode,
            pivot: props.pivot,
            percentComplete: meta.percentComplete
          };
          update[`${props.pivot}_${props.mode}_key`] = sectionKey;
          if (
            this.state.pivot != props.pivot ||
            this.state.mode != props.mode
          ) {
            update.order = [];
            update.getTableDataLoading = true;
          }
          this.setState(update, this.getTableData);
        },
        null,
        false
      );
    });
  };

  componentDidMount() {
    this.updateFields(this.props);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.mode != prevProps.mode ||
      this.props.pivot != prevProps.pivot ||
      (!this.props.loading && prevProps.loading)
    ) {
      this.updateFields(this.props);
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    const [forecastBeginDates, forecastData, enteredValues] =
      this.createForecastTable();
    const metrics = this.getMetrics();
    const panelName = this.props.pivot == "customer" ? "Customer" : "Product";

    return (
      <div>
        <div
          className={classNames("analytics-options", {
            hidden: this.state.hideOptions
          })}
          style={{ overflow: "auto" }}>
          <Stack>
            <Subheader>Filter Inputs</Subheader>
            <div>
              <IconButton
                color="tonal"
                isBackgroundColor
                tooltip="Clear Filters"
                onClick={this.handleResetFields}
                size="large">
                <RefreshIcon />
              </IconButton>
            </div>
          </Stack>
          <br />
          <div>
            <div className="row">
              <div className="col-lg-6">
                <Card title={panelName}>
                  <Select
                    floatingLabelText={panelName}
                    value={this.state.sectionKey}
                    onChange={(event, index, value) => {
                      const update = {
                        sectionKey: value,
                        view: "sales",
                        order: []
                      };
                      update[`${this.state.pivot}_${this.state.mode}_key`] =
                        value;
                      this.setState(update, this.getTableData);
                    }}
                    fullWidth
                    style={styles.input}
                    MenuProps={{
                      getContentAnchorEl: null
                    }}>
                    <MenuItem children={<b>Total</b>} value="Total" />
                    {this.state.pivot == "customer"
                      ? this.state.customersJSX
                      : this.state.productsJSX}
                  </Select>
                </Card>
              </div>
              <div className="col-lg-6">
                <Card title="Year">
                  <Select
                    floatingLabelText="Year"
                    value={this.state.year}
                    onChange={(event, index, value) => {
                      this.setState(
                        {
                          year: value,
                          order: []
                        },
                        this.getTableData
                      );
                    }}
                    fullWidth
                    style={styles.input}
                    MenuProps={{
                      getContentAnchorEl: null
                    }}>
                    <MenuItem value="All" key="wildcard">
                      All
                    </MenuItem>
                    {this.state.yearsJSX}
                  </Select>
                </Card>
              </div>
            </div>
          </div>
        </div>
        <Divider />
        <div className="centering">
          <div
            className="options-toggle-button"
            onClick={() => {
              this.setState({ hideOptions: !this.state.hideOptions });
            }}>
            {this.state.hideOptions ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowUpIcon />
            )}
          </div>
        </div>
        <br />
        <div>
          <br />
          {forecastData === undefined ? (
            <LinearProgress />
          ) : forecastData.error ? (
            <div className="centering">
              Click the 'Update Forecast' button to create a forecast.
            </div>
          ) : (
            <div>
              <div>
                {this.getForecastToolbarJSX(metrics)}
                <DisplayTable
                  key={this.state.mode}
                  mode={this.state.mode}
                  data={forecastData}
                  view={this.state.view}
                  openClose={this.props.openClose}
                  loading={this.props.loading}
                  percentComplete={this.state.percentComplete}
                  seasonality={this.state.seasonality}
                  readOnly={this.props.readOnly}
                  forecastBeginDates={forecastBeginDates}
                  enteredValues={enteredValues}
                  time={this.state.time}
                  weeks={this.state.weeks}
                  metrics={metrics}
                  header="Forecasted Sales Units"
                  updateData={this.updateData}
                  db={this.props.db}
                  pivot={this.state.pivot}
                  sectionKey={this.state.sectionKey}
                  year={this.state.year}
                  openAVF={this.openAVF}
                  heatmap={this.state.heatmap}
                  fetchTable={this.getTableData}
                  getTableDataLoading={this.state.getTableDataLoading}
                />
              </div>
              <Dialog
                dialogTitle="Forecast"
                open={this.state.forecastModalOpen}
                onClose={() => this.setState({ forecastModalOpen: false })}
                titleStyle={styles.modalTitle}
                // actionsContainerStyle={styles.modalActions}
                // contentStyle={styles.dialogContent}
                // bodyStyle={styles.dialogBody}
                style={styles.dialogRoot}
                paperProps={{ overflowY: "auto" }}
                fullWidth
                maxWidth="lg">
                <div
                  style={{
                    height: 750,
                    display: "flex",
                    flexDirection: "column"
                  }}>
                  <div className="rowC" style={{ paddingTop: 20 }}>
                    {this.getForecastToolbarJSX(metrics)}
                  </div>
                  <DisplayTable
                    key={this.state.mode}
                    mode={this.state.mode}
                    data={forecastData}
                    view={this.state.view}
                    openClose={this.props.openClose}
                    loading={this.props.loading}
                    percentComplete={this.state.percentComplete}
                    seasonality={this.state.seasonality}
                    readOnly={this.props.readOnly}
                    forecastBeginDates={forecastBeginDates}
                    enteredValues={enteredValues}
                    time={this.state.time}
                    weeks={this.state.weeks}
                    metrics={metrics}
                    header="Forecasted Sales Units"
                    updateData={this.updateData}
                    db={this.props.db}
                    pivot={this.state.pivot}
                    sectionKey={this.state.sectionKey}
                    year={this.state.year}
                    openAVF={this.openAVF}
                    heatmap={this.state.heatmap}
                    fetchTable={this.getTableData}
                    getTableDataLoading={this.state.getTableDataLoading}
                  />
                </div>
              </Dialog>
              <Dialog
                dialogTitle="Forecast"
                open={this.state.avfModalOpen}
                onClose={() => this.setState({ forecastModalOpen: false })}
                titleStyle={styles.modalTitle}
                actionsContainerStyle={styles.modalActions}
                contentStyle={styles.dialogContent}
                bodyStyle={styles.dialogBody}
                style={styles.dialogRoot}
                paperProps={{ overflowY: "auto" }}
                maxWidth="lg">
                <ActualsVsForecastComparison
                  db={this.props.db}
                  customer={this.state.customer}
                  product={this.state.product}
                  mode={this.state.mode}
                  weeks={this.state.weeks}
                  loading={this.props.loading}
                />
              </Dialog>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ForecastTable;
