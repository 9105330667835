import React from "react";
import Button from "ui-library/Button";
import TextField from "ui-library/TextField";
import Card from "@mui/material/Card";
import ReactPhoneInput from "react-phone-input-mui";
import { firebase } from "helpers/Firebase";
import {
  verifySMSEnroll,
  sendSMSEnrollCode,
  sendSMSVerificationCode,
  verifySMSLogin
} from "helpers/SmsVerification";
import { red, green } from "@mui/material/colors";

const red400 = red["400"];
const green500 = green["500"];

const styles = {
  card: {
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  cancel: {
    color: red400
  },
  next: {
    color: green500
  }
};

/* Authenticate.js
  props:
    - type
        - "enroll": flow for enrolling in 2FA 
            - flow of stepIndex: 0 --> 1 --> 2
        - "verify": flow for verifying 2FA login (reusable component for verifying user within account settings)
            - flow of stepIndex: 0 --> 2
    - stepIndex
        - 0: enter password
        - 1: enter phone
        - 2: enter SMS code
    - cancelCallback
    - successCallback
    - openClose
*/

class Authenticate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stepIndex: this.props.stepIndex || 0,
      password: "",
      passwordErrorText: "",
      phoneNumber: "",
      phoneErrorText: "",
      verificationId: "",
      verificationCode: "",
      codeErrorText: "",
      errorResolver: null
    };
  }

  getStepToolbar = (handleNext, finish = false) => {
    return (
      <div className="centering">
        <Button
          label="Cancel"
          style={styles.cancel}
          onClick={() => {
            this.props.cancelCallback();
            this.setState({
              phoneNumber: "",
              phoneErrorText: ""
            });
          }}
        />
        <Button
          label={finish ? "Finish" : "Next"}
          style={styles.next}
          onClick={handleNext}
        />
      </div>
    );
  };

  getPasswordJSX = () => {
    return (
      <div>
        <div>
          Re-enter your password to verify it's you.
          <TextField
            fullWidth
            label="Password"
            margin="normal"
            name="password"
            type="password"
            variant="outlined"
            value={this.state.password}
            onKeyPress={e => {
              if (e.key === "Enter") {
                this.verifyPassword();
              }
            }}
            onChange={event => {
              this.setState({ password: event.target.value });
            }}
            error={Boolean(this.state.passwordErrorText)}
            helperText={this.state.passwordErrorText}
          />
        </div>
        {this.getStepToolbar(this.verifyPassword)}
      </div>
    );
  };

  verifyPassword = () => {
    // Verify password, then
    //    if "enroll": move to stepIndex 1
    //    if "verify": catch "multi-factor-auth-required" error and send SMS code, skip to stepIndex 2
    const user = firebase.auth().currentUser;
    const cred = firebase.auth.EmailAuthProvider.credential(
      user.email,
      this.state.password
    );
    return user
      .reauthenticateWithCredential(cred)
      .then(userCredential => {
        if (this.props.type == "verify") {
          this.props.successCallback();
        } else if (this.props.type == "enroll") {
          this.setState({
            stepIndex: this.state.stepIndex + 1,
            password: "",
            passwordErrorText: ""
          });
        }
      })
      .catch(error => {
        if (error.code == "auth/multi-factor-auth-required") {
          // send SMS code and move to stepIndex 2
          const { resolver } = error;
          const selectedIndex = 0;
          // Check mode of 2FA - for now only 1 option (phone)
          if (
            resolver.hints[selectedIndex].factorId ===
            firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID
          ) {
            const multiFactorHint = resolver.hints[selectedIndex];
            sendSMSVerificationCode(
              window.recaptchaVerifier,
              multiFactorHint,
              resolver
            ).then(verificationId => {
              // var phoneNumber = resolver.hints[selectedIndex].phoneNumber; // this is hidden phone number (only displaying last few digits)
              const { phoneNumber } =
                user.multiFactor.enrolledFactors[selectedIndex];
              this.setState({
                errorResolver: resolver,
                verificationId,
                phoneNumber,
                stepIndex: this.state.stepIndex + 2,
                password: "",
                passwordErrorText: "",
                actionTime: new Date()
              });
            });
          }
        } else if (error.code == "auth/wrong-password") {
          this.setState({ passwordErrorText: "Incorrect password" });
        } else {
          console.error(error);
          this.setState({ passwordErrorText: "Unknown error" });
        }
      });
  };

  getPhoneJSX = () => {
    return (
      <div>
        <div> What phone number do you want to use? </div>
        <div> (Include country code) </div>
        <ReactPhoneInput
          value={this.state.phoneNumber}
          defaultCountry="us"
          onChange={phoneNumber => this.setState({ phoneNumber })}
          component={TextField}
          inputExtraProps={{
            autoComplete: "phone",
            label: "Phone Number",
            error: Boolean(this.state.phoneErrorText),
            helperText: this.state.phoneErrorText,
            onKeyPress: e => {
              if (e.key == "Enter") {
                this.verifyPhone();
              }
            },
            style: {
              boxShadow: "none",
              height: "100%",
              marginTop: "3px",
              marginBottom: "6px"
            }
          }}
        />
        {this.getStepToolbar(this.verifyPhone)}
      </div>
    );
  };

  cleanPhoneNumber = phoneNumber => {
    const validChars = "+0123456789";
    let result = "";
    for (const char of phoneNumber) {
      if (validChars.includes(char)) {
        result += char;
      }
    }
    return result;
  };

  verifyPhone = () => {
    // Verify phone number and send SMS code
    const user = firebase.auth().currentUser;
    const phoneNumber = this.cleanPhoneNumber(this.state.phoneNumber);
    user.multiFactor.getSession().then(multiFactorSession => {
      return sendSMSEnrollCode(
        window.recaptchaVerifier,
        phoneNumber,
        multiFactorSession
      )
        .then(verificationId => {
          this.setState({
            verificationId,
            stepIndex: this.state.stepIndex + 1,
            actionTime: new Date()
          });
        })
        .catch(error => {
          if (error.code == "auth/invalid-phone-number") {
            this.setState({ phoneErrorText: "Invalid phone number" });
          } else if (error.code == "auth/second-factor-already-in-use") {
            this.setState({ phoneErrorText: "Select a new phone number" });
          } else {
            console.error(error);
            this.setState({ phoneErrorText: "Unknown error" });
          }
        });
    });
  };

  getSMSJSX = () => {
    const finish = this.props.type == "enroll";
    return (
      <div>
        <div> Enter verification code sent to {this.state.phoneNumber}. </div>
        <TextField
          floatingLabelText="Verification Code"
          value={this.state.verificationCode}
          onKeyPress={e => {
            if (e.key === "Enter") {
              this.verifySMSCode();
            }
          }}
          onChange={event => {
            const { value } = event.target;
            this.setState({ verificationCode: value });
          }}
          error={Boolean(this.state.codeErrorText)}
          helperText={this.state.codeErrorText}
        />
        {this.getStepToolbar(this.verifySMSCode, finish)}
      </div>
    );
  };

  setCodeError = error => {
    if (error.code == "auth/invalid-verification-code") {
      this.setState({
        codeErrorText: "Invalid verification code"
      });
    } else {
      console.error(error);
      this.setState({ codeErrorText: "Unknown error" });
    }
  };

  verifySMSCode = () => {
    // Reset auth process if code entered > 2 min after it was sent
    const time1 = this.state.actionTime;
    const time2 = new Date();
    const secDiff = Math.abs(time2.getTime() - time1.getTime()) / 1000;
    if (secDiff > 120) {
      this.props.openClose.showSnack(
        "Authentication timed out, please restart process."
      );
      this.props.cancelCallback();
      return;
    }

    const { verificationId } = this.state;
    const { verificationCode } = this.state;

    // Verify SMS code and enroll in 2FA
    if (this.props.type == "enroll") {
      const user = firebase.auth().currentUser;
      return verifySMSEnroll(user, verificationId, verificationCode)
        .then(() => {
          this.props.successCallback();
        })
        .catch(error => {
          this.setCodeError(error);
        });
    }

    // Verify SMS code and complete 2FA sign-in
    if (this.props.type == "verify") {
      const resolver = this.state.errorResolver;
      return verifySMSLogin(resolver, verificationId, verificationCode)
        .then(userCredential => {
          this.setState({ verificationCode: "", codeErrorText: "" });
          this.props.successCallback();
        })
        .catch(error => {
          this.setCodeError(error);
        });
    }
  };

  getStepContent = () => {
    const { stepIndex } = this.state;
    switch (stepIndex) {
      case 0:
        return <div>{this.getPasswordJSX()}</div>;
      case 1:
        return <div>{this.getPhoneJSX()}</div>;
      case 2:
        return <div>{this.getSMSJSX()}</div>;
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props != prevProps && this.props.type != prevProps.type) {
      this.setState({
        stepIndex: this.props.stepIndex,
        type: this.props.type,
        phoneNumber: "",
        verificationCode: ""
      });
    }
  }

  componentDidMount() {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      { size: "invisible" }
    );
  }

  render() {
    return (
      <Card variant="outlined" style={styles.card}>
        {this.getStepContent()}
        <div id="recaptcha-container" />
      </Card>
    );
  }
}

export default Authenticate;
