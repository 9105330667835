import {
  BroadFigureType,
  DataSourceType,
  DiffType,
  PivotType,
  SubFigureType
} from "components/graphs/BudgetTool";

type Month = string;
type Quarter = string;

type GenericEnum<T> = Record<string, T>;

interface ComparisonTypeMeta {
  title: string;
  valueToCompare: SubFigureType;
  valueToCompareAgainst: SubFigureType;
}

const WILDCARD = -1;
const isWildcard = value =>
  Array.isArray(value)
    ? value.length === 1 && value[0] === WILDCARD
    : value === WILDCARD;

export const BUDGET: SubFigureType = "budget";
export const ESTIMATES: SubFigureType = "estimates";
export const ACTUAL: SubFigureType = "actual";
export const REVENUE: BroadFigureType = "revenue";
export const TRADE_RATE: BroadFigureType = "tradeRate";
export const TRADE_SPEND: BroadFigureType = "tradeSpend";

const broadFigureTypes: BroadFigureType[] = [REVENUE, TRADE_RATE, TRADE_SPEND];

const diffTypes: GenericEnum<DiffType> = {
  None: "none",
  Absolute: "absolute",
  Relative: "relative"
};

const pivotTypes: GenericEnum<PivotType> = {
  DATE: "date",
  CUSTOMER: "customer",
  PRODUCTS: "productGroups",
  CONTACTS: "contacts"
};

const dataSources: GenericEnum<DataSourceType> = {
  SPEND: "spend",
  PROMOTION: "promotion"
};

const comparisonTypes: GenericEnum<ComparisonTypeMeta> = {
  budgetVsActual: {
    title: "Actual vs. Budget",
    valueToCompare: ACTUAL,
    valueToCompareAgainst: BUDGET
  },
  budgetVsEstimate: {
    title: "LE vs. Budget",
    valueToCompare: ESTIMATES,
    valueToCompareAgainst: BUDGET
  },
  estimateVsActual: {
    title: "Actual vs. LE",
    valueToCompare: ACTUAL,
    valueToCompareAgainst: ESTIMATES
  }
};

const months: Month[] = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

const quarters: Quarter[] = ["Q1", "Q2", "Q3", "Q4"];

const thresholdForPivotCharts = 20;

const aberrantPivotGroupTitles = {
  // For PGs that are marked as non-pricing
  [pivotTypes.PRODUCTS]: "Non-pricing PGs",
  // For budget items that don't have associated contacts
  [pivotTypes.CONTACTS]: "No Contact Associated"
};

export {
  Month,
  Quarter,
  WILDCARD,
  isWildcard,
  broadFigureTypes,
  diffTypes,
  pivotTypes,
  comparisonTypes,
  dataSources,
  months,
  quarters,
  thresholdForPivotCharts,
  aberrantPivotGroupTitles
};
