import Typography from "@mui/material/Typography";
import { Divider } from "pui-react-dividers";
import { connect } from "react-redux";
import React from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Select from "ui-library/Select";
import TextField from "ui-library/TextField";
import MenuItem from "ui-library/MenuItem";
import { PromotionSelect } from "ui-library/promotionTag";

import {
  getCompanyUsers,
  getJSXListsFirebase,
  downloadFilesFromStorage,
  firebase
} from "helpers/Firebase";
import { underPromoApprovalLimit } from "helpers/DataProcessing";
import { grey, common } from "@mui/material/colors";
import {
  updateField,
  addFiles,
  removeFile,
  updateFileAttributes,
  setFileInfo
} from "./actions";
import EntryCard from "./EntryCard";
import FilePicker from "../WebsiteElements/FilePicker";

const grey500 = grey["500"];
const { black } = common;

const MAX_FILE_SIZE = 20 << 20;

const styles = {
  superheader: {
    fontFamily: "Ubuntu",
    marginTop: 10
  },
  header: {
    marginLeft: "-15px",
    marginRight: "-50px",
    fontSize: 20,
    color: black
  },
  subheader: {
    marginLeft: "-15px",
    fontSize: 15,
    textTransform: "uppercase"
  },
  divider: {
    margin: "30px auto",
    width: "50%"
  },
  paper: {
    padding: "16px 32px",
    marginBottom: 20
  },
  comment: {
    padding: 16,
    marginBottom: 5
  }
};

const MIN_FILE_CAP = 5;
const MAX_FILE_CAP = 15;

class AddPromStep2 extends React.Component {
  getFileNamesJSX = () => {
    const menuItems = [];
    if (!this.props.fileInfo) return menuItems;
    for (let i = 0; i < this.props.fileInfo.length; i++) {
      menuItems.push(
        <MenuItem value={i} children={this.props.fileInfo[i].name} />
      );
    }
    return menuItems;
  };

  getLinesJSX = () => {
    const menuItems = [];
    menuItems.push(<MenuItem value={0} children="Entire Promotion" />);
    const productGroups = this.props.db.meta.product_groups;
    if (!this.props.lines) return menuItems;
    for (let i = 1; i <= this.props.lines.length; i++) {
      const currentLine = this.props.promState[this.props.lines[i - 1]];
      menuItems.push(
        <MenuItem
          value={i}
          children={`Line #${i.toString()} - ${
            this.props.db.meta.fundTypes?.[currentLine.type]?.name
          }, ${
            currentLine.productGroup in productGroups
              ? productGroups[currentLine.productGroup].name
              : "Custom"
          }`}
        />
      );
    }
    return menuItems;
  };

  downloadFiles = () => {
    const { fileContainingProm } = this.props;

    // check if files have already been associated with promotion
    if (
      fileContainingProm.fileInfo &&
      fileContainingProm.fileInfo.length != 0
    ) {
      const user = firebase.auth().currentUser;
      const fileNames = fileContainingProm.fileInfo.map(x => x.name);
      firebase
        .database()
        .ref(`users/${user.uid}/company_id`)
        .on("value", snapshot => {
          if (snapshot.val()) {
            const company_id = snapshot.val();
            const filePaths = fileNames.map(
              item =>
                `${company_id}/Promotion Files/${fileContainingProm.promKey}/${item}`
            );
            downloadFilesFromStorage(filePaths, fileNames, (blob, fileName) => {
              if (blob) {
                const convertedFile = new File([blob], fileName);
                const { fileInfo } = this.state;
                const oldFileNames = fileContainingProm.fileInfo.map(
                  x => x.name
                );

                const index = oldFileNames.indexOf(fileName);
                const index2 = this.state.fileInfo
                  .map(x => x.name)
                  .indexOf(fileName);

                const fileInfoObj = {
                  file: convertedFile,
                  name: fileName,
                  isContract: fileContainingProm.fileInfo[index].isContract,
                  attachedLine: fileContainingProm.fileInfo[index].attachedLine
                };

                if (index2 >= 0) {
                  fileInfo[index2] = { ...fileInfoObj };
                } else if (index >= 0 && index2 < 0) {
                  fileInfo.push(fileInfoObj);
                }
                this.props.dispatch(setFileInfo(fileInfo, false));
                this.setState(
                  {
                    fileInfo: Array.from(fileInfo)
                  },
                  () => {
                    if (
                      this.props.fileContainingProm.fileInfo.length ==
                      fileInfo.length
                    ) {
                      this.props.dispatch(setFileInfo(fileInfo, true));
                    }
                  }
                );
              }
            });
          }
        });
    } else {
      this.props.dispatch(setFileInfo([], true));
    }
  };

  setHelpText = () => {
    this.setState({
      helpText: "If this hasn't loaded yet, check your internet connection."
    });
  };

  constructor(props) {
    super(props);
    this.state = { fileIndex: 0, fileInfo: [] };
  }

  componentDidMount() {
    this.interval = setInterval(() => this.setHelpText(), 10000);

    const criteria = ["filteredStatuses"];
    const hasDeductions =
      this.props.promState.actMoneyList &&
      this.props.promState.actMoneyList.length > 0;
    if (hasDeductions) criteria.push("filteredStatusesDisableCancelled");

    const user = firebase.auth().currentUser;
    // Get the user's access level
    if (user) {
      getCompanyUsers(companyUsers => {
        const { uid } = user;
        const underLimit = underPromoApprovalLimit(
          companyUsers[uid],
          uid,
          this.props.db.meta.promo_limits,
          this.props.promState.totalExpSpend
        );

        if (!underLimit) {
          criteria.push("filteredStatusesOverLimit");
        }

        getJSXListsFirebase(
          this.props.db,
          allJSX => {
            this.setState({
              selectStatuses: allJSX.filteredStatusesJSX
            });
          },
          criteria,
          true
        );
      });
    }

    // Don't download files if an unconfirmed prom has new attachments
    !this.props?.proposed && this.downloadFiles();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    let fileCapacity = MIN_FILE_CAP;
    if (this.props.lines.length > MIN_FILE_CAP) {
      fileCapacity = this.props.lines.length;
    }
    if (fileCapacity > MAX_FILE_CAP) {
      fileCapacity = MAX_FILE_CAP;
    }
    const msg = `Drop files here to upload (${fileCapacity} files max, up to 20 MB in size each)`;
    const hasDeductions =
      this.props.promState.actMoneyList &&
      this.props.promState.actMoneyList.length > 0;
    const fileNamesJSX = this.getFileNamesJSX();
    const lineNamesJSX = this.getLinesJSX();
    const allFilesDownloaded = !this.props.fileContainingProm.fileInfo
      ? true
      : this.props.fileContainingProm.fileInfo.length ==
        this.state.fileInfo.length;
    return (
      <div style={{ fontFamily: "Oxygen" }}>
        <Typography style={styles.superheader} variant="h4">
          Miscellaneous
        </Typography>
        <Divider />
        <div style={{ height: "400px", overflowY: "scroll" }}>
          <EntryCard title="Upload Files to Promotion">
            {this.props.promState.duplicatedFrom == null ? (
              allFilesDownloaded ? (
                <FilePicker
                  message={msg}
                  onChange={files => {
                    this.props.dispatch(addFiles(files));
                  }}
                  onDelete={file => {
                    this.props.dispatch(removeFile(file));
                  }}
                  initialFiles={(this.props.fileInfo ?? []).map(x => x.file)}
                  promotionUpload={false}
                  filesLimit={fileCapacity}
                  maxFileSize={MAX_FILE_SIZE}
                />
              ) : (
                <div style={{ fontFamily: "Oxygen" }}>
                  <div className="centering">
                    <CircularProgress size={80} thickness={5} />
                  </div>
                  <br />
                  <div className="centering">{this.state.helpText}</div>
                </div>
              )
            ) : (
              <FilePicker
                message={msg}
                onChange={files => {
                  this.props.dispatch(addFiles(files));
                }}
                onDelete={file => {
                  this.props.dispatch(removeFile(file));
                }}
                initialFiles={(this.props.fileInfo ?? [])
                  .map(x => x.file)
                  .filter(x => x)}
                promotionUpload={false}
                filesLimit={fileCapacity}
                maxFileSize={MAX_FILE_SIZE}
              />
            )}
          </EntryCard>
          <EntryCard title="Edit File Attributes">
            {this.props.fileInfo?.length > 0 ? (
              <div>
                <div className="centering">
                  <Select
                    floatingLabelText=""
                    value={this.state.fileIndex}
                    onChange={(event, index, value) => {
                      this.setState({ fileIndex: value });
                    }}>
                    {fileNamesJSX}
                  </Select>
                </div>
                <br />
                <div className="centeringRow">
                  <Select
                    floatingLabelText="Is contract?"
                    value={
                      this.props.fileInfo[this.state.fileIndex]?.isContract
                    }
                    onChange={(event, index, value) => {
                      const curAttributes =
                        this.props.fileInfo[this.state.fileIndex];
                      curAttributes.isContract = value;
                      this.props.dispatch(
                        updateFileAttributes(
                          this.state.fileIndex,
                          curAttributes
                        )
                      );
                    }}>
                    <MenuItem value children="Yes" />
                    <MenuItem value={false} children="No" />
                  </Select>
                  <Select
                    floatingLabelText="Associate attachment with"
                    value={
                      this.props.fileInfo[this.state.fileIndex]
                        ?.attachedLine ?? [0]
                    }
                    onChange={(event, index, value) => {
                      const prevAttached =
                        this.props.fileInfo[this.state.fileIndex].attachedLine;
                      // SINGLE-SELECTION
                      // if (!value || value.length == 0) {
                      //   value = [0];
                      // } else {
                      //   if (value[0] == prevAttached[0]) value = [value[1]];
                      //   else value = [value[0]];
                      // }
                      // MULTI-SELECTION
                      if (
                        !value ||
                        value.length == 0 ||
                        (!prevAttached.includes(0) && value.includes(0))
                      ) {
                        value = [0];
                      } else if (
                        value.length > 1 &&
                        value.includes(0) &&
                        prevAttached.includes(0)
                      ) {
                        value.splice(value.indexOf(0), 1);
                      }
                      const curAttributes =
                        this.props.fileInfo[this.state.fileIndex];
                      curAttributes.attachedLine = value;
                      this.props.dispatch(
                        updateFileAttributes(
                          this.state.fileIndex,
                          curAttributes
                        )
                      );
                    }}
                    multiple>
                    {lineNamesJSX}
                  </Select>
                </div>
              </div>
            ) : (
              <div className="centering">Please upload a file first!</div>
            )}
          </EntryCard>
          <EntryCard title="Append Comments">
            {this.props.promState.commentList &&
              this.props.promState.commentList.map((comment, index) => {
                return (
                  <EntryCard key={index} rounded={false} style={styles.comment}>
                    <div>{comment.text}</div>
                    <div style={{ textAlign: "right", color: grey500 }}>
                      {`${comment.user}, ${comment.date}`}
                    </div>
                  </EntryCard>
                );
              })}
            <TextField
              floatingLabelText="Comments"
              defaultValue={this.props.promState.newComment}
              onChange={event =>
                this.props.dispatch(
                  updateField(this.props.db, "newComment", event.target.value)
                )
              }
              fullWidth
              multiLine
              rows={3}
            />
          </EntryCard>
          <EntryCard title="Status Label">
            {hasDeductions && (
              <div>
                This promotion cannot be cancelled because it has deductions
                attached.
              </div>
            )}
            <div className="rowC">
              <Select
                floatingLabelText="Promotion Status"
                value={this.props.promState.status}
                onChange={(event, index, value) =>
                  this.props.dispatch(
                    updateField(this.props.db, "status", value)
                  )
                }
                MenuProps={{
                  PaperProps: {
                    sx: {
                      width: "400px",
                      background: theme => theme.palette.readOnly.surface2
                    }
                  }
                }}
                fullWidth
                style={{ marginRight: "17px" }}>
                {this.state.selectStatuses}
              </Select>

              <PromotionSelect
                tags={this.props.db.meta.promotionTags}
                value={this.props.promState.tags}
                onChange={value =>
                  this.props.dispatch(updateField(this.props.db, "tags", value))
                }
              />
            </div>
            <br />
          </EntryCard>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const promFields = state.addPromotion;
  return {
    promState: promFields.prom,
    files: promFields.files,
    fileInfo: promFields.fileInfo,
    fileContainingProm: promFields.fileContainingProm,
    lines: promFields.lines,
    forceUpdate: promFields.forceUpdate
  };
};

export default connect(mapStateToProps)(AddPromStep2);
