import { DRMEvent } from "components/Deductions/DeductionsReconciliation/ActivityLog/DRMEvent";
import DRMEventService from "components/Deductions/DeductionsReconciliation/ActivityLog/DRMEventService";
import {
  ERPTransactionObject,
  InvoiceObject
} from "components/Deductions/models";
import { CompanyUser, Customer, Price, Meta, Account } from "js/dbTypes";
import { useContext, createContext } from "react";
import AdminPanelEvent from "components/Settings/AdminPanel/AdminPanelEvent";
import AdminPanelActivityLogService from "components/Settings/AdminPanel/AdminPanelActivityLogService";

// TODO(swey): type this correctly in full
export interface DbContextValues {
  archiveMetadata: any;
  archiveKey: any;
  promotions: any;
  products: any;
  customers: Record<string, Customer>;
  customerGroups: any;
  brokers: any;
  contacts: any;
  actMoney: any;
  revenue: any;
  meta: Meta;
  permissions: any;
  accounts: Record<string, Account>;
  allLines: any;
  pricing: Record<string, Record<string, Price>>;
  lift: any;
  reports: any;
  companyUsers: Record<string, CompanyUser>;
  companyid: string;
  promotion_spend: any;
  access: any;
  dmmOrdering: any;
  invoices: Record<string, InvoiceObject>;
  erpTransactions: Record<string, ERPTransactionObject>;
  drmEventService: DRMEventService;
  drmEvents: Record<string, DRMEvent>;
  companyNames: Record<string, string>;
  adminPanelEvents: Record<string, AdminPanelEvent>;
  adminPanelActivityLogService: AdminPanelActivityLogService;
}

export const DbContext = createContext<DbContextValues | undefined>(undefined);

export const useDb = () => {
  const dbState = useContext(DbContext);

  if (!dbState) {
    throw new Error(
      "No DbContext Provider found in tree. Is it present in the component tree?"
    );
  }

  return dbState;
};
