import React from "react";
import {
  Button,
  ButtonBase,
  Card,
  CardActions,
  CardContent,
  Divider,
  Link,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { OpenInNew } from "@mui/icons-material";
import { DRMFeature } from "components/Deductions/DeductionsReconciliation/redux/DRMSlice";
import classNames from "classnames";
import { displayUSCurrency } from "helpers/DataProcessing";
import {
  isUserCresicorEmployee,
  getFirebaseConsoleURL
} from "helpers/Firebase";
import { useDb } from "contexts/Db";
import { useHistory } from "react-router-dom";
import { DisplayInvoiceObject } from "components/Deductions/DeductionsReconciliation/types/invoiceTypes";
import InvoiceObject from "deductions-models/InvoiceObject";
import { renderUTCDateTimeInLocalTimeZone } from "helpers/Time";
import BackupApprovalToggle from "./BackupApprovalToggle";

const useStyles = makeStyles(() => ({
  card: {
    marginLeft: "25px",
    marginRight: "25px",
    marginTop: "15px",
    fontFamily: "Oxygen"
  },
  invoiceHeader: {
    lineHeight: "20.2px",
    fontWeight: 700
  },
  info: {
    fontSize: "1.25rem",
    fontWeight: 400
  },
  amount: {
    lineHeight: "20.2px"
  },
  amountContainer: {
    display: "flex",
    paddingTop: "10px"
  },
  divider: {
    marginLeft: "15px",
    marginRight: "15px"
  },
  buttonBase: {
    display: "block",
    textAlign: "initial",
    width: "100%"
  },
  cardActions: {
    padding: "0px",
    paddingLeft: "15px"
  },
  cardContent: {
    paddingBottom: "0px"
  }
}));

interface BackupCardProps {
  displayInvoice: DisplayInvoiceObject;
  invoice: InvoiceObject;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  openClose?: any;
  handleProfileOpen?: (
    event: React.MouseEvent<HTMLButtonElement>,
    rowData: DisplayInvoiceObject
  ) => void;
  interactive?: boolean;
}

const BackupCard = (props: BackupCardProps): JSX.Element => {
  const classes = useStyles();
  const db = useDb();
  const history = useHistory();
  const { displayInvoice, invoice, openClose, handleProfileOpen, interactive } =
    props;

  const firebaseConsoleURL = `${getFirebaseConsoleURL()}/companies/${
    db.companyid
  }/reconciliation/invoices/${invoice.key}`;

  const { linkedERPTransactions } = invoice;
  const transactionArr = Object.keys(linkedERPTransactions || {});
  const transactionKey = transactionArr.length ? transactionArr[0] : "";

  const cardContent = (
    <div>
      <Typography variant="h6" className={classes.invoiceHeader}>
        {interactive || !isUserCresicorEmployee() ? (
          <>
            Invoice No.&nbsp;
            {invoice.invoiceNumber}
          </>
        ) : (
          <Link href={firebaseConsoleURL} target="_blank" underline="hover">
            Invoice No.&nbsp;
            {invoice.invoiceNumber}
          </Link>
        )}
      </Typography>
      <Typography color="textSecondary" variant="caption">
        CHECK NO. {invoice.checkNumber}
      </Typography>
      <Typography className={classes.info}>
        {displayInvoice.customerName}
      </Typography>
      <div className={classes.amountContainer}>
        <div>
          <Typography className={classNames(classes.info, classes.amount)}>
            {displayUSCurrency(invoice.amount)}
          </Typography>
          <Typography variant="button" color="textSecondary">
            Original Amount
          </Typography>
        </div>
        <div className={classes.divider}>
          <Divider orientation="vertical" />
        </div>
        <div>
          <Typography className={classNames(classes.info, classes.amount)}>
            {displayUSCurrency(displayInvoice.openAmount)}
          </Typography>
          <Typography variant="button" color="textSecondary">
            Open Amount
          </Typography>
        </div>
      </div>
      <div>
        <Typography color="textSecondary" variant="caption">
          Date Imported:{" "}
          {invoice.createdDate
            ? renderUTCDateTimeInLocalTimeZone(invoice.createdDate)
            : ""}
        </Typography>
      </div>
      {linkedERPTransactions && (
        <Button
          startIcon={<OpenInNew />}
          onClick={() => {
            history.push(
              `/${DRMFeature.RECONCILIATION}/transaction/${transactionKey}`
            );
          }}>
          View Linked Transaction
        </Button>
      )}
    </div>
  );

  return interactive && handleProfileOpen ? (
    <Card className={classes.card}>
      <ButtonBase
        className={classes.buttonBase}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
          handleProfileOpen(e, displayInvoice)
        }>
        <CardContent className={classes.cardContent}>{cardContent}</CardContent>
      </ButtonBase>
      {isUserCresicorEmployee() && openClose && (
        <CardActions className={classes.cardActions}>
          <BackupApprovalToggle
            permissions={db.permissions}
            invoice={invoice}
          />
        </CardActions>
      )}
    </Card>
  ) : (
    <div>{cardContent}</div>
  );
};

export default BackupCard;
