import React from "react";
import { firebase } from "helpers/Firebase";
import TwoFactorAuth from "./TwoFactorAuth";

class Security extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      twoFactorAuth: false
    };
  }

  prettyPrint = list => {
    let result = "";
    for (let i = 0; i < list.length; i++) {
      if (i != 0) {
        result += ", ";
      }
      result += list[i];
    }
    return result;
  };

  componentDidMount() {
    const user = firebase.auth().currentUser;
    const providerIds = [];
    user.providerData.forEach(profile => {
      providerIds.push(profile.providerId);
    });
    this.setState({
      providerIds,
      twoFactorAuth: providerIds.includes("password")
    });
  }

  render() {
    return (
      <>
        {this.state.twoFactorAuth ? (
          <TwoFactorAuth db={this.props.db} openClose={this.props.openClose} />
        ) : (
          <>
            Security settings are managed by your login provider:&nbsp;
            <b>{this.prettyPrint(this.state.providerIds || [])}</b>
          </>
        )}
      </>
    );
  }
}

export default Security;
