import React from "react";
import { Box, keyframes, Stack, styled, Typography } from "@mui/material";

const CutoutImage = () => {
  return (
    <svg
      width="3009"
      height="1682"
      viewBox="0 0 3009 1682"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3009 0H0V1682H3009V0ZM1452 795H1440V887H1452V795ZM1522 795H1534V887H1522V795ZM1568 795H1546V887H1568V795ZM1464 795H1486V887H1464V795ZM1510 795H1498V887H1510V795Z"
        fill="white"
      />
    </svg>
  );
};

const StyledBox = styled(Box)(({ theme }) => ({
  flexDirection: "column",
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  height: "100vh",
  backgroundColor: theme.palette.common.white,

  "& #stack": {
    position: "relative",
    overflow: "hidden",
    height: "27%"
  },

  "& #backImage": {
    position: "absolute",
    transform: "rotate(90deg)"
  },

  "& #frontImage": {
    zIndex: 2
  }
}));

const movingGradient = keyframes`
  0% {
    transform:  rotate(90deg) translateX(45%) ;
  }
/* 
  30% {
    transform: translateY(0%)
  }

  50% {
    transform: translateY(40%)
  }

  70% {
    transform: translateY(0%)
  } */

  100% {
    transform:  rotate(90deg) translateX(-45%);
  }
`;

const GradientBox = styled("div")({
  width: 4000,
  height: 400,
  backgroundRepeat: 4,
  background:
    "repeating-linear-gradient(252.28deg, #FF66FF, #FF67EF, #FF6AC6 , #FF7084 , #FF772A , #FF7910 , #FCA80B , #F7F003 , #F8F221 , #FAF66F, #FCFAAD , #FEFDD9, #D2F6FB , #9DECF7 , #54DDF0 , #00CDE9, #54DDF0, #9DECF7, #FEFDD9, #FCFAAD , #FAF66F, #F8F221 , #F7F003 , #FCA80B , #FF7910 , #FF772A , #FF7084 , #FF6AC6 , #FF67EF, #FF66FF );",
  animation: `${movingGradient} 10s linear infinite`
});

function VividlyLoader({ tip, cuText, url }) {
  return (
    <StyledBox>
      <Stack justifyContent="center" id="stack">
        <div id="frontImage">
          <CutoutImage />
        </div>
        <GradientBox id="backImage" />
      </Stack>
      <Typography sx={{ my: 2 }} variant="h4">
        Loading...
      </Typography>
      <Typography variant="body1">{tip}</Typography>
      <br />
      <Box>
        Visit&nbsp;
        <a
          href={url}
          target="_blank"
          style={{
            color: "black"
          }}
          rel="noreferrer">
          {cuText}
        </a>
        &nbsp;for more info!
      </Box>
    </StyledBox>
  );
}

export default VividlyLoader;
