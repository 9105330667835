import React from "react";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry";
import { IconButton, Stack } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import MenuItem from "ui-library/MenuItem";
import Select from "ui-library/Select";
import Subheader from "ui-library/Subheader";

class FileViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: {},
      companyUsers: {},
      currentlySelected: "",
      showSearch: false,
      searchQuery: "",
      openFilePicker: false,
      modalOpen: false,
      reverseSort: false,
      multi: false,
      snackbar: false,
      allSelected: [],
      customersJSX: [],
      tableBodyRenderKey: 0,
      file: null,
      files: [],
      pageNumber: 1,
      numPages: 1,
      company: "None",
      loading: false,
      promType: "None"
    };
  }

  openFilePicker = jsx => {
    this.setState({
      openFilePicker: true
    });
    this.props.showDropDownWindow(jsx);
  };

  closeFilePicker = () => {
    this.setState({
      openFilePicker: false
    });
  };

  getDropzone = dropzone => {
    this.setState({ dropzone });
  };

  onDocumentLoadSuccess = document => {
    const { numPages } = document;
    this.setState({
      numPages,
      pageNumber: 1
    });
  };

  changePage = offset =>
    this.setState(prevState => ({
      pageNumber: prevState.pageNumber + offset
    }));

  previousPage = () => this.changePage(-1);

  nextPage = () => this.changePage(1);

  downloadFile = () => {
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(this.state.file);
    link.download = this.state.file.name;
    link.click();
  };

  componentDidMount() {
    pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";
    this.setState({
      files: this.props.files,
      file: this.props.files[0] || null
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      files: nextProps.files,
      file: nextProps.files[0] || null
    });
  }

  render() {
    const { pageNumber, numPages } = this.state;
    return (
      <Stack
        direction="column"
        alignItems="flex-start"
        sx={{ position: "relative", display: "block" }}>
        <div style={{ margin: "auto", width: "20%" }}>
          {this.props.title || <Subheader>View File</Subheader>}
        </div>
        <div className="centering">
          <Select
            size="small"
            floatingLabelText="File to View"
            disabled={this.props.files.length == 0}
            onChange={event => {
              const fileNames = this.state.files.map(f => f.name);
              this.setState({
                file: this.state.files[fileNames.indexOf(event.target.value)]
              });
            }}
            value={this.state.file ? this.state.file.name : null}
            style={{ width: "30%", marginTop: -10 }}>
            {this.state.files.map(file => {
              return (
                <MenuItem
                  value={file.name}
                  children={file.name}
                  key={file.name}
                />
              );
            })}
          </Select>
          <IconButton
            edge="start"
            color="inherit"
            disabled={!this.state.file}
            onClick={this.downloadFile}
            aria-label="close"
            style={{ marginLeft: 10 }}
            size="large">
            <CloudDownloadIcon />
          </IconButton>
        </div>
        <div
          style={{
            margin: "auto",
            maxWidth: "80%",
            borderColor: "black",
            borderWidth: 2,
            borderStyle: "solid"
          }}>
          {this.state.file &&
          this.state.file.name.toLowerCase().endsWith(".pdf") ? (
            <Document
              file={this.state.file}
              onLoadSuccess={this.onDocumentLoadSuccess}>
              <Page
                height={0.65 * $(window).height()}
                pageNumber={pageNumber}
              />
            </Document>
          ) : this.state.file ? (
            <div
              style={{
                height: 0.65 * $(window).height(),
                textAlign: "center"
              }}>
              This Type Of File Cannot Be Displayed Right Now.
            </div>
          ) : (
            <div
              style={{
                height: 0.65 * $(window).height(),
                textAlign: "center"
              }}>
              No File To Display
            </div>
          )}
        </div>
        <div className="rowC" style={{ margin: "auto", width: "30%" }}>
          <IconButton
            edge="start"
            color="inherit"
            disabled={pageNumber == 1}
            onClick={this.previousPage}
            aria-label="close"
            size="large">
            <NavigateBeforeIcon />
          </IconButton>
          <div
            style={{
              marginLeft: 10,
              marginRight: 10,
              minWidth: 75
            }}
            className="centering">
            Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
          </div>
          <IconButton
            edge="start"
            color="inherit"
            disabled={pageNumber == numPages}
            onClick={this.nextPage}
            aria-label="close"
            size="large">
            <NavigateNextIcon />
          </IconButton>
        </div>
      </Stack>
    );
  }
}

export default FileViewer;
