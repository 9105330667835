// eslint-disable-next-line max-classes-per-file
import React from "react";
import Typography from "@mui/material/Typography";
import { Toolbar } from "ui-library/Toolbar";
import ToolbarGroup from "ui-library/ToolbarGroup";
import Divider from "@mui/material/Divider";

import AddBoxIcon from "@mui/icons-material/AddBox";
import AdvancedSearchIcon from "@mui/icons-material/ZoomIn";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import DownloadIcon from "@mui/icons-material/GetApp";
import Button from "ui-library/Button";
import IconButton from "ui-library/IconButton";
import IconMenu from "ui-library/IconMenu";
import MenuItem from "ui-library/MenuItem";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import TextField from "ui-library/TextField";
import UploadIcon from "@mui/icons-material/Publish";
import { CSVLink } from "react-csv";

import { displayMoney, escapeQuotes } from "helpers/DataProcessing";
import { removeSpend } from "helpers/Firebase";
import { spendSatisfiesSearch } from "helpers/satisfiesSearch";
import { toUTCDate } from "helpers/Time";
import { Stack } from "@mui/material";
import AdvancedDeletion from "./AdvancedDeletion";

import PaginationMUITable from "../tables/PaginationMUITable";
import NewSpend from "./NewSpend";
import SearchSpend from "./SearchSpend";
import SpendProfile from "./SpendProfile";
import UploadFiles from "../UploadFiles/UploadFiles";

const styles = {
  toolbar: {
    justifyContent: "space-between",
    height: "56px",
    padding: "0px 24px"
  },
  title: {
    marginRight: "30px",
    color: "inherit"
  },
  searchText: {
    marginTop: 0
  }
};
function round(n) {
  return Math.round(n * 100) / 100;
}

class PropDataUpdatedCSVLink extends CSVLink {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data, headers, separator, uFEFF } = nextProps;
    this.setState({ href: this.buildURI(data, uFEFF, headers, separator) });
  }
}

class Spend extends React.PureComponent {
  constructor(props) {
    super(props);
    this.csvLink = React.createRef();
    this.state = {
      spend: {},
      sales: {},
      downloadData: [],
      headers: [
        { label: "Date", key: "date" },
        { label: "Month", key: "month" },
        { label: "Year", key: "year" },
        { label: "Promotion Key", key: "promKey" },
        { label: "Line Key", key: "lineKey" },
        { label: "Account", key: "account" },
        { label: "Product Group", key: "pgName" },
        { label: "Customer Name", key: "customerName" },
        { label: "Promotion Line Details", key: "promDetails" },
        { label: "Spend ($)", key: "spend" },
        { label: "Sales (units)", key: "sales" },
        { label: "Promotion Type", key: "type" },
        { label: "Spend Rate ($/unit)", key: "spendRate" },
        { label: "Region", key: "region" },
        { label: "Customer Channel", key: "customerChannel" },
        { label: "Customer Class", key: "customerClass" },
        { label: "Customer Region", key: "customerRegion" },
        { label: "Check Number", key: "checkNumber" }
      ],
      allSelected: [],
      showSearch: false,
      searchQuery: "",
      modalOpen: false,
      category: 1,
      searchSpend: {},
      spendLength: 0,
      spendJSX: [],
      initialLoad: false,
      count: 20,
      pagelength: 20,
      rowsSelected: []
    };
  }

  openFilePicker = jsx => {
    this.setState({
      openFilePicker: true
    });
    this.props.showDropDownWindow(jsx);
  };

  closeFilePicker = () => {
    this.setState({
      openFilePicker: false
    });
  };

  addNewSpend = () => {
    this.props.openClose.setAppModal(
      "Add New Spend",
      <NewSpend
        mode="spend"
        openClose={this.props.openClose}
        db={this.props.db}
      />,
      null,
      false,
      true,
      "lg"
    );
  };

  deleteSpendItems = () => {
    this.props.openClose.setAppModal(
      "Delete Spend Item(s)",
      <div>Are you sure you wish to delete these item(s)?</div>,

      <Stack>
        <Button
          label="Yes, I'm sure"
          variant="text"
          color="error"
          onClick={() => {
            const spendProm = {};
            for (const spendKey of this.state.allSelected) {
              spendProm[spendKey] = this.props.db.actMoney[spendKey].promKey;
            }
            removeSpend(spendProm, () => {
              this.setState({
                allSelected: []
              });
              this.props.openClose.closeAppModal();
            });
          }}
        />
        <Button
          label="No, go back"
          variant="text"
          onClick={() => {
            this.props.openClose.closeAppModal();
          }}
        />
      </Stack>
    );
  };

  advancedDelete = (deleteAll, deleteStartDate, deleteEndDate) => {
    if (deleteAll) {
      removeSpend(null, () => this.props.openClose.closeAppModal());
    } else if (deleteStartDate && deleteEndDate) {
      const { actMoney } = this.props.db;
      const removeKeys = Object.keys(actMoney).filter(
        key =>
          toUTCDate(deleteStartDate) <=
            toUTCDate(new Date(actMoney[key].date)) &&
          toUTCDate(new Date(actMoney[key].date)) <= toUTCDate(deleteEndDate)
      );
      const spendProm = {};
      for (const key of removeKeys) {
        spendProm[key] = actMoney[key].promKey;
      }
      removeSpend(spendProm, () => this.props.openClose.closeAppModal());
    }

    this.props.openClose.closeAppModal();
  };

  handleSearchButton = () => {
    this.setState({
      showSearch: true
    });
  };

  setSearchSpend = searchSpend => {
    this.setState({ searchSpend });
  };

  handleSearch = event => {
    this.handleInfiniteLoad(true);
    this.setState({ searchQuery: event.target.value.toLowerCase() });
  };

  handleProfileOpen = (event, rowData) => {
    const spendKey = rowData.key;
    this.props.showRightDrawer(
      <SpendProfile
        spendKey={spendKey}
        category={this.state.category}
        db={this.props.db}
      />
    );
  };

  prettyPrint = productsArray => {
    let products = "";
    for (let i = 0; i < productsArray.length; i++) {
      if (i !== 0) {
        products += ", ";
      }
      products += productsArray[i];
    }
    return products;
  };

  handleAdvancedDeletion = () => {
    this.props.openClose.setAppModal(
      "Advanced Deletion",
      <AdvancedDeletion
        mode="spend"
        deletion={this.advancedDelete}
        openClose={this.props.openClose}
        noGoBack={this.handleAdvancedDeletion}
        db={this.props.db}
        revenue={false}
      />
    );
  };

  getSpendJSX = filteredItems => {
    const selectedSet = new Set(this.state.allSelected);
    const spendJSX = [];
    for (let i = 0; i < filteredItems.length && i < this.state.count; i++) {
      const s = filteredItems[i];
      const spendVal = {
        key: s.key,
        date: s.date.toLocaleDateString(),
        customerName: s.customerName,
        promDetails: s.promDetails,
        spend: displayMoney(Math.round(s.spend)),
        sales: s.sales,
        type: s.type,
        spendRate: s.spendRate,
        region: s.region,
        checkNumber: s.checkNumber
      };
      if (selectedSet.has(s.key)) {
        spendVal.tableData = { checked: true };
      }
      spendJSX.push(spendVal);
    }
    return spendJSX;
  };

  getSpendList = (props, cutoff = true) => {
    const spendList = [];
    const allItems = props.db.actMoney;
    const pgs = props.db.meta.product_groups;
    let num = 0;
    for (const key in allItems) {
      const s = { ...allItems[key] };
      s.key = key;
      const prom = props.db.promotions[s.promKey];
      const line = props.db.allLines[s.lineKey];

      // assign products and product group to spend value
      const pgName = line
        ? pgs && pgs[line.productGroup]
          ? pgs[line.productGroup].name
          : line.productGroup
        : "";
      s.productGroup = line.productGroup;
      s.product =
        pgName === "custom" ? line.product : pgs[line.productGroup].products;
      s.pgName = pgName;
      s.account = props.db.accounts?.[line.account]?.name;
      s.month = prom.month;
      s.year = prom.year;
      // customer fields
      s.customerName =
        props.db.customers[s.customer] && props.db.customers[s.customer].name;
      s.customerRegion = props.db.customers[s.customer].region;
      s.customerChannel = props.db.customers[s.customer].channel;
      s.customerClass = props.db.customers[s.customer].class;
      // promotion fields
      s.promDetails =
        prom && line
          ? `${prom.name} (${
              this.props.db.meta.fundTypes?.[line.type]?.name
            }, ${pgName})`
          : "";
      s.type = props.db.meta.fundTypes?.[line.type]?.name;
      s.sales = Math.round(s.sales);
      s.spendRate = round(s.spend / s.sales);
      if (
        (this.state.searchQuery === "" ||
          (s.customerName &&
            s.customerName.toLowerCase().indexOf(this.state.searchQuery) !==
              -1) ||
          (s.promDetails &&
            s.promDetails.toLowerCase().indexOf(this.state.searchQuery) !==
              -1) ||
          (s.lineKey && s.lineKey.indexOf(this.state.searchQuery) !== -1) ||
          (s.accountType &&
            s.accountType.toLowerCase().indexOf(this.state.searchQuery) !==
              -1)) &&
        spendSatisfiesSearch(s, this.state.searchSpend)
      ) {
        spendList.push(s);
        num++;
      }

      if (cutoff && num > this.state.count + this.state.pagelength) {
        break;
      }
    }

    return spendList;
  };

  getSpend = props => {
    const spendList = this.getSpendList(props, true);
    const spendLength = spendList.length;
    const spendJSX = this.getSpendJSX(spendList);

    return [spendLength, spendJSX];
  };

  getDownloadData = () => {
    const totalSpendList = this.getSpendList(this.props, false);
    for (let i = 0; i < totalSpendList.length; i++) {
      totalSpendList[i].date = totalSpendList[i].date.toLocaleDateString();
      totalSpendList[i].spend = -totalSpendList[i].spend;

      // clear fields based on promo type
      const line =
        this.props.db.promotions[totalSpendList[i].promKey].lines[
          totalSpendList[i].lineKey
        ];
      const typeFields =
        this.props.db.meta.fundTypes?.[line.type]?.promCreationFields ?? [];
      if (!typeFields.includes("rate")) {
        totalSpendList[i].sales = "";
        totalSpendList[i].spendRate = "";
      }
    }
    this.setState({ downloadData: totalSpendList.map(escapeQuotes) }, () => {
      this.csvLink.current.link.click();
    });
  };

  handleInfiniteLoad = reset => {
    const count = reset
      ? this.state.pagelength
      : this.state.count + this.state.pagelength;
    this.setState({ count });
  };

  handleRowSelection = rows => {
    const allSelected = rows.map(row => row.key);
    this.setState({ allSelected });
  };

  componentDidMount() {
    const additionalSearch = this.props.additionalSearch || {};
    this.setState({ searchSpend: additionalSearch });
    const spendKey = this.props.match?.params?.key;
    const allSpend = this.props.db.actMoney;
    if (spendKey && spendKey in allSpend) {
      this.handleProfileOpen(null, { key: spendKey });
    }
  }

  render() {
    const [spendLength, spendJSX] = this.getSpend(this.props);
    const actionParams = {
      openProfile: rowData => {
        this.handleProfileOpen;
      }
    };
    const columns = [
      {
        title: "Date",
        field: "date"
      },
      {
        title: "Customer Name",
        field: "customerName"
      },
      {
        title: "Promotion Line Details",
        field: "promDetails",
        width: 200
      },
      {
        title: "Spend ($)",
        field: "spend"
      },
      {
        title: "Sales (units)",
        field: "sales"
      },
      {
        title: "Promotion Type",
        field: "type"
      },
      {
        title: "Spend Rate ($/unit)",
        field: "spendRate",
        width: 200
      },
      {
        title: "Region",
        field: "region"
      },
      {
        title: "Check Number",
        field: "checkNumber"
      }
    ];

    return (
      <div>
        <Toolbar style={styles.toolbar}>
          <ToolbarGroup>
            <Typography variant="h6" style={styles.title}>
              Spend
            </Typography>
            <Divider orientation="vertical" flexItem />
            {this.state.showSearch ? (
              <IconButton
                onClick={this.props.showRightDrawer.bind(
                  null,
                  <SearchSpend
                    searchSpend={this.state.searchSpend}
                    setSearchSpend={this.setSearchSpend}
                    openClose={this.props.openClose}
                    db={this.props.db}
                  />
                )}
                tooltip="Advanced Search"
                style={styles.iconButton}
                size="large">
                <AdvancedSearchIcon />
              </IconButton>
            ) : (
              <IconButton
                onClick={this.handleSearchButton}
                tooltip="Search"
                style={styles.iconButton}
                size="large">
                <SearchIcon />
              </IconButton>
            )}
            {this.state.showSearch && (
              <TextField
                variant="standard"
                placeholder={`Search ${
                  Object.keys(this.props.db.actMoney).length
                } results`}
                onChange={this.handleSearch}
                style={styles.searchText}
              />
            )}
          </ToolbarGroup>

          <ToolbarGroup>
            <IconMenu
              iconButtonElement={
                <IconButton tooltip="Settings" size="large">
                  <SettingsIcon />
                </IconButton>
              }
              anchorOrigin={{ horizontal: "right", vertical: "top" }}
              targetOrigin={{ horizontal: "right", vertical: "top" }}
              onChange={this.props.handleAdditionalDataDisplay}>
              <MenuItem
                value={0}
                leftIcon={<DownloadIcon />}
                disabled={Object.keys(this.props.db.actMoney).length === 0}
                onClick={this.getDownloadData}>
                Download Spend
              </MenuItem>
              {this.props.db.permissions.includes("spend") &&
                !this.props.readOnly && (
                  <MenuItem
                    value={0}
                    leftIcon={<DeleteIcon />}
                    onClick={this.handleAdvancedDeletion}>
                    Advanced Deletion
                  </MenuItem>
                )}
            </IconMenu>
            {this.props.db.permissions.includes("spend") &&
              !this.props.readOnly && (
                <IconButton
                  onClick={this.addNewSpend}
                  tooltip="Add Item"
                  size="large">
                  <AddBoxIcon />
                </IconButton>
              )}
            {this.props.db.permissions.includes("spend") &&
              !this.props.readOnly && (
                <IconButton
                  onClick={this.deleteSpendItems}
                  tooltip="Delete Spend Item(s)"
                  disabled={this.state.allSelected.length == 0}
                  size="large">
                  <DeleteIcon />
                </IconButton>
              )}
            {this.props.db.permissions.includes("spend") &&
              !this.props.readOnly && (
                <Button
                  label="Load from File"
                  onClick={this.openFilePicker.bind(
                    null,
                    <UploadFiles
                      selectedDataType="Spend"
                      openClose={this.props.openClose}
                    />
                  )}
                  icon={<UploadIcon />}
                />
              )}
            {/* this.props.db.permissions.includes("spend") &&
              !this.props.readOnly && (
                <Button
                  label={"Import"}
                  onClick={() =>
                    this.props.openClose.setAppModal(
                      "Import from Accounting Source",
                      <CeligoOptions
                        db={this.props.db}
                        companyid={this.props.companyid}
                        openClose={this.props.openClose}
                        type={"Spend"}
                        finishImporting={(
                          allLinesDF,
                          accumLinesDF,
                          jsonFilePath
                        ) => {
                          this.openFilePicker(
                            <UploadFiles
                              selectedDataType={"Spend"}
                              skipMapData={true}
                              allLinesDF={allLinesDF}
                              accumLinesDF={accumLinesDF}
                              jsonFilePath={jsonFilePath}
                            />
                          );
                        }}
                      />,
                      null,
                      true
                    )
                  }
                  backgroundColor={blue400}
                  hoverColor={blue600}
                  style={{ color: white }}
                  icon={<CloudDownloadIcon color={white} />}
                />
              ) */}
          </ToolbarGroup>
        </Toolbar>
        <PaginationMUITable
          sorting={false}
          allSelected={this.state.allSelected}
          data={spendJSX}
          allLoaded={this.state.count >= spendLength}
          handleProfileOpen={this.handleProfileOpen}
          handleRowSelection={this.handleRowSelection}
          handleInfiniteLoad={this.handleInfiniteLoad}
          columns={columns}
          actionParams={actionParams}
        />
        <PropDataUpdatedCSVLink
          data={this.state.downloadData}
          headers={this.state.headers}
          filename="Spend Export.csv"
          className="hidden"
          ref={this.csvLink}
          target="_blank"
        />
      </div>
    );
  }
}

export default Spend;
