import React from "react";
import { format, differenceInCalendarDays } from "date-fns";
import MenuItem from "ui-library/MenuItem";
import { DateTime } from "luxon";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

function sortDateStrings(dates) {
  return dates.sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
  });
}

function formatDate(date) {
  const d = new Date(date);
  let month = String(d.getMonth() + 1);
  let day = String(d.getDate());
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join("-");
}

function getDateTime(now) {
  const year = now.getFullYear();
  let month = now.getMonth() + 1;
  let day = now.getDate();

  if (month.toString().length == 1) {
    month = `0${month}`;
  }
  if (day.toString().length == 1) {
    day = `0${day}`;
  }

  let hours = now.getHours();
  let minutes = now.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";
  hours %= 12;
  hours = hours || 12;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  const timewithampm = `${hours}:${minutes} ${ampm}`;

  const dateString = `${year}/${month}/${day} - ${timewithampm}`;
  return dateString;
}

function getWeekEnd(date) {
  // get Sunday before date
  date = new Date(date);
  const day = date.getDay();
  const diff = date.getDate() + (7 - day);
  date.setDate(diff);
  return `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(
    -2
  )}-${`0${date.getDate()}`.slice(-2)}`;
}

function getYearWeeks(year) {
  // get the end dates of each week in the year (every Sunday)
  const date = new Date(year, 0, 1);
  while (date.getDay() != 0) {
    date.setDate(date.getDate() + 1);
  }
  const days = [];
  while (date.getFullYear() == year) {
    days.push(
      `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(
        -2
      )}-${`0${date.getDate()}`.slice(-2)}`
    );
    date.setDate(date.getDate() + 7);
  }
  return days;
}

function getTimesJSX(meta, future = true) {
  const years = [];
  const now = new Date();
  if (future) {
    for (var year = now.getFullYear() + 2; year >= meta.start_year; year--) {
      years.push(year);
    }
  } else {
    for (var year = now.getFullYear(); year >= meta.start_year; year--) {
      years.push(year);
    }
  }
  const monthsJSX = [];
  const yearsJSX = [];
  for (var i = 0; i < months.length; i++) {
    monthsJSX.push(<MenuItem value={i + 1} children={months[i]} key={i + 1} />);
  }
  for (var i = 0; i < years.length; i++) {
    yearsJSX.push(
      <MenuItem value={parseInt(years[i])} children={years[i]} key={years[i]} />
    );
  }
  return [monthsJSX, yearsJSX];
}

function printMonthYear(m, y) {
  return `${months[m - 1]} ${y}`;
}

function numDays(date) {
  const now = new Date();
  const seconds = Math.floor((now - date) / 1000);
  const days = Math.floor(seconds / 86400);
  return days;
}

function timeSince(date) {
  const now = new Date();

  const seconds = Math.floor((now - date) / 1000);

  let interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return `${interval} years`;
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return `${interval} months`;
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return `${interval} days`;
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return `${interval} hours`;
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return `${interval} minutes`;
  }
  return `${Math.floor(seconds)} seconds`;
}

function daysBetween(date1, date2) {
  // Get 1 day in milliseconds
  const one_day = 1000 * 60 * 60 * 24;

  // Convert both dates to milliseconds
  const date1_ms = date1.getTime();
  const date2_ms = date2.getTime();

  // Calculate the difference in milliseconds
  const difference_ms = date2_ms - date1_ms;

  // Convert back to days and return
  return Math.round(difference_ms / one_day);
}

function compareMonths(month1, month2) {
  const d1 = new Date(month1);
  const d2 = new Date(month2);
  if (d1 < d2) {
    return 1;
  }
  return -1;
}

const adjustDateForTrailingWeek = week => {
  const weekDate = new Date(week);
  const weekNumber = Number(format(weekDate, "I"));
  const year = weekDate.getFullYear();
  let adjustedWeek = week;
  if (weekNumber === 1 || weekNumber >= 52) {
    const startOfYear = new Date(`${year}-01-01`);
    const daysSinceStartOfYear =
      differenceInCalendarDays(weekDate, startOfYear) + 1;
    if (daysSinceStartOfYear <= 3) {
      adjustedWeek = `${year - 1}-12-31`;
    }
    if (daysSinceStartOfYear >= 365) {
      adjustedWeek = `${year}-12-31`;
    }
  }
  return adjustedWeek;
};

const toUTCDate = date =>
  new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

const renderUTCDateTimeInLocalTimeZone = (date: string): string => {
  return DateTime.fromISO(new Date(date).toISOString()).toFormat("f ZZZZ");
};

export {
  months,
  sortDateStrings,
  formatDate,
  getDateTime,
  getWeekEnd,
  getYearWeeks,
  getTimesJSX,
  printMonthYear,
  timeSince,
  daysBetween,
  numDays,
  compareMonths,
  adjustDateForTrailingWeek,
  toUTCDate,
  renderUTCDateTimeInLocalTimeZone
};
