import React from "react";

import Checkbox from "ui-library/Checkbox";
import MenuItem from "ui-library/MenuItem";
import SelectField from "ui-library/Select";
import Card from "ui-library/Card";

import { objToArray, sortByField } from "helpers/sortByDate";
import { revenueTimeSeries } from "helpers/DataProcessing";
import RevenueTimeSeries from "../graphs/RevenueTimeSeries";

class FinancePlots extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      singleCustomer: 0,
      hideTotalRevenue: false
    };
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {}

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    const revenueTimeSeriesData = revenueTimeSeries(
      "revenue",
      this.props.revList,
      this.props.db.customers,
      this.state.singleCustomer,
      this.state.hideTotalRevenue
    );
    let customerList = this.props.db.customers
      ? objToArray(this.props.db.customers)
      : [];
    if (this.props.pnlGroup) {
      customerList = customerList.filter(
        x => x.pnlGroup == this.props.pnlGroup
      );
    }
    customerList = sortByField("name", customerList, true);
    return (
      <Card title="Total Revenue">
        <RevenueTimeSeries data={revenueTimeSeriesData} aspect={4} />
        <div className="rowRL" style={{ paddingRight: "40px" }}>
          <SelectField
            floatingLabelText="Highlighted Customer"
            value={this.state.singleCustomer}
            onChange={(event, index, value) => {
              this.setState({ singleCustomer: value });
            }}
            fullWidth>
            <MenuItem
              value={0}
              children="Default (Top Customer)"
              key="default"
            />
            {customerList.map(customer => {
              return (
                <MenuItem
                  value={customer.key}
                  children={customer.name}
                  key={customer.key}
                />
              );
            })}
          </SelectField>
          <Checkbox
            label="Hide Total Revenue"
            onChange={event => {
              this.setState({ hideTotalRevenue: event.target.checked });
            }}
            checked={this.state.hideTotalRevenue}
            style={{ marginLeft: 20 }}
            containerStyle={{ width: "250px", marginBottom: 0 }}
          />
        </div>
      </Card>
    );
  }
}

export default FinancePlots;
