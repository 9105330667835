import { styled } from "@mui/material/styles";
import { Chip } from "@mui/material";
import React from "react";

export enum CollapsibleTableSummaryType {
  SUM = "Sum",
  SUM_WITHOUT_NULL = "Sum Without Null",
  COUNT = "Count",
  AVERAGE = "Average"
}

export type CollapsibleTableSummaryCalculation<T> = (
  data: T[],
  key?: keyof T
) => number | string;

export interface SummaryConfigOptions<T> {
  type: CollapsibleTableSummaryType;
  label?: string; // custom label
  calculation?: CollapsibleTableSummaryCalculation<T>; // custom calculation
  render?: (val: string | number) => string;
}

export type CollapsibleTableSummaryConfig<T> = Map<
  keyof T,
  SummaryConfigOptions<T>
>;

export interface CollapsibleTableSummaryProps<T> {
  data: T[];
  summaryConfig: CollapsibleTableSummaryConfig<T>;
  size?: "small" | "medium";
}

const defaultCalculations: Record<
  CollapsibleTableSummaryType,
  CollapsibleTableSummaryCalculation<T>
> = {
  [CollapsibleTableSummaryType.AVERAGE]: (data, key) => {
    return (
      data.reduce((sum, row) => {
        return sum + row[key];
      }, 0.0) / data.length
    );
  },
  [CollapsibleTableSummaryType.SUM]: (data, key) => {
    return data.reduce((sum, row) => {
      return sum + row[key];
    }, 0.0);
  },
  [CollapsibleTableSummaryType.SUM_WITHOUT_NULL]: (data, key) => {
    return data.reduce((sum, row) => {
      if (row[key]) {
        return sum + row[key];
      }
      return sum;
    }, 0.0);
  },
  [CollapsibleTableSummaryType.COUNT]: (data, _key) => {
    return data.length;
  }
};

const SummaryChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  marginRight: theme.spacing(1)
}));

export default function CollapsibleTableSummary<T>(
  props: CollapsibleTableSummaryProps<T>
) {
  const { data, summaryConfig, size } = props;

  return (
    <>
      {Array.from(summaryConfig.entries()).map(entry => {
        const [key, config] = entry;
        const { type, label, calculation, render } = config;

        const value = calculation
          ? calculation(data)
          : defaultCalculations[type](data, key);

        const fullLabel = label || `${type} ${key}`;

        return (
          <SummaryChip
            key={key as string}
            size={size || "medium"}
            variant="outlined"
            label={`${fullLabel}: ${render ? render(value) : value}`}
          />
        );
      })}
    </>
  );
}
