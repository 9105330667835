import React from "react";

import Button from "ui-library/Button";

import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";

import {
  updateSeasonalityFirebase,
  batchUpdateFirebase
} from "helpers/Firebase";
import { getYearWeeks } from "helpers/Time";

import { grey, amber, common } from "@mui/material/colors";

const grey200 = grey["200"];
const grey400 = grey["400"];
const amber500 = amber["500"];
const amber700 = amber["700"];
const grey700 = grey["700"];
const { white } = common;

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const defaultParsePaste = str => {
  return str.split(/\r\n|\n|\r/).map(row => row.split("\t"));
};

class SeasonalityTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      data: [],
      tableKey: 0,
      weeks: [],
      bucketNames: []
    };
  }

  isValid = val => {
    const floatVal = parseFloat(val);
    if (isNaN(floatVal) || floatVal <= 0) {
      return false;
    }
    return true;
  };

  invalidPopup = () => {
    this.props.openClose.setAppModal(
      "Invalid Value",
      <div className="centering">
        All seasonality values must be positive numbers.
      </div>,

      <div className="centering">
        <Button
          label="Ok"
          onClick={() => {
            this.props.openClose.closeAppModal();
          }}
        />
      </div>
    );
  };

  emptySeasonalityBucketPopup = () => {
    this.props.openClose.setAppModal(
      "Empty Seasonality Bucket",
      <div className="centering">
        Seasonality Buckets must have associated products. <br />
        You must add products to this Seasonality Bucket before you add values
        here.
      </div>,

      <div className="centering">
        <Button
          label="Ok"
          onClick={() => {
            this.props.openClose.closeAppModal();
          }}
        />
      </div>
    );
  };

  updatePaste = (pasteData, seasonalityBucket, week) => {
    const weekInd = this.state.weeks.indexOf(week);
    const bucketInd = this.state.bucketNames.indexOf(seasonalityBucket);
    const numRowsUpdate = Math.min(
      pasteData.length,
      this.state.bucketNames.length - bucketInd
    );
    const numColsUpdate = Math.min(
      pasteData[0].length,
      this.state.weeks.length - weekInd
    );
    const update = {};
    for (let i = 0; i < numRowsUpdate; i++) {
      for (let j = 0; j < numColsUpdate; j++) {
        const num = pasteData[i][j];
        if (!this.isValid(num)) {
          this.invalidPopup();
          return;
        }
        update[
          `${this.state.bucketNames[bucketInd + i]}/${
            this.state.weeks[weekInd + j]
          }/seasonality_index`
        ] = num;
      }
    }
    batchUpdateFirebase(21, update);
  };

  emptySeasonalityBucket = specificBucket => {
    const seasonalityBuckets = Object.keys(
      this.props.db.meta.seasonality_buckets
    );
    return !seasonalityBuckets.includes(specificBucket);
  };

  renderCell = cellInfo => {
    const data = [...this.state.data];
    let oldData = "";
    const cellRef = React.createRef();
    const seasonalityBucket = cellInfo.original.bucket;
    const week = cellInfo.column.id;
    return cellInfo.column.Header !== "Seasonality Bucket" ? (
      <div
        ref={cellRef}
        contentEditable
        suppressContentEditableWarning
        onChange={e => {
          data[cellInfo.index][cellInfo.column.id] = parseFloat(
            e.target.textContent
          );
          this.setState({ data });
        }}
        onPaste={e => {
          e.preventDefault();
          const pasteData = defaultParsePaste(
            e.clipboardData.getData("text/plain")
          );
          this.updatePaste(pasteData, seasonalityBucket, week);
        }}
        onFocus={e => {
          oldData = e.target.textContent;
        }}
        onKeyPress={e => {
          if (e.key == "Enter") {
            cellRef.current.blur();
          }
        }}
        onBlur={e => {
          if (!this.props.readOnly) {
            if (this.emptySeasonalityBucket(seasonalityBucket)) {
              e.target.textContent = oldData;
              this.emptySeasonalityBucketPopup();
            } else if (
              e.target.textContent &&
              this.isValid(e.target.textContent)
            ) {
              updateSeasonalityFirebase(
                seasonalityBucket,
                week,
                parseFloat(e.target.textContent)
              );
            } else if (
              e.target.textContent &&
              !this.isValid(e.target.textContent)
            ) {
              e.target.textContent = oldData;
              this.invalidPopup();
            } else {
              // seasonality has been deleted
              updateSeasonalityFirebase(seasonalityBucket, week, null);
            }
          }
        }}
        key={cellInfo.index + cellInfo.column.id}>
        {cellInfo.value}
      </div>
    ) : cellInfo.column.Header == "Customer" ? (
      <div style={{ fontWeight: 800 }}>{cellInfo.value}</div>
    ) : (
      <div>{cellInfo.value}</div>
    );
  };

  updateDataColumns = props => {
    const columns = [];
    columns.push({
      Header: "",
      fixed: "left",
      columns: [
        {
          Header: "Seasonality Bucket",
          accessor: "bucket",
          Cell: this.renderCell,
          minWidth: 200
        }
      ]
    });
    const weekColumns = [];
    const weeks = getYearWeeks(props.year);
    const bucketNames = props.data.map(row => row.bucket);
    for (const week of weeks) {
      weekColumns.push({
        Header: week,
        accessor: week,
        Cell: this.renderCell,
        minWidth: 200
      });
    }
    columns.push({
      Header: "Weeks (click cell to edit)",
      columns: weekColumns
    });

    this.setState({
      data: props.data,
      bucketNames,
      weeks,
      columns
    });
  };

  componentDidMount() {
    this.updateDataColumns(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateDataColumns(nextProps);
  }

  render() {
    return (
      <ReactTableFixedColumns
        ref={r => (this.table = r)}
        style={this.props.style}
        className="-striped"
        data={this.props.liftBucket == 0 ? [] : this.state.data}
        columns={this.state.columns}
      />
    );
  }
}

export default SeasonalityTable;
