import React, { useMemo, useCallback } from "react";
import { DateTime } from "luxon";
import { makeStyles } from "@mui/styles";
import { useDb } from "contexts/Db";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import Button from "ui-library/Button";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { RootState } from "store";
import { useOpenClose } from "contexts/OpenClose";
import { TransactionProcessingService } from "reconciliation-services";
import { useDRMEvents } from "contexts/DRMEvents";
import {
  ErpTransactionCriticalDuplicationError,
  ErpTransactionIdOnlyDuplicateError
} from "components/Deductions/DeductionsReconciliation/UploadFiles/AddTransaction/ErpTransactionErrorObjects";
import {
  reset,
  setCriticalDuplication,
  setOnlyDuplicateTransactionIdFound,
  setShowErrors
} from "./redux/AddTransactionSlice";
import { DRMEventType } from "../../ActivityLog/DRMEvent";
import { duplicateTypeErrors } from "../../services/ERPTransactionServices/transactionProcessing";

const useStyles = makeStyles(() => ({
  footer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  columnFlex: {
    display: "flex",
    flexDirection: "column"
  }
}));
export default function AddTransactionStepButtons() {
  const classes = useStyles();
  const db = useDb();
  const { erpTransactions } = db;
  const openClose = useOpenClose();
  const dispatch = useDispatch();
  const { addEvent } = useDRMEvents();

  const { showErrors, errors, transaction, duplicateWarning } = useSelector(
    (state: RootState) => state.addTransaction,
    shallowEqual
  );

  const errorExists = useMemo(() => {
    return Object.values(errors).some(error => error);
  }, [errors]);

  const handleDuplicateDiscovery = useCallback(
    (duplicateType: duplicateTypeErrors) => {
      if (duplicateType instanceof ErpTransactionCriticalDuplicationError) {
        dispatch(setCriticalDuplication(true));
      } else if (duplicateType instanceof ErpTransactionIdOnlyDuplicateError) {
        if (!duplicateWarning.duplicateTransactionIdWarningShown) {
          dispatch(setOnlyDuplicateTransactionIdFound(true));
        }
      }
    },
    [dispatch, duplicateWarning.duplicateTransactionIdWarningShown]
  );

  const saveTransaction = useCallback(() => {
    const selectedDate = DateTime.fromJSDate(
      new Date(transaction.transactionDate || "")
    ).toFormat("yyyy-MM-dd");

    const transactionEntry = {
      ...transaction,
      transactionDate: selectedDate
    };
    const { foundDuplicate, duplicateType } =
      TransactionProcessingService.checkDuplicates(
        transactionEntry,
        erpTransactions
      );
    if (foundDuplicate) {
      handleDuplicateDiscovery(duplicateType);
      if (!duplicateWarning.duplicateTransactionIdWarningShown) return;
    }
    if (errorExists) {
      dispatch(setShowErrors(true));
      return;
    }
    dispatch(setShowErrors(false));

    TransactionProcessingService.createFirebaseTransaction(
      transactionEntry,
      erpTransactions,
      (newKey: string) => {
        addEvent({
          type: DRMEventType.TRANSACTION_ADDED,
          transactionKey: newKey
        });
        dispatch(reset());
        openClose.closeAppModal();
      }
    );
  }, [
    dispatch,
    addEvent,
    transaction,
    erpTransactions,
    openClose,
    errorExists,
    duplicateWarning.duplicateTransactionIdWarningShown,
    handleDuplicateDiscovery
  ]);

  const cancelButton = (
    <Button
      label="Close"
      onClick={() => {
        dispatch(reset());
        openClose.closeAppModal();
      }}
      variant="outlined"
      color="error"
      icon={<CloseIcon />}
    />
  );

  const submitButton = (
    <Button
      label="Submit"
      icon={<CheckIcon />}
      onClick={() => saveTransaction()}
      disabled={duplicateWarning.criticalDuplicationFound}
    />
  );

  const getErrorsAndWarnings = () => {
    let errorOrWarningJsx = <div>&nbsp;</div>;
    if (showErrors && errorExists) {
      errorOrWarningJsx = (
        <div> Please resolve any errors before submitting.</div>
      );
    } else if (duplicateWarning.criticalDuplicationFound) {
      errorOrWarningJsx = (
        <div>
          Records with identical highlighted fields already exist in the system.
          Please update at least one field.
        </div>
      );
    } else if (duplicateWarning.onlyDuplicateTransactionIdFound) {
      errorOrWarningJsx = (
        <div>
          Transaction ID already exists in the system. Clicking
          &quot;Submit&quot; again will create a new ERP Transaction with the
          same Transaction ID.
        </div>
      );
    }
    return errorOrWarningJsx;
  };

  return (
    <div className={classes.footer}>
      <div className="rowC">{cancelButton}</div>
      <div className="rowC">
        <div className={classes.columnFlex}>{getErrorsAndWarnings()}</div>
      </div>
      <div className="rowC">{submitButton}</div>
    </div>
  );
}
