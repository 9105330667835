import React from "react";
import { connect } from "react-redux";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { grey } from "@mui/material/colors";

const grey200 = grey["200"];

const styles = {
  modalTitle: {
    borderStyle: "solid",
    borderWidth: 0,
    borderBottomWidth: 2,
    borderBottomColor: grey200,
    margin: 0
  }
};

class ModalTitle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="rowC">
        <div className="col-xs-4" style={{ fontSize: 22, paddingTop: 20 }}>
          {this.props.modalTitle}
        </div>
        <div className="col-xs-8">
          <Stepper activeStep={this.props.stepIndex}>
            <Step>
              <StepLabel>Basic Info</StepLabel>
            </Step>
            <Step>
              <StepLabel>Promotion Details</StepLabel>
            </Step>
            <Step>
              <StepLabel>Miscellaneous</StepLabel>
            </Step>
          </Stepper>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const promFields = state.addPromotion;
  return {
    stepIndex: promFields.stepIndex,
    modalTitle: promFields.modalTitle
  };
};
export default connect(mapStateToProps)(ModalTitle);
