import React from "react";
import Button from "ui-library/Button";
import Card from "@mui/material/Card";
import Authenticate from "components/WebsiteElements/Authenticate";
import { firebase } from "helpers/Firebase";
import { Stack } from "@mui/material";

const styles = {
  card: {
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  }
};

class Edit2FASettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "overview",
      stepIndex: 0
    };
  }

  getOverviewJSX = () => {
    return (
      <div>
        <div style={styles.row}>
          <div>Two-factor Authentication is currently ACTIVE.</div>
          <Button
            label="Deactivate"
            onClick={() => {
              this.setState({ status: "deactivate", stepIndex: 0 });
            }}
          />
        </div>
        <br />
        <Card variant="outlined" style={{ padding: "10px" }}>
          <div style={styles.row}>
            <div>
              <div style={{ fontWeight: "bold" }}>Text Message</div>
              <div>Verification codes are sent by SMS.</div>
              <div>Current phone number: {this.state.phoneNumber}</div>
            </div>
            <Button
              label="Change Phone"
              onClick={() => {
                this.setState({ status: "edit", stepIndex: 0 });
              }}
            />
          </div>
        </Card>
      </div>
    );
  };

  getAuthenticateJSX = (type, stepIndex = 0) => {
    return (
      <Authenticate
        type={type}
        stepIndex={stepIndex}
        cancelCallback={() => {
          this.setState({ status: "overview", stepIndex: 0 });
        }}
        successCallback={() => {
          this.setState({ stepIndex: this.state.stepIndex + 1 });
        }}
        openClose={this.props.openClose}
      />
    );
  };

  unenroll2FA = () => {
    const user = firebase.auth().currentUser;
    const options = user.multiFactor.enrolledFactors;
    const selectedIndex = 0; // for now, only 1 mode of 2FA - phone
    return user.multiFactor.unenroll(options[selectedIndex]).then(() => {
      if (options.length == 0) {
        this.props.update2FAState(false);
      }
    });
  };

  getDeactivateStepContent = () => {
    const { stepIndex } = this.state;
    switch (stepIndex) {
      case 0:
        return this.getAuthenticateJSX("verify");
      case 1:
        return (
          <Card variant="outlined" style={styles.card}>
            <div className="centering">
              Are you sure you want to turn off Two-Factor Authentication for
              your account?
            </div>
            <Stack>
              <Button
                label="No, cancel"
                variant="text"
                onClick={() => {
                  this.setState({ status: "overview", stepIndex: 0 });
                }}
              />
              <Button
                label="Yes, I'm sure"
                variant="text"
                color="error"
                onClick={() => {
                  this.unenroll2FA();
                  this.props.update2FAState(false);
                }}
              />
            </Stack>
          </Card>
        );
    }
  };

  getEditContent = () => {
    const { stepIndex } = this.state;
    switch (stepIndex) {
      case 0:
        // verify current phone
        return this.getAuthenticateJSX("verify");
      case 1:
        // enroll new phone
        return this.getAuthenticateJSX("enroll", 1);
      case 2:
        // unenroll old phone
        this.unenroll2FA();
        return (
          <div>
            <p>Success! Your phone number has been changed.</p>
            <Button
              label="Back to Overview"
              onClick={() => {
                this.updatePhoneNumber();
                this.setState({ status: "overview" });
              }}
            />
          </div>
        );
    }
  };

  updatePhoneNumber = () => {
    const user = firebase.auth().currentUser;
    const options = user.multiFactor.enrolledFactors;
    // only support for 1 option right now
    const selectedIndex = 0;
    this.setState({ phoneNumber: options[selectedIndex].phoneNumber });
  };

  componentDidMount() {
    this.updatePhoneNumber();
  }

  render() {
    return (
      <div>
        {this.state.status == "overview" ? (
          <div>{this.getOverviewJSX()}</div>
        ) : this.state.status == "deactivate" ? (
          <div>{this.getDeactivateStepContent()}</div>
        ) : (
          <div> {this.getEditContent()} </div>
        )}
      </div>
    );
  }
}

export default Edit2FASettings;
