import React, { useRef, useEffect } from "react";
import { Box, Theme } from "@mui/material";
import { styled } from "@mui/material/styles";
import MUIDialog, { DialogProps } from "@mui/material/Dialog";
import MuiDialogTitle, { DialogTitleProps } from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogContentText from "@mui/material/DialogContentText";
import MuiDialogActions, {
  DialogActionsProps
} from "@mui/material/DialogActions";
import { SvgIconComponent } from "@mui/icons-material";

const BG_COLOR_MAPPING = {
  white: "#ffffff",
  blue: "linear-gradient(0deg, rgba(0, 103, 130, 0.11), rgba(0, 103, 130, 0.11)), #FDFBFF;"
};

type StyledAlertDialogProps = {
  backgroundColor: "white" | "blue";
  theme: Theme;
};

const StyledAlertDialog = styled(MUIDialog)<
  DialogProps & { backgroundColor: "white" | "blue" }
>(({ theme, backgroundColor }: StyledAlertDialogProps) => ({
  "& .MuiDialog-paper": {
    background: BG_COLOR_MAPPING[backgroundColor],
    borderRadius: "8px",
    color: theme.palette.background.onBackground
  }
}));

type newDialogProps = DialogProps & {
  dialogTitle: DialogTitleProps;
  actions: DialogActionsProps;
  backgroundColor: "white" | "blue";
  titleStyle: Record<string, string | number>;
  icon?: SvgIconComponent;
  contentScrollRefreshKey: unknown;
};

const Dialog = ({
  dialogTitle,
  actions,
  children,
  // titleStyle,
  icon,
  contentScrollRefreshKey,
  fullWidth,
  maxWidth,
  backgroundColor = "white",
  ...other
}: newDialogProps) => {
  const width = maxWidth || "sm";
  const isFullWidth = !!fullWidth;
  const modalContentRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    // Scroll content to the top when key changes
    if (modalContentRef?.current) {
      modalContentRef.current.scrollTop = 0;
    }
  }, [contentScrollRefreshKey]);

  return (
    <StyledAlertDialog
      maxWidth={width}
      fullWidth={isFullWidth}
      backgroundColor={backgroundColor}
      {...other}>
      <Box sx={{ color: "secondary.main", textAlign: "center", py: 0.5 }}>
        {icon}
      </Box>
      <MuiDialogTitle sx={{ textAlign: icon ? "center" : "left" }}>
        {dialogTitle}
      </MuiDialogTitle>
      <MuiDialogContent ref={modalContentRef}>
        <MuiDialogContentText>{children}</MuiDialogContentText>
      </MuiDialogContent>
      {actions && <MuiDialogActions>{actions}</MuiDialogActions>}
    </StyledAlertDialog>
  );
};

export default Dialog;
