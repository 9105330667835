import React from "react";
import { useDispatch, useSelector } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import ClearIcon from "@mui/icons-material/Clear";
import Button from "ui-library/Button";
import { RootState } from "store";
import { useOpenClose } from "contexts/OpenClose";
import { red } from "@mui/material/colors";
import { Stack } from "@mui/material";
import {
  reset,
  changeMatchingModalStep,
  proceedToSaveMatches
} from "./actions";

const red400 = red["400"];

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between"
  },
  closeButton: {
    color: red400
  }
}));

const DRMInvoiceModalButtons = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { closeAppModal } = useOpenClose();
  const { stepIndex } = useSelector(
    (state: RootState) => state.invoiceLinesMatching
  );
  const stepsCompleted = stepIndex === 1;
  const changeStepperStep =
    (back, newStepIndex = null) =>
    () =>
      dispatch(
        changeMatchingModalStep(
          back ? Math.max(stepIndex - 1, 0) : newStepIndex
        )
      );
  const resetModal = () => dispatch(reset());
  const dismissModal = () => {
    resetModal();
    closeAppModal();
  };
  const saveMatches = () => {
    dispatch(proceedToSaveMatches);
  };
  return (
    <div className={classes.root}>
      <div>
        <Button
          label="Close"
          variant="text"
          color="error"
          onClick={dismissModal}
          icon={<ClearIcon />}
        />
      </div>
      <Stack>
        <Button
          label="Back"
          disabled={stepIndex === 0}
          onClick={changeStepperStep(true)}
        />
        <Button
          label={stepsCompleted ? "Confirm Selected Matches" : "Next"}
          onClick={
            stepsCompleted
              ? saveMatches
              : changeStepperStep(false, stepIndex + 1)
          }
        />
      </Stack>
    </div>
  );
};

export default DRMInvoiceModalButtons;
