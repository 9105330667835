import React from "react";
import { connect } from "react-redux";

import AutoComplete from "@mui/material/Autocomplete";
import TextField from "ui-library/TextField";
import MenuItem from "ui-library/MenuItem";
import { updateField } from "./actions";

class AddPromAutoComplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: ""
    };
  }

  handleUpdateInput = event => {
    const searchText = event.target?.value || "";
    if (!this.props.noDispatch) {
      this.props.dispatch(
        updateField(
          this.props.db,
          `${this.props.parent}.${this.props.field}`,
          searchText
        )
      );
    }
    this.setState({
      searchText
    });
  };

  handleNewRequest = (event, chosenRequest) => {
    if (chosenRequest) {
      this.handleUpdateInput({ target: { value: chosenRequest.text } });
      if (!this.props.noDispatch) {
        this.props.dispatch(
          updateField(
            this.props.db,
            `${this.props.parent}.key`,
            chosenRequest.key
          )
        );
      }
      this.props.handleNewRequest(chosenRequest);
    }
  };

  componentDidMount() {
    if (this.props.searchText) {
      this.setState({
        searchText: this.props.searchText
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.searchText) {
      this.setState({
        searchText: nextProps.searchText
      });
    }
  }

  render() {
    return (
      <AutoComplete
        inputValue={this.state.searchText}
        onInputChange={this.handleUpdateInput}
        onChange={this.handleNewRequest}
        options={this.props.dataSource}
        renderOption={(props, option) => (
          <MenuItem {...props}>{option.value}</MenuItem>
        )}
        getOptionLabel={option => option.text}
        disabled={this.props.disabled}
        openOnFocus
        fullWidth
        freeSolo
        style={{ marginTop: "-10px" }}
        listStyle={{ maxHeight: 300, overflow: "auto" }}
        renderInput={params => (
          <TextField
            {...params}
            placeholder={this.props.hintText}
            errorText={this.props.errorText}
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-contact"
            }}
          />
        )}
      />
    );
  }
}

export default connect()(AddPromAutoComplete);
