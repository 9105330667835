import React, { Fragment } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { AxiosPromise } from "axios";
import { RefetchOptions } from "axios-hooks";
import ReactList from "react-list";
import useWindowSize from "helpers/useWindowSize";
import EditForecastCard from "./EditForecastCard";
import { ForecastVersion } from "./types";

const useStyles = makeStyles(() => ({
  listContainer: {
    overflow: "auto"
  },
  columnContainer: {
    width: "100%",
    overflowX: "auto",
    display: "flex",
    flexDirection: "row"
  }
}));

export const EditTable = ({
  versions,
  showCopyButton,
  refetch
}: {
  versions: ForecastVersion[];
  showCopyButton: boolean;
  refetch: (
    config?: any,
    options?: RefetchOptions | undefined
  ) => AxiosPromise<any>;
}) => {
  const classes = useStyles();
  const windowSize = useWindowSize();
  const height = windowSize.height || 0;

  const renderCard = (
    key: number | string,
    index: number,
    data: ForecastVersion[],
    showCopyButton: boolean
  ): JSX.Element => (
    <Fragment key={key}>
      <EditForecastCard
        version={data[index]}
        showCopyButton={showCopyButton}
        refetchVersions={refetch}
      />
    </Fragment>
  );

  return (
    <div className={classes.listContainer} style={{ maxHeight: height - 200 }}>
      <ReactList
        itemRenderer={(index: number, key: number | string) =>
          renderCard(key, index, versions, showCopyButton)
        }
        length={versions.length}
        type="simple"
      />
    </div>
  );
};

export default EditTable;
