import React from "react";
import axios from "axios";
import DataTable from "components/tables/MuiDataTable";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Paper from "@mui/material/Paper";
import Button from "ui-library/Button";
import TextField from "ui-library/TextField";
import { Divider } from "pui-react-dividers";
import { withSnackbar } from "notistack";
import { firebase, cloudRunFunctionURL } from "helpers/Firebase";

import { common, amber, green } from "@mui/material/colors";

const { white } = common;
const green500 = green["500"];
const amber500 = amber["500"];

const styles = {
  paper: {
    padding: 16
  },
  superheader: {
    fontFamily: "Ubuntu",
    marginTop: 20
  }
};

class Referrals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyUsers: {},
      refereeName: "",
      nameErrorText: "",
      refereeEmail: "",
      emailErrorText: "",
      userCompany: null,
      referrals: []
    };
  }

  getReferralLink = () => {
    const uid = firebase.auth().currentUser?.uid;
    return `https://govividly.com/signup?ref=${uid}`;
  };

  sendEmailReferral = () => {
    if (this.state.refereeName.length == 0) {
      this.setState({ nameErrorText: "Name required" });
      return;
    }
    if (!this.state.refereeEmail.includes("@")) {
      this.setState({ emailErrorText: "Email is invalid; must contain @." });
      return;
    }
    axios
      .post(`${cloudRunFunctionURL}/api/send_referral_email`, {
        referee_name: this.state.refereeName,
        referee_email: this.state.refereeEmail,
        referrer_email: firebase.auth().currentUser.email,
        referral_link: this.getReferralLink(),
        referrer_name: firebase.auth().currentUser.displayName,
        referrer_company: this.state.userCompany
      })
      .then(response => {
        console.log("response", response);
      });
    this.setState({ refereeEmail: "" });
    this.props.enqueueSnackbar(
      `Invitation email sent to ${this.state.refereeEmail}.`,
      {
        variant: "default",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center"
        }
      }
    );
  };

  getReferralTableJSX = () => {
    const referralTableJSX = {
      Email: [],
      Timestamp: [],
      Status: []
    };
    for (const [refID, refObj] of Object.entries(
      this.state.referrals
    ).reverse()) {
      referralTableJSX.Email.push(refObj.email);
      referralTableJSX.Timestamp.push(refObj.timestamp);
      referralTableJSX.Status.push(
        refObj.status == "Pending" ? (
          <div style={{ color: amber500 }}>Pending</div>
        ) : (
          <div style={{ color: green500 }}>Signed Up</div>
        )
      );
    }
    return referralTableJSX;
  };

  componentDidMount() {
    firebase.auth().onAuthStateChanged(user => {
      const userEmail = user.email;
      const emailHash = userEmail.replace(/[^a-zA-Z0-9]/g, "");
      firebase
        .database()
        .ref(`validEmails/${emailHash}`)
        .on("value", snapshot => {
          const validEmailObj = snapshot.val();
          this.setState({
            referrals: validEmailObj.referrals || [],
            userCompany: validEmailObj.company[validEmailObj.selectedCompany]
          });
        });
    });
  }

  render() {
    const referralTableJSX = this.getReferralTableJSX();
    return (
      <div style={{ marginLeft: 75, marginRight: 75, marginBottom: 16 }}>
        <Typography style={styles.superheader} variant="h4">
          Referrals
        </Typography>
        <Divider />
        <div style={{ display: "inline-block", width: "100%" }}>
          <div style={{ width: "40%", float: "left" }}>
            <Typography>
              Know someone who needs help revamping their workflows for trade
              and deductions processing? Invite them for a 30-day free trial of
              Vividly's Deductions Scanning Module! If they sign up as paying
              customer, after they submit their first payment, Vividly will send
              out rewards: you and the person referred will each get a{" "}
              <b>$1,000 gift card</b>!
            </Typography>
            <br />
            <Typography variant="subtitle1">Invite by Referral Link</Typography>
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "space-between"
              }}>
              <TextField
                floatingLabelText="Personal Referral Link"
                value={this.getReferralLink()}
                style={{ width: "83%" }}
                disabled
              />
              <Button
                label="Copy"
                onClick={() => {
                  navigator.clipboard.writeText(this.getReferralLink());
                  this.props.enqueueSnackbar("Link copied to clipboard.", {
                    variant: "default",
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "center"
                    }
                  });
                }}
              />
            </div>
            <br />
            <Typography variant="subtitle1">Invite by Email</Typography>
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "space-between"
              }}>
              <TextField
                floatingLabelText="Name"
                value={this.state.refereeName}
                style={{ width: "35%" }}
                onChange={event =>
                  this.setState({ refereeName: event.target.value })
                }
                errorText={this.state.nameErrorText}
              />
              <TextField
                floatingLabelText="Email"
                value={this.state.refereeEmail}
                style={{ width: "45%" }}
                onChange={event =>
                  this.setState({ refereeEmail: event.target.value })
                }
                errorText={this.state.emailErrorText}
              />
              <Button label="Invite" onClick={this.sendEmailReferral} />
            </div>
          </div>
          <div style={{ width: "60%", float: "right" }}>
            <Timeline>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot sx={{ backgroundColor: "primary.main" }}>
                    <Avatar
                      sx={{
                        width: 16,
                        height: 16,
                        color: white,
                        backgroundColor: "primary.main"
                      }}>
                      1
                    </Avatar>
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} style={{ padding: "6px 16px" }}>
                    <Typography variant="h6" component="h1">
                      Send Referral
                    </Typography>
                    <Typography>
                      Share your personal referral link or send your friend an
                      email.
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot sx={{ backgroundColor: "primary.main" }}>
                    <Avatar
                      sx={{
                        width: 16,
                        height: 16,
                        color: white,
                        backgroundColor: "primary.main"
                      }}>
                      2
                    </Avatar>
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} style={{ padding: "6px 16px" }}>
                    <Typography variant="h6" component="h1">
                      Keep Track
                    </Typography>
                    <Typography>
                      Track referrals in the below table and see their statuses.
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot sx={{ backgroundColor: "primary.main" }}>
                    <Avatar
                      sx={{
                        width: 16,
                        height: 16,
                        color: white,
                        backgroundColor: "primary.main"
                      }}>
                      3
                    </Avatar>
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} style={{ padding: "6px 16px" }}>
                    <Typography variant="h6" component="h1">
                      Get Rewarded!
                    </Typography>
                    <Typography>
                      You will get a $1,000 gift card when someone you referred
                      makes their first payment. They will get a $1,000 gift
                      card too!
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          </div>
        </div>
        <br />
        <div style={{ marginTop: 8 }}>
          <DataTable
            title="Track Referrals"
            data={referralTableJSX}
            widths={["33%", "33%", "33%"]}
          />
        </div>
      </div>
    );
  }
}

export default withSnackbar(Referrals);
