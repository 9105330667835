import _ from "lodash";

function objDiff(obj1, obj2) {
  if (!obj1) {
    obj1 = {};
  }
  if (!obj2) {
    obj2 = {};
  }
  const diff = Object.keys(obj1).reduce((result, key) => {
    if (
      !obj2.hasOwnProperty(key) &&
      !(Array.isArray(obj1[key]) && obj1[key].length === 0)
    ) {
      result.push(key);
    } else if (_.isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);
      result.splice(resultKeyIndex, 1);
    }
    return result;
  }, Object.keys(obj2));
  return diff;
}

export default objDiff;
