import Typography from "@mui/material/Typography";
import { Divider } from "pui-react-dividers";
import React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import AsIcon from "@mui/icons-material/AccountBalanceWallet";
import CaIcon from "@mui/icons-material/Group";
import MoneyIcon from "@mui/icons-material/MonetizationOn";
import PermissionIcon from "@mui/icons-material/Lock";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import SettingsIcon from "@mui/icons-material/Settings";
import SecurityIcon from "@mui/icons-material/Security";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import Paper from "@mui/material/Paper";
import Subheader from "ui-library/Subheader";
import MenuItem from "ui-library/MenuItem";
import Select from "ui-library/Select";

import {
  getUserDataFirebase,
  isUserCresicorEmployee,
  updateMetaFirebase,
  updateUserDataFirebase
} from "helpers/Firebase";
import { isForecastEnabled } from "helpers/Misc";
import AccountSelection from "./AccountSelection";
import AdminPanel from "./AdminPanel/AdminPanel";
import CustomerAssign from "./CustomerAssign";
import DeductionsRevenue from "./DeductionsRevenue";
import Miscellaneous from "./Miscellaneous";
import Security from "./Security/Security";
import { Promotions } from "./promotions";
import Permissions from "./Permissions";
import ForecastSettings from "./ForecastSettings/ForecastSettings";

const styles = {
  paper: {
    padding: 16
  },
  superheader: {
    fontFamily: "Ubuntu",
    marginTop: 20
  },
  input: {
    marginBottom: 20
  }
};

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newPg: "",
      notifSettings: {},
      selectedIndex: 0
    };
  }

  handleToggleNotif = (notif, event, isInputToggled) => {
    const { notifSettings } = this.state;
    notifSettings[notif] = isInputToggled;

    updateUserDataFirebase({ notif_settings: notifSettings });
    this.setState({
      notifSettings
    });
  };

  componentDidMount() {
    getUserDataFirebase(notifSettings => {
      this.setState({
        notifSettings
      });
    }, "notif_settings");
  }

  onSwitchTier(e, key, value) {
    updateMetaFirebase({ tier: value });
  }

  render() {
    const editPermission = this.props.db.permissions.includes("admin");

    return (
      <div style={{ marginLeft: 75, marginRight: 75, marginBottom: 16 }}>
        <Typography style={styles.superheader} variant="h4">
          Settings
        </Typography>
        <Divider />
        {this.props.db.meta && this.props.db.meta.demo && (
          <div>
            <Subheader>Switch Product Tier</Subheader>
            <Select
              value={this.props.db.meta.tier}
              onChange={this.onSwitchTier.bind(null)}
              style={styles.input}>
              {Object.keys(this.props.db.meta.tiers).map(tier => {
                return (
                  <MenuItem
                    value={tier}
                    primaryText={this.props.db.meta.tiers[tier]}
                    key={tier}>
                    {this.props.db.meta.tiers[tier]}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        )}
        <Paper elevation={2}>
          <BottomNavigation
            value={this.state.selectedIndex}
            onChange={(event, value) => {
              this.setState({ selectedIndex: value });
            }}
            showLabels>
            <BottomNavigationAction
              label="Permissions"
              icon={<PermissionIcon />}
              value={0}
            />
            <BottomNavigationAction
              label="Customer Assignment"
              icon={<CaIcon />}
              value={1}
            />
            <BottomNavigationAction
              label="Promotions"
              icon={<LocalOfferIcon />}
              value={2}
            />
            <BottomNavigationAction
              label="Account Assignment"
              icon={<AsIcon />}
              value={3}
            />
            <BottomNavigationAction
              label="Deductions & Revenue"
              icon={<MoneyIcon />}
              value={4}
            />
            <BottomNavigationAction
              label="Forecast"
              icon={<CloudQueueIcon />}
              value={5}
            />
            <BottomNavigationAction
              label="Security"
              icon={<SecurityIcon />}
              value={7}
            />
            <BottomNavigationAction
              label="Miscellaneous"
              icon={<SettingsIcon />}
              value={6}
            />
            {isUserCresicorEmployee() && (
              <BottomNavigationAction
                label="Admin Panel"
                icon={<AssignmentIndIcon />}
                value={8}
              />
            )}
          </BottomNavigation>
        </Paper>
        <br />
        {this.state.selectedIndex == 0 && (
          <Paper elevation={2} style={styles.paper}>
            {this.props.db && this.props.db.meta.tier == "aspen" ? (
              <Permissions
                db={this.props.db}
                openClose={this.props.openClose}
                editPermission={editPermission}
              />
            ) : (
              <div className="centering">
                Function only available in Aspen Tier.
              </div>
            )}
          </Paper>
        )}
        {this.state.selectedIndex == 1 && (
          <Paper elevation={2} style={styles.paper}>
            {this.props.db.permissions !== "None" ? (
              <div>
                {this.props.db && this.props.db.meta.tier == "aspen" ? (
                  <CustomerAssign
                    db={this.props.db}
                    openClose={this.props.openClose}
                    editPermission={editPermission}
                  />
                ) : (
                  <div className="centering">
                    Function only available in Aspen Tier.
                  </div>
                )}
              </div>
            ) : (
              <div className="centering">
                You do not have the permissions for this. Please contact an
                administrator.
              </div>
            )}
          </Paper>
        )}
        {this.state.selectedIndex == 2 && (
          <Paper elevation={2} style={styles.paper}>
            <Promotions db={this.props.db} openClose={this.props.openClose} />
          </Paper>
        )}
        {this.state.selectedIndex == 3 && (
          <Paper elevation={2} style={styles.paper}>
            {this.props.db ? (
              <AccountSelection
                db={this.props.db}
                openClose={this.props.openClose}
                editPermission={editPermission}
              />
            ) : (
              <div className="centering">
                You do not have the permissions for this. Please contact an
                administrator.
              </div>
            )}
          </Paper>
        )}
        {this.state.selectedIndex == 4 && (
          <Paper elevation={2} style={styles.paper}>
            {this.props.db.permissions !== "None" ? (
              <div>
                {this.props.db && this.props.db.meta.tier != "pine" ? (
                  <DeductionsRevenue
                    db={this.props.db}
                    openClose={this.props.openClose}
                    editPermission={editPermission}
                  />
                ) : this.props.db.meta.tier == "pine" ? (
                  <DeductionsRevenue
                    db={this.props.db}
                    openClose={this.props.openClose}
                    editPermission={editPermission}
                  />
                ) : (
                  <div className="centering">
                    Function only available in Aspen Tier.
                  </div>
                )}
              </div>
            ) : (
              <div className="centering">
                You do not have the permissions for this. Please contact an
                administrator.
              </div>
            )}
          </Paper>
        )}
        {this.state.selectedIndex == 5 && (
          <Paper elevation={2} style={styles.paper}>
            {this.props.db.permissions !== "None" ? (
              <div>
                {isForecastEnabled(this.props.db) ? (
                  <ForecastSettings
                    db={this.props.db}
                    openClose={this.props.openClose}
                    editPermission={editPermission}
                  />
                ) : (
                  <div className="centering">
                    Function only available in Pine+ and Aspen Tier.
                  </div>
                )}
              </div>
            ) : (
              <div className="centering">
                You do not have the permissions for this. Please contact an
                administrator.
              </div>
            )}
          </Paper>
        )}
        {this.state.selectedIndex == 6 && (
          <Paper elevation={2} style={styles.paper}>
            <Miscellaneous
              db={this.props.db}
              openClose={this.props.openClose}
            />
          </Paper>
        )}
        {this.state.selectedIndex == 7 && (
          <Paper elevation={2} style={styles.paper}>
            <div style={{ margin: 10 }}>
              <Security db={this.props.db} openClose={this.props.openClose} />
            </div>
          </Paper>
        )}
        {this.state.selectedIndex == 8 && (
          <Paper elevation={2} style={styles.paper}>
            <div style={{ margin: 10 }}>
              <AdminPanel db={this.props.db} openClose={this.props.openClose} />
            </div>
          </Paper>
        )}
      </div>
    );
  }
}

export default Settings;
