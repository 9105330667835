import React from "react";
import { displayMoney } from "helpers/DataProcessing";
import {
  REVENUE,
  TRADE_RATE,
  TRADE_SPEND,
  comparisonTypes,
  broadFigureTypes,
  pivotTypes
} from "./constants";

const renderPercentage = value => {
  const sanitizedValue = Number(value);
  return sanitizedValue !== 0 ? `${sanitizedValue.toFixed(2)}%` : `${value}%`;
};

const roundMoney = value => displayMoney(Math.round(Number(value)));

const numberRenderer = ({
  value,
  original: { isMoney = false, isPercentage = false }
}) => {
  let formattedValue = 0;
  let className = "";
  if (typeof value === "object") {
    formattedValue = value.figure;
    className = value?.highlight ? "is-beyond-threshold" : "";
  } else {
    formattedValue = value;
  }
  formattedValue = Number.isFinite(formattedValue) ? formattedValue : 0;
  if (isMoney) {
    formattedValue = roundMoney(formattedValue);
  } else if (isPercentage) {
    formattedValue = renderPercentage(formattedValue);
  }
  return <div className={className}>{formattedValue}</div>;
};

const roundMillions = value => {
  let roundedValue = Number(value);
  if (value >= 1_000_000) {
    const millionFigure = (roundedValue / 1_000_000).toFixed(1);
    roundedValue = `${millionFigure}m`;
  } else {
    roundedValue = Math.round(roundedValue);
  }
  return `$${roundedValue}`;
};

const sanitizeSplit = split =>
  Number.isInteger(split) ? split : Number(split) / 100;

const renderLEFigure = figureType => value =>
  [TRADE_SPEND, REVENUE].includes(figureType)
    ? roundMillions(value)
    : renderPercentage(value);

const renderTickFigure = (
  figureType,
  pivot = pivotTypes.DATE,
  dataSubTypesForPivot = []
) => {
  const figureTypeForPivot =
    pivot === pivotTypes.DATE
      ? figureType
      : dataSubTypesForPivot.find(subType =>
          broadFigureTypes.includes(subType)
        );
  return tick =>
    [TRADE_SPEND, REVENUE].includes(figureTypeForPivot)
      ? roundMoney(tick)
      : renderPercentage(tick);
};

const tooltipFormatter = figureType => value =>
  [TRADE_SPEND, REVENUE].includes(figureType)
    ? roundMoney(value)
    : renderPercentage(value);

const comparisonTooltipFormatter =
  (figureType, comparisonType) => (value, name) => {
    const { title } = comparisonTypes[comparisonType];
    if (figureType === TRADE_RATE || name === `${title} %`) {
      return renderPercentage(value);
    }

    return roundMoney(value);
  };

const pivotTooltipFormatter = dataSubTypesForPivot => (value, name) => {
  const figureType = dataSubTypesForPivot.find(subType =>
    broadFigureTypes.includes(subType)
  );

  if (figureType === TRADE_RATE || name === "Share Of Total") {
    return renderPercentage(value);
  }

  return roundMoney(value);
};

export {
  renderPercentage,
  roundMoney,
  numberRenderer,
  roundMillions,
  sanitizeSplit,
  renderLEFigure,
  renderTickFigure,
  tooltipFormatter,
  comparisonTooltipFormatter,
  pivotTooltipFormatter
};
