import React from "react";

import ReactTable from "react-table";
import Dialog from "ui-library/Dialog";

import { displayMoney } from "helpers/DataProcessing";
import { grey } from "@mui/material/colors";
import Spend from "../RevDed/Spend";

const grey200 = grey["200"];

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];
const quarters = ["Q1", "Q2", "Q3", "Q4"];

const styles = {
  modalTitle: {
    borderStyle: "solid",
    borderWidth: 0,
    borderBottomWidth: 2,
    borderBottomColor: grey200
  },
  modalActions: {
    borderStyle: "solid",
    borderWidth: 0,
    borderTopWidth: 2,
    borderTopColor: grey200
  },
  dialogRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 0,
    paddingBottom: 10,
    zIndex: 500
  },
  dialogContent: {
    position: "relative",
    minWidth: "90vw",
    maxWidth: "90vw",
    transform: ""
  },
  dialogBody: {
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: 650
  }
};

class MoneyTableTiming extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      modalOpen: false,
      title: "",
      additionalSearch: {}
    };
  }

  openSpend = (promTime, spendTime) => {
    const { year } = this.props;
    const additionalSearch = { ...this.props.filters };
    // set spend search fields
    if (spendTime.startsWith("Q")) {
      // quarterly view
      const quarter = spendTime.split("-")[0];
      const converter = {
        Q1: `Jan-${year}`,
        Q2: `Apr-${year}`,
        Q3: `Jul-${year}`,
        Q4: `Oct-${year}`
      };
      var spendStartDate = new Date(converter[quarter]);
      var spendEndDate = new Date(
        spendStartDate.getFullYear(),
        spendStartDate.getMonth() + 3,
        0
      );
    } else {
      var spendStartDate = new Date(spendTime);
      var spendEndDate = new Date(
        spendStartDate.getFullYear(),
        spendStartDate.getMonth() + 1,
        0
      );
    }
    additionalSearch.startDate = spendStartDate;
    additionalSearch.endDate = spendEndDate;
    // set promotion search fields
    if (promTime == "Past Years") {
      additionalSearch.promEndDate = new Date(`${year - 1}/12/31`);
    } else {
      additionalSearch.promStartDate = new Date(`${year + 1}/1/1`);
    }
    // set modal properties
    this.setState({
      modalOpen: true,
      title: `${spendTime} Deductions Items From ${promTime} Promotions`,
      additionalSearch
    });
  };

  renderCell = (length, row) => {
    if (row.column.id == "timeField") {
      return <div>{row.value}</div>;
    }
    if (row.viewIndex <= 1 && row.column.id != "Total") {
      const promTime = row.original.timeField;
      const spendTime = row.column.Header;
      return (
        <div
          style={{ cursor: "pointer" }}
          onClick={this.openSpend.bind(null, promTime, spendTime)}>
          {displayMoney(row.value)}
        </div>
      );
    }
    // in case of percentages where denominator is 0 (e.g 1000/0, 0/0)
    if (isNaN(row.value) || row.value === Infinity) {
      return <div style={{ color: "#990000" }}>N/A</div>;
    }
    return (
      <div>
        {row.viewIndex < length - 1 ? displayMoney(row.value) : `${row.value}%`}
      </div>
    );
  };

  getColumns = props => {
    const columns = [];
    columns.push({
      Header: "",
      columns: [
        {
          Header: "",
          accessor: "timeField",
          Cell: this.renderCell.bind(null, props.data.length)
        }
      ]
    });
    const subcolumns = [];
    if (props.quarter) {
      for (const quarter of quarters) {
        subcolumns.push({
          Header: `${quarter}-${props.year}`,
          accessor: `${quarter}-${props.year}`,
          Cell: this.renderCell.bind(null, props.data.length)
        });
      }
    } else {
      for (const month of months) {
        subcolumns.push({
          Header: `${month}-${props.year}`,
          accessor: `${month}-${props.year}`,
          Cell: this.renderCell.bind(null, props.data.length)
        });
      }
    }
    columns.push({
      Header: "Timing of Deductions Received",
      columns: subcolumns
    });
    columns.push({
      Header: "Total",
      columns: [
        {
          Header: "Total",
          accessor: "Total",
          Cell: this.renderCell.bind(null, props.data.length)
        }
      ]
    });

    this.setState({ columns });
  };

  componentDidMount() {
    this.getColumns(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getColumns(nextProps);
  }

  render() {
    return (
      <div>
        <ReactTable
          data={this.props.data}
          resolveData={data => data.map(row => row)}
          columns={this.state.columns}
          pageSize={this.props.data.length}
          showPaginationBottom={false}
          style={{ fontFamily: "PT sans" }}
        />
        <Dialog
          dialogTitle={this.state.title}
          open={this.state.modalOpen}
          onClose={() => this.setState({ modalOpen: false })}
          autoScrollBodyContent
          repositionOnUpdate={false}
          titleStyle={styles.modalTitle}
          actionsContainerStyle={styles.modalActions}
          contentStyle={styles.dialogContent}
          bodyStyle={styles.dialogBody}
          style={styles.dialogRoot}>
          <div
            style={{
              maxHeight: 650,
              display: "flex",
              flexDirection: "column"
            }}>
            <Spend
              {...{ ...this.props.childProps, ...{ readOnly: true } }}
              additionalSearch={this.state.additionalSearch}
            />
          </div>
        </Dialog>
      </div>
    );
  }
}

export default MoneyTableTiming;
