enum invoiceTypes {
  TRADE_DEDUCTION = "trade_deduction",
  NON_TRADE_DEDUCTION = "non_trade_deduction",
  BILLPAY = "billpay",
  REPAYMENT = "repayment"
}

enum invoiceUploadSources {
  MANUAL = "manual",
  WEB_SCRAPER = "web_scraper"
}

enum ERPTransactionTypes {
  DEDUCTION = "deduction",
  BILLPAY = "billpay",
  REPAYMENT = "repayment",
  UNKNOWN = "unknown"
}

enum accountingSources {
  NETSUITE = "NetSuite",
  QUICKBOOKS = "QuickBooks",
  SAP = "SAP",
  OTHER = "Other"
}

enum resolutionLineTypes {
  CLEAR = "clear",
  DISPUTE = "dispute",
  WRITE_OFF = "write_off",
  SALES_REVIEW = "sales_review"
}

enum repaymentLineTypes {
  REPAYMENT = "repayment",
  CREDIT_WRITE_OFF = "credit_write_off"
}
const NULL_INVOICE_FIELD_VALUE = "NULL_INVOICE_FIELD_VALUE";

export {
  invoiceTypes,
  ERPTransactionTypes,
  accountingSources,
  resolutionLineTypes,
  invoiceUploadSources,
  repaymentLineTypes,
  NULL_INVOICE_FIELD_VALUE
};
