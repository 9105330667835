import React from "react";
import classNames from "classnames";
import { Divider } from "pui-react-dividers";

import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";

import Card from "ui-library/Card";
import Paper from "@mui/material/Paper";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import DateRangeIcon from "@mui/icons-material/DateRange";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "ui-library/IconButton";
import MenuItem from "ui-library/MenuItem";
import Select from "ui-library/Select";
import Subheader from "ui-library/Subheader";

import { getJSXListsFirebase, firebase } from "helpers/Firebase";
import { sortByField } from "helpers/sortByDate";
import { grey, common } from "@mui/material/colors";
import { Stack, Typography } from "@mui/material";
import { graphPalette } from "helpers/ColorPalettes";
import { createNewEvent } from "./helpers";
import MonthView from "./MonthView";
import YearView from "./YearView";

const grey500 = grey["500"];
const { black } = common;
const { blue80, green80, purple80 } = graphPalette;

const styles = {
  calendarFull: {
    marginTop: 50,
    marginLeft: 150,
    marginRight: 150,
    marginBottom: 50,
    fontFamily: "PT Sans"
  },
  calendarSplit: {
    margin: 16,
    fontFamily: "PT Sans"
  },
  subheader: {
    fontSize: 20
  },
  input: {
    margin: 5
  },
  panel: {
    borderRadius: 0,
    marginTop: 10,
    marginBottom: 10
  },
  panelHeader: {
    cursor: "pointer"
  },
  legend: {
    display: "inline-block",
    width: "16px",
    height: "16px",
    borderRadius: "4px"
  }
};

class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promotions: {},
      status: 0,
      selectedCustomers: [-1],
      // customer: null,
      account: null,
      selectedProductGroups: [-1],
      // productGroup: null,
      splitscreen: false,
      rightComponentJSX: (
        <div style={{ height: "100%", color: grey500 }} className="centering">
          <div>Click on a promotion to view details.</div>
        </div>
      ),
      customersJSX: [],
      productsJSX: [],
      productGroupsJSX: [],
      accountsJSX: [],
      typesJSX: [],
      selectedIndex: 0,
      userCustomerList: [],
      promTypesJSX: [],
      selectedPromTypes: [-1]
    };
  }

  getCalendarEvents = () => {
    // Obtain events from database
    const { clist } = this.props;
    const events = [];
    let calendarDate = new Date();
    let earliestDate = null;
    let promotionsInCurrentMonth = false;
    for (let index = 0; index < clist.length; index += 1) {
      var line = clist[index];
      const { status } = line;
      // skip cancelled proms
      if (status === 7) {
        continue;
      }

      // scan through all lines to see if product group and/or prom type matches
      let matchesProductGroup = false;
      let matchesPromType = false;

      for (const l of Object.values(line.lines)) {
        if (this.state.selectedProductGroups.includes(l.productGroup)) {
          matchesProductGroup = true;
        }
        if (this.state.selectedPromTypes.includes(l.type.toString())) {
          matchesPromType = true;
        }
      }

      if (
        (this.state.selectedCustomers.includes(-1) ||
          this.state.selectedCustomers.includes(line.customer)) &&
        (this.state.userCustomerList.length == 0 ||
          this.state.userCustomerList.includes(line.customer)) &&
        (this.state.selectedProductGroups.includes(-1) ||
          matchesProductGroup) &&
        (this.state.selectedPromTypes.includes(-1) || matchesPromType) &&
        this.props.db.meta &&
        (this.state.status == 0 ||
          (this.state.status == 1 &&
            this.props.db.meta.planning.indexOf(status) != -1) ||
          (this.state.status == 2 &&
            this.props.db.meta.execution.indexOf(status) != -1) ||
          (this.state.status == 3 &&
            this.props.db.meta.completed.indexOf(status) != -1))
      ) {
        const startDate = new Date(
          Math.min.apply(
            null,
            Object.keys(line.lines).map(x => new Date(line.lines[x].startDate))
          )
        );
        const endDate = new Date(
          Math.max.apply(
            null,
            Object.keys(line.lines).map(x => new Date(line.lines[x].endDate))
          )
        );

        earliestDate = earliestDate
          ? new Date(Math.min.apply(null, [earliestDate, startDate]))
          : startDate;
        if (
          startDate.getMonth() == calendarDate.getMonth() ||
          endDate.getMonth() == calendarDate.getMonth()
        ) {
          promotionsInCurrentMonth = true;
        }
        const color = this.props.db.meta.planning.includes(status)
          ? purple80
          : this.props.db.meta.execution.includes(status)
          ? blue80
          : green80;
        const year = new Date().getFullYear();

        // set events, month/year view
        if (this.state.selectedIndex == 0) {
          // monthly view
          const dayAfterEnd = new Date(endDate);
          dayAfterEnd.setDate(endDate.getDate() + 1);
          const event = {
            title: line.name,
            allDay: true,
            start: startDate,
            end: dayAfterEnd,
            promKey: line.promKey,
            editable: false,
            color,
            textColor: black
          };
          events.push(event);
        } else if (this.state.selectedIndex == 1) {
          // yearly view
          const newEvents = createNewEvent(
            line.name,
            startDate,
            endDate,
            line.promKey,
            color
          );
          events.push(...newEvents);
        }
      }
    }
    calendarDate = earliestDate;
    return [calendarDate, events];
  };

  handleResetFields = () => {
    this.setState({
      selectedCustomers: [-1],
      status: 0,
      selectedProductGroups: [-1],
      selectedPromTypes: [-1]
    });
  };

  getMenuItems(originalJSX, selectedGroup) {
    const allSelected = !!selectedGroup.includes(-1);
    const newJSX = [];

    // for the ALL option
    if (allSelected) {
      newJSX.push(
        React.cloneElement(originalJSX[0], {
          checked: true,
          insetChildren: true
        })
      );
    } else {
      newJSX.push(
        React.cloneElement(originalJSX[0], {
          checked: false,
          insetChildren: true
        })
      );
    }

    // for all remaining product groups
    for (let i = 1; i < originalJSX.length; i++) {
      if (allSelected) {
        newJSX.push(React.cloneElement(originalJSX[i]));
      } else {
        const singleSelected =
          selectedGroup && selectedGroup.includes(originalJSX[i].value);
        if (singleSelected) {
          // check off this specific item
          newJSX.push(
            React.cloneElement(originalJSX[i], {
              checked: true,
              insetChildren: true
            })
          );
        } else {
          // leave unchecked
          newJSX.push(
            React.cloneElement(originalJSX[i], {
              checked: false,
              insetChildren: true
            })
          );
        }
      }
    }

    return newJSX;
  }

  componentDidMount() {
    const { uid } = firebase.auth().currentUser;
    let userCustomerList = this.props.db.companyUsers[uid].customers;
    // 0-length array means every customer is allowed.
    if (!userCustomerList) userCustomerList = [];

    // Get customers
    const allCustomers = this.props.db.customers;
    let customerList = [];
    for (var key in allCustomers) {
      const customer = allCustomers[key];
      customer.key = key;
      customerList.push(customer);
    }

    // get all promotion types
    const allPromTypes = this.props.db.meta.fundTypes ?? {};
    const promTypeList = [];
    for (var key in allPromTypes) {
      const promType = { name: allPromTypes[key].name, key };
      promTypeList.push(promType);
    }
    promTypeList.sort((a, b) => (a.name < b.name ? -1 : 1));

    customerList = sortByField("name", customerList, true);

    const singleCustomersJSX = customerList
      .filter(customer => {
        // only include customers this user is allowed to see
        return (
          userCustomerList.length == 0 ||
          userCustomerList.includes(customer.key)
        );
      })
      .map(customer => {
        return (
          <MenuItem
            value={customer.key}
            children={customer.name}
            key={customer.key}
          />
        );
      });

    const singlePromTypesJSX = promTypeList.map(promType => {
      return (
        <MenuItem
          value={promType.key}
          children={promType.name}
          key={promType.key}
        />
      );
    });

    getJSXListsFirebase(this.props.db, allJSX => {
      this.setState({
        customersJSX: [
          <MenuItem value={-1} children="All Customers" key={-1} />
        ].concat(singleCustomersJSX),
        customers: [-1],
        productGroupsJSX: [
          <MenuItem value={-1} children="All Product Groups" key={-1} />
        ].concat(allJSX.productGroupsJSX),
        productGroups: [-1],
        userCustomerList,
        selectedPromTypes: [-1],
        promTypesJSX: [
          <MenuItem value={-1} children="All Promotion Types" key={-1} />
        ].concat(singlePromTypesJSX)
      });
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { uid } = firebase.auth().currentUser;
    let userCustomerList = this.props.db.companyUsers[uid].customers;
    // 0-length array means every customer is allowed.
    if (!userCustomerList) userCustomerList = [];

    if (userCustomerList != this.state.userCustomerList) {
      this.setState({ userCustomerList });
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    const calEvents = this.getCalendarEvents(this.state.statusFilter);
    const calendarDate = calEvents[0];
    const events = calEvents[1];

    return (
      <div>
        <div style={{ overflow: "auto" }}>
          <div style={{ padding: 16 }}>
            <Paper elevation={2}>
              <BottomNavigation
                value={this.state.selectedIndex}
                onChange={(event, value) => {
                  this.setState({ selectedIndex: value });
                }}
                showLabels>
                <BottomNavigationAction
                  label="Month View"
                  icon={<AvTimerIcon />}
                  value={0}
                />
                <BottomNavigationAction
                  label="Year View"
                  icon={<DateRangeIcon />}
                  value={1}
                />
              </BottomNavigation>
            </Paper>
          </div>
          <div
            className={classNames("analytics-options", {
              hidden: this.state.hideOptions
            })}
            style={{ padding: 16 }}>
            <Stack>
              <Subheader style={styles.subheader}>Filter Inputs</Subheader>
              <IconButton
                onClick={this.handleResetFields}
                color="tonal"
                isBackgroundColor>
                <AutorenewIcon />
              </IconButton>
            </Stack>
            <div style={{ fontFamily: "Oxygen" }}>
              Click on a specific promotion to view individual lines.
            </div>
            <br />
            <div className="row" ref={this.props.filtersRef}>
              <div className="col-sm">
                <Card title="Product Grouping">
                  <Select
                    multiple
                    value={this.state.selectedProductGroups}
                    onChange={(event, index, values) => {
                      const allSelectedBefore =
                        this.state.selectedProductGroups.includes(-1);
                      let newValues = values;
                      if (allSelectedBefore && values.length > 1) {
                        // something else is clicked, deselect ALL choice
                        newValues = values.filter((val, index, arr) => {
                          return val != -1;
                        });
                      } else if (!allSelectedBefore && values.includes(-1)) {
                        newValues = [-1];
                      }
                      this.setState({ selectedProductGroups: newValues });
                    }}
                    fullWidth
                    style={styles.input}>
                    {this.state.productGroupsJSX}
                  </Select>
                </Card>
              </div>
              <div className="col-sm">
                <Card title="Customer">
                  <Select
                    multiple
                    value={this.state.selectedCustomers}
                    onChange={(event, index, values) => {
                      const allSelectedBefore =
                        this.state.selectedCustomers.includes(-1);
                      let newValues = values;
                      if (allSelectedBefore && values.length > 1) {
                        // something else is clicked, deselect ALL choice
                        newValues = values.filter((val, index, arr) => {
                          return val != -1;
                        });
                      } else if (!allSelectedBefore && values.includes(-1)) {
                        newValues = [-1];
                      }
                      this.setState({ selectedCustomers: newValues });
                    }}
                    fullWidth
                    style={styles.input}>
                    {this.state.customersJSX}
                  </Select>
                </Card>
              </div>
              <div className="col-sm">
                <Card title="Promotion Type">
                  <Select
                    multiple
                    value={this.state.selectedPromTypes}
                    onChange={(event, index, values) => {
                      const allSelectedBefore =
                        this.state.selectedPromTypes.includes(-1);
                      let newValues = values;
                      if (allSelectedBefore && values.length > 1) {
                        // something else is clicked, deselect ALL choice
                        newValues = values.filter((val, index, arr) => {
                          return val != -1;
                        });
                      } else if (!allSelectedBefore && values.includes(-1)) {
                        newValues = [-1];
                      }
                      this.setState({ selectedPromTypes: newValues });
                    }}
                    fullWidth
                    style={styles.input}>
                    {this.state.promTypesJSX}
                  </Select>
                </Card>
              </div>
              <div className="col-sm">
                <Card title="Status">
                  <Select
                    value={this.state.status}
                    onChange={(event, index, value) => {
                      this.setState({ status: value });
                    }}
                    fullWidth
                    style={styles.input}>
                    <MenuItem value={0} children="All Statuses" />
                    <MenuItem value={1} children="Planning" />
                    <MenuItem value={2} children="Execution" />
                    <MenuItem value={3} children="Completed" />
                  </Select>
                </Card>
              </div>
            </div>
          </div>
          <Divider />
          <div className="centering" style={{ width: "100%" }}>
            <div
              className="options-toggle-button"
              onClick={() => {
                this.setState({ hideOptions: !this.state.hideOptions });
              }}>
              {this.state.hideOptions ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowUpIcon />
              )}
            </div>
          </div>
          <br />
          <Stack spacing={2} sx={{ ml: "100px" }}>
            <Typography>STATUSES:</Typography>
            <Stack>
              <div
                style={{ backgroundColor: `${purple80}`, ...styles.legend }}
              />
              <Typography> Planning </Typography>
            </Stack>
            <Stack>
              <div
                style={{
                  backgroundColor: `${blue80}`,
                  ...styles.legend
                }}
              />
              <Typography> Execution </Typography>
            </Stack>
            <Stack>
              <div
                style={{
                  backgroundColor: `${green80}`,
                  ...styles.legend
                }}
              />
              <Typography> Completed </Typography>
            </Stack>
          </Stack>
          <div ref={this.props.calendarRef}>
            {this.state.selectedIndex == 0 ? (
              <MonthView
                events={events}
                calendarDate={calendarDate}
                openClose={this.props.openClose}
                db={this.props.db}
              />
            ) : (
              <YearView
                events={events}
                db={this.props.db}
                openClose={this.props.openClose}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Calendar;
