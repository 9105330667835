import React from "react";

import {
  ComposedChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceArea
} from "recharts";

import { graphPalette } from "helpers/ColorPalettes";

const { pink70 } = graphPalette;

const styles = {
  header: {
    fontSize: 20
  }
};

class LabelAsPoint extends React.Component {
  onClick = () => {
    const { index, key, payload } = this.props;
    this.props.handleClick(index);
  };

  render() {
    const { x, y } = this.props;
    return (
      <circle
        className="rechart-dot"
        onClick={this.onClick}
        cx={x}
        cy={y}
        r={4}
        fill={pink70}
      />
    );
  }
}

class ComplianceTimeSeries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animation: true
    };
  }

  componentDidMount() {
    this.setState({
      animation: false
    });
  }

  render() {
    return this.props.data && this.props.data.length > 0 ? (
      <ResponsiveContainer width="100%" aspect={4}>
        <ComposedChart
          width={650}
          height={400}
          data={this.props.data}
          margin={{ top: 20, right: 10, bottom: 0, left: 15 }}
          syncId={this.props.syncId}>
          <defs>
            <linearGradient id="colorX" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={pink70} stopOpacity={0.8} />
              <stop offset="95%" stopColor={pink70} stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="date" scale="point" domain={["dataMin", "dataMax"]} />
          <YAxis dataKey="Compliance" orientation="left" yAxisId="left" />
          <YAxis dataKey="Compliance" orientation="right" yAxisId="right" />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip
            contentStyle={{
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              color: "white"
            }}
          />
          <Legend />
          <Area
            // added change here
            name="Compliance (TDP, Any Promo / TDP)"
            dataKey="Compliance"
            type="monotone"
            stroke={pink70}
            fill="url(#colorX)"
            isAnimationActive={this.state.animation}
            activeDot={false}
            label={<LabelAsPoint handleClick={this.props.handleClick} />}
            yAxisId="left"
          />
          <ReferenceArea
            x1={
              this.props.startIndex != null &&
              this.props.startIndex in this.props.data
                ? this.props.data[this.props.startIndex].date
                : ""
            }
            x2={
              this.props.endIndex != null &&
              this.props.endIndex in this.props.data
                ? this.props.data[this.props.endIndex].date
                : ""
            }
            stroke="red"
            strokeOpacity={0.3}
            yAxisId="right"
          />
        </ComposedChart>
      </ResponsiveContainer>
    ) : (
      <div className="centering" style={{ margin: 16 }}>
        Please upload time series data with TDP fields to view chart.
      </div>
    );
  }
}

export default ComplianceTimeSeries;
