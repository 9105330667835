import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum DRMRouteState {
  TRANSACTIONS = "transactions",
  INVOICES = "invoices"
}

export enum DRMGuiMode {
  LIST = "list",
  BOARD = "board"
}

export enum DRMFeature {
  BACKUP_RETRIEVAL = "backup_retrieval",
  RECONCILIATION = "reconciliation"
}

export enum DRMFeatureTitle {
  BACKUP_RETRIEVAL = "Backup Retrieval",
  RECONCILIATION = "Deductions Reconciliation"
}

interface DRMState {
  routeState?: DRMRouteState;
  guiMode: DRMGuiMode;
  drmFeatureState: DRMFeature;
  drmFeatureTitleState: DRMFeatureTitle;
}

const initialState: Partial<DRMState> = {
  routeState: undefined,
  guiMode: DRMGuiMode.LIST,
  drmFeatureState: DRMFeature.RECONCILIATION,
  drmFeatureTitleState: DRMFeatureTitle.RECONCILIATION
};

const slice = createSlice({
  name: "drm",
  initialState,
  reducers: {
    setDRMRouteState(state: DRMState, action: PayloadAction<DRMRouteState>) {
      state.routeState = action.payload;
    },
    setDRMGuiMode(state: DRMState, action: PayloadAction<DRMGuiMode>) {
      state.guiMode = action.payload;
    },
    setDRMFeatureState(state: DRMState, action: PayloadAction<DRMFeature>) {
      state.drmFeatureState = action.payload;
      state.drmFeatureTitleState =
        action.payload === DRMFeature.RECONCILIATION
          ? DRMFeatureTitle.RECONCILIATION
          : DRMFeatureTitle.BACKUP_RETRIEVAL;
    }
  }
});

// export redux action
export const { setDRMRouteState, setDRMGuiMode, setDRMFeatureState } =
  slice.actions;

// export reducer as default import
export default slice.reducer;
