import React from "react";

import Button from "ui-library/Button";
import TextField from "ui-library/TextField";

import { addFirebase } from "helpers/Firebase";

export default class NewContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newContact: {}
    };
  }

  getErrorText = id => {
    return this.props.contactState && this.props.contactState[id] === ""
      ? "This field is required"
      : "";
  };

  saveToDatabase = () => {
    // first, check for errors
    if (!this.state.newContact.name) {
      this.setState({
        nameError: "Name is required."
      });
      return;
    }

    for (const key in this.props.db.contacts) {
      const existingContact = this.props.db.contacts[key];
      if (
        this.state.newContact.name == existingContact.name &&
        this.state.newContact.email == existingContact.email
      ) {
        this.setState({
          nameError: "Contact (name and email) already exists in database."
        });
        return;
      }
    }

    addFirebase(3, this.state.newContact);
    this.setState({
      newContact: {}
    });
    this.props.openClose.closeAppModal();
  };

  handleTextChange = event => {
    const { newContact } = this.state;
    newContact[event.target.id] = event.target.value;
    this.setState({ newContact });
  };

  render() {
    return (
      <div>
        <div>
          <br />
          <TextField
            floatingLabelText="Name"
            id="name"
            onChange={this.handleTextChange}
            errorText={this.state.nameError}
            fullWidth
          />
          <br />
          <div className="centering">
            <TextField
              floatingLabelText="Company"
              id="company"
              onChange={this.handleTextChange}
              fullWidth
              style={{ marginRight: "16px" }}
            />
            <TextField
              floatingLabelText="Title"
              id="title"
              onChange={this.handleTextChange}
              fullWidth
            />
          </div>
          <TextField
            floatingLabelText="Contact Email"
            id="email"
            onChange={this.handleTextChange}
            fullWidth
          />
          <br />
          <TextField
            floatingLabelText="Phone Number"
            id="phone"
            onChange={this.handleTextChange}
            fullWidth
          />
          <br />
          <br />
          <div className="centering">
            <Button label="Save to Database" onClick={this.saveToDatabase} />
          </div>
          <br />
          <br />
        </div>
      </div>
    );
  }
}
