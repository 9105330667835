import * as $ from "jquery";
import moment from "moment";
import _ from "lodash";
import { Customer, Price } from "../../dbTypes";

// TODO: actual date at which the distributor loop occurs
// and more description of the nature of the loop
const loopError =
  "This operation will cause a cyclic distributor dependency between two or more customers in the system. Please resolve this issue before attempting to add this distributor.";

// TODO: actual date
function inconsistentError(c: Customer, d: Customer) {
  return `At a certain point in time, customer ${d.name} is not marked as Direct or Distributor, but will be listed as a first receiver for customer ${c.name}. Please resolve this issue first!`;
}

function compareDates(d1: Date, d2: Date): number {
  // undefined == undefined; undefined < date; date < undefined
  if (Number.isNaN(d1?.getTime())) d1 = undefined;
  if (Number.isNaN(d2?.getTime())) d2 = undefined;

  if (!d1 && !d2) {
    return 0;
  }
  if (!d1) {
    return -1;
  }
  if (!d2) {
    return -1;
  }

  if (d1.getFullYear() > d2.getFullYear()) return 1;
  if (d1.getFullYear() < d2.getFullYear()) return -1;

  if (d1.getMonth() > d2.getMonth()) return 1;
  if (d1.getMonth() < d2.getMonth()) return -1;

  if (d1.getDate() > d2.getDate()) return 1;
  if (d1.getDate() < d2.getDate()) return -1;

  return 0;
}

function timeframeType(obj: any, i: number, begin: Date, end: Date): string {
  if (Number.isNaN(begin?.getTime())) begin = undefined;
  if (Number.isNaN(end?.getTime())) end = undefined;

  begin = begin ?? new Date("1/1/1970");
  end = end ?? new Date("12/31/9999");
  const timeframe = obj?.timeframes?.[i] ?? obj;

  const startDate = timeframe.effectiveDate;
  const endDate = timeframe.expirationDate;

  if (startDate && compareDates(end, new Date(startDate)) < 0) {
    return "future";
  }
  if (endDate && compareDates(begin, new Date(endDate)) > 0) {
    return "past";
  }
  return "present";
}

function getCurrentTimeframe(obj: any, curDate: Date): any {
  const timeframes = obj?.timeframes ?? [obj];
  return timeframes[getCurrentTimeframeIndex(obj, curDate)];
}

function getCurrentTimeframeIndex(obj: any, curDate: Date): number {
  for (let i = 0; i < obj?.timeframes?.length; i++) {
    if (timeframeType(obj, i, curDate, curDate) === "present") {
      return i;
    }
  }
  return 0;
}

// Returns timeframe indices that overlap with the range [startDate, endDate]
// If no timeframes at all, outputs [0]
function getTimeframeRange(obj: any, startDate: Date, endDate: Date): number[] {
  if (!obj) return [0];

  const range = [];
  for (let i = 0; i < obj.timeframes?.length; i++) {
    if (timeframeType(obj, i, startDate, endDate) === "present") {
      range.push(i);
    }
  }
  return range.length ? range : [0];
}

function getVisibleDistributors(
  customerKey: string,
  customer: Customer,
  date: Date
): string[] {
  customer = $.extend(true, {}, customer);

  // Figure out timeframe-aware distributors
  const timeframeRange = getTimeframeRange(
    customer,
    new Date(date.getFullYear(), date.getMonth(), 1),
    new Date(date.getFullYear(), date.getMonth() + 1, 0)
  );
  let timeframes: Customer[];
  if (!customer.timeframes) {
    timeframes = [customer];
  } else {
    timeframes = timeframeRange.map(x => customer.timeframes?.[x]);
  }
  const newDistributors = [];
  timeframes.forEach(x => {
    if (x?.isDistributor) {
      newDistributors.push(customerKey);
    }
    if (x?.isDirect) {
      newDistributors.push(customerKey);
    }
    const timeframeDistributors = x?.distributors ?? [];
    timeframeDistributors.forEach(dist => {
      newDistributors.push(dist);
    });
  });
  return Array.from(new Set(newDistributors));
}

// TODO: be more specific about distributor loop error
// This error is niche / uncommon enough that we don't
// need to worry about reporting it in great detail right now.
function checkDistributorCycle(customers: Record<string, Customer>): Boolean {
  function getOutgoingEdges(timeframe: Customer): string[] {
    const edges = [];
    const distributors = timeframe.distributors || [];
    for (const dist of distributors) {
      const distributor = customers[dist];
      if (!distributor) continue;
      const start = timeframe.effectiveDate
        ? new Date(timeframe.effectiveDate)
        : undefined;
      const end = timeframe.expirationDate
        ? new Date(timeframe.expirationDate)
        : undefined;
      const timeframeRange = getTimeframeRange(distributor, start, end);
      timeframeRange.forEach(r => {
        edges.push(`${dist}-${r}`);
      });
    }
    return edges;
  }

  const inDegrees = {};
  let numVisited = 0;
  let numTimeframes = 0;

  // need to connect "abc-0" with "def-2"
  for (const key in customers) {
    const customer = customers[key];
    const timeframes = customer.timeframes ?? [customer];

    for (let i = 0; i < timeframes.length; i++) {
      if (timeframes[i].isDistributor) continue;

      numTimeframes++;
      const tfKey = `${key}-${i}`;
      if (!(tfKey in inDegrees)) inDegrees[tfKey] = 0;

      const edges = getOutgoingEdges(timeframes[i]);
      edges.forEach(e => {
        if (!(e in inDegrees)) inDegrees[e] = 0;
        inDegrees[e] += 1;
      });
    }
  }

  const bfs = [];

  for (const key in inDegrees) {
    if (inDegrees[key] === 0) {
      bfs.push(key);
    }
  }

  while (bfs.length) {
    const modifiedKey = bfs[0];
    bfs.shift();

    const key = modifiedKey.substring(0, modifiedKey.lastIndexOf("-"));
    const tfIndex = parseInt(
      modifiedKey.substring(modifiedKey.lastIndexOf("-") + 1)
    );

    const customer = customers[key];
    const timeframes = customer?.timeframes ?? [customer];
    const timeframe = timeframes[tfIndex];

    if (timeframe.isDistributor) continue;

    numVisited++;

    const edges = getOutgoingEdges(timeframe);
    edges.forEach(e => {
      inDegrees[e] -= 1;
      if (inDegrees[e] == 0) {
        bfs.push(e);
      }
    });
  }

  return numVisited != numTimeframes;
}

// Returns [error_title, error_text] if any inter-customer conflicts
function checkConflicts(
  customers: Record<string, Customer>
): string[] | undefined {
  // check cycles
  if (checkDistributorCycle(customers)) {
    return ["Distributor Loop Error", loopError];
  }

  // check first receiver relationships
  for (const key in customers) {
    const customer = customers[key];
    const timeframes = customer.timeframes ?? [customer];

    for (let i = 0; i < timeframes.length; i++) {
      for (const dist of timeframes[i].distributors ?? []) {
        const distributor = customers[dist];
        if (!distributor) continue;
        const start = timeframes[i].effectiveDate
          ? new Date(timeframes[i].effectiveDate)
          : undefined;
        const end = timeframes[i].expirationDate
          ? new Date(timeframes[i].expirationDate)
          : undefined;
        const timeframeRange = getTimeframeRange(distributor, start, end);
        const distTimeframes = distributor.timeframes ?? [distributor];
        let error;
        timeframeRange.forEach(r => {
          if (!distTimeframes[r].isDirect && !distTimeframes[r].isDistributor) {
            error = [
              "Inconsistent Customer/First Receiver Error",
              inconsistentError(customer, distributor)
            ];
          }
        });
        if (error) return error;
      }
    }
  }
}

function aggregateTimeframes(
  customers: Record<string, Customer>
): Record<string, Customer> {
  const result = {};
  Object.keys(customers).forEach(key => {
    const timeframes = customers[key].timeframes ?? [customers[key]];
    timeframes.forEach((tf, index) => {
      result[`${key}-${index}`] = tf;
    });
  });

  return result;
}

function checkDuplicateName(
  customers: Record<string, Customer>,
  customerKey: string,
  nameType: string,
  proposedName: string
): [string, string] {
  // strips string of all spaces and makes lower case
  function reduce(name: string): string {
    const result = name.toLowerCase();
    return result.split(" ").join("");
  }

  // aggregate all timeframes first...
  // we don't care when duplicates happen
  const aggregatedCustomers = aggregateTimeframes(customers);

  // check name against all other names in the db
  for (const modifiedKey in aggregatedCustomers) {
    const key = modifiedKey.substring(0, modifiedKey.lastIndexOf("-"));
    const otherCustomer = customers[key].name;

    if (key !== customerKey) {
      if (reduce(proposedName) == reduce(otherCustomer)) {
        return ["customer", otherCustomer];
      }
    }

    if (!(key === customerKey && nameType == "customer")) {
      const customerToCheck = customers[key];
      if (key === customerKey) {
        // same customer alt names
        if (customerToCheck.otherNames) {
          for (let i = 0; i < customerToCheck.otherNames.length; i++) {
            const altName = customerToCheck.otherNames[i];
            if (proposedName == altName) {
              return ["alternative", otherCustomer];
            }
          }
        }
      }
      // different customer alt names
      else if (customerToCheck.otherNames) {
        for (let i = 0; i < customerToCheck.otherNames.length; i++) {
          const altName = customerToCheck.otherNames[i];
          if (reduce(proposedName) == reduce(altName)) {
            return ["alternative", otherCustomer];
          }
        }
      }
    }
  }
}

interface PricingResponse {
  pricing: Record<string, Record<string, Price>>;
  changed: Boolean;
}

function breakUpTimeframes(
  priceTimeframes: Price[],
  customer: Customer,
  expirationDates: Date[]
): Price[] {
  let newPriceTimeframes = Array.from(priceTimeframes);
  const customerTimeframes = customer.timeframes ?? [customer];

  customerTimeframes.forEach(tf => {
    if (tf.expirationDate) {
      expirationDates.push(new Date(tf.expirationDate));
    }
  });
  expirationDates = Array.from(new Set(expirationDates));
  expirationDates.sort();

  expirationDates.forEach(ed => {
    const i = getCurrentTimeframeIndex({ timeframes: newPriceTimeframes }, ed);
    if (
      compareDates(ed, new Date(newPriceTimeframes[i].expirationDate)) === 0
    ) {
      return;
    }

    const firstHalf = $.extend(true, {}, newPriceTimeframes[i]);
    const secondHalf = $.extend(true, {}, newPriceTimeframes[i]);

    firstHalf.expirationDate = ed.toDateString();
    secondHalf.effectiveDate = moment(ed)
      .add(1, "days")
      .toDate()
      .toDateString();

    newPriceTimeframes = [
      ...newPriceTimeframes.slice(0, i),
      firstHalf,
      secondHalf,
      ...newPriceTimeframes.slice(i + 1)
    ];
  });

  return newPriceTimeframes;
}

// precondition: priceTimeframes is already broken up at customer's timeframe breakpoints
function combineSimilarTimeframes(
  priceTimeframes: Price[],
  customer: Customer
) {
  let newPriceTimeframes = Array.from(priceTimeframes);
  let i = 0;
  while (i < newPriceTimeframes.length - 1) {
    const thisExpDate = new Date(newPriceTimeframes[i].expirationDate);
    const nextEffDate = new Date(newPriceTimeframes[i + 1].effectiveDate);

    const thisTimeframe = getCurrentTimeframe(customer, thisExpDate);
    const nextTimeframe = getCurrentTimeframe(customer, nextEffDate);

    const eitherDirect =
      thisTimeframe.isDirect ||
      thisTimeframe.isDistributor ||
      nextTimeframe.isDirect ||
      nextTimeframe.isDistributor;
    const diffDistributors = !_.isEqual(
      new Set(thisTimeframe.distributors),
      new Set(nextTimeframe.distributors)
    );

    if (eitherDirect || diffDistributors) {
      i++;
    } else {
      // combine indirect timeframes with same distributors
      const combinedTimeframe = $.extend(true, {}, newPriceTimeframes[i]);
      combinedTimeframe.expirationDate =
        newPriceTimeframes[i + 1].expirationDate;
      newPriceTimeframes = [
        ...newPriceTimeframes.slice(0, i),
        combinedTimeframe,
        ...newPriceTimeframes.slice(i + 2)
      ];
    }
  }
  return newPriceTimeframes;
}

function getExpirationDates(
  priceTimeframes: Price[],
  customer: Customer,
  pg: string,
  pricingData: Record<string, Record<string, Price>>
) {
  const expirationDates = [];
  priceTimeframes.forEach(tf => {
    let customerTf = getCurrentTimeframe(
      customer,
      new Date(tf.expirationDate ?? tf.effectiveDate)
    );
    if (!tf.expirationDate && !tf.effectiveDate) customerTf = customer;

    if (customerTf.isDirect || customerTf.isDistributor) return;
    const distributors = customerTf.distributors ?? [];
    distributors.forEach(d => {
      const distPricingTfRange = getTimeframeRange(
        pricingData[d]?.[pg],
        new Date(tf.effectiveDate),
        new Date(tf.expirationDate)
      );
      for (let i = 0; i < distPricingTfRange.length; i++) {
        const index = distPricingTfRange[i];
        const expirationDate =
          pricingData[d]?.[pg]?.timeframes?.[index]?.expirationDate;
        if (expirationDate) {
          expirationDates.push(new Date(expirationDate));
        }
      }
    });
  });
  return expirationDates;
}

function calculateRetailerPricing(
  pricingData: Record<string, Record<string, Price>>,
  allCustomers: Record<string, Customer>,
  pgKeys: string[]
): PricingResponse {
  const updatedPricing = $.extend(true, {}, pricingData);
  for (const customerKey in allCustomers) {
    const customer = allCustomers[customerKey];
    const priceMap = updatedPricing[customerKey] ?? {};

    pgKeys.forEach(pg => {
      const price = priceMap?.[pg] ?? {};

      if (price.timeframes) {
        const timeframes = price.timeframes ?? [price];
        const brokenTimeframes = breakUpTimeframes(timeframes, customer, []);
        const combinedTimeframes = combineSimilarTimeframes(
          brokenTimeframes,
          customer
        );
        const expirationDates = getExpirationDates(
          combinedTimeframes,
          customer,
          pg,
          pricingData
        );
        const revisedTimeframes = breakUpTimeframes(
          combinedTimeframes,
          customer,
          expirationDates
        );

        price.timeframes = revisedTimeframes;
      } else {
        const timeframes = [price];
        const expirationDates = getExpirationDates(
          timeframes,
          customer,
          pg,
          pricingData
        );
        if (expirationDates.length) {
          price.timeframes = breakUpTimeframes(
            timeframes,
            customer,
            expirationDates
          );
        }
      }

      const timeframes = price.timeframes ?? [price];

      timeframes.forEach(tf => {
        let customerTf = getCurrentTimeframe(
          customer,
          new Date(tf.expirationDate ?? tf.effectiveDate)
        );
        if (!tf.expirationDate && !tf.effectiveDate) customerTf = customer;

        if (customerTf.isDirect || customerTf.isDistributor) return;
        const distributors = customerTf.distributors ?? [];
        let split = customerTf.spendPerDistributor;
        const pgSplit = customer.spendPerDistributorPG;

        if (pgSplit?.[pg]) {
          split = pgSplit[pg];
        } else if (!split) {
          split = {};
          distributors.forEach(d => {
            split[d] = (100 / distributors.length).toString();
          });
        }

        let numerator = 0;
        let denominator = 0;
        Object.keys(split).forEach(d => {
          const multiplier = parseFloat(split[d]);
          const distPrice = pricingData[d]?.[pg];
          let distPriceTf = getCurrentTimeframe(
            distPrice,
            new Date(tf.expirationDate ?? tf.effectiveDate)
          );
          if (!tf.expirationDate && !tf.effectiveDate) distPriceTf = distPrice;

          if (distPriceTf?.price) {
            numerator += multiplier * distPriceTf.price;
            denominator += multiplier;
          }
        });

        if (numerator && denominator) {
          tf.price = numerator / denominator;
        } else {
          // The price probably got deleted, or some other error happened. Set to blank.
          delete tf.price;
        }

        if (!tf.effectiveDate) delete tf.effectiveDate;
        if (!tf.expirationDate) delete tf.expirationDate;
      });
      priceMap[pg] = { timeframes };
      const curPrice = getCurrentTimeframe(priceMap[pg], new Date())?.price;
      if (curPrice) priceMap[pg].price = curPrice;
    });
    updatedPricing[customerKey] = priceMap;
  }

  return {
    pricing: updatedPricing,
    changed: !_.isEqual(pricingData, updatedPricing)
  };
}

function checkProposedCustomerSplits(
  customer: Customer,
  splits: string[],
  subsplits: string[],
  splitField: string,
  subsplitField: string
): string {
  let error = null;

  const splitDict = customer[splitField];
  const subsplitDict = customer[subsplitField] ?? {};

  for (const sub in subsplitDict) {
    let totalPercentage = 0;
    for (const spl in subsplitDict[sub]) {
      const parsedSplit = parseInt(subsplitDict[sub][spl]);
      if (Number.isNaN(parsedSplit) || parsedSplit < 0 || parsedSplit > 100) {
        error = sub;
      }
      if (splits.includes(spl) && subsplits.includes(sub))
        totalPercentage += parsedSplit;
    }
    if (totalPercentage != 100) error = sub;
  }

  if (splitDict) {
    let totalPercentage = 0;
    for (const spl in splitDict) {
      const parsedSplit = parseInt(splitDict[spl]);
      if (Number.isNaN(parsedSplit) || parsedSplit < 0 || parsedSplit > 100) {
        error = "all";
      }
      if (splits.includes(spl)) totalPercentage += parsedSplit;
    }
    if (totalPercentage != 100) error = "all";
  }

  return error;
}

export {
  timeframeType,
  getCurrentTimeframe,
  getCurrentTimeframeIndex,
  getTimeframeRange,
  getVisibleDistributors,
  checkDistributorCycle,
  checkConflicts,
  checkDuplicateName,
  breakUpTimeframes,
  combineSimilarTimeframes,
  getExpirationDates,
  calculateRetailerPricing,
  checkProposedCustomerSplits
};
