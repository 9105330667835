import React from "react";
import axios from "axios";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  Typography,
  TextField
} from "@mui/material";
import {
  GoogleLoginButton,
  MicrosoftLoginButton
} from "react-social-login-buttons";

import {
  firebase,
  cloudRunFunctionURL,
  isUserCresicorEmployee
} from "helpers/Firebase";
import {
  sendSMSVerificationCode,
  verifySMSLogin
} from "helpers/SmsVerification";

const styles = {
  logo: {
    height: "40px",
    margin: "32px 0 32px"
  }
};

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      signInType: "",
      twoFactorAuth: false,
      selectedInterface: "login",
      email: "",
      password: "",
      phoneNumber: "",
      verificationId: "",
      verificationCode: "",
      errorResolver: null
    };
  }

  resolveSignInErrors = error => {
    const errorCode = error.code;
    if (errorCode === "auth/multi-factor-auth-required") {
      this.handle2FALogin(error.resolver);
      this.setState({ twoFactorAuth: true });
    } else if (errorCode === "auth/wrong-password") {
      this.props.openClose.showSnack("Incorrect username and/or password.");
    } else if (errorCode === "auth/account-exists-with-different-credential") {
      this.props.openClose.showSnack(
        "Email address is used by another authentication method."
      );
    } else {
      console.error("Error message", errorCode);
      this.props.openClose.showSnack("Error with login.");
    }
  };

  handleRedirection = userCredential => {
    const url = window.location.href;
    if (url.indexOf("redirectToReadMe=True") !== -1) {
      this.redirectToReadMe(userCredential);
    } else {
      this.handleLoginSuccess(userCredential);
    }
  };

  // TO REDIRECT TO CRESICOR UNIVERSITY
  redirectToReadMe = userCredential => {
    const user = {
      name: userCredential.additionalUserInfo.profile.name,
      email: userCredential.additionalUserInfo.profile.email
    };
    axios
      .post(`${cloudRunFunctionURL}/api/get_readme_jwt`, { data: user })
      .then(result => {
        const readMeUrl = result.data.data;
        window.location.replace(readMeUrl);
      });
  };

  signInGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: "select_account"
    });
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(userCredential => {
        this.handleRedirection(userCredential);
      })
      .catch(error => {
        this.resolveSignInErrors(error);
      });
  };

  signInMicrosoft = () => {
    const provider = new firebase.auth.OAuthProvider("microsoft.com");
    provider.setCustomParameters({
      prompt: "select_account"
    });
    provider.addScope("openid");
    provider.addScope("email");
    provider.addScope("mail.read");
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(userCredential => {
        this.handleRedirection(userCredential);
      })
      .catch(error => {
        this.resolveSignInErrors(error);
      });
  };

  signInNormal = () => {
    if (this.state.email && this.state.password) {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.state.email, this.state.password)
        .then(userCredential => {
          this.handleRedirection(userCredential);
        })
        .catch(error => {
          this.resolveSignInErrors(error);
        });
    } else {
      this.props.openClose.showSnack("Invalid login credentials.");
    }
  };

  handleLoginSuccess = userCredential => {
    const { user, credential } = userCredential;
    const { email } = user;
    const urlpath = window.location.href;

    if (isUserCresicorEmployee() && urlpath.indexOf("employee=") === -1) {
      const newUrl = `${urlpath}?employee=true`;
      window.location.replace(newUrl);
    } else if (
      !isUserCresicorEmployee() &&
      urlpath.indexOf("employee=") === -1
    ) {
      const newUrl = `${urlpath}?employee=false`;
      window.location.replace(newUrl);
    }
    // handle new users
    const continueLogin = this.handleNewUser(userCredential);

    // handle login - backend login logistics + forecast login
    // NB(daniel): Only do this if APP_ENV_PREFIX doesn't exist, i.e. not in PR preview link.
    // Our customers don't log into PR preview links, and we run the risk of cloud function
    // not existing when user logs in.
    if (!APP_ENV_PREFIX) {
      this.handleLoginLogistics(email);
    }
    if (continueLogin) {
      const tokenRequired =
        this.state.signInType === "password" || this.state.twoFactorAuth;
      if (tokenRequired) {
        this.forecastLoginToken(user);
      } else {
        this.forecastLoginCredential(credential);
      }

      // redirect, end loading screen
      this.props.completeLogin();
    }
  };

  handleNewUser = userCredential => {
    const { signInType } = this.state;
    const { user } = userCredential;

    // Password New User
    if (signInType == "password") {
      if (!user.emailVerified) {
        axios.post(`${cloudRunFunctionURL}/api/resend_email_verification`, {
          data: {
            user_email: user.email,
            base_url: window.location.origin
          }
        });
        this.props.openClose.showSnack(
          "Account not yet verified. A new verification link has been sent to your email."
        );
        firebase.auth().signOut();
        return false;
      }
    }

    // Microsoft new user
    else if (signInType == "microsoft") {
      // Microsoft authentication does not automatically get email
      const { isNewUser } = userCredential.additionalUserInfo;
      if (isNewUser) {
        user.updateEmail(user.providerData[0].email);
      }
      return true;
    }

    return true;
  };

  handleLoginLogistics = email => {
    axios.post(`${cloudRunFunctionURL}/api/handle_login_logistics`, {
      data: {
        email
      }
    });
  };

  forecastLoginCredential = credential => {
    const firebaseForecast = firebase.app("forecast");
    firebaseForecast.auth().signInWithCredential(credential);
  };

  forecastLoginToken = user => {
    /* use cases:
    - username/password login does not support credentials
    - multi-factor-auth requires another 2 step verification for forecast (after first login)
     */
    const firebaseForecast = firebase.app("forecast");
    const formData = { uid: user.uid };
    axios
      .post(`${cloudRunFunctionURL}/api/create_custom_token`, {
        data: formData
      })
      .then(response => {
        const customToken = response.data.data;
        firebaseForecast.auth().signInWithCustomToken(customToken);
      });
  };

  handle2FALogin = resolver => {
    const selectedIndex = 0; // for now, only 1 mode of 2FA - phone
    // Check mode of 2FA - for now only phone
    if (
      resolver.hints[selectedIndex].factorId ===
      firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID
    ) {
      const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
        { size: "invisible" }
      );
      const multiFactorHint = resolver.hints[selectedIndex];

      // Send SMS verification
      sendSMSVerificationCode(
        recaptchaVerifier,
        multiFactorHint,
        resolver
      ).then(verificationId => {
        const { phoneNumber } = resolver.hints[selectedIndex];
        this.changeInterface("twoFactorAuth");
        this.setState({
          errorResolver: resolver,
          verificationId,
          phoneNumber
        });
      });
    }
  };

  changeInterface = selectedInterface => {
    this.setState({
      selectedInterface,
      email: "",
      password: ""
    });
  };

  verifySMSCode = () => {
    const resolver = this.state.errorResolver;
    const { verificationId } = this.state;
    const { verificationCode } = this.state;
    // Complete multi-factor sign-in
    return verifySMSLogin(resolver, verificationId, verificationCode)
      .then(userCredential => {
        this.handleLoginSuccess(userCredential);
      })
      .catch(error => {
        if (error.code == "auth/invalid-verification-code") {
          this.props.openClose.showSnack("Invalid verification code");
        } else {
          console.error(error);
          this.props.openClose.showSnack("Unknown error");
        }
      });
  };

  resetPassword = () => {
    const emailAddress = this.state.email;
    if (emailAddress) {
      const sendResetPasswordFunc = axios
        .post(`${cloudRunFunctionURL}/api/send_password_reset_email`, {
          data: {
            reset_mode: "reset",
            user_email: emailAddress,
            base_url: window.location.origin
          }
        })
        .then(response => {
          this.props.openClose.showSnack("Reset link sent to your email");
        })
        .catch(error => {
          console.error(error);
          this.props.openClose.showSnack("Invalid email address provided");
        });
    } else {
      this.props.openClose.showSnack("Please enter your email address");
    }
  };

  loginPageJSX = () => {
    return (
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh"
        }}>
        <Container maxWidth="xs" sx={{ py: 8 }}>
          <div className="centering">
            <img
              src="/vividly_logo.svg"
              alt="company logo"
              style={styles.logo}
            />
          </div>
          <Card>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: 4
              }}>
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 1
                }}>
                <div>
                  <Typography gutterBottom variant="h4">
                    Log In
                  </Typography>
                </div>
              </Box>
              <Box
                sx={{
                  marginBottom: 1
                }}>
                {this.props.unvalidated && (
                  <Typography sx={{ textAlign: "center", color: "error.main" }}>
                    Error with login. Please make sure the selected account or
                    username/password is correct.
                  </Typography>
                )}
              </Box>
              <TextField
                autoFocus
                fullWidth
                label="Email Address"
                margin="normal"
                name="email"
                type="email"
                variant="outlined"
                value={this.state.email}
                onChange={event => this.setState({ email: event.target.value })}
              />
              <TextField
                fullWidth
                label="Password"
                margin="normal"
                name="password"
                type="password"
                variant="outlined"
                value={this.state.password}
                onChange={event =>
                  this.setState({ password: event.target.value })
                }
              />
              <Link
                color="textSecondary"
                style={{
                  marginTop: 8,
                  marginLeft: 8,
                  fontSize: 12,
                  cursor: "pointer"
                }}
                variant="body2"
                onClick={this.changeInterface.bind(null, "passwordReset")}
                underline="hover">
                Forgot password
              </Link>
              <div id="recaptcha-container" />
              <Box style={{ marginTop: 16 }}>
                <Button
                  color="primary"
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    this.setState({ signInType: "password" }, () => {
                      this.signInNormal();
                    });
                  }}
                  sx={{
                    py: "10px",
                    fontFamily: "Arial",
                    fontSize: 16,
                    width: "calc(100% - 10px)"
                  }}>
                  Sign In
                </Button>
              </Box>
              <Divider style={{ marginTop: 24, marginBottom: 24 }} />
              <GoogleLoginButton
                onClick={() => {
                  this.setState({ signInType: "google" }, () => {
                    this.signInGoogle();
                  });
                }}
                style={{
                  borderRadius: 100,
                  marginBottom: 16,
                  height: 40,
                  fontSize: 16,
                  padding: "0px 20px"
                }}>
                <div
                  style={{
                    textAlign: "center",
                    fontWeight: 400,
                    fontFamily: "Arial"
                  }}>
                  Sign In With Google
                </div>
              </GoogleLoginButton>
              <MicrosoftLoginButton
                onClick={() => {
                  this.setState({ signInType: "microsoft" }, () => {
                    this.signInMicrosoft();
                  });
                }}
                style={{
                  borderRadius: 100,
                  height: 40,
                  fontSize: 16,
                  padding: "0px 20px"
                }}>
                <div
                  style={{
                    textAlign: "center",
                    fontWeight: 400,
                    fontFamily: "Arial"
                  }}>
                  Sign In With Microsoft
                </div>
              </MicrosoftLoginButton>
            </CardContent>
          </Card>
        </Container>
      </Box>
    );
  };

  twoFactorAuthJSX = () => {
    return (
      <Box
        style={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh"
        }}>
        <Container maxWidth="sm" sx={{ py: 8 }}>
          <div className="centering">
            <img
              src="vividly_logo.svg"
              alt="company logo"
              style={styles.logo}
            />
          </div>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 64
            }}
          />
          <Card>
            <CardContent
              style={{
                display: "flex",
                flexDirection: "column",
                padding: 32
              }}>
              <Box
                style={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 24
                }}>
                <div>
                  <Typography color="textPrimary" gutterBottom variant="h4">
                    Two Factor Authentication
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    Enter verification code sent to {this.state.phoneNumber}.
                  </Typography>
                </div>
              </Box>
              <Box
                style={{
                  flexGrow: 8
                }}>
                <TextField
                  autoFocus
                  fullWidth
                  label="Verification Code"
                  margin="normal"
                  name="code"
                  variant="outlined"
                  value={this.state.verificationCode}
                  onKeyPress={e => {
                    if (e.key === "Enter") {
                      this.verifySMSCode();
                    }
                  }}
                  onChange={event =>
                    this.setState({
                      verificationCode: String(event.target.value)
                    })
                  }
                />
                <Box style={{ marginTop: 16, marginBottom: 16 }}>
                  <Button
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={this.verifySMSCode}
                    style={{
                      textTransform: "none",
                      fontWeight: 400,
                      fontFamily: "Arial",
                      fontSize: 16
                    }}>
                    Verify
                  </Button>
                </Box>
                <Link
                  color="textSecondary"
                  style={{ marginLeft: 8, fontSize: 12, cursor: "pointer" }}
                  variant="body2"
                  onClick={this.changeInterface.bind(null, "login")}
                  underline="hover">
                  Back To Login
                </Link>
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    );
  };

  passwordResetJSX = () => {
    return (
      <Box
        style={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh"
        }}>
        <Container maxWidth="sm" sx={{ py: 8 }}>
          <div className="centering">
            <img
              src="vividly_logo.svg"
              alt="company logo"
              style={styles.logo}
            />
          </div>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 64
            }}
          />
          <Card>
            <CardContent
              style={{
                display: "flex",
                flexDirection: "column",
                padding: 32
              }}>
              <Box
                style={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 24
                }}>
                <div>
                  <Typography color="textPrimary" gutterBottom variant="h4">
                    Password Recovery
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    Enter your email so we can send you a reset link
                  </Typography>
                </div>
              </Box>
              <Box
                style={{
                  flexGrow: 8
                }}>
                <TextField
                  autoFocus
                  fullWidth
                  label="Email Address"
                  margin="normal"
                  name="email"
                  type="email"
                  variant="outlined"
                  value={this.state.email}
                  onChange={event =>
                    this.setState({ email: event.target.value })
                  }
                />
                <Box style={{ marginTop: 16, marginBottom: 16 }}>
                  <Button
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={this.resetPassword}
                    style={{
                      textTransform: "none",
                      fontWeight: 400,
                      fontFamily: "Arial",
                      fontSize: 16
                    }}>
                    Reset Password
                  </Button>
                </Box>
                <Link
                  color="textSecondary"
                  style={{ marginLeft: 8, fontSize: 12, cursor: "pointer" }}
                  variant="body2"
                  onClick={this.changeInterface.bind(null, "login")}
                  underline="hover">
                  Back To Login
                </Link>
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    );
  };

  componentDidMount() {
    this.props.startLogin();
  }

  render() {
    return (
      <div>
        {/* <StyledEngineProvider injectFirst>
          <MuiThemeProvider theme={loginTheme}> */}
        {this.state.selectedInterface == "login"
          ? this.loginPageJSX()
          : this.state.selectedInterface == "twoFactorAuth"
          ? this.twoFactorAuthJSX()
          : this.passwordResetJSX()}
        {/* </MuiThemeProvider>
        </StyledEngineProvider> */}
      </div>
    );
  }
}

export default Login;
