import React from "react";

import Button from "ui-library/Button";

import { grey, common, red } from "@mui/material/colors";
import { Stack } from "@mui/material";

const grey300 = grey["300"];
const grey700 = grey["700"];
const { white } = common;
const { black } = common;
const red400 = red["400"];
const red600 = red["600"];

// openClose: REQUIRED
// everything else: OPTIONAL
function confirmDialog(
  openClose,
  modalTitle,
  confirmMessage,
  yesCallback,
  noCallback,
  yesButtonColor,
  noButtonColor,
  yesText,
  noText
) {
  openClose.setAppModal({
    title: modalTitle || "Are you sure?",
    content: (
      <div className="centering">
        {confirmMessage || "Are you sure you wish to perform this operation?"}
      </div>
    ),

    actions: (
      <Stack>
        <Button
          label={noText || "No, go back"}
          variant="text"
          color="error"
          onClick={() => {
            openClose.closeAppModal();
            if (noCallback) {
              noCallback();
            }
          }}
        />
        <Button
          label={yesText || "Yes, I'm sure"}
          variant="text"
          onClick={() => {
            openClose.closeAppModal();
            if (yesCallback) {
              yesCallback();
            }
          }}
        />
      </Stack>
    )
  });
}

function infoDialog(
  openClose,
  modalTitle,
  message,
  buttonText,
  buttonColor,
  callback
) {
  openClose.setAppModal({
    title: modalTitle || "Information",
    content: (
      <div className="centering">{message || "Something happened."}</div>
    ),

    actions: (
      <Stack>
        <Button
          label={buttonText || "Go back"}
          variant="text"
          onClick={() => {
            openClose.closeAppModal();
            if (callback) {
              callback();
            }
          }}
        />
      </Stack>
    )
  });
}

export { confirmDialog, infoDialog };
