import React from "react";
import axios from "axios";
import validator from "validator";
import Button from "ui-library/Button";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import SendIcon from "@mui/icons-material/Send";
import TextField from "ui-library/TextField";

import DataTable from "components/tables/MuiDataTable";
import {
  removeCompanyFromUser,
  firebase,
  getCompanyUsers,
  cloudRunFunctionURL,
  COMPANY_EMAIL_DOMAINS
} from "helpers/Firebase";
import { httpsCallable } from "helpers/httpsCallable";

import { Stack } from "@mui/material";

class ManageUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyUsers: {},
      newUserName: "",
      newUserEmail: ""
    };
  }

  handleRemoval = (email, companyId, force = false) => {
    let doneCallback;
    doneCallback = deletedField => {
      if (!force) {
        this.props.openClose.showSnack(`${deletedField} deleted`);
        this.props.openClose.closeAppModal();
      }
    };

    removeCompanyFromUser(
      email,
      companyId,
      doneCallback,
      (deletedField, executeDelete) => {
        if (force) {
          executeDelete();
        } else {
          this.props.openClose.setAppModal(
            `Remove ${deletedField}`,
            <div>
              <br />
              <div className="centering">
                {`Are you sure you want to remove this ${deletedField.toLowerCase()}?`}
              </div>
              <div className="centering">
                (This change only takes effect after you refresh the system.)
              </div>
            </div>,
            <Stack>
              <Button
                label="Yes, I'm sure"
                color="error"
                variant="text"
                onClick={() => {
                  executeDelete();
                }}
              />
              <Button
                label="No, go back"
                variant="text"
                onClick={this.props.openClose.closeAppModal}
              />
            </Stack>
          );
        }
      }
    );
  };

  handleResend = email => {
    const sendResetPasswordFunc = httpsCallable("send_password_reset_email", {
      timeout: 360000
    });
    sendResetPasswordFunc({
      reset_mode: "reset",
      user_email: email,
      base_url: window.location.origin
    })
      .then(response => {
        this.props.openClose.showSnack("Reset password email sent");
      })
      .catch(error => {
        console.error(error);
        this.props.openClose.showSnack("Error sending reset password email.");
      });
  };

  getUsersJSX = () => {
    const usersJSX = {
      User: [],
      Email: [],
      Remove: [],
      "Reset Password": []
    };
    for (const [uid, val] of Object.entries(this.state.companyUsers)) {
      const { name } = val;
      const { email } = val;
      usersJSX.User.push(name);
      usersJSX.Email.push(email);
      usersJSX.Remove.push(
        <Button
          label="Remove"
          disabled={COMPANY_EMAIL_DOMAINS.some(domain =>
            email.endsWith(domain)
          )}
          color="error"
          icon={<DeleteIcon />}
          onClick={this.handleRemoval.bind(
            null,
            email,
            this.props.db.companyid
          )}
        />
      );
      usersJSX["Reset Password"].push(
        <Button
          icon={<SendIcon />}
          onClick={this.handleResend.bind(null, email, this.props.db.companyid)}
        />
      );
    }
    return usersJSX;
  };

  handleNewUserAdd = () => {
    if (this.state.newUserName === "") {
      this.setState({ newUserNameErrorText: "Name cannot be blank" });
      return;
    }
    if (!validator.isEmail(this.state.newUserEmail)) {
      this.setState({ newUserEmailErrorText: "Invalid Email" });
      return;
    }
    this.props.openClose.setAppModal(
      "Add User",

      <div>
        Are you sure you want to add this user? Note that it may take 1-2
        minutes for the user to be created.
      </div>,
      <Stack>
        <Button
          label="Yes, I'm sure"
          variant="text"
          onClick={() => {
            const { newUserEmail, newUserName } = this.state;

            this.props.openClose.showSnack(
              `Attempting to invite ${newUserEmail}. Please wait...`
            );

            const user = firebase.auth().currentUser;
            if (!user) {
              return;
            }

            const formData = {
              user_email: newUserEmail,
              current_user_email: user.email
            };

            axios
              .post(`${cloudRunFunctionURL}/api/allow_new_user_invite`, {
                data: formData
              })
              .then(response => {
                if (response.data.data.success) {
                  const formData = {
                    access: "Admin",
                    company_id: this.props.db.companyid,
                    user_email: newUserEmail,
                    user_name: newUserName
                  };

                  axios
                    .post(
                      `${cloudRunFunctionURL}/api/add_user_to_authentication`,
                      { data: formData }
                    )
                    .then(response => {
                      if (response.data.data.success) {
                        this.props.openClose.showSnack(
                          "User added successfully!"
                        );
                        axios
                          .post(
                            `${cloudRunFunctionURL}/api/send_password_reset_email`,
                            {
                              data: {
                                reset_mode: "set",
                                user_email: newUserEmail,
                                base_url: window.location.origin
                              }
                            }
                          )
                          .then(response => {
                            this.props.openClose.showSnack(
                              "Sign-Up email sent to new user."
                            );
                          })
                          .catch(error => {
                            console.error(error);
                            this.props.openClose.showSnack(
                              "Error sending sign-up email to new user."
                            );
                          });
                      } else if (
                        response.data.reason == "user_exists" ||
                        response.data.reason == "user_exists_auth"
                      ) {
                        this.props.openClose.showSnack(
                          "User already exists. Please contact support to resolve this issue."
                        );
                      } else {
                        console.log(response);
                        this.props.openClose.showSnack(
                          "Invalid domain. Only emails from your organization may be invited."
                        );
                      }
                    })
                    .catch(error => {
                      console.error(error);
                      this.props.openClose.showSnack(
                        "Error adding user. Please contact support if the issue persists."
                      );
                    });
                } else {
                  this.props.openClose.showSnack(
                    "Invalid domain. Only emails from your organization may be invited."
                  );
                }
              })
              .catch(error => {
                console.error(error);
                this.props.openClose.showSnack(
                  "Error adding user. Please contact support if the issue persists."
                );
              });

            this.setState({
              newUserName: "",
              newUserEmail: ""
            });

            this.props.openClose.closeAppModal();
            this.props.openClose.closeAppModal();
          }}
        />
        <Button
          label="No, go back"
          variant="text"
          color="error"
          onClick={() => {
            this.props.openClose.closeAppModal();
          }}
        />
      </Stack>
    );
  };

  componentDidMount() {
    getCompanyUsers(companyUsers => {
      this.setState({ companyUsers });
    });
  }

  render() {
    const usersJSX = this.getUsersJSX();
    return (
      <div>
        <div>
          <DataTable
            title="Add New User to Company"
            data={{
              Name: [
                <TextField
                  id="newUserName"
                  value={this.state.newUserName}
                  onChange={event => {
                    this.setState({
                      newUserName: event.target.value
                    });
                  }}
                  style={{ marginRight: "16px", marginTop: 0 }}
                  errorText={this.state.newUserNameErrorText}
                />
              ],
              Email: [
                <TextField
                  id="newUserEmail"
                  value={this.state.newUserEmail}
                  onChange={event => {
                    this.setState({
                      newUserEmail: event.target.value.toLowerCase()
                    });
                  }}
                  style={{ marginRight: "16px", marginTop: 0 }}
                  errorText={this.state.newUserEmailErrorText}
                />
              ],
              Add: [
                <Button
                  label="Add User"
                  icon={<AddIcon />}
                  onClick={this.handleNewUserAdd}
                />
              ]
            }}
          />
        </div>
        <br />
        <div>
          <DataTable
            title="Manage Users in Company"
            data={usersJSX}
            widths={["35%", "35%", "20%", "10%"]}
          />
        </div>
      </div>
    );
  }
}

export default ManageUsers;
