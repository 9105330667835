import {
  Button,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Theme,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  InvoiceLineAmountService,
  RepaymentLineProcessingService,
  TransactionAmountService,
  TransactionDisplayService
} from "reconciliation-services";
import {
  ERPTransactionObject,
  InvoiceObject,
  RepaymentLineObject
} from "components/Deductions/models";
import { useDb } from "contexts/Db";
import useWindowSize from "helpers/useWindowSize";
import React, { useCallback, useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import {
  ERPTransactionTypes,
  repaymentLineTypes
} from "components/Deductions/constants/ReconciliationTypes";
import { displayUSCurrency } from "helpers/DataProcessing";
import { TransactionDisplaySigns } from "components/Deductions/constants/ReconciliationDisplay";
import { round } from "lodash";
import { AddEventConfig } from "components/Deductions/DeductionsReconciliation/ActivityLog/DRMEventService";
import { useDRMEvents } from "contexts/DRMEvents";
import { DRMEventType } from "components/Deductions/DeductionsReconciliation/ActivityLog/DRMEvent";
import { useOpenClose } from "contexts/OpenClose";
import {
  ApplyRepaymentErrorMap,
  getAvailableDeductions,
  getAvailableInvoices,
  getAvailableRepayments
} from "./redux/applyRepaymentHelpers";
import {
  deselectAllInvoiceLines,
  selectAllInvoiceLines,
  setActivityType,
  setDeductionObject,
  setRepayableInvoiceLines,
  setInvoiceObject,
  setRepaymentObject,
  checkAmountErrors,
  setAvailableRepaymentAmount,
  setRepayableInvoiceLineAmounts,
  setShowErrors,
  reset
} from "./redux/applyRepaymentSlice";
import TransactionCard from "../TransactionCard";
import RepayableInvoiceLinesTable from "./RepayableInvoiceLinesTable";

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    padding: theme.spacing(2)
  },
  paddedContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    height: 80
  },
  rowContainer: {
    alignItems: "center"
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: "90%",
    maxWidth: "90%"
  },
  titleContainer: {
    paddingBottom: theme.spacing(1)
  },
  paddedTableContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    height: 480,
    overflow: "auto"
  },
  rowGroup: {
    display: "flex",
    justifyContent: "space-between"
  },
  error: {
    color: theme.palette.error.main
  },
  button: {
    marginLeft: theme.spacing(1)
  }
}));

export enum ApplyRepaymentEntryPoint {
  REPAYMENT = "Credit Write-Off(s)",
  DEDUCTION = "Repay Deduction"
}

export interface ApplyRepaymentProps {
  initialRepaymentKey?: string;
  initialDeductionKey?: string;
  featureEntryPoint: ApplyRepaymentEntryPoint;
}

export default function ApplyRepayment(props: ApplyRepaymentProps) {
  const { initialDeductionKey, initialRepaymentKey, featureEntryPoint } = props;

  const openClose = useOpenClose();
  const { showSnack } = openClose;
  const dispatch = useDispatch();
  const classes = useStyles();
  const { addEvent } = useDRMEvents();
  const { width } = useWindowSize();

  const db = useDb();
  const {
    erpTransactions = {},
    invoices = {},
    customers = {},
    companyUsers = {}
  } = db;

  const {
    repaymentLines,
    currentObjects,
    repayableInvoiceLines,
    repayableInvoiceLineAmounts,
    activityType,
    availableRepaymentAmount,
    errors,
    showErrors,
    lineErrors
  } = useSelector((state: RootState) => state.applyRepayment, shallowEqual);

  // Calculate the initial transactions and update the store accordingly
  const initialRepayment: ERPTransactionObject | undefined = useMemo(() => {
    return erpTransactions[initialRepaymentKey || ""];
  }, [initialRepaymentKey, erpTransactions]);

  const initialDeduction: ERPTransactionObject | undefined = useMemo(() => {
    return erpTransactions[initialDeductionKey || ""];
  }, [initialDeductionKey, erpTransactions]);

  useEffect(() => {
    dispatch(setRepaymentObject(initialRepayment));
    dispatch(setDeductionObject(initialDeduction));
  }, [initialRepayment, initialDeduction, dispatch]);

  // Setting activity type based on the feature entry point
  useEffect(() => {
    dispatch(
      setActivityType(
        featureEntryPoint === ApplyRepaymentEntryPoint.DEDUCTION
          ? repaymentLineTypes.REPAYMENT
          : repaymentLineTypes.CREDIT_WRITE_OFF
      )
    );
  }, [featureEntryPoint, dispatch]);

  // For populating the dropdown options
  const availableRepayments = useMemo(() => {
    return getAvailableRepayments(initialRepaymentKey, currentObjects, db);
  }, [initialRepaymentKey, currentObjects, db]);

  const availableDeductions = useMemo(() => {
    return getAvailableDeductions(initialDeductionKey, currentObjects, db);
  }, [initialDeductionKey, currentObjects, db]);

  const availableInvoices = useMemo(() => {
    return getAvailableInvoices(currentObjects, db);
  }, [currentObjects, db]);

  // Calculate the currently selected objects
  const {
    invoice: currentInvoice,
    deduction: currentDeduction,
    repayment: currentRepayment
  } = currentObjects;

  // Callback for updating the list of repayable invoice lines
  const updateRepayableInvoiceLines = useCallback(
    (selectedInvoice: InvoiceObject | undefined) => {
      if (!selectedInvoice) {
        dispatch(setRepayableInvoiceLines([]));
        return;
      }

      const { invoiceLines = {} } = selectedInvoice || {};
      const filteredInvoiceLines = Object.entries(invoiceLines)
        .filter(entry => {
          const [_invoiceLineKey, invoiceLine] = entry;
          return (
            (activityType === repaymentLineTypes.REPAYMENT &&
              InvoiceLineAmountService.getInvoiceLineOpenDisputeAmount(
                invoiceLine,
                selectedInvoice,
                invoices,
                erpTransactions
              ) > 0.0) ||
            (activityType === repaymentLineTypes.CREDIT_WRITE_OFF &&
              InvoiceLineAmountService.getInvoiceLineOpenWriteOffAmount(
                invoiceLine,
                selectedInvoice,
                invoices,
                erpTransactions
              ) > 0.0)
          );
        })
        .map(entry => entry[0]);

      dispatch(setRepayableInvoiceLines(filteredInvoiceLines));
    },
    [dispatch, activityType, invoices, erpTransactions]
  );

  // Updates the maximum total amount that can repaid in one session
  const updateAvailableRepaymentAmount = useCallback(
    (selectedRepayment: ERPTransactionObject | undefined) => {
      const amount = selectedRepayment
        ? TransactionAmountService.getTransactionOpenAmount(
            selectedRepayment,
            invoices,
            erpTransactions
          )
        : 0.0;

      dispatch(setAvailableRepaymentAmount(amount));
    },
    [invoices, erpTransactions, dispatch]
  );

  // Updates the line-by-line maximum that each invoice line can be repaid
  const updatedInvoiceLineRepayableAmounts = useCallback(
    (selectedInvoice: InvoiceObject | undefined) => {
      const amounts = {};
      if (selectedInvoice) {
        const { invoiceLines = {} } = selectedInvoice || {};
        Object.entries(invoiceLines).forEach(invoiceLineEntry => {
          const [invoiceLineKey, invoiceLine] = invoiceLineEntry;
          const openDisputeAmount =
            InvoiceLineAmountService.getInvoiceLineOpenDisputeAmount(
              invoiceLine,
              selectedInvoice,
              invoices,
              erpTransactions
            );

          const openWriteOffAmount =
            InvoiceLineAmountService.getInvoiceLineOpenWriteOffAmount(
              invoiceLine,
              selectedInvoice,
              invoices,
              erpTransactions
            );

          amounts[invoiceLineKey] = {
            [repaymentLineTypes.REPAYMENT]: round(openDisputeAmount, 2),
            [repaymentLineTypes.CREDIT_WRITE_OFF]: round(openWriteOffAmount, 2)
          };
        });
      }

      dispatch(setRepayableInvoiceLineAmounts(amounts));
    },
    [dispatch, erpTransactions, invoices]
  );

  // Auto selects a repayment if only one is available
  useEffect(() => {
    const repaymentKeys = Object.keys(availableRepayments);
    if (!currentRepayment && repaymentKeys.length === 1) {
      const selectedRepayment = erpTransactions[repaymentKeys[0]];
      updateAvailableRepaymentAmount(selectedRepayment);
      dispatch(setRepaymentObject(selectedRepayment));
      dispatch(checkAmountErrors());
    }
  }, [
    availableRepayments,
    currentRepayment,
    erpTransactions,
    dispatch,
    updateAvailableRepaymentAmount
  ]);

  // Auto selects a deduction if only one is available
  useEffect(() => {
    const deductionKeys = Object.keys(availableDeductions);
    if (!currentDeduction && deductionKeys.length === 1) {
      dispatch(setDeductionObject(erpTransactions[deductionKeys[0]]));
      dispatch(checkAmountErrors());
    }
  }, [availableDeductions, currentDeduction, erpTransactions, dispatch]);

  // Auto selects an invoice if only one is available
  useEffect(() => {
    const invoiceKeys = Object.keys(availableInvoices);
    if (!currentInvoice && invoiceKeys.length === 1) {
      const selectedInvoice = invoices[invoiceKeys[0]];
      updateRepayableInvoiceLines(selectedInvoice);
      updatedInvoiceLineRepayableAmounts(selectedInvoice);
      dispatch(setInvoiceObject(selectedInvoice));
      dispatch(checkAmountErrors());
    }
  }, [
    availableInvoices,
    currentInvoice,
    dispatch,
    invoices,
    updateRepayableInvoiceLines,
    updatedInvoiceLineRepayableAmounts
  ]);

  // For the select vs deselect all invoice lines button
  const allInvoiceLinesSelected = useMemo(() => {
    return (
      Object.keys(repaymentLines).length ===
      Object.keys(repayableInvoiceLines).length
    );
  }, [repaymentLines, repayableInvoiceLines]);

  const appliedSubtotal = useMemo(() => {
    return Object.values(repaymentLines)
      .map(repaymentLine => repaymentLine.amount)
      .reduce((total, amount) => total + Math.abs(amount), 0.0);
  }, [repaymentLines]);

  // For the title card use only
  const displayInitialDeduction = useMemo(() => {
    return initialDeduction
      ? TransactionDisplayService.processTransactionForDisplay(
          initialDeduction,
          invoices,
          erpTransactions,
          customers,
          companyUsers
        )
      : undefined;
  }, [initialDeduction, invoices, erpTransactions, customers, companyUsers]);

  const displayInitialRepayment = useMemo(() => {
    return initialRepayment
      ? TransactionDisplayService.processTransactionForDisplay(
          initialRepayment,
          invoices,
          erpTransactions,
          customers,
          companyUsers
        )
      : undefined;
  }, [initialRepayment, invoices, erpTransactions, customers, companyUsers]);

  const lineErrorExists = useMemo(() => {
    return Object.values(lineErrors).some(lineError =>
      Object.values(lineError).some(val => val)
    );
  }, [lineErrors]);

  const showErrorsMap: Record<string, boolean> = useMemo(() => {
    return {
      global:
        showErrors &&
        (errors.totalZero ||
          errors.noInvoiceLinesSelected ||
          errors.totalExceedsAvailableAmount ||
          lineErrorExists),
      repaymentKey: showErrors && errors.missingRepaymentKey,
      deductionKey: showErrors && errors.missingDeductionKey,
      invoiceKey: showErrors && errors.missingInvoiceKey
    };
  }, [errors, showErrors, lineErrorExists]);

  const errorMessagesMap: Record<string, string> = useMemo(() => {
    return {
      global:
        (showErrors &&
          ((errors.noInvoiceLinesSelected &&
            ApplyRepaymentErrorMap.noInvoiceLinesSelected) ||
            (errors.totalZero && ApplyRepaymentErrorMap.totalZero) ||
            (errors.totalExceedsAvailableAmount &&
              ApplyRepaymentErrorMap.totalExceedsAvailableAmount) ||
            (lineErrorExists && ApplyRepaymentErrorMap.lineErrors))) ||
        "",
      repaymentKey:
        (showErrors &&
          errors.missingRepaymentKey &&
          ApplyRepaymentErrorMap.missingRepaymentKey) ||
        "",
      deductionKey:
        (showErrors &&
          errors.missingDeductionKey &&
          ApplyRepaymentErrorMap.missingDeductionKey) ||
        "",
      invoiceKey:
        (showErrors &&
          errors.missingInvoiceKey &&
          ApplyRepaymentErrorMap.missingInvoiceKey) ||
        ""
    };
  }, [errors, showErrors, lineErrorExists]);

  const showErrorWithSaving = useCallback(
    (message: string) => {
      showSnack(message);
    },
    [showSnack]
  );

  const addRepaymentActivityLogEvents = useCallback(
    (savedRepaymentLines: Record<string, RepaymentLineObject>) => {
      Object.entries(savedRepaymentLines).forEach(entry => {
        const [_repaymentLineKey, repaymentLine] = entry;

        const eventConfig: AddEventConfig = {
          type:
            repaymentLine.type === repaymentLineTypes.CREDIT_WRITE_OFF
              ? DRMEventType.CREDIT_WRITE_OFF_APPLIED
              : DRMEventType.REPAYMENT_APPLIED,
          transactionKey: repaymentLine.repaymentKey,
          repaidTransactionKey: repaymentLine.deductionKey,
          invoiceKey: repaymentLine.invoiceKey,
          invoiceLineKey: repaymentLine.invoiceLineKey,
          metadata: {
            amount:
              TransactionDisplaySigns[ERPTransactionTypes.REPAYMENT] *
              Math.abs(repaymentLine.amount),
            fundType: repaymentLine.fundTypeKey,
            fundTypeAccount: repaymentLine.fundTypeAccount
          }
        };

        addEvent(eventConfig);
      });
    },
    [addEvent]
  );

  return (
    <Grid
      container
      style={{ width: `${(7 * (width || 0)) / 8}px` }}
      className={classes.gridContainer}>
      <Grid item xs={12}>
        {featureEntryPoint === ApplyRepaymentEntryPoint.DEDUCTION &&
          displayInitialDeduction && (
            <TransactionCard transaction={displayInitialDeduction} />
          )}
        {featureEntryPoint === ApplyRepaymentEntryPoint.REPAYMENT &&
          displayInitialRepayment && (
            <TransactionCard transaction={displayInitialRepayment} />
          )}
      </Grid>
      <Grid item xs={12} className={classes.divider}>
        <Divider orientation="horizontal" />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" className={classes.titleContainer}>
          {featureEntryPoint}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">Repayment Metadata:</Typography>
      </Grid>
      <Grid item xs={12} className={classes.paddedContainer}>
        <Grid container className={classes.rowContainer}>
          <Grid item xs={4}>
            <TextField
              select
              className={classes.textField}
              variant="outlined"
              label="Repayment ID*"
              size="small"
              disabled={Boolean(initialRepaymentKey)}
              error={showErrorsMap.repaymentKey}
              helperText={errorMessagesMap.repaymentKey}
              SelectProps={{
                value: currentRepayment?.key || "",
                renderValue: (rKey: string) => {
                  const selectedRepayment = availableRepayments[rKey];
                  return selectedRepayment.transactionDisplayId;
                }
              }}
              onChange={event => {
                const selectedRepayment =
                  erpTransactions[event.target.value as string];
                updateAvailableRepaymentAmount(selectedRepayment);
                dispatch(setRepaymentObject(selectedRepayment));
                dispatch(checkAmountErrors());
              }}>
              {Object.values(availableRepayments).map(repaymentOption => {
                return (
                  <MenuItem
                    value={repaymentOption.key}
                    key={repaymentOption.key}>
                    {repaymentOption.transactionDisplayId} (
                    {displayUSCurrency(
                      TransactionDisplaySigns[repaymentOption.type] *
                        TransactionAmountService.getTransactionOpenAmount(
                          repaymentOption,
                          invoices,
                          erpTransactions
                        )
                    )}
                    &nbsp;available)
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" className={classes.textField}>
              Amount Available:&nbsp;
              {displayUSCurrency(-availableRepaymentAmount) || "$0.00"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">Apply Repayment to:</Typography>
      </Grid>
      <Grid item xs={12} className={classes.paddedContainer}>
        <Grid container className={classes.rowContainer}>
          <Grid item xs={4}>
            <TextField
              select
              className={classes.textField}
              variant="outlined"
              label="Deduction ID*"
              size="small"
              error={showErrorsMap.deductionKey}
              helperText={errorMessagesMap.deductionKey}
              value={currentObjects.deduction?.key || ""}
              disabled={Boolean(initialDeductionKey)}
              onChange={event => {
                dispatch(
                  setDeductionObject(
                    erpTransactions[event.target.value as string]
                  )
                );
              }}>
              {Object.values(availableDeductions).map(deductionOption => {
                return (
                  <MenuItem
                    value={deductionOption.key}
                    key={deductionOption.key}>
                    {deductionOption.transactionDisplayId}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField
              select
              className={classes.textField}
              variant="outlined"
              label="Invoice Number"
              size="small"
              value={currentInvoice?.key || ""}
              error={showErrorsMap.invoiceKey}
              helperText={errorMessagesMap.invoiceKey}
              onChange={event => {
                const selectedInvoice = invoices[event.target.value as string];
                updateRepayableInvoiceLines(selectedInvoice);
                updatedInvoiceLineRepayableAmounts(selectedInvoice);
                dispatch(setInvoiceObject(selectedInvoice));
              }}>
              {Object.values(availableInvoices).map(invoiceOption => {
                return (
                  <MenuItem value={invoiceOption.key} key={invoiceOption.key}>
                    {invoiceOption.invoiceNumber}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.rowGroup}>
        <Typography variant="subtitle1">
          Invoice Lines with&nbsp;
          {featureEntryPoint === ApplyRepaymentEntryPoint.DEDUCTION
            ? "Open Disputes"
            : "Open Write-Offs"}
          &nbsp;(Subtotal:&nbsp;
          {displayUSCurrency(-appliedSubtotal) || "$0.00"})
        </Typography>
        {currentObjects.invoice && repayableInvoiceLines.length !== 0 && (
          <div>
            <Button
              className={classes.button}
              variant="contained"
              color="inherit"
              size="small"
              disabled={allInvoiceLinesSelected}
              onClick={() => {
                dispatch(selectAllInvoiceLines(db));
                dispatch(checkAmountErrors());
              }}>
              Select All
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              color="inherit"
              size="small"
              disabled={Object.keys(repaymentLines).length === 0}
              onClick={() => {
                dispatch(deselectAllInvoiceLines());
                dispatch(checkAmountErrors());
              }}>
              Deselect All
            </Button>
          </div>
        )}
      </Grid>
      <Grid item xs={12} className={classes.paddedTableContainer}>
        <RepayableInvoiceLinesTable />
      </Grid>
      <Grid item xs={12} className={classes.error}>
        {showErrorsMap.global ? errorMessagesMap.global : <div>&nbsp;</div>}
      </Grid>
      <Grid item xs={12} className={classes.paddedContainer}>
        <Button
          variant="contained"
          color="inherit"
          size="medium"
          onClick={() => {
            if (
              !currentRepayment ||
              !currentDeduction ||
              !currentInvoice ||
              lineErrorExists ||
              Object.values(errors).some(val => val)
            ) {
              dispatch(setShowErrors(true));
              return;
            }

            dispatch(setShowErrors(false));
            RepaymentLineProcessingService.createFirebaseRepaymentLines(
              Object.values(repaymentLines),
              currentRepayment.key,
              db,
              savedRepaymentLines => {
                RepaymentLineProcessingService.updateStatusesAfterRepaymentActivity(
                  savedRepaymentLines,
                  db,
                  currentInvoice,
                  currentDeduction,
                  currentRepayment,
                  showErrorWithSaving
                );
                addRepaymentActivityLogEvents(savedRepaymentLines);
                dispatch(reset());
              },
              () => showErrorWithSaving
            );
          }}>
          Submit
        </Button>
      </Grid>
    </Grid>
  );
}
