import React from "react";

import PollIcon from "@mui/icons-material/Poll";
import {
  PolarRadiusAxis,
  PolarAngleAxis,
  PolarGrid,
  Radar,
  RadarChart,
  ResponsiveContainer
} from "recharts";
import { graphPalette } from "helpers/ColorPalettes";

import { grey } from "@mui/material/colors";

const grey500 = grey["500"];
const { blue70, blue80 } = graphPalette;

export default class TopCustomersRadial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={{ margin: "-50px" }}>
        {this.props.data && this.props.data.length > 0 ? (
          <ResponsiveContainer width="100%" aspect={1}>
            <RadarChart outerRadius={130} data={this.props.data}>
              <Radar
                name="Expected Spend"
                dataKey="totalExpSpend"
                stroke={blue70}
                fill={blue80}
                fillOpacity={0.6}
              />
              <PolarGrid />
              <PolarAngleAxis dataKey="customer" />
              <PolarRadiusAxis tickFormatter={d3.format(".2s")} />
            </RadarChart>
          </ResponsiveContainer>
        ) : (
          <div style={{ height: 200, textAlign: "center", paddingTop: 50 }}>
            <PollIcon style={{ color: grey500, fontSize: 60 }} />
          </div>
        )}
      </div>
    );
  }
}
