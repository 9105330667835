import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import React from "react";

const RadioButton = props => {
  const { label, value, containerStyle, ...other } = props;
  return (
    <FormControlLabel
      control={<Radio color="primary" {...other} />}
      label={label || ""}
      value={value}
      style={containerStyle}
    />
  );
};

export default RadioButton;
