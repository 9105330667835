import React from "react";

import AddIcon from "@mui/icons-material/Add";
import Chip from "@mui/material/Chip";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Button from "ui-library/Button";
import IconButton from "@mui/material/IconButton";
import MenuItem from "ui-library/MenuItem";
import Select from "ui-library/Select";
import TextField from "ui-library/TextField";
import { Toolbar, ToolbarTitle } from "ui-library/Toolbar";
import ToolbarGroup from "ui-library/ToolbarGroup";

import { updateMetaFirebase, firebase } from "helpers/Firebase";
import { formatDependencies } from "helpers/Errors";
import { grey, common } from "@mui/material/colors";
import Subheader from "ui-library/Subheader";
import DataTable from "../tables/MuiDataTable";

const grey300 = grey["300"];
const grey500 = grey["500"];
const grey700 = grey["700"];
const { black } = common;

const styles = {
  notifs: {
    width: "60%"
  },
  subheader: {
    fontSize: 20,
    color: "primary.main",
    fontFamily: "Oxygen",
    my: 1
  },
  productList: {
    borderColor: grey700,
    borderWidth: 1,
    borderStyle: "solid"
    // overflow: 'auto',
    // height: 300
  },
  moneyIcon: {
    width: 15,
    height: 15
  },
  radioButton: {
    marginBottom: 16
  },
  chip: {
    margin: 4
  },
  checkbox: {
    marginLeft: 4
  },
  wrapper: {
    display: "flex",
    flexWrap: "wrap"
  }
};

class RegionMapping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ungroupedRegions: [],
      // FIELDS: name, regions, regionKey
      regionMapping: {},
      chipClicked: {},
      regionGroup: "",
      newGroup: ""
    };
  }

  getDependencies = region => {
    const dependencies = new Set();

    // check spend
    for (const actual in this.props.db.actMoney) {
      if (this.props.db.actMoney[actual].region === region) {
        dependencies.add("spend");
      }
    }

    // check revenue
    for (const revenue in this.props.db.revenue) {
      if (this.props.db.revenue[revenue].region === region) {
        dependencies.add("revenue");
      }
    }

    return dependencies;
  };

  initialize = props => {
    if (props.db.meta && props.db.meta.region_mapping) {
      const ungroupedRegions = props.db.meta.region_mapping.ungrouped_regions;
      const chipClicked = {};
      if (ungroupedRegions) {
        for (let i = 0; i < ungroupedRegions.length; i++) {
          chipClicked[ungroupedRegions[i]] = false;
        }
      }
      this.setState({
        chipClicked,
        ungroupedRegions,
        regionMapping: props.db.meta.region_mapping.groups
      });
    }
  };

  handleAddRegions = () => {
    const { regionGroup } = this.state;

    if (!this.state.regionGroup) {
      return;
    }

    const { ungroupedRegions } = this.state;
    const regionsToAdd = [];
    const delIndices = [];
    for (var i = 0; i < ungroupedRegions.length; i++) {
      if (this.state.chipClicked[ungroupedRegions[i]]) {
        regionsToAdd.push(ungroupedRegions[i]);
        delIndices.push(i);
      }
    }
    delIndices.reverse();

    const newRegionMapping = { ...this.state.regionMapping };

    for (const key in newRegionMapping) {
      // can only push to one region group at a time
      if (key == regionGroup) {
        if (newRegionMapping[key].regions) {
          newRegionMapping[key].regions.push(...regionsToAdd);
        } else {
          newRegionMapping[key].regions = regionsToAdd;
        }

        var update = {};
        update[`region_mapping/groups/${key}`] = newRegionMapping[key];
        updateMetaFirebase(update);

        break;
      }
    }

    for (var i = 0; i < delIndices.length; i++) {
      ungroupedRegions.splice(delIndices[i], 1);
    }

    var update = {};
    update["region_mapping/ungrouped_regions"] = ungroupedRegions;
    updateMetaFirebase(update);

    const chipClicked = {};
    for (var i = 0; i < ungroupedRegions.length; i++) {
      chipClicked[ungroupedRegions[i]] = false;
    }
    this.setState({
      chipClicked,
      ungroupedRegions,
      regionGroup: ""
    });
  };

  handleAddGroup = () => {
    if (!this.state.newGroup) {
      return;
    }

    const { key } = firebase.database().ref().push();

    const newRegionGroup = {
      name: this.state.newGroup,
      regions: [],
      regionKey: key
    };

    const update = {};
    update[`region_mapping/groups/${key}`] = newRegionGroup;
    updateMetaFirebase(update);
    this.setState({ newGroup: "" });
  };

  handleDeleteRegion = (key, region) => {
    const update = {};
    const newUngroupedRegions = this.state.ungroupedRegions || [];

    if (key) {
      // delete region from this group and add to ungrouped regions
      newUngroupedRegions.push(region);
      const thisGroupRegions = this.state.regionMapping[key].regions;
      thisGroupRegions.splice(thisGroupRegions.indexOf(region), 1);
      update[`region_mapping/groups/${key}/regions`] = thisGroupRegions;
    } else {
      // if no dependencies, delete from ungrouped regions
      const dependencies = this.getDependencies(region);
      if (dependencies.size) {
        const errorStr = formatDependencies("region", dependencies);
        this.props.openClose.closeAppModal();
        this.props.openClose.setAppModal(
          "Unable to delete region",
          <div className="centering">{errorStr}</div>,
          <div className="centering">
            <Button
              label="Okay"
              onClick={() => {
                this.props.openClose.closeAppModal();
              }}
            />
          </div>
        );
      } else {
        newUngroupedRegions.splice(newUngroupedRegions.indexOf(region), 1);
      }
    }

    update["region_mapping/ungrouped_regions"] = newUngroupedRegions;
    updateMetaFirebase(update);
  };

  handleDeleteGroup = key => {
    // check if any customers are associated with this group
    const dependencies = new Set();
    for (const c in this.props.db.customers) {
      const customer = this.props.db.customers[c];
      if (customer.regionGroups && customer.regionGroups.includes(key)) {
        dependencies.add("customers");
      }
    }

    if ("brokers" in this.props.db) {
      for (const broker in this.props.db.brokers) {
        if (this.props.db.brokers[broker].regionGroup === key) {
          dependencies.add("brokers");
        }
      }
    }

    if (dependencies.size) {
      const errorStr = formatDependencies("region group", dependencies);
      this.props.openClose.closeAppModal();
      this.props.openClose.setAppModal(
        "Unable to delete region group",
        <div className="centering">{errorStr}</div>,
        <div className="centering">
          <Button
            label="Okay"
            onClick={() => {
              this.props.openClose.closeAppModal();
            }}
          />
        </div>
      );
      return;
    }

    const newUngroupedRegions = this.state.ungroupedRegions || [];
    newUngroupedRegions.push(...(this.state.regionMapping[key].regions || []));

    const update = {};
    update["region_mapping/ungrouped_regions"] = newUngroupedRegions;
    update[`region_mapping/groups/${key}`] = null;
    updateMetaFirebase(update);
  };

  getGroupTableData = () => {
    const tableData = { "Group Name": [], Regions: [], "Delete Group": [] };
    if (!this.state.regionMapping) return tableData;

    for (var key in this.state.regionMapping) {
      tableData["Group Name"].push(this.state.regionMapping[key].name);
      tableData.Regions.push(
        <div style={styles.wrapper}>
          {this.state.regionMapping[key].regions &&
            this.state.regionMapping[key].regions.map((region, index) => {
              return (
                <Chip
                  style={styles.chip}
                  label={region}
                  onDelete={
                    this.props.db.permissions.includes("admin")
                      ? this.handleDeleteRegion.bind(null, key, region)
                      : null
                  }
                />
              );
            })}
        </div>
      );
      tableData["Delete Group"].push(
        <IconButton
          tooltip="Delete Region Group"
          style={{ marginTop: 5 }}
          onClick={this.handleDeleteGroup.bind(null, key)}
          size="large">
          <DeleteForeverIcon sx={{ color: "error.main" }} />
        </IconButton>
      );
    }

    return tableData;
  };

  componentDidMount() {
    this.initialize(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.initialize(nextProps);
  }

  render() {
    const tableData = this.getGroupTableData();

    return (
      <div style={{ fontFamily: "Oxygen" }}>
        <Toolbar>
          <ToolbarGroup>
            <ToolbarTitle text="Region Mapping" style={{ color: black }} />
          </ToolbarGroup>
        </Toolbar>
        <div style={{ padding: 16 }}>
          <Subheader style={styles.subheader}>Ungrouped Regions</Subheader>
          <div style={styles.wrapper}>
            {this.state.ungroupedRegions &&
            this.state.ungroupedRegions.length > 0 ? (
              this.state.ungroupedRegions.map((region, index) => {
                return (
                  <Chip
                    style={styles.chip}
                    label={region}
                    clickable
                    backgroundColor={
                      this.state.chipClicked[region] ? grey500 : grey300
                    }
                    onClick={() => {
                      const chipClicked = {
                        ...this.state.chipClicked
                      };
                      chipClicked[region] = !chipClicked[region];
                      this.setState({ chipClicked });
                    }}
                    onDelete={
                      this.props.db.permissions.includes("admin")
                        ? this.handleDeleteRegion.bind(null, null, region)
                        : null
                    }
                  />
                );
              })
            ) : (
              <Chip label="None" />
            )}
          </div>
          <br />
          <div>Group selected regions into:</div>
          <div className="rowC" style={{ marginLeft: 10, marginTop: 5 }}>
            <Select
              floatingLabelText="Select Region Group"
              value={this.state.regionGroup}
              onChange={(event, index, value) => {
                this.setState({ regionGroup: value });
              }}>
              {this.props.db.meta.region_mapping &&
                this.props.db.meta.region_mapping.groups &&
                Object.keys(this.props.db.meta.region_mapping.groups).map(
                  item => (
                    <MenuItem
                      children={
                        this.props.db.meta.region_mapping.groups[item].name
                      }
                      value={item}
                      key={item}
                    />
                  )
                )}
            </Select>
            <IconButton
              tooltip="Add Regions"
              style={{ marginTop: 5 }}
              onClick={this.handleAddRegions}
              size="large">
              <AddIcon style={{ color: grey700 }} />
            </IconButton>
          </div>
          <Subheader style={styles.subheader}>Region Groups</Subheader>

          <div className="rowC">
            <TextField
              floatingLabelText="New Region Group"
              InputLabelProps={{ style: { top: "-5px" } }}
              onChange={event => {
                this.setState({ newGroup: event.target.value });
              }}
              onKeyPress={ev => {
                if (ev.key === "Enter") {
                  this.handleAddGroup();
                  ev.preventDefault();
                  this.setState({ newGroup: "" });
                }
              }}
              value={this.state.newGroup}
              style={{ marginLeft: 10, marginRight: 10 }}
            />
            <IconButton
              tooltip="Add Group"
              style={{ marginTop: 5 }}
              onClick={this.handleAddGroup}
              size="large">
              <AddIcon style={{ color: grey700 }} />
            </IconButton>
          </div>
          <br />
          <DataTable
            title="Region Groups"
            data={tableData}
            widths={["20%", "60%", "20%"]}
          />
        </div>
      </div>
    );
  }
}

export default RegionMapping;
