const projectId = "cresicor-sandbox";
const cloudRunURLSuffix = "cloudrun-service-o6hkppytha-uc.a.run.app";
const cloudRunFunctionSuffix = "upload-service-o6hkppytha-uc.a.run.app";
const firebaseAPIKey = "AIzaSyD9jrWz1ZJE7GV_xpQnrBT6z0ucQceIAwg";
const firebaseAuthDomain = "sandbox.govividly.com";
const firebaseAppId = "1:672000275874:web:6b71c38d109a9535b66efb";

export default {
  projectId,
  cloudRunURLSuffix,
  cloudRunFunctionSuffix,
  firebaseAPIKey,
  firebaseAuthDomain,
  firebaseAppId
};
