import React from "react";
import Card from "ui-library/Card";

/*
Input to this class will be of the form {Key1: [...], ..., Keyn: [...]}.
Output table will have Key1, ..., Keyn be the headers, and row i consists
of the ith elements of the lists corresponding to the keys.
*/

export default class DataTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: [],
      rows: []
    };
  }

  updateTable = (data, order) => {
    const title = []; // header for the table
    const rows = []; // data for rows of table

    if (!order) {
      for (var key in data) {
        title.push(key);
      }
    } else if (order && data) {
      for (var i = 0; i < order.length; i++) {
        if (order[i] in data) {
          title.push(order[i]);
        }
      }
    }

    if (data && title.length && data[title[0]]) {
      for (var i = 0; i < data[title[0]].length; i++) {
        const row = [];
        for (var key in title) {
          row.push(data[title[key]][i]);
        }
        rows.push(row);
      }
    }

    this.setState({
      title,
      rows
    });
  };

  componentDidMount() {
    this.updateTable(this.props.data, this.props.order);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateTable(nextProps.data, nextProps.order);
  }

  render() {
    return (
      <Card title={this.props.headless ? null : this.props.title}>
        <div
          className="table-responsive"
          style={{
            maxHeight: this.props.height ? this.props.height : null,
            fontSize: this.props.fontSize ? this.props.fontSize : 16,
            overflow: "auto"
          }}>
          <table
            className="table"
            style={this.props.styles ? this.props.styles.table : {}}>
            <thead>
              <tr>
                {this.state.title.map((item, index) => (
                  <th key={`th${index}`}>{item}</th>
                ))}
              </tr>
            </thead>
            <tbody style={{ overflow: "auto" }}>
              {this.state.rows.map((row, index1) => {
                const rendered_row = row.map((item, index2) => (
                  <td
                    key={`td${index2}`}
                    width={
                      this.props.widths &&
                      this.props.widths.length > index2 &&
                      this.props.widths[index2]
                    }>
                    {item}
                  </td>
                ));
                return (
                  <tr
                    key={`tr${index1}${
                      this.props.idAddition ? this.props.idAddition : ""
                    }`}>
                    {rendered_row}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Card>
    );
  }
}
