import { Form, Input, Table } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";

import { FormInstance } from "antd/lib/form";

const EditableContext = React.createContext<FormInstance<any> | undefined>(
  undefined
);

interface Item {
  [key: string]: any;
}

interface EditableRowProps {
  index: number | string;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => Promise<void>;
}

const round = (n?: number) => Math.round((n ?? 0) * 100) / 100;

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<Input>(undefined);
  const form = useContext(EditableContext)!;
  const [dirty, setDirty] = useState(false);

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const value = round(record?.[dataIndex]);

  useEffect(() => {
    setDirty(false);
  }, [value]);

  const startEdit = () => {
    setEditing(true);
    form.setFieldsValue({ [dataIndex]: round(record[dataIndex]) });
  };

  const endEdit = () => {
    setEditing(false);
    setDirty(true);
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      handleSave({ ...record, ...values });
      endEdit();
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`
          }
        ]}>
        <Input ref={inputRef} onPressEnter={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={startEdit}>
        {dirty ? form.getFieldValue(dataIndex) : value}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
  [key: string]: any;
}

interface EditableTableState {
  dataSource: DataType[];
  count: number;
}

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

export { EditableRow, EditableCell };
