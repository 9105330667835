import {
  UPDATE_FIELD,
  SET_DEFAULT,
  ADD_LINE_KEY,
  DELETE_LINE_KEY,
  GET_SUGGESTED_MATCHES,
  RESET,
  CHANGE_MODAL_STEP,
  SAVE_MATCHES
} from "./actions";
import { calculateSpendRateDelta } from "./index";

const initialState = {
  source: "none",
  stepIndex: 0,
  accumToLines: [],
  accumInvoiceLinesDefault: [],
  accumInvoiceLines: [],
  invoiceLinesDefault: [],
  invoiceLines: [],
  suggestMatches: true,
  fileTooLarge: false,
  uploadPath: null,
  saveMatches: false
};

const updateField = (newState, field, value) => ({
  ...newState,
  [field]: value
});

const setDefault = (
  newState,
  accumToLines,
  accumInvoiceLinesDefault,
  invoiceLinesDefault,
  accumInvoiceLines,
  invoiceLines,
  fileLineLimit,
  fileTooLarge,
  uploadPath,
  matchKey,
  suggestMatches
) => {
  newState.accumToLines = accumToLines;
  newState.accumInvoiceLinesDefault = JSON.parse(
    JSON.stringify(accumInvoiceLinesDefault)
  );
  newState.invoiceLinesDefault = JSON.parse(
    JSON.stringify(invoiceLinesDefault)
  );
  newState.accumInvoiceLines = JSON.parse(JSON.stringify(accumInvoiceLines));
  newState.invoiceLines = JSON.parse(JSON.stringify(invoiceLines));
  newState.fileLineLimit = fileLineLimit;
  newState.fileTooLarge = fileTooLarge;
  newState.uploadPath = uploadPath;
  newState.matchKey = matchKey;
  newState.suggestMatches = suggestMatches;
  return newState;
};

const addLineKey = (newState, rows, lineKey, tableType) => {
  const viewAllLines = tableType === "View All Lines";
  const viewAccumulatedLines = tableType === "View Accumulated Lines";
  const data = viewAllLines
    ? newState.invoiceLinesDefault
    : newState.accumInvoiceLinesDefault;
  let tableData = viewAllLines
    ? newState.invoiceLines
    : newState.accumInvoiceLines;

  for (const row of rows) {
    const rowNumber = row.Row;
    if (viewAccumulatedLines) {
      // if we are selecting line keys for accumulated lines, make same changes on individual lines as well
      const correspondingRowInd = new Set(newState.accumToLines[rowNumber]);
      const correspondingRows = Object.values(newState.invoiceLines).filter(x =>
        correspondingRowInd.has(x.Row)
      );
      newState = addLineKey(
        newState,
        correspondingRows,
        lineKey,
        "View All Lines"
      );
    }
    const lineKeys =
      row["Matched Line Key"][0] === "-"
        ? [lineKey]
        : row["Matched Line Key"].split("|").concat(lineKey);
    const origLineKeys = data[rowNumber]["Matched Line Key"].split("|");
    const origPromTypes = data[rowNumber]["Matched Promotion Type"].split("|");
    const origSpendRates =
      data[rowNumber]["Matched Line Spend Rate"].split("|");
    const origLineClosed = data[rowNumber]["Matched Line Closed"].split("|");
    const origLineProductGroup =
      data[rowNumber]["Matched Line Product Group"].split("|");
    const origAccount = data[rowNumber]["Matched Line Account"].split("|");
    const newLineKeys = [];
    const newPromTypes = [];
    const newSpendRates = [];
    const newLineClosed = [];
    const newLineProductGroup = [];
    const newAccount = [];
    for (let i = 0; i < origLineKeys.length; i++) {
      if (lineKeys.includes(origLineKeys[i])) {
        newLineKeys.push(origLineKeys[i]);
        newPromTypes.push(origPromTypes[i]);
        newSpendRates.push(origSpendRates[i]);
        newLineClosed.push(origLineClosed[i]);
        newLineProductGroup.push(origLineProductGroup[i]);
        newAccount.push(origAccount[i]);
      }
    }
    row["Matched Line Key"] = newLineKeys.join("|");
    row["Matched Promotion Type"] = newPromTypes.join("|");
    row["Matched Line Spend Rate"] = newSpendRates.join("|");
    row["Matched Line Closed"] = newLineClosed.join("|");
    row["Matched Line Product Group"] = newLineProductGroup.join("|");
    row["Matched Line Account"] = newAccount.join("|");
    tableData[rowNumber] = row;
  }
  tableData = calculateSpendRateDelta(tableData);

  if (viewAllLines) {
    Object.assign(newState, {
      invoiceLines: tableData
    });
  } else {
    Object.assign(newState, {
      accumInvoiceLines: tableData
    });
  }

  return newState;
};

const deleteLineKey = (newState, rows, lineKey, tableType) => {
  const viewAllLines = tableType === "View All Lines";
  const viewAccumulatedLines = tableType === "View Accumulated Lines";
  const data = viewAllLines
    ? newState.invoiceLinesDefault
    : newState.accumInvoiceLinesDefault;
  let tableData = viewAllLines
    ? newState.invoiceLines
    : newState.accumInvoiceLines;

  for (const row of rows) {
    const rowNumber = row.Row;
    if (viewAccumulatedLines) {
      // if we are selecting line keys for accumulated lines, make same changes on individual lines as well
      const correspondingRowInd = new Set(newState.accumToLines[rowNumber]);
      const correspondingRows = Object.values(newState.invoiceLines).filter(x =>
        correspondingRowInd.has(x.Row)
      );
      newState = deleteLineKey(
        newState,
        correspondingRows,
        lineKey,
        "View All Lines"
      );
    }
    const lineKeys = row["Matched Line Key"].split("|");
    lineKeys.splice(lineKeys.indexOf(lineKey), 1);

    const defaultArray = lineKeys.length === 0 ? ["-"] : [];
    const newLineKeys = defaultArray;
    const newPromTypes = defaultArray;
    const newSpendRates = defaultArray;
    const newLineClosed = defaultArray;
    const newLineProductGroup = ["-"];
    const newAccount = defaultArray;

    if (lineKeys.length !== 0) {
      const origLineKeys = data[rowNumber]["Matched Line Key"].split("|");
      const origPromTypes =
        data[rowNumber]["Matched Promotion Type"].split("|");
      const origSpendRates =
        data[rowNumber]["Matched Line Spend Rate"].split("|");
      const origLineClosed = data[rowNumber]["Matched Line Closed"].split("|");
      const origLineProductGroup =
        data[rowNumber]["Matched Line Product Group"].split("|");
      const origAccount = data[rowNumber]["Matched Line Account"].split("|");

      for (let i = 0; i < origLineKeys.length; i++) {
        if (lineKeys.includes(origLineKeys[i])) {
          newLineKeys.push(origLineKeys[i]);
          newPromTypes.push(origPromTypes[i]);
          newSpendRates.push(origSpendRates[i]);
          newLineClosed.push(origLineClosed[i]);
          newLineProductGroup.push(origLineProductGroup[i]);
          newAccount.push(origAccount[i]);
        }
      }
    }
    row["Matched Line Key"] = newLineKeys.join("|");
    row["Matched Promotion Type"] = newPromTypes.join("|");
    row["Matched Line Spend Rate"] = newSpendRates.join("|");
    row["Matched Line Closed"] = newLineClosed.join("|");
    row["Matched Line Product Group"] = newLineProductGroup.join("|");
    row["Matched Line Account"] = newAccount.join("|");
    tableData[rowNumber] = row;
  }
  tableData = calculateSpendRateDelta(tableData);
  if (tableType === "View All Lines") {
    Object.assign(newState, {
      invoiceLines: tableData
    });
  } else {
    Object.assign(newState, {
      accumInvoiceLines: tableData
    });
  }
  return newState;
};

const getSuggestedMatches = (newState, isInputChecked) => {
  const {
    invoiceLinesDefault,
    invoiceLines,
    accumInvoiceLinesDefault,
    accumInvoiceLines
  } = newState;
  if (!isInputChecked) {
    Object.assign(newState, {
      invoiceLines: JSON.parse(JSON.stringify(invoiceLinesDefault)),
      accumInvoiceLines: JSON.parse(JSON.stringify(accumInvoiceLinesDefault))
    });
    return newState;
  }
  for (let [data, tableData, tableType] of [
    [invoiceLinesDefault, invoiceLines, "All Lines"],
    [accumInvoiceLinesDefault, accumInvoiceLines, "Accumulated Lines"]
  ]) {
    for (let i = 0; i < data.length; i++) {
      const row = { ...data[i] };
      const spendRate = row["Spend Rate"];
      const origLineKeys = row["Matched Line Key"].split("|");
      const origSpendRates = row["Matched Line Spend Rate"].split("|");
      const origPromTypes = row["Matched Promotion Type"].split("|");
      const origLineClosed = row["Matched Line Closed"].split("|");
      const origAccount = row["Matched Line Account"].split("|");
      const newLineKeys = [];
      const newPromTypes = [];
      const newSpendRates = [];
      const newLineClosed = [];
      const newAccount = [];
      let minDiff = Infinity;
      for (let j = 0; j < origLineKeys.length; j++) {
        if (!isNaN(parseFloat(origSpendRates[j]))) {
          const matchedSpendRate = parseFloat(origSpendRates[j]);
          const diff = Math.abs(matchedSpendRate - spendRate);
          if (diff < minDiff) {
            minDiff = diff;
          }
        }
      }
      for (let j = 0; j < origLineKeys.length; j++) {
        const matchedSpendRate = parseFloat(origSpendRates[j]);
        const isSpendNaN = isNaN(matchedSpendRate);
        const diff = Math.abs(matchedSpendRate - spendRate);
        if (isSpendNaN || diff === minDiff) {
          newLineKeys.push(origLineKeys[j]);
          newPromTypes.push(origPromTypes[j]);
          newSpendRates.push(origSpendRates[j]);
          newLineClosed.push(origLineClosed[j]);
          newAccount.push(origAccount[j]);
        }
      }
      row["Matched Line Key"] = newLineKeys.join("|");
      row["Matched Promotion Type"] = newPromTypes.join("|");
      row["Matched Line Spend Rate"] = newSpendRates.join("|");
      row["Matched Line Closed"] = newLineClosed.join("|");
      row["Matched Line Account"] = newAccount.join("|");
      tableData[i] = row;
    }
    tableData = calculateSpendRateDelta(tableData);
    if (tableType === "View All Lines") {
      Object.assign(newState, {
        invoiceLines: tableData
      });
    } else {
      Object.assign(newState, {
        accumInvoiceLines: tableData
      });
    }
  }
  return newState;
};

const reset = () => initialState;

const invoiceLinesMatchingReducer = (state = initialState, action) => {
  const newState = jQuery.extend(true, {}, state);
  switch (action.type) {
    case UPDATE_FIELD:
      return updateField(newState, action.field, action.value);
    case SET_DEFAULT:
      return setDefault(
        newState,
        action.accumToLines,
        action.accumInvoiceLinesDefault,
        action.invoiceLinesDefault,
        action.accumInvoiceLines,
        action.invoiceLines,
        action.fileLineLimit,
        action.fileTooLarge,
        action.uploadPath,
        action.matchKey,
        action.suggestMatches
      );
    case ADD_LINE_KEY:
      return addLineKey(
        newState,
        action.rows,
        action.lineKey,
        action.tableType
      );
    case DELETE_LINE_KEY:
      return deleteLineKey(
        newState,
        action.rows,
        action.lineKey,
        action.tableType
      );
    case GET_SUGGESTED_MATCHES:
      return getSuggestedMatches(newState, action.isInputChecked);
    case RESET:
      return reset(newState);
    case CHANGE_MODAL_STEP:
      return { ...newState, stepIndex: action.stepIndex, saveMatches: false };
    case SAVE_MATCHES:
      return { ...newState, saveMatches: true };
    default:
      return newState;
  }
};

export default invoiceLinesMatchingReducer;
