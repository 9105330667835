import React from "react";

import MenuItem from "ui-library/MenuItem";
import SelectField from "ui-library/Select";
import { red } from "@mui/material/colors";
import DataTableScrollable from "../tables/DataTableScrollable";

const red500 = red["500"];

class MapData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mapData: {},
      columns: [],
      missingColumns: []
    };
  }

  componentDidMount() {
    this.props.onLoad();
    this.setState({ mapData: this.props.mapData, columns: this.props.columns });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      mapData: nextProps.mapData,
      columns: this.props.columns,
      missingColumns: nextProps.missingColumns
    });
  }

  tableInfo = () => {
    const colnames = this.state.columns;
    const tableColnames = this.state.columns.map(col => {
      return this.props.requiredColumns.indexOf(col) != -1 ? (
        <div>
          {col}
          <span style={{ color: red500 }}>*</span>
        </div>
      ) : (
        <div>{col}</div>
      );
    });
    const selection = this.props.header;

    const selectionColnames = [];
    for (let i = 0; i < colnames.length; i++) {
      const colname = this.state.mapData[colnames[i]];
      selectionColnames.push(
        <SelectField
          key={colnames[i]}
          value={colname}
          onChange={this.props.handleChange.bind(null, colnames[i])}
          errorText={
            this.state.missingColumns.indexOf(colnames[i]) >= 0 &&
            "This field is required."
          }>
          {selection.map(item => (
            <MenuItem key={item} value={item} children={item} />
          ))}
        </SelectField>
      );
    }

    const tableData = {
      "Vividly Field Name": tableColnames,
      "Your Field": selectionColnames
    };

    return (
      <DataTableScrollable
        title="Map your fields to Vividly Field Names"
        data={tableData}
      />
    );
  };

  render() {
    return <div>{this.tableInfo()}</div>;
  }
}

export default MapData;
