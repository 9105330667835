import React from "react";
import DatePicker from "ui-library/DatePicker";

import Card from "ui-library/Card";

interface PricingUploadCardProps {
  pricingUploadDate: Date;
  setPricingUploadDate: Function;
  createNewTimeframes: Boolean;
  setCreateNewTimeframes: Function;
  stepIndex: number;
}

export default function PricingUploadCard(props: PricingUploadCardProps) {
  return (
    <Card title="Pricing Upload">
      {props.stepIndex === 0 ? (
        <>
          <DatePicker
            disableToolbar
            floatingLabelText="Effective Date"
            value={props.pricingUploadDate}
            onChange={newDate => {
              props.setPricingUploadDate(newDate);
            }}
            inputFormat="MMM dd, yyyy"
          />
          {/* <br />
          <Checkbox
            label="Create New Timeframes"
            checked={props.createNewTimeframes}
            onChange={event => {
              props.setCreateNewTimeframes(event.target.checked);
            }}
            color="primary"
          /> */}
        </>
      ) : (
        <>
          {"Your pricing upload will become effective on: "}
          <b>{props.pricingUploadDate?.toLocaleDateString()}</b>.
          {/* <br />{"Timeframes "}
          <b>{props.createNewTimeframes ? "will" : "will not"}</b>
          {" be created."} */}
        </>
      )}
    </Card>
  );
}
