import { Checkbox, Icon, TableContainer, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Visibility } from "@mui/icons-material";
import { invoiceLineStatuses } from "components/Deductions/constants/ReconciliationStatuses";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  InvoiceDisplayService,
  InvoiceLineDisplayService,
  TransactionDisplayService
} from "components/Deductions/DeductionsReconciliation/services";

import PromProfile from "components/Planning/PromProfile";
import { useDb } from "contexts/Db";
import { useOpenClose } from "contexts/OpenClose";
import { displayUSCurrency } from "helpers/DataProcessing";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import CresicorIconButton from "ui-library/IconButton";
import { getCleanedInvoiceLineObject } from "components/Deductions/DeductionsReconciliation/services/InvoiceLineServices/invoiceLineProcessing";
import CollapsibleTable, {
  ColumnConfigOptions
} from "ui-library/CollapsibleTable/CollapsibleTable";
import { RootState } from "store";
import { DisplayInvoiceLineObject } from "components/Deductions/DeductionsReconciliation/types/invoiceLineTypes";
import LinkedInvoiceLinesTableControls from "./LinkedInvoiceLinesTableControls";
import ResolveInvoiceLine from "../ResolveInvoiceLine/ResolveInvoiceLine";
import {
  toggleSelectInvoiceLine,
  toggleSelectAllInvoiceLines
} from "../ResolveMultipleInvoiceLines/redux/ResolveMultipleInvoiceLinesSlice";

const ActionButton = styled(CresicorIconButton)(({ theme }) => ({
  color: theme.palette.grey[600]
}));

const NoDataComponent = styled("div")(({ theme }) => ({
  textAlign: "center",
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1)
}));

const CollapsibleTableContainer = styled(TableContainer)(() => ({
  maxHeight: "400px",
  marginBottom: "14px"
}));

const PAGINATION_THRESHOLD = 50;
const DEFAULT_PAGE_SIZE = 10;

interface LinkedInvoiceLinesTableProps {
  invoiceKey: string;
  transactionKey: string;
}

interface LinkedInvoiceLinesSortState {
  orderBy?: string;
  sortDir?: "asc" | "desc";
}

export default function LinkedInvoiceLinesTable(
  props: LinkedInvoiceLinesTableProps
) {
  const dispatch = useDispatch();
  const { selectedInvoiceLines } = useSelector(
    (state: RootState) => state.resolveMultipleInvoiceLines,
    shallowEqual
  );
  const { invoiceKey, transactionKey } = props;
  const openClose = useOpenClose();
  const { showRightDrawer } = openClose;
  const db = useDb();
  const {
    meta: { fundTypes = {} },
    invoices = {},
    erpTransactions = {},
    allLines = {},
    customers = {},
    companyUsers = {},
    products = {}
  } = db;

  const invoice = useMemo(() => {
    return invoices[invoiceKey] || null;
  }, [invoices, invoiceKey]);

  const transaction = useMemo(() => {
    return erpTransactions[transactionKey] || null;
  }, [erpTransactions, transactionKey]);

  const displayInvoice = useMemo(() => {
    return InvoiceDisplayService.processInvoiceForDisplay(
      invoice,
      erpTransactions,
      customers,
      companyUsers
    );
  }, [companyUsers, customers, erpTransactions, invoice]);

  const [sortState, setSortState] = useState<LinkedInvoiceLinesSortState>({
    orderBy: "displayStatus",
    sortDir: "asc"
  });

  const displayTransaction = useMemo(() => {
    return TransactionDisplayService.processTransactionForDisplay(
      transaction,
      invoices,
      erpTransactions,
      customers,
      companyUsers
    );
  }, [transaction, invoices, erpTransactions, customers, companyUsers]);

  const invoiceLines = invoice.invoiceLines || {};

  const allInvoiceLines = Object.entries(invoiceLines);
  const [paginationEnabled] = useState<boolean>(
    allInvoiceLines.length > PAGINATION_THRESHOLD
  );

  const sortedLines = useMemo(() => {
    const entries = allInvoiceLines.sort(
      (entry1, entry2) =>
        entry1[1].invoiceLineNumber - entry2[1].invoiceLineNumber
    );
    return entries;
  }, [allInvoiceLines]);

  const sortedDisplayLines = useMemo(() => {
    return sortedLines.map(entry => {
      const [invoiceLineKey, invoiceLine] = entry;
      return {
        invoiceLine,
        displayInvoiceLine:
          InvoiceLineDisplayService.processInvoiceLineForDisplay(
            displayInvoice,
            invoiceLine,
            invoiceLineKey,
            fundTypes,
            invoices,
            erpTransactions,
            allLines,
            products,
            customers
          )
      };
    });
  }, [
    sortedLines,
    displayInvoice,
    fundTypes,
    invoices,
    erpTransactions,
    allLines,
    products,
    customers
  ]);

  const getHeaderContent = useCallback(
    (headerText: string) => {
      switch (headerText) {
        case "Select": {
          return (
            <Checkbox
              checked={allInvoiceLines.every(([invoiceLineKey]) => {
                return (
                  invoiceKey in selectedInvoiceLines &&
                  invoiceLineKey in selectedInvoiceLines[invoiceKey]
                );
              })}
              onChange={() => {
                dispatch(
                  toggleSelectAllInvoiceLines({
                    invoiceKey,
                    invoiceLines: allInvoiceLines
                  })
                );
              }}
            />
          );
        }
        default: {
          return headerText;
        }
      }
    },
    [allInvoiceLines, dispatch, invoiceKey, selectedInvoiceLines]
  );
  const columnConfig = useMemo(
    () =>
      new Map([
        [
          "key",
          {
            name: getHeaderContent("Select"),
            render: (_key, displayInvoiceLine) => {
              const { key, invoiceLineKey } = displayInvoiceLine;
              const currentKey = invoiceLineKey || key;
              const invoiceLine =
                getCleanedInvoiceLineObject(displayInvoiceLine);
              return (
                <Checkbox
                  checked={
                    invoiceKey in selectedInvoiceLines &&
                    displayInvoiceLine.key in selectedInvoiceLines[invoiceKey]
                  }
                  onChange={() => {
                    dispatch(
                      toggleSelectInvoiceLine({
                        invoiceKey,
                        invoiceLine: {
                          ...invoiceLine,
                          key: currentKey
                        }
                      })
                    );
                  }}
                />
              );
            }
          }
        ],
        [
          "invoiceLineNumber",
          {
            name: "#",
            sorting: {
              enabled: true
            },
            render: (_val, displayInvoiceLine) =>
              displayInvoiceLine.invoiceLineNumber + 1
          }
        ],
        [
          "customerName",
          {
            name: "Customer",
            align: "left",
            sorting: {
              enabled: true
            }
          }
        ],
        [
          "productName",
          {
            name: "Product",
            align: "left",
            sorting: {
              enabled: true
            }
          }
        ],
        [
          "startDate",
          {
            name: "Date",
            align: "left",
            sorting: {
              enabled: true,
              customSort: (
                a: DisplayInvoiceLineObject,
                b: DisplayInvoiceLineObject
              ) => moment.utc(a.startDate).diff(moment.utc(b.startDate))
            },
            render: (_val, displayInvoiceLine) =>
              moment(displayInvoiceLine.startDate).format("MM/DD/YYYY")
          }
        ],
        [
          "amount",
          {
            name: "Original",
            align: "right",
            sorting: {
              enabled: true,
              customSort: (
                a: DisplayInvoiceLineObject,
                b: DisplayInvoiceLineObject
              ) => {
                return (a.amount || 0) - (b.amount || 0);
              }
            },
            render: val => displayUSCurrency(val)
          }
        ],
        [
          "openAmount",
          {
            name: "Open",
            align: "right",
            sorting: {
              enabled: true,
              sorting: {
                enabled: true,
                customSort: (
                  a: DisplayInvoiceLineObject,
                  b: DisplayInvoiceLineObject
                ) => {
                  return (a.openAmount || 0) - (b.openAmount || 0);
                }
              }
            },
            render: val => displayUSCurrency(val)
          }
        ],
        [
          "matchedPromLine",
          {
            name: "Promotion Line",
            align: "left",
            width: "100%",
            sorting: {
              enabled: true
            },
            render: (_val, displayInvoiceLine) => (
              <>
                {displayInvoiceLine.matchedPromLine}
                {displayInvoiceLine.matchedPromLine && (
                  <ActionButton
                    aria-label="view prom line"
                    size="small"
                    tooltip="View Promotion Line"
                    onClick={e => {
                      const promKey =
                        InvoiceDisplayService.getPromKeyFromMatchedPromLine(
                          displayInvoiceLine.matchedPromLine ?? ""
                        );
                      showRightDrawer(
                        <PromProfile
                          promKey={promKey}
                          defaultLine={displayInvoiceLine.matchedPromLine}
                          openClose={openClose}
                          db={db}
                          readOnly
                        />
                      );
                      e.stopPropagation();
                    }}>
                    <Visibility />
                  </ActionButton>
                )}
              </>
            )
          }
        ],
        [
          "fundType",
          {
            name: "Fund Type",
            align: "left",
            sorting: {
              enabled: true
            }
          }
        ],
        [
          "displayStatus",
          {
            name: "Status",
            align: "left",
            sorting: {
              enabled: true
            }
          }
        ],
        [
          "actions",
          {
            name: "Actions",
            align: "center",
            render: (_val, displayInvoiceLine) => (
              <ActionButton
                aria-label="Resolve invoice lince"
                size="small"
                tooltip={
                  displayInvoiceLine.status === invoiceLineStatuses.PENDING
                    ? "Cannot resolve pending invoice lines"
                    : "Resolve Invoice Line"
                }
                disabled={
                  displayInvoiceLine.status === invoiceLineStatuses.PENDING
                }
                onClick={e => {
                  e.stopPropagation();
                  showRightDrawer(
                    <ResolveInvoiceLine
                      invoiceLineKey={displayInvoiceLine.key}
                      invoiceKey={displayInvoice.key}
                      transactionKey={displayTransaction.key}
                    />
                  );
                }}>
                <Icon>grading</Icon>
              </ActionButton>
            )
          }
        ]
      ] as [keyof DisplayInvoiceLineObject, ColumnConfigOptions<DisplayInvoiceLineObject>][]),
    [
      db,
      dispatch,
      displayInvoice.key,
      displayTransaction.key,
      getHeaderContent,
      invoiceKey,
      openClose,
      selectedInvoiceLines,
      showRightDrawer
    ]
  );
  return (
    <>
      <Typography gutterBottom variant="subtitle1">
        Invoice Lines ({allInvoiceLines.length})
      </Typography>
      <LinkedInvoiceLinesTableControls
        invoice={invoice}
        transaction={transaction}
        displayInvoice={displayInvoice}
        displayTransaction={displayTransaction}
        displayInvoiceLines={sortedDisplayLines.map(
          entry => entry.displayInvoiceLine
        )}
      />
      <CollapsibleTable<DisplayInvoiceLineObject>
        maxHeight="360px"
        data={sortedDisplayLines.map(entry => entry.displayInvoiceLine)}
        columnConfig={columnConfig}
        index="key"
        onRowClick={() => {}}
        pagination={{
          enabled: paginationEnabled,
          pageSize: DEFAULT_PAGE_SIZE,
          rowsPerPageOptions: [DEFAULT_PAGE_SIZE, 20, 50]
        }}
        sortState={sortState}
        setSortState={setSortState}
      />
    </>
  );
}
