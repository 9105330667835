/* eslint-disable react/jsx-props-no-spreading */
import React, { Fragment, useCallback, useMemo, useState } from "react";
import {
  useHistory,
  Switch,
  Route,
  useRouteMatch,
  Redirect
} from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { RootState } from "store";
import ScrollableColumns from "ui-library/ScrollableColumns";
import UploadBackup from "components/Deductions/DeductionsReconciliation/UploadFiles/UploadBackup";
import { InvoiceObject } from "components/Deductions/models";
import { invoiceStatuses } from "components/Deductions/constants/ReconciliationStatuses";
import { isUserCresicorEmployee } from "helpers/Firebase";
import { displayUSCurrency } from "helpers/DataProcessing";
import { useDb } from "contexts/Db";
import { useBackupSources } from "components/Deductions/constants/BackupSources";
import { useOpenClose } from "contexts/OpenClose";
import moment from "moment";
import { InvoiceDisplayService } from "reconciliation-services";
import {
  DisplayInvoiceObject,
  invoiceFilters
} from "components/Deductions/DeductionsReconciliation/types/invoiceTypes";
import {
  setBackupActiveFilters,
  setBackupSorting
} from "components/Deductions/DeductionsReconciliation/redux/BackupSlice";
import { invoiceUploadSources } from "components/Deductions/constants/ReconciliationTypes";
import { OpenInNew } from "@mui/icons-material";
import { CollapsibleTableRowControls } from "ui-library/CollapsibleTable/CollapsibleTableRow";
import {
  SummaryConfigOptions,
  CollapsibleTableSummaryType
} from "ui-library/CollapsibleTable/CollapsibleTableSummary";
import { renderUTCDateTimeInLocalTimeZone } from "helpers/Time";
import ViewBackupToolbarActions from "./BackupToolbarActions";
import BackupCard from "./BackupCard";
import ViewTable from "../ViewTable";
import BackupApprovalToggle from "./BackupApprovalToggle";
import CancelInvoiceButton from "./CancelInvoiceButton";

const PAGE_LENGTH = 20;

export default function ViewBackup(props: { outOfDate: boolean }) {
  const isCresicorEmployee = isUserCresicorEmployee();
  const [currentInvoiceFilter, setInvoiceFilter] = useState<invoiceFilters>(
    isCresicorEmployee
      ? invoiceFilters.ACTIVE_ALL
      : invoiceFilters.ACTIVE_APPROVED
  );

  const { path } = useRouteMatch();
  const history = useHistory();
  const db = useDb();
  const { allBackupSources: backupSources } = useBackupSources();
  const {
    permissions = [],
    erpTransactions = {},
    customers = {},
    companyUsers = {}
  } = db;
  const canCancelInvoices = permissions.includes("cancelInvoicesTransactions");
  const openClose = useOpenClose();
  const dispatch = useDispatch();

  const { drmFeatureState, activeFilters, sortState } = useSelector(
    (state: RootState) => ({
      drmFeatureState: state.drm.drmFeatureState,
      activeFilters: state.backupTable.activeFilters,
      sortState: state.backupTable.sortState
    }),
    shallowEqual
  );

  const handleProfileOpen = useCallback(
    (_event, rowData: DisplayInvoiceObject) => {
      history.push(`/${drmFeatureState}/invoice/${rowData.key}`);
    },
    [history, drmFeatureState]
  );

  const invoices: DisplayInvoiceObject[] = useMemo(
    () =>
      Object.values(db.invoices as Record<string, InvoiceObject>)
        .filter(invoice => {
          switch (currentInvoiceFilter) {
            case invoiceFilters.ACTIVE_ALL: {
              return invoice.status !== invoiceStatuses.CANCELLED;
            }
            case invoiceFilters.ACTIVE_APPROVED: {
              return (
                invoice.status !== invoiceStatuses.CANCELLED &&
                !invoice.needsCresicorApproval
              );
            }
            case invoiceFilters.ACTIVE_UNAPPROVED: {
              return (
                invoice.status !== invoiceStatuses.CANCELLED &&
                invoice.needsCresicorApproval
              );
            }
            case invoiceFilters.INACTIVE_ALL: {
              return invoice.status === invoiceStatuses.CANCELLED;
            }
            case invoiceFilters.INACTIVE_APPROVED: {
              return (
                invoice.status === invoiceStatuses.CANCELLED &&
                !invoice.needsCresicorApproval
              );
            }
            case invoiceFilters.INACTIVE_UNAPPROVED: {
              return (
                invoice.status === invoiceStatuses.CANCELLED &&
                invoice.needsCresicorApproval
              );
            }
            default: {
              return false;
            }
          }
        })
        .map(invoice =>
          InvoiceDisplayService.processInvoiceForDisplay(
            invoice,
            erpTransactions,
            customers,
            companyUsers
          )
        ),
    [
      db.invoices,
      currentInvoiceFilter,
      erpTransactions,
      customers,
      companyUsers
    ]
  );

  const columns = useMemo(() => {
    return Object.keys(invoiceStatuses)
      .map(status => {
        const data = invoices.filter(
          invoice => invoice.status === invoiceStatuses[status]
        );
        return {
          name: InvoiceDisplayService.HumanReadableInvoiceStatuses[
            invoiceStatuses[status]
          ],
          data
        };
      })
      .filter(status => {
        return [
          invoiceFilters.INACTIVE_ALL,
          invoiceFilters.INACTIVE_APPROVED,
          invoiceFilters.INACTIVE_UNAPPROVED
        ].includes(currentInvoiceFilter)
          ? true
          : status.name !==
              InvoiceDisplayService.HumanReadableInvoiceStatuses[
                invoiceStatuses.CANCELLED
              ];
      });
  }, [invoices, currentInvoiceFilter]);

  const internalColumns = useMemo(
    () =>
      isCresicorEmployee
        ? {
            needsCresicorApproval: {
              label: "Approved",
              render: (_, rowData) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                <div onClick={e => e.stopPropagation()}>
                  <BackupApprovalToggle
                    hideLabel
                    invoice={db.invoices[rowData.key]}
                    permissions={db.permissions}
                  />
                </div>
              ),
              sorting: {
                enabled: true,
                customSort: (a, b) =>
                  // eslint-disable-next-line no-nested-ternary
                  a.needsCresicorApproval === b.needsCresicorApproval
                    ? 0
                    : a.needsCresicorApproval
                    ? -1
                    : 1
              },
              width: "10%"
            }
          }
        : undefined,
    [db.permissions, db.invoices, isCresicorEmployee]
  );

  const summaryConfig = useMemo(
    () =>
      new Map([
        [
          "key",
          {
            label: "# Invoices",
            type: CollapsibleTableSummaryType.COUNT
          }
        ],
        [
          "amount",
          {
            label: "Total Original Amount",
            type: CollapsibleTableSummaryType.SUM_WITHOUT_NULL,
            render: val => displayUSCurrency(val)
          }
        ],
        [
          "openAmount",
          {
            label: "Total Open Amount",
            type: CollapsibleTableSummaryType.SUM_WITHOUT_NULL,
            render: val => displayUSCurrency(val)
          }
        ]
      ] as [keyof DisplayInvoiceObject, SummaryConfigOptions<DisplayInvoiceObject>][]),
    []
  );

  const rowControls = (
    [
      {
        label: "Open Profile",
        icon: <OpenInNew color="action" />,
        onClick: (e, rowData) => handleProfileOpen(e, rowData)
      }
    ] as CollapsibleTableRowControls<DisplayInvoiceObject>[]
  ).concat(
    canCancelInvoices
      ? ([
          {
            label: "Cancel Invoice",
            render: (row: DisplayInvoiceObject) => (
              <CancelInvoiceButton
                invoice={row}
                iconButton
                isInvoiceCancelled={row.status === invoiceStatuses.CANCELLED}
              />
            )
          }
        ] as CollapsibleTableRowControls<DisplayInvoiceObject>[])
      : []
  );

  return (
    <div>
      <Switch>
        <Route exact path={`/${drmFeatureState}/invoices`}>
          <Redirect to={`/${drmFeatureState}/invoices/list`} />
        </Route>
        <Route exact path={`/${drmFeatureState}/invoices/upload`}>
          <UploadBackup outOfDate={props.outOfDate} />
        </Route>
        <Route>
          <ViewBackupToolbarActions
            setInvoiceFilter={setInvoiceFilter}
            currentInvoiceFilter={currentInvoiceFilter}
          />
          <Switch>
            <Route path={`${path}/board`}>
              <ScrollableColumns<DisplayInvoiceObject>
                columns={columns}
                renderCard={(
                  key: string,
                  index: number,
                  data: DisplayInvoiceObject[]
                ): JSX.Element => (
                  <Fragment key={key}>
                    <BackupCard
                      interactive
                      openClose={openClose}
                      invoice={db.invoices[data[key].key]}
                      displayInvoice={data[key]}
                      handleProfileOpen={handleProfileOpen}
                    />
                  </Fragment>
                )}
              />
            </Route>
            <Route path={`${path}/list`}>
              <ViewTable<DisplayInvoiceObject>
                data={invoices}
                pageLength={PAGE_LENGTH}
                fontSize="0.75rem"
                onRowClick={handleProfileOpen}
                rowControls={rowControls}
                summaryConfig={summaryConfig}
                summaryStyles={{ leftIndent: true }}
                columnsConfig={{
                  ...internalColumns,
                  invoiceNumber: {
                    label: "Invoice #",
                    sorting: {
                      enabled: true
                    },
                    filter: {
                      enabled: true
                    }
                  },
                  customerName: {
                    label: "Customer Name",
                    sorting: {
                      enabled: true
                    },
                    filter: {
                      enabled: true
                    }
                  },
                  backupSource: {
                    label: "Backup Source",
                    sorting: {
                      enabled: true
                    },
                    filter: {
                      enabled: true,
                      textFilterOverride: (
                        value: string,
                        rowData: DisplayInvoiceObject
                      ) => {
                        const displayBackupSource: string =
                          backupSources[rowData.backupSource ?? ""]
                            ?.DISPLAY_NAME ?? "None";
                        return displayBackupSource
                          .toLowerCase()
                          .includes(value.toLowerCase());
                      }
                    },
                    render: (_, rowData) =>
                      backupSources[rowData.backupSource ?? ""]
                        ?.DISPLAY_NAME ?? <i>None</i>
                  },
                  amount: {
                    label: "Original Amount",
                    filter: {
                      enabled: true,
                      range: {
                        min: Math.min(
                          ...invoices.map(invoice => invoice.amount ?? 0)
                        ),
                        max: Math.max(
                          ...invoices.map(invoice => invoice.amount ?? 100)
                        )
                      }
                    },
                    sorting: {
                      enabled: true,
                      customSort: (
                        a: DisplayInvoiceObject,
                        b: DisplayInvoiceObject
                      ) => {
                        return (a.amount || 0) - (b.amount || 0);
                      }
                    },
                    render: (_, rowData) => displayUSCurrency(rowData.amount)
                  },
                  openAmount: {
                    label: "Open Amount",
                    filter: {
                      enabled: true,
                      range: {
                        min: Math.min(
                          ...invoices.map(invoice => invoice.openAmount ?? 0)
                        ),
                        max: Math.max(
                          ...invoices.map(invoice => invoice.openAmount ?? 100)
                        )
                      }
                    },
                    sorting: {
                      enabled: true,
                      customSort: (
                        a: DisplayInvoiceObject,
                        b: DisplayInvoiceObject
                      ) => {
                        return (a.openAmount || 0) - (b.openAmount || 0);
                      }
                    },
                    render: (_, rowData) =>
                      displayUSCurrency(rowData.openAmount)
                  },
                  createdDate: {
                    label: "Date Imported",
                    render: (_, rowData) =>
                      rowData.createdDate
                        ? renderUTCDateTimeInLocalTimeZone(rowData.createdDate)
                        : "",
                    sorting: {
                      enabled: true,
                      customSort: (
                        a: DisplayInvoiceObject,
                        b: DisplayInvoiceObject
                      ) =>
                        moment
                          .utc(a.createdDate)
                          .diff(moment.utc(b.createdDate))
                    },
                    filter: {
                      enabled: true,
                      daterange: {
                        min: new Date(),
                        max: new Date(),
                        minLabel: "Start",
                        maxLabel: "End"
                      }
                    }
                  },
                  checkNumber: {
                    label: "Check Number",
                    filter: {
                      enabled: true
                    },
                    sorting: {
                      enabled: true
                    }
                  },
                  displayAssignedUsers: {
                    label: "Assigned To",
                    filter: {
                      enabled: true
                    },
                    sorting: {
                      enabled: true
                    }
                  },
                  status: {
                    label: "Status",
                    sorting: {
                      enabled: true
                    },
                    filter: {
                      enabled: true,
                      textFilterOverride: (
                        value: string,
                        rowData: DisplayInvoiceObject
                      ) => {
                        const displayStatus: string =
                          InvoiceDisplayService.HumanReadableInvoiceStatuses[
                            rowData.status
                          ];
                        return displayStatus
                          .toLowerCase()
                          .includes(value.toLowerCase());
                      }
                    },
                    render: (_, rowData) =>
                      InvoiceDisplayService.HumanReadableInvoiceStatuses[
                        rowData.status
                      ]
                  },
                  type: {
                    label: "Type",
                    sorting: {
                      enabled: true
                    },
                    filter: {
                      enabled: true,
                      textFilterOverride: (
                        value: string,
                        rowData: DisplayInvoiceObject
                      ) => {
                        const displayType: string =
                          InvoiceDisplayService.HumanReadableInvoiceTypes[
                            rowData.type
                          ];
                        return displayType
                          .toLowerCase()
                          .includes(value.toLowerCase());
                      }
                    },
                    render: (_, rowData) =>
                      InvoiceDisplayService.HumanReadableInvoiceTypes[
                        rowData.type
                      ]
                  },
                  uploadSource: {
                    label: "Source",
                    sorting: {
                      enabled: true
                    },
                    filter: {
                      enabled: true
                    },
                    render: (_, rowData) =>
                      InvoiceDisplayService.HumanReadableInvoiceUploadSources[
                        rowData.uploadSource || invoiceUploadSources.MANUAL
                      ]
                  }
                }}
                activeFilters={activeFilters}
                sortState={sortState}
                setActiveFilters={payload =>
                  dispatch(setBackupActiveFilters(payload))
                }
                setSortState={payload => dispatch(setBackupSorting(payload))}
                resetToDefaults={() => {
                  dispatch(
                    setBackupSorting({
                      orderBy: "createdDate",
                      sortDir: "asc"
                    })
                  );
                }}
              />
            </Route>
          </Switch>
        </Route>
      </Switch>
    </div>
  );
}
