/* eslint-disable import/no-duplicates */
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/messaging";
import "firebase/functions";
import "firebase/storage";
import "firebase/performance";
import {
  projectId,
  cloudRunURL,
  cloudRunFunctionURL,
  forecastCloudRunURL,
  mainFirebaseApp,
  forecastFirebaseApp
} from "config";

firebase.initializeApp(mainFirebaseApp);
firebase.initializeApp(mainFirebaseApp, "detachedAuth");
firebase.initializeApp(forecastFirebaseApp, "forecast");

export { projectId, cloudRunURL, cloudRunFunctionURL, forecastCloudRunURL };
export default firebase;
