import React, { useMemo, useRef } from "react";
import { CSVLink } from "react-csv";
import FilterListIcon from "@mui/icons-material/FilterList";
import IconButton from "ui-library/IconButton";
import IconMenu from "ui-library/IconMenu";
import MenuItem from "ui-library/MenuItem";
import DownloadIcon from "@mui/icons-material/GetApp";
import PublishIcon from "@mui/icons-material/Publish";
import AddBoxIcon from "@mui/icons-material//AddBox";
import { makeStyles } from "@mui/styles";
import { firebase } from "helpers/Firebase";
import { useDb } from "contexts/Db";
import UploadFiles from "components/UploadFiles/UploadFiles";
import { useOpenClose } from "contexts/OpenClose";
import {
  TransactionDisplayService,
  ResolutionLineDisplayService
} from "reconciliation-services";
import { transactionFilters } from "components/Deductions/DeductionsReconciliation/types/transactionTypes";
import { infoDialog } from "helpers/OpenDialog";
import { displayUSCurrency } from "helpers/DataProcessing";
import { exportFiles } from "helpers/Export";
import { useSnackbar } from "notistack";
import AddTransaction from "components/Deductions/DeductionsReconciliation/UploadFiles/AddTransaction/AddTransaction";
import AddTransactionStepButtons from "components/Deductions/DeductionsReconciliation/UploadFiles/AddTransaction/AddTransactionStepButtons";
import moment from "moment";

import { useDispatch } from "react-redux";
import ViewGuiControls from "../ViewGuiControls";
import ActivityLogButton from "../../ActivityLog/ActivityLogButton";
import { findRepaymentLines } from "../../services/RepaymentServices/repaymentLineFilter";
import { HumanReadableRepaymentLineTypes } from "../../services/RepaymentServices/repaymentLineDisplay";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "5px",
    paddingBottom: "5px"
  },
  buttonContainer: { display: "flex" }
}));

interface CsvDownloader {
  link: {
    click: () => void;
  };
}

interface SettlementReportEntry {
  "Date Imported": string;
  "Invoice Number": string;
  "Customer Name": string;
  "Customer Number"?: string;
  "Amount Cleared": string;
  "Date Cleared": string;
  "Promotion Line ID": string;
  "Transaction ID": string;
  "Transaction Type": string;
  "Fund Type": string;
  "GL Number": string;
  "Cleared By": string;
  Comment: string;
}

export interface TransactionToolbarActionsProps {
  currentTransactionFilter: transactionFilters;
  setTransactionFilter: (x: transactionFilters) => void;
}

export default function TransactionToolbarActions(
  props: TransactionToolbarActionsProps
) {
  const classes = useStyles();
  const { currentTransactionFilter, setTransactionFilter } = props;
  const openClose = useOpenClose();

  const db = useDb();
  const user = firebase.auth().currentUser;
  const {
    companyUsers,
    customers,
    erpTransactions,
    invoices,
    meta,
    accounts = {}
  } = db;
  const { accounting_sources, fundTypes = {} } = meta;
  const csvLink = useRef<CsvDownloader | undefined>();
  const settlementCsvLink = useRef<CsvDownloader | undefined>();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const deductionSettlementLines: SettlementReportEntry[] = useMemo(() => {
    const resolutionLines = Object.values(invoices)
      .map(invoice => Object.values(invoice.resolutionLines || {}))
      .reduce((a, b) => a.concat(b), [])
      // only include resolution lines with a closed date
      .filter(line => !!line.closedDate);

    return resolutionLines.map(line => {
      // TODO: Due to COR-460 fund type refactor, we will need to use meta.fundTypes instead of meta.types when we bring this code back.
      /*
      the allLines type field is actually
      the VALUE of the db.meta.types
      record, not the KEY, due to a bug.
      this will eventually be fixed, but
      for now we assume this is always the case
      */
      // const fundValue =
      //   db.meta.types[db.allLines[line.promLine || ""]?.type] || "";
      // const fundKey = Object.entries(db.meta.types)
      //   .filter(([_key, value]) => value === fundValue)
      //   .map(([key, _value]) => key)[0];
      // const fundType = db.meta.types[fundKey];
      // const glNumber = db.meta.type_accounts[fundKey];

      // now resolution lines are storing the fund type and account itself!
      const clearedByUserName = companyUsers[line.closedUser || ""]?.name ?? "";
      return {
        "Activity Type":
          ResolutionLineDisplayService.HumanReadableResolutionLineTypes[
            line.type
          ],
        "Date Imported": moment
          .utc(erpTransactions[line.transactionKey].createdDate)
          .format("MM/DD/YYYY"),
        "Transaction ID":
          erpTransactions[line.transactionKey].transactionDisplayId ?? "",
        "Transaction Type":
          TransactionDisplayService.HumanReadableTransactionTypes[
            erpTransactions[line.transactionKey].type
          ] ?? "",
        "Invoice Number": invoices[line.invoiceKey].invoiceNumber,
        "Customer Name": customers[invoices[line.invoiceKey].customerKey].name,
        "Customer Number": "", // TODO: manual for now
        "Amount Cleared": displayUSCurrency(line.amount || 0.0, false, false),
        "Date Cleared": line.closedDate
          ? moment.utc(line.closedDate).format("MM/DD/YYYY")
          : "",
        "Promotion Line ID": line.promLine ?? "",
        "Fund Type": fundTypes[line.fundTypeKey || ""]?.name || "",
        "GL Number": accounts[line.fundTypeAccount || ""]?.name || "",
        "Cleared By": clearedByUserName,
        Comment: line.closedComment
      };
    });
  }, [companyUsers, customers, erpTransactions, invoices, fundTypes, accounts]);

  const repaymentSettlementLines: SettlementReportEntry[] = useMemo(() => {
    const repaymentLines = findRepaymentLines(db);
    return repaymentLines
      .map(line => {
        return [
          {
            "Activity Type": HumanReadableRepaymentLineTypes[line.type],
            "Date Imported": moment
              .utc(erpTransactions[line.repaymentKey].createdDate)
              .format("MM/DD/YYYY"),
            "Transaction ID":
              erpTransactions[line.repaymentKey].transactionDisplayId ?? "",
            "Transaction Type":
              TransactionDisplayService.HumanReadableTransactionTypes[
                erpTransactions[line.repaymentKey].type
              ] ?? "",
            "Invoice Number":
              invoices[line.invoiceKey || ""].invoiceNumber ?? "",
            "Customer Name":
              customers[invoices[line.invoiceKey || ""].customerKey].name ?? "",
            "Customer Number": "", // TODO: manual for now
            "Amount Cleared": displayUSCurrency(
              -line.amount || 0.0,
              false,
              false
            ),
            "Date Cleared": moment.utc(line.createdDate).format("MM/DD/YYYY"),
            "Promotion Line ID": "",
            "Fund Type": fundTypes[line.fundTypeKey || ""]?.name || "",
            "GL Number": accounts[line.fundTypeAccount || ""]?.name || "",
            "Cleared By": companyUsers[line.createdUser || ""].name,
            Comment: ""
          },
          {
            "Activity Type": HumanReadableRepaymentLineTypes[line.type],
            "Date Imported": moment
              .utc(erpTransactions[line.deductionKey].createdDate)
              .format("MM/DD/YYYY"),
            "Transaction ID":
              erpTransactions[line.deductionKey].transactionDisplayId ?? "",
            "Transaction Type":
              TransactionDisplayService.HumanReadableTransactionTypes[
                erpTransactions[line.deductionKey].type
              ] ?? "",
            "Invoice Number":
              invoices[line.invoiceKey || ""].invoiceNumber ?? "",
            "Customer Name":
              customers[invoices[line.invoiceKey || ""].customerKey].name ?? "",
            "Customer Number": "", // TODO: manual for now
            "Amount Cleared": displayUSCurrency(
              line.amount || 0.0,
              false,
              false
            ),
            "Date Cleared": moment.utc(line.createdDate).format("MM/DD/YYYY"),
            "Promotion Line ID": "",
            "Fund Type": fundTypes[line.fundTypeKey || ""]?.name || "",
            "GL Number": accounts[line.fundTypeAccount || ""]?.name || "",
            "Cleared By": companyUsers[line.createdUser || ""].name,
            Comment: ""
          }
        ];
      })
      .reduce((prevLines, lines) => [...prevLines, ...lines], []);
  }, [
    companyUsers,
    customers,
    db,
    erpTransactions,
    invoices,
    fundTypes,
    accounts
  ]);

  const settlementLines = [
    ...deductionSettlementLines,
    ...repaymentSettlementLines
  ];

  const downloadSettlementReport = (): void => {
    if (settlementLines?.length) {
      settlementCsvLink.current?.link.click();
    } else {
      infoDialog(
        openClose,
        "No Resolution Lines Found",
        "Unable to generate settlement report - no resolution lines were found.",
        "Go Back"
      );
    }
  };

  return (
    <div className={classes.container}>
      <ViewGuiControls />
      <div className={classes.buttonContainer}>
        <ActivityLogButton
          filters={{ allTransactions: true }}
          title="All Transaction Activity"
          iconButton
        />
        <IconMenu
          iconButtonElement={
            <IconButton tooltip="Filter Transactions" size="large">
              <FilterListIcon />
            </IconButton>
          }
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
          targetOrigin={{ horizontal: "right", vertical: "top" }}>
          {Object.values(transactionFilters).map(option => (
            <MenuItem
              checked={currentTransactionFilter === option}
              value={option}
              key={option}
              onClick={() => {
                setTransactionFilter(option);
              }}>
              {option}
            </MenuItem>
          ))}
        </IconMenu>
        <IconButton
          tooltip="Upload Transactions"
          onClick={() =>
            openClose.showDropDownWindow(
              <UploadFiles
                selectedDataType="ERPTransactions"
                openClose={openClose}
                usePresets
                // eslint-disable-next-line camelcase
                revenueSources={accounting_sources}
                extraArgs={{ uid: firebase.auth().currentUser?.uid || "" }}
              />
            )
          }
          size="large">
          <PublishIcon />
        </IconButton>
        <IconButton
          tooltip="Add New Transaction"
          onClick={() =>
            openClose.setAppModal(
              "Add New Transaction",
              <AddTransaction />,
              <AddTransactionStepButtons />,
              true,
              false,
              "lg"
            )
          }
          size="large">
          <AddBoxIcon />
        </IconButton>

        <IconMenu
          iconButtonElement={
            <IconButton tooltip="Download" size="large">
              <DownloadIcon />
            </IconButton>
          }
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
          targetOrigin={{ horizontal: "right", vertical: "top" }}>
          {/* <MenuItem
            value={0}
            leftIcon={<DownloadIcon />}
            onClick={() => csvLink.current?.link.click()}>
            Download ERP Transactions
          </MenuItem> */}
          <MenuItem
            value={1}
            leftIcon={<DownloadIcon />}
            onClick={() =>
              exportFiles(
                user,
                db.companyid,
                "Open Deductions Report",
                "cresicor",
                null,
                null,
                enqueueSnackbar
              )
            }>
            Download Open Deductions Report
          </MenuItem>
          <MenuItem
            value={1}
            leftIcon={<DownloadIcon />}
            onClick={downloadSettlementReport}>
            Download Settlement Report
          </MenuItem>
        </IconMenu>
        <CSVLink
          data={Object.values(erpTransactions)}
          filename="transactions.csv"
          className="hidden"
          ref={csvLink}
          target="_blank"
        />
        <CSVLink
          data={settlementLines}
          filename="settlement_report.csv"
          className="hidden"
          ref={settlementCsvLink}
          target="_blank"
        />
      </div>
    </div>
  );
}
