import { CompanyUser } from "js/dbTypes";

export enum DRMEventType {
  // TRANSACTION
  TRANSACTION_CLEARED = "Transaction Cleared",
  FLAG_DISPUTE = "Flag Dispute",
  WRITE_OFF = "Writeoff",
  TRANSACTION_CANCELLED = "Transaction Cancelled",
  INVOICE_LINKED = "Invoice Linked",
  INVOICE_UNLINKED = "Invoice Unlinked",
  SET_SALES_REVIEW = "Set Sales Review", // notification should be sent to sales people
  TRANSACTION_ASSIGNED = "Transaction Assigned",
  TRANSACTION_UNASSIGNED = "Transaction Unassigned",

  // TODO: Not Yet Integrated
  TRANSACTION_ADDED = "Transaction Added",

  // INVOICE
  INVOICE_CANCELLED = "Invoice Cancelled",
  INVOICE_LINE_PROM_LINE_AUTO_MATCH_ADDED = "Invoice Line <> Promotion Line Auto Match Added", // i.e. 25 lines matched with Auto Matchin, // HITTING AUTO MATCH FOR THE FIRST TIME

  /*
   * PER MINNIE: for simplicity in v1, in automatcher dont distinguish between updated & added
   * INVOICE_LINE_PROM_LINE_AUTO_MATCH_UPDATED = "Invoice Line <> Promotion Line Auto Match Updated", // i.e. 25 lines matched with Auto Matchin, // HITTING AUTO MATCH FOR THE FIRST TIME
   */

  INVOICE_LINE_PROM_LINE_MANUAL_MATCH_ADDED = "Invoice Line <> Promotion Line Manual Match Added", // clicking on the edit icon and adding a match for the first time
  INVOICE_LINE_PROM_LINE_UNMATCHED = "Invoice Unmatched",

  INVOICE_LINES_SAVED = "Invoice Lines Saved",
  INVOICE_LINE_CLEARED = "Invoice Line Cleared",
  INVOICE_CREATED = "Invoice Created",
  INVOICE_ASSIGNED = "Invoice Assigned",
  INVOICE_UNASSIGNED = "Invoice Unassigned",
  INVOICE_LINE_DELETED = "Invoice Line Deleted",
  ALL_INVOICE_LINES_DELETED = "All Invoice Lines Deleted",

  // TODO: Not Yet Integrated
  // INVOICE_LINE_PROM_LINE_MANUAL_MATCH_UPDATED = "Invoice Line <> Promotion Line Manual Match Updated",

  // Comment Events
  NEW_COMMENT_ADDED = "Comment Added",

  // Repayment Events
  REPAYMENT_APPLIED = "Repayment Applied",
  CREDIT_WRITE_OFF_APPLIED = "Credit Write-Off Applied",

  REPAYMENT_ATTACHED = "Repayment Attached",
  REPAYMENT_UNATTACHED = "Repayment Unattached"
}

export interface DRMEvent {
  /**
   * an optional, user-created comment tied to the event
   */
  comment: string;

  /**
   * The enum type of the event
   */
  type: DRMEventType;

  /**
   * a generic record for holding data tied to the event
   */
  metadata?: Record<string, undefined | string | number>;

  key: string;
  userId: string;
  invoiceKey?: string;
  invoiceLineKey?: string;
  transactionKey?: string;
  repaidTransactionKey?: string;
  createDate: string;
  targetUserId: string;
  new?: boolean;
}

export interface DisplayDRMEvent extends DRMEvent {
  user: CompanyUser;
  displayDetails: boolean;
}
