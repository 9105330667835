import React from "react";

import {
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

import Card from "ui-library/Card";

import { displayMoney } from "helpers/DataProcessing";
import { graphPalette } from "helpers/ColorPalettes";

const { blue70, purple70 } = graphPalette;

class CustomTooltip extends React.Component {
  render() {
    const { active } = this.props;
    if (active) {
      const { payload, label, notMoney } = this.props;
      return (
        <div className="custom-tooltip">
          <p className="desc">{label}</p>
          {payload.map(pl => {
            return (
              <p className="desc" style={{ color: pl.color }} key={pl.name}>
                {`${pl.name}: ${
                  notMoney ? pl.value.toLocaleString() : displayMoney(pl.value)
                }`}
              </p>
            );
          })}
        </div>
      );
    }

    return null;
  }
}

export default class ActVsExpPast extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Card title={this.props.title ?? "Actual vs. Expected Time Series"}>
        {this.props.data && this.props.data.length > 0 ? (
          <ResponsiveContainer width="100%" aspect={2}>
            <ComposedChart
              width={650}
              height={400}
              data={this.props.data}
              margin={{ top: 20, right: 0, bottom: 0, left: 0 }}>
              <XAxis dataKey="date" />
              <YAxis yAxisId="left" orientation="left" />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip
                content={<CustomTooltip notMoney={this.props.notMoney} />}
              />
              <Legend />
              <Line
                yAxisId="left"
                dataKey="Actual"
                type="monotone"
                stroke={blue70}
                strokeWidth={2}
              />
              <Line
                type="monotone"
                dataKey="Expected"
                yAxisId="left"
                stroke={purple70}
                strokeWidth={2}
              />
            </ComposedChart>
          </ResponsiveContainer>
        ) : (
          <div className="centering" style={{ margin: 16 }}>
            No data available.
          </div>
        )}
      </Card>
    );
  }
}
