import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import SettingsIcon from "@mui/icons-material/Settings";
import DownloadIcon from "@mui/icons-material/GetApp";
import Checkbox from "ui-library/Checkbox";
import IconMenu from "ui-library/IconMenu";
import IconButton from "ui-library/IconButton";
import MenuItem from "ui-library/MenuItem";
import makeStyles from "@mui/styles/makeStyles";

import { DataGridPro } from "@mui/x-data-grid-pro";
import { columns } from "./DSMEngagementTrackerColumnConfig";
import {
  getDsmEngagementDownload,
  getDsmTrialEngagementInfo
} from "./DSMEngagementTrackerUtilities";

const useStyles = makeStyles(theme => ({
  superheader: {
    fontFamily: "Ubuntu",
    marginTop: 20,
    padding: 0,
    color: theme.palette.primary.main
  },
  subheader: {
    fontSize: 20,
    color: theme.palette.primary.main
  },
  settingsButton: {
    float: "right",
    marginTop: "-36px"
  },
  displayExpired: {
    float: "right",
    marginTop: "-32px"
  },
  dataGrid: {
    height: "80vh",
    width: "100%"
  }
}));

const DsmEngagementTracker = () => {
  const classes = useStyles();

  const [dsmEngagementScanning, setDsmEngagementScanning] = useState([]);
  const [trialData, setTrialData] = useState([]);
  const [expiredTrialData, setExpiredTrialData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [displayExpired, setDisplayExpired] = useState(false);

  useEffect(() => {
    getDsmTrialEngagementInfo().then(response => {
      setDsmEngagementScanning(response);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    setTrialData(dsmEngagementScanning.filter(entry => !entry.isExpired));
    setExpiredTrialData(dsmEngagementScanning.filter(entry => entry.isExpired));
  }, [dsmEngagementScanning]);

  return (
    <>
      <Typography className={classes.superheader} variant="h4">
        DSM Engagement Tracker
      </Typography>
      <div className={classes.settingsButton}>
        <IconMenu
          iconButtonElement={
            <IconButton tooltip="Settings" size="large">
              <SettingsIcon />
            </IconButton>
          }>
          <MenuItem
            value={0}
            leftIcon={<DownloadIcon />}
            onClick={() => getDsmEngagementDownload(dsmEngagementScanning)}
            disabled={Object.keys(dsmEngagementScanning).length === 0}>
            Download Report
          </MenuItem>
        </IconMenu>
      </div>
      <div className={classes.displayExpired}>
        <Checkbox
          color="primary"
          label="Show Only Expired?"
          onChange={e => setDisplayExpired(e.target.checked)}
          checked={displayExpired}
        />
      </div>
      {!displayExpired ? (
        <>
          <Typography variant="h6" className={classes.subheader}>
            Current Free Trial Companies
          </Typography>
          <div className={classes.dataGrid}>
            <DataGridPro
              rows={trialData}
              columns={columns}
              pageSize={10}
              loading={loading}
            />
          </div>
        </>
      ) : (
        <>
          <Typography variant="h6" className={classes.subheader}>
            Expired Free Trial Companies
          </Typography>
          <div className={classes.dataGrid}>
            <DataGridPro
              rows={expiredTrialData}
              columns={columns}
              pageSize={10}
              loading={loading}
            />
          </div>
        </>
      )}
    </>
  );
};

export default DsmEngagementTracker;
