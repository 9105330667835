import React, { useCallback, useEffect, useMemo, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import IconButton from "ui-library/IconButton";
import {
  Button,
  Popover,
  Slider,
  TextField,
  Tooltip,
  Box
} from "@mui/material";
import Paper from "@mui/material/Paper";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { DisplayInvoiceObject } from "components/Deductions/DeductionsReconciliation/types/invoiceTypes";
import DateRangePicker, { DateRange } from "@mui/lab/DateRangePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { useDebouncedCallback } from "use-debounce";
import { displayUSCurrency } from "../../helpers/DataProcessing";
import { FilterRecord } from "./CollapsibleTable";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(1)
  },
  filterIcon: {
    color: theme.palette.primary.main
  },
  flex: {
    display: "flex"
  },
  nowrap: {
    whiteSpace: "nowrap"
  },
  sliderContainer: { width: "auto", padding: theme.spacing(1) }
}));

export interface FilterConfig {
  enabled: boolean;
  tooltip?: string;
  placeholder?: string;
  textFilterOverride?: (
    value: string,
    rowData: DisplayInvoiceObject
  ) => boolean;
  range?: {
    min: number;
    max: number;
  };
  daterange?: {
    min: Date;
    max: Date;
    minLabel: string;
    maxLabel: string;
  };
}

function ValueLabelComponent(props) {
  // eslint-disable-next-line react/prop-types
  const { children, value } = props;

  return (
    <Tooltip
      open
      enterTouchDelay={0}
      placement="top"
      title={displayUSCurrency(value)}>
      {children}
    </Tooltip>
  );
}

interface CollapsibleTableFilterProps<T> {
  headerKey: keyof T;
  onFilter: (key: string, value: string) => void;
  filterConfig: FilterConfig;
  onNumericFilter: (key: keyof T, min: number, max: number) => void;
  onDateFilter: (key: keyof T, min: Date, max: Date) => void;
  activeFilters?: FilterRecord;
}

export default function CollapsibleTableFilter<T>(
  props: CollapsibleTableFilterProps<T>
) {
  const classes = useStyles();
  const {
    headerKey,
    onFilter,
    filterConfig,
    onNumericFilter,
    onDateFilter,
    activeFilters
  } = props;
  const isNumericFilter = Boolean(filterConfig.range);
  const isDateFilter = Boolean(filterConfig.daterange);
  const currFilter = activeFilters ? activeFilters[headerKey as string] : {};
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [filterText, setFilterText] = useState<string>(currFilter?.value || "");
  const [defaultSliderVal] = useState<number[]>([
    (isNumericFilter && currFilter?.min) || filterConfig?.range?.min || 0,
    (isNumericFilter && currFilter?.max) || filterConfig?.range?.max || 100
  ]);
  const [sliderValue, setSliderValue] = useState<number[]>(defaultSliderVal);
  const [defaultDateRangeVal] = useState<DateRange<Date>>([
    (isDateFilter && currFilter?.min) || null,
    (isDateFilter && currFilter?.max) || null
  ]);
  const [debounceActive, setDebounceActive] = useState(false);
  const [dateRangeValue, setDateRangeValue] =
    useState<DateRange<Date>>(defaultDateRangeVal);

  useEffect(() => {
    if (activeFilters && Object.keys(activeFilters).length === 0) {
      if (filterText) {
        setFilterText("");
      }
      if (
        !debounceActive &&
        (sliderValue[0] !== (filterConfig?.range?.min || 0) ||
          sliderValue[1] !== (filterConfig?.range?.max || 100))
      ) {
        setSliderValue([
          filterConfig?.range?.min || 0,
          filterConfig?.range?.max || 100
        ]);
      }
      if (defaultDateRangeVal[0] !== null && defaultDateRangeVal[1] !== null) {
        setDateRangeValue([null, null]);
      } else if (
        dateRangeValue[0] !== defaultDateRangeVal[0] ||
        dateRangeValue[1] !== defaultDateRangeVal[1]
      ) {
        setDateRangeValue(defaultDateRangeVal);
      }
    }
  }, [
    activeFilters,
    dateRangeValue,
    debounceActive,
    defaultDateRangeVal,
    defaultSliderVal,
    filterConfig,
    filterText,
    sliderValue
  ]);

  const open = Boolean(anchorEl);
  const id = `${headerKey}-filter-popover`;

  const onFilterClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const filterClose = (): void => {
    setAnchorEl(null);
  };

  const onTextFilterChange = useCallback(
    (value: string): void => {
      setFilterText(value);
      onFilter(headerKey as string, value);
    },
    [headerKey, onFilter]
  );

  const onDateRangeChange = useCallback(
    (newValue: DateRange<Date>): void => {
      setDateRangeValue(newValue);
      onDateFilter(headerKey, newValue[0]!, newValue[1]!);
    },
    [headerKey, onDateFilter]
  );

  const debouncedOnNumericFilter = useDebouncedCallback(
    (val1: number, val2: number): void => {
      onNumericFilter(headerKey, val1, val2);
      setDebounceActive(false);
    },
    500
  );

  const handleSliderChange = useCallback(
    (_event, newValue: number[]): void => {
      setDebounceActive(true);
      setSliderValue(newValue);
      debouncedOnNumericFilter(newValue[0], newValue[1]);
    },
    [debouncedOnNumericFilter]
  );

  const sliderValueText = value => displayUSCurrency(value);

  const filterJSX = useMemo(
    () =>
      // eslint-disable-next-line no-nested-ternary
      filterConfig.daterange ? (
        <div>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
              startText={filterConfig.daterange.minLabel}
              endText={filterConfig.daterange.maxLabel}
              value={dateRangeValue}
              onChange={onDateRangeChange}
              renderInput={(startProps, endProps) => (
                <>
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <TextField {...endProps} />
                </>
              )}
            />
          </LocalizationProvider>
          <Button size="small" onClick={() => onDateRangeChange([null, null])}>
            Reset
          </Button>
        </div>
      ) : filterConfig.range ? (
        <div className={classes.sliderContainer}>
          <Slider
            ValueLabelComponent={ValueLabelComponent}
            value={sliderValue}
            min={filterConfig.range.min}
            max={filterConfig.range.max}
            onChange={handleSliderChange}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            getAriaValueText={sliderValueText}
          />
          <div className={classes.flex}>
            $
            <input
              min={filterConfig.range.min}
              max={filterConfig.range.max}
              type="number"
              value={sliderValue[0]}
              onChange={e => {
                handleSliderChange(e, [
                  parseFloat(e.target.value),
                  sliderValue[1]
                ]);
              }}
            />
            &nbsp; $
            <input
              min={filterConfig.range.min}
              max={filterConfig.range.max}
              type="number"
              value={sliderValue[1]}
              onChange={e => {
                handleSliderChange(e, [
                  sliderValue[0],
                  parseFloat(e.target.value)
                ]);
              }}
            />
            &nbsp;
            <Button
              size="small"
              onClick={e =>
                handleSliderChange(e, [
                  filterConfig?.range?.min ?? 0,
                  filterConfig?.range?.max ?? 0
                ])
              }>
              Reset
            </Button>
          </div>
        </div>
      ) : (
        <div className={classes.flex}>
          <TextField
            autoFocus
            variant="outlined"
            size="small"
            placeholder="Filter"
            value={filterText}
            onChange={e => onTextFilterChange(e.target.value)}
          />
          <div>&nbsp;</div>
          <Button size="small" onClick={() => onTextFilterChange("")}>
            Reset
          </Button>
        </div>
      ),
    [
      classes.flex,
      classes.sliderContainer,
      dateRangeValue,
      filterConfig.daterange,
      filterConfig.range,
      filterText,
      handleSliderChange,
      onDateRangeChange,
      onTextFilterChange,
      sliderValue
    ]
  );

  const filterActive = useMemo(
    () =>
      Boolean(
        filterText ||
          (filterConfig?.range &&
            (filterConfig?.range?.min !== sliderValue?.[0] ||
              filterConfig?.range?.max !== sliderValue?.[1])) ||
          (dateRangeValue?.[0] && dateRangeValue?.[1])
      ),
    [dateRangeValue, filterConfig?.range, filterText, sliderValue]
  );

  return (
    <div className={classes.nowrap}>
      <Tooltip title="Filter">
        <div>
          <IconButton size="small" onClick={onFilterClick}>
            <FilterAltIcon
              className={filterActive ? classes.filterIcon : undefined}
              color={filterActive ? undefined : "action"}
              fontSize="inherit"
            />
          </IconButton>
        </div>
      </Tooltip>
      <Popover
        sx={{
          "& .MuiPaper-root": {
            overflow: "visible"
          }
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={filterClose}
        onClick={e => e.stopPropagation()}>
        <Paper className={classes.paper}>{filterJSX}</Paper>
      </Popover>
    </div>
  );
}
