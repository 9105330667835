import React from "react";

import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DatePicker from "ui-library/DatePicker";
import Button from "ui-library/Button";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import MenuItem from "ui-library/MenuItem";
import SelectField from "ui-library/Select";
import TextField from "ui-library/TextField";

import { getJSXListsFirebase, getMetaFirebase } from "helpers/Firebase";
import { getTimesJSX } from "helpers/Time";
import Subheader from "ui-library/Subheader";
import { common, grey } from "@mui/material/colors";
import { Stack } from "@mui/material";

const { white } = common;
const grey700 = grey["700"];

class SearchSpend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchSpend: {
        startDate: null,
        endDate: null,
        ...this.props.searchSpend
      },
      promKey: "",
      productsJSX: [],
      accountsJSX: [],
      customersJSX: []
    };
  }

  handleTextChange = (field, event) => {
    const newSearchSpend = this.state.searchSpend;
    newSearchSpend[field] = event.target.value;
    this.setState({
      searchSpend: newSearchSpend
    });
  };

  handleSelectChange = (field, event, index, value) => {
    const newSearchSpend = this.state.searchSpend;
    newSearchSpend[field] = value;
    this.setState({
      searchSpend: newSearchSpend
    });
  };

  handleDateChange = (field, value) => {
    const newSearchSpend = this.state.searchSpend;
    newSearchSpend[field] = new Date(value);
    this.setState({
      searchSpend: newSearchSpend
    });
  };

  handleSubmit = () => {
    const newSearchSpend = Object.fromEntries(
      Object.entries(this.state.searchSpend).filter(([_, v]) => v != null)
    );
    this.props.setSearchSpend(newSearchSpend);
    this.props.openClose.hideRightDrawer();
  };

  resetFields = () => {
    this.setState({
      searchSpend: {
        startDate: null,
        endDate: null,
        ...this.props.searchSpend
      }
    });
    this.props.setSearchSpend({});
  };

  componentDidMount() {
    getMetaFirebase(metaData => {
      const selectTypes = [];
      const selectStatuses = [];
      const { fundTypes = {} } = metaData;
      for (var key in fundTypes) {
        selectTypes.push(
          <MenuItem
            value={parseInt(key)}
            children={fundTypes[key].name}
            key={key}
          />
        );
      }
      for (var key in metaData.statuses) {
        const status = metaData.statuses[key];
        selectStatuses.push(
          <MenuItem value={parseInt(key)} children={status} key={key} />
        );
      }
      this.setState({
        meta: metaData,
        selectTypes,
        selectStatuses
      });
    });
    getJSXListsFirebase(
      this.props.db,
      allJSX => {
        const { productsJSX, accountsJSX, customersJSX } = allJSX;
        this.setState({
          productsJSX,
          accountsJSX,
          customersJSX
        });
      },
      null,
      true
    );

    const timesJSX = getTimesJSX(this.props.db.meta, true);
    this.setState({
      monthsJSX: timesJSX[0],
      yearsJSX: timesJSX[1]
    });
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    return (
      <div key={this.props.contactKey}>
        <ListItem style={{ backgroundColor: grey700, color: white }}>
          <ListItemIcon>
            <ZoomInIcon style={{ color: white }} />
          </ListItemIcon>
          <ListItemText primary="Advanced Search Spend/Deductions" />
        </ListItem>
        <div style={{ marginLeft: "16px", marginRight: "16px" }}>
          <br />
          <div>
            Enter keyword or select from dropdown for any field. Search results
            contain spend entries that match all fields.
          </div>
          <br />
          <Subheader style={{ color: "primary.main" }}>
            Promotion Fields
          </Subheader>
          <TextField
            value={this.state.searchSpend.promKey || ""}
            onChange={this.handleTextChange.bind(null, "promKey")}
            floatingLabelText="Corresponding Promotion ID"
            ref="promID"
            fullWidth
          />
          <br />
          <TextField
            value={this.state.searchSpend.name || ""}
            onChange={this.handleTextChange.bind(null, "name")}
            floatingLabelText="Corresponding Promotion Name"
            ref="promName"
            fullWidth
          />
          <br />
          <SelectField
            value={this.state.searchSpend.customer}
            onChange={this.handleSelectChange.bind(null, "customer")}
            floatingLabelText="Customer"
            fullWidth>
            {this.state.customersJSX}
          </SelectField>
          <br />
          <SelectField
            value={this.state.searchSpend.product}
            onChange={this.handleSelectChange.bind(null, "product")}
            floatingLabelText="Product or Product Group"
            fullWidth>
            {this.state.productsJSX}
          </SelectField>
          <br />
          <SelectField
            floatingLabelText="Account"
            value={this.state.searchSpend.account}
            onChange={this.handleSelectChange.bind(null, "account")}
            multiple
            fullWidth>
            {this.state.accountsJSX}
          </SelectField>
          <br />
          <Stack spacing={2}>
            <SelectField
              floatingLabelText="Month"
              value={this.state.searchSpend.month}
              onChange={this.handleSelectChange.bind(null, "month")}
              fullWidth>
              {this.state.monthsJSX}
            </SelectField>
            <SelectField
              floatingLabelText="Year"
              value={this.state.searchSpend.year}
              onChange={this.handleSelectChange.bind(null, "year")}
              fullWidth>
              {this.state.yearsJSX}
            </SelectField>
          </Stack>
          <br />
          <Subheader style={{ color: "primary.main" }}>Spend Fields</Subheader>
          <Stack spacing={2}>
            <DatePicker
              floatingLabelText="Starts after"
              onChange={this.handleDateChange.bind(null, "startDate")}
              value={this.state.searchSpend.startDate}
              fullWidth
            />
            <DatePicker
              floatingLabelText="Ends before"
              onChange={this.handleDateChange.bind(null, "endDate")}
              value={this.state.searchSpend.endDate}
              fullWidth
            />
          </Stack>
          <TextField
            value={this.state.searchSpend.checkNumber || ""}
            onChange={this.handleTextChange.bind(null, "checkNumber")}
            floatingLabelText="Check Number"
            ref="checkNumber"
            fullWidth
          />
          <br />
          <br />
          <Stack justifyContent="center">
            <Button label="Search" onClick={this.handleSubmit} />
            <Button
              label="Reset Fields"
              variant="text"
              color="error"
              onClick={this.resetFields}
            />
          </Stack>
          <br />
        </div>
      </div>
    );
  }
}

export default SearchSpend;
