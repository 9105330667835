import React from "react";

import Paper from "@mui/material/Paper";
import NotifIcon from "@mui/icons-material/Notifications";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Toolbar, ToolbarTitle, ToolbarSeparator } from "ui-library/Toolbar";
import ToolbarGroup from "ui-library/ToolbarGroup";
import Subheader from "ui-library/Subheader";
import Card from "ui-library/Card";
import SelectField from "ui-library/Select";
import MenuItem from "ui-library/MenuItem";
import IconButton from "ui-library/IconButton";

import {
  getCompanyUsers,
  getUserNotifDataFirebase,
  firebase
} from "helpers/Firebase";
import { numDays } from "helpers/Time";
import { objToArray, sortByField } from "helpers/sortByDate";
import Mixpanel from "helpers/Mixpanel";
import { common, grey } from "@mui/material/colors";
import { Stack } from "@mui/material";
import PromProfile from "../Planning/PromProfile";

const { black } = common;
const grey500 = grey["500"];
const { white } = common;

const ITEM_HEIGHT = 44;

const styles = {
  dashboard: {
    marginLeft: "30px",
    marginRight: "30px"
  },
  widgetIcon: {
    width: "60px",
    height: "60px",
    color: white
  },
  radialBar: {
    marginTop: "-70px"
  },
  miniIcon: {
    marginLeft: "-6px",
    marginRight: "6px",
    color: grey500
  },
  widgetLink: {
    marginTop: 10,
    width: "100%",
    color: white
  },
  loading: {
    marginTop: 100
  },
  customFont: {
    fontFamily: "Oxygen"
  },
  rankedList: {
    borderColor: grey500,
    borderWidth: 1,
    borderStyle: "solid"
  },
  subheader: {
    fontSize: 20,
    marginLeft: "-16px"
  }
};

class AllNotifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promotions: {},
      notifList: [],
      daysAgo: -1,
      promotion: 0,
      notifType: 0
    };
  }

  getReceivedJSX = () => {
    const receivedJSX = [<MenuItem value={0} children="Last Day" key={0} />];
    for (let i = 2; i <= 7; i++) {
      receivedJSX.push(
        <MenuItem value={i - 1} children={`Last ${i} Days`} key={i - 1} />
      );
    }

    return receivedJSX;
  };

  getPromotionsJSX = () => {
    const allPromotions = this.props.db.promotions;
    const promotionsJSX = [];
    const promotionsList = sortByField("name", objToArray(allPromotions), true);
    for (let i = 0; i < promotionsList.length; i++) {
      const promotion = promotionsList[i];
      promotionsJSX.push(
        <MenuItem
          value={promotion.key}
          children={promotion.name}
          key={promotion.key}
        />
      );
    }
    return promotionsJSX;
  };

  getNotifTypesJSX = () => {
    const notifTypesJSX = [];
    const fieldText = [
      ["addProm", "Promotion Added"],
      ["editProm", "Promotion Edited"],
      ["commentsProm", "Promotion Comments Added"],
      ["approveProm", "Promotion Approved"],
      ["declineProm", "Promotion Declined"]
    ];
    for (const [field, text] of fieldText) {
      notifTypesJSX.push(
        <MenuItem value={field} children={text} key={field} />
      );
    }

    return notifTypesJSX;
  };

  handleResetFields = () => {
    this.setState({
      daysAgo: -1,
      promotion: 0,
      notifType: 0
    });
  };

  filterNotifs = () => {
    const filteredNotifs = [];
    for (const notif of this.state.notifList) {
      if (
        (this.state.daysAgo == -1 || notif.timeSince <= this.state.daysAgo) &&
        (!this.state.promotion || notif.promKey === this.state.promotion) &&
        (!this.state.notifType || notif.tag === this.state.notifType)
      ) {
        filteredNotifs.push(notif);
      }
    }

    return filteredNotifs;
  };

  componentDidMount() {
    getUserNotifDataFirebase(notifs => {
      const notifList = [];
      for (const key in notifs) {
        const notif = notifs[key];
        notif.key = key;
        notif.timeSince = numDays(Date.parse(notif.time));
        notifList.push(notif);
      }
      this.setState({
        notifList: notifList.reverse()
      });
    }, "notifs");

    // Obtain updates from company database
    const user = firebase.auth().currentUser;

    // Get the user's access level
    if (user) {
      getCompanyUsers(companyUsers => {
        if (companyUsers) {
          const { uid } = user;
          this.setState({ access: companyUsers[uid].access });
        }
      });
    }
  }

  render() {
    const receivedJSX = this.getReceivedJSX();
    const promotionsJSX = this.getPromotionsJSX();
    const notifTypesJSX = this.getNotifTypesJSX();
    const filteredNotifs = this.filterNotifs();

    const filteredNotifsJSX = [];
    if (filteredNotifs.length >= 0) {
      for (let i = 0; i < filteredNotifs.length; i++) {
        const notif = filteredNotifs[i];
        const globalContextShowRightDrawer =
          this.props.openClose.showRightDrawer.bind(
            null,
            <PromProfile
              promKey={notif.promKey}
              openClose={this.props.openClose}
              db={this.props.db}
            />
          );
        filteredNotifsJSX.push(
          <MenuItem
            key={notif.key}
            ContainerComponent="div"
            secondary={`${notif.timeSince} days ago`}
            onClick={() => {
              if (notif.promKey) {
                globalContextShowRightDrawer();
                Mixpanel.track("Promotion Viewed", {
                  View: "Notifications",
                  Component: "AllNotifications"
                });
              }
            }}>
            {notif.content}
          </MenuItem>
        );
      }
    } else {
      filteredNotifsJSX.push(
        <div className="centering">No notifications at this time.</div>
      );
    }

    return (
      <div>
        <Toolbar>
          <ToolbarGroup>
            <ToolbarTitle text="Notifications" style={{ color: black }} />
            <ToolbarSeparator />
          </ToolbarGroup>
        </Toolbar>
        <div
          style={{
            padding: 16,
            overflow: "hidden"
          }}>
          <Stack>
            <Subheader>Filter Notifications</Subheader>
            <div>
              <IconButton
                color="secondary"
                isBackgroundColor
                tooltip="Clear Filters"
                onClick={this.handleResetFields}
                size="large">
                <RefreshIcon />
              </IconButton>
            </div>
          </Stack>
          <br />
          <div className="row">
            <div className="col-lg-4">
              <Card title="Notification Received">
                <SelectField
                  floatingLabelText="Notification Received"
                  value={this.state.daysAgo}
                  onChange={(event, index, value) => {
                    this.setState({ daysAgo: value });
                  }}
                  fullWidth
                  style={styles.input}>
                  <MenuItem value={-1} children="Any Time" key="wildcard" />
                  {receivedJSX}
                </SelectField>
              </Card>
            </div>
            <div className="col-lg-4">
              <Card title="Promotion">
                <SelectField
                  floatingLabelText="Promotion"
                  value={this.state.promotion}
                  onChange={(event, index, value) => {
                    this.setState({ promotion: value });
                  }}
                  fullWidth
                  style={styles.input}>
                  <MenuItem value={0} children="Any Promotion" key="wildcard" />
                  {promotionsJSX}
                </SelectField>
              </Card>
            </div>
            <div className="col-lg-4">
              <Card title="Notification Type">
                <SelectField
                  floatingLabelText="Notification Type"
                  value={this.state.notifType}
                  onChange={(event, index, value) => {
                    this.setState({ notifType: value });
                  }}
                  fullWidth
                  style={styles.input}>
                  <MenuItem value={0} children="Any Type" key="wildcard" />
                  {notifTypesJSX}
                </SelectField>
              </Card>
            </div>
          </div>
          <br />
          <Card
            icon={<NotifIcon style={styles.miniIcon} />}
            title="Notifications Panel"
            customContent={
              <Paper
                style={{
                  maxHeight: ITEM_HEIGHT * 15,
                  overflowY: "auto",
                  overflowX: "hidden"
                }}>
                {filteredNotifsJSX}
              </Paper>
            }
          />
        </div>
      </div>
    );
  }
}

export default AllNotifications;
