export const UPDATE_FIELD = "UPDATE_FIELD_MATCHING";
export const SET_DEFAULT = "SET_DEFAULT_MATCHING";
export const ADD_LINE_KEY = "ADD_LINE_KEY";
export const DELETE_LINE_KEY = "DELETE_LINE_KEY";
export const GET_SUGGESTED_MATCHES = "GET_SUGGESTED_MATCHES";
export const RESET = "RESET_MATCHING";

export const updateField = (field, value) => ({
  type: UPDATE_FIELD,
  field,
  value
});

export const setDefault = (
  accumToLines,
  accumDF,
  allLinesDF,
  accumDFTable,
  allLinesDFTable,
  fileLineLimit,
  fileTooLarge,
  uploadPath,
  matchKey,
  suggestMatches
) => ({
  type: SET_DEFAULT,
  accumToLines,
  accumDF,
  allLinesDF,
  accumDFTable,
  allLinesDFTable,
  fileLineLimit,
  fileTooLarge,
  uploadPath,
  matchKey,
  suggestMatches
});

export const addLineKey = (rows, lineKey, tableType) => ({
  type: ADD_LINE_KEY,
  rows,
  lineKey,
  tableType
});

export const deleteLineKey = (rows, lineKey, tableType) => ({
  type: DELETE_LINE_KEY,
  rows,
  lineKey,
  tableType
});

export const getSuggestedMatches = isInputChecked => ({
  type: GET_SUGGESTED_MATCHES,
  isInputChecked
});

export const reset = () => ({
  type: RESET
});
