import { ReconciliationDisplayAmounts } from "deductions-constants/ReconciliationDisplay";
import { round } from "lodash";
import {
  invoiceLineStatuses,
  invoiceStatuses
} from "deductions-constants/ReconciliationStatuses";
import {
  repaymentLineTypes,
  resolutionLineTypes
} from "deductions-constants/ReconciliationTypes";
import { ERPTransactionObject, InvoiceObject } from "deductions-models/index";
import {
  InvoiceLineAmountService,
  RepaymentLineFilterService
} from "reconciliation-services";

// Returns the absolute value sum of all clearing activity amounts
export function getInvoiceClearedAmount(invoice: InvoiceObject): number {
  const { resolutionLines = {} } = invoice;

  const clearedAmount = Object.values(resolutionLines)
    .filter(resLine => resLine.type === resolutionLineTypes.CLEAR)
    .map(resLine => resLine.amount || 0.0)
    .reduce((total, amount) => total + Math.abs(amount), 0.0);

  return round(clearedAmount, 2);
}

// Returns the abs value sum of all write-off activity amountss
export function getInvoiceTotalWriteOffAmount(invoice: InvoiceObject): number {
  const { resolutionLines = {} } = invoice;

  const totalWriteOffAmount = Object.values(resolutionLines)
    .filter(resLine => resLine.type === resolutionLineTypes.WRITE_OFF)
    .map(resLine => resLine.amount || 0.0)
    .reduce((total, amount) => total + Math.abs(amount), 0.0);

  return round(totalWriteOffAmount, 2);
}

// Returns the abs value sum of all repayment activities credited towards any existing write-offs
export function getInvoiceCreditedWriteOffAmount(
  invoice: InvoiceObject,
  erpTransactions: Record<string, ERPTransactionObject>
): number {
  const repaymentLines = RepaymentLineFilterService.findInvoiceRepaymentLines(
    invoice,
    erpTransactions
  );

  const creditedWriteOffAmount = repaymentLines
    .filter(
      repaymentLine =>
        repaymentLine.type === repaymentLineTypes.CREDIT_WRITE_OFF
    )
    .map(repaymentLine => repaymentLine.amount)
    .reduce((total, amount) => total + Math.abs(amount), 0.0);

  return round(creditedWriteOffAmount, 2);
}

// Returns the abs value remaining write-off activity amount that have yet to be credited
export function getInvoiceOpenWriteOffAmount(
  invoice: InvoiceObject,
  erpTransactions: Record<string, ERPTransactionObject>,
  precalculated?: {
    totalWriteOffAmount?: number;
    creditedWriteOffAmount?: number;
  }
): number {
  const {
    totalWriteOffAmount = getInvoiceTotalWriteOffAmount(invoice),
    creditedWriteOffAmount = getInvoiceCreditedWriteOffAmount(
      invoice,
      erpTransactions
    )
  } = precalculated || {};

  return round(totalWriteOffAmount - creditedWriteOffAmount, 2);
}

// Returns the abs value sum of all disputed invoice line amounts for a single transaction
export function getInvoiceTotalDisputeAmount(invoice: InvoiceObject): number {
  if (invoice.status !== invoiceStatuses.NEEDS_ATTENTION) {
    return 0.0;
  }

  const { invoiceLines = {} } = invoice;

  const totalDisputeAmount = Object.values(invoiceLines)
    .filter(invoiceLine => invoiceLine.status === invoiceLineStatuses.DISPUTE)
    .map(invoiceLine =>
      InvoiceLineAmountService.getInvoiceLineTotalDisputeAmount(
        invoice,
        invoiceLine
      )
    )
    .reduce((total, amount) => total + Math.abs(amount), 0.0);

  return round(totalDisputeAmount, 2);
}

// Returns the abs value sum of all disputed invoice line amounts that have been repaid
export function getInvoiceRepaidDisputeAmount(
  invoice: InvoiceObject,
  erpTransactions: Record<string, ERPTransactionObject>
): number {
  const repaymentLines = RepaymentLineFilterService.findInvoiceRepaymentLines(
    invoice,
    erpTransactions
  );

  const repaidDisputeAmount = repaymentLines
    .filter(
      repaymentLine => repaymentLine.type === repaymentLineTypes.REPAYMENT
    )
    .map(repaymentLine => repaymentLine.amount)
    .reduce((total, amount) => total + Math.abs(amount), 0.0);

  return round(repaidDisputeAmount, 2);
}

// Returns the abs value of any disputed invoice line amounts that have yet to be repaid
export function getInvoiceOpenDisputeAmount(
  invoice: InvoiceObject,
  erpTransactions: Record<string, ERPTransactionObject>,
  precalculated?: {
    totalDisputeAmount?: number;
    repaidDisputeAmount?: number;
  }
): number {
  const {
    totalDisputeAmount = getInvoiceTotalDisputeAmount(invoice),
    repaidDisputeAmount = getInvoiceRepaidDisputeAmount(
      invoice,
      erpTransactions
    )
  } = precalculated || {};

  return round(totalDisputeAmount - repaidDisputeAmount, 2);
}

// Returns the abs value of the total amount resolved by clearing, write-offs, or repayments
export function getInvoiceResolvedAmount(
  invoice: InvoiceObject,
  erpTransactions: Record<string, ERPTransactionObject>,
  precalculated?: {
    clearedAmount?: number;
    totalWriteOffAmount?: number;
    repaidDisputeAmount?: number;
  }
): number {
  const {
    clearedAmount = getInvoiceClearedAmount(invoice),
    totalWriteOffAmount = getInvoiceTotalWriteOffAmount(invoice),
    repaidDisputeAmount = getInvoiceRepaidDisputeAmount(
      invoice,
      erpTransactions
    )
  } = precalculated || {};

  return round(clearedAmount + totalWriteOffAmount + repaidDisputeAmount, 2);
}

// Returns the abs value of the remaining amount not yet resolved by clearing, write-offs, or repayments
export function getInvoiceOpenAmount(
  invoice: InvoiceObject,
  erpTransactions: Record<string, ERPTransactionObject>,
  precalculated?: {
    resolvedAmount?: number;
  }
): number {
  const {
    resolvedAmount = getInvoiceResolvedAmount(invoice, erpTransactions)
  } = precalculated || {};

  return round(Math.abs(invoice.amount) - resolvedAmount, 2);
}

// Returns absolute value of all calculated amounts
export function getAllInvoiceAmounts(
  invoice: InvoiceObject,
  erpTransactions: Record<string, ERPTransactionObject>
): ReconciliationDisplayAmounts {
  const clearedAmount = getInvoiceClearedAmount(invoice);

  // WRITE-OFF RELATED AMOUNTS
  const totalWriteOffAmount = getInvoiceTotalWriteOffAmount(invoice);
  const creditedWriteOffAmount = getInvoiceCreditedWriteOffAmount(
    invoice,
    erpTransactions
  );
  const openWriteOffAmount = getInvoiceOpenWriteOffAmount(
    invoice,
    erpTransactions,
    {
      totalWriteOffAmount,
      creditedWriteOffAmount
    }
  );

  // DISPUTE RELATED AMOUNTS
  const totalDisputeAmount = getInvoiceTotalDisputeAmount(invoice);
  const repaidDisputeAmount = getInvoiceRepaidDisputeAmount(
    invoice,
    erpTransactions
  );
  const openDisputeAmount = getInvoiceOpenDisputeAmount(
    invoice,
    erpTransactions,
    {
      totalDisputeAmount,
      repaidDisputeAmount
    }
  );

  // AGGREGATE AMOUNTS
  const resolvedAmount = getInvoiceResolvedAmount(invoice, erpTransactions, {
    clearedAmount,
    totalWriteOffAmount,
    repaidDisputeAmount
  });
  const openAmount = getInvoiceOpenAmount(invoice, erpTransactions, {
    resolvedAmount
  });

  return {
    clearedAmount,
    totalWriteOffAmount,
    creditedWriteOffAmount,
    openWriteOffAmount,
    totalDisputeAmount,
    repaidDisputeAmount,
    openDisputeAmount,
    resolvedAmount,
    openAmount
  };
}
