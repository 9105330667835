import * as yup from "yup";
import Button from "ui-library/Button";
import Checkbox from "ui-library/Checkbox";
import React, { useCallback, useEffect, useState } from "react";
import SelectField from "ui-library/Select";
import { getValueFirebase } from "helpers/Firebase";
import { MenuItem, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import firebase from "../../../../firebase-init";
import CompanyMappingsTable from "./CompanyMappingsTable";
import { useCompanyMapping } from "./ScrapingContext";

const useStyles = makeStyles(theme => ({
  pageContainer: {
    "& > *": {
      marginTop: 10
    }
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 20
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  accordion: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    boxShadow: "none",
    borderRadius: 3,

    "&::before": {
      display: "none"
    }
  },
  subheader: {
    fontSize: 20,
    color: theme.palette.primary.main,
    lineHeight: "48px",
    letterSpacing: "initial"
  },
  accordionSummary: {
    maxHeight: 50,
    backgroundColor: "rgb(245, 245, 245)"
  },
  dataGrid: {
    height: "60vh"
  }
}));

type MapCompanyPortalType = {
  companyNames: string[] | null;
};

const validationSchema = yup.object({
  companyId: yup.string().required("Required field"),
  portal: yup.string().required("Required field"),
  customer: yup.string().required("Required field"),
  username: yup.string().required("Required field"),
  password: yup.string().required("Required field")
});

function MapCompanyPortal({ companyNames }: MapCompanyPortalType) {
  const classes = useStyles();
  const [customers, setCustomers] = useState([]);
  const [showCustomerMappingTable, setShowCustomerMappingTable] =
    useState(false);

  const { companyMapping, currentCompany } = useCompanyMapping();
  const { enqueueSnackbar: snackbar } = useSnackbar();

  const enqueueSnackbar = useCallback(
    (message: string) => {
      snackbar(message, {
        variant: "default",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center"
        }
      });
    },
    [snackbar]
  );

  const formik = useFormik({
    initialValues: {
      companyId: "",
      portal: "",
      customer: "",
      username: "",
      password: "",
      isDeductionPositive: false
    },
    onSubmit: async values => {
      const { companyId, portal } = values;

      if (
        Object.values(companyMapping).some(portals =>
          Object.values(portals).some(
            mapping =>
              mapping.username === values.username &&
              mapping.password === values.password &&
              mapping.companyId !== companyId
          )
        )
      ) {
        return enqueueSnackbar(
          "Username/password combo has been used. Please use different credentials"
        );
      }

      const itemsRef = firebase
        .database()
        .ref(`backupRetrieval/${companyId}/${portal}`);
      itemsRef.update({
        ...values,
        customerName: customers?.[values.customer].name,
        companyName: companyNames?.[values.companyId]
      });
      return formik.resetForm();
    },
    validationSchema
  });

  useEffect(() => {
    getValueFirebase(
      `/companies/${formik.values.companyId}/customers`,
      snapshot => {
        setCustomers(snapshot.val());
      }
    );
  }, [formik.values.companyId]);

  return (
    <div className={classes.pageContainer}>
      <div className={classes.header}>
        <Typography variant="h6" className={classes.subheader}>
          Map company & Backup portal
        </Typography>
        <Button
          label={
            showCustomerMappingTable
              ? "Create new mapping"
              : `View Mappings for ${companyNames?.[currentCompany]}`
          }
          onClick={() => setShowCustomerMappingTable(prev => !prev)}
        />
      </div>
      {showCustomerMappingTable ? (
        <div className={classes.dataGrid}>
          <CompanyMappingsTable
            setShowCustomerMappingTable={setShowCustomerMappingTable}
            formik={formik}
          />
        </div>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <div className={classes.pageContainer}>
            <div className={classes.rowContainer}>
              <SelectField
                floatingLabelText="Select Company"
                value={formik.values.companyId}
                fullWidth
                error={
                  formik.touched.companyId && Boolean(formik.errors.companyId)
                }
                helperText={formik.errors.companyId}
                name="companyId"
                onChange={e => {
                  formik.handleChange(e);
                }}
                style={{ marginTop: 0 }}>
                {Object.entries(companyNames || {})
                  .sort((s1, s2) => s1[1].localeCompare(s2[1]))
                  .map(([companyId, companyName]) => (
                    <MenuItem value={companyId} key={companyId}>
                      {companyName}
                    </MenuItem>
                  ))}
              </SelectField>

              <SelectField
                labelId="backup-source"
                id="backup-source"
                value={formik.values.portal}
                floatingLabelText="Backup Source"
                error={formik.touched.portal && Boolean(formik.errors.portal)}
                helperText={formik.errors.portal}
                name="portal"
                onChange={formik.handleChange}
                fullWidth>
                <MenuItem value="kehe">KEHE</MenuItem>
                <MenuItem value="costco">Costco</MenuItem>
              </SelectField>

              <SelectField
                labelId="backup-customer"
                id="backup-customer"
                value={formik.values.customer}
                floatingLabelText="Customer Mapping"
                error={
                  formik.touched.customer && Boolean(formik.errors.customer)
                }
                helperText={formik.errors.customer}
                name="customer"
                onChange={formik.handleChange}
                fullWidth>
                {Object.entries(customers || {})
                  .sort(([, s1], [, s2]) => s1.name?.localeCompare(s2.name))
                  .map(([key, { name }]) => (
                    <MenuItem value={key} key={key}>
                      {name}
                    </MenuItem>
                  ))}
              </SelectField>
            </div>

            <div className={classes.rowContainer}>
              <TextField
                placeholder="Email/Username"
                label="Email/Username"
                name="username"
                value={formik.values.username}
                onChange={formik.handleChange}
                error={
                  formik.touched.username && Boolean(formik.errors.username)
                }
                helperText={formik.touched.username && formik.errors.username}
                fullWidth
              />
              <TextField
                placeholder="Password"
                label="Password"
                name="password"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                fullWidth
              />
            </div>

            <div className={classes.rowContainer}>
              <Checkbox
                color="primary"
                label="Is Deduction Positive?"
                name="isDeductionPositive"
                onChange={formik.handleChange}
                checked={formik.values.isDeductionPositive}
              />
            </div>

            <Button
              label="Save company mapping"
              type="submit"
              disabled={!formik.isValid}
            />
          </div>
        </form>
      )}
    </div>
  );
}

export default MapCompanyPortal;
