import React from "react";

import Autocomplete from "ui-library/Autocomplete";
import Subheader from "ui-library/Subheader";

import DatePicker from "ui-library/DatePicker";
import Select from "ui-library/Select";
import Checkbox from "ui-library/Checkbox";
import Button from "ui-library/Button";

import { updateMetaFirebase } from "helpers/Firebase";
import { getTimesJSX } from "helpers/Time";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ModalContent from "components/WebsiteElements/ModalContent";
import { Stack } from "@mui/material";
import DataTable from "../tables/MuiDataTable";
import SpendSplit from "./SpendSplit";

// import CustomerEditLog from "./CustomerEditLog";
import TimeframeIndicator from "./TimeframeIndicator";

import { checkProposedCustomerSplits } from "./CustomerUtils";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export default class ManageDistribution extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      proposedCustomer: {},
      year: new Date().getFullYear().toString()
    };
  }

  toggleDirect = (event, isInputChecked) => {
    // need to check validity if:
    // direct -> non-direct
    if (
      !isInputChecked &&
      this.props.openNonzeroSplitPopup(this.props.customerKey, 2)
    ) {
      return;
    }

    let { customer } = this.props;
    customer.isDirect = isInputChecked;

    customer = this.props.handleDeleteSplit(customer, this.props.customerKey);

    if (customer.isDirect && customer.isDistributor) {
      customer.isDistributor = false;

      const oldDistributors = customer.oldDistributors || [];
      customer.distributors = Array.from(
        new Set([
          ...Object.keys(this.props.customer.spendPerDistributor || {}),
          ...oldDistributors
        ])
      );
      customer.oldDistributors = [];
    }

    // check if direct -> non-direct to remove Direct tag
    if (!isInputChecked) {
      if (customer.otherNamesMap && customer.otherNames) {
        for (let i = 0; i < customer.otherNamesMap.length; i++) {
          const indexDirect = (
            customer.otherNamesMap[i].distributorTag ?? []
          ).indexOf("Direct");
          if (indexDirect > -1) {
            customer.otherNamesMap[i].distributorTag.splice(indexDirect, 1);
          }
        }
      }
    }

    // always turn buy-in calendar off whenever user toggles checkboxes
    if (this.hasBuyIn()) {
      this.toggleBuyIn();
    }

    this.props.setCustomerProfileState({
      customer,
      changes: true
    });
  };

  getSplitTableData = () => {
    const dict = {};

    let allDistributors = [];
    if (this.props.customer.distributors) {
      allDistributors = Array.from(this.props.customer.distributors);
    }
    if (this.props.customer.isDirect) {
      allDistributors.push(this.props.customerKey);
    }

    const allPg = "All Product Groups";
    if (this.props.customer.spendPerDistributor) {
      dict[allPg] = [];
      for (var i = 0; i < allDistributors.length; i++) {
        var dist = allDistributors[i];
        var split = this.props.customer.spendPerDistributor[dist];
        if (split) {
          dict[allPg].push(`${split.toString()}%`);
        } else {
          dict[allPg].push("");
        }
      }
    }
    if (this.props.customer.spendPerDistributorPG) {
      for (const key in this.props.customer.spendPerDistributorPG) {
        const pgName = this.props.db.meta.product_groups[key].name;
        const pgSplit = this.props.customer.spendPerDistributorPG[key];
        if (pgSplit) {
          dict[pgName] = [];
          for (var i = 0; i < allDistributors.length; i++) {
            var dist = allDistributors[i];
            var split = pgSplit[dist];
            if (split) {
              dict[pgName].push(`${split.toString()}%`);
            } else {
              dict[pgName].push("");
            }
          }
        }
      }
    }
    return dict;
  };

  toggleSelfDistributor = (event, isInputChecked) => {
    // need to check validity if:
    // distributor -> non-distributor
    // direct -> distributor
    if (
      isInputChecked &&
      this.props.customer.isDirect &&
      this.props.openNonzeroSplitPopup(this.props.customerKey, 2)
    ) {
      return;
    }

    const { customer } = this.props;
    customer.isDistributor = isInputChecked;

    if (customer.isDistributor) {
      customer.oldDistributors = customer.distributors;
      customer.distributors = [];
    } else {
      const oldDistributors = customer.oldDistributors || [];
      customer.distributors = Array.from(
        new Set([
          ...Object.keys(this.props.customer.spendPerDistributor || {}),
          ...oldDistributors
        ])
      );
      customer.oldDistributors = [];
    }

    if (customer.isDirect && customer.isDistributor) {
      customer.isDirect = false;
      // check if direct -> non-direct to remove Direct tag
      if (customer.otherNamesMap && customer.otherNames) {
        for (let i = 0; i < customer.otherNamesMap.length; i++) {
          const indexDirect =
            customer.otherNamesMap[i].distributorTag.indexOf("Direct");
          if (indexDirect > -1) {
            customer.otherNamesMap[i].distributorTag.splice(indexDirect, 1);
          }
        }
      }
    }

    // always turn buy-in calendar off whenever user toggles checkboxes
    if (this.hasBuyIn()) {
      this.toggleBuyIn();
    }

    this.props.setCustomerProfileState({
      customer,
      changes: true
    });
  };

  hasBuyIn = () => {
    return this.props.useBuyIn?.[this.props.customerKey];
  };

  toggleBuyIn = () => {
    const name = this.props.customerKey;
    this.props.setCustomerProfileState({
      changes: true,
      useBuyIn: {
        ...this.props.useBuyIn,
        ...{ [name]: !this.props.useBuyIn?.[name] }
      }
    });
  };

  handleAssignSpend = () => {
    const currentCustomer = jQuery.extend(true, {}, this.props.customer);

    // initialize proposed customer
    this.setState({ proposedCustomer: currentCustomer });

    const distributors = [
      ...(this.props.customer.distributors ?? []),
      ...(this.props.customer.isDirect ? [this.props.customerKey] : [])
    ];
    const subsplitEntries = Object.entries(
      this.props.db.meta.product_groups ?? {}
    ).filter(entry => entry[1].isPricing);

    this.props.openClose.setAppModal(
      "Assign Percentage Spend",
      <SpendSplit
        customer={currentCustomer}
        updateProposedCustomer={customer => {
          this.setState({ proposedCustomer: customer });
        }}
        db={this.props.db}
        customerKey={this.props.customerKey}
        subsplit="Product Groups"
        elements={distributors.map(d => [d, this.props.db.customers[d]])}
        splitField="spendPerDistributor"
        subsplitField="spendPerDistributorPG"
        subsplitEntries={subsplitEntries}
      />,
      <Stack>
        <Button
          label="Cancel"
          onClick={this.props.openClose.closeAppModal}
          color="error"
          variant="text"
        />

        <Button
          label="Save"
          variant="text"
          onClick={() => {
            const { proposedCustomer } = this.state;
            const error = checkProposedCustomerSplits(
              proposedCustomer,
              distributors,
              subsplitEntries.map(e => e[0]),
              "spendPerDistributor",
              "spendPerDistributorPG"
            );

            if (!error) {
              this.props.setCustomerProfileState({
                customer: proposedCustomer,
                changes: true
              });
              this.setState({ proposedCustomer: {} });
              this.props.openClose.closeAppModal();
            } else {
              const errorName = `"${
                error == "all"
                  ? "All Product Groups"
                  : this.props.db.meta.product_groups[error].name
              }"`;
              this.props.openClose.showSnack(
                `${errorName} must have numeric values between 0 and 100 that sum to 100`
              );
            }
          }}
        />
      </Stack>
    );
  };

  getBuyInCalendar = () => {
    const { year } = this.state;
    const startDates = [];
    const endDates = [];
    let buyInCalendar = { ...this.props.buyInCalendar };
    if (!this.props.buyInCalendar?.[year]) {
      buyInCalendar = this.createNewBuyInYear(this.state.year);
    }
    [...Array(12).keys()].forEach(m => {
      startDates.push(
        <DatePicker
          floatingLabelText="Start date"
          value={buyInCalendar[year][m].start}
          maxDate={new Date(buyInCalendar[year][m].end)}
          onChange={newDate => {
            buyInCalendar[year][m].start = newDate.toDateString();
            this.props.setCustomerProfileState({
              changes: true,
              buyInCalendar
            });
          }}
        />
      );
      endDates.push(
        <DatePicker
          floatingLabelText="End date"
          value={buyInCalendar[year][m].end}
          minDate={new Date(buyInCalendar[year][m].start)}
          onChange={newDate => {
            buyInCalendar[year][m].end = newDate.toDateString();
            this.props.setCustomerProfileState({
              changes: true,
              buyInCalendar
            });
          }}
        />
      );
    });
    return {
      Month: monthNames,
      "Start Date": startDates,
      "End Date": endDates
    };
  };

  createNewBuyInYear = year => {
    const allMonths = [...Array(12).keys()].map(month => {
      return {
        start: new Date(year, month, 1).toDateString(),
        end: new Date(year, month + 1, 0).toDateString()
      };
    });
    const newBuyInCalendar = {
      ...this.props.buyInCalendar,
      ...{ [year]: allMonths }
    };
    this.props.setCustomerProfileState({ buyInCalendar: newBuyInCalendar });
    updateMetaFirebase(
      { [this.props.customerKey]: newBuyInCalendar },
      "buyin_calendar"
    );
    return newBuyInCalendar;
  };

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {}

  render() {
    const [monthsJSX, yearsJSX] = getTimesJSX(this.props.db.meta, true);
    return !this.props.promptSave ? (
      <ModalContent
        style={{
          paddingLeft: "16px",
          paddingRight: "16px",
          height: this.props.ppHeight,
          overflow: "auto"
        }}
        innerRef={this.props.manageDistributionRef}>
        <br />
        <TimeframeIndicator
          customer={this.props.customer}
          customers={this.props.db.customers}
          timeframeIndex={this.props.timeframeIndex}
          setTimeframe={this.props.setTimeframe}
          meta={this.props.db.meta}
          saveCustomer={customer => {
            this.props.setCustomerProfileState(
              {
                customer,
                changes: true
              },
              true
            );
            this.props.openClose.closeAppModal();
          }}
          openClose={this.props.openClose}
          editPermission={this.props.editPermission}
          presetCustomer={this.props.presetCustomer}
          changes={this.props.changes}
        />
        <br />
        <Subheader>Manage Distributor(s)</Subheader>
        <Stack direction="column" alignItems="inherit" sx={{ mb: 2 }}>
          <Checkbox
            color="primary"
            disabled={!this.props.editPermission || this.props.presetCustomer}
            label="Customer is distributor"
            checked={this.props.customer.isDistributor}
            onChange={this.toggleSelfDistributor}
            containerStyle={{ margin: "0px" }}
          />
          <Checkbox
            color="primary"
            disabled={!this.props.editPermission || this.props.presetCustomer}
            label="Customer is direct"
            checked={this.props.customer.isDirect}
            onChange={this.toggleDirect}
          />
          {this.props.customer.isDistributor && (
            <Checkbox
              color="primary"
              disabled={!this.props.editPermission || this.props.presetCustomer}
              label="Use buy-in calendar"
              checked={this.props.useBuyIn?.[this.props.customerKey]}
              onChange={this.toggleBuyIn}
            />
          )}
        </Stack>
        {this.props.customer.isDistributor &&
          this.props.useBuyIn?.[this.props.customerKey] && (
            <div>
              <Select
                floatingLabelText="Year"
                value={this.state.year}
                onChange={event => {
                  const { value } = event.target;
                  this.setState({ year: value });
                }}>
                {yearsJSX}
              </Select>
              <br />
              <br />
              <DataTable
                title="Buy In Windows"
                widths={["20%", "40%", "40%"]}
                data={this.getBuyInCalendar()}
              />
            </div>
          )}
        {!this.props.customer.isDistributor && (
          <div>
            <Stack alignItems="flex-end">
              <Autocomplete
                disabled={
                  !this.props.editPermission || this.props.presetCustomer
                }
                options={this.props.distributorList || []}
                getOptionLabel={option => option.text ?? ""}
                value={this.props.newDistributor}
                onChange={(_, option) => {
                  this.props.setCustomerProfileState({
                    newDistributor: option,
                    changes: true
                  });
                }}
                openOnFocus
                fullWidth
                floatingLabelText="New Distributor (save changes to add)"
                ignoreFilterOptions
              />
              <Button
                disabled={
                  !this.props.editPermission || this.props.presetCustomer
                }
                label="Assign % Spend"
                onClick={this.handleAssignSpend}
                icon={<AssignmentIcon />}
              />
            </Stack>
            <br />
            <br />
            {!this.props.presetCustomer && (
              <DataTable
                title="First Receivers"
                data={{
                  ...(this.props.customer.distributors ||
                  this.props.customer.isDirect
                    ? { Name: this.props.distributorsJSX }
                    : { Name: ["No distributors stored"] }),
                  ...this.getSplitTableData()
                }}
              />
            )}
          </div>
        )}
        <br />
      </ModalContent>
    ) : (
      this.props.getSaveJSX()
    );
  }
}
