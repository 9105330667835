// TYPES:
// 0 -> promotion (possibly multiple)
// 1 -> product
// 2 -> customer
// 3 -> contact
// 4 -> actual spend/sales (multiple)
// 5 -> expected spend/sales (multiple)
// 6 -> revenue (multiple)
// 7 -> pricing
// 8 -> account

import React from "react";
import axios from "axios";
import _ from "lodash";
import { zipObject, omit } from "lodash-es";
import MenuItem from "ui-library/MenuItem";
import randomstring from "randomstring";
import seedrandom from "seedrandom";
import firebase, {
  projectId,
  cloudRunURL,
  cloudRunFunctionURL,
  forecastCloudRunURL
} from "firebase-init";
import { Meta } from "js/dbTypes";
import { DbContextValues } from "js/contexts/Db";

import { sortByDate, sortByField, objToArray } from "./sortByDate";

import { arrayify } from "./DataProcessing";

const COMPANY_EMAIL_DOMAINS = [
  "@cresicor.com",
  "@cresicor.ai",
  "@govividly.com"
];

const categories = {
  0: "/promotions/",
  1: "/products/",
  2: "/customers/",
  3: "/contacts/",
  4: "/actual/",
  5: "/expected/",
  6: "/revenue/",
  7: "/pricing/",
  8: "/accounts/",
  9: "/bumpChart/",
  10: "/meta/product_groups/",
  11: "/companyUsers/",
  12: "/promotion_spend/",
  13: "/forecast/",
  14: "/brokers/",
  15: "/lift/",
  16: "/reports/",
  17: "/disputedSpend/",
  18: "/reconciledSpend/checks/",
  19: "/reconciledSpend/invoices/",
  20: "/reconciledSpend/spend/",
  21: "/seasonality/",
  22: "/dmm_ordering/",
  23: "/deduction_scans/",
  24: "/budget/",
  25: "/deduction_matches/",
  26: "/reconciliation/invoices/",
  27: "/reconciliation/transactions/",
  28: "/reconciliation/fileBatches/",
  29: "/reconcilliation/promotionInvoices/",
  30: "/reconciliation/events/"
};

enum CATEGORIESMAP {
  PROMOTIONS = "/promotions/",
  PRODUCTS = "/products/",
  CUSTOMERS = "/customers/",
  CONTACTS = "/contacts/",
  ACTUAL = "/actual/",
  EXPECTED = "/expected/",
  REVENUE = "/revenue/",
  PRICING = "/pricing/",
  ACCOUNTS = "/accounts/",
  BUMPCHART = "/bumpChart/",
  PRODUCT_GROUPS = "/meta/product_groups/",
  COMPANY_USERS = "/companyUsers/",
  PROMOTION_SPEND = "/promotion_spend/",
  FORECAST = "/forecast/",
  BROKERS = "/brokers/",
  LIFT = "/lift/",
  REPORTS = "/reports/",
  DISPUTED_SPEND = "/disputedSpend/",
  CHECKS = "/reconciledSpend/checks/",
  SPEND_INVOICES = "/reconciledSpend/invoices/",
  SPEND = "/reconciledSpend/spend/",
  SEASONALITY = "/seasonality/",
  DMM_ORDERING = "/dmm_ordering/",
  DEDUCTION_SCANS = "/deduction_scans/",
  BUDGET = "/budget/",
  DEDUCTION_MATCHES = "/deduction_matches/",
  RECON_INVOICES = "/reconciliation/invoices/",
  TRANSACTIONS = "/reconciliation/transactions/",
  FILE_BATCHES = "/reconciliation/fileBatches/",
  DRM_EVENTS = "/reconciliation/events/"
}

// TODO: Need to refactor this entirely.
let meta: Meta = { fundTypes: {} };
let permissions = [];
let salesteam = [];
let notifSettings = {};
let promotions = {};

var unsubscribe = firebase.auth().onAuthStateChanged(user => {
  unsubscribe();
  if (user) {
    firebase
      .database()
      .ref(`users/${user.uid}/notif_settings`)
      .on("value", snapshot => {
        if (snapshot.val()) {
          notifSettings = snapshot.val();
        }
      });
  }
});
getMetaFirebase(metaData => {
  meta = metaData;
});
getPermissions(permissionsData => {
  permissions = permissionsData;
});
getUsersByLevel("Director", users => {
  salesteam = users;
});
getUsersByLevel("Director", users => {
  salesteam = users;
});
getPromotionsFirebase((proms, promKey = null) => {
  if (!promKey) {
    promotions = proms;
  } else {
    promotions[promKey] = proms;
  }
});

function turnOffChildAndNodeListeners(key) {
  firebase
    .database()
    .ref(key)
    .orderByKey()
    .once("value")
    .then(snapshot => {
      snapshot.forEach(childSnapshot => {
        const childKey = childSnapshot.key;
        firebase.database().ref(`${key}/${childKey}`).off();
      });
      firebase.database().ref(key).off();
    });
}

firebase
  .database()
  .ref("listeners")
  .on("value", snapshot => {
    if (snapshot.val() && snapshot.val() == "off") {
      const user = firebase.auth().currentUser;
      if (user) {
        firebase
          .database()
          .ref(`users/${user.uid}/company_id`)
          .once("value", snapshot => {
            if (snapshot.val()) {
              const company_id = snapshot.val();
              firebase.database().ref("companyNames").off();
              firebase.database().ref("softwareVersion").off();
              turnOffChildAndNodeListeners(`users/${user.uid}`);
              Object.keys(categories).forEach(category =>
                turnOffChildAndNodeListeners(
                  `companies/${company_id}${category}` // category implicitly contains '/'
                )
              );
              turnOffChildAndNodeListeners(`companies/${company_id}/meta`);
              turnOffChildAndNodeListeners(
                `companies/${company_id}/companyUsers`
              );
              turnOffChildAndNodeListeners(`validEmails`);
            }
          });
      }
    }
  });

function displayMoney(num) {
  return num == 0 ? "0" : `$${num.toLocaleString()}`;
}

function getFirebaseConsoleURL() {
  return `https://console.firebase.google.com/u/0/project/${projectId}/database/${projectId}/data`;
}

function generateRandomKey() {
  let stop = false;
  while (!stop) {
    var now = new Date();
    var randStr = randomstring.generate({
      length: 5,
      charset: "BCDFGHJKLMNPQRSTVWXYZ"
    });
    const randomKey = `${
      (now.getYear() % 100) + `0${now.getMonth() + 1}`.slice(-2)
    }-${randStr}`;
    stop = !(randomKey in promotions);
  }
  return `${
    (now.getYear() % 100) + `0${now.getMonth() + 1}`.slice(-2)
  }-${randStr}`;
}

function generateLongRandomKey(all_keys) {
  let stop = false;
  while (!stop) {
    var randStr = randomstring.generate({
      length: 10,
      charset: "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
    });
    stop = !(randStr in all_keys);
  }
  return randStr;
}

function makeKeys(numKeys, callback?, keyLength?: number): string[] {
  const keys: string[] = [];
  for (let i = 0; i < numKeys; i++) {
    const now = new Date();
    const randStr = randomstring.generate({
      length: keyLength || 20,
      charset: "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
    });
    const key = `${
      (now.getYear() % 100) + `0${now.getMonth() + 1}`.slice(-2)
    }-${randStr}`;
    keys.push(key);
  }
  if (callback) {
    callback(keys);
  }

  return keys;
}

function getArchive(callback) {
  var unsubscribe = firebase.auth().onAuthStateChanged(user => {
    unsubscribe();
    getCompanyUsers(companyUsers => {
      const companyUser = companyUsers[user.uid];
      let archiveKey = "cresicor";
      if (companyUser && companyUser.archiveKey) {
        archiveKey = companyUser.archiveKey;
      }
      if (archiveKey != "cresicor") {
        categories[0] = `/archives/${archiveKey}/promotions/`;
        categories[4] = `/archives/${archiveKey}/actual/`;
        categories[6] = `/archives/${archiveKey}/revenue/`;
        categories[12] = `/archives/${archiveKey}/promotion_spend/`;
      }
      callback(categories);
    }, false);
  });
}

function getArchiveTS(type: CATEGORIESMAP, callback) {
  var unsubscribe = firebase.auth().onAuthStateChanged(user => {
    unsubscribe();
    getCompanyUsers(companyUsers => {
      const companyUser = companyUsers[user.uid];
      let archiveKey = "cresicor";
      if (companyUser && companyUser.archiveKey) {
        archiveKey = companyUser.archiveKey;
      }
      if (archiveKey != "cresicor") {
        callback(`/archives/${archiveKey}${type}`);
      } else {
        callback(type);
      }
    }, false);
  });
}

function addNotifsToUsers(bodies, tag, uids, promKeys) {
  // bodies is a list of notification texts
  // uids is a list of lists containing user ids for each body
  const user = firebase.auth().currentUser;
  if (user) {
    const distinctUids = Array.from(new Set(uids.flat()));
    Promise.all(
      distinctUids.map(uid =>
        firebase
          .database()
          .ref(`userNotifs/${uid}/notif_settings`)
          .once("value")
      )
    ).then(settings => {
      const settingsByUid = {};
      for (let i = 0; i < distinctUids.length; i++) {
        settingsByUid[distinctUids[i]] = settings[i].val();
      }
      const notifUpdates = {};
      for (const [ind, body] of Object.entries(bodies || {})) {
        const notifKey = firebase.database().ref().push().key;
        const newNotif = {
          content: body,
          tag,
          time: new Date().toUTCString()
        };
        if (promKeys) {
          newNotif.promKey = promKeys[ind];
        }
        const bodyUids = uids[ind];
        for (const uid of bodyUids) {
          const uidNotifSettings = settingsByUid[uid];
          notifUpdates[`${uid}/notifs/${notifKey}`] = newNotif;
          notifUpdates[`${uid}/numUnreadNotifs/${notifKey}`] = { unread: true };
          if (uidNotifSettings && uidNotifSettings.email) {
            notifUpdates[`${uid}/notifBacklog/${notifKey}`] = newNotif;
          }
        }
      }
      const notifRef = firebase.database().ref("userNotifs");
      notifRef.update(notifUpdates);
    });
  }
}

function sendNotif(bodies, tag, users, otherFields, email) {
  getCompanyUsers(companyUsers => {
    // Save notif to each company user
    if (bodies.constructor != Array) {
      bodies = [bodies];
    }
    if (!users || users.length == 0) {
      users = Object.keys(companyUsers);
    }
    if (
      otherFields &&
      "promKey" in otherFields &&
      otherFields.promKey.constructor != Array
    ) {
      otherFields.promKey = [otherFields.promKey];
    }
    const uids = []; // user uids we send the notifs to

    if (otherFields && "promKey" in otherFields) {
      // Lookup promotion ONCE
      const user = firebase.auth().currentUser;
      const promKeys = otherFields.promKey;
      for (const promKey of promKeys) {
        if (!(promKey in promotions)) continue;
        const prom = promotions[promKey];
        var bodyUids = [];
        for (var i = 0; i < users.length; i++) {
          var uid = users[i];
          // check if user is valid in Firebase
          if (
            notifSettings &&
            notifSettings[tag] &&
            ((uid in companyUsers && !companyUsers[uid].customers) ||
              companyUsers[uid].customers.includes(prom.customer))
          ) {
            bodyUids.push(uid);
          }
        }
        uids.push(bodyUids);
      }
      addNotifsToUsers(bodies, tag, uids, promKeys);
    } else {
      for (const body of bodies) {
        var bodyUids = [];
        for (var i = 0; i < users.length; i++) {
          var uid = users[i];
          // check if user is valid in Firebase
          if (notifSettings && notifSettings[tag]) {
            bodyUids.push(uid);
          }
        }
        uids.push(bodyUids);
      }
      addNotifsToUsers(bodies, tag, uids);
    }
  }, false);
}

function processProm(item) {
  function round(n) {
    return Math.round(n * 100) / 100;
  }

  const prom = { ...item };
  let promExpSpend = 0;
  let promExpSales = 0;
  for (const lineKey in prom.lines) {
    const line = prom.lines[lineKey];
    // compute total expected spend unless it is a lump sum spend
    const typeFields = meta.fundTypes?.[line.type]?.promCreationFields ?? [];
    if (
      line.totalExpSales &&
      line.spendRate &&
      (!typeFields.includes("lumpsum") || line.totalExpSpend == 0)
    ) {
      line.totalExpSpend = line.totalExpSales * line.spendRate;
    }
    promExpSpend += line.totalExpSpend
      ? Math.round(parseFloat(line.totalExpSpend))
      : 0;
    promExpSales += line.totalExpSales
      ? Math.round(parseFloat(line.totalExpSales))
      : 0;

    // compute discount
    line.discount =
      line.basePrice && line.retailPrice
        ? round(((line.basePrice - line.retailPrice) * 100) / line.basePrice)
        : 0;
  }
  prom.totalExpSales = promExpSales;
  prom.totalExpSpend = promExpSpend;
  prom.spendRate = promExpSales ? round(promExpSpend / promExpSales) : 0;

  return prom;
}

/**
 * @deprecated Use `updateFirebaseTS()` instead.
 */
function updateFirebase(type, newItem, key, callback, errorCallback) {
  const user = firebase.auth().currentUser;
  if (user) {
    firebase
      .database()
      .ref(`users/${user.uid}/company_id`)
      .once("value", snapshot => {
        if (snapshot.val()) {
          const company_id = snapshot.val();
          if (type == 0) {
            firebase
              .database()
              .ref(`companies/${company_id}${categories[type]}${key}`)
              .once("value", snapshot => {
                if (snapshot.val()) {
                  newItem = processProm({ ...snapshot.val(), ...newItem });
                  const typeRef = firebase
                    .database()
                    .ref(`companies/${company_id}${categories[type]}${key}`);
                  typeRef.update(newItem);
                  if (callback) {
                    callback(key);
                  }
                }
              });
          } else {
            const typeRef = firebase
              .database()
              .ref(`companies/${company_id}${categories[type]}${key}`);
            typeRef.update(newItem);
            if (callback) {
              callback(key);
            }
          }
        }
      })
      .catch(() => {
        if (errorCallback) {
          errorCallback(key);
        }
      });
  }
}

function updateFirebaseTS(
  type: CATEGORIESMAP,
  newItem,
  key,
  callback,
  errorCallback
) {
  const user = firebase.auth().currentUser;
  if (user) {
    firebase
      .database()
      .ref(`users/${user.uid}/company_id`)
      .once("value", snapshot => {
        if (snapshot.val()) {
          const companyId = snapshot.val();
          if (type === CATEGORIESMAP.PROMOTIONS) {
            firebase
              .database()
              .ref(`companies/${companyId}${type}${key}`)
              .once("value", currentSnapshot => {
                if (currentSnapshot.val()) {
                  newItem = processProm({
                    ...currentSnapshot.val(),
                    ...newItem
                  });
                  const typeRef = firebase
                    .database()
                    .ref(`companies/${companyId}${type}${key}`);
                  typeRef.update(newItem);
                  if (callback) {
                    callback(key);
                  }
                }
              });
          } else {
            const typeRef = firebase
              .database()
              .ref(`companies/${companyId}${type}${key}`);
            typeRef.update(newItem);
            if (callback) {
              callback(key);
            }
          }
        }
      })
      .catch(() => {
        if (errorCallback) {
          errorCallback(key);
        }
      });
  }
}

function updateLineFirebase(newLine, promKey, lineKey, callback) {
  const user = firebase.auth().currentUser;
  if (user) {
    firebase
      .database()
      .ref(`users/${user.uid}/company_id`)
      .once("value", snapshot => {
        if (snapshot.val()) {
          const company_id = snapshot.val();
          const lineRef = firebase
            .database()
            .ref(
              `companies/${company_id}${categories[0]}${promKey}/lines/${lineKey}`
            );
          lineRef.update(newLine);
          if (callback) {
            callback(lineKey);
          }
        }
      });
  }
}

/**
 * @deprecated Use `batchUpdateFirebaseTS()` instead.
 */
function batchUpdateFirebase(type, newItems, callback) {
  const user = firebase.auth().currentUser;
  if (user) {
    firebase
      .database()
      .ref(`users/${user.uid}/company_id`)
      .once("value", snapshot => {
        if (snapshot.val()) {
          const company_id = snapshot.val();
          const itemsRef = firebase
            .database()
            .ref(`companies/${company_id}${categories[type]}`);
          itemsRef.update(newItems);
          if (callback) {
            callback(true);
          }
        } else if (callback) {
          callback(false);
        }
      });
  }
}

function batchUpdateFirebaseTS(
  type: CATEGORIESMAP,
  newItems,
  callback: () => void
) {
  const user = firebase.auth().currentUser;
  if (user) {
    firebase
      .database()
      .ref(`users/${user.uid}/company_id`)
      .once("value", snapshot => {
        if (snapshot.val()) {
          const companyId = snapshot.val();
          const itemsRef = firebase
            .database()
            .ref(`companies/${companyId}${type}`);
          itemsRef.update(newItems);
          if (callback) {
            callback(true);
          }
        } else if (callback) {
          callback(false);
        }
      });
  }
}

function batchEditPromotions(promKeys, proms, editProm) {
  getCompanyUsers(companyUsers => {
    const notifBodies = [];
    for (const prom of proms) {
      notifBodies.push(`Promotion has been edited: ${prom.name}`);
    }
    sendNotif(notifBodies, "editProm", null, { promKey: promKeys });
    // batch update data in firebase
    const user = firebase.auth().currentUser;
    const { uid } = user;
    const userName = companyUsers[uid].name;
    const modified = {
      user: userName,
      uid,
      date: new Date().toDateString(),
      time: new Date().toLocaleTimeString()
    };
    const modifiedPromFields = {};
    for (const key of promKeys) {
      for (const field in editProm) {
        modifiedPromFields[`${key}/${field}`] = editProm[field];
      }
      modifiedPromFields[`${key}/modified`] = modified;
    }
    batchUpdateFirebase(0, modifiedPromFields);
  }, false);
}

function updateStatusFirebase(promKeys, proms, newStatus) {
  getCompanyUsers(companyUsers => {
    if (promKeys.constructor != Array) {
      promKeys = [promKeys];
    }
    if (proms.constructor != Array) {
      proms = [proms];
    }
    const notifBodies = [];
    if (
      newStatus == "Approved" ||
      newStatus == "Running" ||
      newStatus == "Completed"
    ) {
      if (newStatus == "Approved") {
        for (var prom of proms) {
          notifBodies.push(`Promotion has been approved: ${prom.name}`);
        }
        sendNotif(notifBodies, "approveProm", null, { promKey: promKeys });
      }
    } else if (newStatus == "Declined") {
      for (var prom of proms) {
        notifBodies.push(`Promotion has been declined: ${prom.name}`);
      }
      sendNotif(notifBodies, "declineProm", null, { promKey: promKeys });
    }
    // batch update data in firebase
    const user = firebase.auth().currentUser;
    const { uid } = user;
    const userName = companyUsers[uid].name;
    const modified = {
      user: userName,
      uid,
      date: new Date().toDateString(),
      time: new Date().toLocaleTimeString()
    };
    const modifiedPromFields = {};
    const newStatusInt = parseInt(meta.statuses.indexOf(newStatus));
    for (const key of promKeys) {
      modifiedPromFields[`${key}/status`] = newStatusInt;
      modifiedPromFields[`${key}/modified`] = modified;
    }
    batchUpdateFirebase(0, modifiedPromFields);
  }, false);
}

function updatePricingFirebase(customer, productGroup, price) {
  const user = firebase.auth().currentUser;
  if (user) {
    firebase
      .database()
      .ref(`users/${user.uid}/company_id`)
      .once("value", snapshot => {
        if (snapshot.val() && customer && productGroup) {
          const company_id = snapshot.val();
          const priceRef = firebase
            .database()
            .ref(`companies/${company_id}/pricing/${customer}/${productGroup}`);
          priceRef.update({ price });
        }
      });
  }
}

function batchUpdatePricingFirebase(pricingData) {
  const user = firebase.auth().currentUser;
  if (user) {
    const pricingUpdateSize = Object.keys(pricingData).length;
    const priceChunkLimit = 200;
    let priceChunkSize = pricingUpdateSize;
    if (pricingUpdateSize > priceChunkLimit) {
      // Chunk up update so we don't run into firebase limits
      priceChunkSize = priceChunkLimit;
    }
    const pricingDataArr = Object.entries(pricingData);
    const pricingDataUpdateChunks = _.chunk(pricingDataArr, priceChunkSize);
    pricingDataUpdateChunks.forEach(value => {
      const payload = Object.fromEntries(value);

      firebase
        .database()
        .ref(`users/${user.uid}/company_id`)
        .once("value", snapshot => {
          if (snapshot.val()) {
            const company_id = snapshot.val();
            const priceRef = firebase
              .database()
              .ref(`companies/${company_id}/pricing`);
            priceRef.update(payload);
          }
        })
        .catch(e => console.log(e));
    });
  }
}

function updateLiftFirebase(liftBucket, customer, productGroup, liftVal) {
  const user = firebase.auth().currentUser;
  if (user) {
    firebase
      .database()
      .ref(`users/${user.uid}/company_id`)
      .once("value", snapshot => {
        if (snapshot.val() && customer && productGroup) {
          const company_id = snapshot.val();
          const liftRef = firebase
            .database()
            .ref(
              `companies/${company_id}/lift/${liftBucket}/${customer}/${productGroup}`
            );
          liftRef.update({ lift: liftVal });
        }
      });
  }
}

function updateSeasonalityFirebase(seasonalityBucket, week, seasonalityIndex) {
  const user = firebase.auth().currentUser;
  if (user) {
    firebase
      .database()
      .ref(`users/${user.uid}/company_id`)
      .once("value", snapshot => {
        if (snapshot.val() && seasonalityBucket && week) {
          const company_id = snapshot.val();
          const seasonalityRef = firebase
            .database()
            .ref(
              `companies/${company_id}/seasonality/${seasonalityBucket}/${week}`
            );
          seasonalityRef.update({ seasonality_index: seasonalityIndex });
        }
      });
  }
}

// 'type' and 'items' are required, rest are optional
/**
 * @deprecated Use `addFirebaseTS()` instead.
 */
function addFirebase(type, items, callback, customKeys, errorCallback) {
  // convert 'items' to list, if just given a single item
  let single = false;
  if (items.constructor != Array) {
    items = [items];
    single = true;
  }
  const numKeys = items.length;
  if (type == 0) items = items.map(processProm);
  const user = firebase.auth().currentUser;
  if (user) {
    firebase
      .database()
      .ref(`users/${user.uid}/company_id`)
      .once("value", snapshot => {
        if (snapshot.val()) {
          const company_id = snapshot.val();
          const dbRef = firebase
            .database()
            .ref(`companies/${company_id}${categories[type]}`);
          if (customKeys === true) {
            makeKeys(numKeys, data => {
              const pushedData = zipObject(data, items);
              dbRef.update(pushedData);
              if (callback) {
                callback(single ? data[0] : data);
              }
            });
          } else if (customKeys) {
            if (customKeys.constructor != Array) {
              customKeys = [customKeys];
            }
            const pushedData = zipObject(customKeys, items);
            dbRef.update(pushedData);
            if (callback) {
              callback(single ? customKeys[0] : customKeys);
            }
          } else {
            const newKeys = [];
            for (let i = 0; i < numKeys; i++) {
              const newItem = items[i];
              const itemRef = dbRef.push();
              itemRef.set(newItem);
              newKeys.push(itemRef.key);
            }
            if (callback) {
              callback(single ? newKeys[0] : newKeys);
            }
          }
        }
      })
      .catch(error => {
        if (errorCallback) {
          errorCallback(error);
        }
      });
  }
}

// 'type' and 'items' are required, rest are optional
function addFirebaseTS(
  type: CATEGORIESMAP,
  items,
  callback,
  customKeys,
  errorCallback
) {
  // convert 'items' to list, if just given a single item
  let single = false;
  if (items.constructor !== Array) {
    items = [items];
    single = true;
  }
  const numKeys = items.length;
  if (type === CATEGORIESMAP.PROMOTIONS) items = items.map(processProm);
  const user = firebase.auth().currentUser;
  if (user) {
    firebase
      .database()
      .ref(`users/${user.uid}/company_id`)
      .once("value", snapshot => {
        if (snapshot.val()) {
          const companyId = snapshot.val();
          const dbRef = firebase
            .database()
            .ref(`companies/${companyId}${type}`);
          if (customKeys === true) {
            makeKeys(numKeys, data => {
              const pushedData = zipObject(data, items);
              dbRef.update(pushedData);
              if (callback) {
                callback(single ? data[0] : data);
              }
            });
          } else if (customKeys) {
            if (customKeys.constructor !== Array) {
              customKeys = [customKeys];
            }
            const pushedData = zipObject(customKeys, items);
            dbRef.update(pushedData);
            if (callback) {
              callback(single ? customKeys[0] : customKeys);
            }
          } else {
            const newKeys = [];
            for (let i = 0; i < numKeys; i++) {
              const newItem = items[i];
              const itemRef = dbRef.push();
              itemRef.set(newItem);
              newKeys.push(itemRef.key);
            }
            if (callback) {
              callback(single ? newKeys[0] : newKeys);
            }
          }
        }
      })
      .catch(() => {
        if (errorCallback) {
          errorCallback();
        }
      });
  }
}

// If key is blank, returns all items
/**
 * @deprecated Use `getFirebaseTS()` instead.
 */
function getFirebase(type, callback, key) {
  getArchive(categories => {
    const { uid } = firebase.auth().currentUser;
    firebase
      .database()
      .ref(`users/${uid}/company_id`)
      .on("value", snapshot => {
        if (snapshot.val()) {
          const company_id = snapshot.val();
          if (key) {
            firebase
              .database()
              .ref(`companies/${company_id}${categories[type]}${key}`)
              .on("value", snapshot => {
                const typeVal = snapshot.val();
                if (typeVal) callback(typeVal);
                else callback(null);
              });
          } else {
            firebase
              .database()
              .ref(`companies/${company_id}${categories[type]}`)
              .on("value", snapshot => {
                const allVal = snapshot.val();
                if (allVal) callback(allVal);
                else callback({});
              });
          }
        }
      });
  });
}

// If key is blank, returns all items
function getFirebaseTS(
  type: CATEGORIESMAP,
  callback: (typeVal: any) => void,
  key?: string
) {
  getArchiveTS(type, typeCurrent => {
    const { uid } = firebase.auth().currentUser;
    firebase
      .database()
      .ref(`users/${uid}/company_id`)
      .on("value", snapshot => {
        if (snapshot.val()) {
          const companyId = snapshot.val();
          if (key) {
            firebase
              .database()
              .ref(`companies/${companyId}${typeCurrent}${key}`)
              .on("value", snapshotCurrent => {
                const typeVal = snapshotCurrent.val();
                if (typeVal) callback(typeVal);
                else callback(null);
              });
          } else {
            firebase
              .database()
              .ref(`companies/${companyId}${typeCurrent}`)
              .on("value", snapshotCurrent => {
                const allVal = snapshotCurrent.val();
                if (allVal) callback(allVal);
                else callback({});
              });
          }
        }
      });
  });
}

// If key is blank, returns all items
// Uses .once() instead of .on().
function getFirebaseTSOnce(
  type: CATEGORIESMAP,
  callback: (typeVal: any) => void,
  key: string
) {
  getArchiveTS(type, typeCurrent => {
    const { uid } = firebase.auth().currentUser;
    firebase
      .database()
      .ref(`users/${uid}/company_id`)
      .once("value", snapshot => {
        if (snapshot.val()) {
          const companyId = snapshot.val();
          if (key) {
            firebase
              .database()
              .ref(`companies/${companyId}${typeCurrent}${key}`)
              .once("value", snapshotCurrent => {
                const typeVal = snapshotCurrent.val();
                if (typeVal) callback(typeVal);
                else callback(null);
              });
          } else {
            firebase
              .database()
              .ref(`companies/${companyId}${typeCurrent}`)
              .once("value", snapshotCurrent => {
                const allVal = snapshotCurrent.val();
                if (allVal) callback(allVal);
                else callback({});
              });
          }
        }
      });
  });
}

function getArchiveMetadata(callback) {
  var unsubscribe = firebase.auth().onAuthStateChanged(user => {
    unsubscribe();
    if (user) {
      firebase
        .database()
        .ref(`users/${user.uid}/company_id`)
        .once("value", snapshot => {
          if (snapshot.val()) {
            const company_id = snapshot.val();
            firebase
              .database()
              .ref(`companies/${company_id}/archive_metadata`)
              .on("value", snapshot => {
                const metadata = snapshot.val() || {};
                callback(metadata);
              });
          }
        });
    }
  });
}

function getPromotionsFirebase(callback, promKey) {
  getArchive(categories => {
    const { uid } = firebase.auth().currentUser;
    firebase
      .database()
      .ref(`users/${uid}/company_id`)
      .on("value", snapshot => {
        if (snapshot.val()) {
          const company_id = snapshot.val();
          if (promKey) {
            firebase
              .database()
              .ref(`companies/${company_id}${categories[0]}${promKey}`)
              .on("value", snapshot => {
                const prom = snapshot.val();
                prom.promKey = promKey;
                callback(prom);
              });
          } else {
            let initialDataLoaded = false;
            firebase
              .database()
              .ref(`companies/${company_id}${categories[0]}`)
              .on("child_added", snapshot => {
                if (initialDataLoaded) {
                  callback(snapshot.val(), (promKey = snapshot.key));
                }
              });
            firebase
              .database()
              .ref(`companies/${company_id}${categories[0]}`)
              .on("child_changed", snapshot => {
                if (initialDataLoaded) {
                  callback(snapshot.val(), (promKey = snapshot.key));
                }
              });
            firebase
              .database()
              .ref(`companies/${company_id}${categories[0]}`)
              .once("value", snapshot => {
                const promotions = {};

                const allPromotions = snapshot.val();
                for (const key in allPromotions) {
                  const prom = allPromotions[key]; // Get individual promotion object
                  prom.promKey = key;
                  promotions[key] = prom;
                }

                callback(promotions);
                initialDataLoaded = true;
              });
          }
        }
      });
  });
}

// returns an array of money objects with promKey that matches the one provided
function getPromMoneyFirebase(promKey, callback) {
  getArchive(categories => {
    const { uid } = firebase.auth().currentUser;
    firebase
      .database()
      .ref(`users/${uid}/company_id`)
      .once("value", snapshot => {
        if (snapshot.val()) {
          const company_id = snapshot.val();
          firebase
            .database()
            .ref(`companies/${company_id}${categories[12]}${promKey}`)
            .on("value", snapshot => {
              const spendKeys = snapshot.val()
                ? Object.keys(snapshot.val())
                : [];
              Promise.all(
                spendKeys.map(key =>
                  firebase
                    .database()
                    .ref(`companies/${company_id}${categories[4]}${key}`)
                    .once("value")
                )
              ).then(promMoneySnapshots => {
                const promMoney = [];
                for (const snapshot of promMoneySnapshots) {
                  promMoney.push(snapshot.val());
                }
                callback(promMoney);
              });
            });
        }
      });
  });
}

function getEnhancedPromsFirebase(callback, promKey, listen = false) {
  /* If outputFormat is blank, it is the {"Date" : [], "Sales" : []} format.
		Otherwise, it will be the {Date : Sale} format used in the backend and sent
		in the AJAX request in ExplorePromotions.
	*/
  getArchive(categories => {
    const { uid } = firebase.auth().currentUser;
    firebase
      .database()
      .ref(`users/${uid}/company_id`)
      .once("value", snapshot => {
        if (snapshot.val()) {
          const company_id = snapshot.val();
          const ref = firebase
            .database()
            .ref(`companies/${company_id}${categories[0]}${promKey}`);
          if (listen) {
            ref.on("value", snapshot => {
              const prom = snapshot.val();
              if (prom) {
                let enhancedProm = { promKey };
                enhancedProm = { ...prom, ...enhancedProm };

                getPromMoneyFirebase(promKey, promMoney => {
                  let moneyList = [];
                  for (const money of promMoney) {
                    // add type of promotion to money item
                    const { lineKey } = money;
                    const { type } = prom.lines[lineKey];
                    const moneyItem = money;
                    moneyItem.type = type;
                    moneyList.push(moneyItem);
                  }
                  moneyList = sortByDate(moneyList, true);

                  enhancedProm.actMoneyList = moneyList;
                  callback(enhancedProm, promKey);
                });
              } else {
                callback(null, promKey);
              }
            });
          } else {
            ref.once("value", snapshot => {
              const prom = snapshot.val();
              if (prom) {
                let enhancedProm = { promKey };
                enhancedProm = { ...prom, ...enhancedProm };

                getPromMoneyFirebase(promKey, promMoney => {
                  let moneyList = [];
                  for (const money of promMoney) {
                    // add type of promotion to money item
                    const { lineKey } = money;
                    const { type } = prom.lines[lineKey];
                    const moneyItem = money;
                    moneyItem.type = type;
                    moneyList.push(moneyItem);
                  }
                  moneyList = sortByDate(moneyList, true);

                  enhancedProm.actMoneyList = moneyList;
                  callback(enhancedProm, promKey);
                });
              } else {
                callback(null, promKey);
              }
            });
          }
        }
      });
  });
}

function getMetaFirebase(callback) {
  var unsubscribe = firebase.auth().onAuthStateChanged(user => {
    unsubscribe();
    if (user) {
      firebase
        .database()
        .ref(`users/${user.uid}/company_id`)
        .on("value", snapshot => {
          if (snapshot.val()) {
            const company_id = snapshot.val();
            firebase
              .database()
              .ref(`companies/${company_id}/meta`)
              .on("value", snapshot => {
                callback(snapshot.val());
              });
          }
        });
    }
  });
}

function updateMetaFirebase(newMeta, field) {
  var unsubscribe = firebase.auth().onAuthStateChanged(user => {
    unsubscribe();
    if (user) {
      firebase
        .database()
        .ref(`users/${user.uid}/company_id`)
        .once("value", snapshot => {
          if (snapshot.val()) {
            const company_id = snapshot.val();
            if (field) {
              const fieldRef = firebase
                .database()
                .ref(`companies/${company_id}/meta/${field}`);
              fieldRef.update(newMeta);
            } else {
              const metaRef = firebase
                .database()
                .ref(`companies/${company_id}/meta`);
              metaRef.update(newMeta);
            }
          }
        });
    }
  });
}

function addSpend(addMoneyList) {
  var unsubscribe = firebase.auth().onAuthStateChanged(user => {
    unsubscribe();
    if (user) {
      firebase
        .database()
        .ref(`users/${user.uid}/company_id`)
        .once("value", snapshot => {
          if (snapshot.val()) {
            const company_id = snapshot.val();
            const numKeys = addMoneyList.length;
            makeKeys(numKeys, data => {
              const addMoney = zipObject(data, addMoneyList);
              const update = {};
              for (const [spendKey, spendItem] of Object.entries(addMoney)) {
                const { promKey } = spendItem;
                update[`actual/${spendKey}`] = spendItem;
                update[`promotion_spend/${promKey}/${spendKey}`] = true;
              }
              const dbRef = firebase.database().ref(`companies/${company_id}`);
              dbRef.update(update);
            });
          }
        });
    }
  });
}

function removeSpend(spendProm, callback) {
  var unsubscribe = firebase.auth().onAuthStateChanged(user => {
    unsubscribe();
    if (user) {
      firebase
        .database()
        .ref(`users/${user.uid}/company_id`)
        .once("value", snapshot => {
          if (snapshot.val()) {
            const company_id = snapshot.val();
            if (spendProm != null) {
              const updateDelete = {};
              for (const [spendKey, promKey] of Object.entries(spendProm)) {
                updateDelete[`actual/${spendKey}`] = null;
                updateDelete[`promotion_spend/${promKey}/${spendKey}`] = null;
              }
              const companyRef = firebase
                .database()
                .ref(`companies/${company_id}`);
              companyRef.update(updateDelete); // updating to null remove keys
            } else {
              const spendRef = firebase
                .database()
                .ref(`companies/${company_id}${categories[4]}`);
              spendRef.remove();
              const promSpendRef = firebase
                .database()
                .ref(`companies/${company_id}${categories[12]}`);
              promSpendRef.remove();
            }
          }
        });
      if (callback) {
        callback();
      }
    }
  });
}

function removeFirebase(type, keys, callback) {
  var unsubscribe = firebase.auth().onAuthStateChanged(user => {
    unsubscribe();
    if (user) {
      firebase
        .database()
        .ref(`users/${user.uid}/company_id`)
        .once("value", snapshot => {
          if (snapshot.val()) {
            const company_id = snapshot.val();
            if (Array.isArray(keys)) {
              const updateDelete = keys.reduce((map, obj) => {
                map[obj] = null;
                return map;
              }, {});
              var categoryRef = firebase
                .database()
                .ref(`companies/${company_id}${categories[type]}`);
              categoryRef.update(updateDelete); // updating to null remove keys
            } else if (keys) {
              var categoryRef = firebase
                .database()
                .ref(`companies/${company_id}${categories[type]}${keys}`);
              categoryRef.remove();
            } else {
              var categoryRef = firebase
                .database()
                .ref(`companies/${company_id}${categories[type]}`);
              categoryRef.remove();
            }
          }
        });
      if (callback) {
        callback(keys);
      }
    }
  });
}

function removeUserFirebase(user, email, callback) {
  const { currentUser } = firebase.auth();

  if (user) {
    // only peform actions if user is defined (if user hasn't logged in and verified account, it will be null)
    removeFirebase(11, user);
    firebase.database().ref(`users/${user}`).remove();
  }
  const emailHash = email.replace(/[^a-zA-Z0-9]/g, "");
  if (emailHash) {
    firebase
      .database()
      .ref(`validEmails/${emailHash}`)
      .remove()
      .then(() => callback());
  }

  const userData = {
    email,
    userid: currentUser.uid
  };
  axios
    .post(`${cloudRunFunctionURL}/api/delete_user_from_authentication`, {
      data: userData
    })
    .catch(error => {
      console.error("error deleting user", error);
    });
}

// removes user if last company is removed
function removeCompanyFromUser(
  email,
  companyId,
  doneCallback,
  callback,
  errorCallback
) {
  const emailHash = email.replace(/\W/g, "");
  const validEmailRef = firebase.database().ref(`validEmails/${emailHash}`);
  validEmailRef.once("value", snapshot => {
    if (snapshot.val()) {
      const validEmail = snapshot.val();
      const validEmailUpdate = {
        access: arrayify(validEmail.access),
        company: arrayify(validEmail.company),
        cid: arrayify(validEmail.cid),
        email: validEmail.email,
        name: validEmail.name,
        selectedCompany: validEmail.selectedCompany
          ? validEmail.selectedCompany
          : 0
      };
      const index = validEmailUpdate.cid.indexOf(companyId);
      validEmailUpdate.access.splice(index, 1);
      validEmailUpdate.company.splice(index, 1);
      const deletedCidArray = validEmailUpdate.cid.splice(index, 1);

      if (index < validEmailUpdate.selectedCompany) {
        validEmailUpdate.selectedCompany -= 1;
      } else if (index === validEmailUpdate.selectedCompany) {
        validEmailUpdate.selectedCompany = 0;
      }

      let deletedField;
      let executeDelete;

      const cid = deletedCidArray[0];
      const companyUsersRef = firebase
        .database()
        .ref(`companies/${cid}/companyUsers`);
      companyUsersRef.on("value", snapshot => {
        let userId = null;
        if (snapshot.val()) {
          const companyUsers = snapshot.val();
          for (const [uid, val] of Object.entries(companyUsers)) {
            if (val.email === email) {
              userId = uid;
            }
          }
        }

        const companyUsersUpdate = {};
        companyUsersUpdate[userId] = null;
        if (validEmailUpdate.company.length >= 1) {
          deletedField = "Company Association";
          executeDelete = () => {
            companyUsersRef.update(companyUsersUpdate, () => {
              validEmailRef.update(validEmailUpdate, () => {
                doneCallback(deletedField);
              });
            });
          };
        } else {
          deletedField = "User";
          executeDelete = () => {
            companyUsersRef.update(companyUsersUpdate, () => {
              removeUserFirebase(userId, email, () => {
                doneCallback(deletedField);
              });
            });
          };
        }
        callback(deletedField, executeDelete);
      });
    } else {
      errorCallback();
    }
  });
}

function uploadFilesToStorage(
  filePaths,
  files,
  doneCallback,
  snapshotCallback,
  errorCallback
) {
  var unsubscribe = firebase.auth().onAuthStateChanged(user => {
    unsubscribe();
    if (user) {
      const bucket = firebase.storage().ref();
      let ctr = 0;
      if (!files.length) {
        doneCallback();
      }
      files.forEach((item, index, array) => {
        const uploadTask = bucket.child(filePaths[index]).put(files[index]);
        uploadTask.on(
          "state_changed",
          snapshot => {
            if (snapshotCallback) {
              snapshotCallback(snapshot);
            }
          },
          error => {
            if (errorCallback) {
              errorCallback(error);
            }
          },
          () => {
            ctr += 1;
            if (doneCallback && ctr == array.length) {
              doneCallback();
            }
          }
        );
      });
    }
  });
}

function removeFilesFromStorage(filePaths, callback) {
  var unsubscribe = firebase.auth().onAuthStateChanged(user => {
    unsubscribe();
    if (user) {
      const bucket = firebase.storage().ref();
      let ctr = 0;
      filePaths.forEach((item, index, array) => {
        bucket
          .child(filePaths[index])
          .delete()
          .then(() => {
            ctr += 1;
            if (callback && ctr == array.length) {
              callback();
            }
          });
      });
    }
  });
}

function downloadFilesFromStorage(
  filePaths,
  fileNames,
  callback,
  errorCallback
) {
  var unsubscribe = firebase.auth().onAuthStateChanged(user => {
    unsubscribe();
    if (user) {
      filePaths.map((item, i) => {
        const filePath = filePaths[i];
        const fileName = fileNames[i];
        firebase
          .storage()
          .ref()
          .child(filePath)
          .getDownloadURL()
          .then(url => {
            const xhr = new XMLHttpRequest();
            xhr.responseType = "blob";
            xhr.onload = function (event) {
              const blob = xhr.response;
              if (callback) {
                callback(blob, fileName);
              }
            };
            xhr.open("GET", url);
            xhr.send();
          })
          .catch(error => {
            if (errorCallback) {
              errorCallback(fileName);
            }
          });
      });
    }
  });
}

function getPermissions(callback) {
  var unsubscribe = firebase.auth().onAuthStateChanged(user => {
    unsubscribe();
    if (user) {
      firebase
        .database()
        .ref(`users/${user.uid}/company_id`)
        .on("value", snapshot => {
          if (snapshot.val()) {
            const company_id = snapshot.val();
            firebase
              .database()
              .ref(`companies/${company_id}/companyUsers/${user.uid}`)
              .on("value", snapshot => {
                if (snapshot.val()) {
                  const companyUser = snapshot.val();
                  firebase
                    .database()
                    .ref(`companies/${company_id}/meta/permissions`)
                    .on("value", snapshot => {
                      let permissions =
                        snapshot.val()[companyUser.access] || [];
                      const { customPerms } = companyUser;
                      const allowed = 1;
                      const disallowed = 3;
                      for (var p in customPerms) {
                        if (
                          customPerms[p] == allowed &&
                          !permissions.includes(p)
                        ) {
                          permissions.push(p);
                        } else if (
                          customPerms[p] == disallowed &&
                          permissions.includes(p)
                        ) {
                          permissions = permissions.filter(x => x != p);
                        }
                      }
                      callback(permissions);
                    });
                }
              });
          }
        });
    }
  });
}

function getUsersByLevel(level, callback) {
  getCompanyUsers(companyUsers => {
    const users = [];
    for (const uid in companyUsers) {
      if (companyUsers[uid].access == level) {
        users.push(uid);
      }
    }
    callback(users);
  });
}

function getUserDataFirebase(callback, field) {
  var unsubscribe = firebase.auth().onAuthStateChanged(user => {
    unsubscribe();
    if (user) {
      if (field) {
        firebase
          .database()
          .ref(`users/${user.uid}/${field}`)
          .on("value", snapshot => {
            if (snapshot.val() && callback) {
              callback(snapshot.val());
            }
          });
      } else {
        firebase
          .database()
          .ref(`users/${user.uid}`)
          .on("value", snapshot => {
            if (snapshot.val() && callback) {
              callback(snapshot.val());
            }
          });
      }
    }
  });
}

function getUserNotifDataFirebase(callback, field) {
  var unsubscribe = firebase.auth().onAuthStateChanged(user => {
    unsubscribe();
    if (user) {
      const fieldStr = field ? `/${field}` : "";
      firebase
        .database()
        .ref(`userNotifs/${user.uid}${fieldStr}`)
        .on("value", snapshot => {
          if (snapshot.val() && callback) {
            callback(snapshot.val());
          }
        });
    }
  });
}

function getFromCurrentUserCompany(pathFromCompany) {
  const user = firebase.auth().currentUser;
  let returnVal = {};
  firebase
    .database()
    .ref(`users/${user.uid}/company_id`)
    .on("value", snapshot => {
      if (snapshot.val()) {
        const companyId = snapshot.val();
        return firebase
          .database()
          .ref(`companies/${companyId}/${pathFromCompany}`)
          .on("value", returnSnapshot => {
            returnVal = returnSnapshot.val();
          });
      }
      // TODO: Move error string / abstract to another layer eventually
      throw new Error("Cannot find associated company");
    });

  if (Object.keys(returnVal).length === 0) {
    throw new Error("Cannot find data at specified path");
  }
  return returnVal;
}

function updateCurrentUserCompany(pathFromCompany, newValue) {
  return new Promise(resolve => {
    const user = firebase.auth().currentUser;
    firebase
      .database()
      .ref(`users/${user.uid}/company_id`)
      .once("value", async snapshot => {
        if (snapshot.val()) {
          const companyId = snapshot.val();
          const ref = firebase
            .database()
            .ref(`companies/${companyId}/${pathFromCompany}`);
          await ref.update(newValue);
          resolve();
        }
      });
  });
}

function pushToCurrentUserCompany(pathFromCompany, newValue) {
  return new Promise(resolve => {
    const user = firebase.auth().currentUser;
    firebase
      .database()
      .ref(`users/${user.uid}/company_id`)
      .on("value", async snapshot => {
        if (snapshot.val()) {
          const companyId = snapshot.val();
          const ref = firebase
            .database()
            .ref(`companies/${companyId}/${pathFromCompany}`);
          await ref.push().set(newValue);
          resolve();
        }
      });
  });
}

function updateUserDataFirebase(newUser, callback) {
  var unsubscribe = firebase.auth().onAuthStateChanged(user => {
    unsubscribe();
    if (user) {
      const userRef = firebase.database().ref(`users/${user.uid}`);
      userRef.update(newUser, () => {
        if (callback) {
          callback();
        }
      });
    }
  });
}

function updateUserNotifDataFirebase(newUser, callback) {
  var unsubscribe = firebase.auth().onAuthStateChanged(user => {
    unsubscribe();
    if (user) {
      const userRef = firebase.database().ref(`userNotifs/${user.uid}`);
      userRef.update(newUser, () => {
        if (callback) {
          callback();
        }
      });
    }
  });
}

// gets list of whitelisted, blacklisted, and used domains
function getUserDomains(callback, listen = true) {
  var unsubscribe = firebase.auth().onAuthStateChanged(user => {
    unsubscribe();
    if (user) {
      if (listen) {
        firebase
          .database()
          .ref("userDomains/")
          .on("value", snapshot => {
            if (snapshot.val()) {
              const domains = snapshot.val();
              callback(domains || {});
            }
          });
      }
    }
  });
}

// sets list of whitelisted, etc. domains
function setUserDomains(callback, domainType, newDomainList) {
  const user = firebase.auth().currentUser;
  if (user) {
    const domainListRef = firebase.database().ref(`userDomains/${domainType}/`);
    if (domainListRef) {
      domainListRef.set(newDomainList, () => {
        if (callback) {
          callback();
        }
      });
    }
  }
}

// add a single whitelisted, etc. domain
function addUserDomain(callback, domainType, newDomain) {
  const user = firebase.auth().currentUser;
  if (user) {
    const domainListRef = firebase.database().ref(`userDomains/${domainType}/`);
    if (domainListRef) {
      domainListRef.push(newDomain, () => {
        if (callback) {
          callback();
        }
      });
    }
  }
}

function getCompanyUsers(callback, listen = true) {
  var unsubscribe = firebase.auth().onAuthStateChanged(user => {
    unsubscribe();
    if (user) {
      if (listen) {
        firebase
          .database()
          .ref(`users/${user.uid}/company_id`)
          .on("value", snapshot => {
            if (snapshot.val()) {
              const company_id = snapshot.val();
              firebase
                .database()
                .ref(`companies/${company_id}/companyUsers`)
                .on("value", snapshot => {
                  callback(snapshot.val() || {});
                });
            }
          });
      } else {
        firebase
          .database()
          .ref(`users/${user.uid}/company_id`)
          .once("value", snapshot => {
            if (snapshot.val()) {
              const company_id = snapshot.val();
              firebase
                .database()
                .ref(`companies/${company_id}/companyUsers`)
                .once("value", snapshot => {
                  callback(snapshot.val() || {});
                });
            }
          });
      }
    }
  });
}

function updateCompanyFirebase(newItem, callback) {
  const user = firebase.auth().currentUser;
  if (user) {
    firebase
      .database()
      .ref(`users/${user.uid}/company_id`)
      .once("value", snapshot => {
        if (snapshot.val()) {
          const company_id = snapshot.val();
          const companyRef = firebase.database().ref(`companies/${company_id}`);
          companyRef.update(newItem, () => {
            if (callback) {
              callback();
            }
          });
        }
      });
  }
}

function changeUserCompany(nextCompany, nextCompanyId, callback) {
  const user = firebase.auth().currentUser;
  const userEmail = user.email;
  const emailHash = userEmail.replace(/[^a-zA-Z0-9]/g, "");
  const dbRef = firebase.database().ref();
  const updateEntries = {};

  dbRef.child(`validEmails/${emailHash}`).once("value", snapshot => {
    const validEmail = snapshot.val();
    if (!arrayify(validEmail.company).includes(nextCompany)) {
      updateEntries[`validEmails/${emailHash}/company`] = arrayify(
        validEmail.company
      ).concat(nextCompany);
      updateEntries[`validEmails/${emailHash}/cid`] = arrayify(
        validEmail.cid
      ).concat(nextCompanyId);
      updateEntries[`validEmails/${emailHash}/selectedCompany`] = arrayify(
        validEmail.company
      ).length;
      if (isUserCresicorEmployee()) {
        updateEntries[`validEmails/${emailHash}/access`] = arrayify(
          validEmail.access
        ).concat("Admin");
      } else {
        updateEntries[`validEmails/${emailHash}/access`] = arrayify(
          validEmail.access
        ).concat("Viewer");
      }
    } else {
      updateEntries[`validEmails/${emailHash}/selectedCompany`] = arrayify(
        validEmail.company
      ).indexOf(nextCompany);
    }
    updateEntries[`users/${user.uid}/company`] = nextCompanyId;
    updateEntries[`users/${user.uid}/company_id`] = nextCompanyId;
    dbRef.update(updateEntries).then(callback());
  });
}

function createCompanyHash(newCompany) {
  const rng = seedrandom(newCompany); // deterministic seed for hash
  const idLength = 10; // length of company id
  const charset =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghikjlmnopqrstuvwxyz0123456789";
  let cid = "";
  for (let i = 0; i < idLength; i++) {
    const ind = parseInt(rng() * charset.length);
    cid += charset[ind];
  }

  return cid;
}

async function addNewCompany(newCompany) {
  const snapshot = await firebase.database().ref("companyNames").once("value");

  if (!snapshot.val()) return;
  const companyNames = snapshot.val();
  const companyIds = new Set(Object.keys(companyNames));

  // generate key for new company
  let stop = false;
  let seed = newCompany;
  while (!stop) {
    const now = new Date();
    var cid = createCompanyHash(seed);
    stop = !companyIds.has(cid);
    seed += "a"; // modify seed until a distinct id is generated
  }

  // push company's data to Firebase
  const companyNamesUpdate = {};
  companyNamesUpdate[cid] = newCompany;
  await firebase.database().ref("companyNames").update(companyNamesUpdate);
  const newCompanyRef = firebase.database().ref(`companies/${cid}/meta`);
  await newCompanyRef.set({
    cresicor_tpm: true,
    implementation: true,
    demo: false,
    statuses: [
      "Pending",
      "Submitted",
      "Approved",
      "Finalized",
      "Running",
      "Completed",
      "Declined",
      "Cancelled"
    ],
    planning: [0, 1, 2, 3],
    execution: [4],
    completed: [5],
    failed: [6, 7],
    permissions: {
      Admin: [
        "add",
        "contact",
        "promotions",
        "edit",
        "revenue",
        "approve",
        "spend",
        "admin",
        "cancel",
        "masteredit",
        "drag",
        "pricing",
        "lift",
        "broker",
        "forecast",
        "reconciliation",
        "approveInvoices",
        "cancelInvoicesTransactions",
        "submit",
        "close",
        "addContacts",
        "editContacts",
        "approvalLevel",
        "viewBusiness",
        "viewManage",
        "viewCommissions",
        "viewInsights"
      ],
      Director: [
        "promotions",
        "approve",
        "drag",
        "cancel",
        "masteredit",
        "submit"
      ],
      Manager: ["add", "edit", "revenue", "spend", "drag", "approve", "submit"],
      Viewer: "None"
    },
    notif_settings: {
      addProm: true
    },
    tier: "aspen",
    extra_spend_fields: {
      "Forecast Miss": 0
    },
    possible_type_fields: {
      buyin: "Buy-in",
      instore: "In-Store",
      lumpsum: "Lump-Sum",
      rate: "Rate",
      scanback: "Scan-back"
    },
    revenue_sources: ["SPINS", "IRI", "Whole Foods", "Quick Books"],
    start_year: new Date().getFullYear(),
    defaultCustomerAltNameTags: {
      0: { tag: "Syndicated Data" },
      1: { tag: "Accounting Source" },
      2: { tag: "Deductions" }
    }
  });
  return cid;
}

function getLinesFirebase(callback) {
  getFirebase(
    0,
    promotions => {
      const allLines = {};
      const promOmitFields = [
        "lines",
        "totalExpSpend",
        "totalExpSales",
        "closed"
      ]; // prom fields that should NOT be passed to each line
      for (const promKey in promotions) {
        const prom = promotions[promKey];
        for (const lineKey in prom.lines) {
          const line = Object.assign(
            omit(prom, promOmitFields),
            prom.lines[lineKey]
          );
          line.promKey = promKey;
          line.lineKey = lineKey;
          line.promName = prom.name;
          line.month = prom.month;
          line.year = prom.year;
          line.name = `${line.promName} (${
            meta.fundTypes?.[line.type]?.name
          } - ${
            line.productGroup == "custom"
              ? "Custom"
              : meta.product_groups[line.productGroup].name
          })`;
          if (!("closed" in line)) {
            line.closed = false;
          }
          allLines[lineKey] = line;
        }
      }
      if (callback) callback(allLines);
    },
    undefined
  );
}

function getJSXListsFirebase(
  db: DbContextValues,
  callback,
  fieldsList,
  useProductGroups = true
) {
  const allJSX = {};
  const { meta } = db;
  if (!fieldsList || fieldsList.indexOf("products") != -1) {
    // Get all products
    const allProducts = db.products;
    const productsJSX = [];
    const productsList = sortByField("name", objToArray(allProducts), true);
    for (var i = 0; i < productsList.length; i++) {
      const product = productsList[i];
      productsJSX.push(
        <MenuItem
          value={product.key}
          children={product.name}
          key={product.key}
        />
      );
    }
    if (useProductGroups) {
      for (const key in meta.product_groups) {
        const pg = meta.product_groups[key];
        productsJSX.push(
          <MenuItem value={key} children={`*${pg.name}`} key={key} />
        );
      }
    }
    allJSX.productsJSX = productsJSX;
  }

  if (!fieldsList || fieldsList.indexOf("customers") != -1) {
    // Get all customers
    const allCustomers = db.customers;
    const customersJSX = [];
    const customersList = sortByField("name", objToArray(allCustomers), true);
    for (var i = 0; i < customersList.length; i++) {
      const customer = customersList[i];
      customersJSX.push(
        <MenuItem
          value={customer.key}
          children={customer.name}
          key={customer.key}
          customer={customer}
        />
      );
    }
    allJSX.customersJSX = customersJSX;
  }

  if (!fieldsList || fieldsList.indexOf("contacts") != -1) {
    // Get all contacts
    const allContacts = db.contacts;
    const contactsJSX = [];
    const contactsList = sortByField("name", objToArray(allContacts), true);
    for (var i = 0; i < contactsList.length; i++) {
      const contact = contactsList[i];
      contactsJSX.push(
        <MenuItem
          value={contact.key}
          children={contact.name}
          key={contact.key}
        />
      );
    }
    allJSX.contactsJSX = contactsJSX;
  }

  if (!fieldsList || fieldsList.indexOf("promotions") != -1) {
    // Get all promotions
    const allPromotions = db.promotions;
    const promotionsJSX = [];
    const promotionsList = sortByField("name", objToArray(allPromotions), true);
    for (var i = 0; i < promotionsList.length; i++) {
      const promotion = promotionsList[i];
      promotionsJSX.push(
        <MenuItem
          value={promotion.key}
          children={promotion.name}
          key={promotion.key}
        />
      );
    }
    allJSX.promotionsJSX = promotionsJSX;
  }

  if (!fieldsList || fieldsList.indexOf("accounts") != -1) {
    const allAccounts = db.accounts;
    const accountsJSX = Object.entries(meta.fundTypes ?? {}).map(
      ([k, type]) => {
        return (
          <MenuItem value={type?.accountKey} children={type?.name} key={k} />
        );
      }
    );
    allJSX.accountsJSX = accountsJSX;
  }

  if (!fieldsList || fieldsList.indexOf("types") != -1) {
    const { fundTypes = {} } = meta;
    const typesJSX = fundTypes
      ? Object.entries(fundTypes)
          .sort(([k1, v1], [k2, v2]) => (v1.name < v2.name ? -1 : 1))
          .map(([key, value]) => (
            <MenuItem value={key} children={value.name} key={key} />
          ))
      : [];
    allJSX.typesJSX = typesJSX;
  }

  // filter: means based on the user's permissions
  if (
    !fieldsList ||
    fieldsList.indexOf("statuses") != -1 ||
    fieldsList.indexOf("filteredStatuses") != -1
  ) {
    const { statuses } = meta;
    const statusesJSX = [];
    const filteredStatusesJSX = [];

    const descriptions = {
      0: "Default initial status upon creation",
      1: "Awaiting approval",
      2: "Successfully obtained approval",
      3: "Ready for execution",
      4: "Currently executing",
      5: "Past promotion end date or manually closed",
      6: "Failed to obtain approval",
      7: "Cancelled"
    };

    getPermissions(permissionsData => {
      permissions = permissionsData;
    });

    // filter based on permissions
    const isDisabled = {
      0: false, // Pending
      1: !permissions.includes("submit"), // Submitted
      2:
        !permissions.includes("approve") ||
        (fieldsList && fieldsList.includes("filteredStatusesOverLimit")), // Approved
      3: true, // Finalized --> ignore this for now
      4:
        !permissions.includes("approve") ||
        (fieldsList && fieldsList.includes("filteredStatusesOverLimit")), // Running
      5: !permissions.includes("admin"), // Completed
      6:
        !permissions.includes("approve") ||
        (fieldsList && fieldsList.includes("filteredStatusesOverLimit")), // Declined
      7:
        !permissions.includes("cancel") ||
        (fieldsList && fieldsList.includes("filteredStatusesDisableCancelled")) // Cancelled
    };

    if (statuses) {
      for (var i = 0; i < statuses.length; i++) {
        if (statuses[i] == "Finalized") continue; // ignore this for now

        // unfiltered
        if (!fieldsList || fieldsList.indexOf("statuses") != -1) {
          statusesJSX.push(
            <MenuItem
              value={i}
              key={i}
              children={i == 7 ? "Cancelled" : statuses[i]}
              secondary={
                <small
                  style={{
                    color: "#282626",
                    fontWeight: 300,
                    marginLeft: 5
                  }}>
                  {descriptions[i]}
                </small>
              }
            />
          );
        }

        // filtered
        if (!fieldsList || fieldsList.indexOf("filteredStatuses") != -1) {
          filteredStatusesJSX.push(
            <MenuItem
              value={i}
              key={i}
              disabled={isDisabled[i]}
              children={i == 7 ? "Cancelled" : statuses[i]}
              secondary={
                <small
                  style={{
                    color: "#282626",
                    fontWeight: 300,
                    marginLeft: 5
                  }}>
                  {descriptions[i]}
                </small>
              }
            />
          );
        }
      }
    }
    allJSX.statusesJSX = statusesJSX;
    allJSX.filteredStatusesJSX = filteredStatusesJSX;
  }

  if (!fieldsList || fieldsList.indexOf("product_groups") != -1) {
    const productGroups = meta.product_groups;
    const productGroupsJSX = [];
    if (productGroups) {
      for (var i in productGroups) {
        productGroupsJSX.push(
          <MenuItem
            value={i}
            children={productGroups[i].name}
            key={i}
            productGroup={productGroups[i]}
          />
        );
      }
    }
    allJSX.productGroupsJSX = productGroupsJSX;
  }

  if (!fieldsList || fieldsList.indexOf("reports") != -1) {
    const { reports } = db;
    const reportsJSX = [];
    if (reports) {
      for (var i in reports) {
        reportsJSX.push(
          <MenuItem value={i} children={reports[i].name} key={i} />
        );
      }
    }
    allJSX.reportsJSX = reportsJSX;
  }

  callback(allJSX);
}

function setCompany(user, userData, callback) {
  const selectedCompany = userData.selectedCompany
    ? userData.selectedCompany
    : 0;
  const userObj = {
    company: arrayify(userData.cid)[selectedCompany],
    company_id: arrayify(userData.cid)[selectedCompany],
    displayName: userData.name,
    email: userData.email
    // notif_settings: {
    //   addProm: false,
    // }
  };
  const companyUsersRef = firebase
    .database()
    .ref(`companies/${userObj.company_id}/companyUsers`);
  companyUsersRef.once("value", snapshot => {
    const companyUsers = snapshot.val() || {};
    if (!(user.uid in companyUsers)) {
      const companyUsersUpdate = {};
      companyUsersUpdate[user.uid] = {
        name: user.displayName || userData.name,
        access: arrayify(userData.access)[selectedCompany],
        email: userData.email
      };
      companyUsersRef.update(companyUsersUpdate);
    }
  });
  firebase
    .database()
    .ref(`users/${user.uid}`)
    .once("value", snapshot => {
      if (!snapshot.val()) {
        firebase
          .database()
          .ref(`users/${user.uid}`)
          .set(userObj)
          .then(() => callback(userData));
      } else {
        changeUserCompany(
          arrayify(userData.company)[selectedCompany],
          userObj.company_id,
          () => {
            callback(userData);
          }
        );
      }
    });
}

function isEmailValid(user, trueCallback, falseCallback) {
  const userEmail = user.email;
  const emailHash = userEmail.replace(/[^a-zA-Z0-9]/g, "");
  firebase
    .database()
    .ref(`validEmails/${emailHash}`)
    .on("value", snapshot => {
      const userData = snapshot.val();
      const selectedCompany = userData?.selectedCompany ?? 0;
      const companyid = arrayify(userData?.cid)?.[selectedCompany];
      firebase
        .database()
        .ref(`companies/${companyid}/meta`)
        .once("value", metaSnapshot => {
          meta = metaSnapshot.val() || {};
          const enabled =
            meta.cresicor_tpm || meta.cresicor_deductions_scanning;
          if (enabled && snapshot.val()) {
            setCompany(user, snapshot.val(), trueCallback);
          } else {
            falseCallback();
          }
        });
    });
}

function getCompanyNamesFirebase(callback) {
  var unsubscribe = firebase.auth().onAuthStateChanged(user => {
    unsubscribe();
    if (user) {
      firebase
        .database()
        .ref("companyNames")
        .on("value", snapshot => callback(snapshot.val()));
    }
  });
}

function getAdminPanelEventsFirebase(callback) {
  firebase.auth().onAuthStateChanged(user => {
    unsubscribe();
    if (user) {
      firebase
        .database()
        .ref("adminPanelEvents")
        .on("value", snapshot => callback(snapshot.val()));
    }
  });
}

function isUserCresicorEmployee() {
  const user = firebase.auth().currentUser;
  if (user) {
    const { email } = firebase.auth().currentUser;
    return COMPANY_EMAIL_DOMAINS.some(domain => email.endsWith(domain));
  }
  return false;
}

function getAllowedCompanies(callback) {
  if (!isUserCresicorEmployee()) {
    const userEmail = firebase.auth().currentUser.email;
    const emailHash = userEmail.replace(/[^a-zA-Z0-9]/g, "");
    firebase
      .database()
      .ref(`validEmails/${emailHash}`)
      .on("value", snapshot => {
        const validEmailObj = snapshot.val();
        if (callback) {
          callback(validEmailObj);
        }
      });
  }
}

function getSoftwareVersionFirebase(callback) {
  firebase
    .database()
    .ref("softwareVersion")
    .on("value", snapshot => {
      if (snapshot.val()) {
        callback(snapshot.val());
      }
    });
}

function getSoftwareVersionFirebaseOnce(callback) {
  firebase
    .database()
    .ref("softwareVersion")
    .once("value", snapshot => {
      if (snapshot.val()) {
        callback(snapshot.val());
      }
    });
}

// Generic function to subscribe to value at a given ref
function getValueFirebase(
  ref: string,
  callback: (val: firebase.database.DataSnapshot) => void
) {
  firebase
    .database()
    .ref(ref)
    .on("value", snapshot => {
      callback(snapshot);
    });
}

export {
  CATEGORIESMAP,
  makeKeys,
  processProm,
  addFirebase,
  getFirebase,
  getFromCurrentUserCompany,
  updateCurrentUserCompany,
  pushToCurrentUserCompany,
  updateFirebase,
  batchEditPromotions,
  updateStatusFirebase,
  updatePricingFirebase,
  batchUpdatePricingFirebase,
  updateLiftFirebase,
  updateSeasonalityFirebase,
  batchUpdateFirebase,
  addSpend,
  removeSpend,
  removeFirebase,
  getMetaFirebase,
  updateMetaFirebase,
  getPromotionsFirebase,
  getEnhancedPromsFirebase,
  downloadFilesFromStorage,
  uploadFilesToStorage,
  getPermissions,
  getUsersByLevel,
  getUserDataFirebase,
  getUserNotifDataFirebase,
  updateUserDataFirebase,
  updateUserNotifDataFirebase,
  getUserDomains,
  setUserDomains,
  addUserDomain,
  getCompanyUsers,
  sendNotif,
  generateRandomKey,
  generateLongRandomKey,
  getLinesFirebase,
  getJSXListsFirebase,
  updateLineFirebase,
  updateCompanyFirebase,
  isEmailValid,
  getAdminPanelEventsFirebase,
  getCompanyNamesFirebase,
  changeUserCompany,
  isUserCresicorEmployee,
  getAllowedCompanies,
  removeUserFirebase,
  addNewCompany,
  removeFilesFromStorage,
  getArchiveMetadata,
  getSoftwareVersionFirebase,
  getSoftwareVersionFirebaseOnce,
  removeCompanyFromUser,
  getFirebaseConsoleURL,
  firebase,
  cloudRunURL,
  cloudRunFunctionURL,
  forecastCloudRunURL,
  getFirebaseTS,
  getFirebaseTSOnce,
  getArchiveTS,
  addFirebaseTS,
  updateFirebaseTS,
  batchUpdateFirebaseTS,
  getValueFirebase,
  COMPANY_EMAIL_DOMAINS
};
