import React from "react";
import FileSaver from "file-saver";
import DownloadIcon from "@mui/icons-material/GetApp";
import Button from "ui-library/Button";

import { downloadFilesFromStorage } from "helpers/Firebase";

class DownloadSample extends React.Component {
  constructor(props) {
    super(props);
    this.state = { file: null, loadingFile: false };
  }

  saveFile = (blob, fileName) => {
    this.setState({ loadingFile: false });
    FileSaver.saveAs(blob, fileName);
  };

  // File name should match name of file uploaded in Firebase:
  // https://console.firebase.google.com/u/0/project/cresicor-dev/storage/cresicor-dev.appspot.com/files/~2FSamples
  downloadFile = sampleName => {
    const fileNames = {
      Promotions: "Test_Prom_Data.xlsx",
      Spend: "Test_Spend_Data.xlsx",
      Products: "Test_Product_Data.xlsx",
      Contacts: "Test_Contacts_Data.xlsx",
      Revenue: "Test_Revenue_Data.xlsx",
      Pricing: "Test_Pricing_Data.xlsx",
      bumpChart: "Test_Bump_Chart_Data.xlsx",
      Lift: "Test_Lift_Data.csv",
      Budget: "Test_Budget_Data.xlsx",
      "Latest Estimate": "Test_Latest_Estimate_Data.xlsx",
      InvoiceLines: "Test_Invoice_Lines_Data.xlsx",
      ERPTransactions: "Test_ERP_Transactions_Data.xlsx"
    };
    const fileName = fileNames?.[sampleName] || "Test_Customers_Data.xlsx";

    this.setState({ loadingFile: true });
    downloadFilesFromStorage(
      [`Samples/${fileName}`],
      [fileName],
      this.saveFile
    );
  };

  render() {
    return (
      <div>
        <Button
          sx={{ mt: 2 }}
          label={
            this.state.loadingFile ? "Downloading..." : "Download Sample File"
          }
          onClick={this.downloadFile.bind(null, this.props.sampleName)}
          icon={<DownloadIcon />}
          disabled={this.state.loadingFile}
        />
      </div>
    );
  }
}

export default DownloadSample;
