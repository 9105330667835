import React, { useMemo } from "react";
import { useDb } from "contexts/Db";
import { RepaymentLineDisplayService } from "reconciliation-services";
import { repaymentLineTypes } from "deductions-constants/ReconciliationTypes";
import CollapsibleTable, {
  ColumnConfigOptions
} from "ui-library/CollapsibleTable/CollapsibleTable";
import moment from "moment";
import { DisplayRepaymentLineObject } from "components/Deductions/DeductionsReconciliation/types/repaymentLineTypes";
import { findInvoiceLineRepaymentLines } from "components/Deductions/DeductionsReconciliation/services/RepaymentServices/repaymentLineFilter";
import { RepaymentLineObject } from "components/Deductions/models";
import { renderUTCDateTimeInLocalTimeZone } from "helpers/Time";

export interface RepaymentLineHistoryProps {
  invoiceLineKey: string;
  invoiceKey: string;
}

export default function RepaymentLineHistoryTable(
  props: RepaymentLineHistoryProps
): JSX.Element {
  const db = useDb();
  const { invoices = {}, erpTransactions = {} } = db;
  const { invoiceLineKey, invoiceKey } = props;

  const invoice = invoices[invoiceKey];
  const { invoiceLines = {} } = invoice;
  const invoiceLine = invoiceLines[invoiceLineKey] || undefined;

  const repaymentLines: RepaymentLineObject[] = useMemo(() => {
    if (invoice && invoiceLine) {
      return findInvoiceLineRepaymentLines(
        invoiceLine,
        invoices,
        erpTransactions
      );
    }
    return [];
  }, [erpTransactions, invoice, invoiceLine, invoices]);

  const repaymentLinesList: DisplayRepaymentLineObject[] = useMemo(() => {
    return repaymentLines
      .map(repaymentLine =>
        RepaymentLineDisplayService.processRepaymentLineForDisplay(
          repaymentLine,
          db
        )
      )
      .filter(line => line.type === repaymentLineTypes.REPAYMENT)
      .sort((repaymentLine1, repaymentLine2) => {
        return moment(repaymentLine1.createdDate).diff(
          moment(repaymentLine2.createdDate)
        );
      });
  }, [repaymentLines, db]);

  const repaymentColumnConfig = useMemo(
    () =>
      new Map([
        [
          "createdDate",
          {
            name: "Date",
            render: val => renderUTCDateTimeInLocalTimeZone(val as string)
          }
        ],
        [
          "displayType",
          {
            name: "Activity",
            render: val => (!val ? "N/A" : val)
          }
        ],
        [
          "displayRepaymentId",
          {
            name: "Repayment ID"
          }
        ],
        [
          "displayAmount",
          {
            name: "Amount"
          }
        ],
        [
          "displayFundType",
          {
            name: "Fund Type",
            render: val => (!val ? "N/A" : val)
          }
        ],
        [
          "displayFundTypeAccount",
          {
            name: "GL Account",
            render: val => (!val ? "N/A" : val)
          }
        ],
        [
          "createdUser",
          {
            name: "User",
            render: val => db.companyUsers[val as string]?.name || "Unknown"
          }
        ]
      ] as [keyof DisplayRepaymentLineObject, ColumnConfigOptions<DisplayRepaymentLineObject>][]),
    [db.companyUsers]
  );

  return (
    <CollapsibleTable<DisplayRepaymentLineObject>
      data={repaymentLinesList}
      columnConfig={repaymentColumnConfig}
      index="key"
    />
  );
}
