import React from "react";
import DataTable from "components/tables/MuiDataTable";
import { FREE_TRIAL_DAYS, SPECIAL_TRIAL_DAYS } from "../../constants/FreeTrial";

import { MS_DAY } from "../../constants/Time";

class FreeTrial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  runMountTasks() {
    const freeTrial = this.props.db.meta.free_trial;

    if (!freeTrial) {
      return;
    }

    this.setState({
      freeTrial: {
        maxDays: freeTrial?.special_trial
          ? SPECIAL_TRIAL_DAYS
          : FREE_TRIAL_DAYS,
        onTrial: freeTrial.on_trial,
        startDate: new Date(freeTrial.start_date),
        pagesIn: freeTrial.pages_in
      }
    });
  }

  componentDidMount() {
    this.runMountTasks();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.companyid != this.props.companyid) {
      this.runMountTasks();
    }
  }

  render() {
    const { freeTrial } = this.state;
    const daysIn = Math.floor((new Date() - freeTrial?.startDate) / MS_DAY) + 1;
    return (
      <div>
        {this.state.freeTrial ? (
          <DataTable
            title="Free Trial Details"
            data={{
              Type: ["Days"],
              "Amount Remaining": [Math.max(freeTrial.maxDays - daysIn, 0)]
            }}
            widths={["50%", "50%"]}
          />
        ) : (
          "You are not currently on a free trial."
        )}
      </div>
    );
  }
}

export default FreeTrial;
