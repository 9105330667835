import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, TextField, Checkbox, FormControlLabel } from "@mui/material";
import { useDb } from "contexts/Db";
import { useGetForecastVersions } from "./api";
import { EditTable } from "./EditTable";
import { ForecastVersion } from "./types";
import { isDefined, renderVersionToDefaultString } from "./utilities";

export const EditForecast = () => {
  const [versionSearch, setVersionSearch] = useState("");
  const [hideUnnamed, setHideUnnamed] = useState(false);
  const history = useHistory();
  const { companyid, meta } = useDb();
  const overrideVersioning = !!meta.featureGates?.overrideVersioning;
  const [{ data }, refetch] = useGetForecastVersions({
    company_id: companyid
  });

  const versions: ForecastVersion[] = useMemo(
    () => data?.versions ?? [],
    [data, companyid]
  );

  const filteredVersions = useMemo(() => {
    const v = hideUnnamed ? versions.filter(v => !!v.version_name) : versions;

    return v.filter(version => {
      return [
        version.filename,
        version.version_name,
        version.version_num,
        version.creation_time,
        renderVersionToDefaultString(version)
      ]
        .filter(isDefined)
        .map(p => p.toString().toLowerCase())
        .join(" ")
        .includes(versionSearch.toLowerCase());
    });
  }, [versions, versionSearch, hideUnnamed]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        minHeight: "100%",
        width: "100%",
        padding: "24px"
      }}>
      <div>
        <Button
          onClick={() => {
            history.push("/forecast2/retailers/customers");
          }}>
          Back to Forecast
        </Button>
      </div>
      <div
        style={{
          flex: 1,
          maxWidth: "100%",
          minWidth: "100%"
        }}>
        <div
          style={{
            padding: "12px 0",
            display: "flex",
            justifyContent: "space-between"
          }}>
          <TextField
            label="Search"
            value={versionSearch}
            onChange={e => setVersionSearch(e.target.value)}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={hideUnnamed}
                onChange={() => setHideUnnamed(prevState => !prevState)}
              />
            }
            label="Hide Unnamed Versions"
          />
        </div>
        <EditTable
          versions={filteredVersions}
          showCopyButton={overrideVersioning}
          refetch={refetch}
        />
      </div>
    </div>
  );
};

export default EditForecast;
