import React from "react";
import {
  Select as MUISelect,
  InputLabel,
  FormHelperText,
  SelectProps as MUISelectProps,
  FormControl,
  FormControlProps
} from "@mui/material";

import { styled } from "@mui/material/styles";

const StyledFormControl = styled(FormControl)<
  FormControlProps & { isLabel: boolean }
>(({ fullWidth, size, variant, isLabel }) => ({
  width: fullWidth ? "100%" : 256,
  "& .MuiInputBase-input": {
    fontFamily: "Monument Grotesk Regular",
    ...(size === "small" &&
      variant === "outlined" && {
        padding: "12.5px 32px 12.5px 14px"
      }),
    ...(!isLabel &&
      variant === "filled" && {
        padding:
          size === "small"
            ? "12.5px 32px 12.5px 12px"
            : "16.5px 32px 16.5px 12px"
      })
  }
}));

const onChangeWrapper = onChange => {
  const wrappedOnChange = event => {
    const { value } = event.target;
    onChange(event, null, value);
  };
  return wrappedOnChange;
};

type SelectProps = MUISelectProps & {
  floatingLabelText?: string;
  errorText?: string;
  shrink?: boolean;
  style?: Record<string, string | number>;
  labelStyle?: Record<string, string | number>;
};

function Select(props: SelectProps) {
  const {
    children,
    floatingLabelText,
    size = "medium",
    value,
    onChange,
    multiple,
    errorText,
    style,
    labelStyle,
    shrink,
    MenuProps,
    variant = "outlined",
    ...other
  } = props;
  return (
    <StyledFormControl
      style={style}
      size={size}
      fullWidth={props.fullWidth}
      isLabel={!!floatingLabelText}
      variant={variant}
      error={!!errorText}>
      {floatingLabelText && (
        <InputLabel shrink={shrink}>{floatingLabelText}</InputLabel>
      )}
      <MUISelect
        value={value ?? (multiple ? [] : "")}
        onChange={onChangeWrapper(onChange)}
        multiple={multiple}
        displayEmpty
        label={floatingLabelText}
        MenuProps={{
          PaperProps: {
            sx: {
              background: theme => theme.palette.readOnly.surface2
            }
          },
          disableScrollLock: true,
          style: { zIndex: 2000 },
          ...MenuProps
        }}
        {...other}>
        {children}
      </MUISelect>
      <FormHelperText>{errorText}</FormHelperText>
    </StyledFormControl>
  );
}

export default Select;
