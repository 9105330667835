import React from "react";

import { RootStateOrAny, useSelector } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import { red } from "@mui/material/colors";

import {
  AssignPagesErrorObject,
  EnterMetadataErrorObject,
  UploadFilesErrorObject,
  uploadSteps
} from "./redux/definitions";

const red400 = red["400"];

const useStyles = makeStyles(() => ({
  errorMessage: {
    marginTop: "8px",
    color: red400
  }
}));

const convertErrorTypeToMessage: {
  uploadFiles: Partial<Record<keyof UploadFilesErrorObject, string>>;
  assignPages: Record<keyof AssignPagesErrorObject, string>;
  enterMetadata: Record<keyof EnterMetadataErrorObject, string>;
} = {
  uploadFiles: {
    noFilesUploaded: "Please upload at least one file before proceeding."
  },
  assignPages: {
    missingInvoiceNumber: "At least one invoice is missing an invoice number.",
    invoiceNumberExists:
      "At least one invoice number already exists in the system. All invoice numbers must be unique.",
    duplicateInvoiceNumber:
      "There are duplicate invoice numbers in this batch. All invoice numbers must be unique.",
    noAssignedFile: "At least one invoice is missing an assigned file.",
    reversedPageRange:
      "Please ensure that all start pages come before end pages.",
    invalidStartPage:
      "Please ensure that any start pages are between 1 and the total # of pages.",
    invalidEndPage:
      "Please ensure that any end pages are between 1 and the total # of pages."
  },
  enterMetadata: {
    missingInvoiceNumber: "At least one invoice is missing an invoice number.",
    missingCustomer: "At least one invoice is missing a customer name.",
    missingAmount: "At least one invoice is missing a dollar amount.",
    invalidAmount:
      "Please ensure that all invoice amounts are valid dollar amounts.",
    zeroAmount: "At least one invoice has a dollar amount set to zero",
    invalidStartDate: "Please ensure that all invoices have valid start dates.",
    invalidEndDate: "Please ensure that all invoices have valid end dates.",
    endDateBeforeStartDate:
      "At least one invoice has an end date that is before its start date."
  }
};

interface ErrorMessageProps {
  forceUnknownError: Boolean | null;
}

export default function ErrorMessage(props: ErrorMessageProps) {
  const classes = useStyles();

  const { currentUploadStep, errors } = useSelector(
    (state: RootStateOrAny) => state.uploadBackup
  );

  const { forceUnknownError } = props;

  const getFirstErrorString = (errorClass: string) => {
    const errorMessages: string[][] = errors[errorClass]
      .map(
        (
          errorObject:
            | UploadFilesErrorObject
            | AssignPagesErrorObject
            | EnterMetadataErrorObject,
          _errorIdx: number
        ) =>
          Object.entries(errorObject)
            .map(([errorType, value]) =>
              value ? convertErrorTypeToMessage[errorClass][errorType] : null
            )
            .filter((message: string) => !!message)
      )
      .filter((allMessages: string[]) => allMessages.length > 0);

    return errorMessages.length > 0 ? errorMessages[0][0] : "";
  };

  function getPageSpecificErrorString() {
    switch (currentUploadStep) {
      case uploadSteps.ADD_FILES_AND_ASSIGN_PAGES: {
        return (
          getFirstErrorString("uploadFiles") ||
          getFirstErrorString("assignPages")
        );
      }
      case uploadSteps.ADD_METADATA: {
        return getFirstErrorString("enterMetadata");
      }
      default: {
        return "";
      }
    }
  }

  return forceUnknownError ? (
    <div className={classes.errorMessage}>
      Unknown error occurred. Please try again or contact support if this error
      persists.
    </div>
  ) : (
    <div className={classes.errorMessage}>{getPageSpecificErrorString()}</div>
  );
}
