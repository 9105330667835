import { firebase, cloudRunURL } from "helpers/Firebase";
import axios from "axios";
import { FileObject, InvoiceObject } from "components/Deductions/models";
import { importBackupSources } from "deductions-constants/BackupSources";

export default function scanInvoice(
  originalFile: FileObject,
  invoiceKey: string,
  invoice: InvoiceObject,
  companyid: string,
  onScanStart: Function,
  onScanFinish: Function
) {
  importBackupSources().then(backupSources => {
    const user = firebase.auth().currentUser;
    const filePath = `${companyid}/Deductions Reconciliation/Backup Files/${originalFile.fileBatch}/${originalFile.fileName}`;
    // data sent to backend
    const formData = {};
    formData.userid = user.uid;
    formData.file_paths = [filePath];
    formData.template_fb_key = backupSources?.[invoice.backupSource!]?.UUID_KEY;
    const prom_type_key = invoice.suggestedPromTypes
      ? Object.values(invoice.suggestedPromTypes)
      : [];
    formData.prom_type = prom_type_key.join("|");
    formData.companyid = companyid;
    formData.userid = user.uid;
    formData.month = new Date(invoice.endDate).getMonth() + 1;
    formData.year = new Date(invoice.endDate).getFullYear();
    formData.spend_date = invoice.endDate;
    formData.invoice_key = invoiceKey;
    formData.start_page = originalFile.startPage;
    formData.end_page = originalFile.endPage;
    formData.check_number = invoice.checkNumber ?? "N/A";

    // Servicer not applicable for MVP
    // formData["servicer"] = TODO

    // Track in Mixpanel - TODO.
    // Mixpanel.track("Invoice Scan", {
    //   "Backup Source": formData["deductions_type"],
    //   "Promotion Type": formData["prom_type"],
    //   Pages: this.state.numPages
    // });
    onScanStart();

    // send data to scanning service
    axios
      .post(`${cloudRunURL}/api/scan_invoice`, { data: formData })
      .then(response => {
        onScanFinish(response.data.data);
      })
      .catch(error => {
        console.error("error", error.response);
        onScanFinish({ success: "Failure" });
      });
  });
}
