import React from "react";
import axios from "axios";

import Checkbox from "ui-library/Checkbox";
import Subheader from "ui-library/Subheader";
import Button from "ui-library/Button";
import MenuItem from "ui-library/MenuItem";
import Select from "ui-library/Select";
import TextField from "ui-library/TextField";
import SecurityIcon from "@mui/icons-material/Security";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import SaveIcon from "@mui/icons-material/Save";

import {
  changeUserCompany,
  getCompanyNamesFirebase,
  getUserDataFirebase,
  isUserCresicorEmployee,
  getAllowedCompanies,
  updateMetaFirebase,
  getJSXListsFirebase,
  firebase,
  cloudRunFunctionURL
} from "helpers/Firebase";
import { arrayify } from "helpers/DataProcessing";
import _ from "lodash";

import { grey } from "@mui/material/colors";

const grey500 = grey["500"];
const grey700 = grey["700"];

const styles = {
  notifs: {
    width: "60%"
  },
  subheader: {
    color: "primary.main"
  },
  productList: {
    borderColor: grey700,
    borderWidth: 1,
    borderStyle: "solid"
    // overflow: 'auto',
    // height: 300
  },
  moneyIcon: {
    width: 15,
    height: 15
  },
  radioButton: {
    marginBottom: 16
  },
  textField: {
    color: grey500,
    borderColor: grey500
  },
  chip: {
    margin: 4
  },
  wrapper: {
    display: "flex",
    flexWrap: "wrap"
  }
};

class Miscellaneous extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      meta: {},
      userCompany: null,
      companies: {},
      newRevSource: "",
      newDedSource: "",
      importLoading: false,
      customersJSX: [],
      portalEnabled: {}
    };
  }

  saveAutoCloseDays = () => {
    const num = Number(this.state.autoCloseDays);
    if (num && num > 0) {
      updateMetaFirebase({ autoCloseDays: num });
      this.setState({
        autoCloseDaysError: null,
        autoCloseDaysEdit: false
      });
    } else {
      this.setState({
        autoCloseDaysError: "Must be positive integer"
      });
    }
  };

  saveSlack = () => {
    updateMetaFirebase({ slack: this.state.slack });
    this.setState({
      slackEdit: false
    });
  };

  saveLogout = () => {
    const num = Number(this.state.logoutTime);
    if (num && num > 1) {
      updateMetaFirebase({ logoutTime: this.state.logoutTime });
      this.setState({
        logoutError: null,
        logoutEdit: false
      });
    } else {
      this.setState({
        logoutError: "Must be an integer greater than 1"
      });
    }
  };

  getVariables = db => {
    const { meta } = db;
    if (!meta.logoutTime) {
      updateMetaFirebase({ logoutTime: 30 });
      meta.logoutTime = 30;
    }
    if (meta) {
      this.setState({
        meta,
        autoCloseDays: meta.autoCloseDays,
        slack: meta.slack,
        logoutTime: meta.logoutTime
      });
    }
  };

  resetPassword = () => {
    const auth = firebase.auth();
    const emailAddress = auth.currentUser.email;
    if (emailAddress) {
      auth.sendPasswordResetEmail(emailAddress).then(() => {
        this.props.openClose.showSnack("Reset link sent to your email");
      });
    }
  };

  changeCompany = (event, index, value) => {
    changeUserCompany(this.state.companies[value], value, () => {});
  };

  importPortalData = () => {
    const user = firebase.auth().currentUser;
    this.setState({ importLoading: true });
    const formData = {
      companyid: this.props.db.companyid,
      userid: user.uid
    };
    axios
      .post(`${cloudRunFunctionURL}/api/import_portal_data`, { data: formData })
      .then(response => {
        this.setState({ importLoading: false });
        this.props.openClose.setAppModal(
          "Finished Importing Data",
          <div className="centering">
            Customer and Product data has successfully been imported from
            Vividly portal.
          </div>,
          <div className="centering">
            <Button
              label="Close"
              onClick={() => {
                this.props.openClose.closeAppModal();
              }}
            />
          </div>
        );
      });
  };

  getPortalEnabled = companies => {
    // check if portal is enabled for each company in companies
    const tpmEnabled = {};
    const portalEnabled = {};
    const companyIds = Object.keys(companies);
    Promise.all(
      companyIds.map(companyid =>
        firebase
          .database()
          .ref(`companies/${companyid}/meta/cresicor_tpm`)
          .once("value")
      )
    ).then(refs => {
      let ind = 0;
      for (const company in companies) {
        tpmEnabled[company] = refs[ind].val();
        ind++;
      }
      Promise.all(
        companyIds.map(companyid =>
          firebase
            .database()
            .ref(`companies/${companyid}/meta/cresicor_deductions_scanning`)
            .once("value")
        )
      ).then(refs => {
        let ind = 0;
        for (const company in companies) {
          portalEnabled[company] = refs[ind].val() || tpmEnabled[company];
          ind++;
        }
        this.setState({ portalEnabled });
      });
    });
  };

  getAllowedCompanies = () => {
    if (!isUserCresicorEmployee()) {
      const userEmail = firebase.auth().currentUser.email;
      const emailHash = userEmail.replace(/[^a-zA-Z0-9]/g, "");
      firebase
        .database()
        .ref(`validEmails/${emailHash}`)
        .on("value", snapshot => {
          const validEmailObj = snapshot.val();
          this.setState({
            companies: _.zipObject(
              arrayify(validEmailObj.company),
              arrayify(validEmailObj.cid)
            )
          });
        });
    }
  };

  componentDidMount() {
    this.getVariables(this.props.db);
    getUserDataFirebase(
      company => this.setState({ userCompany: company }),
      "company"
    );
    getCompanyNamesFirebase(companies => {
      this.setState(
        { companies },
        getAllowedCompanies.bind(null, validEmailObj => {
          this.setState({
            companies: _.zipObject(
              arrayify(validEmailObj.cid),
              arrayify(validEmailObj.company)
            )
          });
        })
      );
      this.getPortalEnabled(companies);
    });
    getJSXListsFirebase(this.props.db, allJSX => {
      this.setState({ customersJSX: allJSX.customersJSX });
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getVariables(nextProps.db);
    getUserDataFirebase(
      company => this.setState({ userCompany: company }),
      "company"
    );
    getCompanyNamesFirebase(companies => {
      this.setState(
        { companies },
        getAllowedCompanies.bind(null, validEmailObj => {
          this.setState({
            companies: _.zipObject(
              arrayify(validEmailObj.cid),
              arrayify(validEmailObj.company)
            )
          });
        })
      );
      this.getPortalEnabled(companies);
    });
  }

  render() {
    const fbUrl = firebase.app().options.projectId;
    const editPermission = this.props.db.permissions.includes("edit");
    return (
      <div>
        {this.props.db.permissions !== "None" && (
          <div>
            <Subheader style={styles.subheader}>
              Automatically Close Promotions/Lines
            </Subheader>
            <br />
            <div className="rowC">
              <TextField
                floatingLabelText="This many days after end date reached"
                fullWidth
                value={this.state.autoCloseDays}
                onChange={event => {
                  this.setState({
                    autoCloseDays: event.target.value,
                    autoCloseDaysEdit: true
                  });
                }}
                onKeyPress={ev => {
                  if (ev.key === "Enter") {
                    this.saveAutoCloseDays();
                    ev.preventDefault();
                  }
                }}
                errorText={this.state.autoCloseDaysError}
                style={{ marginLeft: 10, marginRight: 10, marginTop: -10 }}
                disabled={this.props.db.meta.no_auto_close || !editPermission}
              />
              {this.state.autoCloseDaysEdit &&
              !this.props.db.meta.no_auto_close &&
              editPermission ? (
                <Button icon={<SaveIcon />} onClick={this.saveAutoCloseDays} />
              ) : (
                <Button icon={<SaveIcon />} disabled />
              )}
            </div>
            <br />
            <Checkbox
              label="Do not auto-close promotions/lines"
              onChange={event => {
                const update = {};
                update.no_auto_close = event.target.checked;
                firebase
                  .database()
                  .ref(`companies/${this.props.db.companyid}/meta`)
                  .update(update);
              }}
              style={styles.checkbox}
              checked={this.props.db.meta.no_auto_close}
              disabled={!editPermission}
            />
            <br />
          </div>
        )}

        {this.props.db.permissions !== "None" && (
          <div>
            <Subheader style={styles.subheader}>
              Team Chat Channel Link
            </Subheader>
            <br />
            <div className="rowC">
              <TextField
                floatingLabelText="Link Address"
                fullWidth
                value={this.state.slack}
                onChange={event => {
                  this.setState({ slack: event.target.value, slackEdit: true });
                }}
                onKeyPress={ev => {
                  if (ev.key === "Enter") {
                    this.saveSlack();
                    ev.preventDefault();
                  }
                }}
                style={{ marginLeft: 10, marginRight: 10, marginTop: -10 }}
                disabled={!editPermission}
              />
              {this.state.slackEdit ? (
                <Button icon={<SaveIcon />} onClick={this.saveSlack} />
              ) : (
                <Button icon={<SaveIcon />} disabled />
              )}
            </div>
            <br />
          </div>
        )}

        {this.props.db.permissions !== "None" && (
          <div>
            <Subheader style={styles.subheader}>
              Logout due to Inactivity
            </Subheader>
            <br />
            <div className="rowC">
              <TextField
                floatingLabelText="Minutes until Logout"
                fullWidth
                value={this.state.logoutTime}
                onChange={event => {
                  this.setState({
                    logoutTime: event.target.value,
                    logoutEdit: true
                  });
                }}
                onKeyPress={ev => {
                  if (ev.key === "Enter") {
                    this.saveLogout();
                    ev.preventDefault();
                  }
                }}
                errorText={this.state.logoutError}
                style={{ marginLeft: 10, marginRight: 10, marginTop: -10 }}
                disabled={!editPermission}
              />
              {this.state.logoutEdit ? (
                <Button icon={<SaveIcon />} onClick={this.saveLogout} />
              ) : (
                <Button icon={<SaveIcon />} disabled />
              )}
            </div>
            <br />
          </div>
        )}

        <Subheader style={styles.subheader}>Reset Password</Subheader>
        <br />
        <Button
          label="Send password reset email"
          icon={<SecurityIcon />}
          onClick={this.resetPassword}
          fullWidth
        />
        <br />

        {isUserCresicorEmployee() && fbUrl != "cresicor-db1c9" && (
          <div>
            <Subheader style={styles.subheader}>Import Portal Data</Subheader>
            <br />
            <Button
              label="Import Portal Data"
              icon={<CloudDownloadIcon />}
              onClick={this.importPortalData}
              fullWidth
            />
          </div>
        )}
        <br />

        {Object.keys(this.state.companies).length > 1 && (
          <div>
            <Subheader style={styles.subheader}>Select Company</Subheader>
            <br />
            <Select
              value={this.state.userCompany}
              onChange={this.changeCompany}
              style={{ marginLeft: 10, marginRight: 10, marginTop: -20 }}>
              {Object.entries(this.state.companies)
                .sort((s1, s2) => s1[1].localeCompare(s2[1]))
                .map(([cid, company]) => {
                  return (
                    <MenuItem
                      value={cid}
                      key={cid}
                      disabled={!this.state.portalEnabled[cid]}>
                      {company}
                    </MenuItem>
                  );
                })}
            </Select>
          </div>
        )}
      </div>
    );
  }
}

export default Miscellaneous;
