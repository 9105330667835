import React from "react";
import pluralize from "pluralize";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { displayMoney } from "helpers/DataProcessing";
import { sortByField } from "helpers/sortByDate";

import { common, grey } from "@mui/material/colors";

const { black } = common;
const grey500 = grey["500"];

function round(n) {
  return Math.round(n * 100) / 100;
}

const prettyPrint = productsArray => productsArray.join(", ");

const getLineProducts = (line, productsList) => {
  const products = line.product.map(product => productsList[product].name);

  return `Individual ${pluralize(
    "item",
    products.length
  )} selected: ${prettyPrint(products)}`;
};

class LineList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reverseSort: false,
      clist: {},
      currentlySelected: "",
      selectedProms: {}
    };
  }

  handleRowTouchTap = key => {
    this.props.handleSelectRow(key);
    if (key == this.state.currentlySelected) {
      this.setState({
        currentlySelected: ""
      });
    } else {
      this.setState({
        currentlySelected: key
      });
    }
  };

  getColumnHeadersJSX = columns => {
    function onSelectHeader(field) {
      if (this.state.sortField == field) {
        this.setState({ reverseSort: !this.state.reverseSort });
      } else {
        this.setState({ sortField: field, reverseSort: false });
      }
    }

    const columnHeadersJSX = [];
    for (let i = 0; i < columns.length; i++) {
      const column = columns[i];
      columnHeadersJSX.push(
        <TableCell
          onClick={onSelectHeader.bind(this, column.field)}
          style={{
            color: this.state.sortField == column.field ? black : grey500,
            cursor: "pointer"
          }}
          key={i}
          hover>
          <b>{column.name}</b>
        </TableCell>
      );
    }
    return columnHeadersJSX;
  };

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  getLinesJSX = () => {
    let lineList = [];
    const linesJSX = [];

    const lineKeys = Object.keys(this.props.lines).sort((a, b) => {
      try {
        const aIndex = parseInt(a.substring(a.lastIndexOf("-") + 1));
        const bIndex = parseInt(b.substring(b.lastIndexOf("-") + 1));
        return aIndex - bIndex;
      } catch (err) {
        return a - b;
      }
    });

    for (const key of lineKeys) {
      var l = { ...this.props.lines[key] };
      l.key = key;
      l.productField =
        l.productGroup && l.productGroup != "custom"
          ? this.props.db.meta.product_groups[l.productGroup].name
          : getLineProducts(l, this.props.db.products);
      l.typeField = this.props.db.meta.fundTypes?.[l.type]?.name;
      l.dateField = new Date(l.startDate).getTime();
      lineList.push(l);
    }

    if (this.state.sortField) {
      lineList = sortByField(
        this.state.sortField,
        lineList,
        this.state.reverseSort
      );
    } else {
      lineList = lineList;
    }

    for (let i = 0; i < lineList.length; i++) {
      var l = lineList[i];
      linesJSX.push(
        <TableRow
          key={l.key}
          onClick={this.handleRowTouchTap.bind(null, l.key)}
          hover>
          <TableCell>
            <div>{l.key}</div>
          </TableCell>
          <TableCell>
            <div>{l.productField}</div>
          </TableCell>
          <TableCell>
            <div>{l.typeField}</div>
          </TableCell>
          <TableCell>
            <div>{`${l.startDate} - ${l.endDate}`}</div>
          </TableCell>
          <TableCell>
            <div>{displayMoney(Math.round(l.totalExpSpend))}</div>
          </TableCell>
          <TableCell>
            <div>{round(l.spendRate)}</div>
          </TableCell>
        </TableRow>
      );
    }
    return linesJSX;
  };

  render() {
    const linesJSX = this.getLinesJSX();

    const columns = [
      { name: "Line Id", field: "key" },
      { name: "Product", field: "productField" },
      { name: "Type", field: "typeField" },
      { name: "Duration", field: "dateField" },
      { name: "Total Expected Spend", field: "totalExpSpend" },
      { name: "Spend Rate", field: "spendRate" }
    ];
    const columnHeadersJSX = this.getColumnHeadersJSX(columns);

    return (
      <Paper variant="outlined">
        <TableContainer
          height={`${this.props.height}px`}
          style={{
            cursor: "pointer"
          }}>
          <Table>
            <TableHead>
              <TableRow>{columnHeadersJSX}</TableRow>
            </TableHead>
            <TableBody>{linesJSX}</TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  }
}

export { getLineProducts };

export default LineList;
