const projectId = "cresicor-dev";
const cloudRunURLSuffix = "cloudrun-service-wbsea5mi4a-uc.a.run.app";
const forecastCloudRunURLSuffix = "forecast-rs-wbsea5mi4a-uc.a.run.app";
const cloudRunFunctionSuffix = "upload-service-wbsea5mi4a-uc.a.run.app";
const forecastServiceURLSuffix = "forecast-rs-wbsea5mi4a-uc.a.run.app";
const cresicorNextURLSuffix = "cresicor-next-wbsea5mi4a-uc.a.run.app";
const firebaseAPIKey = "AIzaSyAn__sK4HzcnvG_aJ43EeOoJmsy9G25Byk";
const firebaseAuthDomain = "beta.govividly.com";
const firebaseAppId = "1:639243488914:web:ab5f0e71b976c36366a04b";

export default {
  projectId,
  cloudRunURLSuffix,
  forecastCloudRunURLSuffix,
  cloudRunFunctionSuffix,
  forecastServiceURLSuffix,
  cresicorNextURLSuffix,
  firebaseAPIKey,
  firebaseAuthDomain,
  firebaseAppId
};
