import React from "react";
import { TableVirtuoso } from "react-virtuoso";

import { AVFView, GranularityOptions, SingleAVF } from "./types";

import "./forecast.css";
import { Row } from "./TableComponents/Row";
import {
  renderCellValue,
  LINE_COLOR,
  BASE_CELL,
  CELL_HEIGHT
} from "./TableComponents/DetailCell";

import { Header } from "./TableComponents/Header";

import {
  getQuarter,
  mapNumToMonth,
  utcToFutureUtc,
  utcToReadableDateInFuture
} from "./utilities";

export const AVFTable = ({
  avfData,
  tableStart,
  tableEnd,
  loading,
  granularity,
  timeFilter
}: {
  avfData: AVFView;
  tableStart: string;
  tableEnd: string;
  loading;
  granularity: GranularityOptions;
  timeFilter: [startWeek: number, endWeek: number];
}) => {
  const headers = [granularity, "Actual", "Forecast", "Difference", "Ratio"];
  const epsilon = 0.00001;

  // filter the data by time
  const filterData = (start, end) => {
    return {
      actuals_values: avfData.actuals_values.slice(start, end + 1),
      forecasted_values: avfData.forecasted_values.slice(start, end + 1),
      difference: avfData.difference.slice(start, end + 1)
    } as AVFView;
  };

  const filteredTableStart = utcToReadableDateInFuture(
    Date.parse(tableStart),
    timeFilter[0]
  );
  const startYear = new Date(Date.parse(filteredTableStart)).getFullYear();

  // group the data by granularity
  const groupDataByGranularity = (
    data: AVFView,
    granularity: GranularityOptions
  ) => {
    let avfValuesWithGrouping: SingleAVF[];

    switch (granularity) {
      case GranularityOptions.month: {
        avfValuesWithGrouping = data.actuals_values.map((actual, ind) => {
          const group_date = new Date(
            utcToFutureUtc(Date.parse(filteredTableStart), ind)
          );
          return {
            actual,
            forecast: data.forecasted_values[ind],
            diff: data.difference[ind],
            group:
              group_date.getMonth() +
              (group_date.getFullYear() % startYear) * 12
          };
        });
        break;
      }
      case GranularityOptions.quarter: {
        avfValuesWithGrouping = data.actuals_values.map((actual, ind) => {
          const group_date: Date = new Date(
            utcToFutureUtc(Date.parse(filteredTableStart), ind)
          );
          return {
            actual,
            forecast: data.forecasted_values[ind],
            diff: data.difference[ind],
            group: getQuarter(group_date, filteredTableStart)
          };
        });
        break;
      }
      case GranularityOptions.year: {
        avfValuesWithGrouping = data.actuals_values.map((actual, ind) => {
          const group_date = new Date(
            utcToFutureUtc(Date.parse(filteredTableStart), ind)
          );
          return {
            actual,
            forecast: data.forecasted_values[ind],
            diff: data.difference[ind],
            group: group_date.getFullYear()
          };
        });
        break;
      }
      default: {
        // default is week
        avfValuesWithGrouping = data.actuals_values.map((actual, ind) => ({
          actual,
          forecast: data.forecasted_values[ind],
          diff: data.difference[ind],
          group: ind
        }));
      }
    }

    return Object.entries(combineElementsWithSameGroup(avfValuesWithGrouping))
      .map((values: [string, SingleAVF], index) => {
        return {
          actuals_values: values[1].actual,
          forecasted_values: values[1].forecast,
          difference: values[1].diff
        };
      })
      .reduce(
        (acc, obj) => {
          acc.actuals_values.push(obj.actuals_values);
          acc.forecasted_values.push(obj.forecasted_values);
          acc.difference.push(obj.difference);
          return acc;
        },
        {
          actuals_values: [],
          forecasted_values: [],
          difference: []
        } as AVFView
      );
  };

  // first filter the data based on the selected time window
  const filteredData = filterData(timeFilter[0], timeFilter[1]);

  // then group it based on the granularity
  const groupedData = groupDataByGranularity(filteredData, granularity);

  // map those values to rows
  const rows = groupedData.actuals_values.map((actual, index) => {
    return [
      getTimeColumnValue(granularity, index, filteredTableStart),
      actual.toLocaleString("en-US"),
      groupedData.forecasted_values[index].toLocaleString("en-US"),
      groupedData.difference[index].toLocaleString("en-US"),
      (actual / (groupedData.forecasted_values[index] + epsilon)).toFixed(2)
    ].map(x => renderCellValue(x));
  });

  interface TableHeaderStyles {
    [key: string]: React.CSSProperties;
  }
  const style: TableHeaderStyles = {
    table: {
      width: "100%",
      minHeight: `700px`,
      maxHeight: `700px`,
      opacity: loading ? 0.5 : 1,
      borderRadius: "3px",
      border: `0px solid ${LINE_COLOR}`,
      background: "white"
    },
    headerCell: {
      ...BASE_CELL,
      textAlign: "left"
    },
    dataCell: {
      ...BASE_CELL,
      textAlign: "left",
      minHeight: CELL_HEIGHT * 0.5,
      height: CELL_HEIGHT * 0.5,
      left: 0,
      borderBottom: `1px solid ${LINE_COLOR}`
    }
  };

  return (
    <>
      <div>
        <TableVirtuoso
          style={style.table}
          data={rows}
          fixedHeaderContent={() => (
            <Header values={headers} style={style.headerCell} avf />
          )}
          itemContent={rowIndex => {
            const rowValues = rows[rowIndex];
            return (
              <Row
                values={rowValues}
                index={rowIndex}
                style={style.dataCell}
                avf
              />
            );
          }}
        />
      </div>
    </>
  );
};

const getTimeColumnValue = (
  granularity: GranularityOptions,
  indexPastStart: number,
  tableStart: string
): string => {
  const startYear: number = new Date(Date.parse(tableStart)).getFullYear();
  const startMonth: number = new Date(Date.parse(tableStart)).getMonth();
  switch (granularity) {
    case GranularityOptions.week: {
      return utcToReadableDateInFuture(Date.parse(tableStart), indexPastStart);
    }
    case GranularityOptions.month: {
      const monthsToAdd: number = indexPastStart % 12;
      const yearsToAdd: number = Math.floor(indexPastStart / 12);
      const newMonth: number = (startMonth + monthsToAdd) % 12;
      let endOfYearCurlAround = 0;
      if (newMonth - startMonth < 0) {
        endOfYearCurlAround = 1;
      }

      const newYear = startYear + yearsToAdd + endOfYearCurlAround;
      return `${mapNumToMonth(newMonth)} ${newYear}`;
    }
    case GranularityOptions.quarter: {
      const startQuarter = Math.floor(startMonth / 3);
      const newQuarter = (startQuarter + indexPastStart) % 4;
      let endOfYearCurlAround = 0;
      if (newQuarter - startQuarter < 0) {
        endOfYearCurlAround = 1;
      }
      const newYear =
        startYear + Math.floor(indexPastStart / 4) + endOfYearCurlAround;

      return `Q${newQuarter + 1} ${newYear}`;
    }
    case GranularityOptions.year: {
      return (startYear + indexPastStart).toString();
    }
  }
};

const combineElementsWithSameGroup = function (array: SingleAVF[]) {
  return array.reduce((acc, obj) => {
    const key = obj.group;
    if (!acc[key]) {
      acc[key] = {
        actual: 0,
        forecast: 0,
        diff: 0,
        group: key
      };
    }
    acc[key].actual += obj.actual;
    acc[key].forecast += obj.forecast;
    acc[key].diff += obj.diff;
    return acc;
  }, [] as SingleAVF[]);
};
