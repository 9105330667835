import React from "react";
import ReactTable from "react-table";

import { round, sum, map } from "lodash-es";
import { displayMoney } from "helpers/DataProcessing";

import Mixpanel from "helpers/Mixpanel";
import { red } from "@mui/material/colors";
import PromProfile from "../Planning/PromProfile";

const red100 = red["100"];

const styles = {
  row: {
    cursor: "pointer"
  }
};

class ROITable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: []
    };
  }

  addCommas = num => {
    return num.toLocaleString();
  };

  showPromotion = rowValues => {
    this.props.showRightDrawer(
      <PromProfile
        promKey={rowValues.key}
        openClose={this.props.openClose}
        db={this.props.db}
      />
    );
    Mixpanel.track("Promotion Viewed", {
      View: "Promotion Analytics",
      Component: "ROITable"
    });
  };

  getBackgroundColor = rowValues => {
    if (
      (false && rowValues.diff < this.props.diffCutoff) ||
      rowValues.percent < this.props.percentCutoff
    ) {
      // turning this off for now
      return red100;
    }
    return null;
  };

  renderCell = row => {
    const moneyCols = ["spend", "revenue"];
    return (
      <div
        onClick={
          this.props.pivot == "prom"
            ? this.showPromotion.bind(null, row.original)
            : null
        }
        style={{
          ...styles.row,
          backgroundColor: this.getBackgroundColor(row.original)
        }}>
        {moneyCols.indexOf(row.column.id) != -1 && this.props.isMoney
          ? displayMoney(Math.round(row.value))
          : ["roiLessCOGS", "roiGrossSales"].includes(row.column.id)
          ? round(row.value, 2)
          : this.props.pivot === "account" && row.column.id === "key"
          ? this.props.db.meta.fundTypes?.[row.value]?.name
          : row.value.toLocaleString()}
      </div>
    );
  };

  sortMethod = (a, b, desc) => {
    const end = desc ? -Infinity : Infinity;
    a = isNaN(a) ? end : a;
    b = isNaN(b) ? end : b;
    if (a > b) {
      return 1;
    }
    if (a < b) {
      return -1;
    }
    return 0;
  };

  getColumns = props => {
    const display = props.isMoney ? displayMoney : this.addCommas;

    const pivotCols = [];
    if (props.pivot == "prom") {
      pivotCols.push({
        Header: "Promotion",
        accessor: "promotion",
        Cell: this.renderCell
      });
    }
    let header = "ID";
    if (props.pivot == "customer") header = "Customer";
    else if (props.pivot == "contact") header = "Contact";
    else if (props.pivot == "date") header = "Month";
    else if (props.pivot == "product") header = "Product Group";
    else if (props.pivot == "account") header = "Fund Type";
    pivotCols.push({
      Header: header,
      accessor: "key",
      Cell: this.renderCell
    });

    this.setState({
      columns: [
        {
          Header: "Pivot Column",
          columns: pivotCols
        },
        {
          Header: "Data",
          columns: [
            {
              Header: "Actual Spend",
              accessor: "spend",
              Cell: this.renderCell,
              Footer: (
                <span>
                  {display(round(sum(map(props.data, d => d.spend))))}
                </span>
              )
            },
            {
              Header: "Incremental Revenue Dollars",
              accessor: "revenue",
              Cell: this.renderCell,
              Footer: (
                <span>
                  {display(round(sum(map(props.data, d => d.revenue))))}
                </span>
              )
            },
            {
              Header: "ROI (Less COGS)",
              accessor: "roiLessCOGS",
              Cell: this.renderCell,
              Footer: (
                <span>
                  {round(
                    sum(map(props.data, d => d.revenue)) /
                      sum(map(props.data, d => d.spend)),
                    2
                  ).toLocaleString()}
                </span>
              ),
              sortMethod: this.sortMethod
            },
            {
              Header: "ROI (Gross Sales)",
              accessor: "roiGrossSales",
              Cell: this.renderCell,
              Footer: (
                <span>
                  {round(
                    sum(map(props.data, d => d.grossSales)) /
                      sum(map(props.data, d => d.spend)),
                    2
                  ).toLocaleString()}
                </span>
              ),
              sortMethod: this.sortMethod
            }
          ]
        }
      ]
    });
  };

  componentDidMount() {
    this.getColumns(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getColumns(nextProps);
  }

  render() {
    return (
      <ReactTable
        data={this.props.data}
        columns={this.state.columns}
        defaultPageSize={12}
        style={{ fontFamily: "PT sans" }}
      />
    );
  }
}

export default ROITable;
