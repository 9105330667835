import React from "react";

import Button from "ui-library/Button";

import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";

import { updateLiftFirebase } from "helpers/Firebase";

const ReactTableFixedColumns = withFixedColumns(ReactTable);

class LiftTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      data: [],
      tableKey: 0
    };
  }

  isValid = val => {
    const floatVal = parseFloat(val);
    if (isNaN(floatVal) || floatVal <= 0) {
      return false;
    }
    return true;
  };

  renderCell = cellInfo => {
    const data = [...this.state.data];
    let oldData = "";
    const cellRef = React.createRef();
    return cellInfo.column.Header !== "Customer" &&
      this.props.db.permissions.includes("lift") ? (
      <div
        ref={cellRef}
        contentEditable
        suppressContentEditableWarning
        onChange={e => {
          data[cellInfo.index][cellInfo.column.id] = parseFloat(
            e.target.textContent
          );
          this.setState({ data });
        }}
        onFocus={e => {
          oldData = e.target.textContent;
        }}
        onKeyPress={e => {
          if (e.key == "Enter") {
            cellRef.current.blur();
          }
        }}
        onBlur={e => {
          if (
            this.props.db.permissions.includes("lift") &&
            !this.props.readOnly
          ) {
            if (e.target.textContent && this.isValid(e.target.textContent)) {
              updateLiftFirebase(
                this.props.liftBucket,
                cellInfo.original.customer,
                cellInfo.column.id,
                parseFloat(e.target.textContent)
              );
            } else if (
              e.target.textContent &&
              !this.isValid(e.target.textContent)
            ) {
              e.target.textContent = oldData;
              this.props.openClose.setAppModal(
                "Invalid Value",
                <div className="centering">
                  All lift values must be positive numbers.
                </div>,

                <div className="centering">
                  <Button
                    label="Ok"
                    onClick={() => {
                      this.props.openClose.closeAppModal();
                    }}
                  />
                </div>
              );
            } else {
              // lift has been deleted
              updateLiftFirebase(
                this.props.liftBucket,
                cellInfo.original.customer,
                cellInfo.column.id,
                null
              );
            }
          }
        }}
        key={cellInfo.index + cellInfo.column.id}>
        {cellInfo.value}
      </div>
    ) : cellInfo.column.Header == "Customer" ? (
      <div style={{ fontWeight: 800 }}>{cellInfo.value}</div>
    ) : (
      <div>{cellInfo.value}</div>
    );
  };

  updateDataColumns = props => {
    const columns = [];

    columns.push({
      Header: "",
      fixed: "left",
      columns: [
        {
          Header: "Customer",
          accessor: "customerName",
          Cell: this.renderCell,
          fixed: "left",
          minWidth: 200
        }
      ]
    });
    const productColumns = [];
    for (const key in props.db.meta.product_groups) {
      const pg = props.db.meta.product_groups[key];
      productColumns.push({
        Header: pg.name,
        accessor: key,
        Cell: this.renderCell,
        minWidth: 200
      });
    }

    columns.push({
      Header: "Product Groups (click cell to edit)",
      columns: productColumns
    });

    this.setState({
      data: props.data,
      columns
    });
  };

  componentDidMount() {
    this.updateDataColumns(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateDataColumns(nextProps);
  }

  render() {
    const columns = [];
    columns.push({
      Header: "",
      fixed: "left",
      columns: [
        {
          Header: "Customer",
          accessor: "customerName",
          Cell: this.renderCell,
          minWidth: 200
        }
      ]
    });
    const productColumns = [];
    for (const key in this.props.db.meta.product_groups) {
      const pg = this.props.db.meta.product_groups[key];
      productColumns.push({
        Header: pg.name,
        accessor: key,
        Cell: this.renderCell,
        minWidth: 200
      });
    }
    columns.push({
      Header: "Product Groups (click cell to edit)",
      columns: productColumns
    });
    return (
      <ReactTableFixedColumns
        ref={r => (this.table = r)}
        style={this.props.style}
        className="-striped"
        data={this.props.liftBucket == 0 ? [] : this.state.data}
        columns={this.state.columns}
      />
    );
  }
}

export default LiftTable;
