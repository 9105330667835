import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import React from "react";

import Card from "ui-library/Card";
import Grid from "@mui/material/Grid";

import Button from "ui-library/Button";
import TextField from "ui-library/TextField";
import MenuItem from "ui-library/MenuItem";
import Select from "ui-library/Select";
import CircularProgress from "@mui/material/CircularProgress";
import BuildIcon from "@mui/icons-material/Build";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import StoreIcon from "@mui/icons-material/Store";

import {
  biggestPromsGraph,
  displayMoney,
  lastModifiedPromotions,
  promotionTodos,
  revenueTimeSeries,
  salesVsRateScatter,
  topCustomersRadial
} from "helpers/DataProcessing";
import { getTimesJSX } from "helpers/Time";
import { objToArray } from "helpers/sortByDate";
import { updateMetaFirebase, firebase } from "helpers/Firebase";
import Mixpanel from "helpers/Mixpanel";
import { grey, lightBlue, cyan, teal, green } from "@mui/material/colors";
import { Stack } from "@mui/material";
import BiggestPromsGraph from "../graphs/BiggestPromsGraph";
import PromProfile from "../Planning/PromProfile";
import RankedList from "../Analytics/RankedList";
import RevenueTimeSeries from "../graphs/RevenueTimeSeries";
import SummaryTable from "../graphs/SummaryTable";
import TopCustomersRadial from "../graphs/TopCustomersRadial";

const grey500 = grey["500"];
const lightBlue700 = lightBlue["700"];
const cyan700 = cyan["700"];
const teal700 = teal["700"];
const green100 = green["100"];
const green700 = green["700"];

const styles = {
  superheader: {
    fontFamily: "Ubuntu",
    my: 2.5,
    color: "inherit"
  },
  dashboard: {
    marginLeft: "10px",
    marginRight: "30px",
    width: "calc(100% - 65px)"
  },
  miniIcon: {
    marginLeft: "-6px",
    marginRight: "6px",
    color: grey500
  },
  widgetLink: {
    marginTop: "8px",
    width: "100%"
  },
  loading: {
    marginTop: 100
  },
  customFont: {
    fontFamily: "Oxygen"
  },
  number: {
    fontSize: 42,
    fontFamily: "Titillium Web",
    fontWeight: "bolder"
  },
  smallText: {
    color: grey500
  }
};

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promotions: {},
      products: {},
      customers: {},
      actSales: {},
      fractionFromPromData: [],
      fysEdits: false,
      budget: 0,
      budgetValid: true,
      budgetEditable: false,
      revenueList: [],
      sources: ["None"],
      selectedSource: 0,
      selectedYear: new Date().getFullYear()
    };
  }

  budgetChange = (event, target, value) => {
    let newBudget = event.target.value;
    if (newBudget === "") {
      newBudget = 0;
    }
    this.setState({ budget: newBudget });
    // updateMetaFirebase({ budget: parseInt(newBudget) });
  };

  budgetEdit = () => {
    if (this.props.db.permissions.includes("admin")) {
      this.setState({ budgetEditable: true });
    } else {
      this.props.openClose.showSnack(
        "You do not have the necessary permissions for this action."
      );
    }
  };

  budgetSave = () => {
    if (Number.isNaN(parseInt(this.state.budget))) {
      this.props.openClose.showSnack("Budget must be an integer.");
      return;
    }
    updateMetaFirebase({ budget: parseInt(this.state.budget) });
    this.setState({ budgetEditable: false });
  };

  summaryTable = allLines => {
    const entries = [];
    let total = 0;
    for (const key in allLines) {
      if (allLines[key].year == new Date().getFullYear()) {
        total += parseFloat(allLines[key].totalExpSpend);
      }
    }
    entries.push({
      field: "Forecast",
      amount: Math.round(total)
    });

    let revisedTotal = 0;
    for (let i = 0; i < entries.length; i++) {
      revisedTotal += entries[i].amount;
    }

    const { budget } = this.state;
    entries.push({
      field: "Budget",
      amount: this.state.budgetEditable ? (
        <TextField
          value={this.state.budget}
          onChange={this.budgetChange}
          style={{ height: "66%", width: "66%" }}
          inputStyle={{
            backgroundColor: green100
          }}
        />
      ) : (
        parseInt(budget)
      ),
      notMoney: this.state.budgetEditable
    });
    entries.push({
      field: "Differential ($)",
      amount: Math.round(budget - revisedTotal)
    });
    entries.push({
      field: "Differential (%)",
      amount: Math.round(((budget - revisedTotal) * 100) / budget),
      notMoney: true
    });
    return entries;
  };

  getCounts = () => {
    const counts = {
      planning: 0,
      execution: 0,
      products: Object.keys(this.props.db.products).length,
      customers: Object.keys(this.props.db.customers).length
    };
    for (const key in this.state.promotions) {
      const prom = this.state.promotions[key];
      if (this.props.db.meta.planning?.indexOf(prom.status) != -1) {
        counts.planning += 1;
      } else if (this.props.db.meta.execution.indexOf(prom.status) != -1) {
        counts.execution += 1;
      }
    }
    return counts;
  };

  filterProms = allPromotions => {
    // returns another obj if just the desired key/value pairs
    const filteredProms = {};
    const user = firebase.auth().currentUser;
    if (user) {
      const { uid } = user;
      const companyUser = this.props.db.companyUsers[uid] || {};
      for (const key in allPromotions) {
        const prom = allPromotions[key];
        if (
          (!companyUser.customers ||
            companyUser.customers.includes(prom.customer)) &&
          this.props.db.meta.statuses[prom.status] != "Cancelled"
        ) {
          filteredProms[key] = prom;
        }
      }
    }
    return filteredProms;
  };

  getRevenueTimeSeriesData = () => {
    const { revenueList, customers, selectedSource, selectedYear } = this.state;
    let revenueListSource = revenueList;
    if (selectedSource !== 0) {
      revenueListSource = revenueListSource.filter(
        x => x.source === selectedSource
      );
    }
    revenueListSource = revenueListSource.filter(x => x.year === selectedYear);

    return revenueTimeSeries("revenue", revenueListSource, customers);
  };

  // only run once per mount
  getGraphData = db => {
    if (!this.state.doneLoading) {
      const { revenue } = db;
      const allPromotions = db.promotions;
      const filteredPromotions = this.filterProms(allPromotions);
      const { customers } = db;
      const { allLines } = db;

      const promsExist = Object.keys(allPromotions).length > 0;
      const moneyExist = Object.keys(db.actMoney).length > 0;
      const linesExist = Object.keys(allLines).length > 0;
      const revenueExist = Object.keys(revenue).length > 0;
      const customersExist = Object.keys(customers).length > 0;

      const actList = objToArray(db.actMoney);
      const revenueList = objToArray(revenue);
      let sources = new Set(
        revenueList.filter(x => x.source != null).map(x => x.source)
      );
      sources.add("None");
      sources = Array.from(sources);
      // only use QuickBooks data for revenue time series graph
      this.setState({
        promotions: allPromotions,
        customers,
        sources,
        revenueList,
        biggestPromsGraphData:
          moneyExist &&
          promsExist &&
          biggestPromsGraph(
            "spend",
            actList,
            "totalExpSpend",
            filteredPromotions,
            this.props.db.meta
          ),
        salesVsRateScatterData:
          moneyExist &&
          promsExist &&
          salesVsRateScatter("sales", actList, allPromotions),
        topCustomersRadialData:
          promsExist && topCustomersRadial(filteredPromotions),
        promotionTodosData:
          promsExist && promotionTodos(filteredPromotions, this.props.db.meta),
        lastModifiedPromotionsData:
          promsExist && lastModifiedPromotions(filteredPromotions),
        doneLoading:
          promsExist &&
          moneyExist &&
          linesExist &&
          revenueExist &&
          customersExist
      });
    }
  };

  // always updates
  getTableData = db => {
    const { allLines } = db;
    // do not pass lines of cancelled promotions to summary table
    const validLines = {};
    if (Object.keys(db.promotions).length > 0) {
      for (const [lineKey, lineData] of Object.entries(allLines)) {
        if (
          db.meta?.statuses[db.promotions[lineData.promKey]?.status] !=
          "Cancelled"
        ) {
          validLines[lineKey] = lineData;
        }
      }
    }
    return [this.summaryTable(validLines), this.state.budget];
  };

  componentDidMount() {
    this.getGraphData(this.props.db);

    // years JSX
    const [monthsJSX, yearsJSX] = getTimesJSX(this.props.db.meta, false);
    this.setState({
      yearsJSX,
      budget: this.props.db.meta.budget || 0
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Only show graph animation once per mount. This also means graph data will not update until refresh.
    if (!(this.state.doneLoading || this.props.doneLoading)) {
      this.getGraphData(nextProps.db);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.sideBar != this.props.sideBar) {
      return false;
    }
    return true;
  }

  render() {
    const revenueTimeSeriesData = this.getRevenueTimeSeriesData();
    const counts = this.getCounts();
    const [summaryTableData, budget] = this.getTableData(this.props.db);

    return this.state.doneLoading || this.props.doneLoading ? (
      <div style={styles.dashboard}>
        <Stack spacing={1} direction="column">
          <Grid container sx={{ ml: 3 }}>
            <Grid item xs={12}>
              <Typography sx={styles.superheader} variant="h4">
                Dashboard
              </Typography>
              <Divider />
            </Grid>
          </Grid>

          <Grid container spacing={3} style={styles.customFont}>
            <Grid item lg={3} md={6}>
              <Card
                icon={<BuildIcon style={styles.miniIcon} />}
                title="Promotion Plan">
                <Typography
                  display="inline"
                  style={{ ...styles.number, color: lightBlue700 }}>
                  {`${counts.planning.toString()} `}
                </Typography>
                <Typography display="inline" style={styles.smallText}>
                  promotions planned
                </Typography>
                <Link to="/planning">
                  <Button
                    variant="contained"
                    label="Manage/Import Planning"
                    sx={{ width: "100%" }}
                  />
                </Link>
              </Card>
            </Grid>
            <Grid item lg={3} md={6}>
              <Card
                icon={<CardGiftcardIcon style={styles.miniIcon} />}
                title="Executing Promotions">
                <Typography
                  display="inline"
                  style={{ ...styles.number, color: cyan700 }}>
                  {`${counts.execution.toString()} `}
                </Typography>
                <Typography display="inline" style={styles.smallText}>
                  promotions ongoing
                </Typography>
                <Link to="/execution">
                  <Button
                    variant="contained"
                    label="Manage/Import Execution"
                    sx={{ width: "100%" }}
                  />
                </Link>
              </Card>
            </Grid>
            <Grid item lg={3} md={6}>
              <Card
                icon={<ShoppingCartIcon style={styles.miniIcon} />}
                title="Products/SKUs">
                {" "}
                <Typography
                  display="inline"
                  style={{ ...styles.number, color: teal700 }}>
                  {`${counts.products.toString()} `}
                </Typography>
                <Typography display="inline" style={styles.smallText}>
                  products
                </Typography>
                <Link to="/products">
                  <Button
                    variant="contained"
                    label="Manage/Import Products"
                    sx={{ width: "100%" }}
                  />
                </Link>
              </Card>
            </Grid>
            <Grid item lg={3} md={6}>
              <Card
                icon={<StoreIcon style={styles.miniIcon} />}
                title="Customers">
                <Typography
                  display="inline"
                  style={{ ...styles.number, color: green700 }}>
                  {`${counts.customers.toString()} `}
                </Typography>
                <Typography display="inline" style={styles.smallText}>
                  customers
                </Typography>
                <Link to="/customers">
                  <Button
                    variant="contained"
                    label="Manage/Import Customers"
                    sx={{ width: "100%" }}
                  />
                </Link>
              </Card>
            </Grid>
          </Grid>

          <Grid container spacing={3} style={styles.customFont}>
            <Grid item xs={8}>
              {this.props.db.meta.tier != "pine" && (
                <Card
                  title="Total Revenue"
                  children={<RevenueTimeSeries data={revenueTimeSeriesData} />}
                  footer={
                    <>
                      <Select
                        floatingLabelText="Data Source"
                        onChange={(event, index, value) => {
                          this.setState({ selectedSource: value });
                        }}
                        value={this.state.selectedSource}
                        style={{
                          marginRight: 0,
                          marginTop: -8,
                          width: "20%",
                          margin: "0 auto"
                        }}>
                        <MenuItem value={0} children="All Sources" key={0} />
                        {this.state.sources.map(source => (
                          <MenuItem
                            value={source}
                            children={source}
                            key={source}
                          />
                        ))}
                      </Select>
                      <Select
                        floatingLabelText="Year"
                        onChange={(event, index, value) => {
                          this.setState({ selectedYear: value });
                        }}
                        value={this.state.selectedYear}
                        style={{
                          marginRight: 0,
                          marginTop: -8,
                          width: "20%",
                          margin: "0 auto"
                        }}>
                        {this.state.yearsJSX}
                      </Select>
                    </>
                  }
                />
              )}
              <br />
              <Card
                title="List of Running Promotions with the greatest total expected
              spend.">
                <RankedList
                  data={this.state.biggestPromsGraphData}
                  getName={entry => {
                    return (
                      <span>
                        {entry.name}{" "}
                        <span style={{ color: grey500 }}>
                          {displayMoney(entry.Expected)}
                        </span>
                      </span>
                    );
                  }}
                  onClick={entry => {
                    if (entry.promKey) {
                      this.props.openClose.showRightDrawer(
                        <PromProfile
                          promKey={entry.promKey}
                          openClose={this.props.openClose}
                          db={this.props.db}
                        />
                      );
                    }
                    Mixpanel.track("Promotion Viewed", {
                      View: "Dashboard",
                      Interface: "Top Promotions",
                      Component: "Dashboard"
                    });
                  }}
                  db={this.props.db}
                  openClose={this.props.openClose}
                />
                <BiggestPromsGraph data={this.state.biggestPromsGraphData} />
              </Card>
              <br />
              <Card title="Companies Last Modified Promotions">
                <RankedList
                  data={this.state.lastModifiedPromotionsData}
                  getName={entry => {
                    return (
                      <span>
                        {entry.name}{" "}
                        <span style={{ color: grey500 }}>{entry.date}</span>
                      </span>
                    );
                  }}
                  onClick={entry => {
                    if (entry.promKey) {
                      this.props.openClose.showRightDrawer(
                        <PromProfile
                          promKey={entry.promKey}
                          openClose={this.props.openClose}
                          db={this.props.db}
                        />
                      );
                      Mixpanel.track("Promotion Viewed", {
                        View: "Dashboard",
                        Interface: "Last Modified",
                        Component: "Dashboard"
                      });
                    }
                  }}
                  db={this.props.db}
                  openClose={this.props.openClose}
                />
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card title="Fiscal Year Summary">
                {this.props.db.permissions.includes("admin") &&
                  (!this.state.budgetEditable ? (
                    <div>
                      <Button
                        variant="contained"
                        label="Edit Budget"
                        onClick={this.budgetEdit.bind(null)}
                        sx={{ ...styles.widgetLink }}
                      />
                    </div>
                  ) : (
                    <div className="vertical-center-row">
                      <div>Shaded cells are editable.</div>
                      <Button
                        variant="contained"
                        label="Save"
                        onClick={this.budgetSave.bind(null)}
                        sx={{ ...styles.widgetLink }}
                      />
                    </div>
                  ))}
                <br />
                <SummaryTable
                  data={summaryTableData || []}
                  db={this.props.db}
                />
                {this.props.db.meta.tier == "aspen" && (
                  <Link to="/analytics">
                    <Button
                      variant="contained"
                      label="Detailed Analytics"
                      sx={{ ...styles.widgetLink }}
                    />
                  </Link>
                )}
              </Card>
              <br />
              <Card title="My Promotion To Do's">
                <Typography>
                  List of Promotions that still need approval, and start within
                  30 days.
                </Typography>
                <RankedList
                  data={this.state.promotionTodosData}
                  onClick={entry => {
                    if (entry.promKey) {
                      this.props.openClose.showRightDrawer(
                        <PromProfile
                          promKey={entry.promKey}
                          openClose={this.props.openClose}
                          db={this.props.db}
                        />
                      );
                      Mixpanel.track("Promotion Viewed", {
                        View: "Dashboard",
                        Interface: "Promotions Todos",
                        Component: "Dashboard"
                      });
                    }
                  }}
                  db={this.props.db}
                  openClose={this.props.openClose}
                />
              </Card>
              <br />
              <Card title="Promotion use at Top Customers this Month">
                <Typography>
                  Customers with the most total expected spend ($).
                </Typography>
                <TopCustomersRadial data={this.state.topCustomersRadialData} />
              </Card>
            </Grid>
          </Grid>
        </Stack>
      </div>
    ) : (
      <div style={styles.loading} className="centering">
        <CircularProgress size={80} thickness={5} />
      </div>
    );
  }
}

export default Dashboard;
