import { InvoiceLineObject } from "components/Deductions/models";
import {
  AddInvoiceLinesState,
  NewInvoiceLineErrorState
} from "./addInvoiceLinesSlice";

export const addInvoiceLinesErrorsMessages: Record<
  keyof NewInvoiceLineErrorState,
  string
> = {
  amountZero: "Cannot be zero",
  missingCustomerKey: "Select a customer",
  missingEndDate: "Enter valid date"
};

export function updateInvoiceLines(
  state: AddInvoiceLinesState,
  index: number,
  invoiceLineToUpdate?: InvoiceLineObject,
  deleteInvoiceLine?: boolean
) {
  const { newInvoiceLines } = state;
  state.newInvoiceLines = [
    ...newInvoiceLines.slice(0, index),
    ...(!deleteInvoiceLine && invoiceLineToUpdate !== undefined
      ? [invoiceLineToUpdate]
      : []),
    ...newInvoiceLines.slice(index + 1)
  ];
}

export function updateInvoiceLinesErrors(
  state: AddInvoiceLinesState,
  index: number,
  invoiceLineErrorToUpdate?: NewInvoiceLineErrorState,
  deleteInvoiceLineError?: boolean
) {
  const { newInvoiceLinesErrors } = state;
  state.newInvoiceLinesErrors = [
    ...newInvoiceLinesErrors.slice(0, index),
    ...(!deleteInvoiceLineError && invoiceLineErrorToUpdate !== undefined
      ? [invoiceLineErrorToUpdate]
      : []),
    ...newInvoiceLinesErrors.slice(index + 1)
  ];
}

export function updateSearchByProductName(
  state: AddInvoiceLinesState,
  index: number,
  searchByProductNameToUpdate?: boolean,
  deleteSearchByProductName?: boolean
) {
  const { searchByProductName } = state;
  state.searchByProductName = [
    ...searchByProductName.slice(0, index),
    ...(!deleteSearchByProductName && searchByProductNameToUpdate !== undefined
      ? [searchByProductNameToUpdate]
      : []),
    ...searchByProductName.slice(index + 1)
  ];
}

export function updateProductCodes(
  state: AddInvoiceLinesState,
  index: number,
  productCodeToUpdate?: string,
  deleteProductCode?: boolean
) {
  const { productCodes } = state;
  state.productCodes = [
    ...productCodes.slice(0, index),
    ...(!deleteProductCode && productCodeToUpdate !== undefined
      ? [productCodeToUpdate]
      : []),
    ...productCodes.slice(index + 1)
  ];
}
