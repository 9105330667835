import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { IconButton as MUIIconButton, IconButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledIconButton = styled(MUIIconButton, {
  shouldForwardProp: prop => prop !== "isBackgroundColor" && prop !== "outlined"
})<IconButtonProps & { isBackgroundColor?: boolean; outlined?: boolean }>(
  ({ theme, color, isBackgroundColor, outlined }) => {
    if (!color && isBackgroundColor) {
      return {
        backgroundColor: theme.palette.background.inverseSurface,
        color: theme.palette.background.inverseOnSurface,
        "&:hover": {
          backgroundColor: `${theme.palette.background.inverseSurface}1a`
        }
      };
    }

    if (!color && !isBackgroundColor) {
      return {
        color: theme.palette.background.inverseSurface,
        border: outlined
          ? `1px solid ${theme.palette.background.outline}`
          : "none",
        "&:hover": {
          backgroundColor: `${theme.palette.background.inverseSurface}1a`
        }
      };
    }

    const colorObject = theme.palette[color!];
    return {
      backgroundColor: isBackgroundColor ? colorObject?.main : "transparent",
      color: isBackgroundColor ? colorObject?.contrastText : colorObject?.main,
      border:
        outlined && !isBackgroundColor
          ? `1px solid ${theme.palette.background.outline}`
          : "none",
      "&:hover": {
        backgroundColor: isBackgroundColor
          ? colorObject?.dark
          : `${colorObject?.main}1a`
      }
    };
  }
);

const wrapInTooltip = (tooltip, iconButton) => {
  return (
    <Tooltip title={tooltip} arrow>
      <span>{iconButton}</span>
    </Tooltip>
  );
};

const IconButton = props => {
  const { tooltip, children, isBackgroundColor, outlined, color, ...other } =
    props;
  const iconButton = (
    <StyledIconButton
      isBackgroundColor={isBackgroundColor}
      outlined={outlined}
      color={color}
      {...other}>
      {children}
    </StyledIconButton>
  );
  if (tooltip) {
    return wrapInTooltip(tooltip, iconButton);
  }
  return iconButton;
};
IconButton.muiName = "IconButton";

export default IconButton;
