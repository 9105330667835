import React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

const styles = {
  titlebarContainer: {
    display: "flex"
  },
  titleOuterContainer: {
    flexGrow: "1"
  },
  titleInnerContainer: {
    height: "100%",
    display: "flex",
    alignItems: "center"
  },
  stepperContainer: {
    flexGrow: "3"
  }
};

const PromModalTitlebar = ({ title, stepIndex }) => {
  return (
    <div style={styles.titlebarContainer}>
      <div style={styles.titleOuterContainer}>
        <div style={styles.titleInnerContainer}>
          <Typography variant="h6">{title}</Typography>
        </div>
      </div>
      <div style={styles.stepperContainer}>
        <Stepper activeStep={stepIndex}>
          <Step>
            <StepLabel>Basic Info</StepLabel>
          </Step>
          <Step>
            <StepLabel>Edit Details</StepLabel>
          </Step>
          <Step>
            <StepLabel>Manage Files</StepLabel>
          </Step>
        </Stepper>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  stepIndex: state.stepIndex
});

export default connect(mapStateToProps)(PromModalTitlebar);
