import React from "react";
import { ISubtable } from "./SubTable";
import {
  BASE_CELL,
  STICKY_CELL,
  CELL_VERTICAL_LINE,
  LINE_COLOR,
  bg,
  DetailCell
} from "./DetailCell";
import { TableItemTypes } from "../utilities";
import { AllDetailFields, EditableRow } from "../types";

export type RowValues = string[];
export interface RowMetadata {
  id: string;
  stableIndex: number;
  subtable: ISubtable;
  forecastBegins?: Date;
  field: AllDetailFields;
}
export interface IRow {
  type: TableItemTypes.ROW;
  meta: RowMetadata;
  values: RowValues;
}

const RIGHT_ENDCAP_DIVIDER = (
  <div
    style={{
      ...CELL_VERTICAL_LINE,
      right: 0,
      boxShadow: "2px 0 5px 0px rgba(0,0,0,0.5)"
    }}
  />
);

const LEFT_ENDCAP_DIVIDER = (
  <div
    style={{
      ...CELL_VERTICAL_LINE,
      left: 0,
      boxShadow: "-2px 0 5px 0px rgba(0,0,0,0.5)"
    }}
  />
);

export const Row = ({
  index,
  meta,
  values,
  editableRow,
  onExpand,
  expandedIds,
  style,
  avf
}: {
  index: number;
  meta?: RowMetadata;
  values: RowValues;
  onExpand?: (id: string) => void;
  expandedIds?: Record<string, boolean>;
  style?: React.CSSProperties;
  avf?: boolean;
  editableRow?: EditableRow;
}) => {
  const { id, forecastBegins, field } = meta || { id: "n/a" };
  const isExpanded = expandedIds ? !!expandedIds[id] : false;
  const [first, ...rest] = values;
  const last = rest.pop();
  const coloredBase = { ...BASE_CELL, background: bg(index) };
  const borderTop = index !== 0 ? `1px solid ${LINE_COLOR}` : undefined;
  const borderBottom = isExpanded ? `1px solid ${LINE_COLOR}` : undefined;
  const summableFields = ["sales", "revenue", "stores"];
  return (
    <>
      <td
        style={
          style || {
            ...coloredBase,
            ...STICKY_CELL,
            left: 0,
            textAlign: "left",
            borderTop,
            borderBottom
          }
        }>
        {first}
        {!avf && (
          <button
            type="button"
            style={{
              background: "transparent",
              border: "1px solid #cccccc",
              borderRadius: "3px",
              float: "right",
              height: "25px",
              width: "25px"
            }}
            onClick={() => (onExpand ? onExpand(id) : () => {})}>
            {isExpanded ? "-" : "+"}
          </button>
        )}

        {!avf && RIGHT_ENDCAP_DIVIDER}
      </td>

      {rest.map((value, index) => {
        return (
          <DetailCell
            value={value}
            columnIndex={index}
            style={style || { ...coloredBase, borderTop, borderBottom }}
            editableRow={editableRow}
            forecastBegins={forecastBegins}
            field={field}
            avf={avf}
          />
        );
      })}

      <td
        style={
          style || {
            ...coloredBase,
            ...STICKY_CELL,
            right: 0,
            borderTop,
            borderBottom
          }
        }>
        {(field && summableFields.includes(field)) || avf ? last : "N/A"}
        {!avf && LEFT_ENDCAP_DIVIDER}
      </td>
    </>
  );
};
