import React, { useState, useMemo } from "react";
import { Divider, Typography, IconButton, Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import DatePicker from "ui-library/DatePicker";
import AutoComplete from "ui-library/Autocomplete";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { DataGridPro, GridRowParams } from "@mui/x-data-grid-pro";
import ClearIcon from "@mui/icons-material/Clear";
import { DisplayERPTransactionObject } from "components/Deductions/DeductionsReconciliation/types/transactionTypes";

const DATA_GRID_COLUMNS = [
  {
    field: "fileName",
    headerName: "File Name",
    width: 220,
    editable: false
  },
  {
    field: "invoiceNumber",
    headerName: "Invoice Number",
    width: 170,
    editable: false
  },
  {
    field: "date",
    headerName: "Invoice Date",
    width: 175,
    editable: false
  },
  {
    field: "customer",
    headerName: "Customer Name",
    width: 170,
    editable: false
  },
  {
    field: "amount",
    headerName: "Amount",
    type: "number",
    width: 120,
    editable: false
  },
  {
    field: "status",
    headerName: "Status",
    type: "number",
    width: 140,
    editable: false
  }
];

// INTERFACES

export interface SimplifiedInvoice {
  id: string;
  invoiceKey: string;
  fileName: string;
  invoiceNumber: string;
  date: Date;
  customer: string;
  amount: string;
  status: string;
  type: string;
}

interface LinkInvoiceProps {
  customers: Array<string>;
  fileNamesAndInvoiceNumbers: Array<string>;
  invoices: Array<SimplifiedInvoice>;
  onLinkInvoice: (linkInvoiceList: SimplifiedInvoice[]) => void;
  onCancel: () => void;
  displayTransaction: DisplayERPTransactionObject;
}

interface LinkInvoiceFilterProps {
  customers: Array<string>;
  fileNamesAndInvoiceNumbers: Array<string>;
  onCustomerSelect: (customers: Array<string>) => void;
  onDateSelect: (date: Date) => void;
  onSearch: (searchCriteria: string) => void;
  onFileNameInvoiceNumberSelected: (
    selectedFileNamesAndInvoiceNumbers: Array<string>
  ) => void;
  onClearDate: () => void;
  selectedDate: Date | null;
}

interface LinkInvoiceDataGridProps {
  invoices: Array<SimplifiedInvoice>;
  onRowClick: (row: GridRowParams) => void;
}

interface LinkInvoiceSelectionProps {
  selectedInvoices: SimplifiedInvoice[];
  removeSelectionClick: (index: number) => void;
  linkInvoiceButtonDisabled: boolean;
  linkInvoiceFunction: () => void;
}

interface CancelButtonProps {
  cancelFunction: () => void;
}

// FUNCTIONS
const LinkInvoiceFilter = ({
  customers,
  fileNamesAndInvoiceNumbers,
  onCustomerSelect,
  onDateSelect,
  onSearch,
  onFileNameInvoiceNumberSelected,
  onClearDate,
  selectedDate
}: LinkInvoiceFilterProps) => {
  return (
    <div style={{ padding: 0, fontFamily: "Oxygen" }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography>Find Invoices</Typography>
          <Typography variant="caption">
            Only showing invoices with an amount less than or equal to
            transaction open amount.
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <b>Filter by Customer Name</b>
        </Grid>
        <Grid item xs={3}>
          <b>Filter by Date</b>
        </Grid>
        <Grid item xs={6}>
          <b>Search Uploaded Files & Invoice Numbers</b>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={3}>
          <AutoComplete
            multiple
            fullWidth
            variant="outlined"
            floatingLabelText="Please enter text or select"
            options={customers}
            onChange={(_event, selectedCustomers) =>
              onCustomerSelect(selectedCustomers)
            }
          />
        </Grid>
        <Grid
          item
          xs={3}
          px={2}
          sx={theme => ({ marginTop: theme.spacing(0.5) })}>
          <DatePicker
            id="date"
            label="Please select a date"
            onChange={onDateSelect}
            value={selectedDate ? selectedDate.toDateString() : null}
            format="MM-dd-yyyy"
            fullWidth
            inputVariant="outlined"
            InputProps={{
              endAdornment: (
                <IconButton onClick={onClearDate} size="large">
                  <ClearIcon />
                </IconButton>
              )
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <AutoComplete
            multiple
            variant="outlined"
            fullWidth
            floatingLabelText="Search by file name or invoice number"
            options={fileNamesAndInvoiceNumbers}
            onChange={(event, selectedFiles) =>
              onFileNameInvoiceNumberSelected(selectedFiles)
            }
            onInputChange={(event, text) => onSearch(text)}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const LinkInvoiceDataGrid = ({
  invoices,
  onRowClick
}: LinkInvoiceDataGridProps) => {
  return (
    <Paper sx={{ width: "100%" }}>
      <div style={{ padding: "2% 0% 0% 0%", width: "100%" }}>
        <DataGridPro
          rows={invoices.map(iv => {
            return {
              ...iv,
              date: `${
                iv.date.getMonth() + 1
              }-${iv.date.getDate()}-${iv.date.getFullYear()}`
            };
          })}
          columns={DATA_GRID_COLUMNS}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection={false}
          disableSelectionOnClick={false}
          onRowClick={onRowClick}
          autoHeight
        />
      </div>
    </Paper>
  );
};

const CancelButton = ({ cancelFunction }: CancelButtonProps) => {
  return (
    <div style={{ padding: "2% 0% 0% 0%" }}>
      <div style={{ padding: "2% 0% 0% 0%" }}>
        <Button variant="contained" onClick={cancelFunction}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

const LinkInvoiceSelection = ({
  selectedInvoices,
  removeSelectionClick,
  linkInvoiceButtonDisabled,
  linkInvoiceFunction
}: LinkInvoiceSelectionProps) => {
  const selectedInvoicesJSX = selectedInvoices?.map(
    (selectedInvoice, index) => {
      return (
        <TableRow key={selectedInvoice.fileName}>
          <TableCell scope="row">{selectedInvoice.fileName}</TableCell>
          <TableCell>{selectedInvoice.invoiceNumber}</TableCell>
          <TableCell>{selectedInvoice.date}</TableCell>
          <TableCell>{selectedInvoice.customer}</TableCell>
          <TableCell>{selectedInvoice.amount}</TableCell>
          <TableCell>{selectedInvoice.status}</TableCell>
          <TableCell>
            <Button
              variant="outlined"
              sx={{ whiteSpace: "nowrap" }}
              onClick={() => {
                removeSelectionClick(index);
              }}>
              Remove Selection
            </Button>
          </TableCell>
        </TableRow>
      );
    }
  );
  return (
    <div style={{ width: "100%" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography>Selected Invoice(s)</Typography>
        <Button
          variant="contained"
          disabled={linkInvoiceButtonDisabled}
          onClick={linkInvoiceFunction}>
          Link Invoice(s)
        </Button>
      </div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Filename</TableCell>
              <TableCell>Invoice Number</TableCell>
              <TableCell>Invoice Date</TableCell>
              <TableCell>Customer Name</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Options</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{selectedInvoicesJSX}</TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const LinkInvoice = ({
  customers,
  fileNamesAndInvoiceNumbers,
  invoices,
  onLinkInvoice,
  displayTransaction,
  onCancel
}: LinkInvoiceProps) => {
  const [selectedCustomers, setSelectedCustomers] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [searchCriteria, setSearchCriteria] = useState<string>("");
  const [selectedInvoices, setSelectedInvoice] = useState<SimplifiedInvoice[]>(
    []
  );
  const [
    selectedFileNamesAndInvoiceNumbers,
    setSelectedFileNamesAndInvoiceNumbers
  ] = useState<string[]>([]);

  const handleLinkInvoiceButtonClick = () => {
    if (selectedInvoices.length > 0) {
      onLinkInvoice(selectedInvoices);
    }
  };

  const handleClearDate = () => {
    setSelectedDate(null);
  };

  const handleRemoveSelection = (index: number) => {
    selectedInvoices.splice(index, 1);
    setSelectedInvoice([...selectedInvoices]);
  };

  const shouldRenderDataGrid =
    selectedCustomers.length > 0 ||
    selectedDate ||
    searchCriteria.length > 0 ||
    selectedFileNamesAndInvoiceNumbers.length > 0;

  // chained filters so its more readable
  const filteredInvoices = useMemo(
    () =>
      invoices
        .filter(
          iv =>
            Math.abs(parseFloat(iv.amount) || 0) <=
            Math.abs(displayTransaction.openAmount)
        )
        .filter(
          iv =>
            !selectedDate ||
            iv.date.toDateString() === selectedDate.toDateString()
        )
        .filter(
          iv =>
            searchCriteria.length === 0 || iv.fileName.includes(searchCriteria)
        )
        .filter(
          iv =>
            selectedCustomers.length === 0 ||
            selectedCustomers.includes(iv.customer)
        )
        .filter(
          iv =>
            selectedFileNamesAndInvoiceNumbers.length === 0 ||
            selectedFileNamesAndInvoiceNumbers.includes(iv.fileName) ||
            selectedFileNamesAndInvoiceNumbers.includes(iv.invoiceNumber)
        ),
    [
      invoices,
      displayTransaction.openAmount,
      selectedDate,
      searchCriteria,
      selectedCustomers,
      selectedFileNamesAndInvoiceNumbers
    ]
  );

  return (
    <div>
      <Stack spacing={2} direction="column">
        {/* SELECTION SECTION - only visible when a row in table is selected  */}
        {selectedInvoices.length > 0 && (
          <LinkInvoiceSelection
            selectedInvoices={selectedInvoices}
            removeSelectionClick={handleRemoveSelection}
            linkInvoiceButtonDisabled={selectedInvoices.length === 0}
            linkInvoiceFunction={handleLinkInvoiceButtonClick}
          />
        )}

        <Divider />

        {/* FILTER */}
        <LinkInvoiceFilter
          customers={customers}
          fileNamesAndInvoiceNumbers={fileNamesAndInvoiceNumbers}
          selectedDate={selectedDate}
          onCustomerSelect={setSelectedCustomers}
          onDateSelect={setSelectedDate}
          onSearch={setSearchCriteria}
          onFileNameInvoiceNumberSelected={
            setSelectedFileNamesAndInvoiceNumbers
          }
          onClearDate={handleClearDate}
        />

        {/* DATA TABLE - only visible when filter is used */}
        {!!shouldRenderDataGrid && (
          <LinkInvoiceDataGrid
            invoices={filteredInvoices}
            onRowClick={({ row }) => {
              const newRow = row as SimplifiedInvoice;
              if (
                selectedInvoices.some(
                  invoice => invoice.invoiceKey === newRow.invoiceKey
                )
              ) {
                return;
              }
              setSelectedInvoice([...selectedInvoices, newRow]);
            }}
          />
        )}
      </Stack>

      {/* BUTTONS */}
      <CancelButton cancelFunction={onCancel} />
    </div>
  );
};

export { LinkInvoice };
