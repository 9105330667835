import firebase from "firebase-init";

function sendSMSEnrollCode(recaptchaVerifier, phoneNumber, multiFactorSession) {
  // Send SMS verification code to ENROLL in multi-factor authentication
  const phoneInfoOptions = {
    phoneNumber,
    session: multiFactorSession
  };
  const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
  return phoneAuthProvider.verifyPhoneNumber(
    phoneInfoOptions,
    recaptchaVerifier
  );
}

function sendSMSVerificationCode(recaptchaVerifier, multiFactorHint, resolver) {
  // Send SMS verification code to login with multi-factor authentication (User already enrolled)
  const phoneInfoOptions = {
    multiFactorHint,
    session: resolver.session
  };
  const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
  return phoneAuthProvider.verifyPhoneNumber(
    phoneInfoOptions,
    recaptchaVerifier
  );
}

function verifySMSEnroll(user, verificationId, verificationCode) {
  // Verifies SMS verification code and ENROLLS user in multi-factor authentication
  const cred = firebase.auth.PhoneAuthProvider.credential(
    verificationId,
    verificationCode
  );
  const multiFactorAssertion =
    firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
  const mfaDisplayName = "Phone Number";
  return user.multiFactor.enroll(multiFactorAssertion, mfaDisplayName);
}

function verifySMSLogin(resolver, verificationId, verificationCode) {
  // Verifies SMS verification code and SIGNS IN user with multi-factor authentication
  const cred = firebase.auth.PhoneAuthProvider.credential(
    verificationId,
    verificationCode
  );
  const multiFactorAssertion =
    firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
  return resolver.resolveSignIn(multiFactorAssertion);
}

export {
  sendSMSEnrollCode,
  sendSMSVerificationCode,
  verifySMSEnroll,
  verifySMSLogin
};
