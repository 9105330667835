import React from "react";

const BudgetLineLabel = ({ x, y, stroke, value, valueFormatter }) => (
  <text
    x={x}
    y={y}
    dy={-4}
    fill={stroke}
    fontSize={15}
    fontWeight="bold"
    textAnchor="middle">
    {valueFormatter(value)}
  </text>
);

export { BudgetLineLabel };
