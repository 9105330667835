import React, { useState, useEffect, useContext } from "react";
import {
  ResponsiveContainer,
  ComposedChart,
  PieChart,
  Pie,
  XAxis,
  YAxis,
  Legend,
  CartesianGrid,
  Bar,
  Line,
  Tooltip,
  Cell
} from "recharts";
import {
  renderLEFigure,
  renderTickFigure,
  broadFigureTypes,
  comparisonTypes,
  tooltipFormatter,
  comparisonTooltipFormatter,
  pivotTooltipFormatter,
  renderPercentage,
  pivotTypes,
  makeTableSubLegend
} from "helpers/Budget";
import { graphPalette } from "helpers/ColorPalettes";
import { InnerLoadingContext } from "./BudgetTool";
import { BudgetLineLabel } from "./BudgetLineLabel";
import { PieChartLabel } from "./PieChartLabel";
import BudgetWorker from "../../../helpers/Budget/budget.worker.js";
import {
  BroadFigureType,
  ComparisonType,
  DBSlice,
  FigureType,
  OrganisedData,
  PivotType,
  SubFigureType,
  TimeScaleType
} from "./types";

const { blue80, pink80, red80, yellow90 } = graphPalette;
const pieChartColors = [blue80, pink80, red80];

interface BudgetChartsProps {
  allData: OrganisedData;
  timeScale: TimeScaleType;
  figureType: FigureType;
  pivot: PivotType;
  comparisonType: ComparisonType;
  dataSubTypesForPivot: [SubFigureType, BroadFigureType];
  db: DBSlice;
  showPieChartOnly: boolean;
}

const BudgetCharts = ({
  allData,
  timeScale,
  figureType,
  pivot,
  comparisonType,
  dataSubTypesForPivot,
  db,
  showPieChartOnly
}: BudgetChartsProps) => {
  const [chartData, setChartData] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);
  const { innerLoading } = useContext(InnerLoadingContext);

  useEffect(() => {
    setChartData([]);
    setPieChartData([]);
    const calculationWorker = new BudgetWorker();
    calculationWorker.onmessage = ({ data }) => {
      if (
        pivot === pivotTypes.DATE &&
        broadFigureTypes.includes(figureType as BroadFigureType)
      ) {
        const timeSeriesData = data.filter(
          ({ timePeriod }) => timePeriod !== "total"
        );
        const recordForTotal = data.find(
          ({ timePeriod }) => timePeriod === "total"
        );
        const pieChartData = Object.entries(recordForTotal)
          .filter(([field]) => field !== "timePeriod")
          .map(([title, value], index) => ({
            title,
            value,
            // Fix incorrect slice size for negative values
            absoluteValue: Math.abs(value),
            color: pieChartColors[index]
          }));
        setChartData(timeSeriesData);
        setPieChartData(pieChartData);
      } else {
        setChartData(data);
      }
      calculationWorker.terminate();
    };
    calculationWorker.postMessage({
      type: "makeChartData",
      args: {
        data: allData,
        figureType,
        timeScale,
        comparisonType,
        pivot,
        db,
        dataSubTypesForPivot
      }
    });

    return () => calculationWorker.terminate();
  }, [allData]);

  return (
    <>
      {!innerLoading &&
      broadFigureTypes.includes(figureType as BroadFigureType) &&
      chartData.length &&
      pivot === pivotTypes.DATE ? (
        <div style={{ marginTop: 16 }}>
          <div className="row">
            {showPieChartOnly ? (
              <div className="col-lg-6 col-lg-offset-3">
                <ResponsiveContainer aspect={2}>
                  <PieChart className="budget-chart">
                    <Pie
                      data={pieChartData}
                      nameKey="title"
                      dataKey="absoluteValue"
                      outerRadius={150}
                      labelLine={false}
                      label={props => (
                        <PieChartLabel
                          formatter={tooltipFormatter(figureType)}
                          {...props}
                        />
                      )}>
                      {pieChartData.map(({ color }, index) => (
                        <Cell key={`cell-${index}`} fill={color} />
                      ))}
                    </Pie>
                    <Legend verticalAlign="top" />
                    <Tooltip formatter={tooltipFormatter(figureType)} />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            ) : (
              <>
                <div className="col-lg-6">
                  <ResponsiveContainer aspect={2}>
                    <ComposedChart data={chartData} className="budget-chart">
                      <XAxis dataKey="timePeriod" />
                      <YAxis tickFormatter={renderTickFigure(figureType)} />
                      <Legend
                        verticalAlign="top"
                        wrapperStyle={{
                          top: "-20px"
                        }}
                      />
                      <CartesianGrid />
                      <Bar dataKey="Budget" fill={blue80} />
                      <Bar dataKey="Actual" fill={red80} />
                      <Line
                        dataKey="Latest Estimate"
                        stroke={yellow90}
                        strokeWidth={4}
                        label={
                          <BudgetLineLabel
                            valueFormatter={renderLEFigure(figureType)}
                          />
                        }
                      />
                      <Tooltip
                        contentStyle={{
                          backgroundColor: "rgba(0, 0, 0, 0.8)",
                          color: "white"
                        }}
                        formatter={tooltipFormatter(figureType)}
                      />
                    </ComposedChart>
                  </ResponsiveContainer>
                </div>
                <div className="col-lg-6">
                  <ResponsiveContainer aspect={2}>
                    <ComposedChart data={chartData} className="budget-chart">
                      <XAxis dataKey="timePeriod" />
                      <YAxis
                        yAxisId="absoluteAxis"
                        tickFormatter={renderTickFigure(figureType)}
                      />
                      <YAxis
                        yAxisId="relativeAxis"
                        orientation="right"
                        tickFormatter={renderPercentage}
                      />
                      <Legend
                        verticalAlign="top"
                        wrapperStyle={{
                          top: "-20px"
                        }}
                      />
                      <CartesianGrid />
                      <Bar
                        yAxisId="absoluteAxis"
                        dataKey={`${comparisonTypes[comparisonType].title} Net`}
                        fill={blue80}
                      />
                      <Line
                        yAxisId="relativeAxis"
                        dataKey={`${comparisonTypes[comparisonType].title} %`}
                        stroke={yellow90}
                        strokeWidth={4}
                        label={
                          <BudgetLineLabel valueFormatter={renderPercentage} />
                        }
                      />
                      <Tooltip
                        contentStyle={{
                          backgroundColor: "rgba(0, 0, 0, 0.8)",
                          color: "white"
                        }}
                        formatter={comparisonTooltipFormatter(
                          figureType,
                          comparisonType
                        )}
                      />
                    </ComposedChart>
                  </ResponsiveContainer>
                </div>
              </>
            )}
          </div>
        </div>
      ) : null}
      {!innerLoading && chartData.length && pivot !== pivotTypes.DATE ? (
        <div style={{ marginTop: 32, marginBottom: 100 }}>
          <div className="row">
            <div className="col-lg-8 col-lg-offset-2">
              <ResponsiveContainer aspect={2}>
                <ComposedChart data={chartData} className="budget-chart">
                  <XAxis
                    dataKey="Name"
                    interval={0}
                    angle={45}
                    textAnchor="start"
                  />
                  <YAxis
                    yAxisId="absoluteAxis"
                    tickFormatter={renderTickFigure(
                      figureType,
                      pivot,
                      dataSubTypesForPivot
                    )}
                  />
                  <YAxis
                    yAxisId="relativeAxis"
                    orientation="right"
                    tickFormatter={renderPercentage}
                  />
                  <Legend
                    verticalAlign="top"
                    wrapperStyle={{
                      top: "-20px"
                    }}
                  />
                  <CartesianGrid />
                  <Bar
                    yAxisId="absoluteAxis"
                    dataKey={makeTableSubLegend(
                      dataSubTypesForPivot[1],
                      dataSubTypesForPivot[0]
                    )}
                    fill={blue80}
                  />
                  <Line
                    yAxisId="relativeAxis"
                    dataKey="Share Of Total"
                    stroke={yellow90}
                    strokeWidth={4}
                    label={
                      <BudgetLineLabel valueFormatter={renderPercentage} />
                    }
                  />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: "rgba(0, 0, 0, 0.8)",
                      color: "white"
                    }}
                    formatter={pivotTooltipFormatter(dataSubTypesForPivot)}
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export { BudgetCharts };
