import React from "react";
import MenuItem from "ui-library/MenuItem";
import makeStyles from "@mui/styles/makeStyles";
import AssignInvoicePagesTable from "./AssignInvoicePagesTable";

const useStyles = makeStyles(() => ({
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    height: "100%"
  },
  columnContainer: {
    display: "flex",
    flexDirection: "column"
  }
}));

export default function AssignPages() {
  const classes = useStyles();

  return (
    <div className={classes.rowContainer}>
      <div className={classes.columnContainer}>
        <AssignInvoicePagesTable />
      </div>
    </div>
  );
}

// generic menu options
export function getFilesMenu(newFiles: File[]): JSX.Element[] {
  return newFiles.length === 0
    ? [
        <MenuItem disabled value={null} key={null}>
          Please upload files first
        </MenuItem>
      ]
    : newFiles.map((file, _fileIdx) => (
        <MenuItem value={file.name} key={file.name}>
          {file.name}
        </MenuItem>
      ));
}
