import React from "react";

import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import { displayMoney } from "helpers/DataProcessing";
import {
  getFirebase,
  getPromotionsFirebase,
  updateFirebase,
  isUserCresicorEmployee,
  getFirebaseConsoleURL
} from "helpers/Firebase";
import { printMonthYear } from "helpers/Time";
import ModalContent from "components/WebsiteElements/ModalContent";
import { common, grey, red } from "@mui/material/colors";
import DataTable from "../tables/MuiDataTable";

const { white } = common;
const grey700 = grey["700"];
const { black } = common;
const red700 = red["700"];

const styles = {
  header: {
    marginLeft: "-15px",
    marginRight: "-50px",
    fontSize: 20,
    color: black
  },
  tabs: {
    backgroundColor: grey700
  }
};

export default class SpendProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      moneyItem: {},
      promFields: {},
      changes: false,
      messages: {},
      searchQuery: "",
      numDistributors: 1
    };
    this.spendProfileRef = React.createRef();
  }

  getMoneyData = (spendKey, category) => {
    getFirebase(
      4,
      actSpend => {
        this.setState({
          moneyItem: actSpend,
          fields: this.getFields(actSpend)
        });
        if (actSpend.promKey) {
          getPromotionsFirebase(prom => {
            this.setState({
              promFields: this.getPromFields(
                actSpend.promKey,
                prom,
                actSpend.lineKey
              )
            });
          }, actSpend.promKey);
        }
      },
      spendKey
    );
  };

  handleTextChange = event => {
    const newItem = this.state.moneyItem;
    newItem[event.target.id] = event.target.value;
    this.setState({
      moneyItem: newItem,
      changes: true
    });
  };

  handleSaveChanges = () => {
    const { moneyItem } = this.state;
    updateFirebase(4, moneyItem, this.props.spendKey);
    this.setState({
      changes: false
    });
  };

  prettyPrint = productsArray => {
    let products = "";
    for (let i = 0; i < productsArray.length; i++) {
      if (i != 0) {
        products += ", ";
      }
      products += productsArray[i];
    }
    return products;
  };

  getFields = moneyItem => {
    if (moneyItem && this.props.db.customers && this.props.db.products) {
      const fields = {
        Field: ["Date", "Spend", "Sales", "Customer", "Region", "Check Number"],
        Value: [
          moneyItem.date,
          displayMoney(moneyItem.spend),
          Math.round(moneyItem.sales),
          this.props.db.customers[moneyItem.customer].name,
          moneyItem.region,
          moneyItem.checkNumber
        ]
      };
      return fields;
    }
    return null;
  };

  getPromFields = (promKey, prom, lineKey) => {
    const line = prom.lines[lineKey];
    const fields = {
      Field: [
        "Promotion Name",
        "Promotion ID",
        "Line ID",
        "Month/Year",
        "Type",
        "First Receiver",
        "Status",
        "Total Expected Spend (line)",
        "Total Expected Unit Sales (line)",
        "Spend Rate (line)",
        "Product Group",
        "Account"
      ],
      Value: [
        prom.name,
        promKey,
        lineKey,
        printMonthYear(prom.month, prom.year),
        this.props.db.meta.fundTypes?.[line.type]?.name,
        prom.distributor,
        this.props.db.meta.statuses[prom.status],
        Math.round(line.totalExpSpend),
        Math.round(line.totalExpSales),
        line.spendRate,
        line.productGroup == "custom"
          ? "Custom"
          : this.props.db.meta.product_groups[line.productGroup].name,
        this.props.db.accounts?.[line.account]?.name
      ]
    };
    return fields;
  };

  componentDidMount() {
    this.getMoneyData(this.props.spendKey, this.props.category);

    if (!this.props.fixedHeight) {
      const ppHeight =
        $(window).height() - $(this.spendProfileRef.current).offset().top;
      this.setState({
        ppHeight // because of strange error of longer ppHeight post-mount
      });
    }
  }

  // This component is unique in that new queries to the database are needed even after the initial mount,
  // every time new props are received.
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getMoneyData(nextProps.spendKey, nextProps.category);

    if (!nextProps.fixedHeight && $(this.spendProfileRef.current).offset()) {
      const ppHeight =
        $(window).height() - $(this.spendProfileRef.current).offset().top;
      this.setState({
        ppHeight
      });
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    const firebaseConsoleURL = `${getFirebaseConsoleURL()}/companies/${
      this.props.db.companyid
    }/actual/${this.props.spendKey}`;
    return (
      <div>
        <ListItem sx={{ background: theme => theme.palette.readOnly.surface3 }}>
          <ListItemText
            primary="Spend/Sales Profile"
            secondary={
              <span>
                {this.state.moneyItem
                  ? `on ${this.state.moneyItem.date}`
                  : "Deleted"}
                {isUserCresicorEmployee() && (
                  <span>
                    {" "}
                    |{" "}
                    <a
                      href={firebaseConsoleURL}
                      target="_blank"
                      style={{ color: "#1b7acf" }}
                      rel="noreferrer">
                      View on Firebase Console
                    </a>
                  </span>
                )}
              </span>
            }
          />
        </ListItem>
        {this.state.moneyItem ? (
          <ModalContent
            style={{
              paddingLeft: "16px",
              paddingRight: "16px",
              height: this.state.ppHeight,
              overflow: "auto"
            }}
            innerRef={this.spendProfileRef}>
            <br />
            <DataTable title="Spend Details" data={this.state.fields} />
            <br />
            {Object.keys(this.state.promFields).length != 0 ? (
              <DataTable
                title="Additional Promotion Line Details"
                data={this.state.promFields}
              />
            ) : (
              <div>No promotion corresponding to this entry.</div>
            )}
            <br />
          </ModalContent>
        ) : (
          <div className="centering" style={{ margin: 16 }}>
            {`${
              this.props.category == 1 ? "Spend" : "Sales"
            } Item no longer exists.`}
          </div>
        )}
      </div>
    );
  }
}
