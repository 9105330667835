import React from "react";

const RADIAN = Math.PI / 180;

const PieChartLabel = ({
  cx,
  cy,
  midAngle,
  outerRadius,
  payload: { value },
  formatter
}) => {
  const radius = outerRadius / 2.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  if (value === 0) {
    return null;
  }

  return (
    <text
      x={x}
      y={y}
      fill="black"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central">
      {formatter(value)}
    </text>
  );
};

export { PieChartLabel };
