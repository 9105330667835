import Typography from "@mui/material/Typography";
import { Divider } from "pui-react-dividers";
import React from "react";

import { Toolbar, ToolbarTitle, ToolbarSeparator } from "ui-library/Toolbar";
import ToolbarGroup from "ui-library/ToolbarGroup";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import GetAppIcon from "@mui/icons-material/GetApp";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import Dialog from "ui-library/Dialog";
import Button from "ui-library/Button";
import IconButton from "ui-library/IconButton";
import IconMenu from "ui-library/IconMenu";
import MenuItem from "ui-library/MenuItem";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import TextField from "ui-library/TextField";
import PublishIcon from "@mui/icons-material/Publish";
import DatePicker from "ui-library/DatePicker";

import { exportFiles } from "helpers/Export";
import { pricingTable } from "helpers/DataProcessing";
import { firebase, removeFirebase } from "helpers/Firebase";
import { confirmDialog, infoDialog } from "helpers/OpenDialog";
import { grey, common } from "@mui/material/colors";
import { Stack } from "@mui/material";
import { graphPalette } from "helpers/ColorPalettes";
import PricingTable from "../graphs/PricingTable";
import UploadFiles from "../UploadFiles/UploadFiles";

const grey200 = grey["200"];
const { black } = common;
const { blue70, purple70 } = graphPalette;

const styles = {
  superheader: {
    fontFamily: "Ubuntu",
    marginTop: 10,
    padding: 0
  },
  modalTitle: {
    borderStyle: "solid",
    borderWidth: 0,
    borderBottomWidth: 2,
    borderBottomColor: grey200
  },
  modalActions: {
    borderStyle: "solid",
    borderWidth: 0,
    borderTopWidth: 2,
    borderTopColor: grey200
  },
  dialogRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 0,
    paddingBottom: 10,
    zIndex: 1300
  },
  dialogContent: {
    position: "relative",
    minWidth: "90vw",
    maxWidth: "90vw",
    transform: ""
  },
  dialogBody: {
    paddingBottom: 0,
    minHeight: 650
  },
  legend: {
    display: "inline-block",
    width: "16px",
    height: "16px",
    borderRadius: "4px"
  }
};

class Pricing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: {},
      currentlySelected: "",
      showSearch: false,
      searchQuery: "",
      openFilePicker: false,
      productGroupsJSX: [],
      productGroup: 0,
      modalOpen: false,
      curDate: new Date(new Date().setHours(0, 0, 0, 0))
    };
  }

  openFilePicker = jsx => {
    this.setState({
      openFilePicker: true
    });
    this.props.showDropDownWindow(jsx);
  };

  closeFilePicker = () => {
    this.setState({
      openFilePicker: false
    });
  };

  handleSearchButton = () => {
    this.setState({
      showSearch: true
    });
  };

  handleSearch = event => {
    this.setState({
      searchQuery: event.target.value.toLowerCase()
    });
  };

  filterData = dataList => {
    const filteredList = [];
    for (let i = 0; i < dataList.length; i++) {
      const d = dataList[i];
      if (
        !this.state.searchQuery ||
        d.customerName.toLowerCase().indexOf(this.state.searchQuery) != -1
      ) {
        filteredList.push(d);
      }
    }
    return filteredList;
  };

  prettyPrint = list => {
    let result = "";
    for (let i = 0; i < list.length; i++) {
      if (i != 0) {
        result += ", ";
      }
      result += list[i];
    }
    return result;
  };

  deletePricing = () => {
    confirmDialog(
      this.props.openClose,
      "Delete Pricing",
      "Are you sure you wish to delete ALL pricing? This cannot be undone.",
      () => removeFirebase(7)
    );
  };

  render() {
    const pricingTableData = this.filterData(
      pricingTable(
        this.props.db.pricing,
        this.props.db.customers,
        this.state.curDate
      )
    );
    const user = firebase.auth().currentUser;
    return (
      <div>
        <Toolbar>
          <ToolbarGroup>
            <ToolbarTitle text="Pricing" style={{ color: black }} />
            <ToolbarSeparator flexItem />
            <IconButton
              onClick={this.handleSearchButton}
              tooltip="Search"
              size="large">
              <SearchIcon />
            </IconButton>
            {this.state.showSearch && (
              <TextField
                style={{ marginTop: 0 }}
                placeholder="Search..."
                onChange={this.handleSearch}
              />
            )}
          </ToolbarGroup>

          <ToolbarGroup>
            <DatePicker
              disableToolbar
              floatingLabelText="View Pricing List At"
              value={this.state.curDate}
              compact
              onChange={newDate => {
                this.setState({ curDate: newDate });
              }}
              inputFormat="MMM dd, yyyy"
            />
            <IconMenu
              iconButtonElement={
                <IconButton tooltip="Settings" size="large">
                  <SettingsIcon />
                </IconButton>
              }
              anchorOrigin={{ horizontal: "right", vertical: "top" }}
              targetOrigin={{ horizontal: "right", vertical: "top" }}
              onChange={this.props.handleAdditionalDataDisplay}>
              <MenuItem
                children={<Typography variant="subtitle1">Export</Typography>}
                disabled
              />
              <MenuItem
                value={0}
                children="Download Prices"
                leftIcon={<GetAppIcon />}
                onClick={() => {
                  exportFiles(
                    user,
                    this.props.db.companyid,
                    "Pricing",
                    "cresicor",
                    null,
                    this.state.curDate.toLocaleDateString()
                  );
                  infoDialog(
                    this.props.openClose,
                    "Export In Progress",
                    "Your export is being prepared and will be emailed to you shortly.",
                    "OK"
                  );
                }}
              />
              <MenuItem
                value={1}
                children="Delete All Pricing Data"
                leftIcon={<DeleteForeverIcon />}
                onClick={this.deletePricing}
              />
            </IconMenu>
            {this.props.db.permissions.includes("add") &&
              !this.props.readOnly && (
                <Button
                  label="Load from File"
                  onClick={this.openFilePicker.bind(
                    null,
                    <UploadFiles
                      selectedDataType="Pricing"
                      openClose={this.props.openClose}
                    />
                  )}
                  icon={<PublishIcon />}
                />
              )}
          </ToolbarGroup>
        </Toolbar>
        <div
          style={{
            paddingRight: 30,
            paddingLeft: 30,
            height: $(window).height() - 130,
            overflow: "auto"
          }}>
          <div>
            <Typography style={styles.superheader} variant="h5">
              Manage Pricing
            </Typography>
            <Divider />
            <Stack justifyContent="space-between" sx={{ mb: 1 }}>
              <Button
                label={<OpenWithIcon />}
                tooltip="Expand Pricing Table"
                onClick={() => this.setState({ modalOpen: true })}
              />
              <Stack spacing={2} sx={{ ml: "100px" }}>
                <Typography>Customer Kind:</Typography>
                <Stack>
                  <div
                    style={{ backgroundColor: `${purple70}`, ...styles.legend }}
                  />
                  <Typography> Distributor </Typography>
                </Stack>
                <Stack>
                  <div
                    style={{
                      backgroundColor: `${blue70}`,
                      ...styles.legend
                    }}
                  />
                  <Typography> Direct </Typography>
                </Stack>
                <Stack>
                  <div
                    style={{
                      backgroundColor: `${black}`,
                      ...styles.legend
                    }}
                  />
                  <Typography> Indirect </Typography>
                </Stack>
              </Stack>
            </Stack>
            <PricingTable
              style={{ height: 500, overflow: "auto" }}
              data={pricingTableData}
              db={this.props.db}
              outOfDate={this.props.outOfDate}
              openClose={this.props.openClose}
            />
            <br />
            <br />
          </div>
        </div>
        <Dialog
          dialogTitle="Manage Pricing"
          open={this.state.modalOpen}
          onClose={() => this.setState({ modalOpen: false })}
          titleStyle={styles.modalTitle}
          actionsContainerStyle={styles.modalActions}
          contentStyle={styles.dialogContent}
          bodyStyle={styles.dialogBody}
          style={styles.dialogRoot}
          fullWidth
          maxWidth="lg">
          <div
            style={{
              maxHeight: 650,
              display: "flex",
              flexDirection: "column"
            }}>
            <PricingTable
              style={{ overflow: "auto" }}
              data={pricingTableData}
              db={this.props.db}
              readOnly={this.props.readOnly}
              openClose={this.props.openClose}
            />
          </div>
        </Dialog>
      </div>
    );
  }
}

export default Pricing;
