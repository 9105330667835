import React from "react";
import axios from "axios";

import { Toolbar, ToolbarTitle, ToolbarSeparator } from "ui-library/Toolbar";
import ToolbarGroup from "ui-library/ToolbarGroup";

import AddIcon from "@mui/icons-material/AddBox";
import AdvancedSearchIcon from "@mui/icons-material/ZoomIn";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import DownloadIcon from "@mui/icons-material/GetApp";
import InterfaceIcon from "@mui/icons-material/DesktopWindows";
import SortIcon from "@mui/icons-material/Sort";
import { CircularProgress, Stack } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import UploadIcon from "@mui/icons-material/Publish";
import FunctionsIcon from "@mui/icons-material/Functions";
import Button from "ui-library/Button";
import IconButton from "ui-library/IconButton";
import IconMenu from "ui-library/IconMenu";
import MenuItem from "ui-library/MenuItem";
import TextField from "ui-library/TextField";
import { CSVLink } from "react-csv";
import DateParser from "fast-date-parse";

import { displayMoney, escapeQuotes } from "helpers/DataProcessing";
import {
  addFirebase,
  removeFirebase,
  firebase,
  cloudRunFunctionURL,
  forecastCloudRunURL
} from "helpers/Firebase";
import { revenueSatisfiesSearch } from "helpers/satisfiesSearch";
import { sortByField } from "helpers/sortByDate";
import { common } from "@mui/material/colors";
import { toUTCDate } from "helpers/Time";

import PaginationMUITable from "../tables/PaginationMUITable";
import AdvancedDeletion from "./AdvancedDeletion";
import NewRevenue from "./NewRevenue";
import RevenueProfile from "./RevenueProfile";
import SearchRevenue from "./SearchRevenue";
import CalcAllOtherBuckets from "./CalcAllOtherBuckets";
import UploadFiles from "../UploadFiles/UploadFiles";

const { black } = common;

const styles = {
  search: { marginBottom: 10 }
};

const pagelength = 20;

class PropDataUpdatedCSVLink extends CSVLink {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data, headers, separator, uFEFF } = nextProps;
    this.setState({ href: this.buildURI(data, uFEFF, headers, separator) });
  }
}

class Revenue extends React.PureComponent {
  constructor(props) {
    super(props);
    this.csvLink = React.createRef();
    this.state = {
      spend: {},
      sales: {},
      downloadData: [],
      headers: [
        { label: "Date", key: "date" },
        { label: "Month", key: "month" },
        { label: "Year", key: "year" },
        { label: "Customer Name", key: "customerNameField" },
        { label: "First Receiver", key: "firstReceiverField" },
        { label: "Product Name", key: "productNameField" },
        { label: "Unit Price", key: "unitPriceField" },
        { label: "Actual Unit Sales", key: "actSalesField" },
        { label: "Revenue ($)", key: "revenueField" },
        { label: "Stores", key: "storesField" },
        { label: "Source", key: "sourceField" },
        { label: "Region", key: "regionField" },
        { label: "Customer Channel", key: "customerChannel" },
        { label: "Customer Class", key: "customerClass" },
        { label: "Customer Region", key: "customerRegion" },
        { label: "Purchase Order", key: "purchaseOrderField" }
      ],
      allSelected: [],
      showSearch: false,
      searchQuery: "",
      modalOpen: false,
      searchRevenue: {},
      category: 0,
      count: 0,
      pagelength: 20,
      revenueLength: 0,
      rowsSelected: [],
      allOtherData: [0, []],
      allOtherBreakdown: {},
      allOtherJSX: [],
      revenueJSX: [],
      interface: 0,
      loading: false
    };
  }

  openFilePicker = jsx => {
    this.setState({
      openFilePicker: true
    });
    this.props.showDropDownWindow(jsx);
  };

  closeFilePicker = () => {
    this.setState({
      openFilePicker: false
    });
  };

  addNewRevenue = () => {
    this.props.openClose.setAppModal(
      "Add New Revenue",
      <NewRevenue openClose={this.props.openClose} db={this.props.db} />,
      null,
      false,
      true,
      "md"
    );
  };

  deleteRevenueItems = () => {
    this.props.openClose.setAppModal(
      "Delete Revenue Item(s)",

      <div className="centering">
        Are you sure you wish to delete these revenue item(s)?
      </div>,

      <Stack>
        <Button
          label="Yes, I'm sure"
          color="error"
          variant="text"
          onClick={() => {
            this.props.openClose.closeAppModal();
            this.props.openClose.closeAppModal();
            this.deleteActualsPostgres(this.state.allSelected);
            removeFirebase(6, this.state.allSelected, () => {
              this.setState({
                allSelected: []
              });
            });
          }}
        />
        <Button
          label="No, go back"
          variant="text"
          onClick={() => {
            this.props.openClose.closeAppModal();
          }}
        />
      </Stack>
    );
  };

  advancedDelete = (
    deleteAll,
    deleteStartDate,
    deleteEndDate,
    deleteRevenueSources
  ) => {
    if (deleteAll) {
      removeFirebase(6);
      this.deleteActualsPostgres(Object.keys(this.props.db.revenue));
    } else if (deleteRevenueSources || (deleteStartDate && deleteEndDate)) {
      const { revenue } = this.props.db;
      let removeKeys = Object.keys(revenue);
      if (deleteStartDate && deleteEndDate) {
        removeKeys = removeKeys.filter(
          key =>
            toUTCDate(deleteStartDate) <=
              toUTCDate(new Date(revenue[key].date)) &&
            toUTCDate(new Date(revenue[key].date)) <= toUTCDate(deleteEndDate)
        );
      }
      if (deleteRevenueSources) {
        removeKeys = removeKeys.filter(key =>
          deleteRevenueSources.includes(revenue[key].source)
        );
      }
      this.deleteActualsPostgres(removeKeys);
      removeFirebase(6, removeKeys, () => this.props.openClose.closeAppModal());
    }

    this.props.openClose.closeAppModal();
  };

  handleSearchButton = () => {
    this.setState({
      showSearch: true
    });
  };

  setSearchRevenue = searchRevenue => {
    this.setState({ searchRevenue });
  };

  handleSearch = event => {
    this.handleInfiniteLoad(true);
    this.setState({ searchQuery: event.target.value.toLowerCase() });
  };

  handleProfileOpen = (event, rowData) => {
    const revenueKey = rowData.key;
    this.props.showRightDrawer(
      <RevenueProfile
        revenueKey={revenueKey}
        category={this.state.category}
        db={this.props.db}
      />
    );
  };

  prettyPrint = productsArray => {
    let products = "";
    for (let i = 0; i < productsArray.length; i++) {
      if (i != 0) {
        products += ", ";
      }
      products += productsArray[i];
    }
    return products;
  };

  handleClearOut = () => {
    this.props.openClose.setAppModal(
      "Confirm Delete",
      <div>
        Are you sure you want to clear out all Revenue data? You may re-upload
        data into a clean database.
      </div>,

      <Stack>
        <Button
          label="Confirm"
          color="error"
          variant="text"
          onClick={() => {
            removeFirebase(6);
            this.props.openClose.closeAppModal();
          }}
        />
        <Button
          label="Go back"
          variant="text"
          onClick={() => {
            this.props.openClose.closeAppModal();
          }}
        />
      </Stack>
    );
  };

  handleAdvancedDeletion = () => {
    this.props.openClose.setAppModal(
      "Advanced Deletion",
      <AdvancedDeletion
        mode="revenue"
        deletion={this.advancedDelete}
        openClose={this.props.openClose}
        noGoBack={this.handleAdvancedDeletion}
        db={this.props.db}
        revenue
      />
    );
  };

  onCalcAllOther = (months, year, buckets, granularity, source) => {
    const user = firebase.auth().currentUser;
    const formData = {
      companyid: this.props.companyid,
      months,
      year,
      buckets,
      granularity,
      source,
      userid: user.uid
    };
    this.props.openClose.closeAppModal();
    this.setState({ loading: true });
    axios
      .post(`${cloudRunFunctionURL}/api/calc_all_other_buckets`, {
        data: formData
      })
      .then(response => {
        const { allOtherData } = response.data.data;
        const { allOtherBreakdown } = response.data.data;
        const { missingPricingWarning } = response.data.data;
        const { conflictingDataWarning } = response.data.data;
        if (missingPricingWarning || conflictingDataWarning) {
          this.props.openClose.setAppModal(
            "Warning",
            <div className="centering">
              {missingPricingWarning}
              <br />
              {conflictingDataWarning}
            </div>,

            <div className="centering">
              <Button
                label="Ok"
                color="error"
                onClick={() => {
                  this.props.openClose.closeAppModal();
                }}
              />
            </div>
          );
        }
        this.setState({
          allOtherData,
          allOtherBreakdown,
          loading: false
        });
      })
      .catch(error => {
        this.props.openClose.setAppModal(
          "Error",
          <div>
            Unknown error occurred. Please try again or contact support if this
            error persists.
          </div>,

          <div>
            <Button
              label="Ok"
              onClick={() => {
                this.props.openClose.closeAppModal();
              }}
            />
          </div>
        );
      });
  };

  expandAllOtherRow = rowData => {
    // callback on expansion of all-other item in table
    const key = rowData.index;
    const breakdown = this.state.allOtherBreakdown[key];
    let breakdownDisplayDataRetailers = [];
    let breakdownDisplayDataDistributors = [];
    for (const s of breakdown) {
      const customer = this.props.db.customers[s.customer];
      const customerNameField = customer && customer.name;
      const productNameField = this.props.db.products[s.product].name ?? "N/A";
      const unitPriceField = s.unitPrice || "N/A";
      const actSalesField =
        s.actSales || s.actSales === 0 ? Math.round(s.actSales) : "N/A";
      const sourceField = s.source || "N/A";
      if (customer.isDistributor) {
        breakdownDisplayDataDistributors.push({
          date: s.date,
          customerName: customerNameField,
          productName: productNameField,
          unitPrice: unitPriceField,
          actSales: actSalesField,
          source: sourceField
        });
      } else {
        breakdownDisplayDataRetailers.push({
          date: s.date,
          percentage: s.percentage,
          distributed_sales: s.distributed_sales,
          customerName: customerNameField,
          productName: productNameField,
          unitPrice: unitPriceField,
          actSales: actSalesField,
          source: sourceField
        });
      }
    }
    breakdownDisplayDataRetailers = sortByField(
      "customerName",
      breakdownDisplayDataRetailers,
      true
    );
    breakdownDisplayDataDistributors = sortByField(
      "customerName",
      breakdownDisplayDataDistributors,
      true
    );
    const expansionColumnsRetailers = [
      {
        title: "Date",
        field: "date"
      },
      {
        title: "Customer (Retailer)",
        field: "customerName"
      },
      {
        title: "Product",
        field: "productName"
      },
      {
        title: "Actual Sales",
        field: "actSales"
      },
      {
        title: "Distributed (%)",
        field: "percentage"
      },
      {
        title: "Distributed Sales",
        field: "distributed_sales"
      },
      {
        title: "Source",
        field: "source"
      }
    ];
    const expansionColumnsDistributors = [
      {
        title: "Date",
        field: "date"
      },
      {
        title: "Customer (Distributor)",
        field: "customerName"
      },
      {
        title: "Product",
        field: "productName"
      },
      {
        title: "Actual Sales",
        field: "actSales"
      },
      {
        title: "Source",
        field: "source"
      }
    ];
    return (
      <div
        style={{
          maxHeight: 300,
          overflow: "scroll",
          marginLeft: 5,
          marginRight: 5,
          borderColor: "#000000",
          borderWidth: 1,
          borderStyle: "solid"
        }}>
        <PaginationMUITable
          data={breakdownDisplayDataRetailers}
          columns={expansionColumnsRetailers}
          selection
          allLoaded
          style={{ overflow: "hidden", height: "100%" }}
        />
        <PaginationMUITable
          data={breakdownDisplayDataDistributors}
          columns={expansionColumnsDistributors}
          selection
          allLoaded
          style={{ overflow: "hidden", height: "100%" }}
        />
      </div>
    );
  };

  getAllOtherJSX = filteredItems => {
    const allOtherJSX = [];
    for (let i = 0; i < filteredItems.length; i++) {
      const s = filteredItems[i];
      allOtherJSX.push({
        key: s.key,
        index: i,
        date: s.date.toLocaleDateString(),
        customerName: s.customerNameField,
        productName: s.productNameField,
        unitPrice: s.unitPriceField,
        actSales: s.actSalesField,
        revenue: displayMoney(Math.round(s.revenueField)),
        source: s.source
      });
    }
    return allOtherJSX;
  };

  getAllOtherList = (props, cutoff = true) => {
    const allOtherList = [];
    let num = 0;
    const parser = new DateParser("YYYY-MM-DD");
    for (const key in this.state.allOtherData) {
      const s = { ...this.state.allOtherData[key] };
      const customer = props.db.customers[s.customer];
      s.key = key;
      s.date = parser.parse(s.date);
      s.month = s.date.getMonth() + 1;
      s.year = s.date.getFullYear();
      // customer fields
      s.customerNameField = customer && customer.name;
      s.customerRegion = customer && customer.region;
      s.customerChannel = customer && customer.channel;
      s.customerClass = customer && customer.class;
      // promotion fields
      s.firstReceivers =
        (customer.isDirect || customer.isDistributor
          ? [s.customer]
          : customer.distributors) || [];
      s.firstReceiverField = s.firstReceivers
        .map(x => props.db.customers[x].name)
        .join(" | ");
      s.productNameField = props.db.products[s.product]
        ? props.db.products[s.product].name
        : "N/A";
      s.unitPriceField = s.unitPrice || "N/A";
      s.actSalesField =
        s.actSales || s.actSales === 0 ? Math.round(s.actSales) : "N/A";
      s.revenueField = s.revenue;
      allOtherList.push(s);
      num++;
      if (cutoff && num > this.state.count + pagelength) {
        break;
      }
    }
    return allOtherList;
  };

  getAllOther = props => {
    axios
      .get(
        `${forecastCloudRunURL}/api/get_all_others_estimates?company_id=${props.companyid}`
      )
      .then(res => {
        const { data } = res;
        const allOther = data.map((row, i) => {
          return {
            // key: s.key,
            index: i,
            date: row.date,
            customerName: props.db.customers[row.customer].name,
            productName: props.db.products[row.product].name,
            unitPrice: row.price,
            actSales: row.sales,
            revenue: displayMoney(Math.round(row.revenue)),
            source: row.revenue_source
          };
        });
        this.setState({
          allOtherData: [data.length, allOther]
        });
      })
      .catch(error => {
        console.error(error);
      });
    // const allOtherList = this.getAllOtherList(props, true);
    // const allOtherLength = allOtherList.length;
    // const allOtherJSX = this.getAllOtherJSX(allOtherList);
    // return [allOtherLength, allOtherJSX];
  };

  importAllOther = () => {
    addFirebase(
      6,
      Object.values(this.state.allOtherData),
      null,
      Object.keys(this.state.allOtherData)
    );
    this.setState({ allOtherData: {} });
    this.props.openClose.showSnack(
      "All-Other Bucket data successfully imported."
    );
  };

  getRevenueJSX = filteredItems => {
    const selectedSet = new Set(this.state.allSelected);
    const revenueJSX = [];
    for (let i = 0; i < filteredItems.length; i++) {
      const s = filteredItems[i];
      const revenueVal = {
        key: s.key,
        date: s.date.toLocaleDateString(),
        customerName: s.customerNameField,
        productName: s.productNameField,
        unitPrice: s.unitPriceField,
        actSales: s.actSalesField,
        revenue: displayMoney(Math.round(s.revenueField)),
        stores: parseInt(s.storesField),
        source: s.sourceField,
        region: s.regionField,
        purchaseOrder: s.purchaseOrderField
      };
      if (selectedSet.has(s.key)) {
        revenueVal.tableData = { checked: true };
      }
      revenueJSX.push(revenueVal);
    }
    return revenueJSX;
  };

  deleteActualsPostgres = deletedKeys => {
    // delete only selected
    console.log("DELETEING ");
    const revenueDict = this.props.db.revenue;
    const now = new Date(0);
    now.setUTCMilliseconds(Date.now());
    const deleteObjects = deletedKeys.map(x => ({
      id: x,
      customer: revenueDict[x].customer,
      product: revenueDict[x].product,
      week: revenueDict[x].date.toISOString().split("T")[0],
      uploaded_at: now.toISOString().slice(0, -1),
      deleted_at: new Date("Dec 31, 9999 12:59:59").toISOString().slice(0, -1),
      stores: revenueDict[x].stores ? revenueDict[x].stores : 0,
      sales: revenueDict[x].actSales,
      revenue_source: revenueDict[x].source,
      companyid: this.props.companyid
    }));

    const payload = { actuals: deleteObjects };

    // postgres endpoint
    axios
      .post(`${forecastCloudRunURL}/api/delete_actuals`, payload, {
        headers: {
          "content-type": "application/json"
        }
      })
      .then(res => {
        console.log(res.data);
      })
      .catch(error => {
        console.error(error);
      });
  };

  getRevenueList = (props, cutoff = true) => {
    const revenueList = [];
    const allItems = props.db.revenue;
    let num = 0;
    for (const key in allItems) {
      const s = { ...allItems[key] };
      const customer = props.db.customers[s.customer];
      s.key = key;
      s.month = s.date.getMonth() + 1;
      s.year = s.date.getFullYear();
      // customer fields
      s.customerNameField = customer && customer.name;
      s.customerRegion = customer && customer.region;
      s.customerChannel = customer && customer.channel;
      s.customerClass = customer && customer.class;
      // promotion fields
      s.firstReceivers =
        (customer.isDirect || customer.isDistributor
          ? [s.customer]
          : customer.distributors) || [];
      s.firstReceiverField = s.firstReceivers
        .map(x => props.db.customers[x].name)
        .join(" | ");
      s.productNameField = props.db.products[s.product]
        ? props.db.products[s.product].name
        : "N/A";
      s.unitPriceField = s.unitPrice || "N/A";
      s.actSalesField =
        s.actSales || s.actSales === 0 ? Math.round(s.actSales) : "N/A";
      s.revenueField = s.revenue;
      s.storesField = s.stores || "N/A";
      s.sourceField = s.source || "N/A";
      s.regionField = s.region || "N/A";
      s.purchaseOrderField = s.purchaseOrder || "N/A";
      if (
        (this.state.searchQuery === "" ||
          (s.customerNameField &&
            s.customerNameField.toLowerCase().indexOf(this.state.searchQuery) !=
              -1) ||
          (s.productNameField &&
            s.productNameField.toLowerCase().indexOf(this.state.searchQuery) !=
              -1)) &&
        revenueSatisfiesSearch(s, this.state.searchRevenue)
      ) {
        revenueList.push(s);
        num++;
      }

      if (cutoff && num > this.state.count + this.state.pagelength) {
        break;
      }
    }

    return revenueList;
  };

  getRevenue = props => {
    const revenueList = this.getRevenueList(props, true);
    const revenueLength = revenueList.length;
    const revenueJSX = this.getRevenueJSX(revenueList);

    return [revenueLength, revenueJSX];
  };

  getDownloadData = () => {
    const totalRevenueList = this.getRevenueList(this.props, false);
    for (let i = 0; i < totalRevenueList.length; i++) {
      totalRevenueList[i].date = totalRevenueList[i].date.toLocaleDateString();
    }
    this.setState({ downloadData: totalRevenueList.map(escapeQuotes) }, () => {
      this.csvLink.current.link.click();
    });
  };

  handleInfiniteLoad = reset => {
    const count = reset ? pagelength : this.state.count + pagelength; // limit page to 20 customers for rendering speed
    this.setState({ count });
  };

  openAllOtherCalculation = () => {
    this.props.openClose.setAppModal(
      "Calculate All-Other Buckets",
      <CalcAllOtherBuckets
        db={this.props.db}
        onCalcAllOther={this.onCalcAllOther}
      />,
      null,
      false,
      true,
      "md"
    );
  };

  handleRowSelection = rows => {
    const allSelected = rows.map(row => row.key);
    this.setState({ allSelected });
  };

  getColumns = () => {
    if (this.state.interface == 0) {
      return [
        {
          title: "Date",
          field: "date"
        },
        {
          title: "Customer",
          field: "customerName"
        },
        {
          title: "Product",
          field: "productName"
        },
        {
          title: "Unit Price",
          field: "unitPrice"
        },
        {
          title: "Actual Sales",
          field: "actSales"
        },
        {
          title: "Revenue ($)",
          field: "revenue"
        },
        {
          title: "Stores",
          field: "stores"
        },
        {
          title: "Source",
          field: "source"
        },
        {
          title: "Region",
          field: "region"
        },
        {
          title: "Purchase Order",
          field: "purchaseOrder"
        }
      ];
    }
    return [
      {
        title: "Date",
        field: "date"
      },
      {
        title: "Customer",
        field: "customerName"
      },
      {
        title: "Product",
        field: "productName"
      },
      {
        title: "Unit Price",
        field: "unitPrice"
      },
      {
        title: "Actual Sales",
        field: "actSales"
      },
      {
        title: "Revenue ($)",
        field: "revenue"
      },
      {
        title: "Source",
        field: "source"
      },
      {
        title: "Purchase Order",
        field: "purchaseOrder"
      }
    ];
  };

  componentDidMount() {
    const tbHeight =
      document.documentElement.clientHeight -
      $(this.refs.table).offset().top -
      37;
    this.setState({
      tbHeight
    });
    const revenueKey = this.props.match?.params?.key;
    const allRevenue = this.props.db.revenue;
    if (revenueKey && revenueKey in allRevenue) {
      this.handleProfileOpen(null, { key: revenueKey });
    }
    this.getAllOther(this.props);
  }

  render() {
    const [revenueLength, revenueJSX] = this.getRevenue(this.props);
    const [allOtherLength, allOtherJSX] = this.state.allOtherData;
    const dataJSX =
      this.state.interface == 0
        ? revenueJSX
        : allOtherJSX.slice(0, this.state.count + pagelength);
    const dataLength =
      this.state.interface == 0 ? revenueLength : allOtherLength;
    const actionParams = {};
    if (this.state.interface == 0) {
      actionParams.openProfile = rowData => {
        this.handleProfileOpen;
      };
    }
    const columns = this.getColumns();
    const style =
      this.state.interface == 1
        ? { height: this.state.tbHeight, overflow: "auto" }
        : {};
    return (
      <div>
        <Toolbar>
          <ToolbarGroup>
            <ToolbarTitle text="Revenue" style={{ color: black }} />
            <ToolbarSeparator />
            <IconMenu
              iconButtonElement={
                <IconButton
                  tooltip="Select Interface"
                  style={styles.iconButton}
                  size="large">
                  <InterfaceIcon />
                </IconButton>
              }
              anchorOrigin={{ horizontal: "right", vertical: "top" }}
              targetOrigin={{ horizontal: "right", vertical: "top" }}
              onChange={(event, value) => {
                this.setState({
                  interface: value,
                  count: pagelength
                });
              }}>
              <MenuItem
                value={0}
                onClick={(event, value) => {
                  this.setState({
                    interface: 0,
                    count: pagelength
                  });
                }}
                leftIcon={<SortIcon />}
                rightIcon={this.state.interface == 0 ? <CheckIcon /> : null}>
                Revenue List
              </MenuItem>
              <MenuItem
                value={1}
                onClick={(event, value) => {
                  this.setState({
                    interface: 1,
                    count: pagelength
                  });
                }}
                leftIcon={<FunctionsIcon />}
                rightIcon={this.state.interface == 1 ? <CheckIcon /> : null}>
                All Other Bucket Calculation
              </MenuItem>
            </IconMenu>
            {this.state.interface == 0 &&
              (this.state.showSearch ? (
                <IconButton
                  onClick={this.props.showRightDrawer.bind(
                    null,
                    <SearchRevenue
                      searchRevenue={this.state.searchRevenue}
                      setSearchRevenue={this.setSearchRevenue}
                      openClose={this.props.openClose}
                      db={this.props.db}
                    />
                  )}
                  tooltip="Advanced Search"
                  style={styles.iconButton}
                  size="large">
                  <AdvancedSearchIcon />
                </IconButton>
              ) : (
                <IconButton
                  onClick={this.handleSearchButton}
                  tooltip="Search"
                  style={styles.iconButton}
                  size="large">
                  <SearchIcon />
                </IconButton>
              ))}
            {this.state.showSearch && (
              <TextField
                variant="standard"
                placeholder={`Search ${
                  Object.keys(this.props.db.revenue).length
                } results`}
                onChange={this.handleSearch}
                style={styles.search}
              />
            )}
          </ToolbarGroup>
          <ToolbarGroup>
            <IconMenu
              iconButtonElement={
                <IconButton tooltip="Settings" size="large">
                  <SettingsIcon />
                </IconButton>
              }
              anchorOrigin={{ horizontal: "right", vertical: "top" }}
              targetOrigin={{ horizontal: "right", vertical: "top" }}>
              <MenuItem
                value={0}
                disabled={!this.props.db.revenue}
                onClick={this.getDownloadData}
                leftIcon={<DownloadIcon />}>
                Download Revenue
              </MenuItem>
              {this.props.db.permissions.includes("revenue") &&
                this.state.interface == 0 &&
                !this.props.readOnly && (
                  <MenuItem
                    value={0}
                    onClick={this.handleAdvancedDeletion}
                    leftIcon={<DeleteIcon />}>
                    Advanced Deletion
                  </MenuItem>
                )}
            </IconMenu>
            {this.state.interface == 0 &&
              this.props.db.permissions.includes("revenue") &&
              !this.props.readOnly && (
                <IconButton
                  onClick={this.addNewRevenue}
                  tooltip="Add Item"
                  size="large">
                  <AddIcon />
                </IconButton>
              )}
            {this.state.interface == 0 &&
              this.props.db.permissions.includes("revenue") &&
              !this.props.readOnly && (
                <IconButton
                  onClick={this.deleteRevenueItems}
                  tooltip="Delete Revenue Item(s)"
                  disabled={this.state.allSelected.length == 0}
                  size="large">
                  <DeleteIcon />
                </IconButton>
              )}
            {this.state.interface == 0 &&
              this.props.db.permissions.includes("revenue") &&
              !this.props.readOnly && (
                <Button
                  label="Load from File"
                  onClick={this.openFilePicker.bind(
                    null,
                    <UploadFiles
                      selectedDataType="Revenue"
                      usePresets
                      revenueSources={this.props.db.meta.revenue_sources}
                      openClose={this.props.openClose}
                    />
                  )}
                  icon={<UploadIcon />}
                />
              )}
            {this.state.interface == 1 &&
              this.props.db.permissions.includes("revenue") &&
              !this.props.readOnly && (
                <Button
                  label="Calculate"
                  icon={<FunctionsIcon />}
                  onClick={this.openAllOtherCalculation}
                />
              )}
          </ToolbarGroup>
        </Toolbar>
        <div style={style} ref="table">
          {this.state.loading ? (
            <div className="centering" style={{ height: this.state.tbHeight }}>
              <CircularProgress size={80} thickness={5} />
            </div>
          ) : (
            <div>
              <PaginationMUITable
                selection
                openProfile={this.state.interface == 0}
                allSelected={this.state.allSelected}
                allLoaded={this.state.count >= dataLength}
                data={dataJSX}
                columns={columns}
                handleProfileOpen={
                  this.state.interface == 0 && this.handleProfileOpen
                }
                actionParams={actionParams}
                handleRowSelection={this.handleRowSelection}
                handleInfiniteLoad={this.handleInfiniteLoad}
                // TODO
                // expandRow={this.state.interface == 1 && this.expandAllOtherRow}
                selection={this.state.interface == 0}
                sorting={false}
              />
            </div>
          )}
        </div>
        {/* {this.state.interface == 1 && (
          <Stack>
            <Button
              label="Cancel"
              color="error"
              variant="outlined"
              disabled={!this.state.allOtherData}
              icon={<CancelIcon color={white} />}
              onClick={() => {
                this.setState({ allOtherData: {} });
              }}
            />
            <Button
              label="Confirm"
              disabled={!this.state.allOtherData}
              icon={<ConfirmIcon color={white} />}
              onClick={this.importAllOther}
            />
          </Stack>
        )} */}
        <PropDataUpdatedCSVLink
          data={this.state.downloadData}
          headers={this.state.headers}
          filename="Revenue Export.csv"
          className="hidden"
          ref={this.csvLink}
          target="_blank"
        />
      </div>
    );
  }
}

export default Revenue;
