import {
  appPrefix,
  forecastServicePrefix,
  cresicorNextPrefix,
  env
} from "./env";
import devConstants from "./constants/dev";
import prodConstants from "./constants/prod";
import sandboxConstants from "./constants/sandbox";

const config = {
  dev: {
    projectId: devConstants.projectId,
    cloudRunURL: `https://${appPrefix}${devConstants.cloudRunURLSuffix}`,
    forecastCloudRunURL: `https://${devConstants.forecastCloudRunURLSuffix}`,
    cloudRunFunctionURL: `https://${appPrefix}${devConstants.cloudRunFunctionSuffix}`,
    forecastServiceURL: `https://${forecastServicePrefix}${devConstants.forecastServiceURLSuffix}`,
    cresicorNextServiceURL: `https://${cresicorNextPrefix}${devConstants.cresicorNextURLSuffix}`,
    mainFirebaseApp: {
      apiKey: devConstants.firebaseAPIKey,
      authDomain: devConstants.firebaseAuthDomain,
      databaseURL: "https://cresicor-dev.firebaseio.com",
      projectId: devConstants.projectId,
      storageBucket: "cresicor-dev.appspot.com",
      messagingSenderId: "639243488914",
      appId: devConstants.firebaseAppId
    },
    forecastFirebaseApp: {
      apiKey: devConstants.firebaseAPIKey,
      databaseURL: "https://cresicor-dev-forecast.firebaseio.com/",
      authDomain: devConstants.firebaseAuthDomain
    }
  },
  prod: {
    projectId: prodConstants.projectId,
    cloudRunURL: `https://${appPrefix}${prodConstants.cloudRunURLSuffix}`,
    forecastCloudRunURL: `https://${prodConstants.forecastCloudRunURLSuffix}`,
    cloudRunFunctionURL: `https://${appPrefix}${prodConstants.cloudRunFunctionSuffix}`,
    forecastServiceURL: `https://${forecastServicePrefix}${prodConstants.forecastServiceURLSuffix}`,
    cresicorNextServiceURL: `https://${cresicorNextPrefix}${prodConstants.cresicorNextURLSuffix}`,
    mainFirebaseApp: {
      apiKey: prodConstants.firebaseAPIKey,
      authDomain: prodConstants.firebaseAuthDomain,
      databaseURL: "https://cresicor-db1c9.firebaseio.com/",
      projectId: prodConstants.projectId,
      storageBucket: "cresicor-db1c9.appspot.com",
      messagingSenderId: "266564883341",
      appId: prodConstants.firebaseAppId
    },
    forecastFirebaseApp: {
      apiKey: prodConstants.firebaseAPIKey,
      databaseURL: "https://cresicor-forecast.firebaseio.com/",
      authDomain: prodConstants.firebaseAuthDomain
    }
  },
  sandbox: {
    projectId: sandboxConstants.projectId,
    cloudRunURL: `https://${appPrefix}${sandboxConstants.cloudRunURLSuffix}`,
    cloudRunFunctionURL: `https://${appPrefix}${sandboxConstants.cloudRunFunctionSuffix}`,
    mainFirebaseApp: {
      apiKey: sandboxConstants.firebaseAPIKey,
      authDomain: sandboxConstants.firebaseAuthDomain,
      databaseURL: "https://cresicor-sandbox-default-rtdb.firebaseio.com/",
      projectId: sandboxConstants.projectId,
      storageBucket: "cresicor-sandbox.appspot.com",
      messagingSenderId: "672000275874",
      appId: sandboxConstants.firebaseAppId
    },
    forecastFirebaseApp: {
      apiKey: sandboxConstants.firebaseAPIKey,
      databaseURL: "https://cresicor-forecast.firebaseio.com/", // TODO: DVO-62
      authDomain: sandboxConstants.firebaseAuthDomain
    }
  }
};

export default config[env];
