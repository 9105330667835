import React from "react";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material";
import CollapsibleTable, {
  ColumnConfigOptions,
  FilterRecord,
  SortState
} from "ui-library/CollapsibleTable/CollapsibleTable";
import { CollapsibleTableSummaryConfig } from "ui-library/CollapsibleTable/CollapsibleTableSummary";
import { CollapsibleTableRowControls } from "ui-library/CollapsibleTable/CollapsibleTableRow";

export type ViewTableActionParams<T> = Record<
  string,
  (rowData: T) => {
    onClick: (event?: Event, rowData?: T) => void;
    icon?: string | OverridableComponent<SvgIconTypeMap<{}, "svg">>;
    tooltip?: string;
    disabled?: boolean;
    fontSize?: string;
  }
>;

export interface ViewTableProps<T> {
  data: T[];
  pageLength: number;
  onRowClick?: (event, rowData: T) => void;
  columnsConfig?: Record<string, ColumnConfigOptions<T>>;
  rowControls?: CollapsibleTableRowControls<T>[];
  fontSize?: string;
  activeFilters: FilterRecord;
  sortState: SortState;
  setActiveFilters: (payload: FilterRecord) => void;
  setSortState: (payload: SortState) => void;
  resetToDefaults: () => void;
  summaryConfig?: CollapsibleTableSummaryConfig<T>;
  summaryStyles?: {
    leftIndent: boolean;
  };
}

function ViewTable<T>(props: ViewTableProps<T>): JSX.Element {
  const {
    data,
    columnsConfig,
    pageLength,
    rowControls,
    fontSize,
    onRowClick,
    activeFilters,
    sortState,
    setActiveFilters,
    setSortState,
    resetToDefaults,
    summaryConfig,
    summaryStyles
  } = props;

  const columnConfig = new Map();
  Object.keys(columnsConfig || {}).forEach(key => {
    columnConfig.set(key, columnsConfig?.[key]);
  });

  return (
    <div>
      <CollapsibleTable<T>
        onRowClick={onRowClick}
        withBorder
        pagination={{
          enabled: true,
          pageSize: pageLength,
          rowsPerPageOptions: [pageLength, 40, 50]
        }}
        rowControls={rowControls}
        data={data}
        columnConfig={columnConfig}
        fontSize={fontSize}
        activeFilters={activeFilters}
        sortState={sortState}
        setActiveFilters={setActiveFilters}
        setSortState={setSortState}
        clearAllEnabled
        resetToDefaults={resetToDefaults}
        summaryConfig={summaryConfig}
        summaryStyles={summaryStyles}
      />
    </div>
  );
}

export default ViewTable;
