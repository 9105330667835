/* eslint-disable react/jsx-props-no-spreading */
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import FileSaver, { saveAs } from "file-saver";
import { CloudDownload, Visibility } from "@mui/icons-material";
import { useDb, DbContextValues } from "contexts/Db";
import { InvoiceObject, InvoiceLineObject } from "components/Deductions/models";
import FilePreview from "components/WebsiteElements/FilePreview";
import {
  downloadFilesFromStorage,
  getFromCurrentUserCompany,
  updateCurrentUserCompany
} from "helpers/Firebase";
import useWindowSize from "helpers/useWindowSize";
import React, {
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback
} from "react";
import { DRMRouteState } from "components/Deductions/DeductionsReconciliation/redux/DRMSlice";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { useSnackbar } from "notistack";
import CresicorCard from "ui-library/Card";
import { infoDialog, confirmDialog } from "helpers/OpenDialog";
import { invoiceStatuses } from "components/Deductions/constants/ReconciliationStatuses";
import UploadFiles from "components/UploadFiles/UploadFiles";
import {
  InvoiceDisplayService,
  InvoiceDependenciesService,
  InvoiceProcessingService,
  InvoiceLineDisplayService
} from "reconciliation-services";
import { invoiceActions } from "components/Deductions/DeductionsReconciliation/types/invoiceTypes";
import DRMBackButton from "components/Deductions/components/DRMBackButton";
import { useDRMEvents } from "contexts/DRMEvents";
import { DRMEventType } from "components/Deductions/DeductionsReconciliation/ActivityLog/DRMEvent";
import ActivityLogButton from "components/Deductions/DeductionsReconciliation/ActivityLog/ActivityLogButton";
import DownloadIcon from "@mui/icons-material/GetApp";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import StyledCSVLink from "ui-library/StyledCSVLink";
import { sortByDate } from "helpers/sortByDate";
import CresicorButton from "ui-library/Button";
import DataTable from "components/tables/MuiDataTable";
import { BACKUP_UPLOADER_FAKE_FILENAME } from "components/Deductions/DeductionsReconciliation/UploadFiles/UploadBackup/Step1/AddFiles";
import scanInvoice from "./ScanInvoice";
import { runMatching } from "../InvoiceLineMatching";
import BackupCard from "../BackupCard";
import MatchingResult from "../InvoiceLineMatching/MatchingResult";
import DRMInvoiceModalTitlebar from "../InvoiceLineMatching/DRMInvoiceModalTitlebar";
import DRMInvoiceModalButtons from "../InvoiceLineMatching/DRMInvoiceModalButtons";
import InvoiceProfileControls from "./InvoiceProfileControls";
import InvoiceProfileTable from "./InvoiceProfileTable";
import MatchedPromInfoObject from "../../../../models/MatchedPromInfoObject";
import AddInvoiceLines from "../AddInvoiceLines/AddInvoiceLines";
import { resetAddInvoiceLines } from "../AddInvoiceLines/redux/addInvoiceLinesSlice";

const useStyles = makeStyles(theme => ({
  previewContainer: {
    marginRight: "15px",
    overflow: "auto"
  },
  container: {
    marginLeft: "15px"
  },
  tableContainer: {
    paddingRight: "15px"
  },
  pdfTitleContainer: {
    marginRight: "15px",
    display: "flex"
  },
  downloadButton: {
    position: "relative",
    bottom: "2px"
  },
  backIcon: {
    position: "relative",
    bottom: "1px"
  },
  comingSoon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  showPreviewContainer: {
    cursor: "pointer",
    padding: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%"
  },
  showPreview: { textAlign: "center", color: theme.palette.grey["600"] },
  topContainer: {
    marginTop: theme.spacing(2)
  },
  activityLogButtonContainer: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "flex-end"
  }
}));
interface InvoiceProfileProps {
  invoice: InvoiceObject;
  invoiceKey: string;
  openClose: any;
}

const showInteractiveProfile = true;

function getInvoiceLinesPretty(
  db: DbContextValues,
  invoiceLines: InvoiceLineObject[]
) {
  return invoiceLines.map(line => {
    const linePretty = {};
    linePretty["Promotion Line ID"] = line.matchedPromLine;
    linePretty.Product = line.productKey
      ? db.products[line.productKey]?.name ?? "Unknown"
      : "";
    if (!linePretty.Product) {
      linePretty.Product = line.productGroupKey
        ? db.meta.product_groups[line.productGroupKey]?.name ?? "Unknown"
        : "";
    }
    linePretty.Customer = db.customers[line.customerKey]?.name ?? "Unknown";
    if (line.matchedProm && line.matchedPromLine) {
      const promLine =
        db.promotions[line.matchedProm]?.lines?.[line.matchedPromLine] ?? {};
      linePretty["Fund Type"] =
        db.meta.fundTypes?.[promLine.type]?.name ?? "Unknown";
      linePretty["Spend Rate"] = promLine.spendRate ?? "N/A";
    } else {
      linePretty["Fund Type"] = "";
      linePretty["Spend Rate"] = "";
    }
    linePretty.Date = new Date(line.date).toLocaleDateString();

    linePretty.Amount = line.amount;
    linePretty.Status =
      InvoiceLineDisplayService.HumanReadableInvoiceLineStatuses[line.status];
    return linePretty;
  });
}

const DRMInvoiceProfile = (props: InvoiceProfileProps): JSX.Element => {
  const classes = useStyles();
  const db = useDb();
  const {
    companyid,
    permissions,
    erpTransactions = {},
    companyUsers = {},
    customers = {},
    products = {},
    invoices = {},
    meta: { deductions_reconciliation: isDRMEnabled } = {}
  } = db;
  const [file, setFile] = useState<File | undefined>();
  const { height: windowHeight } = useWindowSize();
  const topContainerRef = useRef<HTMLDivElement>(null);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const { stepIndex } = useSelector(
    (state: RootState) => state.invoiceLinesMatching
  );
  const { invoiceKey, openClose } = props;
  const {
    closeAppModal,
    setAppModal,
    showSnack,
    showDropDownWindow,
    showStealthDropDownWindow,
    setDropDownWindowVisible,
    setMinimizedWindowToolbar
  } = openClose;

  const { addEvent } = useDRMEvents();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const baseInvoice = props.invoice;
  const [changedInvoiceFields, setChangedInvoiceFields] = useState<
    Partial<InvoiceObject>
  >({});
  const [scanButtonDisabled, setScanButtonDisabled] = useState<boolean>(false);
  const [
    uploadInvoiceLinesButtonDisabled,
    setUploadInvoiceLinesButtonDisabled
  ] = useState<boolean>(false);
  const [matchLoading, setMatchLoading] = useState(false);

  // recalculate data if the invoice changes
  const invoice: InvoiceObject = useMemo(
    () => ({ ...baseInvoice, ...changedInvoiceFields }),
    [baseInvoice, changedInvoiceFields]
  );

  const isFakeFile =
    invoice.originalFile.fileName === BACKUP_UPLOADER_FAKE_FILENAME;
  const invoiceLines: { [invoiceLineKey: string]: InvoiceLineObject } = useMemo(
    () => invoice.invoiceLines || {},
    [invoice]
  );
  const suggestedPromTypes = useMemo(
    () => invoice.suggestedPromTypes || {},
    [invoice]
  );
  const assignedUsers = useMemo(() => invoice.assignedUsers || {}, [invoice]);
  const isInvoiceCancelled = invoice.status === invoiceStatuses.CANCELLED;
  const canUserApproveInvoice = permissions.includes("approveInvoices");

  // generate display values
  const displayInvoice = useMemo(
    () =>
      InvoiceDisplayService.processInvoiceForDisplay(
        invoice,
        erpTransactions,
        customers,
        companyUsers
      ),
    [invoice, erpTransactions, customers, companyUsers]
  );

  // recalculate dependencies if invoice changes
  const allInvoiceDependencies = useMemo(
    () => InvoiceDependenciesService.calculateInvoiceDependencies(invoice),
    [invoice]
  );

  const saveInvoiceChanges = useCallback(
    (callback?: Function) => {
      const valid = Boolean(invoice.invoiceNumber);
      if (valid) {
        InvoiceProcessingService.updateFirebaseInvoice(invoice, () => {
          enqueueSnackbar(
            `Changes to invoice ${invoice.invoiceNumber} have been saved.`,
            {
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center"
              }
            }
          );

          const prevAssignedUsers =
            Object.values(invoices[invoiceKey]?.assignedUsers || {}) || [];
          const newAssignedUsers =
            Object.values(changedInvoiceFields.assignedUsers || {}) || [];

          // newlyAddedUsers
          const newlyAddedUsers = newAssignedUsers.filter(
            newUser => !prevAssignedUsers.find(prevUser => prevUser === newUser)
          );

          // removedUsers
          const newlyRemovedUsers = prevAssignedUsers.filter(
            prevUser => !newAssignedUsers.find(newUser => newUser === prevUser)
          );

          newlyAddedUsers.forEach(newUser => {
            addEvent({
              type: DRMEventType.INVOICE_ASSIGNED,
              metadata: {
                userId: newUser
              },
              invoiceKey
            });
          });

          newlyRemovedUsers.forEach(removedUser => {
            addEvent({
              type: DRMEventType.INVOICE_UNASSIGNED,
              metadata: {
                userId: removedUser
              },
              invoiceKey
            });
          });

          if (callback) callback();
        });
        setChangedInvoiceFields({});
      }
    },
    [
      invoice,
      invoiceKey,
      changedInvoiceFields,
      addEvent,
      invoices,
      enqueueSnackbar
    ]
  );

  const openMatchingResult = React.useCallback(() => {
    InvoiceProcessingService.resetPromotionInvoiceMatches(
      baseInvoice,
      () => {
        enqueueSnackbar(
          "Warning: any existing promotions matches to invoice lines have been reset.",
          {
            variant: "warning",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center"
            }
          }
        );
      },
      () => {
        enqueueSnackbar(
          "Unknown error occurred while attempting to rerun matcher (unable to reset existing promotion matches on invoice).",
          {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center"
            }
          }
        );
      }
    );

    saveInvoiceChanges(() => {
      runMatching(
        db,
        setMatchLoading,
        enqueueSnackbar,
        dispatch,
        invoice,
        () => {
          setAppModal({
            title: <DRMInvoiceModalTitlebar />,
            content: (
              <MatchingResult
                db={db}
                showRightDrawer={openClose.showRightDrawer}
                openClose={openClose}
                invoice={invoice}
                invoiceKey={invoiceKey}
                source={invoice.backupSource}
                stepIndex={stepIndex}
              />
            ),
            actions: <DRMInvoiceModalButtons />,
            maxWidth: "lg",
            backgroundColor: "white"
          });
        }
      );
    });
  }, [
    baseInvoice,
    db,
    enqueueSnackbar,
    dispatch,
    invoice,
    setAppModal,
    openClose,
    invoiceKey,
    stepIndex,
    saveInvoiceChanges
  ]);

  const handleAttemptInvoiceAction = useCallback(
    (action: invoiceActions): void => {
      const newOpenClose = {
        setAppModal,
        closeAppModal,
        showSnack,
        showDropDownWindow,
        setDropDownWindowVisible,
        setMinimizedWindowToolbar
      };
      const { actionAllowed, dependenciesMessage } =
        InvoiceDependenciesService.isSingleInvoiceActionAllowed(
          invoice,
          action,
          allInvoiceDependencies
        );
      switch (action) {
        case invoiceActions.APPROVE: {
          if (actionAllowed) {
            confirmDialog(
              newOpenClose,
              invoiceActions.APPROVE,
              "Are you sure you wish to approve this invoice?",
              () => {
                const approvedInvoice = {
                  ...invoice,
                  needsCresicorApproval: false
                };
                InvoiceProcessingService.updateFirebaseInvoice(
                  approvedInvoice,
                  () => {
                    showSnack(
                      `Invoice ${approvedInvoice.invoiceNumber} has been approved.`
                    );
                  }
                );
              }
            );
          } else {
            infoDialog(
              newOpenClose,
              `Error - ${invoiceActions.APPROVE}`,
              `Invoice ${invoice.invoiceNumber} cannot be approved because: ${dependenciesMessage}. Please handle these dependencies before trying again.`,
              `Go Back`
            );
          }
          break;
        }
        case invoiceActions.UNAPPROVE: {
          if (actionAllowed) {
            confirmDialog(
              newOpenClose,
              invoiceActions.UNAPPROVE,
              "Are you sure you wish to unapprove this invoice?",
              () => {
                const unapprovedInvoice = {
                  ...invoice,
                  needsCresicorApproval: true
                };
                InvoiceProcessingService.updateFirebaseInvoice(
                  unapprovedInvoice,
                  () => {
                    showSnack(
                      `Invoice ${unapprovedInvoice.invoiceNumber} has been unapproved.`
                    );
                  }
                );
              }
            );
          } else {
            infoDialog(
              newOpenClose,
              `Error - ${invoiceActions.UNAPPROVE}`,
              `Invoice ${invoice.invoiceNumber} cannot be unapproved because: ${dependenciesMessage}. Please handle these dependencies before trying again.`,
              `Go Back`
            );
          }
          break;
        }
        case invoiceActions.UPLOAD_INVOICE_LINES: {
          if (actionAllowed) {
            showDropDownWindow(
              <UploadFiles
                selectedDataType="InvoiceLines"
                openClose={newOpenClose}
                extraArgs={{ invoiceKey: invoice.key }}
              />
            );
          } else {
            infoDialog(
              newOpenClose,
              `Error - ${invoiceActions.UPLOAD_INVOICE_LINES}`,
              `New invoice lines may not be uploaded because: ${dependenciesMessage}. Please handle these dependencies before trying again.`,
              `Go Back`
            );
          }
          break;
        }
        case invoiceActions.ADD_INVOICE_LINES: {
          if (actionAllowed) {
            dispatch(resetAddInvoiceLines());
            setAppModal({
              title: "Add Invoice Lines",
              content: (
                <AddInvoiceLines
                  invoice={invoice}
                  customers={customers}
                  products={products}
                />
              ),
              maxWidth: "lg",
              staticModal: true,
              isFullWidth: true,
              backgroundColor: "white"
            });
          } else {
            infoDialog(
              newOpenClose,
              `Error - ${invoiceActions.ADD_INVOICE_LINES}`,
              `New invoice lines may not be added because: ${dependenciesMessage}. Please handle these dependencies before trying again.`,
              `Go Back`
            );
          }
          break;
        }
        case invoiceActions.DELETE_INVOICE_LINES: {
          if (actionAllowed) {
            confirmDialog(
              newOpenClose,
              invoiceActions.DELETE_INVOICE_LINES,
              "Are you sure you wish to delete all invoice lines from this invoice? This action cannot be undone.",
              () => {
                // Remove the invoiceLines from being matched to existing promotions
                const promotionInfoToFix: MatchedPromInfoObject[] =
                  Object.values(
                    getFromCurrentUserCompany(
                      `reconciliation/invoices/${invoiceKey}`
                    ).invoiceLines
                  )
                    .filter(
                      obj => (obj as InvoiceLineObject).matchedProm != null
                    )
                    .map(
                      (line: {
                        matchedProm: string;
                        matchedPromLine: string;
                      }) => {
                        return {
                          matchedProm: line.matchedProm,
                          matchedPromLine: line.matchedPromLine
                        };
                      }
                    );
                const promotionsRemove = {};
                promotionInfoToFix.forEach(info => {
                  const promotionKey = info.matchedProm;
                  const promotionLine = info.matchedPromLine;
                  promotionsRemove[
                    `promotionInvoices/${promotionKey}/lines/${promotionLine}/matchedInvoiceLines/${invoiceKey}`
                  ] = null;
                });
                updateCurrentUserCompany("promotions/", promotionsRemove);

                // Delete the invoice lines and reset to in progress
                const invoiceWithoutInvoiceLines = {
                  ...invoice,
                  invoiceLines: null,
                  status:
                    invoice.status === invoiceStatuses.NEEDS_ATTENTION
                      ? invoiceStatuses.IN_PROGRESS
                      : invoice.status
                };
                InvoiceProcessingService.updateFirebaseInvoice(
                  invoiceWithoutInvoiceLines,
                  () => {
                    showSnack(
                      `Invoice lines of invoice ${invoice.invoiceNumber} have been deleted.`
                    );
                    addEvent({
                      type: DRMEventType.ALL_INVOICE_LINES_DELETED,
                      invoiceKey
                    });
                  }
                );
                setChangedInvoiceFields({});
              }
            );
          } else {
            infoDialog(
              newOpenClose,
              `Error - ${invoiceActions.DELETE_INVOICE_LINES}`,
              `These invoice lines may not be deleted because: ${dependenciesMessage}. Please handle these dependencies before trying again.`,
              `Go Back`
            );
          }
          break;
        }

        case invoiceActions.SCAN: {
          if (actionAllowed) {
            setScanButtonDisabled(true);
            scanInvoice(
              invoice.originalFile,
              invoice.key,
              invoice,
              companyid,
              () => {
                showSnack("Scan started");
              },
              result => {
                const { success } = result;
                const { fileName } = result;

                showSnack(`Scan finished: ${success}`);
                setScanButtonDisabled(false);
              }
            );
          } else {
            infoDialog(
              newOpenClose,
              `Error - ${invoiceActions.SCAN}`,
              `Invoice ${invoice.invoiceNumber} may not be scanned because: ${dependenciesMessage}. Please handle these dependencies before trying again.`,
              `Go Back`
            );
          }
          break;
        }
        case invoiceActions.SCAN_AND_UPLOAD_INVOICE_LINES: {
          if (actionAllowed) {
            setScanButtonDisabled(true);
            setUploadInvoiceLinesButtonDisabled(true);
            scanInvoice(
              invoice.originalFile,
              invoice.key,
              invoice,
              db.companyid,
              () => {
                showSnack("Scan started");
              },
              result => {
                const { success } = result;
                const { fileName } = result;

                showSnack(`Scan finished: ${success}`);

                if (success === "Success") {
                  enqueueSnackbar(
                    "Scan Succeeded! Uploading Invoice Lines...",
                    {
                      variant: "success",
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                      }
                    }
                  );
                  showStealthDropDownWindow(
                    <UploadFiles
                      selectedDataType="InvoiceLines"
                      openClose={newOpenClose}
                      extraArgs={{ invoiceKey: invoice.key }}
                      stealthMode
                      fileName={fileName}
                      afterSuccessCallback={() => {
                        showSnack("Success!");
                        setScanButtonDisabled(false);
                        setUploadInvoiceLinesButtonDisabled(false);
                        openClose.closeDropDownWindow();
                      }}
                    />,
                    () => {
                      setScanButtonDisabled(false);
                      setUploadInvoiceLinesButtonDisabled(false);
                    }
                  );
                } else {
                  enqueueSnackbar(
                    "Scan Failed. :( Cannot proceed any further.",
                    {
                      variant: "error",
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                      }
                    }
                  );
                  setScanButtonDisabled(false);
                  setUploadInvoiceLinesButtonDisabled(false);
                }
              }
            );
          } else {
            infoDialog(
              newOpenClose,
              `Error - ${invoiceActions.SCAN_AND_UPLOAD_INVOICE_LINES}`,
              `Invoice ${invoice.invoiceNumber} may not be scanned, or invoice lines cannot be uploaded, because: ${dependenciesMessage}. Please handle these dependencies before trying again.`,
              `Go Back`
            );
          }
          break;
        }
        case invoiceActions.AUTO_MATCH: {
          if (actionAllowed) {
            openMatchingResult();
          } else {
            infoDialog(
              newOpenClose,
              `Error - ${invoiceActions.AUTO_MATCH}`,
              `These invoice lines may not be auto-matched because: ${dependenciesMessage}. Please handle these dependencies before trying again.`,
              `Go Back`
            );
          }
          break;
        }
        default: {
          break;
        }
      }
    },
    [
      setAppModal,
      closeAppModal,
      showSnack,
      showDropDownWindow,
      setMinimizedWindowToolbar,
      invoice,
      allInvoiceDependencies,
      dispatch,
      customers,
      products,
      invoiceKey,
      addEvent,
      companyid,
      openMatchingResult
    ]
  );

  const latestScanData = useMemo((): {
    Date: string[];
    User: string[];
    Download: JSX.Element[];
  } => {
    const { scannedResultFiles } = invoice;
    let attempts = sortByDate(
      Object.values(scannedResultFiles ?? {}),
      false,
      "date",
      false
    );
    // for MVP, just display a one-row table
    attempts = attempts.slice(-1);

    const dates: string[] = attempts.map(attempt => {
      const attemptDate = new Date(attempt.date);
      return `${attemptDate.toLocaleDateString()} at ${attemptDate.toLocaleTimeString()}`;
    });
    const users: string[] = attempts.map(
      attempt => db.companyUsers[attempt.uid]?.name ?? "Unknown User"
    );
    const downloadButtons: JSX.Element[] = attempts.map(attempt => (
      <Fragment key={attempt.fileName}>
        <CresicorButton
          label="Download"
          onClick={() => {
            downloadFilesFromStorage(
              [
                `${companyid}/Deductions Reconciliation/Scan Results/${invoiceKey}/${attempt.fileName}`
              ],
              [attempt.fileName],
              (blob, name) => {
                FileSaver.saveAs(blob, name);
              },
              null
            );
          }}
          icon={<DownloadIcon />}
        />
      </Fragment>
    ));

    return {
      Date: dates,
      User: users,
      Download: downloadButtons
    };
  }, [
    companyid,
    db.companyUsers,
    invoice,
    invoiceKey,
    uploadInvoiceLinesButtonDisabled
  ]);

  const { guiMode, drmFeatureState } = useSelector(
    (state: RootState) => state.drm,
    shallowEqual
  );

  useEffect(() => {
    downloadFilesFromStorage(
      [
        `${companyid}/Deductions Reconciliation/Backup Files/${invoice.originalFile?.fileBatch}/${invoice.originalFile?.fileName}`
      ],
      [invoice.originalFile?.fileName],
      blob => {
        setFile(
          new File([blob], invoice.originalFile?.fileName, {
            type: blob.type
          })
        );
      },
      null
    );
  }, [
    companyid,
    invoice.key,
    invoice.originalFile?.fileBatch,
    invoice.originalFile?.fileName
  ]);

  const downloadFile = (): void => {
    if (file) {
      const blob = new Blob([file]);
      saveAs(blob, file.name);
    }
  };
  const bottomContainerPosition =
    topContainerRef?.current?.getBoundingClientRect()?.top;
  const previewHeight = bottomContainerPosition
    ? (windowHeight ?? 0) - bottomContainerPosition - 15
    : null;
  const enablePreview = file && previewHeight && !isFakeFile;
  const cardStyle = {
    height: `${previewHeight}px`,
    maxHeight: "75vh"
  };

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item md={4}>
          <DRMBackButton
            text="VIEW ALL BACKUPS"
            toUrl={`/${drmFeatureState}/${DRMRouteState.INVOICES}/${guiMode}`}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={3}>
          <BackupCard displayInvoice={displayInvoice} invoice={invoice} />
        </Grid>
        <Grid item md={5} className={classes.activityLogButtonContainer}>
          <ActivityLogButton
            title="Invoice Activity Log"
            subtitle={`Invoice No. ${invoices[invoice.key].invoiceNumber}`}
            filters={{
              invoiceKey
            }}
          />
        </Grid>
      </Grid>
      <Grid container ref={topContainerRef} className={classes.topContainer}>
        {showInteractiveProfile && (
          <Grid item xs={8}>
            <div style={{ fontFamily: "Oxygen", paddingRight: "5px" }}>
              {/* TODO: Need to take care of case when user clicks out before saving changes? */}
              <InvoiceProfileControls
                allInvoiceDependencies={allInvoiceDependencies}
                setChangedInvoiceFields={setChangedInvoiceFields}
                invoice={invoice}
                isInvoiceCancelled={isInvoiceCancelled}
                handleAttemptInvoiceAction={handleAttemptInvoiceAction}
                canUserApproveInvoice={canUserApproveInvoice}
                suggestedPromTypes={suggestedPromTypes}
                assignedUsers={assignedUsers}
                onSave={() => saveInvoiceChanges()}
              />
              {isDRMEnabled && (
                <InvoiceProfileTable
                  scanButtonDisabled={scanButtonDisabled}
                  uploadInvoiceLinesButtonDisabled={
                    uploadInvoiceLinesButtonDisabled
                  }
                  handleAttemptInvoiceAction={handleAttemptInvoiceAction}
                  matchLoading={matchLoading}
                  invoiceKey={invoiceKey}
                  invoice={invoice}
                  invoiceLines={invoiceLines}
                  companyid={companyid}
                />
              )}
              <br />
              <DataTable
                title="Latest Scan Attempt"
                data={latestScanData}
                order={["Date", "User", "Download"]}
              />
              <StyledCSVLink
                disabled={Object.values(invoiceLines).length === 0}
                data={getInvoiceLinesPretty(db, Object.values(invoiceLines))}
                filename={`invoice_${invoice.invoiceNumber}.csv`}
                target="_blank">
                <>
                  <CloudDownloadIcon />
                  &nbsp; Download Invoice Detail
                </>
              </StyledCSVLink>
            </div>
          </Grid>
        )}
        {enablePreview && (
          <Grid item md={4}>
            <CresicorCard
              title={file?.name || "Preview"}
              action={
                <div>
                  <Tooltip title="Download PDF">
                    <IconButton
                      onClick={downloadFile}
                      color="inherit"
                      className={classes.downloadButton}
                      size="large">
                      <CloudDownload fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </div>
              }
              className={classes.previewContainer}
              cardContentStyle={{
                ...cardStyle,
                paddingRight: "0px",
                paddingLeft: "0px"
              }}>
              {showPreview ? (
                <FilePreview file={file} />
              ) : (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <div
                  onClick={() => setShowPreview(true)}
                  className={classes.showPreviewContainer}>
                  <div className={classes.showPreview}>
                    <Visibility fontSize="large" />
                    <Typography>Click to Show Preview</Typography>
                  </div>
                </div>
              )}
            </CresicorCard>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default DRMInvoiceProfile;
