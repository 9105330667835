import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DRMEventFilters } from "./DRMEventService";

interface ActivityLogState {
  loading: boolean;
  filters: DRMEventFilters;
  title: string;
  subtitle: string;
}

const initialState: Partial<ActivityLogState> = {
  loading: false,
  filters: {},
  title: "",
  subtitle: ""
};

const slice = createSlice({
  name: "activityLog",
  initialState,
  reducers: {
    setFilters(
      state: ActivityLogState,
      action: PayloadAction<DRMEventFilters>
    ) {
      state.filters = action.payload;
    },
    setLoading(state: ActivityLogState, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setTitle(state: ActivityLogState, action: PayloadAction<string>) {
      state.title = action.payload;
    },
    setSubtitle(state: ActivityLogState, action: PayloadAction<string>) {
      state.subtitle = action.payload;
    }
  }
});

// export redux action
export const { setFilters, setLoading, setSubtitle, setTitle } = slice.actions;

// export reducer as default import
export default slice.reducer;
