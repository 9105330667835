import React from "react";
import { CSVLink } from "react-csv";
import { amber, grey, common } from "@mui/material/colors";

import Avatar from "ui-library/Avatar";
import Button from "ui-library/Button";
import IconButton from "ui-library/IconButton";
import IconMenu from "ui-library/IconMenu";
import MenuItem from "ui-library/MenuItem";

// remaining refactoring for the toolbar
import TextField from "ui-library/TextField";
import { Toolbar, ToolbarTitle, ToolbarSeparator } from "ui-library/Toolbar";
import ToolbarGroup from "ui-library/ToolbarGroup";
import AddIcon from "@mui/icons-material/AddBox";
import DownloadIcon from "@mui/icons-material/GetApp";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import UploadIcon from "@mui/icons-material/Publish";

import { addFirebase, removeFirebase } from "helpers/Firebase";
import { sortByField } from "helpers/sortByDate";
import { confirmDialog, infoDialog } from "helpers/OpenDialog";
import { escapeQuotes } from "helpers/DataProcessing";
import { formatDependencies } from "helpers/Errors";
import ContactProfile from "./ContactProfile";
import PaginationMUITable from "../tables/PaginationMUITable";
import NewContact from "./NewContact";
import UploadFiles from "../UploadFiles/UploadFiles";

const amber500 = amber["500"];
const amber700 = amber["700"];
const grey600 = grey["600"];
const grey800 = grey["800"];
const { white } = common;
const { black } = common;

class PropDataUpdatedCSVLink extends CSVLink {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data, headers, separator, uFEFF } = nextProps;
    this.setState({ href: this.buildURI(data, uFEFF, headers, separator) });
  }
}

class Contacts extends React.Component {
  constructor(props) {
    super(props);
    this.csvLink = React.createRef();
    this.state = {
      contacts: {},
      currentlySelected: "",
      showSearch: false,
      searchQuery: "",
      importedContacts1: [],
      importedContacts2: [],
      modalOpen: false,
      modalOpen2: false,
      reverseSort: false,
      downloadData: [],
      headers: [
        { label: "Name", key: "name" },
        { label: "Email", key: "email" },
        { label: "Phone", key: "phone" },
        { label: "Company", key: "company" },
        { label: "Title", key: "title" }
      ]
    };
  }

  addContact = () => {
    this.props.openClose.setAppModal(
      "Add Contact",
      <NewContact openClose={this.props.openClose} db={this.props.db} />
    );
  };

  handleSearchButton = () => {
    this.setState({
      showSearch: true
    });
  };

  handleSearch = event => {
    this.setState({
      searchQuery: event.target.value.toLowerCase()
    });
  };

  handleProfileOpen = (event, rowData) => {
    const contactKey = rowData.key;
    this.props.showRightDrawer(
      <ContactProfile
        contactKey={contactKey}
        showRightDrawer={this.props.showRightDrawer}
        db={this.props.db}
      />
    );
  };

  onColumnSort = (field, direction) => {
    if (this.state.sortField == field) {
      this.setState({ reverseSort: !this.state.reverseSort });
    } else {
      this.setState({ sortField: field, reverseSort: false });
    }
  };

  getContactJSX = c => {
    const contactVal = {
      key: c.key,
      name: (
        <div className="rowC">
          <Avatar
            src={c.avatar}
            color={grey800}
            sx={{ backgroundColor: "tonal.light" }}
            size={4}
          />
          <div className="centering" style={{ marginLeft: "20px" }}>
            {c.name}
          </div>
        </div>
      ),
      email: c.email,
      phone: c.phone,
      company: c.company,
      title: c.title,
      address: c.address
    };
    if (this.state.currentlySelected == c.key) {
      contactVal.tableData = { checked: true };
    }
    return contactVal;
  };

  getContacts = () => {
    let filteredContacts = [];
    const contactKeys = [];

    for (const key in this.props.db.contacts) {
      const c = this.props.db.contacts[key];

      contactKeys.push(key);
      if (
        this.state.searchQuery === "" ||
        c.name.toLowerCase().indexOf(this.state.searchQuery) != -1 ||
        (c.company &&
          c.company.toLowerCase().indexOf(this.state.searchQuery) != -1) ||
        (c.email && c.email.toLowerCase().indexOf(this.state.searchQuery) != -1)
      ) {
        c.key = key;
        filteredContacts.push(c);
      }
    }

    if (this.state.sortField) {
      filteredContacts = sortByField(
        this.state.sortField,
        filteredContacts,
        this.state.reverseSort
      );
    } else {
      filteredContacts = sortByField("name", filteredContacts, true);
    }
    return filteredContacts;
  };

  getDownloadData = () => {
    const contactList = this.getContacts();

    this.setState({ downloadData: contactList.map(escapeQuotes) }, () => {
      this.csvLink.current.link.click();
    });
  };

  getContactsJSX = filteredContacts => {
    const contactsJSX = [];
    for (let i = 0; i < filteredContacts.length; i++) {
      const c = filteredContacts[i];
      contactsJSX.push(this.getContactJSX(c));
    }
    return contactsJSX;
  };

  deleteContact = (event, rowData) => {
    const contactKey = rowData.key;
    confirmDialog(
      this.props.openClose,
      "Delete Contact",
      "Are you sure you want to delete this contact?",
      () => {
        const dependencies = this.getDependencies(contactKey);
        if (dependencies.size) {
          const errorStr = formatDependencies("contact", dependencies);
          infoDialog(
            this.props.openClose,
            "Unable to delete contact",
            errorStr
          );
        } else {
          removeFirebase(3, contactKey);
        }
      }
    );
  };

  getDependencies = key => {
    const dependencies = new Set();

    // promotions
    for (const promKey in this.props.db.promotions) {
      const contacts = this.props.db.promotions[promKey].contact || [];
      if (contacts.includes(key)) {
        dependencies.add("promotions");
      }
    }

    // customers
    for (const customer in this.props.db.customers) {
      if (this.props.db.customers[customer].contact == key) {
        dependencies.add("customers");
      }
    }

    return dependencies;
  };

  openFilePicker = jsx => {
    this.setState({
      openFilePicker: true
    });

    this.props.showDropDownWindow(jsx);
  };

  closeFilePicker = () => {
    this.setState({
      openFilePicker: false
    });
  };

  saveToDatabase = () => {
    for (let i = 0; i < this.state.importedContacts1.length; i++) {
      addFirebase(3, this.state.importedContacts1[i]);
    }
    this.closeModal();
  };

  componentDidMount() {
    const contactKey = this.props.match?.params?.key;
    const allContacts = this.props.db.contacts;
    if (contactKey && contactKey in allContacts) {
      this.handleProfileOpen(null, { key: contactKey });
    }
  }

  render() {
    const filteredContacts = this.getContacts();
    const contactsJSX = this.getContactsJSX(filteredContacts);

    const actionParams = {
      openProfile: rowData => ({
        onClick: this.handleProfileOpen
      })
    };
    if (this.props.db.permissions.includes("admin")) {
      actionParams.delete = rowData => ({
        onClick: this.deleteContact
      });
    }
    const columns = [
      {
        title: "Name",
        field: "name",
        width: 250,
        cellStyle: { paddingLeft: 50 },
        headerStyle: { paddingLeft: 50 }
      },
      { title: "Email", field: "email" },
      { title: "Phone Number", field: "phone" },
      { title: "Company", field: "company" },
      { title: "Title", field: "title" }
    ];

    return (
      <div>
        <Toolbar>
          <ToolbarGroup>
            <ToolbarTitle text="All Contacts" style={{ color: black }} />
            <ToolbarSeparator />
            <IconButton
              onClick={this.handleSearchButton}
              tooltip="Search"
              size="large">
              <SearchIcon />
            </IconButton>
            {this.state.showSearch && (
              <TextField
                placeholder="Search..."
                style={{ marginTop: 0 }}
                onChange={this.handleSearch}
              />
            )}
          </ToolbarGroup>

          <ToolbarGroup>
            <IconMenu
              iconButtonElement={
                <IconButton tooltip="Settings" size="large">
                  <SettingsIcon />
                </IconButton>
              }
              anchorOrigin={{ horizontal: "right", vertical: "top" }}
              targetOrigin={{ horizontal: "right", vertical: "top" }}>
              <MenuItem
                value={0}
                disabled={Object.keys(this.props.db.contacts).length == 0}
                onClick={this.getDownloadData}
                leftIcon={<DownloadIcon />}>
                Download Contacts
              </MenuItem>
            </IconMenu>
            {this.props.db.permissions.includes("addContacts") && (
              <IconButton
                onClick={this.addContact}
                tooltip="Add Contact"
                size="large">
                <AddIcon />
              </IconButton>
            )}
            {this.props.db.permissions.includes("addContacts") && (
              <Button
                label="Load from File"
                onClick={this.openFilePicker.bind(
                  null,
                  <UploadFiles
                    selectedDataType="Contacts"
                    openClose={this.props.openClose}
                  />
                )}
                icon={<UploadIcon color={white} />}
              />
            )}
          </ToolbarGroup>
        </Toolbar>
        <PaginationMUITable
          data={contactsJSX}
          columns={columns}
          allLoaded
          handleProfileOpen={this.handleProfileOpen}
          onColumnSort={this.onColumnSort}
          actionParams={actionParams}
          selection={false}
        />
        <PropDataUpdatedCSVLink
          data={this.state.downloadData}
          headers={this.state.headers}
          filename="Contacts Export.csv"
          className="hidden"
          ref={this.csvLink}
          target="_blank"
        />
      </div>
    );
  }
}

export default Contacts;
