import { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useDb } from "contexts/Db";
import {
  add,
  differenceInWeeks,
  isSunday,
  nextSunday,
  format,
  min,
  max,
  addWeeks,
  parse,
  differenceInCalendarQuarters
} from "date-fns";
import {
  ForecastView,
  ForecastQueryParams,
  Option,
  ForecastVersion
} from "./types";

export function stringToEnumOrDefault<T extends string>(
  param: string | undefined | null,
  enums: { [key: string]: T },
  defaultEnum: T
): T {
  const foundEnum = Object.values(enums).find(enumValue => enumValue === param);
  return foundEnum ?? defaultEnum;
}

export function getName<T extends { name: string }>(
  dictionary: { [key: string]: T },
  key: string,
  defaultName?: string
) {
  return dictionary[key]?.name ?? defaultName;
}

export function useGetDbName(
  key: "products" | "customers",
  defaultName?: string
) {
  const db = useDb();
  const dictionary: { [key: string]: { name: string } } = db[key];

  return useCallback(
    (key: string) => getName(dictionary, key, defaultName),
    [dictionary]
  );
}

export function uniqueArray<T>(array: T[]) {
  return Array.from(new Set(array));
}

export const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export const paramsToURLString = (params: ForecastQueryParams) =>
  new URLSearchParams(params).toString();

export const isNumeric = (
  maybe: Option<string | number>
): maybe is string | number => {
  if (maybe === null || maybe === undefined) return false;
  if (typeof maybe === "number") return !Number.isNaN(maybe);
  return !Number.isNaN(parseFloat(maybe));
};

export const renderDate = (date: number | Date) => format(date, "yyyy-MM-dd");

export const getSunday = (date: number | Date) =>
  isSunday(date) ? date : nextSunday(date);

export const getTimeBounds = (meta: ForecastView["meta"]) => {
  const { table_start, starting_date, ending_date, table_end } = meta;
  const tableStart = max([
    parse(starting_date, "yyyy-MM-dd", new Date()),
    getSunday(new Date(table_start))
  ]);
  const tableEnd = min([
    parse(ending_date, "yyyy-MM-dd", new Date()),
    getSunday(new Date(table_end))
  ]);

  const totalWeeks = Math.abs(
    differenceInWeeks(tableStart, tableEnd, { roundingMethod: "round" })
  );

  const datesAndWeeks = Array.from(
    { length: totalWeeks },
    (_, weeks) => [add(tableStart, { weeks }), weeks] as const
  );
  const dates = datesAndWeeks.map(([date, _week]) => date);
  const formattedDates = dates.map(date => renderDate(date));

  return {
    totalWeeks,
    datesAndWeeks,
    dates,
    formattedDates
  };
};

export enum TableItemTypes {
  ROW,
  SUBTABLE
}

export function isDefined<T>(val: T | undefined | null): val is T {
  return val !== undefined && val !== null;
}

export const utcToReadableDateInFuture = (
  tableStartUTC: number,
  index: number
) => {
  const newDate: Date = utcToFutureUtc(tableStartUTC, index);
  const readableDate: string = newDate.toISOString().split("T")[0];
  return readableDate;
};

export const utcToFutureUtc = (tableStartUTC: number, index: number) => {
  const newDate: Date = addWeeks(new Date(tableStartUTC), index);
  return newDate;
};

export const getQuarter = (date: Date, tableStart: string): number => {
  // returns the number of quarters the date is past the start quarter
  return differenceInCalendarQuarters(date, new Date(Date.parse(tableStart)));
};

export const mapNumToMonth = (month: number): string => {
  return format(new Date(2017, month, 1), "MMM");
};

const correctNumberOfZeros = (num: number): string => {
  if (num < 10 && num > 0) {
    return `0${num}`;
  }
  return num.toString();
};

export const renderVersionDateTime = (s: string) =>
  format(new Date(s), "yyyy/MM/dd h:mm a");

export const renderVersionToDefaultString = (version: ForecastVersion) => {
  const { creation_time, version_num } = version;

  return `Version ${version_num} - Created ${renderVersionDateTime(
    creation_time
  )}`;
};

export const renderVersionToString = (version: ForecastVersion) => {
  const { version_name } = version;

  if (version_name) {
    return version_name;
  }

  return renderVersionToDefaultString(version);
};
