import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { LinkOff } from "@mui/icons-material";
import IconButton from "ui-library/IconButton";
import React, { useMemo, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { DRMFeature } from "components/Deductions/DeductionsReconciliation/redux/DRMSlice";
import {
  ERPTransactionObject,
  InvoiceObject
} from "components/Deductions/models";
import { useDb } from "contexts/Db";
import CollapsibleTable, {
  ColumnConfigOptions
} from "ui-library/CollapsibleTable/CollapsibleTable";
import { displayUSCurrency } from "helpers/DataProcessing";
import { deductionTransactionStatuses } from "deductions-constants/ReconciliationStatuses";
import { ERPTransactionTypes } from "deductions-constants/ReconciliationTypes";
import { InvoiceDisplayService } from "reconciliation-services";
import { DisplayInvoiceObject } from "reconciliation-types/invoiceTypes";
import { updateFirebaseTransaction } from "components/Deductions/DeductionsReconciliation/services/ERPTransactionServices/transactionProcessing";
import { updateFirebaseInvoice } from "components/Deductions/DeductionsReconciliation/services/InvoiceServices/invoiceProcessing";
import { useDRMEvents } from "contexts/DRMEvents";
import { DRMEventType } from "components/Deductions/DeductionsReconciliation/ActivityLog/DRMEvent";
import moment from "moment";
import {
  SummaryConfigOptions,
  CollapsibleTableSummaryType
} from "ui-library/CollapsibleTable/CollapsibleTableSummary";
import LinkedInvoiceLinesTable from "./LinkedInvoiceLinesTable";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  actionButton: {
    color: theme.palette.grey[600]
  }
}));

export interface LinkedInvoicesTableProps {
  transactionKey: string;
  readOnly?: boolean;
}

export default function LinkedInvoicesTable(
  props: LinkedInvoicesTableProps
): JSX.Element {
  const { transactionKey, readOnly } = props;
  const classes = useStyles();
  const db = useDb();
  const history = useHistory();
  const { addEvent } = useDRMEvents();

  const {
    erpTransactions = {},
    invoices = {},
    customers = {},
    companyUsers = {}
  } = db;

  const transaction = erpTransactions[transactionKey];

  const handleUnlinkInvoice = useCallback(
    (invoiceKey: string, curTransactionKey: string) => {
      // update the transaction
      const currentLinkedInvoices = transaction.linkedInvoices || {};
      const updatedTransaction: ERPTransactionObject = {
        ...transaction,
        status:
          Object.keys(currentLinkedInvoices).length === 1
            ? deductionTransactionStatuses.NEW
            : transaction.status,
        type:
          Object.keys(currentLinkedInvoices).length === 1
            ? ERPTransactionTypes.UNKNOWN
            : transaction.type,
        linkedInvoices: {
          ...currentLinkedInvoices
        }
      };
      delete updatedTransaction.linkedInvoices?.[invoiceKey];

      // update the invoice
      const currentInvoice = invoices[invoiceKey] || {};
      const currentLinkedTransactions =
        currentInvoice.linkedERPTransactions || {};
      const updatedInvoice: InvoiceObject = {
        ...currentInvoice,
        linkedERPTransactions: {
          ...currentLinkedTransactions
        }
      };
      delete updatedInvoice.linkedERPTransactions?.[curTransactionKey];

      updateFirebaseTransaction(updatedTransaction, () => {
        updateFirebaseInvoice(updatedInvoice, () => {
          addEvent({
            type: DRMEventType.INVOICE_UNLINKED,
            transactionKey: updatedTransaction.key,
            invoiceLineKey: updatedInvoice.key
          });
        });
      });
    },
    [transaction, invoices, addEvent]
  );

  const columnConfig = useMemo(
    () =>
      new Map([
        [
          "invoiceNumber",
          {
            name: "Invoice #"
          }
        ],
        [
          "customerName",
          {
            name: "Customer"
          }
        ],
        [
          "amount",
          {
            name: "Original",
            render: val => displayUSCurrency(val)
          }
        ],
        [
          "openAmount",
          {
            name: "Open",
            render: val => displayUSCurrency(val)
          }
        ],
        // [
        //   "disputeAmount",
        //   {
        //     align: "right",
        //     name: "Disputed",
        //     render: val => displayUSCurrency(val)
        //   }
        // ],
        // [
        //   "writtenOffAmount",
        //   {
        //     align: "right",
        //     name: "Written-Off",
        //     render: val => displayUSCurrency(val)
        //   }
        // ],
        [
          "createdDate",
          {
            name: "Invoice Date",
            render: val => moment(val as string).format("MM/DD/YYYY")
          }
        ],
        [
          "status",
          {
            name: "Status",
            render: val =>
              InvoiceDisplayService.HumanReadableInvoiceStatuses[val as string]
          }
        ],
        ...(!readOnly
          ? [
              [
                "key",
                {
                  name: "Actions",
                  render: val => {
                    const linkedInvoice = invoices[val];
                    return (
                      <>
                        <IconButton
                          disabled={linkedInvoice.resolutionLines}
                          aria-label="Unlink Invoice"
                          size="small"
                          tooltip="Unlink Invoice"
                          className={classes.actionButton}
                          onClick={e => {
                            e.stopPropagation();
                            handleUnlinkInvoice(
                              linkedInvoice.key,
                              transaction.key
                            );
                          }}>
                          <LinkOff />
                        </IconButton>
                      </>
                    );
                  }
                }
              ]
            ]
          : [])
      ] as [keyof DisplayInvoiceObject, ColumnConfigOptions<DisplayInvoiceObject>][]),
    [
      classes.actionButton,
      handleUnlinkInvoice,
      invoices,
      transaction.key,
      readOnly
    ]
  );

  const summaryConfig = useMemo(
    () =>
      new Map([
        [
          "amount",
          {
            label: "Total Original",
            type: CollapsibleTableSummaryType.SUM,
            render: val => displayUSCurrency(val)
          }
        ],
        [
          "openAmount",
          {
            label: "Total Open",
            type: CollapsibleTableSummaryType.SUM,
            render: val => displayUSCurrency(val)
          }
        ]
      ] as [keyof DisplayInvoiceObject, SummaryConfigOptions<DisplayInvoiceObject>][]),
    []
  );

  return readOnly ? (
    <CollapsibleTable<DisplayInvoiceObject>
      data={Object.values(transaction.linkedInvoices || {}).map(
        linkedInvoiceKey =>
          InvoiceDisplayService.processInvoiceForDisplay(
            invoices[linkedInvoiceKey],
            erpTransactions,
            customers,
            companyUsers
          )
      )}
      columnConfig={columnConfig}
      summaryConfig={summaryConfig}
      index="key"
    />
  ) : (
    <CollapsibleTable<DisplayInvoiceObject>
      data={Object.values(transaction.linkedInvoices || {}).map(
        linkedInvoiceKey =>
          InvoiceDisplayService.processInvoiceForDisplay(
            invoices[linkedInvoiceKey],
            erpTransactions,
            customers,
            companyUsers
          )
      )}
      expandProfile={(displayInvoice: DisplayInvoiceObject) => {
        history.push(
          `/${DRMFeature.RECONCILIATION}/invoice/${displayInvoice.key}`
        );
      }}
      renderCollapse={(displayInvoice: DisplayInvoiceObject) => {
        return (
          <LinkedInvoiceLinesTable
            invoiceKey={displayInvoice.key}
            transactionKey={transactionKey}
          />
        );
      }}
      summaryConfig={summaryConfig}
      columnConfig={columnConfig}
      index="key"
    />
  );
}
