/* eslint-disable lines-between-class-members */
import { DbContextValues } from "contexts/Db";
import { firebase } from "helpers/Firebase";
import { CompanyUser } from "js/dbTypes";
import { DisplayDRMEvent, DRMEvent, DRMEventType } from "./DRMEvent";

const eventUrl = "reconciliation/events";
const tagUrl = "reconciliation/tags";

export interface AddEventConfig {
  type: DRMEventType;
  invoiceKey?: string;
  invoiceLineKey?: string;
  transactionKey?: string;
  repaidTransactionKey?: string;
  comment?: string;
  metadata?: Record<string, undefined | string | number>;
}

export interface DRMEventFilters {
  invoiceKey?: string;
  invoiceLineKey?: string;
  transactionKey?: string;
  allInvoices?: boolean;
  allTransactions?: boolean;
}

export interface TagConfig {}

class DRMEventService {
  private users: Record<string, CompanyUser>;
  private userId: string;
  private db: DbContextValues;
  private companyDataUrl: string;

  constructor(config: { users; db; companyid; userId }) {
    const { users, db, companyid, userId } = config;
    this.users = users;
    this.userId = userId;
    this.companyDataUrl = `companies/${companyid}`;
    this.db = db;
  }

  private getCompanyEventUrl = (): string => {
    return `${this.companyDataUrl}/${eventUrl}`;
  };

  private getCompanyTagUrl = (): string => {
    return `${this.companyDataUrl}/${tagUrl}`;
  };

  private clean = (event: Partial<DRMEvent>): Partial<DRMEvent> => {
    Object.keys(event).forEach(key => {
      if (event[key] === undefined) {
        // eslint-disable-next-line no-param-reassign
        delete event[key];
      }
    });
    if (event.metadata) {
      Object.keys(event.metadata).forEach(key => {
        if (event.metadata && event.metadata[key] === undefined) {
          // eslint-disable-next-line no-param-reassign
          delete event.metadata[key];
        }
      });
    }
    return event;
  };

  public addEvent = (
    config: AddEventConfig,
    tags?: Record<string, number[]>
  ): Promise<Partial<DRMEvent>> => {
    const {
      type,
      invoiceLineKey,
      invoiceKey,
      transactionKey,
      repaidTransactionKey,
      comment,
      metadata
    } = config;
    const event: Partial<DRMEvent> = this.clean({
      new: Boolean(comment),
      type,
      userId: this.userId,
      invoiceLineKey,
      invoiceKey,
      transactionKey,
      repaidTransactionKey,
      metadata,
      // actionDescription: actionDescription ?? this.getDefaultDescription(type),
      comment,
      createDate: new Date().toISOString()
    });
    return firebase
      .database()
      .ref(this.getCompanyEventUrl())
      .push(event)
      .then(({ key: eventKey }) => {
        if (
          // type === DRMEventType.NEW_COMMENT_ADDED &&
          tags &&
          eventKey &&
          comment
        ) {
          // validate tags
          const validatedTags: Record<string, number[]> = {};
          Object.entries(tags).forEach(([userKey, tag]) => {
            const [tagStart, tagEnd] = tag;
            const nameToValidate = comment.slice(tagStart, tagEnd);
            const userVerify = this.users[userKey];
            if (userVerify && userVerify.name === nameToValidate) {
              validatedTags[userKey] = tag;
            }
          });
          const tagRecord = {
            [eventKey]: validatedTags
          };
          firebase.database().ref(this.getCompanyTagUrl()).update(tagRecord);
        }
        return event;
      });
  };

  public getEvents = (filters: DRMEventFilters): Promise<DisplayDRMEvent[]> => {
    return firebase
      .database()
      .ref(this.getCompanyEventUrl())
      .once("value")
      .then(snapshot => {
        const record: Record<string, DisplayDRMEvent> = snapshot.val() || {};
        const list = Object.entries(record).map(entry => ({
          ...entry[1],
          key: entry[0]
        }));
        const filteredList =
          Object.keys(filters).length > 0
            ? list.filter(event => {
                if (filters.allInvoices) {
                  return Boolean(event.invoiceKey);
                }
                if (filters.allTransactions) {
                  return Boolean(event.transactionKey);
                }

                // else, filter by specific keys
                const invoiceMatch =
                  event.invoiceKey && event.invoiceKey === filters.invoiceKey;

                const invoiceLineMatch =
                  event.invoiceLineKey &&
                  event.invoiceLineKey === filters.invoiceLineKey;

                const transactionMatch =
                  event.transactionKey &&
                  event.transactionKey === filters.transactionKey;

                const repaidTransactionMatch =
                  event.repaidTransactionKey &&
                  event.repaidTransactionKey === filters.transactionKey;

                return (
                  invoiceMatch ||
                  invoiceLineMatch ||
                  transactionMatch ||
                  repaidTransactionMatch
                );
              })
            : list;
        return filteredList.map(event => ({
          ...event,
          user: this.users[event.userId] || { name: "None" },
          displayDetails: false
        }));
      });
  };

  public processEventForDisplay = (event: DRMEvent): DisplayDRMEvent => {
    return {
      ...event,
      user: this.users[event.userId],
      displayDetails: false
    };
  };

  public getTags = (): Promise<Record<string, Record<string, number[]>>> => {
    return firebase
      .database()
      .ref(this.getCompanyTagUrl())
      .once("value")
      .then(snapshot => snapshot.val() || {});
  };
}

export default DRMEventService;
