import { useDb } from "contexts/Db";

type DbType = ReturnType<typeof useDb>;

export enum Tier {
  PINE = "pine",
  PINE_PLUS = "pine-plus",
  ASPEN = "aspen"
}

export const forecastEnabled = (db: DbType) =>
  db.meta.tier === Tier.ASPEN ||
  (db.meta.tier === Tier.PINE_PLUS && db.meta.forecast);

export const liftEnabled = (db: DbType) => forecastEnabled(db);
