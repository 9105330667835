import { invoiceLineStatuses } from "deductions-constants/ReconciliationStatuses";
import {
  DisplayInvoiceLineObject,
  invoiceLineDependencies,
  invoiceLineActions,
  invoiceLineDependencyDescriptions
} from "reconciliation-types/invoiceLineTypes";

// get all existing dependencies at once
export function calculateInvoiceLineDependencies(
  invoiceLine: DisplayInvoiceLineObject
): Record<invoiceLineDependencies, boolean> {
  const { resolutionLines, amount, openAmount, status } = invoiceLine;

  return {
    INVOICE_LINE_PENDING: status === invoiceLineStatuses.PENDING,
    FULLY_CLEARED: openAmount <= 0 && openAmount !== amount,
    PARTIALLY_CLEARED:
      Object.keys(resolutionLines || {}).length > 0 || openAmount !== amount
  };
}

// determine whether a specific invoice action is allowed given the current invoice object
export function isSingleInvoiceLineActionAllowed(
  invoiceLine: DisplayInvoiceLineObject,
  action: invoiceLineActions,
  precalculatedDependencies?: Record<invoiceLineDependencies, boolean>
): {
  actionAllowed: boolean;
  dependenciesMessage: string;
} {
  // recalculate dependencies if not given
  const calculatedDependencies =
    precalculatedDependencies || calculateInvoiceLineDependencies(invoiceLine);

  // convert a subset of dependencies into descriptions of valid dependencies
  function filterDependencies(
    dependencies: invoiceLineDependencies[]
  ): invoiceLineDependencyDescriptions[] {
    return dependencies
      .filter(d => calculatedDependencies[d])
      .map(d => invoiceLineDependencyDescriptions[d]);
  }

  // get subset of dependency checks for a single action
  let subset: invoiceLineDependencies[] = [];

  switch (action) {
    case invoiceLineActions.CLEAR: {
      subset = [
        invoiceLineDependencies.INVOICE_LINE_PENDING,
        invoiceLineDependencies.FULLY_CLEARED
      ];
      break;
    }
    case invoiceLineActions.MANUAL_MATCH: {
      subset = [invoiceLineDependencies.PARTIALLY_CLEARED];
      break;
    }
    case invoiceLineActions.DISPUTE: {
      subset = [
        invoiceLineDependencies.FULLY_CLEARED,
        invoiceLineDependencies.INVOICE_LINE_PENDING
      ];
      break;
    }
    default: {
      break;
    }
  }

  // calculate which ones evaluate to true and map to their descriptions
  const actionDependencies = filterDependencies(subset);
  const dependenciesMessage = actionDependencies.join(", ");

  return {
    actionAllowed: actionDependencies.length === 0,
    dependenciesMessage
  };
}
