import React, { memo, forwardRef, CSSProperties } from "react";
import { TableComponents } from "react-virtuoso";

export const StyledTable = memo(
  forwardRef<
    HTMLTableElement,
    TableComponents["Table"] & { style?: CSSProperties }
  >(({ style, ...props }, ref) => (
    <table
      ref={ref}
      {...props}
      style={{
        ...style,
        width: "100%",
        borderCollapse: "separate",
        fontSize: "13px"
      }}
    />
  ))
);
