import React from "react";

import Button from "ui-library/Button";
import TextField from "ui-library/TextField";

import { addFirebase } from "helpers/Firebase";

class NewProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newProduct: {}
    };
  }

  handleTextChange = event => {
    const { newProduct } = this.state;
    newProduct[event.target.id] = event.target.value.trim();
    this.setState({ newProduct });
  };

  checkDuplicateCodes = newCodes => {
    const duplicateCodes = {};
    for (const newCode of newCodes) {
      for (const productKey of Object.keys(this.props.db.products)) {
        const productCodes = this.props.db.products[productKey].codes;
        const productName = this.props.db.products[productKey].name;
        if (productCodes && productCodes.includes(newCode)) {
          duplicateCodes[newCode] = productName;
          break;
        }
      }
    }
    return duplicateCodes;
  };

  displayDuplicateCodeError = duplicateCodes => {
    let displayText =
      "Some of the newly added product codes already exist under current products: ";
    for (const code of Object.keys(duplicateCodes)) {
      const product = duplicateCodes[code];
      displayText += `${code} (${product}), `;
    }
    displayText = displayText.substr(0, displayText.length - 2);
    displayText += ". Please resolve all conflicts before saving your changes.";

    this.setState({
      codeError: displayText
    });
  };

  saveToDatabase = () => {
    // first, check for errors
    if (!this.state.newProduct.name) {
      this.setState({
        nameError: "Name is required."
      });
      return;
    }

    for (const key in this.props.db.products) {
      var newProduct = this.props.db.products[key];
      if (
        this.state.newProduct.name == newProduct.name ||
        (newProduct.codes &&
          newProduct.codes.indexOf(this.state.newProduct.name) != -1)
      ) {
        this.setState({
          nameError: "Name already exists in database (as product or code)."
        });
        return;
      }
    }

    var { newProduct } = this.state;
    if (newProduct.codes) {
      newProduct.codes = newProduct.codes.split(",").map(code => {
        return code.trim();
      });
      const duplicateCodes = this.checkDuplicateCodes(newProduct.codes);
      if (Object.keys(duplicateCodes).length > 0) {
        this.displayDuplicateCodeError(duplicateCodes);
        return;
      }
    }
    addFirebase(1, newProduct);
    this.setState({
      newProduct: {}
    });
    this.props.openClose.closeAppModal();
  };

  render() {
    return (
      <div>
        <TextField
          placeholder="Name"
          id="name"
          onChange={this.handleTextChange}
          errorText={this.state.nameError}
          fullWidth
        />
        <br />
        <TextField
          placeholder="Size"
          id="size"
          onChange={this.handleTextChange}
          fullWidth
        />
        <br />
        <TextField
          placeholder="Associated Codes (separated by commas)"
          id="codes"
          onChange={this.handleTextChange}
          errorText={this.state.codeError}
          fullWidth
        />
        <br />
        <br />
        <div className="centering">
          <Button label="Save to Database" onClick={this.saveToDatabase} />
        </div>
        <br />
      </div>
    );
  }
}

export default NewProduct;
