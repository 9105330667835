import React from "react";
import { Redirect } from "react-router-dom";
import { Box, Card, CardContent, Container } from "@mui/material";
import PasswordReset from "./PasswordReset";

import EmailRecovery from "./EmailRecovery";
import EmailVerification from "./EmailVerification";

const styles = {
  logo: {
    height: 40,
    margin: "32px 0 32px"
  }
};

class AuthenticationHandling extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      params: this.getQuery()
    };
  }

  getQuery = () => {
    return new URLSearchParams(this.props.location.search);
  };

  componentDidMount() {}

  renderUserMgmt = () => {
    const mode = this.state.params.get("mode");
    switch (mode) {
      case "resetPassword":
        return <PasswordReset params={this.state.params} />;
        break;
      case "recoverEmail":
        return <EmailRecovery params={this.state.params} />;
      case "verifyEmail":
        return <EmailVerification params={this.state.params} />;
      default:
        return <Redirect to="/" />;
    }
  };

  render() {
    return (
      <Box
        style={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh"
        }}>
        <Container maxWidth="sm" style={{ py: "80px" }}>
          <div className="centering">
            <img
              src="/vividly_logo.svg"
              alt="company logo"
              style={styles.logo}
            />
          </div>
          <Card>
            <CardContent
              style={{
                display: "flex",
                flexDirection: "column",
                padding: 32
              }}>
              <Box
                style={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 8
                }}>
                {this.renderUserMgmt()}
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    );
  }
}

export default AuthenticationHandling;
