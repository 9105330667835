import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import { downloadFilesFromStorage } from "helpers/Firebase";
import SplitScreen from "components/WebsiteElements/SplitScreen";
import { importBackupSources } from "../constants/BackupSources";
import FileViewer from "./FileViewer";

class ExampleTemplateViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      availableBackupSources: [],
      customers: {},
      companyUsers: {},
      servicedCompanies: {},
      currentlySelected: "",
      showSearch: false,
      searchQuery: "",
      openFilePicker: false,
      modalOpen: false,
      reverseSort: false,
      multi: false,
      snackbar: false,
      allSelected: [],
      customersJSX: [],
      tableBodyRenderKey: 0,
      pageNumber: 1,
      numPages: 1,
      company: "None",
      loading: false,
      promType: [],
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      spendDate: new Date(),
      checkNumber: ""
    };
  }

  downloadTemplateFiles = format => {
    this.setState({ selectedFormat: format });
    const fileNames = this.state.availableBackupSources.find(
      source => source.DISPLAY_NAME === format
    ).FILES;
    const filePaths = fileNames.map(
      name => `Samples/Deductions Scanning Templates/${name}`
    );
    const files = {};
    downloadFilesFromStorage(filePaths, fileNames, (blob, fileName) => {
      blob.name = fileName;
      files[fileName] = blob;
      if (Object.keys(files).length == fileNames.length) {
        this.setState({ files: Object.values(files) });
      }
    });
  };

  componentWillMount = () => {
    importBackupSources()
      .then(backupSources => {
        const availableBackupSources = Object.values(backupSources)
          .sort((source1, source2) => {
            return source1.DISPLAY_NAME.localeCompare(source2.DISPLAY_NAME);
          })
          .filter(source => {
            return "FILES" in source && source.FILES.length > 0;
          });
        this.setState({
          availableBackupSources
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    return (
      <div style={{ height: $(window).height() - 75 }}>
        <SplitScreen
          allowResize={false}
          width="70%"
          height={$(window).height() - 75}
          rightComponent={
            <List component="nav" aria-labelledby="nested-list-subheader">
              {this.state.availableBackupSources.map(source => (
                <ListItem
                  button
                  selected={source.DISPLAY_NAME == this.state.selectedFormat}
                  onClick={this.downloadTemplateFiles.bind(
                    null,
                    source.DISPLAY_NAME
                  )}>
                  <ListItemText primary={source.DISPLAY_NAME} />
                </ListItem>
              ))}
            </List>
          }
          leftComponent={<FileViewer files={this.state.files} />}
        />
      </div>
    );
  }
}

export default ExampleTemplateViewer;
