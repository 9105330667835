import mixpanel from "mixpanel-browser";

const prodHost = "portal.govividly.com";

const prodToken = "b1c1cf67c33102b6441ab8b0d2c8e10e";

function noop() {}

let Mixpanel;

if (window.location.hostname.toLowerCase().search(prodHost) >= 0) {
  mixpanel.init(prodToken, {
    api_host: "https://analytics.cresicor.ai"
  });
  Mixpanel = mixpanel;
} else {
  Mixpanel = {
    track: noop,
    register: noop,
    identify: noop,
    people: {
      set: noop
    }
  };
}
export default Mixpanel;
