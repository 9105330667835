import Typography from "@mui/material/Typography";
import { Divider } from "pui-react-dividers";
import React from "react";

import { Toolbar, ToolbarTitle, ToolbarSeparator } from "ui-library/Toolbar";
import ToolbarGroup from "ui-library/ToolbarGroup";
import GetAppIcon from "@mui/icons-material/GetApp";
import Dialog from "ui-library/Dialog";
import Button from "ui-library/Button";
import ReorderIcon from "@mui/icons-material/Reorder";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import IconButton from "ui-library/IconButton";
import IconMenu from "ui-library/IconMenu";
import MenuItem from "ui-library/MenuItem";
import Select from "ui-library/Select";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import TextField from "ui-library/TextField";
import PublishIcon from "@mui/icons-material/Publish";

import { exportFiles } from "helpers/Export";
import { liftTable } from "helpers/DataProcessing";
import { firebase } from "helpers/Firebase";
import { Stack } from "@mui/material";
import CloseButton from "../WebsiteElements/CloseButton";
import LiftTable from "../graphs/LiftTable";
import LiftBuckets from "./LiftBuckets";
import UploadFiles from "../UploadFiles/UploadFiles";

const styles = {
  superheader: {
    fontFamily: "Ubuntu",
    marginTop: 10,
    padding: 0,
    color: "inherit"
  }
};

class Lift extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: {},
      currentlySelected: "",
      showSearch: false,
      searchQuery: "",
      openFilePicker: false,
      productGroupsJSX: [],
      productGroup: 0,
      modalOpen: false,
      selectedLiftBucket: 0
    };
  }

  openFilePicker = jsx => {
    this.setState({
      openFilePicker: true
    });
    this.props.showDropDownWindow(jsx);
  };

  closeFilePicker = () => {
    this.setState({
      openFilePicker: false
    });
  };

  handleSearchButton = () => {
    this.setState({
      showSearch: true
    });
  };

  handleSearch = event => {
    this.setState({
      searchQuery: event.target.value.toLowerCase()
    });
  };

  filterData = dataList => {
    const filteredList = [];
    for (let i = 0; i < dataList.length; i++) {
      const d = dataList[i];
      if (
        !this.state.searchQuery ||
        d.customerName.toLowerCase().indexOf(this.state.searchQuery) != -1
      ) {
        filteredList.push(d);
      }
    }
    return filteredList;
  };

  prettyPrint = list => {
    let result = "";
    for (let i = 0; i < list.length; i++) {
      if (i != 0) {
        result += ", ";
      }
      result += list[i];
    }
    return result;
  };

  openLiftBucketSelection = () => {
    this.props.openClose.setAppModal(
      "Create Lift Buckets",
      <LiftBuckets db={this.props.db} openClose={this.props.openClose} />,
      <CloseButton openClose={this.props.openClose} />,
      false,
      true,
      "lg"
    );
  };

  changeLiftBucket = (event, index, value) => {
    this.setState({ selectedLiftBucket: value });
  };

  render() {
    const liftTableData = this.filterData(
      liftTable(
        this.props.db.lift,
        this.props.db.customers,
        this.state.selectedLiftBucket
      )
    );
    const bucketNames = this.props.db.meta.lift_bucket_names || [];
    const liftBuckets = this.props.db.meta.lift_buckets || {};
    const user = firebase.auth().currentUser;
    return (
      <div>
        <Toolbar>
          <ToolbarGroup>
            <ToolbarTitle text="Lift" />
            <ToolbarSeparator />
            <IconButton
              onClick={this.handleSearchButton}
              tooltip="Search"
              size="large">
              <SearchIcon />
            </IconButton>
            {this.state.showSearch && (
              <TextField
                placeholder="Search..."
                style={{ marginTop: 0 }}
                onChange={this.handleSearch}
              />
            )}
          </ToolbarGroup>

          <Stack>
            <IconMenu
              iconButtonElement={
                <IconButton tooltip="Settings" size="large">
                  <SettingsIcon />
                </IconButton>
              }
              anchorOrigin={{ horizontal: "right", vertical: "top" }}
              targetOrigin={{ horizontal: "right", vertical: "top" }}
              onChange={this.props.handleAdditionalDataDisplay}>
              <MenuItem
                children={<Typography variant="subtitle1">Export</Typography>}
                disabled
              />
              <MenuItem
                value={0}
                children="Download Lift"
                leftIcon={<GetAppIcon />}
                onClick={() => {
                  exportFiles(user, this.props.db.companyid, "Lift");
                  this.props.openClose.setAppModal(
                    "Export In Progress",
                    <div>
                      Your export is being prepared and will be emailed to you
                      shortly.
                    </div>,

                    <div>
                      <Button
                        label="Ok"
                        onClick={() => {
                          this.props.openClose.closeAppModal();
                        }}
                      />
                    </div>
                  );
                }}
              />
            </IconMenu>
            {this.props.db.permissions.includes("add") &&
              !this.props.readOnly && (
                <Button
                  label="Manage Lift Buckets"
                  icon={<ReorderIcon />}
                  onClick={this.openLiftBucketSelection}
                />
              )}
            {this.props.db.permissions.includes("add") &&
              !this.props.readOnly && (
                <Button
                  label="Load from File"
                  onClick={this.openFilePicker.bind(
                    null,
                    <UploadFiles
                      selectedDataType="Lift"
                      openClose={this.props.openClose}
                    />
                  )}
                  icon={<PublishIcon />}
                />
              )}
          </Stack>
        </Toolbar>
        <div
          style={{
            paddingRight: 30,
            paddingLeft: 30,
            height: $(window).height() - 130,
            overflow: "auto"
          }}>
          <div>
            <Typography style={styles.superheader} variant="h5">
              Manage Lift (%)
            </Typography>
            <Divider />
            <div className="rowC">
              <Button
                label={<OpenWithIcon tooltip="Expand Lift Table" />}
                onClick={() => this.setState({ modalOpen: true })}
              />
              <Select
                value={0}
                onChange={this.changeLiftBucket}
                value={this.state.selectedLiftBucket}
                style={{ marginLeft: 20 }}>
                <MenuItem value={0} children="Select" key="wildcard" />
                {bucketNames.map(name => {
                  return (
                    <MenuItem
                      value={name}
                      children={name}
                      key={name}
                      disabled={!(name in liftBuckets)}
                    />
                  );
                })}
              </Select>
            </div>
            <LiftTable
              style={{ height: 500, overflow: "auto" }}
              data={liftTableData}
              db={this.props.db}
              readOnly={this.props.readOnly}
              openClose={this.props.openClose}
              liftBucket={this.state.selectedLiftBucket}
            />
            <br />
            <br />
          </div>
        </div>
        <Dialog
          dialogTitle="Lift Table"
          open={this.state.modalOpen}
          onClose={() => this.setState({ modalOpen: false })}
          autoScrollBodyContent
          repositionOnUpdate={false}
          maxWidth="lg">
          <div
            style={{
              maxHeight: 650,
              display: "flex",
              flexDirection: "column"
            }}>
            <LiftTable
              style={{ overflow: "auto" }}
              data={liftTableData}
              db={this.props.db}
              openClose={this.props.openClose}
              liftBucket={this.state.selectedLiftBucket}
            />
          </div>
        </Dialog>
      </div>
    );
  }
}

export default Lift;
