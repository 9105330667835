import { ResolutionLineObject } from "deductions-models/index";
import { resolutionLineTypes } from "deductions-constants/ReconciliationTypes";
import { CommentTaggedUser } from "components/Deductions/DeductionsReconciliation/ActivityLog/ActivityLogCommentBox";

export interface DisplayResolutionLineObject extends ResolutionLineObject {
  fundTypeName: string;
  displayType: string;
  displayInvoiceNumber: string;
  displayCustomer: string;
  displayProduct: string;
}

export interface ResolutionLineActivityObject extends ResolutionLineObject {
  taggedUsers?: CommentTaggedUser[];
  comment?: string;
}

export enum ResolutionActivityTypes {
  CLEAR = "Clear",
  DISPUTE = "Dispute",
  SALES_REVIEW = "Sales Review",
  WRITE_OFF = "Write Off"
}

// activity name -> resolution line type
export const ResolutionActivityTypeMap: Record<
  ResolutionActivityTypes,
  resolutionLineTypes
> = {
  [ResolutionActivityTypes.CLEAR]: resolutionLineTypes.CLEAR,
  [ResolutionActivityTypes.DISPUTE]: resolutionLineTypes.DISPUTE,
  [ResolutionActivityTypes.SALES_REVIEW]: resolutionLineTypes.SALES_REVIEW,
  [ResolutionActivityTypes.WRITE_OFF]: resolutionLineTypes.WRITE_OFF
};

export enum ResolutionActivityInputs {
  TYPE = "type",
  AMOUNT = "amount",
  PROM_LINE = "prom_line",
  FUND_TYPE = "fund_type",
  COMMENT = "comment"
}

export const ResolutionActivityStartingInputsMap: Record<
  ResolutionActivityInputs,
  string
> = {
  [ResolutionActivityInputs.TYPE]: ResolutionActivityTypes.CLEAR,
  [ResolutionActivityInputs.AMOUNT]: "", // no longer being used. Prepopulating amount field on start
  [ResolutionActivityInputs.PROM_LINE]: "",
  [ResolutionActivityInputs.FUND_TYPE]: "",
  [ResolutionActivityInputs.COMMENT]: ""
};

export const ResolutionActivityInputErrorsMap: Record<
  ResolutionActivityInputs,
  string
> = {
  [ResolutionActivityInputs.TYPE]:
    "Cannot clear without a matched promotion line.",
  [ResolutionActivityInputs.AMOUNT]:
    "Must be positive number less than or equal to open amount.",
  [ResolutionActivityInputs.PROM_LINE]: "Missing promotion line match.",
  [ResolutionActivityInputs.FUND_TYPE]: "Missing promotion/fund type.",
  [ResolutionActivityInputs.COMMENT]: "Cannot be empty."
};

export const ResolutionActivityRequiredInputsMap: Record<
  ResolutionActivityTypes,
  ResolutionActivityInputs[]
> = {
  [ResolutionActivityTypes.CLEAR]: [
    ResolutionActivityInputs.TYPE,
    ResolutionActivityInputs.AMOUNT,
    ResolutionActivityInputs.PROM_LINE
  ],
  [ResolutionActivityTypes.DISPUTE]: [ResolutionActivityInputs.COMMENT],
  [ResolutionActivityTypes.SALES_REVIEW]: [ResolutionActivityInputs.COMMENT],
  [ResolutionActivityTypes.WRITE_OFF]: [
    ResolutionActivityInputs.AMOUNT,
    ResolutionActivityInputs.FUND_TYPE,
    ResolutionActivityInputs.COMMENT
  ]
};

export const ResolutionActivityInputsMap: Record<
  ResolutionActivityTypes,
  (keyof ResolutionLineActivityObject)[]
> = {
  [ResolutionActivityTypes.CLEAR]: [
    "amount",
    "promLine",
    "comment",
    "fundTypeKey"
  ],
  [ResolutionActivityTypes.WRITE_OFF]: ["amount", "comment", "fundTypeKey"],
  [ResolutionActivityTypes.DISPUTE]: ["comment"],
  [ResolutionActivityTypes.SALES_REVIEW]: ["comment"]
};
