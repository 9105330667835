import { DeleteOverrideRequest, UpdateOverridesRequest } from "./api";

export interface ForecastView {
  meta: {
    forecast_type: ForecastType;
    customer?: Option<string>;
    product?: Option<string>;
    requested_year: string;
    table_start: string;
    starting_date: string;
    ending_date: string;
    table_end: string;
  };
  data: {
    view: string;
    table: Option<ForecastViewSlice<AnyDetails>>;
  };
}

export interface CreateForecastResponse {
  version: string;
}

export type ForecastViewAnySlice = ForecastViewSlice<AnyDetails>;
export type ForecastViewSlice<T extends AnyDetails> = ForecastDetail<T>[];

export type AnyDetails =
  | RetailerDetails
  | AllOtherDetails
  | DistributorDetails
  | TotalDetails;

export type AllDetailFields =
  | keyof RetailerDetails
  | keyof AllOtherDetails
  | keyof DistributorDetails;

export interface ForecastDetail<T extends AnyDetails> {
  key: {
    product: string;
    customer: string;
  };
  value: T;
}

export interface ForecastMetadata {
  customers: CustomersBreakdown;
  products: string[];
  overrides: Overrides[];
  table_start: string;
  table_end: string;
}

export interface Overrides {
  customer: string;
  product: string;
  week: string;
  stores?: Option<number>;
  velocity?: Option<number>;
  base_sales?: Option<number>;
  companyid: string;
  version: number;
}

export interface ForecastVersion {
  companyid: string;
  creation_time: string;
  filename: string;
  upload_user: string;
  version_name: Option<string>;
  version_num: number;
}
interface CustomersBreakdown {
  retailers: string[];
  distributors: string[];
  all_others: string[];
}

export interface TotalDetails {
  sales: Option<number>[];
  revenue: Option<number>[];
}
export interface RetailerDetails {
  // add revenue here when the api supports it
  sales: Option<number>[];
  revenue: Option<number>[];
  stores: Option<number>[];
  velocity: number[];
  seasonality: number[];
  lift: number[];
  is_prom: boolean[];
  forecast_begins: string;
}

export type RetailerLabel =
  | "Sales"
  | "Revenue"
  | "Promotion"
  | "Lift"
  | "Seasonality"
  | "Stores"
  | "Velocity";
export interface RetailerOption {
  value: keyof Omit<RetailerDetails, "forecast_begins">;
  label: RetailerLabel;
}

export interface AllOtherDetails {
  // add revenue here when the api supports it
  sales: Option<number>[];
  revenue: Option<number>[];
  seasonality: number[];
  base_sales: Option<number>[];
  forecast_begins: string;
}

export type AllOtherLabel = "Sales" | "Revenue" | "Seasonality" | "Base Sales";
export interface AllOtherOption {
  value: keyof Omit<AllOtherDetails, "forecast_begins">;
  label: AllOtherLabel;
}

export interface DistributorDetails {
  // add revenue here when the api supports it
  sales: Option<number>[];
  revenue: Option<number>[];
  lift: number[];
  is_prom: boolean[];
  forecast_begins: string;
}

export type DistributorLabel = "Sales" | "Revenue" | "Promotion" | "Lift";
export interface DistributorOption {
  value: keyof Omit<DistributorDetails, "forecast_begins">;
  label: DistributorLabel;
}

export type ForecastViewOption =
  | RetailerOption
  | AllOtherOption
  | DistributorOption;
export type ForecastViewLabel =
  | RetailerLabel
  | AllOtherLabel
  | DistributorLabel;

export type TotalOptionValue = keyof TotalDetails;
export type OtherOptionValue = keyof Omit<AllOtherDetails, "forecast_begins">;
export type DistributorOptionValue = keyof Omit<
  DistributorDetails,
  "forecast_begins"
>;
export type RetailerOptionValue = keyof Omit<
  RetailerDetails,
  "forecast_begins"
>;
export type ForecastViewValue =
  | DistributorOptionValue
  | OtherOptionValue
  | RetailerOptionValue;

export type FilterIdKey =
  | "customersRetailersId"
  | "productsRetailersId"
  | "customersDistributorsId"
  | "productsDistributorsId"
  | "customersOtherId"
  | "productsOtherId";

// update
export type ForecastUpdatePayload<
  T extends RetailerForecastUpdate | AllOtherForecastUpdate
> = ForecastUpdatePayloadBase & T;

export interface ForecastUpdatePayloadBase {
  company_id: string;
  customer_key: string;
  product_key: string;
  week: Date;
}

export interface RetailerForecastUpdate {
  stores: number | null;
  velocity: number | null;
}

export interface AllOtherForecastUpdate {
  base_sales: number;
}

export interface GenerateForecastParams {
  company: string;
  customer: string;
  product: string;
  year: Date;
}

// utility
export type Option<T> = T | null | undefined;

export enum ForecastType {
  RETAILERS = "retailers",
  ALL_OTHERS = "all_others",
  DISTRIBUTORS = "distributors"
}

export enum ForecastFilter {
  CUSTOMER = "customer",
  PRODUCT = "product"
}

export enum ForecastViewType {
  TABLE = "forecast table",
  AVF = "actuals vs. forecast"
}

export type ForecastQueryParams = {
  filterId: string;
  year: string;
  version: string;
};

export interface AVFView {
  forecasted_values: number[];
  actuals_values: number[];
  difference: number[];
}

export interface SingleAVF {
  forecast: number;
  actual: number;
  diff: number;
  group: number;
}

export enum GranularityOptions {
  week = "Week",
  month = "Month",
  quarter = "Quarter",
  year = "Year"
}

export interface EditableRow {
  dates: Date[];
  customer: string;
  product: string;
  overrides: Overrides[];
  companyid: string;
  version: number;
  update: (payload: { data: UpdateOverridesRequest }) => Promise<any>;
  del: (payload: { data: DeleteOverrideRequest }) => Promise<any>;
  refetch: () => void;
}
