import { DragSource } from "react-dnd";
import PropTypes from "prop-types";
import React from "react";

import Button from "ui-library/Button";
import IconButton from "ui-library/IconButton";

import { CardActions, CardContent, Stack } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";

import {
  getEnhancedPromsFirebase,
  updateFirebase,
  updateStatusFirebase,
  firebase
} from "helpers/Firebase";
import { printMonthYear } from "helpers/Time";
import Mixpanel from "helpers/Mixpanel";
import { PromotionChip } from "ui-library/promotionTag";
import { red, amber, common, grey } from "@mui/material/colors";
import ContactChip from "../Contacts/ContactChip";
import PromCard from "./PromCard";
import PromProfile from "./PromProfile";

const red700 = red["700"];
const amber600 = amber["600"];
const red400 = red["400"];
const red600 = red["600"];
const { white } = common;
const grey700 = grey["700"];

const styles = {
  cell: {},
  dragging: {
    opacity: 1,
    margin: "10px",
    cursor: "move"
  },
  fav: {
    marginTop: "-5px",
    position: "absolute",
    right: 18
  },
  chip: {
    margin: "5px"
  },
  wrapper: {
    display: "flex",
    flexWrap: "wrap"
  }
};

const cellSource = {
  beginDrag(props) {
    return {
      promKey: props.promKey,
      name: props.name,
      status: props.status,
      prom: props
    };
  }
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

class Cell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contactDetails: {},
      hasDeductions: false
    };
  }

  openModal = () => {
    this.props.openClose.setAppModal(
      "Confirm Cancel",
      this.state.hasDeductions ? (
        <div>
          <div>
            You cannot cancel a promotion that has deductions attached to it.
          </div>
          <br />
          <Stack justifyContent="flex-end">
            <Button
              label="Go back"
              onClick={() => {
                this.props.openClose.closeAppModal();
              }}
            />
          </Stack>
        </div>
      ) : this.props.db.meta.statuses[
          this.props.db.promotions[this.props.promKey].status
        ] == "Completed" ? (
        this.state.access == "Admin" ? (
          <div>
            <div className="centering">
              {`Are you sure you want to cancel promotion '${this.props.name}'? (Remember that this promotion is completed.)`}
            </div>

            <Stack justifyContent="flex-end" sx={{ mt: 2 }}>
              <Button
                label="Yes, I'm sure"
                variant="text"
                color="error"
                onClick={() => {
                  this.props.openClose.closeAppModal();
                  updateStatusFirebase(
                    this.props.promKey,
                    this.props,
                    "Cancelled"
                  );
                }}
              />
              <Button
                label="No, go back"
                variant="text"
                onClick={() => {
                  this.props.openClose.closeAppModal();
                }}
              />
            </Stack>
          </div>
        ) : (
          <div>
            <br />
            <div>You cannot cancel a completed promotion.</div>

            <Stack justifyContent="flex-end">
              <Button
                label="Go back"
                onClick={() => {
                  this.props.openClose.closeAppModal();
                }}
              />
            </Stack>
          </div>
        )
      ) : this.props.db.permissions.includes("cancel") &&
        !this.props.readOnly ? (
        <div>
          <div className="centering">
            {`Are you sure you want to cancel promotion '${this.props.name}'?`}
          </div>

          <Stack justifyContent="flex-end" sx={{ mt: 2 }}>
            <Button
              label="Yes, I'm sure"
              variant="text"
              color="error"
              onClick={() => {
                this.props.openClose.closeAppModal();
                updateStatusFirebase(
                  this.props.promKey,
                  this.props,
                  "Cancelled"
                );
              }}
            />
            <Button
              label="No, go back"
              variant="text"
              onClick={() => {
                this.props.openClose.closeAppModal();
              }}
            />
          </Stack>
        </div>
      ) : (
        <div>
          <div>
            You do not have the permissions required to cancel this promotion.
            Please speak to an administrator.
          </div>

          <br />
          <Stack justifyContent="flex-end">
            <Button
              label="Go back"
              onClick={() => {
                this.props.openClose.closeAppModal();
              }}
            />
          </Stack>
        </div>
      )
    );
  };

  getCardContent = () => {
    const { meta } = this.props.db;
    const productGroups = this.props.db.meta.product_groups;
    return Object.keys(this.props.lines).map((key, index) => {
      const line = this.props.lines[key];
      return (
        <div>
          {`Line ${index}: ${meta.fundTypes?.[line.type]?.name} | ${
            line.productGroup == "custom"
              ? "custom"
              : productGroups[line.productGroup]
          } | ${line.spendRate}`}
        </div>
      );
    });
  };

  toggleFav = () => {
    updateFirebase(0, { starred: !this.props.starred }, this.props.promKey);
  };

  cancelCell = () => {
    getEnhancedPromsFirebase(
      enhancedProm => {
        if (enhancedProm.actMoneyList && enhancedProm.actMoneyList.length > 0) {
          this.setState({ hasDeductions: true }, this.openModal);
        } else {
          this.openModal();
        }
      },
      this.props.promKey,
      false
    );
  };

  componentDidMount() {
    const user = firebase.auth().currentUser;

    // Get the user's access level
    if (user) {
      const { uid } = user;
      this.setState({ access: this.props.db.companyUsers[uid].access });
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    const { connectDragSource, isDragging } = this.props;
    const content = this.getCardContent();

    const globalContextShowRightDrawer = this.props.showRightDrawer.bind(
      null,
      <PromProfile
        promKey={this.props.promKey}
        openClose={this.props.openClose}
        db={this.props.db}
      />
    );

    return connectDragSource(
      <div style={{ ...styles.dragging, opacity: isDragging ? 0 : 1 }}>
        <PromCard
          title={this.props.name}
          subheader={`${this.props.customerName} | ${printMonthYear(
            this.props.month,
            this.props.year
          )} | ${this.props.numLines}${
            this.props.numLines == 1 ? " line" : " lines"
          }`}
          showExpand={
            this.props.contact?.length > 0 || this.props.tags?.length > 0
          }
          actions={
            <CardActions>
              <Button
                label="Details"
                color="tonal"
                onClick={() => {
                  globalContextShowRightDrawer();
                  Mixpanel.track("Promotion Viewed", {
                    View: "Planning",
                    Interface: "Columns",
                    Component: "Cell"
                  });
                }}
              />
              <Button
                label="Cancel"
                variant="text"
                color="error"
                onClick={this.cancelCell}
                style={{ color: red700 }}
              />
              <IconButton
                style={styles.fav}
                onClick={this.toggleFav}
                size="large">
                {this.props.starred ? (
                  <StarIcon style={{ color: amber600 }} />
                ) : (
                  <StarBorderIcon style={{ color: amber600 }} />
                )}
              </IconButton>
            </CardActions>
          }
          collapsableContent={
            (this.props.contact?.length > 0 || this.props.tags?.length > 0) && (
              <CardContent>
                <div style={styles.wrapper}>
                  {this.props.contact?.map(contactKey => {
                    return (
                      <ContactChip
                        showRightDrawer={this.props.showRightDrawer}
                        name={
                          this.props.contactDetails[contactKey]
                            ? this.props.contactDetails[contactKey].name
                            : null
                        }
                        avatar={
                          this.props.contactDetails[contactKey]
                            ? this.props.contactDetails[contactKey].avatar
                            : null
                        }
                        contactKey={contactKey}
                        key={contactKey} // Suppress warning
                        db={this.props.db}
                      />
                    );
                  })}
                  {this.props.tags?.map(tagKey => (
                    <PromotionChip
                      key={tagKey}
                      tag={this.props.db.meta.promotionTags[tagKey]}
                      onClick={() => this.props.onTagClick(tagKey)}
                    />
                  ))}
                </div>
              </CardContent>
            )
          }
        />
      </div>
    );
  }
}

Cell.propTypes = {
  isDragging: PropTypes.bool.isRequired
};

export default DragSource("cell", cellSource, collect)(Cell);
