enum NotificationTypes {
  ERROR = "error",
  WARNING = "warning",
  INFO = "info"
}

export interface Notification {
  message: string;
  type: NotificationTypes;
}

const createNotificationState = () => {
  let notifications: Notification[] = [];
  let subscribers: ((notifications: Notification[]) => void)[] = [];

  const setErrors = (newErrors: Notification[]) => {
    notifications = newErrors;
    subscribers.forEach(subscriber => {
      subscriber(notifications);
    });
  };
  const add = (notification: Notification) => {
    setErrors([...notifications, notification]);
  };

  const addInfo = (message: string) =>
    add({ type: NotificationTypes.INFO, message });
  const addWarning = (message: string) =>
    add({ type: NotificationTypes.WARNING, message });
  const addError = (message: string) =>
    add({ type: NotificationTypes.ERROR, message });

  const remove = (index: number) => {
    setErrors(notifications.filter((_e, i) => i !== index));
  };
  const clear = () => {
    setErrors([]);
  };

  const unsubscribe = (fn: (notifications: Notification[]) => void) => {
    subscribers = subscribers.filter(subscriber => subscriber !== fn);
  };

  const subscribe = (fn: (notifications: Notification[]) => void) => {
    subscribers = [...subscribers, fn];

    return () => unsubscribe(fn);
  };

  return {
    notifications,
    add,
    addInfo,
    addWarning,
    addError,
    remove,
    clear,
    subscribe,
    unsubscribe
  };
};

export const NotificationState = createNotificationState();
