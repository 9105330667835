import { Checkbox, MenuItem, TextField, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { InvoiceLineDisplayService } from "components/Deductions/DeductionsReconciliation/services";
import { DisplayInvoiceLineObject } from "components/Deductions/DeductionsReconciliation/types/invoiceLineTypes";
import { InvoiceLineObject } from "components/Deductions/models";
import { useDb } from "contexts/Db";
import { displayUSCurrency } from "helpers/DataProcessing";
import moment from "moment";
import React, { useMemo } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import CollapsibleTable, {
  ColumnConfigOptions
} from "ui-library/CollapsibleTable/CollapsibleTable";
import { repaymentLineTypes } from "components/Deductions/constants/ReconciliationTypes";
import DollarFormatter from "ui-library/DollarFormatter";
import {
  checkAmountErrors,
  deselectInvoiceLine,
  selectInvoiceLine,
  setRepaymentLineAmount,
  setRepaymentLineFundTypeKey
} from "./redux/applyRepaymentSlice";
import { ApplyRepaymentLineErrorMap } from "./redux/applyRepaymentHelpers";

const useStyles = makeStyles((theme: Theme) => ({
  checkbox: {
    color: theme.palette.grey[600]
  },
  textField: {
    width: "100%"
  }
}));

export default function RepayableInvoiceLinesTable() {
  const db = useDb();
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    meta: { fundTypes = {} },
    invoices = {},
    erpTransactions = {},
    allLines = {},
    products = {},
    customers = {}
  } = db;

  const {
    repaymentLines,
    lineErrors,
    showErrors,
    activityType,
    repayableInvoiceLines,
    currentObjects: { invoice: currentInvoice } = {}
  } = useSelector((state: RootState) => state.applyRepayment, shallowEqual);

  const invoiceLines = useMemo(() => {
    return currentInvoice?.invoiceLines || {};
  }, [currentInvoice]);

  const repayableInvoiceLinesList: InvoiceLineObject[] = useMemo(() => {
    if (Object.values(invoiceLines).length === 0) {
      return [];
    }

    return repayableInvoiceLines.map(
      invoiceLineKey => invoiceLines[invoiceLineKey]
    );
  }, [repayableInvoiceLines, invoiceLines]);

  const displayRepayableInvoiceLinesList: DisplayInvoiceLineObject[] =
    useMemo(() => {
      if (!currentInvoice) {
        return [];
      }
      return repayableInvoiceLinesList
        .map(invLine =>
          InvoiceLineDisplayService.processInvoiceLineForDisplay(
            currentInvoice,
            invLine,
            invLine.invoiceLineKey || invLine.key,
            fundTypes,
            invoices,
            erpTransactions,
            allLines,
            products,
            customers
          )
        )
        .sort(
          (invLine1, invLine2) =>
            invLine1.invoiceLineNumber - invLine2.invoiceLineNumber
        );
    }, [
      currentInvoice,
      repayableInvoiceLinesList,
      fundTypes,
      invoices,
      erpTransactions,
      allLines,
      products,
      customers
    ]);

  const showLineErrorsMap: Record<
    string,
    Record<string, boolean>
  > = useMemo(() => {
    return Object.keys(repaymentLines)
      .map(invoiceLineKey => {
        const lineError = lineErrors[invoiceLineKey] || undefined;
        return {
          invoiceLineKey,
          showLineError: {
            amount:
              showErrors &&
              (lineError.amountZero || lineError.exceedsMaxAmount),
            fundTypeKey: showErrors && lineError.missingFundTypeKey,
            fundTypeAccount: showErrors && lineError.missingFundTypeAccount
          }
        };
      })
      .reduce((allLineErrorMessages, entry) => {
        const { invoiceLineKey, showLineError } = entry;
        return {
          ...allLineErrorMessages,
          [invoiceLineKey]: showLineError
        };
      }, {});
  }, [lineErrors, repaymentLines, showErrors]);

  const lineErrorMessagesMap: Record<
    string,
    Record<string, string>
  > = useMemo(() => {
    return Object.keys(repaymentLines)
      .map(invoiceLineKey => {
        const lineError = lineErrors[invoiceLineKey] || {};
        return {
          invoiceLineKey,
          lineError: {
            amount:
              (showErrors &&
                ((lineError.amountZero &&
                  ApplyRepaymentLineErrorMap.amountZero) ||
                  (lineError.exceedsMaxAmount &&
                    ApplyRepaymentLineErrorMap.exceedsMaxAmount))) ||
              "",
            fundTypeKey:
              (showErrors &&
                lineError.missingFundTypeKey &&
                ApplyRepaymentLineErrorMap.missingFundTypeKey) ||
              "",
            fundTypeAccount:
              (showErrors &&
                lineError.missingFundTypeAccount &&
                ApplyRepaymentLineErrorMap.missingFundTypeAccount) ||
              ""
          }
        };
      })
      .reduce((allLineErrorMessages, entry) => {
        const { invoiceLineKey, lineError } = entry;
        return {
          ...allLineErrorMessages,
          [invoiceLineKey]: lineError
        };
      }, {});
  }, [repaymentLines, lineErrors, showErrors]);

  const invoiceLineColumnConfig = useMemo(
    () =>
      new Map([
        [
          "key",
          {
            align: "center",
            name: "Select",
            render: key => {
              return (
                <Checkbox
                  checked={Object.keys(repaymentLines).includes(key as string)}
                  onChange={event => {
                    event.stopPropagation();
                    if (event.target.checked) {
                      dispatch(
                        selectInvoiceLine({
                          invoiceLine: invoiceLines[key as string],
                          db
                        })
                      );
                    } else {
                      dispatch(
                        deselectInvoiceLine(invoiceLines[key as string])
                      );
                    }
                    dispatch(checkAmountErrors());
                  }}
                  className={classes.checkbox}
                />
              );
            }
          }
        ],
        [
          "invoiceLineNumber",
          {
            name: "#",
            render: val => ((val as number) + 1).toString()
          }
        ],
        [
          "customerName",
          {
            name: "Customer"
          }
        ],
        [
          "productName",
          {
            name: "Product Group"
          }
        ],
        [
          "amount",
          {
            align: "right",
            name: "Original Amount",
            render: val => displayUSCurrency(val || 0)
          }
        ],
        ...(activityType === repaymentLineTypes.REPAYMENT
          ? [
              [
                "openDisputeAmount",
                {
                  align: "right",
                  name: "Open Dispute",
                  render: val => displayUSCurrency(val || 0.0)
                }
              ]
            ]
          : [
              [
                "openWriteOffAmount",
                {
                  align: "right",
                  name: "Open Write-Off",
                  render: val => displayUSCurrency(val || 0.0)
                }
              ]
            ]),
        [
          "repaymentAmount",
          {
            align: "right",
            name:
              activityType === repaymentLineTypes.REPAYMENT
                ? "Repayment Amount"
                : "Credit Amount",
            render: (_val, invoiceLine) => {
              const invoiceLineKey = invoiceLine.key;
              const repaymentLine =
                repaymentLines[invoiceLineKey as string] || undefined;
              const amount = repaymentLine?.amount || 0.0;
              return (
                <TextField
                  variant="outlined"
                  error={
                    repaymentLine && showLineErrorsMap[invoiceLineKey].amount
                  }
                  helperText={
                    repaymentLine && lineErrorMessagesMap[invoiceLineKey].amount
                  }
                  size="small"
                  onChange={event => {
                    if (repaymentLine) {
                      dispatch(
                        setRepaymentLineAmount({
                          invoiceLineKey: invoiceLineKey as string,
                          amount: event.target.value as string
                        })
                      );
                      dispatch(checkAmountErrors());
                    }
                  }}
                  value={amount}
                  disabled={!repaymentLine}
                  InputProps={{
                    inputComponent: DollarFormatter,
                    inputProps: { showNegative: true }
                  }}
                />
              );
            }
          }
        ],
        [
          "fundType",
          {
            name: "Fund Type",
            render: (_val, displayInvoiceLine) => {
              const { invoiceLineKey, matchedPromLine = "" } =
                displayInvoiceLine;
              const repaymentLine = repaymentLines[invoiceLineKey] || undefined;
              const { allLines = {}, meta: { fundTypes = {} } = {} } = db;
              const fundTypeKey =
                repaymentLine?.fundTypeKey ||
                allLines[matchedPromLine || ""]?.type ||
                displayInvoiceLine.suggestedFundType ||
                "";

              return (
                <TextField
                  className={classes.textField}
                  select
                  size="small"
                  variant="outlined"
                  value={fundTypeKey}
                  disabled={!repaymentLine}
                  error={
                    repaymentLine &&
                    showLineErrorsMap[invoiceLineKey].fundTypeKey
                  }
                  helperText={
                    repaymentLine &&
                    lineErrorMessagesMap[invoiceLineKey].fundTypeKey
                  }
                  onChange={event => {
                    const key = event.target.value as string;
                    dispatch(
                      setRepaymentLineFundTypeKey({
                        fundTypeKey: key,
                        db,
                        invoiceLineKey
                      })
                    );
                  }}>
                  {Object.entries(fundTypes).map(entry => {
                    const [key, fundType] = entry;
                    return (
                      <MenuItem value={key} key={key}>
                        {fundType.name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              );
            }
          }
        ],
        [
          "fundTypeAccount",
          {
            name: "Account",
            render: (_val, displayInvoiceLine) => {
              const { invoiceLineKey, matchedPromLine = "" } =
                displayInvoiceLine;
              const repaymentLine = repaymentLines[invoiceLineKey] || undefined;
              const {
                allLines = {},
                // eslint-disable-next-line camelcase
                meta: { fundTypes = {} } = {},
                accounts = {}
              } = db;
              const fundTypeKey =
                repaymentLine?.fundTypeKey ||
                allLines[matchedPromLine || ""]?.type ||
                displayInvoiceLine.suggestedFundType ||
                "";

              const fundTypeAccount =
                accounts[fundTypes[fundTypeKey]?.accountKey]?.name || "";

              return fundTypeAccount;
            }
          }
        ],
        [
          "startDate",
          {
            name: "Date",
            render: val => moment(val).format("MM/DD/YYYY")
          }
        ]
      ] as [keyof DisplayInvoiceLineObject, ColumnConfigOptions<DisplayInvoiceLineObject>][]),
    [
      classes.checkbox,
      dispatch,
      classes.textField,
      repaymentLines,
      invoiceLines,
      db,
      activityType,
      lineErrorMessagesMap,
      showLineErrorsMap
    ]
  );

  return (
    <>
      <CollapsibleTable<DisplayInvoiceLineObject>
        data={displayRepayableInvoiceLinesList}
        columnConfig={invoiceLineColumnConfig}
        index="key"
        onRowClick={() => {}}
        pagination={{
          enabled: true,
          pageSize: 4,
          rowsPerPageOptions: [4]
        }}
      />
    </>
  );
}
