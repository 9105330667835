import React from "react";

import FileSaver from "file-saver";

import { ListItem, ListItemText } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import Button from "ui-library/Button";

import {
  firebase,
  downloadFilesFromStorage,
  isUserCresicorEmployee,
  getFirebaseConsoleURL
} from "helpers/Firebase";

import { common, grey, yellow } from "@mui/material/colors";

const { white } = common;
const grey700 = grey["700"];
const yellow700 = yellow["700"];
const yellow900 = yellow["900"];

class ReportProfile extends React.Component {
  constructor(props) {
    super(props);
  }

  downloadFile = () => {
    const report = this.props.db.reports[this.props.reportKey];
    const { filePath } = report;
    const fileName = report.filePath.replace(/^.*[\\\/]/, "");

    const user = firebase.auth().currentUser;

    firebase
      .database()
      .ref(`users/${user.uid}/company_id`)
      .once("value", snapshot => {
        downloadFilesFromStorage(
          [filePath],
          [fileName],
          (blob, name) => FileSaver.saveAs(blob, name),
          null
        );
      });
  };

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    const firebaseConsoleURL = `${getFirebaseConsoleURL()}/companies/${
      this.props.db.companyid
    }/reports/${this.props.reportKey}`;
    return (
      <div>
        <ListItem
          sx={{ background: theme => theme.palette.readOnly.surface3 }}
          ContainerComponent="div">
          <ListItemText
            primary={this.props.db.reports[this.props.reportKey].name}
            secondary={
              <span>
                Report Profile
                {isUserCresicorEmployee() && (
                  <span>
                    {" "}
                    |{" "}
                    <a
                      href={firebaseConsoleURL}
                      target="_blank"
                      style={{ color: "#1b7acf" }}
                      rel="noreferrer">
                      View on Firebase Console
                    </a>
                  </span>
                )}
              </span>
            }
          />
        </ListItem>

        <br />
        <div className="centering">
          <Button
            label="Download Report"
            onClick={this.downloadFile}
            icon={<GetAppIcon />}
          />
        </div>
      </div>
    );
  }
}

export default ReportProfile;
