import React from "react";
import {
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction
} from "@mui/material";

import PersonIcon from "@mui/icons-material/Person";
import SaveIcon from "@mui/icons-material/Save";
import Stack from "@mui/material/Stack";

import Button from "ui-library/Button";
import TextField from "ui-library/TextField";

import {
  getFirebase,
  removeFirebase,
  updateFirebase,
  getFirebaseConsoleURL,
  isUserCresicorEmployee
} from "helpers/Firebase";

export default class ContactProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: {},
      changes: false,
      tab: 0,
      messages: {},
      searchQuery: ""
    };
  }

  getContactData = contactKey => {
    getFirebase(
      3,
      contact => {
        if (contact) {
          this.setState({
            contact,
            changes: false
          });
        }
      },
      contactKey
    );
  };

  handleTextChange = (field, event) => {
    const newContact = this.state.contact;
    newContact[field] = event.target.value;
    this.setState({
      contact: newContact,
      changes: true
    });
  };

  handleSaveChanges = () => {
    updateFirebase(3, this.state.contact, this.props.contactKey);
    this.setState({
      changes: false
    });
  };

  handleDelete = () => {
    removeFirebase(3, this.props.contactKey);
  };

  handleTabChange = (event, value) => {
    this.setState({
      tab: value
    });
  };

  handleSearch = event => {
    this.setState({
      searchQuery: event.target.value
    });
  };

  componentDidMount() {
    this.getContactData(this.props.contactKey);

    if (!this.props.fixedHeight) {
      const ppHeight =
        document.documentElement.clientHeight -
        $(this.refs.contactprofile).offset().top;
      this.setState({
        ppHeight
      });
    }
  }

  // This component is unique in that new queries to the database are needed even after the initial mount,
  // every time new props are received.
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getContactData(nextProps.contactKey);

    if (!nextProps.fixedHeight) {
      const ppHeight =
        document.documentElement.clientHeight -
        $(this.refs.contactprofile).offset().top;
      this.setState({
        ppHeight
      });
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    const editPermission = this.props.db.permissions.includes("editContacts");
    const firebaseConsoleURL = `${getFirebaseConsoleURL()}/companies/${
      this.props.db.companyid
    }/contacts/${this.props.contactKey}`;

    return (
      <div key={this.props.contactKey}>
        <List style={{ padding: 0 }}>
          <ListItem
            sx={{ background: theme => theme.palette.readOnly.surface3 }}>
            <ListItemAvatar>
              {!this.state.contact || !this.state.contact.avatar ? (
                <Avatar icon={<PersonIcon />} />
              ) : (
                <Avatar src={this.state.contact.avatar} />
              )}
            </ListItemAvatar>
            <ListItemText
              primary={
                !this.state.contact || this.state.contact.name === ""
                  ? "None"
                  : this.state.contact.name
              }
              secondary={
                <span>
                  <span>Contact Profile</span>
                  {isUserCresicorEmployee() && (
                    <span>
                      {" "}
                      |{" "}
                      <a
                        href={firebaseConsoleURL}
                        target="_blank"
                        style={{ color: "#1b7acf" }}
                        rel="noreferrer">
                        View on Firebase Console
                      </a>
                    </span>
                  )}
                </span>
              }
            />
            <ListItemSecondaryAction>
              {this.state.changes ? (
                <Button
                  label="Save Changes"
                  onClick={this.handleSaveChanges}
                  icon={<SaveIcon />}
                />
              ) : (
                <Button label="Save Changes" disabled icon={<SaveIcon />} />
              )}
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        {this.state.contact ? (
          <div>
            {/* <Tabs
              value={this.state.tab}
              onChange={this.handleTabChange}
              style={styles.tabs}
              variant="fullWidth"
            >
              <Tab label="Contact Info" icon={<InfoIcon />} {...a11yProps(0)} />
              <Tab label="Activity" icon={<ActivityIcon />} {...a11yProps(1)} />
            </Tabs> */}
            <Stack
              direction="column"
              sx={{
                px: 2,
                py: 2,
                height: this.state.ppHeight + 32,
                overflow: "auto",
                mt: 2
              }}
              ref="contactprofile">
              <TextField
                value={this.state.contact.name || ""}
                onChange={this.handleTextChange.bind(null, "name")}
                floatingLabelText="Name"
                fullWidth
                disabled={!editPermission}
              />
              <br />
              <TextField
                value={this.state.contact.company || ""}
                onChange={this.handleTextChange.bind(null, "company")}
                floatingLabelText="Company"
                fullWidth
                disabled={!editPermission}
              />
              <br />
              <TextField
                value={this.state.contact.title || ""}
                onChange={this.handleTextChange.bind(null, "title")}
                floatingLabelText="Title"
                fullWidth
                disabled={!editPermission}
              />
              <br />
              <TextField
                value={this.state.contact.email || ""}
                onChange={this.handleTextChange.bind(null, "email")}
                floatingLabelText="Email"
                fullWidth
                disabled={!editPermission}
              />
              <br />
              <TextField
                value={this.state.contact.phone || ""}
                onChange={this.handleTextChange.bind(null, "phone")}
                floatingLabelText="Phone Number"
                fullWidth
                disabled={!editPermission}
              />
            </Stack>
          </div>
        ) : (
          <div className="centering" style={{ margin: 16 }}>
            Contact no longer exists.
          </div>
        )}
      </div>
    );
  }
}
