export const muiPaletteLight = {
  primary: {
    main: "#004D62",
    light: "#135B6F",
    // light: "#337081",
    dark: "#003544",
    contrastText: "#FFFFFF",
    container: "#B6EAFF",
    onContainer: "#001F2A",
    inverse: "#51D5FF",
    onhover: "#135B6F"
  },
  secondary: {
    main: "#70129A",
    light: "#8C41AE",
    dark: "#4E0C6B",
    contrastText: "#FFFFFF",
    container: "#F9D8FF",
    onContainer: "#310049"
  },
  tertiary: {
    main: "#7F0086",
    light: "#98339E",
    dark: "#58005D",
    contrastText: "#FFFFFF",
    container: "#FFD7F9",
    onContainer: "#37003B"
  },
  tonal: {
    // Secondary container color
    main: "#F9D8FF",
    light: "#FADFFF",
    dark: "#E9C7F0",
    contrastText: "#310049",
    pale: "#DFE5D8"
  },
  error: {
    main: "#930006",
    light: "#A83337",
    dark: "#660004",
    contrastText: "#FFFFFF",
    container: "#FFDAD4",
    onContainer: "#410001"
  },
  warning: {
    main: "#7C2D00",
    light: "#965733",
    dark: "#561F00",
    contrastText: "#FFFFFF",
    container: "#FFDBCB",
    onContainer: "#360F00"
  },
  info: {
    main: "#4C4800",
    light: "#6F6C33",
    dark: "#353200",
    contrastText: "#FFFFFF",
    container: "#F0E82B",
    onContainer: "#1E1C00"
  },
  success: {
    main: "#2D6B28",
    light: "#578853",
    dark: "#1F4A1C",
    contrastText: "#FFFFFF",
    container: "#AEF49F",
    onContainer: "#002200"
  },
  background: {
    default: "#FDFBFF",
    surfaceVariant: "#DCE4E8",
    onBackground: "#1A1B1F",
    onSurfaceVariant: "#40484C",
    inverseSurface: "#2F3034",
    inverseOnSurface: "#F2F0F4",
    outline: "#70787D"
  },
  vividlyGradients: {
    gradient1:
      "linear-gradient(270deg, #F7F003 2.38%, #FBB20A 17.16%, #FF8110 30.8%, #FF6FB2 53.55%, #FF66FF 64.92%, #AA80FF 76.29%, #00B4FF 97.89%)",
    gradient2:
      "linear-gradient(72.28deg, #F7F003 4.45%, #FAC80B 15.27%, #FF8019 32.52%, #FF7F1F 34.33%, #FF7D31 36.81%, #FF7A4E 39.69%, #FF7955 40.26%, #FF785F 41.35%, #FF7578 43.15%, #FF7389 44.13%, #FF6FB4 47.85%, #FF67FC 56.36%, #D67DFF 66.09%, #8F94F4 77.22%, #809AF5 79.54%, #57AAF7 84%, #17C3FC 90.14%, #00CCFD 92.18%, #07CAEA 102.69%)"
  },
  neutralVariants: {
    neutral100: "#FFFFFF",
    neutral99: "#FDFBFF",
    neutral95: "#EAF2F7",
    neutral90: "#DCE4E8",
    neutral80: "#C0C8CC",
    neutral70: "#ACABAF",
    neutral60: "#8A9296",
    neutral50: "#70787D",
    neutral40: "#5E5E61",
    neutral30: "#46474A",
    neutral20: "#2F3034",
    neutral10: "#1A1B1F",
    neutral0: "#000000"
  },
  readOnly: {
    surface1:
      "linear-gradient(0deg, rgba(0, 103, 130, 0.05), rgba(0, 103, 130, 0.05)), #FDFBFF",
    surface2:
      "linear-gradient(0deg, rgba(0, 103, 130, 0.08), rgba(0, 103, 130, 0.08)), #FDFBFF",
    surface3:
      "linear-gradient(0deg, rgba(0, 103, 130, 0.11), rgba(0, 103, 130, 0.11)), #FDFBFF",
    surface4:
      "linear-gradient(0deg, rgba(0, 103, 130, 0.12), rgba(0, 103, 130, 0.12)), #FDFBFF",
    surface5:
      "linear-gradient(0deg, rgba(0, 103, 130, 0.14), rgba(0, 103, 130, 0.14)), #FDFBFF"
  }
};

export const muiPaletteDark = {
  primary: {
    main: "#51D5FF",
    light: "#73DDFF",
    dark: "#3895B2",
    contrastText: "#003545",
    container: "#004D62",
    onContainer: "#B6EAFF",
    inverse: "#006782",
    onhover: "#73DDFF"
  },
  secondary: {
    main: "#EBB2FF",
    light: "#EFC1FF",
    dark: "#A47CB2",
    contrastText: "#500074",
    container: "#70129A",
    onContainer: "#F9D8FF"
  },
  tertiary: {
    main: "#FFA9FA",
    light: "#FFBAFB",
    dark: "#B276AF",
    contrastText: "#5A005F",
    container: "#7F0086",
    onContainer: "#FFD7F9"
  },
  tonal: {
    // Secondary container color
    main: "#70129A",
    light: "#8C41AE",
    dark: "#4E0C6B",
    contrastText: "#F9D8FF",
    pale: "#DFE5D8"
  },
  error: {
    main: "#FFB4A9",
    light: "#FFC3BA",
    dark: "#B27D76",
    contrastText: "#680003",
    container: "#930006",
    onContainer: "#FFDAD4"
  },
  warning: {
    main: "#FFB592",
    light: "#FFC3A7",
    dark: "#B27E66",
    contrastText: "#581E00",
    container: "#7C2D00",
    onContainer: "#FFDBCB"
  },
  info: {
    main: "#D3CB00",
    light: "#DBD533",
    dark: "#938E00",
    contrastText: "#353200",
    container: "#4C4800",
    onContainer: "#F0E82B"
  },
  success: {
    main: "#94D786",
    light: "#A9DF9E",
    dark: "#67965D",
    contrastText: "#003A00",
    container: "#115211",
    onContainer: "#AEF49F"
  },
  background: {
    default: "#1A1B1F",
    paper: "#262626",
    surfaceVariant: "#40484C",
    onBackground: "##E3E2E6",
    onSurfaceVariant: "#C0C8CC",
    inverseSurface: "#E3E2E6",
    inverseOnSurface: "#1A1B1F",
    outline: "#8A9296"
  },
  vividlyGradients: {
    gradient1:
      "linear-gradient(270deg, #F7F003 2.38%, #FBB20A 17.16%, #FF8110 30.8%, #FF6FB2 53.55%, #FF66FF 64.92%, #AA80FF 76.29%, #00B4FF 97.89%)",
    gradient2:
      "linear-gradient(72.28deg, #F7F003 4.45%, #FAC80B 15.27%, #FF8019 32.52%, #FF7F1F 34.33%, #FF7D31 36.81%, #FF7A4E 39.69%, #FF7955 40.26%, #FF785F 41.35%, #FF7578 43.15%, #FF7389 44.13%, #FF6FB4 47.85%, #FF67FC 56.36%, #D67DFF 66.09%, #8F94F4 77.22%, #809AF5 79.54%, #57AAF7 84%, #17C3FC 90.14%, #00CCFD 92.18%, #07CAEA 102.69%)"
  },
  neutralVariants: {
    neutral100: "#FFFFFF",
    neutral99: "#FDFBFF",
    neutral95: "#EAF2F7",
    neutral90: "#DCE4E8",
    neutral80: "#C0C8CC",
    neutral70: "#ACABAF",
    neutral60: "#8A9296",
    neutral50: "#70787D",
    neutral40: "#5E5E61",
    neutral30: "#46474A",
    neutral20: "#2F3034",
    neutral10: "#1A1B1F",
    neutral0: "#000000"
  },
  readOnly: {
    surface1:
      "linear-gradient(0deg, rgba(81, 213, 255, 0.05), rgba(81, 213, 255, 0.05)), #1A1B1F",
    surface2:
      "linear-gradient(0deg, rgba(81, 213, 255, 0.08), rgba(81, 213, 255, 0.08)), #1A1B1F",
    surface3:
      "linear-gradient(0deg, rgba(81, 213, 255, 0.11), rgba(81, 213, 255, 0.11)), #1A1B1F",
    surface4:
      "linear-gradient(0deg, rgba(81, 213, 255, 0.12), rgba(81, 213, 255, 0.12)), #1A1B1F",
    surface5:
      "linear-gradient(0deg, rgba(81, 213, 255, 0.14), rgba(81, 213, 255, 0.14)), #1A1B1F"
  }
};

// DATA VISUALIZATION COLORS

export const graphPalette = {
  yellow90: "#F0E82B",
  blue80: "#51D5FF",
  pink80: "#FFA9FA",
  purple80: "#EBB2FF",
  orange80: "#FFB592",
  red80: "#FF897A",
  green80: "#94D786",
  yellow80: "#D3CB00",
  blue70: "#00BAE8",
  pink70: "#FF72FF",
  purple70: "#DD89FF",
  orange70: "#FF8C51",
  red70: "#FF5449",
  green70: "#79BB6D",
  grey60: "#909094"
};
