import React from "react";
import NavigationPrompt from "react-router-navigation-prompt";
import { ListItem, ListItemText } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Button from "ui-library/Button";
import CancelIcon from "@mui/icons-material/Cancel";

import {
  addFirebase,
  getCompanyUsers,
  getPromotionsFirebase,
  sendNotif,
  firebase,
  uploadFilesToStorage
} from "helpers/Firebase";
import { printMonthYear } from "helpers/Time";
import { promSatisfiesSearch } from "helpers/satisfiesSearch";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "ui-library/Dialog";
import Mixpanel from "helpers/Mixpanel";
import {
  exportComponentAsPDF,
  exportComponentAsPNG
} from "react-component-export-image";
import { grey, common } from "@mui/material/colors";
import { styles } from "../App";
import Dbcolumn from "./Dbcolumn";
import PromList from "./PromList";
import PromToolbar from "./PromToolbar";
import PromProfile from "./PromProfile";
import AdvancedEditPromotions from "./AdvancedEditPromotions";
import Calendar from "./Calendar/Calendar";
import SplitScreen from "../WebsiteElements/SplitScreen";
import store from "../../store";
import { reset } from "../AddPromotion/actions";

const grey700 = grey["700"];
const { white } = common;

const pagelength = 30;

const interfaces = [
  "Columns",
  "List",
  "Submission",
  "Approval",
  "Advanced Duplication",
  "Advanced Editing",
  "Calendar"
];

class Planning extends React.Component {
  calendarRef: React.RefObject<HTMLCanvasElement>;

  filtersRef: React.RefObject<HTMLCanvasElement>;

  constructor(props) {
    super(props);
    this.state = {
      searchQuery: "",
      clists: {},
      interface: props.interface || 0,
      searchProm: {},
      selectedProms: [],
      multi: false,
      count: pagelength,
      proposedProms: {},
      tableKey: 0,
      interfaceJSX: [],
      allProms: {},
      promKeyList: [],
      additionalSearch: {},
      sideBar: false,
      rightDrawerOpen: false,
      sortBy: "date",
      showSearch: false
    };
    this.calendarRef = React.createRef<HTMLCanvasElement>();
    this.filtersRef = React.createRef<HTMLCanvasElement>();
  }

  componentDidMount() {
    this.interval = setInterval(() => this.checkStatusUpdated(), 500);

    var user = firebase.auth().currentUser;
    // Get the user's access level
    if (user) {
      getCompanyUsers(companyUsers => {
        const { uid } = user;
        this.setState({ access: companyUsers[uid].access });
      });
    }

    this.getPromLists(this.props.db.meta);

    const ssHeight =
      document.documentElement.clientHeight - $(this.refs.table).offset().top;
    this.setState({
      tbHeight: ssHeight - 56,
      ssHeight,
      colHeight: document.body.clientHeight - 206,
      sideBar: this.props.sideBar
    });

    var user = firebase.auth().currentUser;
    firebase
      .database()
      .ref(`users/${user.uid}/company_id`)
      .on("value", snapshot => {
        if (snapshot.val()) {
          const companyid = snapshot.val();
          this.setState({ userid: user.uid, companyid });
        }
      });

    const promKey = this.props.match?.params?.key;
    const allPromotions = this.props.db.promotions;
    const mode = this.props.mode || 0;
    const status = this.props.db.promotions[promKey]?.status;
    if (
      promKey &&
      promKey in allPromotions &&
      this.isCorrectMode(mode, status)
    ) {
      this.props.showRightDrawer(
        <PromProfile
          history={this.props.history}
          promKey={promKey}
          openClose={this.props.openClose}
          db={this.props.db}
        />
      );
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    clearInterval(this.interval);
    this.interval = setInterval(() => this.checkStatusUpdated(), 500);

    if (nextProps.db.meta != this.props.db.meta) {
      this.getPromLists(nextProps.db.meta);
    }

    const sideBarDifferent = nextProps.sideBar != this.state.sideBar;

    if (
      sideBarDifferent ||
      nextProps.rightDrawerOpen != this.state.rightDrawerOpen ||
      nextProps.onDrawerClose != this.state.onDrawerClose
    ) {
      this.setState(
        {
          sideBar: nextProps.sideBar,
          rightDrawerOpen: nextProps.rightDrawerOpen,
          onDrawerClose: nextProps.onDrawerClose
        },
        () => {
          if (sideBarDifferent) {
            this.updateInterface();
          }
        }
      );
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.interfaceId != this.state.interfaceId) {
      return true;
    }

    if (
      nextProps.sideBar != this.props.sideBar ||
      nextProps.rightDrawerOpen != this.props.rightDrawerOpen ||
      nextProps.onDrawerClose != this.props.onDrawerClose
    ) {
      return false;
    }
    return true;
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleAdvDupConfirm = () => {
    // if any elements aren't dicts, prompt error
    for (var key in this.state.proposedProms) {
      if (this.state.proposedProms[key].constructor != Object) {
        this.props.openClose.showSnack(
          "Unknown error occurred, please try again."
        );
        // print error info
        console.error(this.state.proposedProms);
        return;
      }
    }
    // write new promotions to database
    const keys = Object.keys(this.state.proposedProms);
    const proms = Object.values(this.state.proposedProms).map(prom => {
      const newProm = { ...prom };
      newProm.duplicatedFrom = null;
      newProm.fileInfo =
        newProm.fileInfo?.map(({ file, ...rest }) => ({
          ...rest
        })) || [];
      return newProm;
    });

    let promotionClash = false;
    for (const newPromKey in proms) {
      const newProm = proms[newPromKey];
      for (var key in this.props.db.promotions) {
        const existingProm = this.props.db.promotions[key];
        if (
          this.props.db.meta.statuses[existingProm.status] != "Cancelled" &&
          newProm.name === existingProm.name
        ) {
          promotionClash = true;
        }
      }
      if (promotionClash) break;
    }

    if (promotionClash) {
      this.props.openClose.setAppModal(
        "Conflicting promotion names",
        <div className="centering">
          At least one unconfirmed promotion here has a name that is the same as
          an existing promotion in the system. Please reconcile this name
          conflict before attempting to continue with advanced duplication.
        </div>,
        <div className="centering">
          <Button
            label="Okay"
            onClick={() => {
              this.props.openClose.closeAppModal();
            }}
          />
        </div>
      );
    } else {
      const promKeys = Object.keys(this.state.proposedProms);
      const promData = {};
      promData.userid = this.state.userid;
      promData.companyid = this.state.companyid;
      const fromTo = [];
      for (var key of promKeys) {
        const promoFrom = this.state.proposedProms[key].duplicatedFrom;
        const fileNames = [];
        if (promoFrom.fileInfo) {
          for (let ind = 0; ind < promoFrom.fileInfo.length; ind++) {
            fileNames.push(promoFrom.fileInfo[ind].name);
          }
        }
        fromTo.push({
          from: promoFrom.promKey,
          to: key,
          fileNames
        });
      }
      promData.from_to = fromTo;

      this.props.openClose.setAppModal(
        `Confirm Promotion${promKeys.length > 1 ? "s" : ""}`,
        <div>
          <br />
          <div className="centering">
            <CircularProgress size={80} thickness={5} />
          </div>
          <br />
          <div className="centering">
            Confirming{" "}
            {`${promKeys.length} promotion${promKeys.length > 1 ? "s" : ""}`},
            please wait...
          </div>
          <br />
          <div className="centering">This may take up to a minute.</div>
          <br />
          <div className="centering">
            <b>
              <font color="red">
                Do not refresh the page or use your browser's Back button—your
                promotions will be lost.
              </font>
            </b>
          </div>
          <br />
        </div>,
        null,
        true
      );

      const uploadNewAttachments = (promKeys, uploadCompletedCallback) => {
        const { proposedProms } = this.state;
        const needsFileUpload = Object.values(proposedProms).some(
          ({ fileInfo = [] }) => fileInfo.length
        );
        if (needsFileUpload) {
          const user = firebase.auth().currentUser;
          return firebase
            .database()
            .ref(`users/${user.uid}/company_id`)
            .once("value", snapshot => {
              if (snapshot.val()) {
                const company_id = snapshot.val();
                promKeys.forEach(promKey => {
                  const { fileInfo } = proposedProms[promKey];
                  const filePaths = fileInfo.map(
                    item =>
                      `${company_id}/Promotion Files/${promKey}/${item.name}`
                  );
                  uploadFilesToStorage(
                    filePaths,
                    fileInfo.map(x => x.file),
                    uploadCompletedCallback,
                    null,
                    () => {
                      this.props.openClose.closeAppModal();
                      this.props.openClose.showSnack("Unknown error occurred");
                    }
                  );
                });
              }
            });
        }
        return uploadCompletedCallback();
      };

      uploadNewAttachments(promKeys, () => {
        addFirebase(
          0,
          proms,
          promKeys => {
            const messages = [];
            for (let ind = 0; ind < promKeys.length; ind++) {
              const { name } = proms[ind];
              messages.push(`New promotion created: ${name}`);
            }
            sendNotif(messages, "addProm", null, {
              promKey: promKeys
            });
            store.dispatch(reset());
            this.setState({ proposedProms: {} }, () => {
              this.updateInterface();
              this.props.openClose.closeAppModal();
              this.props.openClose.showSnack("Confirm successful!");
            });
          },
          keys,
          e => {
            console.log(`Exception warning: ${e}`);
          }
        );
      });
    }
  };

  updateInterface = () => {
    const interfaceJSX = this.getInterfaceJSX();
    const promKeyList = this.getPromKeyList();
    this.setState({
      interfaceJSX,
      promKeyList,
      interfaceId: (this.state.interfaceId || 0) + 1
    });
  };

  promCreationWindowClosed = () => {
    this.updateInterface();
  };

  // declared here at top level because need to be called when searching/sorting
  handleInfiniteLoad = () => {
    // update length of PromList
    this.setState(
      {
        count: this.state.count + pagelength
      },
      this.updateInterface
    );
  };

  handleSearch = value => {
    const newSearchQuery = value.toLowerCase();
    this.setState(
      {
        searchQuery: newSearchQuery,
        tableKey: (this.state.tableKey + 1) % 2
      },
      this.updateInterface
    );
  };

  handleSelectInterface = value => {
    if (this.state.interface != value) {
      Mixpanel.track("Page View", {
        Category: "Promotions",
        View: "Planning",
        Interface: interfaces[value]
      });
    }
    this.setState(
      {
        interface: value,
        searchQuery: "",
        searchProm: {},
        selectedProms: [],
        count: pagelength,
        tableKey: (this.state.tableKey + 1) % 2
      },
      this.updateInterface
    );
  };

  handleSortBy = value => {
    this.setState(
      {
        sortBy: value
      },
      this.updateInterface
    );
  };

  filterClist = clist => {
    const filteredClist = [];
    const user = firebase.auth().currentUser;
    if (user) {
      const { uid } = user;
      const companyUser = this.props.db.companyUsers[uid] || {};
      const { customers } = this.props.db;
      const productGroups = this.props.db.meta.product_groups;
      for (let i = 0; i < clist.length; i++) {
        const c = { ...clist[i] };
        if (!customers[c.customer]) continue;
        c.customerName = customers[c.customer]?.name;
        const linesList = Object.values(c.lines);
        c.productGroup = linesList.map(line =>
          line.productGroup == "custom"
            ? "Custom"
            : productGroups[line.productGroup]?.name
        );
        c.product = linesList.map(line =>
          line.productGroup == "custom"
            ? line.product
            : productGroups[line.productGroup].products
        );
        c.product = [].concat(...c.product); // concanate product arrays together
        c.product = Array.from(new Set(c.product)); // remove duplicates
        c.product = c.product.map(x => this.props.db.products[x]?.name); // convert to product name
        if (
          (this.state.searchQuery === "" ||
            c.name.toLowerCase().indexOf(this.state.searchQuery) != -1 ||
            c.promKey.toLowerCase().indexOf(this.state.searchQuery) != -1 ||
            (c.lines &&
              Object.keys(c.lines).some(lineKey => {
                return (
                  lineKey.toLowerCase().indexOf(this.state.searchQuery) != -1
                );
              })) ||
            printMonthYear(c.month, c.year)
              .toLowerCase()
              .indexOf(this.state.searchQuery) != -1 ||
            c.customerName.toLowerCase().indexOf(this.state.searchQuery) !=
              -1) &&
          (!companyUser.customers ||
            companyUser.customers.includes(c.customer)) &&
          promSatisfiesSearch(c, this.state.searchProm)
        ) {
          const d = { ...clist[i] };
          d.customerName = customers[d.customer]?.name;
          d.dateField = new Date(d.year, d.month, 1).getTime();
          d.lastModified = new Date(
            `${d.modified.date} ${d.modified.time}`
          ).getTime();
          filteredClist.push(d);
        }
      }
    }
    return filteredClist;
  };

  setSearchProm = searchProm => {
    this.setState(
      {
        searchProm,
        tableKey: (this.state.tableKey + 1) % 2
      },
      this.updateInterface
    );
  };

  clearSelectedProms = () => {
    this.setState(
      {
        selectedProms: [],
        tableKey: (this.state.tableKey + 1) % 2 // trick to change key of InfiniteTable so that it remounts
      },
      this.updateInterface
    );
  };

  handleRowSelection = (promList, promLength, rows) => {
    const selectedProms = [];
    if (rows.length == promLength) {
      // all rows were selected
      for (let i = 0; i < promList.length; i++) {
        selectedProms.push(promList[i].promKey);
      }
    } else {
      for (const row of rows) {
        selectedProms.push(row.promKey);
      }
    }
    this.setState({
      selectedProms
    });
  };

  toggleMulti = () => {
    this.setState({
      multi: !this.state.multi
    });
  };

  autoDuplicateCallback = newProms => {
    this.setState(
      {
        proposedProms: newProms
      },
      this.updateInterface
    );
  };

  getPromKeyList = () => {
    const promList = this.filterClist(
      this.props.db.promotions ? Object.values(this.props.db.promotions) : []
    );
    return promList.map(prom => prom.promKey);
  };

  onTagClick = tag => {
    let tags = this.state.searchProm?.tags || [];
    if (tags.includes(tag)) {
      tags = tags.filter(val => val != tag);
    } else {
      tags = [...tags, tag];
    }

    this.setState(
      {
        searchProm: {
          ...this.state.searchProm,
          tags: tags.length === 0 ? undefined : tags
        },
        showSearch: true
      },
      this.updateInterface
    );
  };

  handleSearchButton = () => {
    this.setState({
      showSearch: true
    });
  };

  getInterfaceJSX = () => {
    const addDataDisplay = this.props.showRightDrawer;

    const clists = {};
    let promList = [];
    if (this.state.interface == 0) {
      for (const status in this.state.clists) {
        clists[status] = this.filterClist(this.state.clists[status]);
      }
    } else {
      promList = this.filterClist(
        this.props.db.promotions ? Object.values(this.props.db.promotions) : []
      );
    }

    switch (this.state.interface) {
      case 0:
        return (
          <div
            className="centering"
            style={{
              width: "100%",
              overflowX: "auto",
              justifyContent: "flex-start"
            }}>
            <Dbcolumn
              readOnly={this.props.readOnly}
              name="Pending"
              col={0}
              clist={clists.Pending}
              first
              userAccess={this.state.access}
              showRightDrawer={this.props.showRightDrawer}
              openClose={this.props.openClose}
              db={this.props.db}
              sortBy={this.state.sortBy}
              onTagClick={this.onTagClick}
            />
            <Dbcolumn
              readOnly={this.props.readOnly}
              name="Submitted"
              col={1}
              clist={clists.Submitted}
              userAccess={this.state.access}
              showRightDrawer={this.props.showRightDrawer}
              openClose={this.props.openClose}
              db={this.props.db}
              sortBy={this.state.sortBy}
              onTagClick={this.onTagClick}
            />
            <Dbcolumn
              readOnly={this.props.readOnly}
              name="Approved"
              col={2}
              clist={clists.Approved}
              userAccess={this.state.access}
              showRightDrawer={this.props.showRightDrawer}
              openClose={this.props.openClose}
              db={this.props.db}
              sortBy={this.state.sortBy}
              onTagClick={this.onTagClick}
            />
            <Dbcolumn
              readOnly={this.props.readOnly}
              name="Running"
              col={4}
              clist={clists.Running}
              userAccess={this.state.access}
              showRightDrawer={this.props.showRightDrawer}
              openClose={this.props.openClose}
              db={this.props.db}
              sortBy={this.state.sortBy}
              onTagClick={this.onTagClick}
            />
            <Dbcolumn
              readOnly={this.props.readOnly}
              name="Completed"
              col={5}
              clist={clists.Completed}
              last
              userAccess={this.state.access}
              showRightDrawer={this.props.showRightDrawer}
              openClose={this.props.openClose}
              db={this.props.db}
              sortBy={this.state.sortBy}
              onTagClick={this.onTagClick}
            />
          </div>
        );
      case 1:
        const hasPermission = this.state.access != "Viewer";
        return (
          <div>
            <PromList
              readOnly={this.props.readOnly}
              selection={hasPermission}
              showSelectAllCheckbox={hasPermission}
              count={this.state.count}
              handleInfiniteLoad={this.handleInfiniteLoad}
              handleRowSelection={this.handleRowSelection}
              clist={promList}
              addDataDisplay={addDataDisplay}
              openClose={this.props.openClose}
              db={this.props.db}
              height={this.state.tbHeight}
              additionalSearch={this.props.additionalSearch} // null in Planning, for use in Execution and Closed
              promCreationWindowClosed={this.promCreationWindowClosed}
              mode={this.props.mode || 0} // { Planning: 0 , Execution: 1, Closed:2 , Declined:3 , Cancelled:4}
              tableKey={this.state.tableKey}
              allowExpandRows
              modalOpen={this.props.modalOpen}
              onTagClick={this.onTagClick}
            />
          </div>
        );
      case 2:
        return (
          <div>
            <SplitScreen
              leftComponent={
                <div>
                  <ListItem
                    disabled
                    style={{ backgroundColor: grey700, color: white }}>
                    <ListItemText
                      primary="Pending or Declined"
                      style={{ textAlign: "center" }}
                    />
                  </ListItem>
                  <PromList
                    readOnly={this.props.readOnly}
                    showSelectAllCheckbox
                    count={this.state.count}
                    handleInfiniteLoad={this.handleInfiniteLoad}
                    handleRowSelection={this.handleRowSelection}
                    clist={promList}
                    addDataDisplay={addDataDisplay}
                    additionalSearch={{
                      status: [
                        this.props.db.meta.statuses.indexOf("Pending"),
                        this.props.db.meta.statuses.indexOf("Declined")
                      ]
                    }}
                    openClose={this.props.openClose}
                    db={this.props.db}
                    height={this.state.tbHeight - 48}
                    abridged
                    tableKey={this.state.tableKey}
                    allSelected={this.state.selectedProms}
                    promCreationWindowClosed={this.promCreationWindowClosed}
                    modalOpen={this.props.modalOpen}
                  />
                </div>
              }
              rightComponent={
                <div>
                  <ListItem
                    disabled
                    style={{ backgroundColor: grey700, color: white }}>
                    <ListItemText
                      primary="Submitted"
                      style={{ textAlign: "center" }}
                    />
                  </ListItem>
                  <PromList
                    readOnly={this.props.readOnly}
                    selection={false}
                    count={this.state.count}
                    handleInfiniteLoad={this.handleInfiniteLoad}
                    handleRowSelection={this.handleRowSelection}
                    clist={promList}
                    addDataDisplay={addDataDisplay}
                    additionalSearch={{
                      status: this.props.db.meta.statuses.indexOf("Submitted")
                    }}
                    openClose={this.props.openClose}
                    db={this.props.db}
                    height={this.state.tbHeight - 48}
                    abridged
                    tableKey={this.state.tableKey}
                    promCreationWindowClosed={this.promCreationWindowClosed}
                    modalOpen={this.props.modalOpen}
                  />
                </div>
              }
              sideBar={this.state.sideBar}
              height={this.state.ssHeight}
            />
          </div>
        );
      case 3:
        return (
          <div>
            <SplitScreen
              leftComponent={
                <div>
                  <ListItem
                    disabled
                    style={{ backgroundColor: grey700, color: white }}>
                    <ListItemText
                      primary="Submitted"
                      style={{ textAlign: "center" }}
                    />
                  </ListItem>
                  <PromList
                    readOnly={this.props.readOnly}
                    showSelectAllCheckbox
                    count={this.state.count}
                    handleInfiniteLoad={this.handleInfiniteLoad}
                    handleRowSelection={this.handleRowSelection}
                    clist={promList}
                    addDataDisplay={addDataDisplay}
                    additionalSearch={{
                      status: this.props.db.meta.statuses.indexOf("Submitted")
                    }}
                    openClose={this.props.openClose}
                    db={this.props.db}
                    height={this.state.tbHeight - 48}
                    abridged
                    tableKey={this.state.tableKey}
                    allSelected={this.state.selectedProms}
                    promCreationWindowClosed={this.promCreationWindowClosed}
                    modalOpen={this.props.modalOpen}
                  />
                </div>
              }
              rightComponent={
                <div>
                  <ListItem
                    disabled
                    style={{ backgroundColor: grey700, color: white }}>
                    <ListItemText
                      primary="Approved"
                      style={{ textAlign: "center" }}
                    />
                  </ListItem>
                  <PromList
                    readOnly={this.props.readOnly}
                    selection={false}
                    count={this.state.count}
                    handleInfiniteLoad={this.handleInfiniteLoad}
                    handleRowSelection={this.handleRowSelection}
                    clist={promList}
                    addDataDisplay={addDataDisplay}
                    additionalSearch={{
                      status: this.props.db.meta.statuses.indexOf("Approved")
                    }}
                    openClose={this.props.openClose}
                    db={this.props.db}
                    height={this.state.tbHeight - 48}
                    abridged
                    tableKey={this.state.tableKey}
                    promCreationWindowClosed={this.promCreationWindowClosed}
                    modalOpen={this.props.modalOpen}
                  />
                </div>
              }
              sideBar={this.state.sideBar}
              height={this.state.ssHeight}
            />
          </div>
        );
      case 4:
        return (
          <div>
            <SplitScreen
              leftComponent={
                <div>
                  <ListItem
                    disabled
                    style={{ backgroundColor: grey700, color: white }}>
                    <ListItemText
                      primary="All Promotions"
                      style={{ textAlign: "center" }}
                    />
                  </ListItem>
                  <PromList
                    readOnly={this.props.readOnly}
                    showSelectAllCheckbox
                    count={this.state.count}
                    handleInfiniteLoad={this.handleInfiniteLoad}
                    handleRowSelection={this.handleRowSelection}
                    clist={promList}
                    addDataDisplay={addDataDisplay}
                    openClose={this.props.openClose}
                    db={this.props.db}
                    height={this.state.tbHeight - 48}
                    tableKey={this.state.tableKey}
                    abridged
                    promCreationWindowClosed={this.promCreationWindowClosed}
                    modalOpen={this.props.modalOpen}
                  />
                </div>
              }
              rightComponent={
                <div>
                  <ListItem
                    disabled
                    style={{ backgroundColor: grey700, color: white }}>
                    <ListItemText
                      primary="Proposed Promotions"
                      style={{ textAlign: "center" }}
                    />
                  </ListItem>
                  <PromList
                    readOnly={this.props.readOnly}
                    selection={false}
                    count={this.state.count}
                    handleInfiniteLoad={this.handleInfiniteLoad}
                    handleRowSelection={this.handleRowSelection}
                    clist={Object.values(this.state.proposedProms)}
                    proposedProms={this.state.proposedProms}
                    addDataDisplay={addDataDisplay}
                    openClose={this.props.openClose}
                    db={this.props.db}
                    height={this.state.tbHeight - 48 - 37} // accounting for confirm button below
                    tableKey={this.state.tableKey}
                    abridged
                    selectable={false}
                    promCreationWindowClosed={this.promCreationWindowClosed}
                    modalOpen={this.props.modalOpen}
                  />
                  <div className="rowC">
                    <Button
                      label="Cancel"
                      variant="outlined"
                      color="error"
                      sx={{ width: "100%", borderRadius: 0 }}
                      icon={<CancelIcon />}
                      onClick={() => {
                        store.dispatch(reset());
                        this.setState(
                          {
                            proposedProms: {}
                          },
                          this.updateInterface
                        );
                      }}
                      disabled={
                        Object.keys(this.state.proposedProms).length == 0
                      }
                    />
                    <Button
                      label="Confirm"
                      variant="outlined"
                      sx={{ width: "100%", borderRadius: 0 }}
                      icon={<CheckCircleIcon />}
                      onClick={this.handleAdvDupConfirm}
                      disabled={
                        Object.keys(this.state.proposedProms).length == 0
                      }
                    />
                  </div>
                </div>
              }
              sideBar={this.state.sideBar}
              height={this.state.ssHeight}
            />
          </div>
        );
      case 5:
        return (
          <div>
            <SplitScreen
              leftComponent={
                <div>
                  <ListItem
                    disabled
                    style={{ backgroundColor: grey700, color: white }}>
                    <ListItemText
                      primary="Promotions to be Edited"
                      style={{ textAlign: "center" }}
                    />
                  </ListItem>
                  <PromList
                    readOnly={this.props.readOnly}
                    showSelectAllCheckbox
                    count={this.state.count}
                    handleInfiniteLoad={this.handleInfiniteLoad}
                    handleRowSelection={this.handleRowSelection}
                    clist={promList}
                    addDataDisplay={addDataDisplay}
                    additionalSearch={this.state.additionalSearch}
                    openClose={this.props.openClose}
                    db={this.props.db}
                    height={this.state.tbHeight - 48}
                    abridged
                    tableKey={this.state.tableKey}
                    allSelected={this.state.selectedProms}
                    promCreationWindowClosed={this.promCreationWindowClosed}
                    modalOpen={this.props.modalOpen}
                  />
                </div>
              }
              rightComponent={
                <div style={{ height: "100%" }}>
                  <ListItem
                    disabled
                    style={{ backgroundColor: grey700, color: white }}>
                    <ListItemText
                      primary="Find and Replace"
                      style={{ textAlign: "center" }}
                    />
                  </ListItem>
                  <AdvancedEditPromotions
                    readOnly={this.props.readOnly}
                    db={this.props.db}
                    openClose={this.props.openClose}
                    updateInterface={this.updateInterface}
                    handleFind={additionalSearch => {
                      this.setState({ additionalSearch }, this.updateInterface);
                    }}
                    getSelectedProms={() => {
                      return this.state.selectedProms;
                    }}
                  />
                </div>
              }
              sideBar={this.state.sideBar}
              height={this.state.ssHeight}
            />
          </div>
        );
      case 6:
        return (
          <div>
            <Calendar
              db={this.props.db}
              openClose={this.props.openClose}
              clist={promList}
              calendarRef={this.calendarRef}
              filtersRef={this.filtersRef}
            />
          </div>
        );
      default:
        return <div>Please reload</div>;
    }
  };

  getPromLists = meta => {
    const desiredStatuses = [
      "Pending",
      "Submitted",
      "Approved",
      "Finalized",
      "Running",
      "Completed"
    ];

    getPromotionsFirebase((promotions, promKey = null) => {
      if (promKey) {
        var { allProms } = this.state;
        var prom = promotions;
        allProms[promKey] = prom;
      } else {
        var allProms = promotions;
      }
      // create data to populate columns
      const clists = {};
      for (let i = 0; i < desiredStatuses.length; i++) {
        clists[desiredStatuses[i]] = [];
      }
      const planningPromotions = {};

      for (const key in allProms) {
        var prom = { ...allProms[key] };
        const status = meta.statuses[prom.status];

        if (desiredStatuses.indexOf(status) != -1) {
          clists[status].push(prom);
        }

        // compile all planning promotions in an obj
        if (
          ["Pending", "Submitted", "Approved", "Finalized"].indexOf(status) !=
          -1
        ) {
          planningPromotions[key] = prom;
        }
      }

      if (!this.state.statusUpdated) {
        this.setState({ statusUpdated: Date.now() });
      }
      this.clists = clists;
      this.planningPromotions = planningPromotions;
      this.allProms = allProms;
    }, null);
  };

  checkStatusUpdated = () => {
    if (
      this.state.statusUpdated &&
      Date.now() - this.state.statusUpdated > 500
    ) {
      this.setState(
        {
          clists: this.clists,
          planningPromList: this.planningPromotions,
          allProms: this.allProms,
          statusUpdated: null
        },
        this.updateInterface
      );
    }
  };

  static getCanvasRefDimensions(node: React.RefObject<HTMLCanvasElement>) {
    const w = node.current?.getBoundingClientRect().width * 1.4;
    const h = node.current?.getBoundingClientRect().height * 1.4;
    return [w, h];
  }

  exportCalendar = fileType => {
    const backgroundColor = "#ffffff";
    const calendarDimensions = Planning.getCanvasRefDimensions(
      this.calendarRef
    );
    const filterDimensions = Planning.getCanvasRefDimensions(this.filtersRef);
    switch (fileType) {
      case "filters-png":
        exportComponentAsPNG(this.filtersRef, {
          fileName: "Calendar Filters Export",
          html2CanvasOptions: { backgroundColor }
        });
        break;
      case "filters-pdf":
        exportComponentAsPDF(this.filtersRef, {
          fileName: "Calendar Filters Export",
          html2CanvasOptions: { backgroundColor },
          pdfOptions: {
            unit: "px",
            pdfFormat: filterDimensions
          }
        });
        break;
      case "png":
        exportComponentAsPNG(this.calendarRef, {
          fileName: "Calendar Export",
          html2CanvasOptions: { backgroundColor }
        });
        break;
      case "pdf":
        exportComponentAsPDF(this.calendarRef, {
          fileName: "Calendar Export",
          html2CanvasOptions: { backgroundColor },
          pdfOptions: {
            unit: "px",
            pdfFormat: calendarDimensions
          }
        });
        break;
      default:
      // pass
    }
  };

  isCorrectMode = (mode, status) => {
    const modeToStatus = {
      0: [0, 1, 2, 3, 4, 5], // Planning
      1: [4], // Execution
      2: [5], // Closed
      3: [6], // Declined
      4: [7] // Cancelled
    };
    return modeToStatus[mode].includes(status);
  };

  render() {
    let mainJSX = <div ref="table">{this.state.interfaceJSX}</div>;
    if (this.state.interfaceJSX.length === 0) {
      mainJSX = (
        <div
          style={{ height: this.state.colHeight }}
          className="centering"
          ref="table">
          <CircularProgress />
        </div>
      );
    }

    const hasUnconfirmedProms =
      Boolean(this.state.proposedProms) &&
      Boolean(Object.keys(this.state.proposedProms).length);

    const confirmLeavePageDialog = (onConfirm, onCancel) => (
      <Dialog
        dialogTitle="Confirm Leave Page"
        open={hasUnconfirmedProms}
        titleStyle={styles.modalTitle}
        actionsContainerStyle={styles.modalActions}
        contentStyle={styles.dialogContent}
        bodyStyle={styles.dialogBody}
        style={styles.dialogRoot}>
        <div>
          <br />
          <div className="centering">
            Proposed promotions in Advanced Duplication have NOT been confirmed.
            All your changes will be lost. Are you sure you want to continue?
          </div>

          <div className="centering">
            <Button
              label={"Yes, I'm sure"}
              variant="text"
              color="error"
              style={{ color: white, margin: 16 }}
              onClick={onConfirm}
            />
            <Button label="No, go back" variant="text" onClick={onCancel} />
          </div>
        </div>
      </Dialog>
    );

    const hardRefreshRequiredDialog = (onConfirm, onCancel) => (
      <Dialog
        dialogTitle="Hard Refresh Required"
        open={this.props.outOfDate}
        actions={
          <div className="centering">
            <Button
              label="Ok"
              style={{ color: grey700, margin: 16 }}
              onClick={onCancel}
            />
          </div>
        }
        titleStyle={styles.modalTitle}
        actionsContainerStyle={styles.modalActions}
        contentStyle={styles.dialogContent}
        bodyStyle={styles.dialogBody}
        style={styles.dialogRoot}>
        <div className="centering">
          Your version of Vividly is out of date.
          <br />
          Please save your work, then perform a hard refresh by holding Shift
          then clicking the Reload button in your browser.
        </div>
      </Dialog>
    );

    return (
      <div>
        <PromToolbar
          mode={this.props.mode || 0} // 1: Execution, 2: Closed
          handleSelectInterface={this.handleSelectInterface}
          interface={this.state.interface}
          handleSearch={this.handleSearch}
          showRightDrawer={this.props.showRightDrawer}
          showDropDownWindow={this.props.showDropDownWindow}
          setSearchProm={this.setSearchProm}
          searchProm={this.state.searchProm}
          handleSelectCategory={this.handleSelectCategory}
          multi={this.state.multi}
          toggleMulti={this.toggleMulti}
          db={this.props.db}
          readOnly={this.props.readOnly}
          openClose={this.props.openClose}
          selectedProms={this.state.selectedProms}
          clearSelectedProms={this.clearSelectedProms}
          autoDuplicateCallback={this.autoDuplicateCallback}
          promKeyList={this.state.promKeyList}
          promCreationWindowClosed={this.promCreationWindowClosed}
          sortBy={this.state.sortBy}
          handleSortBy={this.handleSortBy}
          proposedProms={this.state.proposedProms}
          exportCalendar={this.exportCalendar}
          showSearch={this.state.showSearch}
          handleSearchButton={this.handleSearchButton}
        />
        {mainJSX}
        <NavigationPrompt when={hasUnconfirmedProms || this.props.outOfDate}>
          {({ onConfirm, onCancel }) =>
            this.props.outOfDate ? (
              hardRefreshRequiredDialog(onConfirm, onCancel)
            ) : hasUnconfirmedProms ? (
              confirmLeavePageDialog(onConfirm, onCancel)
            ) : (
              <></>
            )
          }
        </NavigationPrompt>
      </div>
    );
  }
}

export default Planning;
