import React from "react";

import AddIcon from "@mui/icons-material/Add";
import Chip from "@mui/material/Chip";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import Button from "ui-library/Button";
import IconButton from "ui-library/IconButton";
import MenuItem from "ui-library/MenuItem";
import Select from "ui-library/Select";
import TextField from "ui-library/TextField";

import {
  removeFirebase,
  getMetaFirebase,
  updateMetaFirebase
} from "helpers/Firebase";
import { grey } from "@mui/material/colors";
import { Stack } from "@mui/material";
import DataTable from "../tables/MuiDataTable";

const grey300 = grey["300"];
const grey700 = grey["700"];

const styles = {
  notifs: {
    width: "60%"
  },
  productList: {
    borderColor: grey700,
    borderWidth: 1,
    borderStyle: "solid"
    // overflow: 'auto',
    // height: 300
  },
  moneyIcon: {
    width: 15,
    height: 15
  },
  radioButton: {
    marginBottom: 16
  },
  chip: {
    margin: 4
  },
  checkbox: {
    marginLeft: 4
  },
  wrapper: {
    display: "flex",
    flexWrap: "wrap"
  }
};

class LiftBuckets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newAccount: "",
      newLiftBucket: "",
      accounts: [],
      meta: {}
    };
  }

  handleAddLiftBucket = () => {
    if (this.state.newLiftBucket) {
      const liftBucketNames = this.state.meta.lift_bucket_names || [];
      liftBucketNames.push(this.state.newLiftBucket);
      updateMetaFirebase({ lift_bucket_names: liftBucketNames });
      this.setState({ newPromType: "" });
    }
  };

  handleAddField = (bucket, event, index, value) => {
    // do not let the user add "Select Field"
    if (value === 0) return;

    const liftBuckets = this.state.meta.lift_buckets || {};
    if (bucket in liftBuckets) {
      liftBuckets[bucket].push(value);
    } else {
      liftBuckets[bucket] = [value];
    }
    updateMetaFirebase({ lift_buckets: liftBuckets });
  };

  handleRequestDelete = (bucket, index) => {
    const liftBuckets = this.state.meta.lift_buckets;
    liftBuckets[bucket].splice(index, 1);
    updateMetaFirebase({ lift_buckets: liftBuckets });
  };

  deleteLiftBucket = name => {
    this.props.openClose.setAppModal(
      "Delete Lift Bucket",
      <div>Are you sure you want to delete this lift bucket?</div>,

      <Stack>
        <Button
          label="Yes, I'm sure"
          color="error"
          variant="text"
          onClick={() => {
            this.props.openClose.closeAppModal();
            // make sure no bug removes all the products
            const liftBucketNames = this.state.meta.lift_bucket_names;
            liftBucketNames.splice(liftBucketNames.indexOf(name), 1);
            const update = {};
            update.lift_bucket_names = liftBucketNames;
            update[`lift_buckets/${name}`] = null;
            updateMetaFirebase(update);
            removeFirebase(15, name);
          }}
        />
        <Button
          label="No, go back"
          variant="text"
          onClick={() => {
            this.props.openClose.closeAppModal();
          }}
        />
      </Stack>
    );
  };

  getBucketJSX = () => {
    const bucketNames = this.state.meta.lift_bucket_names || [];
    const fundTypes = this.state.meta.fundTypes ?? {};
    const newPromTypeJSX = [];
    const assignedPromTypesJSX = [];
    const deleteBucketJSX = [];
    const liftBuckets = this.state.meta.lift_buckets || {};
    let bucketAssigned = [];
    if (Object.keys(liftBuckets).length > 0) {
      bucketAssigned = bucketAssigned.concat(...Object.values(liftBuckets));
    }
    const notBucketAssigned = Object.keys(fundTypes).filter(
      x => !fundTypes[x].liftAssigned || !bucketAssigned.includes(x)
    );
    for (var name of bucketNames) {
      const bucketPromTypes = liftBuckets[name] || [];
      newPromTypeJSX.push(
        <Select
          value={0}
          onChange={this.handleAddField.bind(null, name)}
          style={styles.input}>
          <MenuItem value={0} primaryText="Select Field" key="wildcard" />
          {notBucketAssigned.map(key => {
            return (
              <MenuItem value={key} children={fundTypes[key]?.name} key={key} />
            );
          })}
        </Select>
      );
      assignedPromTypesJSX.push(
        bucketPromTypes.length > 0 ? (
          bucketPromTypes.map((field, index) => {
            return (
              <Chip
                onDelete={this.handleRequestDelete.bind(null, name, index)}
                style={styles.chip}
                label={fundTypes[field]?.name}
              />
            );
          })
        ) : (
          <Chip
            style={Object.assign(jQuery.extend(true, {}, styles.chip), {
              backgroundColor: grey300
            })}
            label="None"
          />
        )
      );
      deleteBucketJSX.push(
        <IconButton
          tooltip="Delete Lift Bucket"
          style={{ marginTop: 5 }}
          color="error"
          onClick={this.deleteLiftBucket.bind(null, name)}
          disabled={!this.props.db.permissions.includes("lift")}
          size="large">
          <DeleteIcon />
        </IconButton>
      );
    }

    return [bucketNames, newPromTypeJSX, assignedPromTypesJSX, deleteBucketJSX];
  };

  componentDidMount() {
    getMetaFirebase(metaData => {
      this.setState({ meta: metaData });
    });
  }

  render() {
    const [bucketNames, newPromTypeJSX, assignedPromTypesJSX, deleteBucketJSX] =
      this.getBucketJSX();

    return (
      <div>
        <div style={{ margin: 10 }}>
          Promotion Types in the same bucket have the same assigned lift values.
          Only alphanumeric characters, spaces, and dashes are allowed in bucket
          names.
        </div>
        <br />
        <div style={{ margin: 10 }}>
          <DataTable
            title="Select Fields for each Promotion Type"
            data={{
              Bucket: bucketNames,
              "Add New Promotion Type": newPromTypeJSX,
              "Promotion Types": assignedPromTypesJSX,
              "Delete Bucket": deleteBucketJSX
            }}
            widths={["20%", "30%", "30%", "20%"]}
          />
        </div>
        <div className="rowC">
          <TextField
            floatingLabelText="New Lift Bucket"
            onChange={event => {
              const newLiftBucket = event.target.value.replace(
                /[^a-zA-Z0-9\s-]/g,
                ""
              );
              this.setState({ newLiftBucket });
            }}
            onKeyPress={ev => {
              if (ev.key === "Enter") {
                this.handleAddLiftBucket();
                ev.preventDefault();
                this.setState({ newLiftBucket: "" });
              }
            }}
            value={this.state.newLiftBucket}
            style={{ marginLeft: 10, marginRight: 10, marginTop: -20 }}
          />
          <Button
            icon={<AddIcon />}
            onClick={this.handleAddLiftBucket}
            sx={{
              "& .MuiButton-startIcon": {
                margin: 0
              }
            }}
          />
        </div>
        <div style={{ marginBottom: 20 }} />
      </div>
    );
  }
}

export default LiftBuckets;
