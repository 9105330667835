import React from "react";
import { Drawer as MUIDrawer } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { grey } from "@mui/material/colors";

const grey800 = grey["800"];

const useStyles = makeStyles({
  drawer: {
    width: 900,
    flexShrink: 0
  },
  drawerPaper: {
    width: 900
  },
  sidebarDrawer: {
    width: 280,
    flexShrink: 0
  },
  sidebarDrawerPaper: {
    border: 0,
    width: 280,
    boxShadow:
      "rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px"
  }
});

const Drawer = props => {
  const {
    title,
    actions,
    children,
    titleStyle,
    actionsContainerStyle,
    contentStyle,
    bodyStyle,
    modal
  } = props;
  const drawerProps = { ...props };
  const classes = useStyles();
  return (
    <MUIDrawer
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper
      }}
      {...drawerProps}>
      {children}
    </MUIDrawer>
  );
};

const SidebarDrawer = props => {
  const {
    title,
    actions,
    children,
    titleStyle,
    actionsContainerStyle,
    contentStyle,
    bodyStyle,
    modal
  } = props;
  const drawerProps = { ...props };
  const classes = useStyles();
  return (
    <MUIDrawer
      className={classes.sidebarDrawer}
      classes={{
        paper: classes.sidebarDrawerPaper
      }}
      {...drawerProps}>
      {children}
    </MUIDrawer>
  );
};

export default Drawer;
export { SidebarDrawer };
