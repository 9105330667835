import { resolutionLineTypes } from "components/Deductions/constants/ReconciliationTypes";
import ActivityLogCommentBox, {
  CommentTaggedUser
} from "components/Deductions/DeductionsReconciliation/ActivityLog/ActivityLogCommentBox";
import {
  InvoiceDisplayService,
  InvoiceLineAmountService,
  InvoiceLineDisplayService,
  InvoiceLineProcessingService,
  InvoiceProcessingService,
  ResolveMultipleInvoiceLinesErrorService,
  TransactionDisplayService,
  TransactionProcessingService
} from "components/Deductions/DeductionsReconciliation/services";
import {
  ResolutionActivityTypes,
  ResolutionLineActivityObject,
  ResolutionActivityTypeMap,
  ResolutionActivityInputsMap
} from "components/Deductions/DeductionsReconciliation/types/resolutionLineTypes";
import {
  ERPTransactionObject,
  InvoiceLineObject,
  InvoiceObject,
  ResolutionLineObject
} from "components/Deductions/models";
import React, { useCallback, useMemo, useState } from "react";
import { firebase, generateRandomKey } from "helpers/Firebase";
import { cloneDeep, isUndefined, round } from "lodash";
import { ResolutionLineActivityErrors } from "components/Deductions/DeductionsReconciliation/services/ResolveMultipleInvoiceLinesServices/resolveMultipleInvoiceLinesErrors";
import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Stack,
  styled,
  TextField,
  Typography
} from "@mui/material";
import DollarFormatter from "ui-library/DollarFormatter";
import IconButton from "ui-library/IconButton";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useHistory } from "react-router-dom";
import { DRMFeature } from "components/Deductions/DeductionsReconciliation/redux/DRMSlice";
import {
  DRMEvent,
  DRMEventType
} from "components/Deductions/DeductionsReconciliation/ActivityLog/DRMEvent";
import { AddEventConfig } from "components/Deductions/DeductionsReconciliation/ActivityLog/DRMEventService";
import { FundType, Customer, CompanyUser, Product } from "js/dbTypes";

import {
  deductionTransactionStatuses,
  invoiceStatuses
} from "components/Deductions/constants/ReconciliationStatuses";
import { blankResolutionLine } from "../ResolveMultipleInvoiceLines/redux/ResolveMultipleInvoiceLinesSlice";
import { ResolutionLinesErrorMessageMap } from "../ResolveMultipleInvoiceLines/MultiEditScreen";

export interface AddResolutionLineProps {
  invoiceLineKey: string;
  invoiceKey: string;
  transactionKey: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  allLines: any;
  fundTypes: Record<string, FundType>;
  invoices: Record<string, InvoiceObject>;
  erpTransactions: Record<string, ERPTransactionObject>;
  customers: Record<string, Customer>;
  companyUsers: Record<string, CompanyUser>;
  products: Record<string, Product>;
  addEvent: (
    config: AddEventConfig,
    tags?: Record<string, number[]> | undefined
  ) => Promise<Partial<DRMEvent>>;
  hideRightDrawer: Function;
  initialActivityType?: ResolutionActivityTypes;
  enqueueSnackbar: Function;
}

export interface AddResolutionLineObject {
  amount: number;
  type: ResolutionActivityTypes;
  comment: "";
  taggedUsers: CommentTaggedUser[];
  fundTypeKey: string;
  fundTypeAccountKey: string;
}

const DEFAULT_INPUT_STACK_WIDTH = "50%";
const DEFAULT_INPUT_WIDTH = "360px";
const FULL_WIDTH = "100%";

const InputTextField = styled(TextField)(() => ({
  width: DEFAULT_INPUT_WIDTH
}));

const InputAutocomplete = styled(Autocomplete)(() => ({
  width: DEFAULT_INPUT_WIDTH
}));

const Subheader = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1)
}));

const InputStackContainer = styled(Stack)(() => ({
  height: "360px",
  width: FULL_WIDTH
}));

const InputStack = styled(Stack)(() => ({
  width: DEFAULT_INPUT_STACK_WIDTH
}));

const TooltipButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.grey[600]
}));

export default function AddResolutionLine(props: AddResolutionLineProps) {
  const {
    invoiceLineKey,
    invoiceKey,
    transactionKey,
    allLines,
    companyUsers,
    fundTypes,
    invoices,
    erpTransactions,
    customers,
    products,
    addEvent,
    hideRightDrawer,
    enqueueSnackbar,
    initialActivityType
  } = props;

  const history = useHistory();

  const transaction = erpTransactions[transactionKey];
  const invoice = invoices[invoiceKey];
  const { invoiceLines = {} } = invoice;
  const invoiceLine = invoiceLines[invoiceLineKey];
  const { matchedPromLine = "", suggestedFundType = "" } = invoiceLine;

  const invoiceLineOpenAmount =
    InvoiceLineAmountService.getInvoiceLineOpenAmount(
      invoiceLine,
      invoice,
      invoices,
      erpTransactions
    );
  const matchedPromLineFundType = allLines[matchedPromLine || ""]?.type || "";

  const displayTransaction =
    TransactionDisplayService.processTransactionForDisplay(
      transaction,
      invoices,
      erpTransactions,
      customers,
      companyUsers
    );
  const displayInvoice = InvoiceDisplayService.processInvoiceForDisplay(
    invoice,
    erpTransactions,
    customers,
    companyUsers
  );
  const displayInvoiceLine =
    InvoiceLineDisplayService.processInvoiceLineForDisplay(
      invoice,
      invoiceLine,
      invoiceLineKey,
      fundTypes,
      invoices,
      erpTransactions,
      allLines,
      products,
      customers
    );

  const fundTypeOptions = useMemo(() => {
    return [
      "",
      ...Object.entries(fundTypes)
        .sort((fundTypeEntry1, fundTypeEntry2) =>
          fundTypeEntry1[1].name.localeCompare(fundTypeEntry2[1].name)
        )
        .map(fundTypeEntry => fundTypeEntry[0])
    ];
  }, [fundTypes]);

  const resetActivityLogCommentState = useState<boolean>(false);
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(false);
  const [showActivityErrors, setShowActivityErrors] = useState<boolean>(false);
  const [activityType, setActivityType] = useState<ResolutionActivityTypes>(
    initialActivityType || ResolutionActivityTypes.CLEAR
  );

  /**
   * @param newActivityType: `ResolutionActivityTypes`
   *
   * Returns a subset of fields of a `ResolutionLineObject` that should be reset or autofilled
   * after a user saves a resolution line activity, switches activity types, or first enters the component.
   *
   * @example getAutoFilledInputs(ResolutionActivityTypes.CLEAR) => {
   *            return {
   *              invoiceLineKey: "fdslkjf-fdfkj",
   *              invoiceKey: "CGDH-76895",
   *              transactionKey: "fmdkjfs-7860o",
   *              type: resolutionLineTypes.CLEAR,
   *              amount: 15.78,
   *              fundTypeKey: "Asd8fkfg",
   *              promLine: "2022-FHGK-1"
   *            }
   *          }
   */
  const getAutoFilledInputs: (
    newActivityType: ResolutionActivityTypes,
    updatedInvoiceLineOpenAmount?: number
  ) => Partial<ResolutionLineActivityObject> = useCallback(
    (
      newActivityType: ResolutionActivityTypes,
      updatedInvoiceLineOpenAmount: number
    ) => {
      return {
        invoiceKey,
        invoiceLineKey,
        transactionKey,
        type: ResolutionActivityTypeMap[newActivityType],
        amount: (() => {
          if (
            newActivityType === ResolutionActivityTypes.DISPUTE ||
            newActivityType === ResolutionActivityTypes.SALES_REVIEW
          ) {
            return 0.0;
          }

          if (!isUndefined(updatedInvoiceLineOpenAmount)) {
            return updatedInvoiceLineOpenAmount;
          }

          if (!isUndefined(invoiceLineOpenAmount)) {
            return invoiceLineOpenAmount;
          }
          return 0.0;
        })(),
        promLine: ResolutionActivityTypes.CLEAR ? matchedPromLine || "" : "",
        fundTypeKey: (() => {
          if (newActivityType === ResolutionActivityTypes.CLEAR) {
            return matchedPromLineFundType;
          }
          if (newActivityType === ResolutionActivityTypes.WRITE_OFF) {
            return suggestedFundType;
          }
          return "";
        })()
      };
    },
    [
      invoiceKey,
      invoiceLineKey,
      invoiceLineOpenAmount,
      matchedPromLine,
      matchedPromLineFundType,
      suggestedFundType,
      transactionKey
    ]
  );

  const [addResolutionLineObject, setAddResolutionLineObject] =
    useState<ResolutionLineActivityObject>(
      cloneDeep({
        ...blankResolutionLine,
        ...getAutoFilledInputs(activityType)
      })
    );

  /**
   *
   * Returns an object mapping an error type (`ResolutionLineActivityErrors`) to boolean.
   *
   * @example const activityErrorMessagesMap = {
   *            amountLessThanOrEqualZero: true,
   *            exceedsMaxAmount: false,
   *            missingFundTypeKey: true,
   *            missingFundTypeAccount: true,
   *            missingComment: false,
   *          }
   */
  const showActivityErrorsMap: ResolutionLineActivityErrors = useMemo(() => {
    const { amount, comment, fundTypeKey } = addResolutionLineObject;

    return ResolveMultipleInvoiceLinesErrorService.getResolutionLineActivityErrors(
      activityType,
      {
        ...ResolveMultipleInvoiceLinesErrorService.hasWriteOffFundTypeErrors(
          activityType,
          fundTypeKey,
          fundTypes
        ),
        ...ResolveMultipleInvoiceLinesErrorService.hasCommentErrors(
          comment,
          activityType
        ),
        ...ResolveMultipleInvoiceLinesErrorService.hasActivityAmountErrors(
          amount,
          invoiceLine,
          displayInvoice,
          displayTransaction,
          invoices,
          erpTransactions
        )
      }
    );
  }, [
    addResolutionLineObject,
    invoiceLine,
    displayInvoice,
    displayTransaction,
    invoices,
    erpTransactions,
    fundTypes,
    activityType
  ]);

  const lineErrorsExist = Object.values(showActivityErrorsMap).some(val => val);

  /**
   *
   * Returns an object mapping an input (`keyof ResolutionLineActivityObject`) to an error string.
   *
   * @example const activityErrorMessagesMap = {
   *              amount: "Must be non-zero",
   *              fundTypeKey: "Select fund type",
   *              fundTypeAccount: "",
   *              comment: "Comment is required"
   *          }
   */
  const activityErrorMessagesMap: Partial<
    Record<keyof ResolutionLineActivityObject, string>
  > = useMemo(() => {
    return {
      amount:
        (showActivityErrorsMap.amountLessThanOrEqualZero &&
          ResolutionLinesErrorMessageMap.amountLessThanOrEqualZero) ||
        (showActivityErrorsMap.exceedsMaxInvoiceAmount &&
          ResolutionLinesErrorMessageMap.exceedsMaxInvoiceAmount) ||
        (showActivityErrorsMap.exceedsMaxInvoiceLineAmount &&
          ResolutionLinesErrorMessageMap.exceedsMaxInvoiceLineAmount) ||
        (showActivityErrorsMap.exceedsMaxTransactionAmount &&
          ResolutionLinesErrorMessageMap.exceedsMaxTransactionAmount) ||
        "",
      fundTypeKey:
        (showActivityErrorsMap.missingFundTypeKey &&
          ResolutionLinesErrorMessageMap.missingFundTypeKey) ||
        "",
      fundTypeAccount:
        (showActivityErrorsMap.missingFundTypeAccount &&
          ResolutionLinesErrorMessageMap.missingFundTypeAccount) ||
        "",
      comment:
        (showActivityErrorsMap.missingComment &&
          ResolutionLinesErrorMessageMap.missingComment) ||
        ""
    };
  }, [showActivityErrorsMap]);

  /**
   * Returns a new resolution line based on the current state of the inputs.
   *
   * @example getNewResolutionLine() => {
   *            return {
   *              key: "-fdsj856Fkld",
   *              type: resolutionLineTypes.CLEAR,
   *              invoiceLineKey: "fdslkjf-fdfkj",
   *              invoiceKey: "CGDH-76895",
   *              transactionKey: "fmdkjfs-7860o",
   *              openedUser: "n518fjfksi921",
   *              openedComment: "Clearing to matched promotion",
   *              openedDate: "2022-07-11T22:14:19.683Z",
   *              closedUser: "n518fjfksi921",
   *              closedComment: "Clearing to matched promotion",
   *              closedDate: "2022-07-11T22:14:19.683Z",
   *              fundTypeAccount: "asdlfjk",
   *              fundTypeKey: "Asd8fkfg",
   *              amount: 15.78,
   *              promLine: "2022-FHGK-1"
   *            }
   *          }
   */
  const getNewResolutionLine: () => ResolutionLineObject = useCallback(() => {
    const {
      amount,
      comment,
      fundTypeKey = "",
      promLine
    } = addResolutionLineObject;

    const newDate = new Date().toISOString();
    const userId = firebase.auth().currentUser?.uid || "";

    let resLineKey = "";
    let unique = false;
    while (!unique) {
      resLineKey = generateRandomKey();
      unique = !(resLineKey in (invoice.resolutionLines || {}));
    }

    // Set common values, including non-input values
    const newResolutionLine: ResolutionLineObject = {
      key: resLineKey,
      type: ResolutionActivityTypeMap[activityType],
      invoiceLineKey,
      invoiceKey,
      transactionKey,
      openedUser: userId,
      openedComment: comment,
      openedDate: newDate
    };

    if (activityType === ResolutionActivityTypes.CLEAR) {
      return {
        ...newResolutionLine,
        closedDate: newDate,
        closedUser: userId,
        closedComment: comment,
        fundTypeAccount: fundTypes[fundTypeKey]?.accountKey || "",
        fundTypeKey,
        promLine,
        amount
      };
    }

    if (activityType === ResolutionActivityTypes.WRITE_OFF) {
      return {
        ...newResolutionLine,
        closedDate: newDate,
        closedUser: userId,
        closedComment: comment,
        fundTypeAccount: fundTypes[fundTypeKey]?.writeoffAccountKey || "",
        fundTypeKey,
        amount
      };
    }

    return {
      ...newResolutionLine
    };
  }, [
    activityType,
    addResolutionLineObject,
    invoice.resolutionLines,
    invoiceKey,
    invoiceLineKey,
    transactionKey,
    fundTypes
  ]);

  /**
   * @param newResolutionLine: ResolutionLineObject
   * @param callback: (success: boolean, transactionCleared?: boolean, invoiceCleared?: boolean) => void
   *
   * Saves the new resolution line to the DB and updates the existing invoice line, invoice, and transaction in the DB.
   * Executes `callback()` to trigger downstream events, depending on `success` (true or false) and whether or not
   * the transaction and/or invoice was fully cleared.
   */
  const saveResolutionLine = useCallback(
    (
      newResolutionLine: ResolutionLineObject,
      callback: (
        success: boolean,
        updated?: {
          transaction: ERPTransactionObject;
          invoice: InvoiceObject;
          invoiceLine: InvoiceLineObject;
        }
      ) => void
    ) => {
      const updatedInvoiceLine: InvoiceLineObject = {
        ...invoiceLine,
        resolutionLines: {
          ...(invoiceLine.resolutionLines || {}),
          [newResolutionLine.key]: newResolutionLine.key
        },
        status: InvoiceLineProcessingService.getUpdatedInvoiceLineStatus(
          displayInvoiceLine,
          invoices,
          erpTransactions,
          newResolutionLine
        )
      };

      // update invoice + strip of any display values
      const invoiceWithNewResolutionLine: InvoiceObject = {
        ...invoice,
        resolutionLines: {
          ...(invoice.resolutionLines || {}),
          [newResolutionLine.key]: newResolutionLine
        },
        invoiceLines: {
          ...invoice.invoiceLines,
          [invoiceLine.key]: updatedInvoiceLine
        }
      };

      const updatedInvoice: InvoiceObject = {
        ...invoiceWithNewResolutionLine,
        status: InvoiceProcessingService.getUpdatedInvoiceStatus(
          invoiceWithNewResolutionLine,
          erpTransactions
        )
      };

      // update transaction + strip of any display values
      const updatedTransaction: ERPTransactionObject = {
        ...transaction,
        status: TransactionProcessingService.getUpdatedTransactionStatus(
          transaction,
          erpTransactions,
          {
            ...invoices,
            [invoice.key]: updatedInvoice
          }
        )
      };

      // update all to DB
      InvoiceProcessingService.updateFirebaseInvoice(
        updatedInvoice,
        () => {
          TransactionProcessingService.updateFirebaseTransaction(
            updatedTransaction,
            () => {
              callback(true, {
                transaction: updatedTransaction,
                invoice: updatedInvoice,
                invoiceLine: updatedInvoiceLine
              });
            },
            () => {
              callback(false);
            }
          );
        },
        () => {
          callback(false);
        }
      );
    },
    [
      displayInvoiceLine,
      erpTransactions,
      invoice,
      invoiceLine,
      invoices,
      transaction
    ]
  );

  /**
   *
   * @param newResolutionLine: `ResolutionLineObject`
   *
   * Dispatches a resolution line activity event to the activity log.
   */
  const dispatchResolutionActivityLogEvent = useCallback(
    (
      newResolutionLine: ResolutionLineObject,
      updated: {
        transaction: ERPTransactionObject;
        invoice: InvoiceObject;
        invoiceLine: InvoiceLineObject;
      }
    ) => {
      const { amount, fundTypeKey, fundTypeAccount, openedComment } =
        newResolutionLine;

      const { taggedUsers = [] } = addResolutionLineObject;
      const taggedUsersRecord: Record<string, number[]> = taggedUsers.reduce(
        (some, taggedUser) => {
          const { userId, tagStart, tagEnd } = taggedUser;
          return {
            ...some,
            [userId]: [tagStart, tagEnd]
          };
        },
        {}
      );

      const loggingMetadata = {
        invoiceLineKey,
        invoiceKey,
        transactionKey,
        comment: openedComment
      };

      switch (newResolutionLine.type) {
        case resolutionLineTypes.CLEAR: {
          addEvent(
            {
              type: DRMEventType.INVOICE_LINE_CLEARED,
              metadata: {
                clearedAmount: amount,
                totalInvoiceLineAmount: invoiceLine.amount,
                productGroupKey: invoiceLine.productGroupKey || "",
                productKey: invoiceLine.productKey || "",
                customerKey: invoiceLine.customerKey,
                clearedFundType: fundTypeKey,
                clearedGl: fundTypeAccount || ""
              },
              ...loggingMetadata
            },
            taggedUsersRecord
          );
          break;
        }
        case resolutionLineTypes.WRITE_OFF: {
          addEvent(
            {
              type: DRMEventType.WRITE_OFF,
              metadata: {
                totalInvoiceLineAmount: invoiceLine.amount,
                customerKey: invoiceLine.customerKey,
                productGroupKey: invoiceLine.productGroupKey || "",
                productKey: invoiceLine.productKey || "",
                writeOffAmount: amount,
                writeOffFundType: fundTypeKey || ""
              },
              ...loggingMetadata
            },
            taggedUsersRecord
          );
          break;
        }
        case resolutionLineTypes.DISPUTE: {
          addEvent(
            {
              type: DRMEventType.FLAG_DISPUTE,
              metadata: {
                totalInvoiceLineAmount: invoiceLine.amount,
                customerKey: invoiceLine.customerKey,
                productGroupKey: invoiceLine.productGroupKey || ""
              },
              ...loggingMetadata
            },
            taggedUsersRecord
          );
          break;
        }
        case resolutionLineTypes.SALES_REVIEW: {
          addEvent(
            {
              type: DRMEventType.SET_SALES_REVIEW,
              metadata: {
                customerKey: invoiceLine.customerKey,
                productGroupKey: invoiceLine.productGroupKey || "",
                totalInvoiceLineAmount: invoiceLine.amount
              },
              ...loggingMetadata
            },
            taggedUsersRecord
          );
          break;
        }
        default: {
          break;
        }
      }

      if (
        updated.transaction.status === deductionTransactionStatuses.RESOLVED
      ) {
        addEvent({
          type: DRMEventType.TRANSACTION_CLEARED,
          ...loggingMetadata
        });
      }

      if (updated.invoice.status === invoiceStatuses.RESOLVED) {
        // TBD ???
      }
    },
    [
      addEvent,
      addResolutionLineObject,
      invoiceKey,
      invoiceLine.amount,
      invoiceLine.customerKey,
      invoiceLine.productGroupKey,
      invoiceLine.productKey,
      invoiceLineKey,
      transactionKey
    ]
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Subheader variant="h6">Select Activity Type</Subheader>
      </Grid>
      <Grid item xs={12}>
        <InputStack direction="row" justifyContent="flex-start" spacing={1}>
          <InputTextField
            variant="outlined"
            label="Activity Type"
            select
            value={activityType}
            onChange={event => {
              setActivityType(event.target.value as ResolutionActivityTypes);
              setAddResolutionLineObject({
                ...addResolutionLineObject,
                ...getAutoFilledInputs(
                  event.target.value as ResolutionActivityTypes
                )
              });
            }}>
            {Object.values(ResolutionActivityTypes).map(activity => {
              const optionDisabled =
                ([
                  ResolutionActivityTypes.DISPUTE,
                  ResolutionActivityTypes.SALES_REVIEW
                ].includes(activity) &&
                  invoiceLineOpenAmount === 0) ||
                (!matchedPromLine &&
                  activity === ResolutionActivityTypes.CLEAR);
              return (
                <MenuItem
                  value={activity}
                  key={activity}
                  disabled={optionDisabled}>
                  {activity}
                </MenuItem>
              );
            })}
          </InputTextField>
        </InputStack>
      </Grid>
      <Grid item xs={12}>
        <Subheader variant="h6">Enter Information</Subheader>
      </Grid>
      <Grid item xs={12}>
        <InputStackContainer
          direction="column"
          justifyContent="flex-start"
          alignItems="self-start"
          spacing={2}>
          {ResolutionActivityInputsMap[activityType].includes("amount") && (
            <InputStack
              direction="row"
              justifyContent="flex-start"
              alignItems="start"
              spacing={1}>
              <InputTextField
                label="Amount"
                variant="outlined"
                value={addResolutionLineObject.amount}
                disabled={
                  activityType === ResolutionActivityTypes.CLEAR &&
                  !matchedPromLine
                }
                onChange={event =>
                  setAddResolutionLineObject({
                    ...addResolutionLineObject,
                    amount: round(parseFloat(event.target.value as string), 2)
                  })
                }
                helperText={
                  showActivityErrors ? activityErrorMessagesMap.amount : null
                }
                error={Boolean(
                  showActivityErrors &&
                    (showActivityErrorsMap.amountLessThanOrEqualZero ||
                      showActivityErrorsMap.exceedsMaxInvoiceAmount ||
                      showActivityErrorsMap.exceedsMaxInvoiceLineAmount ||
                      showActivityErrorsMap.exceedsMaxTransactionAmount)
                )}
                InputLabelProps={{
                  shrink: true
                }}
                name="dollarformat"
                id="formatted-dollarformat-input"
                InputProps={{
                  inputComponent: DollarFormatter,
                  inputProps: { showNegative: false }
                }}
              />
              <TooltipButton
                disabled
                tooltip="Must be between zero and the open amount"
                size="large">
                <HelpOutlineIcon />
              </TooltipButton>
            </InputStack>
          )}
          {ResolutionActivityInputsMap[activityType].includes("promLine") && (
            <InputStack
              direction="row"
              justifyContent="flex-start"
              alignItems="start"
              spacing={1}>
              <InputTextField
                disabled
                variant="outlined"
                label="Promotion Line"
                value={addResolutionLineObject.promLine || "None"}
              />
              <TooltipButton
                onClick={() => {
                  hideRightDrawer();
                  history.push(
                    `/${DRMFeature.RECONCILIATION}/invoice/${invoice.key}`
                  );
                }}
                tooltip="Edit match in invoice profile"
                size="large">
                <HelpOutlineIcon />
              </TooltipButton>
            </InputStack>
          )}
          {ResolutionActivityInputsMap[activityType].includes(
            "fundTypeKey"
          ) && (
            <InputStack
              direction="row"
              justifyContent="flex-start"
              alignItems="start"
              spacing={1}>
              <InputAutocomplete
                id="fund-type"
                value={addResolutionLineObject.fundTypeKey}
                disabled={activityType === ResolutionActivityTypes.CLEAR}
                options={fundTypeOptions}
                getOptionDisabled={(option: string) =>
                  (activityType === ResolutionActivityTypes.CLEAR &&
                    !fundTypes?.[option]?.accountKey) ||
                  (activityType === ResolutionActivityTypes.WRITE_OFF &&
                    !fundTypes?.[option]?.writeoffAccountKey)
                }
                getOptionLabel={(option: string) =>
                  fundTypes?.[option]?.name || "None"
                }
                onChange={(_event, value) => {
                  setAddResolutionLineObject({
                    ...addResolutionLineObject,
                    fundTypeKey: (value as string) || ""
                  });
                }}
                renderInput={params => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    label="Fund Type"
                    variant="outlined"
                    helperText={
                      showActivityErrors && activityErrorMessagesMap.fundTypeKey
                    }
                    error={Boolean(
                      showActivityErrors &&
                        showActivityErrorsMap.missingFundTypeKey
                    )}
                  />
                )}
              />
              <TooltipButton
                disabled
                tooltip="Edit/view fund type mappings in Account Assignment"
                size="large">
                <HelpOutlineIcon />
              </TooltipButton>
            </InputStack>
          )}
          {ResolutionActivityInputsMap[activityType].includes("comment") && (
            <InputStack
              direction="row"
              justifyContent="flex-start"
              alignItems="start"
              spacing={1}>
              <FormControl sx={{ width: DEFAULT_INPUT_WIDTH }}>
                <ActivityLogCommentBox
                  inputOnly
                  addEvent={addEvent}
                  companyUsers={companyUsers}
                  initialValue={addResolutionLineObject.comment}
                  resetState={resetActivityLogCommentState}
                  onCommentChange={(
                    comment: string,
                    tags: CommentTaggedUser[]
                  ) => {
                    setAddResolutionLineObject({
                      ...addResolutionLineObject,
                      taggedUsers: tags,
                      comment
                    });
                  }}
                  TextFieldProps={{
                    sx: { width: FULL_WIDTH },
                    rowsMax: 3,
                    helperText:
                      showActivityErrors && activityErrorMessagesMap.comment,
                    error:
                      showActivityErrors && showActivityErrorsMap.missingComment
                  }}
                />
              </FormControl>
              <TooltipButton disabled tooltip="Cannot be empty" size="large">
                <HelpOutlineIcon />
              </TooltipButton>
            </InputStack>
          )}
        </InputStackContainer>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="inherit"
          size="medium"
          disabled={disabledSubmit}
          onClick={e => {
            e.stopPropagation();
            if (lineErrorsExist) {
              setShowActivityErrors(true);
            } else {
              setShowActivityErrors(false);
              setDisabledSubmit(true);
              const newResolutionLine = getNewResolutionLine();
              saveResolutionLine(
                newResolutionLine,
                (
                  success: boolean,
                  updated: {
                    transaction: ERPTransactionObject;
                    invoice: InvoiceObject;
                    invoiceLine: InvoiceLineObject;
                  }
                ) => {
                  if (success) {
                    const updatedInvoiceLineOpenAmount =
                      InvoiceLineAmountService.getInvoiceLineOpenAmount(
                        updated.invoiceLine,
                        updated.invoice,
                        {
                          ...invoices,
                          [invoiceKey]: updated.invoice
                        },
                        {
                          ...erpTransactions,
                          [transactionKey]: updated.transaction
                        }
                      );

                    dispatchResolutionActivityLogEvent(
                      newResolutionLine,
                      updated
                    );
                    setAddResolutionLineObject(
                      cloneDeep({
                        ...blankResolutionLine,
                        ...getAutoFilledInputs(
                          activityType,
                          updatedInvoiceLineOpenAmount
                        ),
                        comment: "",
                        taggedUsers: []
                      })
                    );
                    resetActivityLogCommentState[1](true);
                  } else {
                    enqueueSnackbar(`Error with resolving invoice line`, {
                      variant: "error",
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right"
                      }
                    });
                  }
                  setDisabledSubmit(false);
                }
              );
            }
          }}>
          Submit
        </Button>
      </Grid>
    </Grid>
  );
}
