import {
  deductionTransactionStatuses,
  repaymentTransactionStatuses
} from "components/Deductions/constants/ReconciliationStatuses";
import { ERPTransactionTypes } from "components/Deductions/constants/ReconciliationTypes";
import {
  TransactionAmountService,
  TransactionDisplayService,
  TransactionProcessingService
} from "components/Deductions/DeductionsReconciliation/services";
import { DisplayERPTransactionObject } from "components/Deductions/DeductionsReconciliation/types/transactionTypes";
import { ERPTransactionObject } from "components/Deductions/models";
import { useDb } from "contexts/Db";
import { Button, Checkbox, Grid, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import CollapsibleTable, {
  ColumnConfigOptions
} from "ui-library/CollapsibleTable/CollapsibleTable";
import { displayUSCurrency } from "helpers/DataProcessing";
import moment from "moment";
import { useOpenClose } from "contexts/OpenClose";
import { useHistory } from "react-router-dom";
import { DRMFeature } from "components/Deductions/DeductionsReconciliation/redux/DRMSlice";
import { useDRMEvents } from "contexts/DRMEvents";
import LinkedInvoicesTable from "../LinkInvoice/LinkedInvoicesTable";

const EMPTY_FUNCTION = () => {};

const useStyles = makeStyles((theme: Theme) => ({
  checkbox: {
    color: theme.palette.grey[600]
  },
  tableContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  buttonContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
}));

export interface AttachTransactionProps {
  transactionKey: string;
}

// Allow users to attach a repayment to a deduction or vice versa!
export default function AttachTransaction(props: AttachTransactionProps) {
  const { transactionKey } = props;

  const classes = useStyles();

  const { addEvent } = useDRMEvents();
  const db = useDb();
  const {
    erpTransactions = {},
    invoices = {},
    customers = {},
    companyUsers = {}
  } = db;

  const openClose = useOpenClose();
  const history = useHistory();

  const [selectedDeductions, setSelectedDeductions] = useState<string[]>([]);
  const [selectedRepayments, setSelectedRepayments] = useState<string[]>([]);

  const transaction: ERPTransactionObject = useMemo(() => {
    return erpTransactions[transactionKey] || undefined;
  }, [transactionKey, erpTransactions]);

  const transactionType: ERPTransactionTypes = useMemo(() => {
    return transaction.type;
  }, [transaction]);

  useEffect(() => {
    if (transactionType === ERPTransactionTypes.DEDUCTION) {
      setSelectedDeductions([transactionKey]);
      setSelectedRepayments([]);
    } else if (transactionType === ERPTransactionTypes.REPAYMENT) {
      setSelectedRepayments([transactionKey]);
      setSelectedDeductions([]);
    }
  }, [transactionKey, transactionType]);

  const handleUpdateCheckbox = useCallback(
    (event, key: string) => {
      if (transactionType === ERPTransactionTypes.REPAYMENT) {
        if (event.target.checked) {
          setSelectedDeductions([...selectedDeductions, key]);
        } else {
          setSelectedDeductions(
            selectedDeductions.filter(deductionKey => deductionKey !== key)
          );
        }
      }
      if (transactionType === ERPTransactionTypes.DEDUCTION) {
        if (event.target.checked) {
          setSelectedRepayments([...selectedRepayments, key]);
        } else {
          setSelectedRepayments(
            selectedRepayments.filter(repaymentKey => repaymentKey !== key)
          );
        }
      }
    },
    [transactionType, selectedDeductions, selectedRepayments]
  );

  const availableTransactions: ERPTransactionObject[] = useMemo(() => {
    return Object.values(erpTransactions)
      .filter(
        otherTransaction =>
          // Filter out cancelled transactions
          otherTransaction.status !== deductionTransactionStatuses.CANCELLED &&
          otherTransaction.status !== repaymentTransactionStatuses.CANCELLED
      )
      .filter(otherTransaction =>
        // Filter for only transactions of the opposite type
        transactionType === ERPTransactionTypes.DEDUCTION
          ? otherTransaction.type === ERPTransactionTypes.REPAYMENT
          : otherTransaction.type === ERPTransactionTypes.DEDUCTION
      )
      .filter(otherTransaction => {
        // Filter out any repayments or deductions already attached
        return (
          !Object.values(transaction.attachedRepayments || {}).includes(
            otherTransaction.key
          ) &&
          !Object.values(transaction.repaidDeductions || {}).includes(
            otherTransaction.key
          )
        );
      })
      .filter(otherTransaction => {
        if (otherTransaction.type === ERPTransactionTypes.DEDUCTION) {
          return (
            TransactionAmountService.getTransactionOpenDisputeAmount(
              otherTransaction,
              invoices,
              erpTransactions
            ) > 0.0 ||
            TransactionAmountService.getTransactionOpenWriteOffAmount(
              otherTransaction,
              invoices,
              erpTransactions
            ) > 0.0
          );
        }

        if (otherTransaction.type === ERPTransactionTypes.REPAYMENT) {
          return (
            TransactionAmountService.getTransactionOpenAmount(
              otherTransaction,
              invoices,
              erpTransactions
            ) > 0.0
          );
        }

        return false;
      });
  }, [
    erpTransactions,
    transactionType,
    transaction.attachedRepayments,
    transaction.repaidDeductions,
    invoices
  ]);

  const displayAvailableTransactions: DisplayERPTransactionObject[] =
    useMemo(() => {
      return availableTransactions
        .map(transactionOption =>
          TransactionDisplayService.processTransactionForDisplay(
            transactionOption,
            invoices,
            erpTransactions,
            customers,
            companyUsers
          )
        )
        .sort((otherTransaction1, otherTransaction2) =>
          moment(otherTransaction1.transactionDate).diff(
            moment(otherTransaction2.transactionDate)
          )
        );
    }, [
      availableTransactions,
      invoices,
      erpTransactions,
      customers,
      companyUsers
    ]);

  const handleSave = useCallback(() => {
    TransactionProcessingService.attachRepaymentsToDeductions(
      selectedRepayments,
      selectedDeductions,
      db,
      addEvent,
      () => {
        openClose.closeAppModal();
      }
    );
  }, [selectedRepayments, selectedDeductions, db, addEvent, openClose]);

  const columnConfig = useMemo(
    () =>
      new Map([
        [
          "key",
          {
            align: "center",
            name: "Select",
            render: otherTransactionKey => (
              <Checkbox
                value={
                  selectedRepayments.includes(otherTransactionKey as string) ||
                  selectedDeductions.includes(otherTransactionKey as string)
                }
                onChange={event =>
                  handleUpdateCheckbox(event, otherTransactionKey as string)
                }
                className={classes.checkbox}
              />
            )
          }
        ],
        [
          "transactionDisplayId",
          {
            name: "Transaction ID",
            align: "left"
          }
        ],
        [
          "customerName",
          {
            align: "right",
            name: "Customer"
          }
        ],
        [
          "amount",
          {
            align: "right",
            name: "Original Amount",
            render: val => displayUSCurrency(val)
          }
        ],
        [
          "openAmount",
          {
            align: "right",
            name:
              transactionType === ERPTransactionTypes.REPAYMENT
                ? "Open Amount"
                : "Available Amount",
            render: val => displayUSCurrency(val)
          }
        ],
        ...(transactionType === ERPTransactionTypes.REPAYMENT
          ? [
              [
                "openDisputeAmount",
                {
                  align: "right",
                  name: "Open Dispute",
                  render: val => displayUSCurrency(val)
                }
              ],
              [
                "openWriteOffAmount",
                {
                  align: "right",
                  name: "Open Write-Off",
                  render: val => displayUSCurrency(val)
                }
              ]
            ]
          : []),
        [
          "transactionDate",
          {
            align: "right",
            name: "Transaction Date",
            render: val => moment(val).format("MM/DD/YYYY")
          }
        ],
        [
          "status",
          {
            align: "right",
            name: "Status",
            render: val =>
              TransactionDisplayService.HumanReadableTransactionStatuses[
                val as string
              ]
          }
        ]
      ] as [keyof DisplayERPTransactionObject, ColumnConfigOptions<DisplayERPTransactionObject>][]),
    [
      classes.checkbox,
      selectedRepayments,
      selectedDeductions,
      handleUpdateCheckbox,
      transactionType
    ]
  );

  const displayAvailableTransactionType =
    transactionType === ERPTransactionTypes.REPAYMENT
      ? TransactionDisplayService.HumanReadableTransactionTypes[
          ERPTransactionTypes.DEDUCTION
        ]
      : TransactionDisplayService.HumanReadableTransactionTypes[
          ERPTransactionTypes.REPAYMENT
        ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography gutterBottom component="div" variant="h6">
          {`Available ${displayAvailableTransactionType}s (${
            displayAvailableTransactions.length || "0"
          })`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {transactionType === ERPTransactionTypes.REPAYMENT &&
          "Deductions with open write-offs or disputes may be attached."}
        {transactionType === ERPTransactionTypes.DEDUCTION &&
          "Repayments with any available amount may be attached."}
      </Grid>
      <Grid item xs={12} className={classes.tableContainer}>
        {transactionType === ERPTransactionTypes.REPAYMENT ? (
          <CollapsibleTable<DisplayERPTransactionObject>
            data={displayAvailableTransactions}
            expandProfile={(
              displayTransaction: DisplayERPTransactionObject
            ) => {
              history.push(
                `/${DRMFeature.RECONCILIATION}/transaction/${displayTransaction.key}`
              );
              openClose.closeAppModal();
            }}
            renderCollapse={(
              displayTransaction: DisplayERPTransactionObject
            ) => {
              return (
                <>
                  <Typography gutterBottom component="div">
                    Linked Invoices (
                    {
                      Object.values(displayTransaction.linkedInvoices || {})
                        .length
                    }
                    )
                  </Typography>
                  <LinkedInvoicesTable
                    transactionKey={displayTransaction.key}
                    readOnly
                  />
                </>
              );
            }}
            columnConfig={columnConfig}
            index="key"
            onRowClick={EMPTY_FUNCTION}
          />
        ) : (
          <CollapsibleTable<DisplayERPTransactionObject>
            data={displayAvailableTransactions}
            expandProfile={(
              displayTransaction: DisplayERPTransactionObject
            ) => {
              history.push(
                `/${DRMFeature.RECONCILIATION}/transaction/${displayTransaction.key}`
              );
              openClose.closeAppModal();
            }}
            columnConfig={columnConfig}
            index="key"
            onRowClick={EMPTY_FUNCTION}
          />
        )}
      </Grid>
      <Grid item xs={12} className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="inherit"
          size="medium"
          onClick={() => {
            setSelectedDeductions([]);
            setSelectedRepayments([]);
            openClose.closeAppModal();
          }}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="inherit"
          size="medium"
          disabled={
            (transactionType === ERPTransactionTypes.REPAYMENT &&
              selectedDeductions.length === 0) ||
            (transactionType === ERPTransactionTypes.DEDUCTION &&
              selectedRepayments.length === 0)
          }
          onClick={handleSave}>
          Save Selection
        </Button>
      </Grid>
    </Grid>
  );
}
