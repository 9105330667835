/* eslint-disable import/prefer-default-export */
import { DbContextValues } from "contexts/Db";
import { resolutionLineTypes } from "deductions-constants/ReconciliationTypes";
import { ResolutionLineObject } from "deductions-models/index";
import {
  DisplayResolutionLineObject,
  ResolutionActivityTypes
} from "reconciliation-types/resolutionLineTypes";

export const HumanReadableResolutionLineTypes: Record<
  resolutionLineTypes,
  string
> = {
  [resolutionLineTypes.CLEAR]: "Clear",
  [resolutionLineTypes.DISPUTE]: "Dispute",
  [resolutionLineTypes.SALES_REVIEW]: "Sales Review",
  [resolutionLineTypes.WRITE_OFF]: "Write Off"
};

export const ActivityTypeToResolutionLineTypes: Record<
  ResolutionActivityTypes,
  resolutionLineTypes
> = {
  [ResolutionActivityTypes.CLEAR]: resolutionLineTypes.CLEAR,
  [ResolutionActivityTypes.DISPUTE]: resolutionLineTypes.DISPUTE,
  [ResolutionActivityTypes.SALES_REVIEW]: resolutionLineTypes.SALES_REVIEW,
  [ResolutionActivityTypes.WRITE_OFF]: resolutionLineTypes.WRITE_OFF
};

export const processResolutionLineForDisplay = (
  resolutionLine: ResolutionLineObject,
  db: DbContextValues
): DisplayResolutionLineObject => {
  const { meta = {}, invoices = {}, customers = {}, products = {} } = db;
  const { fundTypes = {} } = meta;

  const { invoiceKey, invoiceLineKey } = resolutionLine;
  const invoice = invoices[invoiceKey];
  const invoiceLines = invoice.invoiceLines || {};
  const invoiceLine = invoiceLines[invoiceLineKey] || undefined;
  const { customerKey = undefined, productKey = undefined } = invoiceLine;

  return {
    ...resolutionLine,
    fundTypeName: fundTypes[resolutionLine.fundTypeKey || ""]?.name || "",
    displayType: HumanReadableResolutionLineTypes[resolutionLine.type],
    displayInvoiceNumber: invoice.invoiceNumber,
    displayCustomer: customers[customerKey || ""]?.name || "",
    displayProduct: products[productKey || ""]?.name || ""
  };
};
