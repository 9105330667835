import React from "react";
import {
  Button as MUIButton,
  Tooltip as MUITooltip,
  ButtonProps
} from "@mui/material";
import { styled } from "@mui/material/styles";

const TEXT_BUTTON_PADDING_MAPPING = {
  small: "4px 10.5px",
  medium: "6px 12px",
  large: "8px 12px"
};

const BUTTON_PADDING_MAPPING = {
  small: "4px 21px",
  medium: "6px 24px",
  large: "8px 24px"
};

const getPaddingValues = (size, variant) => {
  if (variant === "text") {
    return TEXT_BUTTON_PADDING_MAPPING[size];
  }
  return BUTTON_PADDING_MAPPING[size];
};

const StyledButton = styled(MUIButton)(({ size, variant }) => ({
  padding: getPaddingValues(size, variant)
}));

type ButtonPropTypes = ButtonProps & {
  tooltip?: string;
  label: string | JSX.Element;
  labelPosition?: string;
  icon?: React.ReactNode;
  style?: Record<string, string | number>;
};

const Button = (props: ButtonPropTypes) => {
  const {
    tooltip,
    label,
    labelPosition,
    icon,
    size = "medium",
    color,
    variant = "contained",
    style,
    disableElevation = true,
    ...rest
  } = props;
  const iconProps: { [name: string]: React.ReactNode | undefined } = {
    endIcon: undefined,
    startIcon: undefined
  };
  if (labelPosition === "before" && icon) {
    iconProps.endIcon = icon;
  } else if (icon) {
    iconProps.startIcon = icon;
  }

  return tooltip ? (
    <MUITooltip title={tooltip}>
      <span>
        <StyledButton
          variant={variant}
          disableElevation={disableElevation}
          color={color}
          size={size}
          {...iconProps}
          {...rest}>
          {label}
        </StyledButton>
      </span>
    </MUITooltip>
  ) : (
    <StyledButton
      variant={variant}
      disableElevation={disableElevation}
      color={color}
      size={size}
      {...iconProps}
      {...rest}>
      {label}
    </StyledButton>
  );
};

export default Button;
