import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// todo define types and initialState for reducer
interface ERPState {
  activeFilters: ERPFilterState;
  sortState: ERPSortState;
}

export interface ERPFilterState {
  transactionDisplayId?: ERPTextColumn;
  customerName?: ERPTextColumn;
  invoiceNumber?: ERPTextColumn;
  transactionDate?: ERPDateColumn;
  amount?: ERPAmountColumn;
  checkNumber?: ERPTextColumn;
  type?: ERPTextColumn;
  status?: ERPTextColumn;
}

interface ERPTextColumn {
  value?: string;
}

interface ERPDateColumn {
  max?: Date;
  min?: Date;
}

interface ERPAmountColumn {
  max?: number;
  min?: number;
}

export interface ERPSortState {
  orderBy?: string;
  sortDir?: "asc" | "desc";
}

const initialState: ERPState = {
  activeFilters: {},
  sortState: {
    orderBy: "transactionDate",
    sortDir: "asc"
  }
};

const slice = createSlice({
  name: "ERPTable",
  initialState,
  reducers: {
    setERPActiveFilters(
      state: ERPState,
      action: PayloadAction<ERPFilterState>
    ) {
      state.activeFilters = action.payload;
    },
    setERPSorting(state: ERPState, action: PayloadAction<ERPSortState>) {
      state.sortState = action.payload;
    }
  }
});

// export redux action
export const { setERPActiveFilters, setERPSorting } = slice.actions; // todo, actions

// export reducer as default import
export default slice.reducer;
