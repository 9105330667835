import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Link from "ui-library/Link";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {
  isUserCresicorEmployee,
  getFirebaseConsoleURL,
  firebase
} from "helpers/Firebase";
import { useDb } from "contexts/Db";
import { StyledMenu } from "./StyledComponents";

type CustomerAvatarProps = {
  handleLogout: () => {};
};

export default function CustomerAvatar({ handleLogout }: CustomerAvatarProps) {
  const [openCustomerMenu, setOpenCustomerMenu] = useState(false);
  const [userDetails, setUserDetails] = useState({
    userName: "",
    userEmail: "",
    userPhoto: "",
    companyNames: ""
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const db = useDb();

  const firebaseUrl = `${getFirebaseConsoleURL()}/companies/${db.companyid}`;

  const handleOpen = event => {
    setAnchorEl(event.currentTarget);
    setOpenCustomerMenu(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenCustomerMenu(false);
  };

  const signOut = () => {
    firebase
      .auth()
      .signOut()
      .then(
        () => {
          handleLogout();
        },
        error => {
          // Nothing
        }
      );
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        firebase
          .database()
          .ref(`users/${user.uid}`)
          .on("value", snapshot => {
            const storedUser = snapshot.val();
            setUserDetails(prevState => {
              const newState = {
                ...prevState,
                userId: user.uid,
                userName:
                  user.displayName ||
                  (storedUser ? storedUser.displayName : "Unnamed"),
                userPhoto: user.photoURL,
                userEmail: user.email,
                userCompany: storedUser ? storedUser.company : "None"
              };
              return newState;
            });
          });
        firebase
          .database()
          .ref("companyNames")
          .on("value", snapshot => {
            const companyNames = snapshot.val();
            setUserDetails(prevState => {
              const newState = {
                ...prevState,
                companyNames
              };
              return newState;
            });
          });
      }
    });
  }, []);

  return (
    <div>
      <div>
        <Avatar
          sx={{
            borderRadius: 2,
            bgcolor: "primary.main",
            "&:hover": { cursor: "pointer", bgcolor: "primary.onhover" }
          }}
          onClick={event => handleOpen(event)}
        />
      </div>
      <StyledMenu
        anchorEl={anchorEl}
        open={openCustomerMenu}
        onClose={() => handleClose()}
        disableAutoFocusItem>
        <MenuItem>
          <Typography variant="subtitle1">{userDetails.userName}</Typography>
        </MenuItem>
        <MenuItem>
          {isUserCresicorEmployee() ? (
            <Link
              variant="subtitle2"
              component="a"
              href={firebaseUrl}
              target="_blank">
              {userDetails.companyNames[userDetails.userCompany]}
            </Link>
          ) : (
            <Typography variant="subtitle2">
              {userDetails.companyNames[userDetails.userCompany]}
            </Typography>
          )}
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={handleClose}
          component={Link}
          variant="labelMedium"
          to="/settings">
          <Typography variant="labelMedium">Account Settings</Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={signOut}>
          <Typography variant="labelMedium">Logout</Typography>
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
