/* eslint-disable max-classes-per-file */
import React from "react";
import axios from "axios";
import { Divider } from "pui-react-dividers";
import validator from "validator";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import SettingsIcon from "@mui/icons-material/Settings";
import DownloadIcon from "@mui/icons-material/GetApp";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CancelIcon from "@mui/icons-material/Cancel";
import { CSVLink } from "react-csv";
import Button from "ui-library/Button";
import IconMenu from "ui-library/IconMenu";
import Select from "ui-library/Select";
import MenuItem from "ui-library/MenuItem";
import TextField from "ui-library/TextField";
import IconButton from "ui-library/IconButton";
import Checkbox from "ui-library/Checkbox";
import { arrayify, escapeQuotes } from "helpers/DataProcessing";
import {
  addNewCompany,
  removeCompanyFromUser,
  firebase,
  cloudRunFunctionURL,
  COMPANY_EMAIL_DOMAINS
} from "helpers/Firebase";
import DatePicker from "ui-library/DatePicker";
import { httpsCallable } from "helpers/httpsCallable";
import { grey } from "@mui/material/colors";
import { Stack } from "@mui/material";
import DataTable from "../../tables/MuiDataTable";

const grey700 = grey["700"];

const styles = {
  notifs: {
    width: "60%"
  },
  superheader: {
    fontFamily: "Ubuntu",
    marginTop: 2,
    padding: 0,
    color: "primary.main"
  },
  subheader: {
    fontSize: 20,
    color: "primary.main",
    lineHeight: "48px"
  },
  productList: {
    borderColor: grey700,
    borderWidth: 1,
    borderStyle: "solid"
    // overflow: 'auto',
    // height: 300
  },
  moneyIcon: {
    width: 15,
    height: 15
  },
  radioButton: {
    marginBottom: 16
  },
  chip: {
    margin: "16px 8px 16px 0px"
  },
  wrapper: {
    display: "flex",
    flexWrap: "wrap"
  },
  saveButton: {
    alignSelf: "flex-end",
    marginTop: 0,
    marginRight: 0
  },
  settingsButton: {
    float: "right",
    marginTop: "-36px"
  }
};

const MAX_BROKERS = 0;

class PropDataUpdatedCSVLink extends CSVLink {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data, headers, separator, uFEFF } = nextProps;
    this.setState({ href: this.buildURI(data, uFEFF, headers, separator) });
  }
}

class ManageCompanies extends React.Component {
  constructor(props) {
    super(props);
    this.csvLink = React.createRef();
    this.state = {
      newCompany: "",
      companyNames: {},
      companyUsers: {},
      selectedCompany: "",
      selectedCompanyMeta: {},
      userData: {},
      emailToUid: {},
      newUserName: "",
      newUserEmail: "",
      newUserAccess: "Viewer",
      newUserNameErrorText: "",
      newUserNameEmailText: "",
      downloadData: [],
      trialStatus: {},
      headers: [
        { label: "Name", key: "name" },
        { label: "Email", key: "email" },
        { label: "Company", key: "company" },
        { label: "Company ID", key: "cid" },
        { label: "Access", key: "access" }
      ],
      allCompanies: [],
      referralEmails: [],
      referralStatus: [],
      referralTimestamps: []
    };
  }

  selectCompanySoftwarePlatform = (field, event, isInputChecked) => {
    if (this.state.selectedCompany && this.state.companyNames) {
      const companyId = this.state.selectedCompany;
      const update = {};
      update[field] = isInputChecked;
      if (field == "broker_commissions" && isInputChecked) {
        update.max_brokers = MAX_BROKERS;
      }
      firebase.database().ref(`companies/${companyId}/meta`).update(update);
      this.props.db.adminPanelActivityLogService.addEvent(
        `Set software platform ${field} to ${isInputChecked}`
      );
    }
  };

  setCompanyFeatureGates = (field, event, isInputChecked) => {
    if (this.state.selectedCompany && this.state.companyNames) {
      const companyId = this.state.selectedCompany;
      const update = {};
      update[field] = isInputChecked;
      firebase
        .database()
        .ref(`companies/${companyId}/meta/featureGates`)
        .update(update);
      this.props.db.adminPanelActivityLogService.addEvent(
        `Set feature gate ${field} to ${isInputChecked}`
      );
    }
  };

  changeFreeTrial = (keyToUpdate, value) => {
    const companyId = this.state.selectedCompany;
    let trialStatus = {};
    const defaultTrialStatus = {
      on_trial: true,
      special_trial: false,
      pages_in: 0,
      start_date: new Date().toDateString()
    };

    if (this.state.trialStatus) {
      trialStatus = this.state.trialStatus;
    } else {
      trialStatus = defaultTrialStatus;
    }

    trialStatus[keyToUpdate] = value;
    this.setState({ trialStatus });
    firebase
      .database()
      .ref(`companies/${companyId}/meta`)
      .update({ free_trial: trialStatus });
  };

  saveTrialData = () => {
    const companyId = this.state.selectedCompany;
    firebase
      .database()
      .ref(`companies/${companyId}/meta`)
      .update({ free_trial: this.state.trialStatus });
  };

  handleAddCompany = async () => {
    const { newCompany } = this.state;
    if (!newCompany) return;
    try {
      const cid = await addNewCompany(newCompany);
      this.fetchCompanies(() =>
        this.setState({ selectedCompany: cid, newCompany: "" })
      );
    } catch (error) {
      this.props.openClose.showSnack(
        `Error creating company - ${error.message}.`
      );
    }
  };

  updatePermission = (email, value, callback) => {
    const emailHash = email.replace(/[^a-zA-Z0-9]/g, "");
    const validEmailRef = firebase.database().ref(`validEmails/${emailHash}`);
    let validEmailUpdate = {};
    validEmailRef.on("value", snapshot => {
      const validEmail = snapshot.val();
      validEmailUpdate = {
        access: arrayify(validEmail.access),
        company: arrayify(validEmail.company),
        cid: arrayify(validEmail.cid),
        selectedCompany: validEmail.selectedCompany
          ? validEmail.selectedCompany
          : 0
      };
      const index = validEmailUpdate.company.indexOf(
        this.state.companyNames[this.state.selectedCompany]
      );
      validEmailUpdate.access[index] = value;
    });
    validEmailRef.update(validEmailUpdate, callback());
  };

  handleSelectPermission = (user, email, companyId, event, index, value) => {
    if (user) {
      const { companyUsers } = this.state;
      companyUsers[user].access = value;
      this.updatePermission(email, value, () => {
        firebase
          .database()
          .ref(`companies/${companyId}/companyUsers`)
          .update(companyUsers);
        this.setState({
          companyUsers
        });
      });
    } else {
      this.updatePermission(email, value, () => {});
    }
  };

  handleRemoval = (email, companyId, force = false, callback) => {
    let doneCallback;
    doneCallback = deletedField => {
      if (!force) {
        this.props.openClose.showSnack(`${deletedField} deleted`);
        this.props.openClose.closeAppModal();
      }
      callback?.();
    };

    removeCompanyFromUser(
      email,
      companyId,
      doneCallback,
      (deletedField, executeDelete) => {
        if (force) {
          executeDelete();
        } else {
          this.props.openClose.setAppModal(
            `Remove ${deletedField}`,
            <div>
              <br />
              <div className="centering">
                {`Are you sure you want to remove this ${deletedField.toLowerCase()}?`}
              </div>
              <div className="centering">
                (This change only takes effect after you refresh the system.)
              </div>
            </div>,

            <Stack>
              <Button
                label="Yes, I'm sure"
                variant="text"
                color="error"
                onClick={() => {
                  executeDelete();
                }}
              />
              <Button
                label="No, go back"
                variant="text"
                onClick={this.props.openClose.closeAppModal}
              />
            </Stack>
          );
        }
      }
    );
  };

  sendResetPassword = email => {
    const sendResetPasswordFunc = httpsCallable("send_password_reset_email", {
      timeout: 360000
    });
    sendResetPasswordFunc({
      reset_mode: "reset",
      user_email: email,
      base_url: window.location.origin
    })
      .then(response => {
        this.props.openClose.showSnack("Reset password email sent");
      })
      .catch(error => {
        console.error(error);
        this.props.openClose.showSnack("Error sending reset password email.");
      });
  };

  handle2FA = (uid, name) => {
    const get2FAStatus = httpsCallable("get2FAStatus");
    get2FAStatus({ uid }).then(result => {
      const { success, status } = result.data;
      if (!success) {
        this.props.openClose.setAppModal(
          `Reset 2FA`,
          <div className="centering">
            There was an error fetching user data.
          </div>,
          <div className="centering">
            <Button
              label="Close"
              onClick={this.props.openClose.closeAppModal}
            />
          </div>
        );
      } else if (status) {
        this.props.openClose.setAppModal(
          `Reset 2FA`,
          <div className="centering">
            {`Are you sure you want to reset two-factor authentication for ${name}?`}
          </div>,
          <Stack>
            <Button
              label="Yes, I'm sure"
              variant="text"
              color="error"
              onClick={() => {
                const resetUser2FA = httpsCallable("resetUser2FA");
                resetUser2FA({ uid }).then(result => {
                  const { success } = result.data;
                  if (success) {
                    this.props.openClose.showSnack(
                      "Two factor authentication successfully reset"
                    );
                  } else {
                    this.props.openClose.showSnack(
                      "Error resetting two factor authentication"
                    );
                  }
                  this.props.openClose.closeAppModal();
                });
              }}
            />
            <Button
              label="No, go back"
              variant="text"
              onClick={this.props.openClose.closeAppModal}
            />
          </Stack>
        );
      } else {
        this.props.openClose.setAppModal(
          `Reset 2FA`,
          <div className="centering">
            This user does not have two-factor authentication enabled.
          </div>,
          <div className="centering">
            <Button
              label="Close"
              onClick={this.props.openClose.closeAppModal}
            />
          </div>
        );
      }
    });
  };

  changeCompanyTier = tier => {
    this.setState({ selectedCompanyTier: tier });
    if (this.state.selectedCompany) {
      const companyId = this.state.selectedCompany;
      firebase.database().ref(`companies/${companyId}/meta`).update({ tier });

      this.props.db.adminPanelActivityLogService.addEvent(
        `Changed company tier to ${tier}`
      );
    }
  };

  getCresicorCompaniesJSX = () => {
    const cresicorCompaniesJSX = [];
    for (const [companyId, companyName] of Object.entries(
      this.state.companyNames
    ).sort((s1, s2) => s1[1].localeCompare(s2[1]))) {
      cresicorCompaniesJSX.push(
        <MenuItem value={companyId} children={companyName} key={companyId} />
      );
    }
    return cresicorCompaniesJSX;
  };

  getCompanyUsersJSX = () => {
    const companyUsersJSX = {
      UsersJSX: [],
      EmailJSX: [],
      PermissionsJSX: [],
      RemoveJSX: [],
      PasswordJSX: [],
      TwoFactorAuthJSX: []
    };
    this.state.userData?.[this.state.selectedCompany]?.forEach(
      userDataFields => {
        const { access, name, email } = userDataFields;
        const companyId = this.state.selectedCompany;
        const uid =
          email in this.state.emailToUid ? this.state.emailToUid[email] : null;
        companyUsersJSX.UsersJSX.push(
          <div>
            {name}
            <br />
            <Typography fontSize="10px">{uid}</Typography>
          </div>
        );
        companyUsersJSX.EmailJSX.push(email);
        companyUsersJSX.PermissionsJSX.push(
          <Select
            value={access}
            onChange={this.handleSelectPermission.bind(
              null,
              uid,
              email,
              companyId
            )}
            style={{ marginTop: 0 }}>
            {Object.keys(this.state.selectedCompanyMeta.permissions || []).map(
              level => {
                return <MenuItem value={level} children={level} key={level} />;
              }
            )}
          </Select>
        );
        companyUsersJSX.RemoveJSX.push(
          <Button
            label="Remove"
            disabled={
              COMPANY_EMAIL_DOMAINS.some(domain => email.endsWith(domain)) &&
              !email.toLowerCase().includes("test")
            }
            color="error"
            variant="outlined"
            icon={<DeleteIcon />}
            onClick={this.handleRemoval.bind(null, email, companyId)}
          />
        );
        companyUsersJSX.PasswordJSX.push(
          <Button
            icon={<PersonAddIcon />}
            onClick={this.sendResetPassword.bind(null, email)}
            sx={{
              "& .MuiButton-startIcon": {
                margin: 0
              }
            }}
          />
        );
        companyUsersJSX.TwoFactorAuthJSX.push(
          <IconButton
            color="error"
            onClick={this.handle2FA.bind(null, uid, name)}>
            <CancelIcon />
          </IconButton>
        );
      }
    );

    return companyUsersJSX;
  };

  handleNewUserAdd = () => {
    if (this.state.newUserName === "") {
      this.setState({ newUserNameErrorText: "Name cannot be blank" });
      return;
    }
    if (!validator.isEmail(this.state.newUserEmail)) {
      this.setState({ newUserEmailErrorText: "Invalid Email" });
      return;
    }
    this.props.openClose.setAppModal(
      "Add User",
      <div>
        Are you sure you want to add this user? Note that it may take 1-2
        minutes for the user to be created.
      </div>,
      <Stack>
        <Button
          label="Yes, I'm sure"
          variant="text"
          onClick={() => {
            const {
              selectedCompany,
              newUserEmail,
              newUserName,
              newUserAccess
            } = this.state;
            const formData = {
              access: newUserAccess,
              user_email: newUserEmail,
              user_name: newUserName,
              company_id: selectedCompany
            };
            this.props.openClose.showSnack(
              `Attempting to invite ${newUserEmail}. Please wait...`
            );
            axios
              .post(`${cloudRunFunctionURL}/api/add_user_to_authentication`, {
                data: formData
              })

              .then(response => {
                if (response.data.data.success) {
                  this.props.openClose.showSnack("User added successfully!");
                  axios
                    .post(
                      `${cloudRunFunctionURL}/api/send_password_reset_email`,
                      {
                        data: {
                          reset_mode: "set",
                          user_email: newUserEmail,
                          base_url: window.location.origin
                        }
                      }
                    )
                    .then(response => {
                      this.props.openClose.showSnack(
                        "Sign-Up email sent to new user."
                      );
                    })
                    .catch(error => {
                      console.error(error);
                      this.props.openClose.showSnack(
                        "Error sending sign-up email to new user."
                      );
                    });
                } else if (response.data.data.reason == "user_exists") {
                  this.props.openClose.showSnack(
                    "User already exists in Vividly."
                  );
                } else if (response.data.data.reason == "user_exists_auth") {
                  this.props.openClose.showSnack(
                    "User is authenticated but does not exist in Vividly. Please contact Dev to remove their authentication."
                  );
                } else {
                  console.log(response);
                  this.props.openClose.showSnack("Invalid email domain.");
                }
              })
              .catch(error => {
                console.error(error);
                this.props.openClose.showSnack(
                  "Error adding user. You can try deleting and re-adding."
                );
              });

            this.setState({
              newUserName: "",
              newUserEmail: "",
              newUserAccess: "Viewer"
            });

            this.props.openClose.closeAppModal();
            this.props.openClose.closeAppModal();
          }}
        />
        <Button
          label="No, go back"
          variant="text"
          color="error"
          onClick={() => {
            this.props.openClose.closeAppModal();
          }}
        />
      </Stack>
    );
  };

  getSelectedCompanyData = () => {
    const selectedCompanyId = this.state.selectedCompany;
    firebase
      .database()
      .ref(`companies/${selectedCompanyId}/meta`)
      .on("value", snapshot => {
        if (snapshot.val()) {
          const meta = snapshot.val();
          this.setState({
            selectedCompanyMeta: meta,
            selectedCompanyTier: meta.tier,
            trialStatus: meta.free_trial
          });
        }
      });
    firebase
      .database()
      .ref(`companies/${selectedCompanyId}/companyUsers`)
      .on("value", snapshot => {
        const emailToUid = {};
        if (snapshot.val()) {
          const companyUsers = snapshot.val();
          this.setState({ companyUsers });
          for (const [uid, val] of Object.entries(companyUsers)) {
            emailToUid[val.email] = uid;
          }
        }
        this.setState({ emailToUid });
      });
    firebase
      .database()
      .ref("validEmails")
      .on("value", snapshot => {
        if (snapshot.val()) {
          const validEmails = snapshot.val();
          const userData = {};
          Object.entries(validEmails).forEach(([key, val]) => {
            const companies = arrayify(val.cid);
            val.emailHash = key;
            companies.forEach((company, index) => {
              if (!(company in userData)) {
                userData[company] = [];
              }
              const transformedData = {
                access: arrayify(val.access)[index],
                company: arrayify(val.company)[index],
                cid: arrayify(val.cid)[index],
                email: val.email,
                name: val.name,
                emailHash: key
              };
              userData[company].push(transformedData);
            });
          });
          this.setState({ userData });
        }
      });
  };

  getReferrals = email => {
    const emailHash = email.replace(/[^0-9a-z]/gi, "").toLowerCase();
    const referralEmails = [];
    const referralStatus = [];
    const referralTimestamps = [];
    firebase
      .database()
      .ref(`validEmails/${emailHash}/referrals`)
      .on("value", snapshot => {
        if (snapshot.val()) {
          const referrals = snapshot.val();
          if (referrals) {
            Object.keys(referrals).map(id => {
              referralEmails.push(referrals[id].email);
              referralStatus.push(referrals[id].status);
              referralTimestamps.push(referrals[id].timestamp);
            });
          }
        }
      });
    this.setState({
      referralEmails,
      referralStatus,
      referralTimestamps
    });
  };

  getDownloadData = () => {
    this.setState(
      {
        downloadData: this.state.userData[this.state.selectedCompany]
          ? this.state.userData[this.state.selectedCompany].map(escapeQuotes)
          : []
      },
      () => {
        this.csvLink.current.link.click();
      }
    );
  };

  async fetchCompanies(callback) {
    const snapshot = await firebase
      .database()
      .ref("companyNames")
      .once("value");
    const companyNames = snapshot.val();
    if (!companyNames) {
      callback();
      return;
    }

    this.setState(
      {
        companyNames
      },
      callback
    );
  }

  async resetCompany() {
    let selectedCompany = "None";
    this.fetchCompanies(() => {
      firebase.auth().onAuthStateChanged(async user => {
        if (user) {
          const snapshot = await firebase
            .database()
            .ref(`users/${user.uid}`)
            .once("value");
          const storedUser = snapshot.val();
          selectedCompany = storedUser ? storedUser.company : "None";
          this.setState({ selectedCompany }, () => {
            this.getSelectedCompanyData();
          });
          this.getReferrals(user.email);
        }
      });
    });
  }

  async componentDidMount() {
    await this.resetCompany();
  }

  async delCompany() {
    const currentCompanyId = this.state.selectedCompany;
    if (!currentCompanyId) {
      this.props.openClose.closeAppModal();
      this.props.openClose.showSnack("Error deleting company.");
    } else {
      const promReference = firebase
        .database()
        .ref(`companies/${currentCompanyId}/promotions`);
      const promSnapshot = await promReference.once("value");
      if (promSnapshot.val()) {
        this.props.openClose.closeAppModal();
        this.props.openClose.showSnack(
          "This company has at least one promotion. Please delete all promotions from this company first."
        );
        return;
      }
      const reference = firebase
        .database()
        .ref(`companies/${currentCompanyId}/companyUsers`);
      const userSnapshot = await reference.once("value");

      if (userSnapshot.val()) {
        Object.values(userSnapshot.val()).forEach(val => {
          const { email } = val;
          if (email) {
            this.handleRemoval(email, currentCompanyId, true);
          }
        });
      }
      const validEmailsRef = firebase.database().ref("validEmails");
      const validEmails = await validEmailsRef.once("value");

      Object.values(validEmails.val()).forEach(val => {
        if (val.cid) {
          if (arrayify(val.cid).includes(currentCompanyId)) {
            this.handleRemoval(val.email, currentCompanyId, true, async () => {
              firebase.database().ref(`companies/${currentCompanyId}`).remove();

              firebase
                .database()
                .ref(`companyNames/${currentCompanyId}`)
                .remove();

              firebase
                .database()
                .ref(`companyDetails/${currentCompanyId}`)
                .remove();

              await this.resetCompany();

              this.props.openClose.closeAppModal();
              this.props.openClose.showSnack(
                "Company deleted. Please hard refresh if you just deleted the company you were in."
              );
            });
          }
        }
      });
    }
  }

  handleDeleteCompany = async () => {
    if (this.props.db.companyid == this.state.selectedCompany) {
      this.props.openClose.showSnack(
        `Cannot delete ${
          this.state.companyNames[this.state.selectedCompany]
        }. Please switch to a different company in Miscellaneous and try again.`
      );
      return;
    }

    this.props.openClose.setAppModal(
      "Delete Company",
      <div className="centering">
        Are you sure you want to remove this Company?
      </div>,
      <Stack>
        <Button
          label="Yes, I'm sure"
          variant="text"
          color="error"
          onClick={async () => {
            this.delCompany();
          }}
        />
        <Button
          label="No, go back"
          variant="text"
          onClick={this.props.openClose.closeAppModal()}
        />
      </Stack>
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedCompany !== this.state.selectedCompany) {
      this.getSelectedCompanyData();
    }
  }

  render() {
    const cresicorCompaniesJSX = this.getCresicorCompaniesJSX();
    const {
      UsersJSX,
      EmailJSX,
      PermissionsJSX,
      RemoveJSX,
      PasswordJSX,
      TwoFactorAuthJSX
    } = this.getCompanyUsersJSX();
    const permissions = this.state.selectedCompanyMeta?.permissions ?? {};
    const deductionsServicedCompanies =
      this.state.selectedCompanyMeta.deductions_scanning_serviced_companies ||
      [];
    const onTrial = Boolean(this.state.trialStatus?.on_trial);
    const onSpecialTrial = Boolean(this.state.trialStatus?.special_trial);
    return (
      <div>
        <div>
          <Typography sx={styles.superheader} variant="h4">
            Manage Customer Companies & Users
          </Typography>
          <div style={styles.settingsButton}>
            <IconMenu
              iconButtonElement={
                <IconButton tooltip="Settings" size="large">
                  <SettingsIcon />
                </IconButton>
              }>
              <MenuItem
                children={<Typography variant="subtitle1">Export</Typography>}
                disabled
              />
              <MenuItem
                value={0}
                children="Download User Login Metadata"
                leftIcon={<DownloadIcon />}
                onClick={this.getDownloadData}
              />
            </IconMenu>
          </div>
          <Divider />
          <Typography variant="h6" sx={styles.subheader}>
            Selected Company
          </Typography>
          <Stack>
            <Select
              floatingLabelText="Select Company"
              value={this.state.selectedCompany}
              onChange={(event, index, value) => {
                this.setState({ selectedCompany: value }, () =>
                  this.getSelectedCompanyData()
                );
              }}
              style={{ marginTop: 0 }}>
              {cresicorCompaniesJSX}
            </Select>
            <Select
              floatingLabelText="Select Tier"
              value={this.state.selectedCompanyTier}
              onChange={(event, index, value) => {
                this.changeCompanyTier(value);
              }}
              style={{ marginTop: 0 }}>
              {["Aspen", "Pine-Plus", "Pine"].map(item => (
                <MenuItem
                  value={item.toLowerCase()}
                  children={item}
                  key={item}
                />
              ))}
            </Select>
            <Button
              label={`Delete ${
                this.state.companyNames[this.state.selectedCompany]
              }`}
              onClick={this.handleDeleteCompany}
              color="error"
              variant="outlined"
            />
          </Stack>
          <Typography fontSize="10px">{this.state.selectedCompany}</Typography>
          <br />
          <Stack>
            <TextField
              floatingLabelText="Add Company"
              defaultValue={this.state.newCompany}
              onChange={event => {
                this.setState({ newCompany: event.target.value });
              }}
              onKeyPress={ev => {
                if (ev.key === "Enter") {
                  this.handleAddCompany();
                  ev.preventDefault();
                }
              }}
              value={this.state.newCompany}
              style={{ marginLeft: 10, marginRight: 10, marginTop: 0 }}
            />
            <Button
              label={<AddIcon />}
              onClick={this.handleAddCompany}
              style={{ color: grey700, marginTop: 10 }}
            />
          </Stack>
        </div>
        <br />
        <Typography variant="h6" sx={styles.subheader}>
          Company Status
        </Typography>
        <Stack>
          <Checkbox
            color="primary"
            label="Implementation in Progress"
            onChange={this.selectCompanySoftwarePlatform.bind(
              null,
              "implementation"
            )}
            checked={Boolean(this.state.selectedCompanyMeta.implementation)}
          />
          <Checkbox
            color="primary"
            label="For Demo Use"
            onChange={this.selectCompanySoftwarePlatform.bind(null, "demo")}
            checked={Boolean(this.state.selectedCompanyMeta.demo)}
          />
        </Stack>
        <Typography variant="h6" sx={styles.subheader}>
          Free Trial Status
        </Typography>
        <div className="rowC">
          <Checkbox
            color="primary"
            label="Free Trial"
            onChange={e => this.changeFreeTrial("on_trial", e.target.checked)}
            checked={onTrial}
            disabled={onSpecialTrial}
          />
          <Checkbox
            color="primary"
            label="Special 60 Days"
            onChange={e =>
              this.changeFreeTrial("special_trial", e.target.checked)
            }
            checked={onSpecialTrial}
            disabled={!this.state.trialStatus}
          />
        </div>
        <Stack spacing={2}>
          <DatePicker
            floatingLabelText="Start Date"
            onChange={e => this.changeFreeTrial("start_date", e)}
            value={this.state.trialStatus?.start_date}
            disabled={!this.state.trialStatus}
          />
          <TextField
            floatingLabelText="Pages Scanned"
            onChange={e =>
              this.changeFreeTrial("pages_in", parseInt(e.target.value) || 0)
            }
            value={this.state.trialStatus?.pages_in || ""}
            disabled={!this.state.trialStatus}
            style={{ marginTop: 12 }}
          />
          <Button label="Save" color="primary" onClick={this.saveTrialData} />
        </Stack>
        <br />
        <Typography variant="h6" sx={styles.subheader}>
          Company Software Platforms
        </Typography>
        <div className="rowC">
          <Checkbox
            color="primary"
            label="Trade Promotion Management"
            onChange={this.selectCompanySoftwarePlatform.bind(
              null,
              "cresicor_tpm"
            )}
            checked={Boolean(this.state.selectedCompanyMeta.cresicor_tpm)}
            disabled={this.props.db.companyid == this.state.selectedCompany}
          />
          <Checkbox
            color="primary"
            label="Deductions Scanning"
            onChange={this.selectCompanySoftwarePlatform.bind(
              null,
              "cresicor_deductions_scanning"
            )}
            checked={Boolean(
              this.state.selectedCompanyMeta.cresicor_deductions_scanning
            )}
          />
          <Checkbox
            color="primary"
            label="Deductions Matching"
            onChange={this.selectCompanySoftwarePlatform.bind(
              null,
              "deductions_matching"
            )}
            checked={Boolean(
              this.state.selectedCompanyMeta.deductions_matching
            )}
          />
          <Checkbox
            color="primary"
            label="Backup Retrieval"
            onChange={this.selectCompanySoftwarePlatform.bind(
              null,
              "backup_retrieval"
            )}
            checked={Boolean(this.state.selectedCompanyMeta.backup_retrieval)}
          />
          <Checkbox
            color="primary"
            label="Deductions Reconciliation"
            onChange={this.selectCompanySoftwarePlatform.bind(
              null,
              "deductions_reconciliation"
            )}
            checked={Boolean(
              this.state.selectedCompanyMeta.deductions_reconciliation
            )}
          />
          <Checkbox
            color="primary"
            label="Broker Commissions"
            onChange={this.selectCompanySoftwarePlatform.bind(
              null,
              "broker_commissions"
            )}
            checked={Boolean(this.state.selectedCompanyMeta.broker_commissions)}
          />
          <Checkbox
            color="primary"
            label="Forecast"
            onChange={this.selectCompanySoftwarePlatform.bind(null, "forecast")}
            checked={Boolean(this.state.selectedCompanyMeta.forecast)}
          />
          <Checkbox
            color="primary"
            label="Budgeting"
            onChange={this.selectCompanySoftwarePlatform.bind(
              null,
              "cresicor_budgeting"
            )}
            checked={Boolean(this.state.selectedCompanyMeta.cresicor_budgeting)}
          />
        </div>
        <br />
        {this.state.selectedCompanyMeta.broker_commissions && (
          <div>
            <TextField
              floatingLabelText="Maximum Allowed Brokers"
              onChange={event => {
                this.selectCompanySoftwarePlatform(
                  "max_brokers",
                  event,
                  parseInt(event.target.value) === 0
                    ? 0
                    : parseInt(event.target.value) || ""
                );
                this.setState({ savedText: "Saved!" });
              }}
              value={this.state.selectedCompanyMeta.max_brokers}
              style={{ marginLeft: 10, marginRight: 10, marginTop: 0 }}
            />
            {this.state.savedText}
          </div>
        )}
        <br />
        <Typography variant="h6" sx={styles.subheader}>
          Company Provided Services
        </Typography>
        <div>
          <Checkbox
            color="primary"
            label="Deductions Scanning Servicing"
            onChange={this.selectCompanySoftwarePlatform.bind(
              null,
              "deductions_scanning_servicing"
            )}
            checked={Boolean(
              this.state.selectedCompanyMeta.deductions_scanning_servicing
            )}
          />
          {this.state.selectedCompanyMeta.deductions_scanning_servicing && (
            <>
              <br />
              <div style={styles.wrapper}>
                {deductionsServicedCompanies.length > 0 ? (
                  deductionsServicedCompanies.map((id, index) => {
                    return (
                      <Chip
                        label={this.state.companyNames[id]}
                        style={styles.chip}
                        variant="outlined"
                        key={index}
                      />
                    );
                  })
                ) : (
                  <Chip label="None" variant="outlined" style={styles.chip} />
                )}
              </div>
              <Select
                floatingLabelText="Select Serviced Companies"
                multiple
                value={deductionsServicedCompanies}
                onChange={(event, index, value) => {
                  const companyId = this.state.selectedCompany;
                  firebase
                    .database()
                    .ref(
                      `companies/${companyId}/meta/deductions_scanning_serviced_companies`
                    )
                    .set(value);
                }}
                style={{ marginTop: 0 }}>
                {Object.keys(this.state.companyNames).map(id => (
                  <MenuItem
                    checked={deductionsServicedCompanies.includes(id)}
                    value={id}
                    children={this.state.companyNames[id]}
                    key={id}
                  />
                ))}
              </Select>
            </>
          )}
        </div>
        <br />
        <Typography variant="h6" sx={styles.subheader}>
          Company Feature Gates
        </Typography>
        <div>
          {this.state.selectedCompanyMeta.cresicor_tpm && (
            <Checkbox
              color="primary"
              label="Allow Percentage Spend Rates"
              onChange={this.setCompanyFeatureGates.bind(
                null,
                "allowPercentSpendRates"
              )}
              checked={Boolean(
                this.state.selectedCompanyMeta.featureGates
                  ?.allowPercentSpendRates
              )}
            />
          )}
          {this.state.selectedCompanyMeta.deductions_reconciliation && (
            <Checkbox
              color="primary"
              label="Repayments"
              onChange={event =>
                this.setCompanyFeatureGates(
                  "drmRepayments",
                  event,
                  event.target.checked
                )
              }
              checked={Boolean(
                this.state.selectedCompanyMeta.featureGates?.drmRepayments
              )}
            />
          )}
          {this.state.selectedCompanyMeta.forecast && (
            <Checkbox
              color="primary"
              label="Forecast v2"
              onChange={event =>
                this.setCompanyFeatureGates(
                  "forecastv2",
                  event,
                  event.target.checked
                )
              }
              checked={Boolean(
                this.state.selectedCompanyMeta.featureGates?.forecastv2
              )}
            />
          )}
          {this.state.selectedCompanyMeta.featureGates?.forecastv2 && (
            <Checkbox
              color="primary"
              label="Manual override versioning"
              onChange={event =>
                this.setCompanyFeatureGates(
                  "overrideVersioning",
                  event,
                  event.target.checked
                )
              }
              checked={Boolean(
                this.state.selectedCompanyMeta.featureGates?.overrideVersioning
              )}
            />
          )}
          {this.state.selectedCompanyMeta.featureGates?.forecastv2 && (
            <Checkbox
              color="primary"
              label="Forecast CSV Exports"
              onChange={event =>
                this.setCompanyFeatureGates(
                  "forecastCsvExports",
                  event,
                  event.target.checked
                )
              }
              checked={Boolean(
                this.state.selectedCompanyMeta.featureGates?.forecastCsvExports
              )}
            />
          )}
          {this.state.selectedCompanyMeta.deductions_reconciliation && (
            <Checkbox
              color="primary"
              label="DRM - Skip Backup Uploads"
              onChange={event =>
                this.setCompanyFeatureGates(
                  "skipBackupUpload",
                  event,
                  event.target.checked
                )
              }
              checked={Boolean(
                this.state.selectedCompanyMeta.featureGates?.skipBackupUpload
              )}
            />
          )}
          {this.state.selectedCompanyMeta.cresicor_deductions_scanning &&
            !this.state.selectedCompanyMeta.cresicor_tpm && (
              <Checkbox
                color="primary"
                label="Use Preset Customers in DSM"
                onChange={event =>
                  this.setCompanyFeatureGates(
                    "usePresetCustomersInDsm",
                    event,
                    event.target.checked
                  )
                }
                checked={Boolean(
                  this.state.selectedCompanyMeta.featureGates
                    ?.usePresetCustomersInDsm
                )}
              />
            )}
        </div>
        <br />
        <div className="rowC">
          <Typography variant="h6" sx={styles.subheader}>
            Manage Company's Users
          </Typography>
        </div>
        <div>
          <DataTable
            title="Add New User to Company"
            data={{
              Name: [
                <TextField
                  id="newUserName"
                  value={this.state.newUserName}
                  onChange={event => {
                    this.setState({
                      newUserName: event.target.value
                    });
                  }}
                  style={{ marginRight: "16px", marginTop: 0 }}
                  errorText={this.state.newUserNameErrorText}
                />
              ],
              Email: [
                <TextField
                  id="newUserEmail"
                  value={this.state.newUserEmail}
                  onChange={event => {
                    this.setState({
                      newUserEmail: event.target.value.toLowerCase()
                    });
                  }}
                  style={{ marginRight: "16px", marginTop: 0 }}
                  errorText={this.state.newUserEmailErrorText}
                />
              ],
              Permissions: [
                <Select
                  value={this.state.newUserAccess}
                  onChange={(event, index, value) => {
                    this.setState({ newUserAccess: value });
                  }}
                  style={{ marginTop: 0 }}>
                  {Object.keys(permissions).map(level => {
                    return (
                      <MenuItem value={level} children={level} key={level} />
                    );
                  })}
                </Select>
              ],
              Add: [
                <Button
                  label="Add User"
                  icon={<AddIcon />}
                  onClick={this.handleNewUserAdd}
                />
              ]
            }}
          />
        </div>
        <br />
        <div>
          <DataTable
            title={"Manage Company's Users"}
            data={{
              Name: UsersJSX,
              Email: EmailJSX,
              Permissions: PermissionsJSX,
              Remove: RemoveJSX,
              "Send Password Reset": PasswordJSX,
              "Reset 2FA": TwoFactorAuthJSX
            }}
          />
        </div>
        <div>
          <DataTable
            title="Referrals"
            data={{
              Email: this.state.referralEmails,
              Status: this.state.referralStatus,
              Timestamp: this.state.referralTimestamps
            }}
          />
        </div>
        <PropDataUpdatedCSVLink
          data={this.state.downloadData}
          headers={this.state.headers}
          filename={`${
            this.state.companyNames[this.state.selectedCompany]
          } User Metadata Export.csv`}
          className="hidden"
          ref={this.csvLink}
          target="_blank"
        />
      </div>
    );
  }
}

export default ManageCompanies;
