import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import ReactList from "react-list";
import useWindowSize from "helpers/useWindowSize";
import { blueGrey } from "@mui/material/colors";

const blueGrey50 = blueGrey["50"];

const useStyles = makeStyles(() => ({
  columnContainer: {
    width: "100%",
    overflowX: "auto",
    display: "flex",
    flexDirection: "row"
  },
  col: {
    backgroundColor: blueGrey50
  },
  title: {
    textAlign: "center",
    fontFamily: "Oxygen",
    fontWeight: "bold",
    paddingTop: "25px"
  },
  listContainer: {
    backgroundColor: blueGrey50,
    overflow: "auto"
  }
}));

export interface Column<T> {
  name: string;
  data: T[];
}

export interface ScrollableColumnsProps<T> {
  renderCard: (key: string, index: number, data: T[]) => JSX.Element;
  columns: Column<T>[];
}

/*
 *  A series of infinitely scrolling columns, using the ReactList third-party
 *  component. Uses TypeScript generics for its data list typing.
 *  @template T - data object type
 *  @param {ScrollableColumnsProps} scrollableColumnsProps - input props
 */
function ScrollableColumns<T>(props: ScrollableColumnsProps<T>): JSX.Element {
  const classes = useStyles();
  const windowSize = useWindowSize();
  const width = windowSize.width || 0;
  const height = windowSize.height || 0;
  const { columns, renderCard } = props;

  return (
    <div className={classes.columnContainer}>
      {columns.map((column, columnIdx) => (
        <div key={column.name} style={{ width: width / 4 }}>
          <div
            className={classNames(
              columnIdx === columns.length - 1
                ? "lastRightArrow"
                : "rightArrow",
              classes.title
            )}
            style={{
              width: width / 4
            }}>
            {`${column.name} (${column.data.length})`}
          </div>

          <div
            className={classes.listContainer}
            style={{
              height: height - 223
            }}>
            <ReactList
              itemRenderer={(key, index) => renderCard(key, index, column.data)}
              length={column.data.length}
              type="variable"
            />
          </div>
        </div>
      ))}
    </div>
  );
}

export default ScrollableColumns;
