import { DropTarget } from "react-dnd";
import Dimensions from "react-dimensions";
import PropTypes from "prop-types";
import React from "react";
import ReactList from "react-list";
import DateParser from "fast-date-parse";

import Button from "ui-library/Button";

import { sortByField } from "helpers/sortByDate";
import { underPromoApprovalLimit } from "helpers/DataProcessing";
import { updateStatusFirebase, firebase } from "helpers/Firebase";
import Cell from "./Cell";

const styles = {
  title: {
    textAlign: "center",
    fontFamily: "Oxygen",
    fontWeight: "bold",
    paddingTop: "25px"
  }
};

const colTarget = {
  // Called when cell is dropped on dbcolumn
  drop(props, monitor, component) {
    const cell = monitor.getItem();

    // check for approving promotions
    const promoLimitsDict = props.db.meta.promo_limits;
    const { uid } = firebase.auth().currentUser;
    const companyUser = props.db.companyUsers[uid];
    const underLimit =
      (props.name != "Approved" && props.name != "Running") ||
      underPromoApprovalLimit(
        companyUser,
        uid,
        promoLimitsDict,
        cell.prom.totalExpSpend
      );

    // drag actions allowed based on permissions, excluding Viewers
    const dragAllowed = {
      Pending: true,
      Submitted: props.db.permissions.includes("submit"),
      Approved: props.db.permissions.includes("approve"),
      Running: props.db.permissions.includes("approve"),
      Completed: props.db.permissions.includes("admin")
    };

    if (
      !(props.db.permissions === "None") &&
      dragAllowed[props.name] &&
      !props.db.readOnly &&
      underLimit
    ) {
      // Edit 'status' field in public database to switch columns
      updateStatusFirebase(cell.promKey, cell.prom, props.name);
    } else {
      props.openClose.setAppModal(
        "Promotion Status Change Error",
        <div>
          {`You do not have the ability to move this promotion to ${props.name}. Please check Account Settings > Permissions or speak to an administrator.`}
        </div>,
        <div>
          <Button
            label="Go back"
            onClick={() => {
              props.openClose.closeAppModal();
            }}
          />
        </div>
      );
    }
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
}

class Dbcolumn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contactDetails: {},
      width: this.props.containerWidth
    };
  }

  getSortedClist = clist => {
    const parser = new DateParser("YYYY-MM-DD");
    const sortedClist = [];
    for (let i = 0; i < clist.length; i++) {
      const cell = { ...clist[i] };
      cell.lastModified = new Date(
        `${cell.modified.date} ${cell.modified.time}`
      ).getTime();
      cell.begin = parser.parse(`${cell.year}-01-${cell.month}`);
      cell.showRightDrawer = this.props.showRightDrawer;
      cell.openClose = this.props.openClose;
      cell.db = this.props.db;

      const cellContactDetails = {};
      if (cell.contact) {
        for (let j = 0; j < cell.contact.length; j++) {
          const contactKey = cell.contact[j];
          cellContactDetails[contactKey] = this.props.db.contacts[contactKey];
        }
      }
      cell.contactDetails = cellContactDetails;

      cell.cancelledStatus = this.props.db.meta.statuses.indexOf("Cancelled");

      cell.numLines = cell.lines ? Object.keys(cell.lines).length : 0;

      cell.key = i; // to get rid of warning

      sortedClist.push(cell);
    }

    let sortTag = "begin";
    let reverseSort = true;
    if (this.props.sortBy == "expectedSpend") {
      sortTag = "totalExpSpend";
      reverseSort = false;
    }

    return sortByField(sortTag, sortedClist, reverseSort);
  };

  renderCell = (sortedClist, index, key) => {
    const cell = sortedClist[index];
    return (
      <Cell
        key={key}
        {...cell}
        readOnly={this.props.readOnly}
        onTagClick={this.props.onTagClick}
      />
    );
  };

  componentDidMount() {}

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    const isMac = navigator.appVersion.indexOf("Mac") != -1;
    const { connectDropTarget, isOver } = this.props;

    const clist = this.props.clist || [];
    const count = clist.length;
    const sortedClist = this.getSortedClist(clist);

    return connectDropTarget(
      <div style={{ width: $(window).width() / 4 }}>
        <div
          className={this.props.last ? "lastRightArrow" : "rightArrow"}
          style={{ ...styles.title, ...{ width: $(window).width() / 4 } }}>
          {`${this.props.name} (${count})`}
        </div>

        <div
          style={{
            overflow: "auto",
            height: isMac ? $(window).height() - 206 : $(window).height() - 223
          }}>
          <ReactList
            itemRenderer={this.renderCell.bind(null, sortedClist)}
            length={sortedClist.length}
            type="variable"
          />
        </div>
      </div>
    );
  }
}

Dbcolumn.propTypes = {
  isOver: PropTypes.bool.isRequired
};

export default Dimensions()(DropTarget("cell", colTarget, collect)(Dbcolumn));
