import React from "react";

import AddIcon from "@mui/icons-material/Add";
import DatePicker from "ui-library/DatePicker";
import Button from "ui-library/Button";
import MenuItem from "ui-library/MenuItem";
import SelectField from "ui-library/Select";
import TextField from "ui-library/TextField";

import {
  addSpend,
  getJSXListsFirebase,
  updateMetaFirebase
} from "helpers/Firebase";
import { formatDate } from "helpers/Time";
import { objToArray, sortByField } from "helpers/sortByDate";
import Mixpanel from "helpers/Mixpanel";
import { red } from "@mui/material/colors";
import { Stack } from "@mui/material";
import { getVisibleDistributors } from "../Customers/CustomerUtils";

const red400 = red["400"];

const styles = {
  header: {
    width: "100%",
    marginRight: "-50px",
    fontSize: 20
  },
  radioButton: {
    marginBottom: 16
  }
};

const Subheader = props => <div {...props} />;

class NewSpend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numAddMoney: 0,
      addMoney: {},
      isError: false,
      errorText: "",
      customer: 0,
      distributor: 0,
      promotion: 0,
      line: 0
    };
  }

  isValid = id => {
    if (
      !("date" in this.state.addMoney[id]) ||
      this.state.addMoney[id].distributor == ""
    ) {
      return false;
    }
    const moneyVal = this.state.addMoney[id].spend;
    return parseFloat(moneyVal) == moneyVal;
  };

  checkError = id => {
    if (Object.keys(this.state.addMoney).length == 0) {
      return true;
    }
    if (this.state.line == 0) {
      return true;
    }
    if (!this.isValid(id)) {
      return true;
    }
    return false;
  };

  missingRegions = () => {
    const missingRegions = [];
    for (const key in this.state.addMoney) {
      var { region } = this.state.addMoney[key];
      if (!region || region == "" || region.trim() == "") continue;

      var groups = {};
      let ungrouped = [];
      if (this.props.db.region_mapping) {
        groups = this.props.db.region_mapping.groups || {};
        ungrouped = this.props.db.region_mapping.ungrouped_regions || [];
      }
      if (
        region &&
        ungrouped.indexOf(region) < 0 &&
        Object.keys(groups)
          .map(group => (groups[group].regions || []).indexOf(region))
          .filter(x => x >= 0).length == 0
      ) {
        missingRegions.push(region);
      }
    }
    return missingRegions;
  };

  handleDateChange = (id, event, value) => {
    const newAddMoney = this.state.addMoney;
    newAddMoney[id].date = formatDate(event);
    newAddMoney[id].distributor = "";
    this.setState({
      addMoney: newAddMoney,
      isError: false,
      errorText: ""
    });
  };

  handleTextChange = (field, event) => {
    const newAddMoney = this.state.addMoney;
    newAddMoney[event.target.id][field] = ["region", "checkNumber"].includes(
      field
    )
      ? event.target.value
      : parseFloat(event.target.value);
    this.setState({
      addMoney: newAddMoney,
      isError: false,
      errorText: ""
    });
  };

  handleDistributorChange = (id, event, index, value) => {
    const newAddMoney = this.state.addMoney;
    newAddMoney[id].distributor = value;
    this.setState({
      addMoney: newAddMoney,
      isError: false,
      errorText: ""
    });
  };

  getAddMoneyJSX = () => {
    const addMoneyJSX = [];
    for (let i = 0; i < this.state.numAddMoney; i++) {
      const distributorsJSX = this.getVisibleDistributorsJSX(`${i}`);
      addMoneyJSX.push(
        <Stack alignItems="flex-end" key={i}>
          <DatePicker
            firstDayOfWeek={0}
            floatingLabelText="Date"
            value={this.state.addMoney[`${i}`].date.split("-")}
            onChange={this.handleDateChange.bind(null, `${i}`)}
          />
          <TextField
            placeholder="Spend ($)"
            onChange={this.handleTextChange.bind(null, "spend")}
            id={`${i}`}
            value={this.state.addMoney[`${i}`].spend}
            disabled={!this.state.addMoney[`${i}`]}
            fullWidth
          />
          <TextField
            placeholder="Region"
            onChange={this.handleTextChange.bind(null, "region")}
            id={`${i}`}
            value={this.state.addMoney[`${i}`].region}
            disabled={!this.state.addMoney[`${i}`]}
            fullWidth
          />
          <TextField
            placeholder="Check Number"
            onChange={this.handleTextChange.bind(null, "checkNumber")}
            id={`${i}`}
            value={this.state.addMoney[`${i}`].checkNumber}
            disabled={!this.state.addMoney[`${i}`]}
            fullWidth
          />
          <SelectField
            floatingLabelText="First Receiver"
            value={this.state.addMoney[`${i}`].distributor}
            onChange={this.handleDistributorChange.bind(null, `${i}`)}
            fullWidth>
            {distributorsJSX}
          </SelectField>
        </Stack>
      );
    }
    return addMoneyJSX;
  };

  handleAddMoneyNum = () => {
    const newAddMoney = this.state.addMoney;
    newAddMoney[this.state.numAddMoney] = {
      date: formatDate(Date.now()),
      distributor: ""
    };
    this.setState({
      numAddMoney: this.state.numAddMoney + 1,
      addMoney: newAddMoney
    });
  };

  handleSubmit = () => {
    const { addMoney } = this.state;
    const addMoneyList = [];
    if (Object.keys(addMoney).length == 0) {
      this.setState({ errorText: "Missing/invalid field(s)." });
      return;
    }
    for (const key in addMoney) {
      if (this.checkError(key)) {
        this.setState({ errorText: "Missing/invalid field(s)." });
        return;
      }
      const newItem = { ...addMoney[key] };

      newItem.customer = this.state.addMoney[key].distributor;

      // Fields particular to Spend
      const prom = this.props.db.promotions[this.state.promotion];
      const line = prom.lines[this.state.line];
      newItem.spend = -1 * newItem.spend;
      newItem.productGroup = line.productGroup;
      newItem.spendRate = line.spendRate || null;
      newItem.promKey = this.state.promotion || null;
      newItem.lineKey = this.state.line || null;

      if (newItem.spend === 0) {
        this.setState({
          errorText: "Some item(s) have zero spend and cannot be uploaded."
        });
        return;
      }

      if (newItem.spend && newItem.spendRate) {
        newItem.sales = Math.abs(newItem.spend / newItem.spendRate);
      }

      addMoneyList.push(newItem);
    }

    const missingRegions = this.missingRegions();
    if (missingRegions.length > 0 && !this.state.alreadyCheckedRegions) {
      this.setState({
        errorText:
          "Warning: Some regions are not yet grouped.\
        Press Submit again to finish uploading, then proceed to the Region Mapping page to group these regions.",
        alreadyCheckedRegions: true
      });
      return;
    }
    if (missingRegions.length > 0) {
      const newUngroupedRegions =
        this.props.db.meta.region_mapping.ungrouped_regions || [];
      newUngroupedRegions.push(...(missingRegions || []));

      const update = {};
      update["region_mapping/ungrouped_regions"] = newUngroupedRegions;
      updateMetaFirebase(update);
    }

    Mixpanel.track("Manual Spend Add", {
      "Spend List": addMoneyList
    });
    addSpend(addMoneyList);
    this.props.openClose.closeAppModal();
  };

  getDistributorsJSX = () => {
    const distributorsJSX = [];
    if (!this.state.customer) {
      return distributorsJSX;
    }

    const { customers } = this.props.db;
    const distributors = Array.from(
      customers[this.state.customer].distributors || []
    );
    if (
      customers[this.state.customer].isDistributor ||
      customers[this.state.customer].isDirect
    ) {
      distributors.push(this.state.customer);
    }

    for (let i = 0; i < distributors.length; i++) {
      const distributorKey = distributors[i];
      const distributorName = customers[distributorKey].name;
      distributorsJSX.push(
        <MenuItem
          value={distributorKey}
          children={distributorName}
          key={distributorKey}
        />
      );
    }
    return distributorsJSX;
  };

  getVisibleDistributorsJSX = id => {
    const distributorsJSX = [];
    if (!this.state.customer) {
      return distributorsJSX;
    }

    const { customers } = this.props.db;
    const curCustomer = customers[this.state.customer];
    const distributors = getVisibleDistributors(
      curCustomer.key,
      curCustomer,
      new Date(this.state.addMoney[id].date.split("-"))
    );

    for (let i = 0; i < distributors.length; i++) {
      const distributorKey = distributors[i];
      if (!(distributorKey in customers)) {
        continue;
      }
      const distributorName = customers[distributorKey].name;
      distributorsJSX.push(
        <MenuItem
          value={distributorKey}
          children={distributorName}
          key={distributorKey}
        />
      );
    }
    return distributorsJSX;
  };

  getPromotionsJSX = () => {
    const allPromotions = this.props.db.promotions;
    const promotionsJSX = [];
    const promotionsList = sortByField("name", objToArray(allPromotions), true);
    for (let i = 0; i < promotionsList.length; i++) {
      const promotion = promotionsList[i];
      if (
        this.props.db.meta.statuses[promotion.status] != "Declined" &&
        this.props.db.meta.statuses[promotion.status] != "Cancelled" &&
        (!this.state.customer || promotion.customer == this.state.customer)
      ) {
        promotionsJSX.push(
          <MenuItem
            value={promotion.key}
            children={promotion.name}
            key={promotion.key}
          />
        );
      }
    }
    return promotionsJSX;
  };

  componentDidMount() {
    getJSXListsFirebase(
      this.props.db,
      allJSX => {
        this.setState({
          customersJSX: allJSX.customersJSX,
          customer: allJSX.customersJSX[0].key
        });
      },
      null,
      true
    );

    this.handleAddMoneyNum();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    getJSXListsFirebase(
      nextProps.db,
      allJSX => {
        this.setState({
          customersJSX: allJSX.customersJSX,
          customer: allJSX.customersJSX[0].key
        });
      },
      null,
      true
    );
  }

  render() {
    const addMoneyJSX = this.getAddMoneyJSX();
    const filteredPromotionsJSX = this.props.db.promotions
      ? this.getPromotionsJSX()
      : [];
    const prom = this.props.db.promotions[this.state.promotion];
    const lines = this.state.promotion ? Object.keys(prom.lines) : [];

    return (
      <Stack direction="column" alignItems="inherit">
        <Subheader style={styles.header}>Specify Details</Subheader>
        <div className="rowC">
          <SelectField
            floatingLabelText="Customer"
            value={this.state.customer}
            onChange={(event, index, value) => {
              this.setState({
                customer: value,
                distributor: 0,
                promotion: 0,
                line: 0,
                isError: false
              });
            }}
            fullWidth
            style={{ marginRight: 16 }}>
            {this.state.customersJSX}
          </SelectField>
          <SelectField
            floatingLabelText="Promotion"
            value={this.state.promotion}
            onChange={(event, index, value) => {
              this.setState({ promotion: value, line: 0, isError: false });
            }}
            fullWidth
            style={{ marginRight: 16 }}>
            <MenuItem value={0} children="None" key="none" />
            {filteredPromotionsJSX}
          </SelectField>
          <SelectField
            floatingLabelText="Line"
            value={this.state.line}
            onChange={(event, index, value) => {
              this.setState({ line: value, isError: false });
            }}
            fullWidth
            disabled={!this.state.promotion}
            style={{ marginRight: 16 }}>
            <MenuItem value={0} children="None" key="none" />
            {lines.map(lineKey => {
              const line = prom.lines[lineKey];
              const pgName =
                this.props.db.meta.product_groups &&
                this.props.db.meta.product_groups[line.productGroup]
                  ? this.props.db.meta.product_groups[line.productGroup].name
                  : line.productGroup;
              return (
                <MenuItem
                  value={lineKey}
                  children={`${
                    this.props.db.meta.fundTypes?.[line.type]?.name
                  } - ${pgName} (${lineKey})`}
                  key={lineKey}
                />
              );
            })}
          </SelectField>
        </div>
        <br />
        <br />
        <div className="rowC">
          <Subheader style={styles.header}>Input Items</Subheader>
          <Button onClick={this.handleAddMoneyNum} label={<AddIcon />} />
        </div>
        {addMoneyJSX}
        <br />
        <div className="centering">
          <Button label="Submit" onClick={this.handleSubmit} />
        </div>
        <br />
        <div style={{ color: red400 }} className="centering">
          {this.state.errorText}
        </div>
        <br />
      </Stack>
    );
  }
}

export default NewSpend;
