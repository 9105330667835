import React from "react";

import Button from "ui-library/Button";
import TextField from "ui-library/TextField";

import { addFirebase } from "helpers/Firebase";

import { common } from "@mui/material/colors";

const { black } = common;
class NewBroker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newBroker: {
        name: "",
        abbr: ""
      }
    };
  }

  handleTextChange = (field, value) => {
    const { newBroker } = this.state;

    newBroker[field] = value.trim();

    this.setState({
      newBroker
    });
  };

  saveToDatabase = () => {
    // first, check for errors
    if (!this.state.newBroker.name) {
      this.setState({
        nameError: "Name is required."
      });
      return;
    }

    if (!this.state.newBroker.abbr) {
      this.setState({
        abbrError: "Abbreviation is required."
      });
      return;
    }

    for (const key in this.props.db.brokers) {
      const existingBroker = this.props.db.brokers[key];
      if (
        this.state.newBroker.name == existingBroker.name ||
        (existingBroker.otherNames &&
          existingBroker.otherNames.indexOf(this.state.newBroker.name) != -1)
      ) {
        this.setState({
          nameError: "Broker name already exists in database."
        });
        return;
      }
    }

    addFirebase(14, this.state.newBroker);
    this.setState({
      newBroker: { name: "", abbr: "" }
    });
    this.props.openClose.closeAppModal();
  };

  render() {
    return (
      <div>
        <div>
          <br />
          <TextField
            floatingLabelText="Name"
            onChange={event => {
              this.handleTextChange("name", event.target.value.trim());
            }}
            errorText={this.state.nameError}
            fullWidth
          />
          <br />
          <TextField
            floatingLabelText="Abbreviation"
            onChange={event => {
              this.handleTextChange("abbr", event.target.value.trim());
            }}
            errorText={this.state.abbrError}
            fullWidth
          />
          <br />
          <div className="centering">
            <Button label="Save to Database" onClick={this.saveToDatabase} />
          </div>
          <br />
          <br />
        </div>
      </div>
    );
  }
}

export default NewBroker;
