import React from "react";

import Avatar from "ui-library/Avatar";
import Chip from "@mui/material/Chip";
import FaceIcon from "@mui/icons-material/Face";

import ContactProfile from "./ContactProfile";

class ContactChip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contactKey: ""
    };
  }

  componentDidMount() {
    this.setState({
      contactKey: this.props.contactKey
    });
  }

  // This component is unique in that new queries to the database are needed even after the initial mount,
  // every time new props are received.
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      contactKey: nextProps.contactKey
    });
  }

  render() {
    return (
      this.props.name &&
      (this.props.avatar ? (
        <Chip
          onClick={
            this.props.showRightDrawer &&
            this.props.showRightDrawer.bind(
              null,
              <ContactProfile
                contactKey={this.state.contactKey}
                showRightDrawer={this.props.showRightDrawer}
                db={this.props.db}
              />
            )
          }
          avatar={<Avatar src={this.props.avatar} />}
          label={this.props.name}
        />
      ) : (
        <Chip
          onClick={
            this.props.showRightDrawer &&
            this.props.showRightDrawer.bind(
              null,
              <ContactProfile
                contactKey={this.state.contactKey}
                showRightDrawer={this.props.showRightDrawer}
                db={this.props.db}
              />
            )
          }
          icon={<FaceIcon />}
          label={this.props.name}
        />
      ))
    );
  }
}

export default ContactChip;
