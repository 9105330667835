export const UPDATE_FIELD = "UPDATE_FIELD_INVOICE_LINES_MATCHING";
export const SET_DEFAULT = "SET_DEFAULT_INVOICE_LINES_MATCHING";
export const ADD_LINE_KEY = "ADD_LINE_KEY_INVOICE_LINES_MATCHING";
export const DELETE_LINE_KEY = "DELETE_LINE_KEY_INVOICE_LINES_MATCHING";
export const GET_SUGGESTED_MATCHES = "GET_SUGGESTED_INVOICE_LINES_MATCHING";
export const RESET = "RESET_INVOICE_LINES_MATCHES";
export const CHANGE_MODAL_STEP = "CHANGE_MODAL_STEP";
export const SAVE_MATCHES = "SAVE_MATCHES";

export const updateField = (field, value) => ({
  type: UPDATE_FIELD,
  field,
  value
});

export const setDefault = (
  accumToLines,
  accumInvoiceLinesDefault,
  invoiceLinesDefault,
  accumInvoiceLines,
  invoiceLines,
  fileLineLimit,
  fileTooLarge,
  uploadPath,
  matchKey,
  suggestMatches
) => ({
  type: SET_DEFAULT,
  accumToLines,
  accumInvoiceLinesDefault,
  invoiceLinesDefault,
  accumInvoiceLines,
  invoiceLines,
  fileLineLimit,
  fileTooLarge,
  uploadPath,
  matchKey,
  suggestMatches
});

export const addLineKey = (rows, lineKey, tableType) => ({
  type: ADD_LINE_KEY,
  rows,
  lineKey,
  tableType
});

export const deleteLineKey = (rows, lineKey, tableType) => ({
  type: DELETE_LINE_KEY,
  rows,
  lineKey,
  tableType
});

export const getSuggestedMatches = isInputChecked => ({
  type: GET_SUGGESTED_MATCHES,
  isInputChecked
});

export const reset = () => ({
  type: RESET
});

export const changeMatchingModalStep = stepIndex => ({
  type: CHANGE_MODAL_STEP,
  stepIndex
});

export const proceedToSaveMatches = {
  type: SAVE_MATCHES
};
