import { invoiceStatuses } from "deductions-constants/ReconciliationStatuses";
import {
  invoiceTypes,
  invoiceUploadSources
} from "deductions-constants/ReconciliationTypes";
import { ERPTransactionObject, InvoiceObject } from "deductions-models/index";
import { DisplayInvoiceObject } from "reconciliation-types/invoiceTypes";
import { InvoiceAmountService } from "reconciliation-services";
import { Customer, CompanyUser } from "js/dbTypes";

export const HumanReadableInvoiceStatuses: Record<invoiceStatuses, string> = {
  [invoiceStatuses.CANCELLED]: "Cancelled",
  [invoiceStatuses.IN_PROGRESS]: "In Progress",
  [invoiceStatuses.NEEDS_ATTENTION]: "Needs Attention",
  [invoiceStatuses.NEW]: "New",
  [invoiceStatuses.RESOLVED]: "Resolved"
};

export const HumanReadableInvoiceTypes: Record<invoiceTypes, string> = {
  [invoiceTypes.TRADE_DEDUCTION]: "Trade Deduction",
  [invoiceTypes.NON_TRADE_DEDUCTION]: "Non-Trade Deduction",
  [invoiceTypes.BILLPAY]: "Billpay",
  [invoiceTypes.REPAYMENT]: "Repayment"
};

export const HumanReadableInvoiceUploadSources: Record<
  invoiceUploadSources,
  string
> = {
  [invoiceUploadSources.MANUAL]: "Manual",
  [invoiceUploadSources.WEB_SCRAPER]: "Web Scraper"
};

export const HumanReadableInvoiceLineStatuses = {
  pending: "Pending",
  match_confirmed: "Match Confirmed",
  match_not_found: "Match Not Found",
  sales_review: "Sales Review",
  dispute: "Dispute",
  cleared: "Cleared"
};

export const getPromKeyFromMatchedPromLine = (
  matchedPromotionLine: string | undefined
): string | undefined => {
  return matchedPromotionLine?.split("-").slice(0, -1).join("-");
};

export const processInvoiceForDisplay = (
  invoice: InvoiceObject,
  erpTransactions: Record<string, ERPTransactionObject>,
  customers: Record<string, Customer>,
  companyUsers: Record<string, CompanyUser>
): DisplayInvoiceObject => {
  const customerName = invoice.customerKey
    ? customers[invoice.customerKey || ""]?.name || "Unknown"
    : "";

  const displayAssignedUsers = Object.values(invoice.assignedUsers || {})
    .map(uid => companyUsers[uid]?.name || "")
    .sort()
    .join(" | ");

  const sign = invoice.amount > 0 ? 1 : -1;
  const allInvoiceAmounts = InvoiceAmountService.getAllInvoiceAmounts(
    invoice,
    erpTransactions
  );
  const { openAmount, resolvedAmount } = allInvoiceAmounts;

  const displayInvoiceObject: DisplayInvoiceObject = {
    ...invoice,
    customerName,
    displayAssignedUsers,
    ...allInvoiceAmounts,
    openAmount: sign * openAmount,
    resolvedAmount: sign * resolvedAmount
  };

  return displayInvoiceObject;
};
