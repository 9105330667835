import React from "react";

import { Toolbar, ToolbarTitle, ToolbarSeparator } from "ui-library/Toolbar";
import ToolbarGroup from "ui-library/ToolbarGroup";
import AddBoxIcon from "@mui/icons-material/AddBox";
import DeleteIcon from "@mui/icons-material/Delete";
import PriceIcon from "@mui/icons-material/MonetizationOn";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

import Button from "ui-library/Button";
import IconButton from "ui-library/IconButton";
import MenuItem from "ui-library/MenuItem";
import Select from "ui-library/Select";

import { objToArray, sortByField } from "helpers/sortByDate";
import {
  updateMetaFirebase,
  firebase,
  isUserCresicorEmployee,
  updateFirebase
} from "helpers/Firebase";
import { formatDependencies } from "helpers/Errors";
import { grey } from "@mui/material/colors";
import DataTableSmart from "../tables/DataTableSmart";
import NewProductGroup from "./NewProductGroup";
import ProductGroupProfile from "./ProductGroupProfile";
import ProductProfile from "./ProductProfile";

const grey500 = grey["500"];
const grey600 = grey["600"];

const styles = {
  notifs: {
    width: "60%"
  },
  moneyIcon: {
    width: 15,
    height: 15
  },
  radioButton: {
    marginBottom: 16
  },
  chip: {
    margin: 4,
    cursor: "pointer"
  },
  wrapper: {
    display: "flex",
    flexWrap: "wrap"
  }
};

class ProductGroups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newPg: "",
      productGroups: {},
      nameExists: "",
      allProducts: {}
    };
  }

  handleAddProduct = (pg, event, index, productKey) => {
    // NB(daniel): the desired product key will always be the only element of the array
    if (Array.isArray(productKey)) productKey = productKey[0];

    if (!pg.products) {
      pg.products = [];
    }
    if (!pg.products.includes(productKey)) {
      pg.products.push(productKey);
    }
    const update = {};
    update[`product_groups/${pg.key}/products`] = pg.products;
    updateMetaFirebase(update);

    const product = this.state.allProducts[productKey];
    if ("productGroups" in product) {
      product.productGroups.push(pg.key);
    } else {
      product.productGroups = [pg.key];
    }
    updateFirebase(1, product, productKey);
  };

  deleteProduct = (pg, productKey) => {
    if (!pg.products) {
      pg.products = [];
    }

    if (pg.products.includes(productKey)) {
      const index = pg.products.indexOf(productKey);
      pg.products.splice(index, 1);
    }
    const update = {};
    update[`product_groups/${pg.key}/products`] = pg.products;
    updateMetaFirebase(update);

    const product = this.state.allProducts[productKey];

    if ("productGroups" in product) {
      const idx = product.productGroups.indexOf(pg.key);
      if (idx != -1) {
        product.productGroups.splice(idx, 1);
        updateFirebase(1, product, productKey);
      }
    }
  };

  formatProductDependencies = dependencies => {
    const numDependencies = dependencies.size;

    let sections = "";
    let ix = 0;
    dependencies.forEach(section => {
      const endString = numDependencies - 1 === ix ? "" : ", ";
      sections += section + endString;
      ix += 1;
    });

    const result = `The product group that contains this product is used or referenced in the following sections: ${sections}. Please reconcile these dependencies before deleting this product.`;
    return result;
  };

  handleDeleteFromPG = (pg, productKey) => {
    const dependencies = this.getDependencies(pg.key);
    if (dependencies.size) {
      const errorStr = this.formatProductDependencies(dependencies);
      this.props.openClose.closeAppModal();
      this.props.openClose.setAppModal(
        "Unable to delete product",
        <div>{errorStr}</div>,
        <Stack>
          <Button
            label="Okay"
            onClick={() => {
              this.props.openClose.closeAppModal();
            }}
          />
          {isUserCresicorEmployee() && (
            <Button
              color="error"
              variant="text"
              label="Delete Anyways"
              onClick={() => {
                this.deleteProduct(pg, productKey);
                this.props.openClose.closeAppModal();
              }}
            />
          )}
        </Stack>
      );
    } else {
      this.deleteProduct(pg, productKey);
    }
  };

  handleTogglePricing = pg => {
    const update = {};
    update[`product_groups/${pg.key}/isPricing`] = !pg.isPricing;
    updateMetaFirebase(update);
  };

  addGrossMargin = (pgKey, event, value) => {
    const { productGroups } = this.state;
    productGroups[pgKey].grossMargin = value;
    updateMetaFirebase({ product_groups: productGroups });
  };

  addProductGroup = () => {
    this.props.openClose.setAppModal(
      "Add Product Group",
      <NewProductGroup openClose={this.props.openClose} db={this.props.db} />
    );
  };

  getAssignedProductsJSX = (pg, pgProducts) => {
    const assignedProductsJSXDeps = [];
    if (pgProducts.length == 0) {
      return [
        <div style={styles.wrapper}>
          <Chip style={{ backgroundColor: grey600 }} label="None" />
        </div>,
        assignedProductsJSXDeps
      ];
    }
    return [
      <div style={styles.wrapper}>
        {pgProducts.map((productKey, index) => {
          const productName =
            productKey in this.state.allProducts
              ? this.state.allProducts[productKey].name
              : "Unknown";
          assignedProductsJSXDeps.push(productName);
          return (
            <Chip
              onDelete={
                this.props.db.permissions.includes("admin")
                  ? this.handleDeleteFromPG.bind(null, pg, productKey)
                  : null
              }
              onDoubleClick={this.props.openClose.showRightDrawer.bind(
                null,
                <ProductProfile
                  product={productKey}
                  db={this.props.db}
                  openClose={this.props.openClose}
                />
              )}
              style={styles.chip}
              label={productName}
            />
          );
        })}
      </div>,
      assignedProductsJSXDeps
    ];
  };

  getDependencies = pgKey => {
    const dependencies = new Set();

    // check lines
    for (const line in this.props.db.allLines) {
      if (this.props.db.allLines[line].productGroup == pgKey) {
        dependencies.add("promotions");
      }
    }

    // check pricing
    for (const customer in this.props.db.pricing) {
      if (
        pgKey in this.props.db.pricing[customer] &&
        this.props.db.pricing[customer][pgKey]
      ) {
        dependencies.add("pricing");
      }
    }

    return dependencies;
  };

  deleteProductGroup = pgKey => {
    this.props.openClose.setAppModal(
      "Delete Product Group",
      <div>Are you sure you want to delete this product group?</div>,

      <Stack>
        <Button
          label="Yes, I'm sure"
          variant="text"
          color="error"
          onClick={() => {
            const dependencies = this.getDependencies(pgKey);
            if (dependencies.size) {
              const errorStr = formatDependencies(
                "product group",
                dependencies
              );
              this.props.openClose.closeAppModal();
              this.props.openClose.setAppModal(
                "Unable to delete product group",
                <div>{errorStr}</div>,
                <div>
                  <Button
                    label="Okay"
                    onClick={() => {
                      this.props.openClose.closeAppModal();
                    }}
                  />
                </div>
              );
            } else {
              this.props.openClose.closeAppModal();

              // remove product group from all of its products
              const pg = this.state.productGroups[pgKey];
              const products = pg.products ? pg.products : [];

              for (const productKey of products) {
                const product = this.props.db.products[productKey];
                const idx = product.productGroups.indexOf(pgKey);
                if (idx != -1) {
                  product.productGroups.splice(idx, 1);
                  updateFirebase(1, product, productKey);
                }
              }

              // make sure no bug removes all the products
              const update = {};
              update[`product_groups/${pgKey}`] = null;
              updateMetaFirebase(update);
            }
          }}
        />
        <Button
          label="No, go back"
          variant="text"
          onClick={() => {
            this.props.openClose.closeAppModal();
          }}
        />
      </Stack>
    );
  };

  getPGProductsJSX = pgProducts => {
    const pgProductsJSX = [];
    const pgProductsJSXDeps = [];
    let productsList = objToArray(this.state.allProducts);
    productsList = sortByField("name", productsList, true);
    for (const product of productsList) {
      const { key } = product;
      const checked = pgProducts.includes(key);
      pgProductsJSXDeps.push([key, checked]);
      pgProductsJSX.push(
        <MenuItem
          value={key}
          children={this.props.db.products[key].name}
          key={key}
          disabled={checked}
          checked={checked}
        />
      );
    }
    return [pgProductsJSX, pgProductsJSXDeps];
  };

  getProductGroupsJSX = () => {
    const editPermission = this.props.db.permissions.includes("edit");

    const productGroupsJSX = {
      "Product Group": [],
      "Alternative Name": [],
      "Assigned Products": [],
      "Add New": [],
      "Toggle Pricing": [],
      "Delete Group": []
    };
    const productGroupsJSXDeps = [];
    const productGroupDeps = [];
    const productGroupAltNameDeps = [];
    const totalAssignedProductsJSXDeps = [];
    const totalPgProductsJSXDeps = [];
    const togglePricingDeps = [];

    const pgs = this.state.productGroups
      ? sortByField("name", objToArray(this.state.productGroups), true)
      : [];
    let productsList = objToArray(this.state.allProducts);
    productsList = sortByField("name", productsList);
    const user = firebase.auth().currentUser;
    for (let i = 0; i < pgs.length; i++) {
      const pg = pgs[i];
      const { isPricing } = pg;
      togglePricingDeps.push(isPricing);
      const pgProducts = pg.products || [];
      let pgProductsJSX;
      let pgProductsJSXDeps;
      [pgProductsJSX, pgProductsJSXDeps] = this.getPGProductsJSX(pgProducts);
      totalPgProductsJSXDeps.push(pgProductsJSXDeps);
      productGroupsJSX["Product Group"].push(
        <div
          style={{ cursor: "pointer" }}
          onClick={this.props.openClose.showRightDrawer.bind(
            null,
            <ProductGroupProfile
              pgKey={pg.key}
              db={this.props.db}
              readOnly={this.props.readOnly}
            />
          )}>
          {pg.name}
        </div>
      );
      productGroupDeps.push(pg.name);
      productGroupsJSX["Alternative Name"].push(<div>{pg.altName}</div>);
      productGroupAltNameDeps.push(pg.altName);

      let assignedProductsJSX;
      let assignedProductsJSXDeps;
      [assignedProductsJSX, assignedProductsJSXDeps] =
        this.getAssignedProductsJSX(pg, pgProducts);
      productGroupsJSX["Assigned Products"].push(assignedProductsJSX);
      totalAssignedProductsJSXDeps.push(assignedProductsJSXDeps);
      productGroupsJSX["Add New"].push(
        <Select
          onChange={this.handleAddProduct.bind(null, pg)}
          disabled={!editPermission}
          style={{ marginTop: 5, width: 300 }}
          multiple
          floatingLabelText="Select Product">
          {pgProductsJSX}
        </Select>
      );
      productGroupsJSX["Toggle Pricing"].push(
        editPermission ? (
          <IconButton
            tooltip="Used for pricing"
            style={{ marginTop: 0 }}
            onClick={this.handleTogglePricing.bind(null, pg)}
            size="large">
            <PriceIcon sx={{ color: isPricing ? "success.main" : grey500 }} />
          </IconButton>
        ) : (
          <IconButton
            tooltip="Used for pricing"
            style={{ marginTop: 0 }}
            size="large">
            <PriceIcon sx={{ color: isPricing ? "success.main" : grey500 }} />
          </IconButton>
        )
      );
      productGroupsJSX["Delete Group"].push(
        <IconButton
          tooltip="Delete Product Group"
          style={{ marginTop: 0 }}
          color="error"
          onClick={this.deleteProductGroup.bind(null, pg.key)}
          disabled={!editPermission}
          size="large">
          <DeleteIcon />
        </IconButton>
      );
    }
    productGroupsJSXDeps.push(productGroupDeps);
    productGroupsJSXDeps.push(productGroupAltNameDeps);
    productGroupsJSXDeps.push(totalAssignedProductsJSXDeps);
    productGroupsJSXDeps.push(totalPgProductsJSXDeps);
    productGroupsJSXDeps.push(togglePricingDeps);
    return [productGroupsJSX, productGroupsJSXDeps];
  };

  componentDidMount() {
    const productsJSX = [];
    for (const key in this.props.db.products) {
      productsJSX.push(
        <MenuItem
          value={key}
          primaryText={this.props.db.products[key].name}
          key={key}
        />
      );
    }
    this.setState({
      productGroups: this.props.db.meta.product_groups || {},
      allProducts: this.props.db.products,
      productsJSX
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const productsJSX = [];
    for (const key in nextProps.db.products) {
      productsJSX.push(
        <MenuItem
          value={key}
          primaryText={nextProps.db.products[key].name}
          key={key}
        />
      );
    }
    this.setState({
      productGroups: nextProps.db.meta.product_groups || {},
      allProducts: nextProps.db.products,
      productsJSX
    });
  }

  render() {
    let productGroupsJSX;
    let productGroupsJSXDeps;
    [productGroupsJSX, productGroupsJSXDeps] = this.state.productGroups
      ? this.getProductGroupsJSX()
      : [{}, []];
    return (
      <div>
        <Toolbar>
          <ToolbarGroup>
            <ToolbarTitle text="Product Groups" />
            <ToolbarSeparator />
          </ToolbarGroup>

          <ToolbarGroup>
            {this.props.db.permissions.includes("add") && !this.props.readOnly && (
              <IconButton
                onClick={this.addProductGroup}
                tooltip="Add Product Group"
                size="large">
                <AddBoxIcon />
              </IconButton>
            )}
          </ToolbarGroup>
        </Toolbar>
        <div style={{ padding: 16 }}>
          <div style={{ fontFamily: "Oxygen" }}>
            Click on each product group's name to view and edit additional
            details.
          </div>
          <br />
          <div>
            <DataTableSmart
              title="Manage Product Groups"
              data={productGroupsJSX}
              widths={["13%", "13%", "48%", "12%", "8%", "8%"]}
              deps={productGroupsJSXDeps}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default ProductGroups;
