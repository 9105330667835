import React from "react";
import Box from "@mui/material/Box";
import { styled, Theme } from "@mui/material/styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Menu from "@mui/material/Menu";

type BoxProp = {
  selected: boolean;
  theme: Theme;
};

export const StyledBox = styled(Box)(({ theme, selected }: BoxProp) => ({
  padding: "8px 3px",
  display: "flex",
  alignItems: "baseline",
  borderRadius: theme.spacing(1),
  color: selected ? theme.palette.primary.contrastText : "black",
  backgroundColor: selected ? theme.palette.primary.main : "transparent",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    cursor: "pointer"
  }
}));

export const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)(
  ({ theme }) => ({
    color: theme.palette.primary.inverse,
    fontSize: "14px"
  })
);

export const StyledArrowDropUpIcon = styled(ArrowDropUpIcon)(({ theme }) => ({
  color: theme.palette.primary.inverse,
  fontSize: "14px"
}));

export const StyledMenu = styled(props => (
  <Menu
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center"
    }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    background: theme.palette.readOnly.surface2
  }
}));
