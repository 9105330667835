import React from "react";
import * as jQuery from "jquery";
import moment from "moment";

import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import IconButton from "ui-library/IconButton";
import TextField from "ui-library/TextField";

import DatePicker from "ui-library/DatePicker";

import Button from "ui-library/Button";
import { getTimesJSX } from "helpers/Time";

import { infoDialog } from "helpers/OpenDialog";
import { yellow } from "@mui/material/colors";
import { getCurrentTimeframeIndex } from "./CustomerUtils";

const yellow100 = yellow["100"];
const yellow300 = yellow["300"];

interface IProps {
  meta: any;
  obj: any;
  checkConflicts: Function;
  save: any;
  openClose: any;
  fields: Record<string, string>;
  isTimeframeEditable: Function;
}

class TimeframeEditor extends React.PureComponent<
  IProps,
  { timesJSX: any; obj: any; startYear: number }
> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      timesJSX: getTimesJSX(props.meta),
      obj: jQuery.extend(true, {}, props.obj),
      startYear: new Date().getFullYear()
    };
  }

  handleSave = () => {
    const { obj } = this.state;
    let errors = false;
    (obj.timeframes ?? []).forEach(tf => {
      // NB(daniel): Errors will be visible to the user in bright red.
      if (new Date(tf.expirationDate) < new Date(tf.effectiveDate)) {
        errors = true;
      }
      if (tf.effectiveDate)
        tf.effectiveDate = new Date(tf.effectiveDate).toDateString();
      if (tf.expirationDate)
        tf.expirationDate = new Date(tf.expirationDate).toDateString();
    });
    if (errors) {
      return;
    }
    this.props.save(this.state.obj);
  };

  // this function may result in conflicts
  editTimeframe = (rowIndex: number, date: Date) => {
    const newObj = jQuery.extend(true, {}, this.state.obj);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    // don't let a start date be greater than an end date
    if (
      new Date(year, month, day) < newObj.timeframes[rowIndex].effectiveDate
    ) {
      return;
    }
    if (
      newObj.timeframes[rowIndex + 1].expirationDate &&
      new Date(year, month, day + 1) >
        newObj.timeframes[rowIndex + 1].expirationDate
    ) {
      return;
    }

    newObj.timeframes[rowIndex].expirationDate = new Date(year, month, day);
    newObj.timeframes[rowIndex + 1].effectiveDate = new Date(
      year,
      month,
      day + 1
    );

    const conflicts =
      this.props.checkConflicts && this.props.checkConflicts(newObj);
    if (conflicts) {
      infoDialog(this.props.openClose, conflicts[0], conflicts[1], "Go Back");
    } else {
      this.setState({
        obj: newObj,
        startYear: Math.min(this.state.startYear, year)
      });
    }
  };

  // this function may result in conflicts
  deleteTimeframe = (rowIndex: number) => {
    const newObj = jQuery.extend(true, {}, this.state.obj);
    const startDate = newObj.timeframes[rowIndex].effectiveDate;
    if (startDate) {
      newObj.timeframes[rowIndex + 1].effectiveDate = new Date(startDate);
    } else {
      delete newObj.timeframes[rowIndex + 1].effectiveDate;
    }

    newObj.timeframes.splice(rowIndex, 1);

    const conflicts =
      this.props.checkConflicts && this.props.checkConflicts(newObj);
    if (conflicts) {
      infoDialog(this.props.openClose, conflicts[0], conflicts[1], "Go Back");
    } else {
      this.setState({ obj: newObj });
    }
  };

  // this function guarantees no conflicts
  insertTimeframe = (rowIndex: number) => {
    const newObj = jQuery.extend(true, {}, this.state.obj);
    if (!newObj.timeframes) {
      const newTimeframe = jQuery.extend(true, {}, newObj);
      newObj.timeframes = [newTimeframe];
    }

    const newTimeframe = jQuery.extend(true, {}, newObj.timeframes[rowIndex]);
    let { effectiveDate, expirationDate } = newTimeframe;
    let nextStartDate;
    let nextEndDate;

    if (!expirationDate) {
      if (effectiveDate) {
        nextStartDate = new Date(effectiveDate);
        nextStartDate.setDate(nextStartDate.getDate() + 1);
      } else {
        nextStartDate = new Date();
        nextStartDate.setHours(0, 0, 0, 0);
      }

      expirationDate = new Date(nextStartDate);
    } else {
      nextStartDate = new Date(expirationDate);
      nextEndDate = new Date(nextStartDate);
      expirationDate = new Date(expirationDate);
    }

    expirationDate.setDate(expirationDate.getDate() - 1);

    // split apart two timeframes
    if (expirationDate) {
      newObj.timeframes[rowIndex].expirationDate = expirationDate;
    }
    if (nextStartDate) {
      newTimeframe.effectiveDate = nextStartDate;
    }
    if (nextEndDate) {
      newTimeframe.expirationDate = nextEndDate;
    }

    newObj.timeframes.splice(rowIndex + 1, 0, newTimeframe);

    this.setState({ obj: newObj });
  };

  getTimeframeRowJSX = (startDate, endDate, rowIndex, lastRow) => {
    const timeframe = this.state.obj.timeframes
      ? this.state.obj.timeframes[rowIndex]
      : [this.state.obj];
    const editable = this.props.isTimeframeEditable(timeframe);

    startDate = startDate ? moment(startDate) : startDate;
    endDate = endDate ? moment(endDate) : endDate;

    let startElt = "the beginning of time";
    let endString = "the end of time";
    let endElt;
    if (startDate) {
      startElt = startDate.format("MMM DD, YYYY");
    }
    if (endDate) {
      endString = endDate.format("MMM DD, YYYY");
    }
    if (endDate && !lastRow) {
      endString = undefined;
      endElt = (
        <DatePicker
          type="mobile"
          disableToolbar
          floatingLabelText="Date"
          value={endDate}
          minDate={
            new Date(
              this.state.obj.timeframes?.[rowIndex]?.effectiveDate ?? "1/1/1970"
            )
          }
          maxDate={moment(
            new Date(
              this.state.obj.timeframes?.[rowIndex + 1]?.expirationDate ??
                "12/31/9999"
            )
          )
            .subtract(1, "days")
            .toDate()}
          onChange={newDate => {
            this.editTimeframe(rowIndex, newDate);
          }}
          inputFormat="MMM dd, yyyy"
          disabled={!editable}
        />
      );
    }

    return (
      <tr>
        <td>{startElt}</td>
        <td>to</td>
        <td>{endString ?? endElt}</td>
        <td>
          <IconButton
            onClick={event => this.insertTimeframe(rowIndex)}
            tooltip="Insert Timeframe Below"
            disabled={
              !editable ||
              (startDate &&
                endDate &&
                startDate.date() == endDate.date() &&
                startDate.month() == endDate.month() &&
                startDate.year() == endDate.year())
            }
            size="large">
            <AddIcon />
          </IconButton>
        </td>
        <td>
          <IconButton
            onClick={event => this.deleteTimeframe(rowIndex)}
            tooltip="Delete Timeframe"
            disabled={!editable || lastRow}
            size="large">
            <DeleteForeverIcon />
          </IconButton>
        </td>
        {Object.keys(this.props.fields).map(field => (
          <td>
            <TextField
              floatingLabelText={this.props.fields[field]}
              value={timeframe[field]}
              onChange={event => {
                const { value } = event.target;
                const newObj = jQuery.extend(true, {}, this.state.obj);
                if (!newObj.timeframes?.[rowIndex]) {
                  newObj[field] = value;
                } else {
                  newObj.timeframes[rowIndex][field] = value;
                  if (
                    rowIndex == getCurrentTimeframeIndex(newObj, new Date())
                  ) {
                    newObj[field] = value;
                  }
                }
                this.setState({ obj: newObj });
              }}
              fullWidth
              disabled={!editable}
            />
          </td>
        ))}
      </tr>
    );
  };

  getTimeframeTableJSX = startYear => {
    const timeframeTable = [];
    let showOlder = false;
    if (this.state.obj.timeframes?.length) {
      const numTimeframes = this.state.obj.timeframes.length;
      for (let i = 0; i < numTimeframes; i++) {
        const { effectiveDate, expirationDate } = this.state.obj.timeframes[i];
        if (
          !expirationDate ||
          new Date(expirationDate).getFullYear() >= startYear
        ) {
          timeframeTable.push(
            this.getTimeframeRowJSX(
              effectiveDate,
              expirationDate,
              i,
              i == numTimeframes - 1
            )
          );
        } else {
          showOlder = true;
        }
      }
    } else {
      timeframeTable.push(this.getTimeframeRowJSX(null, null, 0, true));
    }

    if (showOlder) {
      timeframeTable.splice(
        0,
        0,
        <tr>
          <td colSpan="5">
            <Button
              label={`Show Older than ${this.state.startYear}`}
              fullWidth
              backgroundColor={yellow100}
              hoverColor={yellow300}
              onClick={() =>
                this.setState({ startYear: this.state.startYear - 1 })
              }
            />
          </td>
        </tr>
      );
    }

    return timeframeTable;
  };

  componentDidMount() {}

  render() {
    return (
      <div>
        <table
          style={{
            "border-collapse": "separate",
            "border-spacing": "50px 50px"
          }}>
          {this.getTimeframeTableJSX(this.state.startYear)}
        </table>
        <br />
        <Button label="Save" onClick={this.handleSave} />
      </div>
    );
  }
}

export default TimeframeEditor;
