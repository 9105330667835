// logic for each action type is found in reducers.js

/*
 * action types
 */

export const CHANGE_STEP = "CHANGE_STEP";
export const CLEAR_LINE = "CLEAR_LINE";
export const UPDATE_FIELD = "UPDATE_FIELD_PROMOTION";
export const RESET = "RESET_PROMOTION";
export const INCREMENT_LINE = "INCREMENT_LINE";
export const ADD_CONTACT = "ADD_CONTACT";
export const ADD_FILES = "ADD_FILES";
export const REMOVE_FILE = "REMOVE_FILE";
export const CHANGE_MODE = "CHANGE_MODE";
export const UPDATE_FILE_ATTRIBUTES = "UPDATE_FILE_ATTRIBUTES";
export const SET_DEFAULT = "SET_DEFAULT_PROMOTION";
export const SET_LINE_INDEX = "SET_LINE_INDEX";
export const SET_FILE_INFO = "SET_FILE_INFO";

/*
 * action creators
 */

export function changeStep(forward, db, mode) {
  // true => go forward, false => go back
  return { type: CHANGE_STEP, forward, db, mode };
}

export function clearLine(db, line, newFields) {
  return { type: CLEAR_LINE, db, line, newFields };
}

export function updateField(db, field, value, mon, yr) {
  return { type: UPDATE_FIELD, db, field, value, mon, yr };
}

export function reset() {
  return { type: RESET };
}

export function incrementLine(direction, lineName, db) {
  // direction: true => add line, false => subtract line
  // lineName: only needed in subtract case
  return { type: INCREMENT_LINE, direction, lineName, db };
}

export function addContact() {
  return { type: ADD_CONTACT };
}

export function addFiles(file) {
  return { type: ADD_FILES, file };
}

export function removeFile(file) {
  return { type: REMOVE_FILE, file };
}

export function changeMode(mode) {
  return { type: CHANGE_MODE, mode };
}

export function updateFileAttributes(index, newAttributes) {
  return { type: UPDATE_FILE_ATTRIBUTES, index, newAttributes };
}

export function setDefault(dp, callback, db, mode) {
  return { type: SET_DEFAULT, dp, callback, db, mode };
}

export function setSelectedLineIndex(newIndex) {
  return { type: SET_LINE_INDEX, newIndex };
}

export function setFileInfo(fileInfo, filesDownloaded) {
  return { type: SET_FILE_INFO, fileInfo, filesDownloaded };
}
