import React from "react";

import {
  Bar,
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Brush,
  ReferenceLine
} from "recharts";

import Card from "ui-library/Card";
import { graphPalette } from "helpers/ColorPalettes";

const { purple70 } = graphPalette;

class CustomTooltip extends React.Component {
  render() {
    const { active } = this.props;
    if (active) {
      const { payload, label } = this.props;
      return (
        <div className="custom-tooltip">
          <p className="desc">{label}</p>
          <p className="desc" style={{ color: payload[0].color }}>
            {`Differential: ${payload[0].value}%`}
          </p>
        </div>
      );
    }

    return null;
  }
}

export default class DiffBrushGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Card title="Actual vs. Expected Differential (%)">
        {this.props.data && this.props.data.length > 0 ? (
          <ResponsiveContainer width="100%" aspect={2}>
            <BarChart data={this.props.data}>
              <XAxis dataKey="date" />
              <YAxis />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip content={<CustomTooltip />} />
              <ReferenceLine y={0} stroke="#000" />
              <Bar dataKey="percent" fill={purple70} />
              <Brush dataKey="date" height={30} stroke={purple70} />
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <div className="centering" style={{ margin: 16 }}>
            No data available.
          </div>
        )}
      </Card>
    );
  }
}
