import React from "react";
import { connect } from "react-redux";
import { isNil } from "lodash";
import moment from "moment";
import AutoComplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TextField from "ui-library/TextField";
import SelectField from "ui-library/Select";
import MenuItem from "ui-library/MenuItem";
import IconButton from "ui-library/IconButton";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import Toggle from "ui-library/Toggle";
import Chip from "@mui/material/Chip";
import { Divider } from "pui-react-dividers";
import {
  generateRandomKey,
  firebase,
  downloadFilesFromStorage
} from "helpers/Firebase";
import { months } from "helpers/DataProcessing";
import Mixpanel from "helpers/Mixpanel";
import { grey, red, green, common } from "@mui/material/colors";
import DataTable from "../tables/MuiDataTable";
import FilePicker from "../WebsiteElements/FilePicker";
import { setAdvDuplicationError } from "../Planning/actions";
import { getVisibleDistributors } from "../Customers/CustomerUtils";

const grey500 = grey["500"];
const grey700 = grey["700"];
const red500 = red["500"];
const green500 = green["500"];
const { black } = common;

const styles = {
  header: {
    marginRight: "-50px",
    fontSize: 20,
    color: black
  },
  checkbox: {
    padding: 0
  },
  divider: {
    margin: "30px auto",
    width: "50%"
  },
  scrollbar: {
    height: 200,
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: grey700
  }
};

const Subheader = props => <div {...props} />;

class AddPromotionContinuously extends React.Component {
  constructor(props) {
    super(props);

    const metaStartYear = parseInt(props.db.meta.start_year);
    const curYear = new Date().getFullYear();
    const checkboxStates = { numChecked: 0 };
    const startYear = Math.max(metaStartYear, curYear - 1);
    for (let i = startYear; i < curYear + 3; i++) {
      checkboxStates[i] = {};
      for (let j = 0; j < 12; j++) {
        checkboxStates[i][j] = false;
      }
    }

    this.state = {
      finished: false,
      loading: false,
      error: true,
      copyAttachments: true,
      copyComments: true,
      copyTags: true,
      defaultProm: {},
      db: null,
      newProms: {},
      defaultName: "",
      repeatFrequency: 1,
      numRepeats: "",
      repeatEndDate: null,
      maxRepeats: 12,
      checkboxStates,
      doneLoading: false,
      distributorDict: {},
      filesToSelectFrom: [],
      filesAttachedToProm: {},
      companyId: null,
      promFilesWithBlobs: {},
      allFilesDownloaded: false,
      names: {},
      customerDict: {},
      customerSearchText: {},
      promsAlreadyMade: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps?.stepIndex === 1) {
      const newProms = this.state.promsAlreadyMade
        ? this.state.newProms
        : this.makeContinuousPromotions();
      this.populateTableData(newProms, () => {
        for (const promKey in newProms) {
          const prom = newProms[promKey];
          prom.name = this.state.names[promKey];
          prom.customer = this.state.customerDict[promKey];
          prom.customerName =
            this.props.db.customers[this.state.customerDict[promKey]].name;
          prom.distributor = this.state.distributorDict[promKey];
        }
        this.setState({ newProms, promsAlreadyMade: true }, this.handleError);
      });
    } else if (nextProps?.stepIndex === 2) {
      const newProms = jQuery.extend(true, {}, this.state.newProms);
      for (const promKey in newProms) {
        const prom = newProms[promKey];
        prom.name = this.state.names[promKey];
        prom.customer = this.state.customerDict[promKey];
        prom.customerName =
          this.props.db.customers[this.state.customerDict[promKey]].name;
        prom.distributor = this.state.distributorDict[promKey];
      }
      this.setState({ newProms }, this.populateOldAttachments);
    }
    if (nextProps?.continueFromAdvDuplicationModal) {
      this.onEnter();
    }
  }

  populateTableData = (newProms, callback) => {
    // delete distributors that no longer exist
    const distributorDict = {};
    const customerDict = {};
    const customerSearchText = {};
    let nameDict = {};
    for (const promKey in newProms) {
      const prom = newProms[promKey];
      const defaultProm = jQuery.extend(true, {}, prom);
      if (defaultProm.distributor) {
        const customer = this.props.db.customers[defaultProm.customer];
        const distributors = getVisibleDistributors(
          defaultProm.customer,
          customer,
          new Date(defaultProm.year, defaultProm.month - 1)
        );
        const distributorNames = distributors.map(
          x => this.props.db.customers[x].name
        );
        defaultProm.distributor = defaultProm.distributor.filter(x =>
          distributorNames.includes(x)
        );
        if (!defaultProm.distributor.length) {
          defaultProm.distributor = null;
        }
      }

      nameDict[defaultProm.promKey] = defaultProm.name;
      customerDict[defaultProm.promKey] = defaultProm.customer;
      customerSearchText[defaultProm.promKey] =
        this.props.db.customers[defaultProm.customer].name;
      distributorDict[defaultProm.promKey] = defaultProm.distributor;
    }

    nameDict = this.changePromNames(nameDict, newProms);

    this.setState(
      {
        names: nameDict,
        customerDict,
        customerSearchText,
        distributorDict
      },
      callback
    );
  };

  isValid = id => {
    if (id == "numRepeats") {
      return !(
        this.state.numRepeats != "" &&
        (parseInt(this.state.numRepeats) != this.state.numRepeats ||
          this.state.numRepeats <= 0 ||
          this.state.numRepeats > this.state.maxRepeats)
      );
    }
    if (id == "defaultName") {
      return this.state.defaultName != "";
    }
    if (id == "repeatEndDate") {
      return this.state.repeatEndDate != null;
    }
    if (id == "distributorDict") {
      for (var key in this.state.distributorDict) {
        if (!this.state.distributorDict[key]?.length) {
          return false;
        }
      }
      return true;
    }
    if (id == "customerDict") {
      for (var key in this.state.customerDict) {
        if (!this.state.customerDict[key]) {
          return false;
        }
      }
      return true;
    }
    if (id == "promNames") {
      if (this.props.stepIndex < 1) return true;
      for (const promKey in this.state.newProms) {
        // non-empty string means error
        if (this.checkPromNames(promKey)) return false;
      }
      return true;
    }
  };

  downloadExistingPromFiles = () => {
    const {
      state: { companyId, promFilesWithBlobs },
      props: { proms }
    } = this;

    const totalFiles = proms.reduce(
      (acc, { fileInfo = [] }) => acc + fileInfo.length,
      0
    );

    if (totalFiles === 0) {
      return this.setState({
        allFilesDownloaded: true
      });
    }

    let downloadedFiles = 0;

    proms.forEach(({ promKey, fileInfo = [] }) => {
      if (fileInfo.length) {
        const fileNames = fileInfo.map(({ name }) => name);
        const filePaths = fileNames.map(
          name => `${companyId}/Promotion Files/${promKey}/${name}`
        );
        downloadFilesFromStorage(
          filePaths,
          fileNames,
          (blob, fileName) => {
            if (blob) {
              const convertedFile = new File([blob], fileName);
              const fileIndex = fileNames.indexOf(fileName);
              fileInfo[fileIndex].file = convertedFile;
              promFilesWithBlobs[promKey] = fileInfo.map(file => file);
              this.setState({ promFilesWithBlobs });
            }
            downloadedFiles += 1;
            if (downloadedFiles === totalFiles) {
              this.setState(
                { allFilesDownloaded: true },
                this.populateOldAttachments
              );
            }
          },
          () => {
            this.props.openClose.showSnack("Could not download files");
            this.setState({
              allFilesDownloaded: true
            });
          }
        );
      }
    });
  };

  makeNewPromotion = (prom, curDate) => {
    const defaultProm = jQuery.extend(true, {}, prom);
    let numMonths = (curDate.getFullYear() - prom.year) * 12;
    numMonths += curDate.getMonth() + 1 - prom.month;

    prom.promKey = generateRandomKey(); // overwrites promKey of original
    // change new promotion's line keys
    const newLines = {};
    for (var [index, key] of Object.keys(prom.lines).entries()) {
      newLines[`${prom.promKey}-${index}`] = prom.lines[key];
    }
    prom.lines = newLines;
    // change other fields
    prom.month = curDate.getMonth() + 1;
    prom.year = curDate.getFullYear();
    prom.name = `${
      this.props.proms.length > 1 ? prom.name : this.state.defaultName
    } ${curDate.toLocaleString("en-us", {
      month: "long"
    })} ${curDate.getFullYear()}`;

    let dupl = 1;
    let checkPromName = prom.name;

    // make sure this promotion's name is not a duplicate
    let promNameExists;
    do {
      promNameExists = false;
      for (var key in this.props.db.promotions) {
        const existingProm = this.props.db.promotions[key];
        if (
          this.props.db.meta.statuses[existingProm.status] != "Cancelled" &&
          checkPromName === existingProm.name
        ) {
          promNameExists = true;
          checkPromName = `${prom.name} (${dupl.toString()})`;
          dupl += 1;
          break;
        }
      }
    } while (promNameExists);

    prom.name = checkPromName;
    prom.customer =
      this.state.customerDict[defaultProm.promKey] ?? defaultProm.customer;
    prom.customerName =
      this.props.db.customers[
        this.state.customerDict[defaultProm.promKey] ?? defaultProm.customer
      ].name;
    prom.distributor =
      this.state.distributorDict[defaultProm.promKey] ??
      defaultProm.distributor;
    prom.status = this.props.db.meta.statuses.indexOf("Pending");
    for (const lineKey in prom.lines) {
      // open all lines within this promotion
      delete prom.lines[lineKey].closed;

      for (const field in prom.lines[lineKey]) {
        const typeFields =
          this.props.db.meta.fundTypes?.[prom.lines[lineKey].type]
            ?.promCreationFields ?? [];

        if (
          (field == "endDate" || field.endsWith("EndDate")) &&
          !typeFields.includes("buyin") &&
          !typeFields.includes("instore") &&
          !typeFields.includes("scanback")
        ) {
          // special case if the start/end dates are not selected
          // and we want the end date to be the end of each month
          const newEndDate = moment(prom.lines[lineKey][field]).add(
            numMonths,
            "months"
          );
          prom.lines[lineKey][field] = new Date(
            newEndDate.year(),
            newEndDate.month() + 1,
            0
          ).toDateString();
        } else if (field.endsWith("Date")) {
          prom.lines[lineKey][field] = moment(prom.lines[lineKey][field])
            .add(numMonths, "months")
            .format("ddd MMM DD YYYY");
        }

        if (field.startsWith("buyin")) {
          const distributorName = prom.distributor?.[0];
          const distributorId = Object.entries(this.props.db.customers)
            .filter(([key, field]) => field.name === distributorName)?.[0][0]
            .toString();
          const buyInConflict = prom.distributor.length !== 1;
          const useBuyIn =
            this.props.db.meta?.use_buyin_calendar?.[distributorId];

          const buyInCalendar =
            this.props.db.meta?.buyin_calendar?.[distributorId];
          const buyInDates =
            buyInCalendar?.[prom.year.toString()]?.[prom.month - 1];

          if (!buyInConflict && useBuyIn && buyInDates) {
            prom.lines[lineKey].buyinStartDate = buyInDates.start;
            prom.lines[lineKey].buyinEndDate = buyInDates.end;
          }
        }
      }
    }

    // this is an unconfirmed promotion that has been duplicated from another
    prom.duplicatedFrom = defaultProm;

    // overwrite modified and created fields
    const user = firebase.auth().currentUser;
    const { uid } = user;
    const userName = this.props.db.companyUsers[uid].name;
    prom.created = {
      user: userName,
      uid: user.uid,
      date: new Date().toDateString(),
      time: new Date().toLocaleTimeString()
    };

    prom.modified = {
      user: userName,
      uid: user.uid,
      date: new Date().toDateString(),
      time: new Date().toLocaleTimeString()
    };

    if (!this.state.copyComments) {
      delete prom.comments; // is this used anymore?
      delete prom.commentList;
    } else if (prom.commentList && prom.commentList.length > 0) {
      for (let i = 0; i < prom.commentList.length; i++) {
        prom.commentList[i].uid = user.uid;
        prom.commentList[i].user = userName;
        prom.commentList[i].date = new Date().toDateString();
      }
    }

    // get rid of existing fields

    delete prom.starred;
    delete prom.closed;
    delete prom.spendKeys;
    delete prom.actMoneyList;

    return prom;
  };

  makeContinuousPromotions = () => {
    // Create continuous set of promotions with the given parameters.
    // Returns object of new promotions, indexed by newly generated keys.
    const newProms = {};

    for (var defaultProm of this.props.proms) {
      const currentDate = new Date(
        Math.min.apply(
          null,
          Object.keys(defaultProm.lines).map(
            lineKey => new Date(defaultProm.lines[lineKey].startDate)
          )
        )
      );
      // current month/year for the promotion
      let currentMonthYear = new Date(
        defaultProm.year,
        defaultProm.month - 1,
        1
      );
      const { repeatEndDate } = this.state;
      // numRepeats is always 1 if multiple promotions selected
      let { numRepeats } = this.state;
      const { repeatFrequency } = this.state;

      const monthYears = [];

      if (this.state.checkboxStates.numChecked > 0) {
        // SINGLE PROMOTION: checkboxes
        for (const year in this.state.checkboxStates) {
          if (year == "numChecked") continue;
          for (const month in this.state.checkboxStates[year]) {
            if (this.state.checkboxStates[year][month]) {
              monthYears.push({
                month: parseInt(month),
                year: parseInt(year)
              });
            }
          }
        }
      } else {
        // SINGLE OR MULTIPLE PROMOTIONS: repeat frequency / num repeats
        if (numRepeats == "" && repeatEndDate != "") {
          const monthsDiff = moment(repeatEndDate).diff(currentDate, "months");
          numRepeats = parseInt(monthsDiff / repeatFrequency);
        }

        for (let ii = 1; ii <= numRepeats; ii++) {
          currentMonthYear = moment(currentMonthYear)
            .add(this.state.repeatFrequency, "months")
            .toDate();
          monthYears.push({
            month: currentMonthYear.getMonth(),
            year: currentMonthYear.getFullYear()
          });
        }
      }

      for (let i = 0; i < monthYears.length; i++) {
        let prom = jQuery.extend(true, {}, defaultProm);
        const curDate = new Date(monthYears[i].year, monthYears[i].month, 1);

        prom = this.makeNewPromotion(prom, curDate);

        newProms[prom.promKey] = prom;
      }
    }

    return newProms;
  };

  onEnter = () => {
    let mode = "";
    if (this.state.checkboxStates.numChecked > 0) {
      mode = "Checkboxes";
    } else if (this.state.numRepeats !== "") {
      mode = "Occurrences";
    } else if (this.state.repeatEndDate !== null) {
      mode = "End Date";
    }

    const { newProms, filesAttachedToProm, copyTags, copyComments } =
      this.state;

    Mixpanel.track("Advanced Duplication", {
      "Duplication Method": mode,
      "Duplicated Quantity": Object.keys(newProms).length
    });

    Object.keys(newProms).forEach(promKey => {
      // Attach files
      const promFiles = filesAttachedToProm[promKey];
      if (promFiles?.length) {
        newProms[promKey].fileInfo = promFiles;
      } else {
        newProms[promKey].fileInfo = [];
      }
      // Attach tags
      if (!copyTags) {
        newProms[promKey].tags = [];
      }
      // Attach comments
      if (!copyComments) {
        delete newProms[promKey].comments;
        delete newProms[promKey].commentList;
      }
    });

    this.props.callback(newProms);
    this.props.openClose.closeAppModal();
  };

  checkPromNames = promKey => {
    if (!this.state.names[promKey]) {
      return "This field is required.";
    }
    for (var promKey in this.state.newProms) {
      const prom = this.state.newProms[promKey];
      // different promotion, but same name => error
      if (
        prom.promKey != promKey &&
        this.state.names[prom.promKey] == this.state.names[promKey]
      ) {
        return "Duplicate promotion name.";
      }
    }
    return "";
  };

  // promKey is optional, ignored if id != promNames
  getErrorText = (id, promKey) => {
    // NB(daniel): this prevents red text from flashing for a split second
    if (!this.state.doneLoading) {
      return "";
    }

    if (id == "numRepeats") {
      if (!this.isValid(id)) {
        return "Must be a positive integer.";
      }
      if (this.state.numRepeats > this.state.maxRepeats) {
        return `Cannot duplicate more than ${this.state.maxRepeats.toString()} times at once.`;
      }
      return "";
    }
    if (id == "customer") {
      if (!this.isValid(id)) {
        return "This field is required.";
      }
      return "";
    }
    if (id == "promNames") {
      return this.checkPromNames(promKey);
    }
    if (id == "firstReceiver") {
      const defaultProm = this.props.proms[0];
      const defaultDist = this.state.distributorDict[defaultProm.promKey];
      if (!defaultDist?.length) {
        return "This field is required.";
      }
      return "";
    }
  };

  getSelectDistributors = (customerKey, month, year) => {
    const selectDistributors = [];
    if (customerKey in this.props.db.customers) {
      const customer = this.props.db.customers[customerKey];
      const distributors = getVisibleDistributors(
        customerKey,
        customer,
        new Date(year, month - 1)
      );
      if (distributors) {
        for (let i = 0; i < distributors.length; i++) {
          const distributor = distributors[i];
          const distributorName = this.props.db.customers[distributor]
            ? this.props.db.customers[distributor].name
            : distributor;
          selectDistributors.push(
            <MenuItem
              value={distributorName}
              insetChildren
              checked={
                this.state.distributor
                  ? this.state.distributor.includes(distributorName)
                  : false
              }
              children={distributorName}
              key={i}
            />
          );
        }
      }
    }
    return selectDistributors;
  };

  handleError = () => {
    if (
      this.isValid("defaultName") &&
      ((this.isValid("numRepeats") && this.state.numRepeats != "") ||
        this.isValid("repeatEndDate") ||
        this.state.checkboxStates.numChecked > 0 ||
        this.props.proms.length > 1) &&
      this.isValid("distributorDict") &&
      this.isValid("customerDict") &&
      this.isValid("promNames")
    ) {
      this.setState({ error: false });
      this.props.dispatch(setAdvDuplicationError(false));
    } else {
      this.setState({ error: true });
      this.props.dispatch(setAdvDuplicationError(true));
    }
  };

  handleRepeatFrequencyChange = event => {
    this.setState({ repeatFrequency: event.target.value, repeatEndDate: null });
  };

  handleFieldChange = (id, event) => {
    const { value } = event.target;
    if (id == "defaultName") {
      this.setState({ defaultName: value }, this.handleError);
    } else if (id == "numRepeats" || id == "endDate") {
      this.setState(
        {
          numRepeats: id == "numRepeats" ? value : "",
          repeatEndDate: id == "endDate" ? value : null
        },
        this.handleError
      );
    }
  };

  handleDateChange = date => {
    this.setState(
      {
        numRepeats: "",
        repeatEndDate: date
      },
      this.handleError
    );
  };

  handleTableNameChange = (promKey, event) => {
    const { value } = event.target;
    const newNames = jQuery.extend(true, {}, this.state.names);
    newNames[promKey] = value;
    this.setState({ names: newNames }, this.handleError);
  };

  handleTableCustomerChange = (promKey, event, value) => {
    const newCustomerDict = jQuery.extend(true, {}, this.state.customerDict);
    const newDistributorDict = jQuery.extend(
      true,
      {},
      this.state.distributorDict
    );
    newCustomerDict[promKey] = value.value;
    newDistributorDict[promKey] = null;
    this.setState(
      {
        customerDict: newCustomerDict,
        distributorDict: newDistributorDict
      },
      this.handleError
    );
  };

  handleTableCustomerTextChange = (promKey, event, value) => {
    if (isNil(event)) return;
    const newCustomerSearchText = jQuery.extend(
      true,
      {},
      this.state.customerSearchText
    );
    newCustomerSearchText[promKey] = value;
    this.setState(
      {
        customerSearchText: newCustomerSearchText
      },
      this.handleError
    );
  };

  handleTableDistChange = (promKey, event) => {
    const { value } = event.target;
    const newDistributorDict = jQuery.extend(
      true,
      {},
      this.state.distributorDict
    );
    newDistributorDict[promKey] = value.length == 0 ? null : value;
    this.setState(
      {
        distributorDict: newDistributorDict
      },
      this.handleError
    );
  };

  handleTableCopy = promKey => {
    const newCustomerDict = {};
    const newCustomerSearchText = {};
    const newDistributorDict = {};
    let newNames = {};
    for (const key in this.state.customerDict) {
      newCustomerDict[key] = this.state.customerDict[promKey];
      const customerKey = this.state.customerDict[promKey];
      const customer = this.props.db.customers[customerKey];
      newCustomerSearchText[key] = customer.name;
      newDistributorDict[key] = this.state.distributorDict[key] ?? [];
      newDistributorDict[key].push(
        ...Array.from(this.state.distributorDict[promKey] ?? [])
      );
      newDistributorDict[key] = Array.from(new Set(newDistributorDict[key]));

      const distributors = getVisibleDistributors(
        customerKey,
        customer,
        new Date(
          this.state.newProms[key].year,
          this.state.newProms[key].month - 1
        )
      );
      const distributorNames = distributors.map(
        x => this.props.db.customers[x].name
      );
      newDistributorDict[key] = newDistributorDict[key].filter(x =>
        distributorNames.includes(x)
      );

      newNames[key] = this.state.names[promKey];
    }
    newNames = this.changePromNames(newNames, this.state.newProms);
    this.setState(
      {
        customerDict: newCustomerDict,
        customerSearchText: newCustomerSearchText,
        distributorDict: newDistributorDict,
        names: newNames
      },
      this.handleError
    );
  };

  getCheckboxJSX = () => {
    const dataTableInfo = {};
    dataTableInfo[" "] = [];
    for (var year in this.state.checkboxStates) {
      if (year == "numChecked") continue;
      dataTableInfo[" "].push(year);
    }

    for (let month = 0; month < months.length; month++) {
      dataTableInfo[months[month]] = [];
      for (var year in this.state.checkboxStates) {
        if (year == "numChecked") continue;
        dataTableInfo[months[month]].push(
          <Checkbox
            onChange={this.handleCheckboxChange.bind(null, month, year)}
            checked={this.state.checkboxStates[year][month]}
            color="primary"
            style={styles.checkbox}
          />
        );
      }
    }
    return dataTableInfo;
  };

  getPromotionInfoJSX = () => {
    const name = "Name";
    const customer = "Customer";
    const copy = " ";
    const firstReceiver = "First Receiver";
    const columns = [name, customer, copy, firstReceiver];
    const dataTableInfo = {};
    for (const col of columns) {
      dataTableInfo[col] = [];
    }
    let allCustomerNames = [];
    for (const key in this.props.db.customers) {
      allCustomerNames.push({
        text: this.props.db.customers[key].name,
        value: key
      });
    }
    allCustomerNames = allCustomerNames.sort((x, y) => x.text < y.text);

    if (!this.state.distributorDict || !this.state.names) return dataTableInfo;

    for (const promKey in this.state.newProms) {
      const prom = this.state.newProms[promKey];
      dataTableInfo[name].push(
        <TextField
          value={this.state.names[prom.promKey]}
          onChange={this.handleTableNameChange.bind(null, prom.promKey)}
          errorText={this.getErrorText("promNames", prom.promKey)}
          fullWidth
        />
      );

      dataTableInfo[customer].push(
        <AutoComplete
          inputValue={this.state.customerSearchText[prom.promKey]}
          options={allCustomerNames}
          renderOption={(props, option) => <li {...props}>{option.text}</li>}
          getOptionLabel={option => option.text}
          onChange={this.handleTableCustomerChange.bind(null, prom.promKey)}
          disableClearable
          onInputChange={this.handleTableCustomerTextChange.bind(
            null,
            prom.promKey
          )}
          fullWidth
          freeSolo
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-contact"
              }}
            />
          )}
        />
      );

      const selectDistributors = this.getSelectDistributors(
        this.state.customerDict[prom.promKey],
        prom.month,
        prom.year
      );
      dataTableInfo[firstReceiver].push(
        <SelectField
          value={this.state.distributorDict[prom.promKey]}
          multiple
          onChange={this.handleTableDistChange.bind(null, prom.promKey)}
          errorText={
            this.state.distributorDict[prom.promKey]?.length
              ? ""
              : "This field is required."
          }
          fullWidth>
          {selectDistributors}
        </SelectField>
      );

      dataTableInfo[copy].push(
        <IconButton
          tooltip="Copy to All"
          style={{ marginTop: -10, marginLeft: -10 }}
          onClick={this.handleTableCopy.bind(null, prom.promKey)}
          size="large">
          <FileCopyOutlinedIcon />
        </IconButton>
      );
    }

    return dataTableInfo;
  };

  handleCheckboxChange = (month, year, event) => {
    const isInputChecked = event.target.checked;
    const { checkboxStates } = this.state;
    checkboxStates[year][month] = isInputChecked;
    checkboxStates.numChecked += isInputChecked ? 1 : -1;

    this.setState({ checkboxStates }, this.handleError);
  };

  // change promotion names so that they appear "numMonths" ahead
  changePromNames = (oldNames, proms) => {
    const fullMonths = moment.months();
    const lowercaseMonths = moment.months().map(x => x.toLowerCase());
    const lowercaseMonthsShort = moment.monthsShort().map(x => x.toLowerCase());
    const currentYear = new Date().getFullYear();
    const relevantYears = [...Array(15).keys()].map(x =>
      (x - 8 + currentYear).toString()
    );

    const newNames = {};
    for (var promKey in proms) {
      const prom = proms[promKey];
      const promMonth = prom.month;
      const promYear = prom.year;
      var { promKey } = prom;
      const name = oldNames[promKey];
      const pieces = name.split(" ");

      const newPieces = [];

      // delete months and years from current name
      for (let i = 0; i < pieces.length; i++) {
        const x = pieces[i].toLowerCase();
        if (lowercaseMonths.includes(x) || lowercaseMonthsShort.includes(x))
          continue;
        if (relevantYears.includes(x)) continue;
        newPieces.push(pieces[i]);
      }

      // add new month / year to end
      newPieces.push(fullMonths[promMonth - 1]);
      newPieces.push(promYear);

      newNames[promKey] = newPieces.join(" ");
    }
    return newNames;
  };

  populateOldAttachments = () => {
    const {
      copyAttachments,
      newProms,
      promFilesWithBlobs,
      allFilesDownloaded
    } = this.state;
    if (allFilesDownloaded) {
      Object.entries(newProms).forEach(([promKey, prom]) => {
        const { duplicatedFrom: { promKey: originalPromKey } = {} } = prom;
        const existingPromFiles = promFilesWithBlobs?.[originalPromKey];
        if (existingPromFiles) {
          const existingPromFileNames = existingPromFiles.map(
            ({ name }) => name
          );
          this.setState(
            ({
              filesAttachedToProm,
              filesAttachedToProm: { [promKey]: promFiles = [] }
            }) => ({
              filesAttachedToProm: {
                ...filesAttachedToProm,
                [promKey]: copyAttachments
                  ? [...new Set([...existingPromFiles, ...promFiles])]
                  : promFiles.filter(
                      ({ name }) => !existingPromFileNames.includes(name)
                    )
              }
            })
          );
        }
      });
    }
  };

  handleCopyAttachments = event => {
    this.setState(
      { copyAttachments: event.target.checked },
      this.populateOldAttachments
    );
  };

  getContentCopyJSX = () => {
    return (
      <div className="centering">
        <Toggle
          color="primary"
          checked={this.state.copyAttachments}
          onChange={this.handleCopyAttachments}
          label="Copy attachments?"
        />
        <div style={{ width: "10%" }} />
        <Toggle
          color="primary"
          checked={this.state.copyComments}
          onChange={event =>
            this.setState({ copyComments: event.target.checked })
          }
          label="Copy comments?"
        />
        <div style={{ width: "10%" }} />
        <Toggle
          color="primary"
          checked={this.state.copyTags}
          onChange={event => this.setState({ copyTags: event.target.checked })}
          label="Copy tags?"
        />
      </div>
    );
  };

  componentDidMount() {
    let companyId = null;
    const user = firebase.auth().currentUser;
    firebase
      .database()
      .ref(`users/${user.uid}/company_id`)
      .on("value", snapshot => {
        if (snapshot.val()) {
          companyId = snapshot.val();
        }
      });

    this.setState(
      {
        proms: this.props.proms,
        defaultName: this.props.proms[0].name,
        doneLoading: true,
        repeatFrequency: this.props.proms.length > 1 ? 12 : 1,
        numRepeats: this.props.proms.length > 1 ? 1 : "",
        companyId
      },
      () => {
        this.handleError();
        this.downloadExistingPromFiles();
      }
    );
  }

  addFiles = files => {
    this.setState(({ filesToSelectFrom }) => ({
      filesToSelectFrom: files.reduce((acc, file) => {
        const { name } = file;
        const fileAlreadyAdded = filesToSelectFrom.some(
          ({ name: fileName }) => name === fileName
        );
        return fileAlreadyAdded
          ? acc
          : [
              ...acc,
              {
                file,
                name,
                isContract: false,
                attachedLine: [0]
              }
            ];
      }, filesToSelectFrom)
    }));
  };

  addFilesToProm = promKey => (event, index, fileIndex) => {
    const {
      filesToSelectFrom,
      filesAttachedToProm,
      filesAttachedToProm: { [promKey]: promFiles = [] }
    } = this.state;
    const file = filesToSelectFrom[fileIndex];
    this.setState({
      filesAttachedToProm: {
        ...filesAttachedToProm,
        [promKey]: [...promFiles, file]
      }
    });
  };

  removeFileFromProm = (promKey, fileIndex) => () => {
    const {
      filesAttachedToProm,
      filesAttachedToProm: { [promKey]: promFiles }
    } = this.state;
    this.setState({
      filesAttachedToProm: {
        ...filesAttachedToProm,
        [promKey]: promFiles.filter((file, index) => index !== fileIndex)
      }
    });
  };

  getFileUploadJSX = () => {
    const fileUploadData = { Name: [], Files: [], "Add New": [] };
    const {
      filesToSelectFrom,
      filesAttachedToProm,
      newProms,
      allFilesDownloaded,
      copyAttachments
    } = this.state;
    // Populate promotion names
    fileUploadData.Name = Object.values(newProms).map(({ name }) => name);
    // Populate attached files
    fileUploadData.Files = Object.values(newProms).map(({ promKey }) => {
      if (copyAttachments && !allFilesDownloaded) {
        return <span>Downloading attached files, please wait...</span>;
      }
      if (filesAttachedToProm[promKey] && filesAttachedToProm[promKey].length) {
        return filesAttachedToProm[promKey].map(({ name }, index) => (
          <Chip
            style={{ margin: 4 }}
            label={name}
            onDelete={this.removeFileFromProm(promKey, index)}
          />
        ));
      }
      return <span>No Files Selected</span>;
    });
    // Populate files to choose from
    fileUploadData["Add New"] = Object.values(newProms).map(({ promKey }) => (
      <SelectField
        key={promKey}
        floatingLabelText="Select File(s)"
        value={filesToSelectFrom}
        onChange={this.addFilesToProm(promKey)}
        fullWidth
        disabled={filesToSelectFrom.length === 0}>
        {filesToSelectFrom.map(({ name }, index) => {
          const fileAlreadyAttached = filesAttachedToProm[promKey]?.some(
            ({ name: fileName }) => name === fileName
          );
          return (
            <MenuItem
              children={name}
              checked={fileAlreadyAttached}
              disabled={fileAlreadyAttached}
              value={index}
              key={index}
            />
          );
        })}
      </SelectField>
    ));
    return fileUploadData;
  };

  render() {
    // defaultProm and selectDistributors only relevant if
    // exactly one promotion selected
    const defaultProm = this.props.proms[0];
    const selectDistributors = this.getSelectDistributors(
      defaultProm.customer,
      defaultProm.month,
      defaultProm.year
    );

    const manageFilesSection = (
      <>
        {this.getContentCopyJSX()}
        <br />
        <div style={{ color: grey500 }}>
          <FilePicker
            message="Drop files here to upload (5 files max, up to 5 MB in size each)"
            onChange={this.addFiles}
            promotionUpload={false}
            filesLimit={5}
            maxFileSize={5 << 20}
          />
        </div>
        <br />
        <DataTable
          key={this.state.filesAttachedToProm}
          title="Select Files for Promotions"
          data={this.getFileUploadJSX()}
          widths={["40%", "30%", "30%"]}
        />
        <br />
      </>
    );

    const singlePromSteps = (
      <>
        <Subheader style={styles.header}>Promotion Info</Subheader>
        <TextField
          id="defaultName"
          floatingLabelText="Original Promotion Name"
          value={this.state.defaultName}
          disabled
          fullWidth
        />
        <TextField
          floatingLabelText="Customer"
          value={this.props.db.customers[defaultProm.customer].name}
          disabled
          fullWidth
        />
        <br />
        <br />
        <Subheader style={styles.header}>Repetition Details</Subheader>
        <div className="rowC">
          <SelectField
            floatingLabelText="Repeats every"
            value={this.state.repeatFrequency}
            onChange={this.handleRepeatFrequencyChange}
            fullWidth
            disabled={this.state.checkboxStates.numChecked > 0}>
            {[...Array(12).keys()].map(i => (
              <MenuItem
                children={`${i + 1} Months`}
                value={i + 1}
                key={i + 1}
              />
            ))}
          </SelectField>
        </div>
        <br />
        <Subheader style={styles.header}>When to End</Subheader>
        <div
          className="rowC"
          style={{ justifyContent: "space-between", alignItems: "center" }}>
          <div style={{ width: "100%" }}>
            <TextField
              id="numRepeats"
              floatingLabelText="Number of Occurrences"
              value={this.state.numRepeats}
              onChange={this.handleFieldChange.bind(null, "numRepeats")}
              errorText={this.getErrorText("numRepeats")}
              fullWidth
              disabled={this.state.checkboxStates.numChecked > 0}
            />
          </div>
          {/* <div>
            <span>OR</span>
          </div>
          <div style={{ width: "45%" }}>
            <DatePicker
              id={"endDate"}
              floatingLabelText={"End Date"}
              onChange={this.handleDateChange}
              minDate={new Date()}
              maxDate={
                new Date(
                  moment().add(
                    this.state.maxRepeats * this.state.repeatFrequency,
                    "months"
                  )
                )
              }
              value={this.state.repeatEndDate}
              fullWidth={true}
              disabled={this.state.checkboxStates.numChecked > 0}
            />
          </div> */}
        </div>
        {this.state.checkboxStates.numChecked > 0 && (
          <font color="orange">
            These fields are disabled since you have selected at least one
            custom month.
          </font>
        )}
        <Divider style={styles.divider} size="large" className="centering" />
        {this.props.db.meta?.use_buyin_calendar?.[
          Object.values(this.state.distributorDict)[0]
        ] && (
          <div style={{ color: green500 }}>
            Buy in calendar for the first receiver will be used.
          </div>
        )}
        <DataTable
          title="Custom Duplication Months"
          data={this.getCheckboxJSX()}
          order={[" "].concat(months)}
        />
      </>
    );

    const multiPromSteps = (
      <>
        <Subheader style={styles.header}>Copy Promotions</Subheader>
        <br />
        The selected promotions will be copied and moved forward a selected
        number of months. Please choose a number of months.
        <br />
        <br />
        <SelectField
          floatingLabelText="Number of Months"
          value={this.state.repeatFrequency}
          onChange={this.handleRepeatFrequencyChange}
          fullWidth>
          {[...Array(25).keys()].map(i => (
            <MenuItem children={`${i} Months`} value={i} key={i} />
          ))}
        </SelectField>
      </>
    );

    const editDetails = (
      <>
        <Subheader style={styles.header}>
          Edit Promotion Names and Details
        </Subheader>
        <br />
        <DataTable
          title="Promotion Info"
          data={this.getPromotionInfoJSX()}
          order={["Name", "Customer", "First Receiver", " "]}
          widths={["45%", "25%", "25%", "5%"]}
        />
      </>
    );

    return (
      <div
        style={{
          width: "100%",
          paddingLeft: 32,
          paddingRight: 32,
          margin: "auto"
        }}>
        {this.props.stepIndex === 0
          ? this.props.proms.length === 1
            ? singlePromSteps
            : multiPromSteps
          : this.props.stepIndex === 1
          ? editDetails
          : manageFilesSection}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const promFields = state.addPromotion;
  return {
    stepIndex: promFields.stepIndex,
    continueFromAdvDuplicationModal: promFields.continueFromAdvDuplicationModal
  };
};

export default connect(mapStateToProps)(AddPromotionContinuously);
