import React from "react";

import {
  BackupSourceObject,
  BackupSourceCollection
} from "components/Deductions/constants/BackupSources";
import {
  invoiceTypes,
  NULL_INVOICE_FIELD_VALUE
} from "components/Deductions/constants/ReconciliationTypes";
import MenuItem from "ui-library/MenuItem";
import { isUserCresicorEmployee } from "helpers/Firebase";
import { Customer, FundType } from "js/dbTypes";

const makeTitle = (str: string) => {
  let s = str.replace(/_/g, " ");
  // capitalize individual words
  s = s.replace(/\w\S*/g, w => w.replace(/^\w/, c => c.toUpperCase()));
  return s;
};

export function getInvoiceTypesMenu(): JSX.Element[] {
  return Object.keys(invoiceTypes).map(key => {
    const val = invoiceTypes[key];
    return (
      <MenuItem value={val} key={val}>
        {makeTitle(val)}
      </MenuItem>
    );
  });
}

interface CustomerMenuItem {
  key: string;
  name: string;
}

function addNullValue(options: JSX.Element[]) {
  options.splice(
    0,
    0,
    <MenuItem
      value={NULL_INVOICE_FIELD_VALUE}
      children={<i>None</i>}
      key={NULL_INVOICE_FIELD_VALUE}
    />
  );
}

// generic menu options
export function getCustomersMenu(allCustomers: {
  allCustomers: Record<string, Customer>;
}): JSX.Element[] {
  const customersMenu = Object.entries(allCustomers)
    .map(([key, customer]) => ({ key, name: customer.name }))
    .sort((customer1: CustomerMenuItem, customer2: CustomerMenuItem) =>
      customer1.name.localeCompare(customer2.name)
    )
    .map((customer: CustomerMenuItem, _customerIdx: number) => {
      return (
        <MenuItem value={customer.key} key={customer.key}>
          {customer.name}
        </MenuItem>
      );
    });

  if (isUserCresicorEmployee()) {
    addNullValue(customersMenu);
  }
  return customersMenu;
}

export function getBackupSourcesMenu(
  allBackupSources: BackupSourceCollection
): JSX.Element[] {
  const backupSourcesMenu = Object.entries(allBackupSources)
    .sort(
      (a: [string, BackupSourceObject], b: [string, BackupSourceObject]) => {
        return a[1].DISPLAY_NAME < b[1].DISPLAY_NAME ? -1 : 1;
      }
    )
    .map(([sourceKey, backupSource]: [string, BackupSourceObject]) => {
      return (
        <MenuItem
          value={sourceKey}
          children={backupSource.DISPLAY_NAME}
          key={sourceKey}
        />
      );
    });

  if (isUserCresicorEmployee()) {
    addNullValue(backupSourcesMenu);
  }
  return backupSourcesMenu;
}

interface PromTypeMenuItem {
  key: string;
  name: string;
}

export function getSuggestedPromTypesMenu(
  allPromTypes: { [key: string]: FundType },
  selectedPromTypes: string[]
): JSX.Element[] {
  return Object.entries(allPromTypes)
    .map(([key, promType]) => ({ key, name: promType.name }))
    .sort((promType1: PromTypeMenuItem, promType2: PromTypeMenuItem) =>
      promType1.name.localeCompare(promType2.name)
    )
    .map((promType: PromTypeMenuItem, _promTypeIdx: number) => {
      return (
        <MenuItem
          value={promType.key}
          children={promType.name}
          key={promType.key}
          checked={selectedPromTypes.includes(promType.key)}
        />
      );
    });
}
