import { useTheme } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import * as Colors from "@mui/material/colors";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import SaveIcon from "@mui/icons-material/Save";
import { firebase, updateMetaFirebase } from "helpers/Firebase";
import React, { useEffect, useState } from "react";
import Button from "ui-library/Button";
import { ColorDot, PromotionChip, PromotionTag } from "ui-library/promotionTag";
import Select from "ui-library/Select";
import TextField from "ui-library/TextField";

const colorList = [
  { name: "Red", value: Colors.red["600"] },
  { name: "Purple", value: Colors.deepPurple["600"] },
  { name: "Blue", value: Colors.blue["600"] },
  { name: "Yellow", value: Colors.yellow["600"] },
  { name: "Green", value: Colors.green["600"] },
  { name: "Orange", value: Colors.deepOrange["600"] },
  { name: "Brown", value: Colors.brown["600"] },
  { name: "Cyan", value: Colors.cyan["700"] },
  { name: "Grey", value: Colors.grey["600"] },
  { name: "Teal", value: Colors.teal["600"] },
  { name: "Pink", value: Colors.pink["600"] }
];

const styles = {
  footer: {
    justifyContent: "center"
  },
  formControl: {
    width: "100%"
  }
};

interface ModifyTagProps {
  onUpdate: () => void;
  selectedRow: PromotionTag;
  assignedColors: string[];
}

const ModifyTag: React.FC<ModifyTagProps> = ({
  onUpdate,
  selectedRow,
  assignedColors
}) => {
  const [tag, setTag] = useState<PromotionTag>({} as PromotionTag);
  const [filteredColorList, setFilteredColorList] = useState([]);
  const [error, setError] = useState({});

  const theme = useTheme();

  useEffect(() => {
    const list = colorList.filter(
      color => !assignedColors.includes(color.value)
    );
    setFilteredColorList(list);

    if (selectedRow?.key) {
      setTag(selectedRow);
    } else {
      const colorValue = list[0]?.value;
      if (colorValue) {
        setTag({
          name: "",
          description: "",
          color: colorValue,
          textColor: theme.palette.getContrastText(colorValue)
        });
      }
    }
  }, [selectedRow, theme, assignedColors]);

  const onSave = () => {
    if (!tag.name) {
      setError({ name: "Name is required" });
      return;
    }

    const key = selectedRow?.key || firebase.database().ref().push().key;

    const update = {
      [`promotionTags/${key}`]: tag
    };
    updateMetaFirebase(update);
    onUpdate();
  };

  const onFieldChange =
    (prop: keyof PromotionTag) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const options = {};
      if (prop === "color") {
        options.textColor = theme.palette.getContrastText(event.target.value);
      }
      setTag({ ...tag, [prop]: event.target.value, ...options });
      setError({});
    };

  return (
    <Card elevation={0}>
      <CardHeader
        title={
          <>
            Promotion Tag <PromotionChip tag={tag} />
          </>
        }
      />
      <CardContent>
        <TextField
          id="name"
          label="Name"
          fullWidth
          margin="normal"
          onChange={onFieldChange("name")}
          value={tag.name}
          error={error.name}
          helperText={error.name}
        />
        <TextField
          id="description"
          label="Description"
          fullWidth
          margin="normal"
          onChange={onFieldChange("description")}
          value={tag.description}
        />
        <Select
          value={tag.color}
          onChange={onFieldChange("color")}
          input={<Input id="select-color" />}
          fullWidth
          floatingLabelText="Color">
          {filteredColorList.map(color => (
            <MenuItem key={color.name} value={color.value}>
              <ColorDot backgroundColor={color.value} />
              <span>{color.name}</span>
            </MenuItem>
          ))}
        </Select>
      </CardContent>
      <CardActions style={styles.footer}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          onClick={onSave}
          label="Save"
        />
      </CardActions>
    </Card>
  );
};

export { ModifyTag };
