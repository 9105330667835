import React from "react";
import Typography from "@mui/material/Typography";
import { Divider } from "pui-react-dividers";
import SettingsIcon from "@mui/icons-material/Settings";
import DownloadIcon from "@mui/icons-material/GetApp";
import IconMenu from "ui-library/IconMenu";
import IconButton from "ui-library/IconButton";
import MenuItem from "ui-library/MenuItem";
import Select from "ui-library/Select";
import TextField from "ui-library/TextField";
import { firebase } from "helpers/Firebase";
import { compareMonths } from "helpers/Time";
import { grey } from "@mui/material/colors";
import { Stack } from "@mui/material";
import DataTable from "../../tables/DataTable";
import {
  DSM_SETTINGS_DEFAULT,
  getCresicorCompaniesJSX,
  getDeductionsScanningJSX,
  getDeductionsScanningServicingJSX,
  getAllCompaniesDeductionsScanningJSX,
  getAllCompaniesDeductionsScanningServicingJSX,
  getSingleCompanyMonths,
  getDownloadData
} from "./AdminPanelUtils.tsx";

const grey700 = grey["700"];

const styles = {
  notifs: {
    width: "60%"
  },
  superheader: {
    fontFamily: "Ubuntu",
    marginTop: 2,
    padding: 0,
    color: "primary.main"
  },
  subheader: {
    fontSize: 20,
    color: "primary.main"
  },
  productList: {
    borderColor: grey700,
    borderWidth: 1,
    borderStyle: "solid"
    // overflow: 'auto',
    // height: 300
  },
  moneyIcon: {
    width: 15,
    height: 15
  },
  radioButton: {
    marginBottom: 16
  },
  chip: {
    margin: 4
  },
  wrapper: {
    display: "flex",
    flexWrap: "wrap"
  },
  saveButton: {
    alignSelf: "flex-end",
    marginTop: 0,
    marginRight: 0
  },
  settingsButton: {
    float: "right",
    marginTop: "-36px"
  }
};

class CompanyInvoicing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyNames: {},
      selectedCompany: "",
      meta: {},
      opNum: 0,
      months: [],
      selectedMonth: "",
      deductionsScanningRecords: {},
      deductionsScanningServiceRecords: {},
      deductionsScanningSettings: {},
      deductionsScanningServiceSettings: {},
      monthlyInvoicingData: {},
      allCompaniesMonths: [],
      dsmCompanies: []
    };
  }

  updateInvoicingParameters = (field, value) => {
    const validatedValue = value ?? 0;
    const parsedValue = parseInt(validatedValue, 10);
    const selectedCompanyId = this.state.selectedCompany;
    const update = {};
    update[field] = parsedValue;
    firebase
      .database()
      .ref(`monthlyInvoicing/${selectedCompanyId}`)
      .update(update);
  };

  getInvoiceData = () => {
    // pull all invoice information for selected month, if applicable
    const selectedCompanyId = this.state.selectedCompany;
    firebase
      .database()
      .ref(
        `monthlyInvoicing/${selectedCompanyId}/deductionsScanningService/${this.state.selectedMonth}`
      )
      .once("value", snapshot => {
        this.setState({
          deductionsScanningServiceRecords: snapshot.val() || {}
        });
      });
    firebase
      .database()
      .ref(
        `monthlyInvoicing/${selectedCompanyId}/deductionsScanning/${this.state.selectedMonth}`
      )
      .once("value", snapshot => {
        this.setState({ deductionsScanningRecords: snapshot.val() || {} });
      });
  };

  getSelectedCompanyData = () => {
    if (this.state.selectedCompany == "All Companies") {
      this.setState({
        months: this.state.allCompaniesMonths,
        selectedMonth: this.state.allCompaniesMonths[0]
      });
    } else {
      const selectedCompanyId = this.state.selectedCompany;
      const metaRef = firebase
        .database()
        .ref(`monthlyInvoicing/${selectedCompanyId}/meta`);
      firebase
        .database()
        .ref(`companies/${selectedCompanyId}/meta`)
        .once("value", snapshot => {
          this.setState({ meta: snapshot.val() || {} });
        });
      metaRef.on("value", snapshot => {
        const meta = snapshot.val() || {};
        const deductionsScanningSettings = {
          ...DSM_SETTINGS_DEFAULT,
          ...meta.deductionsScanningSettings
        };
        const deductionsScanningServiceSettings = {
          ...DSM_SETTINGS_DEFAULT,
          ...meta.deductionsScanningServiceSettings
        };
        const months = Object.keys(meta.months || {});
        const selectedMonth = months[0] || "";
        this.setState(
          {
            months: months.sort(compareMonths),
            selectedMonth,
            deductionsScanningSettings,
            deductionsScanningServiceSettings,
            metaRef
          },
          this.getInvoiceData
        );
      });
    }
  };

  getAllCompanyMonths = () => {
    let months = new Set();
    for (const companyId in this.state.companyNames) {
      const newMonths = getSingleCompanyMonths(
        companyId,
        this.state.monthlyInvoicingData
      );
      if (newMonths) {
        for (const m of newMonths) {
          months.add(m);
        }
      }
    }
    months = Array.from(months);
    months.sort(compareMonths);
    months.push("All Months");
    this.setState({
      months,
      allCompaniesMonths: months,
      selectedMonth: months[0] || ""
    });
  };

  setDSMCompanies = async companies => {
    const dsmCompanies = [];
    const companyIds = Object.keys(companies);
    await Promise.all(
      companyIds.map(companyid =>
        firebase
          .database()
          .ref(`companies/${companyid}/meta/cresicor_deductions_scanning`)
          .once("value")
      )
    ).then(refs => {
      let ind = 0;
      for (const company in companies) {
        if (refs[ind].val()) {
          dsmCompanies.push(company);
        }
        ind++;
      }
      this.setState({ dsmCompanies });
    });
  };

  async componentDidMount() {
    const snapshot = await firebase
      .database()
      .ref("companyNames")
      .once("value");
    const companyNames = snapshot.val();

    await this.setDSMCompanies(companyNames);

    firebase
      .database()
      .ref("monthlyInvoicing")
      .on("value", snapshot => {
        if (snapshot.val()) {
          const monthlyInvoicingData = snapshot.val();
          const selectedCompany = "All Companies";
          this.setState(
            { monthlyInvoicingData, companyNames, selectedCompany },
            () => {
              this.getAllCompanyMonths();
            }
          );
        }
      });
  }

  render() {
    const cresicorCompaniesJSX = getCresicorCompaniesJSX(
      this.state.companyNames
    );
    let deductionsScanningJSX = getDeductionsScanningJSX(
      this.state.deductionsScanningRecords,
      this.state.deductionsScanningSettings
    );
    let deductionsScanningServicingJSX = getDeductionsScanningServicingJSX(
      this.state.deductionsScanningServiceRecords,
      this.state.deductionsScanningServiceSettings,
      this.state.companyNames
    );

    const allCompanies = this.state.selectedCompany === "All Companies";
    if (allCompanies) {
      deductionsScanningJSX = getAllCompaniesDeductionsScanningJSX(
        this.state.selectedMonth,
        this.state.companyNames,
        this.state.monthlyInvoicingData,
        this.state.dsmCompanies
      );
      deductionsScanningServicingJSX =
        getAllCompaniesDeductionsScanningServicingJSX(
          this.state.selectedMonth,
          this.state.companyNames,
          this.state.monthlyInvoicingData,
          this.state.dsmCompanies
        );
    }
    return (
      <div>
        <Typography sx={styles.superheader} variant="h4">
          Company Invoicing
        </Typography>
        <div style={styles.settingsButton}>
          <IconMenu
            iconButtonElement={
              <IconButton tooltip="Settings" size="large">
                <SettingsIcon />
              </IconButton>
            }>
            <MenuItem
              value={0}
              leftIcon={<DownloadIcon />}
              onClick={() =>
                getDownloadData(
                  this.state.companyNames,
                  this.state.selectedCompany,
                  this.state.selectedMonth,
                  this.state.monthlyInvoicingData,
                  this.state.deductionsScanningRecords,
                  this.state.deductionsScanningSettings,
                  this.state.dsmCompanies
                )
              }
              disabled={
                !allCompanies &&
                Object.keys(this.state.deductionsScanningRecords).length ===
                  0 &&
                Object.keys(this.state.deductionsScanningServiceRecords)
                  .length === 0
              }>
              Download Invoice
            </MenuItem>
          </IconMenu>
        </div>
        <Divider />
        <Typography variant="h6" sx={styles.subheader}>
          Selected Company
        </Typography>
        <Stack>
          <Select
            floatingLabelText="Select Company"
            value={this.state.selectedCompany}
            onChange={(event, index, value) => {
              this.setState({ selectedCompany: value }, () => {
                if (this.state.metaRef) {
                  this.state.metaRef.off();
                }
                this.getSelectedCompanyData();
              });
            }}>
            {cresicorCompaniesJSX}
          </Select>
          <Select
            floatingLabelText="Select Month"
            value={this.state.selectedMonth}
            onChange={(event, index, value) => {
              this.setState(
                {
                  selectedMonth: value
                },
                this.getInvoiceData
              );
            }}>
            {this.state.months.map(item => (
              <MenuItem value={item} key={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </Stack>
        <br />
        <Typography variant="h6" sx={styles.subheader}>
          Deductions Scanning
        </Typography>
        {deductionsScanningJSX["Invoice Amount"].length > 0 && (
          <div style={{ margin: 10 }}>
            {!allCompanies && (
              <div className="rowC">
                <TextField
                  floatingLabelText="Base Price"
                  value={this.state.deductionsScanningSettings.basePrice}
                  onChange={event => {
                    this.updateInvoicingParameters(
                      "meta/deductionsScanningSettings/basePrice",
                      event.target.value
                    );
                  }}
                  fullWidth
                  style={{ margin: 5 }}
                />
                <TextField
                  floatingLabelText="Base Page Limit"
                  value={this.state.deductionsScanningSettings.basePageLimit}
                  onChange={event => {
                    this.updateInvoicingParameters(
                      "meta/deductionsScanningSettings/basePageLimit",
                      event.target.value
                    );
                  }}
                  fullWidth
                  style={{ margin: 5 }}
                />
                <TextField
                  floatingLabelText="Additional Page Increment"
                  value={
                    this.state.deductionsScanningSettings
                      .additionalPageIncrement
                  }
                  onChange={event => {
                    this.updateInvoicingParameters(
                      "meta/deductionsScanningSettings/additionalPageIncrement",
                      event.target.value
                    );
                  }}
                  fullWidth
                  style={{ margin: 5 }}
                />
                <TextField
                  floatingLabelText="Additional Increment Charge"
                  value={
                    this.state.deductionsScanningSettings
                      .additionalIncrementCharge
                  }
                  onChange={event => {
                    this.updateInvoicingParameters(
                      "meta/deductionsScanningSettings/additionalIncrementCharge",
                      event.target.value
                    );
                  }}
                  fullWidth
                  style={{ margin: 5 }}
                />
              </div>
            )}
            <DataTable
              title="Invoice"
              data={deductionsScanningJSX}
              idAddition={
                this.state.companyNames[this.state.selectedCompany] +
                this.state.opNum
              }
            />
          </div>
        )}
        <br />
        <Typography variant="h6" sx={styles.subheader}>
          Deductions Scanning Servicing
        </Typography>
        {deductionsScanningServicingJSX["Invoice Amount"].length > 0 && (
          <div style={{ margin: 10 }}>
            {!allCompanies && (
              <div className="rowC">
                <TextField
                  floatingLabelText="Base Price"
                  value={this.state.deductionsScanningServiceSettings.basePrice}
                  onChange={event => {
                    this.updateInvoicingParameters(
                      "meta/deductionsScanningServiceSettings/basePrice",
                      event.target.value
                    );
                  }}
                  fullWidth
                  style={{ margin: 5 }}
                />
                <TextField
                  floatingLabelText="Base Page Limit"
                  value={
                    this.state.deductionsScanningServiceSettings.basePageLimit
                  }
                  onChange={event => {
                    this.updateInvoicingParameters(
                      "meta/deductionsScanningServiceSettings/basePageLimit",
                      event.target.value
                    );
                  }}
                  fullWidth
                  style={{ margin: 5 }}
                />
                <TextField
                  floatingLabelText="Additional Page Increment"
                  value={
                    this.state.deductionsScanningServiceSettings
                      .additionalPageIncrement
                  }
                  onChange={event => {
                    this.updateInvoicingParameters(
                      "meta/deductionsScanningServiceSettings/additionalPageIncrement",
                      event.target.value
                    );
                  }}
                  fullWidth
                  style={{ margin: 5 }}
                />
                <TextField
                  floatingLabelText="Additional Increment Charge"
                  value={
                    this.state.deductionsScanningServiceSettings
                      .additionalIncrementCharge
                  }
                  onChange={event => {
                    this.updateInvoicingParameters(
                      "meta/deductionsScanningServiceSettings/additionalIncrementCharge",
                      event.target.value
                    );
                  }}
                  fullWidth
                  style={{ margin: 5 }}
                />
              </div>
            )}
            <DataTable
              title="Invoice"
              data={deductionsScanningServicingJSX}
              idAddition={
                this.state.companyNames[this.state.selectedCompany] +
                this.state.opNum
              }
            />
          </div>
        )}
      </div>
    );
  }
}

export default CompanyInvoicing;
