import React from "react";
import ReactTable from "react-table";

import { round, sum, map } from "lodash-es";
import { displayMoney } from "helpers/DataProcessing";

import Mixpanel from "helpers/Mixpanel";
import { red } from "@mui/material/colors";
import PromProfile from "../Planning/PromProfile";

const red100 = red["100"];

const styles = {
  row: {
    cursor: "pointer"
  }
};

class MoneyTableByProm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: []
    };
  }

  addCommas = num => {
    return num.toLocaleString();
  };

  showPromotion = rowValues => {
    this.props.showRightDrawer(
      <PromProfile
        promKey={rowValues.key}
        openClose={this.props.openClose}
        db={this.props.db}
      />
    );
    Mixpanel.track("Promotion Viewed", {
      View: "Promotion Analytics",
      Component: "MoneyTableByProm"
    });
  };

  getBackgroundColor = rowValues => {
    if (
      (false && rowValues.diff < this.props.diffCutoff) ||
      rowValues.percent < this.props.percentCutoff
    ) {
      // turning this off for now
      return red100;
    }
    return null;
  };

  renderCell = row => {
    const moneyCols = ["Actual", "Expected", "diff"];
    return (
      <div
        onClick={this.showPromotion.bind(null, row.original)}
        style={{
          ...styles.row,
          backgroundColor: this.getBackgroundColor(row.original)
        }}>
        {moneyCols.indexOf(row.column.id) != -1 && this.props.isMoney
          ? displayMoney(row.value)
          : row.value.toLocaleString()}
      </div>
    );
  };

  getColumns = props => {
    const display = props.isMoney ? displayMoney : this.addCommas;
    this.setState({
      columns: [
        {
          Header: "Pivot Column",
          columns: [
            {
              Header: "Promotion",
              accessor: "promotion",
              Cell: this.renderCell
            },
            {
              Header: "Id",
              accessor: "key",
              Cell: this.renderCell
            }
          ]
        },
        {
          Header: "Data",
          columns: [
            {
              Header: "Actual",
              accessor: "Actual",
              Cell: this.renderCell,
              Footer: (
                <span>
                  {display(round(sum(map(props.data, d => d.Actual))))}
                </span>
              )
            },
            {
              Header: "Expected",
              accessor: "Expected",
              Cell: this.renderCell,
              Footer: (
                <span>
                  {display(round(sum(map(props.data, d => d.Expected))))}
                </span>
              )
            },
            {
              Header: "Differential",
              accessor: "diff",
              Cell: this.renderCell,
              Footer: (
                <span>{display(round(sum(map(props.data, d => d.diff))))}</span>
              )
            },
            {
              Header: "Diff %",
              accessor: "percent",
              Cell: this.renderCell
            }
          ]
        }
      ]
    });
  };

  componentDidMount() {
    this.getColumns(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getColumns(nextProps);
  }

  render() {
    return (
      <ReactTable
        data={this.props.data}
        columns={this.state.columns}
        defaultPageSize={12}
        style={{ fontFamily: "PT sans" }}
      />
    );
  }
}

export default MoneyTableByProm;
