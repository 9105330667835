import { invoiceLineStatuses } from "components/Deductions/constants/ReconciliationStatuses";
import { round } from "lodash";
import {
  ERPTransactionObject,
  InvoiceLineObject,
  InvoiceObject,
  RepaymentLineObject
} from "deductions-models/index";

import {
  repaymentLineTypes,
  resolutionLineTypes
} from "components/Deductions/constants/ReconciliationTypes";
import { ReconciliationDisplayAmounts } from "components/Deductions/constants/ReconciliationDisplay";
import { findInvoiceLineRepaymentLines } from "../RepaymentServices/repaymentLineFilter";

// Returns the absolute value sum of all clearing activity amounts
export function getInvoiceLineClearedAmount(
  invoice: InvoiceObject,
  invoiceLine: InvoiceLineObject
): number {
  const { resolutionLines = {} } = invoice;
  const invoiceLineResolutionLines = invoiceLine.resolutionLines || {};

  const clearedAmount = Object.values(invoiceLineResolutionLines)
    .map(resLineKey => resolutionLines[resLineKey])
    .filter(
      resLine =>
        resLine &&
        resLine.type === resolutionLineTypes.CLEAR &&
        (resLine.invoiceLineKey === invoiceLine.key ||
          resLine.invoiceLineKey === invoiceLine.invoiceLineKey) // optional filtering, in case of bugs
    )
    .map(resLine => resLine.amount || 0.0)
    .reduce((total: number, amount: number) => {
      return total + Math.abs(amount);
    }, 0.0);

  return round(clearedAmount, 2);
}

// Returns the abs value sum of all write-off activity amounts
export function getInvoiceLineTotalWriteOffAmount(
  invoice: InvoiceObject,
  invoiceLine: InvoiceLineObject
): number {
  const { resolutionLines = {} } = invoice;
  const invoiceLineResolutionLines = invoiceLine.resolutionLines || {};

  const totalWriteOffAmount = Object.values(invoiceLineResolutionLines)
    .map(resLineKey => resolutionLines[resLineKey])
    .filter(
      resLine => resLine && resLine.type === resolutionLineTypes.WRITE_OFF
    )
    .map(resLine => resLine.amount || 0.0)
    .reduce((total: number, clearedAmount: number) => {
      return total + Math.abs(clearedAmount);
    }, 0.0);

  return round(totalWriteOffAmount, 2);
}

// Returns the abs value sum of all repayment activities credited towards any existing write-offs
export function getInvoiceLineCreditedWriteOffAmount(
  invoiceLine: InvoiceLineObject,
  invoices: Record<string, InvoiceObject>,
  erpTransactions: Record<string, ERPTransactionObject>
): number {
  const repaymentLines: RepaymentLineObject[] = findInvoiceLineRepaymentLines(
    invoiceLine,
    invoices,
    erpTransactions
  );

  const creditedWriteOffAmount = repaymentLines
    .filter(
      repaymentLine =>
        repaymentLine.type === repaymentLineTypes.CREDIT_WRITE_OFF
    )
    .reduce((creditTotal, repaymentLine) => {
      return creditTotal + Math.abs(repaymentLine.amount);
    }, 0.0);

  return round(creditedWriteOffAmount, 2);
}

// Returns the abs value remaining write-off activity amount that have yet to be credited
export function getInvoiceLineOpenWriteOffAmount(
  invoiceLine: InvoiceLineObject,
  invoice: InvoiceObject,
  invoices: Record<string, InvoiceObject>,
  erpTransactions: Record<string, ERPTransactionObject>,
  precalculated?: {
    totalWriteOffAmount: number;
    creditedWriteOffAmount: number;
  }
): number {
  const {
    totalWriteOffAmount = getInvoiceLineTotalWriteOffAmount(
      invoice,
      invoiceLine
    ),
    creditedWriteOffAmount = getInvoiceLineCreditedWriteOffAmount(
      invoiceLine,
      invoices,
      erpTransactions
    )
  } = precalculated || {};

  return round(totalWriteOffAmount - creditedWriteOffAmount, 2);
}

// Returns the abs value sum of all disputed invoice line amounts for a single transaction
export function getInvoiceLineTotalDisputeAmount(
  invoice: InvoiceObject,
  invoiceLine: InvoiceLineObject,
  precalculated?: { clearedAmount?: number; totalWriteOffAmount?: number }
): number {
  const { status, amount } = invoiceLine;

  if (status !== invoiceLineStatuses.DISPUTE) {
    return 0.0;
  }

  const {
    clearedAmount = getInvoiceLineClearedAmount(invoice, invoiceLine),
    totalWriteOffAmount = getInvoiceLineTotalWriteOffAmount(
      invoice,
      invoiceLine
    )
  } = precalculated || {};

  const disputeAmount =
    Math.abs(amount) - Math.abs(clearedAmount) - Math.abs(totalWriteOffAmount);

  return round(disputeAmount, 2);
}

// Returns the abs value sum of all disputed invoice line amounts that have been repaid
export function getInvoiceLineRepaidDisputeAmount(
  invoiceLine: InvoiceLineObject,
  invoices: Record<string, InvoiceObject>,
  erpTransactions: Record<string, ERPTransactionObject>
): number {
  const repaymentLines: RepaymentLineObject[] = findInvoiceLineRepaymentLines(
    invoiceLine,
    invoices,
    erpTransactions
  );

  const repaidDisputeAmount = repaymentLines
    .filter(
      repaymentLine => repaymentLine.type === repaymentLineTypes.REPAYMENT
    )
    .reduce((creditTotal, repaymentLine) => {
      return creditTotal + Math.abs(repaymentLine.amount);
    }, 0.0);

  return round(repaidDisputeAmount, 2);
}

// Returns the abs value of any disputed invoice line amounts that have yet to be repaid
export function getInvoiceLineOpenDisputeAmount(
  invoiceLine: InvoiceLineObject,
  invoice: InvoiceObject,
  invoices: Record<string, InvoiceObject>,
  erpTransactions: Record<string, ERPTransactionObject>,
  precalculated?: {
    totalDisputeAmount: number;
    repaidDisputeAmount: number;
  }
): number {
  const {
    totalDisputeAmount = getInvoiceLineTotalDisputeAmount(invoice, invoiceLine),
    repaidDisputeAmount = getInvoiceLineRepaidDisputeAmount(
      invoiceLine,
      invoices,
      erpTransactions
    )
  } = precalculated || {};

  return round(totalDisputeAmount - repaidDisputeAmount, 2);
}

// Returns the abs value of the total amount resolved by clearing, write-offs, or repayments
export function getInvoiceLineResolvedAmount(
  invoiceLine: InvoiceLineObject,
  invoice: InvoiceObject,
  invoices: Record<string, InvoiceObject>,
  erpTransactions: Record<string, ERPTransactionObject>,
  precalculated?: {
    clearedAmount?: number;
    totalWriteOffAmount?: number;
    repaidDisputeAmount?: number;
  }
): number {
  const {
    clearedAmount = getInvoiceLineClearedAmount(invoice, invoiceLine),
    totalWriteOffAmount = getInvoiceLineTotalWriteOffAmount(
      invoice,
      invoiceLine
    ),
    repaidDisputeAmount = getInvoiceLineRepaidDisputeAmount(
      invoiceLine,
      invoices,
      erpTransactions
    )
  } = precalculated || {};

  return round(clearedAmount + totalWriteOffAmount + repaidDisputeAmount, 2);
}

// Returns the abs value of the remaining amount not yet resolved by clearing, write-offs, or repayments
export function getInvoiceLineOpenAmount(
  invoiceLine: InvoiceLineObject,
  invoice: InvoiceObject,
  invoices: Record<string, InvoiceObject>,
  erpTransactions: Record<string, ERPTransactionObject>,
  precalculated?: {
    resolvedAmount?: number;
  }
): number {
  const {
    resolvedAmount = getInvoiceLineResolvedAmount(
      invoiceLine,
      invoice,
      invoices,
      erpTransactions
    )
  } = precalculated || {};

  return round(Math.abs(invoiceLine.amount) - resolvedAmount, 2);
}

// Returns absolute value of all calculated amounts
export function getAllInvoiceLineAmounts(
  invoiceLine: InvoiceLineObject,
  invoice: InvoiceObject,
  invoices: Record<string, InvoiceObject>,
  erpTransactions: Record<string, ERPTransactionObject>
): ReconciliationDisplayAmounts {
  const clearedAmount = getInvoiceLineClearedAmount(invoice, invoiceLine);

  // WRITE-OFF RELATED AMOUNTS
  const totalWriteOffAmount = getInvoiceLineTotalWriteOffAmount(
    invoice,
    invoiceLine
  );
  const creditedWriteOffAmount = getInvoiceLineCreditedWriteOffAmount(
    invoiceLine,
    invoices,
    erpTransactions
  );
  const openWriteOffAmount = getInvoiceLineOpenWriteOffAmount(
    invoiceLine,
    invoice,
    invoices,
    erpTransactions,
    { totalWriteOffAmount, creditedWriteOffAmount }
  );

  // DISPUTE RELATED AMOUNTS
  const totalDisputeAmount = getInvoiceLineTotalDisputeAmount(
    invoice,
    invoiceLine,
    {
      clearedAmount,
      totalWriteOffAmount
    }
  );
  const repaidDisputeAmount = getInvoiceLineRepaidDisputeAmount(
    invoiceLine,
    invoices,
    erpTransactions
  );
  const openDisputeAmount = getInvoiceLineOpenDisputeAmount(
    invoiceLine,
    invoice,
    invoices,
    erpTransactions,
    { totalDisputeAmount, repaidDisputeAmount }
  );
  // AGGREGATE AMOUNTS
  const resolvedAmount = getInvoiceLineResolvedAmount(
    invoiceLine,
    invoice,
    invoices,
    erpTransactions,
    {
      clearedAmount,
      totalWriteOffAmount,
      repaidDisputeAmount
    }
  );

  const openAmount = getInvoiceLineOpenAmount(
    invoiceLine,
    invoice,
    invoices,
    erpTransactions,
    {
      resolvedAmount
    }
  );

  return {
    clearedAmount,
    totalWriteOffAmount,
    creditedWriteOffAmount,
    openWriteOffAmount,
    totalDisputeAmount,
    repaidDisputeAmount,
    openDisputeAmount,
    resolvedAmount,
    openAmount
  };
}
