import {
  ERPTransactionObject,
  InvoiceLineObject,
  InvoiceObject
} from "components/Deductions/models";
import { DbContextValues } from "contexts/Db";

export function getTransactionLinkedInvoices(
  transaction: ERPTransactionObject,
  db: DbContextValues
): InvoiceObject[] {
  const { invoices } = db;
  const { linkedInvoices = {} } = transaction;

  return Object.values(linkedInvoices).map(invoiceKey => invoices[invoiceKey]);
}

export function getTransactionLinkedInvoiceLines(
  transaction: ERPTransactionObject,
  db: DbContextValues
): InvoiceLineObject[] {
  const invoices = getTransactionLinkedInvoices(transaction, db);

  return invoices
    .map(invoice => Object.values(invoice.invoiceLines || {}))
    .reduce((allInvoiceLines, invoiceLines) => {
      return [...allInvoiceLines, ...invoiceLines];
    }, []);
}
