import React from "react";
import Card from "ui-library/Card";

/*
Input to this class will be of the form {Key1: [...], ..., Keyn: [...]}.
Output table will have Key1, ..., Keyn be the headers, and row i consists
of the ith elements of the lists corresponding to the keys.
*/

export default class DataTableScrollable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: [],
      rows: []
    };
  }

  updateTable = (data, order) => {
    const title = []; // header for the table
    const rows = []; // data for rows of table

    if (!order) {
      for (var key in data) {
        title.push(key);
      }
    } else if (order && data) {
      for (var i = 0; i < order.length; i++) {
        if (order[i] in data) {
          title.push(order[i]);
        }
      }
    }

    if (data && title.length && data[title[0]]) {
      for (var i = 0; i < data[title[0]].length; i++) {
        const row = [];
        for (var key in title) {
          row.push(data[title[key]][i]);
        }
        rows.push(row);
      }
    }

    this.setState({
      title,
      rows
    });
  };

  componentDidMount() {
    this.updateTable(this.props.data, this.props.order);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateTable(nextProps.data, nextProps.order);
  }

  render() {
    const styles = {
      flex: {
        display: "flex",
        alignItems: "end"
      },
      table: {
        overflowX: "auto",
        height: this.props.height,
        width: "100%"
      },
      thead: {
        display: "table",
        // maxWidth: "100%",
        width: "100%",
        tableLayout: "fixed",
        overflowX: "auto"
      },
      tr: {
        display: "table",
        // maxWidth: "100%",
        width: "100%",
        tableLayout: "fixed",
        overflowX: "auto"
      },
      tbody: {
        display: "block",
        overflow: "auto",
        width: "100%",
        // tableLayout: "fixed",
        maxHeight: this.props.height - 15
      },
      td: {
        verticalAlign: "middle"
      }
    };
    return (
      <Card title={this.props.headless ? null : this.props.title}>
        <div
          className="table-responsive"
          style={{
            fontSize: this.props.fontSize ? this.props.fontSize : 16,
            overflowX: "auto"
          }}>
          <table className="table" style={styles.table}>
            <thead style={styles.thead}>
              <tr style={styles.tr}>
                {this.state.title.map((item, index) => (
                  <th
                    key={`th${index}`}
                    width={
                      this.props.widths &&
                      this.props.widths.length > index &&
                      this.props.widths[index]
                    }
                    style={styles.th}>
                    <div style={styles.flex}>
                      <div>{item}</div>
                      {this.props.columnConfig?.[item]?.actionJsx ? (
                        <div>{this.props.columnConfig[item].actionJsx}</div>
                      ) : null}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody style={styles.tbody}>
              {this.state.rows.map((row, index1) => {
                const rendered_row = row.map((item, index2) => (
                  <td
                    key={`td${index2}`}
                    width={
                      this.props.widths &&
                      this.props.widths.length > index2 &&
                      this.props.widths[index2]
                    }
                    style={styles.td}>
                    {item}
                  </td>
                ));
                return (
                  <tr
                    style={styles.tr}
                    key={`tr${index1}${
                      this.props.idAddition ? this.props.idAddition : ""
                    }`}>
                    {rendered_row}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Card>
    );
  }
}
