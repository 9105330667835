import React, { useMemo, useState } from "react";
import { capitalize } from "lodash";
import { TableVirtuoso } from "react-virtuoso";
import LinearProgress from "@mui/material/LinearProgress";

import { Checkbox, FormControlLabel } from "@mui/material";
import { StyledTable } from "./TableComponents/StyledTable";
import { StyledTableHead } from "./TableComponents/StyledTableHead";
import {
  ForecastView,
  ForecastFilter,
  Option,
  ForecastDetail,
  AnyDetails,
  ForecastViewValue
} from "./types";
import {
  useGetDbName,
  getTimeBounds,
  TableItemTypes,
  isDefined,
  renderDate
} from "./utilities";
import { useUpdateOverrides, useDeleteOverride, useGetMetadata } from "./api";
import "./forecast.css";
import { Row, IRow } from "./TableComponents/Row";
import {
  bg,
  renderCellValue,
  CELL_HEIGHT,
  LINE_COLOR
} from "./TableComponents/DetailCell";
import {
  SUBTABLE_ORDER,
  Subtable,
  fieldToName
} from "./TableComponents/SubTable";
import { Header } from "./TableComponents/Header";

const PLURALS = {
  product: "products",
  customer: "customers"
} as const;

const MINIMUM_NUMBER_OF_ROWS_TO_SHOW = 2;

export const Table = ({
  view: { data, meta },
  selectedView,
  filter,
  loading,
  companyid,
  version,
  versionNumber,
  refetch
}: {
  view: ForecastView;
  selectedView: ForecastViewValue;
  filter: ForecastFilter;
  loading?: boolean;
  companyid: string;
  version: string;
  versionNumber: number;
  refetch: any;
}) => {
  const [_hook, updateOverrides] = useUpdateOverrides();
  const [_delHook, deleteOverride] = useDeleteOverride();
  const [{ data: metadata }, refetchOverrides] = useGetMetadata({
    company_id: companyid,
    version,
    override_version: versionNumber
  });

  const overrides = useMemo(() => {
    if (!metadata) {
      return [];
    }
    return metadata.overrides;
  }, [metadata]);

  const [expandedIds, setExpandedIds] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [orderBy, setOrderBy] = useState<string>("");
  const [sortDir, setSortDir] = useState<"asc" | "desc">("asc");
  const [productSearchQuery, setProductSearchQuery] = useState<string>("");
  const [customerSearchQuery, setCustomerSearchQuery] = useState<string>("");
  const [showNoValueRows, setShowNoValueRows] = useState<boolean>(false);

  const pivot = meta.customer
    ? ForecastFilter.PRODUCT
    : ForecastFilter.CUSTOMER;
  const unsortedSlice = useMemo(() => {
    return data.table || [];
  }, [data]);

  // kind of jank to have it work in 2 modes like this
  // maybe just have totally separate functions
  const getName = useGetDbName(PLURALS[pivot]);
  const toggleSort = (key: string) => {
    if (key === orderBy) {
      setSortDir(sortDir === "asc" ? "desc" : "asc");
    } else {
      setOrderBy(key);
    }
  };

  const getTotal = (sales: Option<number>[]) => {
    return sales
      .map(sale => {
        if (typeof sale !== "number") {
          return 0;
        }
        return sale;
      })
      .reduce((sum, sale) => sum + sale, 0);
  };

  const dates = useMemo(() => {
    return getTimeBounds(meta).dates;
  }, [meta]);

  const slice = useMemo(() => {
    const asc = sortDir === "asc";
    const keySort = (
      a: ForecastDetail<AnyDetails>,
      b: ForecastDetail<AnyDetails>
    ) => {
      const aName = getName(a.key[pivot]) || "";
      const bName = getName(b.key[pivot]) || "";
      return aName < bName ? -1 : 1;
    };
    const totalSort = (
      a: ForecastDetail<AnyDetails>,
      b: ForecastDetail<AnyDetails>
    ) => {
      const { sales: a_sales } = a.value;
      const { sales: b_sales } = b.value;
      const a_total = getTotal(a_sales);
      const b_total = getTotal(b_sales);
      return a_total < b_total ? -1 : 1;
    };
    const sort = orderBy === "Total" ? totalSort : keySort;
    const sortedSlice = [...unsortedSlice].sort((a, b) =>
      asc ? sort(a, b) : sort(b, a)
    );
    if (showNoValueRows) {
      return sortedSlice;
    }
    return sortedSlice.filter((item: ForecastDetail<AnyDetails>) => {
      const { sales } = item.value;
      const total = getTotal(sales);
      return total !== 0;
    });
  }, [sortDir, orderBy, unsortedSlice, showNoValueRows, getName, pivot]);

  const filteredSlice = useMemo(() => {
    const searchQuery =
      pivot === ForecastFilter.CUSTOMER
        ? productSearchQuery
        : customerSearchQuery;
    return slice.filter((item: ForecastDetail<AnyDetails>) => {
      return (getName(item.key[pivot]) || "")
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
    });
  }, [customerSearchQuery, getName, pivot, productSearchQuery, slice]);

  const headers = useMemo(() => {
    const capitalizedPivot = capitalize(pivot);
    const values = [
      capitalizedPivot,
      ...dates.map(date => renderDate(date)),
      "Total"
    ];
    return values;
  }, [pivot, dates]);

  const rows = useMemo(() => {
    return (filteredSlice ?? slice)
      .filter(detail => {
        const id = detail.key[pivot];
        return !!getName(id);
      })
      .map((detail, index): IRow => {
        const { value } = detail;
        const id = detail.key[pivot];
        const name = getName(id) ?? "unknown";
        const rowData = value[selectedView];
        const forecastBegins =
          "forecast_begins" in value
            ? new Date(value.forecast_begins)
            : undefined;

        const total = getTotal(rowData);

        // don't include the currently selected view
        const subtableKeys = SUBTABLE_ORDER.filter(key => key !== selectedView);
        const subtableData = Object.fromEntries(
          subtableKeys
            .map(key => {
              const subRow = value[key];

              if (!subRow) {
                return;
              }

              const values = [fieldToName(key), ...subRow, "-"].map(v =>
                renderCellValue(v, key)
              );

              return [key, values] as const;
            })
            .filter(isDefined) // TODO: This logic appears performed again unnecessarily in SubTable.tsx
        );

        const values = [name, ...rowData, total].map(v =>
          renderCellValue(v, selectedView)
        );

        const subtable = {
          type: TableItemTypes.SUBTABLE,
          parentId: id,
          parentIndex: index,
          subtable: true,
          data: subtableData,
          forecastBegins
        } as const;

        const meta = {
          id,
          stableIndex: index,
          subtable,
          forecastBegins,
          field: selectedView
        };
        return { type: TableItemTypes.ROW, meta, values };
      });
  }, [filteredSlice, slice, pivot, getName, selectedView]);

  const expandedRows = useMemo(() => {
    return rows.flatMap(row => {
      const {
        meta: { id, subtable }
      } = row;
      if (expandedIds[id]) {
        return [row, subtable];
      }
      return row;
    });
  }, [rows, expandedIds]);

  const expandedRowsCount = expandedRows.reduce((acc, row) => {
    if (row.type === TableItemTypes.SUBTABLE) {
      return acc + Object.keys(row.data).length;
    }

    return acc + 1;
  }, 0);
  const minRows = Math.min(MINIMUM_NUMBER_OF_ROWS_TO_SHOW, rows.length);
  const minHeight = minRows * CELL_HEIGHT;

  return (
    <>
      <div style={{ padding: "4px 12px" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={showNoValueRows}
              onChange={() => setShowNoValueRows(prevState => !prevState)}
            />
          }
          label="Include Rows with No Value"
        />
      </div>
      <div style={{ height: "4px", width: "100%" }}>
        {loading && <LinearProgress />}
      </div>
      <TableVirtuoso
        style={{
          width: "100%",
          minHeight: `${minHeight}px`,
          maxHeight: `${(expandedRowsCount + 1) * CELL_HEIGHT + 26}px`,
          borderRadius: "3px",
          border: `1px solid ${LINE_COLOR}`,
          background: "white"
        }}
        data={expandedRows}
        components={{
          Table: StyledTable,
          TableHead: StyledTableHead
        }}
        fixedHeaderContent={() => (
          <Header
            values={headers}
            toggleSort={toggleSort}
            orderBy={orderBy}
            sortDir={sortDir}
            searchProps={{
              headerKey: pivot,
              pivot,
              customerSearchQuery,
              productSearchQuery,
              setCustomerSearchQuery,
              setProductSearchQuery
            }}
          />
        )}
        itemContent={(_rowIndex, row) => {
          const { type } = row;
          if (type === TableItemTypes.SUBTABLE) {
            return (
              <td
                colSpan={headers.length}
                style={{
                  padding: "16px 0",
                  background: bg(row.parentIndex)
                }}>
                <Subtable
                  subtable={row}
                  editableRow={{
                    overrides,
                    update: updateOverrides,
                    del: deleteOverride,
                    customer: meta?.customer ?? row.parentId,
                    product: meta?.product ?? row.parentId,
                    dates,
                    companyid,
                    version: versionNumber,
                    refetch: () => {
                      refetch();
                      refetchOverrides();
                    }
                  }}
                />
              </td>
            );
          }

          const { meta: rowMeta, values } = row;
          return (
            <Row
              values={values}
              meta={rowMeta}
              index={rowMeta.stableIndex}
              onExpand={toggledId => {
                setExpandedIds(ids => {
                  const expanded = ids[toggledId];
                  return { ...ids, [toggledId]: !expanded };
                });
              }}
              expandedIds={expandedIds}
              editableRow={{
                companyid,
                version: versionNumber,
                dates,
                customer: meta.customer ?? rowMeta.id,
                product: meta.product ?? rowMeta.id,
                overrides,
                del: deleteOverride,
                update: updateOverrides,
                refetch: () => {
                  refetch();
                  refetchOverrides();
                }
              }}
            />
          );
        }}
      />
    </>
  );
};

export default Table;
