import { ReconciliationDisplayAmounts } from "deductions-constants/ReconciliationDisplay";
import {
  ERPTransactionTypes,
  repaymentLineTypes
} from "deductions-constants/ReconciliationTypes";
import { round } from "lodash";
import { ERPTransactionObject, InvoiceObject } from "deductions-models/index";
import {
  InvoiceAmountService,
  RepaymentLineFilterService
} from "reconciliation-services";

// Returns the absolute value sum of all clearing activity amounts
export function getTransactionClearedAmount(
  transaction: ERPTransactionObject,
  invoices: Record<string, InvoiceObject>
): number {
  switch (transaction.type) {
    case ERPTransactionTypes.REPAYMENT: {
      const { repaymentLines = {} } = transaction;

      const clearedAmount = Object.values(repaymentLines)
        .map(repaymentLine => repaymentLine.amount)
        .reduce((total, amount) => total + Math.abs(amount), 0.0);

      return round(clearedAmount, 2);
    }
    default: {
      // DEDUCTION + UNKNOWN
      const { linkedInvoices = {} } = transaction;
      const uniqueLinkedInvoiceKeys = Object.values(linkedInvoices).filter(
        (invoiceKey, idx, arr) => arr.indexOf(invoiceKey) === idx
      );

      const clearedAmount = uniqueLinkedInvoiceKeys
        .map(invoiceKey => invoices[invoiceKey])
        .map(invoice => InvoiceAmountService.getInvoiceClearedAmount(invoice))
        .reduce((total, amount) => total + Math.abs(amount), 0.0);

      return round(clearedAmount, 2);
    }
  }
}

// Returns the abs value sum of all write-off activity amounts
export function getTransactionTotalWriteOffAmount(
  transaction: ERPTransactionObject,
  invoices: Record<string, InvoiceObject>
): number {
  if (transaction.type === ERPTransactionTypes.REPAYMENT) {
    return 0.0;
  }

  const { linkedInvoices = {} } = transaction;
  const uniqueLinkedInvoiceKeys = Object.values(linkedInvoices).filter(
    (invoiceKey, idx, arr) => arr.indexOf(invoiceKey) === idx
  );

  const totalWriteOffAmount = uniqueLinkedInvoiceKeys
    .map(invoiceKey => invoices[invoiceKey])
    .map(invoice => InvoiceAmountService.getInvoiceTotalWriteOffAmount(invoice))
    .reduce((total, amount) => total + Math.abs(amount), 0.0);

  return round(totalWriteOffAmount, 2);
}

// Returns the abs value sum of all repayment activities credited towards any existing write-offs
export function getTransactionCreditedWriteOffAmount(
  transaction: ERPTransactionObject,
  erpTransactions: Record<string, ERPTransactionObject>
): number {
  if (transaction.type === ERPTransactionTypes.REPAYMENT) {
    return 0.0;
  }
  const repaymentLines = RepaymentLineFilterService.findDeductionRepaymentLines(
    transaction,
    erpTransactions
  );

  const creditedWriteOffAmount = repaymentLines
    .filter(
      repaymentLine =>
        repaymentLine.deductionKey === transaction.key &&
        repaymentLine.type === repaymentLineTypes.CREDIT_WRITE_OFF
    )
    .map(repaymentLine => repaymentLine.amount)
    .reduce((total, amount) => total + Math.abs(amount), 0.0);

  return round(creditedWriteOffAmount, 2);
}

// Returns the abs value remaining write-off activity amount that have yet to be credited
export function getTransactionOpenWriteOffAmount(
  transaction: ERPTransactionObject,
  invoices: Record<string, InvoiceObject>,
  erpTransactions: Record<string, ERPTransactionObject>,
  precalculated?: {
    totalWriteOffAmount?: number;
    creditedWriteOffAmount?: number;
  }
): number {
  if (transaction.type === ERPTransactionTypes.REPAYMENT) {
    return 0.0;
  }

  const {
    totalWriteOffAmount = getTransactionTotalWriteOffAmount(
      transaction,
      invoices
    ),
    creditedWriteOffAmount = getTransactionCreditedWriteOffAmount(
      transaction,
      erpTransactions
    )
  } = precalculated || {};

  return round(totalWriteOffAmount - creditedWriteOffAmount, 2);
}

// Returns the abs value sum of all disputed invoice line amounts for a single transaction
export function getTransactionTotalDisputeAmount(
  transaction: ERPTransactionObject,
  invoices: Record<string, InvoiceObject>
): number {
  if (transaction.type === ERPTransactionTypes.REPAYMENT) {
    return 0.0;
  }
  const { linkedInvoices = {} } = transaction;

  const totalDisputeAmount = Object.values(linkedInvoices)
    .map(invoiceKey => invoices[invoiceKey])
    .map(invoice => InvoiceAmountService.getInvoiceTotalDisputeAmount(invoice))
    .reduce((total, amount) => total + Math.abs(amount), 0.0);

  return round(totalDisputeAmount, 2);
}

// Returns the abs value sum of all disputed invoice line amounts that have been repaid
export function getTransactionRepaidDisputeAmount(
  transaction: ERPTransactionObject,
  erpTransactions: Record<string, ERPTransactionObject>
): number {
  if (transaction.type === ERPTransactionTypes.REPAYMENT) {
    return 0.0;
  }
  const repaymentLines = RepaymentLineFilterService.findDeductionRepaymentLines(
    transaction,
    erpTransactions
  );

  const repaidDisputeAmount = repaymentLines
    .filter(
      repaymentLine =>
        repaymentLine.deductionKey === transaction.key &&
        repaymentLine.type === repaymentLineTypes.REPAYMENT
    )
    .map(repaymentLine => repaymentLine.amount)
    .reduce((total, amount) => total + Math.abs(amount), 0.0);

  return round(repaidDisputeAmount, 2);
}

// Returns the abs value of any disputed invoice line amounts that have yet to be repaid
export function getTransactionOpenDisputeAmount(
  transaction: ERPTransactionObject,
  invoices: Record<string, InvoiceObject>,
  erpTransactions: Record<string, ERPTransactionObject>,
  precalculated?: {
    totalDisputeAmount?: number;
    repaidDisputeAmount?: number;
  }
): number {
  if (transaction.type === ERPTransactionTypes.REPAYMENT) {
    return 0.0;
  }

  const {
    totalDisputeAmount = getTransactionTotalDisputeAmount(
      transaction,
      invoices
    ),
    repaidDisputeAmount = getTransactionRepaidDisputeAmount(
      transaction,
      erpTransactions
    )
  } = precalculated || {};

  return round(totalDisputeAmount - repaidDisputeAmount, 2);
}

// Returns the abs value of the total amount resolved by clearing, write-offs, or repayments
export function getTransactionResolvedAmount(
  transaction: ERPTransactionObject,
  invoices: Record<string, InvoiceObject>,
  erpTransactions: Record<string, ERPTransactionObject>,
  precalculated?: {
    clearedAmount?: number;
    totalWriteOffAmount?: number;
    repaidDisputeAmount?: number;
  }
): number {
  const {
    clearedAmount = getTransactionClearedAmount(transaction, invoices),
    totalWriteOffAmount = getTransactionTotalWriteOffAmount(
      transaction,
      invoices
    ),
    repaidDisputeAmount = getTransactionRepaidDisputeAmount(
      transaction,
      erpTransactions
    )
  } = precalculated || {};

  return round(clearedAmount + totalWriteOffAmount + repaidDisputeAmount, 2);
}

// Returns the abs value of the remaining amount not yet resolved by clearing, write-offs, or repayments
export function getTransactionOpenAmount(
  transaction: ERPTransactionObject,
  invoices: Record<string, InvoiceObject>,
  erpTransactions: Record<string, ERPTransactionObject>,
  precalculated?: {
    resolvedAmount?: number;
  }
): number {
  const {
    resolvedAmount = getTransactionResolvedAmount(
      transaction,
      invoices,
      erpTransactions
    )
  } = precalculated || {};

  return round(Math.abs(transaction.amount) - resolvedAmount, 2);
}

// Returns absolute value of all calculated amounts
export function getAllTransactionAmounts(
  transaction: ERPTransactionObject,
  invoices: Record<string, InvoiceObject>,
  erpTransactions: Record<string, ERPTransactionObject>
): ReconciliationDisplayAmounts {
  const clearedAmount = getTransactionClearedAmount(transaction, invoices);

  // WRITE-OFF RELATED AMOUNTS
  const totalWriteOffAmount = getTransactionTotalWriteOffAmount(
    transaction,
    invoices
  );
  const creditedWriteOffAmount = getTransactionCreditedWriteOffAmount(
    transaction,
    erpTransactions
  );
  const openWriteOffAmount = getTransactionOpenWriteOffAmount(
    transaction,
    invoices,
    erpTransactions,
    {
      totalWriteOffAmount,
      creditedWriteOffAmount
    }
  );

  // DISPUTE RELATED AMOUNTS
  const totalDisputeAmount = getTransactionTotalDisputeAmount(
    transaction,
    invoices
  );
  const repaidDisputeAmount = getTransactionRepaidDisputeAmount(
    transaction,
    erpTransactions
  );
  const openDisputeAmount = getTransactionOpenDisputeAmount(
    transaction,
    invoices,
    erpTransactions,
    {
      totalDisputeAmount,
      repaidDisputeAmount
    }
  );

  // AGGREGATE AMOUNTS
  const resolvedAmount = getTransactionResolvedAmount(
    transaction,
    invoices,
    erpTransactions,
    {
      clearedAmount,
      totalWriteOffAmount,
      repaidDisputeAmount
    }
  );

  const openAmount = getTransactionOpenAmount(
    transaction,
    invoices,
    erpTransactions,
    {
      resolvedAmount
    }
  );

  return {
    clearedAmount,
    totalWriteOffAmount,
    creditedWriteOffAmount,
    openWriteOffAmount,
    totalDisputeAmount,
    repaidDisputeAmount,
    openDisputeAmount,
    resolvedAmount,
    openAmount
  };
}
