import { FigureType, PivotGroupType } from "./dataTransformTypes";

export interface TableColumn {
  tableLegend?: string;
  tableSubLegend: string;
  id?: PivotGroupType;
  types?: FigureType[];
  isMoney?: boolean;
  isPercentage?: boolean;
}

export interface PivotGroupMarker {
  id: PivotGroupType;
  name: string;
}
