import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { InvoiceObject } from "components/Deductions/models";
import { useDRMEvents } from "contexts/DRMEvents";
import { useOpenClose } from "contexts/OpenClose";
import { invoiceStatuses } from "components/Deductions/constants/ReconciliationStatuses";
import CresicorButton from "ui-library/Button";
import { grey } from "@mui/material/colors";
import CancelIcon from "@mui/icons-material/Cancel";
import { Delete } from "@mui/icons-material";
import { DRMEventType } from "../../ActivityLog/DRMEvent";
import {
  InvoiceDependenciesService,
  InvoiceProcessingService
} from "../../services";
import { invoiceActions, invoiceDependencies } from "../../types/invoiceTypes";

const grey600 = grey["600"];
const grey700 = grey["700"];

const useStyles = makeStyles(theme => ({
  cancelCommentInput: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
}));

interface CancelInvoiceButtonProps {
  invoice: InvoiceObject;
  iconButton?: boolean;
  isInvoiceCancelled: boolean;
  allInvoiceDependencies?: Record<invoiceDependencies, boolean>;
}

export default function CancelInvoiceButton(
  props: CancelInvoiceButtonProps
): JSX.Element {
  const classes = useStyles();
  const { invoice, allInvoiceDependencies, iconButton, isInvoiceCancelled } =
    props;
  const [cancelDialogOpen, setCancelDialogOpen] = useState<boolean>(false);
  const [cancelComment, setCancelComment] = useState<string>("");
  const [actionAllowed, setActionAllowed] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const { addEvent } = useDRMEvents();
  const { showSnack } = useOpenClose();

  const closeCancelDialog = (): void => {
    setCancelDialogOpen(false);
    setCancelComment("");
    setActionAllowed(false);
    setErrorMessage("");
  };

  const afterInvoiceCancelled = (cancelledInvoice: InvoiceObject) => {
    InvoiceProcessingService.updateFirebaseInvoice(cancelledInvoice, () => {
      addEvent({
        type: DRMEventType.INVOICE_CANCELLED,
        invoiceKey: cancelledInvoice.key,
        comment: cancelComment
      });
      closeCancelDialog();
      showSnack(
        `Invoice ${cancelledInvoice.invoiceNumber} has been cancelled.`
      );
    });
  };

  const onCancel = () => {
    const { actionAllowed: newActionAllowed, dependenciesMessage } =
      InvoiceDependenciesService.isSingleInvoiceActionAllowed(
        invoice,
        invoiceActions.CANCEL,
        allInvoiceDependencies
      );
    setActionAllowed(newActionAllowed);
    setErrorMessage(
      `Invoice ${invoice.invoiceNumber} cannot be cancelled because: ${dependenciesMessage}. Please handle these dependencies before trying again.`
    );
    setCancelDialogOpen(true);
  };

  return (
    <>
      {iconButton ? (
        <IconButton onClick={onCancel} size="large">
          <Delete color="action" />
        </IconButton>
      ) : (
        <CresicorButton
          size="small"
          disabled={isInvoiceCancelled}
          tooltip={isInvoiceCancelled ? "Invoice is already cancelled" : null}
          label="Cancel Invoice"
          onClick={onCancel}
          backgroundColor={grey600}
          hoverColor={grey700}
          style={{ color: "white" }}
          icon={<CancelIcon />}
        />
      )}
      <Dialog open={cancelDialogOpen} onClose={closeCancelDialog}>
        <DialogTitle>
          {actionAllowed ? "Confirm Cancel" : "Action Not Allowed"}
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item md={12}>
              {actionAllowed ? (
                <Typography>
                  Are you sure you want to cancel this invoice? This action
                  cannot be undone.
                </Typography>
              ) : (
                <Typography>{errorMessage}</Typography>
              )}
            </Grid>
          </Grid>
          {actionAllowed && (
            <Grid container justifyContent="center">
              <Grid item md={8}>
                <TextField
                  fullWidth
                  error={!cancelComment}
                  className={classes.cancelCommentInput}
                  variant="outlined"
                  color="secondary"
                  multiline
                  label="Reason for Cancellation"
                  required
                  value={cancelComment}
                  onChange={e => setCancelComment(e.target.value)}
                />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeCancelDialog}>Go Back</Button>
          {actionAllowed && (
            <Button
              onClick={() => {
                const cancelledInvoice = {
                  ...invoice,
                  status: invoiceStatuses.CANCELLED
                };
                afterInvoiceCancelled(cancelledInvoice);
              }}
              disabled={!cancelComment}>
              Confirm
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
