class SetImproved {
  /*
	Extends Set object functionality to JSON serializable objects such as lists and dicts.
	Takes list of JSON serializable objects for constructors. 
	*/
  constructor(x) {
    this.classDict = {};
    this._size = 0;
    if (x) {
      for (let i = 0; i < x.length; i++) {
        this.add(x[i]);
      }
    }
  }

  get size() {
    return this._size;
  }

  add(x) {
    if (!(JSON.stringify(x) in this.classDict)) {
      this._size += 1;
    }
    this.classDict[JSON.stringify(x)] = x;
  }

  has(x) {
    return JSON.stringify(x) in this.classDict;
  }

  delete(x) {
    if (JSON.stringify(x) in this.classDict) {
      this._size -= 1;
    }
    delete this.classDict[JSON.stringify(x)];
  }

  clear() {
    this.classDict = {};
  }

  keys() {
    return Object.keys(this.classDict).map(x => this.classDict[x]);
  }

  entries() {
    return Object.keys(this.classDict).map(x => this.classDict[x]);
  }
}

const isForecastEnabled = (db = {}) =>
  db?.meta?.forecast && db?.meta?.tier !== "pine";

const isForecastv2FeatureGateEnabled = (db = {}) =>
  db?.meta?.featureGates?.forecastv2;

function moveElementWithoutDeletion(arr, oldIndex, newIndex) {
  const newArr = [...arr];
  let oldIndexVal = oldIndex;
  let newIndexVal = newIndex;
  while (oldIndexVal < 0) {
    oldIndexVal += newArr.length;
  }
  while (newIndexVal < 0) {
    newIndexVal += newArr.length;
  }
  if (newIndexVal >= newArr.length) {
    let k = newIndexVal - newArr.length + 1;
    while (k--) {
      newArr.push(undefined);
    }
  }
  newArr.splice(newIndexVal, 0, newArr.splice(oldIndexVal, 1)[0]);
  return newArr;
}

export {
  SetImproved,
  isForecastEnabled,
  isForecastv2FeatureGateEnabled,
  moveElementWithoutDeletion
};
