import React from "react";

import { Divider } from "pui-react-dividers";
import CircularProgress from "@mui/material/CircularProgress";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import SettingsIcon from "@mui/icons-material/Settings";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import RefreshIcon from "@mui/icons-material/Refresh";
import UploadIcon from "@mui/icons-material/Publish";
import DownloadIcon from "@mui/icons-material/GetApp";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import SaveIcon from "@mui/icons-material/Save";
import { parse } from "date-fns";
import { intersection } from "lodash";
import ReactExport from "react-export-excel";
import Button from "ui-library/Button";
import IconMenu from "ui-library/IconMenu";
import IconButton from "ui-library/IconButton";
import { Toolbar, ToolbarTitle, ToolbarSeparator } from "ui-library/Toolbar";
import ToolbarGroup from "ui-library/ToolbarGroup";
import Card from "ui-library/ClickableCard";
import SelectField from "ui-library/Select";
import MenuItem from "ui-library/MenuItem";
import RadioButton from "ui-library/RadioButton";
import StyledToggleButtonGroup from "ui-library/ToggleButtonGroupStyled";
import ToggleButton from "@mui/material/ToggleButton";
import {
  getFirebase,
  getJSXListsFirebase,
  updateMetaFirebase,
  firebase
} from "helpers/Firebase";
import { getDateTime, getTimesJSX } from "helpers/Time";
import {
  WILDCARD,
  isWildcard,
  diffTypes,
  pivotTypes,
  dataSources,
  BUDGET,
  REVENUE
} from "helpers/Budget";
import { round } from "helpers/DataProcessing";
import axios from "axios";
import { grey, common } from "@mui/material/colors";
import { baseURL, Routes } from "components/ForecastRs/routes";
import { BudgetTool } from "../graphs/BudgetTool";
import UploadFiles from "../UploadFiles/UploadFiles";
import { DeleteBudgetData } from "./DeleteBudgetData";
import BudgetWorker from "../../helpers/Budget/budget.worker.js";
import BudgetButtonGroup from "./BudgetButtonGroup";

const {
  grey: { 400: grey400, 700: grey700 },
  common: { black }
} = { grey, common };

const {
  ExcelFile,
  ExcelFile: { ExcelSheet, ExcelColumn }
} = ReactExport;

const styles = {
  input: {
    margin: 5
  },
  toolbarButton: {
    marginLeft: 5,
    marginRight: 5
  },
  loading: {
    // TODO: do this without jQuery
    height: $(window).height()
  }
};

class Budget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      doneLoading: false,
      figureType: WILDCARD,
      time: "month",
      pivot: pivotTypes.DATE,
      pivotMonth: WILDCARD,
      pivotYear: new Date().getFullYear(),
      dataSource: dataSources.SPEND,
      customer: [WILDCARD],
      productGroup: [WILDCARD],
      contact: [WILDCARD],
      agency: [WILDCARD],
      customersJSX: [],
      productGroupsJSX: [],
      accountsJSX: [],
      contactsJSX: [],
      monthsJSX: [],
      yearsJSX: [],
      loadingTableData: true,
      hideOptions: false,
      rawBudgetFields: {
        budget: [],
        latestEstimate: []
      },
      budgetData: [],
      showOnlyPast: false,
      diffType: diffTypes.None,
      exportData: [],
      dataSubTypesForPivot: [BUDGET, REVENUE],
      reset: Math.random(),
      revenueLESource: "fileUpload",
      loadingForecastFigures: false,
      forecastLastUpdated: null,
      combineEstimatesAndActuals: true
    };
    this.excelDownloadButton = React.createRef();
  }

  componentDidMount() {
    getJSXListsFirebase(
      this.props.db,
      allJSX => {
        const [monthsJSX, yearsJSX] = getTimesJSX(this.props.db.meta, true);
        this.setState({
          // Show only first receivers
          customersJSX: allJSX.customersJSX.filter(
            ({ props: { customer } }) =>
              customer?.isDistributor || customer?.isDirect
          ),
          // Show groups used for pricing
          productGroupsJSX: allJSX.productGroupsJSX.filter(
            ({ props: { productGroup } }) => productGroup?.isPricing
          ),
          accountsJSX: allJSX.accountsJSX,
          contactsJSX: allJSX.contactsJSX,
          monthsJSX,
          yearsJSX,
          doneLoading: true
        });
      },
      null,
      true
    );

    // Get default view
    const { uid } = firebase.auth().currentUser;
    firebase
      .database()
      .ref(`users/${uid}/budget/defaultView`)
      .once("value", snapshot => {
        const defaultView = snapshot.val();
        if (defaultView) {
          this.setState(defaultView);
        }
      });

    try {
      // Need 6 types of data:
      // 1 and 2. Budgeted Revenue and Trade Spend Budget: from "budget" in Firebase (fetching here)
      // 3. Revenue LE: from "latest_estimate" in Firebase (fetching here)
      // 4. Trade Spend Expected: from allLines (in props.db)
      // 5 and 6. Revenue Actual and Trade Spend Actual: from "revenue" and "actual" in Firebase (in props.db)

      return getFirebase(24, ({ budget = [], latest_estimate = [] }) => {
        // Revenue LE
        const {
          props: {
            db: {
              meta: { budget_revenue_LE_source: revenueLESource = "fileUpload" }
            }
          },
          state: { dataSource }
        } = this;
        this.setState(
          {
            rawBudgetFields: {
              budget,
              latestEstimate: latest_estimate
            },
            revenueLESource
          },
          () => this.compileAllData(dataSource)
        );
      });
    } catch (e) {
      console.error(e);
      this.props.openClose.showSnack("Could not fetch budget data");
    }

    this.setState({ loadingTableData: false });
  }

  compileAllData = dataSource => {
    const {
      props: {
        db: { actMoney = {}, revenue = {}, allLines }
      },
      state: {
        rawBudgetFields: { budget }
      }
    } = this;

    this.setState({ loadingTableData: true });

    const actualTradeSpend = this.sanitizeMoney(actMoney, "spend");
    const actualRevenue = this.sanitizeMoney(revenue, "revenue");
    const accountingSources = this.props.db.meta.accounting_sources || [];

    const customerNamesToId = Object.fromEntries(
      Object.entries(this.props.db.customers).map(([customerId, { name }]) => [
        name,
        customerId
      ])
    );

    const productGroupsByProduct = this.getProductGroupsByProduct();
    const pricingProductGroupsByProduct = this.getProductGroupsByProduct(true);

    const worker = new BudgetWorker();
    worker.onmessage = ({ data }) => {
      this.setState(
        { budgetData: data, dataSource },
        this.populateRevenueLEFigures
      );
      worker.terminate();
    };

    const { drmEventService, adminPanelActivityLogService, ...serializableDb } =
      this.props.db;

    worker.postMessage({
      type: "compileAllData",
      args: {
        actualTradeSpend,
        actualRevenue,
        db: serializableDb,
        allLines,
        customerNamesToId,
        budget,
        productGroupsByProduct,
        pricingProductGroupsByProduct,
        accountingSources,
        dataSource
      }
    });
  };

  getProductGroupsByProduct = (pricingOnly = false) => {
    const allProductGroups = this.props.db.meta.product_groups || {};
    return Object.entries(allProductGroups)
      .filter(([key, productGroup]) => !pricingOnly || productGroup?.isPricing)
      .reduce((acc, [key, productGroup]) => {
        if (productGroup?.products) {
          productGroup.products.forEach(productKey => {
            const productGroupsForProduct = acc?.[productKey] || [];
            productGroupsForProduct.push(key);
            acc[productKey] = productGroupsForProduct;
          });
        }
        return acc;
      }, {});
  };

  sanitizeMoney = (money, type) => {
    // filter revenue and deductions by date, customer, and account
    const pricingProductGroupsByProduct = this.getProductGroupsByProduct(true);
    const { allLines } = this.props.db;
    const filteredMoney = Object.values(money).map(moneyItem => {
      let productGroup = [];
      if (type === "spend") {
        const line = allLines?.[moneyItem?.lineKey] || {};
        productGroup = line.productGroup;
      }
      if (type === "revenue") {
        productGroup = pricingProductGroupsByProduct?.[moneyItem.product] || [];
      }

      return {
        ...moneyItem,
        productGroups: Array.isArray(productGroup)
          ? productGroup
          : [productGroup]
      };
    });

    return filteredMoney;
  };

  openFilePicker = jsx => {
    this.setState({
      openFilePicker: true
    });
    this.props.showDropDownWindow(jsx);
  };

  closeFilePicker = () => {
    this.setState({
      openFilePicker: false
    });
  };

  handlePivotDropdownChange = type => (event, index, value) => {
    const wasWildcard = this.state[type].includes(WILDCARD);
    const nowWildcard = value.includes(WILDCARD);
    if (!value?.length || (!wasWildcard && nowWildcard)) {
      this.setState({ [type]: [WILDCARD] });
    } else if (wasWildcard && nowWildcard) {
      const newValue = Array.from(value);
      newValue.splice(newValue.indexOf(WILDCARD), 1);
      this.setState({ [type]: newValue });
    } else {
      this.setState({ [type]: value });
    }
  };

  getFilteredBudgetData = () => {
    const {
      budgetData,
      pivotMonth,
      pivotYear,
      customer,
      productGroup,
      contact,
      showOnlyPast
    } = this.state;

    return budgetData.filter(
      ({
        date,
        customer: customerId,
        productGroups,
        contacts,
        status = ""
      }) => {
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        return (
          [WILDCARD, month].includes(pivotMonth) &&
          [WILDCARD, year].includes(pivotYear) &&
          (isWildcard(customer) || customer.includes(customerId)) &&
          (isWildcard(productGroup) ||
            intersection(productGroups, productGroup).length) &&
          (isWildcard(contact) || intersection(contact, contacts).length) &&
          (!status ||
            !showOnlyPast ||
            ["Approved", "Running", "Completed"].includes(status))
        );
      }
    );
  };

  resetFields = () =>
    this.setState({
      figureType: WILDCARD,
      time: "month",
      pivot: pivotTypes.DATE,
      pivotMonth: WILDCARD,
      pivotYear: new Date().getFullYear(),
      dataSource: dataSources.SPEND,
      customer: [WILDCARD],
      productGroup: [WILDCARD],
      contact: [WILDCARD],
      agency: [WILDCARD],
      diffType: diffTypes.None,
      dataSubTypesForPivot: [BUDGET, REVENUE],
      // reset tells the budget table to reset sort column data
      reset: Math.random()
    });

  exportBudgetData = data => () => {
    const {
      props: {
        db: {
          customers: allCustomers,
          meta: { product_groups: allProductGroups },
          contacts: allContacts
        }
      },
      state: { combineEstimatesAndActuals }
    } = this;

    const worker = new BudgetWorker();
    worker.onmessage = ({ data }) => {
      this.setState({ exportData: data }, () =>
        this.excelDownloadButton.current?.click?.()
      );
      worker.terminate();
    };

    worker.postMessage({
      type: "getExportData",
      args: {
        data,
        db: {
          allCustomers,
          allProductGroups,
          allContacts
        },
        combineEstimatesAndActuals
      }
    });
    this.props.openClose.showSnack("Your export will be ready shortly.");
  };

  handlePivotChange = pivot => () => {
    this.setState(({ figureType }) => ({
      pivot,
      diffType: diffTypes.None,
      figureType: isWildcard(figureType) ? BUDGET : figureType
    }));
  };

  showDeleteModal = () => {
    const {
      state: { rawBudgetFields, yearsJSX },
      props: { openClose }
    } = this;
    this.props.openClose.setAppModal(
      "Delete Budget Data",
      <DeleteBudgetData yearsJSX={yearsJSX} {...rawBudgetFields} />
    );
  };

  saveCurrentView = () => {
    const stateFieldsToSave = [
      "figureType",
      "time",
      "pivot",
      "diffType",
      "dataSubTypesForPivot",
      "pivotMonth",
      "pivotYear",
      "dataSource",
      "customer",
      "productGroup",
      "contact"
    ];
    const valuesToSave = stateFieldsToSave.reduce((acc, stateField) => {
      const stateFieldValue = this.state[stateField];
      if (!isWildcard(stateFieldValue)) {
        return { ...acc, [stateField]: stateFieldValue };
      }
      return acc;
    }, {});

    const { uid } = firebase.auth().currentUser;
    firebase
      .database()
      .ref(`users/${uid}/budget/defaultView`)
      .set(valuesToSave);
  };

  populateRevenueLEFigures = async (updateForecast = false) => {
    try {
      this.setState({ loadingTableData: true });
      const {
        revenueLESource,
        budgetData,
        rawBudgetFields: { latestEstimate }
      } = this.state;
      // Remove Revenue LE entries
      const filteredBudgetData = budgetData.filter(
        budgetDataEntry => !budgetDataEntry.hasOwnProperty("estimatedRevenue")
      );
      const productGroupsByProduct = this.getProductGroupsByProduct();
      if (revenueLESource === "forecast") {
        const companyId = this.props.db.companyid;

        if (updateForecast) {
          this.setState({ loadingForecastFigures: true });
        }

        try {
          const overrideVersioning =
            !!this.props.db.meta.featureGates?.overrideVersioning;
          const {
            data: { data, last_updated: forecastLastUpdated }
          } = await axios.get(`${baseURL}${Routes.GET_FIGURES_FOR_BUDGET}`, {
            params: {
              company_id: companyId,
              update_forecast: updateForecast,
              override_versioning: overrideVersioning
            }
          });
          Object.entries(data).forEach(([customerId, customerData]) => {
            const customer = this.props.db.customers?.[customerId] || {};
            Object.entries(customerData).forEach(([productId, productData]) => {
              const product = this.props.db.products?.[productId] || {};
              Object.entries(productData)
                .filter(([_, revenueFigure]) => Boolean(revenueFigure))
                .forEach(([week, revenueFigure]) => {
                  filteredBudgetData.push({
                    date: parse(week, "yyyy-MM-dd", new Date()),
                    customer: customerId,
                    productGroups:
                      product?.productGroups ||
                      productGroupsByProduct?.[productId] ||
                      [],
                    contacts: customer.contact || [],
                    estimatedRevenue: round(revenueFigure)
                  });
                });
            });
          });
          this.setState({
            forecastLastUpdated
          });
        } catch (e) {
          console.error(e);
          this.props.openClose.showSnack(
            "Error fetching revenue figures from forecast"
          );
        }
      } else {
        latestEstimate.forEach((estimateItem = {}) => {
          const customer =
            this.props.db.customers?.[estimateItem?.customer] || {};
          const product = this.props.db.products?.[estimateItem?.product] || {};
          filteredBudgetData.push({
            date: parse(estimateItem.date, "yyyy-MM-dd", new Date()),
            customer: estimateItem.customer,
            productGroups:
              product?.productGroups ||
              productGroupsByProduct?.[estimateItem?.product] ||
              [],
            contacts: customer.contact || [],
            estimatedRevenue: round(estimateItem.revenueLatestEstimate)
          });
        });
      }
      return this.setState({ budgetData: filteredBudgetData }, () => {
        this.setState({
          loadingTableData: false,
          loadingForecastFigures: false
        });
      });
    } catch (e) {
      console.error(e);
    }
    this.setState({ loadingTableData: false, loadingForecastFigures: false });
  };

  changeStateOnButtonClick = stateChange => {
    this.setState(stateChange);
  };

  openRevenueEstimateModal = () => {
    const revenueLESources = [
      {
        label: "Forecast Table",
        value: "forecast"
      },
      {
        label: "Revenue LE File Upload",
        value: "fileUpload"
      }
    ];
    this.props.openClose.setAppModal(
      "Change source for Revenue LE figures",
      <>
        <RadioGroup
          name="select-revenue-LE-source"
          value={this.state.revenueLESource}
          onChange={(event, value) => {
            updateMetaFirebase({ budget_revenue_LE_source: value });
            this.setState({ revenueLESource: value }, () => {
              this.props.openClose.closeAppModal();
              this.populateRevenueLEFigures(value === "forecast");
            });
          }}
          style={{ marginLeft: 16 }}>
          {revenueLESources.map(({ label, value }) => (
            <RadioButton value={value} label={label} key={value} />
          ))}
        </RadioGroup>
      </>
    );
  };

  changeTime = (event, time) => {
    this.setState({ time });
  };

  handleDataSourceChange = (event, index, value) => {
    this.compileAllData(value);
  };

  render() {
    const {
      figureType,
      time,
      pivot,
      pivotMonth,
      monthsJSX,
      yearsJSX,
      customersJSX,
      productGroupsJSX,
      contactsJSX,
      dataSource,
      pivotYear,
      customer,
      productGroup,
      contact,
      agency,
      hideOptions,
      doneLoading,
      loadingTableData,
      showOnlyPast,
      diffType,
      exportData,
      dataSubTypesForPivot,
      reset,
      loadingForecastFigures,
      revenueLESource,
      forecastLastUpdated,
      combineEstimatesAndActuals
    } = this.state;

    const budgetTableData = this.getFilteredBudgetData();

    const excelColumns = [
      {
        label: "Date",
        value: "dateObject"
      },
      {
        label: "Customer",
        value: "customer"
      },
      {
        label: "Product Groups",
        value: "productGroups"
      },
      {
        label: "Contacts",
        value: "contacts"
      },
      {
        label: "Budgeted Revenue",
        value: "budgetedRevenue"
      },
      {
        label: "Revenue LE",
        value: "estimatedRevenue"
      },
      {
        label: "Revenue Actual",
        value: "actualRevenue"
      },
      {
        label: "Trade Spend Budget",
        value: "budgetedTradeSpend"
      },
      {
        label: "Trade Spend Expected",
        value: "estimatedTradeSpend"
      },
      {
        label: "Trade Spend Actual",
        value: "actualTradeSpend"
      }
    ];

    const {
      customers: allCustomers,
      meta: { product_groups: allProductGroups },
      contacts: allContacts
    } = this.props.db;

    return doneLoading ? (
      <div style={{ fontFamily: "Oxygen" }}>
        <Toolbar>
          <ToolbarGroup>
            <ToolbarTitle text="Budgeting" style={{ color: black }} />
            <ToolbarSeparator />
            <IconButton
              onClick={() => {
                this.setState({ showOnlyPast: !showOnlyPast });
              }}
              tooltip={
                showOnlyPast
                  ? "Showing only Approved, Running, Completed promotions"
                  : "Showing all promotions"
              }
              size="large">
              <CardGiftcardIcon
                sx={{ color: showOnlyPast ? "primary.main" : grey400 }}
              />
            </IconButton>
            <ToolbarSeparator />
            <IconButton
              onClick={() => {
                this.setState({
                  combineEstimatesAndActuals: !combineEstimatesAndActuals
                });
              }}
              tooltip={
                combineEstimatesAndActuals
                  ? "LE includes Actuals"
                  : "Showing loaded LE only"
              }
              size="large">
              {combineEstimatesAndActuals ? (
                <ToggleOnIcon sx={{ color: "primary.main" }} />
              ) : (
                <ToggleOffIcon sx={{ color: grey400 }} />
              )}
            </IconButton>
          </ToolbarGroup>
          <ToolbarGroup>
            <BudgetButtonGroup
              pivot={pivot}
              dataSubTypesForPivot={dataSubTypesForPivot}
              diffType={diffType}
              figureType={figureType}
              changeStateOnButtonClick={this.changeStateOnButtonClick}
            />
          </ToolbarGroup>
          <ToolbarGroup>
            {revenueLESource === "forecast" ? (
              <Button
                label="Update Forecast"
                size="small"
                disabled={loadingForecastFigures}
                onClick={() => this.populateRevenueLEFigures(true)}
              />
            ) : null}
            <IconButton
              onClick={this.showDeleteModal}
              tooltip="Delete Budget Data"
              size="large">
              <DeleteIcon />
            </IconButton>
            <IconMenu
              iconButtonElement={
                <IconButton tooltip="Settings" size="large">
                  <SettingsIcon />
                </IconButton>
              }
              anchorOrigin={{ horizontal: "right", vertical: "top" }}
              targetOrigin={{ horizontal: "right", vertical: "top" }}
              onChange={this.props.handleAdditionalDataDisplay}>
              <MenuItem
                value={0}
                children="Upload Budget"
                leftIcon={<UploadIcon />}
                onClick={this.openFilePicker.bind(
                  null,
                  <UploadFiles
                    selectedDataType="Budget"
                    openClose={this.props.openClose}
                  />
                )}
              />
              <MenuItem
                value={1}
                children="Upload Latest Estimate"
                leftIcon={<UploadIcon />}
                onClick={this.openFilePicker.bind(
                  null,
                  <UploadFiles
                    selectedDataType="Latest Estimate"
                    openClose={this.props.openClose}
                  />
                )}
              />
              <MenuItem
                value={2}
                children="Download Current Budget Data"
                leftIcon={<DownloadIcon />}
                onClick={this.exportBudgetData(budgetTableData)}
              />
              <MenuItem
                value={3}
                children="Set as Default View"
                leftIcon={<SaveIcon />}
                onClick={this.saveCurrentView}
              />
              <MenuItem
                value={4}
                children="Change source for Revenue LE figures"
                leftIcon={<SaveIcon />}
                onClick={this.openRevenueEstimateModal}
              />
            </IconMenu>
          </ToolbarGroup>
        </Toolbar>
        <div style={{ padding: 16 }}>
          {!hideOptions ? (
            <div>
              <div className="rowC">
                <Stack sx={{ width: "100%" }}>
                  <Typography variant="h5" sx={{ opacity: 0.54 }}>
                    Filter Inputs
                  </Typography>
                  <StyledToggleButtonGroup
                    value={time}
                    exclusive
                    onChange={this.changeTime}>
                    <ToggleButton value="month">Month</ToggleButton>
                    <ToggleButton value="quarter">Quarter</ToggleButton>
                  </StyledToggleButtonGroup>
                </Stack>
                <div>
                  <IconButton
                    color="tonal"
                    isBackgroundColor
                    tooltip="Clear Filters"
                    onClick={this.resetFields}
                    size="large">
                    <RefreshIcon />
                  </IconButton>
                </div>
              </div>
              <div style={{ fontFamily: "Oxygen" }}>
                Click on a panel header to switch pivot column.
                <br />
                {pivot !== pivotTypes.DATE
                  ? "Please select one of each (Budget, LE or Actual) and (Revenue, Trade Rate or Trade Spend). The option you choose last will determine what expanding a row will display."
                  : null}
              </div>
              <br />
              <div className="row" style={{ justifyContent: "space-between" }}>
                <div className="col-lg-3">
                  <Card
                    onClick={this.handlePivotChange(pivotTypes.DATE)}
                    title="Date"
                    selected={pivot === pivotTypes.DATE}>
                    <div className="rowC">
                      <SelectField
                        floatingLabelText="Month"
                        value={pivotMonth}
                        size="small"
                        onChange={(event, index, value) => {
                          this.setState({ pivotMonth: value });
                        }}
                        fullWidth
                        style={styles.input}>
                        <MenuItem
                          value={WILDCARD}
                          children="Any month"
                          key={0}
                        />
                        {monthsJSX}
                      </SelectField>
                      <SelectField
                        floatingLabelText="Year"
                        value={pivotYear}
                        onChange={(event, index, value) => {
                          this.setState({ pivotYear: value });
                        }}
                        fullWidth
                        size="small"
                        style={styles.input}>
                        <MenuItem
                          value={WILDCARD}
                          children="Any year"
                          key={0}
                        />
                        {yearsJSX}
                      </SelectField>
                    </div>
                    <SelectField
                      floatingLabelText="Data Source"
                      value={dataSource}
                      onChange={this.handleDataSourceChange}
                      fullWidth
                      size="small"
                      style={styles.input}>
                      <MenuItem
                        value={dataSources.SPEND}
                        children="Spend"
                        key={0}
                      />
                      <MenuItem
                        value={dataSources.PROMOTION}
                        children="Promotion"
                        key={1}
                      />
                    </SelectField>
                  </Card>
                </div>
                <div className="col-lg-2">
                  <Card
                    onClick={this.handlePivotChange(pivotTypes.CUSTOMER)}
                    title="Customer"
                    selected={pivot === pivotTypes.CUSTOMER}>
                    <SelectField
                      floatingLabelText="Customer"
                      onChange={this.handlePivotDropdownChange("customer")}
                      value={customer}
                      fullWidth
                      size="small"
                      style={styles.input}
                      multiple>
                      <MenuItem
                        value={WILDCARD}
                        children="Any Customer"
                        key="wildcard"
                      />
                      {customersJSX}
                    </SelectField>
                  </Card>
                </div>
                <div className="col-lg-2">
                  <Card
                    onClick={this.handlePivotChange(pivotTypes.PRODUCTS)}
                    title="Product Grouping"
                    selected={pivot === pivotTypes.PRODUCTS}>
                    <SelectField
                      floatingLabelText="Product Grouping"
                      onChange={this.handlePivotDropdownChange("productGroup")}
                      value={productGroup}
                      fullWidth
                      size="small"
                      style={styles.input}
                      multiple>
                      <MenuItem
                        value={WILDCARD}
                        children="Any Product Grouping"
                        key="wildcard"
                      />
                      {productGroupsJSX}
                    </SelectField>
                  </Card>
                </div>
                <div className="col-lg-2">
                  <Card
                    onClick={this.handlePivotChange(pivotTypes.CONTACTS)}
                    title="Contact"
                    selected={pivot === pivotTypes.CONTACTS}>
                    <SelectField
                      floatingLabelText="Contact"
                      onChange={this.handlePivotDropdownChange("contact")}
                      value={contact}
                      fullWidth
                      size="small"
                      style={styles.input}
                      multiple>
                      <MenuItem
                        value={WILDCARD}
                        children="Any Contact"
                        key="wildcard"
                      />
                      {contactsJSX}
                    </SelectField>
                  </Card>
                </div>
                {/* Don't need agency for now */}
                {/* <div className="col-lg-2">
                  <Card
                    onClick={() => this.setState({ pivot: "agency" })}
                    title="Agency"
                    selected={pivot === "agency"}
                  >
                    <SelectField
                      floatingLabelText="Agency"
                      onChange={this.handlePivotDropdownChange("agency")}
                      value={agency}
                      fullWidth={true}
                      style={styles.input}
                      multiple={true}
                    >
                      <MenuItem
                        value={WILDCARD}
                        children="Any Agency"
                        key="wildcard"
                      />
                    </SelectField>
                  </Card>
                </div> */}
              </div>
            </div>
          ) : (
            ""
          )}
          <Divider />
          <div className="centering" style={{ width: "100%" }}>
            <div
              className="options-toggle-button"
              onClick={() => {
                this.setState(({ hideOptions }) => ({
                  hideOptions: !hideOptions
                }));
              }}>
              {hideOptions ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowUpIcon />
              )}
            </div>
          </div>
          <br />
          {revenueLESource === "forecast" && forecastLastUpdated ? (
            <div style={{ textAlign: "right" }}>
              <span>
                Forecast last updated:{" "}
                {getDateTime(new Date(forecastLastUpdated))}
              </span>
            </div>
          ) : null}
          <BudgetTool
            data={budgetTableData}
            db={{
              [pivotTypes.CUSTOMER]: allCustomers,
              [pivotTypes.PRODUCTS]: allProductGroups,
              [pivotTypes.CONTACTS]: allContacts
            }}
            pivot={pivot}
            figureType={figureType}
            diffType={diffType}
            timeScale={time}
            pivotDate={{ month: pivotMonth, year: pivotYear }}
            loading={loadingTableData}
            dataSubTypesForPivot={dataSubTypesForPivot}
            reset={reset}
            diffThreshold={this.props.db.meta?.budget_diff_threshold ?? 20}
            combineEstimatesAndActuals={combineEstimatesAndActuals}
          />
        </div>
        <ExcelFile
          filename="Budget_Export"
          element={
            <button style={{ display: "none" }} ref={this.excelDownloadButton}>
              Download
            </button>
          }>
          <ExcelSheet data={exportData} name="Budget">
            {excelColumns.map(({ label, value }) => (
              <ExcelColumn label={label} value={value} />
            ))}
          </ExcelSheet>
        </ExcelFile>
      </div>
    ) : (
      <div style={styles.loading} className="centering">
        <CircularProgress size={80} thickness={5} />
      </div>
    );
  }
}

export default Budget;
