import axios from "axios";
import XLSX from "xlsx";
import { cloudRunFunctionURL } from "helpers/Firebase";
import { setDefault } from "./actions";

const MAXIMUM_FIREBASE_TIMEOUT = 540000;

const Confidence = {
  ANY: "ANY",
  HIGH: "HIGH",
  LOW: "LOW",
  UNMATCHED: "UNMATCHED",
  AMBIGUOUS: "AMBIGUOUS"
};

function calculateSpendRateDelta(data) {
  for (const row of data) {
    const rowNumber = row.Row;
    const spendRate = data[rowNumber]["Spend Rate"];
    const matchedSpendRate = data[rowNumber]["Matched Line Spend Rate"];
    let spendDelta = "";
    if (
      !(
        matchedSpendRate === "" ||
        matchedSpendRate.includes("|") ||
        matchedSpendRate === "-"
      )
    ) {
      spendDelta = Math.abs(
        parseFloat(spendRate) - parseFloat(matchedSpendRate)
      ).toFixed(2);
    }
    row["Spend Rate Difference"] = spendDelta;
  }
  return data;
}

function runMatching(
  db,
  setMatchLoading,
  enqueueSnackbar,
  dispatch,
  invoice,
  callback
) {
  setMatchLoading(true);
  const lines = Object.values(invoice.invoiceLines).map(row => ({
    ...row,
    customerName: db.customers[row.customerKey]?.name || "",
    productName: db.products[row.productKey]?.name || "",
    monthYear: `${new Date(row.date).getMonth() + 1}/${new Date(
      row.date
    ).getFullYear()}`,
    promType: Object.values(invoice.suggestedPromTypes ?? {})
      .map(ind => db.meta.fundTypes?.[ind]?.name || "")
      .join("|")
  }));

  const mapData = {
    "Customer Name": "customerName",
    "Customer Name (accumulation)": "No Match",
    "Product Name": "productName",
    "Month-Year": "monthYear",
    "Spend Rate": "spendRate",
    "Deductions Amount": "amount",
    "Promotion Type": "promType"
  };

  const formData = {
    companyid: db.companyid,
    source: invoice.backupSource ?? "",
    map_data: mapData,
    lines
  };

  // call match deductions function
  axios
    .post(`${cloudRunFunctionURL}/api/match_deductions`, { data: formData })
    .then(response => {
      const { data } = response.data;
      setMatchLoading(false);
      if (data.code && data.code === 400) {
        enqueueSnackbar(
          "Unknown error occurred. Please contact support if this issue persists.",
          {
            variant: "default",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center"
            }
          }
        );
      }
      const invoiceLines = Object.values(data["All Lines"]);
      const accumInvoiceLines = Object.values(data.Accumulation);
      const accumToLines = data["Accumulation to Lines"];
      const fileLineLimit = data.FILE_LINE_LIMIT;
      const fileTooLarge = data.FILE_TOO_LARGE;
      const uploadPath = data.upload_path;
      // MVP: do not use accumulated invoice lines anywhere for now
      dispatch(
        setDefault(
          accumToLines,
          invoiceLines,
          invoiceLines,
          invoiceLines,
          invoiceLines,
          fileLineLimit,
          fileTooLarge,
          uploadPath,
          null,
          true
        )
      );
      callback();
    });
}

function confidenceFilter(tableData, confidence, confidenceThresholds, source) {
  switch (confidence) {
    case Confidence.HIGH:
      return tableData.filter(row => {
        return (
          row["Matched Line Key"] != "-" &&
          (parseFloat(row["Spend Rate Difference"]) <=
            row["Spend Rate"] * (confidenceThresholds[source] || 0) * 0.01 ||
            !row["Matched Line Spend Rate"])
        );
      });
    case Confidence.LOW:
      return tableData.filter(row => {
        return (
          row["Matched Line Key"] != "-" &&
          parseFloat(row["Spend Rate Difference"]) >
            row["Spend Rate"] * (confidenceThresholds[source] || 0) * 0.01
        );
      });
    case Confidence.UNMATCHED:
      return tableData.filter(row => {
        return row["Matched Line Key"] === "-";
      });
    case Confidence.AMBIGUOUS:
      return tableData.filter(row => {
        return row["Matched Line Key"].includes("|");
      });
    default:
      return tableData;
  }
}

function exportViews({
  invoiceLines,
  confidenceThresholds,
  source,
  wsAllLinesOptions
}) {
  const allLinesDFTable = invoiceLines;
  const workbook = XLSX.utils.book_new();
  for (const [sheetName, confidenceLevel] of [
    ["All Matches - High Conf.", Confidence.HIGH],
    ["All Matches - Low Conf.", Confidence.LOW],
    ["All Matches - Needs Attn.", Confidence.UNMATCHED],
    ["All Matches - Ambiguous", Confidence.AMBIGUOUS]
  ]) {
    workbook.SheetNames.push(sheetName);
    workbook.Sheets["All Matches - High Conf."] = XLSX.utils.json_to_sheet(
      confidenceFilter(
        allLinesDFTable,
        confidenceLevel,
        confidenceThresholds,
        source
      ),
      wsAllLinesOptions
    );
  }
  workbook.SheetNames.push("All Matches");
  const wsAllLines = XLSX.utils.json_to_sheet(
    allLinesDFTable,
    wsAllLinesOptions
  );
  workbook.Sheets["All Matches"] = wsAllLines;
  const wbout = XLSX.write(workbook, {
    bookType: "xlsx",
    bookSST: false,
    type: "array"
  });
  return new Blob([wbout], { type: "application/octet-stream" });
}

export {
  calculateSpendRateDelta,
  confidenceFilter,
  exportViews,
  runMatching,
  Confidence
};
