/* eslint-disable lines-between-class-members */
import { firebase } from "helpers/Firebase";
import { CompanyUser } from "js/dbTypes";
import AdminPanelEvent from "./AdminPanelEvent";

const eventUrl = "adminPanelEvents";

class AdminPanelActivityLogService {
  private users: Record<string, CompanyUser>;
  private userId: string;
  private companyDataUrl: string;
  private companyid: string;
  private companyNames: Record<string, string>;

  constructor(config: { users; companyNames; companyid; userId }) {
    const { users, companyNames, companyid, userId } = config;
    this.users = users;
    this.userId = userId;
    this.companyDataUrl = `companies/${companyid}`;
    this.companyid = companyid;
    this.companyNames = companyNames;
  }

  private getCompanyEventUrl = (): string => {
    return `${eventUrl}`;
  };

  public addEvent = (event: string) => {
    const adminPanelEvent: AdminPanelEvent = {
      uid: this.userId,
      userName: this.users?.[this.userId]?.name ?? "Unknown User",
      eventDate: new Date().toISOString(),
      companyId: this.companyid,
      companyName: this.companyNames?.[this.companyid] ?? "Unknown Company",
      message: event
    };
    return firebase
      .database()
      .ref(this.getCompanyEventUrl())
      .push(adminPanelEvent);
  };
}

export default AdminPanelActivityLogService;
