import FileSaver from "file-saver";
import React from "react";

import Typography from "@mui/material/Typography";
import { isEqual } from "lodash-es";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import GetAppIcon from "@mui/icons-material/GetApp";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import Button from "ui-library/Button";
import MenuItem from "ui-library/MenuItem";
import Select from "ui-library/Select";

import {
  downloadFilesFromStorage,
  removeFilesFromStorage,
  updateFirebase,
  uploadFilesToStorage,
  firebase
} from "helpers/Firebase";
import { common, grey } from "@mui/material/colors";
import { Stack } from "@mui/material";
import FilePicker from "../WebsiteElements/FilePicker";

const { black } = common;
const grey500 = grey["500"];

const styles = {
  superheader: {
    fontFamily: "Ubuntu",
    marginTop: 20
  },
  header: {
    marginRight: "-50px",
    fontSize: 20,
    color: black
  },
  subheader: {
    fontSize: 20
  },
  divider: {
    margin: "30px auto",
    width: "50%"
  },
  paper: {
    padding: 16,
    marginBottom: 5,
    "white-space": "pre-line"
  }
};

class ManageBrokerFiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropzone: null,
      broker: {},
      brokerKey: "",
      fileNames: [],
      currentFileName: "",
      stagedFiles: {}
    };
  }

  addedfile = files => {
    if (!files || !files.length) {
      this.setState({ stagedFiles: {} });
      return;
    }
    const { stagedFiles } = this.state;
    for (const file of files) {
      stagedFiles[file.name] = file;
    }
    this.setState({ stagedFiles });
  };

  deletefile = file => {
    const { stagedFiles } = this.state;
    delete stagedFiles[file.name];
    this.setState({ stagedFiles });
  };

  downloadFile = () => {
    const { currentFileName } = this.state;
    const user = firebase.auth().currentUser;

    firebase
      .database()
      .ref(`users/${user.uid}/company_id`)
      .once("value", snapshot => {
        if (snapshot.val()) {
          const company_id = snapshot.val();
          const currentFilePath = `${company_id}/Broker Files/${this.state.brokerKey}/${currentFileName}`;
          downloadFilesFromStorage(
            [currentFilePath],
            [currentFileName],
            (blob, name) => FileSaver.saveAs(blob, name),
            null
          );
        }
      });
  };

  deleteFile = () => {
    removeFilesFromStorage([this.state.currentFileName]);
    const newBroker = this.state.broker;
    newBroker.fileNames.splice(
      newBroker.fileNames.indexOf(this.state.currentFileName),
      1
    );
    updateFirebase(14, newBroker, this.state.brokerKey);
  };

  handleChange = (event, index, value) => {
    const currentFileName = value;
    this.setState({ currentFileName });
  };

  uploadStagedFiles = () => {
    const { stagedFiles } = this.state;
    const stagedFileNames = Object.keys(stagedFiles);

    if (!stagedFiles) {
      return;
    }
    const user = firebase.auth().currentUser;
    const newBroker = this.state.broker;

    if (!newBroker.fileNames) {
      newBroker.fileNames = stagedFileNames;
    } else {
      newBroker.fileNames = Array.from(
        new Set(newBroker.fileNames.concat(stagedFileNames))
      );
    }

    firebase
      .database()
      .ref(`users/${user.uid}/company_id`)
      .once("value", snapshot => {
        if (snapshot.val()) {
          const company_id = snapshot.val();
          const filePaths = stagedFileNames.map(
            item => `${company_id}/Broker Files/${this.state.brokerKey}/${item}`
          );
          const fileBlobs = stagedFileNames.map(key => stagedFiles[key]);
          uploadFilesToStorage(filePaths, fileBlobs, () => {
            updateFirebase(14, newBroker, this.state.brokerKey, () => {
              this.setState({
                fileNames: newBroker.fileNames,
                currentFileName: newBroker.fileNames[0]
              });
            });
          });
        }
      });
  };

  componentDidMount() {
    if (!this.state.brokerKey) {
      // only fire on initial load
      this.setState({
        brokerKey: this.props.brokerKey,
        broker: this.props.broker
      });
      if (this.props.broker.fileNames) {
        this.setState({
          fileNames: this.props.broker.fileNames,
          currentFileName: this.props.broker.fileNames[0]
        });
      } else {
        this.setState({ fileNames: [], currentFileName: "" });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.broker, this.state.broker)) {
      this.setState({
        brokerKey: nextProps.brokerKey,
        broker: nextProps.broker
      });
      if (nextProps.broker.fileNames) {
        this.setState({
          fileNames: nextProps.broker.fileNames,
          currentFileName: nextProps.broker.fileNames[0]
        });
      } else {
        this.setState({ fileNames: [], currentFileName: "" });
      }
    }
  }

  render() {
    return (
      <div style={{ color: grey500, margin: 16 }}>
        <div className="rowC">
          <Typography variant="h5" style={styles.subheader}>
            Upload New Files
          </Typography>
        </div>
        <br />
        <div>
          <FilePicker
            message="Drop files here to upload (5 files max, up to 5 MB in size each)"
            onChange={this.addedfile}
            getDropzone={this.getDropzone}
            maxFileSize={5 << 20}
            filesLimit={5}
          />
          <br />
          <div className="centering">
            <Button
              label="Upload Files"
              primary
              onClick={this.uploadStagedFiles}
              sx={{ my: 2 }}
              icon={<AttachFileIcon />}
              disabled={
                !this.props.readOnly &&
                !this.props.db.permissions.includes("edit")
              }
            />
          </div>
        </div>
        <div className="rowC">
          <Typography variant="h5" style={styles.subheader}>
            Download And Remove Files
          </Typography>
        </div>
        <div>
          {this.state.fileNames.length > 0 ? (
            <div>
              <Stack>
                <Select
                  value={this.state.currentFileName}
                  onChange={this.handleChange.bind(null)}>
                  {this.state.fileNames.map((item, index) => (
                    <MenuItem key={index} value={item} children={item} />
                  ))}
                </Select>
                <Button
                  label="Download File"
                  onClick={this.downloadFile.bind(null)}
                  icon={<GetAppIcon />}
                />
                <Button
                  label="Delete File"
                  onClick={this.deleteFile.bind(null)}
                  color="error"
                  variant="outlined"
                  icon={<DeleteForeverIcon />}
                  disabled={
                    !this.props.readOnly &&
                    !this.props.db.permissions.includes("edit")
                  }
                />
              </Stack>
            </div>
          ) : (
            <div className="centering">
              No Associated Files with this Broker.
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ManageBrokerFiles;
