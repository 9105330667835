import React, { Fragment, useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Button,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import { useDRMEvents } from "contexts/DRMEvents";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { DbContextValues, useDb } from "contexts/Db";
import useWindowSize from "helpers/useWindowSize";
import {
  ArrowDownward,
  ArrowUpward,
  ExpandMore,
  ExpandLess
} from "@mui/icons-material";
import { displayUSCurrency } from "helpers/DataProcessing";
import { Link } from "react-router-dom";
import { renderUTCDateTimeInLocalTimeZone } from "helpers/Time";
import { DisplayDRMEvent, DRMEventType } from "./DRMEvent";
import ActivityLogCommentBox from "./ActivityLogCommentBox";
import ActivityLogComment from "./ActivityLogComment";
import { InvoiceLineDisplayService } from "../services";
import { DisplayInvoiceLineObject } from "../types/invoiceLineTypes";
import ActivityLogExport from "./ActivityLogExport";

// TODO: Make CSS work more efficiently at allocating vertical space across screen sizes
const useStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(2),
    height: "100%",
    maxHeight: "100%",
    overflowY: "hidden"
  },
  listItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    paddingLeft: "0px"
  },
  noDataContainer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  commentContainer: {
    display: "flex",
    alignItems: "flex-end",
    height: "20%"
  },
  commentInnerContainer: {
    width: "100%",
    zIndex: 100,
    backgroundColor: "white"
  },
  activityContainer: {
    overflowY: "scroll"
  },
  flex: {
    display: "flex",
    justifyContent: "space-between"
  },
  link: {
    color: theme.palette.primary.main
  }
}));

interface ActivityLogProps {
  pageSize: number;
  onScroll: (numEvents: number) => Promise<DisplayDRMEvent[]>;
}

export default function ActivityLog(props: ActivityLogProps) {
  const classes = useStyles();
  const { title, subtitle, filters } = useSelector(
    (state: RootState) => state.activityLog
  );
  const { getEvents, getTags, addEvent } = useDRMEvents();
  const [events, setEvents] = useState<DisplayDRMEvent[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [tagRecord, setTagRecord] = useState<
    Record<string, Record<string, number[]>>
  >({});
  const [sortDir, setSortDir] = useState<"asc" | "desc">("desc");
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [completeEvents, setCompleteEvents] = useState<DisplayDRMEvent[]>([]);
  const db = useDb();
  const { width } = useWindowSize();

  const loadTags = useCallback(() => {
    setLoading(true);
    getTags().then(record => {
      setTagRecord(record);
      setLoading(false);
    });
  }, [getTags]);

  useEffect(() => {
    loadTags();
  }, [loadTags]);

  const loadEvents = useCallback(() => {
    setLoading(true);
    getEvents(filters || {}).then(incomingEvents => {
      incomingEvents.reverse();
      setCompleteEvents(incomingEvents);
      setEvents(
        incomingEvents.slice(0, Math.min(incomingEvents.length, props.pageSize))
      );
      setLoading(false);
    });
  }, [filters, getEvents, props.pageSize]);

  const reloadEvents = () => {
    setLoading(true);
    getEvents(filters || {}).then(incomingEvents => {
      if (sortDir === "asc") {
        // inverse of what it was before
        incomingEvents.reverse();
      }
      const size = Math.max(events.length, props.pageSize);
      setEvents(incomingEvents.slice(0, Math.min(incomingEvents.length, size)));
      setLoading(false);
    });
  };

  useEffect(() => {
    loadEvents();
  }, [loadEvents]);

  const loadMore = () => {
    if (props.onScroll && props.pageSize) {
      setLoading(true);
      const newLength = events.length + props.pageSize;
      props.onScroll(events.length + props.pageSize).then(incomingEvents => {
        if (sortDir === "desc") {
          // replace with graphql later
          incomingEvents.reverse();
        }
        if (incomingEvents.length < newLength) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
        setEvents(
          incomingEvents.slice(0, Math.min(incomingEvents.length, newLength))
        );
        setLoading(false);
      });
    } else if (events.length === 0) {
      loadEvents();
    }
  };

  const getEntityDetail = (event: DisplayDRMEvent): string | JSX.Element => {
    let entityDescription: string | JSX.Element = "";
    if (filters?.allInvoices && event.invoiceKey) {
      const invoice = db.invoices[event.invoiceKey];
      entityDescription = invoice ? (
        <Link
          className={classes.link}
          to={`/reconciliation/invoice/${invoice.key}`}>{`Invoice No. ${invoice.invoiceNumber}`}</Link>
      ) : (
        ""
      );
    }
    if (filters?.allTransactions && event.transactionKey) {
      const transaction = db.erpTransactions[event.transactionKey];
      entityDescription = transaction ? (
        <Link
          className={classes.link}
          to={`/reconciliation/transaction/${transaction.key}`}>{`Transaction ID # ${transaction.transactionDisplayId}`}</Link>
      ) : (
        ""
      );
    }
    return entityDescription;
  };

  const allEvents = [...(events || [])].sort((drmEventA, drmEventB) =>
    moment
      .utc((sortDir === "asc" ? drmEventA : drmEventB).createDate)
      .diff(moment.utc((sortDir === "asc" ? drmEventB : drmEventA).createDate))
  );

  const sortedCompleteEvents = [...(completeEvents || [])].sort(
    (drmEventA, drmEventB) =>
      moment
        .utc((sortDir === "asc" ? drmEventA : drmEventB).createDate)
        .diff(
          moment.utc((sortDir === "asc" ? drmEventB : drmEventA).createDate)
        )
  );

  const showEntityDetail = Boolean(
    filters?.allInvoices || filters?.allTransactions
  );

  const getCommentBoxSubtitle = () => {
    if (filters?.allInvoices) {
      return "All Invoices";
    }
    if (filters?.allTransactions) {
      return "All Transactions";
    }
    if (Object.keys(filters || {}).length === 1 && filters?.invoiceKey) {
      return `Invoice No. ${db.invoices[filters.invoiceKey].invoiceNumber}`;
    }
    if (Object.keys(filters || {}).length === 1 && filters?.transactionKey) {
      return `Transaction ID # ${
        db.erpTransactions[filters.transactionKey].transactionDisplayId
      }`;
    }
    return undefined;
  };

  const getDescriptionJsx = (event: DisplayDRMEvent): JSX.Element => {
    const userJsx = <strong>{event.user.name}</strong>;
    const invoice = db.invoices[event.invoiceKey || ""];
    let displayInvoiceLine: DisplayInvoiceLineObject | undefined;
    const invoiceLine = invoice?.invoiceLines?.[event.invoiceLineKey || ""];
    if (invoiceLine) {
      // this gives us activity date & product group name
      // eslint-disable-next-line unused-imports/no-unused-vars
      displayInvoiceLine =
        InvoiceLineDisplayService.processInvoiceLineForDisplay(
          invoice,
          invoiceLine,
          event.invoiceLineKey || "",
          db.meta.fundTypes ?? {},
          db.invoices,
          db.erpTransactions,
          db.allLines,
          db.products,
          db.customers
        );
    }
    const transaction = db.erpTransactions[event.transactionKey || ""];

    const repaidTransaction =
      db.erpTransactions[event.repaidTransactionKey || ""];

    switch (event.type) {
      case DRMEventType.NEW_COMMENT_ADDED: {
        return (
          <span>
            {userJsx}
            &nbsp;commented:
          </span>
        );
      }
      case DRMEventType.INVOICE_LINE_CLEARED: {
        return (
          <span>
            {userJsx} cleared{" "}
            {displayUSCurrency(event.metadata?.clearedAmount || 0)} for Invoice
            No. {invoice?.invoiceNumber} linked to Transaction ID #&nbsp;
            {transaction?.transactionDisplayId}.
          </span>
        );
      }
      case DRMEventType.SET_SALES_REVIEW: {
        return (
          <span>
            {userJsx} requested sales review on a Line Item in Invoice No.{" "}
            {invoice?.invoiceNumber}
          </span>
        );
      }
      case DRMEventType.FLAG_DISPUTE: {
        return (
          <span>
            {userJsx} Flagged a Line Item in Invoice No.{" "}
            {invoice?.invoiceNumber} linked to Transaction ID #&nbsp;
            {transaction?.transactionDisplayId}
          </span>
        );
      }
      case DRMEventType.WRITE_OFF: {
        return (
          <span>
            {userJsx} Wrote Off a Line in Invoice No. {invoice?.invoiceNumber}
          </span>
        );
      }
      case DRMEventType.TRANSACTION_CLEARED: {
        return (
          <span>
            Transaction ID # {transaction?.transactionDisplayId} cleared by{" "}
            {userJsx}
          </span>
        );
      }
      case DRMEventType.INVOICE_LINES_SAVED: {
        return (
          <span>
            {userJsx} saved an invoice line to Invoice No.{" "}
            {invoice.invoiceNumber}
          </span>
        );
      }
      case DRMEventType.INVOICE_LINE_DELETED: {
        return (
          <span>
            {userJsx} deleted an invoice line from Invoice No.{" "}
            {invoice.invoiceNumber}
          </span>
        );
      }
      case DRMEventType.ALL_INVOICE_LINES_DELETED: {
        return (
          <span>
            {userJsx} deleted all invoice lines from Invoice No.{" "}
            {invoice.invoiceNumber}
          </span>
        );
      }
      case DRMEventType.INVOICE_ASSIGNED: {
        return (
          <span>
            {userJsx} assigned Invoice No. {invoice.invoiceNumber} to{" "}
            {db.companyUsers[event.metadata?.userId || ""]?.name}
          </span>
        );
      }
      case DRMEventType.TRANSACTION_ASSIGNED: {
        return (
          <span>
            {userJsx} assigned Transaction ID #
            {transaction?.transactionDisplayId} to{" "}
            {db.companyUsers[event.metadata?.userId || ""]?.name}
          </span>
        );
      }
      case DRMEventType.INVOICE_UNASSIGNED: {
        return (
          <span>
            {db.companyUsers[event.metadata?.userId || ""]?.name} was unassigned
            from Invoice No. {invoice.invoiceNumber} by {userJsx}
          </span>
        );
      }
      case DRMEventType.TRANSACTION_UNASSIGNED: {
        return (
          <span>
            {db.companyUsers[event.metadata?.userId || ""]?.name} was unassigned
            from Transaction ID # {transaction?.transactionDisplayId} by{" "}
            {userJsx}
          </span>
        );
      }
      case DRMEventType.REPAYMENT_APPLIED: {
        return (
          <span>
            {userJsx} applied Repayment ID # {transaction?.transactionDisplayId}{" "}
            to repay a dispute under Deduction ID #{" "}
            {repaidTransaction?.transactionDisplayId}
          </span>
        );
      }
      case DRMEventType.REPAYMENT_ATTACHED: {
        return (
          <span>
            {userJsx} attached Repayment ID #{" "}
            {transaction?.transactionDisplayId} to Deduction ID #{" "}
            {repaidTransaction?.transactionDisplayId}
          </span>
        );
      }
      case DRMEventType.REPAYMENT_UNATTACHED: {
        return (
          <span>
            {userJsx} unattached Repayment ID #{" "}
            {transaction?.transactionDisplayId} from Deduction ID #{" "}
            {repaidTransaction?.transactionDisplayId}
          </span>
        );
      }
      case DRMEventType.CREDIT_WRITE_OFF_APPLIED: {
        return (
          <span>
            {userJsx} applied Repayment ID # {transaction?.transactionDisplayId}{" "}
            to credit a write-off under Deduction ID #{" "}
            {repaidTransaction?.transactionDisplayId}
          </span>
        );
      }
      case DRMEventType.INVOICE_UNLINKED:
      case DRMEventType.INVOICE_LINKED:
      default:
        return (
          <span>
            {event.type} by {userJsx}
          </span>
        );
    }
  };

  const handleEventExpand = (key: string) => {
    const updatedEvents = [...events];
    const eventIndex = events.findIndex(event => event.key === key);
    const selectedEvent = updatedEvents[eventIndex];
    selectedEvent.displayDetails = !selectedEvent.displayDetails;
    setEvents(updatedEvents);
  };

  const getDetailsJsx = (event: DisplayDRMEvent): JSX.Element => {
    const { invoiceKey = "", invoiceLineKey = "", metadata } = event;
    const {
      customers = {},
      meta,
      invoices = {},
      accounts = {},
      products = {}
    }: Partial<DbContextValues> = db;
    // eslint-disable-next-line camelcase
    const { product_groups = {}, fundTypes = {} } = meta;

    const customer = customers[metadata?.customerKey || ""];
    const productGroup = product_groups[metadata?.productGroupKey || ""];
    const product = products[metadata?.productKey || ""];
    const fundType = fundTypes[metadata?.fundType || ""]?.name || "None";

    const productGroupName = productGroup?.name || "None";
    const productName = product?.name || "None";
    const customerName = customer?.name || "None";

    const invoice = invoices[invoiceKey] || {};
    const { invoiceLines = {} } = invoice;
    const invoiceLine = invoiceLines[invoiceLineKey];
    const invoiceLineNumber = invoiceLine?.invoiceLineNumber;

    if (metadata) {
      switch (event.type) {
        case DRMEventType.INVOICE_LINE_CLEARED: {
          return (
            <span>
              <div>
                <b>Customer: </b>
                {customerName}
              </div>
              <div>
                <b>Product: </b>
                {productName || "None"}
              </div>
              <div>
                <b>Product Group: </b>
                {productGroupName || "None"}
              </div>
              <div>
                <b>Cleared Amount: </b>
                {displayUSCurrency(metadata.clearedAmount)}
              </div>
              <div>
                <b>Total Amount: </b>
                {displayUSCurrency(metadata.totalInvoiceLineAmount)}
              </div>
              <div>
                <b>Cleared Fund Type: </b>
                {fundTypes[metadata.clearedFundType || ""]?.name}
              </div>
              <div>
                <b>Cleared Gl: </b>
                {accounts[metadata.clearedGl || ""]?.name}
              </div>
            </span>
          );
        }
        case DRMEventType.SET_SALES_REVIEW: {
          return (
            <span>
              <div>
                <b>Customer: </b>
                {customerName}
              </div>
              <div>
                <b>Product: </b>
                {productName || "None"}
              </div>
              <div>
                <b>Product Group: </b>
                {productGroupName !== "" ? productGroupName : "None"}
              </div>
              <div>
                <b>Activity Date: </b>
                {renderUTCDateTimeInLocalTimeZone(event.createDate)}
              </div>
              <div>
                <b>Original Amount: </b>
                {displayUSCurrency(metadata?.totalInvoiceLineAmount || 0)}
              </div>
            </span>
          );
        }
        case DRMEventType.FLAG_DISPUTE: {
          return (
            <span>
              <div>
                <b>Customer: </b>
                {customerName}
              </div>
              <div>
                <b>Product: </b>
                {productName || "None"}
              </div>
              <div>
                <b>Product Group: </b>
                {productGroupName || "None"}
              </div>
              <div>
                <b>Activity Date: </b>
                {renderUTCDateTimeInLocalTimeZone(event.createDate)}
              </div>
              <div>
                <b>Original Amount: </b>
                {displayUSCurrency(metadata?.totalInvoiceLineAmount || 0)}
              </div>
            </span>
          );
        }
        case DRMEventType.WRITE_OFF: {
          return (
            <span>
              <div>
                <b>Customer: </b>
                {customerName}
              </div>
              <div>
                <b>Product: </b>
                {productName || "None"}
              </div>
              <div>
                <b>Product Group: </b>
                {productGroupName || "None"}
              </div>
              <div>
                <b>Activity Date: </b>
                {renderUTCDateTimeInLocalTimeZone(event.createDate)}
              </div>
              <div>
                <b>Original Amount: </b>
                {displayUSCurrency(metadata?.totalInvoiceLineAmount || 0)}
              </div>
              <div>
                <b>Write Off Amount: </b>
                {displayUSCurrency(metadata?.writeOffAmount || 0)}
              </div>
              <div>
                <b>Write Off Fund Type: </b>
                {fundTypes[metadata?.writeOffFundType || ""]?.name}
              </div>
            </span>
          );
        }
        case DRMEventType.REPAYMENT_APPLIED: {
          return (
            <span>
              <div>
                <b>Activity Date: </b>
                {renderUTCDateTimeInLocalTimeZone(event.createDate)}
              </div>
              <div>
                <b>Repaid Invoice: </b>
                {invoice?.invoiceNumber || "None"}
              </div>
              <div>
                <b>Repaid Invoice Line: </b>
                {invoiceLineNumber || invoiceLineNumber === 0
                  ? (invoiceLineNumber + 1).toString()
                  : "None"}
              </div>
              <div>
                <b>Repaid Amount: </b>
                {displayUSCurrency(metadata?.amount || 0)}
              </div>
              <div>
                <b>Fund Type: </b>
                {fundType}
              </div>
              <div>
                <b>Fund Type Account: </b>
                {db.accounts?.[metadata?.fundTypeAccount || ""]?.name || "None"}
              </div>
            </span>
          );
        }
        case DRMEventType.CREDIT_WRITE_OFF_APPLIED: {
          return (
            <span>
              <div>
                <b>Activity Date: </b>
                {renderUTCDateTimeInLocalTimeZone(event.createDate)}
              </div>
              <div>
                <b>Credited Invoice: </b>
                {invoice?.invoiceNumber}
              </div>
              <div>
                <b>Credited Invoice Line: </b>
                {invoiceLineNumber || invoiceLineNumber === 0
                  ? (invoiceLineNumber + 1).toString()
                  : "None"}
              </div>
              <div>
                <b>Credited Amount: </b>
                {displayUSCurrency(metadata?.amount || 0)}
              </div>
              <div>
                <b>Fund Type: </b>
                {fundType}
              </div>
              <div>
                <b>Fund Type Account: </b>
                {db.accounts?.[metadata?.fundTypeAccount || ""]?.name || "None"}
              </div>
            </span>
          );
        }
        default: {
          return <></>;
        }
      }
    }
    return <></>;
  };
  const commentsEnabled = !filters?.allInvoices && !filters?.allTransactions;
  return (
    <Grid
      container
      className={classes.container}
      style={{ width: `${(width || 0) / 3}px` }}>
      <Grid
        item
        md={12}
        className={classes.activityContainer}
        id="activityLogScrollable"
        style={{ height: commentsEnabled ? "80%" : "100%" }}>
        <div className={classes.flex}>
          <Typography variant="h5">{title}</Typography>&nbsp;{" "}
          <div>
            <Button
              size="small"
              startIcon={
                sortDir === "asc" ? <ArrowDownward /> : <ArrowUpward />
              }
              variant="outlined"
              onClick={() => {
                reloadEvents();
                setSortDir(sortDir === "asc" ? "desc" : "asc");
              }}>
              Sort By Date
            </Button>
            &nbsp;
            <ActivityLogExport events={sortedCompleteEvents} />
          </div>
        </div>
        {subtitle && <Typography variant="subtitle1">{subtitle}</Typography>}
        {allEvents.length > 0 ? (
          <>
            <List>
              <InfiniteScroll
                dataLength={events.length}
                next={loadMore}
                hasMore={hasMore}
                scrollableTarget="activityLogScrollable"
                scrollThreshold={0.8}
                loader={<h4>Loading...</h4>}>
                {allEvents.map((event: DisplayDRMEvent, eventIdx) => (
                  <Fragment key={event.key}>
                    <ListItem
                      button
                      onClick={() => handleEventExpand(event.key)}
                      className={classes.listItem}>
                      <ListItemText
                        primary={
                          <div>
                            <Typography variant="body2">{`${moment(
                              event.createDate
                            ).format("L hh:mm a")} | ${
                              event.type
                            }`}</Typography>
                            {showEntityDetail && (
                              <Typography color="primary" variant="body2">
                                {getEntityDetail(event)}
                              </Typography>
                            )}
                            <Typography variant="body2">
                              {getDescriptionJsx(event)}
                            </Typography>
                          </div>
                        }
                        secondary={
                          event.comment ? (
                            <ActivityLogComment
                              event={event}
                              tags={tagRecord[event.key] || {}}
                            />
                          ) : null
                        }
                      />
                      {event.metadata &&
                        Object.keys(event.metadata).length > 1 &&
                        (event.displayDetails ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        ))}
                    </ListItem>
                    <Collapse in={event.displayDetails}>
                      {getDetailsJsx(event)}
                    </Collapse>
                    {eventIdx !== allEvents.length - 1 && <Divider />}
                  </Fragment>
                ))}
              </InfiniteScroll>
            </List>
          </>
        ) : (
          <div className={classes.noDataContainer}>
            {loading ? <CircularProgress /> : <i>No Activity Found</i>}
          </div>
        )}
      </Grid>
      {commentsEnabled && (
        <Grid
          item
          md={12}
          justifyContent="flex-end"
          className={classes.commentContainer}>
          <div className={classes.commentInnerContainer}>
            <Divider />
            <ActivityLogCommentBox
              addEvent={addEvent}
              companyUsers={db.companyUsers ?? {}}
              title="Add a Comment"
              subtitle={getCommentBoxSubtitle()}
              reloadEvents={loadEvents}
            />
          </div>
        </Grid>
      )}
    </Grid>
  );
}
