import { firebase } from "./Firebase";

const httpsCallable = (name, options) =>
  firebase.functions().httpsCallable(`${APP_ENV_PREFIX || ""}${name}`, options);

// For testing python cloud functions with functions-framework
const localFunction =
  (port, url = "http://localhost") =>
  async (data = {}, method = "POST") => {
    const token = await firebase.auth().currentUser.getIdToken();
    const response = await fetch(`${url}:${port}`, {
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ data })
    });

    return response.json();
  };

export { httpsCallable, localFunction };
