import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import {
  MenuItem as MUIMenuItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction
} from "@mui/material";

import { grey } from "@mui/material/colors";

const grey500 = grey["500"];

const getLabel = (children, checked, disabled) => {
  if (checked) {
    return (
      <>
        <ListItemIcon>
          <CheckIcon />
        </ListItemIcon>
        <ListItemText
          primary={children}
          sx={{ color: disabled ? "action.disabled" : "warning.main" }}
        />
      </>
    );
  }
  return children;
};

const MenuItem = props => {
  const {
    children,
    secondary,
    rightIcon,
    leftIcon,
    checked,
    disabled,
    ...other
  } = props;
  return (
    <MUIMenuItem {...other} disabled={disabled}>
      {leftIcon && <ListItemIcon>{leftIcon}</ListItemIcon>}
      {getLabel(children, checked, disabled)}
      {secondary && (
        <ListItemSecondaryAction style={{ color: grey500 }}>
          {secondary}
        </ListItemSecondaryAction>
      )}
      {rightIcon && <ListItemIcon>{rightIcon}</ListItemIcon>}
    </MUIMenuItem>
  );
};

export default MenuItem;
