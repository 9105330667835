import { Button, Drawer, IconButton, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Notifications from "@mui/icons-material/Notifications";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useDRMEvents } from "contexts/DRMEvents";
import { DisplayDRMEvent } from "./DRMEvent";
import { DRMEventFilters } from "./DRMEventService";
import ActivityLog from "./ActivityLog";
import { setSubtitle, setTitle, setFilters } from "./ActivityLogSlice";

const useStyles = makeStyles(theme => ({
  iconButton: {
    color: theme.palette.text.primary
  }
}));

interface ActivityLogButtonProps {
  filters: DRMEventFilters;
  title: string;
  subtitle?: string;
  iconButton?: boolean;
}

export default function ActivityLogButton(props: ActivityLogButtonProps) {
  const classes = useStyles();
  const { filters, title, subtitle, iconButton } = props;
  const dispatch = useDispatch();
  const [showDrawer, setShowDrawer] = useState(false);
  const { getEvents } = useDRMEvents();

  const openActivityLog = () => {
    dispatch(setTitle(title));
    dispatch(setSubtitle(subtitle || ""));
    dispatch(setFilters(filters));
    setShowDrawer(true);
  };

  /**
   * This is a temporary onScroll function, currently it just sends all the events
   * Replace with an actual function that only sends first numEvents events
   * @param numEvents Number of events to send at most
   * @returns The events filtered out by filters, without at most numEvents
   */
  const onScroll = (numEvents: number = -1): Promise<DisplayDRMEvent[]> => {
    // returns a promise, returns all events
    return getEvents(filters || {});
  };

  return (
    <>
      {iconButton ? (
        <div>
          <Tooltip title="View Activity Log">
            <IconButton
              onClick={openActivityLog}
              className={classes.iconButton}
              size="large">
              <Notifications />
            </IconButton>
          </Tooltip>
        </div>
      ) : (
        <Button
          color="primary"
          variant="outlined"
          onClick={openActivityLog}
          startIcon={<Notifications />}>
          Activity Log
        </Button>
      )}
      <Drawer
        anchor="right"
        open={showDrawer}
        onClose={() => setShowDrawer(false)}>
        <ActivityLog onScroll={onScroll} pageSize={50} />
      </Drawer>
    </>
  );
}
