import React, { useMemo } from "react";
import { Avatar, Chip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DisplayDRMEvent } from "./DRMEvent";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "center"
  },
  commentContainer: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    display: "flex",
    borderRadius: theme.spacing(2),
    backgroundColor: "#FCFCF6"
  },
  commentText: {
    marginLeft: theme.spacing(3),
    color: "#454743",
    fontFamily: "Roboto",
    fontSize: "1.3rem"
  }
}));

interface ActivityLogCommentProps {
  event: DisplayDRMEvent;
  tags: Record<string, number[]>;
}

export default function ActivityLogComment({
  event,
  tags
}: ActivityLogCommentProps) {
  const classes = useStyles();
  const tagKeys = useMemo(
    () =>
      Object.keys(tags).sort((a, b) => {
        // we sort the tag keys by the tag's substring start index
        const valueA = tags[a][0];
        const valueB = tags[b][0];
        if (valueA < valueB) {
          return -1;
        }
        if (valueA > valueB) {
          return 1;
        }
        return 0;
      }),
    [tags]
  );

  const getAvatarText = (name: string): string[] =>
    name.split(" ").map(str => str?.[0]?.toUpperCase?.() || "");

  const tagify = (tagText: string): JSX.Element => {
    const avatar = <Avatar sizes="s">{getAvatarText(tagText)}</Avatar>;
    return <Chip clickable avatar={avatar} label={tagText} />;
  };

  const getProcessedText = (): JSX.Element[] | string => {
    if (!tagKeys.length) {
      return event.comment;
    }
    const commentJsx: JSX.Element[] = [];
    let { comment } = event;
    let increment = 0;

    // iterate over tags and form JSX by splitting up text
    tagKeys.forEach((tagKey, idx) => {
      let [start, end] = tags[tagKey];
      start -= increment;
      end -= increment;

      // split up text
      const startText = comment.slice(0, start);
      const tagText = comment.slice(start, end);
      const endText = comment.slice(end, comment.length);

      // append JSX
      commentJsx.push(<span>{startText}</span>);
      commentJsx.push(tagify(tagText));

      if (idx === tagKeys.length - 1) {
        // if last text section, simply append it to JSX
        commentJsx.push(<span>{endText}</span>);
      } else {
        // else, update comment to be remaining text, update increment
        comment = endText;
        increment += startText.length + tagText.length;
      }
    });
    return commentJsx;
  };

  return (
    <div className={classes.container}>
      <div className={classes.commentContainer}>
        <Avatar sizes="s">{getAvatarText(event.user.name)}</Avatar>
        <p className={classes.commentText}>“{getProcessedText()}”</p>
      </div>
    </div>
  );
}
