import React from "react";

const styles = {
  card: {
    marginBottom: 20,
    boxShadow: "0 1px 2px #888888"
  },
  title: {
    fontFamily: "Ubuntu",
    fontSize: 20,
    padding: "16px 16px",
    borderStyle: "solid",
    borderWidth: 0,
    borderBottomWidth: 3,
    borderBottomColor: "#EEEEEE"
  },
  content: {
    padding: "16px 8%"
  }
};

const EntryCard = props => {
  return (
    <div style={styles.card}>
      <div style={styles.title}>{props.title}</div>
      <div style={styles.content}>{props.children}</div>
    </div>
  );
};

export default EntryCard;
