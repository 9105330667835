import React from "react";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import PollIcon from "@mui/icons-material/Poll";
import IconButton from "ui-library/IconButton";
import {
  ComposedChart,
  Line,
  Bar,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceArea
} from "recharts";
import { grey } from "@mui/material/colors";
import { graphPalette } from "helpers/ColorPalettes";

const grey500 = grey["500"];
const { purple70, blue70, green70 } = graphPalette;

const CustomizedAxisTick = ({ x, y, payload, weeks }) => (
  <g transform={`translate(${x},${y})`}>
    <text
      x={0}
      y={0}
      dy={16}
      textAnchor="end"
      fill="#666"
      transform="rotate(-20)"
      fontSize={13}>
      {weeks[payload.value]}
    </text>
  </g>
);

class ActualsVsForecastTimeSeries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      left: "dataMin",
      right: "dataMax",
      bottom: dataMin => 1.1 * dataMin,
      top: dataMax => 1.1 * dataMax,
      refAreaLeft: "",
      refAreaRight: "",
      startingWeek: null,
      endingWeek: null
    };
  }

  getAxisYDomain = (from, to, refs, offset) => {
    const refData = this.props.data.slice(from - 1, to);
    let [bottom, top] = [refData[0][refs[0]], refData[0][refs[0]]];
    refData.forEach(d => {
      for (const ref of refs) {
        if (d[ref] > top) {
          top = d[ref];
        }
        if (d[ref] < bottom) {
          bottom = d[ref];
        }
      }
    });

    return [(bottom | 0) * offset, (top | 0) * offset];
  };

  zoom = () => {
    let { refAreaLeft, refAreaRight, startingWeek, endingWeek } = this.state;

    if (refAreaLeft === refAreaRight || refAreaRight === "") {
      this.setState(() => ({
        refAreaLeft: "",
        refAreaRight: "",
        startingWeek: null,
        endingWeek: null
      }));
      return;
    }

    // xAxis domain
    if (refAreaLeft > refAreaRight) {
      [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];
      [startingWeek, endingWeek] = [endingWeek, startingWeek];
    }
    const [bottom, top] = this.getAxisYDomain(
      refAreaLeft,
      refAreaRight,
      ["Actuals", "Forecast", "Difference"],
      1.1
    );

    this.setState(
      {
        refAreaLeft: "",
        refAreaRight: "",
        startingWeek: null,
        endingWeek: null,
        left: refAreaLeft,
        right: refAreaRight,
        top,
        bottom
      },
      () => this.props.onChartZoom({ startingWeek, endingWeek })
    );
  };

  zoomOut = () => {
    this.setState(
      {
        refAreaLeft: "",
        refAreaRight: "",
        startingWeek: null,
        endingWeek: null,
        left: "dataMin",
        right: "dataMax",
        bottom: dataMin => 1.1 * dataMin,
        top: dataMax => 1.1 * dataMax
      },
      () => this.props.onChartZoom({ startingWeek: null, endingWeek: null })
    );
  };

  formatTooltip = (value, name, props) => {
    return this.props.weeks[value];
  };

  handleChartMouse = boundary => event => {
    const {
      activeLabel,
      activePayload: [
        {
          payload: { Week: week }
        }
      ]
    } = event;
    if (boundary === "left") {
      return this.setState({
        refAreaLeft: activeLabel,
        startingWeek: week
      });
    }
    if (this.state.refAreaLeft) {
      return this.setState({ refAreaRight: activeLabel, endingWeek: week });
    }
  };

  render() {
    const { left, right, bottom, top, refAreaLeft, refAreaRight } = this.state;
    return (
      <div>
        <IconButton onClick={this.zoomOut} size="large">
          <ZoomOutIcon />
        </IconButton>
        <div style={{ height: 550 }}>
          {this.props.data && this.props.data.length > 0 ? (
            <ResponsiveContainer height="100%">
              <ComposedChart
                data={this.props.data}
                margin={{ top: 10, right: 60, left: 0, bottom: 20 }}
                onMouseDown={this.handleChartMouse("left")}
                onMouseMove={this.handleChartMouse("right")}
                onMouseUp={this.zoom}>
                <XAxis
                  allowDataOverflow
                  dataKey="Index"
                  type="number"
                  tick={<CustomizedAxisTick weeks={this.props.weeks} />}
                  domain={[left, right]}
                />
                <YAxis
                  allowDataOverflow
                  tickFormatter={d3.format(".2s")}
                  domain={[bottom, top]}
                />
                <CartesianGrid stroke="#ccc" />
                <Tooltip
                  contentStyle={{
                    backgroundColor: "rgba(0, 0, 0, 0.8)",
                    color: "white"
                  }}
                  labelFormatter={this.formatTooltip}
                />
                <Legend verticalAlign="top" />
                <Line
                  type="monotone"
                  dataKey="Actuals"
                  stroke={blue70}
                  strokeWidth={3}
                  dot={false}
                />
                <Area
                  type="monotone"
                  dataKey="Forecast"
                  fill={purple70}
                  stroke={purple70}
                  strokeWidth={3}
                />
                <Bar barSize={4} dataKey="Difference" fill={green70} />
                {refAreaLeft && refAreaRight ? (
                  <ReferenceArea
                    x1={refAreaLeft}
                    x2={refAreaRight}
                    strokeOpacity={0.4}
                  />
                ) : null}
              </ComposedChart>
            </ResponsiveContainer>
          ) : (
            <div style={{ height: 350, textAlign: "center", paddingTop: 150 }}>
              No data to display. <br />
              <PollIcon style={{ color: grey500, fontSize: 60 }} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ActualsVsForecastTimeSeries;
