import { connect } from "react-redux";
import React from "react";

import objDiff from "helpers/objDiff";
import Mixpanel from "helpers/Mixpanel";

import {
  updateFirebase,
  addFirebase,
  uploadFilesToStorage,
  sendNotif,
  firebase
} from "helpers/Firebase";
import CircularProgress from "@mui/material/CircularProgress";
import { grey, common } from "@mui/material/colors";
import { changeStep } from "./actions";

const grey500 = grey["500"];
const { black } = common;

class AddPromStep3 extends React.Component {
  uploadCompletedCallback = () => {
    this.props.dispatch(changeStep(true, this.props.db, this.props.mode));
  };

  errorCallback = error => {
    this.props.openClose.showSnack("Unknown error occurred.");
    this.props.dispatch(changeStep(false, this.props.db, this.props.mode));
  };

  uploadFiles = () => {
    if (!this.props?.proposed) {
      const { fileInfo } = this.props;
      const newProm = this.props.promState;
      newProm.fileInfo = fileInfo;
      const blobs = fileInfo
        ? jQuery.extend(
            true,
            [],
            fileInfo.map(x => x.file)
          )
        : [];

      if (newProm.fileInfo && !this.props?.proposed) {
        for (let i = 0; i < newProm.fileInfo.length; i++) {
          newProm.fileInfo[i].file = null;
        }
      }

      const user = firebase.auth().currentUser;
      firebase
        .database()
        .ref(`users/${user.uid}/company_id`)
        .once("value", snapshot => {
          if (snapshot.val()) {
            const company_id = snapshot.val();
            const filePaths = fileInfo.map(
              item =>
                `${company_id}/Promotion Files/${newProm.promKey}/${item.name}`
            );
            uploadFilesToStorage(
              filePaths,
              blobs,
              () => {
                // If in editing mode use updateFirebase, otherwise use addFirebase to create new promotion
                if (this.props.mode == "edit" && newProm.promKey) {
                  updateFirebase(
                    0,
                    newProm,
                    newProm.promKey,
                    promKey => {
                      this.uploadCompletedCallback();
                      sendNotif(
                        `Promotion has been edited: ${newProm.name}`,
                        "editProm",
                        null,
                        { promKey: newProm.promKey }
                      );
                      Mixpanel.track("Master Edit", {
                        Delta: objDiff(newProm, this.props.oldProm)
                      });
                    },
                    this.errorCallback
                  );
                } else {
                  addFirebase(
                    0,
                    newProm,
                    promKey => {
                      this.uploadCompletedCallback();
                      sendNotif(
                        `New promotion created: ${newProm.name}`,
                        "addProm",
                        null,
                        { promKey: newProm.promKey }
                      );
                      Mixpanel.track("Promotion Created");
                    },
                    newProm.promKey,
                    this.errorCallback
                  );
                }
              },
              null,
              this.errorCallback
            );
          }
        });
    } else {
      // Unconfirmed promos are only stored in local state
      this.uploadCompletedCallback();
    }
  };

  constructor(props) {
    super(props);
    if (props.oldProm && props.oldProm.fileInfo && !props?.proposed) {
      for (let i = 0; i < props.oldProm.fileInfo.length; i++) {
        props.oldProm.fileInfo[i].file = null;
      }
    }
  }

  componentDidMount() {
    this.uploadFiles();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <div
        style={{ fontFamily: "Oxygen", height: "400px", overflowY: "scroll" }}>
        <div className="centering">
          <CircularProgress size={80} thickness={5} />
        </div>
        <br />
        <div className="centering">
          Uploading this promotion. Please wait...
        </div>
        <br />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const promFields = state.addPromotion;
  return {
    promState: promFields.newProm,
    fileInfo: promFields.fileInfo,
    promKey: promFields.promKey,
    mode: promFields.mode
  };
};

export default connect(mapStateToProps)(AddPromStep3);
