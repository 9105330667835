import React, { Component } from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Subheader from "ui-library/Subheader";
import Button from "ui-library/Button";
import { firebase } from "helpers/Firebase";
import ChipInput from "components/WebsiteElements/ChipInput";

const styles = {
  subheader: {
    marginLeft: "-15px",
    marginRight: "-50px",
    fontSize: 20
  },
  customTagChip: {
    margin: 4
  }
};
class CustomTagsManager extends Component {
  state = {
    companyId: null,
    dbCustomTags: [],
    newCustomTags: [],
    changes: false
  };

  componentDidMount() {
    const { uid: authUserUid } = firebase.auth().currentUser;

    firebase
      .database()
      .ref(`users/${authUserUid}/company_id`)
      .once("value", snapshot => {
        const companyId = snapshot.val();
        this.setState({ companyId });

        firebase
          .database()
          .ref(`companies/${companyId}/meta/customTags`)
          .on("value", snapshot => {
            if (snapshot.exists()) {
              this.setState({
                dbCustomTags: snapshot.val()
              });
            }
          });
      });
  }

  handleNewCustomTags = operation => newTagValues => {
    this.setState({
      newCustomTags: [...newTagValues]
    });
    this.setState({ changes: true });
  };

  saveCustomTags = () => {
    const { companyId, dbCustomTags, newCustomTags } = this.state;
    firebase
      .database()
      .ref(`companies/${companyId}/meta/customTags`)
      .set([...dbCustomTags, ...newCustomTags])
      .then(() => {
        this.setState({
          newCustomTags: [],
          changes: false
        });
      });
  };

  removeDbTag = tagToRemove => () => {
    const { dbCustomTags } = this.state;
    this.setState({
      dbCustomTags: dbCustomTags.filter(tag => tag !== tagToRemove),
      changes: true
    });
  };

  render() {
    const { dbCustomTags, newCustomTags, changes } = this.state;
    return (
      <div>
        <div className="rowC">
          <span>
            Press Enter after typing a Global Custom Tag to create it.
          </span>
        </div>
        <ChipInput
          label="New Global Custom Tags"
          fullWidth
          value={newCustomTags}
          onAdd={this.handleNewCustomTags("add")}
          onDelete={this.handleNewCustomTags("delete")}
        />
        <div className="rowC">
          <Subheader>Saved Global Custom Tags</Subheader>
        </div>
        <div style={{ display: "flex" }}>
          {dbCustomTags.length ? (
            dbCustomTags.map(customTag => (
              <Chip
                style={styles.customTagChip}
                onDelete={this.removeDbTag(customTag)}
                label={customTag}
              />
            ))
          ) : (
            <span>No Global Custom Tags</span>
          )}
        </div>
        <br />
        <Stack>
          <Button
            label="Close"
            onClick={this.props.openClose.closeAppModal}
            variant="text"
            color="error"
          />
          <Button
            label="Save Changes"
            variant="text"
            onClick={this.saveCustomTags}
            disabled={!changes}
          />
        </Stack>
      </div>
    );
  }
}

export default CustomTagsManager;
