const proTips = [
  {
    text: "Duplicating many promotions? Try using Advanced Duplication!",
    showLink: true,
    url: "https://university.govividly.com/docs/advanced-duplication-guide"
  },
  {
    text: "Scrolling through long lists of promotions? Try using Advanced Search!",
    showLink: true,
    url: "https://university.govividly.com/docs/advanced-search-guide"
  },
  {
    text: "Using Advanced Search only for months and years? Try the Calendar interface!"
  },
  {
    text: "Are you spending tons of time picking promotion start and end dates? \
      Check out our video that shows shortcuts to navigate by year, month and day easily!",
    showLink: true,
    url: "https://university.govividly.com/docs/other-promotion-how-tos"
  },
  {
    text: "Have a very similar calendar between multiple retailers? \
      Try using advanced duplication to copy a whole calendar from one retailer to another!",
    showLink: true,
    url: "https://university.govividly.com/docs/advanced-duplication-guide"
  },
  {
    text: "Is your retailer's trade calendar largely repetitive year over year? \
      Try using advanced duplication to carry forward a whole calendar into another year!",
    showLink: true,
    url: "https://university.govividly.com/docs/advanced-duplication-guide"
  },
  // {
  //   text:
  //     "Need to book a rollover reserve? Check out Promotion Timing Analysis!",
  //   showLink: true,
  //   url: "https://university.govividly.com/docs/how-to-use-promotion-timing-analysis"
  // },
  // {
  //   text:
  //     "Not sure what fields to upload with Revenue and Deductions? Check out the sample files as reminders!",
  //   showLink: true,
  //   url: "https://university.govividly.com/docs/revenue-dollars-guide"
  // },
  // {
  //   text:
  //     "Did you know that Finance Analytics can estimate pass-through spend for more accurate indirect trade rates? \
  //     Check out Vividly University for info!",
  //   showLink: true,
  //   url: "https://university.govividly.com/docs/finance-analytics-guide"
  // },
  {
    text: "Did you know all Vividly dropdowns have a shortcut? \
      Just type the first letter of what you're looking for and the dropdown will skip to the letter you selected!"
  }
];

export { proTips };
