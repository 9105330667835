import React from "react";
import Loyalty from "@mui/icons-material/Loyalty";
import People from "@mui/icons-material/People";
import Business from "@mui/icons-material/Business";
import Equalizer from "@mui/icons-material/Equalizer";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import MeetingRoom from "@mui/icons-material/MeetingRoom";

const menuListArray = [
  {
    name: "Overview",
    icon: <AutoAwesomeMosaicIcon />,
    permission: () => true,
    pathname: "/"
  },
  {
    name: "Business",
    icon: <Business />,
    permission: (db, allPermissions) => {
      return db.permissions !== "None" && allPermissions.has("viewBusiness");
    },
    subItems: [
      {
        name: "Revenue Dollars",
        permissions: db => {
          return db?.meta.tier !== "pine";
        },
        pathname: "/revenue"
      },
      {
        name: "Deductions Spend",
        permissions: db => {
          return db?.meta.tier !== "pine";
        },
        pathname: "/spend"
      },
      {
        name: "Deductions Reconciliation",
        permissions: db => {
          return db?.meta.deductions_reconciliation;
        },
        pathname: "/reconciliation"
      },
      {
        name: "Backup Retrieval",
        permissions: db => {
          return (
            db && !db.meta.deductions_reconciliation && db.meta.backup_retrieval
          );
        },
        pathname: "/backup_retrieval"
      },
      {
        name: "Deductions Processing",
        permissions: db => {
          return db?.meta.cresicor_deductions_scanning;
        },
        pathname: "/scan_and_match"
      },
      {
        name: "Budgeting",
        permissions: db => {
          return db?.meta.cresicor_budgeting;
        },
        pathname: "/budget"
      },
      {
        name: "Region Mapping",
        permissions: db => {
          return db?.meta.tier !== "pine";
        },
        pathname: "/region_mapping"
      }
    ]
  },
  {
    name: "Promotions",
    icon: <Loyalty />,
    permission: () => true,
    subItems: [
      {
        name: "Planning",
        permissions: true,
        pathname: "/planning"
      },
      {
        name: "Execution",
        permissions: true,
        pathname: "/execution"
      },
      {
        name: "Closed",
        permissions: true,
        pathname: "/closed"
      },
      {
        name: "Declined",
        permissions: true,
        pathname: "/declined"
      },
      {
        name: "Cancelled",
        permissions: true,
        pathname: "/cancelled"
      }
    ]
  },
  {
    name: "Manage",
    icon: <People />,
    permission: (db, allPermissions) => {
      return db.permissions !== "None" && allPermissions.has("viewManage");
    },
    subItems: [
      {
        name: "Products",
        permissions: true,
        pathname: "/products"
      },
      {
        name: "Product Groups",
        permissions: true,
        pathname: "/product_groups"
      },
      {
        name: "Pricing",
        permissions: true,
        pathname: "/pricing"
      },
      {
        name: "Lift",
        permissions: true,
        pathname: "/lift"
      },
      {
        name: "Customers",
        permissions: true,
        pathname: "/customers"
      },
      {
        name: "Contacts",
        permissions: true,
        pathname: "/contacts"
      }
    ]
  },
  {
    name: "Commissions",
    icon: <MeetingRoom />,
    permission: (db, allPermissions) =>
      db.permissions !== "None" &&
      allPermissions.has("viewCommissions") &&
      db.meta.broker_commissions,
    subItems: [
      {
        name: "Brokers",
        permissions: db => {
          return db?.meta.broker_commissions;
        },
        pathname: "/brokers"
      },
      {
        name: "Distributor Reports",
        permissions: db => {
          return db?.meta.broker_commissions;
        },
        pathname: "/reports"
      }
    ]
  },
  {
    name: "Insights",
    icon: <Equalizer />,
    permission: (db, allPermissions) => {
      return (
        db?.permissions !== "None" &&
        db?.meta.tier !== "pine" &&
        allPermissions.has("viewInsights")
      );
    },
    subItems: [
      {
        name: "Promotion Analytics",
        permissions: db => {
          return db?.meta.tier !== "pine";
        },
        pathname: "/analytics"
      },
      {
        name: "Finance Analytics",
        permissions: db => {
          return db?.meta.tier === "aspen";
        },
        pathname: "/finance"
      },
      {
        name: "Promotion Timing Analysis",
        permissions: db => {
          return db?.meta.tier !== "pine";
        },
        pathname: "/timing"
      },
      {
        name: "Accruals",
        permissions: db => {
          return db?.meta.tier === "aspen";
        },
        pathname: "/accruals"
      },
      {
        name: "Forecast",
        permissions: db => {
          return db?.meta.forecast && !db?.meta.featureGates?.forecastv2;
        },
        pathname: "/forecast"
      },
      {
        name: "Forecast",
        permissions: db => {
          return db?.meta.forecast && db?.meta.featureGates?.forecastv2;
        },
        pathname: "/forecast2"
      }
    ]
  }
];

export { menuListArray };
