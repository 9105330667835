import { ERPTransactionTypes } from "components/Deductions/constants/ReconciliationTypes";
import { ERPTransactionObject } from "components/Deductions/models";
import { DbContextValues } from "contexts/Db";

// Returns list of deductions that have been paid off by children repayment lines
export function findAttachedRepayments(
  deduction: ERPTransactionObject,
  db: DbContextValues
): Record<string, ERPTransactionObject> {
  const { erpTransactions } = db;

  const attachedRepayments = deduction.attachedRepayments || {};

  return Object.values(attachedRepayments)
    .map(repaymentKey => erpTransactions[repaymentKey])
    .filter(
      repayment => repayment && repayment.type === ERPTransactionTypes.REPAYMENT
    )
    .reduce((allRepayments, repayment) => {
      return {
        ...allRepayments,
        [repayment.key]: repayment
      };
    }, {}); // extra check in case some weird stuff happens
}

// Returns list of deductions attached to a repayment transaction
export function findAttachedDeductions(
  repayment: ERPTransactionObject,
  db: DbContextValues
): Record<string, ERPTransactionObject> {
  const { erpTransactions } = db;

  const repaidDeductions = repayment.repaidDeductions || {};

  return Object.values(repaidDeductions)
    .map(deductionKey => erpTransactions[deductionKey])
    .filter(
      deduction => deduction && deduction.type === ERPTransactionTypes.DEDUCTION
    )
    .reduce((all, deduction) => {
      return {
        ...all,
        [deduction.key]: deduction
      };
    }, {});
}
