import moment from "moment";
import { common } from "@mui/material/colors";

const { black } = common;

const resources = [
  { id: "jan", title: "Jan" },
  { id: "feb", title: "Feb" },
  { id: "mar", title: "Mar" },
  { id: "apr", title: "Apr" },
  { id: "may", title: "May" },
  { id: "jun", title: "Jun" },
  { id: "jul", title: "Jul" },
  { id: "aug", title: "Aug" },
  { id: "sep", title: "Sep" },
  { id: "oct", title: "Oct" },
  { id: "nov", title: "Nov" },
  { id: "dec", title: "Dec" }
];

function createNewEvent(title, startDate, endDate, promKey, color) {
  const result = [];

  const monthDifference =
    new Date(endDate).getMonth() - new Date(startDate).getMonth();

  const startResourceId = moment(startDate).format("MMM").toLowerCase();
  const endResourceId = moment(endDate).format("MMM").toLowerCase();
  let currentMonthDate = moment(startDate);

  const monthAsIntStartDate = moment(startDate).format("MM").toLowerCase();

  const dateAsIntStartDate = moment(startDate).format("DD").toLowerCase();

  const dateAsIntEndDate = moment(endDate).format("DD").toLowerCase();

  if (startResourceId === endResourceId) {
    var year = currentMonthDate.year();
    var thisEndDate = new Date(year, 12 - 1, Number(dateAsIntEndDate) + 1);
    result.push({
      resourceId: startResourceId,
      title,
      start: `${year}-12-${dateAsIntStartDate}`,
      end: moment(thisEndDate).format("YYYY-MM-DD"),
      editable: false,
      promKey,
      color,
      textColor: black
    });
  } else {
    // First month
    var year = currentMonthDate.year();
    var thisEndDate = new Date(
      year,
      12 - 1,
      new Date(2019, Number(monthAsIntStartDate), 0).getDate() + 1
    );
    result.push({
      resourceId: startResourceId,
      title,
      start: `${year}-12-${dateAsIntStartDate}`,
      end: moment(thisEndDate).format("YYYY-MM-DD"),
      editable: false,
      promKey,
      color,
      textColor: black
    });

    const currentIndexStartDateInResource = resources.findIndex(
      _item => _item.id === startResourceId
    );

    for (let index = 1; index < monthDifference; index++) {
      const resourceId = resources[currentIndexStartDateInResource + index].id;
      currentMonthDate = currentMonthDate.add(1, "M");
      year = currentMonthDate.year();
      var thisEndDate = new Date(
        year,
        12 - 1,
        new Date(2019, Number(monthAsIntStartDate) + index, 0).getDate() + 1
      );
      if (currentMonthDate.year() == year) {
        result.push({
          resourceId,
          title,
          start: `${year}-12-01`,
          end: moment(thisEndDate).format("YYYY-MM-DD"),
          editable: false,
          promKey,
          color,
          textColor: black
        });
      }
    }

    // Last month
    currentMonthDate = currentMonthDate.add(1, "M");
    year = currentMonthDate.year();
    var thisEndDate = new Date(year, 12 - 1, Number(dateAsIntEndDate) + 1);
    result.push({
      resourceId: endResourceId,
      title,
      start: `${year}-12-01`,
      end: moment(thisEndDate).format("YYYY-MM-DD"),
      editable: false,
      promKey,
      color,
      textColor: black
    });
  }
  return result;
}

export { createNewEvent };
