import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { displayMoney } from "helpers/DataProcessing";

import { Divider } from "pui-react-dividers";
import { ListItemText, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { getLineProducts } from "components/Planning/LineList";
import { grey } from "@mui/material/colors";
import PromotionOverviewTable from "./PromotionOverviewTable";
import PromProfile from "../Planning/PromProfile";

const grey500 = grey["500"];

function round(n) {
  return Math.round(n * 100) / 100;
}

// ------------------------ TABLE -------------------------//

// STYLE
const styles = {
  container: {
    width: "100%"
  },
  header: {
    marginTop: "10px",
    fontFamily: "Ubuntu"
  },
  subheader: {
    fontSize: 20,
    fontWeight: 500,
    fontFamily: "Ubuntu"
  }
};

// Table head and columns inside it
const getColHeaderJSX = columns => {
  const columnHeadersJSX = [];
  for (let i = 0; i < columns.length; i++) {
    const column = columns[i];
    columnHeadersJSX.push(
      <TableCell
        style={{
          color: grey500,
          cursor: "pointer"
        }}
        key={i}>
        <b>{column.name}</b>
      </TableCell>
    );
  }
  return columnHeadersJSX;
};

// Table Body
const getLinesJSX = (simLines, actMoneyList, metadata, openClose, db) => {
  const lines = [];
  let linesJSX = [];
  if (simLines.length > 0) {
    // compute deduction totals for each line
    const actSpendByLine = {};
    for (const spendItem of actMoneyList) {
      if (spendItem.lineKey in actSpendByLine) {
        actSpendByLine[spendItem.lineKey] += spendItem.spend;
      } else {
        actSpendByLine[spendItem.lineKey] = spendItem.spend;
      }
    }

    for (let i = 0; i < simLines.length; i++) {
      const l = { ...simLines[i] };
      l.productField =
        l.productGroup && l.productGroup != "custom"
          ? metadata.product_groups[l.productGroup].name
          : getLineProducts(l, db.products);
      l.typeField = metadata.fundTypes?.[l.type]?.name;
      l.dateField = new Date(l.startDate).getTime();
      const spendRate = l.spendRate || 0;
      l.actualSales = actSpendByLine[l.lineKey]
        ? actSpendByLine[l.lineKey] / spendRate
        : 0;

      lines.push(l);
    }

    linesJSX = lines.map((line, index) => {
      return (
        <TableRow
          key={line.lineKey}
          onClick={() => {
            openClose.showRightDrawer(
              <PromProfile
                readOnly
                promKey={line.promKey}
                openClose={openClose}
                db={db}
                fromSimilarPromos
              />
            );
          }}
          hover>
          <TableCell>
            <div>{line.productField}</div>
          </TableCell>
          <TableCell>
            <div>{line.typeField}</div>
          </TableCell>
          <TableCell>
            <div>{`${line.startDate} - ${line.endDate}`}</div>
          </TableCell>
          <TableCell>
            <div>{Math.round(line.totalExpSales).toLocaleString("en-US")}</div>
          </TableCell>
          <TableCell>
            <div>{parseInt(line.actualSales).toLocaleString("en-US")}</div>
          </TableCell>
          <TableCell>
            <div>{displayMoney(round(line.spendRate))}</div>
          </TableCell>
        </TableRow>
      );
    });
  }

  return linesJSX;
};

// Function to sort similar promotions based on year that is closest to the selected year
const sortByYear = (promoA, promoB) => {
  const promoAStartDate = new Date(promoA.startDate);
  const promoBStartDate = new Date(promoB.startDate);
  return -(promoAStartDate.getFullYear() - promoBStartDate.getFullYear());
};

// Function to sort similar promotions based on month that is closest to the selected month
const sortLinesByMonth = (targetMonth, targetYear, simLines) => {
  let sortedList = [];
  for (let i = 0; i < 12; i++) {
    sortedList.push([]);
  }

  // Filtering out promotions more than two years old
  for (let i = 0; i < simLines.length; i++) {
    const date = new Date(simLines[i].startDate);
    if (Math.abs(date.getFullYear() - targetYear) <= 2) {
      const month = date.getMonth();
      const index = Math.abs(targetMonth - 1 - month);
      sortedList[index].push(simLines[i]);
    }
  }

  // Sorting promotions with same month based on year
  sortedList = sortedList.flatMap(subList => {
    return subList.sort(sortByYear);
  });

  return sortedList;
};

// This function filters the promotion with same customer name
// and also removes cancelled and declined promotions
const getSimilarCustomerAndValidPromotions = (
  currentPromotion,
  allPromotions
) => {
  const filteredPromotions = [];
  Object.keys(allPromotions).forEach(promKey => {
    if (
      currentPromotion &&
      currentPromotion.customer.name == allPromotions[promKey].customerName &&
      // filtering out cancelled and declined promotions
      allPromotions[promKey].status !== 7 &&
      allPromotions[promKey].status !== 6 &&
      !(currentPromotion.promKey && currentPromotion.promKey == promKey)
    ) {
      filteredPromotions.push(allPromotions[promKey]);
    }
  });

  return filteredPromotions;
};

// This function filters based on type of promotions whether it is MCB or Scan or COUPON or advertising etc.
const getSimilarTypePromotions = (
  currentTypeSelected,
  currentProductGroup,
  filteredPromotions
) => {
  const sameTypePromotion = [];
  if (currentTypeSelected !== "") {
    filteredPromotions.map(promotion => {
      Object.keys(promotion.lines).forEach(key => {
        if (
          promotion.lines[key].type == currentTypeSelected &&
          promotion.lines[key].totalExpSales &&
          promotion.lines[key].totalExpSales !== 0
        ) {
          const similarLine = { ...promotion.lines[key] };
          similarLine.lineKey = key;
          similarLine.promKey = promotion.promKey;
          if (
            !currentProductGroup ||
            (currentProductGroup &&
              currentProductGroup === promotion.lines[key].productGroup)
          ) {
            sameTypePromotion.push(similarLine);
          }
        }
      });
    });
  }
  return sameTypePromotion;
};

export default function LikePromotions({ db, openClose }) {
  const [likePromotionsList, setLikePromotionsList] = useState({});
  const [filteredPromotions, setFilteredPromotions] = useState([]);
  const { lines, prom, selectedLineIndex } = useSelector(
    state => state.addPromotion
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const newFilteredPromotions = getSimilarCustomerAndValidPromotions(
      prom,
      db.promotions
    );
    setFilteredPromotions([...newFilteredPromotions]);
  }, [prom.customer.name]);

  useEffect(() => {
    if (lines.length > 0 && selectedLineIndex > -1) {
      const targetMonth = prom.month;
      const targetYear = prom.year;
      const currentLine = lines[lines.length - 1 - selectedLineIndex];
      const similarPromotionList = getSimilarTypePromotions(
        prom[currentLine].type,
        prom[currentLine].productGroup,
        filteredPromotions
      );
      const sortedSimilarPromotionList = sortLinesByMonth(
        targetMonth,
        targetYear,
        similarPromotionList
      );
      setLikePromotionsList([...sortedSimilarPromotionList]);
    } else {
      setLikePromotionsList([]);
    }
  }, [prom, lines.length, selectedLineIndex]);

  const actMoneyList = [];
  filteredPromotions.forEach(promo => {
    const spends = db.promotion_spend[promo.promKey];
    for (const spendKey in spends) {
      if (spends[spendKey] && db.actMoney[spendKey]) {
        actMoneyList.push(db.actMoney[spendKey]);
      }
    }
  });

  const columns = [
    { name: "Product", field: "productField" },
    { name: "Type", field: "typeField" },
    { name: "Duration", field: "dateField" },
    { name: "Total Expected Sales (Units)", field: "totalExpSales" },
    { name: "Actual Sales (Units)", field: "actualSales" },
    { name: "Spend Rate ($)", field: "spendRate" }
  ];

  const columnHeadersJSX = getColHeaderJSX(columns);

  const linesJSX = getLinesJSX(
    likePromotionsList,
    actMoneyList,
    db.meta,
    openClose,
    db
  );

  return (
    <div className="div-container">
      <Typography style={styles.header} variant="h4">
        Summary
      </Typography>
      <Divider />
      <div style={{ height: "400px", overflowY: "scroll" }}>
        <PromotionOverviewTable db={db} />
        <ListItemText
          disableTypography
          style={styles.subheader}
          primary="Similar Promotion Lines"
        />
        <Paper variant="outlined">
          <TableContainer
            style={{
              maxHeight: 360,
              cursor: "pointer"
            }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>{columnHeadersJSX}</TableRow>
              </TableHead>
              <TableBody>{linesJSX}</TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
}
