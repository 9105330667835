import React from "react";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { RootState } from "store";
import Card from "ui-library/Card";

import FilePreview from "components/WebsiteElements/FilePreview";
import { isFilePDFType } from "./redux/helpers";

const useStyles = makeStyles(() => ({
  card: {
    display: "flex",
    flexDirection: "column",
    margin: "1vh 0vh 0vh",
    height: "100vh",
    overflow: "auto"
  }
}));

export default function BackupFilePreview() {
  const classes = useStyles();

  const { newFiles, viewedFileIdx } = useSelector(
    (state: RootState) => state.uploadBackup
  );

  const fileName = newFiles[viewedFileIdx]?.name ?? null;

  return (
    <Card className={classes.card}>
      {fileName && isFilePDFType(fileName) ? (
        <FilePreview file={newFiles[viewedFileIdx]} />
      ) : (
        <>
          {fileName ? (
            <div className="centering">
              The file preview only supports pdf file types.
            </div>
          ) : (
            <div className="centering">
              Please first select a file to preview.
            </div>
          )}
        </>
      )}
    </Card>
  );
}
