import { ERPTransactionTypes, invoiceTypes } from "./ReconciliationTypes";

// ALL OF THESE WILL MAP AN OBJECT TYPE TO THE SIGN THAT IT'S AMOUNT IS DISPLAYED IN
// 1 = POSITIVE, -1 = NEGATIVE

export const TransactionDisplaySigns: Record<ERPTransactionTypes, number> = {
  [ERPTransactionTypes.DEDUCTION]: 1,
  [ERPTransactionTypes.REPAYMENT]: -1,
  [ERPTransactionTypes.BILLPAY]: 1,
  [ERPTransactionTypes.UNKNOWN]: 1 // basically deduction because of MVP
};

export const InvoiceDisplaySigns: Record<invoiceTypes, number> = {
  [invoiceTypes.BILLPAY]: 1,
  [invoiceTypes.TRADE_DEDUCTION]: 1,
  [invoiceTypes.NON_TRADE_DEDUCTION]: 1,
  [invoiceTypes.REPAYMENT]: -1
};

export const INVOICE_LINE_DISPLAY_SIGN = 1;

export const RESOLUTION_LINE_DISPLAY_SIGN = 1;

export const REPAYMENT_LINE_DISPLAY_SIGN = -1;

export interface ReconciliationDisplayAmounts {
  clearedAmount: number;

  totalWriteOffAmount: number;
  creditedWriteOffAmount: number;
  openWriteOffAmount: number;

  totalDisputeAmount: number;
  repaidDisputeAmount: number;
  openDisputeAmount: number;

  resolvedAmount: number; // cleared + totalWriteOff + repaid
  openAmount: number; // pending + total dispute - repaid = pending + open dispute
}
