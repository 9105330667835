import { Divider } from "pui-react-dividers";
import React from "react";

import Typography from "@mui/material/Typography";
import { Toolbar, ToolbarSeparator, ToolbarTitle } from "ui-library/Toolbar";
import ToolbarGroup from "ui-library/ToolbarGroup";
import AdvancedSearchIcon from "@mui/icons-material/ZoomIn";
import RefreshIcon from "@mui/icons-material/Refresh";
import DownloadIcon from "@mui/icons-material/GetApp";
import SaveIcon from "@mui/icons-material/Save";
import Dialog from "ui-library/Dialog";
import EditIcon from "@mui/icons-material/Edit";
import Button from "ui-library/Button";
import IconButton from "ui-library/IconButton";
import Checkbox from "ui-library/Checkbox";
import IconMenu from "ui-library/IconMenu";
import MenuItem from "ui-library/MenuItem";
import SearchIcon from "@mui/icons-material/Search";
import SelectField from "ui-library/Select";
import SettingsIcon from "@mui/icons-material/Settings";
import Subheader from "ui-library/Subheader";
import TextField from "ui-library/TextField";
import Card from "ui-library/Card";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import OpenWithIcon from "@mui/icons-material/OpenWith";

import { accrualTable } from "helpers/DataProcessing";
import { exportAnalytics } from "helpers/Export";
import {
  getUserDataFirebase,
  getJSXListsFirebase,
  updateMetaFirebase,
  firebase
} from "helpers/Firebase";
import { getTimesJSX } from "helpers/Time";
import { promSatisfiesSearch } from "helpers/satisfiesSearch";
import { grey, common, deepOrange, green } from "@mui/material/colors";
import ToggleButton from "@mui/material/ToggleButton";
import StyledToggleButtonGroup from "ui-library/ToggleButtonGroupStyled";
import { Stack } from "@mui/material";
import AccrualTable from "../graphs/AccrualTable";
import SearchPromotions from "../Planning/SearchPromotions";

const grey200 = grey["200"];
const grey400 = grey["400"];
const grey700 = grey["700"];
const deepOrange400 = deepOrange["400"];
const green400 = green["400"];
const { black } = common;

const styles = {
  notifs: {
    width: "500px"
  },
  input: {
    margin: 5
  },
  tabs: {
    backgroundColor: grey700
  },
  tabsBox: {
    borderColor: grey700,
    borderWidth: 2,
    borderStyle: "solid"
  },
  divider: {
    margin: "30px auto",
    width: "50%"
  },
  checkbox: {
    marginLeft: 16,
    minWidth: 232
  },
  textfield: {
    "white-space": "pre-line"
  },
  modalTitle: {
    borderStyle: "solid",
    borderWidth: 0,
    borderBottomWidth: 2,
    borderBottomColor: grey200
  },
  modalActions: {
    borderStyle: "solid",
    borderWidth: 0,
    borderTopWidth: 2,
    borderTopColor: grey200
  },
  dialogRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 0,
    paddingBottom: 10,
    zIndex: 1200
  },
  dialogContent: {
    position: "relative",
    minWidth: "90vw",
    maxWidth: "90vw",
    transform: ""
  },
  dialogBody: {
    paddingBottom: 0,
    minHeight: 650
  },
  dialogOverlay: {
    zIndex: 1000
  }
};

class Accruals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSearch: false,
      searchQuery: "",
      searchProm: {},
      table: "Customer P&L",
      pivot: "customer",
      doneLoading: false,
      modalOpen: false,
      diffCutoff: -10000,
      percentCutoff: -2,
      periodMonthStart: 1,
      periodYearStart: new Date().getFullYear(),
      accrualMonth: 1,
      quarter: false,
      byDate: false,
      mode: "tradeRate",
      accrualComments: "",
      granularity: "promKey"
    };
  }

  saveToDatabase = () => {
    const savedKeys = [
      "showClosed",
      "table",
      "pivot",
      "diffCutoff",
      "percentCutoff",
      "category",
      "periodMonthStart",
      "periodYearStart",
      "accrualMonth",
      "quarter",
      "byDate",
      "mode",
      "granularity"
    ];
    const payload = {};

    for (const key in savedKeys) {
      if (this.state.hasOwnProperty(savedKeys[key])) {
        payload[savedKeys[key]] = this.state[savedKeys[key]];
      }
    }

    const { uid } = firebase.auth().currentUser;
    firebase.database().ref(`users/${uid}/insights/accruals`).set(payload);
  };

  handleSearchButton = () => {
    this.setState({
      showSearch: true
    });
  };

  handleSearch = event => {
    this.setState({
      searchQuery: event.target.value,
      searchProm: {}
    });
  };

  // Used in advanced search.
  setSearchProm = searchProm => {
    this.setState({ searchProm, searchQuery: "" });
  };

  moneyObjSatisfiesSearch = (moneyObj, type) => {
    let satisfies = true;
    let { searchQuery } = this.state;
    searchQuery = searchQuery.toLowerCase();
    // if advanced searching
    if (searchQuery === "") {
      // TODO use spendSatisfiesSearch
      if (type == "spend" || type == "lines") {
        // first check string fields
        for (var field in this.state.searchProm) {
          if (field == "productGroup") continue;
          if (field in moneyObj && moneyObj[field].constructor !== String)
            continue;
          // Need to change this line if we use non-string values in the future.
          satisfies =
            satisfies &&
            field in moneyObj &&
            moneyObj[field].indexOf(this.state.searchProm[field]) != -1;
        }
        if (type == "lines") {
          // for lines only, check a couple non-string fields
          // TODO make a lineSatisfiesSearch
          var productGroups = this.props.db.meta.product_groups;
          if (
            moneyObj.productGroup == "custom" ||
            !(moneyObj.productGroup in productGroups)
          ) {
            moneyObj.productGroup = "Custom";
          } else {
            moneyObj.productGroup = productGroups[moneyObj.productGroup].name;
          }

          const checkFields = ["productGroup", "type"];
          for (let i = 0; i < checkFields.length; i++) {
            const checkField = checkFields[i];
            if (
              checkField in this.state.searchProm &&
              moneyObj[checkField] != this.state.searchProm[checkField]
            ) {
              satisfies = false;
              break;
            }
          }

          if (
            "distributor" in this.state.searchProm &&
            !moneyObj.distributor.includes(this.state.searchProm.distributor)
          ) {
            satisfies = false;
          }
        }
        return satisfies;
      }
      var productGroups = this.props.db.meta.product_groups;
      const linesList = Object.values(moneyObj.lines);
      moneyObj.productGroup = linesList.map(line =>
        line.productGroup == "custom"
          ? "Custom"
          : productGroups[line.productGroup].name
      );
      return promSatisfiesSearch(moneyObj, this.state.searchProm);
    }
    for (var field of ["name", "promKey"]) {
      if (moneyObj[field].toLowerCase().indexOf(searchQuery) != -1) {
        return true;
      }
    }
    return false;
  };

  filterMoney = (moneyObj, type) => {
    const filteredMoney = [];
    const startDate = new Date(
      this.state.periodYearStart,
      this.state.periodMonthStart - 1,
      1
    );
    const endDate = new Date(
      this.state.periodYearStart,
      this.state.periodMonthStart + 11,
      1
    );
    if (moneyObj) {
      for (const key in moneyObj) {
        const s = { ...moneyObj[key] };
        // s["promKey"] = key; // TODO HACK
        if (type == "spend") {
          var date = new Date(s.date);
          if (startDate <= date && date < endDate) {
            filteredMoney.push(s);
          }
        } else {
          const status = this.props.db.meta.statuses[s.status];
          var date = new Date(s.year, s.month - 1, 1);
          if (
            ["Approved", "Running", "Completed"].includes(status) &&
            ((this.state.showClosed && s.closed) ||
              (!this.state.showClosed && !s.closed)) &&
            this.moneyObjSatisfiesSearch(s, type)
          ) {
            filteredMoney.push(s);
          }
        }
      }
    }
    return filteredMoney;
  };

  saveComments = () => {
    const meta = { ...this.props.db.meta };
    meta.accrual_comments = this.state.accrualComments;

    updateMetaFirebase(meta);
    this.setState({ editComments: false });
  };

  updateData = (table, sortedData) => {
    const state = {};
    state[table] = sortedData;
    this.setState(state);
  };

  selectGranularity = (event, granularity) => {
    this.setState({ granularity });
  };

  getMonthYear = () => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    const monthyears = [];
    const startDate = new Date(
      this.state.periodYearStart,
      this.state.periodMonthStart - 1,
      1
    );
    for (let i = 1; i <= 12; i++) {
      const monthName = months[startDate.getMonth() % 12];
      const year = startDate.getFullYear();
      monthyears.push(`${monthName} ${year}`);
      startDate.setMonth(startDate.getMonth() + 1);
    }

    return monthyears;
  };

  componentDidMount() {
    getJSXListsFirebase(this.props.db, allJSX => {
      const timesJSX = getTimesJSX(this.props.db.meta, true);
      this.setState({
        customersJSX: allJSX.customersJSX,
        productsJSX: allJSX.productsJSX,
        accountsJSX: allJSX.accountsJSX,
        accrualComments: this.props.db.meta.accrual_comments,
        pnlComments: this.props.db.meta.pnl_comments,
        monthsJSX: timesJSX[0],
        yearsJSX: timesJSX[1],
        doneLoading: true
      });
    });

    getUserDataFirebase(accruals => {
      if (accruals) {
        this.setState(accruals);
      }
    }, "insights/accruals");
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    getJSXListsFirebase(nextProps.db, allJSX => {
      this.setState({
        customersJSX: allJSX.customersJSX,
        productsJSX: allJSX.productsJSX,
        accountsJSX: allJSX.accountsJSX,
        accrualComments: nextProps.db.meta.accrual_comments,
        pnlComments: nextProps.db.meta.pnl_comments
      });
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.sideBar != this.props.sideBar) {
      return false;
    }
    return true;
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    // if granularity is promKey, only make the accruals table aware of
    // promotions that are in the correct state (open vs. closed).
    const promList = this.filterMoney(this.props.db.promotions, "proms");
    // linesList is a list of promotions if granularity is 'promKey'.
    // otherwise, it's a list of lines.
    const linesList =
      this.state.granularity == "promKey"
        ? promList
        : this.filterMoney(this.props.db.allLines, "lines");
    const actMoney = this.filterMoney(this.props.db.actMoney, "spend");
    const monthyears = this.getMonthYear();

    let accrualData = [];
    if (Object.keys(this.props.db.allLines).length > 0) {
      // granularity is either 'promkey', 'linekey', or 'account'
      accrualData = accrualTable(
        this.state.granularity,
        actMoney,
        linesList,
        this.props.db.promotions,
        this.props.db.allLines,
        monthyears,
        this.state.accrualMonth - 1,
        this.props.db.customers,
        this.props.db.meta,
        this.state.searchProm,
        this.props.db.accounts
      );
    }
    const comments = this.state.accrualComments;
    let rowOrder = [
      "Name",
      "ID",
      "Month/Year",
      "Days until auto-close",
      "Forecasted Spend",
      "First Receiver"
    ];
    if (this.state.granularity == "lineKey") {
      rowOrder.push("Account");
    }
    for (const monthyear of monthyears) {
      rowOrder.push(monthyear);
    }
    rowOrder = rowOrder.concat([
      "Differential to Accrual",
      "Proposed Reversal"
    ]);

    const showBy = this.state.granularity == "lineKey" ? "lines" : "promotions";

    return (
      <div>
        <Toolbar>
          <ToolbarGroup>
            <ToolbarTitle
              text="Accruals"
              style={{ color: black, width: 150 }}
            />
            <ToolbarSeparator />
            <Checkbox
              checkedIcon={<LockIcon sx={{ color: "warning.main" }} />}
              icon={<LockOpenIcon sx={{ color: "success.main" }} />}
              label={
                this.state.showClosed
                  ? `Showing closed ${showBy}`
                  : `Showing open ${showBy}`
              }
              containerStyle={styles.checkbox}
              onChange={event => {
                this.setState({ showClosed: event.target.checked });
              }}
              checked={this.state.showClosed}
            />
          </ToolbarGroup>
          <ToolbarGroup>
            <StyledToggleButtonGroup
              value={this.state.granularity}
              exclusive
              onChange={this.selectGranularity}>
              <ToggleButton value="promKey">By Promotion</ToggleButton>
              <ToggleButton value="lineKey">By Line</ToggleButton>
            </StyledToggleButtonGroup>
          </ToolbarGroup>
          <ToolbarGroup>
            {this.state.showSearch ? (
              <IconButton
                onClick={() => {
                  this.props.showRightDrawer(
                    <SearchPromotions
                      setSearchProm={this.setSearchProm}
                      searchProm={this.state.searchProm}
                      db={this.props.db}
                      openClose={this.props.openClose}
                      showName
                      showPromKey
                      showDistributor
                      showCustomerName
                      showProductGroup
                      showType
                    />
                  );
                }}
                tooltip="Advanced Search"
                style={styles.iconButton}
                size="large">
                <AdvancedSearchIcon />
              </IconButton>
            ) : (
              <IconButton
                onClick={this.handleSearchButton}
                tooltip="Search"
                style={styles.iconButton}
                size="large">
                <SearchIcon />
              </IconButton>
            )}
            {this.state.showSearch && (
              <TextField
                variant="standard"
                placeholder="Search..."
                onChange={event => {
                  this.handleSearch(event, event.target.value);
                }}
                value={this.state.searchQuery}
              />
            )}
          </ToolbarGroup>
          <ToolbarGroup>
            <IconMenu
              iconButtonElement={
                <IconButton tooltip="Settings" size="large">
                  <SettingsIcon />
                </IconButton>
              }
              anchorOrigin={{ horizontal: "right", vertical: "top" }}
              targetOrigin={{ horizontal: "right", vertical: "top" }}
              onChange={this.props.handleAdditionalDataDisplay}>
              <MenuItem
                children={<Typography variant="subtitle1">Export</Typography>}
                disabled
              />
              <MenuItem
                value={0}
                children="Download Accruals"
                leftIcon={<DownloadIcon />}
                onClick={() => {
                  exportAnalytics(
                    firebase.auth().currentUser,
                    accrualData,
                    "Accruals",
                    rowOrder,
                    "export_accruals",
                    this.state.granularity
                  );
                  this.props.openClose.setAppModal(
                    "Export In Progress",
                    <div className="centering">
                      Your export is being prepared and will be emailed to you
                      shortly.
                    </div>,

                    <div className="centering">
                      <Button
                        label="Ok"
                        onClick={() => {
                          this.props.openClose.closeAppModal();
                        }}
                      />
                    </div>
                  );
                }}
              />
              <MenuItem
                value={1}
                children="Set as Default View"
                leftIcon={<SaveIcon />}
                onClick={this.saveToDatabase}
              />
            </IconMenu>
          </ToolbarGroup>
        </Toolbar>
        <div
          style={{
            padding: 16,
            overflow: "auto"
          }}>
          <Stack>
            <Subheader>Filter Inputs</Subheader>
            <div>
              <IconButton
                color="tonal"
                isBackgroundColor
                tooltip="Clear Filters"
                onClick={this.handleResetFields}
                size="large">
                <RefreshIcon />
              </IconButton>
            </div>
          </Stack>
          <div style={{ fontFamily: "Oxygen" }}>
            Toggle filters below to change the month/year for the start of the
            accrual period (the period lasts one year from the start) and the
            accrual month. Promotions shown in accrual table are either
            Approved, Running, or Completed.
          </div>
          <br />
          <div className="row">
            <div className="col-lg-8">
              <Card title="Select Starting Month/Year for Accrual Period">
                <div className="rowC">
                  <SelectField
                    floatingLabelText="Month"
                    value={this.state.periodMonthStart}
                    onChange={(event, index, value) => {
                      this.setState({
                        periodMonthStart: value
                      });
                    }}
                    fullWidth
                    style={styles.input}>
                    {this.state.monthsJSX}
                  </SelectField>
                  <SelectField
                    floatingLabelText="Year"
                    value={this.state.periodYearStart}
                    onChange={(event, index, value) => {
                      this.setState({
                        periodYearStart: value
                      });
                    }}
                    fullWidth
                    style={styles.input}>
                    {this.state.yearsJSX}
                  </SelectField>
                </div>
              </Card>
            </div>
            <div className="col-lg-4">
              <Card title="Select Accrual Month">
                <SelectField
                  floatingLabelText="Accrual Month"
                  value={this.state.accrualMonth}
                  onChange={(event, index, value) => {
                    this.setState({ accrualMonth: value });
                  }}
                  fullWidth
                  style={styles.input}>
                  {monthyears.map((item, index) => (
                    <MenuItem
                      value={index + 1}
                      children={item}
                      key={index + 1}
                    />
                  ))}
                </SelectField>
              </Card>
            </div>
          </div>
          <br />
          <Button
            label={<OpenWithIcon />}
            backgroundColor={grey200}
            hoverColor={grey400}
            onClick={() => this.setState({ modalOpen: true })}
          />
          <AccrualTable
            style={{ height: 500, overflow: "auto", fontFamily: "PT sans" }}
            data={accrualData}
            db={this.props.db}
            openClose={this.props.openClose}
            updateData={this.updateData}
            granularity={this.state.granularity}
            monthyears={monthyears}
          />
          <br />

          <div style={{ fontFamily: "PT sans" }}>
            <Divider style={styles.divider} size="large" />
            <Stack>
              <Subheader style={styles.subheader}>Comments</Subheader>
              {this.state.editComments ? (
                <Button label="Save" onClick={this.saveComments} primary />
              ) : (
                <Button
                  onClick={() => {
                    this.setState({ editComments: true });
                  }}
                  sx={{
                    "& .MuiButton-startIcon": {
                      margin: 0
                    }
                  }}
                  icon={<EditIcon />}
                />
              )}
            </Stack>
            {this.state.editComments ? (
              <TextField
                style={styles.textfield}
                value={comments}
                onChange={event => {
                  this.setState({ accrualComments: event.target.value });
                }}
                floatingLabelText="Comments"
                fullWidth
                multiLine
                rows={5}
              />
            ) : (
              <div style={styles.textfield}>{comments || "None"}</div>
            )}
            <br />
            <br />
          </div>
        </div>
        <Dialog
          dialogTitle="Accrual Table"
          open={this.state.modalOpen}
          onClose={() => this.setState({ modalOpen: false })}
          style={styles.dialogRoot}
          overlayStyle={styles.overlayStyle}
          fullWidth
          maxWidth="lg">
          <div
            style={{
              maxHeight: 650,
              display: "flex",
              flexDirection: "column"
            }}>
            <AccrualTable
              style={{ overflow: "auto", fontFamily: "PT sans" }}
              data={accrualData}
              db={this.props.db}
              openClose={this.props.openClose}
              updateData={this.updateData}
              granularity={this.state.granularity}
              monthyears={monthyears}
            />
          </div>
        </Dialog>
      </div>
    );
  }
}

export default Accruals;
