import axios from "axios";
import { cloudRunURL, firebase, cloudRunFunctionURL } from "./Firebase";

export const exportPromotions = async (companyid, openClose, promKeys) => {
  const { email } = firebase.auth().currentUser;
  try {
    await axios.post(`${cloudRunURL}/api/export_promotions`, {
      data: {
        email,
        companyid,
        promKeys
      }
    });
  } catch (e) {
    console.error(e);
    openClose.showSnack("Something went wrong while exporting promotions.");
  }
};

const exportFiles = (
  user,
  companyid,
  exportType,
  archiveKey = "cresicor",
  promKeyList = null,
  pricingDate = null,
  enqueueSnackbar = null
) => {
  if (enqueueSnackbar) {
    enqueueSnackbar("Report being prepared and will be sent to email", {
      variant: "default",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center"
      }
    });
  }
  axios
    .post(`${cloudRunFunctionURL}/api/export`, {
      data: {
        companyid,
        email: user.email,
        exportType,
        archiveKey,
        userID: user.uid,
        userid: user.uid,
        promKeyList,
        pricingDate
      }
    })
    .catch(error => {
      console.error(error);
      if (enqueueSnackbar) {
        enqueueSnackbar(
          "Report has run into an error. Please try again. If error persists, please reach out to Vividly.",
          {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center"
            }
          }
        );
      }
    });
};

const exportAnalytics = (
  user,
  dataList,
  category,
  rowOrder,
  url,
  pivot = null
) => {
  axios
    .post(`${cloudRunFunctionURL}/api/${url}`, {
      data: {
        email: user.email,
        userid: user.uid,
        data: JSON.stringify(dataList),
        pivot,
        rowOrder: JSON.stringify(rowOrder),
        category
      }
    })
    .catch(error => console.error(error));
};

const exportBroker = (user, data) => {
  axios
    .post(`${cloudRunFunctionURL}/api/export_broker`, {
      data: {
        email: user.email,
        data: JSON.stringify(data)
      }
    })
    .catch(error => console.error(error));
};

const exportForecast = async (companyid, openClose) => {
  const { email, uid: userid } = firebase.auth().currentUser;
  try {
    await axios.post(`${cloudRunURL}/api/export_forecast`, {
      data: {
        email,
        companyid,
        userid
      }
    });
  } catch (e) {
    console.error(e);
    openClose.showSnack("Something went wrong while exporting the forecast.");
  }
};

export { exportFiles, exportAnalytics, exportBroker, exportForecast };
