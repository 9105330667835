import React from "react";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { useDb } from "contexts/Db";
import Button from "ui-library/Button";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import PublishIcon from "@mui/icons-material/Publish";
import { Stack } from "@mui/material";
import { uploadSteps } from "./redux/definitions";
import { reset, changeUploadStep } from "./redux/actionCreators";
import ErrorMessage from "./ErrorMessage";

interface UploadBackupStepButtonsProps {
  backToBoard: Function;
}

export default function UploadBackupStepButtons({
  backToBoard
}: UploadBackupStepButtonsProps) {
  const db = useDb();
  const dispatch = useDispatch();

  const { currentUploadStep, showErrors, showUnknownError, fakeInvoiceMode } =
    useSelector((state: RootState) => state.uploadBackup, shallowEqual);

  function getCloseButton() {
    switch (currentUploadStep) {
      case uploadSteps.UPLOADING: {
        return <div />;
      }
      default: {
        return (
          <Button
            label="Close"
            color="error"
            variant="outlined"
            onClick={() => {
              dispatch(reset());
              backToBoard();
            }}
            icon={<CloseIcon />}
          />
        );
      }
    }
  }

  function getBackButton() {
    switch (currentUploadStep) {
      case uploadSteps.ADD_FILES_AND_ASSIGN_PAGES: {
        return (
          <Button
            label="Back"
            color="tonal"
            icon={<KeyboardArrowLeftIcon />}
            disabled
          />
        );
      }
      case uploadSteps.ADD_METADATA: {
        return (
          <Button
            label="Back"
            color="tonal"
            icon={<KeyboardArrowLeftIcon />}
            onClick={() => {
              if (fakeInvoiceMode) {
                dispatch(reset());
              }
              dispatch(changeUploadStep(false, db));
            }}
          />
        );
      }
      case uploadSteps.UPLOADING: {
        return <div />;
      }
      case uploadSteps.DONE: {
        return <div />;
      }
      default: {
        return (
          <Button
            label="Back"
            color="tonal"
            icon={<KeyboardArrowLeftIcon />}
            onClick={() => dispatch(changeUploadStep(false, db))}
          />
        );
      }
    }
  }

  function getForwardButton() {
    switch (currentUploadStep) {
      case uploadSteps.ADD_METADATA: {
        return (
          <Button
            label="Submit"
            icon={<KeyboardArrowRightIcon />}
            onClick={() => {
              dispatch(changeUploadStep(true, db));
            }}
          />
        );
      }
      case uploadSteps.UPLOADING: {
        return <div />;
      }
      case uploadSteps.DONE: {
        return (
          <Button
            label="Upload More"
            icon={<PublishIcon />}
            onClick={() => dispatch(reset())}
          />
        );
      }
      default: {
        return (
          <Button
            label="Next"
            icon={<KeyboardArrowRightIcon />}
            onClick={() => dispatch(changeUploadStep(true, db))}
          />
        );
      }
    }
  }

  return (
    <div className="rowRL" style={{ width: "100%" }}>
      <div className="rowC">{getCloseButton()}</div>
      {showErrors && <ErrorMessage forceUnknownError={false} />}
      {showUnknownError && <ErrorMessage forceUnknownError />}
      <Stack>
        {getBackButton()}
        {getForwardButton()}
      </Stack>
    </div>
  );
}
