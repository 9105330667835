/* eslint-disable react/jsx-props-no-spreading */
import React, { ClipboardEventHandler } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "ui-library/TextField";
import Chip from "@mui/material/Chip";
import { AutocompleteProps } from "@mui/material";
import MenuItem from "ui-library/MenuItem";

type ChipInputProps<T> = {
  onAdd: (value: AutocompleteProps<T, true, false, true>["value"]) => void;
  onDelete: (value: AutocompleteProps<T, true, false, true>["value"]) => void;
  onSelect: (value: AutocompleteProps<T, true, false, true>["value"]) => void;
  label: string;
  placeholder?: string;
  onPaste?: ClipboardEventHandler<HTMLDivElement>;
  value: T[] | undefined;
  options: T[];
  freeSolo?: boolean;
};

function ChipInput<T>({
  onAdd,
  onDelete,
  label,
  placeholder,
  onPaste,
  value,
  options,
  freeSolo = true,
  onSelect
}: ChipInputProps<T>) {
  return (
    <Autocomplete<T, true, false, typeof freeSolo>
      multiple
      id="tags-filled"
      options={options?.length ? options : []}
      value={value}
      defaultValue={[]}
      freeSolo={freeSolo}
      onPaste={onPaste}
      onChange={(e, currentValue, reason) => {
        if (reason === "createOption") {
          onAdd(currentValue);
        } else if (reason === "removeOption") {
          onDelete(currentValue);
        } else if (reason === "selectOption") {
          onSelect(currentValue);
        }
      }}
      {...(options?.length && {
        renderOption: (props, option) => {
          return <MenuItem {...props}>{option.label}</MenuItem>;
        }
      })}
      renderTags={(
        currentValue,
        getTagProps: (arg0: { index }) => JSX.IntrinsicAttributes
      ) =>
        currentValue.map((option, idx) => {
          return (
            <Chip
              variant="outlined"
              label={option?.label || option}
              {...getTagProps({ index: idx })}
              key={`${option.label}-${idx}`}
            />
          );
        })
      }
      renderInput={params => (
        <TextField {...params} label={label} placeholder={placeholder} />
      )}
    />
  );
}

export default ChipInput;
