/* eslint-disable max-classes-per-file */

export class ErpTransactionCriticalDuplicationError extends Error {
  constructor(
    message = "Transaction ID, Amount, Date of Transaction and Customer exist in database"
  ) {
    super(message);
    this.name = "CRITICAL_DUPLICATE";
    this.message = message;
  }
}

export class ErpTransactionIdOnlyDuplicateError extends Error {
  constructor(message = "Transaction Id already exists in database") {
    super(message);
    this.name = "TRANSACTION_ID_DUPLICATE";
    this.message = message;
  }
}
