import React from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    height: "75vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  textContainer: {
    textAlign: "center"
  }
}));

export default function DRMNoDataState({
  backButton,
  text
}: {
  backButton: JSX.Element;
  text: string;
}) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.textContainer}>
        <Typography variant="h5">{text}</Typography>
        {backButton}
      </div>
    </div>
  );
}
