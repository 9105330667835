import React from "react";

import { ListItem, ListItemText } from "@mui/material";

import { displayMoney } from "helpers/DataProcessing";
import {
  getFirebase,
  updateFirebase,
  isUserCresicorEmployee,
  getFirebaseConsoleURL
} from "helpers/Firebase";
import ModalContent from "components/WebsiteElements/ModalContent";
import { common, grey, red } from "@mui/material/colors";
import DataTable from "../tables/MuiDataTable";

const { white } = common;
const grey700 = grey["700"];
const { black } = common;
const red700 = red["700"];

const styles = {
  header: {
    marginLeft: "-15px",
    marginRight: "-50px",
    fontSize: 20,
    color: black
  },
  tabs: {
    backgroundColor: grey700
  }
};

export default class RevenueProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      revenueItem: {},
      changes: false,
      messages: {},
      fields: {},
      searchQuery: "",
      numDistributors: 1
    };
    this.revenueprofileRef = React.createRef();
  }

  getRevenueData = (revenueKey, category) => {
    getFirebase(
      6,
      revenue => {
        this.setState({
          revenueItem: revenue,
          fields: this.getFields(revenue)
        });
      },
      revenueKey
    );
  };

  handleSaveChanges = () => {
    const { revenueItem } = this.state;
    updateFirebase(6, revenueItem, this.props.revenueKey);
    this.setState({
      changes: false
    });
  };

  prettyPrint = productsArray => {
    let products = "";
    for (let i = 0; i < productsArray.length; i++) {
      if (i != 0) {
        products += ", ";
      }
      products += productsArray[i];
    }
    return products;
  };

  getFields = revenueItem => {
    if (revenueItem) {
      const productName =
        revenueItem.product in this.props.db.products
          ? this.props.db.products[revenueItem.product].name
          : "N/A";
      const fields = {
        Field: [
          "Date",
          "Customer Name",
          "Product Name",
          "Actual Unit Sales",
          "Unit Price",
          "Revenue",
          "Source",
          "Region",
          "Purchase Order"
        ],
        Value: [
          revenueItem.date,
          this.props.db.customers[revenueItem.customer].name,
          productName,
          Math.round(revenueItem.actSales * 100) / 100,
          displayMoney(revenueItem.unitPrice),
          displayMoney(revenueItem.revenue),
          revenueItem.source ?? "N/A",
          revenueItem.region ?? "N/A",
          revenueItem.purchaseOrder ?? "N/A"
        ]
      };
      return fields;
    }
    return null;
  };

  componentDidMount() {
    this.getRevenueData(this.props.revenueKey, this.props.category);

    if (!this.props.fixedHeight) {
      const ppHeight =
        $(window).height() - $(this.revenueprofileRef.current).offset().top;
      this.setState({
        ppHeight: ppHeight - 16 // because of strange error of longer ppHeight post-mount
      });
    }
  }

  // This component is unique in that new queries to the database are needed even after the initial mount,
  // every time new props are received.
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getRevenueData(nextProps.revenueKey, nextProps.category);

    if (!nextProps.fixedHeight && $(this.revenueprofileRef.current).offset()) {
      const ppHeight =
        $(window).height() - $(this.revenueprofileRef.current).offset().top;
      this.setState({
        ppHeight
      });
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    const customerName = this.state.revenueItem.customer
      ? this.props.db.customers[this.state.revenueItem.customer].name
      : "None";
    const firebaseConsoleURL = `${getFirebaseConsoleURL()}/companies/${
      this.props.db.companyid
    }/revenue/${this.props.revenueKey}`;
    return (
      <div>
        <ListItem
          sx={{ background: theme => theme.palette.readOnly.surface3 }}
          ContainerComponent="div">
          <ListItemText
            primary="Revenue Profile"
            secondary={
              <span>
                {this.state.revenueItem
                  ? `for ${customerName} on ${this.state.revenueItem.date}`
                  : "Deleted"}
                {isUserCresicorEmployee() && (
                  <span>
                    {" "}
                    |{" "}
                    <a
                      href={firebaseConsoleURL}
                      target="_blank"
                      style={{ color: "#1b7acf" }}
                      rel="noreferrer">
                      View on Firebase Console
                    </a>
                  </span>
                )}
              </span>
            }
          />
        </ListItem>
        {this.state.revenueItem ? (
          <ModalContent
            style={{
              paddingLeft: "16px",
              paddingRight: "16px",
              height: this.state.ppHeight,
              overflow: "auto"
            }}
            innerRef={this.revenueprofileRef}>
            <br />
            <DataTable title="Revenue Details" data={this.state.fields} />
            <br />
          </ModalContent>
        ) : (
          <div className="centering" style={{ margin: 16 }}>
            Revenue Item no longer exists.
          </div>
        )}
      </div>
    );
  }
}
