import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

export default function DollarFormatter(props) {
  const { inputRef, onChange, showNegative, ...other } = props;
  const prefix = showNegative ? "−$ " : "$ ";
  const allowNegative = !showNegative;

  return (
    <NumberFormat
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      decimalScale={2}
      thousandSeparator
      fixedDecimalScale
      isNumericString
      prefix={prefix}
      allowNegative={allowNegative}
    />
  );
}

DollarFormatter.propTypes = {
  inputRef: PropTypes.func,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  showNegative: PropTypes.bool
};
