import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import thunk from "redux-thunk";
import DRMReducer from "components/Deductions/DeductionsReconciliation/redux/DRMSlice";
import ActivityLogReducer from "components/Deductions/DeductionsReconciliation/ActivityLog/ActivityLogSlice";
import backupReducer from "components/Deductions/DeductionsReconciliation/redux/BackupSlice";
import erpReducer from "components/Deductions/DeductionsReconciliation/redux/ERPSlice";
import { useDispatch } from "react-redux";
import resolveMultipleInvoiceLinesReducer from "components/Deductions/DeductionsReconciliation/ViewData/ViewTransactions/ResolveMultipleInvoiceLines/redux/ResolveMultipleInvoiceLinesSlice";
import addPromotionReducer from "./components/AddPromotion/reducers";
import deductionsMatchingReducer from "./components/Deductions/DeductionsProcessing/DeductionsMatching/reducers";
import invoiceLinesMatchingReducer from "./components/Deductions/DeductionsReconciliation/ViewData/ViewBackup/InvoiceLineMatching/reducers";
import uploadBackupReducer from "./components/Deductions/DeductionsReconciliation/UploadFiles/UploadBackup/redux/reducers";
import addTransactionReducer from "./components/Deductions/DeductionsReconciliation/UploadFiles/AddTransaction/redux/AddTransactionSlice";
import applyRepaymentReducer from "./components/Deductions/DeductionsReconciliation/ViewData/ViewTransactions/ApplyRepayment/redux/applyRepaymentSlice";
import addInvoiceLinesReducer from "./components/Deductions/DeductionsReconciliation/ViewData/ViewBackup/AddInvoiceLines/redux/addInvoiceLinesSlice";

const rootReducer = combineReducers({
  addPromotion: addPromotionReducer,
  deductionsMatching: deductionsMatchingReducer,
  invoiceLinesMatching: invoiceLinesMatchingReducer,
  uploadBackup: uploadBackupReducer,
  drm: DRMReducer,
  activityLog: ActivityLogReducer,
  backupTable: backupReducer,
  erpTable: erpReducer,
  addTransaction: addTransactionReducer,
  applyRepayment: applyRepaymentReducer,
  resolveMultipleInvoiceLines: resolveMultipleInvoiceLinesReducer,
  addInvoiceLines: addInvoiceLinesReducer
});

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk]
});
export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const combinedReducer = rootReducer;
