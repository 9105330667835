import React from "react";

import {
  isUserCresicorEmployee,
  getCompanyUsers,
  removeFirebase,
  firebase
} from "helpers/Firebase";

import { Toolbar, ToolbarSeparator, ToolbarTitle } from "ui-library/Toolbar";
import ToolbarGroup from "ui-library/ToolbarGroup";
import AddBoxIcon from "@mui/icons-material/AddBox";
import SearchIcon from "@mui/icons-material/Search";

import Button from "ui-library/Button";
import IconButton from "ui-library/IconButton";
import TextField from "ui-library/TextField";

import { infoDialog } from "helpers/OpenDialog";
import { grey, common, red } from "@mui/material/colors";
import { Stack } from "@mui/material";
import BrokerProfile from "./BrokerProfile";
import CloseButton from "../WebsiteElements/CloseButton";
import PaginationMUITable from "../tables/PaginationMUITable";
import NewBroker from "./NewBroker";

const grey600 = grey["600"];
const grey700 = grey["700"];
const { white } = common;
const { black } = common;
const red400 = red["400"];
const red600 = red["600"];

const pagelength = 20;

class Brokers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      brokers: {},
      companyUsers: {},
      currentlySelected: "",
      showSearch: false,
      searchQuery: "",
      openFilePicker: false,
      modalOpen: false,
      reverseSort: false,
      snackbar: false,
      allSelected: [],
      count: pagelength,
      brokersJSX: [],
      tableBodyRenderKey: 0
    };
  }

  openFilePicker = jsx => {
    this.setState({
      openFilePicker: true
    });
    this.props.showDropDownWindow(jsx);
  };

  closeFilePicker = () => {
    this.setState({
      openFilePicker: false
    });
  };

  addBroker = () => {
    if (
      (parseInt(this.props.db.meta.max_brokers) || 0) <=
      Object.keys(this.props.db.brokers).length
    ) {
      infoDialog(
        this.props.openClose,
        "Unable to create broker",
        "You have reached the maximum number of brokers allowed by your contract. \
        Please contact Team Vividly if you have any questions.",
        "Go Back"
      );
    } else {
      this.props.openClose.setAppModal(
        "Add Broker",
        <NewBroker openClose={this.props.openClose} db={this.props.db} />,
        <CloseButton openClose={this.props.openClose} />
      );
    }
  };

  deleteBroker = (event, rowData) => {
    const brokerKey = rowData.key;
    this.props.openClose.setAppModal(
      "Delete Broker",
      <div className="centering">
        Are you sure you want to delete this broker?
      </div>,

      <Stack>
        <Button
          label="Yes, I'm sure"
          color="error"
          variant="text"
          onClick={() => {
            if (this.hasDependencies(brokerKey)) {
              this.props.openClose.closeAppModal();
              this.props.openClose.setAppModal(
                "Unable to delete broker",
                <div className="centering">TODO</div>,
                <div className="centering">
                  <Button
                    label="Okay"
                    onClick={() => {
                      this.props.openClose.closeAppModal();
                    }}
                  />
                </div>
              );
            } else {
              this.props.openClose.closeAppModal();
              removeFirebase(14, brokerKey);
            }
          }}
        />
        <Button
          label="No, go back"
          variant="text"
          onClick={() => {
            this.props.openClose.closeAppModal();
          }}
        />
      </Stack>
    );
  };

  hasDependencies = key => {
    // TODO will there be dependencies?

    return false;
  };

  handleSearchButton = () => {
    this.setState({
      showSearch: true
    });
  };

  handleSearch = event => {
    this.handleInfiniteLoad(true);
    this.setState({
      searchQuery: event.target.value.toLowerCase(),
      currentlySelected: "",
      tableBodyRenderKey: this.state.tableBodyRenderKey + 1
    });
  };

  getContactNames = list => {
    let result = "";
    if (list) {
      for (let i = 0; i < list.length; i++) {
        if (i != 0) {
          result += " | ";
        }
        result += this.props.db.contacts[list[i]].name;
      }
    }
    return result;
  };

  handleProfileOpen = (event, rowData) => {
    const brokerKey = rowData.key;
    this.props.showRightDrawer(
      <div>
        <BrokerProfile
          brokerKey={brokerKey}
          db={this.props.db}
          openClose={this.props.openClose}
          readOnly={this.props.readOnly}
        />
      </div>
    );
  };

  getBrokers = () => {
    const filteredBrokers = [];
    for (const key in this.props.db.brokers) {
      const b = this.props.db.brokers[key];
      if (
        this.state.searchQuery === "" ||
        b.name.toLowerCase().indexOf(this.state.searchQuery) != -1 ||
        b.abbr.toLowerCase().indexOf(this.state.searchQuery) != -1
      ) {
        b.key = key;
        filteredBrokers.push(b);
      }
      // TODO more info?
    }

    return filteredBrokers;
  };

  getBrokersJSX = (filteredBrokers, reset) => {
    const brokersJSX = [];
    for (let i = 0; i < filteredBrokers.length && i < this.state.count; i++) {
      const b = filteredBrokers[i];
      brokersJSX.push({
        key: b.key,
        name: b.name,
        abbr: b.abbr,
        contacts: this.getContactNames(b.contacts)
      });
    }

    return brokersJSX;
  };

  componentDidMount() {
    getCompanyUsers(companyUsers => {
      this.setState({ companyUsers });
    });
    const brokerKey = this.props.match?.params?.key;
    const allBrokers = this.props.db.brokers;
    if (brokerKey && brokerKey in allBrokers) {
      this.handleProfileOpen(null, { key: brokerKey });
    }
  }

  render() {
    const user = firebase.auth().currentUser;
    const filteredBrokers = this.getBrokers();
    const brokersJSX = this.getBrokersJSX(filteredBrokers);

    const actionParams = {
      openProfile: rowData => ({
        onClick: this.handleProfileOpen
      })
    };
    if (isUserCresicorEmployee()) {
      actionParams.delete = rowData => ({
        onClick: this.deleteBroker
      });
    }
    const columns = [
      { title: "Name", field: "name", width: 300 },
      { title: "Abbreviation", field: "abbr" },
      { title: "Contacts", field: "contacts", width: 300 }
    ];

    return (
      <div>
        <Toolbar
          style={{ justifyContent: "space-between", minHeight: "unset" }}>
          <ToolbarGroup>
            <ToolbarTitle text="Brokers" style={{ color: black }} />
            <ToolbarSeparator flexItem />
            <IconButton
              onClick={this.handleSearchButton}
              tooltip="Search"
              size="large">
              <SearchIcon />
            </IconButton>
            {this.state.showSearch && (
              <TextField
                placeholder="Search..."
                variant="standard"
                style={{ marginTop: 0 }}
                onChange={this.handleSearch}
              />
            )}
          </ToolbarGroup>

          <ToolbarGroup>
            {/* <IconMenu
              iconButtonElement={
                <IconButton tooltip="Settings">
                  <SettingsIcon />
                </IconButton>
              }
              anchorOrigin={{ horizontal: "right", vertical: "top" }}
              targetOrigin={{ horizontal: "right", vertical: "top" }}
              onChange={this.props.handleAdditionalDataDisplay}
            >
              <MenuItem
                primaryText={
                  <Subheader style={{ marginLeft: "-15px" }}>Export</Subheader>
                }
                disabled={true}
              />
              <MenuItem
                value={0}
                primaryText="Download Brokers"
                leftIcon={<DownloadIcon />}
                disabled={Object.keys(this.props.db.brokers).length == 0}
                onClick={() => {
                  // TODO make an export...
                  // exportFiles(user, "Brokers");
                  this.props.openClose.setAppModal(
                    "Export In Progress",
                    <div>
                      <br />
                      <div className="centering">
                        {
                          "Your export is being prepared and will be emailed to you shortly."
                        }
                      </div>

                      <div className="centering">
                        <Button
                          label="Ok"
                          style={{ color: grey700, margin: 16 }}
                          onClick={() => {
                            this.props.openClose.closeAppModal();
                          }}
                        />
                      </div>
                    </div>
                  );
                }}
              />
            </IconMenu> */}
            {this.props.db.permissions.includes("add") && !this.props.readOnly && (
              <IconButton
                onClick={this.addBroker}
                tooltip="Add Broker"
                size="large">
                <AddBoxIcon />
              </IconButton>
            )}
          </ToolbarGroup>
        </Toolbar>
        <PaginationMUITable
          allLoaded
          data={brokersJSX}
          columns={columns}
          handleProfileOpen={this.handleProfileOpen}
          actionParams={actionParams}
          selection={false}
        />
      </div>
    );
  }
}

export default Brokers;
