import React from "react";
import { connect } from "react-redux";

import Checkbox from "ui-library/Checkbox";
import { Dialog } from "@mui/material";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import RefreshIcon from "@mui/icons-material/Refresh";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import withStyles from "@mui/styles/withStyles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Button from "ui-library/Button";
import IconButton from "ui-library/IconButton";
import MenuItem from "ui-library/MenuItem";
import Select from "ui-library/Select";

import Chip from "@mui/material/Chip";
import { ButtonGroup, ExportCSVButton } from "react-bootstrap-table";
import ReactTable from "react-table-v6";
import withDraggableColumns from "react-table-hoc-draggable-columns";
import FileSaver from "file-saver";
import { withSnackbar } from "notistack";

import PromProfile from "components/Planning/PromProfile";
import Mixpanel from "helpers/Mixpanel";
import { updateFirebase, downloadFilesFromStorage } from "helpers/Firebase";
import { blueGrey, grey, common } from "@mui/material/colors";
import { confidenceFilter } from "./index";
import {
  updateField,
  addLineKey,
  deleteLineKey,
  getSuggestedMatches
} from "./actions";
import DmmWorker from "./dmm.worker.js";

const blueGrey50 = blueGrey["50"];
const grey100 = grey["100"];
const grey200 = grey["200"];
const grey300 = grey["300"];
const grey400 = grey["400"];
const grey500 = grey["500"];
const grey700 = grey["700"];
const { white } = common;
const { black } = common;
const ReactTableDraggableColumns = withDraggableColumns(ReactTable);

const accumColsOrder = [
  "Row",
  "Matched Line Key",
  "Deductions Amount",
  "Matched Store Customer",
  "Customer Name",
  "Matched Promotion Customer",
  "Customer Name (accumulation)",
  "Matched Product",
  "Matched Line Product Group",
  "Spend Rate",
  "Matched Line Spend Rate",
  "Spend Rate Difference",
  "Month-Year",
  "Matched Month-Year",
  "Matched Promotion Type",
  "Matched Line Closed",
  "Matched Line Account"
];
const allLinesColsOrder = [
  "Row",
  "Matched Line Key",
  "Deductions Amount",
  "Matched Store Customer",
  "Customer Name",
  "Matched Promotion Customer",
  "Product Name",
  "Matched Product",
  "Matched Line Product Group",
  "Spend Rate",
  "Matched Line Spend Rate",
  "Spend Rate Difference",
  "Month-Year",
  "Matched Month-Year",
  "Promotion Type",
  "Matched Promotion Type",
  "Matched Line Closed",
  "Matched Line Account"
];

const styles = {
  col: {
    backgroundColor: blueGrey50
  },
  title: {
    fontWeight: "bold",
    textAlign: "center",
    paddingTop: "25px",
    width: $(window).width() / 4
  },
  scrollBar: {
    height: $(window).height() - 330,
    width: $(window).width() * 0.8
  },
  chip: {
    margin: 4,
    cursor: "pointer"
  }
};

const StyledAppBar = withStyles({
  root: {
    position: "relative",
    backgroundColor: "rgb(66 66 66)",
    boxShadow: "none"
  }
})(AppBar);

const StyledTypography = withStyles({
  root: {
    flex: 1
  }
})(Typography);

const StyledSlide = withStyles({
  root: {
    zIndex: "1500 !important"
  }
})(Slide);

const StyledDialog = withStyles({
  root: {
    zIndex: "1500 !important"
  }
})(Dialog);

const Transition = React.forwardRef((props, ref) => {
  return <StyledSlide direction="up" ref={ref} {...props} />;
});

class MatchingResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      fileData: {},
      errors: [],
      warnings: [],
      view: "View Accumulated Lines",
      order: [],
      modalOpen: false,
      height: $(window).height(),
      mouseX: null,
      mouseY: null,
      hiddenColumns: [],
      clicked: null,
      dmmOrdering: props.db.dmmOrdering,
      confidenceLevel: "Any"
    };
  }

  changeView = () => {
    const newView =
      this.state.view == "View All Lines"
        ? "View Accumulated Lines"
        : "View All Lines";
    let order = [];
    if (newView == "View All Lines") {
      order = this.getOrder(
        this.props.allLinesDFTable,
        allLinesColsOrder,
        newView
      );
    } else {
      order = this.getOrder(this.props.accumDFTable, accumColsOrder, newView);
    }
    this.setState({ view: newView, order });

    Mixpanel.track("Toggle View All Lines/Accumulated Match");
  };

  exportBothViews = () => {
    const { companyid } = this.props.db;
    const fileName = Date.now();
    this.props.enqueueSnackbar(
      <div>
        Generating Export
        <RefreshIcon
          className="fa-spin"
          style={{ color: white, marginLeft: 10 }}
        />
      </div>,
      {
        variant: "default",
        persist: true,
        key: fileName,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center"
        },
        action: key => {
          return (
            <>
              <Button
                label="Dismiss"
                onClick={() => {
                  this.props.closeSnackbar(key);
                }}
                style={{ color: white }}
              />
            </>
          );
        }
      }
    );
    const worker = new DmmWorker();
    if (!this.props.fileTooLarge) {
      const wsAccumOptions =
        this.state.view == "View Accumulated Lines"
          ? { header: this.state.order }
          : undefined;
      const wsAllLinesOptions =
        this.state.view == "View Accumulated Lines"
          ? undefined
          : { header: this.state.order };
      worker.onmessage = ({ data }) => {
        this.props.closeSnackbar(fileName);
        FileSaver.saveAs(data, "Deductions Matching Results.xlsx");
        worker.terminate();
      };
      worker.postMessage({
        type: "exportBothViews",
        args: {
          allLinesDFTable: this.props.allLinesDFTable,
          accumDFTable: this.props.accumDFTable,
          confidence: this.state.confidenceLevel,
          confidenceThresholds: this.props.confidenceThresholds,
          source: this.props.source,
          wsAccumOptions,
          wsAllLinesOptions
        }
      });
    } else {
      downloadFilesFromStorage(
        [this.props.uploadPath],
        ["Deductions Matching Results.xlsx"],
        (blob, name) => {
          FileSaver.saveAs(blob, name);
          this.props.closeSnackbar(fileName);
        },
        null
      );
    }
    Mixpanel.track("Export Matching Results");
  };

  unhideColumns = () => {
    this.setState({
      hiddenColumns: []
    });
  };

  createCustomButtonGroup = dialog => {
    const otherView =
      this.state.view == "View All Lines"
        ? "View Accumulated Lines"
        : "View All Lines";

    return (
      <ButtonGroup
        className="my-custom-class"
        sizeClass="btn-group-md"
        style={{
          paddingTop: "5px",
          paddingBottom: "4px",
          paddingRight: "5px"
        }}>
        <ExportCSVButton
          btnText="Export Matching Data"
          onClick={this.exportBothViews}
          style={{
            height: "36px"
          }}
        />
        <button
          type="button"
          className="btn btn-primary"
          onClick={this.changeView}
          style={{
            height: "36px"
          }}>
          {otherView}
        </button>
        {!dialog && (
          <Button
            label={
              <Tooltip title="Expand Result Table">
                <OpenWithIcon />
              </Tooltip>
            }
            backgroundColor={grey200}
            hoverColor={grey400}
            onClick={() => {
              this.setState({ modalOpen: true });
              Mixpanel.track("Match View Expanded");
            }}
            style={{ height: 36 }}
          />
        )}
        {this.state.hiddenColumns.length > 0 && (
          <button
            type="button"
            className="btn btn-default"
            onClick={this.unhideColumns}
            style={{
              height: "36px"
            }}>
            Unhide all
          </button>
        )}
      </ButtonGroup>
    );
  };

  columnClassNameFormat = (colName, fieldValue, row, rowIdx, colIdx) => {
    const data =
      this.state.view == "View All Lines"
        ? this.props.allLinesDF
        : this.props.accumDF;
    const rowNumber = row.Row;
    const lineKeys = data[rowNumber]["Matched Line Key"];
    if (lineKeys == "-" && fieldValue == "-") {
      return "td-column-error";
    }
  };

  handleDelete = (row, lineKey, view) => {
    this.props.dispatch(deleteLineKey([row], lineKey, view));
  };

  lineKeyColumnFormatter = (wrappedRow, isDialog = false) => {
    const { row } = wrappedRow;
    const rowNumber = row.Row;
    const data =
      this.state.view == "View All Lines"
        ? this.props.allLinesDF
        : this.props.accumDF;
    const origLineKeys = data[rowNumber]["Matched Line Key"].split("|");
    const lineKeys = wrappedRow.value.split("|");
    if (lineKeys[0] == "-" && origLineKeys[0] == "-") {
      return <div>-</div>;
    }
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {origLineKeys.map(lineKey => (
          <Chip
            style={{
              backgroundColor: lineKeys.includes(lineKey) ? grey300 : grey500,
              ...styles.chip
            }}
            onDelete={
              lineKeys.includes(lineKey) &&
              !this.props.fileTooLarge &&
              this.handleDelete.bind(null, row, lineKey, this.state.view)
            }
            onClick={() => {
              if (!lineKeys.includes(lineKey)) {
                this.props.dispatch(
                  addLineKey([row], lineKey, this.state.view)
                );
                Mixpanel.track("Line Key ID Added");
              } else {
                const promKey = lineKey.split("-").slice(0, -1).join("-");
                const showRightDrawerArgs = [
                  <PromProfile
                    promKey={promKey}
                    defaultLine={lineKey}
                    openClose={this.props.openClose}
                    db={this.props.db}
                  />
                ];
                if (isDialog) {
                  showRightDrawerArgs.push({
                    zIndex: 1500
                  });
                }
                this.props.showRightDrawer(...showRightDrawerArgs);
                Mixpanel.track("Promotion Viewed", {
                  View: "Deductions Scanner",
                  Component: "MatchingResult"
                });
              }
            }}
            label={lineKey}
          />
        ))}
      </div>
    );
  };

  sortLineKeys = (a, b, order) => {
    const aLines = a.split("|");
    const bLines = b.split("|");
    if (order == "desc") {
      if (a == "-") {
        return 1;
      }
      if (b == "-") {
        return -1;
      }
      if (aLines.length == bLines.length) {
        return 0;
      }
      return aLines.length < bLines.length ? 1 : -1;
    }
    if (a == "-") {
      return -1;
    }
    if (b == "-") {
      return 1;
    }
    if (aLines.length == bLines.length) {
      return 0;
    }
    return aLines.length > bLines.length ? 1 : -1;
  };

  updateHeight = () => {
    this.setState({ height: $(window).height() });
  };

  setDMMOrdering = (order, view) => {
    let obj = {};
    if (!this.state.dmmOrdering || !this.state.dmmOrdering[this.props.source]) {
      obj[this.props.source] = {};
    } else {
      obj = { ...this.state.dmmOrdering };
    }
    obj[this.props.source][view] = order;
    this.setState({ dmmOrdering: obj });
  };

  getOrder = (data, initialOrdering, view) => {
    if (
      this.state.dmmOrdering &&
      this.state.dmmOrdering[this.props.source] &&
      this.state.dmmOrdering[this.props.source][view]
    ) {
      return [
        ...new Set([
          ...this.state.dmmOrdering[this.props.source][view],
          ...Object.keys(data[0])
        ])
      ];
    }
    let order = [];
    if (!data[0]) {
      return order;
    }
    order = [...new Set([...initialOrdering, ...Object.keys(data[0])])];
    updateFirebase(22, order, `${this.props.source}/${view}`);
    this.setDMMOrdering(order, view);
    return order;
  };

  calculateSpendRateDelta = data => {
    for (const row of data) {
      const rowNumber = row.Row;
      const spendRate = data[rowNumber]["Spend Rate"];
      const matchedSpendRate = data[rowNumber]["Matched Line Spend Rate"];
      let spendDelta = "";
      if (
        !(
          matchedSpendRate === "" ||
          matchedSpendRate.includes("|") ||
          matchedSpendRate === "-"
        )
      ) {
        spendDelta = Math.abs(
          parseFloat(spendRate) - parseFloat(matchedSpendRate)
        ).toFixed(2);
      }
      row["Spend Rate Difference"] = spendDelta;
    }
  };

  UNSAFE_componentWillMount() {
    const allLinesDF = Object.keys(this.props.allLinesDF).map(
      x => this.props.allLinesDF[x]
    );
    this.calculateSpendRateDelta(allLinesDF);
    const allLinesDFTable = JSON.parse(JSON.stringify(allLinesDF));

    const accumDF = Object.keys(this.props.accumDF).map(
      x => this.props.accumDF[x]
    );
    const accumDFTable = JSON.parse(JSON.stringify(accumDF));
    this.calculateSpendRateDelta(accumDF);
    const { accumToLines } = this.props;
    const order = this.getOrder(accumDFTable, accumColsOrder, this.state.view);
    this.setState({
      allLinesDF,
      allLinesDFTable,
      accumDF,
      accumDFTable,
      accumToLines,
      order
    });
  }

  componentDidMount() {
    const allLinesDF = Object.keys(this.props.allLinesDF).map(
      x => this.props.allLinesDF[x]
    );
    this.calculateSpendRateDelta(allLinesDF);
    const allLinesDFTable = JSON.parse(JSON.stringify(allLinesDF));

    const accumDF = Object.keys(this.props.accumDF).map(
      x => this.props.accumDF[x]
    );
    this.calculateSpendRateDelta(accumDF);
    const accumDFTable = JSON.parse(JSON.stringify(accumDF));
    const { accumToLines } = this.props;
    const order = this.getOrder(accumDFTable, accumColsOrder, this.state.view);
    this.setState({
      allLinesDF,
      allLinesDFTable,
      accumDF,
      accumDFTable,
      accumToLines,
      order
    });
    window.addEventListener("resize", this.updateHeight.bind(this));
    this.props.dispatch(getSuggestedMatches(this.props.suggestMatches));
    this.props.onLoad();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateHeight.bind(this));
  }

  handleClick = (event, key) => {
    event.preventDefault();
    this.setState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      clicked: key
    });
  };

  handleClose = () => {
    this.setState({
      mouseX: null,
      mouseY: null,
      clicked: null
    });
  };

  handleHide(key) {
    this.setState({
      mouseX: null,
      mouseY: null,
      clicked: null,
      hiddenColumns: [...this.state.hiddenColumns, key]
    });
  }

  filterDuplicates(tableData, columns) {
    columns.forEach(columnKey => {
      tableData.forEach(row => {
        const values = row[columnKey].split("|");
        if (values.every(value => value == values[0])) {
          row[columnKey] = values[0];
        }
      });
    });
    return tableData;
  }

  render() {
    let tableData =
      this.state.view == "View Accumulated Lines"
        ? this.props.accumDFTable
        : this.props.allLinesDFTable;
    tableData = this.filterDuplicates(tableData, [
      "Matched Promotion Customer"
    ]);
    tableData = confidenceFilter(
      tableData,
      this.state.confidenceLevel,
      this.props.confidenceThresholds,
      this.props.source
    );
    console.log("file too large", this.props.fileTooLarge);

    const widths = {
      Row: 55,
      "Deductions Amount": 154,
      "Customer Name (accumulation)": 233,
      "Spend Rate": 98,
      "Month-Year": 101,
      "Matched Month-Year": 163,
      "Matched Promotion Customer": 225,
      "Matched Line Key": 145,
      "Matched Line Product Group": 214
    };

    const baseRenderer = (row, val) => (
      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor:
            row.value === "-" && row.row["Matched Line Key"] === "-"
              ? "#e57373"
              : "#FFFFFF",
          borderRadius: "2px",
          padding: "7px 5px"
        }}>
        {val}
      </div>
    );

    const getRenderer = (isDialog = false) => ({
      "Matched Line Key": [
        row => this.lineKeyColumnFormatter(row, isDialog),
        this.sortLineKeys
      ],
      "Deductions Amount": [
        row => baseRenderer(row, row.value.toFixed(2)),
        undefined
      ],
      "Spend Rate": [row => baseRenderer(row, row.value.toFixed(2)), undefined]
    });

    let columns = [];
    let draggable = [];

    const resultJSX = dialog => {
      const height = dialog ? this.state.height - 145 : this.state.height - 240;
      if (tableData[0]) {
        columns = Object.keys(tableData[0])
          .filter(key => !key.startsWith("_"))
          .map(key => {
            const header = (
              <div onContextMenu={event => this.handleClick(event, key)}>
                <b>{key}</b>
                <Menu
                  keepMounted
                  open={
                    this.state.mouseY !== null && this.state.clicked === key
                  }
                  onClose={this.handleClose.bind(this)}
                  anchorPosition={
                    this.state.mouseY !== null &&
                    this.state.mouseX !== null &&
                    this.state.clicked === key
                      ? { top: this.state.mouseY, left: this.state.mouseX }
                      : undefined
                  }
                  anchorReference="anchorPosition"
                  style={{ zIndex: "1500" }}
                  elevation={1}>
                  <MenuItem
                    onClick={() => this.handleHide(key)}
                    style={{ zIndex: "1500" }}>
                    Hide
                  </MenuItem>
                </Menu>
              </div>
            );
            let width = 200;
            let show = true;
            let cellRender = row => baseRenderer(row, row.value);
            let sortMethod;
            if (Object.keys(widths).includes(key)) {
              width = widths[key];
            }
            if (this.state.hiddenColumns.includes(key)) {
              show = false;
            }
            const renderers = getRenderer(dialog);
            if (Object.keys(renderers).includes(key)) {
              [cellRender, sortMethod] = renderers[key];
            }

            return {
              Header: header,
              accessor: key,
              Cell: cellRender,
              sortMethod,
              width,
              show
            };
          });
        draggable = Object.keys(tableData[0]).filter(
          key => !key.startsWith("_")
        );
      }
      columns.sort((a, b) =>
        this.state.order.indexOf(a.accessor) >
        this.state.order.indexOf(b.accessor)
          ? 1
          : -1
      );
      return (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderWidth: "1px 0 0 0",
              borderStyle: "solid",
              borderColor: "#bdbdbd",
              padding: 5
            }}>
            <Select
              floatingLabelText="Match Confidence"
              value={this.state.confidenceLevel}
              onChange={event => {
                this.setState({ confidenceLevel: event.target.value });
                Mixpanel.track("Change Match Confidence", {
                  "Confidence Level": event.target.value
                });
              }}
              MenuProps={{
                style: { zIndex: 2000 }
              }}
              style={{
                margin: 0,
                width: "auto",
                minWidth: "145px"
              }}>
              <MenuItem value="Any">Any</MenuItem>
              <MenuItem value="High">High</MenuItem>
              <MenuItem value="Low">Low</MenuItem>
              <MenuItem value="Needs Attn.">Needs Attn.</MenuItem>
            </Select>
            {this.createCustomButtonGroup(dialog)}
          </div>
          <style>{`.ReactTable .rt-tbody {
            overflow: overlay !important;
          }`}</style>
          <div className="centering">
            {this.props.fileTooLarge && (
              <font color="red">{`Only displaying the first ${this.props.fileLineLimit.toLocaleString()} rows in read-only format.`}</font>
            )}
          </div>
          <ReactTableDraggableColumns
            draggableColumns={{
              mode: "reorder",
              draggable,
              onDropSuccess: (
                draggedColumn,
                targetColumn,
                oldIndex,
                newIndex,
                oldOffset,
                newOffset
              ) => {
                const arr = this.state.order.slice();
                newIndex = ((newIndex % arr.length) + arr.length) % arr.length;
                arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
                this.setState({
                  order: arr
                });
                this.setDMMOrdering(arr, this.state.view);
                updateFirebase(
                  22,
                  arr,
                  `${this.props.source}/${this.state.view}`
                );
              }
            }}
            minRows={0}
            defaultPageSize={100}
            data={tableData}
            columns={columns}
            style={{ maxHeight: height }}
            key={this.state.view + this.state.order.join("")}
          />
          <Checkbox
            color="primary"
            label="Use suggested matches"
            checked={this.props.suggestMatches}
            onChange={event => {
              const isInputChecked = event.target.checked;
              this.props.dispatch(
                updateField("suggestMatches", isInputChecked)
              );
              this.props.dispatch(getSuggestedMatches(isInputChecked));
              Mixpanel.track("Toggle Use Selected Matches");
            }}
            disabled={this.props.fileTooLarge}
          />
        </div>
      );
    };

    return (
      <div>
        {resultJSX(false)}
        <StyledDialog
          fullScreen
          open={this.state.modalOpen}
          onClose={() => this.setState({ modalOpen: false })}
          TransitionComponent={Transition}>
          <div
            style={{
              maxHeight: 2000,
              flexDirection: "column"
            }}>
            <StyledAppBar>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => this.setState({ modalOpen: false })}
                  aria-label="close"
                  size="large">
                  <CloseIcon />
                </IconButton>
                <StyledTypography variant="h5">
                  Deductions Matching Results
                </StyledTypography>
              </Toolbar>
            </StyledAppBar>
            {resultJSX(true)}
          </div>
        </StyledDialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const matchingFields = state.deductionsMatching;
  return {
    source: matchingFields.source,
    allLinesDF: matchingFields.allLinesDF,
    allLinesDFTable: matchingFields.allLinesDFTable,
    accumDF: matchingFields.accumDF,
    accumDFTable: matchingFields.accumDFTable,
    suggestMatches: matchingFields.suggestMatches,
    fileLineLimit: matchingFields.fileLineLimit,
    fileTooLarge: matchingFields.fileTooLarge,
    uploadPath: matchingFields.uploadPath
  };
};

export default connect(mapStateToProps)(withSnackbar(MatchingResult));
