import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ERPTransactionTypes } from "deductions-constants/ReconciliationTypes";
import { deductionTransactionStatuses } from "deductions-constants/ReconciliationStatuses";
import ERPTransactionObject from "deductions-models/ERPTransactionObject";
import { TransactionConfig } from "components/Deductions/models";
import _ from "lodash";
import moment from "moment";
import {
  resetAllDuplicateWarning,
  updateAmountErrors
} from "./addTransactionHelpers";

interface AddTransactionErrorState {
  missingTransactionId: boolean;
  duplicateTransactionId: boolean;
  missingInvoiceNumber: boolean;
  missingCustomerKey: boolean;
  missingTransactionDate: boolean;
  invalidTransactionDate: boolean;
  amountNotPositive: boolean;
  amountNotNegative: boolean;
  amountZero: boolean;
  missingAccountingSource: boolean;
  missingTransactionType: boolean;
}

interface DuplicateWarningState {
  criticalDuplicationFound: boolean;
  onlyDuplicateTransactionIdFound: boolean;
  duplicateTransactionIdWarningShown: boolean;
}

export interface AddTransactionState {
  transaction: ERPTransactionObject;
  errors: AddTransactionErrorState;
  showErrors: boolean;
  duplicateWarning: DuplicateWarningState;
}

const blankTransaction: ERPTransactionObject = {
  transactionDisplayId: "",
  checkNumber: "",
  invoiceNumber: "",
  type: ERPTransactionTypes.DEDUCTION,
  status: deductionTransactionStatuses.NEW,
  accountingSource: "",
  key: "",
  customerKey: "",
  amount: 0,
  transactionDate: null,
  createdDate: "",
  createdUser: ""
};

const initialErrorState: AddTransactionErrorState = {
  missingTransactionId: true,
  duplicateTransactionId: false,
  missingInvoiceNumber: true,
  missingCustomerKey: true,
  missingTransactionDate: true,
  invalidTransactionDate: false,
  missingAccountingSource: true,
  missingTransactionType: false,
  // TODO: For repayments vs. deductions piece!
  amountNotPositive: false,
  amountNotNegative: false,
  amountZero: true
};

const initialDuplicateWarningState: DuplicateWarningState = {
  criticalDuplicationFound: false,
  onlyDuplicateTransactionIdFound: false,
  duplicateTransactionIdWarningShown: false
};

const initialState: AddTransactionState = {
  transaction: blankTransaction,
  errors: initialErrorState,
  showErrors: false,
  duplicateWarning: initialDuplicateWarningState
};

const slice = createSlice({
  name: "addTransaction",
  initialState,
  reducers: {
    reset(state: AddTransactionState) {
      state.transaction = _.cloneDeep(blankTransaction);
      state.errors = _.cloneDeep(initialErrorState);
      state.showErrors = false;
      resetAllDuplicateWarning(state);
    },
    setShowErrors(state: AddTransactionState, action: PayloadAction<boolean>) {
      state.showErrors = action.payload;
    },
    setTransactionDisplayId(
      state: AddTransactionState,
      action: PayloadAction<{
        displayId: string;
        allTransactions: Record<string, ERPTransactionObject>;
      }>
    ) {
      state.transaction.transactionDisplayId = action.payload.displayId;
      state.errors.missingTransactionId = action.payload.displayId.length === 0;
      resetAllDuplicateWarning(state);
    },
    setInvoiceNumber(
      state: AddTransactionState,
      action: PayloadAction<string>
    ) {
      state.transaction.invoiceNumber = action.payload;
      state.errors.missingInvoiceNumber = action.payload.length === 0;
    },
    setCheckNumber(state: AddTransactionState, action: PayloadAction<string>) {
      state.transaction.checkNumber = action.payload;
    },
    setTransactionType(
      state: AddTransactionState,
      action: PayloadAction<{
        type: ERPTransactionTypes;
        allTransactionConfigs: Record<string, TransactionConfig>;
      }>
    ) {
      const { type, allTransactionConfigs } = action.payload;
      state.transaction.type = type;

      const { amount, accountingSource } = state.transaction;

      updateAmountErrors(
        state,
        type,
        amount,
        accountingSource,
        allTransactionConfigs
      );
      resetAllDuplicateWarning(state);
    },
    setAccountingSource(
      state: AddTransactionState,
      action: PayloadAction<string>
    ) {
      state.transaction.accountingSource = action.payload;
      state.errors.missingAccountingSource = !action.payload;
    },
    setCustomerKey(state: AddTransactionState, action: PayloadAction<string>) {
      state.transaction.customerKey = action.payload;
      state.errors.missingCustomerKey =
        !action.payload || action.payload.length === 0;
      resetAllDuplicateWarning(state);
    },
    setAmount(
      state: AddTransactionState,
      action: PayloadAction<{
        amount: string;
        allTransactionConfigs: Record<string, TransactionConfig>;
      }>
    ) {
      const { amount, allTransactionConfigs } = action.payload;
      const parsedAmount = parseFloat(amount);

      state.transaction.amount = parsedAmount;

      const { type, accountingSource } = state.transaction;

      updateAmountErrors(
        state,
        type,
        parsedAmount,
        accountingSource,
        allTransactionConfigs
      );
      resetAllDuplicateWarning(state);
    },
    setTransactionDate(
      state: AddTransactionState,
      action: PayloadAction<string | null>
    ) {
      const date = new Date(action.payload || "");

      state.transaction.transactionDate = action.payload || "";
      state.errors.missingTransactionDate = !action.payload;
      state.errors.invalidTransactionDate = !moment(date).isValid();
      resetAllDuplicateWarning(state);
    },
    setInvalidTransactionDate(
      state: AddTransactionState,
      action: PayloadAction<boolean>
    ) {
      state.errors.invalidTransactionDate = action.payload;
    },
    setCriticalDuplication(
      state: AddTransactionState,
      action: PayloadAction<boolean>
    ) {
      state.duplicateWarning.criticalDuplicationFound = action.payload;
    },
    setOnlyDuplicateTransactionIdFound(
      state: AddTransactionState,
      action: PayloadAction<boolean>
    ) {
      state.duplicateWarning.duplicateTransactionIdWarningShown =
        action.payload;
      state.duplicateWarning.onlyDuplicateTransactionIdFound = action.payload;
    }
  }
});

// export redux action
export const {
  reset,
  setShowErrors,
  setTransactionDisplayId,
  setInvoiceNumber,
  setCheckNumber,
  setTransactionType,
  setAccountingSource,
  setCustomerKey,
  setAmount,
  setTransactionDate,
  setInvalidTransactionDate,
  setOnlyDuplicateTransactionIdFound,
  setCriticalDuplication
} = slice.actions;

// export reducer as default import
export default slice.reducer;
