import React, { useMemo } from "react";
import { Divider, Link, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import { useDb } from "contexts/Db";
import { displayUSCurrency } from "helpers/DataProcessing";
import moment from "moment";
import { InvoiceLineDisplayService } from "reconciliation-services";
import {
  getFirebaseConsoleURL,
  isUserCresicorEmployee
} from "helpers/Firebase";

const useStyles = makeStyles(() => ({
  card: {
    marginLeft: "25px",
    marginRight: "25px",
    marginTop: "15px",
    fontFamily: "Oxygen"
  },
  header: {
    lineHeight: "24px",
    fontWeight: 700,
    marginBottom: "8px"
  },
  subtitle: {
    lineHeight: "14px",
    fontWeight: 200,
    marginTop: "2px",
    marginBottom: "2px"
  },
  info: {
    fontSize: "1.25rem",
    fontWeight: 400,
    paddingTop: "4px"
  },
  amount: {
    lineHeight: "20.2px"
  },
  amountContainer: {
    display: "flex",
    paddingTop: "10px"
  },
  divider: {
    marginLeft: "15px",
    marginRight: "15px"
  },
  buttonBase: {
    display: "block",
    textAlign: "initial",
    width: "100%"
  },
  headerContainer: {
    paddingBottom: "4px"
  }
}));

interface InvoiceLineCardProps {
  invoiceLineKey: string;
  invoiceKey: string;
  interactive?: boolean;
}

export default function InvoiceLineCard(
  props: InvoiceLineCardProps
): JSX.Element {
  const classes = useStyles();
  const { invoiceLineKey, interactive, invoiceKey } = props;
  const db = useDb();
  const {
    meta: { fundTypes = {} },
    invoices = {},
    erpTransactions = {},
    allLines = {},
    products = {},
    customers = {}
  } = db;

  const invoiceLineConsoleURL = useMemo(() => {
    return `${getFirebaseConsoleURL()}/companies/${
      db.companyid
    }/reconciliation/invoices/${invoiceKey}/invoiceLines/${invoiceLineKey}`;
  }, [invoiceKey, invoiceLineKey, db.companyid]);

  const invoice = invoices[invoiceKey];

  const invoiceLine = InvoiceLineDisplayService.processInvoiceLineForDisplay(
    invoice,
    (invoice.invoiceLines || {})[invoiceLineKey] || {},
    invoiceLineKey,
    fundTypes,
    invoices,
    erpTransactions,
    allLines,
    products,
    customers
  );

  return (
    <div className={classes.headerContainer}>
      <Typography variant="h6" className={classes.header}>
        {interactive || !isUserCresicorEmployee() ? (
          <>Invoice Line No. {invoiceLine.invoiceLineNumber + 1}</>
        ) : (
          <Link href={invoiceLineConsoleURL} target="_blank" underline="hover">
            Invoice Line No. {invoiceLine.invoiceLineNumber + 1}
          </Link>
        )}
      </Typography>
      <Typography className={classes.subtitle} color="textSecondary">
        Invoice No.&nbsp;
        {invoice.invoiceNumber}
      </Typography>
      <Typography className={classes.info}>
        {invoiceLine.customerName}
      </Typography>
      <div className={classes.amountContainer}>
        <div>
          <Typography className={classNames(classes.info, classes.amount)}>
            {displayUSCurrency(invoiceLine.amount)}
          </Typography>
          <Typography variant="button" color="textSecondary">
            Original Amount
          </Typography>
        </div>
        <div className={classes.divider}>
          <Divider orientation="vertical" />
        </div>
        <div>
          <Typography className={classNames(classes.info, classes.amount)}>
            {displayUSCurrency(invoiceLine.openAmount)}
          </Typography>
          <Typography variant="button" color="textSecondary">
            Open Amount
          </Typography>
        </div>
        <div className={classes.divider}>
          <Divider orientation="vertical" />
        </div>
        <div>
          <Typography className={classNames(classes.info, classes.amount)}>
            {moment(invoiceLine.endDate).format("MMM, D YYYY")}
          </Typography>
          <Typography variant="button" color="textSecondary">
            Invoice Line Date
          </Typography>
        </div>
      </div>
      <div className={classes.amountContainer}>
        <Typography color="textSecondary" variant="caption">
          Invoice Line Status:{" "}
          {
            InvoiceLineDisplayService.HumanReadableInvoiceLineStatuses[
              invoiceLine.status
            ]
          }
        </Typography>
        <div className={classes.divider}>
          <Divider orientation="vertical" />
        </div>
        <Typography color="textSecondary" variant="caption">
          Matched Promotion Line: {invoiceLine.matchedPromLine || "None"}
        </Typography>
        <div className={classes.divider}>
          <Divider orientation="vertical" />
        </div>
        <Typography color="textSecondary" variant="caption">
          Fund Type: {invoiceLine.fundType || "None"}
        </Typography>
      </div>
    </div>
  );
}
