import { InvoiceObject } from "components/Deductions/models";

export interface DisplayInvoiceObject extends InvoiceObject {
  customerName: string;
  displayAssignedUsers: string;

  clearedAmount: number;

  totalWriteOffAmount: number;
  creditedWriteOffAmount: number;
  openWriteOffAmount: number;

  totalDisputeAmount: number;
  repaidDisputeAmount: number;
  openDisputeAmount: number;

  resolvedAmount: number; // cleared + totalWriteOff + repaid
  openAmount: number; // pending + total dispute - repaid = pending + open dispute
}

// cresicor employees can see all options, customers can only see those in invoiceFilterNamesCustomerFacing
export enum invoiceFilters {
  ACTIVE_ALL = "ACTIVE_ALL",
  ACTIVE_APPROVED = "ACTIVE_APPROVED",
  ACTIVE_UNAPPROVED = "ACTIVE_UNAPPROVED",
  INACTIVE_ALL = "INACTIVE_ALL",
  INACTIVE_APPROVED = "INACTIVE_APPROVED",
  INACTIVE_UNAPPROVED = "INACTIVE_UNAPPROVED"
}

export enum invoiceFilterNamesCresicorInternal {
  ACTIVE_ALL = "All Active Invoices",
  ACTIVE_APPROVED = "Approved Active Invoices",
  ACTIVE_UNAPPROVED = "Unapproved Active Invoices",
  INACTIVE_ALL = "All Cancelled Invoices",
  INACTIVE_APPROVED = "Approved Cancelled Invoices",
  INACTIVE_UNAPPROVED = "Unapproved Cancelled Invoices"
}

export enum invoiceFilterNamesCustomerFacing {
  ACTIVE_APPROVED = "Active Invoices",
  INACTIVE_APPROVED = "Cancelled Invoices"
}

export enum invoiceActions {
  CANCEL = "Cancel Invoice",
  APPROVE = "Approve Invoice",
  UNAPPROVE = "Unapprove Invoice",
  EDIT = "Edit Invoice",
  SCAN = "Scan Invoice", // DEPRECATED
  UPLOAD_INVOICE_LINES = "Upload Invoice Lines", // DEPRECATED
  ADD_INVOICE_LINES = "Add Invoice Lines",
  SCAN_AND_UPLOAD_INVOICE_LINES = "Scan",
  DELETE_INVOICE_LINES = "Delete Invoice Lines",
  AUTO_MATCH = "Auto Match Invoice Lines",
  MANUAL_MATCH = "Manually Match Invoice Lines",
  UNLINK_TRANSACTION = "Unlink Invoice from Transaction",
  LINK_TRANSACTION = "Link Invoice to Transaction"
}

export enum invoiceDependencies {
  INVOICE_CANCELLED = "INVOICE_CANCELLED",
  INVOICE_UNAPPROVED = "INVOICE_UNAPPROVED",
  INVOICE_LINES = "INVOICE_LINES",
  NO_INVOICE_LINES = "NO_INVOICE_LINES",
  LINKED_TRANSACTIONS = "LINKED_TRANSACTIONS",
  RESOLUTION_LINES = "RESOLUTION_LINES",
  MATCHED_PROMOTION_LINES = "MATCHED_PROMOTION_LINES",
  INVOICE_HAS_NULL_VALUES = "INVOICE_HAS_NULL_VALUES"
}

export enum invoiceDependencyDescriptions {
  INVOICE_CANCELLED = "invoice is cancelled",
  INVOICE_UNAPPROVED = "invoice is unapproved",
  INVOICE_LINES = "at least one invoice line exists",
  NO_INVOICE_LINES = "there are no invoice lines",
  LINKED_TRANSACTIONS = "at least one ERP transaction is linked",
  RESOLUTION_LINES = "at least one invoice line has a resolution history",
  MATCHED_PROMOTION_LINES = "at least one invoice line has been matched to a promotion",
  INVOICE_HAS_NULL_VALUES = "invoice has null or missing value(s)"
}
