import { useState } from "react";

function useToolTip() {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return {
    open,
    handleTooltipClose,
    handleTooltipOpen
  };
}

export default useToolTip;
