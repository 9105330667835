import { useContext, createContext } from "react";

// TODO(swey): type this correctly in full
type OpenCloseContextValue = {
  setAppModal: Function;
  closeAppModal: Function;
  minimizeAppModal: Function;
  showRightDrawer: Function;
  hideRightDrawer: Function;
  defaultDrawerChange: Function;
  setDrawerClose: Function;
  showDropDownWindow: Function;
  showStealthDropDownWindow: Function;
  setDropDownWindowVisible: Function;
  showSnack: Function;
  hideSnack: Function;
  setMinimizedWindowToolbar: Function;
};

export const OpenCloseContext = createContext<
  OpenCloseContextValue | undefined
>(undefined);

export const useOpenClose = () => {
  const openCloseState = useContext(OpenCloseContext);

  if (!openCloseState) {
    throw new Error(
      "No OpenCloseContext Provider found in tree. Is it present in the component tree?"
    );
  }

  return openCloseState;
};
