const dependencyOrder = [
  "product groups",
  "customers",
  "other customers",
  "customer groups",
  "assigned company users",
  "promotions",
  "promotion types",
  "revenue",
  "spend",
  "invoices",
  "invoice lines",
  "transactions",
  "pricing",
  "bump chart",
  "broker accounts",
  "forecast",
  "all other buckets",
  "deductions matching settings",
  "reconciliation"
];

function formatDependencies(item, dependencies) {
  const numDependencies = dependencies.size;

  let sections = "";
  let ix = 0;

  for (const d of dependencyOrder) {
    if (dependencies.has(d)) {
      const endString = numDependencies - 1 === ix ? "" : ", ";
      sections += d + endString;
      ix += 1;
    }
  }

  const result = `This ${item} is used or referenced in the following sections: ${sections}. Please reconcile these dependencies before deleting this ${item}.`;
  return result;
}

export { formatDependencies };
