// eslint-disable-next-line max-classes-per-file
import ReactTooltip from "react-tooltip";
import React from "react";

import ListItemText from "@mui/material/ListItemText";
import RadioButtonGroup from "@mui/material/RadioGroup";
import RadioButton from "ui-library/RadioButton";
import Autocomplete from "ui-library/Autocomplete";

import MuiTooltip from "@mui/material/Tooltip";
import { Typography } from "@mui/material";

interface PGInfo {
  key: string;
  value: string;
  tooltip: string;
  disabled: boolean;
}

interface IProps {
  selectProductGroups: Array<PGInfo>;
  handleSelectProductGroup: Function;
  lineStateProductGroup: string;
}

class SelectProductGroup extends React.PureComponent<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    return (
      <RadioButtonGroup
        name="productGroups"
        onChange={(event, value) => this.props.handleSelectProductGroup(value)}
        value={this.props.lineStateProductGroup}>
        {this.props.selectProductGroups
          .map(option => {
            return (
              <RadioButton
                value={option.key}
                disabled={option.disabled}
                label={
                  <div>
                    <Typography
                      data-tip
                      data-for={option.key}
                      variant="bodyMedium"
                      className="product-group-radio">
                      {option.value}
                    </Typography>
                    <ReactTooltip id={option.key} place="right" effect="solid">
                      <div>
                        <ul
                          style={{
                            columns: 2,
                            marginTop: "8px",
                            marginBottom: "8px"
                          }}>
                          {option.tooltip.split(",").map((item, index) => {
                            return (
                              <li key={index} style={{ marginRight: "5px" }}>
                                {item}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </ReactTooltip>
                  </div>
                }
                key={option.value}
              />
            );
          })
          .concat([
            <RadioButton
              value="custom"
              disabled={false}
              key="custom"
              label="Select Individual Items"
            />
          ])}
      </RadioButtonGroup>
    );
  }
}

class SelectProductGroupLarge extends React.PureComponent<
  IProps,
  { productGroup: string; selectProductGroups: PGInfo[] }
> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      productGroup: this.props.lineStateProductGroup,
      selectProductGroups: this.props.selectProductGroups.concat({
        key: "custom",
        value: "Select Individual Items",
        tooltip: "Select Individual Items",
        disabled: false
      })
    };
  }

  getPGInfo = (productGroup: string) => {
    const PGArr = this.state.selectProductGroups.filter(
      option => option.key === productGroup
    );
    return PGArr?.[0] ?? {};
  };

  render() {
    return (
      <Autocomplete
        options={this.state.selectProductGroups}
        getOptionLabel={(option: PGInfo) => option.value ?? ""}
        getOptionDisabled={(option: PGInfo) => option.disabled}
        value={this.getPGInfo(this.state.productGroup)}
        onChange={(_, option: PGInfo) => {
          this.setState({ productGroup: option.key });
          this.props.handleSelectProductGroup(option?.key ?? "");
        }}
        openOnFocus
        fullWidth
        floatingLabelText="Product Groups"
        renderOption={(props, option: PGInfo) => {
          return (
            <MuiTooltip title={option.tooltip}>
              <ListItemText primary={option.value} {...props} />
            </MuiTooltip>
          );
        }}
      />
    );
  }
}

export { SelectProductGroup, SelectProductGroupLarge };
