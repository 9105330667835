import React from "react";

import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import IconButton from "ui-library/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ClearIcon from "@mui/icons-material/Clear";
import { makeStyles } from "@mui/styles";
import DataTableScrollable from "components/tables/DataTableScrollable";
import TextField from "ui-library/TextField";
import { Tooltip } from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Select from "ui-library/Select";
import { useDb } from "contexts/Db";
import { useOpenClose } from "contexts/OpenClose";
import { getFilesMenu } from "./AssignPages";
import { invoiceInputFields } from "../redux/definitions";
import {
  addInvoiceObject,
  setViewedFile,
  setInvoiceAssignPageField,
  removeInvoiceObject
} from "../redux/actionCreators";
import { isFilePDFType, getFileNameWithoutExtension } from "../redux/helpers";
import BackupFilePreview from "../BackupFilePreview";

const useStyles = makeStyles(() => ({
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  columnContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center"
  },
  tableCell: {
    alignSelf: "center",
    wordWrap: "break-word"
  },
  tableHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  }
}));

export default function AssignInvoicePagesTable() {
  const db = useDb();

  const classes = useStyles();

  const dispatch = useDispatch();
  const { showRightDrawer } = useOpenClose();

  const openFilePreview = fileId => () => {
    dispatch(setViewedFile(fileId));
    showRightDrawer(<BackupFilePreview />);
  };

  const { newInvoices, newFiles } = useSelector(
    (state: RootState) => state.uploadBackup
  );

  function getAssignInvoicePagesHeader(): JSX.Element {
    return (
      <div className={classes.tableHeader}>
        Assign Invoices to Files
        <IconButton
          tooltip="Add new invoice"
          onClick={() => dispatch(addInvoiceObject())}
          size="large">
          <AddBoxIcon />
        </IconButton>
      </div>
    );
  }

  function getAssignInvoicePagesData() {
    const invoiceNumbersColumn = newInvoices.map((invoiceObj, invoiceIdx) => (
      <TextField
        id={invoiceInputFields.INVOICE_NUMBER}
        key={`invoiceNumber_${invoiceIdx}`}
        value={invoiceObj.invoiceNumber}
        fullWidth
        onChange={e =>
          dispatch(
            setInvoiceAssignPageField(
              invoiceIdx,
              invoiceInputFields.INVOICE_NUMBER,
              e.target.value,
              db
            )
          )
        }
      />
    ));

    const filesColumn = newInvoices.map((invoiceObj, invoiceIdx) => (
      <Select
        id={invoiceInputFields.FILE_NAME}
        key={`file_${invoiceIdx}`}
        value={invoiceObj.originalFile.fileName}
        fullWidth
        onChange={e => {
          dispatch(
            setInvoiceAssignPageField(
              invoiceIdx,
              invoiceInputFields.FILE_NAME,
              e.target.value,
              db
            )
          );

          // Auto-set invoice number if not already exists
          if (!newInvoices[invoiceIdx][invoiceInputFields.INVOICE_NUMBER]) {
            const fileName =
              typeof e.target.value === "string" ? e.target.value : "";
            const fileNameWithoutExtension =
              getFileNameWithoutExtension(fileName);
            dispatch(
              setInvoiceAssignPageField(
                invoiceIdx,
                invoiceInputFields.INVOICE_NUMBER,
                fileNameWithoutExtension,
                db
              )
            );
          }
        }}>
        {getFilesMenu(newFiles)}
      </Select>
    ));

    const startPagesColumn = newInvoices.map((invoiceObj, invoiceIdx) => {
      const disabled = !isFilePDFType(invoiceObj.originalFile.fileName || "");

      const textField = (
        <TextField
          id={invoiceInputFields.START_PAGE}
          key={`startPage_${invoiceIdx}`}
          value={invoiceObj.originalFile.startPage}
          fullWidth
          disabled={disabled}
          onChange={e =>
            dispatch(
              setInvoiceAssignPageField(
                invoiceIdx,
                invoiceInputFields.START_PAGE,
                e.target.value,
                db
              )
            )
          }
        />
      );

      return disabled ? (
        <Tooltip title="Must be PDF file to choose page range">
          <span>{textField}</span>
        </Tooltip>
      ) : (
        textField
      );
    });

    const endPagesColumn = newInvoices.map((invoiceObj, invoiceIdx) => {
      const disabled = !isFilePDFType(invoiceObj.originalFile.fileName || "");

      const textField = (
        <TextField
          id={invoiceInputFields.END_PAGE}
          key={`endPage_${invoiceIdx}`}
          value={invoiceObj.originalFile.endPage}
          fullWidth
          disabled={disabled}
          onChange={e =>
            dispatch(
              setInvoiceAssignPageField(
                invoiceIdx,
                invoiceInputFields.END_PAGE,
                e.target.value,
                db
              )
            )
          }
        />
      );

      return disabled ? (
        <Tooltip title="Must be PDF file to choose page range">
          <span>{textField}</span>
        </Tooltip>
      ) : (
        textField
      );
    });

    const actionsColumn = newInvoices.map((invoiceObj, invoiceIdx) => {
      const invoiceFileName = invoiceObj.originalFile.fileName;
      const fileIdx =
        invoiceFileName !== null
          ? newFiles.map(file => file.name).indexOf(invoiceFileName)
          : -1;

      return (
        <div className={classes.rowContainer} key={`actions_${invoiceIdx}`}>
          <IconButton
            tooltip="Preview Assigned File"
            disabled={fileIdx === -1}
            onClick={openFilePreview(fileIdx)}
            size="large">
            <VisibilityIcon />
          </IconButton>
          <IconButton
            tooltip={
              newInvoices.length === 1
                ? "Cannot delete only invoice"
                : "Delete Invoice"
            }
            disabled={newInvoices.length === 1}
            onClick={() => dispatch(removeInvoiceObject(invoiceIdx))}
            size="large">
            <ClearIcon />
          </IconButton>
        </div>
      );
    });

    return {
      File: filesColumn,
      "Invoice #": invoiceNumbersColumn,
      "Start Page": startPagesColumn,
      "End Page": endPagesColumn,
      Actions: actionsColumn
    };
  }

  return (
    <DataTableScrollable
      title={getAssignInvoicePagesHeader()}
      data={getAssignInvoicePagesData()}
      widths={["25%", "25%", "15%", "15%", "15%"]}
      height={380}
    />
  );
}
