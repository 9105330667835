import { invoiceLineStatuses } from "components/Deductions/constants/ReconciliationStatuses";
import {
  ERPTransactionObject,
  InvoiceLineObject,
  InvoiceObject
} from "components/Deductions/models";
import { Customer, FundType, Product } from "js/dbTypes";
import { DisplayInvoiceLineObject } from "../../types/invoiceLineTypes";
import { DisplayInvoiceObject } from "../../types/invoiceTypes";
import { getAllInvoiceLineAmounts } from "./invoiceLineAmount";

export const HumanReadableInvoiceLineStatuses: Record<
  invoiceLineStatuses,
  string
> = {
  [invoiceLineStatuses.PENDING]: "Pending",
  [invoiceLineStatuses.MATCH_CONFIRMED]: "Match Confirmed",
  [invoiceLineStatuses.MATCH_NOT_FOUND]: "Match Not Found",
  [invoiceLineStatuses.SALES_REVIEW]: "Sales Review",
  [invoiceLineStatuses.DISPUTE]: "Dispute",
  [invoiceLineStatuses.CLEARED]: "Cleared"
};

export function processInvoiceLineForDisplay(
  invoice: InvoiceObject | DisplayInvoiceObject,
  invoiceLine: InvoiceLineObject,
  invoiceLineKey: string,
  fundTypes: Record<string, FundType>,
  invoices: Record<string, InvoiceObject>,
  erpTransactions: Record<string, ERPTransactionObject>,
  allLines: any,
  products: Record<string, Product>,
  customers: Record<string, Customer>
): DisplayInvoiceLineObject {
  const fundType =
    (fundTypes || {})[
      allLines[invoiceLine.matchedPromLine || ""]?.type ||
        invoiceLine.suggestedFundType
    ]?.name || "";

  const displayStatus = HumanReadableInvoiceLineStatuses[invoiceLine.status];

  const customerName = invoiceLine.customerKey
    ? customers[invoiceLine.customerKey]?.name || "Unknown"
    : "";

  const productName = invoiceLine.productKey
    ? products[invoiceLine.productKey]?.name || "Unknown"
    : "";

  const sign = invoiceLine.amount > 0 ? 1 : -1;

  const allInvoiceLineAmounts = getAllInvoiceLineAmounts(
    invoiceLine,
    invoice,
    invoices,
    erpTransactions
  );
  const { openAmount, resolvedAmount } = allInvoiceLineAmounts;

  return {
    ...invoiceLine,
    key: invoiceLineKey,
    invoiceLineKey,
    fundType,
    displayStatus,
    customerName,
    productName,
    ...allInvoiceLineAmounts,
    openAmount: sign * openAmount,
    resolvedAmount: sign * resolvedAmount
  };
}
