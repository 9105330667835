import { TransactionDisplaySigns } from "components/Deductions/constants/ReconciliationDisplay";
import {
  ERPTransactionObject,
  InvoiceObject
} from "components/Deductions/models";
import { deductionTransactionStatuses } from "deductions-constants/ReconciliationStatuses";
import { ERPTransactionTypes } from "deductions-constants/ReconciliationTypes";
import { Customer, CompanyUser } from "js/dbTypes";
import { TransactionAmountService } from "reconciliation-services";
import { DisplayERPTransactionObject } from "reconciliation-types/transactionTypes";

export const HumanReadableTransactionStatuses: Record<
  deductionTransactionStatuses,
  string
> = {
  [deductionTransactionStatuses.CANCELLED]: "Cancelled",
  [deductionTransactionStatuses.PENDING]: "Pending",
  [deductionTransactionStatuses.NEEDS_ATTENTION]: "Needs Attention",
  [deductionTransactionStatuses.NEW]: "New",
  [deductionTransactionStatuses.RESOLVED]: "Resolved"
};

export const HumanReadableTransactionTypes: Record<
  ERPTransactionTypes,
  string
> = {
  [ERPTransactionTypes.DEDUCTION]: "Deduction",
  [ERPTransactionTypes.BILLPAY]: "Billpay",
  [ERPTransactionTypes.UNKNOWN]: "Unknown",
  [ERPTransactionTypes.REPAYMENT]: "Repayment"
};

export function processTransactionForDisplay(
  transaction: ERPTransactionObject,
  invoices: Record<string, InvoiceObject>,
  erpTransactions: Record<string, ERPTransactionObject>,
  customers: Record<string, Customer>,
  companyUsers: Record<string, CompanyUser>
): DisplayERPTransactionObject {
  const { status, type, assignedUser, customerKey } = transaction;

  const customerName = customerKey
    ? customers[customerKey]?.name || "Unknown"
    : "";

  const displayAssignedUser = assignedUser
    ? companyUsers[assignedUser]?.name
    : "";

  const allTransactionAmounts =
    TransactionAmountService.getAllTransactionAmounts(
      transaction,
      invoices,
      erpTransactions
    );

  const { openAmount, resolvedAmount } = allTransactionAmounts;

  return {
    ...transaction,
    customerName,
    displayStatus: HumanReadableTransactionStatuses[status],
    displayType: HumanReadableTransactionTypes[type],
    displayAssignedUser,
    ...allTransactionAmounts,
    openAmount: TransactionDisplaySigns[type] * openAmount,
    resolvedAmount: TransactionDisplaySigns[type] * resolvedAmount
  };
}
