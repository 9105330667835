import FileSaver from "file-saver";
import React from "react";

import { isEqual } from "lodash-es";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Button from "ui-library/Button";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import MenuItem from "ui-library/MenuItem";
import Subheader from "ui-library/Subheader";
import Select from "ui-library/Select";
import Stack from "@mui/material/Stack";

import {
  downloadFilesFromStorage,
  removeFilesFromStorage,
  updateFirebase,
  uploadFilesToStorage,
  firebase
} from "helpers/Firebase";
import Mixpanel from "helpers/Mixpanel";
import { common, grey } from "@mui/material/colors";
import FilePicker from "../WebsiteElements/FilePicker";

const { black } = common;
const grey500 = grey["500"];

const MAX_FILE_SIZE = 20 << 20;

const styles = {
  superheader: {
    fontFamily: "Ubuntu",
    marginTop: 20
  },
  header: {
    marginRight: "-50px",
    fontSize: 20,
    color: black
  },
  subheader: {
    marginLeft: "-15px",
    marginRight: "-50px",
    fontSize: 20
  },
  divider: {
    margin: "30px auto",
    width: "50%"
  },
  paper: {
    padding: 16,
    marginBottom: 5,
    "white-space": "pre-line"
  }
};

class ManagePromFiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prom: {},
      promKey: "",
      fileInfo: [],
      currentFileName: "",
      currentFileIndex: 0,
      stagedFiles: {},
      downloadingSelectedFile: false
    };
  }

  addedfile = files => {
    const { stagedFiles } = this.state;
    for (const file of files) {
      stagedFiles[file.name] = file;
    }
    this.setState({ stagedFiles });
  };

  deletefile = file => {
    const { stagedFiles } = this.state;
    delete stagedFiles[file.name];
    this.setState({ stagedFiles });
  };

  downloadFile = () => {
    const {
      state: { currentFileName, fileInfo },
      props: { proposed }
    } = this;
    if (proposed) {
      const [file] = fileInfo.filter(({ name }) => name === currentFileName);
      FileSaver.saveAs(file.file, file.name);
    } else {
      const user = firebase.auth().currentUser;
      let fileContainingKey = this.state.promKey;
      if (this.state.prom.duplicatedFrom) {
        fileContainingKey = this.state.prom.duplicatedFrom.promKey;
      }
      this.setState({
        downloadingSelectedFile: true
      });

      firebase
        .database()
        .ref(`users/${user.uid}/company_id`)
        .once("value", snapshot => {
          if (snapshot.val()) {
            const company_id = snapshot.val();
            const currentFilePath = `${company_id}/Promotion Files/${fileContainingKey}/${currentFileName}`;
            downloadFilesFromStorage(
              [currentFilePath],
              [currentFileName],
              (blob, name) => {
                this.setState({
                  downloadingSelectedFile: false
                });
                FileSaver.saveAs(blob, name);
              },
              null
            );
          }
        });
    }
    Mixpanel.track("Attachment", { Action: "Download" });
  };

  deleteFile = () => {
    const { proposed } = this.props;
    if (proposed) {
      var newProm = this.state.prom;
      newProm.fileInfo = newProm.fileInfo.filter(
        ({ name }) => name !== this.state.currentFileName
      );

      var newFileIndex = Math.max(
        0,
        Math.min(this.state.currentFileIndex, newProm.fileInfo.length - 1)
      );
      this.setState({
        currentFileIndex: newFileIndex,
        currentFileName: newProm.fileInfo.length
          ? newProm.fileInfo[newFileIndex].name
          : "",
        fileInfo: newProm.fileInfo
      });
    } else {
      const currentFilePath = `${this.props.db.companyid}/Promotion Files/${this.state.promKey}/${this.state.currentFileName}`;
      removeFilesFromStorage([currentFilePath]);

      var newProm = this.state.prom;
      newProm.fileInfo = newProm.fileInfo.filter(
        ({ name }) => name !== this.state.currentFileName
      );

      var newFileIndex = Math.max(
        0,
        Math.min(this.state.currentFileIndex, newProm.fileInfo.length - 1)
      );
      this.setState({
        currentFileIndex: newFileIndex,
        currentFileName: newProm.fileInfo.length
          ? newProm.fileInfo[newFileIndex].name
          : "",
        fileInfo: newProm.fileInfo
      });

      updateFirebase(
        0,
        newProm,
        this.state.promKey,
        () => {
          this.props.openClose.showSnack("File deleted!");
        },
        error => {
          // Don't show an error for this for now.
          // this.props.openClose.showSnack("Error deleting file, please try again!");
        }
      );
    }
    Mixpanel.track("Attachment", { Action: "Delete" });
  };

  handleChange = (event, index, value) => {
    const currentFileName = value;
    this.setState({
      currentFileIndex: value,
      currentFileName: this.state.prom.fileInfo[value].name
    });
  };

  uploadStagedFiles = () => {
    const { stagedFiles } = this.state;
    const stagedFileNames = Object.keys(stagedFiles);
    Mixpanel.track("Attachment Uploaded", { Quantity: stagedFileNames.length });

    if (!stagedFiles || stagedFileNames.length == 0) {
      return;
    }

    const user = firebase.auth().currentUser;
    const newProm = this.state.prom;
    newProm.fileInfo = Array.from(newProm.fileInfo || []);
    for (let i = 0; i < stagedFileNames.length; i++) {
      if (!newProm.fileInfo.map(x => x.name).includes(stagedFileNames[i])) {
        newProm.fileInfo.push({
          name: stagedFileNames[i],
          isContract: false,
          attachedLine: [0]
        });
      }
    }
    // newProm.fileNames = Array.from(
    //   new Set(newProm.fileNames.concat(stagedFileNames))
    // );

    this.setState({ uploading: true }, () => {
      firebase
        .database()
        .ref(`users/${user.uid}/company_id`)
        .once("value", snapshot => {
          if (snapshot.val()) {
            const company_id = snapshot.val();
            const filePaths = stagedFileNames.map(
              item =>
                `${company_id}/Promotion Files/${this.state.promKey}/${item}`
            );
            const fileBlobs = stagedFileNames.map(key => stagedFiles[key]);
            uploadFilesToStorage(filePaths, fileBlobs, () => {
              updateFirebase(
                0,
                newProm,
                this.state.promKey,
                () => {
                  this.props.openClose.showSnack("Upload complete!");
                  const newIndex = this.state.currentFileIndex || 0;
                  this.setState({
                    currentFileName: newProm.fileInfo[newIndex].name,
                    currentFileIndex: newIndex,
                    fileInfo: newProm.fileInfo
                  });
                },
                null,
                error => {
                  this.props.openClose.showSnack(
                    "An error occurred while uploading files, please try again!"
                  );
                }
              );
              this.setState({ uploading: false, stagedFiles: {} });
            });
          }
        });
    });
  };

  attachFilesToProposedProm = () => {
    this.setState({ uploading: true }, () => {
      const { stagedFiles, prom } = this.state;
      const newFiles = Object.values(stagedFiles).map(file => ({
        file,
        name: file.name,
        isContract: false,
        attachedLine: [0]
      }));
      prom.fileInfo.push(...newFiles);
      this.setState(
        {
          stagedFiles: {},
          prom,
          currentFileName: newFiles[0]?.name,
          fileInfo: prom.fileInfo,
          uploading: false
        },
        () => {
          this.props.openClose.showSnack("Attached files!");
        }
      );
    });
  };

  componentDidMount() {
    if (!this.state.promKey) {
      // only fire on initial load
      this.setState({ promKey: this.props.promKey, prom: this.props.prom });
      if (this.props.prom.fileInfo && this.props.prom.fileInfo.length > 0) {
        this.setState({
          fileInfo: this.props.prom.fileInfo,
          currentFileName: this.props.prom.fileInfo[0].name,
          currentFileIndex: 0
        });
      } else {
        this.setState({ fileInfo: [], currentFileName: "" });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.promKey, this.state.promKey)) {
      this.setState({ promKey: nextProps.promKey, prom: nextProps.prom });
      if (nextProps.prom.fileInfo && nextProps.prom.fileInfo.length > 0) {
        this.setState({
          fileInfo: nextProps.prom.fileInfo,
          currentFileName: nextProps.prom.fileInfo[0].name,
          currentFileIndex: 0
        });
      } else {
        this.setState({ fileInfo: [], currentFileName: "" });
      }
    }
  }

  render() {
    const productGroups = this.props.db.meta.product_groups;
    return (
      <div style={{ color: grey500, margin: 16 }}>
        <div className="rowC">
          <Subheader>Upload New Files</Subheader>
        </div>
        <div>
          <FilePicker
            message="Drop files here to upload (5 files max, up to 20 MB in size each)"
            onChange={this.addedfile}
            onDelete={this.deletefile}
            promotionUpload={false}
            filesLimit={5}
            maxFileSize={MAX_FILE_SIZE}
            loading={this.state.uploading}
          />
          <br />
          <div className="centering">
            {!this.props.proposed ? (
              <Button
                label={this.state.uploading ? "Uploading..." : "Upload Files"}
                primary
                onClick={this.uploadStagedFiles}
                icon={<AttachFileIcon />}
                disabled={
                  (!this.props.readOnly &&
                    (!this.props.db.permissions.includes("masteredit") ||
                      this.props.proposed)) ||
                  this.state.uploading ||
                  Object.keys(this.state.stagedFiles).length === 0 ||
                  this.state.fileInfo.length +
                    Object.keys(this.state.stagedFiles).length >
                    5
                }
              />
            ) : (
              <Button
                label={this.state.uploading ? "Attaching..." : "Attach Files"}
                primary
                onClick={this.attachFilesToProposedProm}
                style={{ width: "100%", marginTop: -16 }}
                icon={<AttachFileIcon />}
                disabled={
                  (!this.props.readOnly &&
                    !this.props.db.permissions.includes("masteredit")) ||
                  this.state.uploading ||
                  Object.keys(this.state.stagedFiles).length === 0 ||
                  this.state.fileInfo.length +
                    Object.keys(this.state.stagedFiles).length >
                    5
                }
              />
            )}
          </div>
        </div>
        <div className="rowC">
          <Subheader>Download And Remove Files</Subheader>
        </div>
        <div>
          {this.state.fileInfo.length > 0 ? (
            <div>
              <Stack justifyContent="center">
                <Select
                  value={this.state.currentFileIndex || 0}
                  onChange={this.handleChange.bind(null)}>
                  {this.state.fileInfo.map((item, index) => (
                    <MenuItem key={index} value={index} children={item.name} />
                  ))}
                </Select>
                <Button
                  label={
                    this.state.downloadingSelectedFile
                      ? "Downloading..."
                      : "Download File"
                  }
                  onClick={this.downloadFile.bind(null)}
                  icon={
                    this.state.downloadingSelectedFile ? (
                      ""
                    ) : (
                      <CloudDownloadIcon />
                    )
                  }
                  disabled={this.state.downloadingSelectedFile}
                />
                <Button
                  label="Delete File"
                  onClick={this.deleteFile.bind(null)}
                  icon={<DeleteForeverIcon />}
                  disabled={
                    !this.props.readOnly &&
                    !this.props.db.permissions.includes("edit")
                  }
                />
              </Stack>
              <br />
              <Stack justifyContent="center">
                <Select
                  floatingLabelText="Is contract?"
                  value={Boolean(
                    this.state.prom.fileInfo[this.state.currentFileIndex]
                      ?.isContract
                  )}
                  onChange={(event, index, value) => {
                    const isInputChecked = value;
                    const newProm = this.state.prom;
                    newProm.fileInfo[this.state.currentFileIndex].isContract =
                      isInputChecked;
                    this.setState({ prom: newProm }, () => {
                      !this.props.proposed &&
                        updateFirebase(0, this.state.prom, this.state.promKey);
                    });
                  }}
                  disabled={!this.props.db.permissions.includes("masteredit")}>
                  <MenuItem value children="Yes" />
                  <MenuItem value={false} children="No" />
                </Select>
                <Select
                  floatingLabelText="Associate attachment with"
                  value={
                    this.state.prom.fileInfo[this.state.currentFileIndex]
                      ? this.state.prom.fileInfo[this.state.currentFileIndex]
                          .attachedLine
                      : []
                  }
                  onChange={(event, index, value) => {
                    const prevAttached =
                      this.state.prom.fileInfo[this.state.currentFileIndex]
                        .attachedLine;
                    // SINGLE-SELECTION
                    // if (!value || value.length == 0) {
                    //   value = [0];
                    // } else {
                    //   if (value[0] == prevAttached[0]) value = [value[1]];
                    //   else value = [value[0]];
                    // }
                    // MULTI-SELECTION
                    if (
                      !value ||
                      value.length == 0 ||
                      (!prevAttached.includes(0) && value.includes(0))
                    ) {
                      value = [0];
                    } else if (
                      value.length > 1 &&
                      value.includes(0) &&
                      prevAttached.includes(0)
                    ) {
                      value.splice(value.indexOf(0), 1);
                    }
                    const newProm = this.state.prom;
                    newProm.fileInfo[this.state.currentFileIndex].attachedLine =
                      value;
                    this.setState({ prom: newProm }, () => {
                      !this.props.proposed &&
                        updateFirebase(0, this.state.prom, this.state.promKey);
                    });
                  }}
                  multiple>
                  {[
                    <MenuItem key={0} value={0} children="Entire Promotion" />
                  ].concat(
                    this.state.prom.lines &&
                      Object.keys(this.state.prom.lines).map((item, index) => (
                        <MenuItem
                          key={index + 1}
                          value={index + 1}
                          children={`${item} - ${
                            this.props.db.meta.fundTypes?.[
                              this.state.prom.lines[item].type
                            ]?.name
                          }, ${
                            this.state.prom.lines[item].productGroup in
                            productGroups
                              ? productGroups[
                                  this.state.prom.lines[item].productGroup
                                ].name
                              : "Custom"
                          }`}
                        />
                      ))
                  )}
                </Select>
              </Stack>
            </div>
          ) : (
            <div className="centering">
              No Files Associated with this Promotion.
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ManagePromFiles;
