import React from "react";

import SearchIcon from "@mui/icons-material/Search";
import ConfirmIcon from "@mui/icons-material/CheckCircle";
import ListItemText from "@mui/material/ListItemText";

import Autocomplete from "ui-library/Autocomplete";
import Button from "ui-library/Button";
import MenuItem from "ui-library/MenuItem";
import Select from "ui-library/Select";
import TextField from "ui-library/TextField";

import { batchEditPromotions, getJSXListsFirebase } from "helpers/Firebase";
import { getTimesJSX } from "helpers/Time";
import { infoDialog } from "helpers/OpenDialog";

import { common, red } from "@mui/material/colors";
import { Stack } from "@mui/material";

const red400 = red["400"];

const { black } = common;

const styles = {
  subheader: {
    fontSize: 20,
    color: "primary.main"
  },
  divider: {
    margin: "30px auto",
    width: "100%",
    color: black
  }
};

const validFields = [
  "showPromKey",
  "showName",
  "showCustomerName",
  "showDistributor",
  "showType",
  "showStatus",
  "showAttachments",
  "showStarred",
  "showMonth",
  "showYear"
];

class AdvancedEditPromotions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchProm: this.addStatuses({
        startMonth: new Date().getMonth(),
        startYear: new Date().getFullYear(),
        endMonth: new Date().getMonth(),
        endYear: new Date().getFullYear()
      }),
      editProm: { distributor: [] },
      promKey: "",
      customers: [],
      distributors: [],
      products: [],
      productGroups: [],
      errorText: "",
      searchCustomer: "",
      searchDistributor: "",
      editCustomer: ""
    };
  }

  addStatuses = searchProm => {
    if (this.props.db.access !== "Admin") {
      searchProm.status = [
        this.props.db.meta.statuses.indexOf("Pending"),
        this.props.db.meta.statuses.indexOf("Submitted"),
        this.props.db.meta.statuses.indexOf("Approved"),
        this.props.db.meta.statuses.indexOf("Finalized"),
        this.props.db.meta.statuses.indexOf("Declined"),
        this.props.db.meta.statuses.indexOf("Cancelled")
      ];
    }
    return searchProm;
  };

  handleTextChange = (field, event) => {
    const newSearchProm = this.state.searchProm;
    newSearchProm[field] = event.target.value;
    this.setState({
      searchProm: newSearchProm
    });
  };

  getCustomerId = name => {
    for (const c in this.state.customers) {
      if (this.state.customers[c].text === name) {
        return this.state.customers[c].value;
      }
    }
  };

  modifySearchProm = (field, event, value) => {
    const newSearchProm = this.state.searchProm;
    if (!value) delete newSearchProm[field];
    else if (field === "customer") {
      newSearchProm.customer = this.getCustomerId(value);
      newSearchProm.customerName = value;
    } else {
      newSearchProm[field] = value;
    }
    this.setState({
      searchProm: newSearchProm
    });
  };

  modifyEditProm = (field, event, value) => {
    const newEditProm = this.state.editProm;
    if (field === "customer") {
      newEditProm.customer = this.getCustomerId(value);
      newEditProm.customerName = value;
      newEditProm.distributor = [];
    } else {
      newEditProm[field] = value;
    }
    this.setState({
      editText: value?.text ?? value,
      editProm: newEditProm
    });
  };

  handleSelectChangeSearch = (field, event, index, value) => {
    const newSearchProm = this.state.searchProm;
    newSearchProm[field] = value;
    this.setState({
      searchProm: newSearchProm
    });
  };

  handleSelectChangeEdit = (field, event, index, value) => {
    const newEditProm = this.state.editProm;
    newEditProm[field] = value;
    this.setState({
      editProm: newEditProm
    });
  };

  handleDateChange = (field, event, value) => {
    const newSearchProm = this.state.searchProm;
    newSearchProm[field] = value;
    this.setState({
      searchProm: newSearchProm
    });
  };

  editProms = () => {
    this.setState({ errorText: "" });
    const selectedProms = this.props.getSelectedProms();
    const promSet = new Set(selectedProms);
    const proms = [];
    for (const [promKey, prom] of Object.entries(this.props.db.promotions)) {
      if (promSet.has(promKey)) {
        proms.push(prom);
      }
    }
    batchEditPromotions(selectedProms, proms, this.state.editProm);
  };

  handleReplace = () => {
    if (
      !this.state.editProm?.customer ||
      !this.state.editProm?.distributor?.length
    ) {
      infoDialog(
        this.props.openClose,
        "Error",
        "Please select a customer and one or more first receivers.",
        "Go Back"
      );
      return;
    }

    const selectedProms = this.props.getSelectedProms();
    const totalToBeEdited = selectedProms.length;
    if (Object.keys(this.state.editProm).length > 0) {
      this.props.openClose.setAppModal(
        selectedProms.length === 0 ? "Error" : "Confirm Promotion Edits",
        <div className="centering">
          {selectedProms.length === 0
            ? "You did not select any promotions to edit"
            : `You are about to edit ${totalToBeEdited} promotion(s). Please confirm to continue.`}
        </div>,
        <div>
          <Button
            label="Confirm"
            onClick={() => {
              this.editProms();
              this.props.openClose.hideRightDrawer();
              this.props.openClose.closeAppModal();
            }}
          />
        </div>
      );
    } else {
      this.setState({ errorText: "You must select a field to edit" });
    }
  };

  resetFields = () => {
    this.setState({
      searchProm: this.addStatuses({
        startMonth: new Date().getMonth(),
        startYear: new Date().getFullYear(),
        endMonth: new Date().getMonth(),
        endYear: new Date().getFullYear()
      }),
      editProm: { distributor: [] },
      errorText: "",
      searchCustomer: "",
      searchDistributor: "",
      editCustomer: ""
    });
  };

  getCustomersAndDistributors = customers => {
    const customerFields = [];
    const distributorFields = [];
    for (const [key, customer] of Object.entries(customers)) {
      customerFields.push({ text: customer.name, value: key });
      if (customer.isDistributor) {
        distributorFields.push(customer.name);
      }
    }
    this.setState({
      customers: customerFields,
      distributors: distributorFields
    });
  };

  getCustomerDistributors = customerKey => {
    const selectDistributors = [];
    const customer = this.props.db.customers[customerKey];
    const { distributors } = customer;
    if (customer.isDistributor || customer.isDirect || !distributors) {
      selectDistributors.push(customerKey);
    }
    if (!customer.isDistributor && distributors) {
      for (let i = 0; i < distributors.length; i++) {
        const distributor = distributors[i];
        selectDistributors.push(distributor);
      }
    }

    return selectDistributors;
  };

  getProductsAndGroups = (products, pgs) => {
    let productNames = [];
    let productGroupNames = ["Custom"];
    for (const [key, product] of Object.entries(products)) {
      productNames.push(product.name);
    }
    for (const [key, pg] of Object.entries(pgs)) {
      productGroupNames.push(pg.name);
    }
    productNames = Array.from(new Set(productNames));
    productGroupNames = Array.from(new Set(productGroupNames));
    this.setState({ products: productNames, productGroups: productGroupNames });
  };

  // Determine whether to show only certain search fields set in props.
  // True if this.props[k] is true for any k in validFields.
  showSetFields = () => {
    for (let i = 0; i < validFields.length; i++) {
      if (this.props[validFields[i]]) return true;
    }
    return false;
  };

  componentDidMount() {
    this.props.handleFind(this.state.searchProm);
    getJSXListsFirebase(this.props.db, allJSX => {
      this.setState({
        meta: this.props.db.meta,
        statusesJSX: allJSX.statusesJSX
      });
    });

    const timesJSX = getTimesJSX(this.props.db.meta, true);
    this.setState({
      monthsJSX: timesJSX[0],
      yearsJSX: timesJSX[1]
    });
    this.getCustomersAndDistributors(this.props.db.customers);
    this.getProductsAndGroups(
      this.props.db.products,
      this.props.db.meta.product_groups
    );
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    const showSetFields = this.showSetFields();
    let distributors = [];
    if (this.state.editProm.customer) {
      distributors = this.getCustomerDistributors(this.state.editProm.customer);
    }
    return (
      <div key={this.props.contactKey}>
        <div style={{ marginLeft: "16px", marginRight: "16px" }}>
          <br />
          <ListItemText
            disableTypography
            sx={styles.subheader}
            primary="Find"
          />
          {(this.props.showName || !showSetFields) && (
            <TextField
              value={this.state.searchProm.name || ""}
              onChange={this.handleTextChange.bind(null, "name")}
              floatingLabelText="Name"
              fullWidth
            />
          )}
          <br />
          <div className="rowC">
            {(this.props.showCustomerName || !showSetFields) && (
              <Autocomplete
                options={this.state.customers.map(c => c.text).sort()}
                inputValue={this.state.searchCustomer}
                onInputChange={(event, value) =>
                  this.setState({ searchCustomer: value })
                }
                value={this.state.searchProm.customerName}
                onChange={this.modifySearchProm.bind(null, "customer")}
                openOnFocus
                floatingLabelText="Customer"
                style={{ width: "49.5%", maxHeight: 300 }}
              />
            )}
            {(this.props.showDistributor || !showSetFields) && (
              <Autocomplete
                options={this.state.distributors}
                inputValue={this.state.searchDistributor}
                onInputChange={(event, value) =>
                  this.setState({ searchDistributor: value })
                }
                value={this.state.searchProm?.distributor}
                onChange={this.modifySearchProm.bind(null, "distributor")}
                openOnFocus
                floatingLabelText="First Receiver"
                style={{ width: "49.5%", marginLeft: 16, maxHeight: 300 }}
              />
            )}
          </div>
          <br />
          <div className="rowC">
            <Select
              floatingLabelText="Start Month"
              value={this.state.searchProm.startMonth}
              onChange={this.handleSelectChangeSearch.bind(null, "startMonth")}
              fullWidth>
              {this.state.monthsJSX}
            </Select>
            <Select
              floatingLabelText="Start Year"
              value={this.state.searchProm.startYear}
              onChange={this.handleSelectChangeSearch.bind(null, "startYear")}
              fullWidth
              style={{ marginLeft: 16 }}>
              {this.state.yearsJSX}
            </Select>
          </div>
          <br />
          <div className="rowC">
            <Select
              floatingLabelText="End Month"
              value={this.state.searchProm.endMonth}
              onChange={this.handleSelectChangeSearch.bind(null, "endMonth")}
              fullWidth>
              {this.state.monthsJSX}
            </Select>
            <Select
              floatingLabelText="End Year"
              value={this.state.searchProm.endYear}
              onChange={this.handleSelectChangeSearch.bind(null, "endYear")}
              fullWidth
              style={{ marginLeft: 16 }}>
              {this.state.yearsJSX}
            </Select>
          </div>
          <br />
          <ListItemText
            disableTypography
            sx={styles.subheader}
            primary="Replace"
          />
          <div className="rowC">
            {(this.props.showCustomerName || !showSetFields) && (
              <Autocomplete
                options={this.state.customers.map(c => c.text).sort()}
                inputValue={this.state.editCustomer}
                onInputChange={(event, value) =>
                  this.setState({ editCustomer: value })
                }
                value={this.state.editProm.customerName}
                onChange={this.modifyEditProm.bind(null, "customer")}
                openOnFocus
                floatingLabelText="Customer"
                style={{ width: "49.5%", maxHeight: 300 }}
              />
            )}
            {(this.props.showDistributor || !showSetFields) && (
              <Select
                multiple
                value={this.state.editProm.distributor}
                onChange={this.handleSelectChangeEdit.bind(null, "distributor")}
                floatingLabelText="First Receiver"
                style={{ width: "49.5%", marginLeft: 16, maxHeight: 300 }}
                disabled={!this.state.editProm.customer}>
                {distributors.map(distributor => (
                  <MenuItem
                    value={this.props.db.customers[distributor].name}
                    children={this.props.db.customers[distributor].name}
                    key={distributor}
                    checked={
                      this.state.editProm.distributor &&
                      this.state.editProm.distributor.includes(
                        this.props.db.customers[distributor].name
                      )
                    }
                  />
                ))}
              </Select>
            )}
          </div>
          <br />
          <div className="centering">
            <Button
              label="Reset Fields"
              variant="text"
              color="error"
              onClick={this.resetFields}
            />
          </div>
          <br />
          <div className="centering" style={{ color: red400 }}>
            {this.state.errorText}
          </div>
          <br />
          <Stack justifyContent="center">
            <Button
              label="Find"
              icon={<SearchIcon />}
              onClick={() => {
                this.props.handleFind(this.state.searchProm);
              }}
            />
            <Button
              label="Replace"
              icon={<ConfirmIcon />}
              onClick={this.handleReplace}
            />
          </Stack>
        </div>
      </div>
    );
  }
}

export default AdvancedEditPromotions;
