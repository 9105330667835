import {
  ERPTransactionObject,
  InvoiceObject
} from "components/Deductions/models";
import {
  makeKeys,
  firebase,
  addFirebaseTS,
  CATEGORIESMAP,
  updateFirebaseTS
} from "helpers/Firebase";
import { DisplayERPTransactionObject } from "components/Deductions/DeductionsReconciliation/types/transactionTypes";
import {
  deductionTransactionStatuses,
  invoiceStatuses,
  repaymentTransactionStatuses
} from "components/Deductions/constants/ReconciliationStatuses";
import { DbContextValues } from "contexts/Db";
import { TransactionAmountService } from "reconciliation-services";
import {
  ErpTransactionCriticalDuplicationError,
  ErpTransactionIdOnlyDuplicateError
} from "components/Deductions/DeductionsReconciliation/UploadFiles/AddTransaction/ErpTransactionErrorObjects";
import { DisplayInvoiceObject } from "../../types/invoiceTypes";
import { DRMEvent, DRMEventType } from "../../ActivityLog/DRMEvent";

export type duplicateTypeErrors =
  | ErpTransactionCriticalDuplicationError
  | ErpTransactionIdOnlyDuplicateError
  | undefined;

export type duplicateCheckReturnType = {
  foundDuplicate: boolean;
  duplicateType?: duplicateTypeErrors;
};

export function getCleanedTransactionObject(
  displayTransaction: DisplayERPTransactionObject | ERPTransactionObject
) {
  return {
    ...displayTransaction,
    customerName: null,

    displayType: null,
    displayStatus: null,
    displayAssignedUser: null,

    clearedAmount: null,
    totalWriteOffAmount: null,
    creditedWriteOffAmount: null,
    openWriteOffAmount: null,
    totalDisputeAmount: null,
    repaidDisputeAmount: null,
    openDisputeAmount: null,
    resolvedAmount: null,
    openAmount: null
  };
}
export const updateFirebaseTransaction = (
  displayTransaction: DisplayERPTransactionObject | ERPTransactionObject,
  callback?: () => void,
  errorCallback?: () => void
) => {
  // don't write display values to db
  const transaction = getCleanedTransactionObject(displayTransaction);
  updateFirebaseTS(
    CATEGORIESMAP.TRANSACTIONS,
    transaction,
    transaction.key,
    () => {
      if (callback) {
        callback();
      }
    },
    () => {
      if (errorCallback) {
        errorCallback();
      }
    }
  );
};

export const createFirebaseTransaction = (
  transaction: ERPTransactionObject,
  allTransactions: Record<string, ERPTransactionObject>,
  callback?: (newKey: string) => void
) => {
  let newKey = "";
  const existingKeys = Object.keys(allTransactions);
  while (!newKey || existingKeys.includes(newKey)) {
    [newKey] = makeKeys(1, null, 10);
  }

  addFirebaseTS(
    CATEGORIESMAP.TRANSACTIONS,
    {
      ...transaction,
      key: newKey,
      createdDate: new Date().toISOString(),
      createdUser: firebase.auth().currentUser?.uid || ""
    },
    () => {
      if (callback) {
        callback(newKey);
      }
    },
    newKey,
    null
  );
};

export const checkDuplicates = (
  transaction: ERPTransactionObject,
  allTransactions: Record<string, ERPTransactionObject>
): duplicateCheckReturnType => {
  const duplicateComparisonValTransformer = (trans: ERPTransactionObject) => {
    return `${trans.transactionDisplayId.trim()}-${trans.customerKey.trim()}-${trans.amount
      .toString()
      .trim()}-${trans.transactionDate?.toString().trim()}`;
  };
  const filterCancelledTransactions = (t: ERPTransactionObject) =>
    t.status !== deductionTransactionStatuses.CANCELLED &&
    t.status !== repaymentTransactionStatuses.CANCELLED;

  const transactionTransformedString =
    duplicateComparisonValTransformer(transaction);
  const allTransactionsTransformed: string[] = Object.values(allTransactions)
    .filter(filterCancelledTransactions)
    .map(t => duplicateComparisonValTransformer(t));
  if (allTransactionsTransformed.includes(transactionTransformedString)) {
    return {
      foundDuplicate: true,
      duplicateType: new ErpTransactionCriticalDuplicationError()
    };
  }

  const registeredTransactionIds: string[] = Object.values(allTransactions)
    .filter(filterCancelledTransactions)
    .map(t => t.transactionDisplayId);

  if (registeredTransactionIds.includes(transaction.transactionDisplayId)) {
    return {
      foundDuplicate: true,
      duplicateType: new ErpTransactionIdOnlyDuplicateError()
    };
  }
  return { foundDuplicate: false, duplicateType: undefined };
};

export function getUpdatedTransactionStatus(
  updatedTransaction: DisplayERPTransactionObject | ERPTransactionObject,
  erpTransactions: Record<string, ERPTransactionObject>,
  updatedInvoices:
    | Record<string, InvoiceObject>
    | Record<string, DisplayInvoiceObject>
) {
  const linkedInvoices = updatedTransaction.linkedInvoices || {};

  if (
    TransactionAmountService.getTransactionOpenAmount(
      updatedTransaction,
      updatedInvoices,
      erpTransactions
    ) === 0.0
  ) {
    return deductionTransactionStatuses.RESOLVED;
  }

  if (
    Object.values(linkedInvoices).some(invoiceKey => {
      const invoice = updatedInvoices[invoiceKey] || {};
      return invoice.status === invoiceStatuses.NEEDS_ATTENTION;
    })
  ) {
    return deductionTransactionStatuses.NEEDS_ATTENTION;
  }

  return deductionTransactionStatuses.PENDING;
}

interface AddEventType {
  ({ type, transactionKey, repaidTransactionKey }): Promise<Partial<DRMEvent>>;
}

export function attachRepaymentsToDeductions(
  repaymentKeys: string[],
  deductionKeys: string[],
  db: DbContextValues,
  addEvent: AddEventType,
  callback?: () => void,
  errorCallback?: () => void
) {
  const { erpTransactions } = db;

  // Update deductions
  deductionKeys.forEach(deductionKey => {
    const deduction = erpTransactions[deductionKey] || undefined;
    const { status } = deduction;

    const attachedRepayments = deduction.attachedRepayments || {};
    const updatedAttachedRepayments = repaymentKeys.reduce(
      (all, repaymentKey) => {
        return {
          ...all,
          [repaymentKey]: repaymentKey
        };
      },
      { ...attachedRepayments }
    );

    const updatedDeduction: ERPTransactionObject = {
      ...deduction,
      attachedRepayments: updatedAttachedRepayments,
      status:
        status === deductionTransactionStatuses.NEW
          ? deductionTransactionStatuses.PENDING
          : status
    };

    updateFirebaseTransaction(updatedDeduction, undefined, () => {
      if (errorCallback) {
        errorCallback();
      }
    });
  });

  // Update repayments
  repaymentKeys.forEach(repaymentKey => {
    const repayment = erpTransactions[repaymentKey] || undefined;

    const { status } = repayment;

    const repaidDeductions = repayment.repaidDeductions || {};
    const updatedRepaidDeductions = deductionKeys.reduce(
      (all, deductionKey) => {
        return {
          ...all,
          [deductionKey]: deductionKey
        };
      },
      { ...repaidDeductions }
    );

    const updatedRepayment: ERPTransactionObject = {
      ...repayment,
      repaidDeductions: updatedRepaidDeductions,
      status:
        status === repaymentTransactionStatuses.NEW
          ? repaymentTransactionStatuses.PENDING
          : status
    };

    updateFirebaseTransaction(updatedRepayment, undefined, () => {
      if (errorCallback) {
        errorCallback();
      }
    });
  });
  deductionKeys.forEach(deductionKey => {
    repaymentKeys.forEach(repaymentKey => {
      addEvent({
        type: DRMEventType.REPAYMENT_ATTACHED,
        transactionKey: repaymentKey,
        repaidTransactionKey: deductionKey
      });
    });
  });
  if (callback) {
    callback();
  }
}

export function unattachRepaymentFromDeduction(
  repaymentKey: string,
  deductionKey: string,
  db: DbContextValues,
  addEvent: AddEventType,
  callback?: () => void,
  errorCallback?: () => void
) {
  const { erpTransactions } = db;

  const repayment = erpTransactions[repaymentKey];
  const deduction = erpTransactions[deductionKey];

  // Remove reference from repayment
  const repaidDeductions = repayment.repaidDeductions || {};
  delete repaidDeductions[deductionKey];

  const repaymentStatus = repayment.status;

  const updatedRepayment: ERPTransactionObject = {
    ...repayment,
    repaidDeductions,
    status:
      Object.values(repaidDeductions).filter(val => val).length === 0
        ? repaymentTransactionStatuses.NEW
        : repaymentStatus
  };

  updateFirebaseTransaction(updatedRepayment, undefined, () => {
    if (errorCallback) {
      errorCallback();
    }
  });

  // Remove reference from deduction
  const attachedRepayments = deduction.attachedRepayments || {};
  delete attachedRepayments[repaymentKey];

  const deductionStatus = deduction.status;
  const linkedInvoices = deduction.linkedInvoices || {};

  const updatedDeduction: ERPTransactionObject = {
    ...deduction,
    attachedRepayments,
    status:
      Object.values(attachedRepayments).filter(val => val).length === 0 &&
      Object.values(linkedInvoices).length === 0
        ? deductionTransactionStatuses.NEW
        : deductionStatus
  };

  updateFirebaseTransaction(updatedDeduction, undefined, () => {
    if (errorCallback) {
      errorCallback();
    }
  });
  addEvent({
    type: DRMEventType.REPAYMENT_UNATTACHED,
    transactionKey: repaymentKey,
    repaidTransactionKey: deductionKey
  });

  if (callback) {
    callback();
  }
}
