import makeStyles from "@mui/styles/makeStyles";
import Chip, { ChipProps } from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import DoneIcon from "@mui/icons-material/Done";
import React from "react";
import { objToArray } from "helpers/sortByDate";

import { grey } from "@mui/material/colors";

const grey300 = grey["300"];

const baseStyles = makeStyles(theme => ({
  formControl: (props: { value: string[] }) => ({
    width: "100%",
    marginTop: props.value?.length > 0 ? "2px" : "7px"
  }),
  select: {
    paddingTop: "5px",
    "& .MuiSelect-select:focus": {
      backgroundColor: "rgba(0, 0, 0, 0)"
    }
  },
  underline: {
    "&:before": {
      borderColor: grey300
    },
    "&:hover:not($disabled):not($focused):not($error):before": {
      borderColor: grey300
    },
    "&:hover:not(.Mui-disabled):before": {
      borderColor: grey300
    }
  }
}));

interface PromotionTag {
  key?: string;
  name: string;
  description: string;
  color: string;
  textColor: string;
}

interface PromotionChipProps extends ChipProps {
  tag: PromotionTag;
}

interface PromotionSelectProps {
  tags: { [key: string]: PromotionTag };
  onChange?: (tags: string[]) => void;
  value?: string[];
  style?: React.CSSProperties;
  disabled?: boolean;
}

interface PromotionTagMenuProps {
  tags: { [key: string]: PromotionTag };
  onClick?: (tags: string) => void;
  value?: string[];
}

const styles = {
  selectChip: {
    marginRight: "4px"
  },
  color: {
    width: 14,
    height: 14,
    flexShrink: 0,
    borderRadius: 3,
    marginRight: 8,
    marginTop: 2
  },
  text: {
    flexGrow: 1
  },
  close: {
    opacity: 0.6,
    width: 18,
    height: 18
  },
  iconSelected: {
    width: 17,
    height: 17,
    marginRight: 5,
    marginLeft: -2
  }
};

const PromotionChip: React.FC<PromotionChipProps> = ({
  tag,
  style,
  ...props
}) =>
  tag ? (
    <Chip
      label={tag?.name || "Tag Preview"}
      style={{
        backgroundColor: tag?.color,
        color: tag?.textColor,
        marginLeft: "5px",
        marginRight: "5px",
        ...style
      }}
      {...props}
    />
  ) : null;

const ColorDot = ({ backgroundColor }) => (
  <span style={{ ...styles.color, backgroundColor }} />
);

const promotionTagMenu: React.FC<PromotionTagMenuProps> = ({
  tags,
  value,
  onClick
}) => {
  return objToArray(tags || {}).map(tag => (
    <MenuItem key={tag.key} value={tag.key} onClick={() => onClick?.(tag.key)}>
      <DoneIcon
        style={{
          ...styles.iconSelected,
          visibility: value?.includes(tag.key) ? "visible" : "hidden"
        }}
      />
      <ColorDot backgroundColor={tag.color} />
      <div style={styles.text}>
        <span style={{ fontWeight: 600 }}>{tag.name}</span>
        <br />
        {tag.description}
      </div>
    </MenuItem>
  ));
};

const PromotionSelect: React.FC<PromotionSelectProps> = ({
  tags,
  onChange,
  value,
  style,
  disabled
}) => {
  const baseClasses = baseStyles({ value });

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChange?.(event.target.value as string[]);
  };

  return (
    <>
      <FormControl className={baseClasses.formControl} style={style}>
        <InputLabel>Tags</InputLabel>
        <Select
          multiple
          value={value || []}
          onChange={handleChange}
          input={
            <Input
              id="select-multiple-chip"
              classes={{
                underline: baseClasses.underline
              }}
            />
          }
          className={baseClasses.select}
          disabled={disabled}
          renderValue={selected => (
            <div>
              {(selected as string[]).map(tagKey => (
                <PromotionChip
                  key={tagKey}
                  tag={tags[tagKey]}
                  style={styles.selectChip}
                  size="small"
                />
              ))}
            </div>
          )}>
          {promotionTagMenu({ tags, value })}
        </Select>
      </FormControl>
    </>
  );
};

export {
  PromotionChip,
  PromotionTag,
  PromotionSelect,
  promotionTagMenu,
  ColorDot
};
