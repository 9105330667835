import React, { forwardRef, RefObject } from "react";
import { Components } from "react-virtuoso";

export const StyledTableHead: Components["Header"] = forwardRef(
  (props, ref: RefObject<HTMLTableSectionElement>) => (
    <thead
      ref={ref}
      {...props}
      style={{ position: "sticky", top: 0, zIndex: 5 }}
    />
  )
);
