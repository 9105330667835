/* eslint-disable max-classes-per-file */
import React from "react";

import Checkbox from "ui-library/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import AssignmentIcon from "@mui/icons-material/Assignment";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import CheckIcon from "@mui/icons-material/Check";
import RemoveIcon from "@mui/icons-material/Remove";
import ClearIcon from "@mui/icons-material/Clear";
import Button from "ui-library/Button";
import IconButton from "ui-library/IconButton";
import IconMenu from "ui-library/IconMenu";
import MenuItem from "ui-library/MenuItem";
import SaveIcon from "@mui/icons-material/Save";
import SelectField from "ui-library/Select";
import Subheader from "ui-library/Subheader";
import TextField from "ui-library/TextField";
import { Tooltip } from "react-lightweight-tooltip";

import {
  isUserCresicorEmployee,
  removeCompanyFromUser,
  updateCompanyFirebase,
  updateMetaFirebase,
  firebase,
  COMPANY_EMAIL_DOMAINS
} from "helpers/Firebase";
import { arrayify } from "helpers/DataProcessing";
import { grey } from "@mui/material/colors";
import { Box, Stack } from "@mui/material";
import DataTable from "../tables/MuiDataTable";

const grey700 = grey["700"];

const styles = {
  notifs: {
    width: "60%"
  },
  subheader: {
    color: "primary.main"
  },
  productList: {
    borderColor: grey700,
    borderWidth: 1,
    borderStyle: "solid"
    // overflow: 'auto',
    // height: 300
  },
  moneyIcon: {
    width: 15,
    height: 15
  },
  chip: {
    margin: 4
  },
  wrapper: {
    display: "flex",
    flexWrap: "wrap"
  }
};

const abilityDescriptions = {
  add: ["Add", "Add new customers, products, and product groups"],
  edit: ["Edit", "Edit customers, products, and product groups"],
  addContacts: ["Add Contacts", "Add new contacts"],
  editContacts: ["Edit Contacts", "Edit contacts"],
  revenue: ["Revenue Dollars", "Add and delete revenue entries"],
  spend: ["Deductions Spend", "Add and delete deductions entries"],
  promotions: ["Promotions", "Create and duplicate promotions"],
  masteredit: [
    "Master Edit",
    "Master Edit/Advanced Editing functionality for promotions"
  ],
  submit: ["Promotion Submission", "Submit promotions for approval"],
  approve: ["Promotion Approval", "Approve and decline promotions"],
  cancel: ["Promotion Cancellation", "Cancel promotions"],
  close: [
    "Promotion Closure",
    "Open and close completed or cancelled promotions"
  ],
  pricing: ["Pricing", "Edit pricing table"],
  lift: ["Lift", "Edit lift table"],
  viewBusiness: ["View Business", "View the Business section"],
  viewManage: ["View Manage", "View the Manage section"],
  viewInsights: ["View Insights", "View the Insights section"]
};

const featureSpecificDescriptions = {
  broker_commissions: {
    viewCommissions: ["View Commissions", "View the Commissions section"],
    broker: ["Brokers", "Edit broker information including broker contracts"]
  },
  forecast: {
    forecast: ["Forecast", "Use forecasting capabilities"]
  },
  deductions_reconciliation: {
    reconciliation: [
      "Deductions Reconciliation",
      "Use the Deductions Reconciliation platform"
    ],
    cancelInvoicesTransactions: [
      "Cancel Invoices/Transactions",
      "Cancel invoices and transactions in Deductions Reconciliation"
    ]
  }
};

const cresicorSpecificDescriptions = {
  backup_retrieval: {
    approveInvoices: [
      "Approve Invoices",
      "Approve uploaded invoices in Backup Retrieval"
    ]
  }
};

const allowed = 1;
const notConfigured = 2;
const disallowed = 3;

class CustomizeAbilities extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.user,
      companyUser: props.companyUser
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({ user: newProps.user, companyUser: newProps.companyUser });
  }

  onStateChange = (p, event, index, value) => {
    if (
      !this.props.db.permissions.includes(p) &&
      !this.props.owner &&
      !isUserCresicorEmployee()
    )
      return;
    if (!value) return;
    const newCompanyUser = { ...this.state.companyUser };
    if (!newCompanyUser.customPerms) {
      newCompanyUser.customPerms = {};
    }
    newCompanyUser.customPerms[p] = value;
    if (p === "approve" && value !== allowed) {
      newCompanyUser.customPerms.approvalLevel = notConfigured;
    }
    this.setState({ companyUser: newCompanyUser }, () => {
      this.props.updateProposedCompanyUser(this.state.companyUser);
    });
  };

  defaultApprovalAmount = access => {
    if (["Director", "Manager"].includes(access)) {
      return this.props.db.meta.promo_limits?.[access];
    }
    if (access === "Admin") {
      return Infinity;
    }
    return 0;
  };

  onApprovalAmountChange = event => {
    const { value } = event.target;
    if (!this.props.owner && !isUserCresicorEmployee()) return;
    const newCustomPromoLimit = { ...this.state.customPromoLimit };
    newCustomPromoLimit[this.state.user] = value;
    if (isNaN(parseFloat(value))) {
      this.setState(
        { customPromoLimit: newCustomPromoLimit, error: "Invalid input." },
        () => {
          this.props.updateError(this.state.error);
        }
      );
    } else {
      this.setState(
        { customPromoLimit: newCustomPromoLimit, error: "" },
        () => {
          this.props.updateProposedApprovalLimit(this.state.customPromoLimit);
          this.props.updateError(this.state.error);
        }
      );
    }
  };

  getAllowedByDefaultJSX = () => {
    const yes = <Box sx={{ color: "primary.main" }}>Yes</Box>;
    const no = <Box sx={{ color: "error.main" }}>No</Box>;

    const defaultPerms =
      this.props.db.meta.permissions[this.state.companyUser.access];

    const permissionTableJSX = [];
    for (const p of this.props.abilities) {
      permissionTableJSX.push(defaultPerms.includes(p) ? yes : no);
    }
    return permissionTableJSX;
  };

  getPermissionsJSX = () => {
    // NB(daniel): variable names are a tribute to Discord's permission settings
    const check = <CheckIcon sx={{ color: "primary.main" }} />;
    const slash = <RemoveIcon />;
    const cross = <ClearIcon sx={{ color: "error.main" }} />;
    const dummyAllowedLabel = (
      <Checkbox
        checked
        checkedIcon={check}
        uncheckedIcon={check}
        label="Override - Allowed"
      />
    );
    const dummyNotConfiguredLabel = (
      <Checkbox
        checked
        checkedIcon={slash}
        uncheckedIcon={slash}
        label="Same as Default"
      />
    );
    const dummyDisallowedLabel = (
      <Checkbox
        checked
        checkedIcon={cross}
        uncheckedIcon={cross}
        label="Override - Disallowed"
      />
    );

    const permissionTableJSX = [];
    const readOnly = !this.props.owner && !isUserCresicorEmployee();

    for (const p of this.props.abilities) {
      const valueItems = {
        [allowed]: {
          leftIcon: check,
          value: allowed,
          children: "Override - Allowed",
          label: dummyAllowedLabel
        },
        [notConfigured]: {
          leftIcon: slash,
          value: notConfigured,
          children: "Same as Default",
          label: dummyNotConfiguredLabel
        },
        [disallowed]: {
          leftIcon: cross,
          value: disallowed,
          children: "Override - Disallowed",
          label: dummyDisallowedLabel
        }
      };

      const dropDownMenu = (
        <SelectField
          value={
            this.state.companyUser.customPerms
              ? this.state.companyUser.customPerms[p] || notConfigured
              : notConfigured
          }
          labelStyle={{ fontWeight: "bold" }}
          disabled={readOnly}
          onChange={this.onStateChange.bind(null, p)}
          IconComponent={() => <span />}
          underlineStyle={{ borderColor: "transparent" }}
          inputProps={{ style: { borderColor: "transparent" } }}
          renderValue={value => (
            <MenuItem
              leftIcon={valueItems[value]?.leftIcon}
              children={valueItems[value]?.children}
            />
          )}
          className="no-border-select">
          {Object.values(valueItems).map(value => (
            <MenuItem
              leftIcon={value.leftIcon}
              children={value.children}
              value={value.value}
              label={value.label}
            />
          ))}
        </SelectField>
      );
      permissionTableJSX.push(
        readOnly ? (
          <Tooltip
            content="Please ask an owner for assistance."
            styles={{
              tooltip: { background: "#FFF", width: "250px" },
              content: {
                background: "#FFF",
                color: "red",
                zIndex: "99",
                width: "200px"
              }
            }}>
            {dropDownMenu}
          </Tooltip>
        ) : (
          dropDownMenu
        )
      );
    }
    return permissionTableJSX;
  };

  setApprovalLevel = () => {
    const dropDownMenu = (
      <SelectField
        value={
          this.state.companyUser?.customPerms?.approvalLevel ?? notConfigured
        }
        onChange={this.onStateChange.bind(null, "approvalLevel")}
        autoWidth
        className="no-border-select"
        style={{ width: "auto" }}>
        <MenuItem value={notConfigured} children="Default" />
        <MenuItem value={allowed} children="Custom" />
      </SelectField>
    );

    return dropDownMenu;
  };

  setApprovalAmount = () => {
    return (
      <TextField
        error={this.state.error}
        errorText={this.state.error}
        value={
          this.state.companyUser?.customPerms?.approvalLevel === allowed
            ? this.state.customPromoLimit?.[this.state.user] ??
              this.props.db.meta.promo_limits?.custom?.[this.state.user] ??
              this.defaultApprovalAmount(this.state.companyUser.access)
            : this.defaultApprovalAmount(this.state.companyUser.access)
        }
        onChange={this.onApprovalAmountChange}
        disabled={
          this.state.companyUser.customPerms
            ? this.state.companyUser.customPerms.approvalLevel !== allowed
            : true
        }
      />
    );
  };

  canChangeApproval = () => {
    const canApproveByDefault =
      this.state.companyUser.access === "Admin" ||
      (this.state.companyUser.access === "Director" &&
        this.props.db.meta.permissions.Director.includes("approve")) ||
      (this.state.companyUser.access === "Manager" &&
        this.props.db.meta.permissions.Manager.includes("approve"));
    return this.state.companyUser.customPerms
      ? this.state.companyUser.customPerms.hasOwnProperty("approve")
        ? this.state.companyUser.customPerms.approve === allowed ||
          (this.state.companyUser.customPerms.approve === notConfigured &&
            canApproveByDefault)
        : canApproveByDefault
      : canApproveByDefault;
  };

  render() {
    const permissionTableJSX = this.getPermissionsJSX();
    const allowedByDefaultJSX = this.getAllowedByDefaultJSX();
    const setApprovalLevel = this.setApprovalLevel();
    const setApprovalAmount = this.setApprovalAmount();

    return (
      <div style={{ marginLeft: "16px" }}>
        <br />
        You are customizing permissions for user{" "}
        <b>
          {`${this.state.companyUser.name} <${this.state.companyUser.email}>`}
        </b>
        .
        <br />
        <br />
        {this.canChangeApproval() ? (
          <DataTable
            title="Customize Promotion Approval Limits"
            data={{
              "Ability Description": ["Set Promotion Approval Level"],
              Override: [setApprovalLevel],
              Amount: [setApprovalAmount]
            }}
            widths={["50%", "25%", "25%"]}
          />
        ) : (
          <br />
        )}
        <br />
        <DataTable
          title="Customize Abilities"
          data={{
            "Ability Description": this.props.abilities.map(
              x => this.props.abilityDescriptions[x][1]
            ),
            "Allowed By Default?": allowedByDefaultJSX,
            Override: permissionTableJSX
          }}
          widths={["50%", "25%", "25%"]}
        />
      </div>
    );
  }
}

class Permissions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyUsers: {},
      deleteLoading: false
    };
  }

  handleSelectPermission = (user, email, event, index, value) => {
    const { companyUsers } = this.props.db;
    companyUsers[user].access = value;
    if (!this.props.db.meta.permissions[value].includes("approve")) {
      delete companyUsers[user]?.customPerms?.approvalLevel;
    }
    // update companyUsers
    const emailHash = email.replace(/[^a-zA-Z0-9]/g, "");
    const validEmailRef = firebase.database().ref(`validEmails/${emailHash}`);
    let validEmailUpdate = {};
    validEmailRef.on("value", snapshot => {
      const validEmail = snapshot.val();
      validEmailUpdate = {
        access: arrayify(validEmail.access),
        company: arrayify(validEmail.company),
        cid: arrayify(validEmail.cid),
        selectedCompany: validEmail.selectedCompany
          ? validEmail.selectedCompany
          : 0
      };
      const index = validEmailUpdate.cid.indexOf(this.props.db.companyid);
      validEmailUpdate.access[index] = value;
    });
    validEmailRef.update(validEmailUpdate, () => {
      updateCompanyFirebase({ companyUsers });
      this.setState({
        companyUsers
      });
    });
  };

  handleAbilityCheck = (field, userLevel, permissions, event) => {
    const userPermissions = permissions[userLevel] || [];
    if (event.target.checked) {
      userPermissions.push(field);
    } else {
      userPermissions.splice(userPermissions.indexOf(field), 1);
    }
    const newUserPermissions = {};
    newUserPermissions[userLevel] = userPermissions;
    var permissions = Object.assign(permissions, newUserPermissions);
    updateMetaFirebase({ permissions });
  };

  getAbilityDescriptions = props => {
    let descriptions = { ...abilityDescriptions };
    for (const featureName in featureSpecificDescriptions) {
      if (this.props.db.meta[featureName]) {
        descriptions = {
          ...descriptions,
          ...featureSpecificDescriptions[featureName]
        };
      }
    }

    if (isUserCresicorEmployee()) {
      for (const featureName in cresicorSpecificDescriptions) {
        if (this.props.db.meta[featureName]) {
          descriptions = {
            ...descriptions,
            ...cresicorSpecificDescriptions[featureName]
          };
        }
      }
    }

    return descriptions;
  };

  getAbilitiesJSX = (props, readOnly) => {
    const abilitiesJSX = {
      "Ability Name": [],
      "Ability Description": [],
      Director: [],
      Manager: []
    };

    const descriptions = this.getAbilityDescriptions(props);

    for (const [field, [name, description]] of Object.entries(descriptions)) {
      abilitiesJSX["Ability Name"].push(name);
      abilitiesJSX["Ability Description"].push(description);
      const directorPermissions = props.db.meta.permissions.Director || [];
      const managerPermissions = props.db.meta.permissions.Manager || [];
      abilitiesJSX.Director.push(
        <Checkbox
          checked={directorPermissions.includes(field)}
          onChange={this.handleAbilityCheck.bind(
            null,
            field,
            "Director",
            props.db.meta.permissions
          )}
          disabled={readOnly}
        />
      );
      abilitiesJSX.Manager.push(
        <Checkbox
          checked={managerPermissions.includes(field)}
          onChange={this.handleAbilityCheck.bind(
            null,
            field,
            "Manager",
            props.db.meta.permissions
          )}
          disabled={readOnly}
        />
      );
    }

    return abilitiesJSX;
  };

  transferOwnership = user => {
    const cu = this.props.db.companyUsers[user];
    this.props.openClose.setAppModal(
      "Transfer Ownership",
      <div className="centering">
        Are you sure you want to transfer ownership to this user:&nbsp;
        <b>{`${cu.name} <${cu.email}>`}</b>? This cannot be undone!
      </div>,
      <Stack>
        <Button
          label="Yes, I'm sure"
          variant="text"
          color="error"
          onClick={() => {
            const newCompanyUsers = {
              ...this.props.db.companyUsers
            };
            for (const u in this.props.db.companyUsers) {
              newCompanyUsers[u].owner = false;
            }
            newCompanyUsers[user].owner = true;
            firebase
              .database()
              .ref(`companies/${this.state.companyId}/companyUsers`)
              .update(newCompanyUsers);
            this.setState({ opNum: this.state.opNum + 1 });
            this.props.openClose.closeAppModal();
          }}
        />
        <Button
          label="No, go back"
          variant="text"
          onClick={this.props.openClose.closeAppModal}
        />
      </Stack>
    );
  };

  handleRemoval = (email, companyId) => {
    const doneCallback = deletedField => {
      this.setState({ deleteLoading: false });
      this.props.openClose.showSnack(`${deletedField} deleted`);
      this.props.openClose.closeAppModal();
    };
    removeCompanyFromUser(
      email,
      companyId,
      doneCallback,
      (deletedField, executeDelete) => {
        this.props.openClose.setAppModal(
          `Remove ${deletedField}`,
          this.state.deleteLoading ? (
            <div>
              <br />
              <div className="centering">
                <CircularProgress size={80} thickness={5} />
              </div>
              <br />
              <div className="centering">
                {`Deleting this ${deletedField.toLowerCase()}. Please wait...`}
              </div>
              <br />
            </div>
          ) : (
            <div>
              <br />
              <div className="centering">
                {`Are you sure you want to remove this ${deletedField.toLowerCase()}?`}
              </div>
              <Stack justifyContent="flex-end" sx={{ mt: 2 }}>
                <Button
                  label="Yes, I'm sure"
                  variant="text"
                  color="error"
                  onClick={() => {
                    this.setState({
                      deleteLoading: true,
                      opNum: this.state.opNum + 1
                    });
                    executeDelete();
                  }}
                />
                <Button
                  label="No, go back"
                  variant="text"
                  onClick={this.props.openClose.closeAppModal}
                />
              </Stack>
            </div>
          )
        );
      },
      () => {
        this.props.openClose.showSnack("User authentication not enabled.");
      }
    );
  };

  updatePromoLimitsFirebase = companyId => {
    firebase
      .database()
      .ref(`companies/${companyId}/meta/promo_limits`)
      .update(this.state.promoLimitsDict);
    this.setState({ promoLimitsChanges: false });
  };

  handleChangeLimit = (perm, event) => {
    let { value } = event.target;
    if (value === "") {
      value = 0;
    } else if (isNaN(value)) {
      return;
    }
    const dict = this.state.promoLimitsDict;
    dict[perm] = parseInt(value);
    this.setState({
      promoLimitsDict: dict,
      promoLimitsChanges: true
    });
  };

  handleCustomPerms = user => {
    const currentCompanyUser = jQuery.extend(
      true,
      {},
      this.props.db.companyUsers[user]
    );
    const abilityDescriptions = this.getAbilityDescriptions();
    const abilities = Object.keys(abilityDescriptions);

    const { currentUser } = firebase.auth();
    const owner = currentUser
      ? this.props.db.companyUsers[currentUser.uid].owner
      : false;

    // initialize proposed customer
    this.setState({ proposedCompanyUser: currentCompanyUser });

    this.props.openClose.setAppModal(
      "Customize Abilities",
      <CustomizeAbilities
        user={user}
        companyUser={currentCompanyUser}
        owner={owner}
        updateProposedCompanyUser={companyUser => {
          this.setState({ proposedCompanyUser: companyUser });
        }}
        updateProposedApprovalLimit={approvalLimit => {
          this.setState({ proposedApprovalLimit: approvalLimit });
        }}
        updateError={error => {
          this.setState({ error });
        }}
        db={this.props.db}
        abilities={abilities}
        abilityDescriptions={abilityDescriptions}
      />,
      owner || isUserCresicorEmployee() ? (
        <Stack>
          <Button
            label="Cancel"
            onClick={this.props.openClose.closeAppModal}
            color="error"
            variant="text"
          />
          <Button
            label="Save"
            variant="text"
            onClick={() => {
              if (this.state.error) {
                return;
              }
              const { proposedCompanyUser } = this.state;
              const perms = proposedCompanyUser.customPerms;
              for (const p in perms) {
                if (perms[p] == notConfigured) {
                  delete perms[p];
                }
              }
              firebase
                .database()
                .ref(`companies/${this.state.companyId}/companyUsers/${user}`)
                .update(proposedCompanyUser);
              // Update promo limits
              if (!this.state.promoLimitsDict) {
                return;
              }
              const { proposedApprovalLimit } = this.state;
              const newCustomLimits = {
                ...this.state.promoLimitsDict.custom,
                ...proposedApprovalLimit
              };
              this.setState(
                {
                  promoLimitsDict: {
                    ...this.state.promoLimitsDict,
                    ...{ custom: newCustomLimits }
                  }
                },
                this.updatePromoLimitsFirebase.bind(null, this.state.companyId)
              );
              this.props.openClose.closeAppModal();
            }}
          />
        </Stack>
      ) : (
        [<Button label="Close" onClick={this.props.openClose.closeAppModal} />]
      ),
      true,
      "md"
    );
  };

  getMoreOptionsJSX = () => {
    const { currentUser } = firebase.auth();
    const owner =
      currentUser && this.props.db.companyUsers[currentUser.uid]
        ? this.props.db.companyUsers[currentUser.uid].owner
        : false;
    const moreOptionsJSX = [];
    for (const user in this.props.db.companyUsers) {
      const { email } = this.props.db.companyUsers[user];
      moreOptionsJSX.push(
        <IconMenu
          iconButtonElement={
            <IconButton size="large">
              <MoreVertIcon />
            </IconButton>
          }
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
          targetOrigin={{ horizontal: "right", vertical: "top" }}>
          {(isUserCresicorEmployee() || owner) && (
            <MenuItem
              children="Transfer Ownership"
              leftIcon={<ArrowRightAltIcon />}
              disabled={
                (currentUser && currentUser.uid == user) ||
                COMPANY_EMAIL_DOMAINS.some(domain => email.endsWith(domain))
              }
              onClick={this.transferOwnership.bind(null, user)}
            />
          )}
          <MenuItem
            children="Remove"
            leftIcon={<DeleteIcon sx={{ color: "error.main" }} />}
            disabled={
              (currentUser && currentUser.uid == user) ||
              COMPANY_EMAIL_DOMAINS.some(domain => email.endsWith(domain))
            }
            onClick={this.handleRemoval.bind(null, email, this.state.companyId)}
          />
        </IconMenu>
      );
    }

    return moreOptionsJSX;
  };

  getTitlesJSX = props => {
    if (!props.db.meta.permissions) return [];

    const titlesJSX = [];
    for (const user in props.db.companyUsers) {
      const { email, owner } = props.db.companyUsers[user];
      const permsItems = Object.keys(props.db.meta.permissions).map(level => {
        return <MenuItem value={level} children={level} key={level} />;
      });
      if (owner) {
        permsItems.push(
          <MenuItem value="Owner" children="Owner" key="Owner" />
        );
      }
      titlesJSX.push(
        <div>
          <SelectField
            value={owner ? "Owner" : props.db.companyUsers[user].access}
            onChange={this.handleSelectPermission.bind(null, user, email)}
            style={styles.input}
            size="small"
            disabled={owner}>
            {permsItems}
          </SelectField>
          <br />
          {props.db.companyUsers[user].customPerms && (
            <span
              style={{
                display: "block",
                "margin-left": "20px",
                color: "green",
                "font-size": "8pt"
              }}>
              This user has customized permissions.
            </span>
          )}
        </div>
      );
    }
    return titlesJSX;
  };

  getCustomPermsJSX = props => {
    const customPermsJSX = [];
    for (const user in props.db.companyUsers) {
      const { email } = props.db.companyUsers[user];
      customPermsJSX.push(
        <Button
          label="Customize"
          variant="text"
          sx={{ px: "4px" }}
          onClick={this.handleCustomPerms.bind(null, user)}
          primary
          icon={<AssignmentIcon />}
        />
      );
    }
    return customPermsJSX;
  };

  getPromoLimitsJSX = (props, readOnly) => {
    if (!this.props.db.meta.permissions) return [];

    const directorPermissions = props.db.meta.permissions.Director || [];
    const managerPermissions = props.db.meta.permissions.Manager || [];

    const directorValue = !directorPermissions.includes("approve")
      ? "N/A"
      : this.state.promoLimitsDict
      ? this.state.promoLimitsDict.Director
      : 0;

    const managerValue = !managerPermissions.includes("approve")
      ? "N/A"
      : this.state.promoLimitsDict
      ? this.state.promoLimitsDict.Manager
      : 0;

    const promoLimitsJSX = [];
    promoLimitsJSX.push(
      <TextField
        value={directorValue}
        onChange={this.handleChangeLimit.bind(null, "Director")}
        style={styles.textfield}
        id="director_promo_limit_textfield"
        disabled={readOnly || !directorPermissions.includes("approve")}
      />
    );
    promoLimitsJSX.push(
      <TextField
        value={managerValue}
        onChange={this.handleChangeLimit.bind(null, "Manager")}
        style={styles.textfield}
        id="manager_promo_limit_textfield"
        disabled={readOnly || !managerPermissions.includes("approve")}
      />
    );
    return promoLimitsJSX;
  };

  componentDidMount() {
    const user = firebase.auth().currentUser;
    firebase
      .database()
      .ref(`users/${user.uid}/company_id`)
      .on("value", snapshot => {
        if (snapshot.val()) {
          const companyId = snapshot.val();
          this.setState({ userid: user.uid, companyId });
        }
      });

    const promoLimitsDict = {};
    for (const perm of ["Director", "Manager", "custom"]) {
      if (
        this.props.db.meta.promo_limits &&
        Object.keys(this.props.db.meta.promo_limits).indexOf(perm) != -1
      ) {
        promoLimitsDict[perm] = this.props.db.meta.promo_limits[perm];
      } else {
        promoLimitsDict[perm] = 0;
      }
    }
    this.setState({
      promoLimitsDict,
      promoLimitsChanges: false
    });
  }

  render() {
    const userNames = Object.values(this.props.db.companyUsers).map(
      user => user.name
    );
    const user = firebase.auth().currentUser;
    const promoLimitsJSX = this.getPromoLimitsJSX(this.props, false);
    const readOnlyLimitsJSX = this.getPromoLimitsJSX(this.props, true);

    const titlesJSX = this.getTitlesJSX(this.props);
    const customPermsJSX = this.getCustomPermsJSX(this.props);

    const abilitiesJSX = this.getAbilitiesJSX(this.props, false);
    const readOnlyAbilitiesJSX = this.getAbilitiesJSX(this.props, true);

    const moreOptionsJSX = this.getMoreOptionsJSX();

    return (
      <div style={{ margin: 10 }}>
        {this.props.db.permissions !== "None" && (
          <div>
            <Subheader style={styles.subheader}>User Abilities</Subheader>
            {this.props.db.permissions.includes("admin") ||
            this.props.db.companyUsers[user.uid].owner ? (
              <DataTable
                title="Assign Director and Manager Abilities"
                data={{
                  "Ability Name": abilitiesJSX["Ability Name"],
                  "Ability Description": abilitiesJSX["Ability Description"],
                  Director: abilitiesJSX.Director,
                  Manager: abilitiesJSX.Manager
                }}
              />
            ) : (
              <DataTable
                title="Director and Manager Abilities"
                data={{
                  "Ability Name": readOnlyAbilitiesJSX["Ability Name"],
                  "Ability Description":
                    readOnlyAbilitiesJSX["Ability Description"],
                  Director: readOnlyAbilitiesJSX.Director,
                  Manager: readOnlyAbilitiesJSX.Manager
                }}
              />
            )}
            <br />
          </div>
        )}

        {this.props.db.permissions !== "None" && (
          <div>
            <Subheader style={styles.subheader}>
              Promotion Approval Limits
            </Subheader>
            {this.state.promoLimitsDict ? (
              <DataTable
                title={
                  this.props.db.permissions.includes("admin")
                    ? "Assign Promotion Approval Limits"
                    : "Promotion Approval Limits"
                }
                data={{
                  Title: ["Director", "Manager"],
                  "Can approve/decline promotions of at most ($)":
                    this.props.db.permissions.includes("admin") ||
                    this.props.db.companyUsers[user.uid].owner
                      ? promoLimitsJSX
                      : readOnlyLimitsJSX
                }}
                widths={["40%", "60%"]}
              />
            ) : (
              <div style={styles.loading} className="centering">
                <CircularProgress size={80} thickness={5} />
              </div>
            )}
            {(this.props.db.permissions.includes("admin") ||
              this.props.db.companyUsers[user.uid].owner) && (
              <div>
                <Button
                  label="Save Changes"
                  icon={<SaveIcon />}
                  disabled={!this.state.promoLimitsChanges}
                  onClick={this.updatePromoLimitsFirebase.bind(
                    null,
                    this.state.companyId
                  )}
                />
                <br />
              </div>
            )}
            <br />
          </div>
        )}

        <Subheader style={styles.subheader}>User Permissions</Subheader>
        <div>
          Your permission level is{" "}
          <strong>
            {user && this.props.db.companyUsers[user.uid]
              ? this.props.db.companyUsers[user.uid].owner
                ? "Owner"
                : this.props.db.companyUsers[user.uid].access
              : "None"}
          </strong>
          .
        </div>
        <br />
        {this.props.db.permissions.includes("admin") ||
        this.props.db.companyUsers[user.uid].owner ? (
          <DataTable
            title="Assign Permission Levels"
            data={{
              User: userNames,
              Title: titlesJSX,
              Permissions: customPermsJSX,
              Options: moreOptionsJSX
            }}
            widths={["40%", "35%", "20%", "5%"]}
          />
        ) : (
          <div>
            Please contact your administrator with any questions about user
            permissions or promotion approval limits.
          </div>
        )}
      </div>
    );
  }
}

export default Permissions;
