import React from "react";
import { DisplayInvoiceLineObject } from "components/Deductions/DeductionsReconciliation/types/invoiceLineTypes";
import DollarFormatter from "ui-library/DollarFormatter";
import { setResolutionLineActivityAmount } from "components/Deductions/DeductionsReconciliation/ViewData/ViewTransactions/ResolveMultipleInvoiceLines/redux/ResolveMultipleInvoiceLinesSlice";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DisplayERPTransactionObject } from "components/Deductions/DeductionsReconciliation/types/transactionTypes";
import { DisplayInvoiceObject } from "components/Deductions/DeductionsReconciliation/types/invoiceTypes";
import { TextField } from "@mui/material";
import { RootState } from "store";
import { ResolutionLineActivityErrors } from "components/Deductions/DeductionsReconciliation/services/ResolveMultipleInvoiceLinesServices/resolveMultipleInvoiceLinesErrors";

interface MultiEditScreenActivityAmountProps {
  displayInvoiceLine: DisplayInvoiceLineObject;
  displayInvoice: DisplayInvoiceObject;
  displayTransaction: DisplayERPTransactionObject;
  hasErrorsMap: Record<string, ResolutionLineActivityErrors>;
  showErrors: boolean;
  lineErrorMessagesMap: Record<string, Record<string, string>>;
}

const MultiEditScreenActivityAmount = (
  props: MultiEditScreenActivityAmountProps
) => {
  const {
    displayInvoiceLine,
    displayInvoice,
    displayTransaction,
    hasErrorsMap,
    showErrors,
    lineErrorMessagesMap
  } = props;
  const dispatch = useDispatch();

  const { resolutionLines } = useSelector(
    (state: RootState) => state.resolveMultipleInvoiceLines,
    shallowEqual
  );

  const { key: invoiceKey, openAmount: invoiceOpenAmount } = displayInvoice;
  const {
    key,
    invoiceLineKey,
    openAmount: invoiceLineOpenAmount
  } = displayInvoiceLine;
  const { openAmount: transactionOpenAmount } = displayTransaction;
  const currentKey = key || invoiceLineKey;
  const resolutionLineInvoice = resolutionLines[invoiceKey] ?? {};
  const resolutionLine = resolutionLineInvoice[currentKey] ?? {};
  const { amount } = resolutionLine ?? {};
  const hasErrorsByInvoice = hasErrorsMap[currentKey];
  const hasActivityAmountErrors =
    hasErrorsByInvoice?.amountLessThanOrEqualZero ||
    hasErrorsByInvoice?.exceedsMaxInvoiceAmount ||
    hasErrorsByInvoice?.exceedsMaxInvoiceLineAmount ||
    hasErrorsByInvoice?.exceedsMaxTransactionAmount;

  return (
    <TextField
      variant="outlined"
      size="small"
      value={amount}
      error={showErrors && hasActivityAmountErrors}
      helperText={
        showErrors &&
        hasActivityAmountErrors &&
        lineErrorMessagesMap[currentKey].amount
      }
      onChange={event => {
        if (resolutionLine)
          dispatch(
            setResolutionLineActivityAmount({
              invoiceKey,
              invoiceLineKey,
              activityAmount: event.target.value as string,
              invoiceOpenAmount,
              invoiceLineOpenAmount,
              transactionOpenAmount
            })
          );
      }}
      InputProps={{
        inputComponent: DollarFormatter,
        inputProps: { showNegative: false }
      }}
    />
  );
};

export default MultiEditScreenActivityAmount;
