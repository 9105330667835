//
// Code to add a promotion.
// Promotions are added in four steps:
//   1. Basic Info (handled in AddPromStep0.js)
//   2. Promotion Details (handled in AddPromStep1.js)
//   3. Miscellaneous (handled in AddPromStep2.js)
//   4. Uploading Files (handled in AddPromStep3.js)
//

import React from "react";
import { connect } from "react-redux";
import IconButton from "ui-library/IconButton";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Confetti } from "ui-library/Confetti";
import { common } from "@mui/material/colors";
import { setDefault, reset } from "./actions";
import AddPromStep0 from "./AddPromStep0";

import AddPromStep1 from "./AddPromStep1";
import AddPromStep2 from "./AddPromStep2";
import AddPromStep3 from "./AddPromStep3";
import LikePromotions from "./LikePromotions";

const { white } = common;

const styles = {
  header: {
    marginLeft: "-15px",
    fontSize: 20
  },
  addAnother: {
    marginTop: -10,
    marginLeft: 15,
    color: white
  }
};

class AddPromotion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getStepContent = stepIndex => {
    // given a step index, return the corresponding content to show.
    // for example, stepIndex = 0 returns AddPromStep0
    switch (stepIndex) {
      case 0:
        return (
          <AddPromStep0
            db={this.props.db}
            openClose={this.props.openClose}
            proposed={this.props.proposed}
          />
        );
      case 1:
        return (
          <AddPromStep1
            db={this.props.db}
            openClose={this.props.openClose}
            mode={this.props.mode}
            proposed={this.props.proposed}
          />
        );
      case 2:
        return (
          <AddPromStep2
            db={this.props.db}
            openClose={this.props.openClose}
            proposed={this.props.proposed}
          />
        );
      case 3:
        return (
          <AddPromStep3
            db={this.props.db}
            openClose={this.props.openClose}
            oldProm={this.props.defaultProm}
            proposed={this.props.proposed}
          />
        );
      default:
        return <p>Error!</p>;
    }
  };

  getEndContent = () => {
    if (this.props.mode == "edit") {
      return (
        <div style={{ marginBottom: 50 }}>
          <Confetti />
          <div className="rowC">
            <div>
              Promotion {this.props.promState.promKey} successfully edited!
            </div>
            <IconButton
              tooltip="Copy ID"
              style={{ marginTop: -15 }}
              onClick={() => {
                navigator.clipboard.writeText(this.props.promState.promKey);
              }}
              size="large">
              <FileCopyIcon />
            </IconButton>
          </div>
          <br />
          Select option below to edit or add another.
          <h3 style={{ marginTop: "30px" }}> Success!!</h3>
        </div>
      );
    }
    return (
      <div style={{ marginBottom: 50 }}>
        <Confetti />
        <div className="rowC">
          <div>
            Promotion {this.props.promState.promKey} successfully added!
          </div>
          <IconButton
            tooltip="Copy ID"
            style={{ marginTop: -15 }}
            onClick={() => {
              navigator.clipboard.writeText(this.props.promState.promKey);
            }}
            size="large">
            <FileCopyIcon />
          </IconButton>
        </div>
        <br />
        Select option below to edit or add another.
      </div>
    );
  };

  componentDidMount() {
    // if prom fields are provided
    // (e.g. if doing an edit to an existing promotion)
    if (this.props.defaultProm) {
      this.props.dispatch(reset());
      this.props.dispatch(
        setDefault(
          this.props.defaultProm,
          this.props.callback,
          this.props.db,
          this.props.mode
        )
      );
    }
  }

  render() {
    const { finished, stepIndex } = this.props;
    return (
      <div
        style={{
          width: "100%",
          paddingTop: 32,
          margin: "auto",
          fontFamily: "Oxygen"
        }}>
        <div style={{ margin: "0px 16px" }}>
          {finished ? (
            this.getEndContent()
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={stepIndex === 3 ? 12 : 7}>
                <Paper elevation={2} style={{ padding: "20px" }}>
                  {this.getStepContent(stepIndex)}
                </Paper>
              </Grid>
              {stepIndex !== 3 && (
                <Grid item xs={5}>
                  <Paper elevation={2} style={{ padding: "20px" }}>
                    <LikePromotions
                      db={this.props.db}
                      openClose={this.props.openClose}
                    />
                  </Paper>
                </Grid>
              )}
            </Grid>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const promFields = state.addPromotion;
  return {
    promState: promFields.prom,
    lines: promFields.lines,
    stepIndex: promFields.stepIndex,
    finished: promFields.finished
  };
};

export default connect(mapStateToProps)(AddPromotion);
