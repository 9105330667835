import { CSVLink } from "react-csv";

const { styled } = require("@mui/styles");

const StyledCSVLink = styled(CSVLink)(({ theme, disabled, variant }) => ({
  fontFamily: "Monument Grotesk Regular",
  fontWeight: 500,
  fontSize: "0.8125rem",
  lineHeight: "1.25rem",
  letterSpacing: "0.02857rem",
  textTransform: "none",
  minWidth: "64px",
  padding: "6px 24px",
  borderRadius: "8px",
  textDecoration: "none",
  color: "#fff !important",
  backgroundColor: disabled
    ? "rgba(0, 0, 0, 0.26)"
    : theme.palette.primary.main,
  pointerEvents: disabled && "none",

  "&:hover": {
    color: "#fff !important",
    textDecoration: "none",
    backgroundColor: theme.palette.primary.onhover
  },

  "&:focus": {
    outline: "none",
    color: "#fff !important",
    textDecoration: "none"
  }
}));

export default StyledCSVLink;
