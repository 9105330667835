import React, { useMemo } from "react";
import { TextField, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DisplayInvoiceLineObject } from "components/Deductions/DeductionsReconciliation/types/invoiceLineTypes";
import { setResolutionLineFundTypeKey } from "components/Deductions/DeductionsReconciliation/ViewData/ViewTransactions/ResolveMultipleInvoiceLines/redux/ResolveMultipleInvoiceLinesSlice";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DisplayInvoiceObject } from "components/Deductions/DeductionsReconciliation/types/invoiceTypes";
import { ResolutionActivityTypes } from "components/Deductions/DeductionsReconciliation/types/resolutionLineTypes";
import { FundType } from "js/dbTypes";
import { RootState } from "store";
import { ResolutionLineActivityErrors } from "components/Deductions/DeductionsReconciliation/services/ResolveMultipleInvoiceLinesServices/resolveMultipleInvoiceLinesErrors";

interface MultiEditScreenActivityAmountProps {
  displayInvoiceLine: DisplayInvoiceLineObject;
  displayInvoice: DisplayInvoiceObject;
  hasErrorsMap: Record<string, ResolutionLineActivityErrors>;
  showErrors: boolean;
  lineErrorMessagesMap: Record<string, Record<string, string>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  allLines: any;
  activityType: ResolutionActivityTypes;
  fundTypes: Record<string, FundType>;
}

const FundTypeTextField = styled(TextField)(() => ({
  width: "100%"
}));

const MultiEditScreenFundTypeField = (
  props: MultiEditScreenActivityAmountProps
) => {
  const {
    displayInvoiceLine,
    displayInvoice,
    hasErrorsMap,
    showErrors,
    allLines,
    lineErrorMessagesMap,
    activityType,
    fundTypes
  } = props;
  const dispatch = useDispatch();
  const { resolutionLines } = useSelector(
    (state: RootState) => state.resolveMultipleInvoiceLines,
    shallowEqual
  );
  const {
    key: lineKey,
    invoiceLineKey,
    matchedPromLine = ""
  } = displayInvoiceLine;
  const currentKey = lineKey || invoiceLineKey;
  const { key: invoiceKey } = displayInvoice;
  const resolutionLinesInvoice = resolutionLines[invoiceKey] ?? {};
  const resolutionLine = resolutionLinesInvoice[currentKey] ?? {};

  const fundTypeKey =
    resolutionLine?.fundTypeKey ||
    allLines[matchedPromLine || ""]?.type ||
    displayInvoiceLine.suggestedFundType ||
    "";

  const fundTypeOptions = useMemo<Record<string, string>>(
    () =>
      Object.assign(
        {},
        ...Object.keys(fundTypes)
          .map(typeKey => ({
            [typeKey]: fundTypes[typeKey]?.name
          }))
          .sort((obj1, obj2) => {
            return Object.values(obj1)[0].localeCompare(Object.values(obj2)[0]);
          })
      ),
    [fundTypes]
  );

  return (
    <FundTypeTextField
      select
      size="small"
      variant="outlined"
      value={fundTypeKey}
      error={showErrors && hasErrorsMap[currentKey].missingFundTypeKey}
      helperText={
        showErrors &&
        hasErrorsMap[currentKey].missingFundTypeKey &&
        lineErrorMessagesMap[currentKey].fundTypeKey
      }
      onChange={event => {
        const key = event.target.value as string;
        dispatch(
          setResolutionLineFundTypeKey({
            fundTypeKey: key,
            fundTypes,
            activityType,
            invoiceKey,
            invoiceLineKey
          })
        );
      }}>
      {Object.entries(fundTypeOptions).map(([optionKey, option]) => (
        <MenuItem
          disabled={
            !fundTypes?.[optionKey]?.writeoffAccountKey && option !== "None"
          }
          key={optionKey}
          value={optionKey}>
          {option}
        </MenuItem>
      ))}
    </FundTypeTextField>
  );
};

export default MultiEditScreenFundTypeField;
