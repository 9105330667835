import { Box, Typography } from "@mui/material";
import React from "react";

function Subheader({ style, children }) {
  return (
    <Box sx={{ my: 2 }}>
      <Typography
        variant="h5"
        sx={{ fontWeight: 500, color: "inherit", ...style }}>
        {" "}
        {children}{" "}
      </Typography>
    </Box>
  );
}

export default Subheader;
