import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import LabelIcon from "@mui/icons-material/Label";
import NsIcon from "@mui/icons-material/NotificationsActive";
import Paper from "@mui/material/Paper";
import React, { useState } from "react";

import NotifSelection from "./NotifSelection";
import { Tags } from "./tags";

const Promotions = props => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const onTabChange = (event, value) => setSelectedIndex(value);

  return (
    <>
      <Paper elevation={2}>
        <BottomNavigation
          value={selectedIndex}
          onChange={onTabChange}
          showLabels>
          <BottomNavigationAction
            label="Notifications"
            icon={<NsIcon />}
            value={0}
          />
          <BottomNavigationAction label="Tags" icon={<LabelIcon />} value={1} />
        </BottomNavigation>
      </Paper>
      {selectedIndex === 0 && <NotifSelection {...props} />}
      {selectedIndex === 1 && <Tags {...props} />}
    </>
  );
};

export { Promotions };
