/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { addFirebase, generateRandomKey, firebase } from "helpers/Firebase";
import { removeVal, months } from "helpers/DataProcessing";
import objDiff from "helpers/objDiff";
import Mixpanel from "helpers/Mixpanel";
import {
  SET_STEP_INDEX,
  ADV_DUP_ERROR,
  ADV_DUP_PROCEED
} from "../Planning/actions";
import {
  CHANGE_STEP,
  CLEAR_LINE,
  UPDATE_FIELD,
  RESET,
  INCREMENT_LINE,
  ADD_CONTACT,
  ADD_FILES,
  REMOVE_FILE,
  CHANGE_MODE,
  UPDATE_FILE_ATTRIBUTES,
  SET_DEFAULT,
  SET_LINE_INDEX,
  SET_FILE_INFO
} from "./actions";
import { getVisibleDistributors } from "../Customers/CustomerUtils";

const startFields = ["buyinStartDate", "scanbackStartDate", "instoreStartDate"];
const endFields = ["buyinEndDate", "scanbackEndDate", "instoreEndDate"];
const dateTypes = ["buyin", "scanback", "instore"];
const modeTitles = {
  add: "Add New Promotion",
  edit: "Edit Existing Promotion",
  duplicate: "Duplicate Existing Promotion"
};
const initialState = {
  finished: false,
  loading: false,
  stepIndex: 0,
  selectedLineIndex: 0,
  prom: {
    // Set SelectFields to default 1
    status: 0,
    line1: { type: "" },
    contact: [],
    product: [],
    customer: {},
    contact1: {},
    fileNames: [],
    contractNames: [],
    distributor: [],
    fileInfo: []
  },
  addContactNum: 1,
  defaultDates: {},
  defaultDateRemount: 0,
  lines: ["line1"],
  newContacts: ["contact1"],
  files: [],
  fileNames: [],
  contracts: [],
  contractNames: [],
  fileInfo: [],
  fileContainingProm: {},
  allFilesDownloaded: false,
  fieldErrors: {},
  modifySelectedProm: null,
  callback: null,
  forceUpdate: 0,
  mode: null,
  modalTitle: "",
  advDuplicationError: true,
  continueFromAdvDuplicationModal: false,
  errorLines: new Set()
};

// This is also in Firebase.js.
// Temporarily copy-pasting here to resolve promotion inconsistencies
// that are getting sent to Firebase but not actually pushed to the database
// (i.e., proposed promotions).
function processProm(item, meta) {
  function round(n) {
    return Math.round(n * 100) / 100;
  }

  const prom = { ...item };
  let promExpSpend = 0;
  let promExpSales = 0;
  let promPrevExpSales = 0;
  let promPrevExpSpend = 0;
  promPrevExpSpend += prom.totalExpSpend || 0;
  for (const lineKey in prom.lines) {
    const line = prom.lines[lineKey];
    // compute total expected spend unless it is a lump sum spend
    const typeFields = meta.fundTypes?.[line.type]?.promCreationFields ?? [];
    if (
      line.totalExpSales &&
      line.spendRate &&
      !typeFields.includes("lumpsum")
    ) {
      line.totalExpSpend = line.totalExpSales * line.spendRate;
    }
    promExpSpend += line.totalExpSpend
      ? Math.round(parseFloat(line.totalExpSpend))
      : 0;
    promExpSales += line.totalExpSales
      ? Math.round(parseFloat(line.totalExpSales))
      : 0;
    promPrevExpSales += line.prevTotalExpSales
      ? Math.round(parseFloat(line.prevTotalExpSales))
      : 0;

    // compute discount
    line.discount =
      line.basePrice && line.retailPrice
        ? round(((line.basePrice - line.retailPrice) * 100) / line.basePrice)
        : 0;
  }
  prom.totalExpSales = promExpSales;
  prom.totalExpSpend = promExpSpend;
  prom.prevTotalExpSales = promPrevExpSales;
  prom.prevTotalExpSpend = promPrevExpSpend;
  prom.spendRate = promExpSales ? round(promExpSpend / promExpSales) : 0;

  return prom;
}

// Helpers

function submitProm(newState, db, mode) {
  Object.assign(newState, { loading: true });
  // Get current user
  var user = firebase.auth().currentUser;

  // Push contacts to database
  let newProm = { ...newState.prom };

  // delete name init value if set
  delete newProm.nameInit;

  delete newProm.errorLines;

  newProm.contact = [];
  for (var i = 0; i < newState.newContacts.length; i++) {
    const contactNum = newState.newContacts[i];
    if (newState.prom[contactNum].name) {
      // Check if contact already exists
      if (newState.prom[contactNum].key) {
        newProm.contact.push(newState.prom[contactNum].key);
      } else {
        var storedContact = newState.prom[contactNum];
        addFirebase(3, storedContact, newContactKey => {
          newProm.contact.push(newContactKey);
          db.contacts[newContactKey] = storedContact;
        });
      }
    }

    delete newProm[contactNum];
  }

  // Set file names
  if (newState.fileInfo.length) {
    newProm.fileInfo = newState.fileInfo.map(file => ({ ...file }));
  }

  // Store customer name
  const storedCustomer = newState.prom.customer;
  newProm.customer = storedCustomer.key;
  newProm.customerName = storedCustomer.name;

  // Set fields for created and last modified
  var user = firebase.auth().currentUser;
  const { uid } = user;
  const userName = db.companyUsers[uid].name;
  if (mode != "edit") {
    newProm.created = {
      user: userName,
      uid: user.uid,
      date: new Date().toDateString(),
      time: new Date().toLocaleTimeString()
    };
  }

  newProm.modified = {
    user: userName,
    uid: user.uid,
    date: new Date().toDateString(),
    time: new Date().toLocaleTimeString()
  };

  // Convert comments string into first object in list of comments
  if (newProm.newComment) {
    if (!newProm.commentList) newProm.commentList = [];
    newProm.commentList.push({
      text: newProm.newComment,
      date: new Date().toDateString(),
      user: userName,
      uid: user.uid
    });
    newProm.comments = newProm.newComment; // for exporting purposes (only most recent one is shown)
    delete newProm.newComment;
  }

  Object.assign(newState, {
    prom: newProm
  });

  // use original promKey for lines if in editing mode, otherwise generate new one
  const newKey =
    mode == "edit" && newProm.promKey ? newProm.promKey : generateRandomKey();
  newProm.promKey = newKey;

  newProm.lines = {};
  for (var i = 0; i < newState.lines.length; i++) {
    const line = newState.lines[i];

    delete line.liftUpdated;

    // Remove deleted lines
    if (!line || !newProm[line]) {
      delete newProm[line];
      continue;
    }
    if ("lumpSumSpend" in newProm[line]) {
      newProm[line].totalExpSpend = newProm[line].lumpSumSpend;
    }
    // Calculate total exp spend, if not entered
    if (
      !("lumpSumSpend" in newProm[line]) ||
      !("totalExpSpend" in newProm[line])
    ) {
      newProm[line].totalExpSpend =
        newProm[line].totalExpSales * newProm[line].spendRate
          ? newProm[line].totalExpSales * newProm[line].spendRate
          : 0;
    }

    // Calculate discount
    if ("basePrice" in newProm[line] && "retailPrice" in newProm[line]) {
      newProm[line].discount =
        ((newProm[line].basePrice - newProm[line].retailPrice) * 100) /
          newProm[line].basePrice || 0;
    }

    // Set startDate and endDate values if month and year fields are filled
    let datesExist = false;
    var earliestDate;
    var latestDate;
    for (var j = 0; j < dateTypes.length; j++) {
      if (
        db.meta.fundTypes?.[newProm[line].type]?.promCreationFields?.includes(
          dateTypes[j]
        ) &&
        newProm[line][startFields[j]]
      ) {
        datesExist = true;
        if (
          !earliestDate ||
          new Date(newProm[line][startFields[j]]) < new Date(earliestDate)
        ) {
          earliestDate = newProm[line][startFields[j]];
        }
        if (
          !latestDate ||
          new Date(newProm[line][endFields[j]]) > new Date(latestDate)
        ) {
          latestDate = newProm[line][endFields[j]];
        }
      }
    }

    if (datesExist) {
      newProm[line].startDate = earliestDate;
      newProm[line].endDate = latestDate;
    } else {
      newProm[line].startDate = new Date(
        parseInt(newProm.year),
        newProm.month - 1,
        1
      ).toDateString();
      newProm[line].endDate = new Date(
        parseInt(newProm.year),
        newProm.month,
        0
      ).toDateString();
    }

    // Set missing dates equal to startDate and endDate
    for (var j = 0; j < dateTypes.length; j++) {
      if (
        db.meta.fundTypes?.[newProm[line].type]?.promCreationFields?.includes(
          dateTypes[j]
        ) &&
        !newProm[line][startFields[j]]
      ) {
        newProm[line][startFields[j]] = newProm[line].startDate;
        newProm[line][endFields[j]] = newProm[line].endDate;
      }
    }

    // Set account value based on type
    newProm[line].account = db.meta.fundTypes?.[newProm[line].type]?.accountKey;

    const lineKey = `${newKey}-${i}`;
    newProm.lines[lineKey] = { ...newProm[line] };

    delete newProm[line];
  }

  // "lineN" keys are not supposed to be saved to Firebase -- remove.
  removeLineKeys(newProm);

  newProm.tags?.forEach(tagKey => {
    Mixpanel.track("Tag Added in Promotion Submit", {
      "Tag Name": db.meta.promotionTags[tagKey].name
    });
  });

  newProm = processProm({ ...newProm }, db.meta);
  if (newState.callback) {
    newState.callback(newProm);
  }
  Object.assign(newState, {
    newProm
  });

  return newState;
}

function autoFillStartEndDates(newState, db, mode) {
  const { meta } = db;
  const newProm = { ...newState.prom };
  const distributorName = newProm.distributor?.[0];
  const distributorId = Object.entries(db.customers)
    .filter(([key, field]) => field.name === distributorName)?.[0]?.[0]
    .toString();
  const useBuyIn = meta.use_buyin_calendar?.[distributorId];

  const buyInCalendar = meta.buyin_calendar?.[distributorId];
  const buyInConflict = newProm.distributor.length !== 1;
  newState.lines.forEach(line => {
    if (newProm[line]) {
      const firstDay = new Date(newProm.year, newProm.month - 1, 1);
      const lastDay = new Date(newProm.year, newProm.month, 0);

      startFields.forEach(field => {
        const isBuyIn = field === "buyinStartDate";
        if (!buyInConflict && useBuyIn && isBuyIn && mode !== "edit") {
          newProm[line][field] =
            buyInCalendar?.[newProm.year]?.[newProm.month - 1]?.start ??
            firstDay.toDateString();
        } else if (!newProm[line][field] || (buyInConflict && isBuyIn)) {
          newProm[line][field] = firstDay.toDateString();
        }
      });
      endFields.forEach(field => {
        const isBuyIn = field === "buyinEndDate";
        if (!buyInConflict && useBuyIn && isBuyIn && mode !== "edit") {
          newProm[line][field] =
            buyInCalendar?.[newProm.year]?.[newProm.month - 1]?.end ??
            lastDay.toDateString();
        } else if (!newProm[line][field] || (buyInConflict && isBuyIn)) {
          newProm[line][field] = lastDay.toDateString();
        }
      });
    }
  });
  Object.assign(newState, {
    prom: newProm
  });
}

function handleNext(newState, db, mode) {
  let allGood = 1;
  const { stepIndex } = newState;
  // Required fields at each step
  const required = [
    ["name", "customer.name", "customer.key", "month", "year", "distributor"], // step 0
    [], // step 1
    [] // step 2
  ];

  for (var i = 1; i <= newState.lines.length; i++) {
    // add fields specific to each line
    if (newState.prom[`line${i}`]) {
      required[1].push(`line${i}.productGroup`);
    }
  }

  for (let step = 0; step < 3; step++) {
    if (stepIndex == step) {
      for (var i = 0; i < required[step].length; i++) {
        var newProm = { ...newState.prom };
        // Currently only handles depth 2 in processing name of field
        const spl = required[step][i].split(".");
        if (spl.length == 1) {
          if (
            !newState.prom[spl[0]] ||
            newState.prom[spl[0]] === "" ||
            newState.prom[spl[0]].length === 0
          ) {
            newProm[spl[0]] = "";
            Object.assign(newState, { prom: newProm });
            allGood = 0;
          }
        } else if (spl.length == 2) {
          if (!newState.prom[spl[0]][spl[1]]) {
            newProm[spl[0]][spl[1]] = "";
            Object.assign(newState, { prom: newProm });
            allGood = 0;
          }
        }
      }
    }
  }

  // contact keys if new contact
  if (!db.permissions.includes("addContacts")) {
    let counter = 1;
    let contactState = newState.prom.contact1;
    while (contactState?.name != null) {
      if (contactState.name && !contactState?.key) {
        contactState.key = "";
        allGood = 0;
      }
      counter += 1;
      contactState = newState.prom[`contact${counter}`];
    }
  }

  // Other Checks

  // see if prom name already exists
  // checked when going from step 0 -> step 1
  // and not in editing mode, unless we're doing something to an unconfirmed promotion
  const unconfirmed =
    newState.defaultProm && newState.defaultProm.duplicatedFrom;
  const warningsExist = Boolean(newState.fieldWarnings);
  if (stepIndex == 0 && (mode != "edit" || unconfirmed)) {
    for (var key in db.promotions) {
      var existingProm = db.promotions[key];
      if (
        db.meta.statuses[existingProm.status] != "Cancelled" &&
        (newState.prom.name === existingProm.name ||
          (newState.newProm && newState.prom.name === newState.newProm.name))
      ) {
        newState.fieldErrors.promNameExists = true;
        allGood = 0;
        break;
      }
      // check similar names only once
      if (db.meta.statuses[existingProm.status] != "Cancelled") {
        const existingNames = existingProm.name.toLowerCase().split(" ");
        const newNames = newState.prom.name.toLowerCase().split(" ");

        const toDeduplicateKeywords = [];
        if (newState.prom.month) {
          toDeduplicateKeywords.push(months[newState.prom.month - 1]);
        }
        if (newState.prom.year) {
          toDeduplicateKeywords.push(newState.prom.year.toString());
        }
        if (newState.prom.customer.name) {
          toDeduplicateKeywords.push(...newState.prom.customer.name.split(" "));
        }

        toDeduplicateKeywords.forEach(ele => {
          removeVal(existingNames, ele);
          removeVal(newNames, ele);
        });

        if (existingNames.join(" ") == newNames.join(" ")) {
          if (!newState.fieldWarnings) {
            Object.assign(newState, {
              fieldWarnings: `Similar promotion(s) detected: ${existingProm.name}`
            });
          } else {
            newState.fieldWarnings += `, ${existingProm.name}`;
          }
        }
      }
    }
    // if OK, clear error
    if (newState.fieldErrors.promNameExists && allGood) {
      Object.assign(newState, {
        fieldErrors: Object.assign(newState.fieldErrors, {
          promNameExists: false
        })
      });
    }
    // make sure month/year are ok
    if (newState.fieldErrors.promCreatedInPast) {
      allGood = 0;
    }
  }
  // clear warnings when advancing
  if (warningsExist) {
    delete newState.fieldWarnings;
  }

  // make sure discount is positive (promoted price is below base price)
  // also make sure all applicable fields have valid numbers
  if (stepIndex == 1) {
    const discountErrors = {};
    const numberFields = [
      "spendRate",
      "totalExpSales",
      "lumpSumSpend",
      "basePrice",
      "retailPrice"
    ];
    for (var i = 0; i < newState.lines.length; i++) {
      var line = newState.prom[newState.lines[i]];
      if (
        line &&
        line.basePrice &&
        line.retailPrice &&
        line.basePrice < line.retailPrice
      ) {
        discountErrors[`${newState.lines[i]}Discount`] = true;
      }
      for (var j = 0; j < numberFields.length; j++) {
        if (
          line &&
          numberFields[j] in line &&
          line[numberFields[j]] !== null &&
          line[numberFields[j]] !== undefined &&
          Number.isNaN(parseFloat(line[numberFields[j]]))
        ) {
          allGood = 0;
        } else if (
          line[numberFields[j]] === null ||
          line[numberFields[j]] === undefined
        ) {
          // means the user deleted this field, clear it out completely
          delete line[numberFields[j]];
        }
      }
    }
    if (Object.keys(discountErrors).length > 0) {
      Object.assign(newState, {
        fieldErrors: Object.assign(newState.fieldErrors, discountErrors)
      });
      allGood = 0;
    }
  }

  const errorLines = new Set();

  // ensure valid date ranges
  if (stepIndex === 1) {
    for (let h = 0; h < newState.lines.length; h++) {
      // add fields specific to each line
      if (newState.prom[newState.lines[h]]) {
        for (
          let k = 0;
          k < Math.min(startFields.length, endFields.length);
          k++
        ) {
          const parsedEndDate = Date.parse(
            newState.prom[newState.lines[h]][endFields[k]]
          );
          const parsedStartDate = Date.parse(
            newState.prom[newState.lines[h]][startFields[k]]
          );
          if (
            isNaN(parsedEndDate) ||
            isNaN(parsedStartDate) ||
            parsedEndDate < parsedStartDate
          ) {
            errorLines.add(h);
            allGood = 0;
          }
        }
      }
    }
  }

  // make sure at least one product is selected in custom productGroup selection, and a promotion type is selected
  if (stepIndex == 1) {
    var newProm = { ...newState.prom };
    for (var i = 0; i < newState.lines.length; i++) {
      var line = newState.prom[newState.lines[i]];
      if (
        (line && !line.productGroup) ||
        (line.productGroup == "custom" &&
          (!line.product || line.product.length == 0))
      ) {
        newProm[newState.lines[i]].productGroup = "";
        allGood = 0;
        errorLines.add(i);
      } else if (!line?.type && line?.type !== 0) {
        newProm[newState.lines[i]].typeSelected = false;
        allGood = 0;
        errorLines.add(i);
      } else {
        newProm[newState.lines[i]].typeSelected = true;
      }

      const typeFields =
        db.meta.fundTypes?.[line.type]?.promCreationFields ?? [];
      const noLumpSum = !line.lumpSumSpend && line.lumpSumSpend !== 0;
      const noRateInfo =
        (!line.spendRate && line.spendRate !== 0) ||
        (!line.totalExpSales && line.totalExpSales !== 0);

      // check for both spend rate and volume
      if (typeFields?.includes("rate")) {
        if (noRateInfo && !(typeFields?.includes("lumpsum") && !noLumpSum)) {
          line.rateError = true;
          allGood = 0;
          errorLines.add(i);
        } else {
          line.rateError = false;
        }
      }

      // check lump sum
      const ignoreFields = ["monthyear", "instore", "scanback", "buyin"];
      let lumpSumOnly = true;
      for (var j = 0; j < typeFields?.length; j++) {
        if (
          typeFields[j] != "lumpsum" &&
          !ignoreFields.includes(typeFields[j])
        ) {
          lumpSumOnly = false;
          break;
        }
      }
      if (lumpSumOnly) {
        if (noLumpSum) {
          line.lumpSumError = true;
          allGood = 0;
          errorLines.add(i);
        } else {
          line.lumpSumError = false;
        }
      }

      // special case for rate + lumpsum, github #129
      if (typeFields?.includes("rate") && typeFields?.includes("lumpsum")) {
        if (line.rateError && noLumpSum) {
          line.lumpSumError = true;
        }
      }
      if (!typeFields?.includes("lumpsum") && !noLumpSum) {
        delete newProm[newState.lines[i]].lumpSumSpend;
      }
      if (!typeFields?.includes("rate") && !noRateInfo) {
        delete newProm[newState.lines[i]].spendRate;
        delete newProm[newState.lines[i]].totalExpSales;
      }
    }

    // check that at least one line exists
    if (newState.lines.length === 0) {
      allGood = 0;
    }
    // check duplicate promotion
    if (mode != "edit" || unconfirmed) {
      for (var key in db.promotions) {
        var existingProm = db.promotions[key];
        const existingPromKeys = Object.keys(existingProm.lines);
        if (
          db.meta.statuses[existingProm.status] != "Cancelled" &&
          existingPromKeys.length === newState.lines.length
        ) {
          let duplicate = true;
          for (let i = 0; i < existingPromKeys.length; i++) {
            if (
              objDiff(
                existingProm.lines[existingPromKeys[i]],
                newState.prom[newState.lines[i]]
              ).length > 0
            ) {
              duplicate = false;
              break;
            }
          }
          if (duplicate) {
            if (!newState.fieldWarnings) {
              Object.assign(newState, {
                fieldWarnings: `Similar promotion(s) detected: ${existingProm.name}`
              });
            } else {
              newState.fieldWarnings += `, ${existingProm.name}`;
            }
          }
        }
      }
      // set warning based on flag
    }

    Object.assign(newState, { prom: newProm });
  }

  Object.assign(newState, { allGood, errorLines });

  if (allGood) {
    // set default start/end dates based on month/year
    if (stepIndex == 0) {
      autoFillStartEndDates(newState, db, mode);
    }
    Object.assign(newState, {
      stepIndex: stepIndex + 1,
      finished: stepIndex >= 3
    });
    if (stepIndex == 2) {
      return submitProm(newState, db, mode);
    }
  }
  return newState;
}

function handlePrev(newState) {
  const { stepIndex } = newState;
  if (stepIndex > 0) {
    Object.assign(newState, { stepIndex: stepIndex - 1 });
  }
  return newState;
}

function clearLine(newState, db, lineKey, newFields) {
  const newProm = { ...newState.prom };
  const line = newProm[lineKey];
  const newLine = {};
  for (const key in line) {
    if (newFields.includes(key)) {
      newLine[key] = line[key];
    }
  }
  newProm[lineKey] = newLine;
  Object.assign(newState, { prom: newProm });
  autoFillStartEndDates(newState, db);
  return newState;
}

function updateField(newState, db, field, value, mon, yr) {
  const newProm = { ...newState.prom };
  let { defaultDates, defaultDateRemount } = newState;
  const spl = field.split(".");
  const dateField = spl[spl.length - 1];

  // ensure that non-admins cannot create promotions in the past
  const currentMonth = new Date().getMonth() + 1; // month from javascript is numbered from 0
  const currentYear = new Date().getFullYear();
  let month;
  let year;
  if (field == "month" || field == "year") {
    month = "month" in newProm ? newProm.month : null;
    year = "year" in newProm ? newProm.year : null;
    if (field == "month") {
      month = value;
    } else if (field == "year") {
      year = value;
    }
    if (
      month &&
      year &&
      (year < currentYear || (year == currentYear && month < currentMonth)) &&
      !db.permissions.includes("admin")
    ) {
      Object.assign(newState.fieldErrors, { promCreatedInPast: true });
    } else {
      Object.assign(newState.fieldErrors, { promCreatedInPast: false });
    }
  }

  // Set default value across all lines, IF that default value has not been set yet
  if (
    (startFields.includes(dateField) || endFields.includes(dateField)) &&
    !(dateField in defaultDates)
  ) {
    defaultDates[dateField] = value;
    for (var i = 0; i < newState.lines.length; i++) {
      const line = newProm[newState.lines[i]];
      if (line) {
        line[dateField] = value;
      }
    }
    defaultDateRemount++;
    Object.assign(newState, {
      defaultDates,
      prom: newProm,
      defaultDateRemount
    });
  }

  // delete distributors that are no longer visible
  if (field == "distributor" && value) {
    // if distributor is being set, customer is guaranteed to be set
    // and value is guaranteed to be an array
    const newValue = [];
    const customer = db.customers[newProm.customer.key];
    customer.key = newProm.customer.key;
    if (mon !== null && yr !== null) {
      month = mon;
      year = yr;
    }

    const distributors = getVisibleDistributors(
      customer.key,
      customer,
      new Date(year, month - 1)
    );
    const distributorNames = distributors.map(x => db.customers[x].name);

    for (var i = 0; i < value.length; i++) {
      if (distributorNames.includes(value[i])) {
        newValue.push(value[i]);
      }
    }

    value = newValue;
  }

  if (spl.length === 2 && spl[1] === "lift") {
    newProm[spl[0]].liftUpdated = true;
  }

  if (spl.length == 1) {
    newProm[spl[0]] = value;
  } else if (spl.length == 2) {
    newProm[spl[0]][spl[1]] = value;
  } else if (spl.length == 3) {
    newProm[spl[0]][spl[1]][spl[2]] = value;
  }

  Object.assign(newState, {
    prom: newProm
  });

  return newState;
}

function addContact(newState) {
  const newProm = { ...newState.prom };
  const newAddContactNum = newState.addContactNum + 1;

  const newContacts = [];
  for (let i = 1; i <= newAddContactNum; i++) {
    const c = `contact${i}`;
    newContacts.push(c);
    if (!(c in newProm)) {
      newProm[c] = {};
    }
  }

  Object.assign(newState, {
    addContactNum: newAddContactNum,
    newContacts,
    prom: newProm
  });
  return newState;
}

function incrementLine(newState, direction, lineName, db) {
  if (direction) {
    // add line
    const numLines = newState.lines.length;
    const newNumLines = numLines + 1;

    const newProm = { ...newState.prom };
    const lines = [];
    for (let i = 1; i <= newNumLines; i++) {
      const l = `line${i}`;
      lines.push(l);
      if (!(l in newProm)) {
        const newLine = {
          type: ""
        };
        for (const dateField in newState.defaultDates) {
          newLine[dateField] = newState.defaultDates[dateField];
        }
        newProm[l] = newLine;
      }
    }

    setSelectedLineIndex(newState, 0);
    Object.assign(newState, {
      lines,
      prom: newProm,
      selectedLineIndex: 0
    });
    autoFillStartEndDates(newState, db); // set default dates for new line
  } else {
    let removeLineSpendLineError = false;
    let removeLineInvoiceLineError = false;

    const lineIndex = parseInt(lineName.substring(4)) - 1;
    const lineKey = `${newState.prom.promKey}-${lineIndex.toString()}`;

    const existingMatched = db.promotionInvoices[newState.prom.promKey] ?? {};
    const existingMatchedLine = existingMatched.lines ?? {};
    const existingMatchedLineKey = existingMatchedLine[lineKey] ?? {};
    const matchedInvoiceLines =
      existingMatchedLineKey.matchedInvoiceLines ?? [];
    if (Object.keys(matchedInvoiceLines).length > 0) {
      removeLineInvoiceLineError = true;
      Object.assign(newState, {
        removeLineInvoiceLineError: true
      });
    }

    for (const k in db.actMoney) {
      const dbLineKey = db.actMoney[k].lineKey;
      if (
        dbLineKey.substring(0, dbLineKey.lastIndexOf("-")) ==
        lineKey.substring(0, lineKey.lastIndexOf("-"))
      ) {
        removeLineSpendLineError = true;
        Object.assign(newState, {
          removeLineSpendLineError: true
        });
        break;
      }
    }

    if (removeLineInvoiceLineError || removeLineSpendLineError) {
      return newState;
    }

    const newProm = { ...newState.prom };
    const { lines } = newState;
    lines.splice(lines.indexOf(lineName), 1);
    delete newProm[lineName];
    const oldSelectedLineIndex = newState.selectedLineIndex;
    Object.assign(newState, {
      prom: newProm
    });
    if (
      oldSelectedLineIndex &&
      oldSelectedLineIndex === newState.lines.length
    ) {
      setSelectedLineIndex(newState, newState.lines.length);
    }
  }
  return newState;
}

function addFiles(newState, files) {
  const { fileInfo } = newState;
  // prevent files from being uploaded more than once
  const fileNames = fileInfo.map(item => item.name);
  const newFiles = files.filter(file => !fileNames.includes(file.name));
  for (const file of newFiles) {
    fileInfo.push({
      file,
      name: file.name,
      isContract: false,
      attachedLine: [0]
    });
  }

  Object.assign(newState, { fileInfo });
  return newState;
}

function removeFile(newState, file) {
  const fileNames = newState.fileInfo.map(item => item.name);
  const fileIndex = fileNames.indexOf(file.name);
  if (fileIndex < 0) return newState;

  const { fileInfo } = newState;
  fileInfo.splice(fileIndex, 1);
  Object.assign(newState, { fileInfo });
  return newState;
}

function updateFileAttributes(newState, fileIndex, newAttributes) {
  if (fileIndex < 0 || fileIndex > newState.fileInfo.length) return newState;

  const { fileInfo } = newState;
  fileInfo[fileIndex] = newAttributes;
  Object.assign(newState, { fileInfo });
  return newState;
}

// removes keys of the form "lineN" from a dict.
function removeLineKeys(dict) {
  for (const key of Object.keys(dict)) {
    const spl = key.split("line");
    if (spl.length == 2 && !isNaN(parseInt(spl[1]))) {
      delete dict[key];
    }
  }
}

function setDefault(newState, dp, callback, db, mode) {
  const allContacts = db.contacts;

  const defaultProm = jQuery.extend(true, {}, dp);

  // if this promotion is unconfirmed, look for the original one's files instead
  // (the "file-containing prom")
  let fileContainingProm = { ...defaultProm };
  if (defaultProm.duplicatedFrom) {
    fileContainingProm = defaultProm.duplicatedFrom;
  }

  Object.assign(newState, { fileInfo: [] });

  // "lineN" keys don't belong in Firebase in the first place -- ignore them.
  removeLineKeys(defaultProm);

  if (mode != "edit") {
    delete defaultProm.promKey; // clear existing promKey, if it exists and we are not in editing mode
    delete defaultProm.closed;
    delete defaultProm.starred;
    delete defaultProm.spendKeys;
  }

  // set initial name value
  defaultProm.nameInit = defaultProm.name;

  // initialize state to pending
  defaultProm.status = 0;

  // convert lines to AddPromotion format
  const lines = [];
  const lineKeys = Object.keys(defaultProm.lines).sort((a, b) => {
    try {
      const aIndex = parseInt(a.substring(a.lastIndexOf("-") + 1));
      const bIndex = parseInt(b.substring(b.lastIndexOf("-") + 1));
      return aIndex - bIndex;
    } catch (err) {
      return a - b;
    }
  });
  for (var i = 1; i <= lineKeys.length; i++) {
    const line = defaultProm.lines[lineKeys[i - 1]];
    if (!line.productGroup) {
      line.productGroup = "custom";
    }
    const lineName = `line${i}`;
    delete line.closed;

    defaultProm[lineName] = { ...line };
    lines.push(lineName);
  }
  delete defaultProm.lines;

  // convert customer
  defaultProm.customer = {
    key: defaultProm.customer,
    name: defaultProm.customerName
  };

  // convert contacts
  const newContacts = [];
  let newAddContactNum = 1;
  if (defaultProm.contact) {
    for (var i = 0; i < defaultProm.contact.length; i++) {
      const contact = allContacts[defaultProm.contact[i]];
      const c = `contact${i + 1}`;
      newContacts.push(c);
      defaultProm[c] = {
        key: defaultProm.contact[i],
        name: contact.name,
        email: contact.email
      };
    }
    newAddContactNum = defaultProm.contact.length;
  } else {
    newContacts.push("contact1");
    defaultProm.contact1 = {};
  }

  // get rid of existing comments
  if (mode != "edit") {
    delete defaultProm.commentList;
    delete defaultProm.comments;
  }

  // set file names and call file names from storage
  if (callback == null) {
    callback = newState.callback;
  }

  // delete distributors that no longer exist
  if (mode != "edit" && defaultProm.distributor) {
    const customer = db.customers[defaultProm.customer.key];
    const distributors = getVisibleDistributors(
      defaultProm.customer.key,
      customer,
      new Date(defaultProm.year, defaultProm.month - 1)
    );
    const distributorNames = distributors.map(x => db.customers[x].name);

    defaultProm.distributor = defaultProm.distributor.filter(x =>
      distributorNames.includes(x)
    );
    if (!defaultProm.distributor.length) {
      defaultProm.distributor = null;
    }
  }

  Object.assign(newState, {
    prom: defaultProm,
    defaultProm,
    fileInfo: defaultProm.fileInfo,
    newContacts,
    addContactNum: newAddContactNum,
    lines,
    callback,
    fileContainingProm,
    mode
  });
  return newState;
}

function reset(newState) {
  return initialState;
}

function setSelectedLineIndex(newState, newIndex) {
  newIndex = newIndex || 0;
  Object.assign(newState, {
    selectedLineIndex:
      newIndex < newState.lines.length ? newIndex : newState.lines.length - 1
  });
  return newState;
}

function setFileInfo(newState, fileInfo, filesDownloaded) {
  for (let i = 0; i < fileInfo.length; i++) {
    fileInfo[i].uploaded = true;
  }
  Object.assign(newState, {
    fileInfo: Array.from(fileInfo),
    allFilesDownloaded: filesDownloaded
  });
  return newState;
}

function changeMode(newState, mode) {
  Object.assign(newState, {
    mode,
    modalTitle: modeTitles[mode]
  });
  return newState;
}

// Reducers
function addPromotionReducer(state = initialState, action) {
  const newState = jQuery.extend(true, {}, state);
  newState.removeLineSpendLineError = false;
  newState.removeLineInvoiceLineError = false;
  switch (action.type) {
    case CHANGE_STEP:
      if (action.forward) {
        return handleNext(newState, action.db, action.mode);
      }
      return handlePrev(newState);

    case CLEAR_LINE:
      return clearLine(newState, action.db, action.line, action.newFields);
    case UPDATE_FIELD:
      return updateField(
        newState,
        action.db,
        action.field,
        action.value,
        action.mon,
        action.yr
      );
    case RESET:
      return reset(newState);
    case INCREMENT_LINE:
      return incrementLine(
        newState,
        action.direction,
        action.lineName,
        action.db
      );
    case ADD_CONTACT:
      return addContact(newState);
    case ADD_FILES:
      return addFiles(newState, action.file);
    case REMOVE_FILE:
      return removeFile(newState, action.file, action.isContract);
    case CHANGE_MODE:
      return changeMode(newState, action.mode);
    case UPDATE_FILE_ATTRIBUTES:
      return updateFileAttributes(newState, action.index, action.newAttributes);
    case SET_DEFAULT:
      return setDefault(
        newState,
        action.dp,
        action.callback,
        action.db,
        action.mode
      );
    case SET_LINE_INDEX:
      return setSelectedLineIndex(newState, action.newIndex);
    case SET_FILE_INFO:
      return setFileInfo(newState, action.fileInfo, action.filesDownloaded);
    case SET_STEP_INDEX:
      return {
        ...newState,
        stepIndex: action.stepIndex
      };
    case ADV_DUP_ERROR:
      return {
        ...newState,
        advDuplicationError: action.advDuplicationError
      };
    case ADV_DUP_PROCEED:
      return {
        ...newState,
        continueFromAdvDuplicationModal: true
      };
    default:
      return newState;
  }
}

export default addPromotionReducer;
