import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useParams } from "react-router-dom";
import { useDb } from "contexts/Db";
import DRMNoDataState from "components/Deductions/DeductionsReconciliation/NoData/DRMNoDataState";
import DRMBackButton from "components/Deductions/components/DRMBackButton";
import {
  DRMFeature,
  DRMRouteState
} from "components/Deductions/DeductionsReconciliation/redux/DRMSlice";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "store";
import TransactionProfile from "./TransactionProfile";

const useStyles = makeStyles(() => ({
  container: { fontFamily: "Oxygen" }
}));

export default function TransactionProfilePage(): JSX.Element {
  const classes = useStyles();
  const { key } = useParams();
  const { guiMode } = useSelector(
    (state: RootState) => state.drm,
    shallowEqual
  );
  const db = useDb();
  const noDataState = Boolean(!db.erpTransactions[key]);

  return (
    <div className={classes.container}>
      {noDataState ? (
        <DRMNoDataState
          text="Sorry, we were unable to find this transaction!"
          backButton={
            <DRMBackButton
              text="View All Transactions"
              toUrl={`/${DRMFeature.RECONCILIATION}/${DRMRouteState.TRANSACTIONS}/${guiMode}`}
            />
          }
        />
      ) : (
        <TransactionProfile transactionKey={key} />
      )}
    </div>
  );
}
